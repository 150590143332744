import React from "react";
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {filterMeasurementByString, getMeasurementLongLabel, getMeasurementShortLabel} from "../action/SAMAction";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";


/**
 * Component used for SELECTION of measurements.
 */

class MeasurementPanelContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      measurementValue: null // buffer to keep value before confirmation with OK button
    };
    // ["filterMeasurement"].forEach(name => {
    //   this[name] = this[name].bind(this);
    // });

  }

  componentDidMount() {
    const {multi} = this.props;
    if (multi)
      this.setState({measurementValue: this.props.selectedMeasurementConfiguration.slice(0)});
    else
      this.setState({measurementValue: this.props.selectedMeasurementConfiguration})
  }

  componentDidUpdate(prevProps, prevState, ss) {
    const {multi} = this.props;
    if (prevState.dialogVisible !== this.state.dialogVisible && this.state.dialogVisible) {
      if (multi)
        this.setState({measurementValue: this.props.selectedMeasurementConfiguration.slice(0)});
      else
        this.setState({measurementValue: this.props.selectedMeasurementConfiguration});
    }
  }


  render() {
    const {rawData, selectedMeasurementConfiguration, updateSelectedMeasurement, isMultiple} = this.props;

    const itemTemplate = (option) => {
      return (
        <div className="ui-helper-clearfix">
          <span
            style={{margin: '.5em .25em 0.5em 0.5em'}}>{getMeasurementShortLabel(option, rawData)} </span>
        </div>
      );
    };

    return (
      <div>
        <h3>{isMultiple?"Measurements":"Measurement"}
          <Button onClick={() => {
            this.setState({dialogVisible: true})
          }}
                  icon={"fa fa-edit"}
                  title={"Edit"}
                  style={{float: "right"}}/>
        </h3>

        <div style={isMultiple ? {display: "block"} : {display: "flex", justifyContent: "space-between"}}>
          {!isMultiple &&
          <div> {selectedMeasurementConfiguration != null ? getMeasurementLongLabel(selectedMeasurementConfiguration, rawData) : "Not selected"} </div>
          }
          {
            isMultiple && selectedMeasurementConfiguration != null && selectedMeasurementConfiguration.length > 0 &&
            selectedMeasurementConfiguration.map(el => {
              return <div style={{marginBottom: "1em"}}>{getMeasurementLongLabel(el, rawData)}</div>
            })
          }
          <Dialog
            appendTo={document.body}
            header={isMultiple ? "Select measurements" : "Select measurement"}
            onHide={() => this.setState({dialogVisible: false})}
            visible={this.state.dialogVisible}
            width={600}
            height={400}
          >
            <div>
              <div>
                <DataTable
                  value={rawData.measurementConfigurations}
                  selection={this.state.measurementValue}
                  selectionMode={isMultiple ? "multiple" : "single"}
                  metaKeySelection={false}
                  scrollable={true}
                  scrollHeight={"250px"}
                  onSelectionChange={(e) => this.setState({measurementValue: e.data})}
                >
                  <Column body={itemTemplate} filter={true} field={"id"}
                          filterFunction={(v, f) => filterMeasurementByString(v, f, rawData)}
                          filterMatchMode={"custom"}/>
                </DataTable>
              </div>
              <div>
                <Button onClick={() => {
                  this.setState({dialogVisible: false});
                  updateSelectedMeasurement(this.state.measurementValue);
                }} icon={"fa fa-check"} label={"OK"}/>
                <Button onClick={() => {
                  this.setState({dialogVisible: false})
                }} icon={"fa fa-close"} label={"Cancel"}/>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default MeasurementPanelContent;

MeasurementPanelContent.propTypes = {
  rawData: PropTypes.object.isRequired,
  selectedMeasurementConfiguration: PropTypes.object.isRequired,
  updateSelectedMeasurement: PropTypes.func.isRequired,
  isMultiple: PropTypes.bool.isRequired // single or multiple
};
