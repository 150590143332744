import React from 'react';
import PropTypes from 'prop-types';
import {RadioButton} from "primereact/components/radiobutton/RadioButton";
import {create2DArray} from "../../../helpers/arrays";


class LayoutElementSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCell: this.props.selectedCell,
    };
    // ["isRange"]
    //   .forEach(name => {
    //     this[name] = this[name].bind(this);
    //   });
  }


  render() {
    const {selectedLayout,selectedCell,onChange} = this.props;
    let Columns = this.props.Columns;
    const style = {
      display:'inline-block',
      minWidth:
        Columns * this.props.boxSize + (Columns + 5) * this.props.cellBorder,
    };




    return (
      <div
        className="layoutChooser layoutChooser-dropdown-menu"
        role="menu"
        style={style}
      >
        <table>
          <tbody>
          {create2DArray(selectedLayout.row+1, selectedLayout.col+1)
            .map((row, i) => {
            return (
              <tr key={i}>
                {row.map((cell, j) => {
                  return (
                    <td
                      className={cell.className}
                      style={{
                        width: this.props.boxSize,
                        height: this.props.boxSize,
                        border: 'solid 1px black',
                      }}
                      key={j}
                    >
                      <RadioButton checked={i===selectedCell.row && j === selectedCell.col} onChange={()=>{onChange({row:i, col:j})}}/>
                    </td>
                  );
                })}
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    );
  }
}


export default LayoutElementSelector;

LayoutElementSelector.propTypes = {
  Rows: PropTypes.number.isRequired,
  Columns: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  selectedCell: PropTypes.object,
  selectedLayout: PropTypes.object,
  boxSize: PropTypes.number.isRequired,
  cellBorder: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,

};

LayoutElementSelector.defaultProps = {
  Rows: 3,
  Columns: 3,
  visible: true,
  boxSize: 20,
  cellBorder: 1,
  selectedCell: {
    row: 0,
    col: 0,
  },
  selectedLayout:{row:0,col:0}
};