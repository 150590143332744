import React from "react";
import PropTypes from "prop-types";


const SPEED = 0.5;  // speed coefficient for mouse movement
/**
 * Panel for displaying images.
 * It scale down image, to keep original ratio.
 *
 */
class ManualToolHelpPageImagePanel extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      scale : 1,
      brightness: 1,
      contrast:1,
      wl: false,
      panning : false,
      zooming : false,
      pointX : 0,
      pointY : 0,
      start : { x: 0, y: 0 },
    };
    ["onWheel","onMouseUp","onMouseDown","onMouseMove","onContextMenu","getNormalizedDelta","clear"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  onWheel(e){
    const {onNext,onPrevious} = this.props;
    let delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);
    if (delta>0)
      onNext();
    else
      onPrevious();

  }
  clear(){
    this.setState({
      scale : 1,
      brightness: 1,
      contrast:1,
      wl: false,
      panning : false,
      zooming : false,
      pointX : 0,
      pointY : 0,
      start : { x: 0, y: 0 },
    });
  }

  onMouseUp(e){
    e.preventDefault();
    e.stopPropagation();
    console.log("End");
    console.log(this.state);
    this.setState({
      panning:false,
      zooming:false,
      wl:false
    });
  }

  onMouseDown(e){
    const {pointX,pointY,scale} = this.state;
    e.stopPropagation();
    if (!e.ctrlKey)
      this.setState({
      start:{ x: e.clientX - pointX, y: e.clientY - pointY },
      wl: !e.shiftKey && e.button === 0,
      panning:e.shiftKey && e.button === 0,
      zooming : e.button === 2
     });
    else
    this.clear();
  }

  getNormalizedDelta(e){
    const {pointX,pointY,scale,start} = this.state;
    const wx = e.target.clientWidth;
    const wy = e.target.clientHeight;
    const dy = e.clientY - pointY - start.y;
    const dx = e.clientX - pointX - start.x;

    return {
      deltaX: dx/wx*SPEED,
      deltaY: dy/wy*SPEED
    }
  }

  onMouseMove(e){
    const {panning,start,zooming,wl,scale,brightness,contrast} = this.state;
      e.preventDefault();
      if (!panning && !zooming && !wl) {
        return;
      }
      if (panning && e.shiftKey){
        this.setState({pointX:(e.clientX - start.x),pointY:(e.clientY - start.y)});
        return;
      }
      const {deltaX,deltaY} = this.getNormalizedDelta(e);
      if (zooming && !e.shiftKey){
         this.setState({scale: scale + deltaY<1?1.0: scale + deltaY})
      }
      if(wl && !e.shiftKey){
        this.setState({brightness: brightness + deltaY < 0 ? 0 : brightness + deltaY});
        this.setState({contrast: contrast + deltaX < 0 ? 0 : contrast + deltaX});
      }
  }
  onContextMenu(e){
    e.preventDefault();
    e.stopPropagation();
  }

  render(){
  const {pointX,pointY,scale,brightness,contrast} = this.state;
  const {width,height} = this.props;
  const imgStyle = {
    transform: "translate(" + pointX + "px, " + pointY + "px) scale(" + scale + ")",
    filter: "brightness("+brightness+") contrast("+contrast+")"
  };

  return <div id="help-panel-image-dialog-container"
              onMouseDown={this.onMouseDown}
              onMouseMove={this.onMouseMove}
              onMouseUp={this.onMouseUp}
              draggable={false}
              style={imgStyle}
              onWheel={this.onWheel}
              onContextMenu={(e)=>this.onContextMenu(e)}
  >
    <img
      id={"help-panel-image-dialog"}
      src={this.props.src}
      alt={this.props.alt}
      style={{
        objectFit: 'scale-down',
        width:width+"px",
        height:height+"px"
      }}
      onContextMenu={(e)=>this.onContextMenu(e)}
    />
  </div>
  }
}

ManualToolHelpPageImagePanel.propTypes = {
  src:PropTypes.string.isRequired,
  alt:PropTypes.string.isRequired,
  onNext:PropTypes.func.isRequired,
  onPrevious:PropTypes.func.isRequired,
  width:PropTypes.number.isRequired,
  height:PropTypes.number.isRequired
};

ManualToolHelpPageImagePanel.defaultProps = {
  onNext:()=>{},
  onPrevious:()=>{},
  width:600,
  height:400
};

export default ManualToolHelpPageImagePanel;



