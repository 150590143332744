import React from 'react'
import {scaleLinear, scaleSequential} from "d3-scale";
import { event as d3Event, select, selectAll} from "d3-selection";
import {axisBottom, axisLeft} from "d3-axis";
import {line,area} from "d3-shape";
import d3Tip from "d3-tip"
import {extent, max, min,histogram, median,mean,deviation} from "d3-array";
import PropTypes from "prop-types";
import {zoom,zoomTransform} from "d3-zoom";
import {xyzoom,xyzoomTransform, xyzoomIdentity} from "d3-xyzoom";
import {rgb} from "d3-color";
import {interpolateRainbow} from "d3-scale-chromatic";
import {contextMenu} from "./contextMenu";
import {REQUEST_STATUS_FAIL} from "../../../../Constants";
import {withTranslation} from "react-i18next";


/** D3-based AvatarScatterPlot Component integrated with React
 *  Example of use:
 *  import {create_data} from "./DataHelper";
 *  import DynamicHistogram from "./d3components/DynamicHistogram";
 *
 *  Each datapoint can be represent 3 modes:
 *   - collective result (many users contributed to a given result - they are presented in Rater histogram),
 *   - duplicated result (in case of perfectly overlapping results they are translated and hidden until the icon representing them is not clicked)
 *   - regular result (click provides image).
 *
 *    Parameters:
 *    data - mandatory [array of ORDERED objects] containing attribute:[y] and may contains additional attribute
 *           to differentiate subpopulations
 *    colorCat - optional [string] - name of attribute for grouping, which MUST appear in data objects (eg. "userId")
 *    width, height - optional  [number] - size of the whole image
 *    onDataPointClick - optional [function] - callback to click event
 *    contextMenu - optional [array] - items of context menu
 *    useAvatars - if use avatars (default true)
 *    Any value less than x0 will be placed in the first bin; any value greater than or equal to x0 but less than x1
 *    will be placed in the second bin; and so on.
 *
 */
class AvatarScatterPlot extends React.Component {

    constructor(){
        super();
        this.state = {
            objects:null
        };
        this.zoomTransform ={};
        ["createPlot"].forEach(name => { this[name] = this[name].bind(this);});
    }
    componentDidMount() {
        const {outerWidth} = this.props;
        if (outerWidth>0)
            this.createPlot();
    }

    componentDidUpdate(previousProps, previousState) {
        const node = this.node;
        const {prefixId,outerWidth} = this.props;

        //needed for initialization - if width was previously 0 and slide was inactive, the plot needs to be redrawn
        if ((previousProps.outerWidth!==outerWidth && outerWidth!=null  && outerWidth>0)
            || (previousProps.viewId!==this.props.viewId)
            || (previousProps.data !== this.props.data)
            || (previousProps.regressionLineParameters !== this.props.regressionLineParameters)
            || ((previousProps.statsServerState!==this.props.statsServerState)&&
                this.props.statsServerState === REQUEST_STATUS_FAIL)
        )
        {
            select(node).selectAll("*").remove();
            selectAll(".d3-tip."+prefixId+"-tip").remove(); //removes all elements only for active panel (prefixId)
            selectAll(".d3-tip."+prefixId+"-tip.n").remove(); //removes all elements only for active panel
            this.createPlot();
        }
        // if ((previousProps.statsServerState!==this.props.statsServerState)&&
        //     this.props.statsServerState === REQUEST_STATUS_FAIL)
        // {
        //    select(this.node)
        //         .append("text")
        //         .attr("class", "rsquared-text")
        //         .attr("x", outerWidth / 2)
        //         .attr("y", "25")
        //         .attr("text-anchor", "middle")
        //         .attr('fill', "red")
        //         .style('font-size', '20px')
        //         .text("Connection lost! Statistics cannot be computed.");
        // }

    }


    createPlot() {
        const {yLabelTip, xLabelTip, xLabel, prefixId, colorCat,stroke,zoomRatio,selected,
            data,colorLUT, opacityMode, yLabel, showXTickValues, showYTickValues, outerWidth, outerHeight,
            xTipValuePrecision, yTipValuePrecision, renderXLabel, renderYLabel, avatarSize, onDataPointClick,
            regressionVisible,regressionLineColor, regressionLineParameters,regressionLineWidth,
            equationVisible,rSquared,rSquaredVisible,statsServerState,t, viewId
        } = this.props;
        const node = this.node;   //svg ref
        // references to global vars  - initialized here
        this.zoomTransform ={};
        let yValue = function(d) {  return d.y;  };  //Accessor, according to d3-array
        let xValue = function(d) {  return d.x;  };  //Accessor, according to d3-array

        // set the dimensions and margins of the graph
        let margin = {
                top: 20,
                right: 50,
                bottom: 50,
                left: 50
            },
            width = outerWidth - margin.left - margin.right,
            height = outerHeight - margin.top - margin.bottom;

        const DOT_RADIUS = avatarSize;
        const STROKE_WIDTH = regressionLineWidth;

        // set the ranges
        let xScale = scaleLinear()
            .range([0, width]);//.nice();
        let yScale = scaleLinear()
            .range([height, 0]);//.nice();

        //-------------------y domain----------------------------------
        let yDomain = extent(data, yValue);
        const ydiff = (yDomain[1]-yDomain[0])/20; //adding margins to data (because of avatar permiter)
        // yScale.domain(yDomain);
        yDomain = [yDomain[0]- ydiff, yDomain[1]+ydiff];
        yScale.domain(yDomain);


        //-------------------x domain----------------------------------

        let xDomain = extent(data, xValue);
        const xdiff = (xDomain[1]-xDomain[0])/20; //adding margins to data (because of avatar permiter)
        // xScale.domain(xDomain);
        xDomain = [xDomain[0]- xdiff, xDomain[1]+xdiff];
        xScale.domain(xDomain);

        //------------------- setting axis-------------------------------
        let xAxis = axisBottom(xScale);
        let yAxis = axisLeft(yScale);
        let color = scaleSequential(interpolateRainbow);

        if(!showXTickValues) xAxis.tickValues([]); //remove ticks if they are not needed
        if(!showYTickValues) yAxis.tickValues([]); //remove ticks if they are not needed

        // set  tips
        let tip = d3Tip()
            .attr("class", "d3-tip "+prefixId+"-tip")  //prefix added to distinguish tips on many panels - d3Tip uses global variables
            .offset([-DOT_RADIUS, 0])
            .html(function(d) {
                if (d.hasOwnProperty('isCollectiveResult'))
                    return "Case id: ".concat(d['caseId'],"<br/>",yLabelTip,": ",d['y'].toFixed(yTipValuePrecision),"<br/>","Number of raters: ",d['numberOfUsers']);
                if (d.hasOwnProperty('isDuplicated'))
                    return "Overlapping data points.<br/>" + "Click to show/hide all.";
                return "User no"   +": " +d['userId'] + "<br/>"
                  + "Case id: "+ d['caseId'] + "<br/>"
                  + yLabelTip +": " +d['y'].toFixed(yTipValuePrecision) + "<br/>"
                  + xLabelTip +": " +d['x'].toFixed(xTipValuePrecision);
            });

        //zoom parameters and handler assignment
        let zooming = xyzoom()
            // .scaleExtent([.001, 100])
            //  .translateExtent([[0, 0], [width, height]])
            //  .extent([[0, 0], [width, height]])
            .on("zoom", zoomEventHandler);


        let avatarClass =[];
        if (opacityMode==="normal")
            avatarClass=["normalAvatar","selectedAvatar"];
        else
            avatarClass=["selectedAvatar","normalAvatar"]
            ;

        // append the svg object to the body of the page and translate the parent group
        // calculate the rectangle embraced by axes
        let svg = select(node)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(zooming)
            .on("dblclick.zoom", null);

        svg.call(tip);

        svg.append("rect")
            .attr("width", width)
            .attr("height", height);

        // x Axis---------------------------
        if(renderXLabel) {
            svg.append("g")
                .attr("class","x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis)
                .append("text")
                .attr("class", "label")
                .attr("fill", "#000")
                .attr("font-size", "0.7em")
                .attr("x", width)
                .attr("y", margin.bottom - 10)
                .style("text-anchor", "end")
                .text(xLabel);}

        else  svg.append("g")
            .attr("class","x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);

        // y Axis---------------------------
        if(renderYLabel) {
            svg.append("g")
                .attr("class", "y axis")
                .call(yAxis)
                .append("text")
                .attr("class", "label")
                .attr("font-size", "0.7em")
                .attr("fill", "#000")
                .attr("transform", "rotate(-90)")
                .attr("y", -margin.left)
                .attr("dy", "1.1em")
                .style("text-anchor", "end")
                .text(yLabel);
        }
        else  svg.append("g")
            .attr("class","y axis")
            .call(yAxis);

        let objects = svg.append("svg")
            .attr("class", "objects")
            .attr("width", width)
            .attr("height", height);
        this.setState({objects:objects});
        if(!(data!=null))
            return;


        // Data Points ---------------------------------
        if (!this.props.useAvatars) {
            objects.selectAll(".dot")
                .data(data)
                .enter()
                .append("circle")
                .attr("class", "dot")
                .attr("r", DOT_RADIUS-stroke)
                .style("fill", function (d) {
                    return (colorCat == null) ? "#000" : color(d[colorCat] / data.length);
                })
                .attr("cx", function (d) {
                    return xScale(d.x);
                })
                .attr("cy", function (d) {
                    return yScale(d.y);
                })
                .on("mouseover", tip.show)
                .on("mouseout", tip.hide)
                .on("click", onDataPointClick)
                .on('contextmenu', (this.props.contextMenu != null) ? contextMenu(this.props.contextMenu) : null);

        }
        else {
            let getHref = (d) => {
                return d.img;
            };
            let getId = (d) => {
                return  "img_".concat(viewId,prefixId,d['userId'])
            };
            let getImg = (d) => {
                return "url(#img_".concat(viewId,prefixId,d['userId'],")") ;
            };

            let defs = svg.append("defs");
            let imgPattern = defs
                .selectAll("pattern")
                .data(data)
                .enter()
                .append("pattern")
                .attr("id", getId)
                .attr("width", 1)
                .attr("height", 1)
                .attr("patternUnits", "objectBoundingBox")
                .append("image")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", DOT_RADIUS*2-2*stroke)
                .attr("height", DOT_RADIUS*2-2*stroke)
                .attr("xlink:href", getHref);



            let elem = objects
                .selectAll("node-group")
                .data(data);


            // instead of setting separately positions of texts and icons, groups are positioned

            const createIdForNodeGroup = (dataPoint)=>{
                if (dataPoint.hasOwnProperty('isDuplicated'))
                    return 'dupId'+ viewId.concat('dataId',dataPoint.userId);
                return viewId.concat('dataId',dataPoint.userId);
            };

            let elemEnter = elem
                .enter()
                .append("g")
                .attr("id", createIdForNodeGroup)
                .attr("class", "node-group")
                .style('display',function(d){
                    if(d.hasOwnProperty('isDuplicateOf')){
                        return 'none';}
                    else
                        return 'block';})
                .attr("transform", function(d, i) {
                // Set d.x and d.y here so that other elements can use it. d is
                // expected to be an object here.
                return "translate(" + xScale(d.x) + "," + yScale(d.y) + ")";
            });


            const fillFunction = (d)=>{
                if (!d.hasOwnProperty('isCollectiveResult') && !d.hasOwnProperty('isDuplicated') )
                    return getImg(d);
                else {
                    if (d.hasOwnProperty('isCollectiveResult') && !d.hasOwnProperty('isDuplicated') )
                    return 'not@existing.user';
                }
            };

            const classFunction = (d)=>{
                if (!d.hasOwnProperty('isCollectiveResult') && !d.hasOwnProperty('isDuplicated') )
                    return 'img';
                else
                    return 'icon';
            };

            /**
             * Translate and freeze relatively to parent node all nodes that have duplicated value.
             * @param d
             * @return {*}
             */
            const translateDuplicates = (d)=>{
                if (d.hasOwnProperty('isDuplicateOf')) {
                    return "translate(" + (-(d["duplicateNo"]-1) * 2 *  DOT_RADIUS) + "," + (-2 * DOT_RADIUS) + ")";
                }
                return null;
            };

            let kolka = elemEnter
                .append("circle")
                .attr("class",classFunction)
                .attr("transform",translateDuplicates)
                .classed(avatarClass[0],true)
                .classed(avatarClass[1],(d)=>{if(selected!=null) return selected.userId===d.userId; return false;})
                .attr("r", DOT_RADIUS-stroke)
                .style("fill", fillFunction )
                .style("stroke", function (d) {
                    if (colorLUT!=null && colorLUT.length>0)
                        return colorLUT[d[colorCat]];
                    else {
                        color(d[colorCat] / data.length);
                        return (colorCat == null || !((color(d[colorCat] / data.length)!=null))) ? "darkorange" : color(d[colorCat] / data.length);
                    }
                })
                .style("stroke-width",stroke)
                .on("mouseout", function(d) {
                    select(this).style("cursor", "default");
                    tip.hide(d,this);
                })
                .on("mouseover", function(d) {
                    select(this).style("cursor", "pointer");
                    select(this).raise();
                    tip.show(d,this);
                })
                .on( 'mouseenter', function(e) {
                    select(this)
                        .transition()
                        .attr("r", DOT_RADIUS*zoomRatio-stroke);
                    defs.select("pattern#"+getId(e)).select("image")
                        .transition()
                        .attr("width", DOT_RADIUS*2*zoomRatio-2*stroke)
                        .attr("height", DOT_RADIUS*2*zoomRatio-2*stroke);
                })
                .on( 'mouseleave', function(e) {
                    select(this)
                        .transition()
                        .attr("r", DOT_RADIUS-stroke);
                    defs.select("pattern#"+getId(e)).select("image")
                        .transition()
                        .attr("width", DOT_RADIUS*2-2*stroke)
                        .attr("height", DOT_RADIUS*2-2*stroke);
                })
                .on("click", function(e) {
                    let alreadySelected=select(this).classed("selectedAvatar");
                    if (e.hasOwnProperty('isDuplicated')){
                        select(this)
                            .classed("selectedAvatar",!alreadySelected);
                        data.filter((el)=>e['userId'] === el['isDuplicateOf'])
                            .forEach((el)=>{
                                select("#".concat(viewId,'dataId',el['userId']))
                                  .style('display',alreadySelected?'none':'block');
                            });
                        return;
                    }
                    if(alreadySelected){
                        select(this)
                            .classed("selectedAvatar",false);
                        // onDataPointClick(null);
                    } else {
                        svg.selectAll("circle.img")
                            .classed("selectedAvatar",false);
                        select(this)
                            .classed("selectedAvatar",true);
                        onDataPointClick(e, ()=> select(this)
                            .classed("selectedAvatar",false)); //second parameter is callback to unselect
                    }
                    tip.hide();
                    //onHover(e);  // deprecated
                })
                .on('contextmenu',  function(e) {
                    d3Event.preventDefault();
                    alert('context menu');
                });

            // add Caption to collaborative samples
            elemEnter
                .append("text")
                .attr("class", "node-text")
                .attr("dy", "5")
                .attr("text-anchor", "middle")
                .attr('fill', 'darkorange')
                .style('font-size', '20px')
                .text(function(d) {
                    if (d.hasOwnProperty('numberOfUsers'))
                        return d.numberOfUsers;
                    else {
                        if (d.hasOwnProperty('isDuplicated'))
                            return "...";
                        else
                            return "";
                    }
                });

        }

        //=== rendering regression line=========================

        let regressionLine=null;
        let lineParams = regressionLineParameters;

        if(regressionVisible && (statsServerState!==REQUEST_STATUS_FAIL) && (lineParams != null)) {
            console.log("Regression line is visible - params:", regressionLineParameters);
            regressionLine = objects.append("path")
                .datum([{x: xScale.domain()[0], y: 0}, {x:  xScale.domain()[1], y: 0}]) //most extreme values in domain of scale X
                .attr("id", "regression")
                .attr("stroke",regressionLineColor )
                .attr("fill", "none")
                .attr("stroke-width", STROKE_WIDTH)
                .attr("d", line()
                    .x(function (d) {
                        return xScale(d.x);
                    })
                    .y(function (d) {
                        return yScale(d.x * lineParams[1] + lineParams[0]);
                    }))
                .attr("display", (!regressionVisible || isNaN(lineParams[0]) || isNaN(lineParams[1]))? "none" : "block");

        }

        //=== rendering equation=========================
        if(equationVisible && (statsServerState!==REQUEST_STATUS_FAIL) && (lineParams != null)) {
            svg.append("text")
                .attr("class", "regressionLine-text")
                .attr("x", width / 2)
                .attr("y", "5")
                .attr("text-anchor", "middle")
                .attr('fill', regressionLineColor)
                .style('font-size', '20px')
                .style('display', function (d) {
                    if (equationVisible)
                        return 'block';
                    else
                        return 'none';
                })
                .text(function () {
                    if (isNaN(lineParams[0]) || isNaN(lineParams[1]))
                        return t("messages.error.stats.estimation.lineParamsNaN");
                    else {
                          const intercept = lineParams[0].toFixed(2)<0?lineParams[0].toFixed(2) : " + " + lineParams[0].toFixed(2);
                        return yLabel + " = " + lineParams[1].toFixed(2) + "*" + xLabel + intercept;
                    }
                });
        }
        if(rSquaredVisible && (statsServerState!==REQUEST_STATUS_FAIL) && (lineParams != null)) {
            svg.append("text")
                .attr("class", "rsquared-text")
                .attr("x", width / 2)
                .attr("y", "25")
                .attr("text-anchor", "middle")
                .attr('fill', regressionLineColor)
                .style('font-size', '20px')
                .style('display', function (d) {
                    if (rSquaredVisible)
                        return 'block';
                    else
                        return 'none';
                })
                .text(function () {
                    if (rSquaredVisible && !isNaN(rSquared))
                        return "R²: " + rSquared.toFixed(2);
                    else return "R²: "+ t("messages.error.format.nan");
                });
        }

        if((rSquaredVisible || equationVisible || regressionVisible) && statsServerState===REQUEST_STATUS_FAIL) {
            svg.append("text")
                .attr("class", "rsquared-text")
                .attr("x", width / 2)
                .attr("y", "25")
                .attr("text-anchor", "middle")
                .attr('fill', "red")
                .style('font-size', '20px')
                .text(t("messages.error.stats.serverconnection.lost"));
        }

        this.zoomTransform = xyzoomTransform(svg.node());

        var oldTransform = this.zoomTransform;
        //    oldTransform.kx=1;
        // oldTransform.ky=1;
        // oldTransform.x=0;
        // oldTransform.y=0;

        /**
         * Unfinished.
         * Check how to change extents, domains etc. in order to make possible use
         * zoom in X direction, and then in Y, and again X etc.
         * So far ctrl+wheel -> zoom X
         * shift + wheel -> zoom y
         * no ctrl, no shift -> zoom both x and y
         */
        function zoomEventHandler() {
            let new_xScale=xScale;
            let new_yScale=yScale;
            const ev = d3Event;

            if (ev!=null && ev.type==="zoom") {
                const temp = xyzoomTransform(svg.node());
                console.log('event transform', ev.transform);
                console.log('temp transform', oldTransform);

                if (!ev.sourceEvent.shiftKey) {
                    oldTransform.kx = temp.kx;
                    oldTransform.x = temp.x;
                    new_xScale = oldTransform.rescaleX(xScale);
                    svg.select(".x.axis").call(xAxis.scale(new_xScale));
                }
                if (!ev.sourceEvent.ctrlKey) {
                    oldTransform.ky = temp.ky;
                    oldTransform.y = temp.y;
                    new_yScale = oldTransform.rescaleY(yScale);
                    svg.select(".y.axis").call(yAxis.scale(new_yScale));
                }
                // oldTransform =  xyzoomTransform(svg.node(),oldTransform);
                console.log('old transform', oldTransform);

                // xAxis.scale(new_xScale);

                // yAxis.scale(new_yScale);


                svg.selectAll(".node-group")
                    .attr("transform", function (d, i) {
                        // Set d.x and d.y here so that other elements can use it. d is
                        // expected to be an object here.

                        return "translate(" + new_xScale(d.x) + "," + new_yScale(d.y) + ")";
                    });


                if (regressionVisible && (statsServerState !== REQUEST_STATUS_FAIL)) {
                    regressionLine
                        .datum([{x: new_xScale.domain()[0], y: 0}, {x: new_xScale.domain()[1], y: 0}])
                        .attr("d", line()
                            .x(function (d) {
                                return new_xScale(d.x);
                            })
                            .y(function (d) {
                                return new_yScale(d.x * lineParams[1] + lineParams[0]);
                            }))
                        .attr("display", regressionVisible ? "block" : "none");

                }
            }
        }
    }
    render() {
        return <svg ref={node => this.node = node} />;
    }
}
export default withTranslation()(AvatarScatterPlot);

AvatarScatterPlot.defaultProps = {
    useAvatars: true,
    stroke:4,
    zoomRatio:2,
    opacityMode:"normal",
    yLabel:"y",
    xLabel:"x",
    yLabelTip:"y",
    xLabelTip:"x",
    showXTickValues:true,
    showYTickValues:true,
    outerWidth:500,
    outerHeight:300,
    xTipValuePrecision:2,
    yTipValuePrecision:2,
    renderXLabel: true,
    renderYLabel: true,
    avatarSize:25,
    regressionVisible:false,
    regressionLineColor: "#E4002B",
    // regressionLineParameters:[0,0],
    regressionLineWidth:1,
    equationVisible:false,
    rSquaredVisible:false,
    // rSquared:0
};

AvatarScatterPlot.propTypes = {
    regressionVisible:PropTypes.bool.isRequired,
    regressionLineColor:PropTypes.string.isRequired,
    regressionLineParameters:PropTypes.array.isRequired,
    regressionLineWidth: PropTypes.number.isRequired,
    contextMenu: PropTypes.array,
    data: PropTypes.array.isRequired,
    outerWidth:PropTypes.number.isRequired,
    outerHeight:PropTypes.number.isRequired,
    onDataPointClick:PropTypes.func,
    colorCat:PropTypes.string,
    colorLUT:PropTypes.array, //array of user provided colors
    opacityMode:PropTypes.string,
    useAvatars:PropTypes.bool,
    viewId:PropTypes.string,
    prefixId:PropTypes.string.isRequired, // required by external Tip library - since many elements on page can have the same id
    stroke:PropTypes.number,
    zoomRatio:PropTypes.number,
    renderXLabel: PropTypes.bool.isRequired, //should label for X Axis be rendered
    renderYLabel: PropTypes.bool.isRequired, //should label for Y Axis be rendered
    xLabel:PropTypes.string.isRequired, //label for displaying in Axis
    yLabel:PropTypes.string.isRequired, //label for displaying in Axis
    xLabelTip: PropTypes.string.isRequired, //label for displaying in tips
    yLabelTip:PropTypes.string.isRequired, //label for displaying in tips
    xTipValuePrecision:PropTypes.number.isRequired, //precision of float number for displaying in tips
    yTipValuePrecision:PropTypes.number.isRequired, //precision of float number for displaying in tips
    showXTickValues:PropTypes.bool.isRequired, //show ticks on Axis or not
    showYTickValues:PropTypes.bool.isRequired,  //show ticks on Axis or not
    avatarSize: PropTypes.number.isRequired, //adjustable size of avatar
    equationVisible:PropTypes.bool.isRequired,
    rSquaredVisible:PropTypes.bool.isRequired,
    rSquared:PropTypes.number.isRequired,
    statsServerState:PropTypes.string,
};
