import {connect} from "react-redux";
import {SPM_landing_page} from "../component/landingPage";
import {getSPMResults,clearSingleResultStateAC} from "../action/SPMAction";

// Used by connect()
const mapStateToProps = state => {
    console.log("mapStateToProps review")
    return {
        spm: state.spm.spm,
        status: state.spm.status,
        error: state.spm.error
    }
};

const mapDispatchToProps = dispatch => {
  console.log("mapDispatchToProps review")
    return {
        getSPMResults: (spmExperimentId, pval) => {
            dispatch(getSPMResults(spmExperimentId, pval))
        },
        clearSingleResultStateAc: () => {
            dispatch(clearSingleResultStateAC())
        }
    }
};

const spmContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerSPM = spmContainerCreator(SPM_landing_page);
