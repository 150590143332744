import React from "react";
import axios from 'axios';
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/github';
import {InputText} from "primereact/components/inputtext/InputText";
import {Button} from "primereact/components/button/Button";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {getNestedProp} from "../helpers/expressions";


/**
 * This is page displaying documents from database without parsing.
 *
 */
class CouchQueryTool extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      designOptions: [],
      viewOptions: [],
      options: {},
      docId: null,
      design: null,
      view: null,
      keys: "[]",
      output: ""
    };
    ["onGetView", "onGetDoc", "setViewOptions", "onFind","onDesign","onInfo"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  componentDidMount() {

    axios.get(`/api/couch/design`)
      .then((data) => {

        if (data != null && data.data.rows != null) {
          const designOptions = data.data.rows.map(el => {
            return {label: el.id.substring(8), value: el.id.substring(8)}
          });

          const viewOptions = Object.keys(data.data.rows[0].doc.views).map(el => {
            return {label: el, value: el}
          });
          this.setState({
            designOptions: designOptions,
            design: designOptions[0].value,
            viewOptions: viewOptions,
            view: viewOptions[0].value,
            options: data.data
          })
        }

      }).catch((err) => {
      if (getNestedProp(["response", "data", "message"], err) != null)
        this.setState({output: getNestedProp(["response", "data", "message"], err)});
      else
        this.setState({output: "Views cannot be loaded!"})
    })
  }

  setViewOptions(design) {
    const getDesignIndex = this.state.options.rows.findIndex(el => el.id.substring(8) === design);
    const viewOptions = Object.keys(this.state.options.rows[getDesignIndex].doc.views).map(el => {
      return {label: el, value: el}
    });
    this.setState({
      design: design,
      viewOptions: viewOptions,
      view: viewOptions[0].value
    })
  }

  onGetView() {
    axios.post(`/api/couch/view`, {
      keys: this.state.keys,
      view: this.state.view,
      design: this.state.design
    })
      .then((data) => {
        this.setState({output: JSON.stringify(data.data, null, '\t')})
      }).catch(err => {
      this.setState({output: JSON.stringify(err.response.data, null, '\t')})
    })
  }


  onFind() {
    axios.post(`/api/couch/find`, {
      query: this.state.keys,
    })
      .then((data) => {
        this.setState({output: JSON.stringify(data.data, null, '\t')})
      }).catch(err => {
      this.setState({output: JSON.stringify(err.response.data, null, '\t')})
    })
  }

  onDesign() {
    axios.get(`/api/couch/design`)
      .then((data) => {
        this.setState({output: JSON.stringify(data.data, null, '\t')})
      }).catch(err => {
      this.setState({output: JSON.stringify(err.response.data, null, '\t')})
    })
  }
  onInfo() {
    axios.get(`/api/couch/info`)
      .then((data) => {
        this.setState({output: JSON.stringify(data.data, null, '\t')})
      }).catch(err => {
      this.setState({output: JSON.stringify(err.response.data, null, '\t')})
    })
  }

  onGetDoc() {

    axios.post(`/api/couch/doc`, {docId: this.state.docId})
      .then((data) => {
        this.setState({output: JSON.stringify(data.data, null, '\t')})
      }).catch(err => {
      this.setState({output: JSON.stringify(err.response.data, null, '\t')})
    })
  }


  render() {
    const {output, docId, design, view, keys, designOptions, viewOptions} = this.state;


    return <React.Fragment>

      <div className={"ui-g-12"}>
        <div className={"ui-g-6"}>
          <h3>Input</h3>
          <div>
            <label>Id: </label>
            <InputText
              value={docId}
              onChange={(e) => {
                this.setState({docId: e.target.value})
              }}
            /><Button label={"Get document"} onClick={this.onGetDoc}/>
          </div>
          <div style={{marginTop: "1em"}}>
            <label>Design: </label>
            <Dropdown
              options={designOptions}
              value={design}
              style={{width: "15em"}}
              onChange={(e) => {
                this.setViewOptions(e.value);
              }}
            />
            <label>View:</label>
            <Dropdown
              value={view}
              options={viewOptions}
              style={{width: "15em"}}
              onChange={(e) => {
                this.setState({view: e.value})
              }}
            />
            <br/>
            <label>Query editor: </label>

            <AceEditor
              mode="json"
              theme="github"
              name="EDITOR"
              editorProps={{$blockScrolling: true}}
              highlightActiveLine={true}
              value={keys}
              width="100%"
              height="15em"
              onChange={(value) => {
                this.setState({keys: value})
              }}
            />

            <Button label={"Get view"} onClick={this.onGetView}/>
            <Button label={"Find"} onClick={this.onFind}/>
            <Button label={"Show design docs"} onClick={this.onDesign}/>
            <Button label={"Show DB info"} onClick={this.onInfo}/>
          </div>
          <div>
            <h4>How to:</h4>
            <dl>
              <dt>Get document</dt>
              <dd>put id of document into "Id" field, eg. '023e3ce2-d2e0-4b73-87c1-c0c166b8c313' and click button 'Get document'</dd>
              <dt>Get view</dt>
              <dd>Choose design and view from dropdowns and click "Get view". If you want to narrow down results with keys, provide keys in array format into 'Query editor',
              eg. for single key: ['023e3ce2-d2e0-4b73-87c1-c0c166b8c313'] or for multi-key [["AMS Annotation","1.0.0"],["Annotation (Hanna)","0.0.1"]].
                Leave empty for unfiltered results.</dd>
              <dt>Find docs</dt>
              <dd>Provide selectors into 'Query editor' and click "Find" button. Selector can be defined as follows (for more check
                <a href="https://docs.couchdb.org/en/3.2.0/api/database/find.html" target="_blank"> here</a>):
              <pre>
                {String.raw`
               {
                "selector": {
                  "relativePath": { "$eq": "EPVS_MICCAI_VALDO_2021"},
                  "pi": {"$eq": "d4784066-cdbe-4285-9370-72afa1a8269f"}
                  },
                "limit":50
                }
                `}
              </pre>
              </dd>
              <dt>Show design</dt>
              <dd>Shows indexes and views</dd>
            </dl>
          </div>
        </div>
        <div className={"ui-g-6"}>
          <h3>Output</h3>
          <AceEditor
            mode="json"
            theme="github"
            name="OUTPUT"
            editorProps={{$blockScrolling: true}}
            highlightActiveLine={true}
            value={output}
            width="100%"
            height="calc(100vh - 120px)"
          />
        </div>
      </div>
    </React.Fragment>;
  }
}


CouchQueryTool.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func,
  // messageQueue: PropTypes.object
};
export default withRouter(CouchQueryTool);

