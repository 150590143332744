import {connect} from "react-redux";
import React from 'react';
import {clearScriptOutputAC, executeScript} from "../../expDesign/action/ScriptAction";
import {getSeriesObservations} from "../selectors/ObservationsSelectors";
import {getSelectedPlotSettings} from "../selectors/SAMSelectors";
import {updatePlotData, updateScatterPlotData} from "../action/ScatterPlotAction";
import {updatePlotDataState} from "../action/BAAction";
import {updatePlotSettingsProperty} from "../action/SAMAction";
import {getSeriesContributions} from "../selectors/ContributionsSelectors";
import {getSeriesCases} from "../selectors/CasesSelectors";



const CustomScriptView =  React.lazy(() => import("../component/CustomScriptView"));

const mapStateToProps = (state,ownProps) => {
  return {
    plots: state.expDesign.script.plots,
    scriptExecutorState:state.expDesign.script.scriptExecutorState,
    stdOut:state.expDesign.script.stdOut,

    rawData: state.sam.rawData,
    instances: getSeriesObservations(state),
    series: getSeriesContributions(state),
    cases: getSeriesCases(state),
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration,
    script: getSelectedPlotSettings(state).script,

  }
};

const mapDispatchToProps = dispatch => {
  return {
    updatePlotData:(data)=> {
      dispatch(updatePlotData(data));
    },
    updatePlotDataState:(data)=> {
      dispatch(updatePlotDataState(data));
    },
    executeScript:(script,data)=> {
      dispatch(executeScript(script,data));
    },
    clearOutput:()=>{
      dispatch(clearScriptOutputAC());
    },
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    },
    updateScatterPlotData:(p,index)=>{
      dispatch(updateScatterPlotData(p,index))
    }
  }
};


export const ContainerCustomScriptView = connect( mapStateToProps, mapDispatchToProps)(CustomScriptView);