import {
    CLEAR_SKILL_STATE,
    SET_SKILL_SORTER, SKILL_SLIDE_LIST_FAIL, SKILL_SLIDE_LIST_REQUESTED, SKILL_SLIDE_LIST_SUCCESS,
    SKILL_UPDATE_CURRENT_STEP_INDEX
} from '../action/actionType'
import {combineReducers} from "redux";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {
    SET_SKILL_VISIBILITY_FILTER, SKILL_LIST_FAIL, SKILL_LIST_REQUEST,
    SKILL_LIST_SUCCESS
} from "../action/actionType";



const setVisibilityFilter = (state, action) => {

    return Object.assign({}, state, {
        visibilityFilter: action.filter,
    });

};

const setSorter = (state, action) => {

    return Object.assign({}, state, {
        sorter: action.sorter,
    });

};


const skillListReducer = (state = {skillsState: '', skills: [], visibilityFilter:'SHOW_ALL', sorter:'SORT_BY_NAME_DESC'}, action) => {
    switch (action.type) {
        case SKILL_LIST_REQUEST:
            return Object.assign({}, state, {
                skillsState: 'requested',
            });
        case SKILL_LIST_SUCCESS:
            return Object.assign({}, state, {
                skillsState: 'success',
                skills: action.skills
            });

        case SKILL_LIST_FAIL:
            return Object.assign({}, state, {
                skillsState: action.err,
            });

        case SET_SKILL_VISIBILITY_FILTER:
            return setVisibilityFilter(state, action);

        case SET_SKILL_SORTER:
            return setSorter(state, action);

        default:
            return state;
    }
};

/**
 * SkillReducer - responsible for storing data related to skill (live presentation)
 * Properties:
 * - skillState
 * - skill - description of skill (future feature)
 * - slides - array of slides (images, videos, tasks)
 * - currentStep - index of current SLIDE in presentation
 * @param state - redux state
 * @param action
 * @return {*}
 */

const skillReducer = (state = {
    skillState: '',
    skill: {},
    slides:[],
    currentStepIndex:0
}, action) => {
    switch (action.type) {
        case 'SKILL_REQUEST':
            return Object.assign({}, state, {
                skillState: 'requested'
            });
        case 'SKILL_FAIL':
            return Object.assign({}, state, {
                skillState: 'failure',
                skill: null
            });
        case 'SKILL_SUCCESS':
            return Object.assign({}, state, {
                skillState: 'success',
                skill: action.skill
            });
        case SKILL_UPDATE_CURRENT_STEP_INDEX:
            console.log("Skill step set to: ", action.currentStepIndex);
            return Object.assign({}, state, {
                currentStepIndex: action.currentStepIndex
            });
        case SKILL_SLIDE_LIST_REQUESTED:
            return Object.assign({}, state, {
                skillState: REQUEST_STATUS_REQUESTED
            });

        case SKILL_SLIDE_LIST_SUCCESS:
            return Object.assign({}, state, {
                skillState: REQUEST_STATUS_SUCCESS,
                slides: action.slides
            });
        case SKILL_SLIDE_LIST_FAIL:
            return Object.assign({}, state, {
                skillState: REQUEST_STATUS_FAIL,
                slides: action.slides
            });

        case CLEAR_SKILL_STATE:
            return Object.assign({},
                {skillState: '', skill: {}, slides:[], currentStepIndex:0});

        default:
            return state;
    }
};

const resultReducer = (state = {resultState: '', result: {}}, action) => {
    switch (action.type) {
        case 'SKILL_RESULT_REQUEST':
            return Object.assign({}, state, {
                resultState: REQUEST_STATUS_REQUESTED
            });
        case 'SKILL_RESULT_FAIL':
            console.log('Project request failed');
            return Object.assign({}, state, {
                resultState: REQUEST_STATUS_FAIL,
                result: null
            });
        case 'SKILL_RESULT_SUCCESS':
            console.log('Project request succeeded');
            return Object.assign({}, state, {
                resultState: REQUEST_STATUS_SUCCESS,
                result: action.result
            });
        case 'CLEAR_SKILL_RESULT':
            console.log('Project request succeeded');
            return Object.assign({}, state, {
                resultState: "",
                result: {}
            });
        default:
            return state;
    }
};


export const SkillReducer = combineReducers({
    skills: skillListReducer,
    skill: skillReducer,
    skillResult: resultReducer
});