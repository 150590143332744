import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {updateManualToolSAM} from "../action/toolAction";
import {ROITableWidget} from "../component/ROITableWidget";
import {updateManualToolProperty} from "../../visualization/action/ManualToolAction";
import {setSliceNumbersToIJK} from "../../visualization/action/ViewerAction";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    selectedDataPoint: state.sam.selectedDataPoint,
    manualToolConfiguration:state.sam.manualToolConfiguration,
    roiVisualizationData:state.sam.roiVisualizationData, // data for table
    roi: state.visu.roi,
    visualizationToolDialogVisible: state.sam.visualizationToolDialogVisible,

    manualToolState: state.visu.manualTool.manualToolState,
    onHide:ownProps.onHide, // could not be preserved in Store - functions are not deserialized (deselect is required)
    manualToolType:ownProps.manualToolType
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    },
    updateManualToolSAM:(tool)=>{
      dispatch(updateManualToolSAM())
    },
    updateManualToolProperty: (key, property, value) => {
      dispatch(updateManualToolProperty(key, property, value))
    },
    setSliceNumbersToIJK:(ijk) =>{
      dispatch(setSliceNumbersToIJK(ijk))
    }
  }
};

export const ContainerROITableWidget = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(ROITableWidget));