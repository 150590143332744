import React from 'react'
import PropTypes from 'prop-types';
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {Helmet} from "react-helmet";
import {defaultOnClickHandler} from "../action/GeoAction";


/**
 * Component for displaying map with geolayers and sites.
 * Requires a redux store to keep parameters of layesr, sites and visualization.
 * This component is just a container for map content.
 * @see {@link src/frontend/arcgis/README.md }
 */

class GeoComponent extends React.Component {


  constructor(props) {
    super(props);
    ["registerEventHandlers"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {initializeGeoView, zoom, center, basemap, viewType, uiComponents, configurationId, loadConfiguration} = this.props;
    if (configurationId != null && loadConfiguration != null) {
      loadConfiguration(configurationId, this.mapComponent);
    } else if (initializeGeoView != null) {
      const params = {};
      if (zoom != null)
        params['zoom'] = zoom;
      if (basemap != null)
        params['basemap'] = basemap;
      if (center != null)
        params['center'] = [...center];
      if (viewType != null)
        params['viewType'] = viewType;
      if (uiComponents != null)
        params['uiComponents'] = uiComponents;
      initializeGeoView(this.mapComponent, params);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const {viewState,initializeGeoLayers,layers,configurationId}=this.props;
    if (prevProps.viewState!==viewState && viewState===REQUEST_STATUS_SUCCESS){
      if (!(configurationId!=null))
        initializeGeoLayers(layers);  // if configuration is provided then layers are initialized from action
      this.registerEventHandlers();
    }
  }
  componentWillUnmount() {
    const {view,clearGeoView} = this.props;
    view.container = null;
    if (clearGeoView)
      clearGeoView();
  }

  /**
   * Register handlers to arcgis events generated by "view" element.
   * Omitted some of the evnts, eg.: "immediate-click", "immediate-doubleclick","layerview-..." etc.
   * List of all ids available here: https://developers.arcgis.com/javascript/latest/api-reference/esri-views-View.html#event-details
   */
  registerEventHandlers() {
    const {onClick,registerEvent,onBlur,onDoubleClick,onDrag,onFocus,onHold,onKeyDown,onKeyUp,onMouseWheel,onPointerDown,
    onPointerEnter,onPointerLeave,onPointerMove,onPointerUp,onResize, onClickCallback, onZoom, registerWatch} = this.props;

    // mouse events
    if (onClick!=null) registerEvent('click',onClick,onClickCallback);
    if (onDoubleClick!=null) registerEvent('double-click',onDoubleClick);
    if (onDrag!=null) registerEvent('drag',onDrag);
    if (onFocus!=null) registerEvent('focus',onFocus);
    if (onHold!=null) registerEvent('hold',onHold);
    if (onKeyDown!=null) registerEvent('key-down',onKeyDown);
    if (onKeyUp!=null) registerEvent('key-up',onKeyUp);
    if (onMouseWheel!=null) registerEvent('mouse-wheel',onMouseWheel);
    if (onBlur!=null) registerEvent('blur',onBlur);
    if (onPointerDown!=null) registerEvent('pointer-down',onPointerDown);
    if (onPointerEnter!=null) registerEvent('pointer-enter',onPointerEnter);
    if (onPointerLeave!=null) registerEvent('pointer-leave',onPointerLeave);
    if (onPointerMove!=null) registerEvent('pointer-move',onPointerMove);
    if (onPointerUp!=null) registerEvent('pointer-up',onPointerUp);
    if (onResize!=null) registerEvent('resize',onResize);

    // update notifiers
    if (onZoom!=null) registerWatch('zoom',onZoom);

  }


  render() {
    const {width, height}=this.props;
    return (
      <div
        style={{width,height}}
        ref={el=>this.mapComponent = el}>
        <Helmet>
          <link rel="stylesheet" href="https://js.arcgis.com/4.25/esri/css/main.css"/>
        </Helmet>
      </div>
    )
  }
}

export default GeoComponent;

GeoComponent.propTypes = {
  configurationId: PropTypes.string, // optional attribute so parameters can be read from document
  loadConfiguration: PropTypes.func.isRequired,
  initializeGeoView: PropTypes.func.isRequired,
  initializeGeoLayers: PropTypes.func.isRequired,
  clearGeoView:PropTypes.func.isRequired,
  view: PropTypes.object.isRequired,
  viewState: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  center: PropTypes.array,
  zoom: PropTypes.number,
  basemap: PropTypes.string,
  viewType: PropTypes.string,
  layers: PropTypes.array.isRequired,
  uiComponents:PropTypes.array,
  onClick: PropTypes.func.isRequired,  // handler for  onClick - if not defined, default is used
  onDoubleClick: PropTypes.func,
  onDrag: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onHold: PropTypes.func,
  onResize: PropTypes.func,
  onMouseWheel: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onPointerDown: PropTypes.func,
  onPointerEnter: PropTypes.func,
  onPointerLeave: PropTypes.func,
  onPointerMove: PropTypes.func,
  onPointerUp: PropTypes.func,
  onClickCallback: PropTypes.func.isRequired,
  onZoom: PropTypes.func,
  registerEvent: PropTypes.func,
  registerWatch: PropTypes.func.isRequired
};

GeoComponent.defaultProps = {
  width:"600px",
  height:"600px",
  onClick: defaultOnClickHandler,
  onClickCallback: null,
  layers: []
};