import React from 'react'
import {Route, withRouter} from 'react-router'
import SPM from "./SPM";


class SPMRouter extends React.Component {

    render() {
        const {match} = this.props;
        return (
            <React.Fragment>
              <Route exact path={`${match.path}/`} component={SPM}/>
            </React.Fragment>

        )
    }

}

export default withRouter(SPMRouter)
