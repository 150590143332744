import React from "react";
import PropTypes from "prop-types";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {
  INTENSITY_FILTER_TOOL,
  MAIN_TOOL,
  MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE, SAMPLER_TOOL
} from "../../../../Constants";
import {Button} from "primereact/components/button/Button";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {SplitButton} from "primereact/components/splitbutton/SplitButton";

/**
 * Dumb component with template for Intensity Filter Tool.
 * @param changeToolProperties(property,value) - callback
 * @param toolProperties - props object eg. {size:"1"}
 * @param toolConfiguration - initial configuration needed to set visibility of controls
 * @param samplerProperties - properties of sampler widget
 * @param leftButtonMode - properties of sampler widget
 * @returns {*}
 */
export const IntensityFilterToolbar = ({toolProperties, changeToolProperties, toolConfiguration,samplerProperties, leftButtonMode}) => {

  const control = (property, type) => toolConfiguration != null
    && toolConfiguration.properties != null
    && toolConfiguration.properties[property] != null
    && toolConfiguration.properties[property][type] != null
    && toolConfiguration.properties[property][type];

  const checkIfAnyPropertyVisible = Object.keys(toolConfiguration['properties'])
    .find((el) => {
      return toolConfiguration['properties'][el]['controlVisible']
    }) != null;


  const onEyedropperClick =(field) =>{
    if (leftButtonMode!==LEFT_BUTTON_MODE.SAMPLER || samplerProperties['key']!==field){
      changeToolProperties(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE,LEFT_BUTTON_MODE.SAMPLER);
      changeToolProperties(SAMPLER_TOOL,'key',field);
    }
    else changeToolProperties(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE,LEFT_BUTTON_MODE.NONE);
  };

  const items = (filterProperty)=> [
    {label: 'Minimum',  command: () => changeToolProperties(INTENSITY_FILTER_TOOL, filterProperty, samplerProperties['output']['stats']['minimum'])},
    {label: 'Maximum',  command: () => changeToolProperties(INTENSITY_FILTER_TOOL, filterProperty, samplerProperties['output']['stats']['maximum'])},
    {label: '(Max-Min)/2', command: () =>changeToolProperties(INTENSITY_FILTER_TOOL, filterProperty,  Math.round((samplerProperties['output']['stats']['maximum']-samplerProperties['output']['stats']['minimum'])/2))},
    {label: 'Mean',     command: () => changeToolProperties(INTENSITY_FILTER_TOOL, filterProperty, Math.round(samplerProperties['output']['stats']['average']))},
    {label: 'Otsu',     command: () => changeToolProperties(INTENSITY_FILTER_TOOL, filterProperty, samplerProperties['output']['threshold'])},
    {label: 'Mid',     command: () => changeToolProperties(INTENSITY_FILTER_TOOL, filterProperty, samplerProperties['output']['midPoint'])}
  ];

  return (
    <React.Fragment>
      {checkIfAnyPropertyVisible &&
      <Toolbar style={{display: "inline-block"}}>
        <label style={{marginLeft: "1em"}}>Intensities </label>
        {control('minValue', 'controlVisible') &&
        <React.Fragment>
          <label style={{marginLeft: "1em"}}>from:</label>
          <input type={"number"}
                 value={toolProperties.minValue}
                 onChange={(e) => changeToolProperties(INTENSITY_FILTER_TOOL, 'minValue', e.currentTarget.value)}
                 style={{width:"8em"}}
          />
          <SplitButton disabled={!(samplerProperties!=null  && samplerProperties['output']!=null)}
                       icon="fa fa-eyedropper"
                       model={items('minValue')}
                       title={"Copy value from Data probe"}
                       />
        </React.Fragment>}
        {control('maxValue', 'controlVisible') &&
        <React.Fragment>
          <label style={{marginLeft: "1em"}}>to:</label>
          <input type={"number"}  min={0}
                 value={toolProperties.maxValue}
                 onChange={(e) => changeToolProperties(INTENSITY_FILTER_TOOL, 'maxValue', e.currentTarget.value)}
                 style={{width:"8em"}}
          />
          <SplitButton disabled={!(samplerProperties!=null  && samplerProperties['output']!=null)}
                       icon="fa fa-eyedropper"
                       model={items('maxValue')}
                       title={"Copy value from Data probe"}
                       />
        </React.Fragment>}
        {/*{control('showMask', 'controlVisible') &&*/}
        {/*<React.Fragment>*/}
        {/*  <Button icon={"fa fa-magic"} title={"Preview mask"}/>*/}
        {/*</React.Fragment>}*/}
        {control('clear', 'controlVisible') &&
        <React.Fragment>
          <Button icon={"fa fa-close"} title={"Clear values"} onClick={()=>{
            changeToolProperties(INTENSITY_FILTER_TOOL, 'minValue', 0);
            changeToolProperties(INTENSITY_FILTER_TOOL, 'maxValue', 4294967295);
          }}/>
        </React.Fragment>}

      </Toolbar>}
    </React.Fragment>
  );
};

IntensityFilterToolbar.propTypes = {
  toolProperties: PropTypes.object.isRequired,
  samplerProperties: PropTypes.object.isRequired,
  toolConfiguration: PropTypes.object.isRequired,
  changeToolProperties: PropTypes.func.isRequired
};
