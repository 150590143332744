import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";

/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class SeriesCasesPanel extends React.Component {


  render() {
    const {t, cases} = this.props;

    return (

        <React.Fragment>
          <DataTable
            value={cases} // concat and remove dups
            emptyMessage={"No cases found"}
            paginator={true}
            rows={15}
          >
            <Column field="uuid" header="Id" sortable={true} filter={true}/>
            <Column field="label" header="Name" sortable={true} filter={true}/>
            <Column field="subjectId" header="Subject" sortable={true} filter={true}/>
            <Column field="visitId" header="Visit" sortable={true} filter={true}/>
            <Column field="inputs" header="Inputs" sortable={true} />
          </DataTable>
        </React.Fragment>

    );
  }
}

export default withTranslation()(SeriesCasesPanel);

SeriesCasesPanel.propTypes = {
  cases:PropTypes.array.isRequired, // filtered properly cases with selectors
  t: PropTypes.func
};
