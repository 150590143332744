import axios from 'axios';

import {
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL
} from './actionType.js';
import {HOST_URL} from "../../../Constants";

//FORGOT PASSWORD PROCESS ************************************************************

function requestPasswordReset() {
  return {
    type: PASSWORD_RESET_REQUEST
  }
}

function receivePasswordReset() {
  return {
    type: PASSWORD_RESET_SUCCESS
  }
}

function errorPasswordReset() {
  return {
    type: PASSWORD_RESET_FAIL
  }
}


/**
 * Dispatch to reducer the request and it's output
 *
 * @param resetObject {Object} (email to send the reset token, reset token and new password -> {email: 'abc@def.com', resetToken: 'abc12345', password: 'n3wPassw0rd'})
 * @param onSuccess callback for successful petitions. Receives the data of the response
 * @param onError callback for errored petitions. Receives the data of the errored response
 * @return function(*) or success {Object}
 */
export function passwordReset(resetObject,onSuccess,onError) {
    console.log('Executing passwordReset.');
    return dispatch => {
        // Dispatch action request (if the process takes time we can
        // display the rolling ball animation)
        dispatch(requestPasswordReset());
        // Request to the backend
        const passwordResetUrl = `${HOST_URL}/api/user/reset-password`;
        return axios.post(passwordResetUrl, resetObject)
            .then(response => {
                //Check status code
                console.log('response ', response);
                if (response.status !== 200) {
                    //Display error message if reset password request is invalid
                    onError(response.data);
                    dispatch(errorPasswordReset());
                } else {
                    console.log('password reset status:', response.data);
                    console.log('PasswordResetAction::passwordReset::response', response);
                    onSuccess(response.data);
                    dispatch(receivePasswordReset());
                }
            }).catch(err =>{
                onError(err.response.data);
                dispatch(errorPasswordReset());
            })
    }

}