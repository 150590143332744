import React from 'react'
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {
  REQUEST_STATUS_FAIL,
  REQUEST_STATUS_SUCCESS
} from "../../../Constants";
import BlandAltmanPlot from "../../expDesign/component/d3components/BlandAltmanPlot";
import {create2DArray} from "../../helpers/arrays";
import {ContainerSAMSidebar} from "../containers/ContainerSAMSidebar";
import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";
import {ContainerSelectedContributionsPanel} from "../containers/ContainerSelectedContributionsPanel";
import {ContainerVisualizationToolPanel} from "../containers/ContainerVisualizationToolPanel";
import {ContainerSelectedCasePanel} from "../containers/ContainerSelectedCasePanel";
import {ContainerSeriesContributionsPanel} from "../containers/ContainerSeriesContributionsPanel";
import {ContainerSeriesCasesPanel} from "../containers/ContainerSeriesCasesPanel";
import {getNestedProp} from "../../helpers/expressions";
import {ContainerIRRStatsPanel} from "../containers/ContainerIRRStatsPanel";
import {getMeasurementShortLabel} from "../action/SAMAction";
import ScatterPlotSAM from "../../expDesign/component/d3components/ScatterPlotSAM";
import SAMHelpPanel from "./SAMHelpPanel";
import {ContainerDistributionStatsPanel} from "../containers/ContainerDistributionStatsPanel";
import RaterHistogram from "../../expDesign/component/d3components/RaterHistogram";
import {ContainerSeriesDataPointsPanel} from "../containers/ContainerSeriesDataPointsPanel";
import {SAM_PLOT_TYPE} from "../reducer/SAMReducer";
import {ContainerGLMStatsPanel} from "../containers/ContainerGLMStatsPanel";
import {GLMModel} from "../../expDesign/models/GLMModel";


/**
 * Standardized Analysis Module Panel
 * Panel being container for Bland Altman Plot and other agreement results/analysis.
 * It is responsible for handling logic related to overlay windows:
 *  - dialog window to view individual cases results from manual tool
 */
export class SAMPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: null,
      height: null,
      cellWidth:null,
      rowHeight:null,
      showHistogramDialog: false, //TODO put it in store
      manualToolType:"",
      deselectDataPoint: null, //callback function that should be set dynamically by children - this one is for deselecting from hiding dialogs (MRI, Contribution etc.)
      deselectFromRater: null, //callback function that should be set dynamically by children - this one is for deselecting from Volume View
      contextMenuVisible:false, // new UI for data point
      showDataSetDialog:false,
      showCaseDetailsDialog:false,
      showObservationsDetailsDialog:false,
      showStatsDialog:false,
      showHelpDialog:false // can be moved to reducer
    };
    ["setPlotSize", "onDataPointClick", "onHideVolumeDialog", "onHideHistogramDialog", "onContextClick","onMenuOptionSelect"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }


  componentWillUnmount() {
    const {clearStandardizedPlot, clearDataset} = this.props;
    document.body.removeEventListener("keydown", this.keyListener, true);
    window.removeEventListener("resize", this.setPlotSize);
    clearStandardizedPlot();
    clearDataset();  // used from Audit module, TODO move to independent part of Store responsible for R communication

  }

  componentDidMount() {
    const {initializeStandardizedAgreementModel,  match} = this.props;
    initializeStandardizedAgreementModel(match.params.id,match.params.snapshot);  // get experiment Id from navigation
    document.body.addEventListener("keydown", this.keyListener, true);
    this.setPlotSize();
    window.addEventListener("resize", this.setPlotSize);
  }
  componentDidUpdate(prevProps,prevState,ss) {
    const {selectedLayout} = this.props;
    if (prevProps.selectedLayout!==selectedLayout){
      this.setPlotSize();
    }

  }

  /**
   *
   * @param dialogFlag - to be opened {visualizationToolDialogVisible, }
   * @param manualToolType - optional if plot is selected, values {"group","advanced","contribution","roi","contributor"}
   */
  onMenuOptionSelect(dialogFlag,manualToolType=null){
    const {updatePropertySAM} = this.props;

    this.setState({
      contextMenuVisible : false,
      manualToolType : manualToolType,
      showDataSetDialog : dialogFlag==="showDataSetDialog",
      showCaseDetailsDialog: dialogFlag === "showCaseDetailsDialog",
      showStatsDialog: dialogFlag === "showStatsDialog",
      showObservationsDetailsDialog:dialogFlag === "showObservationsDetailsDialog"
    });

    if (dialogFlag==="visualizationToolDialogVisible" && updatePropertySAM!=null)
        updatePropertySAM("visualizationToolDialogVisible",true);

  }

  onContextClick(e, d,onDeselect,rowIndex,colIndex){
    const {onChangeCell,updateSelectedDataPoint} = this.props;
    if (onChangeCell!=null)
      onChangeCell({row:rowIndex,col:colIndex}); // change active cell
    if (d!=null){ // if event comes from data point
      this.cm.show(e);
      updateSelectedDataPoint(d);
      if (this.state.deselectDataPoint != null)
        this.state.deselectDataPoint();
      this.setState({deselectDataPoint: onDeselect})
    }
    else { // if event comes from plot
      this.cmPanel.show(e);
      if (this.state.deselectDataPoint != null)
        this.state.deselectDataPoint();
      this.setState({deselectDataPoint: null});
      updateSelectedDataPoint(null);
    }
  }

  /**
   * Function to be passed to children components.
   *
   * @param {number} settingsIndex -
   * @param {object} dataPoint - data associated with selected element
   * @param {callback} onDeselect - callback function defined in children component and to be set in
   * this component state. This clbk function can be used to deselect element in data.
   *
   */
  onDataPointClick(settingsIndex, dataPoint, onDeselect) {
    // const {getOverlapGroup} = this.props;
    // // TODO: Call backend to get per group segmentations and overlap in one label map
    //
    // getOverlapGroup(settingsIndex, (obj)=>this.setState(obj));

    this.setState({deselectDataPoint: onDeselect})


  }

  /**
   * Event handler to call when volume dialog window is closed.
   */
  onHideVolumeDialog() {
    const{updatePropertySAM,clearViewersState} = this.props;

    if (this.state.deselectDataPoint != null)
      this.state.deselectDataPoint();
    this.setState({
      deselectDataPoint: null,
      selected: null
    });
    if (updatePropertySAM){
      updatePropertySAM("visualizationToolDialogVisible",false);
      updatePropertySAM("manualToolConfiguration", null);
    }
    clearViewersState();
  }


  /**
   * Event handler to call when vertical histogram dialog window is closed.
   */
  onHideHistogramDialog() {
    if (this.state.deselectFromRater != null)
      this.state.deselectFromRater();
    this.setState({
      showHistogramDialog: false,
      deselectFromRater: null
    })
  }

  setPlotSize() {
    const {selectedLayout} = this.props;
    if (this.histogramContainer != null) {
      console.log('Calculate height and width');
      const rect = this.histogramContainer.getBoundingClientRect();
      this.setState({
        height: rect.height,
        width: rect.width,
        rowHeight: Math.round(rect.height / (selectedLayout.row + 1)),
        cellWidth: Math.round(rect.width / (selectedLayout.col + 1))
      });
    }
  }


  render() {
    const {selectedPlotSettings,  plotData, plotDataWithDups, plotSettings, modelParameters, modelParametersState, plotDataState, selectedLayout, rawData, selectedDataPoint, updateSelectedDataPoint,updatePropertySAM} = this.props;
    const {width, height, deselectDataPoint,showCaseDetailsDialog, showObservationsDetailsDialog, showDataSetDialog,showStatsDialog,showHelpDialog,rowHeight,cellWidth} = this.state;


    const rows = create2DArray(selectedLayout.row + 1, selectedLayout.col + 1)
      .map((colArray, index) => {

        return (<div
          id={"row_" + index}
          key={"row"+index}
          style={
            {
              ...flexRowContainer,height: "".concat(String(rowHeight), "px")
            }
          }>{colArray.map((c, cIndex) => {

          const index2 = index * (selectedLayout.col + 1) + cIndex;

          const generateSLR = () => {
            try {
              const glm = new GLMModel(modelParameters[index2]);
              if (glm.getBetasEstimates() != null && selectedPlotSettings != null) {
                const mp = plotSettings[index2].measurementIndexToSymbolMapping;
                const key = Object.keys(mp)
                  .find(key => mp[key] === selectedPlotSettings.axisXIndex);
                if (key != null) {
                  const betaIndex = parseInt(key.substring(1));
                  return glm.convertToSLRModel(betaIndex);
                } else return null;
              } else return null;
            }catch(err){
              return null;
            }
          };

          // try {
          //   const areOptionsAvailable = this.state.plotSettings[index][cIndex] != null && this.state.plotSettings[index][cIndex].optionsForMeasurements != null;
          //   xLabel = (areOptionsAvailable) ? this.state.plotSettings[index][cIndex].optionsForMeasurements.find((el) => {
          //     return el['value'] === this.state.plotSettings[index][cIndex].measurementValue
          //   })['label'] : "";
          //   yLabel = (areOptionsAvailable) ? this.state.plotSettings[index][cIndex].optionsForClinical.find((el) => {
          //     return el['value'] === this.state.plotSettings[index][cIndex].clinicalValue
          //   })['label'] : "";
          // }catch(err){
          //   return ( <div style={{fontSize: "20px", textAlign: "center", color: "red"}}> No valid data. Try  later!</div>);
          // }
          return <div id={"view_".concat(String(index), "_", String(cIndex))}
                      key={"view_".concat(String(index), "_", String(cIndex))}
                      style={{
                        width: "".concat(String(cellWidth), "px"),
                        height: "".concat(String(rowHeight), "px"),
                        display: "inline-block",
                        position:"relative"
                      }}>
            {plotDataState[index2] !== REQUEST_STATUS_SUCCESS && plotDataState[index2] !== REQUEST_STATUS_FAIL &&
            <div className="spinner-centered">Loading dataset <i className="fa fa-spinner fa-spin"/></div>}
            {plotDataState[index2] === REQUEST_STATUS_FAIL &&
            <div className="spinner-centered">No data available</div>}
            {plotSettings[index2].mode === 0 && plotSettings[index2] != null && plotData[index2] != null && plotData[index2].length === 0 &&
            plotDataState[index2] === REQUEST_STATUS_SUCCESS && plotSettings[index2].groups.length === 0 &&
            rawData != null && rawData.hasOwnProperty("contributors") &&
            <div style={{fontSize: "14px", color: "red",position:"absolute",top:"50%",left:"50%", transform:"translate(-50%, -50%)"}}>
              Please select the analysis configuration from the sidebar
            </div>
            }
            {!(rawData != null && rawData.hasOwnProperty("contributors")) &&
              <i className="fa fa-spinner fa-spin"
                 title="Loading data"
                 style={{fontSize: "4em",position:"absolute",top:"50%",left:"50%", transform:"translate(-50%, -50%)"}}/>

            }
            {width != null && height != null
            && plotDataState[index2] === REQUEST_STATUS_SUCCESS && plotSettings[index2].mode === 0 && plotData[index2].length === 0 && plotSettings[index2].groups.length > 0 &&
            <div style={{fontSize: "14px", color: "red",position:"absolute",top:"50%",left:"50%", transform:"translate(-50%, -50%)"}}>
              No ROIs available for the cases and groups selection!<br/>
              Please select a new analysis configuration from the sidebar.
            </div>}

            { plotSettings[index2].mode === 0 &&
            <div style={plotTitleStyle}>{getMeasurementShortLabel(plotSettings[index2].selectedMeasurementConfiguration,rawData).split("WHERE")[0]}</div>}

            {modelParametersState[index2] === REQUEST_STATUS_SUCCESS
            && plotDataState[index2] === REQUEST_STATUS_SUCCESS
            && plotSettings[index2].mode === 0
            && plotData[index2].length > 0 &&
            <BlandAltmanPlot
              yLabel={plotSettings[index2].percentageDifference
                ? "Difference between the two groups [in %]"
                : "Difference between the two groups"}
              xLabel="Average of the two groups"
              yLabelTip={plotSettings[index2].percentageDifference
                ? "Difference between the two groups [in %]"
                : "Difference between the two groups"}
              xLabelTip="Average of the two groups"
              length={(!(plotData[index2] != null)) ? -1 : plotData[index2].length}
              outerWidth={cellWidth}
              outerHeight={rowHeight}
              data={plotDataWithDups[index2].length>0?plotDataWithDups[index2]:plotData[index2]} //this.state.plotData[index][cIndex]} // plotData
              colorCat="userId"
              onDataPointClick={(d, onDeselect) => {
                updateSelectedDataPoint(d);
                this.onDataPointClick(index2, d, onDeselect)
              }}
              onContextClick={(e, d,onDeselect)=>this.onContextClick(e, d,onDeselect,index,cIndex)}
              avatarSize={9}
              selected={selectedDataPoint}
              viewId={"view_" + index + "_" + cIndex}
              prefixId="avatar"
              statsServerState={modelParametersState[index2]}
              modelParameters={modelParameters[index2]}
              meanLineWidth={2}
              percentageDifference={plotSettings[index2].percentageDifference}
            />}


            { plotSettings[index2].mode === 1 // too long title for 2d scatterplot needs to be shortened
            && plotSettings[index2].dimensionality === 2
            && plotSettings[index2].plotType !== SAM_PLOT_TYPE.BOX_STATIC_PLOT
            && plotSettings[index2].plotType !== SAM_PLOT_TYPE.KERNEL_STATIC_PLOT
            && plotSettings[index2].plotType !== SAM_PLOT_TYPE.HISTOGRAM_STATIC_PLOT
            &&
            <div style={plotTitleStyle}>
              {getMeasurementShortLabel(plotSettings[index2].selectedMeasurementConfiguration[plotSettings[index2].axisXIndex],rawData).split("WHERE")[0]}
              vs.
              {getMeasurementShortLabel(plotSettings[index2].selectedMeasurementConfiguration[plotSettings[index2].axisYIndex],rawData).split("WHERE")[0]}
            </div>
            }

            {plotSettings[index2].mode === 1
            && plotSettings[index2].plotType === SAM_PLOT_TYPE.SCATTER_PLOT
            && plotSettings[index2].dimensionality === 2
            && plotData[index2].length > 0 &&
            <ScatterPlotSAM
              key={"view_" + index + "_" + cIndex}
              data={plotDataWithDups[index2].length>0?plotDataWithDups[index2]:plotData[index2]} //this.state.plotData[index][cIndex]} // plotData
              selectedDataPointIndex={selectedDataPoint}
              width={cellWidth}
              margin={{ top: 20,  right: 50, bottom: 50,  left: 50}}
              height={rowHeight}
              contextMenu={null}
              onContextClick={(e, d,onDeselect)=>this.onContextClick(e, d,onDeselect,index,cIndex)}
              slrmodel={null}
              xLabel={getMeasurementShortLabel(plotSettings[index2].selectedMeasurementConfiguration[plotSettings[index2].axisXIndex],rawData)}
              yLabel={getMeasurementShortLabel(plotSettings[index2].selectedMeasurementConfiguration[plotSettings[index2].axisYIndex],rawData)}
              renderXLabel={true}
              onDataPointClick={()=>{alert("clicked datapoint")}}
              colorCat={null}/>}

            {plotSettings[index2].mode === 3 &&
            <ScatterPlotSAM
              key={"view_" + index + "_" + cIndex}
              data={plotDataWithDups[index2].length>0?plotDataWithDups[index2]:plotData[index2]} //this.state.plotData[index][cIndex]} // plotData
              selectedDataPointIndex={selectedDataPoint}
              width={cellWidth}
              margin={{ top: 20,  right: 50, bottom: 50,  left: 50}}
              height={rowHeight}
              contextMenu={null}
              onContextClick={(e, d,onDeselect)=>this.onContextClick(e, d,onDeselect,index,cIndex)}
              slrmodel={generateSLR()}
              showRegLine={true}
              showCILine={plotSettings[index2].showCI}
              showPILine={plotSettings[index2].showPI}
              xLabel={getMeasurementShortLabel(plotSettings[index2].selectedMeasurementConfiguration[plotSettings[index2].axisXIndex],rawData)}
              yLabel={getMeasurementShortLabel(plotSettings[index2].selectedMeasurementConfiguration[plotSettings[index2].axisYIndex],rawData)}
              renderXLabel={true}
              onDataPointClick={()=>{alert("clicked datapoint")}}
              colorCat={null}/>}

            {plotSettings[index2].mode === 1
            && (plotSettings[index2].plotType === SAM_PLOT_TYPE.KERNEL_STATIC_PLOT || plotSettings[index2].plotType === SAM_PLOT_TYPE.BOX_STATIC_PLOT || plotSettings[index2].plotType === SAM_PLOT_TYPE.HISTOGRAM_STATIC_PLOT)
            && plotDataState[index2] === REQUEST_STATUS_SUCCESS
            &&
            <span onContextMenu={(e)=>this.onContextClick(e, null,()=>{},index,cIndex)}>
            <img
              src={`data:image/png;base64,${plotData[index2]}`}
              height={rowHeight}
              width={cellWidth}
              style={{verticalAlign:"inherit"}}
              alt={"No data"}/>
            </span>
            }
            {plotSettings[index2].mode === 2
            && plotDataState[index2] === REQUEST_STATUS_SUCCESS
            && plotData[index2]!=null
            &&
            <span onContextMenu={(e)=>this.onContextClick(e, null,()=>{},index,cIndex)}>
            <img
              src={`${plotData[index2]}`}
              height={rowHeight}
              width={cellWidth}
              style={{verticalAlign:"inherit"}}
              alt={"No data"}/>
            </span>
            }


            {plotSettings[index2].mode === 1
            && plotSettings[index2].dimensionality === 1
            && plotSettings[index2].plotType === SAM_PLOT_TYPE.SCATTER_PLOT
            && plotData[index2].length > 0 &&
            <RaterHistogram data={plotDataWithDups[index2].length>0?plotDataWithDups[index2]:plotData[index2]}
                            prefixId={"view_" + index + "_" + cIndex}
                            xLabel={getMeasurementShortLabel(plotSettings[index2].selectedMeasurementConfiguration[plotSettings[index2].axisXIndex],rawData)}
                            width={cellWidth}
                            outerWidth={cellWidth}
                            outerHeight={rowHeight}
                            onContextClick={(e, d,onDeselect)=>this.onContextClick(e, d,onDeselect,index,cIndex)}
            />}
          </div>
        })}
        </div>);
      });

    const caseLabel = ()=>{
      if(getNestedProp(["realCase"],selectedDataPoint)!=null && getNestedProp(["cases"],rawData)!=null){
        const caseObject = getNestedProp(["cases"],rawData).find(el=>el.uuid === getNestedProp(["realCase"],selectedDataPoint));
        if (caseObject!=null)
          return caseObject.label;
        return getNestedProp(["realCase"],selectedDataPoint);
      }
      return "NA";
    };

    const dataDialogHeader = ()=>{
      if (showDataSetDialog){
        return getNestedProp(["realCase"],selectedDataPoint)!=null
          ? "Contributions for case: "+caseLabel()
          :"Contributions for " + getMeasurementShortLabel(selectedPlotSettings.selectedMeasurementConfiguration,rawData);
      }
      if (showCaseDetailsDialog){
        return getNestedProp(["realCase"],selectedDataPoint)!=null
          ? "Case details:"+caseLabel()
          : "Cases for " + getMeasurementShortLabel(selectedPlotSettings.selectedMeasurementConfiguration,rawData);
      }
      if (showStatsDialog){
        switch(getNestedProp(["mode"],selectedPlotSettings)){
          case 0: return "Inter-rater Reliability and Agreement Statistics";
          case 3: return "Regression Model";
          default: return "Distribution Statistics";
        }
      }
      if (showObservationsDetailsDialog){
        return  "Observations"  ;
      }
    };

    return (
      <div id="avatarId" className="ui-g-12 ui-g-nopad" ref={node => this.histogramContainer = node}>
        {width != null && height != null && rows}

        {!(rawData != null && rawData.hasOwnProperty("contributors")) &&
        <i className="fa fa-spinner fa-spin"
           title="Configuration is loading"
           style={{position: "fixed", top: "70px", right: "10px", fontSize: "20px"}}/>
        }

        {rawData != null && rawData.hasOwnProperty("contributors") &&
        <React.Fragment>
          <a>
            <i className="fa fa-fw fa-ellipsis-v"
               title="Configuration"
               onClick={() => updatePropertySAM("optionsSidebarVisible",true)}
               style={{position: "fixed", top: "70px", right: "10px", fontSize: "20px"}}/>
          </a>
          <ContainerSAMSidebar/>
        </React.Fragment>}

        <ContextMenu
          style={{width:"200px",fontSize:"larger"}}
          model={pointContextMenuItems(this)}
          ref={cm => (this.cm = cm)}/>
        <ContextMenu
          style={{width:"200px",fontSize:"larger"}}
          model={plotContextMenuItems(this)}
          ref={cmPanel => (this.cmPanel = cmPanel)}/>
        <Dialog
                id={"data-dialog"}
                header={dataDialogHeader()}
                closeOnEscape={true}
                resizable={true}
                visible={showDataSetDialog || showCaseDetailsDialog || showStatsDialog || showObservationsDetailsDialog}
                modal={false}
                onHide={()=>{
                  if (deselectDataPoint != null)
                    deselectDataPoint();
                  this.setState({showDataSetDialog:false,showCaseDetailsDialog:false,showStatsDialog:false,showObservationsDetailsDialog:false});
                  updateSelectedDataPoint(null);
                }}
                contentStyle={contentStyle}
                style={dynamicStyle}>
          {selectedDataPoint!=null && Object.keys(selectedDataPoint).length>0 && showDataSetDialog && <ContainerSelectedContributionsPanel/>}
          {!(selectedDataPoint!=null && Object.keys(selectedDataPoint).length>0) && showDataSetDialog && <ContainerSeriesContributionsPanel/>}
          {selectedDataPoint!=null && Object.keys(selectedDataPoint).length>0 && showCaseDetailsDialog && <ContainerSelectedCasePanel/>}
          {!(selectedDataPoint!=null && Object.keys(selectedDataPoint).length>0) && showCaseDetailsDialog && <ContainerSeriesCasesPanel/>}
          {!(selectedDataPoint!=null && Object.keys(selectedDataPoint).length>0) && showStatsDialog
            &&  selectedPlotSettings.mode === 0 &&  <ContainerIRRStatsPanel/>}
          {!(selectedDataPoint!=null && Object.keys(selectedDataPoint).length>0) && showStatsDialog
          &&  selectedPlotSettings.mode === 3 &&  <ContainerGLMStatsPanel/>}
          {!(selectedDataPoint!=null && Object.keys(selectedDataPoint).length>0) && showStatsDialog
          && selectedPlotSettings!=null &&selectedPlotSettings.mode === 1 && <ContainerDistributionStatsPanel/>}
          {!(selectedDataPoint!=null && Object.keys(selectedDataPoint).length>0) && showObservationsDetailsDialog
          && selectedPlotSettings!=null &&  <ContainerSeriesDataPointsPanel/>}
        </Dialog>
        <Dialog
          id={"help-dialog"}
          header={"Analysis Module Help Page"}
          closeOnEscape={true}
          resizable={true}
          visible={showHelpDialog}
          modal={false}
          onHide={()=>{this.setState({showHelpDialog:false}) }}
          >
          <SAMHelpPanel/>
        </Dialog>
        <ContainerVisualizationToolPanel
          onHide={this.onHideVolumeDialog}
          manualToolType={this.state.manualToolType}
        />
        <i className={"fa fa-question-circle-o taskToolHelpButton"}  style={{fontSize: "30px"}} onClick={()=>this.setState({showHelpDialog:true})} title="Help" />
      </div>
    )
  }
}

SAMPanel.defaultProps = {
  plotDataState: "",
  modelParametersState: []
};
SAMPanel.propTypes = {
  experimentId: PropTypes.object.isRequired, //from React-router
  initializeStandardizedAgreementModel: PropTypes.func.isRequired,
  initializeBlandAltmanPlot: PropTypes.func.isRequired,
  clearDataset: PropTypes.func.isRequired,
  plotData: PropTypes.array,
  plotDataWithDups: PropTypes.array,
  plotSettings: PropTypes.array,
  selectedPlotSettings: PropTypes.object,
  plotDataState: PropTypes.string.isRequired,
  modelParameters: PropTypes.array.isRequired,
  modelParametersState: PropTypes.array.isRequired,
  results: PropTypes.object,
  resultsState: PropTypes.string,
  clearStandardizedPlot: PropTypes.func,
  match: PropTypes.object.isRequired,
  updateSelectedDataPoint: PropTypes.func.isRequired,
  selectedDataPoint: PropTypes.object.isRequired,
  onChangeCell:PropTypes.func.isRequired,
  updatePropertySAM:PropTypes.func.isRequired,
  selectedLayout:PropTypes.object.isRequired,
  clearViewersState:PropTypes.func.isRequired
};


const dynamicStyle={
  maxHeight: (window.innerHeight),
  minHeight: (window.innerHeight * 0.7),
  // maxWidth: window.innerHeight / 250 < 3 ? window.innerWidth / 2 : window.innerWidth * 0.9,
  minWidth: (window.innerWidth / 2),
  overflowWrap: "break-word"
};


const flexRowContainer = {
  display: "inline-block",
  position: "relative",
  width: "100%"
};

const contentStyle = {
  minHeight: (window.innerHeight * 0.7),
  minWidth: (window.innerWidth / 2)
};

const plotTitleStyle ={
  position: "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: "0",
  right: "0",
  textAlign: "center"
};


const pointContextMenuItems =(r)=> [
  {
    label:'Visualize groups',
    icon:'fa fa-fw fa-users',
    command:()=>{r.onMenuOptionSelect("visualizationToolDialogVisible","group")}
  },
  {
    label:'Visualize contributions',
    icon:'fa fa-fw fa-user',
    command:()=>{r.onMenuOptionSelect("visualizationToolDialogVisible","contribution")}
  },
  {
    label:'Visualize ROIs',
    icon:'fa fa-fw fa-user',
    command:()=>{r.onMenuOptionSelect("visualizationToolDialogVisible","roi")}
  },
  {
    label:'Visualize contributors',
    icon:'fa fa-fw fa-user',
    command:()=>{r.onMenuOptionSelect("visualizationToolDialogVisible","contributor")}
  },
  {
    label:'Advanced visualization',
    icon:'fa fa-fw fa-users',
    command:()=>{r.onMenuOptionSelect("visualizationToolDialogVisible","advanced")}
  },
  {
    separator:true
  },
  {
    label:'Show data',
    icon:'fa fa-fw fa-database',
    items:[
      {
        label:'Contributions',
        icon:'fa fa-fw fa-pencil',
        command:()=>{r.onMenuOptionSelect("showDataSetDialog")}
      },
      {
        label:'Case details',
        icon:'fa fa-fw fa-address-card-o',
        command:()=>{r.onMenuOptionSelect("showCaseDetailsDialog")}
      }
    ]
  },
  {
    separator:true
  },
  {
    label:'Quit',
    icon:'fa fa-fw fa-power-off'
  }
];

const plotContextMenuItems =(r)=> [
  {
    label:'Advanced visualization',
    icon:'fa fa-fw fa-users',
    command:()=> {alert("Not yet implemented") }
  },
  {
    separator:true
  },
  {
    label:'Show data',
    icon:'fa fa-fw fa-database',
    items:[
      {
        label:'Contributions',
        icon:'fa fa-fw fa-pencil',
        command:()=>{r.onMenuOptionSelect("showDataSetDialog")}
      },
      {
        label:'Cases',
        icon:'fa fa-fw fa-address-card-o',
        command:()=>{r.onMenuOptionSelect("showCaseDetailsDialog")}
      },
      {
        label:'Observations',
        icon:'fa fa-fw fa-dot-circle-o' ,
        command:()=>{r.onMenuOptionSelect("showObservationsDetailsDialog")}
      },
      {
        label:'Statistics',
        icon:'fa fa-fw fa-bar-chart',
        command:()=> {r.onMenuOptionSelect("showStatsDialog")}
      }
    ]
  },
  {
    separator:true
  },
  {
    label:'Quit',
    icon:'fa fa-fw fa-power-off'
  }
];