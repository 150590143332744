import React from "react";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";
import PropTypes from "prop-types";
const MIDDLE = "middle";  //id of left vie
const UPPER_RIGHT = "upperRight";


/**
 * Layout component for displaying viewers panel with Single element.
 *
 */
class OneMainOneReferenceInRowLayout extends React.Component {

    static getLabel(){
        return "One main and one reference viewers of the same size in a row"
    }
    static getClassName(){
        return "perivascularDemo"
    }

    static getViewersPortKeys(){
        return [MIDDLE,UPPER_RIGHT];
    }

    constructor(props) {
        super(props);
        this.state = {
            widthMain:"80vh",
            widthReference:"80vh",
            heightMain:"80vh",
            heightReference:"80vh"
        };
        ["calcLayout"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }
    componentDidMount() {
        this.calcLayout();
        window.addEventListener("resize",this.calcLayout);
    }
    componentDidUpdate(prevProps, prevState) {
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.calcLayout);
    }
    /** Calculates the size of viewers.
     *  Still it is not final version,
     *  TODO FIXME calculate correct factors.
     **/
    calcLayout() {
        if (window.innerWidth < (1.5 * window.innerHeight) ) {
            this.setState({widthMain:"52vw",widthReference:"26vw",heightMain:"52vw",heightReference:"26vw"});
            console.log("Portait mode");
        }
        if (window.innerWidth > (1.5 * window.innerHeight) ) {
            this.setState({widthMain:"80vh",widthReference:"40vh",heightMain:"80vh",heightReference:"40vh"});
            console.log("Landscape mode");
        }
    }

    render() {
        const {toolComponentProps, toolComponentState,widgetPanel} = this.props;
        const {
            viewersState, images
        } = toolComponentProps;

        let getImage = (index) => {
            if (viewersState[index]!=null)
                return images[viewersState[index].imageId];//.find((el) => viewersState[index].imageId === el.uuid);
            else return null;
        };
        // const image = getImage("middle");
        // const styleMain = {padding: "0.1em", height: this.state.heightMain, width: this.state.widthMain};
        // const styleRef = {padding: "0.1em", height: this.state.heightReference, width: this.state.widthReference};

        const middle = (viewersState != null && viewersState.hasOwnProperty(MIDDLE))
            ? BrowserFactory.build(MIDDLE, toolComponentProps, toolComponentState)
            : <div title="Main viewer here."/>;


        const upperRight = (viewersState != null && viewersState.hasOwnProperty(UPPER_RIGHT))
            ? BrowserFactory.build(UPPER_RIGHT, toolComponentProps, toolComponentState)
            : <div title="Upper-right reference viewer here."/>;


        return (
            <div>
                {widgetPanel}
            <div  className="viewerContainerFull">
                <div  className="viewerContainerMainPanel" style={{display:"block"}}>
                    {middle}
                </div>
                <div  className="viewerContainerMainPanel" style={{display:"block"}}>
                    {upperRight}
                </div>
            </div>
            </div>
        )
    }
}

export default OneMainOneReferenceInRowLayout;

OneMainOneReferenceInRowLayout.defaultProps = {};

OneMainOneReferenceInRowLayout.propTypes = {
    widgetPanel:PropTypes.object.isRequired
};


