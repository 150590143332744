import macro from 'vtk.js/Sources/macro';
import vtkPolydata from 'vtk.js/Sources/Common/DataModel/PolyData';
import { vec3 } from "gl-matrix";
import { SlicingMode } from 'vtk.js/Sources/Rendering/Core/ImageMapper/Constants';

const { vtkErrorMacro } = macro;
export const OutlineMode = {
  POLYGON: 'polygon',
  LINES: 'lines',
};
// ----------------------------------------------------------------------------
// vtkImageOutlinePoly methods
// version with Polygons
// ----------------------------------------------------------------------------

function vtkImageOutlinePoly(publicAPI, model) {
  model.classHierarchy.push('vtkImageOutlinePoly');

  publicAPI.requestData = (inData, outData) => {
    // implement requestData
    const input = inData[0];
    if (!input || input.getClassName() !== 'vtkImageData') {
      vtkErrorMacro('Invalid or missing input');
      return;
    }
    const vxls = input
      .getPointData()
      .getScalars()
      .getData();

    const output = vtkPolydata.newInstance();

    const getIndex = (point, dims) =>
      point[0] + point[1] * dims[0] + point[2] * dims[0] * dims[1];


    /**
     * Calculate correction in direction of camera - Attract point to voxel grid.
     * If orientation is Right, Superior or Posterior (in any slicing mode ijk) then the points calculated
     * for outline needs to be translated to the preceding (or following) slice.
     * It is always translated into camera direction for the length of 1 voxel face (spacing) -
     * it would be enough to use smaller value, but the required small value is changing depending on slice number and we
     * don't need to do much calculations). More details in calcPoints function jsdoc.
     * @return {number}
     */
    const getDirectionCorrection =()=> {
      const rowIndex = model.slicingMode === SlicingMode.I
        ? 0
        : model.slicingMode === SlicingMode.J
          ? 3 : 6;
      const row = input.getDirection().slice(rowIndex,rowIndex+3); // get 3-element subarray for I or J or K only
      const indexOfMax =  row.reduce((iMax, x, i, arr) => Math.abs(x) > Math.abs(arr[iMax]) ? i : iMax, 0); // find index with maximum absolute value, iMax - accumulator of index value, 0 - initial index value
      if ((indexOfMax === 0 && row[indexOfMax]>0)|| (indexOfMax === 1 && row[indexOfMax]<0) || (indexOfMax === 2 && row[indexOfMax]<0))
        return input.getSpacing()[model.slicingMode] * 1;
      else return 0;
    };

    const dims = input.getDimensions();
    // calculate corrections related to spreading points grid to extreme values in Imaga Data indexToWorld function
    const correctionI = (dims[0] - 1) / dims[0];
    const correctionJ = (dims[1] - 1) / dims[1] ;
    const correctionK = (dims[2] - 1) / dims[2] ;


    const indexToWorldOrigin = (ijk) => {
      const vout = [0, 0, 0];
      vec3.transformMat4(
        vout,
        [ijk[0] * correctionI, ijk[1] * correctionJ, ijk[2] * correctionK],
        input.getIndexToWorld()
      );
      return vout;
    };

    const AC = model.thickness; // to render after grid line (for rendering on grid line AC = 0 )
    const BC = 1 - AC; // to render before grid line (for rendering on grid line BC = 1 )

    const different = (p1, p2) =>
      Math.abs(vxls[getIndex(p1, dims)] - vxls[getIndex(p2, dims)]) >
      model.threshold;

    /**
     * Calculate points for lines and polygons (2 or 4 endpoints). This function is called when
     * there is a change of value between or(iginal) and tr(anslated) voxel
     * @param or - original voxel location in [i,j,k] space
     * @param tr - translation of voxel in [i,j,k] space eg. [-1, 0, 0], [0, 1, 0] (unit vector - only neighboring voxels)
     * @return {{m1: number[], m2: number[]}|{m1: number[], m2: number[], m3: number[], m4: number[]}}
     */
    const calcPoints = (or, tr) => {
      if (model.slicingMode === SlicingMode.K) {

        const translatedK = or[2]+getDirectionCorrection(); // attract into camera direction if needed

        if (tr[0] !== 0) {
          const lineBEG = different(or, [or[0], or[1] - 1, translatedK])
            ? AC
            : 0 - AC;
          const lineEND = different(or, [or[0], or[1] + 1, translatedK])
            ? BC
            : 1 + AC;
          if (tr[0] > 0) {
            if (model.mode === OutlineMode.LINES)
              return {
                m1: indexToWorldOrigin([or[0] + BC, or[1] + lineBEG, translatedK]),
                m2: indexToWorldOrigin([or[0] + BC, or[1] + lineEND, translatedK]),
              };
            return {
              // lineBEG and lineEND do not apply - gaps must be covered
              m1: indexToWorldOrigin([or[0] + BC, or[1], translatedK]),
              m2: indexToWorldOrigin([or[0] + 1, or[1], translatedK]),
              m3: indexToWorldOrigin([or[0] + 1, or[1] + 1, translatedK]),
              m4: indexToWorldOrigin([or[0] + BC, or[1] + 1, translatedK]),
            };
          }
          if (model.mode === OutlineMode.LINES)
            return {
              m1: indexToWorldOrigin([or[0] + AC, or[1] + lineBEG, translatedK]),
              m2: indexToWorldOrigin([or[0] + AC, or[1] + lineEND, translatedK]),
            };
          return {
            // lineBEG and lineEND do not apply - gaps must be covered
            m1: indexToWorldOrigin([or[0] + AC, or[1], translatedK]),
            m2: indexToWorldOrigin([or[0] + AC, or[1] + 1, translatedK]),
            m3: indexToWorldOrigin([or[0], or[1] + 1, translatedK]),
            m4: indexToWorldOrigin([or[0], or[1], translatedK]),
          };
        }
        if (tr[1] !== 0) {
          const lineBEG = different(or, [or[0] - 1, or[1], translatedK])
            ? AC
            : 0 - AC;
          const lineEND = different(or, [or[0] + 1, or[1], translatedK])
            ? BC
            : 1 + AC;
          if (tr[1] > 0) {
            if (model.mode === OutlineMode.LINES)
              return {
                m1: indexToWorldOrigin([or[0] + lineBEG, or[1] + BC, translatedK]),
                m2: indexToWorldOrigin([or[0] + lineEND, or[1] + BC, translatedK]),
              };
            return {
              // lineBEG and lineEND do not matter - gaps are already covered
              m1: indexToWorldOrigin([or[0] + lineBEG, or[1] + BC, translatedK]),
              m2: indexToWorldOrigin([or[0] + lineEND, or[1] + BC, translatedK]),
              m3: indexToWorldOrigin([or[0] + lineEND, or[1] + 1, translatedK]),
              m4: indexToWorldOrigin([or[0] + lineBEG, or[1] + 1, translatedK]),
            };
          }
          if (model.mode === OutlineMode.LINES)
            return {
              m1: indexToWorldOrigin([or[0] + lineBEG, or[1] + AC, translatedK]),
              m2: indexToWorldOrigin([or[0] + lineEND, or[1] + AC, translatedK]),
            };
          return {
            // lineBEG and lineEND do not matter - gaps are already covered
            m1: indexToWorldOrigin([or[0] + lineBEG, or[1] + AC, translatedK]),
            m2: indexToWorldOrigin([or[0] + lineEND, or[1] + AC, translatedK]),
            m3: indexToWorldOrigin([or[0] + lineEND, or[1], translatedK]),
            m4: indexToWorldOrigin([or[0] + lineBEG, or[1], translatedK]),
          };
        }
      }
      if (model.slicingMode === SlicingMode.I) {

        const translatedI = or[0]+ getDirectionCorrection();  // attract into camera direction if needed

        if (tr[2] !== 0) {
          const lineBEG = different(or, [translatedI, or[1] - 1, or[2]])
            ? AC
            : 0 - AC;
          const lineEND = different(or, [translatedI, or[1] + 1, or[2]])
            ? BC
            : 1 + AC;
          if (tr[2] > 0) {
            if (model.mode === OutlineMode.LINES)
              return {
                m1: indexToWorldOrigin([translatedI, or[1] + lineBEG, or[2] + BC]),
                m2: indexToWorldOrigin([translatedI, or[1] + lineEND, or[2] + BC]),
              };
            return {
              m1: indexToWorldOrigin([translatedI, or[1], or[2] + BC]),
              m2: indexToWorldOrigin([translatedI, or[1], or[2] + 1]),
              m3: indexToWorldOrigin([translatedI, or[1] + 1, or[2] + 1]),
              m4: indexToWorldOrigin([translatedI, or[1] + 1, or[2] + BC]),
            };
          }
          if (model.mode === OutlineMode.LINES)
            return {
              m1: indexToWorldOrigin([translatedI, or[1] + lineBEG, or[2] + AC]),
              m2: indexToWorldOrigin([translatedI, or[1] + lineEND, or[2] + AC]),
            };
          return {
            m1: indexToWorldOrigin([translatedI, or[1], or[2] + AC]),
            m2: indexToWorldOrigin([translatedI, or[1] + 1, or[2] + AC]),
            m3: indexToWorldOrigin([translatedI, or[1] + 1, or[2]]),
            m4: indexToWorldOrigin([translatedI, or[1], or[2]]),
          };
        }
        if (tr[1] !== 0) {
          const lineBEG = different(or, [translatedI, or[1], or[2] - 1])
            ? AC
            : 0 - AC;
          const lineEND = different(or, [translatedI, or[1], or[2] + 1])
            ? BC
            : 1 + AC;
          if (tr[1] > 0) {
            if (model.mode === OutlineMode.LINES)
              return {
                m1: indexToWorldOrigin([translatedI, or[1] + BC, or[2] + lineBEG]),
                m2: indexToWorldOrigin([translatedI, or[1] + BC, or[2] + lineEND]),
              };
            return {
              m1: indexToWorldOrigin([translatedI, or[1] + BC, or[2] + lineBEG]),
              m2: indexToWorldOrigin([translatedI, or[1] + 1, or[2] + lineBEG]),
              m3: indexToWorldOrigin([translatedI, or[1] + 1, or[2] + lineEND]),
              m4: indexToWorldOrigin([translatedI, or[1] + BC, or[2] + lineEND]),
            };
          }
          if (model.mode === OutlineMode.LINES)
            return {
              m1: indexToWorldOrigin([translatedI, or[1] + AC, or[2] + lineBEG]),
              m2: indexToWorldOrigin([translatedI, or[1] + AC, or[2] + lineEND]),
            };
          return {
            m1: indexToWorldOrigin([translatedI, or[1] + AC, or[2] + lineBEG]),
            m2: indexToWorldOrigin([translatedI, or[1] + AC, or[2] + lineEND]),
            m3: indexToWorldOrigin([translatedI, or[1], or[2] + lineEND]),
            m4: indexToWorldOrigin([translatedI, or[1], or[2] + lineBEG]),
          };
        }
      }
      if (model.slicingMode === SlicingMode.J) {
        const translatedJ = or[1]+getDirectionCorrection(); // attract into camera direction if needed
        if (tr[2] !== 0) {
          const lineBEG = different(or, [or[0] - 1, translatedJ, or[2]])
            ? AC
            : 0 - AC;
          const lineEND = different(or, [or[0] + 1, translatedJ, or[2]])
            ? BC
            : 1 + AC;
          if (tr[2] > 0) {
            if (model.mode === OutlineMode.LINES)
              return {
                m1: indexToWorldOrigin([or[0] + lineBEG, translatedJ, or[2] + BC]),
                m2: indexToWorldOrigin([or[0] + lineEND, translatedJ, or[2] + BC]),
              };
            return {
              m1: indexToWorldOrigin([or[0], translatedJ, or[2] + BC]),
              m2: indexToWorldOrigin([or[0], translatedJ, or[2] + 1]),
              m3: indexToWorldOrigin([or[0] + 1, translatedJ, or[2] + 1]),
              m4: indexToWorldOrigin([or[0] + 1, translatedJ, or[2] + BC]),
            };
          }
          if (model.mode === OutlineMode.LINES)
            return {
              m1: indexToWorldOrigin([or[0] + lineBEG, translatedJ, or[2] + AC]),
              m2: indexToWorldOrigin([or[0] + lineEND, translatedJ, or[2] + AC]),
            };
          return {
            m1: indexToWorldOrigin([or[0], translatedJ, or[2] + AC]),
            m2: indexToWorldOrigin([or[0] + 1, translatedJ, or[2] + AC]),
            m3: indexToWorldOrigin([or[0] + 1, translatedJ, or[2]]),
            m4: indexToWorldOrigin([or[0], translatedJ, or[2]]),
          };
        }
        if (tr[0] !== 0) {
          const lineBEG = different(or, [or[0], translatedJ, or[2] - 1])
            ? AC
            : 0 - AC;
          const lineEND = different(or, [or[0], translatedJ, or[2] + 1])
            ? BC
            : 1 + AC;
          if (tr[0] > 0) {
            if (model.mode === OutlineMode.LINES)
              return {
                m1: indexToWorldOrigin([or[0] + BC, translatedJ, or[2] + lineBEG]),
                m2: indexToWorldOrigin([or[0] + BC, translatedJ, or[2] + lineEND]),
              };
            return {
              m1: indexToWorldOrigin([or[0] + BC, translatedJ, or[2] + lineBEG]),
              m2: indexToWorldOrigin([or[0] + BC, translatedJ, or[2] + lineEND]),
              m3: indexToWorldOrigin([or[0] + 1, translatedJ, or[2] + lineEND]),
              m4: indexToWorldOrigin([or[0] + 1, translatedJ, or[2] + lineBEG]),
            };
          }
          if (model.mode === OutlineMode.LINES)
            return {
              m1: indexToWorldOrigin([or[0] + AC, translatedJ, or[2] + lineBEG]),
              m2: indexToWorldOrigin([or[0] + AC, translatedJ, or[2] + lineEND]),
            };
          return {
            m1: indexToWorldOrigin([or[0] + AC, translatedJ, or[2] + lineBEG]),
            m2: indexToWorldOrigin([or[0] + AC, translatedJ, or[2] + lineEND]),
            m3: indexToWorldOrigin([or[0], translatedJ, or[2] + lineEND]),
            m4: indexToWorldOrigin([or[0], translatedJ, or[2] + lineBEG]),
          };
        }
      }
      return { m1: [0, 0, 0], m2: [0, 0, 0] };
    };

    const vertexArray = [];
    const linesArray = [];
    const polysArray = [];

    // calculate loop boundaries for a given slice and SlicingMode
    const iStart = model.slicingMode === 0 ? model.slice : 0;
    const iEnd = model.slicingMode === 0 ? model.slice + 1 : dims[0];
    const jStart = model.slicingMode === 1 ? model.slice : 0;
    const jEnd = model.slicingMode === 1 ? model.slice + 1 : dims[1];
    const kStart = model.slicingMode === 2 ? model.slice : 0;
    const kEnd = model.slicingMode === 2 ? model.slice + 1 : dims[2];

    for (let i = iStart; i < iEnd; i++) {
      for (let j = jStart; j < jEnd; j++) {
        for (let k = kStart; k < kEnd; k++) {
          const val = vxls[getIndex([i, j, k], dims)];
          // check if there is enough change
          if (Math.abs(val - model.label) < model.threshold) {
            const addBorder = (translation) => {
              const pp = [
                translation[0] + i,
                translation[1] + j,
                translation[2] + k,
              ];
              if (Math.abs(vxls[getIndex(pp, dims)] - val) > model.threshold) {
                const { m1, m2, m3, m4 } = calcPoints([i, j, k], translation);
                vertexArray.push(...m1);
                vertexArray.push(...m2);
                if (model.mode === OutlineMode.LINES) {
                  linesArray.push(
                    1000000000,
                    vertexArray.length / 3 - 2,
                    vertexArray.length / 3 - 1
                  );
                } else {
                  vertexArray.push(...m3);
                  vertexArray.push(...m4);
                  polysArray.push(
                    4,
                    vertexArray.length / 3 - 4,
                    vertexArray.length / 3 - 3,
                    vertexArray.length / 3 - 2,
                    vertexArray.length / 3 - 1
                  );
                }
              }
            };
            if (model.slicingMode === SlicingMode.K) {
              addBorder([-1, 0, 0]);
              addBorder([1, 0, 0]);
              addBorder([0, -1, 0]);
              addBorder([0, 1, 0]);
            }
            if (model.slicingMode === SlicingMode.I) {
              addBorder([0, 0, -1]);
              addBorder([0, 0, 1]);
              addBorder([0, -1, 0]);
              addBorder([0, 1, 0]);
            }
            if (model.slicingMode === SlicingMode.J) {
              addBorder([0, 0, -1]);
              addBorder([0, 0, 1]);
              addBorder([-1, 0, 0]);
              addBorder([1, 0, 0]);
            }
          }
        }
      }
    }

    output.getPoints().setData(Float32Array.from(vertexArray), 3);
    output.getLines().setData(Uint16Array.from(linesArray));
    output.getPolys().setData(Uint16Array.from(polysArray));
    outData[0] = output;
  };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  slicingMode: SlicingMode.K,
  slice: 40,
  label: 1,
  threshold: 0.99,
  thickness: 0.15,
  mode: OutlineMode.POLYGON, // or line
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Make this a VTK object
  macro.obj(publicAPI, model);

  // Also make it an algorithm with one input and one output
  macro.algo(publicAPI, model, 1, 1);

  macro.setGet(publicAPI, model, [
    'slicingMode',
    'slice',
    'label',
    'threshold',
    'thickness',
    'mode',
  ]);

  // Object specific methods
  vtkImageOutlinePoly(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkImageOutlinePoly');

// ----------------------------------------------------------------------------

export default { newInstance, extend };
