import React from "react";
import { Link } from "react-router-dom";
import { HOST_URL } from "../../../Constants";
import { getThumbnails } from "../utils/livePresenterHelpers";
import store from "../../Store";
import axios from "axios/index";

const PresentationCard = (props) => {
  const deletePresentation = (presentationId, revisionId) => {
    axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
    axios
      .delete(HOST_URL + `/api/presentation/${presentationId}`, { data: { revisionId: revisionId } })
      .then((response) => {
        console.log("PresentationAction.js :: deletePresentation :: response ::", response);
        props.setRequestData(new Date());
        if (response.status === 200) {
          console.log("Presentation Deleted");
        }
      })
      .catch((error) => {
        console.log("project err:", error);
      });
  };

  return (
    <div className="PresentationCard" style={{ width: "18rem" }}>
      <div className="PresentationCardImg">{getThumbnails(props.data.slides[0])}</div>
      <div className="PresentationCardBody">
        <h5 className="PresentationCardTitle">{props.data.name}</h5>
        <p className="PresentationCardText">{props.data.description}</p>
        <br />
        <div className="PresentationActions">
          <Link to={`/livePresenter/${props.data.uuid}`} className="PresentationView">
            View
          </Link>
          <Link to={`/livePresenter/${props.data.uuid}/slideShow`} className="PresentationPlay">
            Play
          </Link>
          <span
            onClick={() => deletePresentation(props.data.uuid, props.data.revisionId)}
            className="PresentationDelete"
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  );
};

export default PresentationCard;
