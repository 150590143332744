import React from "react";
import PropTypes from "prop-types";
import {getNestedProp} from "../../helpers/expressions";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";


class GraphImageCamera extends React.Component {

  constructor(props) {
    super(props);
  }


  render(){
    const {viewer, image} = this.props;

    const renderer = getNestedProp(["renderer"], viewer);

    if (image!=null && image.state===REQUEST_STATUS_SUCCESS){
      const data = getNestedProp(["data"], image);

      const volume = renderer!=null
        ? renderer.getVolumes()[0]
        : null;


      return (
        <div>
          <h5>Image data properties:</h5>
          <div>Anatomical orientation: { getNestedProp(["properties", "anatomicalOrientation"], image)} </div>
          <div>Spacing: [{data.getSpacing().join(", ")}] </div>
          <div>Origin: [{ data.getOrigin().join(", ")}] </div>
          <div>Dimensionality: [{data.getDimensions().join(", ")}]</div>
          <div>Data range: [{data.getPointData().getScalars().getRange().join(", ")}]</div>
          <div>Number of points: {data.getNumberOfPoints()}</div>
          <div>Number of cells: {data.getNumberOfCells()}</div>
          <div>Direction matrix:</div>
          <div>[{data.getDirection()[0]}, {data.getDirection()[1]}, {data.getDirection()[2]}</div>
          <div>{data.getDirection()[3]}, {data.getDirection()[4]}, {data.getDirection()[5]}</div>
          <div>{data.getDirection()[6]}, {data.getDirection()[7]}, {data.getDirection()[8]}]</div>
          {volume!=null && <React.Fragment>
            <h5>Volume actor properties:</h5>
            <div>Bounds: [{ volume.getBounds().join(", ")}] </div>
            <div>Center: [{ volume.getCenter().join(", ")}] </div>
            <div>Scale: [{ volume.getScale().join(", ")}] </div>
            <div>Orientation: [{ volume.getOrientation().join(", ")}] </div>
            {/*<h5>Camera properties</h5>*/}
            {/*<div>Position: [{renderer.getActiveCamera().getPosition().join(",")}] </div>*/}
            {/*<div>Focal point: [{renderer.getActiveCamera().getFocalPoint().join(",")}] </div>*/}
            {/*<div>Distance: {renderer.getActiveCamera().getDistance()} </div>*/}
            {/*<div>Direction: [{renderer.getActiveCamera().getDirectionOfProjection().join(",")}] </div>*/}
            {/*<div>Clipping range: [{renderer.getActiveCamera().getClippingRange().join(",")}] </div>*/}
          </React.Fragment>}
        </div>
      );

    }
    return "No image"
  }
}
export default GraphImageCamera;

GraphImageCamera.propTypes = {
  viewer:PropTypes.object.isRequired,
  image: PropTypes.object.isRequired
};

GraphImageCamera.defaultProps = {};
