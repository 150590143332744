import React from "react";
import RowLayout from "./RowLayout";
import SingleLayout from "./SingleLayout";
import OneMainFourReferenceLayout from "./OneMainFourReferenceLayout";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import OneMainTwoReferenceLayout from "./OneMainTwoReferenceLayout";
import OneMainOneReferenceWithArrowsLayout from "./OneMainOneReferenceWithArrowsLayout";
import SingleWithWidgetPanelLayout from "./SingleWithWidgetPanelLayout";
import OneMainOneReferenceInRowLayout from "./OneMainOneReferenceInRowLayout";
import OneMainTwoReferenceWidgetsLayout from "./OneMainTwoReferenceWidgetsLayout";
import VerticalForm from "./VerticalForm";
import SixPackLayout from "./SixPackLayout";


const LayoutManager = ({toolComponentProps,toolComponentState,importExportPanel,widgetPanel}) => {


    const manualTaskState = toolComponentProps.manualTaskState;
    const manualTask = toolComponentProps.manualTask;
    const layout = (manualTaskState===REQUEST_STATUS_SUCCESS
        && manualTask['miniWorkflow']!=null
        && manualTask['miniWorkflow']['currentTool']!=null
        && manualTask['miniWorkflow']['currentTool']['configuration']!=null
        && manualTask['miniWorkflow']['currentTool']['configuration']['viewers']!=null
        && manualTask['miniWorkflow']['currentTool']['configuration']['viewers']['layout']!=null
    )    ? manualTask['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['type']:"SINGLE";

    const props={toolComponentProps,toolComponentState,importExportPanel,widgetPanel};
    switch(layout){
        case 'SINGLE':
            return <SingleLayout {...props}/>;
        case '1M1R_IN_ROW':
            return <OneMainOneReferenceInRowLayout {...props}/>;
        case 'SINGLE_WITH_WIDGET_PANEL':
            return <SingleWithWidgetPanelLayout {...props}/>;
        case '1M4R':
            return <OneMainFourReferenceLayout {...props}/>;
        case '1M2R':
            return <OneMainTwoReferenceLayout {...props}/>;
        case '1M2R_WIDGETS':
            return <OneMainTwoReferenceWidgetsLayout {...props}/>;
        case '1M1R_ARROWS':
            return <OneMainOneReferenceWithArrowsLayout {...props}/>;
        case 'VERTICAL_FORM':
            return <VerticalForm {...props}/>;
        case 'SIX_PACK':
            return <SixPackLayout {...props}/>;
        case 'ROW':
        default:
            return <RowLayout {...props}/>;
    }
};
export default LayoutManager;


export const getLayoutClass = (layout)=>{
    switch(layout){
        case 'SINGLE':
            return SingleLayout;
        case '1M1R_IN_ROW':
            return OneMainOneReferenceInRowLayout;
        case 'SINGLE_WITH_WIDGET_PANEL':
            return SingleWithWidgetPanelLayout;
        case '1M4R':
            return OneMainFourReferenceLayout;
        case '1M2R':
            return OneMainTwoReferenceLayout;
        case '1M2R_WIDGETS':
            return OneMainTwoReferenceWidgetsLayout ;
        case '1M1R_ARROWS':
            return OneMainOneReferenceWithArrowsLayout;
        case 'VERTICAL_FORM':
            return VerticalForm;
        case 'SIX_PACK':
            return SixPackLayout;
        case 'ROW':
        default:
            return RowLayout;
    }
};

export const ALL_LAYOUTS=['SINGLE','1M1R_IN_ROW','SINGLE_WITH_WIDGET_PANEL', '1M4R', '1M2R', '1M2R_WIDGETS','1M1R_ARROWS', 'ROW','VERTICAL_FORM', 'SIX_PACK'];
