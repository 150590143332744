import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {CUSTOM_CURSOR_TOOL} from "../../../../Constants";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {checkIfAnyPropertyIsVisible} from "./WidgetToolbar";


const cursorOptions=[
    {label:'crosshair',value:'crosshair'},
    {label:'infoTooltip',value:'infoTooltip'},
    {label:'dottedCrosshair',value:'dottedCrosshair'},
    {label:'circle',value:'circle'},
    {label:'whiskers',value:'whiskers'},
    {label:'cityBlock',value:'cityBlock'},
    {label:'square',value:'square'},
    {label:'fixedCrosshairRelativeCircle',value:'fixedCrosshairRelativeCircle'},
    {label:'No Cursor!',value:'none'},
    ];

/**
 * Stateful component with template for cursor customization.
 * Responsible for changing and parameterizing cursor.
 * @returns {*}
 */
export class CustomCursorToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false
        };
    }

    render() {
        const {toolProperties, changeToolProperties, toolConfiguration} = this.props;
        const control = (property, type) => toolConfiguration != null
            && toolConfiguration.properties != null
            && toolConfiguration.properties[property] != null
            && toolConfiguration.properties[property][type] != null
            && toolConfiguration.properties[property][type];

        const checkIfAnyPropertyVisible = checkIfAnyPropertyIsVisible(toolConfiguration.properties);

        return (
            <React.Fragment>
                {checkIfAnyPropertyVisible &&
                <Toolbar style={{display: "inline-block"}}>
                    {control('type', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Cursor:</label>
                        <Dropdown
                            value={toolProperties['type']}
                            options={cursorOptions}
                            onChange={(e) => changeToolProperties(CUSTOM_CURSOR_TOOL, 'type', e.value)}
                        />
                    </React.Fragment>
                    }
                    {control('diameter', 'controlVisible') && (toolProperties['type']==='circle' || toolProperties['type']==='whiskers' || toolProperties['type']==='cityBlock' || toolProperties['type']==='square') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Diameter[mm]:</label>
                        <Spinner value={toolProperties['diameter']}
                                 min={1}
                                 max={1000}
                                 step={1}
                                 size={3}
                                 onChange={(e) => changeToolProperties(CUSTOM_CURSOR_TOOL, 'diameter', e.value)}
                        />
                    </React.Fragment>
                    }
                </Toolbar>}
            </React.Fragment>
        );
    }

};

CustomCursorToolbar.defaultProps = {
    toolProperties: {range: 1},
};
CustomCursorToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    toolConfiguration: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func
};
