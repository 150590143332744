export const EXPDESIGN_GLOBAL_DEFINITION = "EXPDESIGN_GLOBAL_DEFINITION"
export const EXPDESIGN_WIZARD_UPDATE_STEP = "EXPDESIGN_WIZARD_UPDATE_STEP"
export const EXPDESIGN_WIZARD_UPDATE_SESSION = "EXPDESIGN_WIZARD_UPDATE_SESSION"

//Statistical model
export const EXPDESIGN_STATMODEL_SELECT = "EXPDESIGN_STATMODEL_SELECT"
export const EXPDESIGN_STATMODEL_VARIABLES = "EXPDESIGN_STATMODEL_VARIABLES"
export const EXPDESIGN_STATMODEL_HYPOTHESIS = "EXPDESIGN_STATMODEL_HYPOTHESIS"


//Data selection
export const EXPDESIGN_DATA_SUBJECTS = "EXPDESIGN_DATA_SUBJECTS"
export const EXPDESIGN_DATA_STUDIES = "EXPDESIGN_DATA_STUDIES"
export const EXPDESIGN_DATA_DATASET = "EXPDESIGN_DATA_DATASET"
export const EXPDESIGN_DATA_DATASET_SUCCESS = "EXPDESIGN_DATA_DATASET_SUCCESS"
export const EXPDESIGN_DATA_DATASET_FAIL = "EXPDESIGN_DATA_DATASET_FAIL"
export const EXPDESIGN_DATA_DATASET_REQUEST = "EXPDESIGN_DATA_DATASET_REQUEST"
export const EXPDESIGN_DATA_INCLUSION_CRITERIA_UPDATE = "EXPDESIGN_DATA_INCLUSION_CRITERIA_UPDATE"
export const EXPDESIGN_DATA_EXCLUSION_CRITERIA_UPDATE = "EXPDESIGN_DATA_EXCLUSION_CRITERIA_UPDATE"
export const EXPDESIGN_DATA_SELECTED_STUDIES = "EXPDESIGN_DATA_SELECTED_STUDIES"

export const EXPDESIGN_DATASET_CLEAR = "EXPDESIGN_DATASET_CLEAR";


//Workflow selection
export const USER_LIST_REQUEST = "USER_LIST_REQUEST"
export const USER_LIST_FAIL = "USER_LIST_FAIL"
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS"

export const EXPDESIGN_FILTER_SUBJECTS = "EXPDESIGN_FILTER_SUBJECTS"
export const EXPDESIGN_REQUEST_STUDIES = "EXPDESIGN_REQUEST_STUDIES"
export const EXPDESIGN_SUCCESS_STUDIES = "EXPDESIGN_SUCCESS_STUDIES"
export const EXPDESIGN_FAIL_STUDIES = "EXPDESIGN_FAIL_STUDIES"
export const EXPDESIGN_SELECT_STUDIES = "EXPDESIGN_SELECT_STUDIES"

//Workflow selection
export const WORKFLOW_LIST_REQUEST = "WORKFLOW_LIST_REQUEST";
export const WORKFLOW_LIST_FAIL = "WORKFLOW_LIST_FAIL";
export const WORKFLOW_LIST_SUCCESS = "WORKFLOW_LIST_SUCCESS";

export const SET_WORKFLOWS_VISIBILITY_FILTER = "SET_WORKFLOWS_VISIBILITY_FILTER";

export const WORKFLOW_REQUEST = "WORKFLOW_REQUEST";
export const WORKFLOW_FAIL = "WORKFLOW_FAIL";
export const WORKFLOW_SUCCESS = "WORKFLOW_SUCCESS";

export const WORKFLOW_SAVE_REQUEST = "WORKFLOW_SAVE_REQUEST";
export const WORKFLOW_SAVE_FAIL = "WORKFLOW_SAVE_FAIL";
export const WORKFLOW_SAVE_SUCCESS = "WORKFLOW_SAVE_SUCCESS";


export const WORKFLOW_CLEAR = "WORKFLOW_CLEAR";

export const EXPDESIGN_WORKFLOW_SET_REQUEST = "EXPDESIGN_WORKFLOW_SET_REQUEST";
export const EXPDESIGN_WORKFLOW_SET_FAIL = "EXPDESIGN_WORKFLOW_SET_FAIL";
export const EXPDESIGN_WORKFLOW_SET_SUCCESS = "EXPDESIGN_WORKFLOW_SET_SUCCESS";
//Audit



//R Model interaction
export const EXPDESIGN_R_CALC_REQUEST = "EXPDESIGN_RCALC_REQUEST";
export const EXPDESIGN_R_CALC_FAIL = "EXPDESIGN_R_CALC_FAIL";
export const EXPDESIGN_R_CALC_SUCCESS = "EXPDESIGN_R_CALC_SUCCESS";


//Histogram request

export const EXPDESIGN_VARIABLE_PLOT_REQUEST = "EXPDESIGN_VARIABLE_PLOT_REQUEST";
export const EXPDESIGN_VARIABLE_PLOT_FAIL = "EXPDESIGN_VARIABLE_PLOT_FAIL";
export const EXPDESIGN_VARIABLE_PLOT_SUCCESS = "EXPDESIGN_VARIABLE_PLOT_SUCCESS";

//Stats request

export const EXPDESIGN_VARIABLE_STATS_REQUEST = "EXPDESIGN_VARIABLE_STATS_REQUEST";
export const EXPDESIGN_VARIABLE_STATS_FAIL = "EXPDESIGN_VARIABLE_STATS_FAIL";
export const EXPDESIGN_VARIABLE_STATS_SUCCESS = "EXPDESIGN_VARIABLE_STATS_SUCCESS";


// Clearing/setting expDesign/experiment
export const EXPDESIGN_SET_EXPERIMENT = "EXPDESIGN_SET_EXPERIMENT";

export const EXPDESIGN_EXPERIMENT_REQUEST = "EXPDESIGN_EXPERIMENT_REQUEST";
export const EXPDESIGN_EXPERIMENT_FAIL = "EXPDESIGN_EXPERIMENT_FAIL";
export const EXPDESIGN_EXPERIMENT_SUCCESS = "EXPDESIGN_EXPERIMENT_SUCCESS";

//Saving experiment do DB
export const EXPDESIGN_EXPERIMENT_SAVE_REQUEST = "EXPDESIGN_EXPERIMENT_SAVE_REQUEST";
export const EXPDESIGN_EXPERIMENT_SAVE_FAIL = "EXPDESIGN_EXPERIMENT_SAVE_FAIL";
export const EXPDESIGN_EXPERIMENT_SAVE_SUCCESS = "EXPDESIGN_EXPERIMENT_SAVE_SUCCESS";


//Tasks
export const TASK_LIST_REQUEST = "TASK_LIST_REQUEST";
export const TASK_LIST_FAIL = "TASK_LIST_FAIL";
export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS";

export const TASK_REQUEST = "TASK_REQUEST";
export const TASK_FAIL = "TASK_FAIL";
export const TASK_SUCCESS = "TASK_SUCCESS";



//Tools
export const TOOL_LIST_REQUEST = "TOOL_LIST_REQUEST";
export const TOOL_LIST_FAIL = "TOOL_LIST_FAIL";
export const TOOL_LIST_SUCCESS = "TOOL_LIST_SUCCESS";

export const TOOL_REQUEST = "TOOL_REQUEST";
export const TOOL_FAIL = "TOOL_FAIL";
export const TOOL_SUCCESS = "TOOL_SUCCESS";

//Statistical calculator
export const EXPDESIGN_CALCULATE_SS_REQUEST = "EXPDESIGN_CALCULATE_SS_REQUEST";
export const EXPDESIGN_CALCULATE_SS_FAIL = "EXPDESIGN_CALCULATE_SS_FAIL";
export const EXPDESIGN_CALCULATE_SS_SUCCESS = "EXPDESIGN_CALCULATE_SS_SUCCESS";

export const EXPDESIGN_PLOT_SS_REQUEST = "EXPDESIGN_PLOT_SS_REQUEST";
export const EXPDESIGN_PLOT_SS_FAIL = "EXPDESIGN_PLOT_SS_FAIL";
export const EXPDESIGN_PLOT_SS_SUCCESS = "EXPDESIGN_PLOT_SS_SUCCESS";

export const EXPDESIGN_SET_CALCULATOR_PARAMS = "EXPDESIGN_SET_CALCULATOR_PARAMS";


//TimeFrame settings
export const EXPDESIGN_SET_TIMEFRAME = "EXPDESIGN_SET_TIMEFRAME";


//Tablet game Experiment (special case)
export const TGAME_EXPERIMENT_RESULTS_REQUEST = "TGAME_EXPERIMENT_RESULTS_REQUEST";
export const TGAME_EXPERIMENT_RESULTS_FAIL = "TGAME_EXPERIMENT_RESULTS_FAIL";
export const TGAME_EXPERIMENT_RESULTS_SUCCESS = "TGAME_EXPERIMENT_RESULTS_SUCCESS";

//Tablet game image request
export const TGAME_IMAGE_REQUEST = "TGAME_IMAGE_REQUEST";
export const TGAME_IMAGE_FAIL = "TGAME_IMAGE_FAIL";
export const TGAME_IMAGE_SUCCESS = "TGAME_IMAGE_SUCCESS";
export const TGAME_IMAGE_CLEAR = "TGAME_IMAGE_CLEAR";


//Script execution
export const EXPDESIGN_EXECUTE_SCRIPT_REQUEST = "EXPDESIGN_EXECUTE_SCRIPT_REQUEST";
export const EXPDESIGN_EXECUTE_SCRIPT_FAIL = "EXPDESIGN_EXECUTE_SCRIPT_FAIL";
export const EXPDESIGN_EXECUTE_SCRIPT_SUCCESS = "EXPDESIGN_EXECUTE_SCRIPT_SUCCESS";

export const EXPDESIGN_CLEAR_SCRIPT_SUCCESS = "EXPDESIGN_CLEAR_SCRIPT_SUCCESS";