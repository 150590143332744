import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import BAParametersPanel from "../component/BAParametersPanel";



const mapStateToProps = (state,ownProps) => {
  return {
    percentageDifference: getSelectedPlotSettings(state).percentageDifference,
    aggregateCalculation: getSelectedPlotSettings(state).aggregateCalculation,
    missingContributionsStrategy: getSelectedPlotSettings(state).missingContributionsStrategy,
    levelAgreementValue: getSelectedPlotSettings(state).levelAgreementValue
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    }
  }
};

export const ContainerBAParametersPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(BAParametersPanel));