import React from "react";
import PropTypes from "prop-types";
import {Prompt, withRouter} from "react-router";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Button} from "primereact/components/button/Button";
import DownloadLink from "react-download-link";
import {convertFromAnnotationsTransferObject, convertToAnnotationsTransferObject} from "../../action/AnnotationAction";
import {FileInputButton} from "../../../root/component/FileInputButton";
import {
  EXPORT_ANNOTATIONS_TO_DISK_TOOL,
  EXPORT_ANNOTATIONS_TO_SERVER_TOOL,
  IMPORT_ANNOTATIONS_FROM_DISK_TOOL,
  PIN_TOOL, SPAWN_VIEWERS_WINDOW
} from "../../../../Constants";

/**
 * Toolbar for control components responsible for local saving/loading data.
 *
 *
 */
class ImportExportToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipDisabled: false
        };
        ['loadAnnotations','handleSave','saveAnnotations'].forEach(name => { this[name] = this[name].bind(this);});
    }

    handleSave  ()  {
        let element = document.getElementById('downloadButton');
        const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
        mouseClickEvents.forEach(mouseEventType =>
          element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
          )
        );
    };

    loadAnnotations(file){
        const {updateAnnotationData,updateActiveROI,annotationsDefinitionDocumentId,messageQueue}=this.props;
        const handleFileRead = (e) => {
            const {data,annotationTableDefinitionId,activeRoiId} = convertFromAnnotationsTransferObject(JSON.parse(fileReader.result.toString()));
            if (annotationsDefinitionDocumentId===annotationTableDefinitionId) {
                updateAnnotationData(data);
                if (activeRoiId != null)
                    updateActiveROI(activeRoiId);
            }
            else{
                messageQueue.show( {
                        sticky: false,
                        severity: 'error',
                        summary: 'Error',
                        detail:"The annotations cannot be loaded due to incorrect version of annotation table definition."
                    });
            }
        };
        let fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    }

    saveAnnotations(data){
      // Save annotatio to server
      const {saveAnnotationDataToServer, materializedTaskId, messageQueue}=this.props;
      
      saveAnnotationDataToServer(materializedTaskId, data);

    }

    render() {
        const {
            manualToolState, manualToolConfiguration,
            showWindowPortal,onChangeWindowPortal,annotationsData,annotationsDefinition, activeRoiId,annotationsDefinitionDocumentId
        } = this.props;


        const toolVisible = (toolKey) => (manualToolConfiguration != null && manualToolConfiguration[toolKey] != null)
            ? manualToolConfiguration[toolKey]['controlVisible'] : false;

        const extendedMenuVisible = (toolKey) =>
            manualToolState !=null
            && manualToolState[toolKey]!=null
            && manualToolState[toolKey][toolKey]; //check if menu should be visible (tool is "on")

        const checkIfAnyPropertyVisible = (tool)=>Object.keys(manualToolConfiguration[tool]['properties'])
            .find((el)=>{return manualToolConfiguration[tool]['properties'][el]['controlVisible']})!=null;

        return (
            <div style={{float:"right"}}>
                <Toolbar style={{display: "inline-block"}}>


                        {/*<Button onClick={()=>{saveOverlayData(painter.getLabelMap(),match.params.id)}}*/}
                        {/*icon="fa fa-save" title="Save image"/>*/}
                  {toolVisible(EXPORT_ANNOTATIONS_TO_DISK_TOOL) &&
                  <span title={"Save to file"}>
                        <DownloadLink
                          ref={this.download}
                          tagName={"i"}
                          className={"ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only fa fa-save"}
                          style={{color:"white"}}
                          label={""}
                          filename="Annotations.json"
                          exportFile={() => JSON.stringify(convertToAnnotationsTransferObject(annotationsData,activeRoiId,annotationsDefinition,annotationsDefinitionDocumentId))}  />
                  </span>}

                  {toolVisible(IMPORT_ANNOTATIONS_FROM_DISK_TOOL) &&
                  <span title={"Load annotations from your local disk"}>
                        <FileInputButton
                          style={{display:"inline-block"}}
                          label=""
                          filter="application/json"
                          onChange={e => this.loadAnnotations(e.target.files[0])}
                        />
                    </span>}
                  {toolVisible(EXPORT_ANNOTATIONS_TO_SERVER_TOOL) &&
                    <span title={"Save annotations to server"}>
                     <Button
                        onClick={()=>this.saveAnnotations(JSON.stringify(convertToAnnotationsTransferObject(annotationsData,activeRoiId,annotationsDefinition,annotationsDefinitionDocumentId)))}  >
                        Save annotations
                      </Button>
                    </span>}
                  {toolVisible(SPAWN_VIEWERS_WINDOW) &&
                  <Button onClick={()=>onChangeWindowPortal(!showWindowPortal)}
                          title={showWindowPortal?"Display viewers in the original window":"Display viewers in separate window"}
                          icon={showWindowPortal?"fa fa-window-maximize":"fa fa-window-restore"}/>}
                </Toolbar>
            </div>
        )
    }

}

export default withRouter(ImportExportToolbar);

ImportExportToolbar.defaultProps = {
};

ImportExportToolbar.propTypes = {
    manualToolState: PropTypes.object.isRequired,
    manualToolConfiguration: PropTypes.object.isRequired,//initial settigs of widgets
    showWindowPortal:PropTypes.bool.isRequired,
    onChangeWindowPortal:PropTypes.func.isRequired,
    annotationsData: PropTypes.object.isRequired, //annotation data
    updateAnnotationData:PropTypes.func.isRequired,
    saveAnnotationDataToServer:PropTypes.func.isRequired,
    updateActiveROI:PropTypes.func.isRequired,
    annotationsDefinition: PropTypes.object.isRequired, //annotation data
    annotationsDefinitionDocumentId:PropTypes.string.isRequired,
    activeRoiId: PropTypes.string.isRequired,
    messageQueue:PropTypes.object.isRequired //messaging bus
};
