import React from "react";
import {Button} from "primereact/components/button/Button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {Dialog} from "primereact/components/dialog/Dialog";
import {PIN_TOOL} from "../../../../Constants";
import {checkIfAnyPropertyIsVisible} from "./WidgetToolbar";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";
import {getNestedProp, setNestedProp} from "../../../helpers/expressions";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {
    animatedEllipse,
    ANNOTATION_SHAPES,
    checkeredCursor,
    circleCursor, rotatedEllipse,
    twoEllipses,
    whiskersCursor
} from "../../../helpers/canvasUtils";


/**
 * Stateful component with template for Pin Tool.
 *
 * See BrushToolbar
 * @returns {*}
 */
export class PinToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false
        };
    }

    render() {
        const {toolProperties, clearAll, changeToolProperties, toolConfiguration} = this.props;
        const control = (property, type) => toolConfiguration != null
            && toolConfiguration.properties != null
            && toolConfiguration.properties[property] != null
            && toolConfiguration.properties[property][type] != null
            && toolConfiguration.properties[property][type];

        const checkIfAnyPropertyVisible = checkIfAnyPropertyIsVisible(toolConfiguration.properties);

        return (
            <React.Fragment>
                {checkIfAnyPropertyVisible &&
                <Toolbar style={{display: "inline-block"}}>
                    {control('fontSize', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Font size:</label>
                        <Spinner value={toolProperties.fontSize}
                                 min={0} max={36} step={1} size={3}
                                 onChange={(e) => changeToolProperties(PIN_TOOL, 'fontSize', e.value)}
                        />
                    </React.Fragment>}
                    {control('markerSize', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Marker size:</label>
                        <Spinner value={toolProperties.markerSize}
                                 min={1} max={20} step={1} size={3}
                                 onChange={(e) => changeToolProperties(PIN_TOOL, 'markerSize', e.value)}
                        />
                    </React.Fragment>
                    }
                    {control('selectionOnly', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Selection only:</label>
                        <Checkbox checked={toolProperties['selectionOnly']}
                                 min={1} max={20} step={1} size={3}
                                 onChange={(e) => changeToolProperties(PIN_TOOL, 'selectionOnly', !toolProperties['selectionOnly'])}
                         />
                    </React.Fragment>
                    }
                    {control('clearAll', 'controlVisible') &&
                    <Button icon="fa fa-trash" label="Clear all" onClick={() => this.setState({dialogVisible: true})}/>
                    }
                    {control('activeColor', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Active:</label>
                                 <span title={getNestedProp(['activeColor'],toolProperties)}>
                    <ColorPicker value={getNestedProp(['activeColor'],toolProperties).substring(1)}
                                 disabled={!control('activeColor', 'controlEnabled')}
                                 onChange={(e) => changeToolProperties(PIN_TOOL, 'activeColor',"#".concat(e.value))}
                                 id={"activeColorPicker"}/>
                                 </span>
                    </React.Fragment>
                    }
                    {control('normalColor', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Normal:</label>
                        <span title={getNestedProp(['normalColor'],toolProperties)}>
                        <ColorPicker value={getNestedProp(['normalColor'],toolProperties).substring(1)}
                                     disabled={!control('normalColor', 'controlEnabled')}
                                     onChange={(e) => changeToolProperties(PIN_TOOL, 'normalColor',"#".concat(e.value))}
                                     id={"normalColorPicker"}/>
                        </span>
                    </React.Fragment>
                    }
                    {control('activeProjectionColor', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Active projection:</label>
                        <span title={getNestedProp(['activeProjectionColor'],toolProperties)}>
                        <ColorPicker value={getNestedProp(['activeProjectionColor'],toolProperties).substring(1)}
                                     disabled={!control('activeProjectionColor', 'controlEnabled')}
                                     onChange={(e) => changeToolProperties(PIN_TOOL, 'activeProjectionColor',"#".concat(e.value))}
                                     id={"activeProjectionColorPicker"}/>
                        </span>
                    </React.Fragment>
                    }
                    {control('normalProjectionColor', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Normal projection:</label>
                        <span title={getNestedProp(['normalProjectionColor'],toolProperties)}>
                        <ColorPicker value={getNestedProp(['normalProjectionColor'],toolProperties).substring(1)}
                                     disabled={!control('normalProjectionColor', 'controlEnabled')}
                                     onChange={(e) => changeToolProperties(PIN_TOOL, 'normalProjectionColor',"#".concat(e.value))}
                                     id={"normalProjectionColorPicker"}/>
                        </span>
                    </React.Fragment>
                    }
                    {control('regularShape', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Shape:</label>
                        <span title={"Shape of regular annotation"}>
                        <Dropdown
                          value={toolProperties['regularShape']}
                          options={ANNOTATION_SHAPES}
                          onChange={(e) => changeToolProperties(PIN_TOOL, 'regularShape',e.value)}
                        />
                        </span>
                    </React.Fragment>
                    }
                    {control('subShape', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>SubShape:</label>
                        <span title={"Shape of sub annotation"}>
                        <Dropdown
                          value={toolProperties['subShape']}
                          options={ANNOTATION_SHAPES}
                          onChange={(e) => changeToolProperties(PIN_TOOL, 'subShape',e.value)}
                        />
                        </span>
                    </React.Fragment>
                    }
                    <Dialog header="Confirmation Dialog"
                            visible={this.state.dialogVisible}
                            width="350px"
                            modal={true}
                            onHide={() => this.setState({dialogVisible: false})}
                            style={{textAlign: "center"}}
                    >
                        Are you sure that you want to remove all annotations?<br/>
                        This change is irrevertible!<br/>
                        <Button icon="fa fa-check" label="OK" onClick={() => {
                            this.setState({dialogVisible: false});
                            clearAll();
                        }
                        }/>
                        <Button icon="fa fa-close" label="Cancel"
                                onClick={() => this.setState({dialogVisible: false})}/>
                    </Dialog>
                </Toolbar>}
            </React.Fragment>
        );
    }

};

PinToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    toolConfiguration: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired
};
