import React from "react";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {Card} from "primereact/components/card/Card";
import {Button} from 'primereact/components/button/Button';
import { withTranslation } from 'react-i18next'; // High order translation component

/**
 * List available events (current and previous) inside SPINE
 * @returns {*}
 */
class EventsPage extends React.Component {
  
  constructor(props){
    super(props);

    // Set the state
    this.state = {};

    // Bind functions
    this.renderEvents = this.renderEvents.bind(this);
  }

  componentWillMount() {
    const {getEvents} = this.props;
    getEvents((error)=>{
      this.props.messageQueue.show(
        {severity: 'error',
        summary: 'Error', detail: error});
    });
  }

  renderEvents(events){
    const { t, eventLink='/event/' } = this.props;
    const dinamycCardStyle = {width: "85%", height: "20em", margin: "4.5em", marginTop:"2em", fontSize: "1.2em"};
    const dinamycTextStyle = {height: "15em", overflowY: "auto"};
    const dinamycTextStyleButtons = {textAlign: "right", height: "3em", overflowY: "auto"};

    const dynamicHeader = (image, className, subtitle)=><div className={className}>
      <div style={{width: "100%",display: "inline-block", padding:"1em"}}>
          <img src={image} style={{height:"11em",objectFit:"contain", border: "#D5D5D5 1px solid"}}/>
      </div>
      <div className="ui-card-subtitle" style={{padding: "1em"}}>
          {subtitle}
      </div>
    </div>;

    return events.map(event => {
      const openingDate = new Date(event.openingDate);
      const openingDateString = openingDate.toDateString();
      const closingDate = new Date(event.closingDate);
      const closingDateString = closingDate.toDateString();
      const eventPlace = event.place? `| ${event.place}`: '';

      const eventImage = event.imageUrl || '../dist/icon-completed.png';
      return <Card
              header={dynamicHeader(eventImage, "ui-g-4", `${openingDateString} - ${closingDateString} ${eventPlace}`)}
              style={dinamycCardStyle}
             >
              <div style={dinamycTextStyle}>
              <div style={{width: "100%",display: "inline-block", textAlign:"center",padding:"1em"}}>
                  <h1 style={{display: "inline-block",margin:"auto"}}>{event.name}</h1>
              </div>
                <div dangerouslySetInnerHTML={{__html: event.description}}/>
                {event.externalUrl?
                  <p>
                    <a href={event.externalUrl} target="_blank">{event.externalUrl} <i className="fa fa-external-link"/></a>
                  </p>
                 : null}
              </div>
              <div style={dinamycTextStyleButtons}>
                <Link to={`${eventLink}${event.uuid}`}>
                  <Button
                    label={t("gotoevent.label")}
                    title={t("gotoevent.title")}
                  />
                </Link>
              </div>
            </Card>;
    })
  }

  render() {
    const { t } = this.props;

    const currentEvents = this.props.events.filter(event=>{
      const eventDate = new Date(event.closingDate);
      const todayDate = new Date(); 
      return eventDate >= todayDate;
    });

    const previousEvents = this.props.events.filter(event=>{
      const eventDate = new Date(event.closingDate);
      const todayDate = new Date(); 
      return eventDate < todayDate;
    });

    return (
      <React.Fragment>
        {currentEvents.length > 0?
        <React.Fragment>
          <div style={{
          marginTop: "3em",
          marginLeft: "6em"
          }}>
            <span className="reg-headtitle">{t("events.current.header")}</span>
          </div>

          <div style={{
            flexDirection: "row",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start"
          }}>
            {this.renderEvents(currentEvents)}
          </div>
        </React.Fragment>
        :<div></div>}

        {previousEvents.length > 0?
        <React.Fragment>
        <div style={{
          marginTop: "3em",
          marginLeft: "6em"
        }}>
          <span className="reg-headtitle">{t("events.previous.header")}</span>
        </div>

        <div style={{
          flexDirection: "row",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start"
        }}>
          {this.renderEvents(previousEvents)}
        </div>
        </React.Fragment>: <div></div>}
      </React.Fragment>
    );
  }
};

export default withTranslation()(EventsPage);
EventsPage.propTypes = {
  getEvents: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
}