import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import SeriesContributionsPanel from "../component/SeriesContributionsPanel";
import {
  getSelectedContributionsFilteredByMeasurement,
} from "../selectors/ContributionsSelectors";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    contributions: getSelectedContributionsFilteredByMeasurement(state)
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    }
  }
};

export const ContainerSelectedContributionsFilteredByMeasurementPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SeriesContributionsPanel));