import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getSelectedModelParameters, getSelectedPlotData} from "../selectors/SAMSelectors";
import GLMStatsPanel from "../component/GLMStatsPanel";



const mapStateToProps = (state) => {
  return {
    modelParameters: getSelectedModelParameters(state),
    plotData: getSelectedPlotData(state)
  }
};

const mapDispatchToProps = () => {
  return {
  }
};

export const ContainerGLMStatsPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(GLMStatsPanel));