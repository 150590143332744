import macro from 'vtk.js/Sources/macro';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';
import {createOffsets} from "../processing/VoxelProcessing";
import { vec3 } from "gl-matrix";
import vtkPolydata from 'vtk.js/Sources/Common/DataModel/PolyData';
import { SlicingMode } from 'vtk.js/Sources/Rendering/Core/ImageMapper/Constants';
const { vtkErrorMacro } = macro;

// ----------------------------------------------------------------------------
// vtkBrushTracing methods
// Filter to trace a points based only on spatial relationship.
// As output it uses polydata with lines creating contour.
// ----------------------------------------------------------------------------

function vtkBrushTracing(publicAPI, model) {
  model.classHierarchy.push('vtkBrushTracing');


  function getIndex(point, dims) {
    return point[0] + point[1] * dims[0] + point[2] * dims[0] * dims[1];
  }

  function checkIfWithinExtent(off,dims){
    return  off[0] >= 0 &&  off[1] >= 0 &&  off[2] >= 0 &&  off[0] <= dims[0] - 1 &&  off[1] <= dims[1] - 1 &&  off[2] <= dims[2] - 1;
  }

  function createMaskWithOffset(voxel,offset){
    return [voxel[0] + offset[0], voxel[1] + offset[1], voxel[2] + offset[2]];
  }

  function sumVec(vec1,vec2){
    return [vec1[0] + vec2[0], vec1[1] + vec2[1], vec1[2] + vec2[2]];
  }

  publicAPI.requestData = (inData, outData) => {

    // ------------------ Input ------------------------------------------------------
    const input = inData[0];
    if (!input || input.getClassName() !== 'vtkImageData') {
      vtkErrorMacro('Invalid or missing input');
      return;
    }
    const dims = input.getDimensions();
    const inputDataArray = input
      .getPointData()
      .getScalars()
      .getData();


    // ------------------ Output definition------------------------------------------------------
    const output = vtkPolydata.newInstance();
    const vertexArray = [];
    const linesArray = [];
    const polysArray = [];
    //--------------------


    // calculate corrections related to spreading points grid to extreme values in Imaga Data indexToWorld function
    const correctionI = (dims[0] - 1) / dims[0];
    const correctionJ = (dims[1] - 1) / dims[1] ;
    const correctionK = (dims[2] - 1) / dims[2] ;

    const indexToWorldOrigin = (ijk) => {
      const vout = [0, 0, 0];
      vec3.transformMat4(
        vout,
        [ijk[0] * correctionI, ijk[1] * correctionJ, ijk[2] * correctionK],
        input.getIndexToWorld()
      );
      return vout;
    };

    const threshold = inputDataArray[getIndex(model.seedPoint, dims)];
    const getValueAtPixel = (vec3) => inputDataArray[getIndex(vec3, dims)];


    const offsets = createOffsets(model.slicingMode, model.radius,model.shape);

    /**
     * Calculate correction in direction of camera - Attract point to voxel grid.
     * If orientation is Right, Superior or Posterior (in any slicing mode ijk) then the points calculated
     * for outline needs to be translated to the preceding (or following) slice.
     * It is always translated into camera direction for the length of 1 voxel face (spacing) -
     * it would be enough to use smaller value, but the required small value is changing depending on slice number and we
     * don't need to do much calculations). More details in calcPoints function jsdoc.
     * @return {number}
     */
    const getDirectionCorrection =()=> {
      const rowIndex = model.slicingMode === SlicingMode.I
        ? 0
        : model.slicingMode === SlicingMode.J
          ? 3 : 6;
      const row = input.getDirection().slice(rowIndex,rowIndex+3); // get 3-element subarray for I or J or K only
      const indexOfMax =  row.reduce((iMax, x, i, arr) => Math.abs(x) > Math.abs(arr[iMax]) ? i : iMax, 0); // find index with maximum absolute value, iMax - accumulator of index value, 0 - initial index value
      if ((indexOfMax === 0 && row[indexOfMax]>0)|| (indexOfMax === 1 && row[indexOfMax]<0) || (indexOfMax === 2 && row[indexOfMax]<0))
        return input.getSpacing()[model.slicingMode] * 1;
      else return 0;
    };


    // *************************************************************************
    // Below can be added any other filtering criteria (eg. thresholding filter)
    // .filter(whatever)
    // *************************************************************************
    const maskPoints = offsets
      .map(offset=>{return createMaskWithOffset(model.seedPoint,offset)})  // generate n-by-n mask in real coordinates
      .filter(p=>checkIfWithinExtent(p,dims));                             // remove from mask points outside range

    let hash = {};
    for(let i = 0 ; i < maskPoints.length; i++) {
      hash[maskPoints[i]] = i;
    }

    const BC = 1;

    const calcPoints = (or, tr) => {   // this is
      if (model.slicingMode === SlicingMode.K) {
        const translatedK = or[2]+getDirectionCorrection(); // attract into camera direction if needed
        if (tr[0] !== 0) {
          if (tr[0] > 0) {
              return {
                m1: indexToWorldOrigin([or[0] + BC, or[1] , translatedK]),
                m2: indexToWorldOrigin([or[0] + BC, or[1] + 1, translatedK]),
              };
          }
          return {
              m1: indexToWorldOrigin([or[0], or[1] , translatedK]),
              m2: indexToWorldOrigin([or[0], or[1] + 1, translatedK]),
            };
        }
        if (tr[1] !== 0) {
          if (tr[1] > 0) {
            return {
                m1: indexToWorldOrigin([or[0] , or[1] + BC, translatedK]),
                m2: indexToWorldOrigin([or[0] + 1, or[1] + BC, translatedK]),
              };
          }
          return {
              m1: indexToWorldOrigin([or[0] , or[1], translatedK]),
              m2: indexToWorldOrigin([or[0] + 1, or[1], translatedK]),
            };
        }
      }
      if (model.slicingMode === SlicingMode.I) {
        const translatedI = or[0]+ getDirectionCorrection();  // attract into camera direction if needed
        if (tr[2] !== 0) {
          if (tr[2] > 0) {

              return {
                m1: indexToWorldOrigin([translatedI, or[1] , or[2] + BC]),
                m2: indexToWorldOrigin([translatedI, or[1] + 1, or[2] + BC]),
              };

          }
            return {
              m1: indexToWorldOrigin([translatedI, or[1] , or[2]]),
              m2: indexToWorldOrigin([translatedI, or[1] + 1, or[2]]),
            };

        }
        if (tr[1] !== 0) {
          if (tr[1] > 0) {

              return {
                m1: indexToWorldOrigin([translatedI, or[1] + BC, or[2] ]),
                m2: indexToWorldOrigin([translatedI, or[1] + BC, or[2] + 1]),
              };
          }

            return {
              m1: indexToWorldOrigin([translatedI, or[1], or[2] ]),
              m2: indexToWorldOrigin([translatedI, or[1], or[2] + 1]),
            };
        }
      }
      if (model.slicingMode === SlicingMode.J) {
        const translatedJ = or[1]+getDirectionCorrection(); // attract into camera direction if needed
        if (tr[2] !== 0) {
          if (tr[2] > 0) {
              return {
                m1: indexToWorldOrigin([or[0] , translatedJ, or[2] + BC]),
                m2: indexToWorldOrigin([or[0] + 1, translatedJ, or[2] + BC]),
              };
          }

            return {
              m1: indexToWorldOrigin([or[0] , translatedJ, or[2]]),
              m2: indexToWorldOrigin([or[0] + 1, translatedJ, or[2]]),
            };
        }
        if (tr[0] !== 0) {
          if (tr[0] > 0) {
              return {
                m1: indexToWorldOrigin([or[0] + BC, translatedJ, or[2] ]),
                m2: indexToWorldOrigin([or[0] + BC, translatedJ, or[2] + 1]),
              };
          }

            return {
              m1: indexToWorldOrigin([or[0], translatedJ, or[2] ]),
              m2: indexToWorldOrigin([or[0], translatedJ, or[2] + 1]),
            };
        }
      }
      return { m1: [0, 0, 0], m2: [0, 0, 0] };
    };


    /**
     * This function is used to render line between to points. For better performance, only displaying lines is available.
     * (OutlineMode.LINES). To see how implement with polygons (OutlineMode.POLYGON) check ImageOutlinePoly class.
     * @param translation
     * @param ijk
     */
    const addBorder = (translation,ijk) => {
      const { m1, m2 } = calcPoints(ijk, translation);
      vertexArray.push(...m1);
      vertexArray.push(...m2);
      linesArray.push(     //  see ImageOulinePoly to implementation with polygons
        1000000000,
        vertexArray.length / 3 - 2,
        vertexArray.length / 3 - 1
      );
    };

    for (let i=0;i<maskPoints.length;i++){
      if (model.slicingMode === SlicingMode.K) {
        if(!hash.hasOwnProperty(sumVec([-1,0,0],maskPoints[i])))
          addBorder([-1, 0, 0],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([1,0,0],maskPoints[i])))
          addBorder([1, 0, 0],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([0,-1,0],maskPoints[i])))
          addBorder([0, -1, 0],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([0,1,0],maskPoints[i])))
          addBorder([0, 1, 0],maskPoints[i]);
      }
      if (model.slicingMode === SlicingMode.I) {
        if(!hash.hasOwnProperty(sumVec([0, 0, -1],maskPoints[i])))
          addBorder([0, 0, -1],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([0, 0, 1],maskPoints[i])))
          addBorder([0, 0, 1],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([0, -1, 0],maskPoints[i])))
          addBorder([0, -1, 0],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([0, 1, 0],maskPoints[i])))
        addBorder([0, 1, 0],maskPoints[i]);
      }
      if (model.slicingMode === SlicingMode.J) {
        if(!hash.hasOwnProperty(sumVec([0, 0, -1],maskPoints[i])))
          addBorder([0, 0, -1],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([0, 0, 1],maskPoints[i])))
          addBorder([0, 0, 1],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([-1, 0, 0],maskPoints[i])))
          addBorder([-1, 0, 0],maskPoints[i]);
        if(!hash.hasOwnProperty(sumVec([1, 0, 0],maskPoints[i])))
          addBorder([1, 0, 0],maskPoints[i]);
      }

    }

    output.getPoints().setData(Float32Array.from(vertexArray), 3);
    output.getLines().setData(Uint16Array.from(linesArray));
    output.getPolys().setData(Uint16Array.from(polysArray));
    outData[0] = output;
  };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  slicingMode: 2,
  background: 0,
  seedPoint: [0, 0, 0],
  label: 1,
  levelFunction: (a, b) => a <= b,  // function to use in connected components
  radius:1,
  shape:'square'
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Make this a VTK object
  macro.obj(publicAPI, model);

  // Also make it an algorithm with one input and one output
  macro.algo(publicAPI, model, 1, 1);

  macro.setGet(publicAPI, model, [
    'slicingMode',
    'background',
    'seedPoint',
    'label',
    'levelFunction',
    'radius',
    'shape'
  ]);

  // Object specific methods
  vtkBrushTracing(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  'vtkBrushTracing'
);

// ----------------------------------------------------------------------------

export default { newInstance, extend };
