import {
	USER_LIST_REQUEST,
	USER_LIST_FAIL,
	USER_LIST_SUCCESS
} from '../action/actionType'

export const userListReducer = (state = { userListState: '', users: [] }, action) => {
	// console.log("userlistreducer", action);
	switch (action.type) {
		case USER_LIST_REQUEST:
			return Object.assign({},state, {
        		userListState: 'requested'
      });
		case USER_LIST_FAIL:
			return Object.assign({},state, {
			    userListState: 'fail'
			});
		case USER_LIST_SUCCESS:
			return Object.assign({}, state, {
				userListState: 'success',
				users: action.users
			});
		default:
			return state;
	}
};