import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {ToggleButton} from "primereact/components/togglebutton/ToggleButton";
import {BrushToolbar} from "./BrushToolbar";
import {PinToolbar} from "./PinToolbar";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {ProjectionToolbar} from "./ProjectionToolbar";
import {Button} from "primereact/components/button/Button";
import {
  ANNOTATION_OPACITY_TOOL,
  BRUSH_TOOL,
  CROSS_HAIR_TOOL,
  ERASER_TOOL,
  FILLING_TOOL,
  LABELMAP_LUT_TOOL,
  LABELMAP_OPACITY_TOOL,
  LABELMAP_UNDO_REDO_TOOL,
  LINK_ALL_TOOL,
  MAIN_TOOL,
  MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE,
  POINTER_TOOL,
  PIN_TOOL,
  PROJECTION_TOOL,
  CUSTOM_CURSOR_TOOL,
  FIDUCIAL_TOOL,
  ECHO_TOOL,
  SMOOTHING_ALL_TOOL,
  CASE_PROGRESS_TOOL,
  PIN_UNDO_REDO_TOOL,
  WINDOW_LEVEL__ALL_TOOL,
  INVERSION_TOOL,
  SLICE_POSITION_INDEX,
  INTENSITY_FILTER_TOOL,
  SAMPLER_TOOL,
  LEVEL_TRACING_TOOL,
  DRAW_TOOL,
  INFO_TOOL,
  HIDE_LABELMAPS_TOOL,
  MAIN_TOOL_PROPERTY_NAME_SEGMENTATIONS_VISIBLE, RULER_TOOL, ROI_TABLE_TOOL, SEGMENTATION_METRICS_TOOL, CAMERA_3D_TOOL
} from "../../../../Constants";
import {EraserToolbar} from "./EraserToolbar";
import {FillingToolbar} from "./FillingToolbar";
import {ColorDropdown} from "./ColorDropdown";
import {CustomCursorToolbar} from "./CustomCursorToolbar";
import {PointerToolbar} from "./PointerToolbar";
import {FiducialToolbar} from "./FiducialToolbar";
import {InversionToolbar} from "./InversionToolbar";
import {ContainerSlicePositionWidget} from "../../containers/ContainerSlicePositionWidget";
import {InputText} from "primereact/components/inputtext/InputText";
import {IntensityFilterToolbar} from "./IntensityFilterToolbar";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DataProbeOutputPanel} from "./DataProbeOutputPanel";
import {LevelTracingToolbar} from "./LevelTracingToolbar";
import {updateManualToolProperty} from "../../action/ManualToolAction";
import {areAllViewersSmoothed, setAllViewersSmoothing} from "../../../vtk/SmoothingAllTool";
import {Spinner} from "primereact/components/spinner/Spinner";
import {ContainerSegmentationMetricsWidget} from "../../containers/ContainerSegmentationMetricsWidget";

/**
 * Helper function to check if there are visible properties to display
 * Use: in toolbars for tools, eg. PinToolbar, PointerToolbar, etc
 * @param props - widget configuration
 */
export const checkIfAnyPropertyIsVisible = (props) => {
  if (props != null)
    return Object.keys(props)
      .find((el) => {
        return props[el]['controlVisible'] === true
      }) != null;
  else
    return false;
};

/**
 * Widget Toolbar component.
 *
 */
class WidgetToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipDisabled: false
    };
    ["setLeftButtonMode"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  /**
   * This is wrapper function to provide logic for exclusive buttons handling switching between tools associated
   * with left button.
   * Set of exclusive buttons can be in only 2 states:
   * - none selected,
   * - one selected
   * @param e
   */
  setLeftButtonMode(e) {
    const {updateManualToolProperty} = this.props;
    const {leftButtonMode} = this.props.manualToolState[MAIN_TOOL];
    if (e !== leftButtonMode) {
      updateManualToolProperty(MAIN_TOOL, MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE, e);
    } else {
      updateManualToolProperty(MAIN_TOOL, MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE, LEFT_BUTTON_MODE.NONE);
    }
  }


  render() {
    const {
      activeColor, activeColormap, viewersState, updateViewerProperty,
      colorOptions, clearAll,
      manualToolState, manualToolConfiguration, updateCameraSynchronization,
      updateManualToolProperty, manualTask, undoPin, redoPin, scenes, updateActiveColorInScene, activePainter, invertColorsInOverlay
    } = this.props;

    const {activeViewerId} = this.props.manualToolState[MAIN_TOOL];
    const {leftButtonMode} = this.props.manualToolState[MAIN_TOOL];


    const toolVisible = (toolKey) => (manualToolConfiguration != null && manualToolConfiguration[toolKey] != null)
      ? manualToolConfiguration[toolKey]['controlVisible'] : false;

    const extendedMenuVisible = (toolKey) =>
      manualToolState != null
      && manualToolState[toolKey] != null
      && manualToolState[toolKey][toolKey]; //check if menu should be visible (tool is "on")

    const checkIfAnyPropertyVisible = (tool) => manualToolConfiguration[tool]['properties'] && Object.keys(manualToolConfiguration[tool]['properties'])
      .find((el) => {
        return manualToolConfiguration[tool]['properties'][el]['controlVisible']
      }) != null;

    const extendedToolbarSeparatorVisible =
      (leftButtonMode === LEFT_BUTTON_MODE.BRUSH && manualToolConfiguration[BRUSH_TOOL] != null && checkIfAnyPropertyVisible(BRUSH_TOOL))
      || (leftButtonMode === LEFT_BUTTON_MODE.PIN && manualToolConfiguration[PIN_TOOL] != null && checkIfAnyPropertyVisible(PIN_TOOL))
      || (leftButtonMode === LEFT_BUTTON_MODE.FILLING && manualToolConfiguration[BRUSH_TOOL] != null && checkIfAnyPropertyVisible(FILLING_TOOL))
      || (leftButtonMode === LEFT_BUTTON_MODE.ERASER && manualToolConfiguration[ERASER_TOOL] != null && checkIfAnyPropertyVisible(ERASER_TOOL));

    const shouldToolSeparatorBeVisible = manualToolConfiguration != null &&
      (
        (manualToolConfiguration['pointerTool'] != null && manualToolConfiguration['pointerTool']['controlVisible'])
        || (manualToolConfiguration['projectionTool'] != null && manualToolConfiguration['projectionTool']['controlVisible'])
        || (manualToolConfiguration['linkAllTool'] != null && manualToolConfiguration['linkAllTool']['controlVisible'])
        || (manualToolConfiguration['smoothingAllTool'] != null && manualToolConfiguration['smoothingAllTool']['controlVisible'])
        || (manualToolConfiguration['crossHairTool'] != null && manualToolConfiguration['crossHairTool']['controlVisible'])
        || (manualToolConfiguration['annotationOpacity'] != null && manualToolConfiguration['annotationOpacity']['controlVisible'])
        || (manualToolConfiguration['labelmapOpacity'] != null && manualToolConfiguration['labelmapOpacity']['controlVisible']
          || (manualToolConfiguration['inversionTool'] != null && manualToolConfiguration['inversionTool']['inversionTool'])
        )
      );


    const calculateCases = (status) => {
      let all = "NA";
      let statused = 0;
      try {
        all = manualTask.listOfCases.length;
        statused = manualTask.listOfCases.map((el) => {
          if (el.caseStatus.toUpperCase() === status)
            return 1;
          else
            return 0;
        }).reduce((prev, current) => {
          return prev + current;
        });
      } catch (e) {

      }

      return {allCases: all, statused: statused}
    };

    return (
      <React.Fragment>
        <Toolbar style={{display: "inline-block"}}>
          {toolVisible(PIN_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="pinToggleButton"
              checked={leftButtonMode === LEFT_BUTTON_MODE.PIN}
              onIcon="fa fa-map-pin" offIcon="fa fa-map-pin"
              onLabel="" offLabel=""
              iconPos="right" tooltip="Annotator"
              onChange={() => this.setLeftButtonMode(LEFT_BUTTON_MODE.PIN)}/>
            <Tooltip
              for="#pinToggleButton"
              escape={false}
              title={"<strong>Pin</strong>  \n Pin annotation on mouse click."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(BRUSH_TOOL) &&
          <React.Fragment>
            <ToggleButton id="brushToggleButton"
                          checked={leftButtonMode === LEFT_BUTTON_MODE.BRUSH}
                          onIcon="fa fa-paint-brush" offIcon="fa fa-paint-brush"
                          onLabel="" offLabel=""
                          iconPos="right" tooltip="Enter your username" tooltipOptions={{position: 'right'}}
                          onChange={() => this.setLeftButtonMode(LEFT_BUTTON_MODE.BRUSH)}/>
            <Tooltip
              for="#brushToggleButton"
              escape={false}
              title={"<strong>Brush</strong>  \n Draw on mouse click."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(DRAW_TOOL) &&
          <React.Fragment>
            <ToggleButton id="drawToggleButton"
                          checked={leftButtonMode === LEFT_BUTTON_MODE.DRAW}
                          onIcon="fa fa-pencil" offIcon="fa fa-pencil"
                          onLabel="" offLabel=""
                          iconPos="right" tooltipOptions={{position: 'right'}}
                          onChange={() => this.setLeftButtonMode(LEFT_BUTTON_MODE.DRAW)}/>
            <Tooltip
              for="#drawToggleButton"
              escape={false}
              title={"<strong>Draw</strong>  \n Draw areas on mouse move and left click. Close area with mouse right click."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(FILLING_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="fillingToggleButton"
              checked={leftButtonMode === LEFT_BUTTON_MODE.FILLING}
              onIcon="fa fa-flask" offIcon="fa fa-flask"
              onLabel="" offLabel=""
              iconPos="right" tooltip="Filling tool"
              onChange={() => this.setLeftButtonMode(LEFT_BUTTON_MODE.FILLING)}
            /> <Tooltip
            for="#fillingToggleButton"
            escape={false}
            title={"<strong>Fill</strong>  \n Fill closed area on mouse click."}
            tooltipDisabled={this.state.tooltipDisabled}
          />
          </React.Fragment>
          }
          {toolVisible(ERASER_TOOL) &&
          <React.Fragment>
            <ToggleButton id="eraserToggleButton"
                          checked={leftButtonMode === LEFT_BUTTON_MODE.ERASER}
                          onIcon="fa fa-eraser" offIcon="fa fa-eraser"
                          onLabel="" offLabel=""
                          iconPos="right" tooltip="Eraser"
                          onChange={() => this.setLeftButtonMode(LEFT_BUTTON_MODE.ERASER)}/>
            <Tooltip
              for="#eraserToggleButton"
              escape={false}
              title={"<strong>Erase</strong>  \n Erase drawing on mouse click."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(LEVEL_TRACING_TOOL) &&
          <React.Fragment>
            <ToggleButton id="levelTracingToggleButton"
                          checked={leftButtonMode === LEFT_BUTTON_MODE.LEVEL}
                          onIcon="fa fa-square-o" offIcon="fa fa-square-o"
                          onLabel="" offLabel=""
                          iconPos="right" tooltip="Level Tracing"
                          onChange={(e) => {
                            this.setLeftButtonMode(LEFT_BUTTON_MODE.LEVEL);
                            if (activeViewerId!=null && !e.value){
                              const oldActor = viewersState[activeViewerId].renderer.getActors().find((el)=>el.get().levelTracingTool === true);
                            if (oldActor!=null) {
                              viewersState[activeViewerId].renderer.removeActor(oldActor);
                              viewersState[activeViewerId].renderer.getRenderWindow().render();

                            }
                            }
                          }}/>
            <Tooltip
              for="#levelTracingToggleButton"
              escape={false}
              title={"<strong>Level Tracing Effect</strong>  \n Trace intensity level and draw on mouse click."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(SAMPLER_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="samplerToolToggleButton"
              checked={leftButtonMode === LEFT_BUTTON_MODE.SAMPLER}
              onLabel="" offLabel=""
              onChange={() =>
                this.setLeftButtonMode(LEFT_BUTTON_MODE.SAMPLER)
              }
              onIcon="fa fa-eyedropper " offIcon="fa fa-eyedropper"/>
            <Tooltip
              for="#samplerToolToggleButton"
              title={"<strong>Data probe</strong>  \n Calculate stats and determine threshold for a selected region."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(LABELMAP_UNDO_REDO_TOOL) &&
          <React.Fragment>
            <Button id="undoButton"
                    icon="fa fa-undo" iconPos="right" title="Undo"
                    onClick={() => {
                      if (activePainter != null) activePainter.undo();
                    }}/>
            <Tooltip
              for="#undoButton"
              escape={false}
              title={"<strong>Undo</strong>  \n Undo last operation on drawing."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
            <Button id="redoButton"
                    icon="fa fa-repeat" iconPos="right" title="Redo"
                    onClick={() => {
                      if (activePainter != null) activePainter.redo();
                    }}/>
            <Tooltip
              for="#redoButton"
              escape={false}
              title={"<strong>Redo</strong>  \n Redo reverted operation on drawing."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(PIN_UNDO_REDO_TOOL) &&
          <React.Fragment>
            <Button id="undoButtonPin"
                    icon="fa fa-undo" iconPos="right" title="Undo"
                    onClick={() => undoPin()}/>
            <Tooltip
              for="#undoButtonPin"
              escape={false}
              title={"<strong>Undo</strong>  \n Undo last operation on pin."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
            <Button id="redoButtonPin"
                    icon="fa fa-repeat" iconPos="right" title="Redo"
                    onClick={() => redoPin()}/>
            <Tooltip
              for="#redoButtonPin"
              escape={false}
              title={"<strong>Redo</strong>  \n Redo reverted operation on pin."}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {shouldToolSeparatorBeVisible &&
          <i className="fa fa-bars ui-toolbar-separator" style={{marginRight: '.25em'}}/>
          }
          {toolVisible(POINTER_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="pickerToggleButton"
              checked={manualToolState[POINTER_TOOL][POINTER_TOOL]}
              onIcon="fa fa-hand-o-up"
              onLabel="" offLabel=""
              offIcon="fa fa-hand-o-up"
              iconPos="right"
              onChange={(e) => updateManualToolProperty(POINTER_TOOL, POINTER_TOOL, e.value)}/>
            <Tooltip
              for="#pickerToggleButton"
              title={"<strong>Picker</strong>  \n Intersect sections at picked voxel."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(PROJECTION_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="projectionToggleButton"
              checked={manualToolState[PROJECTION_TOOL][PROJECTION_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => updateManualToolProperty(PROJECTION_TOOL, PROJECTION_TOOL, e.value)}
              onIcon="fa fa-snapchat-ghost " offIcon="fa fa-snapchat-ghost "/>
            <Tooltip
              for="#projectionToggleButton"
              title={"<strong>Projection</strong>  \n Project annotations from neihgboring slices."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}/>
          </React.Fragment>
          }
          {toolVisible(CROSS_HAIR_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="rulersToggleButton"
              checked={manualToolState[CROSS_HAIR_TOOL][CROSS_HAIR_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => updateManualToolProperty(CROSS_HAIR_TOOL, CROSS_HAIR_TOOL, e.value)}
              onIcon="fa fa-crosshairs" offIcon="fa fa-crosshairs"/>
            <Tooltip
              for="#rulersToggleButton"
              title={"<strong>Cross-hair</strong>  \n Show relative position of section."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}/>
          </React.Fragment>
          }
          {toolVisible(ECHO_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="echoToggleButton"
              checked={manualToolState[ECHO_TOOL][ECHO_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => updateManualToolProperty(ECHO_TOOL, ECHO_TOOL, e.value)}
              onIcon="fa fa-cubes" offIcon="fa fa-cubes"/>
            <Tooltip
              for="#echoToggleButton"
              title={"<strong>Cursor echo</strong>  \n Projection of cursor on remaining viewers."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}/>
          </React.Fragment>
          }
          {toolVisible(INVERSION_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="inversionToggleButton"
              checked={manualToolState[INVERSION_TOOL][INVERSION_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => updateManualToolProperty(INVERSION_TOOL, INVERSION_TOOL, e.value)}
              onIcon="fa fa-exchange" offIcon="fa fa-exchange"/>
            <Tooltip
              for="#inversionToggleButton"
              title={"<strong>Inversion Tool</strong>  \n Switch labelmap value with background."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}/>
          </React.Fragment>
          }
          {toolVisible(INFO_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="infoToggleButton"
              checked={manualToolState[INFO_TOOL][INFO_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => updateManualToolProperty(INFO_TOOL, INFO_TOOL, e.value)}
              onIcon="fa fa-info-circle" offIcon="fa fa-info-circle"/>
            <Tooltip
              for="#infoToggleButton"
              title={"<strong>Info Tool</strong>  \n Switch displaying information about source image. Bear in mind that only one image can be controlled at a time."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}/>
          </React.Fragment>
          }
          {toolVisible(HIDE_LABELMAPS_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="hideLabelmapsToggleButton"
              checked={manualToolState[MAIN_TOOL][MAIN_TOOL_PROPERTY_NAME_SEGMENTATIONS_VISIBLE]!=null
                ? !manualToolState[MAIN_TOOL][MAIN_TOOL_PROPERTY_NAME_SEGMENTATIONS_VISIBLE]
                : false}
              onLabel="G"
              offLabel="G"
              onChange={
                (e) => {
                  document
                    .getElementById('hideLabelmapsToggleButton')
                    .dispatchEvent( new KeyboardEvent('keydown', {key:'g', code:"KeyG"}));
                }
              }
              />
            <Tooltip
              for="#hideLabelmapsToggleButton"
              title={"<strong>Hide all seGmentations</strong>  \n Switch displaying labelmaps (segmentations) and contours."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}/>
          </React.Fragment>
          }
          {toolVisible(LINK_ALL_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="linkViewersToggleButton"
              checked={Object.keys(viewersState).every((el) => {
                return viewersState[el]['linked'];
              })}
              onLabel="" offLabel=""
              onChange={(e) => {
                Object.keys(viewersState).forEach((el) => updateViewerProperty(el, 'linked', e.value))
              }}
              onIcon="fa fa-chain" offIcon="fa fa-chain"/>
            <Tooltip
              for="#linkViewersToggleButton"
              title={"<strong>Link viewers</strong>  \n Change of current slice is applied to all of linked viewers , " +
              "on the condition that they are set in the same orientation."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(CAMERA_3D_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="link3DCamerasButton"
              checked={manualToolState[CAMERA_3D_TOOL][CAMERA_3D_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => {updateCameraSynchronization(e.value)}}
              onIcon="fa fa-video-camera" offIcon="fa fa-video-camera"/>
            <Tooltip
              for="#link3DCamerasButton"
              title={"<strong>Use single camera for 3D viewers</strong>  \n All 3D viewers (including graphs)" +
              "use the same camera positioning."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(SMOOTHING_ALL_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="smoothingAllViewersToggleButton"
              checked={areAllViewersSmoothed(viewersState)}
              onLabel="" offLabel=""
              onChange={(e) => setAllViewersSmoothing(viewersState,updateViewerProperty,e.value)}
              onIcon="fa fa-tint" offIcon="fa fa-tint"/>
            <Tooltip
              for="#smoothingAllViewersToggleButton"
              title={"<strong>Interpolation</strong>  \n Turn on/off interpolation (smoothing) " +
              "in all viewers."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(WINDOW_LEVEL__ALL_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="windowLevelAllViewersToggleButton"
              checked={manualToolState[WINDOW_LEVEL__ALL_TOOL][WINDOW_LEVEL__ALL_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => updateManualToolProperty(WINDOW_LEVEL__ALL_TOOL, WINDOW_LEVEL__ALL_TOOL, e.value)}
              onIcon="fa fa-adjust" offIcon="fa fa-adjust"/>
            <Tooltip
              for="#windowLevelAllViewersToggleButton"
              title={"<strong>Synchronize Window Level</strong>  \n When turned on, value of Window Level is applied to all viewers."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(ANNOTATION_OPACITY_TOOL) &&
          <div style={{display: "inline-block"}} id="pinOpacitySlider">
            <label style={{marginLeft: "1em"}}>Pin opacity:</label>
            <input type="range"
                   onChange={(e) => updateManualToolProperty(ANNOTATION_OPACITY_TOOL, ANNOTATION_OPACITY_TOOL, e.target.value / 100)}
                   min={0} step={10} max={100}
                   style={{
                     width: "4em",
                     verticalAlign: "middle",
                     marginRight: "5px",
                     marginLeft: "5px"
                   }}
                   value={100 * manualToolState[ANNOTATION_OPACITY_TOOL][ANNOTATION_OPACITY_TOOL]}
            />
            <Tooltip
              for="#pinOpacitySlider"
              title={"<strong>Opacity of annotations.</strong>  \n Change opacity of annotations and fiducials."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </div>}
          {toolVisible(LABELMAP_OPACITY_TOOL) &&
          <div style={{display: "inline-block"}} id="labelmapOpacitySlider">
            <label style={{marginLeft: "1em"}}>Opacity:</label>
            <input type="range"
                   onChange={(e) => updateManualToolProperty(LABELMAP_OPACITY_TOOL, LABELMAP_OPACITY_TOOL, e.target.value / 100)}
                   min={0} step={10} max={100}
                   className="opacitySlider"
                   value={100 * manualToolState[LABELMAP_OPACITY_TOOL][LABELMAP_OPACITY_TOOL]}
            />
            <Tooltip
              for="#labelmapOpacitySlider"
              title={"<strong>Opacity of labelmap.</strong>  \n Change opacity of labelmap."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </div>
          }
          {toolVisible(LABELMAP_LUT_TOOL) &&
          <React.Fragment>
            <label style={{marginLeft: "1em"}}>Label:</label>
            <ColorDropdown key="labelmapLUT"
                           options={activeColormap}
                           value={activeColor}
                           onChange={(e) => updateActiveColorInScene(e)}
                           disabled={!manualToolConfiguration[LABELMAP_LUT_TOOL]['controlEnabled']}
            />
          </React.Fragment>
          }
          {toolVisible(INTENSITY_FILTER_TOOL) &&
            <React.Fragment>
              <ToggleButton
                id="intensityFilterToggleButton"
                checked={manualToolState[INTENSITY_FILTER_TOOL][INTENSITY_FILTER_TOOL]}
                onLabel="" offLabel=""
                onChange={(e) => updateManualToolProperty(INTENSITY_FILTER_TOOL, INTENSITY_FILTER_TOOL, e.value)}
                onIcon="fa fa-filter " offIcon="fa fa-filter"/>
                <Tooltip
                  for="#intensityFilterToggleButton"
                  title={"<strong>Intensity filter for drawing</strong>  \n When drawing with brush or filling tool pixels with values out of range will not be drawn."}
                  escape={false}
                  tooltipDisabled={this.state.tooltipDisabled}
                />
            </React.Fragment>
          }
          {toolVisible(CUSTOM_CURSOR_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="customCursorToggleButton"
              checked={manualToolState[CUSTOM_CURSOR_TOOL][CUSTOM_CURSOR_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => updateManualToolProperty(CUSTOM_CURSOR_TOOL, CUSTOM_CURSOR_TOOL, e.value)}
              onIcon="fa fa-mouse-pointer " offIcon="fa fa-mouse-pointer"/>
            <Tooltip
              for="#customCursorToggleButton"
              title={"<strong>Custom cursor</strong>  \n Change the shape of cursor."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(FIDUCIAL_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="fiducialToggleButton"
              checked={manualToolState[FIDUCIAL_TOOL][FIDUCIAL_TOOL]}
              onLabel="" offLabel=""
              onChange={(e) => updateManualToolProperty(FIDUCIAL_TOOL, FIDUCIAL_TOOL, e.value)}
              onIcon="fa fa-text-width" offIcon="fa fa-text-width"/>
            <Tooltip
              for="#fiducialToggleButton"
              title={"<strong>Fiducial</strong>  \n Measure size of objects."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(ROI_TABLE_TOOL) &&
          <React.Fragment>
            <ToggleButton
              id="roiTableToggleButton"
              checked={manualToolState[ROI_TABLE_TOOL][ROI_TABLE_TOOL]}
              onLabel="ROI" offLabel=""
              onChange={(e) => updateManualToolProperty(ROI_TABLE_TOOL, ROI_TABLE_TOOL, e.value)}
              onIcon="fa fa-table" offIcon="fa fa-table"/>
            <Tooltip
              for="#roiTableToggleButton"
              title={"<strong>ROIs table</strong>  \n Show all ROIs in table."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(CASE_PROGRESS_TOOL) &&
          <React.Fragment>
            <div id="caseProgressTool" style={{display: "inline-block"}}>
              Done: {calculateCases("DONE").statused} out of {calculateCases("DONE").allCases}
            </div>
            <Tooltip
              for="#caseProgressTool"
              title={"<strong>Case progress</strong>  \n Number of FULLY finished cases."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
          {toolVisible(SEGMENTATION_METRICS_TOOL) &&
          <React.Fragment>
            <ContainerSegmentationMetricsWidget />
          </React.Fragment>
          }
          {toolVisible(SLICE_POSITION_INDEX) &&
          <React.Fragment>
            <ContainerSlicePositionWidget id="slicePositionIndexTool"/>
            <Tooltip
              for="#slicePositionIndexTool"
              title={"<strong>Slice position</strong>  \n Slice position index / number of all slices."}
              escape={false}
              tooltipDisabled={this.state.tooltipDisabled}
            />
          </React.Fragment>
          }
        </Toolbar>
        {extendedToolbarSeparatorVisible &&
        <i className="fa fa-bars ui-toolbar-separator" style={{marginRight: '.25em'}}/>
        }
        {leftButtonMode === LEFT_BUTTON_MODE.BRUSH &&
        <BrushToolbar toolProperties={manualToolState[BRUSH_TOOL]}
                      changeToolProperties={updateManualToolProperty}
                      toolConfiguration={manualToolConfiguration[BRUSH_TOOL]}
        />
        }
        {leftButtonMode === LEFT_BUTTON_MODE.ERASER &&
        <EraserToolbar toolProperties={manualToolState[ERASER_TOOL]}
                       changeToolProperties={updateManualToolProperty}
                       toolConfiguration={manualToolConfiguration[ERASER_TOOL]}
        />}
        {leftButtonMode === LEFT_BUTTON_MODE.LEVEL &&
        <LevelTracingToolbar toolProperties={manualToolState[LEVEL_TRACING_TOOL]}
                       changeToolProperties={updateManualToolProperty}
                       toolConfiguration={manualToolConfiguration[LEVEL_TRACING_TOOL]}
        />}
        {leftButtonMode === LEFT_BUTTON_MODE.FILLING &&
        <FillingToolbar toolProperties={manualToolState[FILLING_TOOL]}
                        toolConfiguration={manualToolConfiguration[FILLING_TOOL]}
                        changeToolProperties={updateManualToolProperty}
                        colorOptions={colorOptions}
        />}
        {leftButtonMode === LEFT_BUTTON_MODE.PIN &&
        <PinToolbar toolProperties={manualToolState[PIN_TOOL]}
                    toolConfiguration={manualToolConfiguration[PIN_TOOL]}
                    changeToolProperties={updateManualToolProperty}
                    clearAll={clearAll}/>
        }
        {(extendedMenuVisible(PROJECTION_TOOL)
          || extendedMenuVisible(CUSTOM_CURSOR_TOOL)
          || extendedMenuVisible(POINTER_TOOL)
        ) &&
        <i className="fa fa-bars ui-toolbar-separator" style={{marginRight: '.25em'}}/>
        }
        {extendedMenuVisible(PROJECTION_TOOL) &&
        <ProjectionToolbar
          toolProperties={manualToolState[PROJECTION_TOOL]}
          changeToolProperties={updateManualToolProperty}
          toolConfiguration={manualToolConfiguration[PROJECTION_TOOL]}
        />
        }
        {extendedMenuVisible(CUSTOM_CURSOR_TOOL) &&
        <CustomCursorToolbar
          toolProperties={manualToolState[CUSTOM_CURSOR_TOOL]}
          changeToolProperties={updateManualToolProperty}
          toolConfiguration={manualToolConfiguration[CUSTOM_CURSOR_TOOL]}
        />
        }
        {extendedMenuVisible(FIDUCIAL_TOOL) &&
        <FiducialToolbar
          toolProperties={manualToolState[FIDUCIAL_TOOL]}
          changeToolProperties={updateManualToolProperty}
          toolConfiguration={manualToolConfiguration[FIDUCIAL_TOOL]}
        />
        }
        {manualToolState[RULER_TOOL]!=null && manualToolState[RULER_TOOL]["zoom"]!=null && manualToolConfiguration[RULER_TOOL]["properties"]["zoom"]["controlVisible"] &&
        <React.Fragment>
          Ruler Zoom
        <Spinner value={manualToolState[RULER_TOOL]["zoom"]}
                 min={1}
                 max={10}
                 step={0.5}
                 size={3}
                 onChange={(e) => updateManualToolProperty(RULER_TOOL, 'zoom', e.value)}
        />
        </React.Fragment>
        }
        {extendedMenuVisible(POINTER_TOOL) &&
        <PointerToolbar
          toolProperties={manualToolState[POINTER_TOOL]}
          changeToolProperties={updateManualToolProperty}
          toolConfiguration={manualToolConfiguration[POINTER_TOOL]}
        />
        }
        {extendedMenuVisible(INVERSION_TOOL) &&
        <InversionToolbar
          invertColorsInOverlay={invertColorsInOverlay}
          toolProperties={manualToolState[INVERSION_TOOL]}
          changeToolProperties={updateManualToolProperty}
          toolConfiguration={manualToolConfiguration[INVERSION_TOOL]}
        />
        }
        {extendedMenuVisible(INTENSITY_FILTER_TOOL) &&
          <IntensityFilterToolbar changeToolProperties={updateManualToolProperty}
                                  toolProperties={manualToolState[INTENSITY_FILTER_TOOL]}
                                  setLeftButtonMode={this.setLeftButtonMode}
                                  samplerProperties={manualToolState[SAMPLER_TOOL]}
                                  leftButtonMode={manualToolState[MAIN_TOOL][MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE]}
                                  toolConfiguration={manualToolConfiguration[INTENSITY_FILTER_TOOL]}
          />
        }
        { manualToolState[SAMPLER_TOOL]!=null && manualToolState[SAMPLER_TOOL]['output']!=null && manualToolState[SAMPLER_TOOL]['output']['stats']!=null &&
        <React.Fragment>
          {manualToolState[SAMPLER_TOOL]['histogram'] !== false &&
          <Button icon={"fa fa-bar-chart"} onClick={()=>updateManualToolProperty(SAMPLER_TOOL, 'dialogVisible', true)}/>}
          <Dialog header="Data Probe Voxel Intensity Statistics"
                  visible={manualToolState[SAMPLER_TOOL]['dialogVisible'] === true}
                  modal={true}
                  onHide={() =>updateManualToolProperty(SAMPLER_TOOL, 'dialogVisible', false)}
                  style={{textAlign: "center"}}
          >
            { manualToolState[SAMPLER_TOOL]!=null && manualToolState[SAMPLER_TOOL]['output']!=null && manualToolState[SAMPLER_TOOL]['dialogVisible'] === true &&
            <DataProbeOutputPanel
              samplerToolOutput={manualToolState[SAMPLER_TOOL]['output']}
              samplerToolBucketing={manualToolState[SAMPLER_TOOL]['bucketing']}
              samplerToolInteractions={manualToolState[SAMPLER_TOOL]["interactions"]}
              updateManualToolProperty={updateManualToolProperty}/>
            }
          </Dialog>

        </React.Fragment>
        }
      </React.Fragment>
    )
  }

}

export default withRouter(WidgetToolbar);

WidgetToolbar.defaultProps = {};

WidgetToolbar.propTypes = {
  manualToolState: PropTypes.object.isRequired,
  manualToolConfiguration: PropTypes.object.isRequired,//initial settigs of widgets
  manualTask: PropTypes.object.isRequired,
  updateManualToolProperty: PropTypes.func.isRequired,
  viewersState: PropTypes.object,
  updateViewerProperty: PropTypes.func.isRequired,
  brushProperties: PropTypes.object.isRequired,
  setBrushProperties: PropTypes.func.isRequired,
  colorOptions: PropTypes.object.isRequired,
  clearAll: PropTypes.func.isRequired,
  undoPin: PropTypes.func.isRequired,
  redoPin: PropTypes.func.isRequired,
  luts: PropTypes.object,
  lutDescriptions: PropTypes.object,
  scenes: PropTypes.object,
  updateActiveColorInScene: PropTypes.func,
  activePainter: PropTypes.object,
  activeColor: PropTypes.number,
  activeColormap: PropTypes.array,
  invertColorsInOverlay: PropTypes.func,
  updateCameraSynchronization: PropTypes.func,
};


