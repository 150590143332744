// noinspection JSUnusedLocalSymbols
/**
 * Helper to create 2-D array of values provided with function
 * @param rows - number of rows
 * @param columns - number of columns
 * @param value - function eg. ()=>[] , ()=>{}, ()=>0
 */
export function create2DArray(rows, columns, value = (x, y) => 0) {
  const array = new Array(rows);
  for (let i = 0; i < rows; i++) {
    array[i] = new Array(columns);
    for (let j = 0; j < columns; j++) {
      array[i][j] = value(i, j);
    }
  }
  return array;
}


/**
 * Helper to create 1-D array of values provided with function
 * @param rows - number of elements
 * @param value - function eg. ()=>[] , ()=>{}, ()=>0
 */
export function create1DArray(rows, value = (x, y) => 0) {
  const array = new Array(rows);
  for (let i = 0; i < rows; i++)
    array[i] = value(i);
  return array;
}


/**
 * Removes duplicates having the same value of property.
 * @param arr
 * @param filterFunction - function to determine duplicate eg. x=>x["key"]
 */
export function removeDuplicatesByFilterFunction(arr, filterFunction) {
  return [
    ...new Map(
      arr.map(x => [filterFunction(x), x])
    ).values()
  ]
}