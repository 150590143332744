// Import libraries
import React from 'react'
import { Provider } from 'react-redux'
import { Router, Route, Switch  } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'
// Import styles
import 'primereact/resources/primereact.min.css'
import './sass/index.scss'
// Import components/containers/classes
import store from './Store.js'
import ContainerApp from './authentication/containers/ContainerApp.js'
import Home from './home/Home'
import { withTranslation } from 'react-i18next';
import ContainerRegister from "./authentication/containers/ContainerRegister";
import ContainerPasswordReset from "./authentication/containers/ContainerPasswordReset";
import ContainerForgotPassword from "./authentication/containers/ContainerForgotPassword";
import {ContainerHome} from "./home/container/ContainerHome";
import ContainerRedirectLogin from './authentication/containers/ContainerRedirectLogin'
import {ContainerPublicEventsPage} from './event/containers/ContainerPublicEventsPage';
import {ContainerPublicEventDetailsPage} from './event/containers/ContainerPublicEventDetailsPage';
import ContainerEmailValidation from './authentication/containers/ContainerEmailValidation'
import {ContainerLandingPage} from "./landingPage/ContainerLandingPage";


/**
 * Reference to Router history.
 * Can be injected into the business logic code in the same way as 'store' object.
 */
export const history = createBrowserHistory();

/**
 * Class responsible for initializing and providing references to:
 * 1. Redux Store
 * 2. React-Router history
 * 3. React-I18next localization
 *
 */

class Root extends React.Component{

  render(){
      const {i18n}=this.props;
      // the code below is to validate whether React components are reRendered after change of language
      i18n.on('languageChanged', function(lng) {
          console.log("App::Language changed to: " +lng);
      });
    return(
      <Provider store={store}>
        <Router history={history}>
            <Switch>
              <Route exact path='/landingPageProposal' component={ContainerLandingPage}/>
              <Route exact path='/' component={ContainerApp}/>
              <Route exact path='/login' component={ContainerRedirectLogin}/>
              <Route exact path='/register' component={ContainerRegister}/>
              <Route exact path='/passwordReset/:resetToken/reset' component={ContainerPasswordReset}/>
              <Route exact path='/forgotPassword' component={ContainerForgotPassword}/>
              <Route exact path='/publicEvent' component={ContainerPublicEventsPage}/>
              <Route exact path='/publicEvent/:eventId' component={ContainerPublicEventDetailsPage}/>
              <Route exact path='/accountValidation/:validationToken/validation' component={ContainerEmailValidation}/>
              <Route path='/*' component={ContainerHome}/>
            </Switch>
        </Router>
      </Provider>
    );
  }
}

export default withTranslation()(Root);