import React, { Component } from 'react'
import {scaleLinear, scaleOrdinal} from "d3-scale";
import { event, select} from "d3-selection";
import {axisBottom, axisLeft} from "d3-axis";
import {line} from "d3-shape";
import d3Tip from "d3-tip"
import {extent, max, min} from "d3-array";
import PropTypes from "prop-types";
import {zoom} from "d3-zoom";
import {schemeCategory10} from "d3-scale-chromatic";

/** D3-based ScatterPlot Component integrated with React (based on
 *  traditional D3 rendering with React as one can see in
 *  {@link https://medium.com/@Elijah_Meeks/interactive-applications-with-react-d3-f76f7b3ebc71}
 *  Other sources:
 *  Scatter plot: {@link https://bl.ocks.org/ctufts/298bfe4b11989960eeeecc9394e9f118}
 *  Interactive scatter plot {@link http://bl.ocks.org/peterssonjonas/4a0e7cb8d23231243e0e}
 *
 *  Example of use:
 *  import {create_data} from "./DataHelper";
 *  import ScatterPlot from "./d3components/ScatterPlot";
 *  ...
 *  render(){return ...
 *   <ScatterPlot data={create_data(1000)} width={500}  height={250}
 *    xLabel="Age" yLabel="Volume of L-Thalamus"/>
 *
 *    Parameters:
 *    data - mandatory [array of objects] containing mandatory attributes:[x,y] and may contains additional attribute
 *           to differentiate subpopulations
 *    colorCat - optional [string] - name of attribute for grouping, which MUST appear in data objects
 *    width, height - optional  [number] - size of the whole image
 *    xLabel, yLabel - optional [string] - labels for axis
 *    onDataPointClick - optional [function] - callback to click event
 *    contextMenu - optional [array] - items of context menu
 *    slrmodel - required [object of SLRModel class] - stats for model
 *    showRegLine - optional [bool] - flag indicating displaying regression line
 *    showCILine - optional [bool] - flag indicating displaying CI line
 *    showPILine - optional [bool] - flag indicating displaying PI line
 *
 */
class ScatterPlotSAM extends Component {

  constructor(props){
    super(props);
    this.state = {
      objects:null,
      DOT_RADIUS : 4
    };
    ["createScatterPlot","updateScatterPlot","clearPlot"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }
  componentDidMount() {
    this.createScatterPlot();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {width,height,data} = this.props;
    if(prevProps.width!==width || prevProps.height!==height || prevProps.data !== data){
      this.clearPlot();
      this.createScatterPlot();
      return;
    }
    this.updateScatterPlot();
  }

  /**
   * Clears everything.
   */
  clearPlot() {
    select(this.node)
      .selectAll("*")
      .remove();
  }

  updateScatterPlot() {
    const{DOT_RADIUS} = this.state;
    const {margin,slrmodel} = this.props;
    const node = this.node;   //svg ref
    let onDataPointClick= this.props.onDataPointClick;
    const selectedDataPointIndex = this.props.selectedDataPointIndex;
    let outerWidth = (this.props.width!=null)?this.props.width:960;
    let colorCat = this.props.colorCat;
    let color = scaleOrdinal(schemeCategory10);

    let
      width = outerWidth - margin.left - margin.right,
      height = outerHeight - margin.top - margin.bottom;

    let svg = select(node);

    svg.selectAll("circle")
      .on('click',onDataPointClick)
      .style("fill", function(d) {
        if (d.index===selectedDataPointIndex)
          return "#F00";
        else
          return (colorCat==null)?"#000":color(d[colorCat]);
      })
      .style("fill-opacity",function(d) {
        if(d.index===selectedDataPointIndex)
          return 1;
        else return 0.5;});

    //====rendering legend==============================
    if (colorCat!==this.state.colorCat) {

      let legendGroup = svg
        .select("#legend")
        .attr("transform","translate(" + (0) + ",0)");
      //.attr("transform","translate(" + (-margin.right+margin.left+20) + ",0)"); //FIXME

      legendGroup.selectAll("*").remove(); //clearing nodes inside

      let cd = color.domain().filter(function(n){ return n != null });
      let legend = legendGroup.selectAll(".legend")
        .data(cd)
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; })

      legend.append("circle")
        .attr("r", DOT_RADIUS)
        .attr("cx", width + 20)
        .attr("fill", color)
        .attr("fill-opacity", .5)
      ;

      legend.append("text")
        .attr("x", width + 26)
        .attr("dy", ".35em")
        .attr("font-size","10px")
        .text(function(d) { return d; });
    }

    //=== rendering lines=========================
    if(slrmodel) {
      this.state.objects.select("#regression")
        .attr("display", this.props.showRegLine ? "block" : "none");
      this.state.objects.select("#cilwr")
        .attr("display", this.props.showCILine ? "block" : "none");
      this.state.objects.select("#ciupr")
        .attr("display", this.props.showCILine ? "block" : "none");
      this.state.objects.select("#pilwr")
        .attr("display", this.props.showPILine ? "block" : "none");
      this.state.objects.select("#piupr")
        .attr("display", this.props.showPILine ? "block" : "none");
    }
  }

  createScatterPlot() {
    const{DOT_RADIUS} = this.state;
    const {margin,onContextClick,colorLUT,slrmodel} = this.props;
    const node = this.node;   //svg ref
    let outerWidth = (this.props.width===undefined)?960:this.props.width;
    let outerHeight = (this.props.height===undefined)?500:this.props.height;
    let onDataPointClick= this.props.onDataPointClick;
    let data = this.props.data;
    const selectedDataPointIndex = this.props.selectedDataPointIndex;

    let lineParams = (slrmodel)?slrmodel.betasEstimates:null;
    let cilines = (slrmodel)?slrmodel.lowerboundci:null;
    let cilines2 = (slrmodel)?slrmodel.upperboundci:null;
    let predlines = (slrmodel)?slrmodel.lowerboundpi:null;
    let predlines2 = (slrmodel)?slrmodel.upperboundpi:null;
    let orderedx = (slrmodel)?slrmodel.orderedx:null;
    let colorCat = this.props.colorCat;
    let xLabel = (this.props.xLabel==null)?'x':this.props.xLabel;
    let yLabel = (this.props.yLabel==null)?'y':this.props.yLabel;

    const getColor = (d) => {
      if (colorLUT != null && colorLUT.length > 0 && d[colorCat]){
        return colorLUT[d[colorCat]];
      } else {
        return "darkorange";
      }
    };

    const STROKE_WIDTH = 1;

    // set the dimensions and margins of the graph
    let
      width = outerWidth - margin.left - margin.right,
      height = outerHeight - margin.top - margin.bottom;

    // set the ranges
    let xScale = scaleLinear()
      .range([0, width]).nice();
    let yScale = scaleLinear()
      .range([height, 0]).nice();

    let xValue = function(d) {  return d.x;  };
    let yValue = function(d) {  return d.y;  };



    // set domains with some margin
    //-------------------x domain----------------------------------
    let xDomain = extent(data, xValue);
    const xdiff = (xDomain[1]-xDomain[0])/20; //adding margins to data
    // xScale.domain(xDomain);
    xDomain = [xDomain[0]- xdiff, xDomain[1]+xdiff];
    xScale.domain(xDomain);
    console.log("DATA DOMAINS: xScale", xScale.domain());
    //-------------------y domain----------------------------------
    let yDomain = extent(data, yValue);
    const ydiff = (yDomain[1]-yDomain[0])/20; //adding margins to data
    // yScale.domain(yDomain);
    yDomain = [yDomain[0]- ydiff, yDomain[1]+ydiff];
    yScale.domain(yDomain);
    console.log("DATA DOMAINS: yScale",yScale.domain());


    // set the axes
    let xAxis = axisBottom(xScale);
    let yAxis = axisLeft(yScale);
    let color = scaleOrdinal(schemeCategory10);


    // set  tips
    let tip = d3Tip()
      .attr("class", "d3-tip")
      .offset([-10, 0])
      .html(function(d) {
        return xLabel + ": " + d['x'] + "<br>" + yLabel + ": " + d['y'];
      });

    //zoom parameters and handler assignment
    let zooming = zoom()
      .scaleExtent([.05, 100])
      .translateExtent([[0, 0], [width, height]])
      .extent([[0, 0], [width, height]])
      .on("zoom", zoomEventHandler);


    // append the svg object to the body of the page and translate the parent group
    // calculate the rectangle embraced by axes
    let svg = select(node)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .call(zooming)
      .on('contextmenu', function (e) {
      if (onContextClick!=null) {
        event.preventDefault();
        event.stopPropagation();
        onContextClick(event, null, () => {
        }); //second parameter is callback to unselect
      }
    });

    svg.call(tip);

    svg.append("rect")
      .attr("width", width)
      .attr("height", height);

    // x Axis---------------------------
    if(this.props.renderXLabel) {
      svg.append("g")
        .attr("class","x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .append("text")
        .attr("class", "label")
        .attr("fill", "#000")
        .attr("font-size", "0.7em")
        .attr("x", width)
        .attr("y", margin.bottom - 10)
        .style("text-anchor", "end")
        .text(xLabel);}

    else  svg.append("g")
      .attr("class","x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);


    // y Axis---------------------------
    svg.append("g")
      .attr("class","y axis")
      .call(yAxis)
      .append("text")
      .attr("class", "label")
      .attr("font-size", "0.7em")
      .attr("fill", "#000")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .text(yLabel);

    let objects = svg.append("svg")
      .attr("class", "objects")
      .attr("width", width)
      .attr("height", height);

    this.setState({objects:objects});

    // Data Points ---------------------------------
    objects.selectAll(".dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class","dot")
      .attr("r", DOT_RADIUS)
      .style("fill", function(d) {
        if (d.index===selectedDataPointIndex)
          return "#F00";
        else
          return (colorCat==null)?"#000":color(d[colorCat]); })
      .attr("cx", function(d) {
        return xScale(d.x);
      })
      .attr("cy", function(d) {
        return yScale(d.y);
      })
      .style("fill-opacity",function(d) {
        if(d.index===selectedDataPointIndex)
          return 1;
        else return 0.5;})
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide)
      .on("click", onDataPointClick)
      .on('contextmenu', function (e) {
        if (onContextClick!=null) {
          event.preventDefault();
          event.stopPropagation();
          tip.hide();
          let selected = select(this).classed("selectedAvatar");
          svg.selectAll("circle")
            .classed("selectedAvatar", false)
            .style("stroke", "none");
          if (e.hasOwnProperty('isDuplicated')) {
            select(this)
              .classed("selectedAvatar", !selected);
            data.filter((el) => e['userId'] === el['isDuplicateOf'])
              .forEach((el) => {
                select("#".concat(viewId,'dataId',el['userId']))
                  .style('display', selected ? 'block' : 'none');
              });
            return;
          }
          select(this)
            .classed("selectedAvatar", true)
            .style("stroke", "red");
          onContextClick(event, e, () => select(this)
            .classed("selectedAvatar", false)
            .style("stroke", "none")); //third parameter is callback to unselect
        }
      });

    //===rendering legend  =================================

    let legendGroup = svg
      .append("g")
      .attr("id","legend")
      .attr("transform","translate(" + (-margin.right+margin.left+20) + ",0)");
    let legend = legendGroup.selectAll(".legend")
      .data(color.domain())
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; });

    legend.append("circle")
      .attr("r", DOT_RADIUS)
      .attr("cx", width + 20)
      .attr("fill", color)
      .attr("fill-opacity", .5)
    ;

    legend.append("text")
      .attr("x", width + 26)
      .attr("font-size","12px")
      .attr("dy", ".35em")
      .text(function(d) { return d; });


    //=== rendering regression line=========================

    let regressionLine=null;
    let ciLineLwr=null;
    let ciLineUpr=null;
    let predLineLwr=null;
    let predLineUpr=null;

    if(slrmodel) {
      let minX = min(data, function (d) {
        return d.x;
      });
      let maxX = max(data, function (d) {
        return d.x;
      });
      let dataLine1 = [{x: minX, y: 0}, {x: maxX, y: 0}];



      let _line = line()
        .x(function (d) {
          return xScale(d.x);
        })
        .y(function (d) {
          return yScale(d.x * lineParams[1] + lineParams[0]);
        });

      // dataLine1 = [data[0], data[data.length - 1]];
      regressionLine = objects.append("path")
        .datum(dataLine1)
        .attr("id", "regression")
        .attr("stroke", "#E4002B")
        .attr("fill", "none")
        .attr("stroke-width", STROKE_WIDTH)
        .attr("d", _line)
        .attr("display", this.props.showRegLine ? "block" : "none");

      //=== rendering CI lines=========================

      let _ciline = line()
        .x(function (d, i) {
          return xScale(orderedx[i]);
        })
        .y(function (d) {
          return yScale(d);
        });

      if (!(cilines == null)) {

        ciLineLwr = objects.append("path")
          .datum(cilines)
          .attr("id", "cilwr")
          .attr("stroke", "#00AA00")
          .attr("fill", "none")
          .attr("stroke-width", STROKE_WIDTH)
          .attr("d", _ciline);

        ciLineUpr = objects.append("path")
          .datum(cilines2)
          .attr("id", "ciupr")
          .attr("stroke", "#00AA00")
          .attr("fill", "none")
          .attr("stroke-width", STROKE_WIDTH)
          .attr("d", _ciline);
      }
      if (!(predlines == null)) {
        predLineLwr = objects.append("path")
          .datum(predlines)
          .attr("id", "pilwr")
          .attr("stroke", "#0000FF")
          .attr("fill", "none")
          .attr("stroke-width", STROKE_WIDTH)
          .attr("d", _ciline);

        predLineUpr = objects.append("path")
          .datum(predlines2)
          .attr("id", "piupr")
          .attr("stroke", "#0000FF")
          .attr("fill", "none")
          .attr("stroke-width", STROKE_WIDTH)
          .attr("d", _ciline);
      }
    }

    function zoomEventHandler() {
      svg.select(".x.axis").call(xAxis.scale(event.transform.rescaleX(xScale)));
      svg.select(".y.axis").call(yAxis.scale(event.transform.rescaleY(yScale)));
      svg.selectAll("circle.dot")
        .attr("transform", event.transform);
      svg.selectAll("circle.dot")
        .attr("r", DOT_RADIUS/event.transform.k)
        .attr("stroke-width", 1/event.transform.k);


      if (!(lineParams == null)) {
        regressionLine.attr("transform", event.transform);
        regressionLine.attr("stroke-width", STROKE_WIDTH / event.transform.k);
      }
      if (!(cilines == null)) {
        ciLineLwr.attr("transform", event.transform);
        ciLineLwr.attr("stroke-width", STROKE_WIDTH / event.transform.k);
        ciLineUpr.attr("transform", event.transform);
        ciLineUpr.attr("stroke-width", STROKE_WIDTH / event.transform.k);
      }
      if (!(predlines == null)) {
        predLineLwr.attr("transform", event.transform);
        predLineLwr.attr("stroke-width", STROKE_WIDTH / event.transform.k);
        predLineUpr.attr("transform", event.transform);
        predLineUpr.attr("stroke-width", STROKE_WIDTH / event.transform.k);
      }

    }


  }
  render() {
    return     <svg ref={node => this.node = node} />   ;
  }
}
export default ScatterPlotSAM

ScatterPlotSAM.defaultProps = {
  margin:{ top: 50,  right: 300, bottom: 50,  left: 50},
  renderXLabel:true
};

ScatterPlotSAM.propTypes = {
  contextMenu: PropTypes.array,
  data: PropTypes.array.isRequired,
  slrmodel: PropTypes.object,
  predictionLevel:PropTypes.number,
  width:PropTypes.number,
  height:PropTypes.number,
  xLabel:PropTypes.string.isRequired,
  yLabel:PropTypes.string.isRequired,
  onDataPointClick:PropTypes.func,
  colorCat:PropTypes.string,
  showRegLine:PropTypes.bool,
  showCILine:PropTypes.bool,
  showPILine:PropTypes.bool,
  selectedDataPointIndex:PropTypes.number,
  margin:PropTypes.object,
  renderXLabel:PropTypes.bool.isRequired,
  onContextClick:PropTypes.func.isRequired
};