import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import PropTypes from 'prop-types';
import {ColorDropdown} from "./ColorDropdown";
import {FILLING_TOOL} from "../../../../Constants";

/**
 * Dumb component for displaying toolbar for Filling Tool.
 * @returns {*}
 */
export const FillingToolbar = ({toolProperties, changeToolProperties, colorOptions, toolConfiguration}) => {

    // const control = (property, type) => toolConfiguration != null
    //     && toolConfiguration.properties != null
    //     && toolConfiguration.properties[property] != null
    //     && toolConfiguration.properties[property][type] != null
    //     && toolConfiguration.properties[property][type];
    //
    // const labelmapOptions = (toolConfiguration != null
    //     && toolConfiguration.properties != null
    //     && toolConfiguration.properties['labelmap'] != null
    //     && toolConfiguration.properties['labelmap']['properties'] != null
    //     && toolConfiguration.properties['labelmap']['properties']['options'] != null)
    //     ? toolConfiguration.properties['labelmap']['properties']['options'] : null;

    const checkIfAnyPropertyVisible = toolConfiguration['properties']!=null && Object.keys(toolConfiguration['properties'])
        .find((el) => {
            return toolConfiguration['properties'][el]['controlVisible']
        }) != null;

    return (
        <React.Fragment>
            {checkIfAnyPropertyVisible &&
            <Toolbar style={{display: "inline-block"}}>
                {/*{control('labelmap', 'controlVisible') &&*/}
                {/*<React.Fragment>*/}
                    {/*<label style={{marginLeft: "1em"}}>Label:</label>*/}
                    {/*<ColorDropdown key="fillColorDropdown"*/}
                                   {/*disabled={!control('labelmap', 'controlEnabled')}*/}
                                   {/*options={labelmapOptions}*/}
                                   {/*value={toolProperties['labelmap']}*/}
                                   {/*onChange={(e) => changeToolProperties(FILLING_TOOL, 'labelmap', e)}*/}
                    {/*/>*/}
                {/*</React.Fragment>}*/}
            </Toolbar>}
        </React.Fragment>
    );
};
FillingToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    toolConfiguration: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func.isRequired,
    // colorOptions: PropTypes.array.isRequired //Removed to general properties
};
