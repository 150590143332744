import {connect} from "react-redux";
import {
    goToNextStep, goToPreviousStep, initializeSkillSummary,

} from "../action/ManualToolAction";
import SkillSummaryLP from "../component/SkillSummaryLP";
import {getNewCase, recreateTrainingWorkflowInLP} from "../../dashboard/action/ManualTaskAction";


const mapStateToProps = (state,ownProps) => {
    return {
        skillResult:state.skill.skillResult,
        livePresenterExperimentId: ownProps.livePresenterExperimentId,
        data: state.visu.annotations.annotationsData,
        images:state.visu.images.images,
        overlays:state.visu.images.overlays,
        imageOptions: state.visu.viewers.imageOptions,
        viewersState: state.visu.viewers.viewersState,
        taskId:ownProps.taskId,
        manualTaskState:state.activity.manualTask.manualTaskState,
        workload: state.activity.manualTask.manualTask,
        manualTool: state.visu.manualTool,
        experimentId: ownProps.experimentId,
        miniWorkflowSetId : ownProps.miniWorkflowSetId,
        miniWorkflowNameInSet : ownProps.miniWorkflowNameInSet,
        taskNameInMiniWorkflow : ownProps.taskNameInMiniWorkflow
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initialize : (experimentId) =>{
            dispatch (initializeSkillSummary(experimentId))
        },
        recreateTrainingWorkflowInLP :  (experimentId,miniWorkflowSet,miniWorkflowKey) =>{
            dispatch (recreateTrainingWorkflowInLP (experimentId,miniWorkflowSet,miniWorkflowKey))
        },
        getNewCase: (liveExperimentId,history)=>{
            dispatch (getNewCase (liveExperimentId,history))
        },
        goToPreviousStep: ()=>{
            dispatch(goToPreviousStep())
        },
        goToNextStep: ()=>{
            dispatch(goToNextStep())  //unused, fetaure for the future
        }
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerSkillSummaryLP = containerCreator(SkillSummaryLP);

