import React from "react";
import PropTypes from "prop-types";
import {HierarchicalDropdownGenerator} from "./HierarchicalDropdownGenerator";
import {CUSTOM_ELEMENT} from "./AnnotationCell";
import {searchParent} from "../../../helpers/trees";
import HierarchicalOptionsEditor from "./HierarchicalOptionsEditor";
import {Dialog} from "primereact/components/dialog/Dialog";
import ClassSearchInputText from "../../../ncbo/component/ClassSearchInputText";
import {ANNOTATION_PROPERTY_NAME__ID} from "../../../../Constants";
import TermSearchResultsDataTable from "../../../ncbo/component/TermSearchResultsDataTable";
import {Button} from "primereact/components/button/Button";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import ChoiceOptionsEditor from "./ChoiceOptionsEditor";


/**
 * Form for creating/editing annotation column.
 */
class AnnotationOptionsEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOntologyEditorVisible:false,
      searchedTerm:null,
      queryResult:null,
      selectedResult:null,
      callbackToUpdate:null,
      filteringCheckbox:false,
      key:null
    };

    ["findInOntology","onOntologyEditorHide","onSetOntology"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  /**
   *
   * @param key -
   * @param callback - function passed from child
   */
  findInOntology(key,callback){
    this.setState({isOntologyEditorVisible:true,key:key,searchedTerm:key,callbackToUpdate:callback});
  }

  onOntologyEditorHide(){
    this.setState({isOntologyEditorVisible:false, queryResult:null,key:null,searchedTerm:null,selectedResult:null,callbackToUpdate:null })
  }

  onSetOntology(){
    const{callbackToUpdate,selectedResult,key}=this.state;
    if(callbackToUpdate!=null){
      callbackToUpdate(key,'ontologyClassIri',selectedResult['@id'])
    }
    this.onOntologyEditorHide();
  }

  render() {

    const{type,options,optionsDetails,updateOptions,hide,messageQueue,ontologyList}=this.props;
    const{isOntologyEditorVisible,searchedTerm,queryResult,selectedResult,filteringCheckbox}=this.state;
    return (
      <div className={"ui-g-12"} >
        <h2>Options Editor</h2>

        <div style={{maxHeight:"80vh",overflowY:"scroll"}}>
        {type === CUSTOM_ELEMENT.HIERARCHICAL.type &&  <HierarchicalOptionsEditor options={options} optionsDetails={optionsDetails} updateOptions={updateOptions} hide={hide}
                                                                                  findInOntology={this.findInOntology} ontologyList={ontologyList}/> }
        {(type === CUSTOM_ELEMENT.LIST.type || type === CUSTOM_ELEMENT.CHOICE.type || type=== CUSTOM_ELEMENT.MULTIPLE_CHOICE.type )&&
          <ChoiceOptionsEditor options={options} optionsDetails={optionsDetails} updateOptions={updateOptions} hide={hide} findInOntology={this.findInOntology} type={type} ontologyList={ontologyList}/>
        }

        </div>

        <Dialog
          width={"80vw"} height={"80vh"}
          onHide={this.onOntologyEditorHide} visible={isOntologyEditorVisible} header={"Ontology search term"}>
          {isOntologyEditorVisible &&
          <React.Fragment>
            <ClassSearchInputText value={searchedTerm}
                                  inputGroupStyle={{display:"inline-block"}}
                                  onValueChange={(v)=>this.setState({searchedTerm:v})}
                                  onResponse={(data) => this.setState({queryResult: data})}
                                  onError={(err)=>{   messageQueue.show({
                                    sticky: false,
                                    severity: 'error',
                                    summary: 'NCBO Error',
                                    detail: err
                                  });}}
                                  filteringOntologies={filteringCheckbox
                                    ? ontologyList.map((cur) => {return cur.name}).filter(el=>{return el !== "SPINE ontology"})
                                    : []}/>

            <Button label={"Set"} onClick={this.onSetOntology} style={{marginLeft:"1em",display:"inline-block"}} disabled={!(selectedResult!=null)}/>
            <Checkbox checked={filteringCheckbox} style={{marginLeft:"2em",display:"inline-block"}} onChange={(e) => this.setState({filteringCheckbox: !filteringCheckbox})}/>
            <label style={{marginLeft:"1em",display:"inline-block"}}>Use SPINE registered ontologies only</label>
            <TermSearchResultsDataTable queryResult={queryResult} onRowSelected={(data)=>this.setState({selectedResult:data})}/>
          </React.Fragment>
          }


        </Dialog>
      </div>
    )
  }


}
const SEPARATOR = '-';

export default AnnotationOptionsEditor;

AnnotationOptionsEditor.propTypes = {
  options:PropTypes.object.isRequired,
  type:PropTypes.string.isRequired,
  optionsDetails:PropTypes.object.isRequired,
  updateOptions:PropTypes.func.isRequired,
  hide:PropTypes.func.isRequired,
  messageQueue:PropTypes.object.isRequired,
  ontologyList: PropTypes.array.isRequired
};
