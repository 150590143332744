// This code is just to avoids using eval() which can be dangerous for code safety


export function calculateRelationalExpression(op1,op2,operator){
    switch(operator){
        case "===":
            return op1===op2;
        case '<':
            return op1<op2;
        case '<=':
            return op1<op2;
        case '==':
            return op1==op2;
        case '!==':
            return op1!==op2;
        case '!=':
            return op1!=op2;
        case '>=':
            return op1>=op2;
        case '>' :
            return op1>op2;
    }
}


/**
 * This function allows for safely access deeply nested values in JavaScript.
 * See https://medium.com/javascript-inside/safely-accessing-deeply-nested-values-in-javascript-99bf72a0855a
 * Example:
 * console.log(getNestedProp(['user', 'posts', 0, 'comments'], props))
 * will display props.user.posts[0].comments property
 *
 * @param p - array of path elements
 * @param o - variable to peel off
 * @return {*}
 */
export function getNestedProp(p,o){
    return p.reduce((xs, x) => (xs!=null && xs[x]!=null) ? xs[x] : null, o)
}

/**
 * This function allows for safely setting up deeply nested values in JavaScript.
 * See https://medium.com/javascript-inside/safely-accessing-deeply-nested-values-in-javascript-99bf72a0855a
 * Example:
 * setNestedProp(['user', 'posts', 0, 'comments'], 'aaa',props)
 *
 * @param p - array of path elements
 * @param v - value to set
 * @param o - variable to peel off
 * @return {*}
 */
export function setNestedProp(p, v,o) {
    let len = p.length;
    for(let i = 0; i < len-1; i++) {
        let elem = p[i];
        if( !o[elem] ) o[elem] = {};
        o = o[elem];
    }
    o[p[len-1]] = v;
}