import React from "react";


export const colorBoxStyle = {
  width:"20px",
  height:"20px",
  display:"inline-block",
  marginInlineEnd:"5px",
  verticalAlign:"middle"
};


/**
 *
 * @param labelMap
 * @return {*}
 */

export const LabelmapStripBody = (labelMap) => {
  const style = {
    ...colorBoxStyle,
    background: "linear-gradient(to right,"+labelMap.background+")",
    width: "4em"
  };

  return <div style={{width: "6em"}}>
    <div style={style}/>
  </div>
};
