import {connect} from "react-redux";
import {
    goToNextStep, goToPreviousStep, initializeResultsPage,
} from "../action/ManualToolAction";

import {recreateTrainingWorkflowInLP} from "../../dashboard/action/ManualTaskAction";
import ResultsPage from "../component/annotationTool/ResultsPage";
import {clearSkillStateAC, goToPreviousSkillStep, successSkillResults} from "../../skills/action/SkillAction";
import {clearViewersState, updateViewerProperty, updateViewersState} from "../action/ViewerAction";
import ResultsPageINOS from "../component/annotationTool/ResultsPageINOS";
import ResultsPageBirds from "../component/annotationTool/ResultsPageBirds";


const mapStateToProps = (state,ownProps) => {
    return {
        skillResult:state.skill.skillResult,
        livePresenterExperimentId: ownProps.livePresenterExperimentId,
        images:state.visu.images.images,
        overlays:state.visu.images.overlays,
        imageOptions: state.visu.viewers.imageOptions,
        viewersState: state.visu.viewers.viewersState,
        taskId:ownProps.taskId,
        manualTaskState:state.activity.manualTask.manualTaskState,
        workload: state.activity.manualTask.manualTask,
        manualTool: state.visu.manualTool,
        experimentId: ownProps.experimentId,
        miniWorkflowSetId : ownProps.miniWorkflowSetId,
        miniWorkflowNameInSet : ownProps.miniWorkflowNameInSet,
        taskNameInMiniWorkflow : ownProps.taskNameInMiniWorkflow
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        initializeResultsPage : (experimentId) =>{
            dispatch (initializeResultsPage(experimentId))
        },
        recreateTrainingWorkflowInLP :  (experimentId,miniWorkflowSet,miniWorkflowKey) =>{
            dispatch (recreateTrainingWorkflowInLP (experimentId,miniWorkflowSet,miniWorkflowKey))
        },
        clearViewersState: () => {
            dispatch(clearViewersState())
        },
        updateViewersState: (viewers) => {
            dispatch(updateViewersState(viewers))
        },
        updateViewerProperty: (index, property, value) => {
            dispatch(updateViewerProperty(index, property, value))
        },
        goToPreviousSkillStep:()=>{
            dispatch(goToPreviousSkillStep())
        },
        // goToPreviousStep: ()=>{
        //     dispatch(goToPreviousStep())
        // },
        // goToNextStep: ()=>{
        //     dispatch(goToNextStep())  //unused, fetaure for the future
        // },
        clearSkillState:()=>{
            dispatch(clearSkillStateAC())
        }
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerResultsPage = containerCreator(ResultsPage);



const mapStateToProps2 = (state,ownProps) => {
    return {
        skillResult:state.skill.skillResult,
        livePresenterExperimentId: ownProps.livePresenterExperimentId,
        images:state.visu.images.images,
        overlays:state.visu.images.overlays,
        imageOptions: state.visu.viewers.imageOptions,
        viewersState: state.visu.viewers.viewersState,
        taskId:ownProps.taskId,
        manualTaskState:state.activity.manualTask.manualTaskState,
        workload: state.activity.manualTask.manualTask,
        manualTool: state.visu.manualTool,
        experimentId: ownProps.experimentId,
        miniWorkflowSetId : ownProps.miniWorkflowSetId,
        miniWorkflowNameInSet : ownProps.miniWorkflowNameInSet,
        taskNameInMiniWorkflow : ownProps.taskNameInMiniWorkflow
    }
};

const mapDispatchToProps2 = (dispatch, ownProps) => {
    return {
        initializeResultsPage : (experimentId) =>{
            dispatch (initializeResultsPage(experimentId))
        },
        recreateTrainingWorkflowInLP :  (experimentId,miniWorkflowSet,miniWorkflowKey) =>{
            dispatch (recreateTrainingWorkflowInLP (experimentId,miniWorkflowSet,miniWorkflowKey))
        },
        clearViewersState: () => {
            dispatch(clearViewersState())
        },
        updateViewersState: (viewers) => {
            dispatch(updateViewersState(viewers))
        },
        updateViewerProperty: (index, property, value) => {
            dispatch(updateViewerProperty(index, property, value))
        },
        goToPreviousSkillStep:()=>{
            dispatch(goToPreviousSkillStep())
        },
        clearSkillState:()=>{
            dispatch(clearSkillStateAC())
        }
    }
};

export const ContainerResultsPageINOS =  connect( mapStateToProps2, mapDispatchToProps2)(ResultsPageINOS);
export const ContainerResultsPageBirds =  connect( mapStateToProps2, mapDispatchToProps2)(ResultsPageBirds);

const score = {
    "final":
      {
          "livePresenterExperiment":  "8f09b60a78cf2eb3a438674fec00fcab",
          "miniWorkflowSet":  "8f09b60a78cf2eb3a438674fec00fcab",
          "miniWorkflow": "mw1",
          "score": 87,
          "max": 100,
          "value":false,                     // OR true
          "text":"You are not certified",    // OR "You can now contribute to the real experiment!"
          "linkText":"Try again" ,           // OR "Go to real experiment"
          "tooltip": "Final score: score1 * score2. Certified is the score > 80",
          "link": "/skills/playskill/8f09b60a78cf2eb3a438674fec00fcab"
      },
    "scoring": [
        {
            "name": "Lower slice",
            "value": 10,
            "max": 20,
            "view":"slices",
            "tooltip": "How many slices off? More than 4 you get zero points.\n" +
              "    - Slice difference -> Score\n" +
              "    - 1 -> 100\n" +
              "    - 2 -> 95\n" +
              "    - 3 -> 90\n" +
              "    - 4 -> 85\n" +
              "    - 5 -> 0\n"
        },
        {
            "name": "Segmentation",
            "value": 35.3385127636,
            "max": 80,
            "view":"volumes",
            "tooltip":"Correspondence of the volume, absolute =  100 - abs( 1-(volUser/volExpert) )  percentage difference."
        }
    ],
    "views": {
        "main":{
            "labelMapImageId": "cfc61966aa88162e1451a66ac6002491",
            "originalImageId": "575bddd50492d394fe3e506e21134850",
            "lut": "47313af7291048fda8797b016200158b",
            "lutDescription": "47313af7291048fda8797b0162004542"
        },
        "slices":{
            "originalImageId": "575bddd50492d394fe3e506e21134850",
            "userResult":{
                "sliceDirection": "AXIAL",
                "value": 45
            },
            "expertResult":{
                "sliceDirection": "AXIAL",
                "value": 50
            },
        },
        "volumes":{
            "labelMapImageId": "cfc61966aa88162e1451a66ac6002491",
            "originalImageId": "575bddd50492d394fe3e506e21134850",
            "lut": "47313af7291048fda8797b016200158b",
            "lutDescription": "47313af7291048fda8797b0162004542"
        }
    }
};
