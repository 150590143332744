import {
 POLY_REQUEST_FAILED, POLY_REQUEST_SUCCESS, POLY_REQUESTED
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


/**
 * }
 * @param state
 * @param action
 * @return {{polys: {}}|({} & {polys: {}} & {luts: any})}
 */
export const polyReducer = (state = {
  polys:{}, // polydata
}, action) => {
  switch (action.type) {
    case POLY_REQUESTED:{
      let stateCopy = Object.assign({},state.polys);
      let isPolyAlreadyInArray = stateCopy[action.polyId]!=null;
      if (isPolyAlreadyInArray)  //Maybe some conditions if error occurred (reload?)
        return state;
      let newImage = {};
      newImage['state'] =  REQUEST_STATUS_REQUESTED;
      stateCopy[action.polyId] = newImage;
      return Object.assign({}, state, {
        polys:Object.assign({},stateCopy)
      });
    }
    case POLY_REQUEST_FAILED:{
      let stateCopy = Object.assign({},state.polys);
      if (!(stateCopy[action.polyId]!=null))
        return state;
      let requestedImage = stateCopy[action.polyId];
      requestedImage['state'] =  REQUEST_STATUS_FAIL;
      requestedImage['data'] =  action.err;
      stateCopy[action.polyId]=Object.assign({},requestedImage);
      return Object.assign({}, state, {
        polys:Object.assign({},stateCopy)
      });
    }

    case POLY_REQUEST_SUCCESS:{
      let stateCopy = Object.assign({},state.polys);
      if (!(stateCopy[action.polyId]!=null))
        return state;
      let requestedImage = stateCopy[action.polyId];
      requestedImage['state'] =  REQUEST_STATUS_SUCCESS;
      requestedImage['data'] =  action.polyData;
      stateCopy[action.polyId]=Object.assign({},requestedImage);
      return Object.assign({}, state, {
        polys:Object.assign({},stateCopy)
      });
    }

    default:
      return state;
  }
};

