
/**
 * Class providing  accessors to data from Single Linear Model
 * obtained from  R Plumber and jsonlite serializer.
 * It works as a kind of mapper from R->Spine model.
 *
 *  This class has replaced previous solution based on mapping function and enums.
 *
 */
export class SLRModel{

    constructor(object, levelCI=0.95){
        let getIdx = function (name){return object.attributes.names.value.indexOf(name);};
        this._attributes = object.attributes.names.value;
        this._levelCI = levelCI;
        this._ebetas = object.value[getIdx('coefficients')].value.slice(0,2);
        this._stdbetas = object.value[getIdx('coefficients')].value.slice(2,4);
        this._tvaluesbetas = object.value[getIdx('coefficients')].value.slice(4,6);
        this._pvaluesbetas = object.value[getIdx('coefficients')].value.slice(6,8);
        this._betasCI =  object.value[getIdx('confintresult')].value;
        this._orderedx = object.value[getIdx('orderedx')].value;
        this._ci = object.value[getIdx('ci')].value;
        this._predi = object.value[getIdx('predi')].value;
        this._rsquared = object.value[getIdx('r.squared')].value;
        this._object = object;
    }

    getIndex(name){
        return this._attributes.indexOf(name);
    }

    /**
     *
     * @returns {*} confidence level as defined by object creation
     */
    get levelCI() {
        return this._levelCI;
    }
    /**
     *
     * @returns {*} raw object as it is generated by R with Plumber and jsonlite serializer
     */
    get object() {
        return this._object;
    }

    /**
     * @returns {*} beta estimations as 2 element array (intercept, slope)
     */
    get betasEstimates() {
        return this._ebetas;
    }

    /**
     * @returns {*} STD of beta estimations as 2 element array (intercept, slope)
     */
    get betasSTD() {
        return this._stdbetas;
    }

    /**
     * @returns {*} t-Values of beta estimations as 2 element array (intercept, slope)
     */
    get betasTValues() {
        return this._tvaluesbetas;
    }
    /**
     * Get p-values for intercept and slope params (i.e. Pr(>|t|))
     * @returns {*} p-Values of beta estimations as 2 element array (intercept, slope)
     */
    get betasPValues() {
        return this._pvaluesbetas;
    }

    /**
     * Get CI for intercept and slope params. These values are used to evaluate hypothesis H0!!!
     * @returns {*} confidence intervals for beta estimations as presented in R language
     * i.e. eg.
     *               2.5 %    97.5 %
     *     (Intercept) -8.5669158 5.9892763
     *                  0.1904498 0.2156429
     */
    get betasCI() {
        return this._betasCI;
    }
    /**
     * Get CI for intercept and slope params in the form convenient to present them in data table (Prime React Model),
     * i.e. as an array of objects.
     * These values are used to evaluate hypothesis H0!!!
     * @returns {*} confidence intervals for beta estimations as presented in R language
     * i.e. eg.
     *               2.5 %    97.5 %
     *     (Intercept) -8.5669158 5.9892763
     *                  0.1904498 0.2156429
     *
     * is represented as:
     * {lwr:'2.5%',
     *  upr:'97,5%',
     *  data: [{parameter:'Intercept', lwr:[-8.566..., 5.9892...], upr:[0.19..., 0.21...],},
     *         {parameter:'Slope', lwr:..., pwr:...}]
     * }
     *
     */
    get betasCIDataTableModel() {
        let result = {};
        let idx = this.getIndex('confintresult');
        result.lwr = this._object.value[idx].attributes.dimnames.value[1].value[0];
        result.upr = this._object.value[idx].attributes.dimnames.value[1].value[1];
        result.data =[];
        result.data.push({parameter:'Intercept',
            lwr: Number.parseFloat(this._object.value[idx].value[0]).toPrecision(4),
            upr: Number.parseFloat(this._object.value[idx].value[2]).toPrecision(4)});
        result.data.push({parameter:'Slope',
            lwr: Number.parseFloat(this._object.value[idx].value[1]).toPrecision(4),
            upr: Number.parseFloat(this._object.value[idx].value[3]).toPrecision(4)});
        return result;
    }

    /**
     * Get ordered x from LM model in ASCENDING ORDER.
     * These values can be used to draw splined lines (curves) for confidence intervals, prediction intervals, etc.
     * @returns {*}
     */
    get orderedx() {
        return this._orderedx;
    }

    /**
     *  Get confidence interval values calculated  for each point x in raw R format.
     *  (lpr,upr)
     * @returns {*} vector  of size [2xn] containing both lower values and upper values of CI calculated for
     * ordered x
     */
    get ci() {
        return this._ci;
    }

    /**
     *  Get prediction interval values for each point x.
     *  (lpr,upr)
     * @returns {*} vector  of size [2*n] containing both lower values and upper values of PI calculated for
     * ordered x
     */

    get predi() {
        return this._predi;
    }

    /**
     * Get confidence interval lower bound values calculated  for each point x.
     * @returns {*} vector  of size [n] containing lower bound values of CI calculated for
     * ordered ASCENDING x
     */
    get lowerboundci() {
        return this._ci.slice(0,this._ci.length/2);
    }


    /**
     * Get confidence interval lower bound values calculated  for each point x.
     * @returns {*} vector  of size [n] containing lower bound values of CI calculated for
     * ordered ASCENDING x
     */
    get upperboundci() {
        return this._ci.slice(this._ci.length/2);
    }

    /**
     * Get prediction interval lower bound values calculated  for each point x.
     * @returns {*} vector  of size [n] containing lower bound values of PI calculated for
     * ordered ASCENDING x
     */
    get lowerboundpi() {
        return this._predi.slice(0,this._predi.length/2);
    }


    /**
     * Get prediction interval lower bound values calculated  for each point x.
     * @returns {*} vector  of size [n] containing lower bound values of PI calculated for
     * ordered ASCENDING x
     */
    get upperboundpi() {
        return this._predi.slice(this._predi.length/2);
    }

    /**
     * Get r-squared value.
     * @returns {*} single value o R-squared coeff.
     */
    get rsquared(){
        return this._rsquared[0];
    }
}
