import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  getSelectedExtendedModelParameters,
  getSelectedPlotData,
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";

import {
  calculateModelForKappa,
  updateExtendedModelPropertySAM
} from "../action/statsAction";
import KappaCalculator from "../component/KappaCalculator";


const mapStateToProps = (state) => {
  return {
    rawData: state.sam.rawData,
    plotSettings: getSelectedPlotSettings(state),
    extendedModelParameters: getSelectedExtendedModelParameters(state),
    plotData: getSelectedPlotData(state)
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    calculateModelForKappa:(model)=>{
      dispatch(calculateModelForKappa(model))
    },
    updateExtendedModelPropertySAM:(property,value)=>{
      dispatch(updateExtendedModelPropertySAM(property,value))
    }
  }
};

export const ContainerKappaCalculator = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(KappaCalculator));