import axios from "axios/index";




/**
 * Async function to get a most recent results of materialized task
 * @param materializedTaskId
 * @return {Promise<Response<any>>}
 */
export const getMaterializedTaskResults = async (materializedTaskId) => {
  const response = await axios.get(`/api/materialized-task/${materializedTaskId}/result`);
  return response.data;
};


/**
 * To synchronize Redux state with state in DB.
 * This is required when autosave function is saving results to backend, and currentMaterializedTask needs to
 * be refreshed.
 * @param materializedTask
 * @return {Promise<void>}
 */
export const updateMaterializedTaskResults = async (materializedTask)=>{
  const recentMatTaskResults = await getMaterializedTaskResults(materializedTask.uuid);
  if (recentMatTaskResults!=null &&  Object.keys(recentMatTaskResults).length === 0){
    materializedTask["results"] = recentMatTaskResults;
    console.log("Updated Results",materializedTask)
  }
} ;
