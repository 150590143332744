import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {ERASER_TOOL} from "../../../../Constants";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {updateBrushTracingActor} from "../../../vtk/paintbrush/BrushTracingRenderer";


/**
 * Dumb component with template for Eraser Tool.
 * @param changeToolProperties(property,value) - callback
 * @param toolProperties - props object eg. {size:"1"}
 * @param toolConfiguration - initial configuration needed to set visibility of controls
 * @returns {*}
 */
export const EraserToolbar = ({toolProperties, changeToolProperties, toolConfiguration}) => {

    const control = (property, type) => toolConfiguration != null
        && toolConfiguration.properties != null
        && toolConfiguration.properties[property] != null
        && toolConfiguration.properties[property][type] != null
        && toolConfiguration.properties[property][type];

    const checkIfAnyPropertyVisible = Object.keys(toolConfiguration['properties'])
        .find((el) => {
            return toolConfiguration['properties'][el]['controlVisible']
        }) != null;

    return (
        <React.Fragment>
            {checkIfAnyPropertyVisible &&
            <Toolbar style={{display: "inline-block"}}>
                {control('radius', 'controlVisible') &&
                <React.Fragment>
                    <label style={{marginLeft: "1em"}}>Eraser Radius:</label>
                    <Spinner key="eraserSpinner"
                             value={toolProperties.radius}
                             min={0} max={100} step={1} size={3}
                             onChange={(e) => changeToolProperties(ERASER_TOOL, 'radius', e.value)}
                    /> {toolProperties.radius===0?"1 voxel":`${2*toolProperties.radius+1} by ${2*toolProperties.radius+1} voxels`}
                </React.Fragment>}
                {control('contour', 'controlVisible') &&
                <React.Fragment>
                    <label style={{marginLeft: "1em"}}>Contour:</label>
                    <Checkbox onChange={(e)=>{changeToolProperties(ERASER_TOOL, 'contour', e.checked)}}
                              checked={toolProperties.contour}
                              disabled={!control('contour', 'controlEnabled')}
                    />

                </React.Fragment>
                }
                {control('shape', 'controlVisible') &&
                <React.Fragment>
                    <label style={{marginLeft: "1em"}}>Shape:</label>
                    <Dropdown
                      options={[{value:"square",label:"square"},{value:"circle",label:"circle"},{value:"cityblock",label:"City block"}]}
                      value={toolProperties.shape}
                      onChange={(e)=>{
                          changeToolProperties(ERASER_TOOL, 'shape', e.value);
                          updateBrushTracingActor(null,{shape:e.value});
                      }} />
                </React.Fragment>
                }
            </Toolbar>}
        </React.Fragment>
    );
};

EraserToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    toolConfiguration: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func.isRequired
};
