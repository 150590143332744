import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {getNestedProp} from "../../helpers/expressions";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import {OverlayPanel} from "primereact/components/overlaypanel/OverlayPanel";
import {REQUEST_STATUS_FAIL} from "../../../Constants";


/**
 * Component designed for displaying Kappa Calculator.
 * It uses data from plot series.
 *
 */

class KappaCalculator extends React.Component {

  constructor(props) {
    super(props);
    ["updateKappaParams"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {extendedModelParameters, calculateModelForKappa} = this.props;
    if (!(getNestedProp(["kappaOutcome", "value"], extendedModelParameters) != null)) {
      calculateModelForKappa();
    }
  }

  componentDidUpdate(prevProps, prevState, ss) {
    const {extendedModelParameters, calculateModelForKappa} = this.props;
    if (prevProps.extendedModelParameters !== extendedModelParameters &&
      getNestedProp(["kappaParameters"], extendedModelParameters) !== getNestedProp(["kappaParameters"], prevProps.extendedModelParameters)) {
      calculateModelForKappa();
    }
  }

  updateKappaParams(prop, value) {
    const {extendedModelParameters, updateExtendedModelPropertySAM} = this.props;
    const copyParam = Object.assign({}, getNestedProp(["kappaParameters"], extendedModelParameters));
    copyParam[prop] = value;
    updateExtendedModelPropertySAM("kappaParameters", copyParam);
  }


  render() {
    const {extendedModelParameters} = this.props;

    return (

      <React.Fragment>
        <OverlayPanel ref={op => this.op = op}>
          <h4>Cohen’s Kappa and weighted Kappa for two raters</h4>
          <p><b>Description:</b>
          Calculates Cohen’s Kappa and weighted Kappa as an index of inter-rater agreement between 2 raters
          on categorical (or ordinal) data. Own weights for the various degrees of disagreement could be
            specified</p>
          <p><b>Details:</b>
          <ul>
            <li>Missing data are omitted in a listwise way.</li>
            <li>During computation, ratings are converted to factors. Therefore, the categories are ordered accordingly.
              When ratings are numeric, a sorting of factor levels occurs automatically. Otherwise, levels
              are sorted when the function is called with sort.levels=TRUE.
            </li>
            <li>Allows for calculating weighted Kappa coefficients. Beneath ’"unweighted"’ (default), predefined sets of
              weights are ’"equal"’ (all levels disagreement between raters are weighted equally) and
              "squared" (disagreements are weighted according to their squared distance from perfect agreement). The
              weighted Kappa coefficient with ’"squared"’ weights equals the product moment cor-
              relation under certain conditions. Own weights could be specified by supplying the function with
              a numeric vector of weights, starting from perfect agreement to worst disagreement. The length of
              this vector must equal the number of rating categories.
            </li>
          </ul>
          </p>
          <p><b>References:</b>
          <ol>
            <li><b>Various Coefficients of Interrater Reliability and Agreement (IRR)</b>, M.Gamer et al., Package
              ‘irr’, CRAN Repository, https://cran.r-project.org/web/packages/irr/irr.pdf)
            </li>
          </ol>
          </p>
        </OverlayPanel>

        <h3>Arguments</h3>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div title={"n*2 matrix, n subjects m raters."}>
            <label>Ratings</label>
            <SelectButton
              options={[{label: "Group Means", value: "means"}]}
              // onChange={(e)=>{this.updateKappaParams("ratings",e.value)}}
              value={"means"}/>
          </div>
          <div title={Object.values(MSG)[2]}>
            <label>{Object.keys(MSG)[2]}</label>
            <SelectButton
              options={[{value: "unweighted", label: "unweighted"}, {value: "equal", label: "equal"}, {
                value: "squared",
                label: "squared"
              }]}
              onChange={(e) => {
                this.updateKappaParams("weight", e.value)
              }}
              value={getNestedProp(["kappaParameters", "weight"], extendedModelParameters)}/>
          </div>
          <div title={Object.values(MSG)[3]}>
            <label>{Object.keys(MSG)[3]}</label>
            <SelectButton
              options={[{label: "Sorted", value: true}, {label: "Unsorted", value: false}]}
              onChange={(e) => {
                this.updateKappaParams("sort.levels", e.value)
              }}
              value={getNestedProp(["kappaParameters", "sort.levels"], extendedModelParameters)}/>
          </div>
          <div>
            <i className={"fa fa-question-circle-o"}
               onClick={(e) => this.op.toggle(e)}
               // onMouseOver={(e) => this.op.toggle(e)}
               // onMouseLeave={(e) => this.op.toggle(e)}
               style={{fontSize: "xxx-large", color: "#00D2D0"}}
            />
          </div>
        </div>
        <h3>Value</h3>
        <DataTable
          value={getNestedProp(["kappaOutcome"], extendedModelParameters)} // concat and remove dups
          emptyMessage={getNestedProp(["kappaOutcomeState"], extendedModelParameters) === REQUEST_STATUS_FAIL
            ? <span style={{color: "red"}}>R server problem</span>
            : "No statistics found"
          }
        >
          <Column field={"subjects"} header={<div title={Object.values(MSG)[0]}>{Object.keys(MSG)[0]}</div>}/>
          <Column field={"raters"} header={<div title={Object.values(MSG)[1]}>{Object.keys(MSG)[1]}</div>}/>
          <Column body={(row) => {
            return row['stat.name']
          }} header={<div title={Object.values(MSG)[2]}>{Object.keys(MSG)[2]}</div>}/>
          <Column field={"value"} header={<div title={Object.values(MSG)[3]}>{Object.keys(MSG)[3]}</div>}/>
          <Column body={(row) => {
            return row['p.value']
          }} header={<div title={Object.values(MSG)[4]}>{Object.keys(MSG)[4]}</div>}/>
          <Column field={"statistic"} header={<div title={Object.values(MSG)[5]}>{Object.keys(MSG)[5]}</div>}/>
        </DataTable>
        <div style={{fontStyle: "italic", float: "right"}}>Generated with use of CRAN package: <a
          style={{textDecoration: "underline"}} target={"_blank"}
          href={"https://cran.r-project.org/web/packages/irr/irr.pdf#nameddest=kappa2"}>Various Coefficients of Interrater Reliability
          and Agreement (IRR)</a></div>

      </React.Fragment>

    );
  }
}


const MSG = {
  "Cases": "The number of subjects (cases) examined",
  "Raters": "The number of raters",
  "Irr name": "A character string specifying the name of the coefficient",
  "Value": "The value of Kappa",
  "p-value": "The p-value for the test",
  "Statistic": "The value of the test statistic"
};


export default withTranslation()(KappaCalculator);


KappaCalculator.propTypes = {
  rawData: PropTypes.object.isRequired,
  extendedModelParameters: PropTypes.object.isRequired,
  plotData: PropTypes.array.isRequired,
  calculateModelForKappa: PropTypes.func.isRequired,
  updateExtendedModelPropertySAM: PropTypes.func.isRequired,
  t: PropTypes.func
};


// {
//   "method": "Cohen's Kappa for 2 Raters (Weights: equal)",
//   "subjects": "5",
//   "raters": "2",
//   "irr.name": "Kappa",
//   "value": "0.571428571428571",
//   "stat.name": "z",
//   "statistic": "2.23606797749979",
//   "p.value": "0.0253473186774682"
// }