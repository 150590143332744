import React from "react";
import { FaSearch } from "react-icons/fa";
import PropTypes from "prop-types";

const SearchBar = ({ onChange, placeholder }) => {
  return (
    <div className="Search">
      <span className="SearchSpan">
        <FaSearch />
      </span>
      <input className="SearchInput" type="text" onChange={onChange} placeholder={placeholder} />
    </div>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SearchBar;
