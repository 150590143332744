import React from 'react'
import PropTypes from "prop-types";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {withTranslation} from 'react-i18next';
import {ContainerLayoutMultiPlotPanel} from "../containers/ContainerLayoutMultiPlotPanel";
import {ContainerBlandAltmanPanel} from "../containers/ContainerBlandAltmanPanel";
import {Button} from "primereact/components/button/Button";
import {ContainerDistributionPanel} from "../containers/ContainerDistributionPanel";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import {ContainerCustomAnalysisPanel} from "../containers/ContainerCustomAnalysisPanel";
import {ContainerRegressionPanel} from "../containers/ContainerRegressionPanel";


/**
 * Component for displaying agreement experiment results grouping options.
 * Version for multiple diagrams.
 *
 */
class SAMSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }; //initial state is in action initializeBlandAltmanPlotSegmentations
    [ "onApplyMatching", "onCancel","onApply"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }



  onApplyMatching() {
    const {updatePlotSettings,updatePropertySAM} = this.props;
    updatePlotSettings(this.state);
    // updatePropertySAM("optionsSidebarVisible",false);
  }

  onApply(){
    const {updateBlandAltmanPlotData,plotSettings,updateScatterPlotData,updateRegressionPlotData} = this.props;
    if (plotSettings.mode===0)
      updateBlandAltmanPlotData();
    if (plotSettings.mode===1)
      updateScatterPlotData();
    if (plotSettings.mode===3)
      updateRegressionPlotData();
  }

  onCancel() {
    const {updatePropertySAM} = this.props;
    updatePropertySAM("optionsSidebarVisible",false);
  }

  // renderMatchingAlgorithm(){
  //   const {t} = this.props;
  //   return (
  //     <React.Fragment>
  //       <div className="ui-g-12">
  //         <h2 style={{fontWeight: 'normal'}}>Threshold Distance (mm)</h2>
  //         <InputText
  //           inputId="thresholdDistance"
  //           type="number"
  //           keyFilter="pnum"
  //           min="0"
  //           value={this.state.thresholdDistance}
  //           onChange={(e) => {
  //             const parsedThresholdDistance = parseFloat(e.target.value);
  //             this.setState({thresholdDistance: parsedThresholdDistance})
  //           }}/>
  //       </div>
  //       <div className="ui-g-12" style={{height: '5%'}}>
  //         <Button label={t("general.button.apply.label")} onClick={this.onApplyMatching}/>
  //         <Button label={t("general.button.cancel.label")} onClick={() => this.props.onHide()}/>
  //       </div>

  //     </React.Fragment>
  //   );
  // }

  render() {
    const {optionsSidebarVisible, position,  t ,plotSettings,updatePropertySAM,refreshAllPlots,switchAnalysisMode} = this.props;

    const disabledUpdatePlot = plotSettings.mode === 0
      ? plotSettings.minNumberOfGroups > plotSettings.groups.length || plotSettings.maxNumberOfGroups < plotSettings.groups.length
      : false;
    const titleUpdatePlot = plotSettings.mode === 0
      ? plotSettings.minNumberOfGroups > plotSettings.groups.length || plotSettings.maxNumberOfGroups < plotSettings.groups.length ?"Improper number of groups":"Update plot"
      : "Update plot";

    // TODO: Change hardcoded labels to use internationalization
    return (
      <Sidebar
        position={position}
        visible={optionsSidebarVisible}
        onHide={() =>  updatePropertySAM("optionsSidebarVisible",false)}
        style={{overflow: 'auto',width:"450px"}}
      >
        {/* // TODO: Use tabs to put differentiate elements to be rendered */}
        <h1 style={{fontWeight: 'normal'}}>
          <i className="fa fa-cog" style={{color: "green", margin: "0.5em 0.25em 0px 0px"}}/>
          {t("agreement.sidebar.configuration.header")}
        </h1>
        <h2 style={{fontWeight: 'normal'}}> {"Layout"}</h2>
        <ContainerLayoutMultiPlotPanel/>
        <SelectButton value={ plotSettings.mode}
                      options={[{label:"Agreement",value:0},{label:"Distribution",value:1},{label:"Regression",value:3},{label:"Custom",value:2}]}
                      onChange={(e)=>{switchAnalysisMode(e.value)}}
        />
        {plotSettings.mode===0 && <ContainerBlandAltmanPanel/>}
        {plotSettings.mode===1 &&  <ContainerDistributionPanel/>}
        {plotSettings.mode===2 &&  <ContainerCustomAnalysisPanel/>}
        {plotSettings.mode===3 &&  <ContainerRegressionPanel/>}
        <div className="ui-g-12" style={{height: '5%'}}>
          <Button label={"Refresh plot"}
                  onClick={this.onApply}
                  disabled={disabledUpdatePlot}
                  title={titleUpdatePlot}
          />
          <Button label={"Refresh all plots"} onClick={() => refreshAllPlots()}/>
          <Button label={"Close sidebar"} onClick={() => updatePropertySAM("optionsSidebarVisible",false)}/>
        </div>
        {/*<h1 style={{fontWeight: 'normal'}}>*/}
        {/*  <i className="fa fa-envelope" style={{color: "green", margin: "0.5em 0.25em 0px 0px"}}/>*/}
        {/*  {t("agreement.sidebar.messages.header")}*/}
        {/*</h1>*/}
        {/*<Accordion>*/}
        {/*  <AccordionTab header="Data">*/}
        {/*    {this.state.emptyCases != null && this.state.emptyCases.length>0*/}
        {/*      ? 'Warning - The following cases don\'t include any ROI and will not be shown: \n\n'*/}
        {/*      + this.state.emptyCases.toString().replace(/,/g, '\n\n')*/}
        {/*      : 'All the data was loaded'}*/}
        {/*  </AccordionTab>*/}
        {/*</Accordion>*/}
      </Sidebar>
    )
  }
}

export default withTranslation()(SAMSidebar);

SAMSidebar.defaultProps = {
  position:"right"
};
SAMSidebar.propTypes = {
  optionsSidebarVisible: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  plotSettings: PropTypes.object.isRequired,
  clearInteractivePlotState: PropTypes.func.isRequired,
  updatePlotSettings: PropTypes.func.isRequired,
  updateBlandAltmanPlotData:PropTypes.func.isRequired,
  updatePropertySAM:PropTypes.func.isRequired,
  refreshAllPlots:PropTypes.func.isRequired,
  switchAnalysisMode:PropTypes.func.isRequired,
  updateScatterPlotData:PropTypes.func.isRequired,
  updateRegressionPlotData:PropTypes.func.isRequired,
  t: PropTypes.func
};
