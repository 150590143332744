import {
    SPM_FAIL, SPM_VIEW_SUCCESS, SPM_VIEW_REQUEST, CLEAR_SPM_STATE
} from '../action/actionType'
import {combineReducers} from "redux";


export const SPMReducer = ( state = { spm: {} }, action) => {

    switch (action.type) {

        case SPM_VIEW_REQUEST:
          return Object.assign({}, state, {
              status: "requesting"
          });

        case  SPM_FAIL:
          return Object.assign({}, state, {
              error: action.error,
              status: "error"
          });

        case SPM_VIEW_SUCCESS:
            return Object.assign({}, state, {
                spm: {"workload": action.result},
                status: "success"
            });

        case CLEAR_SPM_STATE:
          return Object.assign({}, state, {
              spm: {},
              status: "",
              error: ""
          });

        default:
            return state;
    }
};
