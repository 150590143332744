import React, { useState } from "react";
import {
  getRenderCurrentSlide,
  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK,
  SLIDE_PROPERTY_NAME__SLIDE_TYPE,
} from "../utils/livePresenterHelpers";

const PlaySlidesManual = (props) => {
  const { slides, type } = props;
  const [slideIndex, setSlideIndex] = useState(0);

  let sortedSlides = null;
  if (slides != null && slides.length > 0) {
    sortedSlides = slides.sort((a, b) => {
      return parseInt(a.orderNumber) - parseInt(b.orderNumber);
    });
  }

  const plusSlides = (n) => {
    let newValue = slideIndex + n;
    if (slideIndex + n >= props.slides.length || slideIndex + n < 0) return; // no change
    setSlideIndex(newValue);
  };

  let actualSlideRender = null;
  if (sortedSlides) {
    let currentSlide = sortedSlides[slideIndex];
    actualSlideRender = getRenderCurrentSlide(currentSlide);
  }

  return (
    <>
      {type === "slideShow" ? (
        <>
          {slides && slides.length > 0 && (
            <div className="slideshow-div">
              {actualSlideRender}
              {slides[slideIndex][SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase() !==
                SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK &&
                slideIndex > 0 && (
                  <a className="prev" onClick={() => plusSlides(-1)}>
                    &#10094;
                  </a>
                )}
              {slides[slideIndex][SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase() !==
                SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK &&
                slideIndex <= slides.length && (
                  <a className="next" onClick={() => plusSlides(1)}>
                    &#10095;
                  </a>
                )}
            </div>
          )}
        </>
      ) : (
        <div class="carousel-wrapper">
          {slides && slides.length > 0 && (
            <div class="carousel-item">
              {actualSlideRender}
              <a class="arrow arrow-prev" onClick={() => plusSlides(-1)}></a>
              <a class="arrow arrow-next" onClick={() => plusSlides(1)}></a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PlaySlidesManual;
