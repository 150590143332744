import {connect} from "react-redux";
import PersonalPage from "../PersonalPage";




const mapStateToProps = (state, ownProps) => {
    console.log("tasks", state.expDesign.tasks.tasks);
    return {
        auth: state.auth,
    }
};

/**
 * Methods to create/edit personal info should be provided here...
 *
 * @param dispatch
 * @param ownProps - call params functions
 * @returns {*}
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
};

const ContainerPersonalPage = connect( mapStateToProps, mapDispatchToProps)(PersonalPage);
export default  ContainerPersonalPage;