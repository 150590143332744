import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {Dialog} from "primereact/components/dialog/Dialog";
import {withTranslation} from 'react-i18next';
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Spinner} from "primereact/components/spinner/Spinner";

/**
 * Component used for Other parameters panel.
 */

class BAParametersPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      percentageDifference: false,  // parameter from Other parameters
      aggregateCalculation: "mean",
      missingContributionsStrategy: "ignored",
      levelAgreementValue: 80
    };
  }

  componentDidMount() {
    this.setState(
      {
        percentageDifference: this.props.percentageDifference,
        aggregateCalculation: this.props.aggregateCalculation,
        missingContributionsStrategy: this.props.missingContributionsStrategy,
        levelAgreementValue: this.props.levelAgreementValue
      }
    )
  }

  componentDidUpdate(prevProps, prevState, ss) {
    if (prevState.dialogVisible !== this.state.dialogVisible && this.state.dialogVisible)
      this.setState(
        {
          percentageDifference: this.props.percentageDifference,
          aggregateCalculation: this.props.aggregateCalculation,
          missingContributionsStrategy: this.props.missingContributionsStrategy,
          levelAgreementValue: this.props.levelAgreementValue
        }
      )
  }


  render() {
    const {t, updatePlotSettingsProperty, missingContributionsStrategy, percentageDifference, aggregateCalculation, levelAgreementValue} = this.props;

    return (
      <div>
        <h3>Agreement parameters
          <Button onClick={() => {
            this.setState({dialogVisible: true})
          }}
                  icon={"fa fa-edit"}
                  title={"Edit"}
                  style={{float: "right"}}
          />
        </h3>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            {"Difference expressed in: "} <b>{(percentageDifference) ? "%" : "absolute values"}</b><br/>
            {"Strategy for handling missing contribution: "}<b>{MISSING_OPTIONS.find(el => el.value === missingContributionsStrategy).label}</b><br/>
            {"Results of group aggregated with use of: "}<b>{AGGREGATE_OPTIONS.find(el => el.value === aggregateCalculation).label}</b><br/>
            {"Level of agreement [%]: "}<b>{levelAgreementValue}</b>
          </div>
          <Dialog
            header={"Other parameters"}
            width={600}
            height={400}
            visible={this.state.dialogVisible}
            appendTo={document.body}
            onHide={() => {
              this.setState({dialogVisible: false})
            }}>

            <div style={{height: "300px"}}>
              <div className="ui-g-12">
                <Checkbox
                  inputId="difference" style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({percentageDifference: e.checked})
                  }}
                  checked={this.state.percentageDifference}>
                </Checkbox>
                <label htmlFor="difference"
                       className="ui-checkbox-label">
                  {"Difference as a %"}
                </label>
              </div>
              <div className="ui-g-12">
                <Dropdown
                  inputId="aggregation" style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({aggregateCalculation: e.value})
                  }}
                  options={AGGREGATE_OPTIONS}
                  value={this.state.aggregateCalculation}>
                </Dropdown>
                <label htmlFor="aggregation"
                       className="ui-checkbox-label">
                  {"Aggregation within group"}
                </label>
              </div>
              <div className="ui-g-12">
                <Dropdown
                  inputId="missing" style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({missingContributionsStrategy: e.value})
                  }}
                  options={MISSING_OPTIONS}
                  value={this.state.missingContributionsStrategy}>
                </Dropdown>
                <label htmlFor="missing"
                       className="ui-checkbox-label">
                  {"Strategy for missing contributions"}
                </label>
              </div>
              <div className="ui-g-12">
                <Spinner
                  id={"levelAgreement"}
                  style={{marginRight: "1em", width: "15em"}}
                  step={1}
                  maxlength={3}
                  size={3}
                  min={0}
                  max={100}
                  title="Level Agreement Value within the group in %"
                  value={levelAgreementValue}
                  onChange={(e) => {
                    this.setState({levelAgreementValue: e.value})
                  }}
                /><label htmlFor={"levelAgreement"} className="ui-checkbox-label">Level of agreement</label>
              </div>
            </div>
            <div>
              <Button onClick={() => {
                this.setState({dialogVisible: false});
                updatePlotSettingsProperty("aggregateCalculation", this.state.aggregateCalculation);
                updatePlotSettingsProperty("percentageDifference", this.state.percentageDifference);
                updatePlotSettingsProperty("missingContributionsStrategy", this.state.missingContributionsStrategy);
                updatePlotSettingsProperty("levelAgreementValue", this.state.levelAgreementValue);
              }} icon={"fa fa-check"} label={"OK"}/>
              <Button onClick={() => {
                this.setState({dialogVisible: false})
              }} icon={"fa fa-close"} label={"Cancel"}/>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

const AGGREGATE_OPTIONS = [
  {value: "mean", label: "mean"},
  {value: "median", label: "median"},
  {value: "tmean", label: "trimmed mean"}
];

const MISSING_OPTIONS = [
  {value: "rejected", label: "reject case"},
  {value: "extrapolated", label: "extrapolate contribution"},
  {value: "ignored", label: "ignore contribution"}
];

export default withTranslation()(BAParametersPanel);

BAParametersPanel.propTypes = {
  updatePlotSettingsProperty: PropTypes.func.isRequired,
  percentageDifference: PropTypes.bool.isRequired,
  aggregateCalculation: PropTypes.string.isRequired,
  missingContributionsStrategy: PropTypes.string.isRequired,
  levelAgreementValue: PropTypes.number.isRequired,
  t: PropTypes.func
};
