import React, { Component } from 'react'
import PropTypes from "prop-types";
import {REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import Timeline from "./Timeline";
import {withRouter} from "react-router";


class TimelinePanel extends Component {



  render() {
    const {timelineData,timelineDataState,enrollments} = this.props;

    const getEmail = (subId)=>{
      const subject = enrollments.find(el=>el.subjectId === subId);
      if (subject!=null)
        return subject.email;
    else return subId;
    };

      return(<React.Fragment>
        {timelineDataState===REQUEST_STATUS_REQUESTED &&
          <div className="spinner-centered">Loading timeline data <i className="fa fa-spinner fa-spin"/></div>}
        <Timeline data={timelineData} yLabel={""} xLabel={"Time"} yAttribute={"subjectId"} yAttributeFunction={getEmail} colorCat={"category"}/>
    </React.Fragment>);
  }
}
export default withRouter(TimelinePanel)

TimelinePanel.propTypes = {
  timelineData: PropTypes.array.isRequired,
  timelineDataState: PropTypes.string.isRequired
};