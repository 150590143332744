import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {RadioButton} from "primereact/components/radiobutton/RadioButton";
import {Button} from "primereact/components/button/Button";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";
import {Dialog} from "primereact/components/dialog/Dialog";
import {MultiSelect} from "primereact/components/multiselect/MultiSelect";
import {getNestedProp} from "../../helpers/expressions";
import {IMAGE_URL} from "../../../Constants";
import {getContributorName, getContributorType} from "../action/SAMAction";
import {ListBox} from "primereact/components/listbox/ListBox";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Spinner} from "primereact/components/spinner/Spinner";


/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class GroupPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      insertMode: false,
      groupIndex: 0,
      color: "#000000",
      contributors: [],
      filterGenderOn: false,
      filterCountryOn: false,
      filterLanguageOn: false,
      filteredTypeOn:false,
      filterProfessionOn: false,
      filterAgeOn: false,
      filteredProfession: "",
      filteredGender: "",
      filteredLanguage: "",
      filteredCountryOfResidence: "", // this is default
      filterAgeFrom: 0,
      filterAgeTo: 100,
      filterType:"",
      filterOptions: {}, // constant - OPTIONS from JSON
    };
    ["renderGroups", "onAddGroup", "renderGroupMembers", "onEdit", "onRemove", "onApplyFilters"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {rawData, t} = this.props;

    let filterOptions = {};

    filterOptions['profession'] = [...new Set(rawData.contributors.map(el => el.profession))] // remove duplicates
      .filter(el => {
        return el != null
      }) //remove empty
      .map(el => {
        return {value: el, label: el}
      }); // prepare labels


    filterOptions['language'] = [...new Set(rawData.contributors.map(el => el.language))]
      .filter(el => {
        return el != null
      }) //remove empty
      .map(el => {
        return {value: el, label: t('languages:'+el)}
      });

    filterOptions['countryOfResidence'] = [...new Set(rawData.contributors.map(el => el.countryOfResidence))]
      .filter(el => {
        return el != null
      }) //remove empty
      .map(el => {
        return {value: el, label:t('countries:'+el)}
      });

    filterOptions['gender'] = [...new Set(rawData.contributors.map(el => el.gender))]
      .filter(el => {
        return el != null
      }) //remove empty
      .map(el => {
        return {value: el, label: t('gender:'+el)}
      });

    filterOptions['type'] = [...new Set(rawData.contributors.map(el => el.type))]
      .filter(el => {
        return el != null
      }) //remove empty
      .map(el => {
        return {value: el, label: el}
      });

    this.setState({filterOptions});


  }

  onApplyFilters() {
    const {filteredGender, filterGenderOn, filterCountryOn, filterLanguageOn, filteredCountryOfResidence, filterProfessionOn,
      filteredProfession, filteredLanguage, filterTypeOn, filteredType, filterAgeOn,
      filterAgeFrom, filterAgeTo} = this.state;
    const {rawData} = this.props;

    const filtered = rawData.contributors
      .filter(contributor => {
        return (!filterGenderOn || filteredGender.includes(contributor.gender))
      })
      .filter(contributor => {
        return (!filterCountryOn || filteredCountryOfResidence.includes(contributor.countryOfResidence))
      })
      .filter(contributor => {
        return (!filterLanguageOn || filteredLanguage.includes(contributor.language))
      })
      .filter(contributor => {
        return (!filterProfessionOn || filteredProfession.includes(contributor.profession))
      })
      .filter(contributor => {
        return (!filterTypeOn || filteredType.includes(contributor.type))
      })
      .filter(contributor => {
        return (!filterAgeOn || (
          contributor.birthYear!=null
          && (filterAgeFrom <= (new Date().getYear()+1900 - Number(contributor.birthYear)))
          && (filterAgeTo >= (new Date().getYear()+1900 - Number(contributor.birthYear)))
          )
        )
      })
      .map(el => el.uuid);
    const duplicates = this.state.contributors
      .filter(function (val) {
        return filtered.indexOf(val) !== -1;
      });
    this.setState({
      contributors: duplicates
    });
  }

  onAddGroup() {
    const {plotSettings} = this.props;
    const groupNumber = plotSettings.groups.length;
    const randomColor = "#000000".replace(/0/g, function () {
      return (~~(Math.random() * 16)).toString(16);
    });

    this.setState({
      dialogVisible: true,
      insertMode: true,
      groupIndex: groupNumber,
      color: groupNumber === 0 ? plotSettings.initialGroupColor : randomColor,
      contributors: [],
      filteredProfession: "",
      filteredGender: "",
      filteredType: "",
      filterGenderOn: false,
      filterCountryOn: false,
      filterLanguageOn: false,
      filterProfessionOn: false,
      filterTypeOn:false,
      filterAgeOn: false,
      filterAgeFrom: 0,
      filterAgeOnto: 100,
      filteredLanguage: "",
      filteredCountryOfResidence: "", // this is default
    });
  }

  onEdit(index) {
    const {groups = []} = this.props.plotSettings;
    this.setState({
      dialogVisible: true,
      insertMode: false,
      groupIndex: index,
      color: groups[index].color,
      contributors: groups[index].contributors,
      filteredProfession: groups[index].filteredProfession,
      filteredGender: groups[index].filteredGender,
      filteredType: groups[index].filteredType,
      filterGenderOn: groups[index].filterGenderOn,
      filterCountryOn: groups[index].filterCountryOn,
      filterLanguageOn: groups[index].filterLanguageOn,
      filterProfessionOn: groups[index].filterProfessionOn,
      filteredLanguage: groups[index].filteredLanguage,
      filteredCountryOfResidence: groups[index].filteredCountryOfResidence,
      filterAgeOn: groups[index].filterAgeOn,
      filterAgeFrom: groups[index].filterAgeFrom,
      filterAgeOnto: groups[index].filterAgeTo
    });
  }

  onRemove(index) {
    const {updatePlotSettingsProperty} = this.props;
    const {groups = []} = this.props.plotSettings;
    let groupsTemp = [...groups];
    groupsTemp.splice(index, 1);
    updatePlotSettingsProperty("groups", groupsTemp);
  }


  renderGroupMembers(groupIndex) {
    const {groups = []} = this.props.plotSettings;
    const {rawData} = this.props;
    return groups[groupIndex]
      .contributors
      .map((el, key) =>
      {
        if (key>9)
          return <div className={"fa fa-ellipsis-h"} style={{
            color:groups[groupIndex].color,
            fontSize: "20px",
            transform: "translate(0px, 15px)"
          }} title={"...and ".concat(String(groups[groupIndex].contributors.length - 10), " more") } />;
        if (key>10)
          return <React.Fragment/>;
        if (getContributorType(el,rawData)==="user")
        return ( <img
        key={"group" + groupIndex + "_" + key}
        src={IMAGE_URL(el)}
        className="groupMemberIcon"
        // alt={getContributorName(el,rawData).split(" ").map((n)=>n[0]).join("")} //get initials only
        style={{
          border: "solid 2px" + groups[groupIndex].color
        }}
        title={getContributorName(el, rawData)}/>);
        return (<div className={"wrkFreesurferLogo groupMemberIcon"}
                     style={{
                       border: "solid 2px" + groups[groupIndex].color,
                       verticalAlign:"middle",
                       display:"inline-block"
                     }}
                     title={getContributorName(el, rawData)}
                     />);
      })
  }

  renderGroups() {
    const {updatePlotSettingsProperty, t} = this.props;
    const {groups = []} = this.props.plotSettings;

    return (
      groups.map((item, key) =>
        <div key={key} style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
          <div>
            {this.renderGroupMembers(key)}
          </div>
          <div>
            <Button
              title={t("translation:general.button.edit.label")}
              icon="fa fa-edit" onClick={() => {
              this.onEdit(key)
            }}/>
            <Button
              title={t("translation:general.button.remove.label")}
              icon="fa fa-trash" onClick={() => {
              this.onRemove(key)
            }}/>
          </div>
        </div>)
    );
  }


  render() {
    const {t, rawData, plotSettings, updatePlotSettingsProperty} = this.props;
    const {filterGenderOn, filterCountryOn, filterLanguageOn,filterTypeOn,  filterProfessionOn, filterAgeFrom, filterAgeTo, filterAgeOn} = this.state;
    return (
      <React.Fragment>
        {this.state.possibleRatersAndCasesForIntra != null && this.state.possibleRatersAndCasesForIntra.length > 0 &&
        <div className="ui-g-12">
          <h3 style={{fontWeight: 'normal'}}>Intra-rater data available</h3>
          <div className="ui-g-12" style={{marginRight: "1em"}}>
            <RadioButton
              inputId="intraRaterRb1"
              name="intra-rater"
              value="ALL_DATA"
              onChange={(e) => {
                this.setState({intraRaterHandling: e.value})
              }}
              checked={this.state.intraRaterHandling === 'ALL_DATA'}>
            </RadioButton>
            <label htmlFor="intraRaterRb1"
                   className="ui-radiobutton-label">
              {"Use all the data available for the raters per case"}
            </label>
          </div>
          <div className="ui-g-12" style={{marginRight: "1em"}}>
            <RadioButton
              inputId="intraRaterRb2"
              name="intra-rater"
              value="FIRST_MATCH_DATA"
              onChange={(e) => {
                this.setState({intraRaterHandling: e.value})
              }}
              checked={this.state.intraRaterHandling === 'FIRST_MATCH_DATA'}>
            </RadioButton>
            <label htmlFor="intraRaterRb2"
                   className="ui-radiobutton-label">
              {"Use raters first attempt found per case"}
            </label>
          </div>
        </div>}
        <div style={{maxHeight: '70%'}}>
          {this.renderGroups()}
        </div>
        <Button label="Add Group"
                onClick={this.onAddGroup}
                disabled={getNestedProp(["maxNumberOfGroups"], plotSettings) === getNestedProp(["groups", "length"], plotSettings)}
                title={getNestedProp(["maxNumberOfGroups"], plotSettings) === getNestedProp(["groups", "length"], plotSettings) ? "Maximum number of groups has been reached" : "Add group"}
        />

        <Dialog
          header={"Group " + (this.state.groupIndex + 1)}
          width={800}
          height={600}
          visible={this.state.dialogVisible}
          appendTo={document.body}
          onHide={() => {
            this.setState({dialogVisible: false})
          }}>
          <div style={{maxWidth: '100%'}}>
            <div className="ui-g-12">
              <h3>Filters</h3>
              <div  className={"filter-row-sam"}>
                <div className="ui-checkbox-label" style={{width:"10em"}}/>
                <div className="ui-checkbox-label" style={{width:"60%"}}/>
                <div className="ui-checkbox-label" style={{width:"1em",transform:"translate(-10px, 0px)"}}>{"Apply"}</div>
              </div>
              <div  className={"filter-row-sam"}>
                <label htmlFor="type" className="ui-checkbox-label" >{"Type"}</label>
                <MultiSelect
                  id={"type"}
                  options={this.state.filterOptions.type}
                  value={this.state.filteredType}
                  onChange={(e) => this.setState({filteredType: e.value})}
                  filter={true}
                />
                <Checkbox checked={filterTypeOn} onChange={e => this.setState({filterTypeOn: e.checked})}/>
              </div>
              <div className={"filter-row-sam"}>
                <label htmlFor="prof" className="ui-checkbox-label" >{"Profession"}</label>
                <MultiSelect
                  id={"prof"}
                  options={this.state.filterOptions.profession}
                  value={this.state.filteredProfession}
                  onChange={(e) => this.setState({filteredProfession: e.value})}
                  filter={true}
                />
                <Checkbox checked={filterProfessionOn} onChange={e => this.setState({filterProfessionOn: e.checked})}/>
              </div>
              <div  className={"filter-row-sam"}>
                <label htmlFor="countryOfResidence" className="ui-checkbox-label"
                       >{"Country"}</label>
                <MultiSelect
                  id={"country"}
                  options={this.state.filterOptions.countryOfResidence}
                  value={this.state.filteredCountryOfResidence}
                  onChange={(e) => this.setState({filteredCountryOfResidence: e.value})}
                  filter={true}
                />
                <Checkbox checked={filterCountryOn} onChange={e => this.setState({filterCountryOn: e.checked})}/>
              </div>
              <div  className={"filter-row-sam"}>
                <label htmlFor="lang" className="ui-checkbox-label" >{"Language"}</label>
                <MultiSelect
                  id={"lang"}
                  options={this.state.filterOptions.language}
                  value={this.state.filteredLanguage}
                  onChange={(e) => this.setState({filteredLanguage: e.value})}
                  filter={true}
                />
                <Checkbox checked={filterLanguageOn} onChange={e => this.setState({filterLanguageOn: e.checked})}/>
              </div>
              <div  className={"filter-row-sam"}>
                <label htmlFor="gender" className="ui-checkbox-label" >{"Gender"}</label>
                <MultiSelect
                  id={"gender"}
                  options={this.state.filterOptions.gender}
                  value={this.state.filteredGender}
                  onChange={(e) => this.setState({filteredGender: e.value})}
                  filter={true}
                />
                <Checkbox checked={filterGenderOn} onChange={e => this.setState({filterGenderOn: e.checked})}/>
              </div>
              <div  className={"filter-row-sam"}>
                <label htmlFor="year" className="ui-checkbox-label" >{"Age"}</label>
                <div style={{width: '60%', whiteSpace: 'nowrap'}}>
                  {"From:"}
                  <input type="number"
                         id="year"
                         onChange={(e) => this.setState({filterAgeFrom: Number(e.target.value)})}
                         min={0} step={1} max={100}
                         value={filterAgeFrom}
                         size={2}
                  />
                  {"To:"}
                  <input type="number"
                         onChange={(e) => this.setState({filterAgeTo: Number(e.target.value)})}
                         min={0} step={1} max={100}
                         value={filterAgeTo}
                         size={2}
                  />
                </div>
                <Checkbox checked={filterAgeOn} onChange={e => this.setState({filterAgeOn: e.checked})}/>
              </div>
            </div>
            <div className="ui-g-12 ui-g-nopad">
              <h3>Group members</h3>
              <ColorPicker value={this.state.color}
                           onChange={(e) => {
                             this.setState({color: `#${e.value}`});
                           }}
                           style={{opacity: 1}}/>
              <Button
                label={t("translation:general.button.selectall.label")}
                onClick={() => this.setState({contributors: rawData.contributors.map(e => e.uuid)})}/>
              <Button
                label={t("translation:general.button.deselectall.label")}
                onClick={() => this.setState({contributors: []})}/>
              <Button
                label={"Apply filters"}
                onClick={this.onApplyFilters}/>

              <ListBox
                options={rawData.contributors}
                value={this.state.contributors}
                onChange={(e) => {
                  this.setState({contributors: e.value});
                }
                }
                filter={true}
                multiple={true}
                style={{overflowY: 'scroll', width: 'auto',height:"200px"}}
              />
            </div>
            {/* // Change Contributors manual selection for a filter component that takes as input harcoded definitions and then puts the rules in the plot settings?
            /* TODO: A filter per group is needed. As it is here you have the reference to the filter will be overwrite with each new group
            */}


          </div>
          <div>
            <Button onClick={() => {
              this.setState({dialogVisible: false});
              const groups = this.props.plotSettings.groups.slice(0);
              const copy =
                {
                  color: this.state.color,
                  contributors: this.state.contributors,
                  filteredProfession: this.state.filteredProfession,
                  filteredGender: this.state.filteredGender,
                  filteredType: this.state.filteredType,
                  filterGenderOn: this.state.filterGenderOn,
                  filterCountryOn: this.state.filterCountryOn,
                  filterLanguageOn: this.state.filterLanguageOn,
                  filterProfessionOn: this.state.filterProfessionOn,
                  filteredLanguage: this.state.filteredLanguage,
                  filteredCountryOfResidence: this.state.filteredCountryOfResidence,
                  filterTypeOn: this.state.filterTypeOn,
                  filterAgeOn: this.state.filterAgeOn,
                  filterAgeFrom: this.state.filterAgeFrom,
                  filterAgeTo: this.state.filterAgeTo
                };
              if (this.state.insertMode)
                groups.push(copy);
              else
                groups[this.state.groupIndex] = copy;
              updatePlotSettingsProperty("groups", groups);
            }} icon={"fa fa-check"} label={"OK"}
                    disabled={this.state.contributors.length === 0}
                    title={this.state.contributors.length === 0 ? "Group is empty. Please select at least one member." : "OK"}
            />
            <Button onClick={() => {
              this.setState({dialogVisible: false})
            }} icon={"fa fa-close"} label={"Cancel"}
            />
          </div>

        </Dialog>
      </React.Fragment>
    );
  }
}

export default withTranslation(['languages','countries','gender','translation'])(GroupPanel);

GroupPanel.propTypes = {
  rawData: PropTypes.object.isRequired,
  plotSettings: PropTypes.object.isRequired,
  updatePlotSettingsProperty: PropTypes.func.isRequired,
  t: PropTypes.func
};
