import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Simple component
 */
class AuthorizationWrapper extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {authorization,roles,groups} = this.props;

    let  isInRole =false;
    let isInGroup = false;

    roles.forEach(role=>{

      if (authorization.roles!=null && authorization.roles.findIndex((r)=>{return r===role})>-1)
        isInRole = true;
    });

    groups.forEach(role=>{
      if (authorization.roles!=null && authorization.roles.findIndex((r)=>{return r===role})>-1)
        isInGroup = true;
    });

    if (isInRole || isInGroup)
      return <React.Fragment>{this.props.children}</React.Fragment>;
    else
      return <React.Fragment/>;
  }
}

AuthorizationWrapper.propTypes = {
  roles: PropTypes.array,
  groups: PropTypes.array
};

AuthorizationWrapper.defaultProps = {
  roles: [],
  groups: []
};

const mapStateToProps = state => {
  return{
    authorization : state.auth
  }
};

const mapDispatchToProps = dispatch => ({

});

const Authorization = connect(
  mapStateToProps,
  mapDispatchToProps)(AuthorizationWrapper);

export default Authorization