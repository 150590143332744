import {saveDataSelection, updateSelectionCriteriaActionCreator} from "../../expDesign/action/DataSelectionAction";
import {connect} from "react-redux";
import {getSorter, getVisibilityFilter, getVisibleCohorts} from "../../visualization/selectors/CohortsSelectors";
import {getStudyList, setSorter, setVisibilityFilter} from "../../visualization/action/CohortAction";
import CohortsPage from "../component/CohortsPage";
import {withRouter} from "react-router";



const mapStateToProps = state => {
  return{
    filteredCohorts : getVisibleCohorts(state),
    cohorts:state.visu.studies.studies,
    cohortFilter: getVisibilityFilter(state),
    cohortSorter:getSorter(state),
    experiment: state.expDesign.experiment,
    expDesign: state.expDesign,
    visu:state.visu
  }
};

const mapDispatchToProps = dispatch => {
  return{
    getCohorts: () => {dispatch(getStudyList())},
    saveSelection: (data) => {dispatch(saveDataSelection(data))},
    updateCriteria: (data, kind)=>{dispatch(updateSelectionCriteriaActionCreator(data,kind))},
    onSorterChange: (id)=>{dispatch(setSorter(id))},
    onFilterChange: (data)=>{dispatch(setVisibilityFilter(data))}
  }
};


export const ContainerCohortsPage  = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(CohortsPage));