import {connect} from "react-redux";

import {withRouter} from "react-router";
import ChartsPanel from "../component/ChartsPanel";



const mapStateToProps = (state,ownProps) => {
  return{
    cohort:state.visu.study.study,
  }
};

const mapDispatchToProps = dispatch => {
  return{
  }
};


export const ContainerChartsPanel  =withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(ChartsPanel));