import {
  TOOL_LIST_FAIL, TOOL_LIST_REQUEST, TOOL_LIST_SUCCESS
} from "./actionType";

import axios from 'axios'
import {HOST_URL} from "../../../Constants";


const requestToolList = () => ({
  type: TOOL_LIST_REQUEST
});

const errorToolList = (err) => ({
  type: TOOL_LIST_FAIL,
  err,
});

const successToolList = (tools) => ({
  type: TOOL_LIST_SUCCESS,
  tools
});



/**
 * ActionCreator for getting Tasks.
 * @returns {function(*)}
 */
export const getAllToolsList = () => {
  return (dispatch,getState) => {
    dispatch(requestToolList());
    const config = {
      headers: {'Authorization': "bearer" + getState().auth.token_bearer}
    };
    axios.get(HOST_URL + '/api/tool?all=true',config)
      .then(response => {
        console.log('ToolAction.js :: getAllToolsList :: response ::', response);
        if (response.status !== 200) {
          dispatch(errorToolList(response.status))
        } else {
          dispatch(successToolList(response.data));
        }
      }).catch(error => {
      console.log('ToolAction.js :: getAllToolsList :: error ::', error);
      dispatch(errorToolList(error))
    });

  }
};