import {connect} from "react-redux";
import {
  clearWorkflow,
  getAllWorkflowList,
  getSingleWorkflow,
  saveWorkflow
} from "../../expDesign/action/WorkflowAction";
import {WorkflowEditor} from "../component/WorkflowEditor";



const mapStateToProps = state => {
  return {
    workflowState: state.expDesign.workflows.workflowState,
    workflowSaveState: state.expDesign.workflows.workflowSaveState,
    workflowSaveError: state.expDesign.workflows.workflowSaveError,
    workflow:state.expDesign.workflows.workflow,
    messageQueue: state.messaging.msgQueue,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getWorkflow:(id) => {
      dispatch(getSingleWorkflow(id))
    },
    getWorkflows:() => {
      dispatch(getAllWorkflowList())
    },
    clearWorkflow:()=>{
      dispatch(clearWorkflow())
    },
    saveWorkflow:(workflowId,payload,status)=>{
      dispatch(saveWorkflow(workflowId,payload,status))
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerWorkflowEditor = containerCreator(WorkflowEditor);