import React from "react";
import PropTypes from "prop-types";
import {OverlayPanel} from "primereact/components/overlaypanel/OverlayPanel";
import ToolHintPanel from "./ToolHintPanel";
import {getNestedProp} from "../../helpers/expressions";

const style={
  display: "flex",
  justifyContent: "space-between",
  alignItems:"center",
  marginTop: "1em",
  borderRadius: "1em",
  border: "solid 1px",
  padding: "1em",
  color: "white"
};

export class TaskTrayItem extends React.Component {


  constructor(prop) {
    super(prop);
    this.state = {visible:false};
    this.getNewTask = this.getNewTask.bind(this);
  }

  getNewTask(){
    return Object.assign({}, {name:"UntitledTask",description: "Description here",taskId:this.props.taskData._id});
  }

  render() {
    const {taskData,toolData,color} = this.props;

    style.backgroundColor = color;

    const toolType = (toolData!=null&& toolData.docType!=null && (toolData.docType === "manualTool" || toolData.docType === "tool" || toolData.docType === "manual"))?"manual":"auto";
    const icon =  <i className={toolType ==="manual"?"fa fa-check-circle-o":"fa fa-android"} style={{marginRight:"0.5em",fontSize:"medium"}} title={toolType==="manual"?"Manual":"Automatic"}/>;
    const anchor = document.getElementById("workflow-editor-left-panel-anchor");

    return  <div
      style={style}
      draggable={true}
      onDragStart={event => {
        event.dataTransfer.setData('node-toolData', JSON.stringify(toolData));
        event.dataTransfer.setData('node-taskData', JSON.stringify(taskData));
        event.dataTransfer.setData('node-taskInWorkflowData', JSON.stringify(this.getNewTask()));
        event.dataTransfer.setData('node-colour', color);
        event.dataTransfer.setData('node-type', "task")
      }}
    >
      {icon}
      <span style={{textAlign:"center"}}>{taskData.name}</span>
      <i  ref={(el) => this["icon"+taskData._id]=el}
        id={"task"+taskData._id} style={{marginLeft:"0.5em",fontSize:"medium"}} className="fa fa-info-circle"
         onMouseOver={(e)=>{
           this[taskData._id].toggle(e);
           this.setState({visible:true})
         }}
         onMouseOut={(e)=>{
           this[taskData._id].toggle(e);
           this.setState({visible:false});
         }}
      />
      <OverlayPanel ref={(el) => this[taskData._id]=el} appendTo={anchor} >
        {this.state.visible &&
        <div style={{width:"320px"}}>
          <strong>{getNestedProp(["docType"],toolData)!=="boutiquesDescriptor"
            ? getNestedProp(["name"],toolData)
            : getNestedProp(["descriptor","name"],toolData)} {icon}</strong>
          <p>{taskData.description}</p>
          {getNestedProp(["docType"],toolData)!=="boutiquesDescriptor"&&
          <ToolHintPanel toolData={toolData}/>
          }
        </div>
        }
      </OverlayPanel>
    </div>
  }
}

TaskTrayItem.propTypes = {
  taskData: PropTypes.object.isRequired,
  toolData: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};