import React from "react";
import {Button} from "primereact/components/button/Button";
import PropTypes from "prop-types";
import {InputText} from "primereact/components/inputtext/InputText";
import {Dropdown} from "primereact/components/dropdown/Dropdown";




export const HierarchicalDropdownGenerator  = ({parentNode,updateData,addNode,removeNode,ontologyRegister,updateOntologyRegister,findInOntology,ontologyList})=>{

    const renderOntologyClassIRI= ontologyRegister[parentNode.value]!=null?ontologyRegister[parentNode.value].ontologyClassIri:"";
    const renderOntologyId= ontologyRegister[parentNode.value]!=null?ontologyRegister[parentNode.value].ontologyId:"";

    return   <div >
            {parentNode['key'] !== "0"&&
            <div className="ui-g-12 ui-g-nopad">
                <span className="ui-g-1 ui-g-nopad">{parentNode['key']}</span>
                <InputText className="ui-g-2 ui-g-nopad" title={"Value"} placeholder="Value" value={parentNode.value} onChange={(e)=>updateData(parentNode,'value',e.target.value)} />
                <InputText className="ui-g-2 ui-g-nopad" title={"Title"} placeholder="Title" value={parentNode.title} onChange={(e)=>updateData(parentNode,'title',e.target.value)} />
                <InputText className="ui-g-1 ui-g-nopad" title={"IRI- compliant with old model"} placeholder="IRI" value={parentNode.iri} onChange={(e)=>updateData(parentNode,'iri',e.target.value)} />
                <div className="ui-g-2 ui-g-nopad" style={{display:"flex",justifyContent:"flex-end"}}>
                  <InputText className="ui-g-nopad" title={"Ontology class"} style={{width:"100%"}} placeholder="Ontology class" value={renderOntologyClassIRI} onChange={(e)=>updateOntologyRegister(parentNode.value,"ontologyClassIri",e.target.value)} />
                  <Button icon="fa fa-edit" title={"Search in ontologies"} onClick={()=>findInOntology(parentNode['value'])}/>
                </div>
                <div className="ui-g-2 ui-g-nopad">
                <Dropdown options={ontologyList!=null ? ontologyList.map(el => { return {label: el.name, value: el._id} }) : []}
                          placeholder="Ontology id" value={renderOntologyId} onChange={(e)=>updateOntologyRegister(parentNode.value,"ontologyId",e.value)} />
                </div>
                <div className="ui-g-2 ui-g-nopad">
                    <Button icon="fa fa-plus" onClick={()=>addNode(parentNode)}/>
                    <Button icon="fa fa-trash" onClick={()=>removeNode(parentNode)}/>
                    {parentNode['iri']!=null && parentNode['iri'].length>0 &&
                    <a target="_blank" href={parentNode['iri']}>
                        <i className="fa fa-external-link" title="Get more information"/>
                    </a>
                    }
                </div>
            </div>}
            {
                (parentNode.children !=null) && parentNode.children.map((el, index) =>
                    <HierarchicalDropdownGenerator parentNode={el}
                                                   updateData={updateData}
                                                   key={el.key}
                                                   addNode={addNode}
                                                   removeNode={removeNode}
                                                   ontologyRegister={ontologyRegister}
                                                   updateOntologyRegister={updateOntologyRegister}
                                                   findInOntology={findInOntology}
                                                   ontologyList={ontologyList}
                    />)
            }
            {parentNode['key'] === "0"&&
            <React.Fragment>
                <Button icon="fa fa-plus" onClick={()=>addNode(parentNode)}/>
            </React.Fragment>}
            </div>;
};

HierarchicalDropdownGenerator.propTypes = {
    parentNode: PropTypes.object.isRequired,
    updateData:PropTypes.func, //callback for updating values
    addNode:PropTypes.func,//callback for adding node
    removeNode:PropTypes.func, //callback for removing node
    ontologyList:PropTypes.array.isRequired
};