import {connect} from "react-redux";
import {SRR_landing_page} from "../component/landingPage";
import {getResult,clearSingleResultStateAC} from "../action/SingleResultViewAction";

// Used by connect()
const mapStateToProps = state => {
    console.log("mapStateToProps review")
    return {
        singleResult: state.singleResultReview.singleResult,
        status: state.singleResultReview.status,
        error: state.singleResultReview.error
    }
};

const mapDispatchToProps = dispatch => {
  console.log("mapDispatchToProps review")
    return {
        getSingleResult: (workflowExecutionUuid) => {
            dispatch(getResult(workflowExecutionUuid))
        },
        clearSingleResultStateAc: () => {
            dispatch(clearSingleResultStateAC())
        }
    }
};

const singleResultReviewContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerSingleResultReview = singleResultReviewContainerCreator(SRR_landing_page);
