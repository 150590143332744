import {
  QUESTION_LIST_REQUESTED,
  QUESTION_LIST_REQUEST_FAILED,
  QUESTION_LIST_REQUEST_SUCCESS,
  QUESTION_SAVE_REQUEST,
  QUESTION_SAVE_FAIL,
  QUESTION_SAVE_SUCCESS,
  CLEAR_QUESTION_SAVE
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


/**
 * }
 * @param state
 * @param action
 * @return {{polys: {}}|({} & {polys: {}} & {luts: any})}
 */
export const questionReducer = (state = {
  questionList:[],question:{},questionListError:{},questionError:{},questionListState:"",questionState:""
}, action) => {
  switch (action.type) {
    case QUESTION_LIST_REQUESTED:{
      return Object.assign({},state, {
        questionListState: REQUEST_STATUS_REQUESTED
      });
    }
    case QUESTION_LIST_REQUEST_FAILED:{
      return Object.assign({}, state, {
        questionListState:REQUEST_STATUS_FAIL,
        questionListError:action.err
      });
    }

    case QUESTION_LIST_REQUEST_SUCCESS:{
      return Object.assign({}, state, {
        questionListState:REQUEST_STATUS_SUCCESS,
        questionList:action.questions
      });
    }

    default:
      return state;
  }
};




export const saveQuestionReducer = (state = {

  saveQuestionState:"",
  saveQuestionError:{},
  saveQuestionResponse:{}

},action) => {
  switch (action.type) {
    case QUESTION_SAVE_REQUEST:{
      return Object.assign({}, state, {
        saveQuestionState: REQUEST_STATUS_REQUESTED
      });
    }
    case QUESTION_SAVE_FAIL:{
      return Object.assign({}, state, {
        saveQuestionState: REQUEST_STATUS_FAIL,
        saveQuestionError: action.err
      });
    }
    case QUESTION_SAVE_SUCCESS:{
      return Object.assign({}, state, {
        saveQuestionState: REQUEST_STATUS_SUCCESS,
        saveQuestionResponse: action.data
      });
    }
    case CLEAR_QUESTION_SAVE:{
      return Object.assign({}, state, {
        saveQuestionState:"",
        saveQuestionError:{},
        saveQuestionResponse:{}
      });
    }
    default:
      return state;
  }
};

