import React from "react";
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {withTranslation} from 'react-i18next';
import {ContainerSeriesCasesPanel} from "../containers/ContainerSeriesCasesPanel";
import {ContainerSeriesContributionsPanel} from "../containers/ContainerSeriesContributionsPanel";
import {getMeasurementShortLabel} from "../action/SAMAction";
import {ContainerSeriesDataPointsPanel} from "../containers/ContainerSeriesDataPointsPanel";
import {Button} from "primereact/components/button/Button";
import {ContainerSeriesFilterEditor} from "../containers/ContainerSeriesFilterEditor";

/**
 * Component used for displaying valid data points to be used in plot (all data after matching criteria to
 * selected case, measurement, group etc.). Part of sidebar.
 */

class ValidDataPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      seriesContributionsInfoVisible: false,
      seriesCasesInfoVisible: false,
      seriesDataInfoVisible: false,
      filterDialogVisible:false
    };
  }

  render() {
    const {seriesContributions, seriesCases, plotIndex, selectedMeasurementConfiguration, rawData, instances} = this.props;
    const measurementShortLabel = getMeasurementShortLabel(selectedMeasurementConfiguration, rawData);
    return (
      <div>
        <h3>Data points
          <Button onClick={() => {
            this.setState({
              filterDialogVisible: true,
              casesInfoTitle: "Add/remove filters to data points"
            })
          }}
                  icon={"fa fa-filter"}
                  title={"Add filters"}
                  style={{float: "right"}}
          />
        </h3>
        <div style={{display: "flex", justifyContent: "space-between"}}>

          <div id={"cases_" + plotIndex} style={{width: "80%"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{width: "10em"}}>Valid data:</div>
              <a onClick={() => this.setState({
                seriesCasesInfoVisible: true,
                casesInfoTitle: DIALOG_TITLE[0] + measurementShortLabel
              })}
                 className={"nav__text_link"}
                 title={MSG[0]}>
                cases - {seriesCases.length}
              </a>
              <a onClick={() => this.setState({
                seriesContributionsInfoVisible: true,
                casesInfoTitle: DIALOG_TITLE[1] + measurementShortLabel
              })}
                 className={"nav__text_link"}
                 title={MSG[1]}>
                contributions - {seriesContributions.length}
              </a>

            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{width: "10em"}}>Data points:</div>
              <a onClick={() => this.setState({
                seriesDataInfoVisible: true,
                casesInfoTitle: DIALOG_TITLE[2] + measurementShortLabel
              })}
                 className={"nav__text_link"}
                 title={MSG[2]}>
                observations - {instances.length}
              </a>
            </div>
          </div>
          <Dialog
            id={"cases-contributions-panel-dialog"}
            appendTo={document.body}
            header={this.state.casesInfoTitle}
            closeOnEscape={true}
            resizable={true}
            visible={this.state.seriesContributionsInfoVisible || this.state.seriesCasesInfoVisible || this.state.seriesDataInfoVisible || this.state.filterDialogVisible}
            modal={false}
            onHide={() => {
              this.setState({
                seriesCasesInfoVisible: false,
                seriesContributionsInfoVisible: false,
                seriesDataInfoVisible: false,
                filterDialogVisible:false
              });
            }}
            contentStyle={contentStyle}
            style={dynamicStyle}>
            {this.state.seriesCasesInfoVisible && <ContainerSeriesCasesPanel/>}
            {this.state.seriesContributionsInfoVisible && <ContainerSeriesContributionsPanel/>}
            {this.state.seriesDataInfoVisible && <ContainerSeriesDataPointsPanel/>}
            {this.state.filterDialogVisible && <ContainerSeriesFilterEditor onHide={()=>{this.setState({filterDialogVisible:false})}}/>}
          </Dialog>
        </div>
      </div>
    )
  }
}

const dynamicStyle = {
  maxHeight: (window.innerHeight),
  minHeight: (window.innerHeight * 0.7),
  // maxWidth: window.innerHeight / 250 < 3 ? window.innerWidth / 2 : window.innerWidth * 0.9,
  minWidth: (window.innerWidth / 2),
  overflowWrap: "break-word"
};


const contentStyle = {
  minHeight: (window.innerHeight * 0.7),
  minWidth: (window.innerWidth / 2)
};

export default withTranslation()(ValidDataPanel);

ValidDataPanel.propTypes = {
  seriesContributions: PropTypes.array.isRequired,
  seriesCases: PropTypes.array.isRequired,
  instances: PropTypes.array.isRequired,
  t: PropTypes.func
};

const MSG = [
  "The number of cases matching to all filters",
  "The number of contributions matching to all filters",
  "The number of data points (observations, feature vector realizations, instances) matching to all filters",
];

const DIALOG_TITLE = [
  "Valid cases containing ",
  "Valid contributions containing ",
  "Valid observations containing ",
];