import React from 'react';
import {Link} from "react-router-dom";
import {ContainerManualTool} from "../container/ContainerManualTool";

export class SRR_landing_page extends React.Component {

    constructor(){
        super();
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getManualTool = this.getManualTool.bind(this);
        this.getForm = this.getForm.bind(this);
    }
    componentWillMount() {
        const {} = this.props;
    }
    
    componentWillUnmount() {
      const {clearSingleResultStateAc} = this.props;
      clearSingleResultStateAc(); // clear state
    }


    handleChange(event) {
     this.setState({value: event.target.value});
     }

     handleSubmit(event) {
       event.preventDefault();
       const {getSingleResult, clearSingleResultStateAc} = this.props;
       clearSingleResultStateAc(); // clear state
       getSingleResult(this.state.value);
     }
     
    getManualTool() {
      return (<ContainerManualTool/>);
    }
    
    getForm() {
      return (          
        <form onSubmit={this.handleSubmit}>
          <label>
            Workflow execution UUID :
            <input type="text" value={this.state.value} onChange={this.handleChange} />
          </label>
          <input type="submit" value="Envoyer" />
        </form>);
    }
    
    displayRequesting() {
      return (          
          <label>
            Loading case...
          </label>);
    }
    
    displayError() {
      const {error} = this.props;
      return (          
          <label>
            {JSON.stringify(error)}
          </label>);
    }


    render() {
      
      const {singleResult,status} = this.props;
      
    return(
        <React.Fragment>

          {!singleResult.hasOwnProperty("workload") && this.getForm()}
          {!singleResult.hasOwnProperty("workload") && status == "requesting" && this.displayRequesting()}
          {!singleResult.hasOwnProperty("workload") && status == "error" && this.displayError()}
          {singleResult.hasOwnProperty("workload") && this.getManualTool()}
          

        </React.Fragment>

    )}
};
