import {createSelector} from "reselect";
import {calculateIndex, getPlotSettings, getRawData, getSelectedCell, getSelectedLayout} from "./SAMSelectors";
import {getSeriesContributions} from "./ContributionsSelectors";

/**
 * Rearrange contributions into Pattern Recognition instances (patterns, instances of feature vector etc.).
 * This selector is required to create scatter plot.
 * @param state
 * @param index - index of plot (if null then selected cell index)
 * @return {array} returns array of instances
 */
export const getSeriesObservations = createSelector(
  [(state, index) => index, getSelectedCell, getSelectedLayout, getPlotSettings, getRawData, (state, index) => getSeriesContributions(state, index)],
  (index, cell, layout, plot, rawData, series) => {

    const currentPlotSettings = (index != null)
      ? plot[index]
      : plot[calculateIndex(cell, layout)];
    let compositeKeysMap = new Map();  // we need composite keys to differentiate
    if (currentPlotSettings.selectedMeasurementConfiguration != null && currentPlotSettings.mode !== 0) {
      // First create compositeKeysMap for "normal" contributions, so that clinical data can be added to them later
      series
        .filter(el=>el.type!=="clinicalData")
        .filter(el=>{ // remove contributions filtered out

          // check if filters are present
            if (currentPlotSettings.filters!=null
              && Array.isArray(currentPlotSettings.filters)
              && currentPlotSettings.filters.length > 0){

              // check every filter if any of them matches
              return currentPlotSettings.filters.some(filter=>{
                return filterContribution(filter,"measurementConfigurationId",el)
                  && filterContribution(filter,"contributorId",el)
                  && filterContribution(filter,"workflowId",el)
                  && filterContribution(filter,"roiId",el);
              });
            }
            else return true; // if no filters include contribution
          }
        )
        .forEach((el) => {
          const compositeKey = generateCompositeKey(el,currentPlotSettings.compositeKey);
          const temp = compositeKeysMap.get(compositeKey);  // check if already exist
          if (temp != null) { // if so, fill in measurement

            // find measurement index and replace value in array with element (HERE different criteria of handling repetitions can be applied )
            const valueIndex = currentPlotSettings.selectedMeasurementConfiguration.findIndex(meas => el.measurementConfigurationId === meas.id);
            if (temp[valueIndex]===undefined || currentPlotSettings.reps === "random") // if not repetition, just assign
              temp[valueIndex] = el;
            else{
              if (temp[valueIndex].hasOwnProperty(CONTRIBUTION_DATE)
                && Date.parse(temp[valueIndex][CONTRIBUTION_DATE])
                && el.hasOwnProperty(CONTRIBUTION_DATE)
                && Date.parse(el[CONTRIBUTION_DATE])
              ){
                if (currentPlotSettings.reps==="recent"){
                  if (new Date(el[CONTRIBUTION_DATE])>new Date(temp[valueIndex][CONTRIBUTION_DATE]))
                    temp[valueIndex] = el;
                }
                if (currentPlotSettings.reps==="obsolete"){
                  if (new Date(el[CONTRIBUTION_DATE]) < new Date(temp[valueIndex][CONTRIBUTION_DATE]))
                    temp[valueIndex] = el;
                }
              }
            }
            compositeKeysMap.set(compositeKey, temp);
          } else { // if not, add full table
            const arr = new Array(currentPlotSettings.selectedMeasurementConfiguration.length);
            arr[currentPlotSettings.selectedMeasurementConfiguration.findIndex(meas => el.measurementConfigurationId === meas.id)] = el;
            compositeKeysMap.set(compositeKey, arr)
          }
        });
      series // Add clinical data contributions based on caseId
        .filter(el=>el.type==="clinicalData")
        .forEach((el) => {
          compositeKeysMap.forEach((value, key) =>{
            if (key.includes(el.caseId)){
              value[currentPlotSettings.selectedMeasurementConfiguration.findIndex(meas => el.measurementConfigurationId === meas.id)] = el;
            }
          });
        });

      return Array
        .from(compositeKeysMap, ([name, value]) => ({key: JSON.parse(name), contributions: value}))  // contributions are ordered with measurement selection
        .filter(el=>el.contributions.flat(0).length === el.contributions.length);   // remove entries with empty contributions, eg. missing measurement
    } else return [];
  }
);


const CONTRIBUTION_DATE="contributionDate";

const filterContribution = (filter,property,contribution)=>{
  if (filter.hasOwnProperty(property)
    && filter[property]!=null
    && Array.isArray(filter[property])
    && filter[property].length>0){
    return filter[property].findIndex(mes=>mes === contribution[property])>-1;
  }
  else
    return true;
};


/**
 * Generates composite key used to consolidate (match, merge) contributions into observation according matching criteria.
 * The key has to be stringified , otherwise keys implemented as object won't work properly.
 * @param {object} el - contribution
 * @param {array} compositeKeyConf - configuration of composite Keys
 * @return {string}
 */
const generateCompositeKey = (el,compositeKeyConf) => {
  if(compositeKeyConf!=null && Array.isArray(compositeKeyConf) && compositeKeyConf.length>0) {
    const key = {};
    if (compositeKeyConf.includes("caseId"))
      key["caseId"] = el.caseId;
    if (compositeKeyConf.includes("contributorId"))
      key["contributorId"] = el.contributorId;
    if (compositeKeyConf.includes("workflowId"))
      key["workflowId"] = el.workflowId;
    if (compositeKeyConf.includes("roiId"))
      key["roiId"] = el.roiId;

    return JSON.stringify(key);
  }
  else return JSON.stringify({
    "caseId": el.caseId,
    "contributorId": el.contributorId
  });

};