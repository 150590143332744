import React from "react";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";
import PropTypes from "prop-types";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {getBoundarySlices} from "../../visualization/action/ViewerAction";
import {ContainerWidgetToolbar} from "../../visualization/containers/ContainerWidgetToolbar";
const MIDDLE = "middle";  //id of left vie
const LOWER_RIGHT = "lowerRight";
const UPPER_RIGHT = "upperRight";


/**
 * Layout component for displaying viewers panel with Single element.
 *
 */
class OneMainOneReferenceWithArrowsLayout extends React.Component {
    static getLabel(){
        return "One main viewer with one reference viewer and arrows"
    }
    static getClassName(){
        return "segmentationWithArrowsDemo"
    }
    static getViewersPortKeys(){
        return [MIDDLE,UPPER_RIGHT];
    }

    constructor(props) {
        super(props);
        this.state = {
            widthMain:"80vh",
            widthReference:"40vh",
            heightMain:"80vh",
            heightReference:"40vh"
        };
        ["calcLayout"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }
    componentDidMount() {
        this.calcLayout();
        window.addEventListener("resize",this.calcLayout);
    }
    componentDidUpdate(prevProps, prevState) {
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.calcLayout);
    }
    /** Calculates the size of viewers.
     *  Still it is not final version,
     *  TODO FIXME calculate correct factors.
     **/
    calcLayout() {
        if (window.innerWidth < (1.5 * window.innerHeight) ) {
            this.setState({widthMain:"52vw",widthReference:"26vw",heightMain:"52vw",heightReference:"26vw"});
            console.log("Portait mode");
        }
        if (window.innerWidth > (1.5 * window.innerHeight) ) {
            this.setState({widthMain:"80vh",widthReference:"40vh",heightMain:"80vh",heightReference:"40vh"});
            console.log("Landscape mode");
        }
    }

    render() {
        const {toolComponentProps, toolComponentState,widgetPanel} = this.props;
        const {
             viewersState, images
        } = toolComponentProps;

        let getImage = (index) => {
            if (viewersState[index]!=null)
                return images[viewersState[index].imageId];//.find((el) => viewersState[index].imageId === el.uuid);
            else return null;
        };
        const image = getImage("middle");
        const styleMain = {padding: "0.1em", height: this.state.heightMain, width: this.state.widthMain};
        const styleRef = {padding: "0.1em", height: this.state.heightReference, width: this.state.widthReference};

        const middle = (viewersState != null && viewersState.hasOwnProperty(MIDDLE))
            ? BrowserFactory.build(MIDDLE, toolComponentProps, toolComponentState)
            : <div title="Main viewer here."/>;


        const upperRight = (viewersState != null && viewersState.hasOwnProperty(UPPER_RIGHT))
            ? BrowserFactory.build(UPPER_RIGHT, toolComponentProps, toolComponentState)
            : <div title="Upper-right reference viewer here."/>;

        let visibleArrowUp = 'inline-block';
        let visibleArrowDown = 'inline-block';

        const {minSlice,maxSlice} = (viewersState!=null
            && viewersState["middle"]!=null
            && image!=null
            && image['state'] === REQUEST_STATUS_SUCCESS)?getBoundarySlices(viewersState["middle"], image):{minSlice:0,maxSlice:0};

        if (image !=null
            && image.properties !=null
            && viewersState !=null
            && viewersState["middle"] !=null){


            if (maxSlice === viewersState["middle"]["sliceNumber"]){
                visibleArrowUp = 'none';
            }
            if (minSlice === viewersState["middle"]["sliceNumber"]){
                visibleArrowDown = 'none';
            }


        }


        return (
            <div  className="viewerContainerFull">
                <div  className="viewerContainerSidePanel">
                    <div className="viewerContainerSide"/>
                    <div className="viewerContainerSide"/>
                </div>
                <div  className="viewerContainerMainPanel" style={{display:"block"}}>
                    {widgetPanel}
                    {middle}
                </div>
                <div className="viewerContainerSidePanel">
                    <div className="viewerContainerSideHalfHeight">
                        <div className="fa fa-caret-up slicingButton" style={{fontSize:"20vh",display:visibleArrowUp}}
                             onClick={() => {
                                 toolComponentProps.handleKeySlicing("middle", {code:"ArrowUp"})}}
                        />
                    </div>
                    <div style={{padding: "0.1em", height: this.state.heightReference, width: this.state.widthReference}}>
                        {upperRight}
                    </div>
                    <div className="viewerContainerSideHalfHeight">
                        <div className="fa fa-caret-down slicingButton"  style={{fontSize:"20vh",display:visibleArrowDown}}
                             onClick={() => {
                                 toolComponentProps.handleKeySlicing("middle", {code:"ArrowDown"})}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default OneMainOneReferenceWithArrowsLayout;

OneMainOneReferenceWithArrowsLayout.defaultProps = {};

OneMainOneReferenceWithArrowsLayout.propTypes = {
widgetPanel:PropTypes.object.isRequired
};


