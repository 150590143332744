import React from "react";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";
import PropTypes from "prop-types";
import FormFactory from "../../visualization/component/annotationTool/FormFactory";

/**
 * Layout component for displaying players in left panel and forms in right panel.
 * No toolbar !
 */
class VerticalForm extends React.Component {
  static getLabel(){
    return "Two panels with player on the left and form elements on the right"
  }
  static getClassName(){
    return "birdsDemo"
  }
  static getViewersPortKeys(){
    return [];
  }

  constructor(props) {
    super(props);
  }


  render() {
    const {toolComponentProps, toolComponentState} = this.props;
    const viewersState = toolComponentProps.viewersState;
    const formsState = toolComponentProps.formsState;

    let arrayOfBrowsers = (viewersState!=null && Object.keys(viewersState).length>0)?Object.keys(viewersState).map((el,index)=>
      {
        return BrowserFactory.build(el,toolComponentProps,toolComponentState)
      }
    ): <div className="ui-g-12" style={{padding:"0.1em"}}/>;

    let arrayOfForms = (formsState!=null && Object.keys(formsState).length>0)?Object.keys(formsState).map((el,index)=>
      {
        return FormFactory.build(el,toolComponentProps,toolComponentState)
      }
    ): <div className="ui-g-12" style={{padding:"0.1em"}}/>;


    return (
      <React.Fragment>
        <div className="ui-g-12">
          {arrayOfBrowsers!=null && Array.isArray(arrayOfBrowsers) && arrayOfBrowsers.map((el,index)=>
            <div className="ui-g-12">
            <div className="ui-g-6" >
              {el}
            </div>
            <div className="ui-g-6" >
              {arrayOfForms[index]}
            </div>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default VerticalForm;

VerticalForm.defaultProps = {};

VerticalForm.propTypes = {
  widgetPanel:PropTypes.object.isRequired
};


