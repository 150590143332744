import React from "react";
import { Route, withRouter } from "react-router";
import LivePresenter from "../root/LivePresenter";
import SideView from "../livePresenter/component/slideView";
import SlideNavigator from "../livePresenter/component/slideNavigator";
import SlideShow from "../livePresenter/component/slideShow";

const LivePresenterRouter = (props) => {
  const { match } = props;
  return (
    <>
      <Route exact path={`${match.path}/`} component={LivePresenter} />
      <Route exact path={`${match.path}/:id`} component={SideView} />
      <Route exact path={`${match.path}/:presentationId/slide/:slideId`} component={SlideNavigator} />
      <Route exact path={`${match.path}/:presentationId/slideShow`} component={SlideShow} />
    </>
  );
};

export default withRouter(LivePresenterRouter);
