import {ANNOTATION_PROPERTY_NAME__ID, HOST_URL} from "../../Constants";


/**
 * Convert vtk display point to html canvas point (upside-down height in comparison to html canvas).
 * @param p - vtk display point
 * @param ctx - HTML Canvas Context
 */
function VTKDisplayToHTMLCanvas(ctx,p){
    const startPointX = p[0];
    const startPointY = ctx.canvas.height - p[1];
    return {startPointX:startPointX,startPointY:startPointY}
}



function drawDot(ctx,z,size,fontSize,element,isVideo){
    const {startPointX,startPointY} = (!isVideo)?VTKDisplayToHTMLCanvas(ctx,z): {startPointX:z[0],startPointY:z[1]};
    ctx.beginPath();
    ctx.arc(startPointX, startPointY, size, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.fill();
    ctx.font = fontSize + "px Helvetica";
    ctx.fillText(element[ANNOTATION_PROPERTY_NAME__ID], z[0], (!isVideo)?ctx.canvas.height - z[1]:z[1]);
}
/** Drawing flag shape.
 *
 * @param ctx - canvas context
 * @param z - starting point in VTK display coords
 * @param size - dot size
 * @param fontSize - size of font
 * @param element -
 */
function drawFlag(ctx, z, size, fontSize, element) {
    const {startPointX,startPointY} = VTKDisplayToHTMLCanvas(ctx,z);
    const sizeRatio = size*.5;
    ctx.beginPath();
    ctx.moveTo(startPointX,startPointY );
    ctx.lineTo(startPointX, startPointY-20*sizeRatio);
    ctx.lineTo(startPointX+5*sizeRatio, startPointY-15*sizeRatio);
    ctx.lineTo(startPointX, startPointY-10*sizeRatio);
    ctx.stroke();
    ctx.lineWidth=2;
    ctx.closePath();
    ctx.font = fontSize + "px Helvetica";
    // ctx.fillStyle = "green";  //To decide later
    ctx.fillText(element[ANNOTATION_PROPERTY_NAME__ID], startPointX+5, startPointY+5);
}
function drawX(ctx, z, size, fontSize, element) {
    const {startPointX,startPointY} = VTKDisplayToHTMLCanvas(ctx,z);
    const sizeRatio = size*.5;
    const shift = 4;
    ctx.beginPath();
    ctx.moveTo(startPointX-shift*sizeRatio,startPointY -shift*sizeRatio);
    ctx.lineTo(startPointX+shift*sizeRatio, startPointY+shift*sizeRatio);
    ctx.moveTo(startPointX+shift*sizeRatio,startPointY -shift*sizeRatio);
    ctx.lineTo(startPointX-shift*sizeRatio, startPointY+shift*sizeRatio);
    ctx.stroke();
    ctx.lineWidth=2;
    ctx.closePath();
    ctx.font = fontSize + "px Helvetica";
    // ctx.fillStyle = "green";  //To decide later
    ctx.fillText(element[ANNOTATION_PROPERTY_NAME__ID], startPointX+(shift+1)*sizeRatio, startPointY+(shift+2)*sizeRatio);
}
function drawEmptyX(ctx, z, size, fontSize, element) {
    const {startPointX,startPointY} = VTKDisplayToHTMLCanvas(ctx,z);
    const sizeRatio = size*.5;
    const shift = 4;
    const shiftRatio = 3;

    ctx.beginPath();
    ctx.moveTo(startPointX-shift*sizeRatio,startPointY -shift*sizeRatio);
    ctx.lineTo(startPointX-(shift/shiftRatio)*sizeRatio, startPointY-(shift/shiftRatio)*sizeRatio);
    ctx.moveTo(startPointX+(shift/shiftRatio)*sizeRatio, startPointY+(shift/shiftRatio)*sizeRatio);
    ctx.lineTo(startPointX+shift*sizeRatio, startPointY+shift*sizeRatio);
    ctx.moveTo(startPointX+shift*sizeRatio,startPointY -shift*sizeRatio);
    ctx.lineTo(startPointX+(shift/shiftRatio)*sizeRatio,startPointY -(shift/shiftRatio)*sizeRatio);
    ctx.moveTo(startPointX-(shift/shiftRatio)*sizeRatio,startPointY +(shift/shiftRatio)*sizeRatio);
    ctx.lineTo(startPointX-shift*sizeRatio, startPointY+shift*sizeRatio);
    ctx.stroke();
    ctx.lineWidth=2;
    ctx.closePath();
    ctx.font = fontSize + "px Helvetica";
    // ctx.fillStyle = "green";  //To decide later
    ctx.fillText(element[ANNOTATION_PROPERTY_NAME__ID], startPointX+(shift+1)*sizeRatio, startPointY+(shift+2)*sizeRatio);
}
function drawWhiskers(ctx, z, size, fontSize, element) {
    const {startPointX,startPointY} = VTKDisplayToHTMLCanvas(ctx,z);
    const sizeRatio = size*.5;
    const shift = 4;
    const shiftRatio = 3;

    ctx.beginPath();
    ctx.moveTo(startPointX-shift*sizeRatio,startPointY);
    ctx.lineTo(startPointX-(shift/shiftRatio)*sizeRatio, startPointY);
    ctx.moveTo(startPointX+(shift/shiftRatio)*sizeRatio, startPointY);
    ctx.lineTo(startPointX+shift*sizeRatio, startPointY);
    ctx.moveTo(startPointX,startPointY -shift*sizeRatio);
    ctx.lineTo(startPointX,startPointY -(shift/shiftRatio)*sizeRatio);
    ctx.moveTo(startPointX,startPointY +(shift/shiftRatio)*sizeRatio);
    ctx.lineTo(startPointX, startPointY+shift*sizeRatio);
    ctx.stroke();
    ctx.lineWidth=2;
    ctx.closePath();
    ctx.font = fontSize + "px Helvetica";
    // ctx.fillStyle = "green";  //To decide later
    ctx.fillText(element[ANNOTATION_PROPERTY_NAME__ID], startPointX+(shift+1)*sizeRatio, startPointY+(shift+2)*sizeRatio);
}

export const ANNOTATION_SHAPES = [
    {
        label:"dot",
        value:"dot",
        func : drawDot
    },
    {
        label:"flag",
        value:"flag",
        func : drawFlag
    },
    {
        label:"x",
        value:"x",
        func: drawX
    },
    {
        label:"hollow X",
        value:"emptyX",
        func: drawEmptyX
    },
    {
        label:"whiskers",
        value:"whiskers",
        func: drawWhiskers
    }

];


/** Drawing circle annotation (dot).
 *
 * @param ctx - canvas context
 * @param z - starting point in VTK display coords
 * @param size - dot size
 * @param fontSize - size of font
 * @param element -
 */
export function drawCircleShape(ctx, z, radius, color) {
    const {startPointX,startPointY} = VTKDisplayToHTMLCanvas(ctx,z);
    ctx.beginPath();
    ctx.arc(startPointX, startPointY, radius, 0, 2 * Math.PI);
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
}

/** Drawing annotation shape (dot).
 *
 * @param ctx - canvas context
 * @param z - starting point in VTK display coords
 * @param size - dot size
 * @param fontSize - size of font
 * @param element -
 */
export function drawAnnotationShape(shape, ctx, z, size, fontSize, element,isVideo=false) {
    // TODO: Draw shape depending on annotation status (projection, active, normal, default)
    const annotationShape = ANNOTATION_SHAPES.find(el=>el.value===shape);
    if (annotationShape!=null)
        annotationShape.func(ctx,z,size,fontSize,element,isVideo);
}

/** Drawing ruler end shape (cross).
 *
 * @param ctx - canvas context
 * @param z - starting point in VTK display coords
 * @param size - dot size
 * @param label - if this is referenced to a point, use it
 * @param end - end label ("b","e")
 * @param color -  ("b","e")
 *
 */
export function drawRulerEnd(ctx, z, size, end, label, color) {
    const {startPointX,startPointY} = VTKDisplayToHTMLCanvas(ctx,z);
    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.moveTo(startPointX-size,startPointY);
    ctx.lineTo(startPointX+size, startPointY);
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(startPointX,startPointY-size);
    ctx.lineTo(startPointX, startPointY+size);
    ctx.lineWidth = 1;
    ctx.stroke();
    if (label!=null) {
        ctx.font = "8px Arial";
        ctx.fillText(label+end, startPointX, startPointY+10);
    }
}

/** Drawing ruler line.
 *
 * @param ctx - canvas context
 * @param p1 - starting point in VTK display coords
 * @param p1 - ending point in VTK display coords
 * @param color -
 */
export function drawRulerLine(ctx, p1,p2,color) {
    const point1 = VTKDisplayToHTMLCanvas(ctx,p1);
    const point2  = VTKDisplayToHTMLCanvas(ctx,p2);

    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.strokeStyle = color;
    ctx.moveTo(point1.startPointX,point1.startPointY);
    ctx.lineTo(point2.startPointX, point2.startPointY);
    ctx.lineWidth = 1;
    ctx.stroke();
}


export const calculateCursorRadius = (c, renderer)=>{
    const d=c['diameter']!=null?c['diameter']:30;
    const scale = displayToWorldScale(renderer);
    return  Math.floor(d/scale);
}

/**
 * Creates cursor definition
 * @param c - Custom Cursor Tool configuration object {type,diameter}
 * @param renderer - renderer
 * @return {string}
 */
export const createCursor = (c,renderer,glass)=>{
    let responseSVG = null;

    const type = c['type'];

    const rotation = c['rotation']!=null && Number.isInteger(c['rotation'])?c['rotation']:0;

    if (type!=null){
        switch (type){
            case 'circle':
                return circleCursor(calculateCursorRadius(c,renderer));
            case 'whiskers':
                return whiskersCursor(calculateCursorRadius(c,renderer));
            case 'checkered':
                return checkeredCursor(calculateCursorRadius(c,renderer));
            case 'twoEllipses':
                return twoEllipses(calculateCursorRadius(c,renderer));
            case 'animatedEllipse':
                return animatedEllipse(calculateCursorRadius(c,renderer));
            case 'rotatedEllipse':
                return rotatedEllipse(calculateCursorRadius(c,renderer),rotation);
            case 'fixedCrosshairRelativeCircle':
                return fixedCrosshairRelativeCircle(calculateCursorRadius(c,renderer));
            case 'dottedCrosshair':
                return createEcho(10,'rgb(255,249,103)',2);
            case 'cityBlock':
                return cityBlockCursor(calculateCursorRadius(c,renderer));
            case 'square':
                return squareCursor(calculateCursorRadius(c,renderer));
            case 'infoTooltip':
                return infoTooltip(100, glass)
            default:
                return null
        }
    }
};

/**
 * Custom cursor definition for echo
 * This version can be assigned to cursor property, eg.
 * element.style.cursor =  circleCursorAsSVGCursor(10);
 * @return {string}
 */
export const createEcho = (size,color,strokeWidth)=>{
        return `data:image/svg+xml;utf8,`
            +`<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*size}' height='${2*size}'>`
            +`<line x1='${size}' y1='${0}' x2='${size}' y2='${size-2}' style='stroke:${color};stroke-width:${strokeWidth}' />`
            +`<line x1='${size}' y1='${size+2}' x2='${size}' y2='${2*size}' style='stroke:${color};stroke-width:${strokeWidth}' />`
            +`<line x1='${0}' y1='${size}' x2='${size-2}' y2='${size}' style='stroke:${color};stroke-width:${strokeWidth}' />`
            +`<line x1='${size+2}' y1='${size}' x2='${2*size}' y2='${size}' style='stroke:${color};stroke-width:${strokeWidth}' />`
            +`</svg>`;
};


/**
 * Custom cursor definition.
 * This version can be assigned to cursor property, eg.
 * element.style.cursor =  circleCursorAsSVGCursor(10);
 * @param r - radius of circle
 * @return {string}
 */
export const circleCursorAsSVGCursor =(r)=>`url('data:image/svg+xml;utf8,<svg id="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="${2*r+4}" height="${2*r+4}"><circle cx="${r+2}" cy="${r+2}" r="${r}" stroke-width="2" style="stroke: red;fill:none"/><line x1="${r+2}" y1="${r+2-5}" x2="${r+2}" y2="${r+2+5}" style="stroke:rgb(255,0,0);stroke-width:1" /><line x1="${r+2-5}" y1="${r+2}" x2="${r+2+5}" y2="${r+2}" style="stroke:rgb(255,0,0);stroke-width:1" /> </svg>') ${r+2} ${r+2}, pointer`;

/**
 * Custom circle cursor definition.
 * This version can be used with <img> element, eg.
 * <img src={circleCursor(10)} ...>
 */
export const circleCursor =(r,color='lightblue')=>`data:image/svg+xml;utf8,<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*r+4}' height='${2*r+4}'><circle cx='${r+2}' cy='${r+2}' r='${r}' stroke-width='2' style='stroke: ${color};fill:none'/><line x1='${r+2}' y1='${r+2-5}' x2='${r+2}' y2='${r+2+5}' style='stroke:${color};stroke-width:1' /><line x1='${r+2-5}' y1='${r+2}' x2='${r+2+5}' y2='${r+2}' style='stroke:${color};stroke-width:1' /> </svg>`;

/**
 * Custom ellipses cursor definition.
 * This version can be used with <img> element, eg.
 * <img src={circleCursor(10)} ...>
 */
export const twoEllipses =(r)=>`data:image/svg+xml;utf8,<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*r+4}' height='${2*r+4}'>`+
    `<ellipse cx='${r+2}' cy='${r+2}' rx='${r}' ry='${r*.5}' stroke-width='2' style='stroke: lightblue;fill:none'/>`+
    `<ellipse cx='${r+2}' cy='${r+2}' ry='${r}' rx='${r*.5}' stroke-width='2' style='stroke: lightblue;fill:none'/>`+
    `<line x1='${r+2}' y1='${r+2-5}' x2='${r+2}' y2='${r+2+5}' style='stroke:lightblue;stroke-width:1' />`+
    `<line x1='${r+2-5}' y1='${r+2}' x2='${r+2+5}' y2='${r+2}' style='stroke:lightblue;stroke-width:1' />`+
    `</svg>`;



/**
 * Custom ellipses cursor definition.
 * This version can be used with <img> element, eg.
 * <img src={circleCursor(10)} ...>
 */
export const rotatedEllipse =(r,rot)=>`data:image/svg+xml;utf8,<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*r+4}' height='${2*r+4}'>`+
    `<ellipse cx='${r+2}' cy='${r+2}' rx='${r}' ry='${r*.5}' stroke-width='2' style='stroke: red;fill:none' transform="rotate(${rot},${r+2},${r+2})"/>`+
    `<line x1='${r+2}' y1='${r+2-5}' x2='${r+2}' y2='${r+2+5}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${r+2-5}' y1='${r+2}' x2='${r+2+5}' y2='${r+2}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `</svg>`;




/**
 * Custom  obliue ellipses cursor definition.
 * This version can be used with <img> element, eg.
 * <img src={circleCursor(10)} ...>
 */
export const animatedEllipse =(r)=>`data:image/svg+xml;utf8,<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*r+4}' height='${2*r+4}'>`+
    `<ellipse cx='${r+2}' cy='${r+2}' rx='${r}' ry='${r*.5}' stroke-width='2' style='stroke: red;fill:none'>`+
    `<animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 ${r+2} ${r+2}" to="360 ${r+2} ${r+2}" dur="15s" repeatCount="indefinite"/>`+
    `</ellipse>`+
    `</svg>`;

/**
 * Custom whiskers cursor definition.
 * This version can be used with <img> element,
 * @see circleCursor
 */
export const whiskersCursor =(r)=>`data:image/svg+xml;utf8,`+
    `<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*r}' height='${2*r}'>`+
    `<line x1='${r}' y1='${0}' x2='${r}' y2='${r-2}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${r}' y1='${r+2}' x2='${r}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${0}' y1='${r}' x2='${r-2}' y2='${r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${r+2}' y1='${r}' x2='${2*r}' y2='${r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `</svg>`;


/**
 * Custom cursor definition.
 * This version can be used with <img> element,
 * @see circleCursor
 */
export const cityBlockCursor =(r)=>`data:image/svg+xml;utf8,`+
  `<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*r}' height='${2*r}'>`+
  `<line x1='${r}' y1='${0}' x2='${2*r}' y2='${r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
  `<line x1='${0}' y1='${r}' x2='${r}' y2='${0}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
  `<line x1='${0}' y1='${r}' x2='${r}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
  `<line x1='${r}' y1='${2*r}' x2='${2*r}' y2='${r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
  `</svg>`;


/**
 * Custom cursor definition.
 * This version can be used with <img> element,
 * @see circleCursor
 */
export const squareCursor =(r)=>`data:image/svg+xml;utf8,`+
  `<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*r}' height='${2*r}'>`+
  `<line x1='${0}' y1='${0}' x2='${2*r}' y2='${0}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
  `<line x1='${2*r}' y1='${0}' x2='${2*r}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
  `<line x1='${0}' y1='${2*r}' x2='${2*r}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
  `<line x1='${0}' y1='${0}' x2='${0}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
  `</svg>`;
/**
 * Custom  cursor definition with:
 *  - white crosshair in the middle, of fixed size (32px).
 *  - red circle of relative size to World coordinates (in milimmeters)
 * This version can be used with <img> element,
 * @see circleCursor
 */
export const fixedCrosshairRelativeCircle =(r)=> {
    const CURSOR_SIZE = 16;
    const size = ((2 * r + 4)> CURSOR_SIZE)?2 * r + 4:CURSOR_SIZE;
    const offset = ((r+2)>CURSOR_SIZE*.5)?r+2:CURSOR_SIZE*.5;

    return `data:image/svg+xml;utf8,` +
        `<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${size}' height='${size}'>` +
        `<circle cx='${offset}' cy='${offset}' r='${r}' stroke-width='2' style='stroke: red;fill:none'/>` +
        `<line x1='${offset}' y1='${offset - CURSOR_SIZE*.5}' x2='${offset}' y2='${offset + CURSOR_SIZE*.5}' style='stroke:white;stroke-width:2' />` +
        `<line x1='${offset - CURSOR_SIZE*.5}' y1='${offset}' x2='${offset + CURSOR_SIZE*.5}' y2='${offset}' style='stroke:white;stroke-width:2' />` +
        `</svg>`;
}


/**
 * Custom tooltip cursor definition to show pin properties
 * This version can be used with <img> element,
 * @see circleCursor
 */
export const infoTooltip =(totalSize, glass)=> {
    const raterName = glass && glass['over-data-pin'] && glass['over-data-pin'].raterName ? glass['over-data-pin'].raterName.replace('@', '%40') : null;
    if(raterName!=null){
        const color = glass['over-data-pin'].color.replace('#', '%23');
        const raterId = glass && glass['over-data-pin'] && glass['over-data-pin'].raterId ? glass['over-data-pin'].raterId : '';
        const avatarURL = `${HOST_URL}/api/user/${raterId}/picture`;
        return `data:image/svg+xml;utf8,`
        +`<svg id='svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'> version='1.1' width='${2*totalSize}' height='${2*totalSize}'>`
        +`<defs><clipPath id='avatarBorder'><circle cx='${totalSize/4}' cy='${totalSize/4}' r='${totalSize/4}' fill='${color}'/></clipPath></defs>`
        +`<image x='0' y='0' width='${totalSize/2}' height='${totalSize/2}' xlink:href='${avatarURL}' clip-path='url(%23avatarBorder)'/>`
        +`<text x='0' y='${(totalSize*3)/4}' fill='${color}'>${raterName}</text>`
        +`</svg>`;
    } else {
        return `data:image/svg+xml;utf8,`
        +`<svg id='svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'> version='1.1' width='${2*totalSize}' height='${2*totalSize}'>`
        +`</svg>`;
    }

}

/**
 * Custom checkered cursor definition.
 * This version can be used with <img> element,
 * @see circleCursor
 */
export const checkeredCursor =(r)=>`data:image/svg+xml;utf8,`+
    `<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${2*r}' height='${2*r}'>`+
    `<line x1='${r}' y1='${0}' x2='${r}' y2='${r-2}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${r}' y1='${r+2}' x2='${r}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${0}' y1='${r}' x2='${r-2}' y2='${r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${r+2}' y1='${r}' x2='${2*r}' y2='${r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${0}' y1='${0}' x2='${0}' y2='${r-2}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${0}' y1='${r+2}' x2='${0}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${2*r}' y1='${0}' x2='${2*r}' y2='${r-2}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${2*r}' y1='${r+2}' x2='${2*r}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${0}' y1='${0}' x2='${r-2}' y2='${0}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${r+2}' y1='${0}' x2='${2*r}' y2='${0}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${0}' y1='${2*r}' x2='${r-2}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `<line x1='${r+2}' y1='${2*r}' x2='${2*r}' y2='${2*r}' style='stroke:rgb(255,0,0);stroke-width:1' />`+
    `</svg>`;

//export const whiskersCursor =(ctx)=>`data:image/svg+xml;utf8,<svg id='svg' xmlns='http://www.w3.org/2000/svg' version='1.1' width='${ctx.canvas.width}' height='${ctx.canvas.height}'><line x1='0' y1='0' x2='${ctx.canvas.width}' y2='0' style='stroke:rgb(255,0,0);stroke-width:1' /> </svg>`;


/**
 * Given a renderer, return the scale factor
 * from display coords to world coords at that point.
 *
 */

export function displayToWorldScale (renderer)
{
    // Start by computing the height of the window at the cursor position.
    let worldHeight;
    let camera = renderer.getActiveCamera();
    if (camera.getParallelProjection())
    {
        worldHeight = 4*camera.getParallelScale();//2*
    }
    else {};
    // {
    //     vtkMatrix4x4* matrix = camera->GetViewTransformMatrix();
    //     // Get a 3x3 matrix with the camera orientation
    //     double cvz[3];
    //     cvz[0] = matrix->GetElement(2, 0);
    //     cvz[1] = matrix->GetElement(2, 1);
    //     cvz[2] = matrix->GetElement(2, 2);
    //     double cameraPosition[3];
    //     camera->GetPosition(cameraPosition);
    //     double v[3];
    //     v[0] = cameraPosition[0] - position[0];
    //     v[1] = cameraPosition[1] - position[1];
    //     v[2] = cameraPosition[2] - position[2];
    //     worldHeight = 2*(vtkMath::Dot(v,cvz)
    //         * tan(0.5*camera->GetViewAngle()/57.296));
    // }
    // Compare world height to window height.
    let scale = 1.0;
    try{
    //let windowHeight = renderer.getRenderWindow().getViews()[0].getSize()[1]
    const windowHeight = getRendererClientHeight(renderer);
    if (windowHeight > 0)
    {
        scale = worldHeight/windowHeight;
    }}
    catch (err){

    }
    return scale;
};

/**
 * Returns real height of VTK viewport (witdth and height of canvas are set on 300 by default, so the real
 * values must be read from client params)
 * @param renderer
 * @return {number}
 */
export function getRendererClientHeight(renderer){
    return renderer.getRenderWindow().getViews()[0].getCanvas().clientHeight;
}

/**
 * Returns real width of VTK viewport (witdth and height of canvas are set on 300 by default, so the real
 * values must be read from client params)
 * @param renderer
 * @return {number}
 */
export function getRendererClientWidth(renderer){
    return renderer.getRenderWindow().getViews()[0].getCanvas().clientWidth;
}

