import React from 'react'
import { connect } from 'react-redux'
import EventsPage from "../component/EventsPage";
import {getEventsList} from "../action/EventsAction";

const mapStateToProps = state => {
  return{
    auth : state.auth,
    events: state.event.events.events,
    messageQueue: state.messaging.msgQueue
  }
};

const mapDispatchToProps = dispatch => ({
    getEvents : (onError) => {
        dispatch(getEventsList(onError))
  }
});

export const ContainerEventsPage = connect( mapStateToProps, mapDispatchToProps)(EventsPage);
