import React from 'react'
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";


class ShowHide extends React.Component {

   constructor(){
       super();
       this.onShowHide = this.onShowHide.bind(this);
   }

   onShowHide(){
       const {clb} = this.props;
       //this.setState({showCompleted:!this.state.showCompleted});
       clb();
   }
    render() {
       const {t} = this.props;
       let text =  (this.props.value)
           ?t("showHideFilterComponent.value.showCompleted")
           :t("showHideFilterComponent.value.hideCompleted");

        return (
            <a style={{float:'right', marginTop:"5px"}} onClick={this.onShowHide}>
                {text}
            </a>
        );
    }
}

export default withTranslation() (ShowHide);

ShowHide.propTypes = {
    clb: PropTypes.func.isRequired, //callback to pass up state of the component
    value: PropTypes.bool
};



