import React from "react";
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {ContainerCustomScriptView} from "../containers/ContainerCustomScriptView";


/**
 * Component used for SELECTION of measurements.
 */

class CustomScriptPanelContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false
    };
  }


  render() {
    const {rawData, script} = this.props;

    return (
      <div>
        <h3>Script
          <Button onClick={() => {
            this.setState({dialogVisible: true})
          }}
                  icon={"fa fa-edit"}
                  title={"Edit"}
                  style={{float: "right"}}
          /></h3>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            {"Script: "} <b>{script.split(/\r\n|\r|\n/).length}</b> lines, <b>{script.length}</b> {" characters"}<br/>
          </div>

          <Dialog
            header={"Execute custom analysis"}
            width={"90vw"}
            height={"90vh"}
            visible={this.state.dialogVisible}
            appendTo={document.body}
            onHide={() => {
              this.setState({dialogVisible: false})
            }}>
            {this.state.dialogVisible &&
            <React.Suspense fallback={<p>Loading</p>}>
              <ContainerCustomScriptView onHide={() => {
                this.setState({dialogVisible: false})
              }}/>
            </React.Suspense>}
          </Dialog>
        </div>
      </div>
    )
  }
}

export default CustomScriptPanelContent;

CustomScriptPanelContent.propTypes = {
  rawData: PropTypes.object.isRequired,
  selectedMeasurementConfiguration: PropTypes.object.isRequired,
  updatePlotSettingsProperty: PropTypes.func.isRequired,
  updateScatterPlotData: PropTypes.func.isRequired
};
