import {connect} from "react-redux";
import {
  getSingleWorkflow
} from "../../expDesign/action/WorkflowAction";
import GeneralTab from "../component/GeneralTab";





const mapStateToProps = (state,own) => {
  return {
    messageQueue: state.messaging.msgQueue,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getWorkflow:(id) => {
      dispatch(getSingleWorkflow(id))
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerGeneralTab = containerCreator(GeneralTab);