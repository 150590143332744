import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {ContainerCasesPanel} from "../containers/ContainerCasesPanel";
import {ContainerValidDataPanel} from "../containers/ContainerValidDataPanel";
import {ContainerMeasurementPanelContent} from "../containers/ContainerMeasurementPanelContent";
import {ContainerRegressionModelPanelContent} from "../containers/ContainerRegressionModelPanelContent";


/**
 * Component used for displaying regression analysis  panel in sidebar.
 *
 *
 */

class RegressionPanel extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
        return (
      <div>
        <ContainerMeasurementPanelContent/>
        <ContainerCasesPanel/>
        <ContainerValidDataPanel/>
        <ContainerRegressionModelPanelContent/>
      </div>
    );
  }
}

export default withTranslation()(RegressionPanel);

RegressionPanel.propTypes = {
  rawData:PropTypes.object.isRequired,
  plotSettings:PropTypes.object.isRequired,
  updatePlotSettingsProperty:PropTypes.func.isRequired,
  t: PropTypes.func
};
