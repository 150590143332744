import { connect } from 'react-redux';
import { forgotPassword } from '../action/ForgotPasswordAction.jsx';
import ForgotPassword from '../component/ForgotPassword';

const mapStateToProps = state => {
  return{
  	loginStatus : state.auth.login_status
  }
};

const mapDispatchToProps = dispatch => {
  return{
  	forgotPassword : (emailObject,onSuccess,onError) => {
  	  dispatch(forgotPassword(emailObject,onSuccess,onError))
  	} 
  }
};

const ContainerForgotPassword = connect(
 mapStateToProps,
 mapDispatchToProps)(ForgotPassword);

export default ContainerForgotPassword;