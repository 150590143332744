import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {ContainerMeasurementPanelContent} from "../containers/ContainerMeasurementPanelContent";
import {ContainerCasesPanel} from "../containers/ContainerCasesPanel";
import {ContainerPlotParametersPanelContent} from "../containers/ContainerPlotParametersPanelContent";
import {ContainerValidDataPanel} from "../containers/ContainerValidDataPanel";
import {ContainerCustomScriptPanelContent} from "../containers/ContainerCustomScriptPanelContent";


/**
 * Component used for displaying distribution panel in sidebar.
 *
 *
 */

class CustomAnalysisPanel extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {t,instances} = this.props;
    return (
      <div>
        <ContainerMeasurementPanelContent/>
        <ContainerCasesPanel/>
        <ContainerValidDataPanel/>
        <ContainerCustomScriptPanelContent/>
      </div>
    );
  }
}

export default withTranslation()(CustomAnalysisPanel);

CustomAnalysisPanel.propTypes = {
  rawData:PropTypes.object.isRequired,
  plotSettings:PropTypes.object.isRequired,
  updatePlotSettingsProperty:PropTypes.func.isRequired,
  t: PropTypes.func
};
