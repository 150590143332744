import React from "react";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import {MiniWorkflowStepMonitor} from "./MiniWorkflowStepMonitor";
import SpinePaintFilter from "../../../vtk/paintbrush/SpinePaintFilter";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {ToggleButton} from "primereact/components/togglebutton/ToggleButton";
import {TutorialVideo} from "../TutorialVideo";
import {calcRulers} from "../../../vtk/SpineVTKHelper";
import CasesSelectionDropdown from "./CasesSelectionDropdown";
import {ContainerTaskControlPanel} from "../../containers/ContainerTaskControlPanel";
import {getParameterFromUrl} from "../../../helpers/urls";

//dynamic import
const Viewer2D =  React.lazy(() => import("../../../vtk/Viewer2D")); //dynamic import
const Viewer3D =  React.lazy(() => import("../../../vtk/Viewer3D")); //dynamic import
/**
 * Quickly-generated template for confirmation tool.
 * Still needs to be refactored
 *
 */
class ConfirmationTool extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeViewerId: null,
            helpVisible:false,
            videoVisible:true,
            sidebarVisible:false, //annotation Table sidebar at bottom
            colorOptions: [{value:1,label:"Medulla Oblongata",color:'#f00'}],
            painter:SpinePaintFilter.newInstance(),
            showWindowPortal: false,
            linkViewers:true,
            leftButtonMode:LEFT_BUTTON_MODE.NONE
        };
        ["setButtonLeftMode","keyListener","pickerCallback"].forEach(name => { this[name] = this[name].bind(this);});
    }
    componentDidMount(){
        const {initializeTool, images, overlays, viewersState}=this.props;
        const {painter} = this.state;
        initializeTool();
        document.body.addEventListener("keydown", this.keyListener, false);
        try{
            if(this.props.workload.miniWorkflow.currentTutorial!=null){
                this.setState({videoVisible: !this.props.workload.miniWorkflow.currentTutorial.seen});
            }
        }
        catch(err){
        }
        if (viewersState!=null &&  viewersState["left"]!=null) {
            const image = images[viewersState["left"].imageId];//.find((el) => el.uuid === viewersState["left"].imageId);
            const overlay = overlays[viewersState["left"].overlayId];//.find((el) => el.uuid === viewersState["left"].overlayId);
            if (!painter.getBackgroundImage() && image != null && image['state'] === REQUEST_STATUS_SUCCESS && overlay != null && overlay['state'] === REQUEST_STATUS_SUCCESS) {
                painter.setBackgroundImage(image['data']);
                painter.update();
                painter.setOverlayImageData(overlay['data']);//FIXME here change to input
                painter.modified();
                this.setState({painter:Object.assign({},painter)}); // this can be applied only if interactor is not using painter to brush, fill, etc. !!
            }
        }
    }

    componentDidUpdate(prevProps,prevState){
        const {overlays, images,  viewersState} = this.props;
        const {painter} = this.state;
        if (viewersState != null &&  viewersState["left"]!=null) {
            //TODO change below to get imageId from task input
            const image = images[viewersState["left"].imageId];//.find((el) => el.uuid === viewersState["left"].imageId);
            const overlay = overlays[viewersState["left"].overlayId];//.find((el) => el.uuid === viewersState["left"].overlayId);
            if (!painter.getBackgroundImage() && image != null && image['state'] === REQUEST_STATUS_SUCCESS&& overlay!=null && overlay['state'] === REQUEST_STATUS_SUCCESS) {
                painter.setBackgroundImage(image['data']);
                painter.update();
                painter.setOverlayImageData(overlay['data']);//FIXME here change to input
                painter.modified();
                this.setState({painter:Object.assign({},painter)}); // this can be applied only if interactor is not using painter to brush, fill, etc. !!
            }
        }
        if(prevProps.workload !== this.props.workload) {
            try {
                if (this.props.workload.miniWorkflow.currentTutorial != null) {
                    this.setState({videoVisible: !this.props.workload.miniWorkflow.currentTutorial.seen});
                }
            }
            catch (err) {
            }
        }
    }

    pickerCallback(viewerId, ijk) {
        const {viewersState, updateViewerProperty, images} = this.props;
        const image = images[viewersState[viewerId].imageId];//.find((el) => el.uuid === viewersState[viewerId].imageId);
        const slicingModes = image['properties']['slicingModes'];
        let copyCis = {};
        Object.keys(viewersState).forEach((el) => {
            copyCis[el] = Object.assign({}, viewersState[el])
        });
        const id = copyCis[viewerId].imageId;
        Object.keys(copyCis).forEach((el, i) => {
            if (viewersState[el].imageId === id) {
                updateViewerProperty(el, 'sliceNumber',
                    ijk[slicingModes[viewersState[el]['orientation']]]); // set ijk depending on current orientation
            }
        });
    }

    /**
     * Clear state of viewers and widgets.
     * Removes key listener.
     */
    componentWillUnmount() {
        const {clearViewersState,clearManualToolState} = this.props;
        clearViewersState();
        clearManualToolState();
        document.body.removeEventListener("keydown", this.keyListener, false);
    }

    setButtonLeftMode(e){
        const {leftButtonMode} = this.state;
        if (e!==leftButtonMode){
            this.setState({leftButtonMode:e});
        }
        else{
            this.setState({leftButtonMode:LEFT_BUTTON_MODE.NONE});
        }
    }


    keyListener(event) {
        const {activeViewerId} = this.state;
        const {handleKeySlicing} = this.props;
        if (activeViewerId !=null) {
            handleKeySlicing(activeViewerId,event);
        }
    }



    render(){
        const {activeViewerId,linkViewers,
            leftButtonMode,colorOptions,painter,videoVisible,helpVisible}=this.state;
        const {data,  viewersState,match,workload,getAnotherCase,manualTool,updateManualToolProperty,
            updateViewerProperty,imageOptions,changeImageType,images,overlays,changeOrientation, setTutorialVideoState} =this.props;

        let getImage =(key)=>{
            return images[viewersState[key].imageId];//.find((el)=>viewersState[key].imageId === el.uuid);
        };

        let getOverlay =(key)=>{
                return overlays[viewersState[key].overlayId];//.find((el)=>viewersState[key].overlayId === el.uuid);
        };

        const tracker = (manualTool.manualToolState!=null
                         && (manualTool != null)
                         && (manualTool.manualToolState.referenceLines != null)
                         && (manualTool.manualToolState.referenceLines.referenceLines != null))
                        ? manualTool.manualToolState.referenceLines.referenceLines
                        : false;

        const steps = workload.miniWorkflow['miniWorkflowPath'].map(el => {
            return {name: el.stepName}
        });


        let browserPanel = (el)=> {
            if (viewersState != null && Object.keys(viewersState).length>0 && Object.keys(manualTool.manualToolState).length>0)
            return (viewersState[el].type ==="2D")
                ?  <React.Suspense fallback={<p>Loading</p>}>
                <Viewer2D key={el}
                                           id={el}
                                           isActive={this.state.activeViewerId === el}
                                           setActive={() => this.setState({activeViewerId: el})}
                                           annotationData={data}
                                           addRow={() => { }}
                                           pinProperties={null}
                                           tracker={tracker}
                                           linkViewers={linkViewers}
                                           painter={painter}
                                           leftButtonMode={leftButtonMode}
                                           opacity={manualTool.manualToolState.labelmapOpacity.labelmapOpacity*100}
                                           pinOpacity={null}
                                           colorOptions={colorOptions}
                                           imageOptions={imageOptions}
                                           setLinkViewers={() => this.setState({linkViewers: !linkViewers})}
                                           viewerState={viewersState[el]}
                                           updateViewer={(viewerState) => this.updateViewer(el, viewerState)}
                                           pickerCallback={(ijk) => this.pickerCallback(el, ijk)}
                                           smoothing={viewersState[el]['smoothing']}
                                           changeSmoothing={() => updateViewerProperty(el, 'smoothing', !viewersState[el]['smoothing'])}
                                           changeOrientation={(v) => changeOrientation(el, v)}
                                           updateViewerProperty={(property, value) => updateViewerProperty(el, property, value)}
                                           image={getImage(el)}
                                           overlay={getOverlay(el)}
                                           rulers={(tracker) ? calcRulers(el, activeViewerId, viewersState, images) : null}
                                           hasCropping={[false, false, false, false, false, false]}
                                           croppingSlices={[0, 0, 0, 0, 10, 50]}
                                           hasGlass={false}
                                           isOrientationControlDisabled={true}
                                           isOrientationControlVisible={true}
                                           isLinkedControlVisible={false}
                /></React.Suspense>

               : <React.Suspense fallback={<p>Loading</p>}>
                    <Viewer3D
                        key={el}
                        isActive={false}
                        setActive={() => {
                        }}
                        annotationData={data}
                        tracker={tracker}
                        linkViewers={linkViewers}
                        painter={painter}
                        leftButtonMode={leftButtonMode}
                        opacity={manualTool.manualToolState.labelmapOpacity.labelmapOpacity*100}
                        pinOpacity={null}
                        colorOptions={colorOptions}
                        imageOptions={imageOptions}
                        setLinkViewers={() => this.setState({linkViewers: !linkViewers})}
                        viewersState={viewersState} //object of 3 viewers state
                        updateViewer={(viewerState) => this.updateViewer(0, viewerState)}
                        pickerCallback={(ijk) => this.pickerCallback(0, ijk)}
                        smoothing={viewersState[el]['smoothing']}
                        changeSmoothing={() => updateViewerProperty(el, 'smoothing', !viewersState[el]['smoothing'])}
                        changeOrientation={(v) => changeOrientation(0, v)}
                        imageType={0}
                        changeImageType={(e) => changeImageType(0, e)}
                        updateViewerProperty={(property, value) => updateViewerProperty(el, property, value)}
                        image={getImage(el)}
                        overlay={getOverlay(el)}
                        rulers={(tracker) ? calcRulers(el, activeViewerId, viewersState, images) : null}
                        sliceVisibility={viewersState[el]['sliceVisibility']}
                    />;
                </React.Suspense>
            else return <div className="ui-g-12" style={{padding: "0.1em"}}/>;
        };

        return (
            <React.Fragment>
                <div style={{display: "block", position: "fixed", top: "10px", left: "auto", zIndex: "1000"}}>
                    <MiniWorkflowStepMonitor
                        steps={steps}
                        currentStep={workload.miniWorkflow.currentStep}/>
                </div>
                {workload!=null && !match.path.includes("demo") &&
                <ContainerTaskControlPanel
                    videoButtonOn={videoVisible}
                    helpButtonOn={helpVisible}
                    onHelpButtonClick={()=>this.setState({helpVisible:!helpVisible})}
                    onVideoButtonClick={()=>this.setState({videoVisible:!videoVisible})}/>
                }
                {!videoVisible &&
                <div >
                <div className="taskToolTopbar">
                    <div>
                        {!(this.props.onNext != null) &&
                        <CasesSelectionDropdown
                            listOfCases={workload['listOfCases']}
                            currentCase={workload['currentCase']}
                            onChange={(caseNumber)=>getAnotherCase(
                                match.params['miniWorkflowSetId'],
                                match.params['miniWorkflowKey'],
                                match.params['experimentId'],
                                caseNumber
                            )}
                        />}
                        <Toolbar style={{display:"inline-block"}}>
                                <ToggleButton checked= {leftButtonMode===LEFT_BUTTON_MODE.PICKER}
                                              onIcon="fa fa-hand-o-up"
                                              onLabel="" offLabel=""
                                              offIcon="fa fa-hand-o-up"
                                              iconPos="right"
                                              tooltip="Picker"
                                              onChange={()=>{this.setButtonLeftMode(LEFT_BUTTON_MODE.PICKER)}}/>
                                <label style={{marginLeft:"1em"}}>Opacity:</label>
                                <input type="range"
                                       onChange={(e) =>  updateManualToolProperty('labelmapOpacity','labelmapOpacity',e.target.value/100)}
                                       min={0} step={1} max={100}
                                       className="opacitySlider"
                                       value={(Object.keys(manualTool.manualToolState).length>0)
                                           ?100*manualTool.manualToolState.labelmapOpacity.labelmapOpacity
                                           :100}
                                />
                                <ToggleButton checked={tracker}  onLabel="" offLabel="" toolTip="Crosshairs On/Off"
                                              onChange={() =>  updateManualToolProperty('referenceLines','referenceLines',!tracker)}
                                              onIcon="fa fa-crosshairs"
                                              offIcon="fa fa-crosshairs"/>
                         </Toolbar>
                    </div>
                </div>
                <div className="ui-g-12">
                    <div className="ui-g-3" style={{padding: "0.1em"}}>
                        {browserPanel("left")}
                    </div>
                    <div className="ui-g-3" style={{padding: "0.1em"}}>
                        {browserPanel("middleLeft")}
                    </div>
                    <div className="ui-g-3" style={{padding: "0.1em"}}>
                        {browserPanel("middleRight")}
                    </div>
                    <div className="ui-g-3" style={{padding: "0.1em"}}>
                        {browserPanel("right")}
                    </div>
                </div>

            </div>}
                {workload !=null
                && workload.miniWorkflow !=null
                && workload.miniWorkflow.currentTutorial
                && workload.miniWorkflow.currentTutorial.video
                && <TutorialVideo
                    visible={videoVisible}
                    videoId={getParameterFromUrl(workload.miniWorkflow.currentTutorial.video,'v')}
                    onEnd={() => {
                        this.setState({videoVisible: false});
                        if (!workload.miniWorkflow.currentTutorial.video.seen)
                            setTutorialVideoState(workload.miniWorkflow.currentMaterializedTask.uuid);
                    }}/>}
            </React.Fragment>
        )
    }

}
export default withRouter(ConfirmationTool);

ConfirmationTool.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    getAnnotations: PropTypes.func.isRequired,
    updateAnnotationData:PropTypes.func.isRequired,
    rmCallback:PropTypes.func.isRequired,
    saveOverlayData:PropTypes.func,
    clearAll : PropTypes.func.isRequired,
    addAnnotation : PropTypes.func.isRequired,
    setActiveAnnotation:PropTypes.func.isRequired,
    clearAnnotatorState:PropTypes.func.isRequired,
    clearViewersState: PropTypes.func.isRequired,
    initialize:PropTypes.func.isRequired,
    viewersState:PropTypes.object.isRequired,  //array containing state of all viewers
    updateViewerProperty:PropTypes.func.isRequired,
    updateViewer:PropTypes.func,
    imageOptions:PropTypes.array.isRequired,
    changeImageType:PropTypes.func.isRequired,
    images:PropTypes.array.isRequired,
    overlays:PropTypes.array.isRequired,
    changeOrientation:PropTypes.func.isRequired,
    manualTaskState:PropTypes.object.isRequired,
    workload: PropTypes.object.isRequired,
    updateManualTask:PropTypes.func.isRequired,
    manualTool:PropTypes.object.isRequired,
    updateManualToolProperty:PropTypes.func.isRequired,
    clearManualToolState:PropTypes.func.isRequired
};
