import React from 'react';
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {WorkflowCard} from "./WorkflowCard";
import {DemoCard} from "../../visualization/component/annotationTool/DemoCard";
import {Link} from "react-router-dom";

const Editor =React.lazy(()=>import("workflow-new/dist/editor.bundle"));

/**
 * Component to represent the workflow manager as a separate module.
 * @deprecated "We" do not want list view anymore as landing page.
 */
class WorkflowManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filter:""
        };

    }

    componentDidMount() {
        this.props.getWorkflows();
    }

    render() {
        const {workflows,workflowListState} =this.props;

        return (
              <React.Fragment>
                  <div style={{    padding: "10px", position: "fixed",  backgroundColor: "aquamarine"}}>
                      <label>Search:</label><InputText onChange={(e)=>this.setState({filter:e.target.value})} value={this.state.filter}/>
                  </div>
                  <div style={{
                      flexDirection: "row",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                      paddingTop:"40px"
                  }}>
                      {workflowListState===REQUEST_STATUS_SUCCESS && workflows.length>0 &&
                      workflows
                        .filter(el=>{
                            const srch = "".concat(el.name,el.description,el.owner);
                            return srch.toLowerCase().includes(this.state.filter.toLowerCase())})
                        .map(el =>{return <Link to={`/workflowManager/${el._id}`}>
                          <WorkflowCard  footer={{version:el.version,owner:el.owner,privacy:el.privacy}}
                                                           headerClassName={"annotationToolDemo"}
                                                           title={el['name']}
                                                           description={el['description']}
                                                    filter={this.state.filter}/></Link>})}
                  </div>
              </React.Fragment>
        );
    }
};



export default WorkflowManager;