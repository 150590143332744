import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {DataTable} from "primereact/components/datatable/DataTable";
import {
  ANNOTATION_PROPERTY_NAME__ID, REQUEST_STATUS_FAIL,
  REQUEST_STATUS_REQUESTED,
  REQUEST_STATUS_SUCCESS
} from "../../../../Constants";
import {Column} from "primereact/components/column/Column";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {StatusColumn} from "./StatusColumn";
import ClassSearchInputText from "../../../ncbo/component/ClassSearchInputText";
import TermSearchResultsDataTable from "../../../ncbo/component/TermSearchResultsDataTable";
import RecommenderInputText from "../../../ncbo/component/RecommenderInputText";
import RecommenderResultsDataTable from "../../../ncbo/component/RecommenderResultsDataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {uuidv4} from "../../../helpers/strings";
import {Checkbox} from "primereact/components/checkbox/Checkbox";



const blockStyle={
  border: "solid 1px black",
  borderRadius: "20px",
  padding: "10px",
  width: "45%",
  margin: "2em",
  display: "inline-block"
};

/**
 * Form for creating/editing annotation column.
 */
class AnnotationQuestionBuilder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      expandedRows: null,
      selectedStatus: "ALL",
      selectedOntology: "",
      conceptFilter: "",
      filteringOntologies: [], //ontologies acronyms - needs to be converted to ids before sending
      queryResult: {},
      recommenderText: "",
      recommenderResult: [],
      _id: uuidv4(),
      questionText: "",
      recommenderVisible: false,
      termSearchVisible: false,
      filteringCheckbox: false
    };
    ["onChangeDefinitionProperty", "onSave", "updateSelected", "onRowSelect", "renderOntologies", "filterOntologies", "onClear",
      "rowExpansionTemplate", "onRowEdit", "onRowDelete", "onRowUnselect"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    if (this.props.getQuestionsList != null)
      this.props.getQuestionsList();
    if (this.props.getOntologyList != null)
      this.props.getOntologyList();
  }
  componentWillUnmount() {
    if (this.props.clearSaveQuestion!=null)
      this.props.clearSaveQuestion();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {saveQuestionResponse,saveQuestionError,saveQuestionState, messageQueue, getQuestionsList} = this.props;
    if (prevProps.saveQuestionState !== saveQuestionState) {
      if (saveQuestionState === REQUEST_STATUS_SUCCESS) {
        messageQueue.show({
          sticky: false,
          severity: 'info',
          summary: 'Saved Annotation Table Definition',
          detail: "A new Question has been saved in ".concat(saveQuestionResponse.status, " mode.")
        });
        if (getQuestionsList != null)
          getQuestionsList();
        if (saveQuestionResponse.status!=="DRAFT"){//clear editor after publishing, otherwise keep it
          this.onClear();
        }
      }
      if (saveQuestionState === REQUEST_STATUS_FAIL) {
        messageQueue.show({
          sticky: false,
          severity: 'error',
          summary: 'Error during Saving',
          detail: saveQuestionError.message
        });
      }
    }
  }


  onChangeDefinitionProperty(prop, val) {
    let col = Object.assign({}, this.state.column);
    col[prop] = val;
    this.setState({column: col});
  }

  onSave(mode){
    const {_id,questionText,filteringOntologies} = this.state;
    const {messageQueue,saveQuestion,ontologyList,ontologyListState} = this.props;


    if ( !(questionText!=null) || ""===questionText){
      messageQueue.show( {
        sticky: false,
        severity: 'error',
        summary: 'Validation Error',
        detail:"Missing \'questionText\' data! Data has not been saved."
      });
      return ;
    }

    const transferObject = {
      "_id": _id,
      "status": mode === "draft" ? "DRAFT" : "PUBLISHED",
      "questionText": questionText
    };

    if (REQUEST_STATUS_SUCCESS === ontologyListState && filteringOntologies.length>0) {
      const fTemp = filteringOntologies.map((el) => {
        const ont = ontologyList.find((o) => {
          return o.name === el
        });
        if (ont != null)
          return ont._id;
        else return null;
      });
      transferObject["ontologies"] = fTemp;
    }
    saveQuestion(mode,transferObject);
  }

  updateSelected(column) {

    this.setState({column: Object.assign({}, column.definition), selectionEditorVisible: false});
  }

  onRowSelect(e) {
    this.setState({
      selectedRow: e.data
    });
  }

  rowExpansionTemplate(row) {
    return (
      <div>
        <h5>{row.questionText} > Properties:</h5>
        <DataTable value={row.questionProperties} emptyMessage={"No properties found"}>
          <Column field="answerFormat" header="Answer format"/>
          <Column field="isInteger" header="Integer"/>
          <Column field="lowerLimit" header="Lower Limit"/>
          <Column field="upperLimit" header="Upper Limit"/>
        </DataTable>
      </div>
    );
  }


  onRowEdit() {
    const {ontologyList, ontologyListState} = this.props;
    const {selectedRow} = this.state;
    this.setState({
      questionText: selectedRow.questionText,
      _id: "DRAFT" === selectedRow.status ? selectedRow[ANNOTATION_PROPERTY_NAME__ID] : uuidv4()
    });
    if (REQUEST_STATUS_SUCCESS === ontologyListState) {
      const fTemp = selectedRow.ontologies.map((el) => {
        const ont = ontologyList.find((o) => {
          return o._id === el
        });
        if (ont != null)
          return ont.name;
        else return "";
      });
      this.setState({filteringOntologies: fTemp});
    }
  }

  onRowDelete() {
    const {messageQueue} = this.props;
    const {selectedRow} = this.state;
    messageQueue.show({
      sticky: false,
      severity: 'info',
      summary: 'Delete',
      detail: "Question ".concat(selectedRow.questionText, " has been removed from SPINE knowledge base. Warning: This feature has not been yet implemented!.")
    });


    // const {columns,successAnnotationsDefinition} = this.props;
    // const tempColumns = Object.assign({},columns);
    // delete tempColumns[this.state.selectedRow['_key']];
    // successAnnotationsDefinition(tempColumns);
    this.onRowUnselect();
  }

  onRowUnselect() {
    this.setState({selectedRow: null})
  }

  onClear() {
    this.setState({
      questionText: "",
      _id: uuidv4(),
      filteringOntologies: []
    });
  }


  renderOntologies(row) {
    const {ontologyList, ontologyListState} = this.props;
    if (ontologyListState === REQUEST_STATUS_SUCCESS && row.ontologies!=null && Array.isArray(row.ontologies)) {
      return row.ontologies.map((el) => {
        const ont = ontologyList.find((o) => {
          return o._id === el
        });
        if (ont != null)
          return ont.name;
        else return "";
      }).reduce((prev, cur) => {
        return prev.concat(cur, ", ")
      }, "")
    }
    return "";
  }

  filterOntologies(value, filter) {
    const {ontologyList, ontologyListState} = this.props;
    if (filter === undefined || filter === null || (typeof filter !== 'string') || ontologyListState !== REQUEST_STATUS_SUCCESS || value === undefined || value === null) {
      return true;
    }
    let result = false;
    value.forEach((el) => {
      const ont = ontologyList.find((o) => {
        return o._id === el
      });
      result |= ont.name.toLowerCase().includes(filter.toLowerCase());
    });
    return result;
  }

  iriExpansionTemplate(row) {
    return <a target="_blank" href={row['@id']} title={"Open ".concat(row['@id'], " in external window")}>
      {row['@id'].substring(row['@id'].lastIndexOf('/') + 1)}
    </a>
  }

  owlExpansionTemplate(row) {
    return <span title={"Full specification: ".concat(row['@type'])}>
      {row['@type'].substring(row['@type'].lastIndexOf('/') + 1)}
    </span>
  }

  render() {
    const {questionList, questionListState, ontologyList, ontologyListState, saveQuestionState} = this.props;
    const {
      expandedRows, selectedRow, selectedOntology, selectedStatus, filteringOntologies,
      queryResult, questionText, recommenderResult, recommenderVisible, _id, termSearchVisible, filteringCheckbox
    } = this.state;

    return (
      <div className={"ui-g"}>
        <div className={"ui-g-12"}>
          {questionListState === REQUEST_STATUS_SUCCESS
          && <DataTable
            ref={(el) => this.dt = el}
            sortField="status"
            sortOrder={-1}
            expandedRows={expandedRows}
            value={questionList}
            rows={10}
            paginator={true}
            onRowToggle={(e) => this.setState({expandedRows: e.data})}
            rowExpansionTemplate={this.rowExpansionTemplate}
            selection={selectedRow}
            onRowSelect={this.onRowSelect}
            selectionMode="single"
            onSelectionChange={() => {
            }}>
            {StatusColumn(selectedStatus, (value) => {
              this.setState({selectedStatus: value});
              this.dt.filter(value, 'status', 'custom')
            })}
            <Column field="_id" header={"Id"} sortable={true} filter={true} filterMatchMode={"contains"}/>
            <Column field="questionText" header={"Text"} sortable={true} filter={true} filterMatchMode={"contains"}/>
            <Column field="ontologies" header={"Ontologies"} body={this.renderOntologies} filter={true}
                    filterFunction={this.filterOntologies} filterMatchMode={"custom"}/>
            <Column field="questionProperties" header={"Properties"} expander={true}/>
          </DataTable>}
          <div>
          <Button label={(selectedRow != null && "DRAFT" === selectedRow.status) ? "View/Edit" : "View/Duplicate"}
                  disabled={!(selectedRow != null)} onClick={this.onRowEdit}/>
          <Button label={"Delete"} disabled={!(selectedRow != null && "DRAFT" === selectedRow.status)}
                  onClick={this.onRowDelete} title={"You can delete selected question in DRAFT mode only"}/>
          <Button label={"Unselect"} disabled={!(selectedRow != null)} onClick={this.onRowUnselect}/>
          </div>
          <div style={{display:"flex"}}>
          <div style={blockStyle}>
            <h2>Add/Edit Question</h2>
            <div style={{margin: "1em"}}>
              <span style={{width: "5em", display: "inline-block"}}>Id:</span>
              <input type={"text"} value={_id}
                     className={"ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-disabled"}
                     readOnly={true} style={{width: "20em"}}/>
            </div>
            <div style={{margin: "1em", display: "inline-block"}}>
              <span style={{width: "5em", display: "inline-block"}}>Question:</span>
              <RecommenderInputText text={questionText}
                                    onTextChange={(v) => {
                                      this.setState({questionText: v})
                                    }}
                                    inputStyle={{display: "inline-block"}}
                                    onError={() => {
                                    }}
                                    onResponse={v => this.setState({recommenderResult: v, recommenderVisible: true})}/>
            </div>
            <div>
              <span style={{width: "5em", display: "inline-block"}}>Ontologies:</span>
              <input type={"text"} value={this.state.filteringOntologies.reduce((prev, current) => {
                return prev.concat(current, ",")
              }, "")} readOnly={true}/>
              <Button onClick={() => {
                const temp = this.state.filteringOntologies.slice(0);
                temp.push(this.state.selectedOntology);
                this.setState({filteringOntologies: temp});
              }} icon={"fa fa-plus"} title={"Add to the list"}/>
              <label>Find in SPINE Ontology list:</label>
              <Dropdown
                value={selectedOntology}
                options={ontologyListState === REQUEST_STATUS_SUCCESS ? ontologyList.map(el => {
                  return {label: el.name, value: el.name}
                }) : []}
                onChange={el => this.setState({selectedOntology: el.value})}
              />
            </div>
            <div style={{marginTop:"2em"}}>
              <Button label={"Save as Draft"}
                      onClick={e => this.onSave("draft")}
                      disabled={saveQuestionState === REQUEST_STATUS_REQUESTED}
                      icon={saveQuestionState === REQUEST_STATUS_REQUESTED ? "fa fa-spinner fa-spin" : "fa fa-save"}/>
              <Button label={"Save and Publish"}
                      onClick={e => this.onSave("published")}
                      disabled={saveQuestionState === REQUEST_STATUS_REQUESTED}
                      icon={saveQuestionState === REQUEST_STATUS_REQUESTED ? "fa fa-spinner fa-spin" : "fa fa-save"}/>
              <Button label={"Clear"} icon={"fa fa-trash"} onClick={this.onClear}/>
            </div>
          </div>
          <div style={blockStyle}>
            <h2>Search term in NCBO</h2>
            <div style={{display: "inline-block"}}>

                <ClassSearchInputText value={this.state.conceptFilter}
                                      onValueChange={(v) => {
                                        this.setState({conceptFilter: v})
                                      }}
                                      onResponse={(data) => this.setState({queryResult: data, termSearchVisible: true})}
                                      onError={(err) => {
                                        messageQueue.show({
                                          sticky: false,
                                          severity: 'error',
                                          summary: 'Error',
                                          detail: err
                                        });
                                      }}
                                      filteringOntologies={filteringCheckbox ? filteringOntologies.filter(el => {
                                        return el !== "SPINE ontology"
                                      }) : null}
                />
              <div style={{marginTop:"2em"}}>
                <Checkbox checked={filteringCheckbox}
                          onChange={(e) => this.setState({filteringCheckbox: !filteringCheckbox})}/>
               <label style={{display: "inline-block",marginLeft:"1em"}}>Use ontologies field to filter</label>
             </div>
            </div>
          </div>
          </div>
          {termSearchVisible &&
          <React.Fragment>
            <Button icon={"fa fa-close"} label={"Close query results"} onClick={() => {
              this.setState({termSearchVisible: false})
            }}/>
            <TermSearchResultsDataTable queryResult={queryResult}/>
          </React.Fragment>
          }
          <Dialog header="Recommender results"
                  visible={recommenderVisible}
                  modal={true}
                  onHide={() => this.setState({recommenderVisible: false})}
                  style={{textAlign: "center", width: "80vw", height: "60vh"}}
          >
            <RecommenderResultsDataTable queryResult={recommenderResult}/>
          </Dialog>


        </div>
      </div>

    )
  }

}

export default AnnotationQuestionBuilder;

AnnotationQuestionBuilder.propTypes = {
  questionList: PropTypes.array.isRequired,
  questionListState: PropTypes.string.isRequired,
  ontologyList: PropTypes.array.isRequired,
  ontologyListState: PropTypes.string.isRequired,
  getQuestionsList: PropTypes.func.isRequired,
  getOntologyList: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  taskListState: PropTypes.string.isRequired,
  allColumns: PropTypes.object.isRequired,
  allColumnsState: PropTypes.string.isRequired,
  messageQueue: PropTypes.object.isRequired,
  saveQuestionState: PropTypes.string.isRequired,
  saveQuestionError:PropTypes.object.isRequired,
  saveQuestionResponse:PropTypes.object.isRequired,
  saveQuestion:PropTypes.func.isRequired,
  clearSaveQuestion:PropTypes.func.isRequired
};
