import React from "react";
import PropTypes from "prop-types";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {Panel} from "primereact/components/panel/Panel";
import {getNestedProp} from "../../helpers/expressions";
import GraphInfoPanel from "./GraphInfoPanel";
import GraphVisualizationPanel from "./GraphVisualizationPanel";
import GraphSelectedPanel from "./GraphSelectedPanel";
import GraphImageCamera from "./GraphImageCamera";
import {
  ContainerGraphCasePanel
} from "../../expDesign/containers/ContainerGraphCasePanel";

/**
 * Component displaying control and info panel for Directed Graph.
 * It can be bound with
 */
class DirectedGraphControlPanel extends React.Component {

  constructor(props) {
    super(props);
  }





  render() {
    const {viewer,  manualTool, updateViewerProperty, changeScene, image} = this.props;


    const disabled =  !(getNestedProp(["directedGraph"], viewer)!=null);
    return (
        <Panel style={{height:"inherit"}}>
          <TabView>
            <TabPanel header="Cases" headerClassName={"graph-case"}>
              <ContainerGraphCasePanel/>
            </TabPanel>
            <TabPanel header="Graph info" headerClassName={"graph-info"} disabled={disabled}>
              <GraphInfoPanel
                graph={getNestedProp(["directedGraph"], viewer)}
                graphTool={getNestedProp(["graphTool"],manualTool)}
                viewer={viewer}
                updateViewerProperty={updateViewerProperty}
                changeScene={changeScene}
              />
            </TabPanel>
            <TabPanel header="Visualization" headerClassName={"graph-vis"} disabled={disabled}>
              <GraphVisualizationPanel
                updateViewerProperty={updateViewerProperty}
                {...viewer}
              />

            </TabPanel>
            <TabPanel header="Nodes/Edges" headerClassName={"graph-nodes"} disabled={disabled}>
              <GraphSelectedPanel
                selected = {getNestedProp(["selected"], viewer)}
                viewer = {viewer}
              />
            </TabPanel>
            <TabPanel header="Background Image" disabled={disabled}>
              <GraphImageCamera
                viewer = {viewer}
                image={image}
              />
            </TabPanel>
          </TabView>
        </Panel>
    );
  }
}

export default DirectedGraphControlPanel;

DirectedGraphControlPanel.propTypes = {
  viewer: PropTypes.object.isRequired,
  updateGraphToolProperty: PropTypes.func.isRequired,
  updateViewerProperty: PropTypes.func.isRequired
};

DirectedGraphControlPanel.defaultProps = {};
