import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { login, register, forgotPassword } from '../action/LoginAction.jsx'
import Login from '../component/Login'

const mapStateToProps = state => {
  return{
  	loginStatus : state.auth.login_status
  }
};

const mapDispatchToProps = dispatch => ({

  	onLogin : (creds, onSuccess, onError) => {
  	  dispatch(login(creds, onSuccess, onError))
  	} ,
    onRegister : () => {
        dispatch(register())
    } ,
    onForgotPassword : () => {
        dispatch(forgotPassword())
    },

});

const ContainerLogin = withRouter(connect(
 mapStateToProps,
 mapDispatchToProps)(Login));

export default ContainerLogin