import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {max, histogram, range} from 'd3-array'
import {axisBottom, axisLeft, axisRight} from "d3-axis";
import {drag} from "d3-drag";
import {event as d3event, select,selectAll} from 'd3-selection'
import {scaleLinear} from 'd3-scale'
import {line} from 'd3-shape'


const VERTICAL_DOMAIN_TO_DISPLAY = 1.1;  // this is to offset highest lines from top border
const HORIZONTAL_DOMAIN_OFFSET = 0.1;  // this is to offset highest lines from top border

/** D3-based PixelHistogram Component integrated with React (based on
 *  traditional D3 rendering with React as one can see in
 *  {@link https://medium.com/@Elijah_Meeks/interactive-applications-with-react-d3-f76f7b3ebc71}
 *  Example:
 * <PixelHistogram size={[500,500]} />
 */

class PixelHistogram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      margin: props.margin,
      width: props.width - props.margin.left - props.margin.right,
      height: props.height - props.margin.top - props.margin.bottom,
      xScale:null,
      yScale:null
    };
    ["calculateScales","createChart", "clearChart", "createXAxis", "createYAxis", "createBars", "createMarkingLines","removeMarkingLines",
      "createLinesPlot", "createInteractiveLines","removeInteractiveLines","updatePlotType","updateRange"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  componentDidMount() {
    this.createChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {bins, xDomain, markersVisible,movingMarkers, plotType} = this.props;
    const {height,margin,width,xScale,yScale} = this.state;

    if(prevProps.bins!==bins){ // must create everything from scratch
      this.clearChart();
      this.createChart();
    }

    if (prevProps.plotType!==plotType){
      this.updatePlotType(xScale,yScale);
    }

    if (prevProps.xDomain!==xDomain){ // must recalculate axes and already set up lines
      this.updateRange();
    }

    if(prevProps.movingMarkers!==movingMarkers){
      if (!(prevProps.movingMarkers!=null)){
        this.removeInteractiveLines();
        this.createInteractiveLines(xScale, yScale);
      }
      if (!(movingMarkers!=null))
        this.removeInteractiveLines();
    }

    if(prevProps.markersVisible!==markersVisible){
      if (this.props.markersVisible){
         this.createMarkingLines(xScale,yScale);
      }
      else this.removeMarkingLines();
    }
  }

  /**
   * Calculates range and domain
   * @return {{xScale: *, yScale: *}}
   */
  calculateScales(){
    const {bins, xDomain, markersVisible} = this.props;
    const {margin, width, height} = this.state;

    const calcDomain = () => {
      const offsetX = (bins[bins.length - 1].x1 - bins[0].x0) * HORIZONTAL_DOMAIN_OFFSET / 2;
      return [bins[0].x0 - offsetX, bins[bins.length - 1].x1 + offsetX]
    };

    const xScale = scaleLinear()
      .domain(xDomain != null ? xDomain : calcDomain())
      .range([this.props.margin.left, this.props.width - this.props.margin.right]);

    const yScale = scaleLinear()
      .domain([0, VERTICAL_DOMAIN_TO_DISPLAY * max(bins, d => d.length)])
      .nice()
      .range([height, margin.top]);

    this.setState({xScale,yScale});
    return {xScale,yScale};
  }

  /**
   * Clears everything.
   */
  clearChart() {
    select(this.barchart)
      .selectAll("*")
      .remove();
  }

  createXAxis(xScale) {
    const {margin, width, height} = this.state;
    const axis = g => g
      .attr("transform", `translate(0,${height})`)
      .attr("id","xAxis")
      .call(axisBottom(xScale).ticks(width / 80).tickSizeOuter(0))
      .call(g => g.append("text")
        .attr("x", this.props.width - margin.right)
        .attr("y", 18)
        .attr("dy", "0.7em")
        .attr("fill", "currentColor")
        .attr("font-weight", "bold")
        .attr("text-anchor", "end")
        .text("Intensity"));
    select(this.barchart)
      .append("g")
      .call(axis);

  }

  createYAxis(yScale) {
    const {margin, width, height} = this.state;
    const axis = g => g
      .attr("transform", `translate(${margin.left},0)`)
      .call(axisLeft(yScale).ticks(height / 40))
      .call(g => g.select(".domain").remove())
      .call(g => g.select(".tick:last-of-type text").clone()
        .attr("x", "-6em")
        .attr("dy", "-3.3em")
        .attr("text-anchor", "start")
        .attr("font-weight", "bold")
        .attr("transform", "rotate(270)")
        .text("Occurences"));
    select(this.barchart)
      .append("g")
      .call(axis);
  }

  createBars(xScale, yScale) {
    const {bins} = this.props;

    let g = select(this.barchart)
      .append("g")
      .attr("id","dataplot")
    ;

    g.selectAll("rect")
      .data(bins)
      .join("rect")
      .attr("x", d => xScale(d.x0) + 1)
      .attr("width", d => Math.max(0, xScale(d.x1) - xScale(d.x0) - 1))
      .attr("y", d => yScale(d.length))
      .attr("height", d => yScale(0) - yScale(d.length))
      .style("fill", "steelblue")
      .on("mouseover", function (d) {
        select(this).transition().duration(200).style("fill", "#d30715");
        g.selectAll("#tooltip")
          .data([d])
          .enter()
          .append("text")
          .attr("id", "tooltip")
          .text(function (d, i) {
            return "".concat(d.length, " in [", d.x0, ", ", d.x1, "]");
          })
          .attr("y", function (d) {
            return yScale(d.length) - 12
          })
          .attr("x", function (d) {
            return xScale(d.x1);
          })
      })
      .on("mouseout", function (d) {
        select(this).transition().duration(500).style("fill", "steelblue");
        g.selectAll("#tooltip").remove();
      })
      .on("click", function (d) {
        select(this).transition().duration(500).style("fill", "steelblue");
      })
    ;

    ;
  }

  createLinesPlot(xScale, yScale) {
    const {bins} = this.props;
    let g = select(this.barchart)
      .append("g")
      .attr("id","dataplot")
    ;
     g.append("path")
      .datum(bins)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", line()
        .x(function (d) {
          return xScale(d.x1)
        })
        .y(function (d) {
          return yScale(d.length)
        })
      )
  }

  createMarkingLines(xScale, yScale) {
    const {threshold, lineMarkers} = this.props;

    if (lineMarkers != null) {
      const markerContainer =
        select(this.barchart)
          .append("g")
          .attr("id","markers")
      ;
      lineMarkers.forEach(lm => {
        markerContainer
          .append("line")
          .attr("x1", xScale(lm.value))
          .attr("x2", xScale(lm.value))
          .attr("y1", yScale(0))
          .attr("y2", 20)
          .attr("stroke", lm.color)
          .attr("stroke-dasharray", "4");

        markerContainer
          .append("text")
          .attr("x", 0)
          .attr("y", 0)
          .attr("transform", "translate(" + xScale(lm.value) + ",20) rotate(270)")
          .text(lm.label)
          .style("font-size", "10px")
          .style("fill", lm.color)
      })
    }
  };
  removeMarkingLines(){
    select("#markers").remove();
  }
  removeInteractiveLines(){
    select("#movingMarkers").remove();
  }

  createInteractiveLines(xScale, yScale) {
    const {movingMarkers,onAdjustedRangeChange} = this.props;
    const _dragLine = () => {
      let stroke = null;

      function dragstarted(d) {
        stroke = select(this).attr("stroke");
        select(this).raise().attr("stroke", "black");
        selectAll("#tooltipMarker").remove();
      }

      function dragged(d) {
        select(this).attr("x1", d3event.x).attr("x2", d3event.x);
        let otherPeak = 0;
        if (select(this).attr("id") === "High")
          otherPeak = parseInt(select("#Low").attr("x1"));
        else
          otherPeak = parseInt(select("#High").attr("x1"));

        select("#Mid")
          .attr("x1", Math.round((d3event.x + otherPeak) / 2)).attr("x2", Math.round((d3event.x + otherPeak) / 2));
        select("#MidText")
          .attr("transform", "translate(" + Math.round((d3event.x + otherPeak) / 2) + ",20) rotate(270)");
        onAdjustedRangeChange(Math.round(xScale.invert(Math.round((d3event.x + otherPeak) / 2))));
      }

      function dragended(d) {
        select(this).attr("stroke", stroke);
      }

      return drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    };


    if (movingMarkers != null) {

      const midPoint = Math.round(xScale((movingMarkers[0].value+movingMarkers[1].value)/2));

      const markerContainer =
        select(this.barchart)
          .append("g")
          .attr("id","movingMarkers");

      movingMarkers.forEach(lm => {
        markerContainer
          .append("line")
          .attr("id", lm.id)
          .attr("x1", xScale(lm.value))
          .attr("x2", xScale(lm.value))
          .attr("y1", yScale(0))
          .attr("y2", 0)
          .attr("stroke", lm.color)
          .attr("stroke-width", 2)
          .style("cursor", "w-resize")
          .on("mouseover", function (d) {
            select("#movingMarkers")
              .append("text")
              .attr("id", "tooltipMarker")
              .text(lm.label)
              .attr("y", 20)
              .attr("x",(d)=>{
                let obj = select("#"+lm.id);
                return Math.round(parseFloat(obj.attr("x1"))+5);
              });
          })
          .on("mouseout", function (d) {
            selectAll("#tooltipMarker").remove();
          })
          .call(_dragLine());
      });

      // --- Mid line ---
      markerContainer
        .append("line")
        .attr("id", "Mid")
        .attr("x1", midPoint)
        .attr("x2", midPoint)
        .attr("y1", yScale(0))
        .attr("y2", 20)
        .attr("stroke", "red")
        .attr("stroke-width", "1.5px")
        .style("cursor", "not-allowed");
      markerContainer
        .append("text")
        .attr("id", "MidText")
        .attr("x", 0)
        .attr("y", 0)
        .attr("transform", "translate(" + midPoint + ",20) rotate(270)")
        .text("Mid")
        .style("font-size", "10px")
        .style("fill", "red")
        .style("cursor", "not-allowed");

    }
  };


  createChart() {
    const {markersVisible} = this.props;

    const {xScale,yScale} = this.calculateScales();
    this.createXAxis(xScale);  // render XAxis
    this.createYAxis(yScale);  // render YAxis

    this.updatePlotType(xScale,yScale);

    if (markersVisible)
      this.createMarkingLines(xScale, yScale);

    this.createInteractiveLines(xScale, yScale);

    // const color = (d, index) => {
    //   if (threshold != null && index <= threshold)
    //     return "#29b327";
    //   else return "#b36826"
    // };

  }

  updatePlotType(xScale,yScale){
    const {plotType} = this.props;
    select("#dataplot").remove();
    if (plotType === "bars")
      this.createBars(xScale, yScale);
    else
      this.createLinesPlot(xScale, yScale);
  }

  updateRange(){
    const {markersVisible} = this.props;

    //copy old values from interactive lines
    const midXValue = this.state.xScale.invert(select("#Mid").attr("x1")); // get old position of middle line
    const lowXValue = this.state.xScale.invert(select("#Low").attr("x1")); // get old position of middle line
    const highXValue = this.state.xScale.invert(select("#High").attr("x1")); // get old position of middle line

    const {xScale,yScale} = this.calculateScales();
    select("#xAxis").remove();
    this.createXAxis(xScale);  // render XAxis a
    this.updatePlotType(xScale,yScale);
    this.removeMarkingLines();
    if (markersVisible)
      this.createMarkingLines(xScale, yScale);

    // apply old values from interactive lines in new scale
    select("#Mid").attr("x1",xScale(midXValue));
    select("#Mid").attr("x2",xScale(midXValue));
    select("#MidText").attr("transform", "translate(" + xScale(midXValue) + ",20) rotate(270)");
    select("#Low").attr("x1",xScale(lowXValue));
    select("#Low").attr("x2",xScale(lowXValue));
    select("#High").attr("x1",xScale(highXValue));
    select("#High").attr("x2",xScale(highXValue));

  }

  render() {
    return <svg ref={node => this.barchart = node}
                width={this.props.width} height={this.props.height}/>
  }
}

export default PixelHistogram


PixelHistogram.defaultProps = {
  margin: {top: 10, right: 30, bottom: 30, left: 40},
  plotType: "bars",  // value from the set: {"bars", "lines"}
  markersVisible: false
};

PixelHistogram.propTypes = {
  bins: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  threshold: PropTypes.number,
  margin: PropTypes.object,
  plotType: PropTypes.string,
  xDomain: PropTypes.array, // optional range for x
  markersVisible: PropTypes.bool,
  lineMarkers: PropTypes.array,
  movingMarkers: PropTypes.array,
  onAdjustedRangeChange:PropTypes.func.isRequired
};
