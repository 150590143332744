import React from "react";
import PropTypes from "prop-types";
import {uuidv4} from "../../helpers/strings";
import ToolHintPanel from "./ToolHintPanel";
import {OverlayPanel} from "primereact/components/overlaypanel/OverlayPanel";
import {getNestedProp} from "../../helpers/expressions";

const style={
  display: "flex",
  justifyContent: "space-between",
  alignItems:"center",
  marginTop: "1em",
  borderRadius: "1em",
  border: "solid 1px",
  padding: "1em",
  color: "white"
};


export class ToolTrayItem extends React.Component {

  constructor(prop) {
    super(prop);
    this.state = {
      taskId:uuidv4()   // task needs to be created
    };
    this.getNewTask = this.getNewTask.bind(this);
    this.getNewTaskInWorkflow = this.getNewTaskInWorkflow.bind(this);
  }

  getNewTaskInWorkflow(){
    return Object.assign({}, {name:"UntitledTask",description: "Description here",taskId:this.state.taskId});
  }

  getNewTask(){
    const {toolData} = this.props;
    const task = {
      _id:this.state.taskId,
      name: toolData.docType === "boutiquesDescriptor"?String(getNestedProp(["descriptor","name"],toolData)):String(toolData.name),
      description: toolData.docType === "boutiquesDescriptor"?String(getNestedProp(["descriptor","description"],toolData)):String(toolData.description),
      version:"1.0.0",
      privacy:"PUBLIC"
    };

    if (toolData.docType === "manualTool" || toolData.docType === "tool"){
      task['process'] = {};
      task['process']['type'] = "manual";
      task['process']['execution'] = {};
      task['process']['execution'] ['executor']= "SPINE";
      task['process']['execution'] ['descriptorId']= String(toolData._id);
      task['process']['contributionType']= "SINGLE_USER";
      task['status'] = "DRAFT";
    }
    if (toolData.docType === "boutiquesDescriptor"){
      task['process'] = {};
      task['process']['type'] = "auto";
      task['process']['execution'] = {};
      task['process']['execution'] ['executor']= "bosh";
      task['process']['execution'] ['descriptorId']= String(toolData._id);
      task['status'] = "DRAFT";
    }

    const ports = ['constants','inputs','outputs'];

    if (toolData.docType !== "boutiquesDescriptor") {
      ports.forEach((port) => {
        task[port] = {};
        if (toolData[port] != null && Object.keys(toolData[port]).length > 0) {
          Object.keys(toolData[port])
            .forEach((key) => {
              task[port][key] = Object.assign({}, toolData[port][key]);
              task[port][key]["idInDescriptor"] = key;
            })
        }
      });
    } else{
      ports.forEach((port) => {
        const toolPortType = port === "outputs" ? "output-files" : port;
        task[port] = {};
        if (getNestedProp(["descriptor",toolPortType],toolData)!= null && Object.keys(getNestedProp(["descriptor",toolPortType],toolData)).length > 0) {
          Object.keys(getNestedProp(["descriptor",toolPortType],toolData))
            .forEach((key) => {
              task[port][getNestedProp(["descriptor",toolPortType,key,"id"],toolData)] = Object.assign({}, getNestedProp(["descriptor",toolPortType,key],toolData));
              task[port][getNestedProp(["descriptor",toolPortType,key,"id"],toolData)]["idInDescriptor"] = getNestedProp(["descriptor",toolPortType,key,"id"],toolData);
            })
        }
      });
    }

    return Object.assign({}, task);
  }

  render() {
    const {toolData,color} = this.props;
    const anchor = document.getElementById("workflow-editor-left-panel-anchor");
    const toolType = (toolData!=null&& toolData.docType!=null && (toolData.docType === "manualTool" || toolData.docType === "tool" || toolData.docType === "manual"))?"manual":"auto";
    const icon =  <i className={toolType ==="manual"?"fa fa-check-circle-o":"fa fa-android"} style={{marginRight:"0.5em",fontSize:"medium"}} title={toolType==="manual"?"Manual":"Automatic"}/>;

    style.backgroundColor = color;

    return  <div
      style={style}
      draggable={true}
      onDragStart={event => {
        event.dataTransfer.setData('node-toolData', JSON.stringify(toolData));
        event.dataTransfer.setData('node-taskData', JSON.stringify(this.getNewTask()));
        event.dataTransfer.setData('node-taskInWorkflowData', JSON.stringify(this.getNewTaskInWorkflow()));
        event.dataTransfer.setData('node-colour', color);
        event.dataTransfer.setData('node-type', "tool")
      }}
    >
      <i className={"fa fa-cog"} style={{marginRight:"0.5em"}}/>
      <span style={{textAlign:"center"}}>{toolData.docType!=="boutiquesDescriptor"
        ? getNestedProp(["name"],toolData)
        : getNestedProp(["descriptor","name"],toolData)}</span>
      <i id={"tool"+toolData._id} style={{marginLeft:"0.5em"}} className="fa fa-info-circle"
         onMouseOver={(e)=>{
           this[toolData._id].toggle(e);
           this.setState({visible:true})
         }}
         onMouseOut={(e)=>{
           this[toolData._id].toggle(e);
           this.setState({visible:false});
         }}
      />
      <OverlayPanel ref={(el) => this[toolData._id]=el} appendTo={anchor} >
        {this.state.visible &&
        <div style={{width:"320px"}}>
          <strong>{
            getNestedProp(["docType"],toolData)!=="boutiquesDescriptor"
            ? getNestedProp(["name"],toolData)
            : getNestedProp(["descriptor","name"],toolData)
          } {icon}</strong>
          <p>{getNestedProp(["description"],toolData)}</p>
          {getNestedProp(["docType"],toolData)!=="boutiquesDescriptor" &&
            <ToolHintPanel toolData={toolData}/>
          }
        </div>
        }
      </OverlayPanel>
    </div>
  }
}

ToolTrayItem.propTypes = {
  toolData: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};