import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";


class VersionInfoPanel extends React.Component {

  // props are not passed

  constructor(props) {
    super(props);
    this.state = {
      versionContent: null
    };
  }

  componentDidMount() {
    fetch('/dist/version.json')// or axios.get if bearer is needed (it shouldn't be though+)
      .then(response => response.json())
      .then(data => {
        this.setState({versionContent: data});
      })
      .catch(e => {
        console.log(e);
      });
  }



  render() {
    const {versionContent} = this.state;

    const copyToClipboard = ()=>{
      return Object.keys(versionContent).map(el=>" " + el + " : " + versionContent[el]).join("\n")+ "\n"
        + "Agent: " + navigator.userAgent +"\n"
        + "Browser: " + browserDetection() + "\n"
        + "Platform: " + navigator.platform + "\n"
        + "Resolution: " + window.screen.width +" " + window.screen.height+" "+window.screen.availWidth + " " +window.screen.availHeight+"\n"
        + "GL Context: " + webglDetect() + "\n"
        + "Memory: " + navigator.deviceMemory + "GB\n"
        + "Host: "+  document.location.hostname;
    };

    if (versionContent !=null && versionContent.hasOwnProperty("name"))
    return (<div>
            <h1>{versionContent.name}</h1>
            <div style={{display: "flex",justifyContent:"space-around"}}>
              <div>
                <div title={"Timestamp"+versionContent.buildTimestamp}>Build on: {versionContent.buildDate} </div>
                <div>Release: {versionContent.latestTag}</div>
                <div>Variant: {versionContent.branch} </div>
                <div>Commit: {versionContent.hash} </div>
                <div>Number of commits: {versionContent.numCommits}</div>
                <div>Npm package: {versionContent.version}</div>
                <div>SPINE JSON Model: {versionContent.schemaVer.substring(versionContent.schemaVer.indexOf('#') + 1)}</div>
                <div>Worfklow Editor: {versionContent.wfVer.substring(versionContent.wfVer.indexOf('#') + 1)}</div>
                <div>ITK.js: {versionContent.ITK}</div>
                <div>VTK.js: {versionContent.VTK}</div>
              </div>
              <div>
                <div>Memory available (approx.): {navigator.deviceMemory} GB </div>
                <div title={navigator.userAgent} style={{textDecoration: "underline"}}>Browser: {browserDetection()}</div>
                <div>Platform: {navigator.platform}</div>
                <div>Display resolution: {window.screen.width} x {window.screen.height} available: {window.screen.availWidth} x {window.screen.availHeight}</div>
                <div>Host: {document.location.hostname}</div>
                <div><a href={"https://browserleaks.com/webgl#howto-detect-webgl"}
                        style={{textDecoration: "underline"}}
                        title={"Check your WebGL properties in details"} target={"_blank"}>Web GL
                  Support: {webglDetect()} </a></div>
                <i className="fa fa-clipboard" title="Copy to clipboard"  style={{color:'#00D2D0',fontSize:'medium'}}
                   onClick={()=>{navigator.clipboard.writeText(copyToClipboard())}}/>
                {/*<img src="https://cni.bwh.harvard.edu/files/cnitest/files/logo-small_0.gif" style={{width: "8em"}}/>*/}
              </div>
            </div>

            <div style={{textAlign: "center", marginTop: "1em"}}>Copyright © 2012 - 2022 Charles R.G. Guttmann</div>

          </div>
    );
    else return <React.Fragment/>;
  }
}

VersionInfoPanel.propTypes = {
  auth: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired
};
export default withTranslation()(withRouter(VersionInfoPanel));



function webglDetect() {
  const result = [];
  if (!!window.WebGLRenderingContext) {
    const canvas = document.createElement("canvas"),
      names = ["webgl2", "webgl", "experimental-webgl", "moz-webgl", "webkit-3d"];
    let context = false;
    for (let i = 0; i < names.length; i++) {
      try {
        context = canvas.getContext(names[i]);
        if (context && typeof context.getParameter == "function") {
          // WebGL is enabled
          result.push(names[i]);
        }
      } catch (e) {
      }
    }
    // WebGL is supported, but disabled
    if (result.length === 0)
      return "WebGL is disabled (but supported)!";
    else return result.join(",");
  }
  return "WebGL is not supported ";
}


function browserDetection(){
// Opera 8.0+
  const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
// Firefox 1.0+
  const isFirefox = typeof InstallTrigger !== 'undefined';
// Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
// Internet Explorer 6-11
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
// Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;
// Chrome 1 - 71
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
// Blink engine detection
  const isBlink = (isChrome || isOpera) && !!window.CSS;
  return (isFirefox)?'Firefox: ':"" + isChrome?'Chrome: ' :""+ isSafari?'Safari: ' :'' + isOpera?'Opera: ':'' + isIE?'IE: ':""
  + isEdge?'Edge: ' :'' + isBlink?'Blink: ':'';
}
