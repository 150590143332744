import React from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from "react-i18next";
import {Menu} from "primereact/components/menu/Menu";
import {HashRouter} from "react-router-dom";
import {Route} from 'react-router'
import ContainerRegionPage from "./containers/ContainerRegionPage";
import ContainerPicturePage from "./containers/ContainerPicturePage";


//===============================PREFERENCES URI===================================================
export const PICTURE_EDIT_PAGE_URI = "/settings/picture";
export const LANGUAGE_EDIT_PAGE_URI = "/settings/language";
const items = [
    {
        label: 'Personal picture',
        command:()=>{ window.location.hash=PICTURE_EDIT_PAGE_URI; }
    },
    {
        label: 'Language and localization',
        command:()=>{ window.location.hash=LANGUAGE_EDIT_PAGE_URI; }
    },
    {
        label: 'Account',
        command:()=>{ window.location.hash="/settings/account"; },
    },
    {
        label: 'Security',
        command:()=>{ window.location.hash="/settings/security"; },
    },
    {
        label: 'SSH and GPG keys',
        command:()=>{ window.location.hash="/settings/ssh"; },
    },
    {
        label: 'Notifications',
        command:()=>{ window.location.hash="/settings/notifications"; },
    },
    {
        label: 'Viewer control',
        command:()=>{ window.location.hash="/settings/interactors"; },
    }

];

/**
 * Displays personal preferences.
 */
class PreferencesPage extends React.Component {


    render() {
        const {auth,t} = this.props;
        console.log("PreferencesPage ...render");
        return (
            <HashRouter basename="/preferences#">
            <div style={{margin: '16px'}}>
                <h1>Settings</h1>
                <h3>Work in progress</h3>

                <div className="ui-g-2">
                    <Menu model={items}>
                    </Menu>
                </div>
                <div className="ui-g-10">
                    <Route exact path={PICTURE_EDIT_PAGE_URI} component={ContainerPicturePage}/>
                    <Route exact path={LANGUAGE_EDIT_PAGE_URI} component={ContainerRegionPage}/>

                </div>

            </div>
            </HashRouter>
        )
    }
}

PreferencesPage.propTypes = {
    auth: PropTypes.object.isRequired
};


export default withTranslation()(PreferencesPage);

