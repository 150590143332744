import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {ContainerICCCalculator} from "../containers/ContainerICCCalculator";
import {ContainerBACalculator} from "../containers/ContainerBACalculator";
import {ContainerKappaCalculator} from "../containers/ContainerKappaCalculator";

/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class IRRStatsPanel extends React.Component {

  constructor(props) {
    super(props);
  }




  render() {
    return (

      <React.Fragment>
        <TabView >
          <TabPanel header="Bland-Altman stats" >
            <ContainerBACalculator/>
          </TabPanel>
          <TabPanel header="ICC Calculator" >
            <ContainerICCCalculator/>
          </TabPanel>
          <TabPanel header="Kappa Calculator" >
            <ContainerKappaCalculator/>
          </TabPanel>
        </TabView>

      </React.Fragment>

    );
  }
}

export default withTranslation()(IRRStatsPanel);



IRRStatsPanel.propTypes = {
  modelParameters: PropTypes.object.isRequired,
  plotData: PropTypes.array.isRequired,
  t: PropTypes.func
};
