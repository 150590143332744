import React from "react";
import PropTypes from "prop-types";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {ORDINAL10, ordinalColors, WAHIDAH} from "../../helpers/colors";

const style={
  display: "flex",
  justifyContent: "space-between",
  alignItems:"center",
  marginTop: "1em",
  borderRadius: "1em",
  border: "solid 1px",
  padding: "1em",
  color: "white"
};

export class WorkflowTrayItem extends React.Component {

  render() {
    const {workflowData} = this.props;

    const status = workflowData.status!=null?workflowData.status.toUpperCase():"PUBLISHED";
    let color = status==="DRAFT"?"#789":ordinalColors(WAHIDAH)[0];
    style.backgroundColor = color;
    return  <div
      key={workflowData._id.concat(status)}
      style={style}
      draggable={true}
      onDragStart={event => {
        event.dataTransfer.setData('node-workflowId', workflowData._id);
        event.dataTransfer.setData('node-colour', color);
        event.dataTransfer.setData('node-type', "workflow");
        event.dataTransfer.setData('node-status', status);
      }}
    >
      <i className={"fa fa-sitemap"} style={{marginRight:"0.5em"}}/>
         <span style={{textAlign:"center"}}>{workflowData.name}</span>
      <i id={"wf"+workflowData._id} style={{marginLeft:"0.5em"}} className="fa fa-info-circle"/>
      <Tooltip
        for={"#wf"+workflowData._id}
        escape={false}
        title={"<strong>"+workflowData.name+"</strong>  \n "+workflowData.description}
      />
    </div>
  }
}

WorkflowTrayItem.propTypes = {
  workflowData: PropTypes.object, //callback to pass up state of the component
};