import React from "react";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";

const MIDDLE = "middle";  //id of left vie
const LOWER_RIGHT = "lowerRight";
const UPPER_RIGHT = "upperRight";


/**
 * Layout component for displaying viewers panel with Single element.
 *
 */
class OneMainTwoReferenceLayout extends React.Component {

    static getLabel(){
        return "One Main viewer with two half-size reference viewers and optimized viewport";
    }
    static getClassName(){
        return "sliceSelection3ViewersDemo";
    }
    static getViewersPortKeys(){
        return [MIDDLE,LOWER_RIGHT,UPPER_RIGHT];
    }
    constructor(props) {
        super(props);
        this.state = {
            widthMain:"80vh",
            widthReference:"40vh",
            heightMain:"80vh",
            heightReference:"40vh"
        };
        ["calcLayout"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }
    componentDidMount() {
        this.calcLayout();
        window.addEventListener("resize",this.calcLayout);
    }
    componentDidUpdate(prevProps, prevState) {
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.calcLayout);
    }
    /** Calculates the size of viewers.
     *  Still it is not final version,
     *  TODO FIXME calculate correct factors.
     **/
    calcLayout() {
        if (window.innerWidth < (1.5 * window.innerHeight) ) {
            this.setState({widthMain:"52vw",widthReference:"26vw",heightMain:"52vw",heightReference:"26vw"});
            console.log("Portait mode");
        }
        if (window.innerWidth > (1.5 * window.innerHeight) ) {
            this.setState({widthMain:"80vh",widthReference:"40vh",heightMain:"80vh",heightReference:"40vh"});
            console.log("Landscape mode");
        }
    }

    render() {
        const {toolComponentProps, toolComponentState} = this.props;
        const viewersState = toolComponentProps.viewersState;
        const styleMain = {padding: "0.1em", height: this.state.heightMain, width: this.state.widthMain};
        const styleRef = {padding: "0.1em", height: this.state.heightReference, width: this.state.widthReference};

        const middle = (viewersState != null && viewersState.hasOwnProperty(MIDDLE))
            ? BrowserFactory.build(MIDDLE, toolComponentProps, toolComponentState)
            : <div title="Main viewer here."/>;

        const lowerRight = (viewersState != null && viewersState.hasOwnProperty(LOWER_RIGHT))
            ? BrowserFactory.build(LOWER_RIGHT, toolComponentProps, toolComponentState)
            : <div title="Lower-right reference viewer here."/>;

        const upperRight = (viewersState != null && viewersState.hasOwnProperty(UPPER_RIGHT))
            ? BrowserFactory.build(UPPER_RIGHT, toolComponentProps, toolComponentState)
            : <div title="Upper-right reference viewer here."/>;


        return (
            <div  style={{display:"flex",justifyContent:"center"}}>
                <div style={{display:"inline-flex"}}>
                    <div style={styleMain} >
                         {middle}
                    </div>
                </div>
                <div>
                    <div style={styleRef} >
                        {upperRight}
                    </div>
                    <div style={styleRef} >
                        {lowerRight}
                    </div>
                </div>
            </div>
        )
    }
}

export default OneMainTwoReferenceLayout;

OneMainTwoReferenceLayout.defaultProps = {};

OneMainTwoReferenceLayout.propTypes = {};


