import React from "react";
import PropTypes from "prop-types";

class DisclaimerContentScrollPanel extends React.Component {

  constructor(props) {
    super(props);
    ["isTextTooShort"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver( this.isTextTooShort);
    this.resizeObserver.observe( document.getElementById("disclaimerScrollView"));
    this.isTextTooShort();
  }

  componentWillUnmount(){
    this.resizeObserver.disconnect();
  }

  isTextTooShort() {
    const {setEndReached} = this.props;
    const element = document.getElementById("disclaimerScrollView");
    if (element!=null
      && element.scrollHeight > 0
      && element.clientHeight > 0
      && element.parentElement !=null
      && element.clientHeight <= (element.parentElement.clientHeight+6))
      setEndReached(true);
  }

  render() {
    const {disclaimerContent, onScroll, language} = this.props;

    return (
      <pre style={preStyle}
             onScroll={onScroll}>
        <div
          id={"disclaimerScrollView"}
          dangerouslySetInnerHTML={{__html: disclaimerContent[language]!=null
                                ? disclaimerContent[language]["termsAndConditions"]
                                : "No Terms yet"}}/>
      </pre>
    );
  }
}

const preStyle = {
  whiteSpace: "pre-wrap",
  wordBreak: "keep-all",
  maxHeight:"25em",
  overflowY:"auto"
};

export default DisclaimerContentScrollPanel;

DisclaimerContentScrollPanel.propTypes = {
  disclaimerContent:PropTypes.string.isRequired,
  onScroll:PropTypes.func.isRequired,
  language:PropTypes.string.isRequired,
  setEndReached:PropTypes.func.isRequired,
};

DisclaimerContentScrollPanel.defaultProps = {};
