
import {connect} from "react-redux";
import { downloadExperimentResultsCSV } from "../action/ResultsAction";
import Results from "../components/Results";


const mapStateToProps = state => {
  return {
    messageQueue: state.messaging.msgQueue,
    downloadResultsStatus: state.results.downloadResults.downloadResultsStatus
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onDownloadResults: (experimentId, workflowId, miniworkflowId,
                        miniworkflowSet, downloadId, onError) => {
      dispatch(downloadExperimentResultsCSV(
        experimentId, workflowId, miniworkflowId, miniworkflowSet, downloadId, onError));
    }
  }
};

const projectContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerResults = projectContainerCreator(Results);
