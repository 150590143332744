import {createSelector} from 'reselect';
import {getNested, stringPropertyComparator} from "../../helpers/comparators";
import {matchRule} from "../../helpers/regulars";

export const getVisibilityFilter = (state) => state.expDesign.workflows.visibilityFilter;
export const getSorter = (state) => state.expDesign.workflows.sorter;
const getWorkflows = (state) => state.expDesign.workflows.workflows;

export const getVisibleWorkflows = createSelector(
  [ getVisibilityFilter, getSorter, getWorkflows ],
  (visibilityFilter, sorter, workflows) => {
    return getSortedWorkflows(sorter,getFilteredWorkflows(visibilityFilter,workflows));
  }
);

/**
 * Helper  for filtering cohorts.
 * @param filter
 * @param workflows
 * @returns {*}
 */
const getFilteredWorkflows = (filter, workflows) => {
  if ( filter==null || filter ==='' ) return workflows;
  return workflows.filter(t => matchRule(t.name.toLowerCase(),filter.toLowerCase()));

};



/**
 *  Helper func for sorting strings by name.
 * @param path - property path eg. "project.name", "name","project.experiments[0].name"
 * @returns {Function} negative, positive or 0 (as required in JS comparator interface for sort function)
 */
export function statusPropertyComparator(path) {
  return function(a,b) {
    const ax = getNested(a, path, '.');
    const x = ax!=null ?ax.toUpperCase():"PUBLISHED";
    const ay = getNested(b, path, '.');
    const y = ay!=null?ay.toUpperCase():"PUBLISHED";
    return x < y ? -1 : x > y ? 1 : 0;
  };
}

/** Helper used for sorting workflows.
 *
 * @param sorter
 * @param workflows
 * @returns {void | * | Uint8Array | Uint16Array | Int16Array | Float32Array} sorted workflows
 */
const getSortedWorkflows = (sorter, workflows) => {
  switch (sorter) {
    case 'SORT_BY_NAME_DESC':
      return workflows.sort(stringPropertyComparator('name'));
    case 'SORT_BY_NAME_ASC':
      return workflows.sort(stringPropertyComparator('name')).reverse();
    case 'SORT_BY_STATUS_DESC':
      return workflows.sort(statusPropertyComparator('status')).reverse();
    case 'SORT_BY_STATUS_ASC':
      return workflows.sort(statusPropertyComparator('status'));
  }
};
