import React from "react";
import PropTypes from 'prop-types';


/**
 * Dumb component for displaying StepMonitor for Miniworkflow.
 * @param steps
 * @param currentStep
 * @param subStep - optional solution to display sub-task withing general task
 * @param lock - showing lock icon
 * @returns {*}
 */
export const MiniWorkflowStepMonitor = ({steps,currentStep,subStep,lock}) => {

    const isLongProcess = steps!=null && steps.length>3;
    if  (subStep!=null && subStep['name']!=null)
        steps[currentStep]['name'] = `${steps[currentStep]['name']} : ${subStep['name'].toUpperCase()} `;
    let contents = (steps)?steps.map((el,index)=> {
        let classNam={};
        if(index<currentStep)
            classNam="taskStatusDone";
        else if (index===currentStep)
            classNam="taskStatusInProgress";
        else if (index>currentStep)
            classNam="taskStatusNotStarted";
         return  <li className={classNam} key={`sm${index}`} title={el.name}>
                       {(index!==currentStep && isLongProcess)?(index+1).toString():el.name}{lock===index && <i className="fa fa-lock" style={{textIndent:"5px",position:"absolute",fontSize:"small",zIndex:"10"}}/>}
                 </li>;
        })
        :null;

    return (<React.Fragment>
        <ul className="taskStatus" style={{display: "inline",marginLeft: "0px",paddingLeft: "inherit"}}>
        {contents}
        </ul>
    </React.Fragment>);
};

MiniWorkflowStepMonitor.propTypes={
    steps:PropTypes.array.isRequired,//array of step objects
    currentStep:PropTypes.number.isRequired,
    subStep:PropTypes.string
};
