import React from "react";
import PropTypes from "prop-types";
import {Prompt, withRouter} from "react-router";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {ContainerWorkflowEditorLeftPanel} from "../container/ContainerWorkflowEditorLeftPanel";
import WorkflowEditorRightPanel from "./WorkflowEditorRightPanel";
import Editor  from "workflow-new/dist/editor.bundle";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {TaskPropertiesPanel} from "./TaskPropertiesPanel";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/mode/text';
import 'brace/theme/github';
import {uuidv4} from "../../helpers/strings";
import {TaskPreviewPanel} from "./TaskPreviewPanel";
import {InputText} from "primereact/components/inputtext/InputText";


export class WorkflowEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      /*state for temporary objects (cache) for Transfer Object*/
      annotationTables:{},
      annotationTableColumns:{},
      questions:{},
      /*workflow Data*/
      workflowName:"Name of workflow",
      workflowDescription:"Desc of workflow",
      workflowId:uuidv4(),
      /*sidebars and Dialogs*/
      leftSidebarVisible:false,
      rightSidebarVisible:false,
      selectedNodes:{},
      workflowDroppedDialogVisible:false,
      inputWorkflow:null,
      inputWorkflowId:null,
      taskSettingsDialogVisible:false,
      taskPreviewDialogVisible:false,
      nodeSettings:null,
      activeIndex:0,
      transferObject:null, //finalresult to send to DB
      validationErrors:null,
      topOffset:0,
      serializedModel:null,
      workflowDropMode:null, // just to keep value of "edit","add", "duplcate", "task"
      workflowDropEvent:null,  // just to pass when task
      taskDropMode:null,
      taskDropEvent:null,
      taskDroppedDialogVisible:false,
      ioDialogVisible:false,
      ioLabel:"",
      ioNodeSettings:null
    };
    this.editorRef = React.createRef();
    ["onShow","onSelectionChange","onWorkflowDropped","onWorkflowDialogHide","onSettingsClick","onIOSettingsClick","onPreviewClick",
      "onTabChange","onOutputModified","updateTransferObjectCache","onTaskDropped","redispatch","lockDiagramModel"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  /**
   * setting up the distance between editor canvas and the top border of the viewport
   * it is required to set height of canvas - to remove scroll bar
   */
  componentDidMount() {
    const topOffset = this.editorRef.current.getBoundingClientRect().top;
    this.setState({topOffset})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {clearWorkflow,workflow,workflowState,workflowSaveState,workflowSaveError,messageQueue} = this.props;
    const {workflowDropMode,workflowDropEvent} = this.state;

    if(prevProps.workflow!==workflow && prevProps.workflowState !==workflowState && workflowState===REQUEST_STATUS_SUCCESS){

      if (workflowDropMode !== "add") {
        this.setState({
          workflowName: workflow.workflow.name,
          workflowDescription: workflow.workflow.description
        });
      }
      if (workflowDropMode === "edit") {
        this.setState({
          workflowId: workflow.workflow._id});
      }

      this.clickDropWorkflow(this.props.workflow);
      setTimeout(() => {
        this.distribute();
        this.zoomToFit();
      }, 500);
      clearWorkflow();
    }
    if(prevProps.workflowSaveState !==workflowSaveState ){
      if (workflowSaveState===REQUEST_STATUS_SUCCESS){
        messageQueue.show({severity: 'info', summary: 'Info', detail: "Workflow saved."});
      }
      if (workflowSaveState===REQUEST_STATUS_FAIL){
        messageQueue.show({severity: 'error', summary: 'Error', detail: workflowSaveError.message});
      }
    }
  }

  /**
   * This function is created  to prevent removing node, when "backspace" or "delete key" is pressed to clear the text in
   * the dialog window over Workflow Editor (Settings Panels).
   * According to Projectstorm documentation, to turn off deleting items or other event handlers, stateMachine should be applied.
   * Unfortunately, this does not work as supposed (see issues https://github.com/projectstorm/react-diagrams/issues/463,
   * https://github.com/projectstorm/react-diagrams/issues/414). Solution can be found in 414:
   * Lock the model with BaseModel.setLocked(true). This allows me to delete in the input field with [backspace] or [del]
   * without deleting the node. Furthermore it blocks the node from dragging if I select something in the textfield.
   *
   * @param shouldBeLocked - boolean
   */
  lockDiagramModel(shouldBeLocked){
    if (this.retrieveEngine!=null)
    this.retrieveEngine().getModel().setLocked(shouldBeLocked); //this should be exposed by WorkflowEditorAPI
  }

  componentWillUnmount() {
    this.props.clearWorkflow();
  }

  updateTransferObjectCache(key,value){
    this.setState({[key]:value});
  }

  onShow() {
    let el = document.getElementsByClassName("ui-sidebar-mask");
    el[0].style.visibility = "collapse";
  }

  onSelectionChange() {
    this.setState({selectedNodes:this.clickSelected()});
  }

  onWorkflowDropped(e){
    let id = e.dataTransfer.getData('node-workflowId');
    this.setState({workflowDropEvent:Object.assign({},e),workflowDroppedDialogVisible:true,inputWorkflowId:id});
  }

  onTaskDropped(e){
    this.taskDataTransfer =new DataTransfer(); //this has to be copied otherwise it will be destroyed by stopPropagation
    this.taskDataTransfer.setData('node-toolData',  e.dataTransfer.getData('node-toolData'));
    this.taskDataTransfer.setData('node-taskData', e.dataTransfer.getData('node-taskData'));
    this.taskDataTransfer.setData('node-taskInWorkflowData', e.dataTransfer.getData('node-taskInWorkflowData'));
    this.taskDataTransfer.setData('node-colour', e.dataTransfer.getData('node-colour'));
    this.taskDataTransfer.setData('node-type',  e.dataTransfer.getData('node-type'));
    this.taskDataTransfer.setData('event-redirected',  'true');

    this.taskDropEvent = new DragEvent("drop",e);

    this.setState({taskDroppedDialogVisible:true});
  }

  onSettingsClick(node,onUpdate){
    this.onNodeUpdate=onUpdate;
    this.setState(
      {
        taskSettingsDialogVisible:true,
        nodeSettings:node,
        leftSidebarVisible:false,
        rightSidebarVisible:false
      })
  }
  onIOSettingsClick(node,onUpdate){

    this.lockDiagramModel(true);
    this.onIONodeUpdate=onUpdate;
    this.setState(
      {
        ioDialogVisible:true,
        ioNodeSettings:node,
        ioLabel:node.name
      })
  }
  onPreviewClick(node){
    this.setState({taskPreviewDialogVisible:true})
    this.setState(
      { taskPreviewDialogVisible:true,
        nodeSettings:node
      })
  }

  onWorkflowDialogHide(){
    this.setState({workflowDroppedDialogVisible:false,taskDroppedDialogVisible:false});
  }

  onTabChange(e){
    if (e.index!==this.state.activeIndex) {
      this.setState({activeIndex: e.index});
      if (e.index > 0) { //close sidebars
        this.setState({
          leftSidebarVisible: false,
          rightSidebarVisible: false,
          serializedModel: this.clickGetDiagramModel()
        });
      } else {

        setTimeout(() => {
          this.clickSetDiagramModel(this.state.serializedModel);
        }, 50);

      }
    }
  }

  onOutputModified(transferObject,errors){

    if (transferObject!=null){
      transferObject.workflow.name = this.state.workflowName;
      transferObject.workflow.description = this.state.workflowDescription;
      transferObject.workflow._id = this.state.workflowId;
    }


    this.setState({
      transferObject:Object.assign({},transferObject),
      validationErrors:errors
    });
    if (errors!=null){
      this.props.messageQueue.show({severity: 'warning', summary: 'Warning', detail: "There are validation errors. Please check JSON tab."});
    }
  }


  redispatch(mode){
    if (mode==="duplicate"){
      const tskData = JSON.parse(this.taskDataTransfer.getData('node-taskData'));
      tskData._id = uuidv4();
      this.taskDataTransfer.setData('node-taskData',JSON.stringify(tskData));
    }

    const evt = new DragEvent("drop", {
      clientX:this.taskDropEvent.clientX,
      clientY:this.taskDropEvent.clientY,
      y:this.taskDropEvent.y,
      x:this.taskDropEvent.x,
      bubbles:true,
      dataTransfer:this.taskDataTransfer
    });
    document.getElementById("wf-editor-wrapper").children[0].dispatchEvent(evt);
    this.onWorkflowDialogHide();
  }

  render() {
    const {workflow,workflowState,match,getWorkflow,messageQueue,saveWorkflow,getWorkflows} =this.props;
    const {workflowDropEvent,taskDropEvent,workflowDroppedDialogVisible,taskDroppedDialogVisible} =this.state;
    // 1. For Diamond Node --------------------------------------
    /** Diamond Node - OnClick */
    const handleClickDiamondNode = (node) => {
      console.log('Click for diamond node', node);
    };
    /** Diamond Node - Double Click */
    const handleDoubleClickDiamondNode = (node) => {
      console.log('Double Click for diamond node', node)
    };
    // 2. For IO Node --------------------------------------------
    /** IO Node - OnClick */
    const handleClickIONode = (node) => {
      console.log('Click for IO node', node)
    };
    /** IO Node - Double Click */
    const handleDoubleClickIONode = (node) => {
      console.log('Double Click for IO node', node)
    };

    return (
      <React.Fragment>
        <Prompt
          when={true}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Sidebar
          position={"left"}
          visible={this.state.leftSidebarVisible}
          id="palette-sidebar"
          onShow={this.onShow}
          onHide={() => this.setState({leftSidebarVisible:false})}
          {...(this.state.leftSidebarVisible ? {style: {marginLeft:"50px",marginTop:"50px"}} :{style: {marginTop:"50px"}})}
        >
        <ContainerWorkflowEditorLeftPanel  lockDiagramModel={this.lockDiagramModel}/>
        </Sidebar>
        <Sidebar
          position={"right"}
          visible={this.state.rightSidebarVisible}
          id="action-sidebar"
          onShow={this.onShow}
          onHide={() => this.setState({rightSidebarVisible:false})}
          style={{marginTop:"50px"}}
        >
        <WorkflowEditorRightPanel
          transferObject={this.state.transferObject}
          clickClearCanvas={this.clickClearCanvas}
          clickCopyToClipboard={this.clickGetDiagramModel}
          clickParse={this.clickParse}
          clickRemoveLinks={this.clickRemoveLinks}
          clickRetrieveFromClipboard={this.clickSetDiagramModel}
          clickSelected={this.clickSelected}
          distribute={this.distribute}
          zoomToFit={this.zoomToFit}
          selectedNodes={this.state.selectedNodes}
          workflowName={this.state.workflowName}
          workflowDescription={this.state.workflowDescription}
          setWorkflowProperty={(object)=>this.setState(object)}
          saveWorkflow={saveWorkflow}
          getWorkflows={getWorkflows}
          lockDiagramModel={this.lockDiagramModel}
        />
        </Sidebar>

        <TabView activeIndex={this.state.activeIndex} onTabChange={this.onTabChange} >
          <TabPanel header="Editor" contentStyle={{padding:"10px 0 0 0px"}} >
            <div className="fa fa-chevron-right"
                 title={"Palette"}
                 style={{fontSize:"2em",color:"white", position:"absolute", left:"100px", top:"125px", zIndex:"1"}}
                 id="hamburger"
                 onClick={()=>this.setState({leftSidebarVisible:true,rightSidebarVisible:false})}/>
            <div className="fa fa-bars"
                 title={"Actions"}
                 style={{fontSize:"2em",color:"white", position:"absolute", right:"75px", top:"125px", zIndex:"1"}}
                 id="hamburger2"
                 onClick={()=>this.setState({rightSidebarVisible:true,leftSidebarVisible:false})}/>
                 <div   ref={this.editorRef}>
          <div
            className={"workflow-editor-marker"}
            id={"wf-editor-wrapper"}
            onDropCapture={(e)=>{
            console.log("onDropCapture",e);
            let type = e.dataTransfer.getData('node-type');
            let redirected = e.dataTransfer.getData('event-redirected');
            if (type==="workflow") {
              this.onWorkflowDropped(e);
              e.preventDefault();
              e.stopPropagation();
            }
            if (type==="task"  && redirected!=="true") {
              this.onTaskDropped(e);
              e.preventDefault();
              e.stopPropagation();
            }
          }
          }
               >
            <Editor
              canvasStyle={{height:`calc(100vh - ${this.state.topOffset}px - 4px)`}}
              autoLayout={true}
              inputWorkflow={null}
              onOutputModified={this.onOutputModified}
              /** data - not required here, since editor is not used with built-in panels (leftPanelVisible=false)*/
              jsonTasks={null}
              jsonTools={null}
              /** handler */
              // 1. Diamond Node
              handleClickDiamondNode={handleClickDiamondNode}
              handleDoubleClickDiamondNode={handleDoubleClickDiamondNode}
              // 2. IO Node
              setCallRepaint={click => this.repaintCanvas = click}
              handleClickIONode={handleClickIONode}
              handleDoubleClickIONode={handleDoubleClickIONode}
              onNodesUpdate={()=>this.setState({transferObject:null,validationErrors:null})}
              onLinksUpdated={()=>this.setState({transferObject:null,validationErrors:null})}
              setCallClearCanvas={click => this.clickClearCanvas = click}
              setCallParse={click => this.clickParse = click}
              setCallQuit={click => this.clickQuit = click}
              setCallRetrieveEngine={(click)=>this.retrieveEngine = click}
              setCallGetDiagramModel={click => this.clickGetDiagramModel = click}
              setCallSetDiagramModel={click => this.clickSetDiagramModel = click}
              setCallRemoveLinks={click => this.clickRemoveLinks = click}
              setCallRetrieveSelected={click =>this.clickSelected = click}
              setCallDistribute={click =>this.distribute = click}
              setCallZoomToFit={click =>this.zoomToFit = click}
              onSelectionChanged={()=>this.onSelectionChange()}
              setCallDropWorkflow={click => this.clickDropWorkflow = click}
              onSettingsIconClick={(node,onUpdate)=>this.onSettingsClick(node,onUpdate)}
              onIOSettingsIconClick={(node,onUpdate)=>this.onIOSettingsClick(node,onUpdate)}
              onPreviewIconClick={(node)=>this.onPreviewClick(node)}
              onError={(e)=>messageQueue.show({severity: 'error', summary: 'Parsing error', detail: e.name + ': ' + e.message})}
            />
                   </div>
                 </div>
          </TabPanel>
          <TabPanel header="JSON" disabled={!(this.state.transferObject!=null)}>
            <div className="ui-g-7">
              <h1>Workflow document</h1>
            <AceEditor
              mode="json"
              readOnly={true}
              theme="github"
              name="EDITOR"
              highlightActiveLine={true}
              width="100%"
              height="calc(100vh - 200px)"
              value={this.state.transferObject!=null && this.state.transferObject.workflow!=null ? JSON.stringify(this.state.transferObject.workflow, null, '\t'):"Not available"}
              wrapEnabled={true}
            />
            </div>
            <div className="ui-g-5">
              <h1>Workflow Validation</h1>
              <AceEditor
                mode="json"
                readOnly={true}
                theme="github"
                name="EDITOR"
                highlightActiveLine={false}
                value={this.state.validationErrors!=null?JSON.stringify(this.state.validationErrors,null,'\t'):"Not available"}
                height="calc(100vh - 200px)"
                width="100%"
                wrapEnabled={true}
              />
            </div>
          </TabPanel>
          <TabPanel header="Simulator" disabled={true}>
          </TabPanel>
        </TabView>
        <Dialog header={"IO Properties"}
                visible={this.state.ioDialogVisible}
                modal={true}
                onHide={()=>{
                  this.setState({ioDialogVisible:false});
                  this.lockDiagramModel(false);
                }}
                style={{width:'auto'}}

        > <div>
            <label>Name: </label>
            <InputText title={"Label"}
                       value={this.state.ioLabel}
                       onChange={(e)=>{
                         this.setState({ioLabel:e.target.value});
                       }}
            />
          </div>
          <Button label={"Save"} onClick={
                ()=>{
                  const ioNode = this.state.ioNodeSettings;
                  ioNode.name = this.state.ioLabel;
                  this.onIONodeUpdate(ioNode);
                  this.setState({ioDialogVisible:false});
                }
              }
              />
          <Button label={"Cancel"} onClick={()=>this.setState({ioDialogVisible:false})}/>
        </Dialog>
        <Dialog header={workflowDroppedDialogVisible?"Workflow":"Task"}
                visible={workflowDroppedDialogVisible || taskDroppedDialogVisible }
                modal={true}
                onHide={this.onWorkflowDialogHide}
                style={{width:'auto'}}
        >
          {workflowDropEvent !=null && workflowDropEvent.dataTransfer!=null && workflowDroppedDialogVisible &&
            <React.Fragment>
          <h1> What do you want to do with this workflow?</h1>
          <div style={{textAlign:"center"}}>
          {workflowDropEvent.dataTransfer.getData("node-status")==="DRAFT" &&
          <Button label={"Edit"} onClick={()=>{
            getWorkflow(this.state.inputWorkflowId);
            this.setState(  {   workflowDropMode: "edit"      }      );
            this.onWorkflowDialogHide();
          }
          }/>}
          <Button label={"Duplicate"} onClick={()=>{
            getWorkflow(this.state.inputWorkflowId);
            this.setState(      { workflowDropMode: "duplicate"  }    );
            this.onWorkflowDialogHide();
          }
          }/>
          <Button label={"Add elements"} onClick={()=>{
            getWorkflow(this.state.inputWorkflowId);
            this.setState(      { workflowDropMode: "add"  }    );
            this.onWorkflowDialogHide();
          }
          }/>
          <Button label={"Cancel"} onClick={this.onWorkflowDialogHide}/>
          </div>
            </React.Fragment>}
          {this.taskDropEvent !=null && this.taskDropEvent.dataTransfer!=null && taskDroppedDialogVisible &&
          <React.Fragment>
            <h1> What do you want to do with this task?</h1>
            <div style={{textAlign:"center"}}>
              {this.taskDropEvent.dataTransfer.getData("node-status")==="DRAFT" &&
              <Button label={"Edit"} onClick={()=>this.redispatch("edit") }/>}
              <Button label={"Duplicate"} onClick={()=>this.redispatch("duplicate")}/>
              <Button label={"Add"} onClick={()=>this.redispatch("add")}/>
              <Button label={"Cancel"} onClick={this.onWorkflowDialogHide}/>
            </div>
          </React.Fragment>}
        </Dialog>
        <Dialog header={"Task properties"}
                visible={this.state.taskSettingsDialogVisible}
                modal={true}
                onHide={()=> {
                  this.lockDiagramModel(false);
                  if (this.onNodeUpdate!=null)
                    this.onNodeUpdate();
                  this.onNodeUpdate = null;
                  this.setState({taskSettingsDialogVisible:false,nodeSettings:null});
                  this.repaintCanvas();
                }}
                onShow={()=>{this.lockDiagramModel(true);}}
                style={{width:'60vw'}}>
          <TaskPropertiesPanel nodeSettings={this.state.nodeSettings}
                               updateTransferTool={()=>{this.setState({transferObject:null,validationErrors:null})}}
                               annotationTables={this.state.annotationTables}
                               annotationTableColumns={this.state.annotationTableColumns}
                               questions={this.state.questions}
                               updateTransferObjectCache={this.updateTransferObjectCache}
          />
        </Dialog>
        <Dialog header={"Task Preview"}
                visible={this.state.taskPreviewDialogVisible}
                modal={true}
                onHide={()=> this.setState({taskPreviewDialogVisible:false,nodeSettings:null})}>
          <TaskPreviewPanel nodeSettings={this.state.nodeSettings}/>
        </Dialog>
      </React.Fragment>
    )
  };
}
export default withRouter(WorkflowEditor);

WorkflowEditor.propTypes= {
  workflow : PropTypes.object.isRequired,
  workflowState: PropTypes.string.isRequired,
  workflowSaveState: PropTypes.string.isRequired,
  workflowSaveError: PropTypes.object.isRequired,
  getWorkflow:PropTypes.func.isRequired,
  getWorkflows:PropTypes.func.isRequired,
  clearWorkflow:PropTypes.func.isRequired,
  saveWorkflow:PropTypes.func.isRequired,
  messageQueue:PropTypes.object.isRequired
};
