import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {getNestedProp} from "../../helpers/expressions";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import {OverlayPanel} from "primereact/components/overlaypanel/OverlayPanel";
import {REQUEST_STATUS_FAIL} from "../../../Constants";


/**
 * Component designed for displaying ICC Calculator.
 * It uses data from plot series.
 *
 */

class ICCCalculator extends React.Component {

  constructor(props) {
    super(props);
    ["updateICCParams"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {extendedModelParameters, calculateModelForICC} = this.props;
    if (!(getNestedProp(["ICCOutcome", "value"], extendedModelParameters) != null)) {
      calculateModelForICC();
    }
  }

  componentDidUpdate(prevProps, prevState, ss) {
    const {extendedModelParameters, calculateModelForICC} = this.props;
    if (prevProps.extendedModelParameters !== extendedModelParameters &&
      getNestedProp(["ICCParameters"], extendedModelParameters) !== getNestedProp(["ICCParameters"], prevProps.extendedModelParameters)) {
      calculateModelForICC();
    }
  }

  updateICCParams(prop, value) {
    const {extendedModelParameters, updateExtendedModelPropertySAM} = this.props;
    const copyParam = Object.assign({}, getNestedProp(["ICCParameters"], extendedModelParameters));
    copyParam[prop] = value;
    updateExtendedModelPropertySAM("ICCParameters", copyParam);
  }


  render() {
    const {t, extendedModelParameters} = this.props;

    return (

      <React.Fragment>
        <OverlayPanel ref={op => this.op = op}>
          <h4>Intraclass correlation coefficient (ICC) for oneway and twoway models</h4>

          <p><b>Description:</b>
          Computes single score or average score ICCs as an index of interrater reliability of quantitative
            data. Additionally, F-test and confidence interval are computed.</p>
          <p><b>Details:</b>
          <ul>
            <li>Missing data are omitted in a listwise way.</li>
            <li>When considering which form of ICC is appropriate for an actual set of data, one has take several
              decisions (Shrout & Fleiss, 1979):
              <ul>
                <li>
                  Should only the subjects be considered as random effects ("oneway" model) or are subjects
                  and raters randomly chosen from a bigger pool of persons ("twoway" model).
                </li>
                <li>
                  If differences in judges’ mean ratings are of interest, interrater "agreement" instead of "consistency" should be computed.
                </li>
                <li>
                  If the unit of analysis is a mean of several ratings, unit should be changed to ’"average"’. In
                  most cases, however, single values (unit=’"single"’) are regarded
                </li>
              </ul>
            </li>
          </ul>
          </p>
          <p><b>References:</b>
          <ol>
            <li><b>Various Coefficients of Interrater Reliability and Agreement (IRR)</b>, M.Gamer et al., Package
              ‘irr’, CRAN Repository, https://cran.r-project.org/web/packages/irr/irr.pdf)
            </li>
          </ol>
          </p>
        </OverlayPanel>

        <h3>Arguments</h3>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div title={"n*m matrix, n subjects m raters."}>
            <label>Ratings</label>
            <SelectButton
              options={[{value: "all", label: "All Raters"}, {
                label: "Group 1 Raters only",
                value: "group1"
              }, {label: "Group 2 Raters only ", value: "group2"}, {label: "Group Means", value: "means"}]}
              onChange={(e) => {
                this.updateICCParams("ratings", e.value)
              }}
              value={getNestedProp(["ICCParameters", "ratings"], extendedModelParameters)}/>
          </div>
          <div title={Object.values(MSG)[2]}>
            <label>{Object.keys(MSG)[2]}</label>
            <SelectButton
              options={[{value: "oneway", label: "oneway"}, {value: "twoway", label: "twoway"}]}
              onChange={(e) => {
                this.updateICCParams("model", e.value)
              }}
              value={getNestedProp(["ICCParameters", "model"], extendedModelParameters)}/>
          </div>
          <div title={Object.values(MSG)[3]}>
            <label>{Object.keys(MSG)[3]}</label>
            <SelectButton
              options={[{label: "consistency", value: "consistency"}, {label: "agreement", value: "agreement"}]}
              onChange={(e) => {
                this.updateICCParams("type", e.value)
              }}
              value={getNestedProp(["ICCParameters", "type"], extendedModelParameters)}/>
          </div>
          <div title={Object.values(MSG)[4]}>
            <label>{Object.keys(MSG)[4]}</label>
            <SelectButton
              options={[{label: "single", value: "single"}, {label: "average", value: "average"}]}
              onChange={(e) => {
                this.updateICCParams("unit", e.value)
              }}
              value={getNestedProp(["ICCParameters", "unit"], extendedModelParameters)}/>
          </div>
          <div
            title={"Specification of the null hypothesis r=r0. Note that a one sided test (H1: r > r0) is performed"}>
            <label>{Object.keys(MSG)[7]}</label>
            <SelectButton
              options={[{label: "0", value: 0}, {label: "1", value: 1}]}
              onChange={(e) => {
                this.updateICCParams("r0", e.value)
              }}
              value={getNestedProp(["ICCParameters", "r0"], extendedModelParameters)}
            />
          </div>
          <div title={Object.values(MSG)[12]}>
            <label>{Object.keys(MSG)[12]}</label>
            <SelectButton
              options={[{label: "0.90", value: 0.90}, {label: "0.95", value: 0.95}, {label: "0.99", value: .99}]}
              onChange={(e) => {
                this.updateICCParams("conf.level", e.value)
              }}
              value={getNestedProp(["ICCParameters", "conf.level"], extendedModelParameters)}/>
          </div>
          <div>
            <i className={"fa fa-question-circle-o"}
               onClick={(e) => this.op.toggle(e)}
              //onMouseOver={(e) => this.op.toggle(e)}
               //onMouseLeave={(e) => this.op.toggle(e)}
               style={{fontSize: "xxx-large", color: "#00D2D0"}}
            />
          </div>
        </div>
        <h3>Value</h3>
        <DataTable
          value={getNestedProp(["ICCOutcome"], extendedModelParameters)} // concat and remove dups
          emptyMessage={getNestedProp(["ICCOutcomeState"], extendedModelParameters) === REQUEST_STATUS_FAIL
            ? <span style={{color: "red"}}>R server problem</span>
            : "No statistics found"
          }
        >
          <Column field={"subjects"} header={<div title={Object.values(MSG)[0]}>{Object.keys(MSG)[0]}</div>}/>
          <Column field={"raters"} header={<div title={Object.values(MSG)[1]}>{Object.keys(MSG)[1]}</div>}/>
          <Column field={"model"} header={<div title={Object.values(MSG)[2]}>{Object.keys(MSG)[2]}</div>}/>
          <Column field={"type"} header={<div title={Object.values(MSG)[3]}>{Object.keys(MSG)[3]}</div>}/>
          <Column field={"unit"} header={<div title={Object.values(MSG)[4]}>{Object.keys(MSG)[4]}</div>}/>
          <Column body={(row) => {
            return row['icc.name']
          }} header={<div title={Object.values(MSG)[5]}>{Object.keys(MSG)[5]}</div>}/>
          <Column field={"value"} header={<div title={Object.values(MSG)[6]}>{Object.keys(MSG)[6]}</div>}/>
          <Column field={"r0"} header={<div title={Object.values(MSG)[7]}>{Object.keys(MSG)[7]}</div>}/>
          <Column field={"Fvalue"} header={<div title={Object.values(MSG)[8]}>{Object.keys(MSG)[8]}</div>}/>
          <Column field={"df1"} header={<div title={Object.values(MSG)[9]}>{Object.keys(MSG)[9]}</div>}/>
          <Column field={"df2"} header={<div title={Object.values(MSG)[10]}>{Object.keys(MSG)[10]}</div>}/>
          <Column body={(row) => {
            return row['p.value']
          }} header={<div title={Object.values(MSG)[11]}>{Object.keys(MSG)[11]}</div>}/>
          <Column body={(row) => {
            return row['conf.level']
          }} header={<div title={Object.values(MSG)[12]}>{Object.keys(MSG)[12]}</div>}/>
          <Column field={"lbound"} header={<div title={Object.values(MSG)[13]}>{Object.keys(MSG)[13]}</div>}/>
          <Column field={"ubound"} header={<div title={Object.values(MSG)[14]}>{Object.keys(MSG)[14]}</div>}/>
        </DataTable>
        <div style={{fontStyle: "italic", float: "right"}}>Generated with use of CRAN package: <a
          style={{textDecoration: "underline"}} target={"_blank"}
          href={"https://cran.r-project.org/web/packages/irr/irr.pdf#nameddest=icc"}>Various Coefficients of Interrater Reliability
          and Agreement (IRR)</a></div>

      </React.Fragment>

    );
  }
}


const MSG = {
  "Cases": "The number of subjects (cases) examined",
  "Raters": "The number of raters",
  "Model": "A character string describing the selected model for the analysis",
  "Type": "A character string describing the selected type of interrater reliability",
  "Unit": "A character string describing the unit of analysis",
  "Icc name": "A character string specifying the name of ICC according to McGraw & Wong (1996)",
  "Value": "The intraclass correlation coefficient",
  "r0": "The specified null hypothesis",
  "F": "The value of the F-statistic",
  "df1": "The numerator degrees of freedom",
  "df2": "The denominator degrees of freedom",
  "p-value": "The p-value for a two-sided test",
  "Conf. Level": "The confidence level for the interval",
  "L. bound": "The lower bound of the confidence interval",
  "U. bound": "The upper bound of the confidence interval"

};


export default withTranslation()(ICCCalculator);


ICCCalculator.propTypes = {
  rawData: PropTypes.object.isRequired,
  extendedModelParameters: PropTypes.object.isRequired,
  plotData: PropTypes.array.isRequired,
  calculateModelForICC: PropTypes.func.isRequired,
  updateExtendedModelPropertySAM: PropTypes.func.isRequired,
  t: PropTypes.func
};


// {
//   "subjects": "5",
//   "raters": "2",
//   "model": "twoway",
//   "type": "agreement",
//   "unit": "single",
//   "icc.name": "ICC(A,1)",
//   "value": "0.777777777777778",
//   "r0": "1",
//   "Fvalue": "0",
//   "df1": "4",
//   "df2": "4.78622478825951",
//   "p.value": "1",
//   "conf.level": "0.95",
//   "lbound": "-0.0197637269413956",
//   "ubound": "0.974014790722964"
// }