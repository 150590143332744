import React from "react";
import PropTypes from "prop-types";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Spinner} from "primereact/components/spinner/Spinner";
import {Panel} from "primereact/components/panel/Panel";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";

/**
 * Panel containing UI for map adjustments.
 * Just for demo purposes
 */
class GeoControlPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      mapPanel:true,
      layersPanel:true,
      sitesPanel:true
    };
    ["changeBasemapType"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {loadSitesData}=this.props;
    if (loadSitesData){
      loadSitesData();
    }
  }


  changeBasemapType(event){
    const {updateMapProperty}=this.props;
    updateMapProperty("basemap", event.value);
  }



  render() {
    const {sites,updateSiteVisibility,updateEditableLayersProperty,basemap,blendMode,markerSize,updateMarkersSize, opacity,
      fill,updateFillingStyle,legend,updateLegendVisibility} = this.props;

    const labelmapBody = (row) => {
      return <div style={{width: "4em"}}>
        <Checkbox checked={row.visible}
                  onChange={(ev)=>{
                    updateSiteVisibility(row,ev.checked);
                    this.setState({}); /// force refreshing since geojson features are not stored in  Redux
                  }}/>
      </div>
    };

    return (
      <div style={{maxWidth:"max-content",width:"100%"}}>
        <Panel
          header={"Map settings"}
          toggleable={true}
          collapsed={!this.state.mapPanel}
          onCollapse={() => this.setState({mapPanel:!this.state.mapPanel})}
          onExpand={() => this.setState({mapPanel:!this.state.mapPanel})}
        >
          <div>
          Type of Map: <Dropdown options={basemaps}
                                 value={basemap}
                                 onChange={this.changeBasemapType}/>
          </div>
          <div>
            Legend:
            <SelectButton value={legend}
                          options={[{value:true,label:"On" },{value:false,label:"Off"}]}
                          onChange={e=>updateLegendVisibility(e.value)} />
          </div>
        </Panel>
        <Panel
          header={"Layer settings"}
          toggleable={true}
          collapsed={!this.state.layersPanel}
          onCollapse={() => this.setState({layersPanel:!this.state.layersPanel})}
          onExpand={() => this.setState({layersPanel:!this.state.layersPanel})}
        >
          <div>
          Layers blend mode:
          <Dropdown options={blendModes.map(el=>{return {value:el,label:el}})}
                    value={blendMode}
                    onChange={(e)=>{
                      updateEditableLayersProperty("blendMode",e.value);
                    }}
          />
          </div>
          <div>
          Layers opacity:
          {/*<Slider min={0} max={1} value={opacity} step={0.01} style={{width:"140px"}}*/}
          {/*          onChange={(e)=>{*/}
          {/*            updateEditableLayersProperty("opacity",e.value);*/}
          {/*          }}*/}
          {/*/>*/}
            <input type="range"
                   onChange={(e) => updateEditableLayersProperty("opacity", e.target.value / 100)}
                   min={0} step={10} max={100}
                   style={{
                     width: "140px",
                   }}
                   value={100 * opacity}
            />
          </div>
          <div>
            Filling style:
            <SelectButton value={fill}
                          options={[{value:"mixed",label:"mixed" },{value:"solid",label:"solid"}]}
                          onChange={e=>updateFillingStyle(e.value)} />
          </div>
        </Panel>
        <Panel
          header={"Sites settings"}
          toggleable={true}
          collapsed={!this.state.sitesPanel}
          onCollapse={() => this.setState({sitesPanel:!this.state.sitesPanel})}
          onExpand={() => this.setState({sitesPanel:!this.state.sitesPanel})}
        >
          Marker size:
          <Spinner  value={markerSize}
                    min={1}
                    max={50}
                    step={1}
                    size={2}
                    onChange={(e)=>updateMarkersSize(e.value)}
          />
          <DataTable value={sites}
                     scrollable={true}
                     style={{width:"100%"}}
                     scrollHeight={"200px"}>
            <Column field={"Site_ID"} header={"Id"} sortable={true}/>
            <Column field={"Country"} header={"Country"} sortable={true}/>
            <Column field={"Latitude"} header={"Lat."} sortable={true}/>
            <Column field={"Longitude"} header={"Long."} sortable={true}/>
            <Column header={"visible"}  body={labelmapBody}/>
          </DataTable>
        </Panel>
      </div>
    );
  }
}

export default GeoControlPanel;

GeoControlPanel.propTypes = {
  view:PropTypes.object.isRequired,
  viewState:PropTypes.string.isRequired,
  sites:PropTypes.object.isRequired,
  sitesState:PropTypes.string.isRequired,
  updateMapProperty:PropTypes.func.isRequired,
  updateSitesData:PropTypes.func.isRequired,
  updateSiteVisibility:PropTypes.func.isRequired,
  updateMarkersSize:PropTypes.func.isRequired,
  updateOpacity:PropTypes.func.isRequired,
  loadSitesData:PropTypes.func.isRequired,
  updateEditableLayersProperty:PropTypes.func.isRequired,
  updateFillingStyle:PropTypes.func.isRequired,
  basemap:PropTypes.object.isRequired,
  blendMode:PropTypes.string.isRequired,
  opacity:PropTypes.number.isRequired,
  markerSize:PropTypes.number.isRequired,
  fill:PropTypes.string.isRequired,
  updateLegendVisibility:PropTypes.func.isRequired,
  legend:PropTypes.bool.isRequired
};

GeoControlPanel.defaultProps = {};

const basemaps = [
  {value:"satellite", label:"satellite"},
  {value:"hybrid", label:"hybrid"},
  {value:"osm", label:"Open Street Map"},
  {value:"terrain", label:"World Terrain Base"},
  {value:"dark-gray-vector", label:"Dark Gray"},
  {value:"gray-vector", label:"Light Gray"},
  {value:"streets-vector", label:"World Street"},
  {value:"streets-night-vector", label:"World Street at Night"},
  {value:"streets-navigation-vector", label:"World Navigation"},
  {value:"topo-vector", label:"World Topography"},
  {value:"streets-relief-vector", label:"World Hillshade and Streets"},
  {value:"national-geographic", label:"National Geographic"},
];


const blendModes = [
  'average', 'color-burn', 'color-dodge', 'color', 'darken', 'destination-atop', 'destination-in', 'destination-out',
  'destination-over', 'difference', 'exclusion', 'hard-light', 'hue', 'invert', 'lighten', 'lighter', 'luminosity',
  'minus', 'multiply', 'normal', 'overlay', 'plus', 'reflect', 'saturation', 'screen', 'soft-light', 'source-atop',
  'source-in', 'source-out', 'vivid-light', 'xor'
  ];