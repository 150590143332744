import React from 'react';
import {withRouter} from "react-router";
import PropTypes from 'prop-types';
import {renderSortIcon, sortByProperty} from "../../helpers/comparators";
import ShowHide from "./ShowHide";
import {Link} from "react-router-dom";
import {dateDiffInDays} from "../../helpers/dates";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";

const TASK_ID="taskId";
const MF_KEY="miniWorkflowKey";
const MF_SET_ID="miniWorkflowSetId";
/**
 *  Panel displaying miniworkflow tasks or tasks, depending on type passed through router link.
 */
class TaskDashboard extends React.Component {
    constructor() {
        super();
        this.onShowHideCompleted = this.onShowHideCompleted.bind(this);
        TaskDashboard.isMissedDeadline = TaskDashboard.isMissedDeadline.bind(this);
    }

    componentDidMount(){
        const{getManualTasks, match}=this.props;
        if(match!=null){
            if (match.params[MF_SET_ID]!=null) {
                getManualTasks(match.params[MF_SET_ID],match.params[MF_KEY]);
            }
            else if (match.params[TASK_ID]!=null) {
                getManualTasks(match.params[TASK_ID]);
            }
        }

    }

    onShowHideCompleted() {
        if (this.props.filter !== 'HIDE_COMPLETED')
            this.props.onFilterChange('HIDE_COMPLETED');
        else this.props.onFilterChange('SHOW_ALL');
    }
    static isMissedDeadline(date, status) {
        if (status === "COMPLETED")
            return <span title="Task completed">Completed</span>;
        else {
            let now=new Date();
            let thenn = new Date(date);
            if (now > thenn) {
                return <span className="missedDeadline"
                             title="Missed deadline">{thenn.toLocaleDateString()}</span>;
            }
            else {
                if (dateDiffInDays(now, thenn) < 2)
                    return <span className="closeDeadline"
                                 title="Don't miss the deadline! This is the last day!">
                        {thenn.toLocaleDateString()}
                    </span>;
                else return <span>{thenn.toLocaleDateString()}</span>;
            }
        }
    };

    render() {
        const {manualTasks, onSorterChange, sorter, manualTasksState,match,getManualTask} = this.props;


        const nodes = (manualTasks!=null && manualTasksState===REQUEST_STATUS_SUCCESS)?manualTasks.map((p) =>
            <div className='spineRowStyle' style={{display: "inline-block",width:"100%"}} key={p.expId}>
                <Link to={`/tool/${match.params[MF_KEY]}/${match.params[MF_SET_ID]}/${p.expId}`}>
                    <div className="spineColStyle col5"
                         style={{width: "50%"}}
                    >{p.expName}</div>
                </Link>

                <div className="spineColStyle " style={{width: "20%"}}>{new Date(p.dueDate).toLocaleDateString()}</div>
                <div className="spineColStyle " style={{textAlign: "center",width: "10%"}}>{p['numberOfCasesMissing']}</div>
                <div className="spineColStyle " style={{textAlign: "center",width: "10%"}}>{p['numberOfCasesDone']}</div>
                <div className="spineColStyle " style={{textAlign: "center",width: "10%"}}>{p['numberOfCases']}</div>
                {/*{isMissedDeadline(["spineColStyle","col4"],p.dueDate)}*/}
            </div>):<div/>;



        return (
            <div style={{width: '98%', margin: '1%'}}>
                <span className="reg-headtitle">Workload</span>
                <div className="spineTableStyle" style={{display: "inline-block",width:"100%"}}>
                    <div className='spineRowHeaderStyle' style={{width:"100%",display:"inline-block"}}>
                        <div className="spineColStyle col5" style={{width: "50%"}} onClick={() => {
                            sortByProperty("NAME", sorter, onSorterChange);
                        }}>
                            Experiment{renderSortIcon('NAME', sorter)}</div>

                        <div className="spineColStyle"  style={{width: "20%"}} onClick={() => {
                            sortByProperty("DUE_DATE", sorter, onSorterChange);
                        }}>
                            Due Date{renderSortIcon('DUE_DATE', sorter)}</div>
                        <div className="spineColStyle" style={{textAlign: "center",width: "10%"}} onClick={() => {
                            sortByProperty("CASES_MISSING", sorter, onSorterChange);
                        }}>
                            Cases Missing {renderSortIcon('CASES_MISSING', sorter)}</div>
                        <div className="spineColStyle col5" style={{textAlign: "center",width: "10%"}} onClick={() => {
                            sortByProperty("CASES_DONE", sorter, onSorterChange);
                        }}>
                            Cases Done {renderSortIcon('CASES_DONE', sorter)}</div>
                        <div className="spineColStyle col5" style={{textAlign: "center",width: "10%"}} >
                            Cases Total {renderSortIcon('CASES', sorter)}</div>
                    </div>
                    <div className="spineTableScrollableContent">
                        <div className="spineTableBody" style={{display: "inline-block",width:"100%"}}>
                            {nodes}
                        </div>
                    </div>
                </div>
                <ShowHide clb={this.onShowHideCompleted} value={this.props.filter === 'HIDE_COMPLETED'}/>
            </div>
        )
    }
}


TaskDashboard.propTypes = {
    getManualTasks: PropTypes.func.isRequired,
    getManualTask:PropTypes.func.isRequired,
    manualTasks: PropTypes.array.isRequired,
    manualTasksState: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func,
    onSorterChange: PropTypes.func,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default withRouter(TaskDashboard);