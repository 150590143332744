import axios from 'axios';

import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL
} from './actionType.js';
import {HOST_URL} from "../../../Constants";

//FORGOT PASSWORD PROCESS ************************************************************

function requestForgotPassword() {
  return {
    type: FORGOT_PASSWORD_REQUEST
  }
}

function receiveForgotPassword() {
  return {
    type: FORGOT_PASSWORD_SUCCESS
  }
}

function errorForgotPassword() {
  return {
    type: FORGOT_PASSWORD_FAIL
  }
}


/**
 * Dispatch to reducer the request and it's output
 *
 * @param emailObject {Object} (email to send the reset token -> {email: 'abc@def.com'})
 * @param onSuccess callback for successful petitions. Receives the data of the response
 * @param onError callback for errored petitions. Receives the data of the errored response
 * @return function(*) or success {Object}
 */
export function forgotPassword(emailObject,onSuccess,onError) {
    console.log('Executing forgotPassword.');
    return dispatch => {
        // Dispatch action request (if the process takes time we can
        // display the rolling ball animation)
        dispatch(requestForgotPassword());
        // Request to the backend
        const forgotPasswordUrl = `${HOST_URL}/api/user/forgot-password`;
        return axios.post(forgotPasswordUrl, emailObject)
            .then(response => {
                //Check status code
                console.log('response ', response);
                if (response.status !== 200) {
                    //Display error message if forgot password request is invalid
                    onError(response.data);
                    dispatch(errorForgotPassword());
                } else {
                    console.log('forgot password status:', response.data);
                    console.log('ForgotPasswordAction::forgotPassword::response', response);
                    onSuccess(response.data);
                    dispatch(receiveForgotPassword());
                }
            }).catch(err =>{
                onError(err.response.data);
                dispatch(errorForgotPassword());
            })
    }

}