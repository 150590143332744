import {messageQueueSetup} from "../action/MessageQueueAction";
import {withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import Home from "../Home";
import { getProfileInformationAndRoles } from "../../authentication/action/LoginAction.jsx";
import { getProjectList } from "../../project/action/ProjectAction";

const mapStateToProps = state => {
    return{
        auth : state.auth,
        appState: state,
        messageQueue: state.messaging.msgQueue
    }
};

const mapDispatchToProps = dispatch => {
    return{
        setupMsgQueue: (referenceToQueue) =>{dispatch(messageQueueSetup(referenceToQueue))},
        getSession: (onSuccess,onError)=>{
            dispatch(getProfileInformationAndRoles(onSuccess,onError))
        },
        getProjects: () => {
            dispatch(getProjectList())
        }
    }
};
export const ContainerHome =  withRouter(connect(mapStateToProps,mapDispatchToProps)(Home));