import React from 'react'
import PropTypes from "prop-types";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {Dialog} from "primereact/components/dialog/Dialog";
import {
ContainerMultiHistogramSidebar
} from "../../../visualization/containers/ContainerAvatarScatterPlotSidebar";
import {generateVisualizationToolConfiguration} from "../../../visualization/component/annotationTool/ToolConfigurationFactory";
import {ContainerManualTool} from "../../../visualization/containers/ContainerManualTool";
import {create2DArray} from "../../../helpers/arrays";
import DynamicHistogram from "./DynamicHistogram";

/**
 * Multi-panel component for dynamic histograms with avatars.
 *
 *
 */
class MultiHistogramPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedLayout:{row: 0, col: 0, className: "hover"},
      selectedCell:{row: 0, col: 0},
      plotData:create2DArray(3,3,(x, y) => []), // Added to multi
      plotSettings:create2DArray(3,3,(x, y) => {}),
      width: null,
      height: null,
      optionsSidebarVisible: false,
      showMRIDialog: false,
      showHistogramDialog: false,
      raters: null,
      opts: [{label: "None", value: "none"}],
      selected: null,   //selected Data Points in left and right
      leftButtonMode: LEFT_BUTTON_MODE.NONE,
      onDeselectFromVolume: null, //callback function that should be set dynamically by children - this one is for deselecting from Volume View
      onDeselectFromRater:null
    };
    ["setPlotSize", "onDataPointClick", "onHideVolumeDialog", "onHideHistogramDialog","onHideSidebar",
      "setPlotData"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  componentDidUpdate(prevProps, prevState,snapshot) {
    // const {plotData,plotSettings,match,slideProperties, initializeAvatarScatterPlot,refreshData}=this.props;
    // if (prevProps.plotData !== plotData) {
    //   this.state.plotData[this.state.selectedCell.row][this.state.selectedCell.col] = plotData.slice(0);
    //   this.state.plotSettings[this.state.selectedCell.row][this.state.selectedCell.col] = Object.assign({},plotSettings);
    //   this.setState({plotData:this.state.plotData, plotSettings:this.state.plotSettings});
    // }
  }

  componentWillUnmount() {
    const {clearInteractivePlotState,clearDataset,plotSettings} = this.props;
    document.body.removeEventListener("keydown", this.keyListener, true);
    window.removeEventListener("resize", this.setPlotSize);
    clearInteractivePlotState();
    clearDataset();  // used from Audit module, TODO move to independent part of Store responsible for R communication
  }

  componentDidMount() {
    const {results, resultsState, getImage, getTabletGameExperimentResults,slideProperties} = this.props;

    //get Results
    getTabletGameExperimentResults(this.props.match.params.id);

    this.setPlotSize();
    window.addEventListener("resize", this.setPlotSize);
  }


  setPlotData(filteredData,settings){

    //const {data,thresholds} = this.prepareData(filteredData,settings);
    this.state.plotData[this.state.selectedCell.row][this.state.selectedCell.col] = filteredData;

    this.state.plotSettings[this.state.selectedCell.row][this.state.selectedCell.col] = Object.assign({},settings);
    this.setState(
      {
        plotData:this.state.plotData,
        plotSettings:this.state.plotSettings
      }
      );
  }

  /**
   * Function to be passed to children components.
   *
   * @param dataPoint - data associated with selected element
   * @param onDeselect - callback function defined in children component and to be set in
   * this component state. This clbk function can be used to deselect element in data.
   *
   */
  onDataPointClick(dataPoint, onDeselect) {
    if (dataPoint.hasOwnProperty('isCollectiveResult')) {
      this.setState({
        showHistogramDialog: true,
        onDeselectFromRater: onDeselect,
        selected:dataPoint,
        raters: dataPoint['raters']
      });
    }
    else {
      this.setState({
        showMRIDialog: true,
        onDeselectFromVolume: onDeselect,
        selected:dataPoint
      });
    }
  }

  /**
   * Event handler to call when volume dialog window is closed.
   */
  onHideVolumeDialog() {
    if (this.state.onDeselectFromVolume != null)
      this.state.onDeselectFromVolume();
    this.setState({
      showMRIDialog: false,
      onDeselectFromVolume: null,
      selected:null
    })
  }

  onHideSidebar(){
    this.setState({optionsSidebarVisible: false});
  }


  /**
   * Event handler to call when vertical histogram dialog window is closed.
   */
  onHideHistogramDialog() {
    if (this.state.onDeselectFromRater != null)
      this.state.onDeselectFromRater();
    this.setState({
      showHistogramDialog: false,
      onDeselectFromRater: null
    })
  }


  /**
   * Calculate width and height for the whole container.
   **/
  setPlotSize() {
    if (this.histogramContainer != null) {
      const rect = this.histogramContainer.getBoundingClientRect();
      this.setState({height: rect.height, width: rect.width});
    }
  }




  render() {
    const {results} = this.props;
    const {width, height,selected} = this.state;

    const rows = create2DArray(this.state.selectedLayout.row+1,this.state.selectedLayout.col+1)
      .map((colArray,index)=>{
        const hheight = Math.round(this.state.height/(this.state.selectedLayout.row+1));
        const wwidth = Math.round(this.state.width/(this.state.selectedLayout.col+1));

        return (<div style={
          {
            ...flexRowContainer,
            height:"".concat(String(hheight),"px")
          }
        }>{colArray.map((c,cIndex)=>{

        return <DynamicHistogram
            yLabel={this.state.plotSettings[index][cIndex]!=null && this.state.plotSettings[index][cIndex].yLabel!=null?this.state.plotSettings[index][cIndex].yLabel:""}
            viewId={"view_"+index+"_"+cIndex}
            width={wwidth-10}
            height={hheight-10}
            data={this.state.plotData[index][cIndex]}
            prefixId={"view_"+index+"_"+cIndex}
            useAvatars={true}
            onDataPointClick={(d, onDeselect) => {
              this.onDataPointClick(d, onDeselect)
            }}
            thresholds={this.state.plotSettings[index][cIndex]!=null && this.state.plotSettings[index][cIndex].thresholds!=null?this.state.plotSettings[index][cIndex].thresholds:[10,20]}/>;
        })}
        </div>);
      });


    return (
      <div id="avatarId" className="ui-g-12 ui-g-nopad" ref={node => this.histogramContainer = node}>
        {results != null && results.length === 0 &&
        <div style={{fontSize: "20px", textAlign: "center", color: "red"}}> No available data yet. Try
          later!</div>
        }
        {/*{plotDataState === REQUEST_STATUS_REQUESTED &&*/}
        {/*<i className="fa fa-spinner fa-spin" style={{fontSize:"4em", textAlign: "center", color: "#00D2D0"}}/>*/}
        {/*}*/}

        {width != null && height != null && rows}

        <a>
          <i className="fa fa-fw fa-ellipsis-v"
              title="Configuration"
              onClick={(e) => this.setState({optionsSidebarVisible: true})}
              style={{position: "fixed", top: "70px", right: "10px",fontSize:"20px"}}/>
        </a>

        <ContainerMultiHistogramSidebar
          selectedLayout={this.state.selectedLayout}
          selectedCell={this.state.selectedCell}
          // data={results}
          onChangeLayout={(layoutCell)=>this.setState({selectedLayout:layoutCell,selectedCell:{row:0,col:0}})}
          onChangeCell={(layoutCell)=>this.setState({selectedCell:layoutCell})}
          onApply={(filteredData,settings)=>{this.setPlotData(filteredData,settings)}}
          visible={this.state.optionsSidebarVisible}
          position="right"
          onHide={() => this.onHideSidebar()}
        />
        <Dialog header='Volume'
                closeOnEscape={true}
                resizable={true}
                visible={this.state.showMRIDialog}
                modal={false}
                onHide={this.onHideVolumeDialog}
          // onShow={() => this.setState({showMRIDialog: true})}
                contentStyle={{
                  minHeight: '400px',
                  minWidth: '600px'
                }}
                style={{minHeight: '400px', minWidth: '600px'}}>
          {selected != null &&
          <ContainerManualTool
            predefinedToolConfiguration={generateVisualizationToolConfiguration(selected.originalImageId,selected.segmentationId)}
          />}
        </Dialog>
      </div>
    )
  }
}

export default MultiHistogramPanel;

MultiHistogramPanel.defaultProps = {
  plotDataState:""
};
MultiHistogramPanel.propTypes = {
  experimentId: PropTypes.object.isRequired, //from React-router
  initializeAvatarScatterPlot: PropTypes.func.isRequired,
  loadImageData: PropTypes.func.isRequired,
  clearViewersState:PropTypes.func.isRequired,
  clearDataset:PropTypes.func.isRequired,
  plotData: PropTypes.array,
  plotSettings: PropTypes.object,
  plotDataState:PropTypes.string.isRequired,
  results: PropTypes.object,
  resultsState: PropTypes.string,
  getTabletGameExperimentResults: PropTypes.func,
  clearOverlayImage: PropTypes.func,
  slideProperties: PropTypes.object, //passed from slide executor through own props
  clearInteractivePlotState:PropTypes.func,
  match:PropTypes.object.isRequired,
  refreshData:PropTypes.func.isRequired
};

const flexRowContainer = {
  display: "inline-block",
  position:"relative",
  width:"100%"
};