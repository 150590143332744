import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  getSelectedExtendedModelParameters,
  getSelectedPlotData,
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import ICCCalculator from "../component/ICCCalculator";
import {
  calculateModelForICC,
  updateExtendedModelPropertySAM,
} from "../action/statsAction";


const mapStateToProps = (state) => {
  return {
    rawData: state.sam.rawData,
    plotSettings: getSelectedPlotSettings(state),
    extendedModelParameters: getSelectedExtendedModelParameters(state),
    plotData: getSelectedPlotData(state)
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    calculateModelForICC:()=>{
      dispatch(calculateModelForICC())
    },
    updateExtendedModelPropertySAM:(property,value)=>{
      dispatch(updateExtendedModelPropertySAM(property,value))
    }
  }
};

export const ContainerICCCalculator = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(ICCCalculator));