import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HOST_URL } from "../../Constants";
import PresentationCard from "../livePresenter/component/presentationCard";
import store from "../Store";
import axios from "axios/index";
import Fuse from "fuse.js";
import SearchBar from "../livePresenter/component/livePresenterSearch";

const LivePresenter = () => {
  const [presentations, setPresentations] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [requestData, setRequestData] = useState(new Date());
  const [searchInfo, setSearchInfo] = useState([]);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
    axios
      .get(HOST_URL + "/api/presentation")
      .then((response) => {
        console.log("PresentationAction.js :: getPresentationList :: response ::", response);
        if (response.status === 200) {
          setPresentations(response.data);
          setSearchInfo(response.data);
        }
      })
      .catch((error) => {
        console.log("project err:", error);
      });
  }, [requestData]);

  const searchData = (pattern) => {
    if (!pattern) {
      setSearchInfo(presentations);
      return;
    }

    const fuse = new Fuse(searchInfo, {
      keys: ["name", "description"],
    });

    const result = fuse.search(pattern);
    const matches = [];
    if (!result.length) {
      setSearchInfo([]);
    } else {
      result.forEach(({ item }) => {
        matches.push(item);
      });
      setSearchInfo(matches);
    }
  };

  const onSubmit = (data) => {
    axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
    axios
      .post(HOST_URL + `/api/presentation`, {
        name: data.presentationName,
        description: data.presentationDescription,
        privacy: data.privacy,
        slides: [],
      })
      .then((response) => {
        console.log("PresentationAction.js :: addPresentation :: response ::", response);
        setRequestData(new Date());
        if (response.status === 200) {
          console.log("Presentation Added");
        }
      })
      .catch((error) => {
        console.log("project err:", error);
      });
    setModalShow(false);
  };

  return (
    <div className="PresentationList">
      {modalShow && (
        <>
          <div className="AddPresentationModalFade"></div>
          <div className="AddPresentationModalWrapper">
            <div className="AddPresentationModalBlock">
              <div className="AddPresentationModal">
                <div className="AddPresentationModalHeader">
                  <h4>Add Presentation</h4>
                  <button onClick={() => setModalShow(false)}>
                    <span>×</span>
                  </button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="AddPresentationModalBody">
                    <label>Presentation Name: </label>
                    <input
                      type="text"
                      className="PresentationTextBox"
                      name="presentationName"
                      ref={register({ required: true })}
                    />
                    <br />
                    <label>Presentation Description: </label>
                    <input
                      type="text"
                      className="PresentationTextBox"
                      name="presentationDescription"
                      ref={register({ required: true })}
                    />
                    <label>Privacy: </label>
                    <br />
                    <select name="privacy" ref={register({ required: true })}>
                      <option value="PRIVATE">PRIVATE</option>
                      <option value="PUBLIC">PUBLIC</option>
                    </select>
                  </div>
                  <div className="AddPresentationModalFooter">
                    <button type="submit">Add Presentation</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="PresentationDeckWrapper">
        <div className="PresentationAddSearch">
          <button className="PresentationAddBtn" onClick={() => setModalShow(true)}>
            Add Presentation +
          </button>
          <SearchBar placeholder="Search" onChange={(e) => searchData(e.target.value)} />
        </div>
        <div className="PresentationDeck">
          {searchInfo.map((items) => {
            return <PresentationCard data={items} setRequestData={setRequestData} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default LivePresenter;
