import React from "react";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {SKILL_URL_MOCK_ROCHE_FR} from "../../skills/action/actionType";

/**
 *  Live Presenter Slide component to represent summary for the skill.
 *  See @link https://docs.google.com/document/d/1YBhDgSbbWs-YExxYNgRU-ix1DXvFHOGea1ip_Hgs0eA/edit#heading=h.b62lmagqo0ss
 *
 */
class SkillSummaryLP extends React.Component {
    constructor(props){
        super(props);
    }
    componentDidMount(){
        const {initialize,livePresenterExperimentId} = this.props;
        initialize(livePresenterExperimentId);
    }

    render(){
        const {match,skillResult, history, urlFailure, urlSuccess,recreateTrainingWorkflowInLP, livePresenterExperimentId,mfKey,
            miniWorkflowSetId,goToFirstSlide, getNewCase} = this.props;

        const decisionCriterion = skillResult!=null && skillResult['result']['dice'] >=skillResult['result']['diceThreshold'] ;
        const imageToDisplay = (decisionCriterion)
            ?<img src={urlSuccess}
                  height="80%"
                  width="80%"
                  style={imageStyle}
            />
            :<img src={urlFailure}
                  height="80%"
                  width="100%"
                  style={imageStyle}
            />;
        const linkToDisplay = (decisionCriterion)
            ? <a    style={linkStyle}
                    onClick = { () => {
                       getNewCase(livePresenterExperimentId,history);
                  }}
            >
                Go to real experiments
            </a>
            :<Link to={match['url']}
                   style={linkStyle}
                   onClick = { () => {
                       recreateTrainingWorkflowInLP(livePresenterExperimentId,miniWorkflowSetId,mfKey);
                       goToFirstSlide();
                   }}
            >
              Try again
            </Link>;


        return (
            <React.Fragment >
              {imageToDisplay}
              {linkToDisplay}
            </React.Fragment>
        )
    }
}

SkillSummaryLP.propTypes = {
    initialize:PropTypes.func.isRequired,
    skillResult:PropTypes.object.isRequired,
    livePresenterExperimentId:PropTypes.string.isRequired,
    urlFailure:PropTypes.string.isRequired,
    urlSuccess:PropTypes.string.isRequired,
    recreateTrainingWorkflowInLP: PropTypes.func.isRequired, // function to recreate new training skill session (mwf)
    getNewCase: PropTypes.func.isRequired,
    mfKey:PropTypes.string.isRequired,
    miniWorkflowSetId:PropTypes.string.isRequired,
    goToFirstSlide:PropTypes.func.isRequired,
};
export default withRouter(SkillSummaryLP);


const imageStyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
};
const linkStyle = {
    display: "block",
    textAlign: "center",
    textDecoration: "underline",
    fontSize:"xx-large"
};


