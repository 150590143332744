import React, { Component } from 'react'
import {scaleLinear, scaleSequential} from "d3-scale";
import { event, select, selectAll} from "d3-selection";
import {axisBottom, axisLeft} from "d3-axis";
//import {line} from "d3-shape";
import d3Tip from "d3-tip"
import {extent, max, min,histogram, median,mean} from "d3-array";
import PropTypes from "prop-types";
import {zoom} from "d3-zoom";
import {interpolateRainbow} from "d3-scale-chromatic";
import {contextMenu} from "./contextMenu";


/** D3-based DynamicHistogram Component integrated with React
 *  Example of use:
 *  import {create_data} from "./DataHelper";
 *  import DynamicHistogram from "./d3components/DynamicHistogram";
 *  ...
 *     <DynamicHistogram
 *                       width={width}
 *                       data={dataForHistogram2}
 *                       colorCat="userId"
 *                       thresholds={thresholds}
 *                       onDataPointClick={(e)=>this.onDataPointClick(e,1)}
 *                       useAvatars={true}
 *                       selected = {this.state.selected[1]}
 *                       viewId = {plotsState[1]}
 *                       prefixId="right"
 *                   />
*
*    Parameters:
*    data - mandatory [array of ORDERED objects] containing attribute:[y] and may contains additional attribute
*           to differentiate subpopulations
*    colorCat - optional [string] - name of attribute for grouping, which MUST appear in data objects (eg. "userId")
*    width, height - optional  [number] - size of the whole image
*    onDataPointClick - optional [function] - callback to click event
*    contextMenu - optional [array] - items of context menu
*    thresholds - required  [array of numbers] defined as an array of values [x0, x1, …].
*    useAvatars - if use avatars (default true)
*    Any value less than x0 will be placed in the first bin; any value greater than or equal to x0 but less than x1
*    will be placed in the second bin; and so on.
*    TODO:
*    1. Y Axis in Left or Right Mode
*
*/
class DynamicHistogram extends Component {

    constructor(props){
        super(props);
        this.state = {
            objects:null
        };
        ["createPlot"].forEach(name => { this[name] = this[name].bind(this);});
    }
    componentDidMount() {
        if (this.props.width>0)
            this.createPlot();
    }

    componentDidUpdate(previousProps, previousState) {
        const node = this.node;
        const {objects} = this.state;
        const {prefixId,width,height,data} = this.props;

        //needed for initialization - if width was previously 0 and slide was inactive, the plot needs to be redrawn
        if ((previousProps.width!==width && width!=null  && width>0) || (previousProps.height!==height && height!=null  && height>0)){
            select(node).selectAll("*").remove();
            selectAll(".d3-tip."+prefixId+"-tip").remove(); //removes all elements only for active panel (prefixId)
            selectAll(".d3-tip."+prefixId+"-tip.n").remove(); //removes all elements only for active panel
            this.createPlot();
        }
        if (previousProps.data!==data && data!=null  && width>0){
            select(node).selectAll("*").remove();
            selectAll(".d3-tip."+prefixId+"-tip").remove(); //removes all elements only for active panel (prefixId)
            selectAll(".d3-tip."+prefixId+"-tip.n").remove(); //removes all elements only for active panel
            this.createPlot();
        }
        if (previousProps.viewId!==this.props.viewId){
            select(node).selectAll("*").remove();
            selectAll(".d3-tip."+prefixId+"-tip").remove(); //removes all elements only for active panel (prefixId)
            selectAll(".d3-tip."+prefixId+"-tip.n").remove(); //removes all elements only for active panel
            this.createPlot();
        }

            if(previousProps.length<this.props.length) {
                select(node).selectAll("*").remove();
                selectAll(".d3-tip." + prefixId + "-tip").remove(); //removes all elements only for active panel
                selectAll(".d3-tip." + prefixId + "-tip.n").remove(); //removes all elements only for active panel
                this.createPlot();
                // let changeScope = this.props.data.length- previousProps.data.length;
                //
                // let outerWidth = (this.props.width!=null)?this.props.width:500;
                // let margin = {
                //         top: 20,
                //         right: 50,
                //         bottom: 10, //required 50 for ticks and tick labels - now they are removed
                //         left: 50
                //     },
                //     width = outerWidth - margin.left - margin.right,
                //     height = outerHeight - margin.top - margin.bottom;
                // let xScale = scaleLinear()
                //     .range([0, width]);//.nice();
                // let yScale = scaleLinear()
                //     .range([height, 0]).nice();
                // const DOT_RADIUS = .5*height/(this.props.thresholds.length + 1);
                //
                // for (let i = previousProps.data.length; i<this.props.data.length;i++){
                //     console.log("<<<<<<<<<<<<<<<<<Changed data>>>>>>>>>>>>>>>>>>>>");
                //     console.log("<<<<<<<<<<<<<<<<<",this.props.data[i],">>>>>>>>>>>>>>>>>>>>");
                //     objects.insert("circle", ".cursor")
                //         .attr("class", "img")
                //         .attr("r", DOT_RADIUS)
                //         .attr("cx", xScale(this.props.data[i]['x']))
                //         .attr("cy", yScale(this.props.data[i]['yBin']));
                // }
                // }

            }
    }


    createPlot() {
        const {thresholds,prefixId, colorCat,stroke,zoomRatio,selected,data,colorLUT, opacityMode, yLabel,viewId} = this.props;
        const node = this.node;   //svg ref
        let outerWidth = (this.props.width!=null)?this.props.width:500;
        let outerHeight = (this.props.height!=null)?this.props.height:300;
        let onDataPointClick= this.props.onDataPointClick;
        let yValue = function(d) {  return d.y;  };  //Accessor, according to d3-array
        let xValue = function(d) {  return d.x;  };  //Accessor, according to d3-array

        // set the dimensions and margins of the graph
        let margin = {
                top: 20,
                right: 50,
                bottom: 10, //required 50 for ticks and tick labels - now they are removed
                left: 50
            },
            width = outerWidth - margin.left - margin.right,
            height = outerHeight - margin.top - margin.bottom;

        const DOT_RADIUS = .5*height/(thresholds.length + 1);

        // set the ranges
        let xScale = scaleLinear()
            .range([0, width]);//.nice();
        let yScale = scaleLinear()
            .range([height, 0]).nice();

      //-------------------y domain----------------------------------
      // let yDomain = extent(data, yValue);
        yScale.domain([thresholds[0]-(thresholds[1] - thresholds[0]),thresholds[thresholds.length-1]+(thresholds[thresholds.length-1] - thresholds[thresholds.length-2]) ]);

        //-------------------x domain----------------------------------

        let xDomain=[0, (data!=null)?extent(data, xValue)[1]+1:1];
        xScale.domain(xDomain);

        // set the axes
        let xAxis = axisBottom(xScale);
        let yAxis = axisLeft(yScale);
        let color = scaleSequential(interpolateRainbow);

        xAxis.tickValues([]); //removed ticks
        yAxis.tickValues(thresholds);
         // set  tips
        let tip = d3Tip()
            .attr("class", "d3-tip "+prefixId+"-tip")  //prefix added to distinguish tips on many panels - d3Tip uses global variables
            .offset([-DOT_RADIUS, 0])
            .html(function(d) {
                return "User no" + ": " + d['userId'] + "<br/>"+"Case id: " + d['caseId'] + "<br/>"  + "Value: " + d['y'].toFixed(2);
            });

        //zoom parameters and handler assignment
        let zooming = zoom()
            .scaleExtent([.5, 100])
            .translateExtent([[0, 0], [width, height]])
            .extent([[0, 0], [width, height]])
            .on("zoom", zoomEventHandler);

        let avatarClass =[];
        if (opacityMode==="normal")
            avatarClass=["normalAvatar","selectedAvatar"];
            else
            avatarClass=["selectedAvatar","normalAvatar"]
            ;

        // append the svg object to the body of the page and translate the parent group
        // calculate the rectangle embraced by axes
        let svg = select(node)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(zooming);

        svg.call(tip);

        svg.append("rect")
            .attr("width", width)
            .attr("height", height);

        svg.append("g")
            .attr("class","x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);

        // y Axis---------------------------
        svg.append("g")
            .attr("class","y axis")
            .call(yAxis)
            .append("text")
            .attr("class", "label")
            .attr("font-size", "0.7em")
            .attr("fill", "#000")
            .attr("transform", "rotate(-90)")
            .attr("y", -margin.left)
            .attr("dy", ".71em")
            .style("text-anchor", "end")
            .text(yLabel);

        let objects = svg.append("svg")
            .attr("class", "objects")
            .attr("width", width)
            .attr("height", height);
        this.setState({objects:objects});
        // Bin borders
        thresholds.forEach((t)=> {
            svg.append("line")          // attach a line
                .style("stroke", "lightgrey")  // colour the line
                .attr("x1", xScale(0))
                .attr("y1", yScale(t))      // y position of the first end of the line
                .attr("x2", xScale(xDomain[1]))     // x position of the second end of the line
                .attr("y2", yScale(t));    //
        });

        if(!(data!=null))
            return;

        // Data Points ---------------------------------
        if (!this.props.useAvatars) {
            objects.selectAll(".dot")
                .data(data)
                .enter()
                .append("circle")
                .attr("class", "dot")
                .attr("r", DOT_RADIUS-stroke)
                .style("fill", function (d) {
                    return (colorCat == null) ? "#000" : color(d[colorCat] / data.length);
                })
                .attr("cx", function (d) {
                    return xScale(d.x);
                })
                .attr("cy", function (d) {
                    return yScale(d.yBinMode);
                })
                .on("mouseover", tip.show)
                .on("mouseout", tip.hide)
                .on("click", onDataPointClick)
                .on('contextmenu', (this.props.contextMenu != null) ? contextMenu(this.props.contextMenu) : null);

        }
        else {
            let getHref = (d) => {
                return d.img;
            };
            let getId = (d) => {
                return  viewId.concat("img_",prefixId,d.userId);
            };
            let getImg = (d) => {
                return "url(#".concat(viewId,"img_",prefixId,d.userId,")");
            };

            let defs = svg.append("defs");
            let imgPattern = defs
                .selectAll("pattern")
                .data(data)
                .enter()
                .append("pattern")
                .attr("id", getId)
                .attr("width", 1)
                .attr("height", 1)
                .attr("patternUnits", "objectBoundingBox")
                .append("image")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", DOT_RADIUS*2-2*stroke)
                .attr("height", DOT_RADIUS*2-2*stroke)
                .attr("xlink:href", getHref);

            objects.selectAll("circle")
                .data(data)
                .enter()
                .append("circle")
                .attr("class", "img")
                .classed(avatarClass[0],true)
                .classed(avatarClass[1],(d)=>{if(selected!=null) return selected.userId===d.userId; return false;})
                .attr("cx",  function (d) {return xScale(d.x);})
                .attr("cy", function (d) {return yScale(d.yBinMode);})
                .attr("r", DOT_RADIUS-stroke)
                .style("fill", getImg)
                .style("stroke", function (d) {
                    if (colorLUT!=null && colorLUT.length>0)
                            return colorLUT[d[colorCat]];
                        else
                            return (colorCat == null) ? "#000" : color(d[colorCat] / data.length);
                    })
                .style("stroke-width",stroke)
                .on("mouseout", tip.hide)
                .on("mouseover", function(d) {
                    select(this).raise();
                    tip.show(d,this);
                })
                .on( 'mouseenter', function(e) {
                    select(this)
                        .transition()
                        .attr("r", DOT_RADIUS*zoomRatio-stroke);
                    defs.select("pattern#"+getId(e)).select("image")
                        .transition()
                        .attr("width", DOT_RADIUS*2*zoomRatio-2*stroke)
                        .attr("height", DOT_RADIUS*2*zoomRatio-2*stroke);
                })
                .on( 'mouseleave', function(e) {
                    select(this)
                        .transition()
                        .attr("r", DOT_RADIUS-stroke);
                    defs.select("pattern#"+getId(e)).select("image")
                        .transition()
                        .attr("width", DOT_RADIUS*2-2*stroke)
                        .attr("height", DOT_RADIUS*2-2*stroke);
                })
                .on("click", function(e) {
                    let alreadySelected=select(this).classed("selectedAvatar");
                    if(alreadySelected){
                        select(this)
                            .classed("selectedAvatar",false);
                        onDataPointClick(e);
                    } else {
                        svg.selectAll("circle.img")
                            .classed("selectedAvatar",false);
                        select(this)
                            .classed("selectedAvatar",true);
                        onDataPointClick(e);
                    }
                    tip.hide();
                })
                .on('contextmenu',  function(e) {
                    event.preventDefault();
                    alert('context menu');
                });
        }



        // select(svg.selectAll(".tick")[0][0]).attr("visibility","hidden");
        // ----------------Median and mean lines---------------------------------------
        let medianData = median(data, yValue);
        let meanData = mean(data, yValue);

        svg.append("line")          // attach a line
            .style("stroke", "red")  // colour the line
            .attr("x1", xScale(0))
            .attr("y1", yScale(medianData))      // y position of the first end of the line
            .attr("x2", xScale(xDomain[1]))     // x position of the second end of the line
            .attr("y2", yScale(medianData))    //
          .on("mouseover", function () {
              select("#"+viewId+"medianTooltip")
                .style("display",null)

                 // x position of the second end of the line
          })
          .on("mouseout", function (d) {
              select("#"+viewId+"medianTooltip")
                .style("display","none")


          });
        svg.append("text")          // attach a line
          .text("Median")
          .style("stroke", "red")  // colour the line
          .attr("id", viewId+"medianTooltip")
          .attr("x", xScale(xDomain[1]))     // x position of the second end of the line
          .attr("y", yScale(medianData)-10)
          .style("display","none")
        ;
        // svg.append('text')
        //     .attr('text-anchor', 'end')
        //     .style("fill", "red")
        //     .attr("x", xScale(xDomain[1]))
        //     .attr("y", yScale(medianData))
        //     .text("Median: "+ yScale(medianData).toFixed(2));
        // // select(svg.selectAll(".tick")[0][0]).attr("visibility","hidden");
        // // Mean line
        svg.append("line")          // attach a line
            .style("stroke", "blue")  // colour the line
            .attr("x1", xScale(0))
            .attr("y1", yScale(meanData))      // y position of the first end of the line
            .attr("x2", xScale(xDomain[1]))     // x position of the second end of the line
            .attr("y2", yScale(meanData))
          .on("mouseover", function () {
              select("#"+viewId+"meanTooltip")
                .style("display",null)

          })
          .on("mouseout", function (d) {
              select("#"+viewId+"meanTooltip")
                .style("display","none")

          });

        svg.append("text")          // attach a line
          .text("Mean")
          .style("stroke", "blue")  // colour the line
          .attr("id", viewId+"meanTooltip")
          .attr("x", xScale(xDomain[1]))     // x position of the second end of the line
          .attr("y", yScale(meanData)-10)
          .style("display","none")
        ;

        // let meanLabelPosition = (yScale(meanData)===yScale(medianData))?yScale(meanData)-10:yScale(meanData);
        //
        // svg.append('text')
        //     .attr('text-anchor', 'end')
        //     .style("fill", "blue")
        //     .attr("x", xScale(xDomain[1]))
        //     .attr("y", meanLabelPosition)
        //     .text("Mean: "+ yScale(meanData).toFixed(2));//


        function zoomEventHandler() {
            //Zoom only in x axis
            let new_xScale = event.transform.rescaleX(xScale);
            xAxis.scale(new_xScale);
            svg.select(".x.axis").call(xAxis.scale(new_xScale));
            svg.selectAll("circle.dot")
                .attr('cx',(d)=>{ return  new_xScale(d.x)});
            svg.selectAll("circle.img")
                .attr('cx',(d)=>{ return  new_xScale(d.x)});
        }
    }
    render() {
        return <svg ref={node => this.node = node} />;
    }
}
export default DynamicHistogram

DynamicHistogram.defaultProps = {
    useAvatars: true,
    stroke:4,
    zoomRatio:2,
    opacityMode:"normal",
    yLabel:"",
    onDataPointClick:()=>{},
    viewId:""
};

DynamicHistogram.propTypes = {
    contextMenu: PropTypes.array,
    data: PropTypes.array.isRequired,
    width:PropTypes.number,
    height:PropTypes.number,
    onDataPointClick:PropTypes.func.isRequired,
    colorCat:PropTypes.string,
    colorLUT:PropTypes.array, //array of user provided colors
    opacityMode:PropTypes.string,
    thresholds:PropTypes.array,
    useAvatars:PropTypes.bool,
    viewId:PropTypes.string,
    prefixId:PropTypes.string.isRequired, // required since many elements on page can have the same id
    stroke:PropTypes.number,
    zoomRatio:PropTypes.number,
    yLabel:PropTypes.string
};