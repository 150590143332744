import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {withTranslation} from 'react-i18next';
import {MultiSelect} from "primereact/components/multiselect/MultiSelect";
import {getContributorName, getMeasurementShortLabel} from "../action/SAMAction";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {RadioButton} from "primereact/components/radiobutton/RadioButton";


/**
 * Component used for setting up filter on observations.
 */

class SeriesFilterEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: [],
      selectedContributors: [],
      selectedMeasurements: [],
      selectedWorkflows: [],
      selectedROIs: [],
      compositeKey: [],
      reps:"random"
    };
    ["onAddFilter", "onRemoveContributorFilter", "onChangeKey", "renderFilter"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {

    if (this.props.filters != null)
      this.setState(
        {
          filters: this.props.filters
        }
      );
    if (this.props.compositeKey != null)
      this.setState(
        {
          compositeKey: this.props.compositeKey
        }
      );
    if (this.props.reps != null)
      this.setState(
        {
          reps: this.props.reps
        }
      )
  }

  onAddFilter() {
    const ffilters = this.state.filters.slice(0);
    let newFilter = {};
    if (this.state.selectedContributors.length > 0)
      newFilter["contributorId"] = this.state.selectedContributors;
    if (this.state.selectedMeasurements.length > 0)
      newFilter["measurementConfigurationId"] = this.state.selectedMeasurements;
    if (this.state.selectedWorkflows.length > 0)
      newFilter["workflowId"] = this.state.selectedWorkflows;
    if (this.state.selectedROIs.length > 0)
      newFilter["roiId"] = this.state.selectedROIs;
    ffilters.push(newFilter);
    this.setState({filters: ffilters})
  }

  onRemoveContributorFilter(index) {
    const ffilters = this.state.filters.slice(0);
    ffilters.splice(index, 1);
    this.setState({filters: ffilters})
  }

  onChangeKey(key) {
    const {compositeKey} = this.state;
    if (compositeKey.includes(key)) {
      this.setState({compositeKey: compositeKey.filter(e => e !== key)})
    } else {
      this.setState({compositeKey: compositeKey.concat([key])})
    }
  }

  /**
   * Render filters as chips
   * @param el
   * @param index
   * @return {*}
   */
  renderFilter(el, index) {
    const {rawData} = this.props;
    return (<div>
      <div style={{display: "inline-block", width: "3em"}}>{index !== 0 ? "OR" : ""}</div>

      {el.measurementConfigurationId != null
        ? el.measurementConfigurationId.map(con => {
          const option = rawData.measurementConfigurations.find(mel => mel.id === con);
          return getMeasurementShortLabel(option, rawData)
        }).join(" OR ")
        : ""
      }
      {el.measurementConfigurationId != null && (el.contributorId != null || el.workflowId != null || el.roiId != null) &&
      <span style={{fontWeight: "bold"}}> WITH: </span>}
      {el.contributorId != null
        ? el.contributorId.map(con => {
          return getContributorName(con, rawData)
        }).join(" OR ")
        : ""
      }
      {el.workflowId != null
        ? el.workflowId.map(con => {
          return con
        }).join(" OR ")
        : ""
      }
      {el.roiId != null
        ? el.roiId.map(con => {
          return con
        }).join(" OR ")
        : ""
      }
      <Button style={{marginLeft: "1em"}} onClick={() => this.onRemoveContributorFilter(index)} icon={"fa fa-trash"}
              title={"Remove filter rule"}/>
    </div>)
  }

  render() {
    const {rawData, updatePlotSettingsProperty, onHide, selectedContributionsFiltered} = this.props;
    const {filters, compositeKey, reps, selectedContributors, selectedMeasurements, selectedWorkflows, selectedROIs} = this.state;

    const pfilters = filters.map(this.renderFilter);

    const contributorOptions = [...new Set(selectedContributionsFiltered.map(el => el.contributorId))]
      .map(el => {
          return {value: el, label: getContributorName(el, rawData)}
        }
      );

    const measurementOptions = [...new Set(selectedContributionsFiltered.map(el => el.measurementConfigurationId))]
      .map(el => {
          const option = rawData.measurementConfigurations.find(mel => mel.id === el);
          return {value: el, label: getMeasurementShortLabel(option, rawData)}
        }
      );
    const workflowOptions = [...new Set(selectedContributionsFiltered.map(el => el.workflowId))]
      .map(el => {
          return {value: el, label: el}
        }
      );
    const roiOptions = [...new Set(selectedContributionsFiltered.map(el => el.roiId))]
      .map(el => {
          return {value: el, label: el}
        }
      );

    // check if filtering criteria can be added (disable Add button) -  measurement and at least one other prop must be selected
    const isCriteriaValid = selectedMeasurements.length === 0 || (selectedContributors.length === 0 && selectedROIs.length === 0 && selectedWorkflows);

    return (
      <div>
        <h3>Multidimensional data points</h3>
        <div>Compose data point only from contributions containing the same:
          <label htmlFor={"caseId"}> Case:</label>
          <Checkbox
            id={"caseId"}
            style={{marginRight: "1em"}}
            disabled={true}
            checked={true}
            onChange={() => {
            }}>
          </Checkbox>
          <label htmlFor={"contributorId"}> Contributor:</label>
          <Checkbox
            id={"contributorId"}
            style={{marginRight: "1em"}}
            checked={compositeKey.includes("contributorId")}
            onChange={() => this.onChangeKey("contributorId")}>
          </Checkbox>
          <label htmlFor={"workflowId"}> Workflow:</label>
          <Checkbox
            id={"workflowId"}
            style={{marginRight: "1em"}}
            checked={compositeKey.includes("workflowId")}
            onChange={() => this.onChangeKey("workflowId")}>
          </Checkbox>
          <label htmlFor={"roiId"}> ROI:</label>
          <Checkbox
            id={"roiId"}
            style={{marginRight: "1em"}}
            checked={compositeKey.includes("roiId")}
            onChange={() => this.onChangeKey("roiId")}>
          </Checkbox>
        </div>
        <div>In case of repetitions use:
          <label htmlFor={"randomId"}> First matching:</label>
          <RadioButton
            id={"randomId"}
            style={{marginRight: "1em"}}
            checked={reps==="random"}
            onChange={() => {this.setState({reps:"random"})  }}>
          </RadioButton>
          <label htmlFor={"recentId"}> Most recent:</label>
          <RadioButton
            id={"recentId"}
            style={{marginRight: "1em"}}
            checked={reps==="recent"}
            onChange={() => {this.setState({reps:"recent"})  }}>
          </RadioButton>
          <label htmlFor={"obsoleteId"}> Least recent:</label>
          <RadioButton
            id={"obsoleteId"}
            style={{marginRight: "1em"}}
            checked={reps==="obsolete"}
            onChange={() => {this.setState({reps:"obsolete"})  }}>
          </RadioButton>
        </div>
        <h3>Filter contributions</h3>
        {pfilters}

        <div style={fbStyle}>
          <h4 style={{marginTop:"0px"}}>Build new matching criteria</h4>
          <div className={"filter-row-sam"}>
            <label htmlFor="measurements" className="ui-checkbox-label">{"Measurement(-s)*"}</label>
            <MultiSelect
              id={"measurements"}
              options={measurementOptions}
              value={this.state.selectedMeasurements}
              onChange={(e) => this.setState({selectedMeasurements: e.value})}
              filter={true}
            />
          </div>
          <div className={"filter-row-sam"}>
            <label htmlFor="contributors" className="ui-checkbox-label">{"Contributors"}</label>
            <MultiSelect
              id={"contributors"}
              options={contributorOptions}
              value={this.state.selectedContributors}
              onChange={(e) => this.setState({selectedContributors: e.value})}
              filter={true}
            />
          </div>
          <div className={"filter-row-sam"}>
            <label htmlFor="workflows" className="ui-checkbox-label">{"Workflows"}</label>
            <MultiSelect
              id={"workflows"}
              options={workflowOptions}
              value={this.state.selectedWorkflows}
              onChange={(e) => this.setState({selectedWorkflows: e.value})}
              filter={true}
            />
          </div>
          <div className={"filter-row-sam"}>
            <label htmlFor="rois" className="ui-checkbox-label">{"ROIs"}</label>
            <MultiSelect
              id={"rois"}
              options={roiOptions}
              value={this.state.selectedROIs}
              onChange={(e) => this.setState({selectedROIs: e.value})}
              filter={true}
            />
          </div>
          <div>
            <Button label={"Add criteria"}
                    onClick={this.onAddFilter}
                    icon={"fa fa-plus"}
                    disabled={isCriteriaValid}
                    title={isCriteriaValid? "You have to set up measurement(-s) and at least one of the remaining properties" : "Add filtering criteria for a measurement"}

            />
            <Button label={"Clear criteria"}
                    icon={"fa fa-trash"}
                    onClick={()=>{
                      this.setState({selectedROIs:[], selectedContributors:[], selectedMeasurements:[], selectedWorkflows:[]})
                    }}
            />
          </div>
        </div>
        <Button onClick={() => {
          updatePlotSettingsProperty("filters", filters);
          updatePlotSettingsProperty("compositeKey", compositeKey);
          updatePlotSettingsProperty("reps", reps);
          onHide();
        }} icon={"fa fa-check"} label={"Apply"}/>
        <Button onClick={() => {
          onHide();
        }} icon={"fa fa-close"} label={"Cancel"}/>
      </div>
    )
  }
}

export default withTranslation()(SeriesFilterEditor);

SeriesFilterEditor.propTypes = {
  rawData: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  compositeKey: PropTypes.array.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedContributionsFiltered: PropTypes.array.isRequired,
  t: PropTypes.func
};


const fbStyle = {
  border: "solid 1px #2de2de",
  borderRadius: "1em",
  padding: "10px",
  marginBottom: "2em",
  marginTop: "2em"
};