import React from 'react'
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import axios from "axios/index";
import {HOST_URL, USER} from "../../../Constants";
import { Button } from 'primereact/components/button/Button';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Dialog } from "primereact/components/dialog/Dialog";
import { PickList } from 'primereact/components/picklist/PickList';



class Users extends React.Component {

    constructor(props){
        super(props);
    
        // Set the state
        this.state = {
            loading: true,
            error: false,
            users: null,
            totalUsers: 0,
            source: [],
            target: [],
            rows: 15,
            offset:0,
            currentUser: null,
            showUserRolesDialog: false,
            showUserDeleteDialog: false
        };

        this.onLazyLoad = this.onLazyLoad.bind(this);
        this.onRolesPickerChange = this.onRolesPickerChange.bind(this);

        this.getUsers = this.getUsers.bind(this);
        this.updateUserRoles = this.updateUserRoles.bind(this);
        this.deleteUser = this.deleteUser.bind(this);

        this.onUserDelete = this.onUserDelete.bind(this);
        this.onUserUpdateRoles = this.onUserUpdateRoles.bind(this);
        this.closeUserRolesDialog = this.closeUserRolesDialog.bind(this);
        this.closeUserDeleteDialog = this.closeUserDeleteDialog.bind(this);

      }

      onLazyLoad(event){
        this.setState({
            loading: false,
            offset: event.first||0
        });
        this.getUsers(event.first||0);
      }

      getUsers(offset){
        // Get list of users following paging
        const {auth} = this.props;
        const {rows} = this.state;
        axios.defaults.headers.common['Authorization'] = auth.token_bearer;
        axios.get(`${HOST_URL}/api/user?limit=${rows}&offset=${offset}`)
            .then(response => {
                if (response.status !== 200) {
                    console.log('Error getting users information');
                    this.showError('Error getting users information');
                    this.setState({
                        totalUsers: 0,
                        users: [],
                        loading: false,
                        error: true
                    });
                } else {
                    const {count, rows: users} = response.data;
                    this.setState({
                        totalUsers: count,
                        users,
                        currentUser: null,
                        source: [],
                        target: [],
                        loading: false,
                        error: false,
                        showUserRolesDialog: false,
                        showUserDeleteDialog: false
                    });
                }
            }).catch(error => {
            console.log('Users.js :: getUsers :: error', error);
            this.setState({
                totalUsers: 0,
                users: [],
                source: [],
                target: [],
                loading: false,
                error: true,
                showUserRolesDialog: false,
                showUserDeleteDialog: false
            });
        });
      }

      updateUserRoles(){
        // Call endpoint to update user roles and update component state 
        this.setState({
            loading: true
        });
        const {currentUser, target: roles, offset} = this.state;
        const {auth} = this.props;
        const {id} = currentUser;
        axios.defaults.headers.common['Authorization'] = auth.token_bearer;
        axios.put(`${HOST_URL}/api/user/${id}/roles`, {roles})
            .then(response => {
                if (response.status !== 200) {
                    console.log('Error updating user roles');
                    this.showError('Error updating user roles');
                    this.setState({
                        loading: false,
                        error: true
                    });
                } else {
                    this.getUsers(offset);
                }
            }).catch(error => {
            console.log('Users.js :: updateUserRoles :: error', error);
            this.setState({
                loading: false,
                error: true
            });
        });
      }

      deleteUser(){
        // Call endpoint and update component state
        this.setState({
            loading: true
        });
        const {auth} = this.props;
        const {id} = this.state.currentUser;
        axios.defaults.headers.common['Authorization'] = auth.token_bearer;
        axios.delete(`${HOST_URL}/api/user/${id}`)
            .then(response => {
                if (response.status !== 200) {
                    console.log('Error deleteing user');
                    this.showError('Error deleteing user');
                    this.setState({
                        loading: false,
                        error: true
                    });
                } else {
                    this.getUsers(this.state.offset);
                }
            }).catch(error => {
            console.log('Users.js :: deleteUser :: error', error);
            this.setState({
                loading: false,
                error: true
            });
        });
      }

      onRolesPickerChange(event){
		this.setState({
			source: event.source,
			target: event.target
		});
      }

      onUserUpdateRoles(userData){
        const roles = userData.roles.map(role => role.name);
        const source = Object.values(USER.ROLES).filter(role=>!roles.includes(role));
        const target = roles;
        this.setState({
            currentUser: userData,
            source,
            target,
            showUserRolesDialog: true
        });
      }

      onUserDelete(userData){
        this.setState({
            currentUser: userData,
            showUserDeleteDialog: true
        });
      }

      closeUserRolesDialog(){
          this.setState({
              currentUser: null,
              showUserRolesDialog: false
        });
      }

      closeUserDeleteDialog(){
        this.setState({
            currentUser: null,
            showUserDeleteDialog: false
      });
      }

    render() {
        const {users, totalUsers, loading, rows} = this.state;
        return (
            <div className="ui-g-12 ui-g-nopad" style={{height: '100%'}} >
                <div className="ui-g-12" style={{height: '100%'}}>
                    <h2 style={{fontWeight: 'normal'}}>Users</h2>
                    <DataTable
                        ref={(el) => this.datatable = el}
                        lazy={true}
                        paginator={true}
                        value={users}
                        totalRecords={totalUsers}
                        rows={rows}
                        onLazyLoad={this.onLazyLoad}
                        loading={loading}
                        scrollable={true}
                        scrollHeight="90%"
                        style={{overflowWrap:'break-word'}}
                    >
                        <Column header="Name" field="name"/>
                        <Column header="Lastname" field="lastname"/>
                        <Column header="email" field="email"/>
                        <Column header="Active" field="active"
                                body={(rowData)=>{
                                    return <div>
                                            <label>{String(rowData.active)}</label>
                                        </div>;
                                    }
                        }/>
                        <Column header="Roles" field="roles"
                                style={{textAlign: 'center'}}
                                body={(rowData)=>{
                                        return rowData.roles.map(
                                            role => {
                                                const label = <div>
                                                        <label>{role.name}</label>
                                                    </div>;
                                                return label;
                                            }
                                        );
                        }}/>
                        
                        <Column header="Actions"
                                style={{textAlign: 'center'}}
                                body={(rowData)=>{
                                        return (
                                            <div>
                                                <Button
                                                    label="Update roles"
                                                    onClick={()=>{this.onUserUpdateRoles(rowData)}}
                                                />
                                                <Button
                                                    label="Delete"
                                                    onClick={()=>{this.onUserDelete(rowData)}}
                                                />       
                                            </div>
                                        );
                        }}/>
                    </DataTable>
                 </div>
                <Dialog header="User roles"
                        closeOnEscape={true}
                        resizable={true}
                        visible={this.state.showUserRolesDialog}
                        modal={false}
                        onHide={this.closeUserRolesDialog}
                        style={{overflowWrap:"break-word", textAlign:"center"}}>
                        <PickList
                            source={this.state.source}
                            target={this.state.target}
                            sourceHeader='Available'
                            targetHeader='Selected'
                            responsive={true}
                            onChange={this.onRolesPickerChange}
                        />
                        <Button
                            label="Update roles"
                            onClick={()=>{this.updateUserRoles()}}
                        />
                        <Button
                            label="Cancel"
                            onClick={()=>{this.closeUserRolesDialog()}}
                        />
                </Dialog>
                <Dialog header="User delete"
                        closeOnEscape={true}
                        resizable={true}
                        visible={this.state.showUserDeleteDialog}
                        modal={false}
                        onHide={this.closeUserDeleteDialog}
                        style={{overflowWrap:"break-word", textAlign:"center"}}>
                        <p>
                            Are you sure you want to delete the user?
                        </p>
                        <Button
                            label="Delete user"
                            onClick={()=>{this.deleteUser()}}
                        />
                        <Button
                            label="Cancel"
                            onClick={()=>{this.closeUserDeleteDialog()}}
                        />
                </Dialog>
         </div>
        );
    }
};

export default withTranslation()(Users);
Users.propTypes = {
    auth: PropTypes.object.isRequired
};