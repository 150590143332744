import React from 'react'
import {Sidebar} from 'primereact/components/sidebar/Sidebar'
import {Button} from 'primereact/components/button/Button'
import WorkflowPanelMenu from "./WorkflowPanelMenu";
import PropTypes from "prop-types";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {ItemList} from "../../project/component/ItemList";
import WorkflowItemCardElement from "./WorkflowItemCardElement";
import WorkflowItemListElement from "./WorkflowItemListElement";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Panel} from "primereact/components/panel/Panel";
const WorkflowVisualizerDemoPromise = import ("../../workflowManager/component/WorkflowVisualizerDemo");
const WorkflowVisualizerDemo = React.lazy(()=>WorkflowVisualizerDemoPromise);


/**
 *
 */
export default class WorkflowDialog extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            isList:false,
            isWorkflowItemVisible:false,
            viewedWorkflow:{},
            workflowVisualizationMode:"overview",
            selectedTask:null
        };
        this.onDialogHide=this.onDialogHide.bind(this);
        this.onDialogShow=this.onDialogShow.bind(this);
    }

    onDialogShow(e){
        this.props.getTaskList(e.uuid);
        this.setState(
            {isWorkflowItemVisible:true,
                viewedWorkflow:e});


    }
    onDialogHide(){
        this.setState({isWorkflowItemVisible:false});
    }

    render() {
        const { workflows, visible, hideMe,setWorkflow,experiment,variableIndex,variable } = this.props;
        return (
            <Sidebar visible={visible} fullScreen={true} baseZIndex={1} onHide={hideMe}  >
                <WorkflowPanelMenu variable={variable} isList={this.state.isList} listModeClbk={(val)=>this.setState({isList:val})}/>
                {workflows.workflowListState ===REQUEST_STATUS_SUCCESS && workflows.workflows.length>0 &&
                <ItemList model={workflows.workflows}
                          isList={this.state.isList}
                          classNames={["ui-g-4","ui-g-4"]}
                          headers={["Name","Description"]}
                          properties={["name","description"]}
                          cardRenderer={<WorkflowItemCardElement variableIndex={variableIndex} experiment={experiment} variable={workflows.workflowVariable} dialogShowClbk={this.onDialogShow} dialogHideClbk={this.onDialogHide} selectClbk={setWorkflow}/>}
                          listRenderer={<WorkflowItemListElement variableIndex={variableIndex} experiment={experiment} variable={workflows.workflowVariable} dialogShowClbk={this.onDialogShow} dialogHideClbk={this.onDialogHide} selectClbk={setWorkflow}/>}
                          columnStyle="ui-g-4  ui-sm-12"
                />
                }
                <Button type="button" onClick={hideMe} label="Close" className="ui-button-success"/>
                <Dialog
                    fullScreen={true}
                    baseZIndex={1000000}
                    // position="right"
                    header={"Workflow view/edit"}
                    visible={this.state.isWorkflowItemVisible}
                    modal={false}
                    onHide={this.onDialogHide}
                    // style={{width:'80vw',height:'640px'}}
                >
                    <Sidebar
                         position="left"
                         header={"Properties"}
                         visible={this.state.isWorkflowItemVisible}
                         modal={true}
                         onHide={()=>{}}
                    >
                        <Panel header="Workflow">
                        <Dropdown
                            options={[{label:"Overview",value:"overview"},{label:"Detailed",value:"detailed"}]}
                            value={this.state.workflowVisualizationMode}
                            onChange={(e)=>this.setState({workflowVisualizationMode:e.value})}
                        />
                        </Panel>

                        {this.state.selectedTask!=null &&
                            <Panel header={"Task: ".concat(this.state.selectedTask.getToolName())}>

                            </Panel>
                        }
                    </Sidebar>
                    {this.state.isWorkflowItemVisible && this.state.viewedWorkflow!=null&&
                    <React.Suspense fallback={<p>Loading</p>}>
                        <WorkflowVisualizerDemo workflowSchema={null} handleClickDiamondNode={ (node) => {
                            this.setState({selectedTask:node});
                        }}/>
                    </React.Suspense>}
                </Dialog>
            </Sidebar>

        )
    }

}

WorkflowDialog.propTypes = {
    workflows: PropTypes.object.isRequired, // full branch from Redux
    visible: PropTypes.bool.isRequired,
    hideMe: PropTypes.func.isRequired
    };

