import React from 'react'
import { connect } from 'react-redux'
import EventPage from "../component/EventPage";
import {recreateTrainingWorkflowInLP} from "../../dashboard/action/ManualTaskAction";
import {updateLayerRenderingEventForm} from "../../arcgis/action/GeoAction";

const mapStateToProps = state => {
  return{
    auth : state.auth,
    messageQueue: state.messaging.msgQueue,
    layers: state.geo.layers
  }
};

const mapDispatchToProps = dispatch => ({
    recreateTrainingWorkflowInLP : (experimentId,miniWorkflowSet,miniWorkflowKey,eventId) => {
        dispatch(recreateTrainingWorkflowInLP(experimentId,miniWorkflowSet,miniWorkflowKey,eventId))},
    updateLayerRenderingEventForm:(regionsAsString)=>{
        dispatch(updateLayerRenderingEventForm(regionsAsString))
    }
});

export const ContainerEventPage = connect(
  mapStateToProps,
  mapDispatchToProps)(EventPage);
