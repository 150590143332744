import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {VisualizationToolPanel} from "../component/VisualizationToolPanel";
import {createManualToolConfiguration, updateManualToolSAM} from "../action/toolAction";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    selectedDataPoint: state.sam.selectedDataPoint,
    manualToolConfiguration:state.sam.manualToolConfiguration,
    roi: state.visu.roi,
    visualizationToolDialogVisible: state.sam.visualizationToolDialogVisible,
    onHide:ownProps.onHide, // could not be preserved in Store - functions are not deserialized (deselect is required)
    manualToolType:ownProps.manualToolType
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    },
    createManualToolConfiguration:(mType)=>{
      dispatch(createManualToolConfiguration(mType))
    },
    updateManualToolSAM:(tool)=>{
      dispatch(updateManualToolSAM())
    }
  }
};

export const ContainerVisualizationToolPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(VisualizationToolPanel));