import {createSelector} from 'reselect';


export const getSelectedCell = (state) => state.sam.selectedCell;
export const getSelectedLayout = (state) => state.sam.selectedLayout;
export const getPlotSettings = (state) => state.sam.plotSettings;
export const getModelParameters = (state) => state.sam.modelParameters;
export const getExtendedModelParameters = (state) => state.sam.extendedModelParameters;
export const getPlotData = (state) => state.sam.plotData;
export const getRawData = (state) => state.sam.rawData;

/**
 * Returns flag if many  measurements can be selected in a given model (or just one).
 * If mode===0 (Bland-Altman) then false, else true (distribution=1, custom=2, regression=3).
 * @param state - Redux state
 * @return {boolean}
 */
export const isMultipleMeasurementMode = (state)=>{
  return getSelectedPlotSettings(state).mode !== 0;
};

/**
 * Function to calculate index from 2D layout of diagrams into 1D array of settings.
 * Flattening is applied to keep Reducer as flat as possible.
 * @param cell
 * @param layout
 * @return {number}
 */
export const calculateIndex = (cell,layout)=>{
  return cell.row * (layout.col +1) + cell.col;
};

export const getSelectedPlotIndex = createSelector(
  [ getSelectedCell, getSelectedLayout],
  (cell, layout) => {
    return calculateIndex(cell,layout);
  }
);

export const getSelectedPlotSettings = createSelector(
  [ getSelectedCell, getSelectedLayout,getPlotSettings],
  (cell, layout,plot) => {
    return plot[calculateIndex(cell,layout)];
  }
);


export const getSelectedModelParameters = createSelector(
  [ getSelectedCell, getSelectedLayout,getModelParameters],
  (cell, layout,mp) => {
    return mp[calculateIndex(cell,layout)];
  }
);

export const getSelectedExtendedModelParameters = createSelector(
  [ getSelectedCell, getSelectedLayout,getExtendedModelParameters],
  (cell, layout,emp) => {
    return emp[calculateIndex(cell,layout)];
  }
);


export const getSelectedPlotData = createSelector(
  [ getSelectedCell, getSelectedLayout,getPlotData],
  (cell, layout,pd) => {
    return pd[calculateIndex(cell,layout)];
  }
);




