import React from "react";
import PropTypes from "prop-types";
import {InputText} from "primereact/components/inputtext/InputText";


export const  ImageForm =(props)=> {
    return <div className="ui-grid ui-fluid">
        <div className="ui-g-4" style={{padding: ".75em"}}>
            <label htmlFor="name">Name</label></div>
        <div className="ui-g-8" style={{padding: ".5em"}}>
            <InputText id="name" onChange={props.onChange} value={props.img.name}/>
        </div>

        <div className="ui-g-4" style={{padding: ".75em"}}>
            <label htmlFor="link">Link</label></div>
        <div className="ui-g-8" style={{padding: ".5em"}}>
            <InputText id="link" onChange={props.onChange1} value={props.img.src}/>
        </div>
    </div>;
};

ImageForm.propTypes = {
    onChange: PropTypes.func,
    img: PropTypes.any,
    onChange1: PropTypes.func
};