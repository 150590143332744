import React from 'react';
import PropTypes from "prop-types";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {TaskTrayItem} from "./TaskTrayItem";
import {ordinalColors, WAHIDAH} from "../../helpers/colors";

/**
 * Component to represent workflow tray in Palette.
 * Panel only for workflows.
 */
class TaskPanelContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter:""
    };
  }

  componentDidMount() {
    this.props.getTasks();
    this.props.getTools();
  }

  render() {
    const {tasks,taskListState,toolListState,tools,lockDiagramModel} =this.props;
    const {filter} =this.state;
    const assignTool =(task)=>{
      let result=null;
     if (task.hasOwnProperty('process')
       // && task.process.type==="manual"
       && task.process.execution!=null
       // && task.process.execution.executor ==="SPINE"
       &&  task.process.execution.descriptorId!=null)
       result = tools.find((el)=>{return el._id=== task.process.execution.descriptorId});

     if (!(result!=null))
       console.log("Warning: Not found tool for task! ",task);
     return result;

    };

    const filtered =(tasks!=null && taskListState===REQUEST_STATUS_SUCCESS && toolListState===REQUEST_STATUS_SUCCESS)
      ? tasks.filter(el=>{return el['name'].toLowerCase().includes(filter.toLowerCase())})
        .map((el,index)=><TaskTrayItem taskData={el} toolData={assignTool(el)} color={ordinalColors(WAHIDAH)[0]}/>)
      :<React.Fragment/> ;

    return (
      <React.Fragment>
        <InputText
          onChange={(e)=>this.setState({filter:e.target.value})}
          value={filter}
          onFocus={(e)=>{
            lockDiagramModel(true);
          }}
          onBlur={(e)=>{
            lockDiagramModel(false);
          }}/>
        <div className={"workflow-editor-panel-tray-content"} draggable={false}>
        {filtered}
        </div>
      </React.Fragment>
    );
  }
};



export default TaskPanelContent;

TaskPanelContent.propTypes= {
  tasks:PropTypes.array.isRequired,
  taskListState: PropTypes.string.isRequired,
  tools:PropTypes.array.isRequired,
  toolListState: PropTypes.string.isRequired,
  getTasks: PropTypes.func.isRequired,
  getTools: PropTypes.func.isRequired,
  lockDiagramModel:PropTypes.func.isRequired
};