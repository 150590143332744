import {connect} from "react-redux";
import DirectedGraphControlPanel from "../component/DirectedGraphControlPanel";
import {updateManualToolProperty} from "../action/ManualToolAction";
import {getViewerAssignedToGraphTool} from "../selectors/ViewersSelectors";
import {getImageAssignedToGraphTool} from "../selectors/ImageSelectors";
import {changeScene, updateViewerProperty} from "../action/ViewerAction";


const mapStateToProps = state => {
  return {
    manualToolState: state.visu.manualTool.manualToolState,
    viewer: getViewerAssignedToGraphTool(state),
    image: getImageAssignedToGraphTool(state)
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateGraphToolProperty: (property, value) => {
      dispatch(updateManualToolProperty("graphTool", property, value))
    },
    updateViewerProperty:(index,property,value)=>{
      dispatch(updateViewerProperty(index,property,value))
    },
    changeScene:(id,value)=>{
      dispatch(changeScene(id,value))
    }
  }
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);


export const ContainerDirectedGraphControlPanel = containerCreator(DirectedGraphControlPanel);