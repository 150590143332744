import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperties,
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import RegressionModelPanelContent from "../component/RegressionModelPanelContent";
import {updateRegressionPlotData} from "../action/RegressionPlotAction";




const mapStateToProps = (state) => {
  return {
    axisXIndex: getSelectedPlotSettings(state).axisXIndex,
    axisYIndex: getSelectedPlotSettings(state).axisYIndex,
    family: getSelectedPlotSettings(state).family,
    formula: getSelectedPlotSettings(state).formula,
    confint: getSelectedPlotSettings(state).confint,
    showCI:getSelectedPlotSettings(state).showCI,
    showPI:getSelectedPlotSettings(state).showPI,
    measurementIndexToSymbolMapping:getSelectedPlotSettings(state).measurementIndexToSymbolMapping,
    interactions:getSelectedPlotSettings(state).interactions,
    rawData: state.sam.rawData,
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration,
    messageQueue: state.messaging.msgQueue,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    },
    updateRegressionPlotData:(p,index)=>{
      dispatch(updateRegressionPlotData(p,index))
    },
    updatePlotSettingsProperties:(o)=>{
      dispatch(updatePlotSettingsProperties(o))
    }
  }
};

export const ContainerRegressionModelPanelContent = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(RegressionModelPanelContent));