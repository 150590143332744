import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import WorkflowVisualizerDemo from "../../workflowManager/component/WorkflowVisualizerDemo";
import {Dialog} from "primereact/components/dialog/Dialog";
import {
  filterContributors,
  filterMeasurementByString,
  getMeasurementById,
  getMeasurementShortLabel
} from "../action/SAMAction";

/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class SeriesContributionsPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      wfDialogVisible: false,
      selectedWf:null
    };
    ["contributorBody","workflowBody","roiBody","measurementBody"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }


  contributorBody (row, column) {
    const {rawData} = this.props;
    const contributor = rawData.contributors.find(el=>el.uuid===row.contributorId);
    const label = contributor!=null
      ? contributor.label!=null
        ? contributor.label
        : contributor.name !=null
          ? contributor.name
          : "NA"
      :"NA";
    return <div >{label}</div>;
  };

  measurementBody(row,column){
    const {rawData} = this.props;
    const measurement = getMeasurementById(row.measurementConfigurationId,rawData);
    if (measurement!=null)
      return getMeasurementShortLabel(measurement,rawData);
    return "NA";
  }

  workflowBody (row) {

    return <div onClick={()=>this.setState({wfDialogVisible:true,selectedWf:row.workflowId})} >{row.workflowId}</div>;
  };

  roiBody(row){
    if (Array.isArray(row.roiId)){
      return row.roiId.map((el,i)=><a title={el} style={{marginRight:".5em"}}>{(i+1)}</a>);
    }
    else
      return <a title={row.roiId}>{1}</a>;
  }

  filterRoiType(value,filter){
    const {rawData} = this.props;
    if (filter === undefined || filter === null || (typeof filter !== 'string')) {
      return true;
    }

    let result = false;
    if (value != null ) {
      const roi = rawData["ROIs"].find(el=>el._id===value);
      if (roi!=null){
        return roi.typeROI!=null && roi.typeROI.toLowerCase().includes(filter.toLowerCase());
      }
    }
    return result;
  }

  render() {
    const {t, contributions,rawData} = this.props;

    return (
      <React.Fragment>
        <DataTable
          value={contributions} // concat and remove dups
          emptyMessage={"No properties found"}
          paginator={true}
          rows={10}
        >
          <Column field="contributorId" header="Contributor" body={this.contributorBody} filter={true} filterFunction={(v,f)=>filterContributors(v,f,rawData)} filterMatchMode={"custom"}/>
          <Column field="measurementConfigurationId" header="Measurement" body={this.measurementBody} style={{width:"20em"}} filter={true} filterFunction={(v,f)=>filterMeasurementByString(v,f,rawData)}  filterMatchMode={"custom"}/>
          <Column field="value" header="Value" sortable={true} filter={true} filterMatchMode={"contains"}/>
          <Column field="units" header="Unit" sortable={true} filter={true} filterMatchMode={"contains"}/>
          <Column field="caseId" header="Case id" sortable={true} filter={true} filterMatchMode={"contains"}/>
          <Column id="roi" field="roiId" header="ROIs" sortable={true} filter={true} filterMatchMode={"contains"} body={this.roiBody} />
          {/*<Column id="roiType" field="roiId" header="ROI type" body={this.roiBody} />*/}
          <Column field="workflowId" header="Workflow id" body={this.workflowBody} filter={true} filterMatchMode={"contains"}/>
          <Column field="taskExecutorId" header="Task id" sortable={true} filter={true} filterMatchMode={"contains"}/>
          <Column field="contributionDate" header="Date" sortable={true} filter={true} filterMatchMode={"contains"}/>
        </DataTable>
        <Dialog onHide={()=>this.setState({wfDialogVisible:false})}
                visible={this.state.wfDialogVisible}
                width={800}
                height={600}
                header={"Workflow visualization"}
        >
          {
            this.state.wfDialogVisible &&
            <WorkflowVisualizerDemo wSchema={null}/>
          }
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withTranslation()(SeriesContributionsPanel);

SeriesContributionsPanel.propTypes = {
  rawData: PropTypes.object.isRequired,
  contributions:PropTypes.array.isRequired,
  updateSelectedDataPoint: PropTypes.func.isRequired,
  t: PropTypes.func
};
