import React from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import {AutoComplete} from "primereact/components/autocomplete/AutoComplete";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";



/**
 * Component responsible for providing Ontology List from NCBO Bioportal.
 *
 */
class OntologyAutoComplete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ontologiesNCBO:[],
      filteredNodes:null,
      requestStatus:""
    };
    ["searchNode"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {onError,onOntologyListLoaded} = this.props;
    this.setState({requestStatus:REQUEST_STATUS_REQUESTED});
    axios.get("/api/ncbo/ontologies"  ).then(response => {
      if (onOntologyListLoaded!=null)
        onOntologyListLoaded(response);
      this.setState(        {ontologiesNCBO:response.data.map(el=>{return {acronym:el.acronym,name:el.name, id:el['@id']}})});
      this.setState({requestStatus:REQUEST_STATUS_SUCCESS});
    }).catch(err => {
      onError(err);
      this.setState({requestStatus:REQUEST_STATUS_FAIL});
    });
  }

  searchNode(event) {

    const all = this.state.ontologiesNCBO;
    setTimeout(() => {
      let filteredNodes;
      if (!event.query.trim().length) {
        filteredNodes = all;
      }
      else {
        filteredNodes = all.filter((node) => {
          return node.name.toLowerCase().startsWith(event.query.toLowerCase())|| node.acronym.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      this.setState({ filteredNodes });
    }, 20);
  }

  render() {
    const{value,onValueChange}=this.props;
    const {requestStatus} = this.state;


    return (
          <AutoComplete value={requestStatus===REQUEST_STATUS_REQUESTED?"Loading NCBO data":value}
                        inputClassName={requestStatus===REQUEST_STATUS_REQUESTED?"blinkingText":""}
                        inputStyle={{width:"18em"}}
                        suggestions={this.state.filteredNodes}
                        completeMethod={this.searchNode}
                        dropdown={true}
                        field="name"
                        onChange={(e) => onValueChange(e.value )}
          />
    )
  }
}

export default OntologyAutoComplete;

OntologyAutoComplete.propTypes = {
  value:PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onError:PropTypes.func.isRequired,
  onOntologyListLoaded:PropTypes.func
};

