import React from "react";
import {CheckboxGroup} from "../../root/CheckboxGroup";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import PropTypes from "prop-types";
import {ItemList} from "./ItemList";
import ExperimentItemCardElement from "./ExperimentItemCardElement";
import ExperimentItemListElement from "./ExperimentItemListElement";
import {
    EXPERIMENT_PROPERTY_NAME__QUESTION,
    EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED, EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT,
    EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW
} from "../../../Constants";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import Editor from "workflow-new/dist/editor.bundle";
import { TaskPropertiesPanel } from "../../workflowManager/component/TaskPropertiesPanel";
import AnalysisChoiceView from "./AnalysisChoiceView";



export class ProjectDetailsExperimentsTab extends React.Component {

    constructor(props){
        super(props);
        this.state={
            rmDialogVisible: false,
            workflowProgressDialogVisible: false,
            taskProgressDialogVisible: false,
            analysisDialogVisible: false,
            analysedExperiment:null,
            selected:{},
            nodeSettings: null
        };
        ["onWorkflowProgressDialogHide","onWorkflowProgressDialogShow",
         "onTaskProgressDialogHide","onTaskProgressDialogShow",
         "onRmDialogHide","onRmDialogShow",
         "onDelete"]
         .forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    /**
     *
     */
    onDelete(){
        this.props.onDeleteExperiment(this.state.selected);
        this.onRmDialogHide();
    }

    onRmDialogHide(){
        this.setState({
            rmDialogVisible:false,
            selected:{}
        });
    }
    onRmDialogShow(item){
        this.setState({
            rmDialogVisible:true,
            selected:item
        });
    }

    // Handlers for Editor (workflowEditor) progress dialog
    onWorkflowProgressDialogHide(){
        this.setState({
            workflowProgressDialogVisible:false,
            taskProgressDialogVisible:false,
            selected:{},
            nodeSettings:null
        });
    }
    onWorkflowProgressDialogShow(item){
        this.setState({
            workflowProgressDialogVisible:true,
            selected:item
        });
    }

    // Handlers for task progress dialog
    onTaskProgressDialogHide(){
        this.setState({
            taskProgressDialogVisible:false,
            selected:{}
        });
    }
    onTaskProgressDialogShow(node,onUpdate){
        this.setState({
            taskProgressDialogVisible:true,
            nodeSettings:node
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(!prevState.workflowProgressDialogVisible){
            // Timeout needed since seems like the Editor functions are asynchronous but no promise is being returned to trigger a callback
            setTimeout(()=>{
                this.distribute();
                setTimeout(()=>{
                    this.retrieveEngine().getModel().setLocked(true);
                }, 500);
            }, 500);
        }
    }

    render() {
        // TODO: The input workflow should be populated from the props
        const defaultInputWorkflow = {
            "workflow": {
                "_id": "008f2e22-1d27-4f85-bb52-f2cca96df52d",
                "_rev": "1-49ceb512f685d5f2f8eb6b60f53ec80f",
                "version": "0.4",
                "name": "Miklo's Medulla Oblongata Volume Measurement Workflow",
                "description": "Worfklow to measure the volume of the medulla oblongata",
                "privacy": "PUBLIC",
                "constants": {
                    "label": {
                        "name": "Label",
                        "description": "Label corresponding to the medulla oblongata",
                        "type": "Number",
                        "value": 1,
                        "isList": false
                    },
                    "inputShiftingNumber_key": {
                        "name": "Number of shifing slices",
                        "description": "Number of slice shifing in axial direction for the upper slice",
                        "type": "Number",
                        "value": 5,
                        "isList": false
                    },
                    "inputShiftingDirection_key": {
                        "name": "Direction of shifting slices.",
                        "description": "Direction of shifting slices. ",
                        "type": "String",
                        "value": "AXIAL_CRANIAL",
                        "isList": false
                    }
                },
                "inputs": {
                    "image": {
                        "name": "Input image",
                        "description": "Input image",
                        "isList": false,
                        "type": "imageEntityInOut",
                        "imageEntityInOut_Type": "ANATOMICAL",
                        "imageEntityInOut_FileFormat": "dicom.gz",
                        "required": true
                    },
                    "icc-volume": {
                        "name": "Intra cranial volume",
                        "description": "Intra cranial volume that will be used for normalization",
                        "type": "Number",
                        "isList": false
                    }
                },
                "outputs": {
                    "medulla-oblongata-volume": {
                        "name": "Medulla Oblongata Volume",
                        "description": "Volume of the medulla oblongata",
                        "type": "annotationInOut",
                        "isList": false,
                        "annotation": {
                            "typeAnnotationForm": "OPEN_ENDED",
                            "typeAnnotation": "NUMBER",
                            "properties": {}
                        }
                    },
                    "medulla-oblongata-volume-normalized": {
                        "name": "Medulla Oblongata Volume Normalized by ICC volume",
                        "description": "Volume of the medulla oblongata normalized by ICC",
                        "type": "annotationInOut",
                        "isList": false,
                        "annotation": {
                            "typeAnnotationForm": "OPEN_ENDED",
                            "typeAnnotation": "NUMBER",
                            "properties": {}
                        }
                    },
                    "mean-medulla-oblongata-volume-normalized": {
                        "name": "Mean Medulla Oblongata Volume Normalized by ICC volume",
                        "description": "Volume of the medulla oblongata divded by the number of slice normalized by ICC",
                        "type": "annotationInOut",
                        "isList": false,
                        "annotation": {
                            "typeAnnotationForm": "OPEN_ENDED",
                            "typeAnnotation": "NUMBER",
                            "properties": {}
                        }
                    },
                    "mean-medulla-oblongata-volume": {
                        "name": "Mean Medulla Oblongata Volume",
                        "description": "Volume of the medulla oblongata divded by the number of slices",
                        "type": "annotationInOut",
                        "isList": false,
                        "annotation": {
                            "typeAnnotationForm": "OPEN_ENDED",
                            "typeAnnotation": "NUMBER",
                            "properties": {}
                        }
                    }
                },
                "tasks": {
                    "lowerbound": {
                        "name": "Lower bound",
                        "name_fr": "Limite inférieure",
                        "description": "The user selects the lower bound",
                        "taskId": "637274f6-1b9a-4f47-be28-33dee7db423a"
                    },
                    "segmentation": {
                        "name": "Segmentation",
                        "description": "The user segments the medulla oblongata",
                        "taskId": "2da88349-93e4-4e15-8dc5-4bd27220575f"
                    },
                    "volume-measurement": {
                        "name": "Measurement of the volume of the medulla oblongata (4 measurements)",
                        "description": "Automatic volume measurement of the medulla oblongata",
                        "taskId": "06bf3451e2d63c3bf86b43983801e7f4"
                    }
                },
                "connectionsBetweenWorkflowAndTasks": {
                    "inputs": {
                        "01": {
                            "workflowInput": "image",
                            "task": {
                                "name": "lowerbound",
                                "input": "image"
                            }
                        },
                        "03": {
                            "workflowInput": "image",
                            "task": {
                                "name": "segmentation",
                                "input": "image"
                            }
                        },
                        "05": {
                            "workflowInput": "label",
                            "task": {
                                "name": "volume-measurement",
                                "input": "label"
                            }
                        },
                        "06": {
                            "workflowInput": "icc-volume",
                            "task": {
                                "name": "volume-measurement",
                                "input": "icc"
                            }
                        }
                    },
                    "outputs": {
                        "01": {
                            "workflowOutput": "medulla-oblongata-volume",
                            "task": {
                                "name": "volume-measurement",
                                "output": "measurement"
                            }
                        },
                        "02": {
                            "workflowOutput": "medulla-oblongata-volume-normalized",
                            "task": {
                                "name": "volume-measurement",
                                "output": "measurement2"
                            }
                        },
                        "03": {
                            "workflowOutput": "mean-medulla-oblongata-volume-normalized",
                            "task": {
                                "name": "volume-measurement",
                                "output": "measurement3"
                            }
                        },
                        "04": {
                            "workflowOutput": "mean-medulla-oblongata-volume",
                            "task": {
                                "name": "volume-measurement",
                                "output": "measurement4"
                            }
                        }
                    }
                },
                "connectionsBetweenTasks": {
                    "01": {
                        "fromTask": {
                            "name": "lowerbound",
                            "output": "upperSlice"
                        },
                        "toTask": {
                            "name": "segmentation",
                            "input": "upper-bound"
                        }
                    },
                    "02": {
                        "fromTask": {
                            "name": "lowerbound",
                            "output": "slice"
                        },
                        "toTask": {
                            "name": "segmentation",
                            "input": "lower-bound"
                        }
                    },
                    "04": {
                        "fromTask": {
                            "name": "segmentation",
                            "output": "segmentation"
                        },
                        "toTask": {
                            "name": "volume-measurement",
                            "input": "labelmap"
                        }
                    }
                },
                "transitions": {
                    "new01": {
                        "to": [
                            "lowerbound"
                        ]
                    },
                    "03": {
                        "from": [
                            "lowerbound"
                        ],
                        "to": [
                            "segmentation"
                        ]
                    },
                    "05": {
                        "from": [
                            "segmentation"
                        ],
                        "to": [
                            "volume-measurement"
                        ]
                    },
                    "06": {
                        "from": [
                            "volume-measurement"
                        ]
                    }
                },
                "owner": "gregory.bliault@u-bordeaux.fr",
                "creationDate": "2020-06-25T11:04:20.181Z",
                "docType": "workflow"
            },
            "tasks": {
                "lowerbound": {
                    "_id": "637274f6-1b9a-4f47-be28-33dee7db423a",
                    "_rev": "1-5a56547f009d4395e6e447b40857952f",
                    "name": "Medulla Oblongata lower Slice selection",
                    "description": "This task is used to select the lower bound of the medulla oblongata in the image",
                    "version": "0.0.3",
                    "privacy": "PUBLIC",
                    "helpFile": {
                        "video": "https://www.youtube.com/watch?v=_BEGorIkzYs&feature=youtu.be",
                        "panel": "https://drive.google.com/uc?export=download&id=1THyD_Z4rvbP6iL7Y1kQfJhSb1KAFkel5",
                        "video_fr": "https://www.youtube.com/watch?v=AuzE_Wx8Rtw&feature=youtu.be",
                        "panel_fr": "https://drive.google.com/uc?export=download&id=1JkEzGKLvJIBEynZdaA4AqmPZXBM6fSEz"
                    },
                    "process": {
                        "type": "manual",
                        "execution": {
                            "executor": "SPINE",
                            "descriptorId": "07ddfc16-402a-4134-a929-773f67cbc84e"
                        },
                        "contributionType": "SINGLE_USER"
                    },
                    "inputs": {
                        "image": {
                            "name": "Input image",
                            "description": "Input image",
                            "isList": false,
                            "type": "imageEntityInOut",
                            "idInDescriptor": "inputImage_key",
                            "imageEntityInOut_Type": "ANATOMICAL",
                            "imageEntityInOut_FileFormat": "dicom.gz",
                            "required": true
                        },
                        "inputShiftingNumber_key": {
                            "name": "Number shifting slices in the axial direction.",
                            "description": "Number of slices to shift the shifted output in the cranial direction. ",
                            "isList": false,
                            "type": "Number",
                            "idInDescriptor": "inputShiftingNumber_key",
                            "required": true
                        },
                        "inputShiftingDirection_key": {
                            "name": "Direction of shifting slices.",
                            "description": "Direction of shifting slices. ",
                            "isList": false,
                            "type": "String",
                            "idInDescriptor": "inputShiftingDirection_key",
                            "required": true
                        }
                    },
                    "constants": {},
                    "outputs": {
                        "slice": {
                            "name": "The slice number",
                            "description": "The selected slice number",
                            "isList": false,
                            "type": "roiWithAnnotationsInOut",
                            "typeROI": "EXPLICIT",
                            "annotations": [
                                {
                                    "typeAnnotationForm": "MULTIPLE_CHOICE_SINGLE_ANSWER",
                                    "propertiesAnnotation": {
                                        "isOntologyBased": true,
                                        "typeAnnotationOptions": "TEXT",
                                        "ontologyId": "https://bioportal.bioontology.org/ontologies/SNOMEDCT",
                                        "options": [
                                            {
                                                "value": "http://purl.bioontology.org/ontology/SNOMEDCT/279104005",
                                                "valueToDisplay": "Medulla Oblongata"
                                            }
                                        ]
                                    }
                                }
                            ],
                            "idInDescriptor": "slice_number_axial_key"
                        },
                        "upperSlice": {
                            "name": "Upper slice number.",
                            "description": "Uppers slice from the 'slice' number. This is shifted by a predefined amount of slices",
                            "isList": false,
                            "type": "roiWithAnnotationsInOut",
                            "typeROI": "EXPLICIT",
                            "annotations": [
                                {
                                    "typeAnnotationForm": "MULTIPLE_CHOICE_SINGLE_ANSWER",
                                    "propertiesAnnotation": {
                                        "isOntologyBased": true,
                                        "typeAnnotationOptions": "TEXT",
                                        "ontologyId": "https://bioportal.bioontology.org/ontologies/SNOMEDCT",
                                        "options": [
                                            {
                                                "value": "http://purl.bioontology.org/ontology/SNOMEDCT/279104005",
                                                "valueToDisplay": "Medulla Oblongata"
                                            }
                                        ]
                                    }
                                }
                            ],
                            "idInDescriptor": "slice_number_axial_shifted_key"
                        }
                    },
                    "owner": "gregory.bliault@u-bordeaux.fr",
                    "creationDate": "2020-06-25T11:04:20.181Z",
                    "docType": "task"
                },
                "segmentation": {
                    "_id": "2da88349-93e4-4e15-8dc5-4bd27220575f",
                    "_rev": "2-8c7c4230273934c07ea670e921c57555",
                    "name": "Segmentation (Brush)",
                    "description": "This task is used to segment the medulla oblongata in an image using the paint brush",
                    "version": "0.0.1",
                    "privacy": "PUBLIC",
                    "helpFile": {
                        "video": "https://www.youtube.com/watch?v=q2pg9slBEGE",
                        "panel": "https://drive.google.com/uc?export=download&id=19UujEMKIvGvFmUMZAtZx3luVod5R0-JP"
                    },
                    "process": {
                        "type": "manual",
                        "execution": {
                            "executor": "SPINE",
                            "descriptorId": "f70acd8b-e835-442a-bf18-a3e5130b49f2"
                        },
                        "contributionType": "SINGLE_USER"
                    },
                    "inputs": {
                        "image": {
                            "name": "Input image",
                            "description": "Input image",
                            "isList": false,
                            "type": "imageEntityInOut",
                            "imageEntityInOut_Type": "ANATOMICAL",
                            "imageEntityInOut_FileFormat": "dicom.gz",
                            "required": true,
                            "idInDescriptor": "inputImage_key"
                        },
                        "lower-bound": {
                            "name": "The lower bound",
                            "description": "The selected slice number for the lower bound",
                            "isList": false,
                            "type": "roiWithAnnotationsInOut",
                            "typeROI": "EXPLICIT",
                            "annotations": [],
                            "idInDescriptor": "lowerSliceNumber_key"
                        },
                        "upper-bound": {
                            "name": "The upper bound",
                            "description": "The selected slice number for the upper bound",
                            "isList": false,
                            "type": "roiWithAnnotationsInOut",
                            "typeROI": "EXPLICIT",
                            "annotations": [],
                            "idInDescriptor": "upperSliceNumber_key"
                        }
                    },
                    "constants": {
                        "inputLut_key1": {
                            "name": "Input LUT data",
                            "description": "Input LUT data for overlay displaying.",
                            "isList": false,
                            "type": "lookUpTable",
                            "required": true,
                            "idInDescriptor": "inputLut_key1",
                            "value": "c63c3c687bfeab4782a90290e1002fa9"
                        },
                        "inputLutDescription_key1": {
                            "name": "Input description of LUT data",
                            "description": "Input LUT data for displaying options.",
                            "isList": false,
                            "type": "lookUpTableDescription",
                            "required": true,
                            "idInDescriptor": "inputLutDescription_key1",
                            "value": "c63c3c687bfeab4782a90290e1007642"
                        }
                    },
                    "outputs": {
                        "segmentation": {
                            "name": "Medulla oblongata segmentation",
                            "description": "The medulla oblongata segmentation from the user",
                            "isList": false,
                            "idInDescriptor": "segmentation_key",
                            "type": "roiWithAnnotationsInOut",
                            "typeROI": "EXPLICIT",
                            "annotations": [
                                {
                                    "typeAnnotationForm": "MULTIPLE_CHOICE_SINGLE_ANSWER",
                                    "propertiesAnnotation": {
                                        "isOntologyBased": true,
                                        "typeAnnotationOptions": "TEXT",
                                        "ontologyId": "https://bioportal.bioontology.org/ontologies/SNOMEDCT",
                                        "options": [
                                            {
                                                "value": "http://purl.bioontology.org/ontology/SNOMEDCT/279104005",
                                                "valueToDisplay": "Medulla Oblongata"
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    "owner": "gregory.bliault@u-bordeaux.fr",
                    "creationDate": "2020-06-25T11:04:20.181Z",
                    "docType": "task"
                },
                "volume-measurement": {
                    "_id": "06bf3451e2d63c3bf86b43983801e7f4",
                    "_rev": "1-ad3b7ebc79e140dc4eb51316307e2e7e",
                    "name": "Label Volume Measurement (4 Measurements)",
                    "version": "0.1",
                    "description": "Measure the volume of one ROI given the label value",
                    "privacy": "PUBLIC",
                    "process": {
                        "type": "auto",
                        "execution": {
                            "executor": "bosh",
                            "descriptorId": "06bf3451e2d63c3bf86b43983801deaa"
                        }
                    },
                    "inputs": {
                        "labelmap": {
                            "name": "Label Map",
                            "description": "A discrete label map",
                            "type": "imageEntityInOut",
                            "idInDescriptor": "labelmap"
                        },
                        "label": {
                            "name": "Label",
                            "description": "Labem",
                            "type": "Number",
                            "idInDescriptor": "label"
                        },
                        "icc": {
                            "name": "ICC",
                            "description": "ICC",
                            "type": "Number",
                            "idInDescriptor": "icc"
                        }
                    },
                    "outputs": {
                        "measurement": {
                            "name": "Measurement",
                            "description": "Volume of the region",
                            "type": "annotationInOut",
                            "idInDescriptor": "measurement",
                            "annotation": {
                                "typeAnnotationForm": "OPEN_ENDED",
                                "propertiesAnnotation": {
                                    "typeAnnotationOptions": "NUMBER",
                                    "options": "Volume"
                                }
                            }
                        },
                        "measurement2": {
                            "name": "Measurement 2",
                            "description": "Volume of the region",
                            "type": "annotationInOut",
                            "idInDescriptor": "measurement2",
                            "annotation": {
                                "typeAnnotationForm": "OPEN_ENDED",
                                "propertiesAnnotation": {
                                    "typeAnnotationOptions": "NUMBER",
                                    "options": "Volume"
                                }
                            }
                        },
                        "measurement3": {
                            "name": "Measurement 3",
                            "description": "Volume of the region",
                            "type": "annotationInOut",
                            "idInDescriptor": "measurement3",
                            "annotation": {
                                "typeAnnotationForm": "OPEN_ENDED",
                                "propertiesAnnotation": {
                                    "typeAnnotationOptions": "NUMBER",
                                    "options": "Volume"
                                }
                            }
                        },
                        "measurement4": {
                            "name": "Measurement 4",
                            "description": "Volume of the region",
                            "type": "annotationInOut",
                            "idInDescriptor": "measurement4",
                            "annotation": {
                                "typeAnnotationForm": "OPEN_ENDED",
                                "propertiesAnnotation": {
                                    "typeAnnotationOptions": "NUMBER",
                                    "options": "Volume"
                                }
                            }
                        }
                    },
                    "owner": "admin@test.com",
                    "creationDate": "Wed Jul 10 2019 14:05:44 GMT-0400 (GMT-04:00)",
                    "docType": "task"
                }
            },
            "tools": {
                "07ddfc16-402a-4134-a929-773f67cbc84e": {
                    "_id": "07ddfc16-402a-4134-a929-773f67cbc84e",
                    "_rev": "1-0e8d56235a488b81664784797ccb3991",
                    "name": "Slice selector tool with picker only",
                    "description": "This tool has one viewer visible and another not rendered, kept just to provide output.Tool is used to select a specific slice in the axial direction, but with use of viewer in sagittal direction,  picker and reference line. This tool also outputs the slice number shifted N slices in a given direction.",
                    "version": "0.0.3",
                    "privacy": "PUBLIC",
                    "type": "SLICE_SELECTOR_WITH_PICKER",
                    "inputs": {
                        "inputImage_key": {
                            "name": "Input image",
                            "description": "Input image",
                            "isList": false,
                            "type": "imageEntityInOut",
                            "imageEntityInOut_Type": "ANATOMICAL",
                            "imageEntityInOut_FileFormat": "nii.gz",
                            "required": true
                        },
                        "inputShiftingNumber_key": {
                            "name": "Number shifting slices in the axial direction.",
                            "description": "Number of slices to shift the shifted output in the cranial direction. ",
                            "isList": false,
                            "type": "Number",
                            "required": true
                        },
                        "inputShiftingDirection_key": {
                            "name": "Direction of shifting slices.",
                            "description": "Direction of shifting slices. ",
                            "isList": false,
                            "type": "String",
                            "required": true
                        }
                    },
                    "outputs": {
                        "slice_number_axial_key": {
                            "name": "The slice number in axial direction",
                            "description": "The selected slice number in axial direction",
                            "isList": false,
                            "type": "roiInOut",
                            "typeROI": "IMPLICIT",
                            "implicitGeometryPointer": "SLICE",
                            "implicitRelationWithRoi": "CAUDAL_EXTERNAL_LIMIT",
                            "planeProperties": {
                                "anatomicalDirection": "AXIAL"
                            }
                        },
                        "slice_number_axial_shifted_key": {
                            "name": "The slice number shifted the in the axial direction",
                            "description": "The selected slice number shifted in the axial direction",
                            "isList": false,
                            "type": "roiInOut",
                            "typeROI": "IMPLICIT",
                            "implicitGeometryPointer": "SLICE",
                            "implicitRelationWithRoi": "CRANIAL_EXTERNAL_LIMIT",
                            "planeProperties": {
                                "anatomicalDirection": "AXIAL"
                            }
                        }
                    },
                    "bindingToolInputsToViewersInputs": [],
                    "bindingViewersOutputsToToolOutputs": [
                        {
                            "outputTool": "slice_number_axial_key",
                            "outputViewer": {
                                "viewerId": "1",
                                "property": "sliceNumber",
                                "orientation": "AXIAL"
                            }
                        },
                        {
                            "outputTool": "slice_number_axial_shifted_key",
                            "outputViewer": {
                                "viewerId": "1",
                                "property": "sliceNumber",
                                "orientation": "AXIAL",
                                "function": {
                                    "operation": "ADDITION",
                                    "keyInputNumberSlices": "inputShiftingNumber_key",
                                    "keyInputDirection": "inputShiftingDirection_key"
                                }
                            }
                        }
                    ],
                    "configuration": {
                        "scenes": {
                            "sceneKey1": {
                                "primaryImageInputKey": "inputImage_key"
                            }
                        },
                        "viewers": {
                            "layout": {
                                "type": "SINGLE",
                                "layoutOrder": {
                                    "middle": "0",
                                    "output": "1"
                                }
                            },
                            "renderWindows": {
                                "0": {
                                    "name": "Sagittal viewer",
                                    "type": "2D",
                                    "initialState": {
                                        "orientationAndSliceNumber": {
                                            "orientation": "SAGITTAL",
                                            "strategy": "DYNAMIC",
                                            "dynamicSliceValue": "MIDDLE"
                                        },
                                        "initialWindowLevel": {
                                            "strategy": "DYNAMIC",
                                            "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
                                        }
                                    },
                                    "displayScenes": {
                                        "possibleScenesToDisplay": [
                                            "sceneKey1"
                                        ],
                                        "hasDefaultSceneToDisplay": true,
                                        "defaultSceneToDisplay": "sceneKey1"
                                    },
                                    "displayControls": {
                                        "orientation": {
                                            "controlVisible": false,
                                            "controlEnabled": false,
                                            "options": [
                                                {
                                                    "label": "Axial",
                                                    "value": "AXIAL"
                                                }
                                            ]
                                        },
                                        "smoothing": {
                                            "controlVisible": false,
                                            "controlEnabled": false,
                                            "defaultValue": false
                                        },
                                        "sequence": {
                                            "controlVisible": false,
                                            "controlEnabled": false
                                        }
                                    },
                                    "lookAndFeel": {
                                        "color": "#ff0000",
                                        "hasRim": false,
                                        "hasControlPanel": false
                                    },
                                    "interactions": {
                                        "activeViewer": {
                                            "controlEnabled": false,
                                            "defaultValue": true
                                        }
                                    }
                                },
                                "1": {
                                    "name": "Axial invisible viewer for output purposes",
                                    "type": "2D",
                                    "initialState": {
                                        "orientationAndSliceNumber": {
                                            "orientation": "AXIAL",
                                            "strategy": "DYNAMIC",
                                            "dynamicSliceValue": "MIDDLE"
                                        },
                                        "initialWindowLevel": {
                                            "strategy": "DYNAMIC",
                                            "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
                                        }
                                    },
                                    "displayScenes": {
                                        "possibleScenesToDisplay": [
                                            "sceneKey1"
                                        ],
                                        "hasDefaultSceneToDisplay": true,
                                        "defaultSceneToDisplay": "sceneKey1"
                                    },
                                    "displayControls": {
                                        "orientation": {
                                            "controlVisible": false,
                                            "controlEnabled": false,
                                            "options": [
                                                {
                                                    "label": "Axial",
                                                    "value": "AXIAL"
                                                }
                                            ]
                                        },
                                        "smoothing": {
                                            "controlVisible": false,
                                            "controlEnabled": false,
                                            "defaultValue": false
                                        },
                                        "sequence": {
                                            "controlVisible": false,
                                            "controlEnabled": false
                                        }
                                    },
                                    "lookAndFeel": {
                                        "color": "#00ff00"
                                    }
                                }
                            }
                        },
                        "widgets": {
                            "pointerTool": {
                                "controlVisible": true,
                                "controlEnabled": true,
                                "defaultValue": true,
                                "properties": {
                                    "mode": {
                                        "controlVisible": true,
                                        "controlEnabled": true,
                                        "defaultValue": "onClick"
                                    }
                                }
                            },
                            "crossHairTool": {
                                "controlVisible": false,
                                "controlEnabled": false,
                                "defaultValue": true
                            }
                        }
                    },
                    "owner": "gregory.bliault@u-bordeaux.fr",
                    "creationDate": "2020-06-25T11:04:20.181Z",
                    "docType": "manualTool"
                },
                "f70acd8b-e835-442a-bf18-a3e5130b49f2": {
                    "_id": "f70acd8b-e835-442a-bf18-a3e5130b49f2",
                    "_rev": "2-02c9a5584eab6deb2b2b261276584842",
                    "name": "Segmentation (Brush)",
                    "description": "This tool is used to segment something in an image using the paint brush",
                    "version": "0.0.1",
                    "privacy": "PUBLIC",
                    "type": "SEGMENTATION_TOOL",
                    "inputs": {
                        "inputImage_key": {
                            "name": "Input image",
                            "description": "Input image",
                            "isList": false,
                            "type": "imageEntityInOut",
                            "imageEntityInOut_Type": "ANATOMICAL",
                            "imageEntityInOut_FileFormat": "nii.gz",
                            "required": true
                        },
                        "lowerSliceNumber_key": {
                            "name": "Lower axial slice number",
                            "description": "Lower axial slice number",
                            "isList": false,
                            "type": "Number",
                            "required": true
                        },
                        "upperSliceNumber_key": {
                            "name": "The upper bound",
                            "description": "The selected slice number for the upper bound",
                            "isList": false,
                            "type": "Number",
                            "required": true
                        },
                        "inputLut_key1": {
                            "name": "Input LUT data",
                            "description": "Input LUT data for overlay displaying.",
                            "isList": false,
                            "type": "lookUpTable",
                            "required": true
                        },
                        "inputLutDescription_key1": {
                            "name": "Input description of LUT data",
                            "description": "Input LUT data for displaying options.",
                            "isList": false,
                            "type": "lookUpTableDescription",
                            "required": true
                        }
                    },
                    "outputs": {
                        "segmentation_key": {
                            "name": "Medulla oblongata segmentation",
                            "description": "The medulla oblongata segmentation from the user",
                            "isList": false,
                            "type": "roiInOut",
                            "typeROI": "EXPLICIT"
                        }
                    },
                    "bindingToolInputsToViewersInputs": [
                        {
                            "inputTool": "lowerSliceNumber_key",
                            "inputViewer": {
                                "viewerId": "0",
                                "property": "slicingRanges_AXIAL_inferiormostSliceNumber"
                            }
                        },
                        {
                            "inputTool": "upperSliceNumber_key",
                            "inputViewer": {
                                "viewerId": "0",
                                "property": "slicingRanges_AXIAL_superiormostSliceNumber"
                            }
                        }
                    ],
                    "bindingViewersOutputsToToolOutputs": [
                        {
                            "outputTool": "segmentation_key",
                            "outputViewer": {
                                "viewerId": "0",
                                "viewerProperty": "currentSliceNumber_AXIAL"
                            }
                        }
                    ],
                    "bindingSceneElementsToToolOutputs": [
                        {
                            "outputTool": "segmentation_key",
                            "roiKeyName": "overlay_key_1"
                        }
                    ],
                    "configuration": {
                        "luts": {
                            "lut_key1": {
                                "fromInputs": true,
                                "lutInputKey": "inputLut_key1"
                            }
                        },
                        "lutDescriptions": {
                            "lutDescription_key1": {
                                "fromInputs": true,
                                "lutDescriptionInputKey": "inputLutDescription_key1"
                            }
                        },
                        "rois": {
                            "overlays": {
                                "overlay_key_1": {
                                    "fromInputs": false,
                                    "lutKey": "lut_key1",
                                    "lutDescriptionKey": "lutDescription_key1"
                                }
                            }
                        },
                        "scenes": {
                            "sceneKey1": {
                                "primaryImageInputKey": "inputImage_key",
                                "rois": {
                                    "overlays": [
                                        "overlay_key_1"
                                    ]
                                }
                            }
                        },
                        "viewers": {
                            "layout": {
                                "type": "1M1R_ARROWS",
                                "layoutOrder": {
                                    "middle": "0",
                                    "upperRight": "1"
                                }
                            },
                            "renderWindows": {
                                "0": {
                                    "name": "Axial viewer",
                                    "type": "2D",
                                    "initialState": {
                                        "orientationAndSliceNumber": {
                                            "orientation": "AXIAL",
                                            "strategy": "DYNAMIC",
                                            "dynamicSliceValue": "INFERIORMOST"
                                        },
                                        "initialWindowLevel": {
                                            "strategy": "DYNAMIC",
                                            "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
                                        }
                                    },
                                    "displayScenes": {
                                        "possibleScenesToDisplay": [
                                            "sceneKey1"
                                        ],
                                        "hasDefaultSceneToDisplay": true,
                                        "defaultSceneToDisplay": "sceneKey1"
                                    },
                                    "displayControls": {
                                        "orientation": {
                                            "controlVisible": false,
                                            "controlEnabled": false,
                                            "options": [
                                                {
                                                    "label": "Axial",
                                                    "value": "AXIAL"
                                                }
                                            ]
                                        },
                                        "smoothing": {
                                            "controlVisible": false,
                                            "controlEnabled": false,
                                            "defaultValue": false
                                        },
                                        "sequence": {
                                            "controlVisible": false,
                                            "controlEnabled": false
                                        }
                                    },
                                    "lookAndFeel": {
                                        "color": "#ff0000",
                                        "hasRim": false,
                                        "hasControlPanel": false
                                    },
                                    "interactions": {
                                        "activeViewer": {
                                            "controlEnabled": false,
                                            "defaultValue": true
                                        },
                                        "crossHairTool": {
                                            "controlEnabled": false
                                        }
                                    }
                                },
                                "1": {
                                    "name": "Sagittal viewer",
                                    "type": "2D",
                                    "initialState": {
                                        "orientationAndSliceNumber": {
                                            "orientation": "SAGITTAL",
                                            "strategy": "DYNAMIC",
                                            "dynamicSliceValue": "MIDDLE"
                                        },
                                        "initialWindowLevel": {
                                            "strategy": "DYNAMIC",
                                            "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
                                        }
                                    },
                                    "displayScenes": {
                                        "possibleScenesToDisplay": [
                                            "sceneKey1"
                                        ],
                                        "hasDefaultSceneToDisplay": true,
                                        "defaultSceneToDisplay": "sceneKey1"
                                    },
                                    "displayControls": {
                                        "orientation": {
                                            "controlVisible": false,
                                            "controlEnabled": false,
                                            "options": [
                                                {
                                                    "label": "Axial",
                                                    "value": "AXIAL"
                                                }
                                            ]
                                        },
                                        "smoothing": {
                                            "controlVisible": false,
                                            "controlEnabled": false,
                                            "defaultValue": false
                                        },
                                        "sequence": {
                                            "controlVisible": false,
                                            "controlEnabled": false
                                        }
                                    },
                                    "lookAndFeel": {
                                        "color": "#ff0000",
                                        "hasControlPanel": false
                                    },
                                    "interactions": {
                                        "activeViewer": {
                                            "controlEnabled": false,
                                            "defaultValue": false
                                        },
                                        "crossHairTool": {
                                            "controlEnabled": true,
                                            "defaultValue": {
                                                "viewers": {
                                                    "middle": {
                                                        "color": "#00FF00"
                                                    }
                                                },
                                                "limits": {
                                                    "middle": {
                                                        "slicingRanges_AXIAL_superiormostSliceNumber": {
                                                            "color": "#FF0000"
                                                        },
                                                        "slicingRanges_AXIAL_inferiormostSliceNumber": {
                                                            "color": "#FF0000"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "widgets": {
                            "brushTool": {
                                "controlVisible": true,
                                "controlEnabled": true,
                                "properties": {
                                    "radius": {
                                        "controlVisible": true,
                                        "controlEnabled": true,
                                        "defaultValue": 1
                                    }
                                }
                            },
                            "fillingTool": {
                                "controlVisible": true,
                                "controlEnabled": true
                            },
                            "eraserTool": {
                                "controlVisible": true,
                                "controlEnabled": true,
                                "properties": {
                                    "radius": {
                                        "controlVisible": true,
                                        "controlEnabled": true,
                                        "defaultValue": 1
                                    }
                                }
                            },
                            "labelmapUndoRedoTool": {
                                "controlVisible": true,
                                "controlEnabled": true
                            },
                            "labelmapOpacity": {
                                "controlVisible": true,
                                "controlEnabled": true,
                                "defaultValue": 1
                            },
                            "labelmapLUT": {
                                "controlVisible": false,
                                "controlEnabled": false,
                                "defaultValue": 1,
                                "properties": {}
                            },
                            "crossHairTool": {
                                "controlVisible": false,
                                "controlEnabled": false,
                                "defaultValue": true
                            },
                            "slicePositionIndexTool": {
                                "controlVisible": true,
                                "controlEnabled": true,
                                "defaultValue": true,
                                "properties": {
                                    "viewerKey": {
                                        "controlVisible": false,
                                        "controlEnabled": false,
                                        "defaultValue": "middle"
                                    }
                                }
                            }
                        }
                    },
                    "owner": "gregory.bliault@u-bordeaux.fr",
                    "creationDate": "2020-06-25T11:04:20.181Z",
                    "docType": "manualTool"
                },
                "06bf3451e2d63c3bf86b43983801deaa": {
                    "_id": "06bf3451e2d63c3bf86b43983801deaa",
                    "_rev": "1-bb9d9f64c9f67220d6d6cd41570987d8",
                    "docType": "boutiquesDescriptor",
                    "creationDate": "2019-07-10T18:05:44.014Z",
                    "owner": "admin@test.com",
                    "descriptor": {
                        "tool-version": "0.1",
                        "name": "Label Volume Measurement (4 Measurements)",
                        "command-line": "python /src/label_measurement4.py [labelmap] [label]",
                        "inputs": [
                            {
                                "description": "Label Map with the ROI to measures",
                                "value-key": "[labelmap]",
                                "type": "File",
                                "optional": false,
                                "id": "labelmap",
                                "name": "Label Map"
                            },
                            {
                                "value-key": "[label]",
                                "type": "Number",
                                "optional": false,
                                "id": "label",
                                "name": "Label"
                            },
                            {
                                "value-key": "[icc]",
                                "type": "Number",
                                "optional": false,
                                "id": "icc",
                                "name": "icc"
                            }
                        ],
                        "container-image": {
                            "image": "micheletgregory/spine-measurement:1.0.0",
                            "type": "docker"
                        },
                        "schema-version": "0.5",
                        "output-files": [
                            {
                                "path-template": "output/measurement.json",
                                "description": "JSON file with the volume for the ROI",
                                "optional": false,
                                "id": "measurement",
                                "name": "File with the measurement"
                            },
                            {
                                "path-template": "output/measurement2.json",
                                "description": "JSON file with the volume for the ROI",
                                "optional": false,
                                "id": "measurement2",
                                "name": "File with the measurement"
                            },
                            {
                                "path-template": "output/measurement3.json",
                                "description": "JSON file with the volume for the ROI",
                                "optional": false,
                                "id": "measurement3",
                                "name": "File with the measurement"
                            },
                            {
                                "path-template": "output/measurement4.json",
                                "description": "JSON file with the volume for the ROI",
                                "optional": false,
                                "id": "measurement4",
                                "name": "File with the measurement"
                            }
                        ],
                        "description": "Python script to measure the volume of one region of interest"
                    }
                }
            }
        };
        const {project, isList, filter, inputWorkflow=defaultInputWorkflow } = this.props;
        const onFilter = this.props.onFilterChange;

        console.log('IsList', isList);
        /**  filter params. should be taken from schema*/
        const titles = ["All", 'Draft', 'In review', 'Active', 'Completed', 'Published'];
        const filterValues= ["ALL", EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT,EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW,
            'ACTIVE', EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED, 'PUBLISHED'];
        const hc = (e,i)=>{
            if (e.checked) {
                filter.delete(filterValues[i]);
                if (filter.size===1) onFilter(new Set());
                else onFilter(new Set(filter));}
            else  {
                filter.add(filterValues[i]);
                filter.add(filterValues[0]);//always add All to inactive filters
                onFilter(new Set(filter))}
        };

        // if active ise on -> draft and inreview are on too
        const ac = (e)=>{
            if (e.checked) {
                filter.delete(filterValues[3]);
                filter.delete(filterValues[2]);
                filter.delete(filterValues[1]);
                if (filter.size===1) onFilter(new Set());
                else onFilter(new Set(filter));}
            else  {
                filter.add(filterValues[3]);
                filter.add(filterValues[1]);
                filter.add(filterValues[2]);
                filter.add(filterValues[0]);//always add All to inactive filters
                onFilter(new Set(filter))}
        };
        const allChecked = (e,i)=>{
            if (e.checked)
                onFilter(new Set());}
        ;

        let clbks = [allChecked,hc,hc,ac,hc,hc];

        let values = [
            !filter.has('ALL'),
            !filter.has(EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT),
            !filter.has(EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW),
            !filter.has('ACTIVE'),
            !filter.has(EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED),
            !filter.has('PUBLISHED')];
        let sorterOptions=[{value: 'SORT_BY_STATUS_DESC', label: 'Status Descending'},
            {value: 'SORT_BY_STATUS_ASC', label: 'Status Ascending'},
            {value: 'SORT_BY_LAST_MODIFIED_DESC', label: 'Last Modified Descending'},
            {value: 'SORT_BY_LAST_MODIFIED_ASC', label: 'Last Modified Ascending'},
            {value: 'SORT_BY_NAME_DESC', label: 'Question Descending'},
            {value: 'SORT_BY_NAME_ASC', label: 'Question Ascending'}
        ];

        const rmDialogFooter = (
            <div>
                <Button label="Yes" icon="fa fa-check" onClick={this.onDelete} />
                <Button label="No" icon="fa fa-times" onClick={this.onRmDialogHide} />
            </div>
        );

        return (
            <div>
                <div id="filters">
                    <CheckboxGroup titles={titles} clbks={clbks} values={values} filter={filter}/>
                    <Dropdown options={sorterOptions}
                              value={this.props.sorter}
                              onChange={(e)=>this.props.onSorterChange(e.value)}
                              style={{border:'none',background:'#f0f4f7'}}/>
                </div>
                {/* // Progress column removed for now: See classNames and headers props */}
                {this.props.experimentsState ==='success' && this.props.experiments.length>0 &&
                <ItemList model={this.props.experiments}
                                isList={isList}
                                classNames={["ui-g-4","ui-g-2","ui-g-1","ui-g-1","ui-g-2","ui-g-2"]}
                                headers={["Question","People","Status","Last modified","Progress"]}
                                properties={["question","co-investigators","status","modificationDate","progress"]}
                                cardRenderer={<ExperimentItemCardElement onRemoveClick={this.onRmDialogShow}
                                                                         onAnalysisClick={item=>this.setState({analysedExperiment:item, analysisDialogVisible:true })}
                                />}
                                listRenderer={<ExperimentItemListElement onRemoveClick={this.onRmDialogShow}
                                                                         onProgressClick={this.onWorkflowProgressDialogShow}
                                                                         onAnalysisClick={item=>this.setState({analysedExperiment:item, analysisDialogVisible:true })}

                                />}
                />
                }
                {/* //Remove dialog */}
                <Dialog header="Confirmation Dialog"
                        visible={this.state.rmDialogVisible}
                        width="350px"
                        modal={true}
                        footer={rmDialogFooter}
                        onHide={this.onRmDialogHide}>
                    Are you sure that you want to remove experiment answering the following question:
                    <div style={{fontWeight:'bold'}}>
                    {this.state.selected[EXPERIMENT_PROPERTY_NAME__QUESTION]}

                        ?</div>
                </Dialog>
                <Dialog header="Analyze data"
                        visible={this.state.analysisDialogVisible}
                        style={{width:'60vw'}}
                        modal={true}
                        onHide={()=>this.setState({analysisDialogVisible:false})}>
                    {this.state.analysisDialogVisible &&
                    <AnalysisChoiceView item={this.state.analysedExperiment}/>}
                </Dialog>
                {/* // Progress dialog with Editor (workflowEditor)*/}
                <Dialog header="Progress Dialog"
                        visible={this.state.workflowProgressDialogVisible}
                        style={{width: window.innerWidth*0.9,
                                height: window.innerHeight*0.9}}
                        modal={true}
                        contentStyle={{height: "100%", width: "100%"}}
                        onHide={this.onWorkflowProgressDialogHide}>
                    <Editor
                        canvasStyle={{height:"90%", width:"100%"}}
                        inputWorkflow={inputWorkflow}
                        jsonTasks={null}
                        jsonTools={null}
                        setCallRetrieveEngine={click =>this.retrieveEngine = click}
                        setCallDistribute={click =>this.distribute = click}
                        setCallZoomToFit={click =>this.zoomToFit = click}
                        onSettingsIconClick={(node,onUpdate)=>this.onTaskProgressDialogShow(node,onUpdate)}
                        onError={(e)=>messageQueue.show({severity: 'error', summary: 'Parsing error', detail: e.name + ': ' + e.message})}
                    />
                </Dialog>
                {/* // Progress dialog with task progress*/}
                <Dialog header="Task Progress Dialog"
                        visible={this.state.taskProgressDialogVisible}
                        style={{width: window.innerWidth/2,
                                height: window.innerHeight*0.9}}
                        modal={true}
                        contentStyle={{height: "100%", width: "100%"}}
                        onHide={this.onTaskProgressDialogHide}>
                    {/* // Add tab for progress or new component to only render progress */}
                    <TaskPropertiesPanel
                            nodeSettings={this.state.nodeSettings}
                    />
                </Dialog>
            </div>
        )
    }
}
ProjectDetailsExperimentsTab.propTypes = {
    project: PropTypes.array.isRequired, //project
    isList:PropTypes.bool.isRequired, // display mode (true ->list in 1 column, false-> cards in 2 columns)
    filter:PropTypes.object
};