import { connect } from 'react-redux';
import EmailValidation from '../component/EmailValidation';

const mapStateToProps = state => {
  return{
  	loginStatus : state.auth.login_status
  }
};

const mapDispatchToProps = dispatch => {
  return{
  }
};

const ContainerEmailValidation = connect(
 mapStateToProps,
 mapDispatchToProps)(EmailValidation);

export default ContainerEmailValidation ;