import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {removeDuplicatesByFilterFunction} from "../../helpers/arrays";
import {filterMeasurementByString, getMeasurementById, getMeasurementShortLabel} from "../action/SAMAction";

/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class SelectedContributionsPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,

    };
    ["contributorBody","groupBody","roiBody","measurementBody"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }


  contributorBody (row, column) {
    const {rawData} = this.props;
    const contributor = rawData.contributors.find(el=>el.uuid===row.contributorId);
    const label = contributor!=null
      ? contributor.label!=null
        ? contributor.label
        : contributor.name !=null
          ? contributor.name
          : "NA"
      :"NA";
    return <div >{label}</div>;
  };
  groupBody(row){
    const {selectedDataPoint} = this.props;
    const in1 = selectedDataPoint.contributionsX.findIndex(el=>el.key===row.key)>-1?"1":"";
    const in2 = selectedDataPoint.contributionsY.findIndex(el=>el.key===row.key)>-1?"2":"";
    const label = (in1!==""&&in2!=="" )
      ? "1, 2"
      : in1 + in2;
    return <div title={"This contribution was included into group(s):"}>{label}</div>;
  }
  roiBody(row){
    if (Array.isArray(row.roiId)){
      return row.roiId.map((el,i)=><a title={el} style={{marginRight:".5em"}}>{(i+1)}</a>);
    }
    else
      return <a title={row.roiId}>{1}</a>;
  }
  measurementBody(row,column){
    const {rawData} = this.props;
    const measurement = getMeasurementById(row.measurementConfigurationId,rawData);
    if (measurement!=null)
      return getMeasurementShortLabel(measurement,rawData);
    return "NA";
  }

  render() {
    const {t, selectedDataPoint, rawData} = this.props;

    const data = selectedDataPoint.mode === 0
      ? removeDuplicatesByFilterFunction(selectedDataPoint.contributionsX.concat(selectedDataPoint.contributionsY),x=>x["key"]) // handle agreement
      : selectedDataPoint.contributionsX.concat(selectedDataPoint.contributionsY);  // handle MD distribution


    return (
      <React.Fragment>
        <DataTable
          value={data} // concat and remove dups
          emptyMessage={"No properties found"}
          paginator={true}
          rows={15}
        >
          <Column field="contributorId" header="Contributor" body={this.contributorBody}/>
          {selectedDataPoint.mode === 0 && <Column header="Group" body={this.groupBody}/>}
          {selectedDataPoint.mode === 1 && <Column field="measurementConfigurationId" header="Measurement" body={this.measurementBody} style={{width:"20em"}}/>}
          <Column field="value" header="Value" filter={true} filterMatchMode={"contains"}/>
          <Column field="units" header="Unit" filter={true} filterMatchMode={"contains"}/>
          <Column field="roiId" header="ROIs" sortable={true} filter={true} filterMatchMode={"contains"} body={this.roiBody}/>
          {/*<Column field="roiId" header="ROI type" body={this.roiBody}/>*/}
          <Column field="workflowId" header="Workflow" filter={true} filterMatchMode={"contains"}/>
          <Column field="taskExecutorId" header="Task id" filter={true} filterMatchMode={"contains"}/>
          <Column field="contributionDate" header="Date"/>
        </DataTable>
      </React.Fragment>
    );
  }
}

export default withTranslation()(SelectedContributionsPanel);

SelectedContributionsPanel.propTypes = {
  rawData: PropTypes.object.isRequired,
  updateSelectedDataPoint: PropTypes.func.isRequired,
  selectedDataPoint: PropTypes.object.isRequired,
  t: PropTypes.func
};
