import {createSelector} from 'reselect';
import {datePropertyComparator, numberPropertyComparator, stringPropertyComparator} from "../../helpers/comparators";

export const getVisibilityFilter = (state) => state.activity.manualTasks.visibilityFilter;
export const getSorter = (state) => state.activity.manualTasks.sorter;
const getManualTasks = (state) => state.activity.manualTasks.manualTasks;

export const getVisibleManualTasks = createSelector(
    [ getVisibilityFilter, getSorter, getManualTasks ],
    (visibilityFilter, sorter, manualTasks) => {
        return getSortedManualTasks(sorter,getFilteredManualTasks(visibilityFilter,manualTasks));
    }
);

/**
 * Helper  for filtering manualTasks.
 * @param filter
 * @param manualTasks
 * @returns {*}
 */
const getFilteredManualTasks = (filter, manualTasks) => {
    switch (filter) {
        case 'SHOW_ALL':
            return manualTasks;
        case 'HIDE_COMPLETED':
            return manualTasks.filter(t => t.status!=='COMPLETED');
        default:
            return manualTasks;
    }
};


/** Helper used for sorting manualTasks.
 *
 * @param sorter
 * @param manualTasks
 * @returns {void | * | Uint8Array | Uint16Array | Int16Array | Float32Array} sorted manualTasks
 */
const getSortedManualTasks = (sorter, manualTasks) => {
    switch (sorter) {
        case 'SORT_BY_NAME_DESC':
            return manualTasks.sort(stringPropertyComparator('expName'));
        case 'SORT_BY_NAME_ASC':
            return manualTasks.sort(stringPropertyComparator('expName')).reverse();
        case 'SORT_BY_DUE_DATE_DESC':
            return manualTasks.sort(datePropertyComparator('dueDate'));
        case 'SORT_BY_DUE_DATE_ASC':
            return manualTasks.sort(datePropertyComparator('dueDate')).reverse();
        case 'SORT_BY_CASES_MISSING_DESC':
            return manualTasks.sort(numberPropertyComparator('numberOfCasesMissing'));
        case 'SORT_BY_CASES_MISSING_ASC':
            return manualTasks.sort(numberPropertyComparator('numberOfCasesMissing')).reverse();
        case 'SORT_BY_CASES_DONE_DESC':
            return manualTasks.sort(numberPropertyComparator('numberOfCasesDone'));
        case 'SORT_BY_CASES_DONE_ASC':
            return manualTasks.sort(numberPropertyComparator('numberOfCasesDone')).reverse();
        case 'SORT_BY_CASES_DESC':
            return manualTasks.sort(numberPropertyComparator('numberOfCases'));
        case 'SORT_BY_CASES_ASC':
            return manualTasks.sort(numberPropertyComparator('numberOfCases')).reverse();
    }
};
