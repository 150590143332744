import React from "react";
import {Prompt, withRouter} from "react-router";
import PropTypes from "prop-types";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
// import Viewer2D from "../../../vtk/Viewer2D";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {ToggleButton} from "primereact/components/togglebutton/ToggleButton";
//import Viewer3D from "../../../vtk/Viewer3D";
import {calcRulers, slicingKeyHandler} from "../../../vtk/SpineVTKHelper";
import DynamicHistogram from "./DynamicHistogram";
import {REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import SpinePaintFilter from "../../../vtk/paintbrush/SpinePaintFilter";
import {MOCKED_EXPERTS_DATA} from "../../tests/Mocks";
const Viewer2D =  React.lazy(() => import("../../../vtk/Viewer2D")); //dynamic import
const Viewer3D =  React.lazy(() => import("../../../vtk/Viewer3D")); //dynamic import
/**
 * Panel to display frequency maps.
 *
 */
class DynamicHistogramDicePanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tracker: false,
            width:800,
            helpVisible: false,
            activeViewerId: null,
            opacity: 100,
            selected: null,  //selected Data Points in left and right
            imageTypes: [0, 0, 0],
            smoothingVolume:false,
            colorOptions: [
                {
                    value: 2,
                    label: "user",
                    color: '#00f'
                },
                {
                    value: 3,
                    label: "experts",
                    color: '#ff0'
                },
                {
                    value: 1,
                    label: "agreed",
                    color: '#0f0'
                }],
            painter:SpinePaintFilter.newInstance(),
            linkViewers: true,
            leftButtonMode: LEFT_BUTTON_MODE.NONE
        };
        ["setButtonLeftMode", "keyListener", "pickerCallback", "calcWidth", "calcHeight"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    componentDidMount() {
        const {initialize,match, livePresenterExperimentId} = this.props;
        initialize(livePresenterExperimentId);
        let width = this.calcWidth();
        this.setState({width});
        document.body.addEventListener("keydown", this.keyListener, false);
    }

    componentDidUpdate(prevProps, prevState) {
        const {overlays, images,imageId, viewersState, initializeViewersInDicePanel,skillResult} = this.props;
        const {painter}=this.state;
        const orig = (images!=null && viewersState['left']!=null)?images[viewersState['left'].imageId]:null;//.find((el)=>{return el.uuid === viewersState['left'].imageId}):null;
        const ov = (overlays!=null && viewersState['left']!=null)?overlays[viewersState['left'].overlayId]:null;//.find((el)=>{return el.uuid === viewersState['left'].overlayId}):null;

        // if viewers not set and results are available
        if (!(viewersState["left"]["imageId"] != null) && skillResult.resultState === REQUEST_STATUS_SUCCESS)
            initializeViewersInDicePanel(skillResult['result']['originalImageId'],skillResult['result']['overlapLabelMapId'],);
        //if painter not set then resample original image
        if (!painter.getBackgroundImage() && orig!=null && orig['state']===REQUEST_STATUS_SUCCESS
            && ov !=null &&  ov['state']===REQUEST_STATUS_SUCCESS){
            painter.setBackgroundImage(orig['data']);
            painter.update();
            painter.setOverlayImageData(ov['data']);
            painter.modified();
            this.setState({painter:Object.assign({},painter)}); // this can be applied only if interactor is not using painter to brush, fill, etc. !! READ MODE ONLY
        }
        //if workflowResultId changes then  inititialize viewer for new set of images
    }

    pickerCallback(viewerId, ijk) {
        const {viewersState, updateViewerProperty, images} = this.props;
        const image = images[viewersState[viewerId].imageId];//.find((el) => el.uuid === viewersState[viewerId].imageId);
        const slicingModes = image['properties']['slicingModes'];
        let copyCis = {};
        Object.keys(viewersState).forEach((el) => {
            copyCis[el] = Object.assign({}, viewersState[el])
        });
        const id = copyCis[viewerId].imageId;
        Object.keys(copyCis).forEach((el, i) => {
            if (viewersState[el].imageId === id) {
                updateViewerProperty(el, 'sliceNumber',
                    ijk[slicingModes[viewersState[el]['orientation']]]); // set ijk depending on current orientation
            }
        });
    }

    calcHeight() {
        if (this.histogramContainer != null) {
            const rect = this.histogramContainer.getBoundingClientRect();
            return rect.height;
        }
    }

    calcWidth() {
        if (this.histogramContainer != null) {
            const rect = this.histogramContainer.getBoundingClientRect();
            return rect.width;
        }
    }

    componentWillUnmount() {
        const {clearViewersState} = this.props;
        clearViewersState();
        document.body.removeEventListener("keydown", this.keyListener, false);
    }

    setButtonLeftMode(e) {
        const {painter, leftButtonMode, brushProperties, fillingProperties} = this.state;
        if (e !== leftButtonMode) {
            this.setState({leftButtonMode: e});
            if (painter != null) {
                if (e === LEFT_BUTTON_MODE.BRUSH)
                    painter.setLabel(brushProperties.colorValue);
                if (e === LEFT_BUTTON_MODE.FILLING)
                    painter.setLabel(fillingProperties.colorValue);
            }
        }
        else {
            this.setState({leftButtonMode: LEFT_BUTTON_MODE.NONE});
        }
    }


    keyListener(event) {
        const {activeViewerId} = this.state;
        const {handleKeySlicing} = this.props;
        if (activeViewerId !=null) {
            handleKeySlicing(activeViewerId,event);
        }
    }


    render() {
        const {
            activeViewerId, linkViewers, tracker, leftButtonMode, colorOptions, opacity, imageTypes, painter, helpVisible
        } = this.state;
        const {
            resultsState, slideProperties,email,skillResult,
            data, viewersState,
            updateViewerProperty, imageOptions, changeImageType, images, overlays, changeOrientation,
            clearViewersState
        } = this.props;

        //subtract first and last
        // let binSize = (slideProperties['bins'] > 2)
        //     ? (slideProperties['thresholdMaxValue'] - slideProperties['thresholdMinValue']) / (slideProperties['bins'] - 2)
        //     : slideProperties['thresholdMaxValue'] - slideProperties['thresholdMinValue'];
        //

        const binSize = 0.1; //MOCK
        let thresholds = [1.5, 1.6,1.7,1.8 ];

        let getOverlay = (key) => {
            return overlays[ viewersState[key].overlayId];//.find((el) => viewersState[key].overlayId === el.uuid);
        };

        let browserPanel = (viewersState != null && Object.keys(viewersState).length > 0)
            ? Object.keys(viewersState).map((el, index) =>{

            return (viewersState[el].type ==="2D")
                ?
                <div className="ui-g-3" style={{padding: "0.1em"}}>
                    <React.Suspense fallback={<p>Loading</p>}>
                    <Viewer2D key={el}
                                       id={el}
                                       isActive={this.state.activeViewerId === el}
                                       setActive={() => this.setState({activeViewerId: el})}
                                       annotationData={data}
                                       addRow={() => {}}
                                       pinProperties={null}
                                       tracker={tracker}
                                       linkViewers={linkViewers}
                                       painter={painter}
                                       leftButtonMode={leftButtonMode}
                                       opacity={opacity}
                                       pinOpacity={null}
                                       colorOptions={colorOptions}
                                       imageOptions={imageOptions}
                                       setLinkViewers={() => this.setState({linkViewers: !linkViewers})}
                                       viewerState={viewersState[el]}
                                       updateViewer={(viewerState) => this.updateViewer(el, viewerState)}
                                       pickerCallback={(ijk) => this.pickerCallback(el, ijk)}
                                       smoothing={viewersState[el]['smoothing']}
                                       changeSmoothing={() => updateViewerProperty(el, 'smoothing', !viewersState[el]['smoothing'])}
                                       changeOrientation={(v) => changeOrientation(el, v)}
                                       updateViewerProperty={(property, value) => updateViewerProperty(el, property, value)}
                                       image={images[viewersState[el].imageId]}
                                       overlay={getOverlay(el)}
                                       rulers={(tracker) ? calcRulers(el, activeViewerId, viewersState, images) : null}
                                       hasCropping={[false, false, false, false, false, false]}
                                       croppingSlices={[0, 0, 0, 0, 10, 50]}
                                       hasGlass={false}
                                       isOrientationControlDisabled={true}
                                       isOrientationControlVisible={true}
                                       isLinkedControlVisible={false}
                                       {...(index < 3 ? {dynamicInitialSliceSetting: "MIDDLE"} : {})}

                    /></React.Suspense>
                </div>
                :<div className="ui-g-3" style={{padding: "0.1em"}}>
                    <React.Suspense fallback={<p>Loading</p>}>
                    <Viewer3D
                        key={'viewer3'}
                        isActive={false}
                        setActive={() => {}}
                        annotationData={data}
                        tracker={tracker}
                        linkViewers={linkViewers}
                        painter={painter}
                        leftButtonMode={leftButtonMode}
                        opacity={opacity}
                        pinOpacity={null}
                        colorOptions={colorOptions}
                        imageOptions={imageOptions}
                        setLinkViewers={() => this.setState({linkViewers: !linkViewers})}
                        viewersState={viewersState} //object of 3 viewers state
                        updateViewer={(viewerState) => this.updateViewer(0, viewerState)}
                        pickerCallback={(ijk) => this.pickerCallback(0, ijk)}
                        smoothing={this.state.smoothingVolume}
                        changeSmoothing={() => this.setState({smoothingVolume:!this.state.smoothingVolume})}
                        changeOrientation={(v) => changeOrientation(0, v)}
                        imageType={imageTypes[0]}
                        changeImageType={(e) => changeImageType(0, e)}
                        updateViewerProperty={(property, value) => updateViewerProperty('right', property, value)}
                        image={images[viewersState['left'].imageId]}
                        overlay={getOverlay('left')}
                        rulers={(tracker) ? calcRulers('left', activeViewerId, viewersState, images) : null}
                        sliceVisibility={viewersState[el]['sliceVisibility']}
                    /></React.Suspense>
                </div>
            })
            : <div className="ui-g-12" style={{padding: "0.1em"}}/>;


        let resultsPropertyName = 'volume';
        let processDataForScatter = (roomId) => {
            if (roomId === "none") return null;
            let dataForScatter = [];
            let dataToProcess = (skillResult.resultState !== REQUEST_STATUS_SUCCESS)
                ? MOCKED_EXPERTS_DATA(email)
                : skillResult.result;
            let bins = new Array(thresholds.length + 1).fill(0);
            ['user','experts'].forEach((el)=> {
                if (dataToProcess[el] != null)
                    dataToProcess[el].forEach((s) => {
                        let single = {
                            "y": s['results'][resultsPropertyName],
                            "isExpert": (el==='user')?0:1,
                            //"imageFileId": s['results']['imageFileId'],
                            "userId": dataForScatter.length + 1,
                            "img": '/api/user/' + s['userId'] + '/picture'
                        };
                        if (s['results'][resultsPropertyName] < thresholds[0]) {
                            single['x'] = ++bins[0];
                            single['yBinMode'] = thresholds[0] - binSize * .5;
                        }
                        else if (s['results'][resultsPropertyName] >= thresholds[thresholds.length - 1]) {
                            single['x'] = ++bins[bins.length - 1];
                            single['yBinMode'] = thresholds[thresholds.length - 1] + binSize * .5;
                        }
                        else
                            for (let i = 0; i < thresholds.length; i++)
                                if (thresholds[i] <= s['results'][resultsPropertyName] && s['results'][resultsPropertyName] < thresholds[i + 1]) {
                                    single['x'] = ++bins[i + 1];
                                    single['yBinMode'] = (thresholds[i + 1] + thresholds[i]) * .5;
                                }
                        dataForScatter.push(single)
                    });
            });
            return dataForScatter;
        };


        // let dataForHistogram = (resultsState === REQUEST_STATUS_SUCCESS) ? processDataForScatter() : null;
        let dataForHistogram =  processDataForScatter();
        let width = this.calcWidth();
        const score = (skillResult.resultState === REQUEST_STATUS_SUCCESS)
            ?skillResult.result['dice']*100
            :0;
        const threshold = (skillResult.resultState === REQUEST_STATUS_SUCCESS)
            ?skillResult.result['diceThreshold']*100
            :100;
        const labelStyle = {fontSize:"2em",textAlign:"center"};
        const scoreStyle=(score>=threshold)?{color:"green",...labelStyle}:{color:"red",...labelStyle};
        return (
            <React.Fragment>
                {/*<i className="fa fa-question-circle-o taskToolHelpButton"*/}
                   {/*style={{fontSize: "30px"}}*/}
                   {/*onClick={() => this.setState({helpVisible: !helpVisible})}/>*/}
                {!helpVisible &&
                <div>
                    <div className="ui-g-12">
                        <div className="ui-g-6 ui-g-nopad" ref={node => this.histogramContainer = node}>
                            <DynamicHistogram
                                length={(!(dataForHistogram != null)) ? -1 : dataForHistogram.length}
                                width={width}
                                data={dataForHistogram}
                                colorCat="isExpert"
                                thresholds={thresholds}
                                colorLUT={["rgb(0, 0, 255)","rgb(255, 255, 0)"]}
                                //onDataPointClick={(e) => this.onDataPointClick(e, 0)}
                                useAvatars={true}
                                selected={this.state.selected}
                                viewId={this.state.plotsState}
                                prefixId="left"
                                opacityMode="crazy"
                                yLabel="Volume[cc3]"
                            />
                        </div>
                        <div className="ui-g-6 ui-g-nopad">
                            <div style={scoreStyle}>Your Dice Score</div>
                            {(skillResult!=null && skillResult.resultState === REQUEST_STATUS_SUCCESS) &&
                                <div style={scoreStyle}>{score.toPrecision(2)}%</div>
                            }
                            {(!(skillResult!=null && skillResult.resultState === REQUEST_STATUS_SUCCESS)) &&
                                 <div style={scoreStyle}>
                                     <i className="fa fa-spinner fa-spin"/>
                                 </div>

                            }
                            <div className="diceResultsImage"/>
                        </div>
                        <div className="ui-g-12 ui-g-nopad">
                            <Toolbar style={{display: "inline-block"}}>
                                <ToggleButton checked={leftButtonMode === LEFT_BUTTON_MODE.PICKER}
                                              onIcon="fa fa-hand-o-up"
                                              onLabel="" offLabel=""
                                              offIcon="fa fa-hand-o-up"
                                              iconPos="right"
                                              tooltip="Picker"
                                              onChange={() => {
                                                  this.setButtonLeftMode(LEFT_BUTTON_MODE.PICKER)
                                              }}/>
                                <label style={{marginLeft: "1em"}}>Opacity:</label>
                                <input type="range" onChange={(e) => this.setState({opacity: e.target.value})}
                                       min={0} step={10} max={100}
                                       style={{
                                           width: "4em",
                                           verticalAlign: "middle",
                                           marginRight: "5px",
                                           marginLeft: "5px"
                                       }}
                                       value={opacity}
                                />
                                <ToggleButton checked={tracker} onLabel="" offLabel="" toolTip="Crosshairs On/Off"
                                              onChange={() => {
                                                  this.setState({tracker: !tracker})
                                              }} onIcon="fa fa-crosshairs" offIcon="fa fa-crosshairs"/>
                            </Toolbar>
                        </div>
                    </div>
                    <div className="ui-g-12">
                        {browserPanel}
                    </div>
                </div>}
            </React.Fragment>
        )
    }

}

export default withRouter(DynamicHistogramDicePanel);

DynamicHistogramDicePanel.propTypes = {
    email:PropTypes.string.isRequired,
    skillResult:PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    getAnnotations: PropTypes.func.isRequired,
    updateAnnotationData: PropTypes.func.isRequired,
    rmCallback: PropTypes.func.isRequired,
    saveOverlayData: PropTypes.func,
    clearAll: PropTypes.func.isRequired,
    addAnnotation: PropTypes.func.isRequired,
    setActiveAnnotation: PropTypes.func.isRequired,
    clearViewersState: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    viewersState: PropTypes.object.isRequired,  //array containing state of all viewers
    updateViewerProperty: PropTypes.func.isRequired,
    updateViewer: PropTypes.func,
    imageOptions: PropTypes.array.isRequired,
    changeImageType: PropTypes.func.isRequired,
    images: PropTypes.array.isRequired,
    overlays: PropTypes.array.isRequired,
    changeOrientation: PropTypes.func.isRequired,
    manualTaskState: PropTypes.object.isRequired,
    updateManualTask: PropTypes.func.isRequired,
    livePresenterExperimentId: PropTypes.string.isRequired,
    initializeViewersInDicePanel: PropTypes.func.isRequired
};


