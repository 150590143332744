import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {InputText} from "primereact/components/inputtext/InputText";
import {CUSTOM_ELEMENT} from "./AnnotationCell";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {REQUEST_STATUS_SUCCESS} from "../../../../Constants";


/**
 * Form for creating/editing annotation column.
 */
class ChoiceOptionsEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data:null,
      dataDetails:null
    };
    ["addNode","removeNode","updateData","updateOntologyRegister","renderClassIRI","getOntologyIdEditor","handleTrashButton","renderSelectColumnTemplate","getEditor",
      "onRowReorder","renderTrashTemplate","renderOntologyId","getIRIEditor"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  static generateLink(link) {
    return (<a target="_blank" href={link} title={"Open ".concat(link, " in external window")}>
      {link.substring(link.lastIndexOf('/') + 1)}
    </a>);
  }

  componentDidMount() {
    const {  options,optionsDetails,type} =this.props;

    if (options!=null){
      if (type===CUSTOM_ELEMENT.MULTIPLE_CHOICE.type){
        this.setState({
          data:options.slice(0).map(el=>{return {value:el}}),
        });
      }
      else  this.setState({
        data:options.slice(0),
      }); //deep copy of tree to avoid unwanted modification
    }
    else this.setState({data:[]});
    if (optionsDetails!=null){
      this.setState({
        dataDetails:Object.assign({},JSON.parse(JSON.stringify(optionsDetails)))
      }); //deep copy of tree to avoid unwanted modification
    }
    else this.setState({dataDetails:{}});
  }


  static generateLink(link) {
    return (<a target="_blank" href={link} title={"Open ".concat(link, " in external window")}>
      {link.substring(link.lastIndexOf('/') + 1)}
    </a>);
  }


  addNode() {
   const {data}=this.state;
   const {type}=this.props;
   const tempData = data.slice(0);
   if(type===CUSTOM_ELEMENT.MULTIPLE_CHOICE.type){
     tempData.push({value:""});
   }
   else tempData.push({label:"",value:"",iri:""});
   this.setState({data: tempData});
  }

  removeNode(node) {
    const {data} = this.state;

    this.setState({data: Object.assign({}, data)});
  }


  updateData(node, property, value) {
    node[property] = value;
    let data = Object.assign({}, this.state.data);
    this.setState({data: data});
  }

  updateOntologyRegister(key, property,value) {
    const {dataDetails}  = this.state;
    let data = Object.assign({}, dataDetails);
    if(!(data[key]!=null)){
      data[key]={};
    }
    data[key][property] = value;
    this.setState({dataDetails: data});
  }

  renderIRIOptions(row) {
    if (row != null && row['iri'] != null)
      return ChoiceOptionsEditor.generateLink(row['iri']);
    else return " ";
  }
  renderClassIRI(row) {
    const {dataDetails} =this.state;
    if (row != null && row['value'] != null &&  dataDetails[row['value']]!=null && dataDetails[row['value']]['ontologyClassIri']!=null)
      return ChoiceOptionsEditor.generateLink( dataDetails[row['value']]['ontologyClassIri']);
    else return " ";
  }

  getIRIEditor(props){
    const {dataDetails} =this.state;
    const isValueSet = props['rowData']['value'] != null  && dataDetails[props['rowData']['value']]!=null;
    return <InputText type="text"
                      value={isValueSet?dataDetails[props['rowData']['value']]['ontologyClassIri']:null}
                      onChange={(e) => this.updateOntologyRegister(props['rowData']['value'],'ontologyClassIri',e.target.value)}
                      key={props.rowData['_id']+props.field}
    />;
  }

  getOntologyIdEditor(props) {
    const {dataDetails} =this.state;
    const {ontologyList} =this.props;
    const isValueSet = props['rowData']['value'] != null  && dataDetails[props['rowData']['value']]!=null;

    return  <Dropdown
      value={isValueSet?dataDetails[props['rowData']['value']]['ontologyId']:null}
      options={ontologyList!=null ? ontologyList.map(el => {
        return {label: el.name, value: el._id}
      }) : []}
      onChange={el => this.updateOntologyRegister(props['rowData']['value'],'ontologyId',el.value)}
    />;
  }
  renderTrashTemplate (row,col){
    return <div>  <Button icon="fa fa-trash"  onClick={()=>this.handleTrashButton(row,col)}/>   </div>;
  };
  renderOntologyId (row){
    const {ontologyList} = this.props;
    const{dataDetails} = this.state;
    if (ontologyList!=null && row != null && row['value'] != null &&  dataDetails[row['value']]!=null && dataDetails[row['value']]['ontologyId']!=null){
      const ont = ontologyList.find((el) => {
        return el._id === dataDetails[row['value']]['ontologyId'];
      });
      if(ont!=null) return ont.name;
      else return "";
    }
    return "";
  };

  handleTrashButton(row,col){
    const {data,dataDetails} = this.state;
    const temp = data.slice(0);
    temp.splice(col.rowIndex,1);
    const details = Object.assign({},dataDetails);
    delete details[row['value']];
    this.setState({data:temp,dataDetails:details})
  }

  renderSelectColumnTemplate  (row) {
    return <div>  <Button icon="fa fa-edit" title={"Set ontology data"}
                          onClick={()=>this.props.findInOntology(row['value'],this.updateOntologyRegister)}/>   </div>;
  };

  onRowReorder(e){
    this.setState({data: e.value})
  }

  getEditor(props){
    const{data} = this.state;

    const onEditorValueChange = (props, value) => {
      let updatedData = data.slice(0);//Important!!!
      updatedData[props.rowIndex][props.field] = value;
      this.setState({data:updatedData})
    };

    return <InputText type="text"
                      value={props.rowData[props.field]}
                      onChange={(e) => onEditorValueChange(props, e.target.value)}
                      key={props.rowData['_id']+props.field}
    />;
  }

  render() {
    const{data,dataDetails}=this.state;
    const{hide,updateOptions,options,findInOntology,type}=this.props;

    return (
      <div className={"ui-g-12"} >
        <Button label={"Save"} icon={"fa fa-save"} onClick={()=>{
          if (type===CUSTOM_ELEMENT.MULTIPLE_CHOICE.type){
            updateOptions(data.map(el=>{return el.value}),dataDetails) //convert to simple array of sstrings
          }
          else updateOptions(data,dataDetails)}
        }/>
        <Button label={"Close"} icon={"fa fa-close"} onClick={()=>{hide()}}/>

        <div>
          {data!=null && dataDetails!=null &&
          <DataTable value={data}
                     emptyMessage={"No options found"}
                     rows={15}
                     paginator={true}
                     onRowReorder={this.onRowReorder}
          >
            <Column rowReorder={true} style={{width:"3em"}}  />
            {type!==CUSTOM_ELEMENT.MULTIPLE_CHOICE.type &&
            <Column field="label" header="Label" editor={this.getEditor}/>}
            <Column field="value" header="Value" editor={this.getEditor}/>
            {type!==CUSTOM_ELEMENT.MULTIPLE_CHOICE.type &&
            <Column field="iri" header="IRI" body={this.renderIRIOptions} editor={this.getEditor}/>}
            <Column header="Class IRI" body={this.renderClassIRI} editor={this.getIRIEditor}/>
            <Column body={this.renderSelectColumnTemplate} style={{width: "3em"}}/>
            <Column header="Ontology Id" editor={this.getOntologyIdEditor} body={this.renderOntologyId}/>
            <Column body={this.renderTrashTemplate} style={{width: "3em"}}/>
          </DataTable>
          }
          <Button icon={"fa fa-plus"} onClick={this.addNode}/>
        </div>

      </div>
    )
  }
}

export default ChoiceOptionsEditor;

ChoiceOptionsEditor.propTypes = {
  options:PropTypes.object.isRequired,
  optionsDetails:PropTypes.object.isRequired,
  updateOptions:PropTypes.func.isRequired,
  hide:PropTypes.func.isRequired,
  findInOntology:PropTypes.func.isRequired,
  type:PropTypes.string.isRequired,
  ontologyList:PropTypes.array.isRequired
};
