import * as ErrorMessages from './errorMessages.js';
import validator from 'validator';

export const required = (text) => {
    if (text) {
        return null;
    } else {
        return ErrorMessages.isRequired;
    }
};

export const mustMatch = (field, fieldName) => {
    return (text, state) => {
        return state[field] === text ? null : ErrorMessages.mustMatch(fieldName);
    };
};

export const minLength = (length) => {
    return (text) => {
        return text.length >= length ? null : ErrorMessages.minLength(length);
    };
};

export const isStrongPassword = (text) => {
    return validator.isStrongPassword(text, {minSymbols: 0}) ? null : ErrorMessages.isStrongPassword();
};

export const isEmail = (text) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text).toLowerCase())?null:ErrorMessages.isEmail;
};


export const isChecked = (value) => {
    if (value) {
        return null;
    } else {
        return ErrorMessages.isChecked;
    }
};