import React from 'react'
import PropTypes from 'prop-types'
import {renderSortIcon, sortByProperty} from "../../helpers/comparators";
import {Link} from "react-router-dom";
import {SKILL_URL_MOCK_ROCHE_EN, SKILL_URL_MOCK_ROCHE_FR, SKILL_URL_MOCK_ROCHE_FR_2} from "../action/actionType";
import {withTranslation} from "react-i18next";

class Skills extends React.Component {

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
    }

    componentWillMount() {
        const {getSkills} = this.props;
        getSkills();
    }

    render() {

        const {skills, onSorterChange,sorter,recreateTrainingWorkflowInLP,t} = this.props;
        let displaySingleColumn = (s)=>{
            if (s.contribution > 0){
                return <React.Fragment>
                <div className="spineColStyle col3">{s.expertise}</div>
                    <div className="spineColStyle col3">{s.contribution}</div>
                </React.Fragment>;
            }
            else if (s.contribution ===-2){
                return <React.Fragment>
                    <div className="spineColStyle" style={{width:"66%",textAlign:"center",backgroundColor:'powderblue'}}>
                        {t("dashboard.tables.mySkills.property.contribution.value.coming")}
                    </div>
                </React.Fragment>;
            }
            else return(
            <div className="spineColStyle" style={{width:"66%",textAlign:"center",backgroundColor:'powderblue',textDecoration:'underline'}}>
                <Link to={s.link}
                      onClick = { () => {
                          recreateTrainingWorkflowInLP(...s.workflowParams);
                      }}
                >
                   {t("dashboard.tables.mySkills.property.contribution.value.tryIt")}
                </Link>
            </div>);
        };

        const skillNodes = skills.map((s) =>
            <div className='spineRowStyle' key={s.uuid}>
                <div className="spineColStyle col3" onClick={this.handleClick}>{s.name}</div>
                {displaySingleColumn(s)}
            </div>);
        return (
            <div style={{width: '96%', margin: '2%'}}>
                <span className="reg-headtitle">{t("dashboard.tables.mySkills.header")}</span>
                <div className="spineTableStyle">
                    <div className='spineRowHeaderStyle'>
                        <div className="spineColStyle col3" onClick={()=>{sortByProperty("NAME",sorter,onSorterChange); }}>{t("dashboard.tables.mySkills.property.skill.label")} {renderSortIcon('NAME',sorter)}</div>
                        <div className="spineColStyle col3" onClick={()=>{sortByProperty("EXPERTISE",sorter,onSorterChange); }}>{t("dashboard.tables.mySkills.property.expertise.label")}  {renderSortIcon('EXPERTISE',sorter)}</div>
                        <div className="spineColStyle col3" onClick={()=>{sortByProperty("CONTRIBUTION",sorter,onSorterChange); }}>{t("dashboard.tables.mySkills.property.contribution.label")} {renderSortIcon('CONTRIBUTION',sorter)}</div>
                    </div>
                    <div className="spineTableScrollableContent">
                        <div className="spineTableBody">
                            {skillNodes}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default withTranslation()(Skills);
Skills.propTypes = {
    getSkills: PropTypes.func.isRequired,
    skills: PropTypes.array.isRequired,
    onFilterChange:PropTypes.func,
    onSorterChange:PropTypes.func,
    recreateTrainingWorkflowInLP:PropTypes.func.isRequired
};