import {
    CLEAR_INTERACTIVE_PLOT,
    UPDATE_PLOT_DATA, UPDATE_PLOT_DATA_STATE, UPDATE_PLOT_SETTINGS, UPDATE_RAW_PLOT_DATA
} from "../action/actionType";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";



const initialState = {
    plotSettings:{},
    plotData:[],    // images requested or loaded
    rawData:{},
    plotDataState:""
};

export const interactivePlotReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_INTERACTIVE_PLOT:{
            console.log('Update plot settings:', action.plotSettings);
            return Object.assign({}, state, initialState);
        }
        case UPDATE_PLOT_SETTINGS:{
            console.log('Update plot settings:', action.plotSettings);
            return Object.assign({}, state, {
                plotSettings:Object.assign({},action.plotSettings)
            });
        }
        case UPDATE_PLOT_DATA:{
            console.log('Update plot data:', action.plotData);
            return Object.assign({}, state, {
                plotData:action.plotData.slice(0)
            });
        }
        case UPDATE_RAW_PLOT_DATA:{
            console.log('Update raw data:', action.rawData);
            return Object.assign({}, state, {
                rawData:Object.assign({},action.rawData)
            });
        }
        case UPDATE_PLOT_DATA_STATE:{
            console.log('Update state:', action.state);
            return Object.assign({}, state, {
                plotDataState:action.state
            });
        }
        default:
            return state;
    }
};

