import {createSelector} from 'reselect';
import {getActiveViewerId, getCurrentToolConfiguration} from "./ManualToolSelectors";
import {getViewersState} from "./ViewersSelectors";
import {PREVENT_FROM_ADDING_PIN, PREVENT_FROM_CHANGING_VOXEL_VALUE} from "../../../Constants";

const  getScenes = state => state.visu.scenes.scenes;

/**
 * Get painter object for layer being active from active viewer.
 * This is a MEMOIZED selector that recalculates painter when the value of
 * activeViewer or scene or sceneLayerIndex changes, but not when changes occur in other (unrelated) parts of the state tree.
 * @param state
 * @return {null}
 */
export const getActivePainterSelector =  createSelector(  [getActiveViewerId,getScenes,getViewersState],
    (activeViewerId,scenes,viewersState) => {
    let painter = null;
    try {
        if (activeViewerId != null) {
            const scene = scenes[viewersState[activeViewerId]['sceneId']];
            const activeLayerIndex = scene['activeLayer'];
            painter = scene['painters'][activeLayerIndex];
        }
    } catch (err) {
    }
    return painter;
});

/**
 * Get value of selected color for active layer in active viewer.
 * This is a MEMOIZED selector that recalculates painter when the value of
 * activeViewer or scene or sceneLayerIndex changes, but not when changes occur in other (unrelated) parts of the state tree.
 * @param state
 * @return {null}
 */
export const getActiveLayerValueSelector =  createSelector(  [getActiveViewerId,getScenes,getViewersState],
    (activeViewerId,scenes,viewersState) => {
        let activeValue = 0;
        try {
            if (activeViewerId != null) {
                const scene = scenes[viewersState[activeViewerId]['sceneId']];
                const activeLayerIndex = scene['activeLayer'];
                activeValue = scene['activeColor'][activeLayerIndex];
            }
        } catch (err) {
        }
        return activeValue;
    });

/**
 * Get value of selected color for active layer in active viewer.
 * This is a MEMOIZED selector that recalculates painter when the value of
 * activeViewer or scene or sceneLayerIndex changes, but not when changes occur in other (unrelated) parts of the state tree.
 * @param state
 * @return {null}
 */
export const getActiveLayerColormapSelector =  createSelector(  [getActiveViewerId,getScenes,getViewersState],
    (activeViewerId,scenes,viewersState) => {
        let colormap = [{label:"No data",value:"0",color:"#fff"}];
        try {
            if (activeViewerId != null) {
                const scene = scenes[viewersState[activeViewerId]['sceneId']];
                const activeLayerIndex = scene['activeLayer'];
                colormap = scene['colorMaps'][activeLayerIndex];
            }
        } catch (err) {
        }
        return colormap;
    });



/**
 * Get mask rules about adding pins, if there any.
 * This is a MEMOIZED selector that provides
 * @param state
 * @return [Array of objects with information about: mask data, type of values range (scalar,vector, function) and value/values eg. {overlayId:"cf90e53985eb",type:"scalar",value:1}]
 */
export const getMasksPreventingFromAddingPin =  createSelector(  [getActiveViewerId,getScenes,getViewersState,getCurrentToolConfiguration],
  (activeViewerId,scenes,viewersState,currentToolConfiguration) => {
    let masksPreventingFromAddingPin = [];
    try {
      if (activeViewerId != null) {
        const scene = scenes[viewersState[activeViewerId]['sceneId']];
        const masks = scene['masks'];
        if (masks!=null && Array.isArray(masks)) {
          masks.forEach((el,index) => {
            if (el) {
              if (currentToolConfiguration['rois']!=null && currentToolConfiguration['rois']['masks']!=null) {
                let maskConfiguration = currentToolConfiguration['rois']['masks'][el];
                if (maskConfiguration['interactions'][PREVENT_FROM_ADDING_PIN] != null) {
                  const maskRules  = Object.assign({},maskConfiguration['interactions'][PREVENT_FROM_ADDING_PIN]);
                  maskRules['overlayId'] = scene['layers'][index];
                  masksPreventingFromAddingPin.push(maskRules);
                }
              }
            }
          })
        }

      }
    } catch (err) {
    }
    return masksPreventingFromAddingPin;
  });


/**
 * Get mask rules about drawing.
 * This is a MEMOIZED selector that provides
 * @param state
 * @return [Array of objects with information about: mask data, type of values range (scalar,vector, function) and value/values eg. {overlayId:"cf90e53985eb",type:"scalar",value:1}]
 */
export const getMasksPreventingFromDrawing =  createSelector(  [getActiveViewerId,getScenes,getViewersState,getCurrentToolConfiguration],
  (activeViewerId,scenes,viewersState,currentToolConfiguration) => {
    let masksPreventingFromDrawing = [];
    try {
      if (activeViewerId != null) {
        const scene = scenes[viewersState[activeViewerId]['sceneId']];
        const masks = scene['masks'];
        if (masks!=null && Array.isArray(masks)) {
          masks.forEach((el,index) => {
            if (el) {
              if (currentToolConfiguration['rois']!=null && currentToolConfiguration['rois']['masks']!=null) {
                let maskConfiguration = currentToolConfiguration['rois']['masks'][el];
                if (maskConfiguration['interactions'][PREVENT_FROM_CHANGING_VOXEL_VALUE] != null) {
                  const maskRules  = Object.assign({},maskConfiguration['interactions'][PREVENT_FROM_CHANGING_VOXEL_VALUE]);
                  maskRules['overlayId'] = scene['layers'][index];
                  masksPreventingFromDrawing.push(maskRules);
                }
              }
            }
          })
        }
      }
    } catch (err) {
    }
    return masksPreventingFromDrawing;
  });