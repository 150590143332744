import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {DataTable} from "primereact/components/datatable/DataTable";
import {
  ANNOTATION_PROPERTY_NAME__ID,
  REQUEST_STATUS_SUCCESS
} from "../../../../Constants";
import {Column} from "primereact/components/column/Column";
import {StatusColumn} from "./StatusColumn";


/**
 * Form for creating/editing annotation column.
 */
class AnnotationQuestionSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      expandedRows: null,
      selectedStatus: "ALL"
    };
    [ "updateSelected", "onRowSelect", "renderOntologies", "filterOntologies","checkIfSelected",
      "rowExpansionTemplate",  "onRowUnselect"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    if (this.props.getQuestionsList != null)
      this.props.getQuestionsList();
    if (this.props.getOntologyList != null)
      this.props.getOntologyList();
    if (this.props.questionListState===REQUEST_STATUS_SUCCESS) {
      this.checkIfSelected();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {questionList,questionListState} = this.props;
    if ((prevProps.questionList !==  questionList) && questionListState === REQUEST_STATUS_SUCCESS){
      this.checkIfSelected();
    }
  }

  updateSelected(column) {
    this.setState({column: Object.assign({}, column.definition), selectionEditorVisible: false});
  }

  /**
   * Checks if value assigned in constants can be found in list of all annotations - required to
   * initialize selectedRow for data table.
   */
  checkIfSelected(){
    const {questionList,questionListState,selected} = this.props;
    const isSelected = questionList.find((el) => {
      return el[ANNOTATION_PROPERTY_NAME__ID] === selected
    });
    if (isSelected!=null){
      this.setState({selectedRow:isSelected});
    }
  }

  onRowSelect(e) {
    this.setState({
      selectedRow: Object.assign({}, e.data)
    });
  }

  rowExpansionTemplate(row) {
    return (
      <div>
        <h5>{row.questionText} > Properties:</h5>
        <DataTable value={row.questionProperties} emptyMessage={"No properties found"}>
          <Column field="answerFormat" header="Answer format"/>
          <Column field="isInteger" header="Integer"/>
          <Column field="lowerLimit" header="Lower Limit"/>
          <Column field="upperLimit" header="Upper Limit"/>
        </DataTable>
      </div>
    );
  }




  onRowUnselect() {
    this.setState({selectedRow: null})
  }



  renderOntologies(row) {
    const {ontologyList, ontologyListState} = this.props;
    if (ontologyListState === REQUEST_STATUS_SUCCESS && row.ontologies!=null && Array.isArray(row.ontologies)) {
      return row.ontologies.map((el) => {
        const ont = ontologyList.find((o) => {
          return o._id === el
        });
        if (ont != null)
          return ont.name;
        else return "";
      }).reduce((prev, cur) => {
        return prev.concat(cur, ", ")
      }, "")
    }
    return "";
  }

  filterOntologies(value, filter) {
    const {ontologyList, ontologyListState} = this.props;
    if (filter === undefined || filter === null || (typeof filter !== 'string') || ontologyListState !== REQUEST_STATUS_SUCCESS || value === undefined || value === null) {
      return true;
    }
    let result = false;
    value.forEach((el) => {
      const ont = ontologyList.find((o) => {
        return o._id === el
      });
      result |= ont.name.toLowerCase().includes(filter.toLowerCase());
    });
    return result;
  }


  render() {
    const {questionList, questionListState,onSave} = this.props;
    const {
      expandedRows, selectedRow,  selectedStatus} = this.state;

    return (
      <div className={"ui-g"}>
        <div className={"ui-g-12"}>
          {questionListState === REQUEST_STATUS_SUCCESS
          && <DataTable
            ref={(el) => this.dt = el}
            sortField="status"
            sortOrder={-1}
            expandedRows={expandedRows}
            value={questionList}
            rows={10}
            paginator={true}
            onRowToggle={(e) => this.setState({expandedRows: e.data})}
            rowExpansionTemplate={this.rowExpansionTemplate}
            selection={selectedRow}
            onRowSelect={this.onRowSelect}
            selectionMode="single"
            onSelectionChange={() => {
            }}>
            {StatusColumn(selectedStatus, (value) => {
              this.setState({selectedStatus: value});
              this.dt.filter(value, 'status', 'custom')
            })}
            <Column field="_id" header={"Id"} sortable={true} filter={true} filterMatchMode={"contains"}/>
            <Column field="questionText" header={"Text"} sortable={true} filter={true} filterMatchMode={"contains"}/>
            <Column field="ontologies" header={"Ontologies"} body={this.renderOntologies} filter={true}
                    filterFunction={this.filterOntologies} filterMatchMode={"custom"}/>
            <Column field="questionProperties" header={"Properties"} expander={true}/>
          </DataTable>}
          <div className={"ui-g-12"}>
            <Button label={"Set selected"} disabled={!(selectedRow!=null)} onClick={()=>onSave(selectedRow)}/>
            <Button label={"Collapse All Expanded"} disabled={!(expandedRows!=null)} onClick={()=>this.setState({expandedRows:null})}/>
          </div>
        </div>
      </div>

    )
  }

}

export default AnnotationQuestionSelection;

AnnotationQuestionSelection.propTypes = {
  questionList: PropTypes.array.isRequired,
  questionListState: PropTypes.string.isRequired,
  ontologyList: PropTypes.array.isRequired,
  ontologyListState: PropTypes.string.isRequired,
  getQuestionsList: PropTypes.func.isRequired,
  getOntologyList: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  taskListState: PropTypes.string.isRequired,
  allColumns: PropTypes.object.isRequired,
  allColumnsState: PropTypes.string.isRequired,
  messageQueue: PropTypes.object.isRequired,
  saveQuestionState: PropTypes.string.isRequired,
  saveQuestionError:PropTypes.object.isRequired,
  saveQuestionResponse:PropTypes.object.isRequired,
  saveQuestion:PropTypes.func.isRequired,
  clearSaveQuestion:PropTypes.func.isRequired,
  selected:PropTypes.string.isRequired
};
