
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {CLEAR_ROI, ROI_GET_FAIL, ROI_GET_REQUESTED, ROI_GET_SUCCESS} from "../action/actionType";


// 
/**ROI documents registry using Map structure:
 * "id":{ roi:{}, state:"",error:{}}
 * 
 * 
 * @param state
 * @param action
 * @return {{}}
 */
export const roiReducer = (state = {}, action) => {
    switch (action.type) {
    case CLEAR_ROI:{
      return {};
    }
    case ROI_GET_SUCCESS:{
      console.log("Updated ROI:",action.id,action.roi);
      const requested = {};
      requested[action.id]={};
      requested[action.id]['state'] = REQUEST_STATUS_SUCCESS;
      requested[action.id]['roi'] = action.roi;
      return Object.assign({}, state, requested);
    }
    case ROI_GET_FAIL:{
      console.log(action.error);
      console.log(action.id);
      const requested = {};
      requested[action.id]={};
      requested[action.id]['state'] = REQUEST_STATUS_FAIL;
      requested[action.id]['error'] = action.error;
      return Object.assign({}, state, requested);
    }
    case ROI_GET_REQUESTED:{
      console.log(action.id);
      const requested = {};
      requested[action.id]={};
      requested[action.id]['state'] = REQUEST_STATUS_REQUESTED;
      return Object.assign({}, state, requested);
    }
    
    default:
      return state;
  }
};

