import {
    WORKFLOW_LIST_REQUEST,
    WORKFLOW_LIST_FAIL,
    WORKFLOW_LIST_SUCCESS,
    WORKFLOW_REQUEST,
    WORKFLOW_FAIL,
    WORKFLOW_SUCCESS,
    WORKFLOW_CLEAR,
    WORKFLOW_SAVE_REQUEST,
    WORKFLOW_SAVE_FAIL, WORKFLOW_SAVE_SUCCESS, SET_WORKFLOWS_VISIBILITY_FILTER
} from '../action/actionType'
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";

/**
 *
 * @param state - state of workflows, ListState:status for the list of optional workflows,
 * workflows: optional workflows, workflowVariable: measurement for which the list of matching workflows is created
 * @param action
 * @returns {*}
 */
export const workflowListReducer = (state = {
    visibilityFilter:'',
    sorter:'SORT_BY_STATUS_ASC',
    workflowListState: '',
    workflows: [],
    workflowVariable:{},
    workflowState:'',
    workflow:{},
    workflowSaveState:'',
    workflowSaveError:{}
    }, action) => {
    // console.log("workflowListreducer", action);
    switch (action.type) {
        case WORKFLOW_LIST_REQUEST:
            return Object.assign({},state, {
                workflowListState: REQUEST_STATUS_REQUESTED,
                workflowVariable:action.variable
            });
        case WORKFLOW_LIST_FAIL:
            return Object.assign({},state, {
                workflowListState: REQUEST_STATUS_FAIL,
                workflowVariable:action.variable
            });
        case WORKFLOW_LIST_SUCCESS:
            return Object.assign({}, state, {
                workflowListState: REQUEST_STATUS_SUCCESS,
                workflows: action.workflows,
                workflowVariable:action.variable
            });
        case SET_WORKFLOWS_VISIBILITY_FILTER:
            return Object.assign({}, state, {
                visibilityFilter: action.filter,
            });
        case WORKFLOW_REQUEST:
            return Object.assign({},state, {
                workflowState: REQUEST_STATUS_REQUESTED
            });
        case WORKFLOW_FAIL:
            return Object.assign({},state, {
                workflowState: REQUEST_STATUS_FAIL
            });
        case WORKFLOW_SUCCESS:
            return Object.assign({}, state, {
                workflowState: REQUEST_STATUS_SUCCESS,
                workflow: action.workflow
            });
        case WORKFLOW_SAVE_REQUEST:
            return Object.assign({},state, {
                workflowSaveState: REQUEST_STATUS_REQUESTED
            });
        case WORKFLOW_SAVE_FAIL:
            return Object.assign({},state, {
                workflowSaveState: REQUEST_STATUS_FAIL,
                workflowSaveError: action.err
            });
        case WORKFLOW_SAVE_SUCCESS:
            return Object.assign({}, state, {
                workflowSaveState: REQUEST_STATUS_SUCCESS
            });
        case WORKFLOW_CLEAR:
            return Object.assign({}, state, {
                workflowState: "",
                workflow: {}
            });
        default:
            return state;
    }
};