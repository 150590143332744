import {connect} from "react-redux";
import React from "react";
import {clearSaveOntology, getOntologyListActionCreator, saveOntology} from "../action/OntologyAction";
import AnnotationOntologyBuilder from "../component/annotationTool/AnnotationOntologyBuilder";


const mapStateToProps = (state,ownProps) => {
  return {
    messageQueue: state.messaging.msgQueue,
    ontologyList: state.visu.ontologies.ontologyList,
    ontologyListState: state.visu.ontologies.ontologyListState,
    saveOntologyState:state.visu.saveOntologies.saveOntologyState,
    saveOntologyError:state.visu.saveOntologies.saveOntologyError,
    saveOntologyResponse:state.visu.saveOntologies.saveOntologyResponse,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getOntologyList:()=>{
      dispatch(getOntologyListActionCreator())
    },
    saveOntology:(mode,payload)=>{
      dispatch(saveOntology(mode,payload))
    },
    clearSaveOntology:()=>{
      dispatch(clearSaveOntology())
    },
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);


export const ContainerAnnotationOntologyBuilder = containerCreator(AnnotationOntologyBuilder);