import {connect} from "react-redux";
import {withRouter} from 'react-router';
import TaskDashboard from "../components/TaskDashboard";
import {getSorter, getVisibilityFilter, getVisibleManualTasks} from "../selectors/ManualTasksSelectors";
import {
    getManualTask, getManualTaskList,  setSorter,
    setVisibilityFilter
} from "../action/ManualTaskAction";


const mapStateToProps = state => {
    return {
        manualTasks: getVisibleManualTasks(state),
        manualTasksState: state.activity.manualTasks.manualTasksState,
        filter: getVisibilityFilter(state),
        sorter: getSorter(state)
    }
};


const mapDispatchToProps = dispatch => {
    return {
        getManualTasks: (uuid,mfKey) => {
            dispatch(getManualTaskList(uuid,mfKey))
        },
        getManualTask: (uuid,mfKey,expId) => {
            dispatch(getManualTask(uuid,mfKey,expId))
        },
        onFilterChange: (id) => {
            dispatch(setVisibilityFilter(id))
        },
        onSorterChange: (id)=>{
            dispatch(setSorter(id))
        }
    }
};

const taskContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerTaskDashboard = taskContainerCreator(TaskDashboard);
