import React from "react";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import PropTypes from 'prop-types';
import {colorBoxStyle} from "../../../vtk/controlPanel/LabelmapStripBody";


/**
 * Dumb component for displaying dropdown with colors in it.
 * @use BrushToolbar, FillingToolbar
 * @returns {*}
 */
export const ColorDropdown = ({options,value,onChange,disabled}) => {

    const itemTemplate = (option)=>{
        if(!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="ui-helper-clearfix" >
                    <span style={{float:'left',margin:'.5em .25em 0 0'}}>{option.label}</span>
                    {option.iri!=null &&
                    <a target="_blank" href={option.iri}>
                        <i className="fa fa-external-link" title="External link to definition"/>
                    </a>
                    }
                    <div style={{display:'inline-block',width:'1em',height:'1em',backgroundColor:option.color, marginLeft: '2px', verticalAlign: 'bottom',float:'right'}}/>
                </div>
            );
        }};
    const getActiveColor = (value)=>{
        let color="#FFF";
        try {
            color = options.find(el => el.value === value).color
        }catch(err){
        }
        return color;
    };

return(
    <React.Fragment>
    <Dropdown value={value}
        disabled={disabled}
        style={{width:'10em'}}
        options={options}
        onChange={(e)=>onChange(e.value)}
        itemTemplate={itemTemplate}/>
        <div style={{...colorBoxStyle, backgroundColor:getActiveColor(value)}}/>
    </React.Fragment>)
};
ColorDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    onChange:PropTypes.func.isRequired,
    disabled:PropTypes.bool.isRequired
};