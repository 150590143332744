import React from "react";

function highlight(highlight,text) {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <span className="demoCardHighlightedText">{part}</span> : part)}</span>;
}



export const WorkflowCard = ({title,filter,footer, description, headerClassName }) => {

    return (
      <div className="ui-card ui-widget ui-widget-content ui-corner-all demoCard">
          <div className="ui-card-header">
              <div className={headerClassName}/>
          </div>
          <div className="ui-card-body">
              <div className="ui-card-title">
                  {filter.length>0?highlight(filter,title):title}
              </div>
              <div className="ui-card-content">
                  <div style={{height: "4em", overflowY: "auto"}}>
                      {filter.length>0?highlight(filter,description):description}
                  </div>
              </div>
              <div className="ui-card-footer">
                {footer.privacy==="PUBLIC" &&
                <i className="fa fa-unlock" title="Open to public"  style={{fontSize:"1.5em", marginRight:"1em"}}/>
                }
                {footer.privacy!=="PUBLIC" &&
                <i className="fa fa-lock" title="Private"  style={{fontSize:"1.5em", marginRight:"1em"}}/>
                }
                <i className="fa fa-code-fork" title="Version"  style={{fontSize:"1.5em"}}/>{footer.version}
                {footer.owner!=null&& <React.Fragment>
                <i className="fa fa-user-o" title="Owner"  style={{fontSize:"1.5em", marginLeft:"1em",marginRight:"0.2em"}}/>
                  {filter.length>0?highlight(filter,footer.owner):footer.owner}
                </React.Fragment>
                }
              </div>
          </div>
      </div>

    );
};