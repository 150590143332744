import {LEVEL_TRACING_TOOL} from "../../../Constants";
import vtkImageOutlineLevelTracing from "./ImageOutlineLevelTracing";
import ImageOutlinePoly, {OutlineMode} from "../processing/ImageOutlinePoly";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor/index";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper/index";
import {parseColor} from "../SpineVTKHelper";



export const LEVEL_FUNCTION_OPTIONS = {
  margin:25,
  options:[
  {
    label : "dark (neighbor - voxel <= 0)",
    value : "DARKER",
    levelFunction: (a,b)=>a<=b,
  },
  {
    label : "bright (neighbor - voxel >= 0)",
    value : "BRIGHTER",
    levelFunction: (a,b)=>a>=b,
  },
  {
    label : "dark (neighbor - voxel <= delta)",
    value : "DARKER_WITH_MARGIN",
    levelFunction: (a,b)=>a<=(b+LEVEL_FUNCTION_OPTIONS.margin),
  },
  {
    label : "bright (neighbor - voxel >= delta)",
    value : "BRIGHTER_WITH_MARGIN",
    levelFunction: (a,b)=>a>=(b-LEVEL_FUNCTION_OPTIONS.margin),
  }
]};


/**
 * All elements of Level tracing Effect widget are singletons, so references can be kept here.
 *
 *
 */
const tracer = vtkImageOutlineLevelTracing.newInstance();
const outline = ImageOutlinePoly.newInstance();
const polyActor = vtkActor.newInstance();
const polyMapper = vtkMapper.newInstance();

/**
 * Get actor representing levelTracing Effect or null if not present
 * @param renderer - inspected vtkRenderer
 * @return {*|number|bigint} -  object of polyActor representing levelTracing Effect or null if not present
 */
export const getLevelTracingActor  = (renderer) => renderer.getActors().find((el)=>el.get()[LEVEL_TRACING_TOOL] === true);


/**
 * Remove levelTracing Effect actor if exists
 * @param renderer
 */
export const removeLevelTracingActor  = (renderer) => {
  const actor = getLevelTracingActor(renderer);
  if (actor != null)
    renderer.removeActor(actor);
};


export const createLevelTracingActor = (renderer, layerIndex, labelValue, seed = [-1,-1,-1]) =>{
    // calculate region with Connected component Filter
  tracer.setInputData(renderer.getActors()[layerIndex].getMapper().getInputData());
  tracer.setSlicingMode(renderer.getActors()[layerIndex].getMapper().getSlicingMode());
  tracer.setSeedPoint(seed);
  tracer.setLabel(labelValue);

  // calculate lines
  outline.setSlice(renderer.getActors()[layerIndex].getMapper().getSlice());
  outline.setMode(OutlineMode.LINES);
  outline.setThickness(0);
  outline.setSlicingMode(renderer.getActors()[layerIndex].getMapper().getSlicingMode());
  outline.setInputData(tracer.getOutputData());
  outline.setLabel(labelValue);

  // add polydata actor to renderer
  polyMapper.setInputData(outline.getOutputData());
  polyMapper.update();
  polyActor.setMapper(polyMapper);
  polyActor.set({'levelTracingTool':true});
  renderer.addActor(polyActor);
};


/**
 *
 * @param renderer - renderer containing
 * @param options:{seed : {},slice: {}, label:{}, f:{}}
 *
 * }
 */

export const updateLevelTracingActor = (renderer, options) => {

  if (options.seed!=null && Array.isArray(options.seed)){
    tracer.setSeedPoint(options.seed);
    tracer.update();
  }
  if (options.slice!=null){
    outline.setSlice(options.slice);
  }
  // if (options.levelFunction!=null){
  //   tracer.setLevelFunction(options.levelFunction);
  // }
  outline.setInputData(tracer.getOutputData());
  polyMapper.setInputData(outline.getOutputData());
};

/**
 * Set up Level Function using name alias
 * @param levelFunctionName - name alias ("BRIGHTER","DARKER") under which function is registered
 */
export const setLevelFunction = (levelFunctionName) =>{
  const f = LEVEL_FUNCTION_OPTIONS.options.find(el=>el.value === levelFunctionName);
  if (f!=null){
    tracer.setLevelFunction(f.levelFunction);
    tracer.update();
  }
};

/**
 * Set up Level Function using name alias
 * @param levelFunctionName - name alias ("BRIGHTER","DARKER") under which function is registered
 */
export const setThreeD = (threeD) =>{
  tracer.setThreeD(threeD);
  tracer.update();
};

/**
 * Set up Level Function margin
 * @param margin - name alias ("BRIGHTER","DARKER") under which function is registered
 */
export const setLevelFunctionMargin = (margin) =>{
  LEVEL_FUNCTION_OPTIONS.margin = margin;
};

/**
 * Set up outline line width.
 * @param lineWidth -
 */
export const setOutlineThickness = (lineWidth) =>{
  outline.setThickness(lineWidth)

};


/**
 * Set up color for outline.
 * Color is provided as string "#fff", or "#ffffff".
 * There is no need to change label along with color of outline - label can be set when
 * selected region is copied to overlay volume.
 */
export const setTracingOutlineColor = (color) =>{

  if (color!=null){
    polyActor
      .getProperty()
      .setColor(...parseColor(color));
  }
};

/**
 * Get segmented data (only voxel values)
 */
export const getLevelTracingRegion = () =>{
  return outline.getInputData();
};