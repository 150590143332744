import React from "react";
import PropTypes from "prop-types";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {getNestedProp} from "../../helpers/expressions";
import BlinkingText from "../../root/component/BlinkingText";
import {Button} from "primereact/components/button/Button";


class AnnotationTabPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredAnnotationsCounter:0,
      filters:{},
      switchFilter:true
    }
  }

  componentDidMount() {
    const {getAnnotations} = this.props;
    if (getAnnotations!=null){
      getAnnotations();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.switchFilter !== this.state.switchFilter)
      this.setState({switchFilter:true});
  }

  renderAnnotationProperties(row){

    const val = getNestedProp(["annotationProperties","value"],row);
    if (val!=null && typeof val==='object'){
      return <a target={"_blank"} href={getNestedProp(["annotationProperties","value","ontologyClassIri"],row)}>{getNestedProp(["annotationProperties","value","label"],row)}</a>;
    }
    return val;
  }

  annotationFilter(value,filter){
    if (filter === undefined || filter === null || filter==="") {
      return true;
    }
    if (getNestedProp(["value"],value)!=null && typeof getNestedProp(["value"],value)==='object') {
      return getNestedProp(["value","label"],value)!=null && getNestedProp(["value","label"],value).toLowerCase().includes(filter.toLowerCase());
    }
    return false;
  }

  render() {
const {annotations,annotationsState} = this.props;

    return (
      <div >
        {annotationsState === REQUEST_STATUS_REQUESTED &&
        <div className="spinner-centered"> Loading data: <i className="fa fa-spinner fa-spin"/></div>
        }
        {annotationsState === REQUEST_STATUS_SUCCESS &&
          <React.Fragment>
        <div style={filterNumbersStyle}>Filtered Annotations: <BlinkingText value={this.state.filteredAnnotationsCounter}/>
          / {annotations.length} total</div>
        <Button label={"Clear filters"}
          onClick={()=>{
          this.setState(
        {
          filters:{},
          filteredAnnotationsCounter:annotations.length,
          switchFilter:false,
        });
          this.annoDt.setState({filters:{}});
          this.annoDt.processData();
        }}/>
        <DataTable
          filters={this.state.filters}
          onFilter={(e)=>{
            const data = this.annoDt.processData();
            if (data!==null )
              this.setState(
                {
                  filteredAnnotationsCounter : data.length,
                  filters: Object.assign(e.filters,{})
                })
          }}
          ref={(el) => {
            this.annoDt = el;
            if (el != null){
              const rowCounter = el.processData().length;
              if (rowCounter!=null && rowCounter!==this.state.filteredAnnotationsCounter)
                this.setState({filteredAnnotationsCounter : rowCounter})
            }
          }}
          value={annotations}>
          <Column header={"Question"} field={"question.questionText"} filterMatchMode={"contains"} filter={this.state.switchFilter}/>
          <Column header={"Is answered?"} field={"hasAnswer"} body={row=>row["hasAnswer"]===true?"YES":"NO"} filterMatchMode={"contains"} filter={this.state.switchFilter}/>
          <Column header={"Answer type"} field={"answerType"} filterMatchMode={"contains"} filter={this.state.switchFilter}/>
          <Column header={"Measurement"} field={"variable.name"} filterMatchMode={"contains"} filter={this.state.switchFilter}/>
          <Column header={"Measurement unit"} field={"variable.units"} />
          <Column header={"Statistical type"} field={"variable.statisticalType"} filterMatchMode={"contains"} filter={this.state.switchFilter}/>
          <Column header={"Value"} field={"annotationProperties"} body={this.renderAnnotationProperties} filterMatchMode={"custom"} filterFunction={this.annotationFilter} filter={this.state.switchFilter}/>
        </DataTable>
          </React.Fragment>
        }
      </div>
    )
  }
}

export default AnnotationTabPanel;

const filterNumbersStyle={
  fontSize:"larger",
  display:"inline-block",
  marginRight:"1em"
};

AnnotationTabPanel.propTypes = {
  getAnnotations:PropTypes.func.isRequired,
  annotationsState:PropTypes.string.isRequired,
  annotations:PropTypes.array.isRequired
};