import React from 'react'
import {withRouter} from "react-router-dom";
import Link from "react-router-dom/Link";


/**
 * "Dumb" component for starting creation new analysis.
 * How would you like to start your Experiment?
 * Comment souhaitez-vous commencer votre Expérience ?
 *
 */

const AnalysisChoiceView = ({item}) => {

  return (
    <div>
      <div className="expChoiceClose"/>
      <h1 align="center" style={{margin: '20px auto'}}>
        How would you like to start your Analysis?
      </h1>
      <div className="rectExpContainer">
        <Link to={`/project/${item.projectId}/experiment/${item.uuid}/analysis`}>
          <div className="rectExp">
            <div className="rectExpIntNew"/>
            <div className="rectExpInt">
              A brand new Analysis
            </div>
          </div>
        </Link>
        <div className="rectExp">
          <div className="rectExpIntTemp"/>
          <div className="rectExpInt">
            From template
          </div>
        </div>
        <div className="rectExp">
          <Link to={`/project/${item.projectId}/experiment/${item.uuid}/analysis/snapshot/true`}>
            <div className="rectExpIntDup"/>
            <div className="rectExpInt">
              <div>
                <span> From existing Analysis </span>
                <br/>
                <span style={{fontSize: 'small', marginLeft: '2em'}}>1 analysis available </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
  // }
};


export default withRouter(AnalysisChoiceView);

