import {connect} from "react-redux";
import {updateAnnotationData} from "../action/AnnotationAction";
import TaskView from "../component/TaskView";
import {withRouter} from "react-router";




const mapStateToProps = state => {
    return {
        imageData:state.visu.images.imageData,
        imageState:state.visu.images.imageState
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateAnnotationData:(data)=>{
            dispatch(updateAnnotationData(data))
        }
    }
};

export const ContainerTaskView =withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)(TaskView));
