import {
    CLEAR_VIEWERS_STATE,
    MANUAL_TOOL_CONFIGURATION_FAIL, MANUAL_TOOL_CONFIGURATION_REQUESTED,
    MANUAL_TOOL_CONFIGURATION_SUCCESS,
    UPDATE_IMAGE_OPTIONS, UPDATE_MANUAL_TASK_STATE,
    UPDATE_SINGLE_VIEWER_PROPERTY,
    UPDATE_SINGLE_VIEWER_STATE, UPDATE_VIEWERS_STATE
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


export const viewersReducer = (state = {
    viewersState:{},
    manualToolConfigurationState:"",//only for Demo
    manualToolConfiguration:{},
    taskState:{lowerBoundary:0,upperBoundary:0,segmentationOutput:null},
    demoCase:0  //remove after AnnotationDemo
}, action) => {
    switch (action.type) {
        case CLEAR_VIEWERS_STATE:
            return Object.assign({}, state, {
                viewersState:{}
            });
        case UPDATE_SINGLE_VIEWER_STATE:{
            let temp= Object.assign({},state.viewersState);
            temp[action.id]=Object.assign({},action.viewer);
            return Object.assign({}, state, {
                viewersState:temp
            });
        }
        case UPDATE_VIEWERS_STATE:{
            let result = {};
            Object.keys(action.viewers).forEach((el)=>
                result[el] = Object.assign({},action.viewers[el]));
            return Object.assign({}, state, {
                viewersState:result
            });
        }
        case UPDATE_SINGLE_VIEWER_PROPERTY:{
            let temp= Object.assign({},state.viewersState);
            let tempRow = Object.assign({},temp[action.id]);
            tempRow[action.property]=action.value;
            temp[action.id]=tempRow;
            return Object.assign({}, state, {
                viewersState:temp
            });
        }
        case UPDATE_IMAGE_OPTIONS:{
            return Object.assign({}, state, {
                imageOptions:action.options.slice(0)
            });
        }

        // Added methods from ManualAction
        case MANUAL_TOOL_CONFIGURATION_REQUESTED:{
            return Object.assign({}, state, {
                manualToolConfigurationState:REQUEST_STATUS_REQUESTED
            });
        }
        case MANUAL_TOOL_CONFIGURATION_SUCCESS:{
            return Object.assign({}, state, {
                manualToolConfigurationState:REQUEST_STATUS_SUCCESS,
                manualToolConfiguration:action.configuration,
            });
        }
        case MANUAL_TOOL_CONFIGURATION_FAIL:{
            return Object.assign({}, state, {
                manualToolConfigurationState:REQUEST_STATUS_FAIL,
                manualToolConfiguration:action.err,
            });
        }

        //THis is MOCK for presentation purpose
        case UPDATE_MANUAL_TASK_STATE:{
            let task = {};
            task['lowerBoundary'] = action.task.lowerBoundary;
            task['upperBoundary'] = action.task.upperBoundary;
            task['painter'] = action.task.painter;
            console.log('UPDATE_MANUAL_TASK_STATE',task);
            return Object.assign({}, state, {
                taskState:task
            });
        }
        //THis is MOCK for presentation purpose
        case "UPDATE_DEMO_CASE":{
            return Object.assign({}, state, {
                demoCase:action.demoCase
            });
        }

        default:
            return state;
    }
};

