import React from "react";
import {TreeSelect} from "antd";
import PropTypes from "prop-types";
const TreeNode = TreeSelect.TreeNode;
/**
 * This "dumb" component uses ant Design TreeSelect and TreeNode stateful components.
 * Beware!
 * In simple use case TreeSelect component can be fed with treeData property,
 * however in this case changes in props (change in data structure) are not detected.
 * Therefore, to see update in tree values, TreeNode components need to be used
 */
export const HierarchicalDropdown = ({parentNode,updateValue,value,onFocus})=>{

        let renderNode = (parent,parentKey)=> {
         return parent.children.map((el, index) => {
             if (el.children )
                return (<TreeNode value={el.value} title={el.title} key={parentKey + "_" + index}>
                        {renderNode(el,parentKey + "_" + index)}
                    </TreeNode>
                );
             else return (<TreeNode value={el.value} title={el.title} key={parentKey + "_" + index}/>)
            });
        };

        return (
            <TreeSelect
                showSearch={true}
                style={{ width: "300" }}
                value={value ? value : "Please select" }
                dropdownStyle={{ maxHeight: "400", overflow: 'auto' }}
                placeholder={"Please select"}
                allowClear={true}
                // treeData={parentNode.children}
                treeDefaultExpandAll={true}
                onChange={updateValue}
                onFocus={onFocus}
            >
                {renderNode(parentNode,"0")}
            </TreeSelect>
        )
    };



HierarchicalDropdown.propTypes = {
    parentNode: PropTypes.object.isRequired,
    updateValue:PropTypes.func
    // value:PropTypes.
};