import React from 'react';
import {TabPanel, TabView} from "primereact/components/tabview/TabView";


// TODO This should be moved to translation jsons (as separate file and namespace)
const messages = {
  "Acquisition":"An image retrieved from an external source (MRI, CT, PET etc.) for further processing. It’s always the foundation step in the workflow since no process is available before obtaining an image.",
  "Agreement analyses": <span>A common group of data analysis methods in reproductive biology investigating whether or not the measurement of a variable by two different methods, or by two different operators using the same method, or by one operator repeating the measurement at two different times, produces essentially the same result. See<a href={"https://www.sciencedirect.com/science/article/pii/S0093691X10000233"} target={"_blank"} > (Watson & Petrie 2010)</a> </span>,
  "Bland-Altman plot":"A method of data plotting used in analyzing the agreement between two different assays. It is identical to a Tukey mean-difference plot.",
  "Case":"An outcome for an individual observation of a patient. An observation can involve the perception and recording of data via the use of scientific instruments, like MRI, CT and regular clinical interview. ",
  "Contribution":"A complete output to the task execution including rater ",
  "Data Point (observation)":"A set of one or more measurements on a single member of the unit of observation. In pattern recognition, it is a realization of the feature vector or training set sample (instance). ",
  "Distribution analyses":"A common group of data analysis methods to identify distribution of data and describe data location and dispersion. A distribution analysis can include descriptive statistics, graphs, density estimates, and cumulative distribution function estimate.",
  "Explicit ROI":"Samples within a data set identified for a particular purpose expressed as set of voxels (volume of interest).",
  "External Task":"Task executed in external environment to SPINE.",
  "Implicit ROI":"Annotations referring to samples within a data set identified for a particular purpose. Implicit ROI are not a subset of original data set but represent elements of data set by pointing space locations (pin markers), expressing morphometrical features (lines visualizing diameter), geometrical shapes delimiting size of discern objects etc. ",
  "Measurement":"The quantification of attributes of an object or event, which can be used to compare with other objects or events. The measurement of a property may be categorized by the following criteria: level, magnitude, unit, and uncertainty." +
    "The level is a taxonomy for the methodological character of a comparison (eg. ratio, difference, or ordinal preference). The magnitude is the numerical value of the characterization (eg. real number, complex, vector, logarithmic scale).  " +
    "A unit assigns a mathematical weighting factor to the magnitude that is derived as a ratio to the property of an artifact used as standard or a natural physical quantity." +
    "An uncertainty represents the random and systemic errors of the measurement procedure; it indicates a confidence level in the measurement.",
  "Measurement value":"An outvcome of measurement process.",
  "Rater (Contributor)":"a person or program to perform a given processing task.",
  "Region Of Interest (ROI)":"Samples within a data set identified for a particular purpose. A ROI is a form of Annotation, often associated with categorical or quantitative information (e.g., measurements like volume or mean intensity), expressed as text or in a structured form.",
  "Subject":"A person or animal subjected to treatment, observation, or experiment. For example, it can be an individual who participates in a clinical trial, either as recipient of the investigational product(s) or as a control.",
  "Task":"A unit of work representing one of the steps in a process. Task is to be accomplished by a device or process and therefore can be in states: incoming (to be done) or completed",
  "Visit":"Visit",
  "Workflow":"A workflow consists of an orchestrated and repeatable pattern of activity, " +
    "enabled by the systematic organization of resources into processes that transform materials, provide services, or process information"

};

class SAMHelpPanel extends React.Component {



  render(){
    return (
    <TabView>
      <TabPanel header={"Glossary"} contentStyle={{height:"500px",width:"1185px",overflowY:"scroll"}}>
        <div >
          <dl>
          {Object.keys(messages).map(el=>(
            <React.Fragment>
            <dt style={{fontWeight:"600"}}>{el}</dt>
            <dd>{messages[el]}</dd>
            </React.Fragment>
          ))}
          </dl>
        </div>
      </TabPanel>
      <TabPanel header={"Case && Contributions example 1"}>
        <img src={"/dist/jsonDocs/data/images/CC1.png"} />
      </TabPanel>
      <TabPanel header={"Case && Contributions example 2"}>
        <img src={"/dist/jsonDocs/data/images/CC2.png"} />
      </TabPanel>
      <TabPanel header={"Observations example"}>
        <img src={"/dist/jsonDocs/data/images/Observations.png"} />
      </TabPanel>
    </TabView>
    )}
}

export default SAMHelpPanel;

SAMHelpPanel.propTypes = {

};