import React from "react";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

/**
 * Component to show detailed information of an event and redirect to enroll to an event or go to the instructions and skills if already enrolled (or show a custom button for futher actions)
 */
class EventDetailsPage extends React.Component {
    render() {
        const { t,event, eventButton } = this.props;
        if(event==null){
            return (
                <div className="spinner-centered">Loading <i className="fa fa-spinner fa-spin"/></div>
            );
        }

        return (
            <div style={{
                marginTop: "2em",
                marginLeft: "3em",
                marginRight: "3em"
                }}>
                <div className="ui-g-4" style={{textAlign: "center"}}>
                 <div className="ui-g-12">
                    {event.externalUrl?
                        <a href={event.externalUrl} target="_blank">
                            <img src={event.imageUrl || '../dist/icon-completed.png'} style={{width:"20em", height: "20em"}}/>
                        </a>
                        : <img src={event.imageUrl || '../dist/icon-completed.png'} style={{width:"20em", height: "20em"}}/>}
                 </div>
                 {event.sponsors && event.sponsors.length > 0 && <div className="ui-g-12">
                    <h3>{t("events.sponsors.title")}</h3>
                    {event.sponsors.map(sponsor=>{
                        return  <React.Fragment>
                            {sponsor.externalUrl?
                                <a href={sponsor.externalUrl} target="_blank"><img src={sponsor.imageUrl} style={{height: "6em"}}/></a>
                                : <img src={sponsor.imageUrl} style={{height: "6em"}}/>}
                        </React.Fragment>
                    })}
                 </div>}
                </div>
                <div className="ui- widget ui-g-7" style={{fontSize: "1.2em"}}>
                    <p className="ui-card-subtitle">{new Date(event.openingDate).toDateString()} - {new Date(event.closingDate).toDateString()} {event.place? `| ${event.place}`: ''}</p>
                    <p className="ui-card-content">
                        <div dangerouslySetInnerHTML={{__html: event.description}}/>
                    </p>
                    <div style={{width: "100%", textAlign:"left"}}>
                        {eventButton}
                    </div>
                 </div>
             </div>        
        );
    }
};

EventDetailsPage.propTypes={
    t:PropTypes.func.isRequired,
    event:PropTypes.object.isRequired,
    eventButton:PropTypes.func.isRequired
};

export default withTranslation()(EventDetailsPage);
