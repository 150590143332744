import React from "react";
import PropTypes from "prop-types";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {getNestedProp} from "../../helpers/expressions";

class GraphInfoPanel extends React.Component {

  constructor(props) {
    super(props);
    [].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, ss) {

  }

  render() {
    const {graph, graphTool, viewer, updateViewerProperty} = this.props;

    const author = (graph!=null && graph.getAuthor!=null)
      ? graph.getAuthor()
      : "No data"
    ;

    const version = (graph!=null && graph.getVersion!=null)
      ? graph.getVersion()
      : "No data"
    ;

    const nodesNumber = (graph!=null && graph.getNodes!=null)
      ? graph.getNodes().length
      : "No data"
    ;

    const edgesNumber = (graph!=null && graph.getNodes!=null)
      ? graph.getEdges().length
      : "No data"
    ;

    const nodeProps =  (graph!=null && graph.getNodeAttributes!=null)
    ? graph.getNodeAttributes().map((el)=>(
      <div style={rowStyle}>
        <span style={labelStyle}>{el.name}</span>
        <span style={{display: "inline-block"}}>{el.description}</span>
      </div>)
      )
    : <div style={rowStyle}>
        <span style={labelStyle}>No data</span>
      </div>;

    const edgeProps =  (graph!=null && graph.getEdgeAttributes!=null)
      ? graph.getEdgeAttributes().map((el)=>(
        <div style={rowStyle}>
          <span style={labelStyle}>{el.name}</span>
          <span style={{display: "inline-block"}}>{el.description}</span>
        </div>)
      )
      : <div style={rowStyle}>
        <span style={labelStyle}>No data</span>
      </div>;

    return (
      <React.Fragment>
        <h5>Model</h5>
        <div style={tableStyle}>
          {/*<div style={rowStyle}>*/}
            {/*<span style={labelStyle}>Name:</span>*/}
            {/*<Dropdown style={{width:'20em'}}*/}
            {/*          value={getNestedProp(["sceneId"],viewer)}*/}
            {/*          onChange={(e)=>{updateViewerProperty(viewer["id"],"sceneId",e.value)}}*/}
            {/*          options={getNestedProp(["imageOptions"],viewer)}>*/}
            {/*</Dropdown>*/}
          {/*</div>*/}
          <div style={rowStyle}>
            <span style={labelStyle}>Author:</span>
            <span style={{width: "20em", display: "inline-block"}}>{author}</span>
          </div>
          <div style={rowStyle}>
            <span style={labelStyle}>Version:</span>
            <span style={{width: "20em", display: "inline-block"}}>{version}</span>
          </div>
        </div>
        <h5>Statistics</h5>
        <div style={tableStyle}>
          <div style={rowStyle}>
            <span style={labelStyle}>Number of nodes:</span>
            <span style={{width: "20em", display: "inline-block"}}>{nodesNumber}</span>
          </div>
          <div style={rowStyle}>
            <span style={labelStyle}>Number of edges:</span>
            <span style={{width: "20em", display: "inline-block"}}>{edgesNumber}</span>
          </div>
        </div>
        <h5>Node properties</h5>
        <div style={tableStyle}>
          {nodeProps}
        </div>
        <h5>Edge properties</h5>
        <div style={tableStyle}>
          {edgeProps}
        </div>

      </React.Fragment>
    );
  }
}

export default GraphInfoPanel;

GraphInfoPanel.propTypes = {
  graph:PropTypes.object.isRequired,  // graph data object
  graphTool:PropTypes.object.isRequired, // widget object
  viewer:PropTypes.object.isRequired,
  updateViewerProperty:PropTypes.func.isRequired
};

GraphInfoPanel.defaultProps = {};

const labelStyle = {
  width: "12em",
  display: "table-cell"
};

const tableStyle = {
  display:"table"
};

const rowStyle = {
  margin:"1em",
  display:"table-row"
};
