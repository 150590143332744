import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {GLMModel} from "../../expDesign/models/GLMModel";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Rating} from "primereact/components/rating/Rating";


/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class GLMStatsPanel extends React.Component {

  constructor(props) {
    super(props);
  }



  render() {
    const {modelParameters}=this.props;

    if (!(modelParameters!=null))
      return <div/>;

    const glmModel = new GLMModel(modelParameters);

    const betas = glmModel.getBetasArray();
    const betasHeaders = glmModel.getBetasHeaders(); //names of statistics and attributes btw


    const cis = glmModel.getCIArray();
    const cisHeaders = glmModel.getCIHeaders();


    const modelDetails = {
      terms:glmModel.getArrayOfValuesByAttributeName("terms")[0],
      aic:glmModel.getArrayOfValuesByAttributeName("aic")[0],
      family:glmModel.getArrayOfValuesByAttributeName("family")[0].value[0],
      dfresidual:glmModel.getArrayOfValuesByAttributeName("df.residual")[0],
      dfnull:glmModel.getArrayOfValuesByAttributeName("df.null")[0],
      deviance:glmModel.getArrayOfValuesByAttributeName("deviance")[0],
      nulldev:glmModel.getArrayOfValuesByAttributeName("null.deviance")[0],
      dispersion:glmModel.getArrayOfValuesByAttributeName("dispersion")[0],
      iter:glmModel.getArrayOfValuesByAttributeName("iter")[0]
    };

    const significance = (v)=> {
      if (v==null) return null;
      let value =0;
      if (v<0.1) value= 1;
      if (v<0.05) value= 2;
      if (v<0.01) value= 3;
      if (v<0.001) value= 4;
      return    <Rating value={value} stars={4} readonly={true}/> ;
    };


    return (

      <React.Fragment>


        {/*<DataTable*/}
        {/*  header={"Symbols"}*/}
        {/*  value={[]}>*/}
        {/*  <Column field={"name"} header={"Symbol"} />*/}
        {/*  <Column header={betasHeaders[0]} field={}/>*/}
        {/*</DataTable>*/}

        <DataTable header={"Coefficients"}
          value={betas!=null?betas:[]}>
          <Column field={"name"} header={"name"} />
          <Column body={(row)=>row[betasHeaders[0]]} header={betasHeaders[0]} />
          <Column body={(row)=>row[betasHeaders[1]]} header={betasHeaders[1]} />
          <Column body={(row)=>row[betasHeaders[2]]} header={betasHeaders[2]} />
          <Column body={(row)=>row[betasHeaders[3]]} header={betasHeaders[3]} />
          <Column body={(row)=>significance(row[betasHeaders[3]])} header={"Significance"} />
        </DataTable>

        <p/>
        <DataTable header={"Confidence interval"}
                   value={cis!=null?cis:[]}>
          <Column field={"name"} header={"name"} />
          <Column body={(row)=>row[cisHeaders[0]]} header={cisHeaders[0]} />
          <Column body={(row)=>row[cisHeaders[1]]} header={cisHeaders[1]} />
        </DataTable>
        <p/>
        <DataTable
          header={"Model details"}
          value={[modelDetails]}>
          <Column  header={<div title={"Terms object used"}>terms</div>}
                   field={"terms"}/>
          <Column  header={<div title={"A version of Akaike's An Information Criterion, minus twice the maximized log-likelihood plus twice the number of parameters"}>aic</div>}
                   field={"aic"}/>
          <Column  header={<div title={"A description of the error distribution and link function"}>family</div>}
                   field={"family"}/>
          <Column  header={<div title={"The residual degrees of freedom"}>df.res</div>}
                   field={"dfresidual"}/>
          <Column  header={<div title={"The residual degrees of freedom for the null model"}>df.null</div>}
                   field={"dfnull"}/>
          <Column  header={<div title={"Up to a constant, minus twice the maximized log-likelihood"}>deviance</div>}
                   field={"deviance"}/>
          <Column  header={<div title={"The deviance for the null model"}>null.dev</div>}
                   field={"nulldev"}/>
          <Column  header={<div title={"Estimated dispersion"}>dispersion</div>}
                   field={"dispersion"}/>
          <Column  header={<div title={"Number of iterations of Iteratively Reweighted Least Squares"}>iter</div>}
                   field={"iter"} />
        </DataTable>


        <div style={{fontStyle:"italic",float:"right"}}>Generated with use of CRAN package:
          <a  style={{textDecoration:"underline"}} target={"_blank"}
              href={"https://stat.ethz.ch/R-manual/R-devel/library/stats/html/00Index.html"}>
            Stats
          </a>
          (see
          <a  style={{textDecoration:"underline"}} target={"_blank"}
              href={"https://stat.ethz.ch/R-manual/R-devel/library/stats/html/glm.html"}>
            glm
          </a>)
        </div>

      </React.Fragment>

    );
  }
}

export default withTranslation()(GLMStatsPanel);



GLMStatsPanel.propTypes = {
  modelParameters: PropTypes.object.isRequired,
  plotData: PropTypes.array.isRequired,
  t: PropTypes.func
};
