import React from "react";
import PropTypes from "prop-types";
import {ANNOTATION_PROPERTY_NAME__ID, REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import {AnnotationTable} from "./AnnotationTable";

class AnnotationTableSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRow:null
    };
    ["renderNumberOfColumns","renderUseCases","renderValidation","onRowSelect","renderOptions","checkIfSelected"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }


  componentDidMount(){
    const {getAnnotationsDefinitionsList,annotationsDefinitionsListState} = this.props;
    getAnnotationsDefinitionsList();
    if (annotationsDefinitionsListState===REQUEST_STATUS_SUCCESS) {
      this.checkIfSelected();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {annotationsDefinitionsList,annotationsDefinitionsListState} = this.props;
    if ((prevProps.annotationsDefinitionsList !==  annotationsDefinitionsList) && annotationsDefinitionsListState === REQUEST_STATUS_SUCCESS){
      this.checkIfSelected();
    }
  }

  /**
   * Checks if value assigned in constants can be found in list of all annotations - required to
   * initialize selectedRow for data table.
   */
  checkIfSelected(){
    const {annotationsDefinitionsList,selected} = this.props;
    const isSelected = annotationsDefinitionsList.find((el) => {
      return el[ANNOTATION_PROPERTY_NAME__ID] === selected
    });
    if (isSelected!=null){
      this.setState({selectedRow:isSelected});
    }
  }


  renderNumberOfColumns(row){
    return Object.keys(row.columns).length;
  }

  renderValidation(row){
    return JSON.stringify(row['validation']);
  }

  renderOptions(row){
    if (Array.isArray(row['options']))
     return row['options'].reduce((prev,current)=>{ return prev.concat(current.label,", ")},"");
    else return "";
  }

  renderUseCases(row){
    const {tasks,taskListState} = this.props;
    if (taskListState===REQUEST_STATUS_SUCCESS){
      return tasks
        .filter((el)=>{
          return el['constants']!=null && Object.values(el['constants']).findIndex((val)=> {return val.value === row[ANNOTATION_PROPERTY_NAME__ID]})>-1})
        .reduce((prev,current)=>{ return prev.concat(current.name," ")},"")
    }
    else return "NA";
  }


  onRowSelect(e) {
    const {getColumnsDefinition} = this.props;

    this.setState({
      selectedRow: Object.assign({}, e.data)
    });
    getColumnsDefinition(e.data[ANNOTATION_PROPERTY_NAME__ID]);
  }


  render() {
    const {annotationsDefinitionsListState, annotationsDefinitionsList,columns,columnsState,
      updateData,onDuplicateClick,onCreateNewClick} = this.props;
    const {selectedRow} = this.state;
    return (
      <div className={"ui-g"}>
        <div className={"ui-g-10"}>
        {annotationsDefinitionsListState===REQUEST_STATUS_SUCCESS
        && <DataTable
          value={annotationsDefinitionsList}
          rows={10}
          paginator={true}
          selection={this.state.selectedRow}
          onRowSelect={this.onRowSelect}
          selectionMode="single"
          onSelectionChange={()=>{}}>
          <Column field="_id" header={"id"}/>
          <Column field="docType" header={"Type"}/>
          <Column field="columns" header={"No of columns"} body={this.renderNumberOfColumns} style={{width:"15em"}}/>
          <Column header={"Use cases"}  body={this.renderUseCases} />
        </DataTable>}
        </div>
        <div className={"ui-g-2"}>
          <Button label={"Set selected"} disabled={!(selectedRow!=null)} onClick={()=>{updateData(selectedRow)}}/>
          {onDuplicateClick!=null &&
          <Button label={"Duplicate selected"} onClick={()=>onDuplicateClick(selectedRow)}/>}
          {onCreateNewClick!=null &&
          <Button label={"Create new"} onClick={()=>onCreateNewClick()}/>}
        </div>

        <div className={"ui-g-12"}>
        {selectedRow!=null
        && <React.Fragment>
        <h2>Preview</h2>
          <AnnotationTable columns={columns}
                           data={[{_id:"1",_roi:{roiCellIJK:[0,0,0]},annotations:{}}]}
                           manualToolState={this.props.manualToolState}
                           columnsState={columnsState}
                           viewCallback={()=>{}}
                           activeRowOnly={false}
                           updateManualSubTask={()=>{}}
                           updateManualToolState={()=>{}}
                           updateManualToolProperty={()=>{}}/>

        </React.Fragment>
          }
        </div>
      </div>
    )
  }

}

export default AnnotationTableSelection;

AnnotationTableSelection.propTypes = {
  getAnnotationsDefinitionsList: PropTypes.func.isRequired,
  annotationsDefinitionsListState: PropTypes.string.isRequired,
  annotationsDefinitionsList: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired, //required to display use cases of tables
  taskListState: PropTypes.string.isRequired, //required to display use cases of tables
  columns: PropTypes.object.isRequired, //required to show Preview of selected table
  columnsState: PropTypes.string.isRequired, //required to show Preview of selected table
  getColumnsDefinition: PropTypes.func.isRequired, //required to show Preview of selected table
  updateData: PropTypes.func.isRequired,
  manualToolState: PropTypes.object.isRequired,
  onDuplicateClick: PropTypes.func, //unused but feature for future
  onCreateNewClick: PropTypes.func, //unused but feature for future
  selected:PropTypes.string
};
