import {CROSS_HAIR_TOOL, PIN_TOOL, PIN_UNDO_REDO_TOOL, POINTER_TOOL, PROJECTION_TOOL} from "../../../Constants";


export const HelpEditorIcons = {};
HelpEditorIcons['Tools'] = {};

HelpEditorIcons['Tools'][PIN_TOOL] = {};
HelpEditorIcons['Tools'][PIN_TOOL]['label'] = "Pin";
HelpEditorIcons['Tools'][PIN_TOOL]['src'] = "https://drive.google.com/uc?export=download&id=1HkVVlpY1r1p6YldlRhWmSYFqd5tDn1F-";
HelpEditorIcons['Tools'][PIN_TOOL]['description'] = "Turn on/off pin tool";
HelpEditorIcons['Tools'][PIN_TOOL]['icon'] = "fa fa-map-pin";

HelpEditorIcons['Tools'][PROJECTION_TOOL] = {};
HelpEditorIcons['Tools'][PROJECTION_TOOL]['label'] = "Projection";
HelpEditorIcons['Tools'][PROJECTION_TOOL]['src'] = "https://drive.google.com/uc?export=download&id=11bfY3nu1E2iro8B-zac92sNI1AxXyUs9";
HelpEditorIcons['Tools'][PROJECTION_TOOL]['description'] = "Project annotations to adjacent slices";
HelpEditorIcons['Tools'][PROJECTION_TOOL]['icon'] = "fa fa-snapchat-ghost";


HelpEditorIcons['Tools'][POINTER_TOOL] = {};
HelpEditorIcons['Tools'][POINTER_TOOL]['label'] = "Pointer";
HelpEditorIcons['Tools'][POINTER_TOOL]['src'] = "https://drive.google.com/uc?export=download&id=1pd4vioZcOgcCkZJNxMiSrYSRtjIvIg27";
HelpEditorIcons['Tools'][POINTER_TOOL]['description'] = "Triangulate a focal area in all three projections";
HelpEditorIcons['Tools'][POINTER_TOOL]['icon'] = "fa fa-hand-o-up";


HelpEditorIcons['Tools'][PIN_UNDO_REDO_TOOL] = {};
HelpEditorIcons['Tools'][PIN_UNDO_REDO_TOOL]['label'] = "Pin Undo/Redo";
HelpEditorIcons['Tools'][PIN_UNDO_REDO_TOOL]['src'] = "https://drive.google.com/uc?export=download&id=1uyjb5n5ngjB9rzlKyyvbV_l9aWmiByx6";
HelpEditorIcons['Tools'][PIN_UNDO_REDO_TOOL]['description'] = "Undo/redo the most recently placed annotation";
HelpEditorIcons['Tools'][PIN_UNDO_REDO_TOOL]['icon'] = "fa fa-undo";

HelpEditorIcons['Tools'][CROSS_HAIR_TOOL] = {};
HelpEditorIcons['Tools'][CROSS_HAIR_TOOL]['label'] = "Crosshairs";
HelpEditorIcons['Tools'][CROSS_HAIR_TOOL]['src'] = "https://drive.google.com/uc?export=download&id=1eHHxrtW3gMhlnT-m0fdyTodRazF3JCxq";
HelpEditorIcons['Tools'][CROSS_HAIR_TOOL]['description'] = "Display a 3D crosshair showing the position of the slices currently shown in all 3 viewer windows";
HelpEditorIcons['Tools'][CROSS_HAIR_TOOL]['icon'] = "fa fa-crosshairs";



HelpEditorIcons['Combos'] = {};
HelpEditorIcons['Combos']['mouseLeftClick'] = {};
HelpEditorIcons['Combos']['mouseLeftClick']['label'] = "Mouse Left Click";
HelpEditorIcons['Combos']['mouseLeftClick']['src'] = "https://drive.google.com/uc?export=download&id=115RChWVB5vzscoAC05B1QR2SMH5_nVI8";

HelpEditorIcons['Combos']['slicing'] = {};
HelpEditorIcons['Combos']['slicing']['label'] = "Slicing";
HelpEditorIcons['Combos']['slicing']['src'] = "https://drive.google.com/uc?export=download&id=1O8SkwIZgVGsD_U8JcdmKbg2tHKXtrj_h";

HelpEditorIcons['Combos']['backquote'] = {};
HelpEditorIcons['Combos']['backquote']['label'] = "Navigating pins forward";
HelpEditorIcons['Combos']['backquote']['src'] = "https://drive.google.com/uc?export=download&id=19MENfpFJoHZ4qzseaUM9IBSLMvMx_vA2";

HelpEditorIcons['Combos']['shiftbackquote'] = {};
HelpEditorIcons['Combos']['shiftbackquote']['label'] = "Navigating pins backward";
HelpEditorIcons['Combos']['shiftbackquote']['src'] = "https://drive.google.com/uc?export=download&id=14LBXTLC1ycm5zIe71BeMU8i11k5wJ4OZ";


HelpEditorIcons['Combos']['mouseLeftClickAndMove'] = {};
HelpEditorIcons['Combos']['mouseLeftClickAndMove']['label'] = "Mouse Left Click and Move";
HelpEditorIcons['Combos']['mouseLeftClickAndMove']['src'] = "https://drive.google.com/uc?export=download&id=1NFjNlFSbIEtXnf17mbq2c76GWyEXAOil";

HelpEditorIcons['Combos']['mouseLeftClickAndMoveControl'] = {};
HelpEditorIcons['Combos']['mouseLeftClickAndMoveControl']['label'] = "Mouse Left Click and Move + Ctrl";
HelpEditorIcons['Combos']['mouseLeftClickAndMoveControl']['src'] = "https://drive.google.com/uc?export=download&id=1uEUpX97BuBrxOkord4Ee-dpkTga_9p69";

HelpEditorIcons['Combos']['mouseLeftClickAndMoveShift'] = {};
HelpEditorIcons['Combos']['mouseLeftClickAndMoveShift']['label'] = "Mouse Left Click and Move + Shift";
HelpEditorIcons['Combos']['mouseLeftClickAndMoveShift']['src'] = "https://drive.google.com/uc?export=download&id=1dV1dOBSZ8y5p1wnvRtlKK9WBxaO9uXKL";

HelpEditorIcons['Combos']['mouseRightClickAndMove'] = {};
HelpEditorIcons['Combos']['mouseRightClickAndMove']['label'] = "Mouse Right Click and Move";
HelpEditorIcons['Combos']['mouseRightClickAndMove']['src'] = "https://drive.google.com/uc?export=download&id=1-fD1d4wTU4J4908BBQZ83vdLAFIOw2Pg";

HelpEditorIcons['Combos']['mouseRightClickAndMoveControl'] = {};
HelpEditorIcons['Combos']['mouseRightClickAndMoveControl']['label'] = "Mouse Right Click and Move + Ctrl";
HelpEditorIcons['Combos']['mouseRightClickAndMoveControl']['src'] = "https://drive.google.com/uc?export=download&id=1XxlCwfZPbMQZe45D7KXSwelbjbVJ10mN";

HelpEditorIcons['Combos']['selectedDelete'] = {};
HelpEditorIcons['Combos']['selectedDelete']['label'] = "Select and Delete";
HelpEditorIcons['Combos']['selectedDelete']['src'] = "https://drive.google.com/uc?export=download&id=1ixDZqFAniMhJnDWc6oxuUQjPP4Qbf0XA";


HelpEditorIcons['Annotations'] = {};

HelpEditorIcons['Annotations']['mouseLeftClick'] = {};
HelpEditorIcons['Annotations']['mouseLeftClick']['label'] = "Mouse Left Click";
HelpEditorIcons['Annotations']['mouseLeftClick']['src'] = "https://drive.google.com/uc?export=download&id=115RChWVB5vzscoAC05B1QR2SMH5_nVI8";
HelpEditorIcons['Annotations']['Delete'] = {};
HelpEditorIcons['Annotations']['Delete']['label'] = "Delete";
HelpEditorIcons['Annotations']['Delete']['src'] = "https://drive.google.com/uc?export=download&id=13brFCX5hbLAXS_wMaSjQNxetuVQpiqsI";