import {connect} from "react-redux";
import {
    removeAnnotation,
    setActiveAnnotation,
    setSubAnnotation,
    updateAnnotationData, updateAnnotationFormData
} from "../action/AnnotationAction";
import {AnnotationTable} from "../component/annotationTool/AnnotationTable";
import {updateManualSubTask} from "../../dashboard/action/ManualTaskAction";
import {AnnotationForm} from "../component/annotationTool/AnnotationForm";
import {updateManualToolProperty, updateManualToolState} from "../action/ManualToolAction";
import {resetAllViewersCameras} from "../action/ViewerAction";
const mapStateToProps = (state,ownProps) => {
    return {
        manualToolState: state.visu.manualTool.manualToolState,
        manualToolConfiguration: state.visu.manualTool.manualToolConfiguration,
        columns: state.visu.annotations.annotationsFormDefinition,
        columnsState: state.visu.annotations.annotationsFormDefinitionState,
        data: state.visu.annotations.annotationsFormData,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateAnnotationData:(data)=>{
            dispatch(updateAnnotationFormData(data))
        },
        updateManualSubTask: (name)=>{
            dispatch(updateManualSubTask(name))
        },
        updateManualToolProperty: (key, property, value) => {
            dispatch(updateManualToolProperty(key, property, value))
        },
        updateManualToolState: (state, id) => {
            dispatch(updateManualToolState(state,id))
        },
        resetAllViewersCameras: () => {
            dispatch(resetAllViewersCameras())
        }
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerAnnotationForm = containerCreator(AnnotationForm);

