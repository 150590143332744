import React from "react";
import {Card} from "primereact/components/card/Card";
import PropTypes from "prop-types";
import {
    COHORT_PROPERTY_NAME__DESCRIPTION,
    COHORT_PROPERTY_NAME__NAME, COHORT_PROPERTY_NAME__PI,
    COHORT_PROPERTY_NAME__PRIVACY, COHORT_PROPERTY_NAME__SUBJECTS,
    COHORT_PROPERTY_NAME__UUID, COHORT_PROPERTY_NAME__VISITS,
    COHORT_PROPERTY_VALUE_PRIVACY__PUBLIC, EXPERIMENT_PROPERTY_NAME__COHORTS,
} from "../../../Constants";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import imagePlaceholder from "../../sass/images/iconforcohort.jpg";
import {Link} from "react-router-dom";
import {getNestedProp} from "../../helpers/expressions";



/** "Dumb" Renderer for "Cards" mode.
 * @param item - mandatory component if used with ItemList container
 * @param cohorts- all cohorts
 * @param onSelect - callback to provide handler for checkbox onSelect
 * @returns {*}
 * @constructor
 */
const CohortOptionsItemCardElement = ({item, onSelect, experiment}) => {

    // src={`data:image/png;base64,${image}`}/>; //switching to data acquired from DB via streaming

    const description =(item[COHORT_PROPERTY_NAME__DESCRIPTION]!=null && item[COHORT_PROPERTY_NAME__DESCRIPTION].length>0)
        ?(item[COHORT_PROPERTY_NAME__DESCRIPTION].length>250)?item[COHORT_PROPERTY_NAME__DESCRIPTION].slice(0,250)+"...":item[COHORT_PROPERTY_NAME__DESCRIPTION]
        :"";

    let privacyClass = "fa fa-lock cohortCardInternalIcon";
    let privacyTitle = "Private";




    switch  (item[COHORT_PROPERTY_NAME__PRIVACY]) {
        case COHORT_PROPERTY_VALUE_PRIVACY__PUBLIC:{
            privacyClass =  "fa fa-unlock cohortCardInternalIcon" ;
            privacyTitle = "Public";
            break;
        }
        default : {
            break;
        }
    }

    const extLink = (<div style={{float:'right'}}>
        <a target="_blank" href={(item["linkClinicalTrials"]!=null)?item["linkClinicalTrials"]:"https://clinicaltrials.gov/"}>
            <i className="fa fa-external-link cohortCardInternalIcon" title={name+" Website"}/>
        </a>
    </div>);

    // item["linkClinicalTrials"]  do the link just for clinical trials

    const name = item[COHORT_PROPERTY_NAME__NAME];
    let imageSrc = imagePlaceholder;

    if (item['logo']!=null && (item['logo']['type']==='url' || item['logo']['type']==='data') && item['logo']['value']!=null){
        imageSrc = item['logo']['value']
    }

    return (

        <Card className="cohortCard" >
            <div className="cohortCardHeader">
                <div style={{width:"30%",height:"inherit",display:"inline-block",verticalAlign:"top"}}>
                    <a
                      target="_blank"
                      {...(item['logo']!=null &&  item['logo']['url']!=null)?{href:item['logo']['url']}:{}}>
                        <img alt={name + ' Logo'} className="cohortCardIcon"  src={imageSrc} title={name}/>
                    </a>
                </div>
                <Link to={`/cohorts/${item.uuid}`}>
                <div style={{width: "70%", height: "inherit", display: "inline-block",verticalAlign:"top"}}>
                    <div style={{textAlign: "center"}}>
                        <h2 style={{overflowWrap: "break-word", color:"#6A797F"}}>{name}</h2>
                    </div>
                    <div style={{float:'right'}}>
                        <i className={privacyClass} title={privacyTitle} style={{fontSize:'20px'}}/>
                    </div>
                </div>
                </Link>
            </div>
            <div style={{height: '4em'}}>
                <hr/>
                <div >
                    <div title={"Description"} style={{height:"2em",overflow:"hidden"}}>
                        <i className="fa fa-info-circle cohortCardInternalIcon"/>
                        <span className="cohortCardIconText" title={description}>{description} </span>
                    </div>
                    <div title="Keywords"  style={{textAlign:'left'}}>
                        <a target="_blank" >
                            <i className="fa fa-key cohortCardInternalIcon"/>
                            {getNestedProp(['keywords'],item)!=null && Array.isArray(getNestedProp(['keywords'],item)) &&
                            getNestedProp(['keywords'],item).map(el=>
                              <a target="_blank" href={el.iri!=null? el.iri : "#"}>
                                <span style={{fontSize:"1.2em", fontWeight: "bold", color: "#6A797F",marginLeft:"1em"}}> {el.label} </span>
                              </a>
                            )}
                        </a>
                    </div>
                    <div title="Principal Investigator" style={{textAlign:'left'}}>
                        <i className="fa fa-user-md cohortCardInternalIcon"/>
                        <span className="cohortCardIconText">
                            {item[COHORT_PROPERTY_NAME__PI]}
                        </span>
                    </div>

                    <div style={{display:"flex",justifyContent:"space-between"}}>
                        <div title="Number of subjects" style={{textAlign: 'left'}}>
                            <i className="fa fa-users cohortCardInternalIcon"/>
                            <span className="cohortCardIconText">
                            {item[COHORT_PROPERTY_NAME__SUBJECTS].count}
                        </span>
                        </div>
                        <div title="Number of visits" style={{textAlign: 'left'}}>
                            <i className="fa fa-handshake-o cohortCardInternalIcon"/>
                            <span className="cohortCardIconText">
                            {item[COHORT_PROPERTY_NAME__VISITS].count}
                        </span>
                        </div>
                        <div title="Number of images" style={{textAlign: 'left'}}>
                            <i
                              className="fa fa-picture-o  cohortCardInternalIcon"/>
                            <span
                              className="cohortCardIconText">{(item["images"] != null) ? item["images"].count : ""}</span>
                        </div>
                    </div>

                    {/*<div title="Diagnosis Code" style={{textAlign:'left'}}>*/}
                        {/*<a target="_blank" href={MOCK_LINK_TO_G35}> <i*/}
                            {/*className="fa fa-barcode cohortCardInternalIcon"/>*/}
                            {/*<span className="cohortCardIconText">{MOCK_CODE}</span></a>*/}
                    {/*</div>*/}

                    {onSelect!=null &&
                        <div title="Select cohort" style={{float: 'right'}}>
                            <Checkbox style={{float: 'right', fontSize: '14px'}}
                                      className="greyCheckbox"
                                      checked={experiment[EXPERIMENT_PROPERTY_NAME__COHORTS] != null ? experiment[EXPERIMENT_PROPERTY_NAME__COHORTS].includes(item[COHORT_PROPERTY_NAME__UUID]) : false}
                                      onChange={() => {
                                          onSelect(item)
                                      }}/>
                        </div>
                    }
                </div>
            </div>
        </Card>

    )
};

export default CohortOptionsItemCardElement;

CohortOptionsItemCardElement.propTypes = {
    item: PropTypes.object.isRequired,
    experiment: PropTypes.object.isRequired, //experiment
    cohortList: PropTypes.array,//does not apply here
    onSelect: PropTypes.func.isRequired,//callback to select cohort
    selectCheckboxState: PropTypes.func,// flag indicating whether all cohorts are selected
    handleChange: PropTypes.func.isRequired,//does not apply here, just passed with others
    isList: PropTypes.bool,//does not apply here
    listModeClb: PropTypes.func//does not apply here
};
