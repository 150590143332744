import React from 'react';
import YouTube from 'react-youtube';
import {
  ContainerTabletGameExperiment} from "../../expDesign/containers/ContainerTabletGameExperiment";
import {ContainerManualToolDispatcher} from "../../visualization/containers/ContainerManualToolDispatcher";
import {ContainerSkillSummaryLP} from "../../visualization/containers/ContainerSkillSummaryLP";
import {ContainerDynamicHistogramDicePanel} from "../../expDesign/containers/ContainerDynamicHistogramDicePanel";

export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK = 'MINIWORKFLOWTASK';
// this will be moved to Constants (after we know the final version of Slides and Presentation schema)
export const SLIDE_PROPERTY_NAME__SLIDE_TYPE = "slideType";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE = 'IMAGE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO = 'VIDEO';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE = 'WEBSITE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT = 'EXPERIMENT';
export const SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES = 'slideProperties';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_RESULTS_DICE = 'MINIWORKFLOWRESULTSDICE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__SKILL_SUMMARY = 'SKILLSUMMARY';

class playSlidesManual extends React.Component {

  constructor() {
    super();
    this.state = {
      slideIndex: 0,
    };
    ["plusSlides", "currentSlide", "keyListener"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  };

  plusSlides(n) {
    let newValue = this.state.slideIndex + n;
    // if ((this.state.slideIndex + n)>=this.props.slides.length) newValue=0;
    // if ((this.state.slideIndex + n)<0) newValue=this.props.slides.length-1;
    if ((this.state.slideIndex + n) >= this.props.slides.length || (this.state.slideIndex + n) < 0) return; // no change
    this.setState({slideIndex: newValue});
  }

  currentSlide(n) {
    this.setState({slideIndex: n});
  }

  keyListener(event) {
    if (event.code === "Escape") {
      this.props.history.push("/livePresenter");
      return;
    }
    // if (event.code === "ArrowDown" || event.code === "PageDown") {
    // if (event.code === "PageDown") {
    //     this.plusSlides(1);
    //     return;
    // }
    // if (event.code === "PageUp") {
    //     this.plusSlides(-1);
    //     return;
    // }
    let slides = null;
    if (this.props.slides != null && this.props.slides.length > 0) {
      slides = this.props.slides.sort((a, b) => {
        return parseInt(a.orderNumber) - parseInt(b.orderNumber)
      })
    }
    if (event.code === "PageDown" || event.code === "Comma") {
      // this.currentSlide(0);
      this.plusSlides(-1);
      return;
    }
    let currentSlide = slides[this.state.slideIndex];
    if (currentSlide[SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase() !== SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK) {
      if (event.code === "PageUp" || event.code === "Period") {
        // this.currentSlide(0);
        this.plusSlides(1);
      }
    }
  }

  getRenderCurrentSlide(currentSlide) {

    const playerOpts = {
      height: '100%',
      width: '100%',
      host: 'https://www.youtube-nocookie.com',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        rel: 0,
        autoplay: 1,
        cc_load_policy:1,
        cc_lang_pref:'fr',
        mute: 0
      }
    };

    const title_style = {
      position: 'fixed',
      left: "9em",
      top: "0px",
      color: "white",
      zIndex: "1001",
      fontSize: "16px",
      marginTop: "20px",
      marginLeft: "25px",
      display: "inline-block"
    };
    const slide_style = {
      width: 'inherit',
      height: 'inherit',
      position: 'relative',
      display: "block"
    };
    const slide_style_summary = {
      width: 'inherit',
      height: 'inherit',
      position: 'relative',
      display: "block",
      backgroundColor: "white"
    };
    const slide_style_video = {
      width: 'inherit',
      height: 'inherit',
      position: 'relative',
      display: "block",
      overflowX: "hidden",
      overflowY: "hidden"
    };
    // const slide_style = (index===this.state.slideIndex)?
    //   {width: '100%', height: '100%', position: 'relative',  display:"block"}:
    //   {width: '100%', height: '100%', position: 'relative',  display:"none"};

    const present = this.props.presentations.find((el)=>el.uuid===currentSlide.reference.presentationId);
    const breadcrumb =  <span style={title_style}> {present!=null?" > " +present['name']:""} > {currentSlide.title}</span>;

    switch (currentSlide[SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase()) {

      case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE:
        return (
          <React.Fragment key={currentSlide.orderNumber}>
            <div style={slide_style} className="slide_div fade">
              {breadcrumb}
              <iframe is="x-frame-bypass" src={currentSlide.slideProperties.url}
                      height="100%" width="100%"/>
            </div>
          </React.Fragment>
        );

      case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO: {
        const onEnd = (currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['onEndGoNext'] != null &&
          currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['onEndGoNext'])
          ? () => this.plusSlides(1)
          : () => {
          };
        let url = new URL(currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['url']); // i am parsing url here, since i need 1 param only
        return (
          <React.Fragment key={currentSlide.orderNumber}>
            <div style={slide_style_video} className="slide_div fade">
              {breadcrumb}
              <YouTube videoId={url.searchParams.get('v')}
                       onEnd={onEnd}
                       opts={playerOpts}
                       height="100%"
                       width="100%"
                       containerClassName="youtubeVideo"
              />
            </div>
          </React.Fragment>
        );
      }

      case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT:
        return (
          <React.Fragment key={currentSlide.orderNumber}>
            <div style={slide_style} className="slide_div fade">
              {breadcrumb}
              <ContainerTabletGameExperiment
                slideProperties={currentSlide['slideProperties']}
                active={true}/>
            </div>
          </React.Fragment>
        );

      case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE:
        return (
          <React.Fragment key={currentSlide.orderNumber}>
            <div style={slide_style} className="slide_div fade">
              {breadcrumb}
              <img
                src={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['url']}
                height="100%" width="100%"/>
              {/* <p>{currentSlide.desc}</p> */}
            </div>
          </React.Fragment>
        );

      case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK:
        return (
          <React.Fragment key={currentSlide.orderNumber}>
            <div style={slide_style} className="slide_div fade">
              <ContainerManualToolDispatcher
                materializedTaskId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['taskId']}
                livePresenterExperimentId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['livePresenterExperimentId']}
                miniWorkflowSetId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['miniWorkflowSetId']}
                miniWorkflowNameInSet={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['miniWorkflowNameInSet']}
                taskNameInMiniWorkflow={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['taskNameInMiniWorkflow']}
                currentStep={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['currentStep']}
                onNext={this.plusSlides}
              />
            </div>
          </React.Fragment>
        );

      case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_RESULTS_DICE:
        return (
          <React.Fragment key={currentSlide.orderNumber}>
            <div style={slide_style} className="slide_div fade">
              <ContainerDynamicHistogramDicePanel
                livePresenterExperimentId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['livePresenterExperimentId']}
              />
            </div>
          </React.Fragment>
        );
      case  SLIDE_PROPERTY_VALUE_SLIDE_TYPE__SKILL_SUMMARY:
        return (
          <React.Fragment key={currentSlide.orderNumber}>
            <div style={slide_style_summary} className="slide_div fade">
              <ContainerSkillSummaryLP
                urlFailure={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['urlFailure']}
                urlSuccess={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['urlSuccess']}
                livePresenterExperimentId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['livePresenterExperimentId']}
                mfKey={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['miniWorkflowNameInSet']}
                miniWorkflowSetId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['miniWorkflowSetId']}
                goToFirstSlide={()=>{this.setState({slideIndex:0});}}
              />
            </div>
          </React.Fragment>
        );
    }
  }

  componentDidMount() {
    const {predefined} = this.props;
    const script = document.createElement("script");
    document.body.addEventListener("keydown", this.keyListener, false);
    script.src = "https://unpkg.com/x-frame-bypass";
    document.body.appendChild(script);
    const {getSlides} = this.props;
    getSlides(predefined!=null?predefined:this.props.match.params['id']);
  }

  /** Needed to remove listeners. */
  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.keyListener, false);
  }

  render() {

    const {slideIndex} = this.state;
    const {slides,slideShowClassName,prevClassName,nextClassName} = this.props;
    // Sort the slide based on its number
    let sortedSlides = null
    if (slides != null && slides.length > 0) {
      sortedSlides = slides.sort((a, b) => {
        return parseInt(a.orderNumber) - parseInt(b.orderNumber)
      })
    }

    // Get the render for the current slide
    let actualSlideRender = null
    if (sortedSlides) {
      let currentSlide = sortedSlides[slideIndex]
      actualSlideRender = this.getRenderCurrentSlide(currentSlide)
    }

    console.log('Play slides... render');
    return (
      <React.Fragment>
        {slides && slides.length > 0 &&
        <div className={slideShowClassName!=null?slideShowClassName:"slideshow-div"}>
          {actualSlideRender}
          {slides[slideIndex][SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase() !== SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK
          && (slideIndex > 0) &&
          <a className={prevClassName!=null?prevClassName:"prev"} onClick={() => this.plusSlides(-1)}>&#10094;</a>
          }
          {slides[slideIndex][SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase() !== SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK
          && (slideIndex <= slides.length) &&
          <a className={nextClassName!=null?nextClassName:"next"} onClick={() => this.plusSlides(1)}>&#10095;</a>
          }
          {/*<div style={{textAlign: "center", position: "absolute", top: "96%", width: "100%", zIndex:"1001"}}>*/}
          {/*{slide_manualdots}*/}
          {/*</div>*/}
        </div>}
      </React.Fragment>
    )

  }
};

export default playSlidesManual;