import { combineReducers } from 'redux'

import AuthReducer from './authentication/reducers/AuthReducer.jsx'
import VisuReducer from './visualization/VisuReducer'
import ExpDesignReducer from './expDesign/ExpDesignReducer'
import { ProjectReducer } from './project/reducer/ProjectReducer'
import {ActivityReducer} from "./dashboard/reducer/ActivityReducer";
import {SkillReducer} from "./skills/reducer/SkillReducer";
import {PresentationReducer} from "./livePresenter/reducer/PresentationReducer";
import {SingleResultReviewReducer} from "./singleResultReview/reducer/SingleResultReviewReducer";
import {SPMReducer} from "./spm/reducer/SPMReducer";
import {messagingQueueReducer} from "./home/reducer/MessagingQueueReducer";
import {ResultsReducer} from "./dashboard/reducer/ResultsReducer";
import {EventsReducer} from "./event/reducer/EventsReducer";
import {SAMReducer} from "./analysis/reducer/SAMReducer";
import {spineQMonitorReducer} from "./spineqmonitor/reducer/SpineQMonitorReducer";
import {GeoReducer} from "./arcgis/reducer/GeoReducer";

/**
 * Combines reducers for ALL Store items.
 * All "slice reducers" are available with 'state' postfix, eg.
 * "state.visu..."
 * "state.visu..."
 * Splitting up reducer logic is well explained here:
 * @see https://redux.js.org/recipes/structuring-reducers/splitting-reducer-logic
 */
const RootReducer = combineReducers({
	auth: AuthReducer,
	visu: VisuReducer,
	expDesign: ExpDesignReducer,
	project: ProjectReducer,
	activity:ActivityReducer,
	skill:SkillReducer,
	presentation: PresentationReducer,
	singleResultReview: SingleResultReviewReducer,
  spm: SPMReducer,
	messaging:messagingQueueReducer,
	results:ResultsReducer,
	event: EventsReducer,
	sam:SAMReducer,
	geo:GeoReducer,
	spineQMonitor:spineQMonitorReducer
});

export default RootReducer;
