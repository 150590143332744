import React from 'react';
import PropTypes from "prop-types";
import {Prompt, withRouter} from "react-router";
import 'brace/mode/json';
import 'brace/mode/text';
import 'brace/theme/github';
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {ALL_LAYOUTS, getLayoutClass} from "../../root/component/LayoutManager";
import {DataView} from "primereact/components/dataview/DataView";
import {ViewersLayoutCard} from "./ViewersLayoutCard";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {Button} from "primereact/components/button/Button";
import {InputText} from "primereact/components/inputtext/InputText";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import Viewer2DControls from "./Viewer2DControls";
import Viewer3DControls from "./Viewer3DControls";
import {uuidv4} from "../../helpers/strings";
import {renameKey} from "../../helpers/properties";




const layoutOptions = ALL_LAYOUTS.map(el=>{return {
  label:getLayoutClass(el).getLabel(),
  className:getLayoutClass(el).getClassName!=null?getLayoutClass(el).getClassName():"",
  value:el}});

class ViewersConfigurationTabContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeViewerIndex:0
    };
    ["onTabChange","addViewer","removeViewer"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  onTabChange(e){
    if (e.index!==this.state.activeViewerIndex) {
      this.setState({activeViewerIndex: e.index});
    }
  }

  addViewer(type){
    const {transferObject,updateTransferObject} = this.props;

    const tempKey = uuidv4();
    transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['layoutOrder'][tempKey]=tempKey;

    if(type==="2D"){
      transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'][tempKey] = JSON.parse(JSON.stringify(viewer2DToBeAdded));
    }
    if(type==="3D"){
      transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'][tempKey] = JSON.parse(JSON.stringify(viewer3DToBeAdded));
    }
    updateTransferObject(transferObject);
  }

  removeViewer(key){
    const {transferObject,updateTransferObject} = this.props;

    const orderKey = transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['layoutOrder'][key];
    delete transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'][orderKey] ;
    delete transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['layoutOrder'][key];
    updateTransferObject(transferObject);
  }


  render() {
   const {transferObject,updateTransferObject,viewersConfiguration,messageQueue} = this.props;

    const itemTemplate = (data, layout) => {

      if (layout === 'grid') {
        return (
          <div onClick={()=>{
            transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['type'] = data.value;
            updateTransferObject(transferObject);
          }}>
          <ViewersLayoutCard
            footer={<React.Fragment/>}
            headerClassName={data.className}
            title={data.label}
            subTitle={""}
            description={""}
            filter={""}
            style={{
              width:"10em",
              margin:"1.5em",
              backgroundColor: viewersConfiguration['layout']['type'] === data.value?"aquamarine":"white"
            }}
          />
          </div>
      );
      }
    };
    return (
      <React.Fragment>
        Layout:         <DataView value={layoutOptions} layout={'grid'} itemTemplate={itemTemplate}/>
        Template mappings:
        {Object
          .keys(transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['layoutOrder'])
          .map(el=>{return (
            <div>
              <InputText value={el} onChange={(e)=>{
                transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['layoutOrder']
                  = renameKey(transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['layoutOrder'],el,e.currentTarget.value);
                updateTransferObject(transferObject);
              }}/>
              <Dropdown
                value={viewersConfiguration['layout']['layoutOrder'][el]}
                options={Object.keys(viewersConfiguration['renderWindows']).map(v=>{return {value:v,label:v}})}
                onChange={e=>{
                  transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['layout']['layoutOrder'][el]=e.value;
                  updateTransferObject(transferObject);
                }
                }
              />
              <Button icon={"fa fa-trash"} onClick={()=>{this.removeViewer(el)}}/>
            </div>)})
        }

        <Button icon={"fa fa-plus"} label={"2D"} onClick={()=>{this.addViewer("2D")}}/>
        <Button icon={"fa fa-plus"} label={"3D"} onClick={()=>{this.addViewer("3D")}}/>Required templates:
        {
          getLayoutClass(viewersConfiguration['layout']['type']).getViewersPortKeys().reduce((prev,cur)=>{return prev.concat(" ", cur,",")},"")
        }
        <TabView activeIndex={this.state.activeViewerIndex} onTabChange={this.onTabChange} header={"Viewers"}>
          {Object
          .keys(transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'])
          .map(el=>{
            const viewerKey = el;
            return (
          <TabPanel header={el}>
            <div className={"ui-g"}>
            <div className={"ui-g-3"}>
              <div>
                <label>Key:</label>
                <InputText value={viewerKey} onChange={(e)=>{

                transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows']
                  = renameKey(transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'],viewerKey,e.currentTarget.value);
                updateTransferObject(transferObject);
              }}/>
              </div>
              <div>
                <label>Name:</label>{ transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'][viewerKey]['name']}
              </div>
              <div>
                <label>Type:</label>
                <SelectButton value={ transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'][viewerKey]['type']}
                              options={[{value: '2D', label: '2D'},
                                {value: '3D', label: '3D'}]}
                  // onChange={(e) => this.setState({layout: e.value})}
                />
              </div>
            </div>
            <div className={"ui-g-9"}>
              { transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'][viewerKey]['type']==="2D" &&
              <Viewer2DControls transferObject={transferObject} updateTransferObject={updateTransferObject} viewerKey={viewerKey} messageQueue={messageQueue}/>}
              { transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'][viewerKey]['type']==="3D" &&
              <Viewer3DControls transferObject={transferObject} updateTransferObject={updateTransferObject} viewerKey={viewerKey} messageQueue={messageQueue}/>}
            </div>
            </div>
          </TabPanel>
          )})}
        </TabView>

      </React.Fragment>
    )
  };
}
export default withRouter(ViewersConfigurationTabContent);

const viewer2DToBeAdded={
  "name": "New viewer",
  "type": "2D",
  "initialState": {
  "orientationAndSliceNumber": {
    "orientation": "AXIAL",
      "strategy": "DYNAMIC",
      "dynamicSliceValue": "MIDDLE"
  },
  "initialWindowLevel": {
    "strategy": "dynamic",
      "value": "MIDDLE-FULL"
  }
},
  "displayScenes": {
  "possibleScenesToDisplay": [
    "sceneKey1",
    "sceneKey2",
    "sceneKey3"
  ],
    "hasDefaultSceneToDisplay": true,
    "defaultSceneToDisplay": "sceneKey1"
},
  "displayControls": {
  "orientation": {
    "controlVisible": false,
      "controlEnabled": false,
      "options": [
      {
        "label": "Sagittal",
        "value": "SAGITTAL"
      },
      {
        "label": "Coronal",
        "value": "CORONAL"
      },
      {
        "label": "Axial",
        "value": "AXIAL"
      }
    ],
      "defaultValue": "AXIAL"
  },
    "smoothing": {
      "controlVisible": false,
      "controlEnabled": false,
      "defaultValue": false
    },
    "sequence": {
      "controlVisible": false,
      "controlEnabled": false
    },
    "linked": {
      "controlVisible": false,
      "controlEnabled": false
    }
},
  "lookAndFeel": {
  "color": "#90ee90"
}
};

const viewer3DToBeAdded = {
  "name": "3D viewer",
  "type": "3D",
  "displayControls": {
    "smoothing": {
      "controlVisible": false,
      "controlEnabled": false,
      "defaultValue": true
    },
    "sequence": {
      "controlVisible": false,
      "controlEnabled": false
    }
  },
  "displayScenes": {
    "possibleScenesToDisplay": [
      "sceneKey1",
      "sceneKey2",
      "sceneKey3"
    ],
    "hasDefaultSceneToDisplay": true,
    "defaultSceneToDisplay": "sceneKey1"
  },
  "displayImageSlices": {

  }
}


ViewersConfigurationTabContent.propTypes= {
  viewersConfiguration:PropTypes.object.isRequired,
  transferObject: PropTypes.object.isRequired,
  messageQueue:PropTypes.object.isRequired,
  updateTransferObject: PropTypes.func.isRequired,
  updateIsChangedFlag: PropTypes.func.isRequired
};
