import {
  CLEAR_CONNECTOME_STATE, CONNECTOME_CASES_REQUEST_FAILED, CONNECTOME_CASES_REQUEST_SUCCESS, CONNECTOME_CASES_REQUESTED,
  CONNECTOME_REQUEST_FAILED, CONNECTOME_REQUEST_SUCCESS,
  CONNECTOME_REQUESTED, UPDATE_CONNECTOME_SELECTED_CASES,
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


/**
 * Reducer for handling connectomes.
 * Keeps a map of objects of a structure:
 * {
 *   "key":{
 *   "state":"success"
 *   "data":{
 *     ...directedGraph document here
 *   };
 *   }
 * }
 * @param state
 * @param action
 */
export const connectomeReducer = (state = {
  connectomes:{}, // connectome
  selectedCase:null,  // for case handling (ugly hack)
  cases:{}
}, action) => {
  switch (action.type) {
    case CLEAR_CONNECTOME_STATE:return Object.assign({}, state, {
      connectomes:{}
    });
    case CONNECTOME_REQUESTED:{
      let stateCopy = Object.assign({},state.connectomes);
      let isConnAlreadyInArray = stateCopy[action.connId]!=null;
      if (isConnAlreadyInArray)  //Maybe some conditions if error occurred (reload?)
        return state;
      let newImage = {};
      newImage['state'] =  REQUEST_STATUS_REQUESTED;
      stateCopy[action.connId] = newImage;
      return Object.assign({}, state, {
        connectomes:Object.assign({},stateCopy)
      });
    }
    case CONNECTOME_REQUEST_FAILED:{
      let stateCopy = Object.assign({},state.connectomes);
      if (!(stateCopy[action.connId]!=null))
        return state;
      let requestedImage = stateCopy[action.connId];
      requestedImage['state'] =  REQUEST_STATUS_FAIL;
      requestedImage['data'] =  action.err;
      stateCopy[action.connId]=Object.assign({},requestedImage);
      return Object.assign({}, state, {
        connectomes:Object.assign({},stateCopy)
      });
    }

    case CONNECTOME_REQUEST_SUCCESS:{
      let stateCopy = Object.assign({},state.connectomes);
      if (!(stateCopy[action.connId]!=null))
        return state;
      let requestedImage = stateCopy[action.connId];
      requestedImage['state'] =  REQUEST_STATUS_SUCCESS;
      requestedImage['data'] =  action.connectome;
      stateCopy[action.connId]=Object.assign({},requestedImage);
      return Object.assign({}, state, {
        connectomes:Object.assign({},stateCopy)
      });
    }

    case UPDATE_CONNECTOME_SELECTED_CASES:{
      return Object.assign({}, state, {
        selectedCase:Object.assign({},action.selectedCase)
      });
    }

    case CONNECTOME_CASES_REQUESTED:{
      return Object.assign({}, state, {
        cases: {state:REQUEST_STATUS_REQUESTED, data:[]}
      });
    }
    case CONNECTOME_CASES_REQUEST_FAILED:{
      return Object.assign({}, state, {
        cases: {state:REQUEST_STATUS_FAIL, data:action.err}
      });
    }

    case CONNECTOME_CASES_REQUEST_SUCCESS:{
      return Object.assign({}, state, {
        cases: {state:REQUEST_STATUS_SUCCESS, data:action.data}
      });
    }

    default:
      return state;
  }
};

