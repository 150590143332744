import React from 'react'
import 'font-awesome/css/font-awesome.css';
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'
import LayoutTopbar from './component/LayoutTopbar.js'
import ContainerRegister from './containers/ContainerRegister.js'
import Footer from "../root/Footer"
import { withTranslation } from 'react-i18next';
import {Route, Switch, withRouter} from "react-router";
import EntryPagePanel from "./component/EntryPagePanel";
import CookieConsent from 'react-cookie-consent';

/**
 * Landing page of application.
 * It all starts here...
 */
class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            registerForm:false,
            landingPage:"/home",
            configurationState:""
        };
    }
    componentDidMount() {
        const {getSession} = this.props;
        fetch('/dist/version.json')
          .then(response => response.json())
          .then(data => {
            getSession(()=>{this.props.history.push(data.landingPage)},()=>{});
            this.setState(
            {landingPage:data.landingPage, configurationState:"success"}
            )});
    }

    render() {
        const {t} = this.props;
        console.log("App:: this.propsAA:", this.props);
        return (
            <div>
                <LayoutTopbar/>
                <div style={{height:'calc(100vh - 75px)',overflowY:'auto', width:"100%"}}>
                        <EntryPagePanel/>
                    <div className="ui-g-12">
                        <Footer/>
                    </div>
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText={t("general.cookies.acceptbutton.label")}
                    style={{opacity: '0.9'}}
                    >
                    {t("general.cookies.message.label")}
                </CookieConsent>
            </div>
        );
    }

}

App.propTypes = {
    loginStatus: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    match:PropTypes.func.isRequired //Router function from wrapper
};

export default withTranslation()(withRouter(App));