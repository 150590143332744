import React from 'react';
import {Growl} from "primereact/components/growl/Growl";
import {withTranslation} from 'react-i18next';
import Footer from "../../root/Footer";
import {Link} from "react-router-dom";
import SpineLogo from "./SpineLogo";
import {HOST_URL, VALIDATING, VALIDATION_ERROR, VALIDATION_SUCCESS} from "../../../Constants";
import CookieConsent from 'react-cookie-consent';
import axios from 'axios';


class EmailValidation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validation: VALIDATING
        };

    }

    componentDidMount(){
        const {history, match} = this.props;
        const validationToken = match.params.validationToken;
        if(validationToken == null){
            history.push("/");
        }
        const url = `${HOST_URL}/api/userValidation`
        axios.post(url, {userValidationToken: validationToken})
        .then(response => {
                if (response.status !== 200){
                    this.growl.show({
                        sticky: false,
                        severity: 'info',
                        summary: 'Email validation error',
                        detail: 'Unable to validate email'
                    });
                    this.setState({validation: VALIDATION_ERROR});
                    setTimeout(()=>history.push('/'), 5000);
                } else {
                    this.setState({validation: VALIDATION_SUCCESS});
                    setTimeout(()=>history.push('/'), 5000);
                }
            }
        )
        .catch(err => {
                this.growl.show({
                    sticky: false,
                    severity: 'info',
                    summary: 'Email validation error',
                    detail: 'Unable to validate email'
                });
                this.setState({validation: VALIDATION_ERROR});
            }
        )
    }

    render(){
        const { t } = this.props;
        const { validation } = this.state;
        return (
            <React.Fragment>
                <Growl ref={(el) => { this.growl = el; }}/>
                <div className="header landingPageHeaderTopbar"
                        style={{display:"flex",alignItems:"center"}}>
                    <div className="landingPageSpineLogoContainer" style={{display: "inline-flex",  margin: "auto", marginLeft:"16px"}}>
                        <Link to='/'>
                            <SpineLogo/>
                        </Link>
                    </div>
                    <div className="reg-headtitle" style={{ color: "#A7FFFE",    margin: "auto", display: "inline-flex"}}>
                        {t("redirectEmailValidation.header")}
                    </div>
                </div>
                <div style={{height: 'calc(100vh - 75px)', overflowY: 'auto', diplay: "flex"}}>
                    <div className="ui-g-12" style={{height: "40%", marginTop: "10em", fontSize:"2em", textAlign: "center"}}>
                        {validation==VALIDATION_ERROR? t("general.emailValidation.errorMessage.label"): validation==VALIDATION_SUCCESS? t("general.emailValidation.successMessage.label"): t("general.emailValidation.validatingMessage.label")}
                        <div>
                        {validation==VALIDATING? <i className="fa fa-spinner fa-spin"/> : <Link to="/">{t("general.landingPage.redirectionlinkmessage.label")}</Link> }
                        </div>
                    </div>
                    <Footer/>
                </div>
                <CookieConsent
                    location="bottom"
                    buttonText={t("general.cookies.acceptbutton.label")}
                    style={{opacity: '0.9'}}>
                    {t("general.cookies.message.label")}
                </CookieConsent>
            </React.Fragment>
        );
    }
}

export default withTranslation()(EmailValidation);
