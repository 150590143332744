import React from 'react';
import {getNestedProp} from "../../../helpers/expressions";

const AutoComplete =  React.lazy(() => import("./AutoCompleteFormInput"));
const MultiAutoComplete =  React.lazy(() => import("./MultiAutoCompleteFormInput"));

/**
 * Factory for creating inputs components to be used in templates (Layouts).
 *
 */
export default class FormFactory {

  static build(el, toolProps, toolState) {
    const {messageQueue,formsState,updateFormProperty} =toolProps;

    if (!formsState.hasOwnProperty(el))
      return null;


    const commonProps = Object.assign({
      key:el,
      id:el,
      messageQueue:messageQueue
    },formsState[el]);


    const type = getNestedProp([el,'type'],formsState)!=null
      ? getNestedProp([el,'type'],formsState).toUpperCase()
      : null;

    switch (type) {
      case "AUTOCOMPLETE": {
        if(commonProps.multiple===true) { // if autocomplete with multiple outputs
          return (<React.Suspense fallback={<p>Loading</p>}>
              <MultiAutoComplete
                {...commonProps}
                updateFormProperty={updateFormProperty}/>
            </React.Suspense>
          );
        }
        return (<React.Suspense fallback={<p>Loading</p>}>
            <AutoComplete
              {...commonProps}
              updateFormProperty={updateFormProperty}/>
          </React.Suspense>
        );
      }
      default: return <React.Fragment/>
    }
  }
}