import {LEVEL_TRACING_TOOL} from "../../../Constants";
import vtkBrushTracing from "../LevelTracingEffect/BrushTracing";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor/index";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper/index";
import {parseColor} from "../SpineVTKHelper";

/**
 * All elements of Level tracing Effect widget are singletons, so references can be kept here.
 *
 *
 */
const tracer = vtkBrushTracing.newInstance();
const polyActor = vtkActor.newInstance();
const polyMapper = vtkMapper.newInstance();

/**
 * Get actor representing levelTracing Effect or null if not present
 * @param renderer - inspected vtkRenderer
 * @return {*|number|bigint} -  object of polyActor representing levelTracing Effect or null if not present
 */
export const getBrushTracingActor  = (renderer) => renderer.getActors().find((el)=>el.get()[LEVEL_TRACING_TOOL] === true);


/**
 * Remove levelTracing Effect actor if exists
 * @param renderer
 */
export const removeBrushTracingActor  = (renderer) => {
  const actor = getBrushTracingActor(renderer);
  if (actor != null)
    renderer.removeActor(actor);
};


export const createBrushTracingActor = (renderer, layerIndex, labelValue, radius, shape,  seed = [-1,-1,-1]) =>{
  // calculate region with Connected component Filter
  tracer.setInputData(renderer.getActors()[layerIndex].getMapper().getInputData());
  tracer.setSlicingMode(renderer.getActors()[layerIndex].getMapper().getSlicingMode());
  tracer.setSeedPoint(seed);
  tracer.setRadius(radius);
  tracer.setShape(shape);

  // add polydata actor to renderer
  polyMapper.setInputData(tracer.getOutputData());
  polyMapper.update();
  polyActor.setMapper(polyMapper);
  polyActor.set({'levelTracingTool':true});
  renderer.addActor(polyActor);
};


/**
 *
 * @param renderer - renderer containing
 * @param options:{seed : {},slice: {}, label:{}, f:{}, radius:{}
 *
 * }
 */

export const updateBrushTracingActor = (renderer, options) => {

  if (options.seed!=null && Array.isArray(options.seed)){
    tracer.setSeedPoint(options.seed);
    tracer.update();
  }

  if (options.radius!=null){
    tracer.setRadius(options.radius);
    tracer.update();
  }

  if (options.shape!=null){
    tracer.setShape(options.shape);
    tracer.update();
  }

  // if (options.levelFunction!=null){
  //   tracer.setLevelFunction(options.levelFunction);
  // }

  polyMapper.setInputData(tracer.getOutputData());
};

/**
 * Set up Level Function using name alias
 * @param levelFunctionName - name alias ("BRIGHTER","DARKER") under which function is registered
 */
export const setLevelFunction = (levelFunctionName) =>{
  const f = LEVEL_FUNCTION_OPTIONS.options.find(el=>el.value === levelFunctionName);
  if (f!=null){
    tracer.setLevelFunction(f.levelFunction);
    tracer.update();
  }
};

/**
 * Set up radius
 * @param radius -
 */
export const setRadius = (radius) =>{
  tracer.setRadius(radius);
  tracer.update();
};

/**
 * Set up Level Function using name alias
 * @param levelFunctionName - name alias ("BRIGHTER","DARKER") under which function is registered
 */
export const setThreeD = (threeD) =>{
  tracer.setThreeD(threeD);
  tracer.update();
};

/**
 * Set up Level Function margin
 * @param margin - name alias ("BRIGHTER","DARKER") under which function is registered
 */
// export const setLevelFunctionMargin = (margin) =>{
//   LEVEL_FUNCTION_OPTIONS.margin = margin;
// };



/**
 * Set up color for outline.
 * Color is provided as string "#fff", or "#ffffff".
 * There is no need to change label along with color of outline - label can be set when
 * selected region is copied to overlay volume.
 */
export const setBrushTracingOutlineColor = (color) =>{

  if (color!=null){
    polyActor
      .getProperty()
      .setColor(...parseColor(color));
  }
};

/**
 * Get segmented data (only voxel values)
 */
export const getBrushTracingRegion = () =>{
  return polyMapper.getInputData();
};