import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types'
import {Dropdown} from "primereact/components/dropdown/Dropdown";


/**
 * Temporarily used as testing page for various components.
 *
 */

class RegionPage extends React.Component {


    componentDidMount(){
        // const {getTaskList} =this.props;
        // getTaskList('whatever');
    }
    render() {
        const {auth,t,i18n} = this.props;
        const lang = i18n.language.substring(0,2);
        const options = [{
            label:t("general.language.english.label"),
            value:'en'
        },{
            label:t("general.language.french.label"),
            value:'fr'
        },
            {
                label:t("general.language.polish.label"),
                value:'pl'
            }
        ];
        console.log("RegionPage ...render");
        return (
            <div  style={{margin: '16px'}}>
                Please select language:
                <Dropdown value={lang} options={options} onChange={(e)=>{i18n.changeLanguage(e.value)}}/>
            </div>
        )
    }
}
RegionPage.propTypes = {
    auth: PropTypes.object.isRequired // user record
};

export default withTranslation() (RegionPage);