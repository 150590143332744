import React from "react";
import PropTypes from "prop-types";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/github';
import {InputText} from "primereact/components/inputtext/InputText";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {getPortLabelByType} from "../../visualization/action/ManualToolAction";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Button} from "primereact/components/button/Button";
import {NonModalSidebar} from "../../root/component/NonModalSidebar";
import {ContainerAnnotationTableSelection} from "../../visualization/containers/ContainerAnnotationTableSelection";
import {ANNOTATION_PROPERTY_NAME__ID} from "../../../Constants";
import {ContainerTaskPropertiesHelpPanelTab} from "../container/ContainerTaskPropertiesHelpPanelTab";


export class TaskPropertiesPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeindex: 0,
      annotationEditorVisible: false,
      editedAnnotationKey: null
    };
    ["portTypeBody", "booleanTypeBody", "buttonTypeBody", "changePortType", "constantValueBody", "updateHelpPanel",
      "updateConstantsTable","clearConstant"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  portTypeBody(row) {
    return getPortLabelByType(row.type)
  }

  updateHelpPanel(data, key) {
    const {nodeSettings} = this.props;
    if (!(nodeSettings.taskData.helpPanel != null)) {
      nodeSettings.taskData['helpPanel'] = {};
    }
    nodeSettings.taskData['helpPanel'][key] = Object.assign({}, data);
    this.setState({});//refresh
  }

  updateConstantsTable(data) {
    const {nodeSettings} = this.props;
    const {editedAnnotationKey} = this.state;
    nodeSettings.taskData['constants'][editedAnnotationKey]['value'] = data[ANNOTATION_PROPERTY_NAME__ID];// Object.assign({}, data);
    this.setState({annotationEditorVisible: false, editedAnnotationKey: null});//refresh
  }

  clearConstant(annotationKey){
    const {nodeSettings}=this.props;
    nodeSettings.taskData['constants'][annotationKey]['value'] = null;
    this.setState({});
  }

  constantValueBody(row) {
    const {nodeSettings}=this.props;
    if (nodeSettings.taskData.status==="DRAFT" && (row.type === "annotationFormDefinitionInputOutput" || row.type === "annotationTableDefinitionInputOutput")){

      const label = row.value != null
        ?"View/Edit"
        :"Select";
      return (<React.Fragment>
        <Button
          onClick={() => {
          this.setState({annotationEditorVisible: true, editedAnnotationKey: row.key})
        }} label={label}/>
        {label!=="Select" && <Button
          onClick={() => {
          this.clearConstant(row.key);
        }} icon={"fa fa-trash"} title={"Clear"}/>
        }
      </React.Fragment>);
    }
    else return ((row.value!=null) ? <Button disabled={true} label={"View"} title={"Work in progress"}/>:<div>Not available</div>);
  }

  booleanTypeBody(row, col) {
    return <Checkbox readOnly={true} checked={row[col.field]}/>
  }

  buttonTypeBody(row, col) {
    const target = this.state.activeIndex === 1 ? "constants" : "inputs";
    return <Button label={"Move"} onClick={() => {
      this.changePortType(target, row)
    }}/>
  }

  changePortType(target, data) {
    this.props.nodeSettings.taskData[target][data.key] = data;
    if (target === "constants") {
      delete this.props.nodeSettings.taskData["inputs"][data.key];
    } else {
      delete this.props.nodeSettings.taskData["constants"][data.key];
    }
    this.setState({});
    this.props.updateTransferTool();
  }

  render() {
    const {nodeSettings} = this.props;
    const {editedAnnotationKey} = this.state;

    const prepareTableData = (portType) => {
      if (nodeSettings.taskData[portType] != null && Object.keys(nodeSettings.taskData[portType]).length > 0)
        return Object.keys(nodeSettings.taskData[portType]).map((el) => {
          return {...nodeSettings.taskData[portType][el], key: el}
        });
      else return [];
    };

    return <React.Fragment>
      {nodeSettings != null &&
      <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}
               style={{height: "450px"}}>
        <TabPanel header="Properties">
          <h1> Task definition to use in workflow </h1>
          <div className="ui-g-12">
            <div className="ui-g-2 ">
              <label> Name: </label>
            </div>
            <div className="ui-g-10 ">
              <InputText label={"Name"}
                         style={{width:"100%"}}
                         value={nodeSettings.taskInWorkflowData.name} onChange={(e) => {
                nodeSettings.taskInWorkflowData.name = e.target.value;
                this.setState({});
              }}/>
            </div>
            <div className="ui-g-2 ">
              <label> Description: </label>
            </div>
            <div className="ui-g-10 ">
              <InputTextarea value={nodeSettings.taskInWorkflowData.description}
                             style={{width:"100%"}}
                             onChange={(e) => {
                nodeSettings.taskInWorkflowData.description = e.target.value;
                this.setState({});
              }}/>
            </div>
            <div className="ui-g-2">
              <label> Document id: </label>
            </div>
            <div className="ui-g-10 ">
              <InputText value={nodeSettings.taskInWorkflowData.taskId}
                         readOnly={true}
                         style={{width:"100%"}}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Inputs">
          <DataTable value={prepareTableData('inputs')}>
            {nodeSettings.taskData.status === "DRAFT" &&
            <Column header="Set as constant" body={this.buttonTypeBody}/>}
            <Column field="name" header="Name"/>
            <Column field="description" header="Description"/>
            <Column header="Is List" field="isList" body={this.booleanTypeBody}/>
            <Column header="Type" body={this.portTypeBody}/>
            <Column field="required" header="Required" body={this.booleanTypeBody}/>
            <Column field="value" header="Value"/>
          </DataTable>
        </TabPanel>
        <TabPanel header="Constants">
          <DataTable value={prepareTableData('constants')}>
            {nodeSettings.taskData.status === "DRAFT" &&
            <Column header="Set as input" body={this.buttonTypeBody}/>}
            <Column field="name" header="Name"/>
            <Column field="description" header="Description"/>
            <Column header="Is List" field="isList" body={this.booleanTypeBody}/>
            <Column header="Type" body={this.portTypeBody}/>
            <Column field="required" header="Required" body={this.booleanTypeBody}/>
            <Column field="value" header="Value" body={this.constantValueBody}/>
          </DataTable>
          <NonModalSidebar
            visible={this.state.annotationEditorVisible}
            onHide={() => this.setState({annotationEditorVisible: false, editedAnnotationKey: null})}>

            {this.state.annotationEditorVisible &&
              <React.Fragment>
                <h1>Annotation Definition Selection</h1>
                <ContainerAnnotationTableSelection
                    selected={nodeSettings.taskData['constants'][editedAnnotationKey]['value']}
                    updateData={this.updateConstantsTable}
                    // onDuplicateClick={()=>{this.setState({annotationBuilderVisible:true,annotationEditorVisible:false})}}
                    // onCreateNewClick={()=>{this.setState({annotationBuilderVisible:true,annotationEditorVisible:false})}}
                />
              </React.Fragment>
            }

          </NonModalSidebar>
        </TabPanel>
        <TabPanel header="Outputs">
          <DataTable value={prepareTableData('outputs')}>
            <Column field="name" header="Name"/>
            <Column field="description" header="Description"/>
            <Column header="Is List" field="isList" body={this.booleanTypeBody}/>
            <Column header="Type" body={this.portTypeBody}/>
            <Column field="required" header="Required" body={this.booleanTypeBody}/>
            <Column field="value" header="Value"/>
          </DataTable>
        </TabPanel>
        <TabPanel header="Task Code">
          <AceEditor
            mode="json"
            readOnly={true}
            theme="github"
            name="EDITOR"
            highlightActiveLine={true}
            value={JSON.stringify(nodeSettings.taskData, null, '\t')}
            height="350px"
            width="100%"
            wrapEnabled={true}
          />
        </TabPanel>
        <TabPanel header="Tool Code">
          <AceEditor
            mode="json"
            readOnly={true}
            theme="github"
            name="EDITOR"
            highlightActiveLine={true}
            value={JSON.stringify(nodeSettings.toolData, null, '\t')}
            height="350px"
            width="100%"
            wrapEnabled={true}
          />
        </TabPanel>
        <TabPanel header="Help Panel">
          <ContainerTaskPropertiesHelpPanelTab
            taskData={nodeSettings.taskData}
            helpPanel={nodeSettings.taskData.helpPanel}
            updateHelpPanel={this.updateHelpPanel}
            constants={nodeSettings.taskData.constants}
            disabled={nodeSettings.status !== "DRAFT"}
          />
        </TabPanel>
      </TabView>

      }


    </React.Fragment>
  }
}

TaskPropertiesPanel.propTypes = {
  nodeSettings: PropTypes.object.isRequired,
  updateTransferTool: PropTypes.func.isRequired,
  annotationTables: PropTypes.object.isRequired,
  annotationTableColumns: PropTypes.object.isRequired,
  questions: PropTypes.object.isRequired,
  updateTransferObjectCache: PropTypes.func.isRequired
};