import toolSchema from "SPINE-json-schema/schemas/tool.schema"
//======================= ENVIRONMENT ==============================================================
export const HOST_URL = HOST_URL_ENV;
export const RECAPTCHA_API_KEY = RECAPTCHA_API_KEY_ENV;

//======================= AUTHORIZATION ==============================================================
export const COOKIE_TOKEN_NAME = 'TokenCookie';

//======================= DEFAULT VALUES ==============================================================
export const DEFAULT_USER_ID = '00000000-0000-0000-0000-000000000000';

//======================= DATA CONSTANTS=============================================================

// --------------------ACCOUNT VALIDATION STATUS VALUES---------------------------------------------------------------
export const VALIDATING = 'VALIDATING'; 
export const VALIDATION_ERROR = 'VALIDATION_ERROR'; 
export const VALIDATION_SUCCESS = 'VALIDATION_SUCCESS'; 

// --------------------REQUEST STATUS VALUES---------------------------------------------------------------
export const REQUEST_STATUS_REQUESTED = 'requested';
export const REQUEST_STATUS_FAIL = 'failure';
export const REQUEST_STATUS_SUCCESS = 'success';


//----------------------EXPERIMENT STATUS CONSTANTS------------------------------------------------------------
export const EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED = 'COMPLETED';
export const EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT = 'DRAFT';
export const EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW = 'REVIEW';
export const EXPERIMENT_PROPERTY_VALUE_STATUS__TRASHED = 'TRASHED';
export const EXPERIMENT_PROPERTY_VALUE_STATUS__EXECUTION = 'EXECUTION';


//------------------------VARIABLE VALUES------------------------------------------------------------
export const VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL = 'clinicalVariable';
export const VARIABLE_PROPERTY_VALUE_META_TYPE__IMAGING = 'imagingVariable';

export const VARIABLE_PROPERTIES_NAMES = {
  VARIABLE_TYPE: 'variableType',
  DESCRIPTION: 'description',
  META_TYPE: 'metaType',
  MIN_VALUE: 'minValue',
  NAME: 'name',
  STATISTICAL_TYPE: 'statisticalType',
  UNITS: 'units',
  UUID: 'uuid'
};


export const VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE = {
  NUMBER: 'Number',
  MULTIPLE_CHOICE: 'MultipleChoice',
  DATE: 'Date',
  TEXT: 'Text',


};


// -------------------- USER ROLES ---------------------------------------------
export const USER = {
  ROLES: {
    ADMIN: "admin",
    CONTRIBUTOR: "contributor",
    SCIENTIFIC: "scientific",
    DOCTOR: "doctor"
  }
};


//========================TYPE (OBJECT) PROPERTIES======================================================

//------------------------EXPERIMENT PROPERTIES-------------------------------------------------------------------
export const EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA = 'inclusion';
export const EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA = 'exclusion';
export const EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA = 'selectionCriteria';


export const EXPERIMENT_PROPERTY_NAME__COHORTS = 'cohortIds';  //AM scheme changed here ...
export const EXPERIMENT_PROPERTY_NAME__HYPOTHESIS = 'hypothesis';
export const EXPERIMENT_PROPERTY_NAME__NAME = 'name';
export const EXPERIMENT_PROPERTY_NAME__QUESTION = 'question';
export const EXPERIMENT_PROPERTY_NAME__DESCRIPTION = 'description';
export const EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES = 'systemVariablesForStatisticalModel';
export const EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID = 'statisticalModelId';
export const EXPERIMENT_PROPERTY_NAME__PROJECT_ID = 'projectId';
export const EXPERIMENT_PROPERTY_NAME__PRIVACY = 'privacy';
export const EXPERIMENT_PROPERTY_NAME__UUID = 'uuid';
export const EXPERIMENT_PROPERTY_NAME__REVISION_ID = 'revisionId';
export const EXPERIMENT_PROPERTY_NAME__TIMEFRAME = 'timeFrame';

//-----------------EXPERIMENT PROPERTIES VALUES----------------------


export const EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC = 'PUBLIC';
export const EXPERIMENT_PROPERTY_VALUE_PRIVACY__PRIVATE = 'PRIVATE';


//----------------EXPERIMENT INSTANCE VARIABLE PROPERTIES- (according to JOI)-------
export const EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID = "variableId";
export const EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__SYMBOL = 'symbol';
export const EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__WORKFLOW_ID = 'workflowId';


//----------------CRITERIA INSTANCE VARIABLE PROPERTIES- (according to JOI)-------
export const EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID = "variableId";
export const EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR = "operator";
export const EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE = "value";


// -----------------------TIMEFRAME------------------
export const TIMEFRAME_PROPERTIES_NAMES = {
  VARIABLE_ID: "variableId",
  REFERENCE_TIME_FRAME: "referenceTimeFrame",
  RELATIVE_REFERENCE_VARIABLE_ID: "relativeReferenceVariableId",
  PRIMARY_REFERENCE_TIME_POINT: "primaryReferenceTimePoint",
  TARGET_DATE: "targetDate",
  LOWER_DEVIATION_TIME_WINDOW: "lowerDeviationTimeWindow",
  UPPER_DEVIATION_TIME_WINDOW: "upperDeviationTimeWindow",
  UNIT: "unit"
};

export const TIMEFRAME_PROPERTY_VALUES__REFERENCE_TIME_FRAME = {
  RELATIVE: "relative",
  ABSOLUTE: "absolute"
};


//----------------COHORT PROPERTIES VALUES----------------------------------------
export const COHORT_PROPERTY_VALUE_PRIVACY__PUBLIC = 'public';
export const COHORT_PROPERTY_VALUE_PRIVACY__PRIVATE = 'private';


//----------------COHORT PROPERTIES-------------------------------------------------------------------
export const COHORT_PROPERTY_NAME__PRIVACY = 'privacy';
export const COHORT_PROPERTY_NAME__NAME = 'name';
export const COHORT_PROPERTY_NAME__UUID = 'uuid';
export const COHORT_PROPERTY_NAME__CREATION_DATE = 'creationDate';
export const COHORT_PROPERTY_NAME__COINVESTIGATORS = 'co-investigators';
export const COHORT_PROPERTY_NAME__SUBJECTS = 'subjects';
export const COHORT_PROPERTY_NAME__VISITS = 'visits';
export const COHORT_PROPERTY_NAME__DESCRIPTION = 'description';
export const COHORT_PROPERTY_NAME__PI = 'pi';
export const COHORT_PROPERTY_NAME__SUMMARY_CLINICAL_TRIALS = 'summaryClinicalTrials';
export const COHORT_PROPERTY_NAME__SERVER_NAME = 'serverName';
export const COHORT_PROPERTY_NAME__REMOTE_ID = 'remoteId';
export const COHORT_PROPERTY_NAME__BIDS_STANDARD = 'bidsStandard';
export const COHORT_PROPERTY_NAME__SOURCE_SYSTEM = 'sourceSystem';
export const COHORT_PROPERTY_NAME__SOURCE_OBJECT = 'sourceObject';
export const COHORT_PROPERTY_NAME__SOURCE_ETAG = 'sourceEtag';


//----------------ANNOTATION PROPERTIES-------------------------------------------------------------------
export const ANNOTATION_PROPERTY_NAME__ID = '_id'; //underscore just for annotation table cause
export const ANNOTATION_PROPERTY_NAME__ROI = '_roi'; //underscore just for annotation table cause
export const ANNOTATION_PROPERTY_NAME__RULER = '_ruler'; //underscore just for annotation table cause

export const ANNOTATION_PROPERTY_NAME__ANNOTATIONS = 'annotations';
export const ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB = 'roiSub';
export const ANNOTATION_PROPERTY_VALUES__ROI_ROI_STATUS = ["normal", "active"];
export const ROI_PROPERTY_NAME__PROPERTIES = 'roiProperties';
export const ROI_PROPERTY_NAME__IMAGE_COORDINATES = 'imageCoordinates';
export const ROI_PROPERTY_NAME__WORLD_COORDINATES = 'worldCoordinates';
export const ROI_PROPERTY_VALUE_TYPE__POINT = 'POINT';

export const RULER_PROPERTY_NAME__START = 'RULER_START';
export const RULER_PROPERTY_NAME__END = 'RULER_END';
export const RULER_PROPERTY_NAME__LINE = 'RULER_LINE';
export const RULER_PROPERTY_NAME__ANNOTATION_MODE = 'ANNOTATION_MODE';
export const RULER_PROPERTY_NAME__ANNOTATION_SOURCE = 'ANNOTATION_SOURCE';
export const RULER_PROPERTY_VALUES__ANNOTATION_SOURCE = ['FORM', 'TABLE'];

export const ANNOTATION_FORM = 'FORM';
export const ANNOTATION_TABLE = 'TABLE';

//----------------OPERATORS--------------------------------------------------------

export const ORDINAL_OPERATOR = "ORDINAL_OPERATOR";
export const LOGICAL_OPERATOR = "LOGICAL_OPERATOR";
export const CATEGORICAL_OPERATOR = "CATEGORICAL_OPERATOR";
export const TEXTUAL_OPERATOR = "TEXTUAL_OPERATOR";


export const LOGICAL_OPERATOR_INIT = {
  "type": "LOGICAL_OPERATOR",
  "label": "AND",
  "value": "AND"
};

// export const ALL_OPERATORS = ORDINAL_OPERATORS.concat(CATEGORICAL_OPERATORS,TEXTUAL_OPERATORS);
// constant for representation of +, - dropdowns
export const NUMBER_SIGN = {
  NAME: "NUMBER_SIGN",
  VALUES: {
    PLUS: 'PLUS',
    MINUS: 'MINUS'
  },
  DROPDOWN_OPTIONS: [{label: "+", value: "PLUS"}, {label: "-", value: "MINUS"}]
};


export const ORIENTATION_LUT = (orientationString) => {
  switch (orientationString.toLowerCase().trim()) {
    case "sagittal":
      return 0;
    case "coronal":
      return 1;
    case "axial":
      return 2;
  }
};


//============================= TOOLS ========================================================

export const TOOL_TYPE = {
  SLICE_SELECTOR: "SLICE_SELECTOR_TOOL",
  SEGMENTATION: "SEGMENTATION_TOOL",
  CONFIRMATION: "CONFIRMATION_TOOL",
  ANNOTATION: "ANNOTATION_TOOL",
  IDENTIFICATION: "IDENTIFICATION_TOOL",
  CHARACTERIZATION: "CHARACTERIZATION_TOOL",
  SLICE_SELECTOR_PICKER: "SLICE_SELECTOR_PICKER_TOOL",
  SLICE_SELECTOR_TOOL_RANGED: "SLICE_SELECTOR_TOOL_RANGED",
  SLICE_SELECTOR_WITH_PICKER: "SLICE_SELECTOR_WITH_PICKER",
  VISUALIZATION: "VISUALIZATION_TOOL",
};
Object.freeze(TOOL_TYPE);

export const SLICE_SELECTOR_TOOL_TYPE = "SLICE_SELECTOR_TOOL";
export const SEGMENTATION_TOOL_TYPE = "SEGMENTATION_TOOL";
export const CONFIRMATION_TOOL_TYPE = "CONFIRMATION_TOOL";
export const ANNOTATION_TOOL_TYPE = "ANNOTATION_TOOL";
export const IDENTIFICATION_TOOL_TYPE = "IDENTIFICATION_TOOL";
export const CHARACTERIZATION_TOOL_TYPE = "CHARACTERIZATION_TOOL";
export const SLICE_SELECTOR_PICKER_TOOL_TYPE = "SLICE_SELECTOR_PICKER_TOOL";
export const SLICE_SELECTOR_TOOL_RANGED = "SLICE_SELECTOR_TOOL_RANGED";
export const SLICE_SELECTOR_WITH_PICKER = "SLICE_SELECTOR_WITH_PICKER";
export const VISUALIZATION_TOOL = "VISUALIZATION_TOOL";


//FIXME Not used yet  - reading type of tools from schema json
//--------------------------Schema
const SCHEMA_TOOL_TYPES = new Map();
toolSchema.definitions.toolType.enum.forEach((el) => SCHEMA_TOOL_TYPES.set(el, el));

const set1 = new Set(Object.values(TOOL_TYPE));
const set2 = new Set(SCHEMA_TOOL_TYPES.values());
const compareSet = new Set([...Object.values(TOOL_TYPE), ...SCHEMA_TOOL_TYPES.values()]);
const isSetEqual = compareSet.size === set2.size && compareSet.size === set1.size;
console.log(isSetEqual);
// if (!isSetEqual) {
//
//     alert("Incorrect schema");
//     throw "Incorrect schema";
// }
// Finish me
console.log(compareSet);

//---------------------------Schema

//============================WIDGET TOOLS====================================================
export const POINTER_TOOL = "pointerTool";
export const PIN_TOOL = "pinTool";
export const BRUSH_TOOL = "brushTool";
export const DRAW_TOOL = "drawTool";
export const INFO_TOOL = "infoTool";
export const HIDE_LABELMAPS_TOOL = "hideLabelmapsTool";
export const FILLING_TOOL = "fillingTool";
export const INTENSITY_FILTER_TOOL = "intensityFilterTool";
export const SAMPLER_TOOL = "samplerTool";
export const PROJECTION_TOOL = "projectionTool";
export const LINK_ALL_TOOL = "linkAllTool";
export const SMOOTHING_ALL_TOOL = "smoothingAllTool";
export const WINDOW_LEVEL__ALL_TOOL = "windowLevelAllTool";
export const CASE_PROGRESS_TOOL = "caseProgressTool";
export const SEGMENTATION_METRICS_TOOL = "segmentationMetrics";
export const CASE_CONTROL_TOOL = "casesControl";
export const SLICE_POSITION_INDEX = "slicePositionIndexTool";
export const CUSTOM_CURSOR_TOOL = "customCursorTool";
export const FIDUCIAL_TOOL = "fiducialTool";
export const ECHO_TOOL = "echoTool";
export const RULER_TOOL = "rulerTool";
export const INVERSION_TOOL = "inversionTool";
export const CROSS_HAIR_TOOL = "crossHairTool";
export const ANNOTATION_OPACITY_TOOL = "annotationOpacity";
export const ANNOTATION_TABLE_TOOL = "annotationTableTool";
export const ROI_TABLE_TOOL = "roiTableTool";
export const LABELMAP_OPACITY_TOOL = "labelmapOpacity";
export const LABELMAP_LUT_TOOL = "labelmapLUT";
export const CAMERA_3D_TOOL = "camera3DTool";
export const ERASER_TOOL = "eraserTool";
export const LEVEL_TRACING_TOOL = "levelTracingTool";
export const LABELMAP_UNDO_REDO_TOOL = "labelmapUndoRedoTool";
export const PIN_UNDO_REDO_TOOL = "pinUndoRedoTool";
export const MAIN_TOOL = "mainTool";
export const MAIN_TOOL_PROPERTY_NAME_ACTIVE_VIEWER_ID = "activeViewerId";
export const MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE = "leftButtonMode";
export const MAIN_TOOL_PROPERTY_NAME_ANNOTATIONS_VISIBLE = "annotationsVisible";
export const MAIN_TOOL_PROPERTY_NAME_PROJECTIONS_VISIBLE = "projectionsVisible";
export const MAIN_TOOL_PROPERTY_NAME_SEGMENTATIONS_VISIBLE = "segmentationsVisible";

export const GRAPH_TOOL_LABEL_PROPERTY_CODE = "l";
export const GRAPH_TOOL_INTENSITY_PROPERTY_CODE = "i";

export const IMPORT_ANNOTATIONS_FROM_DISK_TOOL = "importAnnotationsFromDiskTool";
export const IMPORT_ANNOTATIONS_FROM_SERVER_TOOL = "importAnnotationsFromServerTool";
export const EXPORT_ANNOTATIONS_TO_SERVER_TOOL = "exportAnnotationsToServerTool";
export const EXPORT_ANNOTATIONS_TO_DISK_TOOL = "exportAnnotationsToDiskTool";
export const SPAWN_VIEWERS_WINDOW = "spawnViewersWindowTool";


//===============================MASK RULES====================================================
export const PREVENT_FROM_ADDING_PIN = "PREVENT_FROM_ADDING_PIN";
export const PREVENT_FROM_CHANGING_VOXEL_VALUE = "PREVENT_FROM_CHANGING_VOXEL_VALUE";

//============================== PERSONAL DATA =================================================

/**
 * Function to get static url to user picture.
 * @param id
 * @return {string}
 * @constructor
 */
export const IMAGE_URL = (id) => HOST_URL + "/api/user/" + id + "/picture";