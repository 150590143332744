import {withRouter} from "react-router";
import {connect} from "react-redux";
import {SAMPanel} from "../component/SAMPanel";
import {clearOverlayImageActionCreator, getImageActionCreator} from "../../expDesign/action/TabletGameExperimentAction";
import {clearViewersState, updateViewerProperty} from "../../visualization/action/ViewerAction";
import {
  initializeBlandAltmanPlot,
  initializeBlandAltmanPlotSegmentations, refreshDataAC
} from "../../visualization/action/InteractivePlotAction";
import {loadImageData} from "../../visualization/action/ImageAction";
import {calculateModelParams} from "../../expDesign/action/AuditAction";
import {clearDataset} from "../../expDesign/action/StatisticalModelAction";
import {
  clearStandardizedPlot,
  initializeStandardizedAgreementModel, onSelectedCellChange, updatePropertySAM, updateSelectedDataPoint
} from "../action/SAMAction";
import {getSelectedPlotSettings} from "../selectors/SAMSelectors";



//TODO Remove OK
const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    plotSettings: state.sam.plotSettings, // OK
    selectedPlotSettings: getSelectedPlotSettings(state),
    plotData:state.sam.plotData,
    plotDataWithDups:state.sam.plotDataWithDups,
    selectedLayout:state.sam.selectedLayout,
    selectedCell:state.sam.selectedCell,
    selectedDataPoint: state.sam.selectedDataPoint,
    plotDataState:state.sam.plotDataState,
    rawData:state.sam.rawData,
    modelParameters: state.sam.modelParameters,
    modelParametersState: state.sam.modelParametersState,
    images: state.visu.images.images,
    overlays: state.visu.images.overlays,
    viewersState: state.visu.viewers.viewersState,
  }
};


/**
 *
 * @param dispatch
 * @returns {{getTabletGameExperimentResults: function(*=), getImage: function(*=), clearOverlayImage: function(*=)}}
 */
const mapDispatchToProps = dispatch => {
  return {
    getImage: (imageProperties) => {
      dispatch(getImageActionCreator(imageProperties))
    },
    clearOverlayImage: (propName) => {
      dispatch(clearOverlayImageActionCreator(propName))
    },
    clearViewersState: () => {
      dispatch(clearViewersState())
    },
    updateViewerProperty: (key, property, value) => {
      dispatch(updateViewerProperty(key, property, value))
    },
    //TODO: Add another function that initializes the data using the avatarscatterplot endpoint with segmentations
    initializeBlandAltmanPlotSegmentations:(scientficEventId)=>{
      dispatch(initializeBlandAltmanPlotSegmentations(scientficEventId))
    },
    initializeBlandAltmanPlot:(scientficEventId)=>{
      dispatch(initializeBlandAltmanPlot(scientficEventId))
    },
    clearStandardizedPlot:()=>{
      dispatch(clearStandardizedPlot())
    },
    loadImageData: (imageId, format, url, type)=>{
      dispatch(loadImageData(imageId, format, url, type))
    },
    calculateParameters: (dim, data)=>{
      dispatch(calculateModelParams(dim,data))
    },
    clearDataset:()=>{
      dispatch(clearDataset())
    },
    refreshData:(scientificEventId)=>{
      dispatch(refreshDataAC(scientificEventId))
    },
    initializeStandardizedAgreementModel:(scientficEventId, snapshot)=>{
      dispatch(initializeStandardizedAgreementModel(scientficEventId, snapshot))
    },
    updateSelectedDataPoint:(selectedDataPoint)=>{
      dispatch(updateSelectedDataPoint(selectedDataPoint))
    },
    onChangeCell:(layoutCell)=>{
      dispatch(onSelectedCellChange(layoutCell))
    },
    updatePropertySAM:(property,value)=>{
      dispatch(updatePropertySAM(property,value))
    }
  }
};

export const ContainerSAMPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SAMPanel));