import axios from 'axios'
import {
    PROJECT_CREATE,
    PROJECT_FAIL,
    PROJECT_LIST_FAIL,
    PROJECT_LIST_REQUEST,
    PROJECT_LIST_SUCCESS, PROJECT_REQUEST,
    PROJECT_SUCCESS
} from './actionType'

import store from '../../Store'
import {HOST_URL} from "../../../Constants";

const requestProjectList = () => ({
    type: PROJECT_LIST_REQUEST
});

const successProjectList = (projects) => ({
    type: PROJECT_LIST_SUCCESS,
    projects
});

const failProjectList = (err) => ({
    type: PROJECT_LIST_FAIL,
    err
});

const addProject = (project) => ({
    type: PROJECT_CREATE,
    isFetching: true,
    isAuthenticated: false,
    project
});


const errorProject = err => ({
    type: PROJECT_FAIL,
    err
});

const successProject = (project) => ({
    type: PROJECT_SUCCESS,
    project
});

const requestProject = (project) => ({
    type: PROJECT_REQUEST,
    project
});


/**
 * Getting list of all projects.
 * @return {[type]}
 */
export const getProjectList = () => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        axios.get(HOST_URL + '/api/project')
            .then(response => {

                console.log('ProjectAction.js :: getProjectList :: response ::', response)
                if (response.status !== 200) {
                    dispatch(failProjectList(err))
                } else {
                    dispatch(successProjectList(response.data))
                }
            }).catch(error => {
            console.log('project err:', error);
            dispatch(failProjectList(error))
        });
    }
};

export const createProject = (proj) => {
    return dispatch => {
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        let bodyFormData = new FormData();
        bodyFormData.set('name', proj.name);
        bodyFormData.set('summary', proj.summary);
        bodyFormData.set('about', proj.about);
        bodyFormData.set('howCanIHelp', proj.howCanIHelp);
        bodyFormData.set('forMe', proj.forMe);
        console.log(store);

        axios.post(HOST_URL + '/api/project', bodyFormData, config)
            .then(response => {
                console.log('ProjectAction.js :: getProjectList :: response ::', response)
                if (response.status !== 200) {
                    // dispatch(failProjectList(err))
                    console.log(err);
                } else {
                    //dispatch(successProjectList(response.data))
                }
            }).catch(error => {
            console.log('project err:', error);
            //  dispatch(failProjectList(error))
        });
    }
};

//Get project (StudyList)
export const getProject = (idProject) => {
    return dispatch => {
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        console.log('ProjectAction.js :: getProject :: requested ::', idProject);
        dispatch(requestProject(idProject));
        return axios.get(HOST_URL + '/api/project/' + idProject,
            config
        ).then(response => {
            if (response.status !== 200) {
                dispatch(errorProject(response.data));
                return Promise.reject(response.data);
            } else {
                dispatch(successProject(response.data));
            }
        }).catch(err => dispatch(errorProject(err)));
    };
};