import React from "react";
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {getMeasurementShortLabel} from "../action/SAMAction";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Spinner} from "primereact/components/spinner/Spinner";
import {RadioButton} from "primereact/components/radiobutton/RadioButton";
import {Checkbox} from "primereact/components/checkbox/Checkbox";




/**
 * Component used for setting up model properties (regressand, regressors and covariates, interactions)
 * and associated plot (axes, lines, CI, PI).
 */

class RegressionModelPanelContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      axisXIndex: 0,
      axisYIndex: 1,
      family:null,
      formula:"",
      confint:0.95,
      predint:0.95, // not used yet
      showCI:false,
      showPI:false,
      measurementIndexToSymbolMapping:{},
      interactions:false
    };
    ["handleAdd","handleRemove","parseRFormula","updateState"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }


  updateState(){
    this.setState(
      {
        axisXIndex: this.props.axisXIndex,
        axisYIndex: this.props.axisYIndex,
        family: this.props.family,
        formula: this.props.formula,
        confint: this.props.confint,
        predint: this.props.predint,
        showCI:this.props.showCI,
        showPI:this.props.showPI,
        measurementIndexToSymbolMapping:this.props.measurementIndexToSymbolMapping,
        interactions:this.props.interactions
      }
    )
  }

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate(prevProps, prevState, ss) {
    if (prevState.dialogVisible !== this.state.dialogVisible && this.state.dialogVisible)
      this.updateState();
    if (this.state.dialogVisible && prevProps!==this.props) {
      this.setState({dialogVisible: false});
      this.props.updateRegressionPlotData();
    }
    if (prevState.measurementIndexToSymbolMapping!==this.state.measurementIndexToSymbolMapping
         || prevState.interactions!==this.state.interactions){
      this.setState({formula:this.parseRFormula()})
    }
  }

  /**
   * Calculate formula in R redable format eg. "Y~X1+X2", "Y~X1+X2+X1:X2"
   * @return {string}
   */
  parseRFormula(){
    const {measurementIndexToSymbolMapping,interactions} = this.state;
    let separator = interactions
      ? "*"   // replace with ":" notation in future
      : "+";

    let f = "Y~X1";
    let i = 2;
    let stop = false;
    do{
      if(measurementIndexToSymbolMapping.hasOwnProperty("X"+i))
        f=f.concat(separator+"X"+i);
      else stop=true;
      i=i+1;
    }while(!stop);
    return f;
  }

  handleAdd(){
    const {selectedMeasurementConfiguration} = this.props;
    const {measurementIndexToSymbolMapping} = this.state;
    let n = Object.keys(measurementIndexToSymbolMapping).length;
    let emptySlot = 0;
    for (let i = 0; i < selectedMeasurementConfiguration.length; i++){
      if(!Object.values(measurementIndexToSymbolMapping).includes(i)){
        emptySlot = i;
        break;
      }
    }
    measurementIndexToSymbolMapping["X"+n] = emptySlot;
    this.setState({
      measurementIndexToSymbolMapping:Object.assign({},measurementIndexToSymbolMapping)
    });
  }
  handleRemove(){
    const {measurementIndexToSymbolMapping} = this.state;
    let n = Object.keys(measurementIndexToSymbolMapping).length;
    delete measurementIndexToSymbolMapping["X"+(n-1)];
    this.setState({
      measurementIndexToSymbolMapping:Object.assign({},measurementIndexToSymbolMapping)
    });
  }

  render() {
    const {rawData, selectedMeasurementConfiguration, updatePlotSettingsProperty,updatePlotSettingsProperties} = this.props;
    const {measurementIndexToSymbolMapping,axisXIndex,axisYIndex,showCI,showPI} = this.state;

    const optionsForAxisSelection = selectedMeasurementConfiguration.map((el, index) => {
      return {value: index, label: getMeasurementShortLabel(el, rawData)}
    });

    const isDepAlreadySet = measurementIndexToSymbolMapping.hasOwnProperty("Y");
    const isIndepAlreadySet = Object.keys(measurementIndexToSymbolMapping).findIndex(el=>el.startsWith("X"))>-1;
    const allSet = selectedMeasurementConfiguration.length === Object.keys(measurementIndexToSymbolMapping).length;

    const independentSymbolsMap = Object.keys(measurementIndexToSymbolMapping).length>1
      ? Object.keys(measurementIndexToSymbolMapping)
        .filter(el=>el!=="Y")
        .map(el=>{
        return <div><label>{el} :</label><Dropdown
          style={bigDropdownStyle}
          onChange={(e) => {
            const target = {};
            target[el] =e.value;
            this.setState({measurementIndexToSymbolMapping: Object.assign({},measurementIndexToSymbolMapping,target)})
          }}
          options={optionsForAxisSelection}
          value={this.state.measurementIndexToSymbolMapping[el]}>
        </Dropdown>
        <RadioButton checked={measurementIndexToSymbolMapping[el]===axisXIndex}
                     onChange={(e)=>{
                       if (e.checked)
                         this.setState({axisXIndex:measurementIndexToSymbolMapping[el]})
                     }}/>
        </div>})
      : <div/>;


    return (
      <div>
        <h3>Model & Plot
          <Button onClick={() => {
            this.setState({dialogVisible: true})
          }}
                  icon={"fa fa-edit"}
                  title={"Edit"}
                  style={{float: "right"}}
          />
        </h3>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            {"Axis X: "}<b>{getMeasurementShortLabel(selectedMeasurementConfiguration[axisXIndex], rawData)}</b><br/>
            <React.Fragment>
              {"Axis Y: "}<b>{getMeasurementShortLabel(selectedMeasurementConfiguration[axisYIndex], rawData)}</b><br/>
            </React.Fragment>
          </div>
          <Dialog
            header={"Adjust model parameters"}
            width={800}
            height={600}
            visible={this.state.dialogVisible}
            appendTo={document.body}
            contentStyle={{height: "90%"}}
            onHide={() => {
              this.setState({dialogVisible: false})
            }}>

            <div>
              <div className="ui-g-12">
                <h5>Dependent variable</h5>
                <label>Y:</label><Dropdown
                style={bigDropdownStyle}
                onChange={(e) => {
                  this.setState({
                    measurementIndexToSymbolMapping: Object.assign({},measurementIndexToSymbolMapping, {Y: e.value}),
                    axisYIndex: e.value  // AXIS y always displays Y
                  })
                }}
                options={optionsForAxisSelection}
                value={this.state.measurementIndexToSymbolMapping.Y}>
                </Dropdown>

                <h5>Independent variables</h5>
                {independentSymbolsMap}

                <Button label={"Add"}
                        title={isDepAlreadySet ? "Add independent variable (regressor, covariate)" : allSet?"Number of measurements has been reached":"Please first set the dependent variable"}
                        disabled={!isDepAlreadySet || allSet}
                        onClick={this.handleAdd}
                />
                <Button label={"Remove"}
                        title={isIndepAlreadySet ? "Remove last independent variable (regressor, covariate)" : "There is no independent variable to remove"}
                        disabled={!isIndepAlreadySet}
                        onClick={this.handleRemove}
                />
              </div>


              <div className="ui-g-6" style={{marginBottom:"1em"}}>
                <Checkbox
                  inputId="interactionsId"
                  style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({interactions: e.checked})
                  }}
                  checked={this.state.interactions}>
                </Checkbox>
                <label htmlFor="interactionsId"
                       className="ui-checkbox-label">
                  {"Interactions"}
                </label>
                <Dropdown
                  inputId="family" style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({family: e.value})
                  }}
                  options={optionsForFamily}
                  value={this.state.family}>
                </Dropdown>
                <label htmlFor="family"
                       className="ui-checkbox-label">
                  {"Error distribution - family"}
                </label>
                <Spinner
                  inputId="confInt"
                  style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({confint: e.value})
                  }}
                  max={1}
                  min={0}
                  step={0.01}
                  value={this.state.confint}>
                </Spinner>
                <label htmlFor="confInt"
                       className="ui-checkbox-label"
                >
                  {"Confidence intervals"}
                </label>
                <Checkbox
                  inputId="showCI"
                  style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({showCI: e.checked})
                  }}
                  checked={showCI}>
                </Checkbox>
                <label htmlFor="showCI"
                       className="ui-checkbox-label"
                       title={"Confidence interval pertains to a statistic estimated from multiple values, in this case — the regression coefficient. " +
                       "It expresses sampling uncertainty, which comes from the fact that our data is just a random sample of the population we try to model"}
                >
                  {"Show confidence intervals"}
                </label>
                <Checkbox
                  inputId="showPI"
                  style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({showPI: e.checked})
                  }}
                  checked={showPI}>
                </Checkbox>
                <label htmlFor="showPI"
                       title={"Prediction interval expresses inherent uncertainty in the particular data point on top of the sampling uncertainty. It is thus wider than the confidence interval"}
                       className="ui-checkbox-label">
                  {"Show prediction intervals"}
                </label>
              </div>
              <div className={"ui-g-6"}>
                <div style={{textAlign:"center",fontSize:"larger"}}> R Formula: {this.state.formula}</div>
              </div>
            </div>
            <div  className={"ui-g-12"} style={{textAlign:"center"}}>
              <Button onClick={() => {
                updatePlotSettingsProperties({
                  "axisXIndex": this.state.axisXIndex,
                  "axisYIndex": this.state.axisYIndex,
                  "family": this.state.family,
                  "formula": this.state.formula,
                  "confint": this.state.confint,
                  "predint": this.state.predint,
                  "showCI": this.state.showCI,
                  "showPI": this.state.showPI,
                  "measurementIndexToSymbolMapping": this.state.measurementIndexToSymbolMapping,
                  "interactions": this.state.interactions});
              }} icon={"fa fa-check"} label={"OK"}/>
              <Button onClick={() => {
                this.setState({dialogVisible: false})
              }} icon={"fa fa-close"} label={"Cancel"}/>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default RegressionModelPanelContent;

RegressionModelPanelContent.propTypes = {
  rawData: PropTypes.object.isRequired,
  selectedMeasurementConfiguration: PropTypes.object.isRequired,
  updatePlotSettingsProperty: PropTypes.func.isRequired,
  updatePlotSettingsProperties: PropTypes.func.isRequired,
  updateRegressionPlotData: PropTypes.func.isRequired,
  axisYIndex: PropTypes.number.isRequired,
  axisXIndex: PropTypes.number.isRequired,
  measurementIndexToSymbolMapping:PropTypes.object.isRequired,
  interactions:PropTypes.bool.isRequired,
  messageQueue:PropTypes.object.isRequired
};

const optionsForFamily =  [
  {value: "gaussian", label:"Gaussian" },
  {value: "inverse.gaussian", label:"Inversed Gaussian"},
  {value: "poisson", label:"Poisson"},
  {value: "binomial", label:"Binomial"},
  {value: "quasibinomial", label:"quasi Binomial"},
  {value: "quasipoisson", label:"quasi Poisson"},
  {value: "Gamma", label:"Gamma"},
];

const bigDropdownStyle = {
  marginRight: "1em",
  marginLeft: "1em",
  width: "50em"
};