/**
 * Traverse tree in a Depth First manner with node processing
 * @param tree
 * @param current
 * @param process - function
 */
export const traverseTree = function(tree, current,process) {

  //process current
  process(current);

  //visit children
  for (let cki in current.children) {
    traverseTree(tree, current.children[cki],process);
  }
};


export const  searchParent = function(element, matchingKey) {
  if (element.children != null && element.children.find((el) => el.key === matchingKey)) {
    return element;
  } else if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchParent(element.children[i], matchingKey);
    }
    return result;
  }
  return null;
};


export const  searchTree = function(element, matchingKey) {
  if (element.key === matchingKey) {
    return element;
  } else if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], matchingKey);
    }
    return result;
  }
  return null;
};