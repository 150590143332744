import React from "react";
import PropTypes from "prop-types";
import {Dropdown} from "primereact/components/dropdown/Dropdown";


/**
 * Dropdown for selection of cases.
 * Uses TODO Put link to json-schema here!!!
 * @deprecated replaces with CasesSelectionOverlayPanel
 * @link
    */
class CasesSelectionDropdown extends React.Component {


    static itemTemplate(option) {
        const status =  (option.status!=null)?option.status.toUpperCase():"";

        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="ui-helper-clearfix">
                    <span style={{float: 'left', margin: '.5em .25em 0 0'}}>{option.label}</span>
                    {status === "DONE" &&
                    <i className="fa fa-check" title="Done"
                       style={{color: 'green', margin: '.5em .25em 0 0', float: 'right', verticalAlign: 'middle'}}/>
                    }
                    {status === "STARTED" &&
                    <i className="fa fa-spinner" title="In progress"
                       style={{color: 'orange', margin: '.5em .25em 0 0', float: 'right', verticalAlign: 'middle'}}/>
                    }
                    {status === "NOTSTARTED" &&
                    <i className="fa fa-square-o" title="Not started"
                       style={{color: 'red', margin: '.5em .25em 0 0', float: 'right', verticalAlign: 'middle'}}/>
                    }
                </div>
            );
        }
    };


    render() {
        const {listOfCases, currentCase, onChange} = this.props;
        // const options = [{label: "1", value: "123", status: "done"},{label: "2", value: "234", status: "inProgress"},{label: "3", value: "345", status: ""}];
        const options = listOfCases.map((el) => {
            return {
                label: el['caseNumber'],
                value: el['caseNumber'],
                status: el['caseStatus']
            }
        });
        return (<Dropdown value={currentCase}
                          options={options}
                          onChange={(e) => onChange(e.value)}
                          itemTemplate={CasesSelectionDropdown.itemTemplate}/>);
    }
}

export default CasesSelectionDropdown;

CasesSelectionDropdown.propTypes = {
    listOfCases: PropTypes.array.isRequired,
    currentCase: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};


