import macro from 'vtk.js/Sources/macro';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';

const { vtkErrorMacro } = macro;

// ----------------------------------------------------------------------------
// vtkImageOutlineLevelTracing methods
// Filter to create imageData segmented with connected components method
// ----------------------------------------------------------------------------

function vtkImageOutlineLevelTracing(publicAPI, model) {
  model.classHierarchy.push('vtkImageOutlineLevelTracing');

  const connectedOffset = []; // neighborhood matrix
  connectedOffset[0] = [[0, -1, 0], [0, 0, -1], [0, 1, 0], [0, 0, 1]];
  connectedOffset[1] = [[-1, 0, 0], [0, 0, -1], [1, 0, 0], [0, 0, 1]];
  connectedOffset[2] = [[-1, 0, 0], [0, -1, 0], [1, 0, 0], [0, 1, 0]];

  connectedOffset[3] = [[-1, 0, 0], [0, -1, 0], [0, 0, -1], [1, 0, 0], [0, 1, 0],[0, 0, 1]];

  function getIndex(point, dims) {
    return point[0] + point[1] * dims[0] + point[2] * dims[0] * dims[1];
  }

  publicAPI.requestData = (inData, outData) => {
    // implement requestData
    const input = inData[0];
    if (!input || input.getClassName() !== 'vtkImageData') {
      vtkErrorMacro('Invalid or missing input');
      return;
    }

    const output = vtkImageData.newInstance(
      input.get('spacing', 'origin', 'direction')
    );

    const dims = input.getDimensions();
    output.setDimensions(dims);
    output.computeTransforms();
    const values = new Uint8Array(input.getNumberOfPoints());

    const inputDataArray = input
      .getPointData()
      .getScalars()
      .getData();

    const threshold = inputDataArray[getIndex(model.seedPoint, dims)];
    const getValueAtPixel = (vec3) => inputDataArray[getIndex(vec3, dims)];

    const setValueAtPixel = (index, value) => {
      const ind = getIndex(index, dims);
      values[ind] = value;
    };

    const voxelCoords = model.seedPoint.slice();
    const offset = model.threeD?connectedOffset[3]:connectedOffset[model.slicingMode];
    let frontpix = [voxelCoords];

    while (frontpix.length > 0) {
      const tempfront = [];
      frontpix.forEach((front) => {
        offset.forEach((offs) => {
          const off = [];
          off[0] = offs[0] + front[0];
          off[1] = offs[1] + front[1];
          off[2] = offs[2] + front[2];
          if (
            off[0] >= 0 &&
            off[1] >= 0 &&
            off[2] >= 0 &&
            off[0] <= dims[0] - 1 &&
            off[1] <= dims[1] - 1 &&
            off[2] <= dims[2] - 1
          ) {
            const currentLabel = getValueAtPixel(off);
            if (
              model.levelFunction(currentLabel, threshold) &&
              values[getIndex(off, dims)] === 0
            ) {
              setValueAtPixel(off, model.label);
              tempfront.push(off);
            }
          }
        });
      });
      frontpix = tempfront;
    }

    setValueAtPixel(model.seedPoint, model.label); // add initial Point at the end of processing

    const dataArray = vtkDataArray.newInstance({
      numberOfComponents: 1,
      values,
    });
    output.getPointData().setScalars(dataArray);
    outData[0] = output;
  };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  slicingMode: 2,
  background: 0,
  seedPoint: [0, 0, 0],
  label: 1,
  threeD:false,
  levelFunction: (a, b) => a <= b,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Make this a VTK object
  macro.obj(publicAPI, model);

  // Also make it an algorithm with one input and one output
  macro.algo(publicAPI, model, 1, 1);

  macro.setGet(publicAPI, model, [
    'slicingMode',
    'background',
    'seedPoint',
    'label',
    'levelFunction',
    'threeD'
  ]);

  // Object specific methods
  vtkImageOutlineLevelTracing(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  'vtkImageOutlineLevelTracing'
);

// ----------------------------------------------------------------------------

export default { newInstance, extend };
