import {connect} from "react-redux";
import {withRouter} from 'react-router';
import PlaySkill from "../component/PlaySkill";
import {
    getSlideListForSkill, updateCurrentStepIndexActionCreator, goToNextSkillStep, goToPreviousSkillStep,
    clearSkillStateAC
} from "../action/SkillAction";



// Used by connect()
const mapStateToProps = state => {
    return {
        slides: state.skill.skill.slides,
        skill:  state.skill,
        currentStepIndex:state.skill.skill.currentStepIndex,
    }
};
/**
 *
 * @param dispatch
 * @return {{getSlides: function(*=)}}
 */
const mapDispatchToProps = dispatch => {
    return {
        getSlides: (id) => {
            dispatch(getSlideListForSkill(id))
        },
        updateCurrentStepIndex: (index)=>{
            dispatch(updateCurrentStepIndexActionCreator(index));
        },
        clearSkillState:()=>{
            dispatch(clearSkillStateAC());
        },
        goToNextStep:()=>{
            dispatch(goToNextSkillStep());
        },
        goToPreviousStep:()=>{
            dispatch(goToPreviousSkillStep());
        },
    }
};

export const ContainerPlaySkill = withRouter(connect( mapStateToProps, mapDispatchToProps)(PlaySkill));

