/**
 * Trimmer for String
 * Use: stringObject.trimEllip(25)
 * @param length
 * @returns {string}
 */
export const trimEllip = function (str,length) {
    return str.length > length ? str.substring(0, length) + " ..." : str;
};


/**
 * Simple uuid generator for dynamic lists.
 * @returns {string}
 */
export const getUniqueId = ()=> {return Math.random().toString(36).substring(2)
    + (new Date()).getTime().toString(36)};


/**
 * Another uuid generator.
 * @returns {string}
 */
export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
        return v.toString(16);
    });
}
