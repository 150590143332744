import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import LayoutChooser from "../../expDesign/component/d3components/LayoutChooser";
import LayoutElementSelector from "../../expDesign/component/d3components/LayoutElementSelector";
import DownloadLink from "react-download-link";
import {FileInputButton} from "../../root/component/FileInputButton";
import ExportCSVDialog from "./ExportCSVDialog";


/**
 * Component used for SELECTION of Layout.
 */

class LayoutMultiPlotPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      layoutChooserVisible: false,
      exportDialogVisible:false
    };
    ['loadConfiguration'].forEach(name => { this[name] = this[name].bind(this);});
  }
  loadConfiguration(file){
    const {messageQueue,updateState,refreshAllPlots}=this.props;
    const handleFileRead = (e) => {
      const object = JSON.parse(fileReader.result.toString());
      if (object.hasOwnProperty("selectedLayout")) { // TODO Finish here with AJV
        object['manualToolConfiguration']=null;
        updateState(object);
        refreshAllPlots();
      }
      else{
        messageQueue.show( {
          sticky: false,
          severity: 'error',
          summary: 'Error',
          detail:"There was a problem with loading."
        });
      }
    };
    let fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  }

  render() {
    const {duplicatePlotSettings,copyPlotSettingsToClipboard,selectedLayout,selectedCell,onChangeCell,onChangeLayout,fullState} = this.props;
    const {exportDialogVisible} = this.state;

    return (
      <div style={{height: '8em'}} className={"ui-g-12"}>
        <div style={{height: '8em'}} className={"ui-g-6"}>
          <Button icon={"fa fa-th"} label={"Select layout"}
                  onClick={()=>{this.setState({layoutChooserVisible:!this.state.layoutChooserVisible})}}/>
          <LayoutChooser
            visible={this.state.layoutChooserVisible}
            onChange={(cell)=>{onChangeLayout(cell)}}
            onClick={()=>{this.setState({layoutChooserVisible:!this.state.layoutChooserVisible})}}
            selectedCell={selectedLayout}
          />
          <LayoutElementSelector
            visible={true}
            onChange={(cell)=>{onChangeCell(cell)}}
            selectedCell={selectedCell}
            selectedLayout={selectedLayout}
          />
        </div>
        <div style={{height: '5em'}} className={"ui-g-6"}>
          <Button icon={"fa fa-copy"}
                  title={"Copy configuration to clipboard"}
                  onClick={()=>{copyPlotSettingsToClipboard()}}/>
          <Button icon={"fa fa-clipboard"}
                  title={"Paste configuration from clipboard"}
                  onClick={()=>{duplicatePlotSettings(false)}}/>
          <Button icon={"fa fa-th"}
                  title={"Paste configuration from clipboard to all"}
                  onClick={()=>{duplicatePlotSettings(true)}}/>
          <span title={"Save configuration to local disk"}>
                    <DownloadLink
                      className={"ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only fa fa-save"}
                      style={{color:"white"}}
                      label={""}
                      filename="SAM.json"
                      tagName={"i"}
                      exportFile={() => JSON.stringify(fullState)}/>
           </span>
          <Button icon={"fa fa fa-file-excel-o"}
                  title={"Save data to local disk"}
                  onClick={()=>{this.setState({exportDialogVisible:true})}}/>
          <span title={"Load configuration from your local disk"}>
                    <FileInputButton
                      style={{display: "inline-block"}}
                      label=""
                      filter="application/json"
                      onChange={e => this.loadConfiguration(e.target.files[0]) }
                    />
          </span>
        </div>
        <ExportCSVDialog
          fullState={fullState}
          exportDialogVisible={exportDialogVisible}
          onHide={()=>{this.setState({exportDialogVisible:false})}}/>
      </div>
    )
  }
}

export default LayoutMultiPlotPanel;

LayoutMultiPlotPanel.propTypes = {
  copyPlotSettingsToClipboard:PropTypes.func.isRequired,
  duplicatePlotSettings:PropTypes.func.isRequired,
  selectedLayout:PropTypes.object.isRequired,
  selectedCell:PropTypes.object.isRequired,
  onChangeLayout:PropTypes.func.isRequired,
  onChangeCell:PropTypes.func.isRequired,
  fullState:PropTypes.object.isRequired,
  updateState:PropTypes.func.isRequired,
  refreshAllPlots: PropTypes.func.isRequired
};
