export const GEO_UPDATE_VIEW = 'GEO_UPDATE_VIEW';
export const GEO_UPDATE_SITES ='GEO_UPDATE_SITES';
export const GEO_UPDATE_BASEMAP = 'GEO_UPDATE_BASEMAP';
export const GEO_UPDATE_BLEND_MODE = 'GEO_UPDATE_BLEND_MODE';
export const GEO_UPDATE_OPACITY = 'GEO_UPDATE_OPACITY';
export const GEO_UPDATE_FILL_STYLE='GEO_UPDATE_FILL_STYLE';
export const GEO_UPDATE_LEGEND = 'GEO_UPDATE_LEGEND';
export const GEO_UPDATE_MARKER_SIZE="GEO_UPDATE_MARKER_SIZE";
export const GEO_UPDATE_LAYER_STATE="GEO_UPDATE_LAYER_STATE";
export const GEO_UPDATE_LAYERS="GEO_UPDATE_LAYERS";
export const GEO_CLEAR = 'GEO_CLEAR';