import React from "react";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";

const MIDDLE = "middle";  //id of left vie

/**
 * Layout component for displaying viewers panel with Single element.
 *
 */
class SingleLayout extends React.Component {
    static getLabel(){
        return "Single viewer with maximized viewport"
    }
    static getClassName(){
        return "oneViewerWithoutPickerDemo"
    }
    static getViewersPortKeys(){
        return [MIDDLE];
    }
    constructor(props) {
        super(props);
        this.state = {
            widthMain: "80vh",
            heightMain: "80vh"
        };
        ["calcLayout"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }
    componentDidMount() {
        console.log("Row Layout");
    }
    componentDidUpdate(prevProps, prevState) {
    }
    componentWillUnmount() {
    }
    /** Calculates the size of viewers.
     *  Still it is not final version,
     **/
    calcLayout() {
        if (window.innerWidth < window.innerHeight) {
            this.setState({widthMain: "80vw", heightMain: "80vw"});
            console.log("Portait mode");
        }
        if (window.innerWidth > window.innerHeight) {
            this.setState({widthMain: "80vh", heightMain: "80vh"});
            console.log("Landscape mode");
        }
    }
    render() {
        const {toolComponentProps, toolComponentState} = this.props;
        const viewersState = toolComponentProps.viewersState;

        const middle = (viewersState != null && Object.keys(viewersState).length > 0)
            ? BrowserFactory.build(MIDDLE, toolComponentProps, toolComponentState)
            : <div className="ui-g-12" style={{padding: "0.1em"}}/>;

        return (
            <div className="ui-g-12">
            <div className="viewerContainerFull">
                <div className="viewerContainerSidePanel">
                    <div className="viewerContainerSide"/>
                    <div className="viewerContainerSide"/>
                </div>
                <div className="viewerContainerMainPanel">
                    <div style={{padding: "0.1em", height: this.state.heightMain, width: this.state.widthMain}}>
                        {middle}
                    </div>
                </div>
                <div className="viewerContainerSidePanel">
                    <div className="viewerContainerSide"/>
                    <div className="viewerContainerSide"/>
                </div>
            </div>
            </div>
        )
    }

}

export default SingleLayout;

SingleLayout.defaultProps = {};

SingleLayout.propTypes = {};


