import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { login, register, forgotPassword, getProfileInformationAndRoles } from '../action/LoginAction.jsx'
import RedirectLogin from '../component/RedirectLogin'

const mapStateToProps = state => {
  return{
  	loginStatus : state.auth.login_status
  }
};

const mapDispatchToProps = dispatch => ({

  	onLogin : (creds, onSuccess, onError) => {
  	  dispatch(login(creds, onSuccess, onError))
  	} ,
    onRegister : () => {
        dispatch(register())
    } ,
    onForgotPassword : () => {
        dispatch(forgotPassword())
    },
    getSession: (onSuccess, onError) => {
        dispatch(getProfileInformationAndRoles(onSuccess, onError))
    }

});

const ContainerRedirectLogin = withRouter(connect(
 mapStateToProps,
 mapDispatchToProps)(RedirectLogin));

export default ContainerRedirectLogin;
