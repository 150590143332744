import React from 'react'
import {scaleLinear, scaleSequential} from "d3-scale";
import {event as d3Event, select, selectAll} from "d3-selection";
import {axisBottom, axisLeft} from "d3-axis";
import {line} from "d3-shape";
import d3Tip from "d3-tip"
import {extent} from "d3-array";
import PropTypes from "prop-types";
import {xyzoom, xyzoomTransform} from "d3-xyzoom";
import {interpolateRainbow} from "d3-scale-chromatic";
import {REQUEST_STATUS_FAIL} from "../../../../Constants";
import {withTranslation} from "react-i18next";
import {sum, map} from "lodash";

/** D3-based Bland-Altman component integrated with React.
 *
 *  Implementation is based on AvatarScatterPlot.
 *  Each datapoint can be represent in 2 modes:
 *   - duplicated result (number of cases > 1) - click provides fake data points placed above with interactions as regular results,
 *   - regular result (click provides manual tool for data (ROIs and Annotations)).
 *
 * The most important property is modelParameters, which is object responsed by Rserver/plumber
 * (function bland.altman.stats , see https://cran.r-project.org/web/packages/BlandAltmanLeh/BlandAltmanLeh.pdf).
 *
 */
class BlandAltmanPlot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objects: null
        };
        this.zoomTransform = {};
        ["createPlot", "stdDeviation"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    componentDidMount() {
        const {outerWidth} = this.props;
        if (outerWidth > 0)
            this.createPlot();
    }

    componentDidUpdate(previousProps, previousState, ss) {
        const node = this.node;
        const {prefixId, outerWidth,outerHeight} = this.props;

        //needed for initialization - if width was previously 0 and slide was inactive, the plot needs to be redrawn
        if ((previousProps.outerWidth !== outerWidth && outerWidth != null && outerWidth > 0)
            || (previousProps.outerHeight !== outerHeight && outerHeight != null && outerHeight > 0)
            || (previousProps.viewId !== this.props.viewId)
            || ((previousProps.statsServerState !== this.props.statsServerState) &&
                this.props.statsServerState === REQUEST_STATUS_FAIL)
        ) {
            select(node).selectAll("*").remove();
            selectAll(".d3-tip." + prefixId + "-tip").remove(); //removes all elements only for active panel (prefixId)
            selectAll(".d3-tip." + prefixId + "-tip.n").remove(); //removes all elements only for active panel
            this.createPlot();
        }
        if (previousProps.statsServerState !== this.props.statsServerState) {
            select(node).selectAll("*").remove();
            selectAll(".d3-tip." + prefixId + "-tip").remove(); //removes all elements only for active panel (prefixId)
            selectAll(".d3-tip." + prefixId + "-tip.n").remove(); //removes all elements only for active panel
            this.createPlot();
        }
    }

    stdDeviation (array) {
        // Based on https://gist.github.com/venning/b6593f965773985f923f
        let avg = sum(array) / array.length;
        return [Math.sqrt(sum(map(array, (i) => Math.pow((i - avg), 2))) / array.length), avg];
    };

    createPlot() {
        const {
            yLabelTip, xLabelTip, xLabel, prefixId, colorCat, stroke, zoomRatio, selected,viewId,
            data, colorLUT, opacityMode, yLabel, showXTickValues, showYTickValues, outerWidth, outerHeight,
            xTipValuePrecision, yTipValuePrecision, renderXLabel, renderYLabel, avatarSize, onDataPointClick,
            limitLineColor, limitLineWidth, modelParameters, meanLineColor, meanLineWidth, t,
            percentageDifference, onContextClick
        } = this.props;
        const node = this.node;   //svg ref
        // references to global vars  - initialized here
        this.zoomTransform = {};

        // set the dimensions and margins of the graph
        let margin = {
                top: 20,
                right: 50,
                bottom: 50,
                left: 50
            },
            width = outerWidth - margin.left - margin.right,
            height = outerHeight - margin.top - margin.bottom;

        const DOT_RADIUS = avatarSize;


        // set the ranges
        let xScale = scaleLinear()
            .range([0, width]);//.nice();
        let yScale = scaleLinear()
            .range([height, 0]);//.nice();

        //-------------------y domain----------------------------------
        // let yDomain = extent(data, yValue);
        let yDomain = extent(modelParameters['diffs']);
        let yDomainValues = modelParameters['diffs'];
        let meanDiffs = modelParameters['mean.diffs'][0];
        let lowerLimit = modelParameters['lower.limit'][0];
        let upperLimit = modelParameters['upper.limit'][0];
        const isLowerLimitNaN = isNaN(lowerLimit);
        const isUpperLimitNaN = isNaN(upperLimit);

        if(percentageDifference){
            yDomainValues = modelParameters['diffs'].map((diff, idx)=>{
                return (diff/modelParameters['means'][idx])*100 || 0;
            });
            yDomain = extent(yDomainValues);
            const [percentageDeviation, avgDeviation] = this.stdDeviation(yDomainValues);
            meanDiffs = avgDeviation;
            lowerLimit = avgDeviation - (2 * percentageDeviation);
            upperLimit = avgDeviation + (2 * percentageDeviation);
        }
        yDomain[0] = isLowerLimitNaN ? yDomain[0] : (yDomain[0] > lowerLimit) ? lowerLimit : yDomain[0];
        yDomain[1] = isUpperLimitNaN ? yDomain[1] :  (yDomain[1] < upperLimit) ? upperLimit : yDomain[1];
        let ydiff = (yDomain[1] - yDomain[0]) / 20; //adding margins to data (because of avatar permiter)
        // yScale.domain(yDomain);
        if(ydiff === 0){
            ydiff = 1;
        }
        yDomain = [yDomain[0] - ydiff, yDomain[1] + ydiff];


        yScale.domain(yDomain);


        //-------------------x domain----------------------------------

        // let xDomain = extent(data, xValue);
        let xDomain = extent(modelParameters['means']);
        let xdiff = (xDomain[1] - xDomain[0]) / 20; //adding margins to data (because of avatar permiter)
        // xScale.domain(xDomain);
        if(xdiff === 0){
            xdiff = 1;
        }
        xDomain = [xDomain[0] - xdiff, xDomain[1] + xdiff];
        xScale.domain(xDomain);

        //------------------- setting axis-------------------------------
        let xAxis = axisBottom(xScale);
        let yAxis = axisLeft(yScale);
        let color = scaleSequential(interpolateRainbow);

        if (!showXTickValues) xAxis.tickValues([]); //remove ticks if they are not needed
        if (!showYTickValues) yAxis.tickValues([]); //remove ticks if they are not needed

        // set  tips
        let tip = d3Tip()
            .attr("class", "d3-tip " + prefixId + "-tip")  //prefix added to distinguish tips on many panels - d3Tip uses global variables
            .offset([-DOT_RADIUS, 0])
            .html(function (d) {
                if (d.hasOwnProperty('isDuplicated'))
                    return "Overlapping data points.<br/>" + "Click to show/hide all.";
                if (d.hasOwnProperty('isCollectiveResult'))
                    return "Case: " + d['realCase'] + "<br/>" + "Group 1: " + d['x'].toFixed(yTipValuePrecision) + "<br/>" + "Group 2: " + d['y'].toFixed(yTipValuePrecision);
                return "User no" + ": " + d['userId'] + "<br/>"
                    + yLabelTip + ": " + d['y'].toFixed(yTipValuePrecision) + "<br/>"
                    + xLabelTip + ": " + d['x'].toFixed(xTipValuePrecision);
            });

        //zoom parameters and handler assignment
        let zooming = xyzoom()
            .on("zoom", zoomEventHandler);


        let avatarClass = [];
        if (opacityMode === "normal")
            avatarClass = ["selectedAvatar", "normalAvatar"];
        else
            avatarClass = ["normalAvatar", "selectedAvatar"];


        // append the svg object to the body of the page and translate the parent group
        // calculate the rectangle embraced by axes
        let svg = select(node)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(zooming)
            .on("dblclick.zoom", null)
            .on('contextmenu', function (e) {
                if (onContextClick!=null) {
                    d3Event.preventDefault();
                    d3Event.stopPropagation();
                    onContextClick(d3Event, null, () => {
                    }); //second parameter is callback to unselect
                }
          });

        svg.call(tip);

        svg.append("rect")
            .attr("width", width)
            .attr("height", height);

        // x Axis---------------------------
        if (renderXLabel) {
            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis)
                .append("text")
                .attr("class", "label")
                .attr("fill", "#000")
                .attr("font-size", "0.7em")
                .attr("x", width)
                .attr("y", margin.bottom - 10)
                .style("text-anchor", "end")
                .text(xLabel);
        } else svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);

        // y Axis---------------------------
        if (renderYLabel) {
            svg.append("g")
                .attr("class", "y axis")
                .call(yAxis)
                .append("text")
                .attr("class", "label")
                .attr("font-size", "0.7em")
                .attr("fill", "#000")
                .attr("transform", "rotate(-90)")
                .attr("y", -margin.left)
                .attr("dy", "1.1em")
                .style("text-anchor", "end")
                .text(yLabel);
        } else svg.append("g")
            .attr("class", "y axis")
            .call(yAxis);


        //=== rendering  lines BEGIN=========================

        let upperLine = null;
        let upperTitle = null;
        if (!isUpperLimitNaN){
            upperLine = svg.append("path")
                           .datum([{x: xScale.domain()[0], y: 0}, {
                                x: xScale.domain()[1],
                                y: 0
                            }]) //most extreme values in domain of scale X
                           .attr("id", "regression")
                           .attr("stroke", limitLineColor)
                           .attr("fill", "none")
                           .attr("stroke-width", limitLineWidth)
                           .attr("d", line().x(function (d) {
                                                return xScale(d.x);
                                            })
                                            .y(function (d) {
                                                return yScale(upperLimit);
                                            }));

            upperTitle = svg.append("text")
                            .attr("class", "rsquared-text")
                            .attr("x", width)
                            .attr("y", yScale(upperLimit))
                            .attr("text-anchor", "end")
                            .attr("dominant-baseline", "text-after-edge")
                            .attr('fill', limitLineColor)
                            .style('font-size', '1em')
                            .text("Limit of Agreement");
        }

        let lowerLine = null;
        let lowerTitle = null;
        if (!isLowerLimitNaN){
            lowerLine = svg.append("path")
                            .datum([{x: xScale.domain()[0], y: 0}, {
                                x: xScale.domain()[1],
                                y: 0
                            }]) //most extreme values in domain of scale X
                            .attr("id", "regression")
                            .attr("stroke", limitLineColor)
                            .attr("fill", "none")
                            .attr("stroke-width", limitLineWidth)
                            .attr("d", line().x(function (d) {
                                                return xScale(d.x);
                                            })
                                            .y(function (d) {
                                                return yScale(lowerLimit);
                                            }));
            lowerTitle = svg.append("text")
                            .attr("class", "rsquared-text")
                            .attr("x", width)
                            .attr("y", yScale(lowerLimit))
                            .attr("text-anchor", "end")
                            .attr("dominant-baseline", "text-after-edge")
                            .attr('fill', limitLineColor)
                            .style('font-size', '1em')
                            .text("Limit of Agreement");
        }

        let meanLine = svg.append("path")
            .datum([{x: xScale.domain()[0], y: 0}, {
                x: xScale.domain()[1],
                y: 0
            }]) //most extreme values in domain of scale X
            .attr("id", "regression")
            .attr("stroke", meanLineColor)
            .attr("fill", "none")
            .attr("stroke-width", meanLineWidth)
            .attr("d", line()
                .x(function (d) {
                    return xScale(d.x);
                })
                .y(function (d) {
                    return yScale(meanDiffs);
                }));
        let meanTitle = svg.append("text")
            .attr("class", "rsquared-text")
            .attr("x", width)
            .attr("y", yScale(meanDiffs))
            .attr("text-anchor", "end")
            .attr("dominant-baseline", "text-after-edge")
            .attr('fill', meanLineColor)
            .style('font-size', '1em')
            .text("Mean");

        //=== rendering  lines END=========================

        let objects = svg.append("svg")
            .attr("class", "objects")
            .attr("width", width)
            .attr("height", height);
        this.setState({objects: objects});
        if (!(data != null))
            return;

        let elem = objects
            .selectAll("node-group")
            .data(data);

        // instead of setting separately positions of texts and icons, groups are positioned

        const createIdForNodeGroup = (dataPoint)=>{
            if (dataPoint.hasOwnProperty('isDuplicated'))
                return 'dupId'+ viewId.concat('dataId',dataPoint.userId);
            return viewId.concat('dataId',dataPoint.userId);
        };

        let elemEnter = elem
            .enter()
            .append("g")
            .attr("id", createIdForNodeGroup)
            .attr("class", "node-group")
            .style('display', function (d) {
                if (d.hasOwnProperty('isDuplicateOf')) {
                    return 'none';
                } else
                    return 'block';
            })
            .attr("transform", function (d, index) {
                // Set d.x and d.y here so that other elements can use it. d is expected to be an object here.
                // If value is duplicate, find the "origin" one and read values.
                const duplicateIndex = (d['isDuplicateOf']!=null)?data.findIndex(el=>el['userId']===d['isDuplicateOf']):index;
                const res = "translate(" + xScale(modelParameters['means'][duplicateIndex]) + "," + yScale(yDomainValues[duplicateIndex]) + ")";
                return res;
            });


        const classFunction = (d) => {
            if (!d.hasOwnProperty('isCollectiveResult') && !d.hasOwnProperty('isDuplicated'))
                return 'img';
            else
                return 'icon';
        };

        /**
         * Translate and freeze relatively to parent node all nodes that have duplicated value.
         * @param d
         * @return {*}
         */
        const translateDuplicates = (d) => {
            if (d.hasOwnProperty('isDuplicateOf')) {
                return  "translate(" + (-(d["duplicateNo"] - 1) * 2 * DOT_RADIUS) + "," + (-2 * DOT_RADIUS) + ")";
            }
            return null;
        };

        const getColor = (d) => {
            if (colorLUT != null && colorLUT.length > 0 && d[colorCat]){
                return colorLUT[d[colorCat]];
            } else {
                return "darkorange";
            }            
        };

        const fillFunction = (d) => {
            if (d.hasOwnProperty('isDuplicated'))
                return "black";
            else return getColor(d);
        };
        // data   (order is important here - data after lines)
        let kolka = elemEnter
            .append("circle")
            .attr("class", classFunction)
            .attr("transform", translateDuplicates)
            .classed(avatarClass[0], true)
            .classed(avatarClass[1], (d) => {
                if (selected != null) return selected.userId === d.userId;
                return false;
            })
            .attr("r", DOT_RADIUS - stroke)
            .style("fill", fillFunction)
            .style("stroke", getColor)
            .style("stroke-width", stroke)
            .on("mouseout", function (d) {
                select(this).style("cursor", "default");
                tip.hide(d, this);
            })
            .on("mouseover", function (d) {
                select(this).style("cursor", "pointer");
                select(this).raise();
                tip.show(d, this);
            })
            .on("click", function (e) {
                tip.hide();
                let selected = select(this).classed("selectedAvatar");
                svg.selectAll("circle")
                    .classed("selectedAvatar", false)
                    .style("stroke", getColor);
                if (e.hasOwnProperty('isDuplicated')) {
                    select(this)
                        .classed("selectedAvatar", !selected);
                    data.filter((el) => e['userId'] === el['isDuplicateOf'])
                        .forEach((el) => {
                            select("#".concat(viewId,'dataId',el['userId']))
                              .style('display', selected ? 'block' : 'none');
                        });
                    return;
                }
                select(this)
                    .classed("selectedAvatar", true)
                    .style("stroke", "red");
                onDataPointClick(e, () => select(this)
                    .classed("selectedAvatar", false)
                    .style("stroke", getColor)); //second parameter is callback to unselect
            })
            .on('contextmenu', function (e) {
                if (onContextClick!=null) {
                    d3Event.preventDefault();
                    d3Event.stopPropagation();
                    tip.hide();
                    let selected = select(this).classed("selectedAvatar");
                    svg.selectAll("circle")
                      .classed("selectedAvatar", false)
                      .style("stroke", getColor);
                    if (e.hasOwnProperty('isDuplicated')) {
                        select(this)
                          .classed("selectedAvatar", !selected);
                        data.filter((el) => e['userId'] === el['isDuplicateOf'])
                          .forEach((el) => {
                              select("#".concat(viewId,'dataId',el['userId']))
                                .style('display', selected ? 'block' : 'none');
                          });
                        return;
                    }
                    select(this)
                      .classed("selectedAvatar", true)
                      .style("stroke", "red");
                    onContextClick(d3Event, e, () => select(this)
                      .classed("selectedAvatar", false)
                      .style("stroke", getColor)); //second parameter is callback to unselect
                    // alert('context menu');
                }
            });

        // }


        this.zoomTransform = xyzoomTransform(svg.node());

        let oldTransform = this.zoomTransform;

        /**
         * Unfinished.
         * Check how to change extents, domains etc. in order to make possible use
         * zoom in X direction, and then in Y, and again X etc.
         * So far ctrl+wheel -> zoom X
         * shift + wheel -> zoom y
         * no ctrl, no shift -> zoom both x and y
         */
        function zoomEventHandler() {
            let new_xScale = xScale;
            let new_yScale = yScale;
            const ev = d3Event;

            if (ev != null && ev.type === "zoom") {
                const temp = xyzoomTransform(svg.node());

                if (!ev.sourceEvent.shiftKey) {
                    oldTransform.kx = temp.kx;
                    oldTransform.x = temp.x;
                    new_xScale = oldTransform.rescaleX(xScale);
                    svg.select(".x.axis").call(xAxis.scale(new_xScale));
                }
                if (!ev.sourceEvent.ctrlKey) {
                    oldTransform.ky = temp.ky;
                    oldTransform.y = temp.y;
                    new_yScale = oldTransform.rescaleY(yScale);
                    svg.select(".y.axis").call(yAxis.scale(new_yScale));
                }
                // oldTransform =  xyzoomTransform(svg.node(),oldTransform);
                console.log('old transform', oldTransform);

                if(upperLine != null){
                    upperLine
                        .datum([{x: new_xScale.domain()[0], y: 0}, {x: new_xScale.domain()[1], y: 0}])
                        .attr("d", line()
                            .x(function (d) {
                                return new_xScale(d.x);
                            })
                            .y(function (d) {
                                return new_yScale(upperLimit);
                            }));

                    upperTitle.attr("x", width)
                        .attr("y", new_yScale(upperLimit));
                }
                if(lowerLine != null){
                    lowerLine
                        .datum([{x: new_xScale.domain()[0], y: 0}, {x: new_xScale.domain()[1], y: 0}])
                        .attr("d", line()
                            .x(function (d) {
                                return new_xScale(d.x);
                            })
                            .y(function (d) {
                                return new_yScale(lowerLimit);
                            }));
                    lowerTitle.attr("x", width)
                        .attr("y", new_yScale(lowerLimit));
                }

                meanLine
                    .datum([{x: new_xScale.domain()[0], y: 0}, {x: new_xScale.domain()[1], y: 0}])
                    .attr("d", line()
                        .x(function (d) {
                            return new_xScale(d.x);
                        })
                        .y(function (d) {
                            return new_yScale(meanDiffs);
                        }));
                meanTitle.attr("x", width)
                    .attr("y", new_yScale(meanDiffs));

                svg.selectAll(".node-group")
                    .attr("transform", function (d, index) {
                        // Set d.x and d.y here so that other elements can use it. d is expected to be an object here.
                        const duplicateIndex = (d['isDuplicateOf']!=null)?data.findIndex(el=>el['userId']===d['isDuplicateOf']):index; //handle duplicates differently
                        return "translate(" + new_xScale(modelParameters['means'][duplicateIndex]) + "," + new_yScale(yDomainValues[duplicateIndex]) + ")";
                    });


            }
        }
    }

    render() {
        return <svg ref={node => this.node = node}/>;
    }
}

export default withTranslation()(BlandAltmanPlot);

BlandAltmanPlot.defaultProps = {
    stroke: 4,
    zoomRatio: 2,
    opacityMode: "normal",
    yLabel: "Difference",
    xLabel: "Mean",
    yLabelTip: "Difference",
    xLabelTip: "Mean",
    showXTickValues: true,
    showYTickValues: true,
    outerWidth: 500,
    outerHeight: 300,
    xTipValuePrecision: 2,
    yTipValuePrecision: 2,
    renderXLabel: true,
    renderYLabel: true,
    avatarSize: 9,
    limitLineColor: "#0000FF",
    limitLineWidth: 1,
    meanLineColor: "#E4002B",
    meanLineWidth: 1
};

BlandAltmanPlot.propTypes = {
    percentageDifference: PropTypes.bool,
    limitLineColor: PropTypes.string.isRequired,
    limitLineWidth: PropTypes.number.isRequired,
    meanLineColor: PropTypes.string.isRequired,
    meanLineWidth: PropTypes.number.isRequired,
    contextMenu: PropTypes.array,
    data: PropTypes.array.isRequired,
    outerWidth: PropTypes.number.isRequired,
    outerHeight: PropTypes.number.isRequired,
    onDataPointClick: PropTypes.func,
    onContextClick: PropTypes.func, // optional function to provide on context handler
    colorCat: PropTypes.string,
    colorLUT: PropTypes.array, //array of user provided colors
    opacityMode: PropTypes.string,
    viewId: PropTypes.string,
    prefixId: PropTypes.string.isRequired, // required by external Tip library - since many elements on page can have the same id
    stroke: PropTypes.number,
    zoomRatio: PropTypes.number,
    renderXLabel: PropTypes.bool.isRequired, //should label for X Axis be rendered
    renderYLabel: PropTypes.bool.isRequired, //should label for Y Axis be rendered
    xLabel: PropTypes.string.isRequired, //label for displaying in Axis
    yLabel: PropTypes.string.isRequired, //label for displaying in Axis
    xLabelTip: PropTypes.string.isRequired, //label for displaying in tips
    yLabelTip: PropTypes.string.isRequired, //label for displaying in tips
    xTipValuePrecision: PropTypes.number.isRequired, //precision of float number for displaying in tips
    yTipValuePrecision: PropTypes.number.isRequired, //precision of float number for displaying in tips
    showXTickValues: PropTypes.bool.isRequired, //show ticks on Axis or not
    showYTickValues: PropTypes.bool.isRequired,  //show ticks on Axis or not
    avatarSize: PropTypes.number.isRequired, //adjustable size of avatar
    statsServerState: PropTypes.string,
    modelParameters: PropTypes.object.isRequired //model compliant with bland.altman.stats, see https://cran.r-project.org/web/packages/BlandAltmanLeh/BlandAltmanLeh.pdf
};
