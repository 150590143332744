import React from 'react'
import {Route, withRouter} from 'react-router'
import SingleResultReview from "./SingleResultReview";


class SingleResultReviewRouter extends React.Component {

    render() {
        const {match} = this.props;
        return (
            <React.Fragment>
              <Route exact path={`${match.path}/`} component={SingleResultReview}/>
            </React.Fragment>

        )
    }

}

export default withRouter(SingleResultReviewRouter)
