import {connect} from "react-redux";
import React from "react";
import {
  clearAnnotatorState, clearSaveAnnotationsTableDefinition,
  getAnnotationsDefinitionsList, getAnnotationsList,
  initializeAnnotationTable,
  parseAndLoadAnnotationDefinition, saveAnnotationsTableDefinition, successAnnotationsDefinition,
  updateAnnotationData
} from "../action/AnnotationAction";
import AnnotationTableBuilder from "../component/annotationTool/AnnotationTableBuilder";
import {getAllTasksList} from "../../expDesign/action/TaskAction";




const mapStateToProps = state => {
  return {
    messageQueue: state.messaging.msgQueue,
    annotationsDefinitionsListState:state.visu.annotations.annotationsDefinitionsListState,
    annotationsDefinitionsList:state.visu.annotations.annotationsDefinitionsList,
    saveAnnotationState:state.visu.saveAnnotations.saveAnnotationState,
    saveAnnotationError:state.visu.saveAnnotations.saveAnnotationError,
    saveAnnotationResponse:state.visu.saveAnnotations.saveAnnotationResponse,
    allColumns: state.visu.annotations.annotationsList,
    allColumnsState: state.visu.annotations.annotationsListState,
    data: state.visu.annotations.annotationsData,
    tasks: state.expDesign.tasks.tasks,
    taskListState: state.expDesign.tasks.taskListState,
    manualToolState: state.visu.manualTool.manualToolState
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAnnotationData:(data)=>{
      dispatch(updateAnnotationData(data))
    },
    initialize:()=>{
      dispatch(initializeAnnotationTable())
    },
    getAnnotationsDefinitionsList:()=>{
      dispatch(getAnnotationsDefinitionsList())
    },
    getAnnotationsList:()=>{
      dispatch(getAnnotationsList())
    },
    successAnnotationsDefinition:(columns)=>{
      dispatch(successAnnotationsDefinition(columns))
    },
    clearAnnotatorState:()=>{
      dispatch(clearAnnotatorState())
    },
    getTasks: () => {
      dispatch(getAllTasksList())
    },
    saveAnnotationsTableDefinition:(mode,payload)=>{
      dispatch(saveAnnotationsTableDefinition(mode,payload))
    },
    clearSaveAnnotationsTableDefinition:()=>{
      dispatch(clearSaveAnnotationsTableDefinition())
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);


export const ContainerAnnotationTableBuilder = containerCreator(AnnotationTableBuilder);