import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/es/Link";
import {withRouter} from "react-router";

const LpItemCardElement = ({item,history}) => {

    function delete_presentation(){
        alert("delete");
    }

    return (
        <React.Fragment>
            <div className="experimentListElement card-shadow" style={{marginTop: "30px"}}>
                <div className = "ui-g-4"><b>{item.name}</b></div>
                {/*<div className = "ui-g-2">{item.status}</div>*/}
                {/*<div className = "ui-g-2">*/}
                {/*    <Link to={'/livePresenter/playAuto/' + item.uuid}>Play Auto</Link>*/}
                {/*</div>*/}
                <div className = "ui-g-2">
                    <Link to={'/livePresenter/playManual/' + item.uuid}>Play </Link>
                </div>
                {/*<div className = "ui-g-1">{item.time_min}:{String("0" + item.time_sec).slice(-2)}</div>*/}
                {/*<div className = "ui-g-2">*/}
                {/*    <Link to={'/livePresenter/' + item.uuid}>Edit</Link>*/}
                {/*</div>*/}
                {/*<div className = "ui-g-2 delete-slide" onClick={delete_presentation}>*/}
                {/*    Delete*/}
                {/*</div>*/}
            </div>
            {/* <div class="skill-card">
                <header class="skill-card__header"><img class="skill-card__icon" src="https://upload.wikimedia.org/wikipedia/commons/3/38/HTML5_Badge.svg" alt="HTML5 Logo"/></header>
                <section class="skill-card__body">
                    <h2 class="skill-card__title">{item.name}</h2>
                    <ul class="skill-card__knowledge">
                        <li>{item.status}</li>
                        <li><Link to={'/livePresenter/playManual/' + item.id}>Manual</Link></li>
                        <li><Link to={'/livePresenter/playAuto/' + item.id}>Auto</Link></li>
                        <li><Link to={'/livePresenter/' + item.id}>Edit</Link></li>
                        <li><div onClick={delete_presentation}>Delete</div></li>
                    </ul>
                </section>
            </div> */}
        </React.Fragment>
    )
};
export default withRouter(LpItemCardElement);

LpItemCardElement.propTypes = {
    item: PropTypes.object.isRequired
};