import {connect} from "react-redux";
import WidgetToolbar from "../component/annotationTool/WidgetToolbar";
import {updateManualToolProperty} from "../action/ManualToolAction";
import {clearAllAnnotations, updateActiveROI, updateAnnotationData,saveAnnotationDataToServer} from "../action/AnnotationAction";
import {updateViewerProperty} from "../action/ViewerAction";
import ImportExportToolbar from "../component/annotationTool/ImportExportToolbar";




const mapStateToProps = (state, ownProps) => {
    return {
        manualToolState: state.visu.manualTool.manualToolState,
        manualToolConfiguration: state.visu.manualTool.manualToolConfiguration,
        viewersState: state.visu.viewers.viewersState,
        annotationsData: state.visu.annotations.annotationsData,
        annotationsDefinition: state.visu.annotations.annotationsDefinition,
        annotationsDefinitionDocumentId: state.visu.annotations.annotationsDefinitionDocumentId,
        activeRoiId: state.visu.annotations.activeROI,
        messageQueue: state.messaging.msgQueue
    }
};

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        updateAnnotationData:(data)=>{
            dispatch(updateAnnotationData(data))
        },
        saveAnnotationDataToServer:(materializedTaskId,data)=>{
            dispatch(saveAnnotationDataToServer(materializedTaskId,data))
        },
        updateActiveROI:(data)=>{
            dispatch(updateActiveROI(data))
        },
        updateManualToolProperty: (key, property, value) => {
            dispatch(updateManualToolProperty(key, property, value))
        },
        updateViewerProperty:(index,property,value)=>{
            dispatch(updateViewerProperty(index,property,value))
        },
        clearAll : ()=>{
            dispatch(clearAllAnnotations())
        },
    }
};

export const ContainerImportExportToolbar =connect( mapStateToProps, mapDispatchToProps)(ImportExportToolbar);
