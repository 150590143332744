import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {
  ANNOTATION_PROPERTY_NAME__ID,
  REQUEST_STATUS_FAIL,
  REQUEST_STATUS_REQUESTED,
  REQUEST_STATUS_SUCCESS
} from "../../../../Constants";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {StatusColumn} from "./StatusColumn";
import {CUSTOM_ELEMENT} from "./AnnotationCell";
import {TreeTable} from "primereact/components/treetable/TreeTable";
import {traverseTree} from "../../../helpers/trees";
import {uuidv4} from "../../../helpers/strings";
import {InputText} from "primereact/components/inputtext/InputText";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Dialog} from "primereact/components/dialog/Dialog";
import {ContainerAnnotationQuestionSelection} from "../../containers/ContainerAnnotationQuestionSelection";
import {HierarchicalDropdown} from "./HierarchicalDropdown";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {NonModalSidebar} from "../../../root/component/NonModalSidebar";
import AnnotationOptionsEditor from "./AnnotationOptionsEditor";


const getInitialState = () => {
  return {
    _id: uuidv4(),
    name: "",
    placeholder: "",
    iri: "",
    type: "simple-dropdown",
    value: "",
    required: null,
    questionId: null,
    options: null,
    optionsDetails:null
  }
};

/**
 * Form for creating/editing annotation column.
 */
class AnnotationColumnBuilder extends React.Component {

  constructor(props) {
    super(props);
    this.state = Object.assign(getInitialState(), {
      selectedRow: null,
      selectedStatus: "ALL",
      expandedRows: null,
      isQuestionSelectionVisible:false,
      isOptionsEditorVisible:false
    });

    ["onRowSelect", "onRowUnselect", "onRowEdit", "onRowDelete", "renderQuestion", "filterQuestions", "rowExpansionTemplate"
      , "renderIRIOptionsDetails", "onSaveAnnotation", "onClear","onQuestionSelection","optionsShortRenderer"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  static generateLink(link) {
    return (<a target="_blank" href={link} title={"Open ".concat(link, " in external window")}>
      {link.substring(link.lastIndexOf('/') + 1)}
    </a>);
  }

  componentDidMount() {
    if (this.props.getAnnotationsList != null)
      this.props.getAnnotationsList();
    if (this.props.getQuestionsList != null)
      this.props.getQuestionsList();
    if (this.props.getOntologyList != null)
      this.props.getOntologyList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {saveAnnotationError, saveAnnotationResponse, saveAnnotationState, messageQueue, getAnnotationsList} = this.props;
    if (prevProps.saveAnnotationState !== saveAnnotationState) {
      if (saveAnnotationState === REQUEST_STATUS_SUCCESS) {
        messageQueue.show({
          sticky: false,
          severity: 'info',
          summary: 'Saved Annotation Definition',
          detail: "A new annotation has been saved in ".concat(saveAnnotationResponse.status, " mode.")
        });
        if (getAnnotationsList != null)
          getAnnotationsList();
        if (saveAnnotationResponse.status!=="DRAFT"){//clear editor after publishing, otherwise keep it
          this.onClear();
        }
      }
      if (saveAnnotationState === REQUEST_STATUS_FAIL) {
        messageQueue.show({
          sticky: false,
          severity: 'error',
          summary: 'Error during Saving',
          detail: saveAnnotationError.message
        });
      }
    }
  }


  componentWillUnmount() {
    const{clearAnnotatorState,clearSaveAnnotationsTableDefinition}=this.props;
    if (clearAnnotatorState)
      clearAnnotatorState();
    if (clearSaveAnnotationsTableDefinition)
      clearSaveAnnotationsTableDefinition();
  }

  onRowSelect(e) {
    this.setState({
      selectedRow: Object.assign({}, e.data)
    });
  }

  onQuestionSelection(selectedQuestion){
    this.setState({questionId:selectedQuestion._id, isQuestionSelectionVisible:false})
  }

  onRowUnselect() {
    this.setState({selectedRow: null})
  }

  onRowEdit() {
    const {selectedRow} = this.state;
    this.setState({
      questionId: selectedRow.reference!=null?selectedRow.reference.questionId:null,
      _id: "DRAFT" === selectedRow.status ? selectedRow[ANNOTATION_PROPERTY_NAME__ID] : uuidv4(),
      name: selectedRow.definition.name != null ? selectedRow.definition.name : "",
      placeholder: selectedRow.definition.placeholder != null ? selectedRow.definition.placeholder : "",
      type: selectedRow.definition.type,
      value: selectedRow.definition.value != null ? selectedRow.definition.value : "",
      iri: selectedRow.definition.iri != null ? selectedRow.definition.iri : "",
      options: selectedRow.definition.options,
      optionsDetails: selectedRow.definition.optionsDetails,
      required: (selectedRow.definition.validation != null) ? selectedRow.definition.validation.required : null
    });

  }

  onRowDelete() {
    const {messageQueue} = this.props;
    const {selectedRow} = this.state;
    messageQueue.show({
      sticky: false,
      severity: 'info',
      summary: 'Delete',
      detail: "Column ".concat(selectedRow[ANNOTATION_PROPERTY_NAME__ID], " has been removed from SPINE knowledge base. Warning: This feature has not been yet implemented!.")
    });
    this.onRowUnselect();
  }

  onSaveAnnotation(mode) {
    const {_id, name, placeholder, iri, type, value, required,questionId,options,optionsDetails} = this.state;
    const {messageQueue, saveAnnotationColumn} = this.props;

    if (!(questionId != null)) {
      messageQueue.show({
        sticky: false,
        severity: 'error',
        summary: 'Validation Error',
        detail: "Question is required."
      });
      return;
    }

    const transferObject = {
        "_id": _id,
        "status": mode === "draft" ? "DRAFT" : "PUBLISHED",
        "definition": {
          "name": name,
          "placeholder": placeholder,
          "iri": iri,
          "type": type,
          "value": type===CUSTOM_ELEMENT.RULER.type?null:value
        }
      };
    if (required!=null){
      transferObject['definition']["validation"]={"required":required};
    }
     transferObject['reference']={"questionId":questionId};

    if (options!=null){
      transferObject['definition']['options']=options;
    }
    if (optionsDetails!=null){
      transferObject['definition']['optionsDetails']=optionsDetails;
    }
    saveAnnotationColumn(mode, transferObject);
  }

  onClear() {
    this.setState(
      {...getInitialState()}
    );
  }

  renderQuestion(row) {
    const {questionList, questionListState} = this.props;

    if (questionListState === REQUEST_STATUS_SUCCESS && questionList != null && questionList.length > 0) {

      if (row != null && row.reference != null && row.reference.questionId != null) {
        const question = questionList.find((el) => {
          return el[ANNOTATION_PROPERTY_NAME__ID] === row.reference.questionId
        });
        if (question != null)
          return question.questionText;
      }
    }
    return "NA";
  }

  filterQuestions(value, filter) {
    const {questionList, questionListState} = this.props;
    if (filter === undefined || filter === null || (typeof filter !== 'string') || questionListState !== REQUEST_STATUS_SUCCESS) {
      return true;
    }
    let result = false;
    if (value != null && value.questionId != null) {
      const ont = questionList.find((o) => {
        return o._id === value.questionId
      });
      if (ont != null) {
        result = ont.questionText.toLowerCase().includes(filter.toLowerCase());
      }
    }
    return result;
  }

  renderValidation(row) {
    if (row['definition'] != null && row['definition']['validation'] != null)
      return JSON.stringify(row['definition']['validation']);
    else return " ";
  }

  renderIRI(row) {
    if (row['definition'] != null && row['definition']['iri'] != null)
      return AnnotationColumnBuilder.generateLink(row['definition']['iri']);
    else return " ";
  }

  renderIRIOptions(row) {
    if (row != null && row['iri'] != null)
      return AnnotationColumnBuilder.generateLink(row['iri']);
    else return " ";
  }

  renderIRIOptionsDetails(row, key, prop) {
    const {ontologyList, ontologyListState} = this.props;
    if (row.definition.optionsDetails != null && row.definition.optionsDetails[key]) {
      if (prop === "ontologyClassIri") {
        return AnnotationColumnBuilder.generateLink(row.definition.optionsDetails[key][prop])
      }
      if (ontologyListState === REQUEST_STATUS_SUCCESS) {
        const ont = ontologyList.find((el) => {
          return el._id === row.definition.optionsDetails[key][prop]
        });
        if (ont != null) {
          return ont.name;
        } else return row.definition.optionsDetails[key][prop];
      } else return row.definition.optionsDetails[key][prop];
    } else return "";
  }

  rowExpansionTemplate(row) {
    if (row.definition.type === CUSTOM_ELEMENT.HIERARCHICAL.type) {

      const processing = (el) => {
        el['data'] = {};
        el['data']['title'] = el.title;
        el['data']['value'] = el.value;
        el['data']['iri'] = el.iri;
        if (el.iri != null)
          el['data']['iri'] = AnnotationColumnBuilder.generateLink(el['iri']);
      };
      const newData = [row.definition.options];
      traverseTree(newData, newData[0], processing);
      return (<div>
        <h5>{row.definition.name} > Properties Tree:</h5>
        <TreeTable value={newData} emptyMessage={"No options found"}>
          <Column field="title" header="Title" expander/>
          <Column field="value" header="Value"/>
          <Column field="iri" header="IRI"/>
        </TreeTable>
      </div>);
    }
    if (row.definition.type === CUSTOM_ELEMENT.MULTIPLE_CHOICE.type) {
      return (
        <div>
          <h5>{row.definition.name} > Options:</h5>
          <DataTable value={row.definition.options} emptyMessage={"No options found"}>
            <Column header="Label" body={(r) => {
              return r
            }}/>
            <Column header="Ontology" body={(r) => {
              return this.renderIRIOptionsDetails(row, r, "ontologyId")
            }}/>
            <Column header="IRI" body={(r) => {
              return this.renderIRIOptionsDetails(row, r, "ontologyClassIri")
            }}/>
          </DataTable>
        </div>
      );
    }
    return (
      <div>
        <h5>{row.definition.name} > Options:</h5>
        <DataTable value={row.definition.options} emptyMessage={"No options found"}>
          <Column field="label" header="Label"/>
          <Column field="value" header="value"/>
          <Column field="iri" header="IRI" body={this.renderIRIOptions}/>
        </DataTable>
      </div>
    );
  }

  optionsShortRenderer(){
    const {type,options}=this.state;

    if (!(options!=null))
      return <span>EMPTY</span>;


    const itemTemplate = (option) => {
      if (!(option.iri != null)) {
        return option.label;
      } else {
        return (
          <div className="ui-helper-clearfix">
            <span style={{float: 'left', margin: '.5em .25em 0 0'}}>{option.label}</span>
            <a target="_blank" href={option.iri} style={{float: 'right'}}>
              <i className="fa fa-external-link" title="External link to definition"/>
            </a>
          </div>
        );
      }
    };
    if (type===CUSTOM_ELEMENT.TEXT.type || type===CUSTOM_ELEMENT.RULER.type){
      return <span>NA</span>
    }

    if (type===CUSTOM_ELEMENT.HIERARCHICAL.type){
        return  <HierarchicalDropdown value={null}
                                      parentNode={options}
                                      onFocus={()=>{}}
                                      updateValue={(val) => {}}
        />
    }
    if (type===CUSTOM_ELEMENT.LIST.type){
        return   <Dropdown itemTemplate={itemTemplate}
                           value={null}
                           options={options}
                           onChange={(e) => {}}
                           style={{width: '20em'}}
        />
    }
    if (type===CUSTOM_ELEMENT.CHOICE.type){
      return <div>{options.map((el) => {
        return <div style={{width: "100%"}} >
          <input type="radio"
                 value={el.value}
                 checked={false}
                 style={{width: "1em"}}
                 onChange={(e) => {}}
                 onFocus={()=>{}}
                 autoFocus={false}
          />
          <span> {el.label}</span>
        </div>
      })}</div>;
    }
    if (type===CUSTOM_ELEMENT.MULTIPLE_CHOICE.type){
      return <div >
        {options.map((el) => {
          return <div style={{width: "100%"}}  >
            <Checkbox value={el}
                      onChange={(e) => {}}
                      checked={false}/>
            <span> {el}</span>
          </div>
        })}</div>;
    }
  }

  render() {
    const {allColumns, allColumnsState, updateData, saveAnnotationState,ontologyList,messageQueue} = this.props;
    const {isQuestionSelectionVisible, isOptionsEditorVisible, selectedRow, selectedStatus,
      expandedRows, _id, name, questionId, placeholder, value, type, required,options,optionsDetails} = this.state;

    return (
      <div className={"ui-g"}>
        <div className={"ui-g-12"}>
          {allColumnsState === REQUEST_STATUS_SUCCESS
          && <DataTable
            ref={(el) => this.dt = el}
            sortField="status"
            sortOrder={-1}
            value={allColumns}
            expandedRows={expandedRows}
            onRowToggle={(e) => this.setState({expandedRows: e.data})}
            rows={10}
            paginator={true}
            selection={selectedRow}
            onRowSelect={this.onRowSelect}
            rowExpansionTemplate={this.rowExpansionTemplate}
            selectionMode="single"
            resizableColumns={true}
            onSelectionChange={() => {
            }}>
            {StatusColumn(selectedStatus, (value) => {
              this.setState({selectedStatus: value});
              this.dt.filter(value, 'status', 'custom')
            })}
            <Column field="_id" header={"id"} sortable={true} filter={true} filterMatchMode="contains"
                    style={{overflowX: "hidden"}}/>
            <Column field="reference" header={"Question"} body={this.renderQuestion} filter={true}
                    filterMatchMode="custom" filterFunction={this.filterQuestions}/>
            <Column field="definition.name" header={"Name"} sortable={true} filter={true} filterMatchMode="contains"/>
            <Column field="definition.placeholder" header={"Placeholder"} sortable={true} filter={true}
                    filterMatchMode="contains"/>
            <Column field="definition.iri" body={this.renderIRI} header={"IRI"} sortable={true} filter={true}
                    filterMatchMode="contains" style={{overflowX: "hidden"}}/>
            <Column field="definition.type" header={"Type"} sortable={true} filter={true} filterMatchMode="contains"/>
            <Column field="definition.value" header={"Value"} sortable={true} filter={true} filterMatchMode="contains"/>
            <Column field="definition.validation" header={"Validation"} body={this.renderValidation}/>
            <Column field="definition.options" header={"Options"} expander={true} style={{width: "7em"}}/>
          </DataTable>}
        </div>
        <div className={"ui-g-12"}>
          <Button label={(selectedRow != null && "DRAFT" === selectedRow.status) ? "View/Edit" : "View/Duplicate"}
                  disabled={!(selectedRow != null)} onClick={this.onRowEdit}/>
          <Button label={"Delete"} disabled={!(selectedRow != null && "DRAFT" === selectedRow.status)}
                  onClick={this.onRowDelete} title={"You can delete selected question in DRAFT mode only"}/>
          <Button label={"Unselect"} disabled={!(selectedRow != null)} onClick={this.onRowUnselect}/>
          <Button label={"Collapse All Expanded"} disabled={!(expandedRows != null)}
                  onClick={() => this.setState({expandedRows: null})}/>
        </div>
        <div className={"ui-g-12"}>
          <h2>Add/Edit Annotation</h2>
          <div style={{display:"flex"}}>
            <div>
              <div style={{margin: "1em"}}>
                <span style={{width: "6em", display: "inline-block"}}>Id:</span>
                <input type={"text"} value={_id}
                       className={"ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-disabled"}
                       readOnly={true} style={{width: "20em"}}/>
              </div>
              <div style={{margin: "1em"}}>
                <span style={{width: "6em", display: "inline-block"}}>Name:</span>
                <InputText type={"text"} value={name}
                           style={{width: "20em"}} onChange={(e) => this.setState({name: e.target.value})}/>
              </div>
              <div style={{margin: "1em"}}>
                <span style={{width: "6em", display: "inline-block"}}>Placeholder:</span>
                <InputText type={"text"} value={placeholder}
                           style={{width: "20em"}} onChange={(e) => this.setState({placeholder: e.target.value})}/>
              </div>
              <div style={{margin: "1em"}}>
                <span style={{width: "6em", display: "inline-block"}}>Is required?</span>
                <Dropdown
                  onChange={(e)=>this.setState({required:e.value})}
                  options={[{label: "true", value: true}, {label: "false", value: false}, {label: "Empty", value: null}]}
                  value={required}/>
              </div>
            </div>
            <div>
              <div style={{margin: "1em"}}>
                <span style={{width: "6em", display: "inline-block"}}>Question</span>
                <InputText value={this.renderQuestion({reference:{questionId:questionId}})}
                           readOnly={true}
                           style={{width: "20em"}} />
                <Button icon={"fa fa-edit"} title={"Set question"} onClick={()=>{this.setState({isQuestionSelectionVisible:true})}}/>
                <Dialog
                  onHide={()=>{this.setState({isQuestionSelectionVisible:false})}}
                  visible={isQuestionSelectionVisible}
                  header={"Select question"} width="80vw" height="60vh">
                  {isQuestionSelectionVisible &&
                  <ContainerAnnotationQuestionSelection selected={questionId}
                                                        onSave={this.onQuestionSelection}
                                                        onClose={()=>this.setState({isQuestionSelectionVisible:false})}/>}
                </Dialog>
              </div>
              <div style={{margin: "1em"}}>
                <span style={{width: "6em", display: "inline-block"}}>Type:</span>
                <Dropdown style={{width: "20em"}} options={Object.keys(CUSTOM_ELEMENT).map((key) => {
                  return {label: CUSTOM_ELEMENT[key].type, value: CUSTOM_ELEMENT[key].type,}
                })} value={type} onChange={(e)=>this.setState({type:e.value,options:null})}/>
              </div>
            </div>
            <div>
              <div style={{margin: "1em"}}>
                <span style={{width: "6em", display: "inline-block"}}>Value:</span>
                <InputText type={"text"}
                           value={type===CUSTOM_ELEMENT.RULER.type?null:value}
                           disabled={type===CUSTOM_ELEMENT.RULER.type}
                           className={"ui-inputtext ui-state-default ui-corner-all ui-widget"}
                           style={{width: "20em"}} onChange={(e) => this.setState({value: e.target.value})}/>
              </div>
              <div style={{margin: "1em"}}>
                <span style={{width: "6em", display: "inline-block"}}>Options</span>
                {this.optionsShortRenderer()}
                <Button icon={"fa fa-edit"} title={"Set options"} onClick={()=>{this.setState({isOptionsEditorVisible:true})}}
                        disabled={type===CUSTOM_ELEMENT.TEXT.type || type===CUSTOM_ELEMENT.RULER.type}
                />
                <NonModalSidebar
                  onHide={()=>{this.setState({isOptionsEditorVisible:false})}}
                  visible={isOptionsEditorVisible}>
                  {isOptionsEditorVisible &&
                  <AnnotationOptionsEditor ontologyList={ontologyList}
                                           type={type}
                                           options={options}
                                           optionsDetails={optionsDetails}
                                           hide={()=>this.setState({isOptionsEditorVisible:false})}
                                           updateOptions={(o,d)=>this.setState({options:o,optionsDetails:d,isOptionsEditorVisible:false})}
                                           messageQueue={messageQueue}
                  />
                  }
                </NonModalSidebar>
              </div>
            </div>
          </div>
          <div style={{marginTop: "2em"}}>
            <Button label={"Save as Draft"}
                    onClick={e => this.onSaveAnnotation("draft")}
                    disabled={saveAnnotationState === REQUEST_STATUS_REQUESTED}
                    icon={saveAnnotationState === REQUEST_STATUS_REQUESTED ? "fa fa-spinner fa-spin" : "fa fa-save"}/>
            <Button label={"Save and Publish"}
                    onClick={e => this.onSaveAnnotation("published")}
                    disabled={saveAnnotationState === REQUEST_STATUS_REQUESTED}
                    icon={saveAnnotationState === REQUEST_STATUS_REQUESTED ? "fa fa-spinner fa-spin" : "fa fa-save"}/>
            <Button label={"Clear"} icon={"fa fa-trash"} onClick={this.onClear}/>
          </div>
        </div>
      </div>

    )
  }


}

export default AnnotationColumnBuilder;

AnnotationColumnBuilder.propTypes = {
  messageQueue: PropTypes.object.isRequired,
  getAnnotationsList: PropTypes.func.isRequired,
  getQuestionsList: PropTypes.func.isRequired,
  getOntologyList: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  taskListState: PropTypes.string.isRequired,
  allColumns: PropTypes.object.isRequired,
  allColumnsState: PropTypes.string.isRequired,
  questionList: PropTypes.array.isRequired,
  questionListState: PropTypes.string.isRequired,
  ontologyList: PropTypes.array.isRequired,
  ontologyListState: PropTypes.string.isRequired,
  saveAnnotationState: PropTypes.string.isRequired,
  saveAnnotationError: PropTypes.object.isRequired,
  saveAnnotationResponse: PropTypes.object.isRequired,
  saveAnnotationColumn:PropTypes.func.isRequired,
  clearSaveAnnotationsTableDefinition:PropTypes.func.isRequired
};
