import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root.js';
import './i18n';
import '@babel/polyfill';

/**
 *This is where all rendering starts!
 * Do not remove!
 */
ReactDOM.render((
    <Root/>
  ),
  document.getElementById('root')
);