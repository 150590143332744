import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {Panel} from "primereact/components/panel/Panel";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Spinner} from "primereact/components/spinner/Spinner";
import {InputText} from "primereact/components/inputtext/InputText";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";

//const WorkflowEditorRightPanel = ({clickClearCanvas,clickParse,clickCopyToClipboard, clickRetrieveFromClipboard, clickRemoveLinks, clickSelected, distribute}) => {


const rankDirOptions = [{label: "Left To Right", value: "LR"}, {
  label: "Top To Bottom",
  value: "TB"
}, {label: "Right To Left", value: "RL"}, {label: "Bottom To Top", value: "BT"}];
const rankerOptions = [{label: "Tight Tree", value: "tight-tree"}, {
  label: "Longest Path",
  value: "longest-path"
}, {label: "Simplex", value: "network-simplex"}];

class WorkflowEditorRightPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      workflowCollapsed: false,
      distributedCollapsed: true,
      selectedCollapsed: true,
      canvasCollapsed: true,
      modelCollapsed: true,
      ranker: "longest-path",
      rankDir: "LR",
      marginx: 25,
      marginy: 25
    };
    ["toClipboard","fromClipboard"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  toClipboard(){
    navigator.clipboard.writeText(this.props.clickCopyToClipboard());
  }
  async fromClipboard(){
    try {
      const text = await navigator.clipboard.readText();
      this.props.clickRetrieveFromClipboard(text);
    } catch (error) {
      alert(error);
    }
  };

  render() {
    const {clickClearCanvas, clickParse, clickCopyToClipboard, clickRetrieveFromClipboard, clickRemoveLinks,
      distribute,selectedNodes,workflowName,workflowDescription,setWorkflowProperty, saveWorkflow,transferObject,
      lockDiagramModel,getWorkflows} = this.props;


    return (
      <div id="help-panel-content">

        <Panel id="workflowCollapsed-panel-content-goal"
               key="workflowCollapsed-panel"
               header={"Workflow"}
               toggleable={true}
               collapsed={this.state.workflowCollapsed}
               onCollapse={() => this.setState({workflowCollapsed: !this.state.workflowCollapsed})}
               onExpand={() => this.setState({workflowCollapsed: !this.state.workflowCollapsed})}>
          <InputText title={"Name"}
                     style={{width:"100%"}}
                     onChange={(e) => {setWorkflowProperty({workflowName:e.target.value}) }}
                     value={workflowName} label={"Name"}
                     onFocus={(e)=>{
                       lockDiagramModel(true);
                     }}
                     onBlur={(e)=>{
                       lockDiagramModel(false);
                     }}
          />
          <InputTextarea title={"Description"}
                         onChange={(e) => {setWorkflowProperty({workflowDescription:e.target.value}) }}
                         value={workflowDescription}
                         rows={6}
                         style={{width:"100%"}}
                         onFocus={(e)=>{
                           lockDiagramModel(true);
                         }}
                         onBlur={(e)=>{
                           lockDiagramModel(false);
                         }}
                         />
          <div>
            <Button onClick={() => clickParse()} label={"Parse"}/>
          </div>
          <div>
            <Button
              title={(transferObject!=null)?"Save parsed workflow":"Parse workflow before saving"}
              disabled={!(transferObject!=null && transferObject['workflow']!=null)}
              onClick={() => {
              const transferObjectCopy = Object.assign({},transferObject);
              transferObjectCopy['workflow']['status']="DRAFT";
              saveWorkflow(transferObjectCopy.workflow._id, transferObjectCopy,"draft");
              getWorkflows(); //refresh left panel with workflows
            }} label={"Save as Draft"}/>
          </div>
          <div>
            <Button
              title={(transferObject!=null)?"Save parsed workflow":"Parse workflow before saving"}
              disabled={!(transferObject!=null && transferObject['workflow']!=null)}
              onClick={() => {
              const transferObjectCopy = Object.assign({},transferObject);
                transferObjectCopy['workflow']['status']="PUBLISHED";
              saveWorkflow(transferObjectCopy.workflow._id, transferObjectCopy,"publish");
              getWorkflows(); //refresh left panel with workflows
            }} label={"Save and Publish"}/>
          </div>
        </Panel>
        <Panel id="distributedCollapsed-panel-content-goal"
               key="distributedCollapsed-panel"
               header={"Layout"}
               toggleable={true}
               collapsed={this.state.distributedCollapsed}
               onCollapse={() => this.setState({distributedCollapsed: !this.state.distributedCollapsed})}
               onExpand={() => this.setState({distributedCollapsed: !this.state.distributedCollapsed})}
        >
          <div>
            <label>Margin x</label>
            <Spinner step={1} maxlength={3} size={3} value={this.state.marginx}
                     onChange={(e) => this.setState({marginx: e.value})}/>
          </div>
          <div>
            <label>Margin y</label>
            <Spinner step={1} maxlength={3} size={3} value={this.state.marginy}
                     onChange={(e) => this.setState({marginy: e.value})}/>
          </div>
          <div>
            <label>Direction</label>
            <Dropdown value={this.state.rankDir} onChange={(e) => this.setState({rankDir: e.value})}
                      options={rankDirOptions}/>
          </div>
          <div>
            <label title={"Type of algorithm to assigns a rank to each node in the input graph."}>Algorithm</label>
            <Dropdown value={this.state.ranker} onChange={(e) => this.setState({ranker: e.value})}
                      options={rankerOptions}/>
          </div>
          <Button onClick={() => distribute({
            rankdir: this.state.rankDir,
            ranker: this.state.ranker,
            marginx: this.state.marginx,
            marginy: this.state.marginy
          })} label={"Distribute"}/>
        </Panel>
        <Panel id="selectedCollapsed-panel-content-goal"
               key="selectedCollapsed-panel"
               header={"Selected"}
               toggleable={true}
               collapsed={this.state.selectedCollapsed}
               onCollapse={() => this.setState({selectedCollapsed: !this.state.selectedCollapsed})}
               onExpand={() => this.setState({selectedCollapsed: !this.state.selectedCollapsed})}
        >
          {selectedNodes!=null?"not null":"null"}
        </Panel>
        <Panel id="canvasCollapsed-panel-content-goal"
               key="canvasCollapsed-panel"
               header={"Canvas"}
               toggleable={true}
               collapsed={this.state.canvasCollapsed}
               onCollapse={() => this.setState({canvasCollapsed: !this.state.canvasCollapsed})}
               onExpand={() => this.setState({canvasCollapsed: !this.state.canvasCollapsed})}>
          <div>
            <Button onClick={() => this.toClipboard()} label={"Copy To Clipboard"}/>
          </div>
          <div>
            <Button onClick={() => this.fromClipboard()} label={"Retrieve From Clipboard"}/>
          </div>
          <div>
            <Button onClick={() => clickRemoveLinks()} label={"Remove Links"}/>
          </div>
          <div>
            <Button onClick={() => clickClearCanvas()} label={"Clear canvas"}/>
          </div>
        </Panel>
        {/*<Panel id="modelCollapsed-panel-content-goal"*/}
        {/*       key="modelCollapsed-panel"*/}
        {/*       header={"Model"}*/}
        {/*       toggleable={true}*/}
        {/*       collapsed={this.state.modelCollapsed}*/}
        {/*       onCollapse={() => this.setState({modelCollapsed: !this.state.modelCollapsed})}*/}
        {/*       onExpand={() => this.setState({modelCollapsed: !this.state.modelCollapsed})}>*/}
        {/* */}

        {/*</Panel>*/}
      </div>
    );
  }
};

export default WorkflowEditorRightPanel;

WorkflowEditorRightPanel.propTypes = {
  transferObject: PropTypes.object.isRequired,
  clickClearCanvas: PropTypes.func,
  clickParse: PropTypes.func,
  clickCopyToClipboard: PropTypes.func,
  clickRetrieveFromClipboard: PropTypes.func,
  clickRemoveLinks: PropTypes.func,
  clickSelected: PropTypes.func,
  distribute: PropTypes.func,
  selectedNodes:PropTypes.object,
  workflowName:PropTypes.string.isRequired,
  workflowDescription:PropTypes.string.isRequired,
  setWorkflowProperty:PropTypes.func.isRequired,
  getWorkflows:PropTypes.func.isRequired,
  lockDiagramModel:PropTypes.func.isRequired
};