import {
    ANNOTATION_DATA_FAIL,
    ANNOTATION_DATA_REQUEST,
    ANNOTATION_DATA_SUCCESS,
    ANNOTATION_DATA_UPDATE,
    ANNOTATION_DEFINITION_FAIL,
    ANNOTATION_DEFINITION_REQUEST,
    ANNOTATION_DEFINITION_SUCCESS,
    ANNOTATION_FORM_DATA_FAIL,
    ANNOTATION_FORM_DATA_REQUEST,
    ANNOTATION_FORM_DATA_SUCCESS,
    ANNOTATION_FORM_DATA_UPDATE,
    ANNOTATION_FORM_DEFINITION_FAIL,
    ANNOTATION_FORM_DEFINITION_REQUEST,
    ANNOTATION_FORM_DEFINITION_SUCCESS,
    ANNOTATION_DEFINITIONS_LIST_SUCCESS,
    ANNOTATION_DEFINITIONS_LIST_FAIL,
    ANNOTATION_DEFINITIONS_LIST_REQUEST,
    CLEAR_ANNOTATOR_STATE,
    PIN_REDO,
    PIN_UNDO,
    ROI_LIST_FAIL,
    ROI_LIST_REQUEST,
    ROI_LIST_SUCCESS,
    UPDATE_ACTIVE_ROI,
    UPDATE_ANNOTATION_DOCUMENT_ID,
    UPDATE_ANNOTATION_FORM_DOCUMENT_ID,
    UPDATE_PIN_HISTORY,
    ANNOTATION_LIST_REQUEST,
    ANNOTATION_LIST_FAIL,
    ANNOTATION_LIST_SUCCESS,
    ANNOTATION_DEFINITION_SAVE_REQUEST,
    ANNOTATION_DEFINITION_SAVE_FAIL,
    ANNOTATION_DEFINITION_SAVE_SUCCESS,
    CLEAR_ANNOTATION_DEFINITION_SAVE,
    ANNOTATION_SAVE_REQUEST,
    ANNOTATION_SAVE_FAIL,
    ANNOTATION_SAVE_SUCCESS,
    CLEAR_ANNOTATION_SAVE,
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS, ANNOTATION_PROPERTY_NAME__ID} from "../../../Constants";
import { object } from "prop-types";


export const annotationsReducer = (state = {
    //annotation Tables/Forms
    annotationsDefinitionsList:[],
    annotationsDefinitionsListState:"",
    annotationsDefinitionsListError:{},
    // just a single form containing state of annotations referring to generic ROI, eg. original image
    annotationsFormDefinitionDocumentId: '',
    annotationsFormDefinitionState: '',
    annotationsFormDefinition: {},
    annotationsFormDataState: '',
    annotationsFormData: [],


    //single annotations (Columns)
    annotationsList:[],
    annotationsListState:"",
    annotationsListError:{},

    // dynamic data (for table),
    annotationsDefinitionDocumentId: '',
    annotationsDefinitionState: '',
    annotationsDefinition: {},
    annotationsDataState: '',
    annotationsDataError: {},
    annotationsData: [], //update changes history
    //TODO: Change annotationsData to be an object. See ImageReducer
    annotationsReadDataState: {},
    annotationsDataInputs: {},
    annotationsReadData: [],

    readOnly: false,
    activeROI: null,  //update changes history
    past: [], //undo/redo
    future: [] //undo/redo
}, action) => {
    switch (action.type) {
        case ANNOTATION_DEFINITION_REQUEST:
            return Object.assign({}, state, {
                annotationsDefinitionState: REQUEST_STATUS_REQUESTED,
            });
        case ANNOTATION_FORM_DEFINITION_REQUEST:
            return Object.assign({}, state, {
                annotationsFormDefinitionState: REQUEST_STATUS_REQUESTED,
            });
        case ANNOTATION_DEFINITION_SUCCESS:
            return Object.assign({}, state, {
                annotationsDefinitionState: REQUEST_STATUS_SUCCESS,
                annotationsDefinition: action.columns
            });
        case ANNOTATION_FORM_DEFINITION_SUCCESS:
            return Object.assign({}, state, {
                annotationsFormDefinitionState: REQUEST_STATUS_SUCCESS,
                annotationsFormDefinition: action.columns
            });
        case ANNOTATION_DEFINITION_FAIL:
            return Object.assign({}, state, {
                annotationsDefinitionState: REQUEST_STATUS_FAIL,
                annotationsDefinition: action.err
            });
        case ANNOTATION_FORM_DEFINITION_FAIL:
            return Object.assign({}, state, {
                annotationsFormDefinitionState: REQUEST_STATUS_FAIL,
                annotationsFormDefinition: action.err
            });
        case ANNOTATION_DATA_REQUEST:
        case ROI_LIST_REQUEST:
            return Object.assign({}, state, {
                annotationsDataState: REQUEST_STATUS_REQUESTED,
            });
        case ANNOTATION_FORM_DATA_REQUEST:
            return Object.assign({}, state, {
                annotationsFormDataState: REQUEST_STATUS_REQUESTED,
            });

        case ANNOTATION_DATA_SUCCESS:
        case ROI_LIST_SUCCESS:
            if (action.readOnly) {
                let stateCopyAnnotationsDataInputs = Object.assign({}, state.annotationsDataInputs);
                stateCopyAnnotationsDataInputs[action.inputKey] = action.data;
                let stateCopyAnnotationsDataInputsState = Object.assign({}, state.annotationsReadDataState);
                stateCopyAnnotationsDataInputsState[action.inputKey] = REQUEST_STATUS_SUCCESS;
                let stateCopyAnnotationsReadData = [];
                for(const input in stateCopyAnnotationsDataInputs){
                    stateCopyAnnotationsReadData = stateCopyAnnotationsReadData.concat(stateCopyAnnotationsDataInputs[input])
                }
                stateCopyAnnotationsReadData.forEach((roi, idx) => {
                    const _id = idx + 1;
                    return roi[ANNOTATION_PROPERTY_NAME__ID] = _id.toString();
                });
                return Object.assign({}, state, {
                    annotationsDataState: REQUEST_STATUS_SUCCESS,
                    annotationsData: action.data,
                    annotationsDataInputsState: Object.assign({}, stateCopyAnnotationsDataInputsState),
                    annotationsDataInputs: Object.assign({}, stateCopyAnnotationsDataInputs),
                    annotationsReadData: stateCopyAnnotationsReadData,
                    readOnly: action.readOnly,
                });
            } else {
                return Object.assign({}, state, {
                    annotationsDataState: REQUEST_STATUS_SUCCESS,
                    annotationsData: action.data,
                    readOnly: action.readOnly,
                });
            }
        case ANNOTATION_FORM_DATA_SUCCESS:
            return Object.assign({}, state, {
                annotationsFormDataState: REQUEST_STATUS_SUCCESS,
                annotationsFormData: action.data
            });
        case ANNOTATION_DATA_FAIL:
        case ROI_LIST_FAIL:
            // TODO: Check if handling for the ReadData is needed
            return Object.assign({}, state, {
                annotationsDataState: REQUEST_STATUS_FAIL,
                annotationsDataError: action.err
            });
        case ANNOTATION_FORM_DATA_FAIL:
            return Object.assign({}, state, {
                annotationsFormDataState: REQUEST_STATUS_FAIL,
                annotationsFormData: action.err
            });
        case ANNOTATION_DATA_UPDATE: {
            const newArray = action.data.map(a => Object.assign({}, a));
            console.log(newArray);
            return Object.assign({}, state, {
                annotationsData: newArray
            });
        }
        case ANNOTATION_FORM_DATA_UPDATE:
            return Object.assign({}, state, {
                annotationsFormData: action.data.slice(0)
            });
        case UPDATE_ACTIVE_ROI:
            return Object.assign({}, state, {
                activeROI: action.roiId
            });
        case UPDATE_ANNOTATION_DOCUMENT_ID:
            return Object.assign({}, state, {
                annotationsDefinitionDocumentId: action.id
            });
        case UPDATE_ANNOTATION_FORM_DOCUMENT_ID:
            return Object.assign({}, state, {
                annotationsFormDefinitionDocumentId: action.id
            });
        case CLEAR_ANNOTATOR_STATE:
            return Object.assign({}, state, {
                annotationsFormDefinitionDocumentId: '',
                annotationsFormDefinitionState: '',
                annotationsFormDefinition: {},
                annotationsFormDataState: '',
                annotationsFormData: [],
                annotationsDefinitionDocumentId: '',
                annotationsDefinitionState: '',
                annotationsDefinition: {},
                annotationsDataState: '',
                annotationsData: [],
                annotationsDataInputsState: {},
                annotationsDataInputs: {},
                annotationsReadData: [],
                activeROI: null,
                past: [],
                future: []
            });
        case PIN_UNDO: {
            // Remove the last element from the past.
            // Set the present to the element we removed in the previous step.
            // Insert the old present state at the beginning of the future.
            const previous = state.past[state.past.length - 1];
            if (previous!=null){
            const newPast = state.past.slice(0, state.past.length - 1);
            const present = {
                annotationsData: state.annotationsData,
                activeROI: state.activeROI
            };
            const newState =  Object.assign({}, state, {
                past: newPast,
                annotationsData: [...previous.annotationsData],
                activeROI:previous.activeROI,
                future: [present, ...state.future]
            });
            console.log("----------Undo--------");
            logNewState(newState);
            console.log("==========Undo========");
            return newState;
            }
            else return state;
        }
        case PIN_REDO: {
            // Remove the first element from the future.
            // Set the present to the element we removed in the previous step.
            // Insert the old present state at the end of the past.
            const next = state.future[0];
            if (next!=null){
            const newFuture = state.future.slice(1);
            const present = {
                annotationsData: state.annotationsData,
                activeROI: state.activeROI
            };
            const newState = Object.assign({}, state, {
                past: [...state.past, present],
                annotationsData: [...next.annotationsData],
                activeROI:next.activeROI,
                future: newFuture
            });
            console.log("----------Redo--------");
            logNewState(newState);
            console.log("==========Redo========");
            return newState;
            }
            else return state;
        }
        case UPDATE_PIN_HISTORY:{
            const newArray = state.annotationsData.map(a => Object.assign({}, a));
            const prevState = {
                annotationsData: newArray,//copy array
                activeROI: state.activeROI
            };
            const newState = Object.assign({}, state, {
                past: [...state.past, prevState],
                future: []
            });
            console.log("----------History--------");
            logNewState(newState);
            console.log("==========History========");
            return newState;
        }
        case ANNOTATION_DEFINITIONS_LIST_REQUEST:{
            return Object.assign({}, state, {
                annotationsDefinitionsListState: REQUEST_STATUS_REQUESTED
            });
        }
        case ANNOTATION_DEFINITIONS_LIST_FAIL:{
            return Object.assign({}, state, {
                annotationsDefinitionsListState: REQUEST_STATUS_FAIL,
                annotationsDefinitionsListError: action.err
            });
        }
        case ANNOTATION_DEFINITIONS_LIST_SUCCESS:{
            return Object.assign({}, state, {
                annotationsDefinitionsListState: REQUEST_STATUS_SUCCESS,
                annotationsDefinitionsList: action.data
            });
        }
        case ANNOTATION_LIST_REQUEST:{
            return Object.assign({}, state, {
                annotationsListState: REQUEST_STATUS_REQUESTED
            });
        }
        case ANNOTATION_LIST_FAIL:{
            return Object.assign({}, state, {
                annotationsListState: REQUEST_STATUS_FAIL,
                annotationsListError: action.err
            });
        }
        case ANNOTATION_LIST_SUCCESS:{
            return Object.assign({}, state, {
                annotationsListState: REQUEST_STATUS_SUCCESS,
                annotationsList: action.data
            });
        }
        default:
            return state;
    }
};
/**
 * The same state for saving both annotation tables and columns
 * @param state
 * @param action
 * @return {({} & {saveAnnotationError: {}, saveAnnotationState: string, saveAnnotationResponse: {}} & {saveAnnotationError: *, saveAnnotationState: string})|{saveAnnotationError: {}, saveAnnotationState: string, saveAnnotationResponse: {}}|({} & {saveAnnotationError: {}, saveAnnotationState: string, saveAnnotationResponse: {}})|({} & {saveAnnotationError: {}, saveAnnotationState: string, saveAnnotationResponse: {}} & {saveAnnotationState: string})|({} & {saveAnnotationError: {}, saveAnnotationState: string, saveAnnotationResponse: {}} & {saveAnnotationState: string, saveAnnotationResponse: *})}
 */
export const saveAnnotationsReducer = (state = {

    saveAnnotationState:"",
    saveAnnotationError:{},
    saveAnnotationResponse:{}

},action) => {
    switch (action.type) {
        case ANNOTATION_DEFINITION_SAVE_REQUEST || ANNOTATION_SAVE_REQUEST:{
            return Object.assign({}, state, {
                saveAnnotationState: REQUEST_STATUS_REQUESTED
            });
        }
        case ANNOTATION_DEFINITION_SAVE_FAIL || ANNOTATION_SAVE_FAIL:{
            return Object.assign({}, state, {
                saveAnnotationState: REQUEST_STATUS_FAIL,
                saveAnnotationError: action.err
            });
        }
        case ANNOTATION_DEFINITION_SAVE_SUCCESS || ANNOTATION_SAVE_SUCCESS:{
            return Object.assign({}, state, {
                saveAnnotationState: REQUEST_STATUS_SUCCESS,
                saveAnnotationResponse: action.data
            });
        }
        case CLEAR_ANNOTATION_DEFINITION_SAVE || CLEAR_ANNOTATION_SAVE :{
            return Object.assign({}, state, {
                saveAnnotationState:"",
                saveAnnotationError:{},
                saveAnnotationResponse:{}
            });
        }
        default:
            return state;
    }
};



const logNewState=(newState)=>{
    console.log("Past size",newState.past.length);
    console.log("Future size",newState.future.length);
    console.log("Active ROI",newState.activeROI);
    console.log("Present number of ROI",newState.annotationsData.length);
    if (new Set(newState.annotationsData.map((el)=>el._id)).size !== newState.annotationsData.length)
        console.log("Duplicate!!!");
};

