import {
    ACTIVITY_FAIL,
    ACTIVITY_LIST_FAIL,
    ACTIVITY_LIST_REQUEST,
    ACTIVITY_LIST_SUCCESS,
    ACTIVITY_REQUEST,
    ACTIVITY_SUCCESS,
    SET_ACTIVITY_SORTER,
    SET_ACTIVITY_VISIBILITY_FILTER
} from "./actionType";
import axios from 'axios';
import {mockActivities} from "../tests/Activities.test";
import {HOST_URL} from "../../../Constants";
import store from "../../Store";

//****************************************************************************
//
//                                Actions
//
//****************************************************************************



// Activity actions *************************************************************

const requestActivityList = () => ({
    type: ACTIVITY_LIST_REQUEST
});



const errorActivityList = err => ({
    type: ACTIVITY_LIST_FAIL,
    err
});

const successActivityList = (activities) => ({
    type: ACTIVITY_LIST_SUCCESS,
    activities
});

export function setVisibilityFilter(filter) {
    return { type: SET_ACTIVITY_VISIBILITY_FILTER, filter }
}

export function setSorter(sorter) {
    return { type: SET_ACTIVITY_SORTER, sorter }
}

/**
 * Action Creator for reading workload for a given user.
 * @returns {function(*)}
 */
export const getActivityList = () => {
    return dispatch => {
        dispatch (requestActivityList());
        console.log('ActivityAction.js :: getActivityList :: Before axios request');
        const config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        // Local version MOCK
        // dispatch (successActivityList(mockActivities2));
        return axios.get(HOST_URL+'/api/workload',config
        ).then(response => {
            console.log('ActivityAction.js :: getActivityList :: response', response);
            if(response.status !== 200) {
                dispatch (errorActivityList(response.data));
                return Promise.reject(response.data);
            } else {
                console.log('StudyAction.js :: getActivityList :: Before successStudyList');
                dispatch (successActivityList(response.data));
            }
        }).catch(err => dispatch (errorActivityList(err)));
    };
};




// One activity *****************************************************************
const requestActivity = (idActivity) => ({
    type: ACTIVITY_REQUEST,
    idActivity
});



const errorActivity = err => ({
    type: ACTIVITY_FAIL,
    err
});

const successActivity = (activity) => ({
    type: ACTIVITY_SUCCESS,
    activity
});


/** TODO Bind with real data instead mockup*/
export const getActivity = () => {
    return dispatch => {
        dispatch (successActivity(mockActivities[0]));
    };
};

//Get study (ActivityList)
// export const getActivity = (idActivity) => {
// 	return dispatch => {
// 		dispatch (requestActivity(idActivity))
// 		return axios.get(HOST_URL+'/activity',
// 			{params: idActivity}
// 		).then(response => {
// 			if(response.statusCode !== 200) {
// 				dispatch (errorActivity(response.data));
// 				return Promise.reject(response.data);
// 			} else {
// 				dispatch (successActivity(response.data));
// 			}
// 		}).catch(err => dispatch (errorActivity(err)));
// 	};
// };


