import axios from 'axios';
import store from "../../Store";
import {

    EXPDESIGN_DATASET_CLEAR, EXPDESIGN_EXPERIMENT_FAIL, EXPDESIGN_EXPERIMENT_REQUEST,
    EXPDESIGN_EXPERIMENT_SAVE_FAIL, EXPDESIGN_EXPERIMENT_SAVE_REQUEST,
    EXPDESIGN_EXPERIMENT_SAVE_SUCCESS,
    EXPDESIGN_EXPERIMENT_SUCCESS,
    EXPDESIGN_SET_EXPERIMENT,
    EXPDESIGN_STATMODEL_HYPOTHESIS,
    EXPDESIGN_STATMODEL_SELECT,
    EXPDESIGN_STATMODEL_VARIABLES
} from './actionType'

import {
    EXPERIMENT_PROPERTY_NAME__COHORTS,
    EXPERIMENT_PROPERTY_NAME__DESCRIPTION, EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__HYPOTHESIS,
    EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID, EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES,
    EXPERIMENT_PROPERTY_NAME__NAME,
    EXPERIMENT_PROPERTY_NAME__PRIVACY, EXPERIMENT_PROPERTY_NAME__PROJECT_ID, EXPERIMENT_PROPERTY_NAME__QUESTION,
    EXPERIMENT_PROPERTY_NAME__REVISION_ID, EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__TIMEFRAME,
    EXPERIMENT_PROPERTY_NAME__UUID,
    EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC,
    HOST_URL
} from "../../../Constants";
import {setStep} from "./WizardStepAction";
import {expDesignPagesEnum} from "../ExpDesign";




export const selectStatModel = model => ({
    type: EXPDESIGN_STATMODEL_SELECT,
    model
});

export const defineVariables = variables => ({
    type: EXPDESIGN_STATMODEL_VARIABLES,
    variables
});

export const defineHypothesis = hypothesis => ({
    type: EXPDESIGN_STATMODEL_HYPOTHESIS,
    hypothesis
});

export const clearDataset = () => ({
    type: EXPDESIGN_DATASET_CLEAR,
});


/**
 * @param experiment {Object} containing a string and an array
 * @return action {Object} the above functions
 */
export const saveStatModel = experiment => (
    dispatch => {
        dispatch(selectStatModel(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID]));
        dispatch(defineHypothesis(experiment[EXPERIMENT_PROPERTY_NAME__HYPOTHESIS]));
    }
);

export const defineVariablesActionCreator = experiment => (
    dispatch => {
        dispatch(defineVariables(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]));
        dispatch(clearDataset());
    }
);



//====================EXPDESIGN EXPERIMENT===========================================

//--------------------SAVE EXPERIMENT IN DB---------------------------------
const requestSaveExperiment = (experiment) => ({
    type: EXPDESIGN_EXPERIMENT_SAVE_REQUEST,
    experiment
});

const successSaveExperiment = (experiment) => ({
    type: EXPDESIGN_EXPERIMENT_SAVE_SUCCESS,
    experiment
});

const failSaveExperiment = (err,experiment) => ({
    type: EXPDESIGN_EXPERIMENT_SAVE_FAIL,
    experiment,
    err
});


/**
 * Function for preparing payload for Creating Experiment as defined in API/Schema
 * @see experiment.schema.js experimentJoi_create
 * @param experiment
 * @returns {{}}
 *
 */
function prepareToUpdateMethods(experiment) {
    let payload = {};
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__NAME)) payload[EXPERIMENT_PROPERTY_NAME__NAME] = experiment[EXPERIMENT_PROPERTY_NAME__NAME];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__DESCRIPTION)) payload[EXPERIMENT_PROPERTY_NAME__DESCRIPTION] = experiment[EXPERIMENT_PROPERTY_NAME__DESCRIPTION];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__QUESTION)) payload[EXPERIMENT_PROPERTY_NAME__QUESTION] = experiment[EXPERIMENT_PROPERTY_NAME__QUESTION];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__PRIVACY)) payload[EXPERIMENT_PROPERTY_NAME__PRIVACY] = EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC;
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__REVISION_ID)) payload[EXPERIMENT_PROPERTY_NAME__REVISION_ID] = experiment[EXPERIMENT_PROPERTY_NAME__REVISION_ID];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__HYPOTHESIS)) payload[EXPERIMENT_PROPERTY_NAME__HYPOTHESIS] = experiment[EXPERIMENT_PROPERTY_NAME__HYPOTHESIS];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID)) payload[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID] = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES)) payload[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES] = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__COHORTS)) payload[EXPERIMENT_PROPERTY_NAME__COHORTS] = experiment[EXPERIMENT_PROPERTY_NAME__COHORTS];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA)) payload[EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA] = experiment[EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA)) payload[EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA] = experiment[EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__TIMEFRAME)) payload[EXPERIMENT_PROPERTY_NAME__TIMEFRAME] = experiment[EXPERIMENT_PROPERTY_NAME__TIMEFRAME];
    return payload;
}

/**
 * Action Creator function for saving to DB and setting in ExpDesign.
 * If experiment already has uuid -  data are updated, otherwise created.
 * @param experiment -
 * @returns {function(*)}
 */
export const saveExperimentActionCreator = (experiment) => {
    return dispatch => {
        let sendingMethod=axios.put;
        let path=HOST_URL + '/api/experiment/'+experiment[EXPERIMENT_PROPERTY_NAME__UUID]+'/methods';
        let payload =  prepareToUpdateMethods(experiment);

        console.log(store);
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestSaveExperiment(experiment));
        sendingMethod(path,payload)
        .then(response => {
                console.log('StatisticalModelAction.js :: saveExperimentActionCreator :: response ::', response);
                if (response.status !== 200) {
                    dispatch(failSaveExperiment(err,experiment))
                } else {
                    dispatch(successSaveExperiment(response.data));
                }
            }).catch(error => {
            console.log('Experiment Action.js :: getExperimentList :: error ::', error);
            dispatch(failExperiment(error,experiment))
        });
    }
};

//--------------------UPDATE SINGLE EXPERIMENT------------------------------
/**
 * Overwrite the experiment in store with the one given in parameter.
 * @param experiment - new value for experiment
 * @returns {{type, experiment: *}}
 */
const updateExperiment = experiment => ({
    type: EXPDESIGN_SET_EXPERIMENT,
    experiment
});

/** Setting, initializing experiment from component
 * @param data {Object} containing a string and an array
 * @return action {Object} the above functions
 */
export const updateExperimentActionCreator = (experiment) => (
    dispatch => {
        dispatch(updateExperiment(experiment));
    }
);
//-----------------------LOADING SINGLE EXPERIMENT---------------------------

const requestExperiment = (expId) => ({
    type: EXPDESIGN_EXPERIMENT_REQUEST,
    expId
});

const successExperiment = (experiment) => ({
    type: EXPDESIGN_EXPERIMENT_SUCCESS,
    experiment
});

const failExperiment = (err,expId) => ({
    type: EXPDESIGN_EXPERIMENT_FAIL,
    expId,
    err
});

/**
 * Action Creator function for fetching experiment from DB and setting in ExpDesign.
 * @param expId -
 * @returns {function(*)}
 */
export const retrieveExperimentActionCreator = (expId) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestExperiment(expId));
        axios.get(HOST_URL + '/api/experiment/'+expId)
            .then(response => {
                console.log('StatisticalModelAction.js :: getExperiment :: response ::', response)
                if (response.status !== 200) {
                    dispatch(failExperiment(err,expId))
                } else {
                    dispatch(successExperiment(response.data));
                }
            }).catch(error => {
            console.log('Experiment Action.js :: getExperimentList :: error ::', error);
            dispatch(failExperiment(error,expId))
        });
    }
};

