import {withRouter} from "react-router";
import {connect} from "react-redux";
import {AvatarScatterPlotPanel} from "../component/d3components/AvatarScatterPlotPanel";
import {
    clearOverlayImageActionCreator,
    getImageActionCreator,
    getTabletGameExperimentResults
} from "../action/TabletGameExperimentAction";
import {
    clearViewersState,
    updateViewerProperty
} from "../../visualization/action/ViewerAction";
import {
    clearInteractivePlotState, initializeAvatarScatterPlot, refreshDataAC
} from "../../visualization/action/InteractivePlotAction";
import {loadImageData} from "../../visualization/action/ImageAction";
import {calculateModelParams} from "../action/AuditAction";
import {clearDataset} from "../action/StatisticalModelAction";
import {MultiAvatarScatterPlotPanel} from "../component/d3components/MultiAvatarScatterPlotPanel";

const mapStateToProps = (state, ownProps) => {
    return {
        email: state.auth.email,
        plotSettings: state.visu.interactivePlot.plotSettings,
        plotData:state.visu.interactivePlot.plotData,
        rawData:state.visu.interactivePlot.rawData,
        plotDataState:state.visu.interactivePlot.plotDataState,
        images: state.visu.images.images,
        overlays: state.visu.images.overlays,
        viewersState: state.visu.viewers.viewersState,
        modelParameters: state.expDesign.audit.modelParameters,
        modelParametersState:state.expDesign.audit.modelParametersState
    }
};
/**
 *
 * @param dispatch
 * @returns {{getTabletGameExperimentResults: function(*=), getImage: function(*=), clearOverlayImage: function(*=)}}
 */
const mapDispatchToProps = dispatch => {
    return {
        getTabletGameExperimentResults: (tabletExperimentId) => {
            dispatch(getTabletGameExperimentResults(tabletExperimentId))
        },
        getImage: (imageProperties) => {
            dispatch(getImageActionCreator(imageProperties))
        },
        clearOverlayImage: (propName) => {
            dispatch(clearOverlayImageActionCreator(propName))
        },
        clearViewersState: () => {
            dispatch(clearViewersState())
        },
        updateViewerProperty: (key, property, value) => {
            dispatch(updateViewerProperty(key, property, value))
        },
        initializeAvatarScatterPlot:(scientficEventId)=>{
            dispatch(initializeAvatarScatterPlot(scientficEventId))
        },
        clearInteractivePlotState:()=>{
            dispatch(clearInteractivePlotState())
        },
        loadImageData: (imageId, format, url, type)=>{
            dispatch(loadImageData(imageId, format, url, type))
        },
        calculateParameters: (dim, data)=>{
            dispatch(calculateModelParams(dim,data))
        },
        clearDataset:()=>{
            dispatch(clearDataset())
        },
        refreshData:(scientificEventId)=>{
            dispatch(refreshDataAC(scientificEventId))
        }
    }
};
export const ContainerAvatarScatterPlotPanel = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)(AvatarScatterPlotPanel));


export const ContainerMultiAvatarScatterPlotPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(MultiAvatarScatterPlotPanel));