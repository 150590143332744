import React from "react";
import {Prompt, withRouter} from "react-router";
import PropTypes from "prop-types";
import {
    ANNOTATION_TOOL_TYPE, CHARACTERIZATION_TOOL_TYPE,
    CONFIRMATION_TOOL_TYPE,
    IDENTIFICATION_TOOL_TYPE,
    REQUEST_STATUS_FAIL,
    REQUEST_STATUS_SUCCESS,
    SEGMENTATION_TOOL_TYPE,
    SLICE_SELECTOR_TOOL_RANGED,
    SLICE_SELECTOR_TOOL_TYPE,
    SLICE_SELECTOR_WITH_PICKER
} from "../../../Constants";
import ConfirmationTool from "../../visualization/component/annotationTool/ConfirmationTool";
import ResultsPage from "../../visualization/component/annotationTool/ResultsPage";
import {ContainerResultsPage} from "../../visualization/containers/ContainerResultsPage";
import {ContainerManualTool} from "../../visualization/containers/ContainerManualTool";
import {Link} from "react-router-dom";

const DONE = 'DONE';

/**
 * Component providing template for tools.
 * Uses tool type to provide template specific to a given activity.
 * In the future templates are going to be replaced with a single Manual Task Configuration Tool template.
 * Can be used for demos.
 */
class ManualToolDispatcher extends React.Component {

  constructor(props) {
    super(props);
    this.getTool = this.getTool.bind(this);
  }


    componentDidMount() {
        const {getManualTask, getManualTaskLivePresenter, getManualTaskSingleReview, match, workload} = this.props;
        console.log(workload)
        getManualTaskSingleReview(workload);
        // if (!match.path.includes("playskill")) {
        //     getManualTask(
        //         match.params['miniWorkflowSetId'],
        //         match.params['miniWorkflowKey'],
        //         match.params['experimentId']);
        // }
        // else {
        //     getManualTaskLivePresenter(
        //         this.props.livePresenterExperimentId,
        //         this.props.currentStep,
        //         this.props.miniWorkflowSetId,
        //         this.props.miniWorkflowNameInSet
        //     );
        // }
    }
    componentDidUpdate(prevProps,prevState,snapshot) {
        const {currentStep,manualTaskState} = this.props;

    }

    componentWillUnmount() {
        const {clearManualToolState,clearManualTaskState} = this.props;
        clearManualToolState();
        clearManualTaskState();
    }

    getTool() {
        const {workload,manualTaskState} = this.props;
        console.log("getting tool")
        if (manualTaskState === REQUEST_STATUS_FAIL){
                    return (
                        <ContainerResultsPage {...this.props}
                                  message={"There has been an error in communication with server"}/>
                    );
            }

//FIXME the second condition is due to improper work of backend - there should be no cases when all cases were done
        if ((workload != null && !(workload.miniWorkflow!=null))
            ||
            (workload != null && Array.isArray(workload.listOfCases) && workload.listOfCases.every((el)=>{return el.caseStatus.toUpperCase()==="DONE"})) )

            return  (<div style={{display: "table",width:"100%",height:"100%"}}>

            <div style={{fontSize:"xx-large", display:"table-cell", verticalAlign:"middle",textAlign:"center"}}>

                    All the cases have been done.
                <br/>Please go to  <Link to="/home">dashboard.</Link>

            </div>

        </div>);

        if (workload != null && workload.miniWorkflow !=null) {
            const currentTool = workload.miniWorkflow.currentTool;

            // Check if we are in the Live Presenter based on the url
            const {match} = this.props;
            if (match.path.includes("playskill")) {

                // If the miniworkflow has been already DONE then we need to inform
                // the user to continue with the presentation
                let miniworkflowStatus = workload.miniWorkflow.status.toUpperCase();
                if (miniworkflowStatus === DONE) {
                    return (
                        <ContainerResultsPage  {...this.props}
                                     message={"There has been an error in communication with server"}/>
                    )
                }
            }

            switch (currentTool['type']) {
                case SLICE_SELECTOR_TOOL_TYPE:
                case SLICE_SELECTOR_TOOL_RANGED:
                case SEGMENTATION_TOOL_TYPE:
                case ANNOTATION_TOOL_TYPE:
                case IDENTIFICATION_TOOL_TYPE:
                case CHARACTERIZATION_TOOL_TYPE:
                // case SLICE_SELECTOR_WITH_PICKER:{
                //     return <SliceSelectionTool {...this.props}/>;
                // }
                case SLICE_SELECTOR_WITH_PICKER:{
                    return <ContainerManualTool/>;
                }
                // case SEGMENTATION_TOOL_TYPE: {
                //     return <SegmentationTool {...this.props}/>;
                // }
                case CONFIRMATION_TOOL_TYPE: {
                    return <ConfirmationTool {...this.props}/>;
                }
                default:
                    return <div/>;
            }
        }
        return null;
    }


    render() {

    const {workload, manualTaskState, taskId} = this.props; //taskId used only for DEMO!!!!
    return (
      <React.Fragment>
        {(manualTaskState === REQUEST_STATUS_SUCCESS || manualTaskState === REQUEST_STATUS_FAIL) && this.getTool()}

      </React.Fragment>
    )
  }

}

export default withRouter(ManualToolDispatcher);

ManualToolDispatcher.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getAnnotations: PropTypes.func.isRequired,
  updateAnnotationData: PropTypes.func.isRequired,
  rmCallback: PropTypes.func.isRequired,
  saveOverlayData: PropTypes.func,
  clearAll: PropTypes.func.isRequired,
  addAnnotation: PropTypes.func.isRequired,
  setActiveAnnotation: PropTypes.func.isRequired,
  clearAnnotatorState: PropTypes.func.isRequired,
  clearManualToolState:PropTypes.func.isRequired,
  clearManualTaskState:  PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  viewersState: PropTypes.array.isRequired,  //array containing state of all viewers
  updateViewerProperty: PropTypes.func.isRequired,
  updateViewer: PropTypes.func,
  imageOptions: PropTypes.array.isRequired,
  changeImageType: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  overlays: PropTypes.array.isRequired,
  changeOrientation: PropTypes.func.isRequired,
  manualToolConfiguration: PropTypes.object.isRequired,
  manualToolConfigurationState: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,//just for demo!!!!
  manualTaskState: PropTypes.string.isRequired,
  workload: PropTypes.object.isRequired,
  experimentId: PropTypes.string.isRequired,
  livePresenterExperimentId: PropTypes.string.isRequired,
  miniWorkflowSetId: PropTypes.string.isRequired,
  miniWorkflowNameInSet: PropTypes.string.isRequired,
  taskNameInMiniWorkflow: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired
};
