import {createSelector} from "reselect";
import {calculateIndex, getPlotSettings, getRawData, getSelectedCell, getSelectedLayout} from "./SAMSelectors";
import {getNestedProp} from "../../helpers/expressions";
import {removeDuplicatesByFilterFunction} from "../../helpers/arrays";


/**
 * If mode is single [0], check only if contribution is providing a given measurement.
 * If mode is multiple [1], check if all measurements are covered by contributions for the same case
 * @param c - contribution to be filtered
 * @param index -
 * @param cArray - contributions array provided to filter
 * @param currentPlotSettings
 * @return {boolean}
 */
export function filterContributionsByMeasurementsMode(c,index,cArray,currentPlotSettings){
  if (currentPlotSettings.mode === 0) //single measurement - check only
    return c.measurementConfigurationId === currentPlotSettings.selectedMeasurementConfiguration.id;
  else {
    // check all measurements if there are all contributions with the same case id and having all measurements ids
    return currentPlotSettings.selectedMeasurementConfiguration.findIndex(meas=>c.measurementConfigurationId===meas.id) >-1 &&
    currentPlotSettings.selectedMeasurementConfiguration.every(meas=>{
      return cArray.findIndex(contribution=> contribution.caseId === c.caseId && contribution.measurementConfigurationId === meas.id) > -1
    })
  }
}

/**
 * If mode is multiple [1], do nothing. In case of bland-altman [0] check groups.
 * @param c - contribution to be filtered
 * @param currentPlotSettings
 * @return {boolean}
 */
export function filterContributionsByContributorsMode(c,currentPlotSettings){
 return currentPlotSettings.mode !==0 // if "distribution or custom mode" no need to check groups
   || (getNestedProp(["groups",0,"contributors"],currentPlotSettings)!=null
     && getNestedProp(["groups",1,"contributors"],currentPlotSettings) !=null
     && (currentPlotSettings.groups[0].contributors.includes(c.contributorId) ||  currentPlotSettings.groups[1].contributors.includes(c.contributorId)));
}

/**
 * Get all Contributions matching a selected measurement.
 * @type {OutputSelector<unknown, unknown, (res1: unknown, res2: unknown, res3: unknown, res4: unknown) => unknown>}
 */
export const getTotalContributionsFilteredByMeasurement = createSelector(
  [getSelectedCell, getSelectedLayout, getPlotSettings, getRawData],
  (cell, layout, plot, rawData) => {
    const currentPlotSettings = plot[calculateIndex(cell, layout)];
    if (currentPlotSettings.selectedMeasurementConfiguration != null)
      return rawData
        .contributions
        .filter((c,i,ar) => filterContributionsByMeasurementsMode(c,i,ar,currentPlotSettings));
    else return [];
  }
);




/**
 * Filter contributions by measurements, and selected cases. Selected cases is calculated with use of filters for visit and subject .
 * @type {OutputSelector<unknown, unknown, (res1: *, res2: *, res3: *, res4: *) => unknown>}
 */
export const getSelectedContributionsFilteredByMeasurement = createSelector(
  [getSelectedCell, getSelectedLayout, getPlotSettings, getRawData],
  (cell, layout, plot, rawData) => {
    const currentPlotSettings = plot[calculateIndex(cell, layout)];
    if (currentPlotSettings.selectedMeasurementConfiguration != null)
      return rawData
        .contributions
        .filter((c) => {
          return currentPlotSettings.selectedCases.includes(c.caseId)
        })
        .filter((c,i,ar) => filterContributionsByMeasurementsMode(c,i,ar,currentPlotSettings));
    else return [];
  }
);


/**Check what to do in case of missing contributions from raters in group.
 * If "ignored" then just use others to calc mean in group, if "reject case" then remove case from matching cases.
 *
 * @param currentPlotSettings
 * @param el - object of type "contribution"
 * @param cArray - array of already filtered contributions (by contributors, measurements and cases)
 */
export const checkMissingContributionsStrategy = (currentPlotSettings,el,cArray)=>{


  const contributorsMatched = cArray.filter(cc=>cc.caseId === el.caseId).map(el=>el.contributorId);

  if (currentPlotSettings.missingContributionsStrategy === "ignored"){
    const g1 =  currentPlotSettings.groups[0].contributors.filter(con=>contributorsMatched.includes(con));
    const g2 =  currentPlotSettings.groups[1].contributors.filter(con=>contributorsMatched.includes(con));
    return g1.length>0 && g2.length>0;
  }


  // check other strategies
  const uniqueContributors =removeDuplicatesByFilterFunction(
    currentPlotSettings.groups[0].contributors.concat(currentPlotSettings.groups[1].contributors), x=>x);


  return ( uniqueContributors.length === contributorsMatched.length);
};

/**
 * Filter cases by measurements, selected cases and groups. Selected cases is calculated with use of filters for visit and subject .
 * @type {OutputSelector<unknown, unknown, (res1: *, res2: *, res3: *, res4: *) => unknown>}
 */
export const getSeriesContributions = createSelector(
  [(state,index)=>index, getSelectedCell, getSelectedLayout, getPlotSettings, getRawData],
  (index, cell, layout, plot, rawData) => {

    const  currentPlotSettings= (index!=null) ? plot[index]: plot[calculateIndex(cell, layout)];
    if (currentPlotSettings.selectedMeasurementConfiguration != null)
      return rawData
        .contributions
        .filter((c,i,ar) => filterContributionsByMeasurementsMode(c,i,ar,currentPlotSettings))
        .filter((c) =>currentPlotSettings.selectedCases.includes(c.caseId))
        .filter((el)=> filterContributionsByContributorsMode(el,currentPlotSettings))
        .filter((el,index,cArray)=>
          currentPlotSettings.mode !== 0 // if "distribution"[1] or "custom mode"[2] no need to check missing contributions strategy
         || checkMissingContributionsStrategy(currentPlotSettings,el,cArray)
        );
    else return [];
  }
);


