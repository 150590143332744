import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {INVERSION_TOOL, POINTER_TOOL} from "../../../../Constants";
import {checkIfAnyPropertyIsVisible} from "./WidgetToolbar";
import {Button} from "primereact/components/button/Button";


/**
 * Stateful component with template for Inversion Tool.
 * Responsible for changing and parameterizing cursor.
 * @returns {*}
 */
export class InversionToolbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false
    };
  }

  render() {
    const {toolProperties, changeToolProperties, toolConfiguration,invertColorsInOverlay} = this.props;
    const control = (property, type) => toolConfiguration != null
      && toolConfiguration.properties != null
      && toolConfiguration.properties[property] != null
      && toolConfiguration.properties[property][type] != null
      && toolConfiguration.properties[property][type];

    const checkIfAnyPropertyVisible = checkIfAnyPropertyIsVisible(toolConfiguration.properties);

    return (
      <React.Fragment>
        {checkIfAnyPropertyVisible &&
        <Toolbar style={{display: "inline-block"}}>
          {control('valueToInvert', 'controlVisible') &&
          <React.Fragment>
            <label style={{marginLeft: "1em"}}>Value to invert:</label>
            <Spinner
              min={1}
              size={3}
              step={1}
              value={toolProperties['valueToInvert']}
              onChange={(e) => changeToolProperties(INVERSION_TOOL, 'valueToInvert', e.value)}
            />
            <Button onClick={()=>invertColorsInOverlay()} icon={"fa fa-exchange"}/>
          </React.Fragment>
          }
        </Toolbar>}
      </React.Fragment>
    );
  }

};

InversionToolbar.defaultProps = {
  toolProperties: {valueToInvert: 1},
};
InversionToolbar.propTypes = {
  toolProperties: PropTypes.object.isRequired,
  toolConfiguration: PropTypes.object.isRequired,
  changeToolProperties: PropTypes.func,
  invertColorsInOverlay:PropTypes.func
};
