import React from 'react'
import SpineLogo from './SpineLogo.js'
import ContainerRolePicker from '../authentication/containers/ContainerRolePicker'
import ContainerLogout from '../authentication/containers/ContainerLogout'
import PropTypes from "prop-types";
import {Breadcrumb} from "./Breadcrumb";


async function loadRegister() {
    let response = await fetch('/dist/jsonDocs/tools/register.json');
    const data = await response.json();
    return await data;
}

export default class LayoutTopbar extends React.Component {

    // props are not passed

    constructor() {
        super();
        this.state = {
            role: 'contributor',
            isLoading: true
        };
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        this.setState({role: 'contributor'});
    }

    componentDidMount() {
        loadRegister().then((data) =>
            this.setState({isLoading: false, register: data})
        );
    }

    render() {
        const {pageTitle,match,location, history,appState} = this.props;
        return (
            <div className="headerHome ui-notdraggable ui-unselectable-text" draggable="false" >
                <div style={{display:'inline-block',marginTop:'18px',marginLeft:'10px'}}>
                <SpineLogo />
                </div>
                {this.state.isLoading
                  ? <div></div>
                  : <Breadcrumb location={location} appState={appState} register={this.state.register}/>
                }
                <ContainerLogout />
                {/*<ContainerRolePicker />*/}

            </div>
        )
    }
}

LayoutTopbar.propTypes = {
    pageTitle: PropTypes.string,
    match:PropTypes.object,
    location: PropTypes.object,
    history:PropTypes.object
};