import React from "react";

function highlight(highlight,text) {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <span className="demoCardHighlightedText">{part}</span> : part)}</span>;
}

/**
 * "Dumb" component responsible for displaying demo.
 *
 * @param title
 * @param subTitle
 * @param filter
 * @param footer
 * @param description
 * @param headerClassName - name of class with png to visualize (can be changed in future to encoded stream data)
 * @param style - additional style to apply for whole card
 * @return {*}
 * @constructor
 */

export const DemoCard = ({title,subTitle,filter,footer, description, headerClassName,style }) => {

    return (
            <div className="ui-card ui-widget ui-widget-content ui-corner-all demoCard"  {... (style !=null?{style : style}:{})}>
                <div className="ui-card-header">
                    <div className={headerClassName}/>
                </div>
                <div className="ui-card-body">
                    <div className="ui-card-title">
                        {filter.length>0?highlight(filter,title):title}
                    </div>
                    <div className="ui-card-content">
                        <div style={{height: "4em", overflowY: "auto"}}>
                            {filter.length>0?highlight(filter,description):description}
                        </div>
                    </div>
                    <div className="ui-card-footer">
                        {footer}
                    </div>
                </div>
            </div>

    );
};