
export const isRequired = fieldName => `${fieldName} is required`;

export const mustMatch = otherFieldName => {
    return (fieldName) => `${fieldName} must match ${otherFieldName}`;
};

export const minLength = length => {
    return (fieldName) => `${fieldName} must be at least ${length} characters`;
};

export const isStrongPassword = () => {
    return (fieldName) => `${fieldName} must be at least 8 characters and must be alphanumeric, containing at least one of each (one upper case, one lower case and one number)`;
};

export const isEmail = fieldName =>  `${fieldName} has invalid email format`;


export const isChecked = fieldName => `${fieldName} needs to be approved.`;