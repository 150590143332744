import React from "react";
import PropTypes from "prop-types";



const style={
  display: "flex",
  justifyContent: "space-between",
  alignItems:"center",
  marginTop: "1em",
  borderRadius: "1em",
  border: "solid 1px",
  padding: "1em",
  color: "wheat"
};

export class DataTrayItem extends React.Component {


  render() {
    const {type,color} = this.props;

    let title = type;

    style.backgroundColor = color;
    return  <div
      style={style}
      draggable={true}
      onDragStart={event => {
        // event.dataTransfer.setData("Text", event.target.id);
        // event.currentTarget.classList.add("fa fa-tint");
        event.dataTransfer.setData('node-toolData', "");
        event.dataTransfer.setData('node-taskData', "");
        event.dataTransfer.setData('node-taskInWorkflowData', "");
        event.dataTransfer.setData('node-colour', color);
        event.dataTransfer.setData('node-type', type)
      }}
    > <i className="fa fa-database" style={{marginRight:"0.5em"}}/>
      <span style={{textAlign:"center"}}>{title}</span>
      <i style={{marginLeft:"0.5em"}} className="fa fa-info-circle"/>
    </div>
  }
}

DataTrayItem.propTypes = {
  type:PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};