import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {Panel} from "primereact/components/panel/Panel";
import {ContainerWorkflowPanelContent} from "../container/ContainerWorkflowPanelContent";
import {ContainerTaskPanelContent} from "../container/ContainerTaskPanelContent";
import {ContainerToolPanelContent} from "../container/ContainerToolPanelContent";
import {DataTrayItem} from "./DataTrayItem";


export class WorkflowEditorLeftPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredNodes:null,
      allNodes:null,
      activeTabIndex: 0,
      workflowsCollapsed: false,
      inputsCollapsed:true,
      toolsCollapsed: true,
      tasksCollapsed: true,
    };
    ["onShow"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  onShow() {
    let el = document.getElementsByClassName("ui-sidebar-mask");
    el[0].style.visibility = "collapse";
  }

  render() {

    const {toolsCollapsed, workflowsCollapsed,tasksCollapsed,inputsCollapsed}=this.state;
    const {lockDiagramModel}=this.props;

    return (

          <div style={{marginTop:"2em"}} >
            <div id={"workflow-editor-left-panel-anchor"}/>
            <Panel id="workflows-panel-content-goal"
                   key="workflows-panel"
                   header={"Workflows"}
                   toggleable={true}
                   collapsed={workflowsCollapsed}
                   onCollapse={() => this.setState({workflowsCollapsed: !this.state.workflowsCollapsed})}
                   onExpand={() => this.setState({workflowsCollapsed: !this.state.workflowsCollapsed})}
            >
             <ContainerWorkflowPanelContent lockDiagramModel={lockDiagramModel}/>
            </Panel>
            <Panel id="tasks-panel"
                   key="tasks-panel"
                   header={"Tasks"}
                   toggleable={true}
                   collapsed={tasksCollapsed}
                   onCollapse={() => this.setState({tasksCollapsed: !this.state.tasksCollapsed})}
                   onExpand={() => this.setState({tasksCollapsed: !this.state.tasksCollapsed})}
            >
              <ContainerTaskPanelContent lockDiagramModel={lockDiagramModel}/>
            </Panel>
            <Panel id="tools-panel"
                   key="tools-panel"
                   header={"Tools"}
                   toggleable={true}
                   collapsed={toolsCollapsed}
                   onCollapse={() => this.setState({toolsCollapsed: !this.state.toolsCollapsed})}
                   onExpand={() => this.setState({toolsCollapsed: !this.state.toolsCollapsed})}
            >
              <ContainerToolPanelContent lockDiagramModel={lockDiagramModel}/>
            </Panel>
            <Panel id="inputs-panel-content"
                   key="inputs-panel"
                   header={"Data"}
                   toggleable={true}
                   collapsed={inputsCollapsed}
                   onCollapse={() => this.setState({inputsCollapsed: !this.state.inputsCollapsed})}
                   onExpand={() => this.setState({inputsCollapsed: !this.state.inputsCollapsed})}
            >
              <DataTrayItem type={"input"} color={"#ff0000"}/>
              <DataTrayItem type={"output"}  color={"#006900"}/>
              <DataTrayItem type={"constant"}  color={"#0000ff"}/>
            </Panel>
          </div>


    )
  };
}
export default withRouter(WorkflowEditorLeftPanel);

WorkflowEditorLeftPanel.propTypes= {
  lockDiagramModel:PropTypes.func.isRequired
};
