import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import PlotParametersPanelContent from "../component/PlotParametersPanelContent";
import { updateScatterPlotData} from "../action/ScatterPlotAction";




const mapStateToProps = (state,ownProps) => {
  return {
    dimensionality: getSelectedPlotSettings(state).dimensionality,
    axisXIndex: getSelectedPlotSettings(state).axisXIndex,
    axisYIndex: getSelectedPlotSettings(state).axisYIndex,
    axisZIndex: getSelectedPlotSettings(state).axisZIndex,
    plotType: getSelectedPlotSettings(state).plotType,
    rawData: state.sam.rawData,
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    },
    updateScatterPlotData:(p,index)=>{
      dispatch(updateScatterPlotData(p,index))
    }
  }
};

export const ContainerPlotParametersPanelContent = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(PlotParametersPanelContent));