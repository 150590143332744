// This file provides actions for scenes only - mainly for synchronization of images within the scene
import {
    INVERSION_TOOL,
    MAIN_TOOL,
    PREVENT_FROM_ADDING_PIN, PREVENT_FROM_CHANGING_VOXEL_VALUE,
    REQUEST_STATUS_SUCCESS
} from "../../../Constants";
import {
    UPDATE_SCENE_ACTIVE_COLOR,
    UPDATE_SCENE_ACTIVE_LAYER, UPDATE_SCENE_INTERACTIVE_LAYER,
    UPDATE_SCENE_LAYER_COLOR,
    UPDATE_SCENES
} from "./actionType";
import SpinePaintFilter from "../../vtk/paintbrush/SpinePaintFilter";
import { initLabelMapInStore} from "./ImageAction";
import {
    getActivePainterSelector,
    getMasksPreventingFromAddingPin,
    getMasksPreventingFromDrawing
} from "../selectors/SceneSelectors";
import {getNestedProp} from "../../helpers/expressions";


/**
 *  Replace scenes with object passed as argument.
 * @param scenes
 * @return {{scenes: *, type: string}}
 */
export const updateScenes = (scenes) => ({
    type: UPDATE_SCENES,
    scenes
});


const changeActiveLayer = (sceneKey, layerIndex) => ({
    type: UPDATE_SCENE_ACTIVE_LAYER,
    sceneKey,
    layerIndex
});

const changeInteractiveLayer = (sceneKey, layerIndex) => ({
    type: UPDATE_SCENE_INTERACTIVE_LAYER,
    sceneKey,
    layerIndex
});

const changeActiveColor = (sceneKey, layerIndex, value) => ({
    type: UPDATE_SCENE_ACTIVE_COLOR,
    sceneKey,
    layerIndex,
    value
});


const updateColormap = (sceneKey, layerIndex, value) =>({
    type: UPDATE_SCENE_LAYER_COLOR,
    sceneKey,
    layerIndex,
    value
});

// //--------------------TODO Interfaces to implement
// /**
//  * Add layer to overlay in scene
//  * @param sceneId
//  */
// const addLayerToScene = (sceneId, layer) => {
//
//
// };
// /**
//  * Remove layer from scene
//  * @param sceneId
//  */
// const removeLayerFromScene = (sceneId, layerIndex) => {
//
//
// };




/** Unbind inputs and scenes.
 * Inputs are loaded in asynchronous way.
 * @return {function(...[*]=)}
 */
export const initializeScenes = () => {
    return (dispatch, getState) => {
        const mTask = getState().activity.manualTask.manualTask;
        const sceneConf = mTask.miniWorkflow.currentTool.configuration.scenes;
        let scenes = {};
        Object.keys(sceneConf).forEach((el) => {
            scenes[el] = parseScene(el,sceneConf[el], getState, dispatch)
        });
        updatePainters(scenes, getState,dispatch); //create painters if images are already in Store or to be created
        console.log("Initialized scenes", scenes);
        dispatch(updateScenes(scenes));
    }
};

/**
 * Updates scenes according to the state of inputs. If all inputs required by an overlay are loaded, then overlay (painter) can
 * be initialised.
 * It should be dispatched after input is loaded (image, lut, mask, lut description, polydata).
 * @param overlayId
 * @return {function(...[*]=)}
 */
export const updateScenesOnInputLoaded = (overlayId) => {
    return (dispatch, getState) => {
        const scenes = getState().visu.scenes.scenes;
        if (updatePainters(scenes, getState,dispatch))
            dispatch(updateScenes(scenes));
        console.log("SceneReducer::updateScenesOnInputLoaded::" + overlayId);
    }
};

/**
 * Replaces lut keys  from overlay configuration with uuids or temporary Ids from inputs or created.
 * @param lutKey - key in currentTool.configuration.luts.
 * @param getState
 */
const replaceLutKeyWithId = (lutKey, getState) => {
    if (!(lutKey!=null)) return null;
    const inputs = getState().activity.manualTask.manualTask.miniWorkflow.currentMaterializedTask.inputs;
    const lutConfiguration = getState().activity.manualTask.manualTask.miniWorkflow.currentTool.configuration.luts[lutKey];

    if (lutConfiguration['fromInputs'] && inputs[lutConfiguration['lutInputKey']]!=null){
        return  inputs[lutConfiguration['lutInputKey']].value
    }
    else return lutKey;
};

/**
 * Replaces lut keys  from overlay configuration with uuids or temporary Ids from inputs or created.
 * @param lutDescKey - key in currentTool.configuration.luts.
 * @param getState
 */
const replaceLutDescriptionKeyWithId = (lutDescKey, getState) => {
    if (!(lutDescKey!=null)) return null;
    const inputs = getState().activity.manualTask.manualTask.miniWorkflow.currentMaterializedTask.inputs;
    const lutDescConfiguration = getState().activity.manualTask.manualTask.miniWorkflow.currentTool.configuration.lutDescriptions[lutDescKey];

    if (lutDescConfiguration['fromInputs'] && inputs[lutDescConfiguration['lutDescriptionInputKey']]!=null){
        return  inputs[lutDescConfiguration['lutDescriptionInputKey']].value
    }
    else return lutDescKey;
};

/**
 * Replaces polydata keys  from overlay configuration with uuids or temporary Ids from inputs or created.
 * @param polyKey - key in currentTool.configuration.
 * @param getState
 */
const replacePolyKeyWithId = (polyKey, getState) => {
    const inputs = getState().activity.manualTask.manualTask.miniWorkflow.currentMaterializedTask.inputs;
    const polyConfiguration = getState().activity.manualTask.manualTask.miniWorkflow.currentTool.configuration.rois.polydatas[polyKey];

    if (polyConfiguration['fromInputs']){
        return  inputs[polyConfiguration['dataInputKey']].value
    }
    else return polyKey;
};

/**
 * Replaces connectome keys  from overlay configuration with uuids or temporary Ids from inputs or created.
 * @param conKey - key in currentTool.configuration.
 * @param getState
 */
const replaceConnectomeKeyWithId = (conKey, getState) => {
    const inputs = getState().activity.manualTask.manualTask.miniWorkflow.currentMaterializedTask.inputs;
    const conConfiguration = getState().activity.manualTask.manualTask.miniWorkflow.currentTool.configuration.rois.connectomes[conKey];

    if (conConfiguration['fromInputs']){
        return  inputs[conConfiguration['dataInputKey']].value
    }
    else return conKey;
};

/**
 * Replaces keys in scene configuration with uuids or temporary ids.
 * @param sceneKey
 * @param sceneConfiguration
 * @param getState
 */
const parseScene = (sceneKey, sceneConfiguration, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const inputs = mTask.miniWorkflow.currentMaterializedTask.inputs;
    const results = mTask.miniWorkflow.currentMaterializedTask.results;
    const bindingSceneElementsToToolOutputs = mTask.miniWorkflow.currentTool.bindingSceneElementsToToolOutputs;
    const roisConfiguration = mTask.miniWorkflow.currentTool.configuration.rois;

    const sceneObject = {};
    sceneObject["primaryImage"] = inputs[sceneConfiguration['primaryImageInputKey']].value;

    if (sceneConfiguration['label']!=null){
        sceneObject["label"] = sceneConfiguration['label'];
    }
    else
        sceneObject["label"] = (inputs[sceneConfiguration['primaryImageInputKey']].label!=null)
          ? inputs[sceneConfiguration['primaryImageInputKey']].label
          : sceneKey;

    if (sceneConfiguration['rois'] != null  && roisConfiguration != null) {
        if ((sceneConfiguration['rois']['overlays'] != null && roisConfiguration['overlays'] != null) ||
            (sceneConfiguration['rois']['masks'] != null && roisConfiguration['masks'] != null)) {
            sceneObject["activeLayer"] = 0; // index of overlay layer responding to paint, fill etc.
            sceneObject["interactiveLayer"] = 0; // index of volume layer responding to window level, colormap etc.
            sceneObject["fromInputs"] = [];
            sceneObject["layers"] = [];
            sceneObject["layerLabels"] = [];
            sceneObject["luts"] = [];
            sceneObject["lutDescriptions"] = [];
            sceneObject["painters"] = [];
            sceneObject["colorMaps"] = [];
            sceneObject["activeColor"] = [];
            sceneObject["masks"] = [];
            sceneObject["visibleOnInit"] =[];

            const pushLayer =(layer,layerType) => {
                // initially this can be false but if outputs exist, then it might be taken from output file
                let isReallyFromInput = roisConfiguration[layerType][layer]['fromInputs'];

                if (roisConfiguration[layerType][layer]['fromInputs'])
                    sceneObject["layers"].push(inputs[roisConfiguration[layerType][layer]['imageInputKey']].value);
                else {
                    let _layer = layer;
                    let _outputToolKey = null;
                    if (results!=null && bindingSceneElementsToToolOutputs!=null){
                        const roiKeyNameBinding = bindingSceneElementsToToolOutputs.find(el=>el.roiKeyName===layer);
                        if (roiKeyNameBinding!=null && roiKeyNameBinding.outputTool!=null) {
                            _outputToolKey = roiKeyNameBinding.outputTool;
                            if (getNestedProp([_outputToolKey,"value"],results)!=null) {
                                if (getNestedProp([_outputToolKey,"doc"],results)!=null){
                                    _layer = getNestedProp([_outputToolKey,"doc","properties","explicit","labelMap","imageEntityId"],results);
                                }
                                isReallyFromInput = true;
                            }
                        }
                    }
                    sceneObject["layers"].push(_layer);
                }
                sceneObject["fromInputs"].push(isReallyFromInput);
                sceneObject["luts"].push(replaceLutKeyWithId(roisConfiguration[layerType][layer]['lutKey'],getState));
                sceneObject["lutDescriptions"].push(replaceLutDescriptionKeyWithId(roisConfiguration[layerType][layer]['lutDescriptionKey'],getState));
                sceneObject["painters"].push(null);
                sceneObject["colorMaps"].push(null);
                sceneObject["activeColor"].push(0);
                sceneObject["layerLabels"].push(roisConfiguration[layerType][layer]['label']!=null?roisConfiguration[layerType][layer]['label']:null); //removing undefined
                sceneObject["visibleOnInit"].push(roisConfiguration[layerType][layer]['onInit']!=null && roisConfiguration[layerType][layer]['onInit']['visible']!=null
                  ?roisConfiguration[layerType][layer]['onInit']['visible'] // if property is defined in configuration, use it
                  :true); //otherwise set visibility on by default
                if (layerType==="overlays")
                     sceneObject["masks"].push(null);
                else sceneObject["masks"].push(layer);
            };

            if (sceneConfiguration['rois']['overlays'] != null && roisConfiguration['overlays'] != null)
              sceneConfiguration['rois']['overlays'].forEach((layer) => pushLayer(layer,'overlays'));

            if(sceneConfiguration['rois']['masks'] != null && roisConfiguration['masks'] != null)
              sceneConfiguration['rois']['masks'].forEach((layer) =>  pushLayer(layer,'masks'));

        }
        if (sceneConfiguration['rois']['polydatas'] != null && roisConfiguration['polydatas'] != null){
            sceneObject["polydatas"]=[];
            if(sceneConfiguration['rois']['polydatas'] != null && roisConfiguration['polydatas'] != null)
                sceneConfiguration['rois']['polydatas'].forEach((layer) =>  sceneObject["polydatas"].push( replacePolyKeyWithId(layer,getState)));
        }

        // connectomes can be single only - plural used for backward compatibility!!!
        if (sceneConfiguration['rois']['connectomes'] != null && roisConfiguration['connectomes'] != null){

            const createConnConf =(conf) => {
                sceneObject["connectomes"] = replaceConnectomeKeyWithId(conf,getState);
            };

            if(sceneConfiguration['rois']['connectomes'] != null && roisConfiguration['connectomes'] != null)
                createConnConf(sceneConfiguration['rois']['connectomes']);
        }
    }
    return sceneObject;
};


const getActiveColor =(getState) =>{
    const {activeViewerId} =  getState().visu.manualTool.manualToolState[MAIN_TOOL];
    const viewersState =  getState().visu.viewers.viewersState;
    const scenes = getState().visu.scenes.scenes;
    const scene = scenes[viewersState[activeViewerId]['sceneId']];
    const activeLayerIndex = scene['activeLayer'];
    return scene['activeColor'][activeLayerIndex];
};

/**
 * Replaces keys in scene configuration with uuids.
 * @param scenes - reference to scenes object
 * @param getState
 * @param dispatch
 * @return (boolean) -
 */
const updatePainters = (scenes, getState,dispatch) => {
    const overlays = getState().visu.images.overlays;
    const images = getState().visu.images.images;
    const luts = getState().visu.luts.luts;
    const lutDescriptions = getState().visu.luts.lutDescriptions;
    let hasChanged = false;


    Object.values(scenes).forEach((scene) => {
        //first check necessary condition - primary image has to be loaded
        if (scene.hasOwnProperty('layers') && images[scene['primaryImage']] != null && images[scene['primaryImage']].state === REQUEST_STATUS_SUCCESS) {

            scene.layers.forEach((el, index) => {
                // update only if painter has not been initialized yet
                if (!(scene.painters[index]!=null)) {
                    const areNonImageInputsLoaded = el!=null
                      && luts[scene['luts'][index]]!=null
                      && luts[scene['luts'][index]].state===REQUEST_STATUS_SUCCESS
                      && lutDescriptions[scene['lutDescriptions'][index]]!=null
                      && lutDescriptions[scene['lutDescriptions'][index]].state===REQUEST_STATUS_SUCCESS
                    ;
                    if (
                        (!scene.fromInputs[index] && areNonImageInputsLoaded) //if overlay has to be created from scratch
                        ||
                        (scene.fromInputs[index] && areNonImageInputsLoaded && overlays[el] != null && overlays[el].state === REQUEST_STATUS_SUCCESS && scene.painters[index] === null)
                    ) {
                        const painter = SpinePaintFilter.newInstance();  //create object
                        painter.setBackgroundImage(images[scene['primaryImage']]['data']); //resample according to original image - create empty volume
                        painter.setCheckMasksFunc(roi=>checkMasks(roi,getState,PREVENT_FROM_CHANGING_VOXEL_VALUE));
                        painter.setToolPropsFunc((tool, prop) => {
                            if (prop!=null && getState().visu.manualTool.manualToolState[tool]!=null)
                                return getState().visu.manualTool.manualToolState[tool][prop];
                            else
                                return getState().visu.manualTool.manualToolState[tool];
                        }); // provide manual tool properties (size)
                        painter.setActiveColorFunc(()=>getActiveColor(getState));
                        painter.update();
                        if (scene.fromInputs[index])
                            painter.setOverlayImageData(overlays[el]['data']); // copy data to resampled volume
                        else {
                           dispatch(initLabelMapInStore(painter.getOutputData(),el));
                        }
                        console.log("Painter for image"+el + " CREATED");
                        painter.modified();
                        hasChanged = true;
                        scene.painters[index] = painter;

                        const data  = luts[scene['luts'][index]].data;
                        const dataDesc  = lutDescriptions[scene['lutDescriptions'][index]].data;
                        if (data!=null){
                            scene.colorMaps[index] = data.map((el,index)=>{return Object.assign({},el,dataDesc[index])});
                            scene.activeColor[index] = scene.colorMaps[index][0].value;
                        }
                    }
                    // WATCH HERE
                    // Extend here for non-layer scene elements
                }
            });
        }
    });
    return hasChanged;
};
/**
 * Updates index of layer that is responsive to drawing, erasing, filling etc.
 * @param sceneKey
 * @param activeLayerIndex
 * @return {function(...[*]=)}
 */
export const updateActiveLayerIndex = (sceneKey, activeLayerIndex) => {
    return (dispatch) => {
        dispatch(changeActiveLayer(sceneKey,activeLayerIndex));
    }
};

/**
 * Updates index of layer that is responding to window interaction (window level, colormap WL).
 * Applies only to original images!!!
 * @param sceneKey
 * @param activeLayerIndex
 * @return {function(...[*]=)}
 */
export const updateInteractiveLayerIndex = (sceneKey, activeLayerIndex) => {
    return (dispatch) => {
        dispatch(changeInteractiveLayer(sceneKey,activeLayerIndex));
    }
};

/**
 * Updates color for painting, filling, etc. If sceneId and layerId not passed, use active Scene and layer
 * @param value
 * @param sceneId - [optional] key
 * @param layerId - id of layer
 * @return {function(...[*]=)}
 */
export const updateActiveColorInScene = (value,sceneId=null,layerId=null) => {
    return (dispatch, getState) => {
        const scenes = getState().visu.scenes.scenes;
        const manualToolState = getState().visu.manualTool.manualToolState;
        const viewerState =  getState().visu.viewers.viewersState;
        const {activeViewerId} = manualToolState[MAIN_TOOL];
        const sceneKey = sceneId!=null? sceneId: viewerState[activeViewerId]['sceneId'];
        const activeLayerIndex = layerId!=null? layerId: scenes[sceneKey]['activeLayer'];
        dispatch(changeActiveColor(sceneKey,activeLayerIndex,value));
    }
};

/**
 * Update colormap in the scene.
 * @param colormapValue - color
 * @param sceneKey -  key
 * @param layerIndex - id of layer
 * @return {function(...[*]=)}
 */
export const updateColorMapInScene = (colormapValue,sceneKey,layerIndex) => {
    return (dispatch) => {
        dispatch(updateColormap(sceneKey,layerIndex,colormapValue));
    }
};

/**
 * Just helper to get primary image id based on overlay key.
 * It uses state of Store, not scene configuration!
 * Returns uuid of primaryimage.
 */
export const findPrimaryImageForOverlayKey =(getState,overlayKey)=>{
    const scenes = getState().visu.scenes.scenes;
    let result = null;
    for( const scene of Object.values(scenes)) {
        if (scene['layers'] != null && Array.isArray(scene['layers'])) {
            if (scene['layers'].indexOf(overlayKey) > -1) {
                result = scene["primaryImage"];
                break;
            }
        }

    }
    return result;
};


/**
 * Check mask values
 * TODO Implement other types of masks : function
 * @param mask - object
 * @param roiValue - scalar
 * @return {boolean|boolean} - if there is no preventing masks, then return true
 */

const checkMaskValue = (mask,roiValue)=>{
    if (mask.type==="scalar"){
        if (mask.value===roiValue)
             return false;
    }
    if (mask.type==="vector"){
        if (mask.value.includes(roiValue))
            return false;
    }
    return true;
};

/**
 * Check whether there is mask preventing from adding a pin.
 * It uses selector
 * @param getState - required to use selector
 * @param roi - ijk point
 * @param preventionMode - {PREVENT_FROM_ADDING_PIN, PREVENT_FROM_CHANGING_VOXEL_VALUE}
 * @return {boolean|boolean} - if there is no preventing masks, then return true
 */
export const checkMasks=(roi,getState,preventionMode)=>{
    const getMasks = ()=>{
        if (preventionMode===PREVENT_FROM_ADDING_PIN)
            return  getMasksPreventingFromAddingPin(getState());
        if (preventionMode===PREVENT_FROM_CHANGING_VOXEL_VALUE)
            return getMasksPreventingFromDrawing(getState());
    };
    let result = true;
    const masks = getMasks();
    masks.forEach((mask)=>{
        const data = getState().visu.images.overlays[mask['overlayId']].data;
        const scalarData = data.getPointData().getScalars().getData();
        const dims = data.getDimensions();
        const roiValue = scalarData[roi[0] + roi[1] * dims[0] + roi[2] * dims[0] * dims[1]];
        result = result && checkMaskValue(mask,roiValue);
    });
    return result;
};


/**
 * Invert color with background.
 * Requires widget for inversion in Store.
 * @return {function(...[*]=)}
 */
export const invertColors = () => {
    return (dispatch, getState) => {
        const manualToolState = getState().visu.manualTool.manualToolState;
        if (manualToolState[INVERSION_TOOL]!=null && manualToolState[INVERSION_TOOL]['valueToInvert']!=null) {
            const painter = getActivePainterSelector(getState());
            painter.invertColorsInOverlay(manualToolState[INVERSION_TOOL]['valueToInvert']);

        }
    }
};
