import React, { useEffect, useState } from "react";
import PlaySlidesManual from "./slideType";
import store from "../../Store";
import axios from "axios/index";
import { HOST_URL } from "../../../Constants";

const SlideShow = (props) => {
  const { match } = props;
  const [requestData, setRequestData] = useState(new Date());
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
    axios
      .get(HOST_URL + "/api/presentation/" + match.params.presentationId + "/slide")
      .then((response) => {
        console.log("PresentationAction.js :: getSlideList :: response ::", response);
        if (response.status === 200) {
          setItems(response.data);
        }
      })
      .catch((error) => {
        console.log("project err:", error);
      });
  }, [requestData]);

  return <PlaySlidesManual slides={items} type="slideShow" />;
};

export default SlideShow;
