import React from "react";
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';




/**
 * Dumb component for displaying Tutorial Video on Full screen.
 * @returns {*}
 */
export const TutorialVideo = ({visible,videoId, onEnd, containerStyle}) => {

    const slide_style = {width: 'calc( 100% - 50px)%', height: 'calc( 100% - 50px)', position: 'fixed',  display:"block"};

    const playerOpts = {
        height: '100%',
        width: '100%',
        host: 'https://www.youtube-nocookie.com',
        playerVars: { // https://developers.google.com/youtube/player_parameters
            rel:0,  // this parameter is not working after September 2018 - the related videos are alwas displayed
            autoplay: 1,
            cc_load_policy: '1',
            cc_lang_pref:'fr',
            mute:0
        }
    };

    return (
        <React.Fragment>
        {visible &&
        <div style={containerStyle!=null?containerStyle:slide_style} className="slideshow-div fade">
            <YouTube videoId={videoId}
                     onEnd={onEnd}
                     opts={playerOpts}
                     height="100%"
                     width="100%"
                     containerClassName="youtubeVideo"/>
        </div>
        }
        </React.Fragment>
    );
};

TutorialVideo.propTypes={
    visible:PropTypes.bool.isRequired,
    videoId:PropTypes.string.isRequired,
    onEnd:PropTypes.func.isRequired
};





