import React from 'react'
import axios from "axios/index";
import {HOST_URL} from "../../../Constants";
import {
  CLEAR_ROI,
  ROI_GET_FAIL,
  ROI_GET_REQUESTED,
  ROI_GET_SUCCESS
} from "./actionType";




const requestROI = (id) => ({
  type: ROI_GET_REQUESTED,
  id
});

const errorROI = (error,id) => ({
  type: ROI_GET_FAIL,
  error,
  id
});

const successROI = (id,roi) => ({
  type: ROI_GET_SUCCESS,
  id,
  roi
});


export const clearROI = () => ({
  type: CLEAR_ROI,
});


/**
 * Action Creator for loading ROI Data into memory
 * @param roiId
 * @return {function(...[*]=)}
 */
export const getROIData = (roiId) => {
  return (dispatch,getState) => {
    dispatch(requestROI(roiId));
    // Needs to be retrieved from the plotSettings
    // The experimentProperties should have the experiemntId, lut_key, lutDescription_key
    const config = {headers: {"Authorization": "bearer" + getState().auth.token_bearer}};
    axios.get(`${HOST_URL}/api/roi/${roiId}`, config)
      .then((response) => {
        console.log("ROIAction :: getROIData() :: success");
        dispatch(successROI(roiId,response.data))

      }).catch((err) => {
        console.log("ROIAction :: getROIData() :: failure");
        dispatch(errorROI(err,roiId));
      });
  }
};


/**
 * Async function to get am ROI without Redux dispatching
 * @param id
 * @return {Promise<Response<any>>}
 */
export const getROI = async (id) => {
  const response = await axios.get(`/api/roi/${id}`);
  return response.data;
};