import {withRouter} from "react-router";
import {connect} from "react-redux";
import {getSelectedModelParameters, getSelectedPlotData} from "../selectors/SAMSelectors";
import IRRStatsPanel from "../component/IRRStatsPanel";



const mapStateToProps = (state,ownProps) => {
  return {
    modelParameters: getSelectedModelParameters(state),
    plotData: getSelectedPlotData(state)
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
  }
};

export const ContainerIRRStatsPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(IRRStatsPanel));