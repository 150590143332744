import {connect} from "react-redux";
import LandingPage from "./LandingPage";


const mapStateToProps = state => {
  return {
    view: state.geo.view,
    viewState: state.geo.viewState
  }
};

const mapDispatchToProps = () => {
  return {
    }
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerLandingPage= containerCreator(LandingPage);