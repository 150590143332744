import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {ContainerMeasurementPanelContent} from "../containers/ContainerMeasurementPanelContent";
import {ContainerCasesPanel} from "../containers/ContainerCasesPanel";
import {ContainerValidDataPanel} from "../containers/ContainerValidDataPanel";
import {ContainerPlotParametersPanelContent} from "../containers/ContainerPlotParametersPanelContent";


/**
 * Component used for displaying distribution panel in sidebar.
 *
 *
 */

class DistributionPanel extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {t,instances} = this.props;
    return (
            <div>
              <ContainerMeasurementPanelContent/>
              <ContainerCasesPanel/>
              <ContainerValidDataPanel/>
              <ContainerPlotParametersPanelContent/>
            </div>
      );
  }
}

export default withTranslation()(DistributionPanel);

DistributionPanel.propTypes = {
  rawData:PropTypes.object.isRequired,
  plotSettings:PropTypes.object.isRequired,
  updatePlotSettingsProperty:PropTypes.func.isRequired,
  t: PropTypes.func
};
