import React from 'react'
import PropTypes from 'prop-types';
import ScatterPlot2 from "./ScatterPlot2";
import {Growl} from 'primereact/components/growl/Growl';
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Card} from "primereact/components/card/Card";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Rating} from "primereact/components/rating/Rating";
import {Panel} from "primereact/components/panel/Panel";
import SpineVtkMRIBrowser from "../../../vtk/SpineVtkMRIBrowser";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {BetasTable} from "../BetasTable";
import {EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID} from "../../../../Constants";
import {TestSignificancePanel} from "../TestSignificancePanel";
import {TestConclusionPanel} from "../TestConclusionPanel";
import {ContainerViewer} from "../../../visualization/containers/ContainerViewer";


/**
 *  Panel for ScatterPlot and dropdowns controlling what is actually displayed.
 *  This is version for multivariate case.
 *
 */
export class MultiScatterPlotPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexY:0,
            indexX:1,
            xLabel:'',
            yLabel:'',
            action:null,
            actionValue:'None',
            selectedDataPointIndex:-1,  //index of selected dot
            groups:null,
            data:null,
            ciLevel:0.95, //required to create SLRModel
            fitted:null,
            confidenceBounds:null,
            lineValue:'None',
            isLoading: true,
            displayImage:false,
            displayData:false,
            indexToDisplay:0,  //index of data to display
            workflowResultId:null,
            significancePanelCollapsed:false,
            urlContext:'nifti/'
        };
        this.showMessage = this.showMessage.bind(this);
        this.showAlert = this.showAlert.bind(this);
        this.onGroupsChange = this.onGroupsChange.bind(this);
        this.onRegressorsChange = this.onRegressorsChange.bind(this);
        this.onActionsChange = this.onActionsChange.bind(this);
        this.onLinesChange = this.onLinesChange.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onShow = this.onShow.bind(this);
        this.calculateStatistics = this.calculateStatistics.bind(this);
    }

    onHide(){
        this.setState({displayImage:false,displayData:false, selectedDataPointIndex:-1});
        document.body.classList.remove("ui-overflow-hidden");
    }
    onShow(){
        document.body.className += ' ' + 'ui-overflow-hidden';
    }
    showMessage(d) {
        this.growl.show({sticky:true, severity: 'info', summary: 'Data Point', detail: this.props.xlabel + d.x + this.props.ylabel+ d.y
            + 'Tool:' + d.groups + 'Version:' + d.groups2+  'Contributor:' + d.groups3});
    }
    showAlert(d){
        alert( this.props.xlabel + d.x + '\n'+this.props.ylabel+ d.y
            + '\nTool:' + d.groups + '\nVersion:' + d.groups2+  '\nContributor:' + d.groups3);
    }


    calculateStatistics(){
        let y = this.props.data.map(function (obj) {
            return obj.variablesValues[0].variableValue;
        });
        let data=[];
        this.props.data.forEach(((s)=>{
            let k=[];
            s.variablesValues.forEach((n,i)=>{
                if (i>0) //remove y from data
                k.push(n.variableValue)});//add x vector value
            data.push(k)}));

        console.log(data);

        let transferObject = {};
        transferObject.x = data;
        transferObject.y = y;
        transferObject.l = this.state.ciLevel;

        //Redux action below - can be moved upper

        this.props.calculateParameters(this.props.vars.length, transferObject);

    }

    componentDidMount() {
        const {descriptions}=this.props;
        this.setState({xLabel:descriptions[1].name});
        this.setState({yLabel:descriptions[0].name});
        if (this.props.modelStatus!=='success')
        this.calculateStatistics();

    }
    onGroupsChange(event){
        this.setState({
            groups: event.value
        });
    }

    onRegressorsChange(event){
        this.setState({
            indexX: event.value
        });
    }

    onActionsChange(event){
        switch(event.value)
        {
            case 'Growl':
                this.setState({
                    action: this.showMessage,
                    actionValue:'Growl'
                });
                break;
            case 'Alert':
                this.setState({
                    action: this.showAlert,
                    actionValue:'Alert'
                });
                break;
            default:
                this.setState({
                    action: null,
                    actionValue:'None'
                });
        }


    }


    onLinesChange(event){
        this.setState({
            lineValue:event.value
        });
    }

    processDataForScatter(dataToProcess){
        let dataForScatter = [];
        if (!dataToProcess.length>0) return null;
        dataToProcess.forEach((s,index)=>{
          let single = {
                    "y": s.variablesValues[this.state.indexY].variableValue,
                    "x": s.variablesValues[this.state.indexX].variableValue,
                    "visitX": s.variablesValues[this.state.indexX].visitLabel,
                    "visitY": s.variablesValues[this.state.indexY].visitLabel,
                    "dateX":  s.variablesValues[this.state.indexX].visitDate,
                    "dateY":  s.variablesValues[this.state.indexY].visitDate,
                    "index":  index
                };
        if (s.variablesValues[this.state.indexX].hasOwnProperty('workflowResultId'))
        //if(s.variablesValues[this.state.indexX].hasOwnProperty('workflowResultId')&&s.variablesValues[this.state.indexX]['workflowResultId'].length>0)
                   single['workflowResultId']=s.variablesValues[this.state.indexX].workflowResultId;
        if (s.variablesValues[this.state.indexY].hasOwnProperty('workflowResultId')){
            if(!(single['workflowResultId'] != null && single['workflowResultId'].length>0))
                    single['workflowResultId']=s.variablesValues[this.state.indexY].workflowResultId;
        }

        //Comment: What if they are not the same?!

        dataForScatter.push(single)}
        );

        return dataForScatter;
    }

    render() {
        const {vars,descriptions}=this.props;
        const {workflowResultId,selectedDataPointIndex} = this.state;

        let dataForScatterPlot = this.processDataForScatter(this.props.data);

        let footer = <div>
            <Button label="Close" icon="fa-check" onClick={this.onHide} />
        </div>;
        let thisView = this;
        //Dialog window flags
        const displayImage=this.state.displayImage;
        const displayData=this.state.displayData;
        const title =  (this.state.displayImage)?`Image data for: ${workflowResultId}`:'Clinical data';


        const groups = [
            {label: 'None', value: null},
            {label: 'Visit Label X', value: 'visitX'},
            {label: 'Visit Label Y', value: 'visitY'},
            {label: 'Visit date X', value: 'dateX'},
            {label: 'Visit date Y', value: 'dateY'},
        ];
        let regressors =[];
        vars.forEach((s,i)=>{
                let label = ''
                if (s["entityType"]==="SUBJECT")
                    label = s['clinicalVariableId']['name'] + ' [' + s['clinicalVariableId']['units'] + ']';
                else if(s["entityType"]==="ANATOMICAL")
                    label = s['metricAnnotation']['preferredName'] +" of "+
                        s['anatomicalAnnotation']['preferredName']  ;
                else if(s["entityType"]==="PATHOLOGICAL")
                    label = s['metricAnnotation']['preferredName'] +" of " +
                        s['pathologicalAnnotation']['preferredName'] + " in "+
                        s['anatomicalAnnotation']['preferredName'] + " [mm3]";
                // TODO: We are missing the units for each variable. We mocked using
                // TODO: mm3, but the back-end should take care of this!!!
                //let label= descriptions[i].name + ' ['+descriptions[i].units+']';
                regressors.push(Object.assign({},s,{value:i},{label: label}));
            });

        const clickActions = [
            {label: 'None', value: 'None'},
            {label: 'Alert', value:'Alert'},
            {label: 'Growl', value: 'Growl'}
        ];
        const linesActions = [
            {label: 'None', value: 'None'},
            {label: 'Line', value:'Line'},
            {label: 'Line + CI', value: 'CI'},
            {label: 'Line + CI + PI', value: 'PI'}
        ];
        let dataPoint = {};
        let menu = [
            {
                title: 'Clinical data',
                action: function(elm, d, i) {
                    console.log('Item #1 clicked!');
                    // console.log('The data for this circle is: ' + d);
                    thisView.setState({displayData:true, indexToDisplay:i});
                }
            },
            {
                title: 'Image',
                action: function(elm, d, i) {
                    console.log('You have clicked the second item!');
                    console.log('The data for this circle is: ' + d);
                    if (d.hasOwnProperty('workflowResultId'))
                    thisView.setState({displayImage:true,  workflowResultId:d.workflowResultId });
                }
            }
        ];

        let leftClick= function(d){
            if (d.hasOwnProperty('workflowResultId')&&d['workflowResultId'].length>0) {
                thisView.setState({
                    displayImage: true,
                    workflowResultId: d.workflowResultId,
                    selectedDataPointIndex: d.index
                });
            }
        };

        let vars__=this.props.descriptions;
        let printSlopes = function(betas__){
            return betas__.map((s,i)=>{
                if(i>0){
                    const sign = (Number.parseFloat(s)>0)?' + ':' - ';
                return ` ${sign} ${Math.abs(Number.parseFloat(s)).toFixed(2).toString()}*${vars__[i].name}` ;}
                else return null;
            }).join(' ')
        };
        const xLabel = descriptions[this.state.indexX].name+' ['+descriptions[this.state.indexX].units+']';
        const yLabel = descriptions[this.state.indexY].name+' ['+descriptions[this.state.indexY].units+']';

        return (
            <div>
                <Growl ref={(el) => { this.growl = el; }}/>
                <div className="ui-g">
                    <div className="ui-lg-2"/>
                    <div className="ui-g-12 ui-md-12 ui-lg-8">
                        { this.props.modelStatus!=='success' && dataForScatterPlot!=null &&
                        <div> Processing data in R Server: <i className="fa fa-spinner fa-spin"/></div>
                        }
                        {this.props.modelStatus==='success' && dataForScatterPlot!=null &&
                        <p style={{color:'#009d9b', fontFamily: "Roboto", fontSize:"larger"}}>
                            {this.state.yLabel} =

                            {Number.parseFloat(this.props.modelParameters.betasEstimates[0]).toFixed(2)}
                            {printSlopes(this.props.modelParameters.betasEstimates)}</p>
                        }
                        {/** If key of component is changed,  component is not recognized
                         i.e. it is unmounted and mounted again*/}
                        {this.props.modelStatus!=='success' && dataForScatterPlot!=null &&
                        <ScatterPlot2 key={this.state.indexX}
                                      data={dataForScatterPlot}
                                      selectedDataPointIndex={selectedDataPointIndex}
                                      width={1050}
                                      height={500}
                                      slrmodel={null}
                                      contextMenu={menu}
                                      showRegLine={this.state.lineValue!=='None'}
                                      showCILine={(this.state.lineValue!=='None')&&(this.state.lineValue!=='Line')}
                                      showPILine={this.state.lineValue==='PI'}
                                      xLabel={xLabel}
                                      renderXLabel={this.props.vars.length<3}
                                      yLabel={yLabel}
                                      onDataPointClick={leftClick}
                                      colorCat={this.state.groups}/>
                        }
                        {this.props.modelStatus==='success' && dataForScatterPlot!=null &&

                        <ScatterPlot2 key={this.state.indexX+100}
                                      data={dataForScatterPlot}
                                      selectedDataPointIndex={selectedDataPointIndex}
                                      width={1050} height={500}
                                      contextMenu={menu}
                                      slrmodel={(this.props.vars.length<3)?this.props.modelParameters:null}
                                      showRegLine={this.state.lineValue!=='None'}
                                      showCILine={(this.state.lineValue!=='None')&&(this.state.lineValue!=='Line')}
                                      showPILine={this.state.lineValue==='PI'}
                                      xLabel={xLabel}
                                      renderXLabel={this.props.vars.length<3}
                                      yLabel={yLabel}
                                      onDataPointClick={leftClick}
                                      colorCat={this.state.groups}/>
                        }
                        {this.props.vars.length>2 &&
                        < Dropdown value={this.state.indexX}
                                   options={regressors}
                                   onChange={this.onRegressorsChange}
                                   style={{width:'auto',float: 'right',
                                       marginRight: '160px',
                                       marginTop: '-25px'}}/>

                        }
                    </div>
                    <div className="ui-lg-2"/>
                </div>

                <div className="ui-g">
                    <div className="ui-g-12 ui-md-6 ui-lg-4"> Categories: <Dropdown value={this.state.groups}
                                                                                    options={groups}
                                                                                    onChange={this.onGroupsChange}
                                                                                    style={{width:'auto'}}
                    />

                        {/*Actions:<Dropdown value={this.state.actionValue}*/}
                        {/*options={clickActions}*/}
                        {/*onChange={this.onActionsChange}*/}
                        {/*style={{width:'auto'}}*/}
                        {/*/>*/}
                        { this.props.vars.length<3 && dataForScatterPlot!=null &&
                        <div key={this.props.vars.length}>
                            Intervals: <Dropdown value={this.state.lineValue}
                                             options={linesActions}
                                             onChange={this.onLinesChange}
                                             style={{width:'auto'}}
                        /></div>}
                    </div>
                    <div className="ui-g-12 ui-md-6 ui-lg-4">
                        {this.props.modelStatus==='success'  && this.props.vars.length<3 && dataForScatterPlot!=null &&
                        <DataTable value={this.props.modelParameters.betasCIDataTableModel.data}>
                            <Column field="parameter" header="Parameter" />
                            <Column field="lwr" header={this.props.modelParameters.betasCIDataTableModel.lwr} />
                            <Column field="upr" header={this.props.modelParameters.betasCIDataTableModel.upr} />
                        </DataTable>
                        }

                    </div>
                    <div className="ui-g-12 ui-md-6 ui-lg-4">
                        { this.props.vars.length<3 && dataForScatterPlot!=null &&
                        <div key={this.props.vars.length}>
                           <TestConclusionPanel {...this.props}/>
                           <TestSignificancePanel {...this.props}/>
                        </div>   }
                    </div>
                </div>
                {this.props.modelStatus==='success'  && this.props.vars.length>2 && dataForScatterPlot!=null &&
                <BetasTable vars={this.props.vars} betas={this.props.modelParameters.betasEstimates}/>
                }

                <Dialog header={title } closeOnEscape={true} resizable={true}
                        visible={this.state.displayImage || this.state.displayData} modal={false}
                        onHide={this.onHide} onShow={this.onShow}
                        contentStyle={{minHeight:'400px',minWidth:'600px'}}
                        style={{minHeight:'400px',minWidth:'600px'}}>
                    {displayImage &&
                        <ContainerViewer imageId={workflowResultId}/>
                    }

                    {displayData &&
                    <Panel>
                        <DataTable value={this.props.data[this.state.indexToDisplay].variablesValues}>
                            <Column field="variableName" header="Name" />
                            <Column field="variableValue" header="Value" />
                            <Column field="visitDate" header="Date" />
                            <Column field="visitLabel" header="Visit Id" />
                            <Column field="subjectId" header="Subject Id" />
                        </DataTable>
                    </Panel>
                    }
                </Dialog>

            </div>

        )
    }
}

MultiScatterPlotPanel.propTypes = {
    errorMessage: PropTypes.string,
    data:PropTypes.array,
    vars:PropTypes.array,
    modelStatus:PropTypes.string,
    modelParameters:PropTypes.object  //GLRModel or SLRModel object
};


