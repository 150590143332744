import React, { Component } from 'react'
import {scaleOrdinal,scaleTime,scalePoint} from "d3-scale";
import {event, select} from "d3-selection";
// import {timeFormat} from "d3-time-format";
import {axisBottom, axisLeft} from "d3-axis";
import d3Tip from "d3-tip"
import {max, min} from "d3-array";
import PropTypes from "prop-types";
import {zoom} from "d3-zoom";
import {schemeCategory10} from "d3-scale-chromatic";

/**
 * Timeline component to display:
 *  - points in time represented as dots
 *  - intervals in time represented as lines
 *
 *  Designed to visualize time of eventLogs occurrence  (point) and time to fill in the questionnaire.
 * Exemplary use:
 * <Timeline data={timelineData} yLabel={""} xLabel={"Time"} yAttribute={"subjectId"} colorCat={"category"}/>
 * where
 *     [{
        "startDate": "2022-02-23T12:24:44.971Z",
        "endDate": "2022-02-23T12:25:08.223Z",
        "category": "START_EXPERIMENT",
        "subjectId": "4b83164d25fa02f290fb284566003041",
        "label": "START_EXPERIMENT",
        "type": "q"
    },
 {
        "startDate": "2022-03-05T00:05:37.379Z",
        "category": "SLEEP",
        "subjectId": "4b83164d25fa02f290fb284566003041",
        "label": "Testing logs",
        "type": "e"
    }]

 */
class Timeline extends Component {

  constructor(props){
    super(props);
    this.state = {
      objects:null,
      DOT_RADIUS : 4
    };
    ["createPlot"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }
  componentDidMount() {
    this.createPlot();
    // fix for conflict between dialog component (with dynamic z-index calculation) and d3-tip with static one
    const coll = document.getElementsByClassName("d3-tip");
    if (coll!=null && coll[0]!=null)
      coll[0].style.zIndex=1000000;
  }

  componentDidUpdate(prevProps,prevState,ss) {
    select(this.node)
      .selectAll("*")
      .remove();
    this.createPlot();
    // fix for conflict between dialog component (with dynamic z-index calculation) and d3-tip with static one
    const coll = document.getElementsByClassName("d3-tip");
    if (coll!=null && coll[0]!=null)
      coll[0].style.zIndex=1000000;
  }


  componentWillUnmount() {
    select(this.node)
      .selectAll("*")
      .remove();
    const coll = document.getElementsByClassName("d3-tip");
    for (let i=0;i<coll.length;i++)
      coll[i].remove();
  }

  createPlot() {
    const{DOT_RADIUS} = this.state;
    const {margin,yAttribute,yAttributeFunction} = this.props;
    const node = this.node;   //svg ref
    let outerWidth = (this.props.width===undefined)?960:this.props.width;
    let outerHeight = (this.props.height===undefined)?500:this.props.height;
    let data = this.props.data;
    let colorCat = this.props.colorCat;
    let xLabel = (this.props.xLabel==null)?'x':this.props.xLabel;
    let yLabel = (this.props.yLabel==null)?'y':this.props.yLabel;


    let color = scaleOrdinal(schemeCategory10);
    const STROKE_WIDTH = 1;

    // set the dimensions and margins of the graph
    let
      width = outerWidth - margin.left - margin.right,
      height = outerHeight - margin.top - margin.bottom;

    // set the ranges
    let xScale = scaleTime()
      .range([0, width]);

    let yScale = scalePoint()
      .range([20,height-20]);

    let xValueStart = function(d) {  return new Date(d.startDate);  };
    let xValueEnd = function(d) {  return new Date(d.endDate);  };

    let yValue = function(d) {
      if (yAttributeFunction!=null)
        return yAttributeFunction(d[yAttribute]);
      else
        return d[yAttribute];
    };
    // set domains


    if (data.length === 0)
      return;

    let xMin1 = min(data, xValueStart);
    let xMin2 = min(data, xValueEnd);

    let xMax1 = max(data, xValueStart);
    let xMax2 = max(data, xValueEnd);

    let xMin = (xMin1<xMin2 || xMin2===undefined)
      ? xMin1
      : xMin2;

    let xMax = (xMax1 > xMax2 || xMax2===undefined)
      ? xMax1
      : xMax2;

    xScale.domain([new Date(xMin.getTime()-24*60*60*1000), new Date(xMax.getTime()+24*60*60*1000)]);
    yScale.domain(data.map(yValue));

    console.log("DATA DOMAINS: xScale ", xScale.domain());
    console.log("DATA DOMAINS: yScale ", yScale.domain());

    let xAxis = axisBottom(xScale).tickSizeInner(-height);
    // xAxis.ticks(10).tickFormat(timeFormat("%Y-%m-%d"));
    let yAxis = axisLeft(yScale).tickSizeInner(-width);



    // set  tips
    let tip = d3Tip()
      .attr("class", "d3-tip")
      .offset([-10, 0])
      .html(function(d) {
        let date = new Date(d['startDate']);
        let type = d.type==="q"?"QUESTIONNAIRE":"ACTION";
        return type + "<br/>" + d['label'] + "<br/>"+xLabel + ": " + date.toUTCString();
      });

    //zoom parameters and handler assignment
    let zooming = zoom()
      .scaleExtent([.5, 1000])
      .translateExtent([[0, 0], [width, height]])
      .extent([[0, 0], [width, height]])
      .on("zoom", zoomEventHandler);


    // append the svg object to the body of the page and translate the parent group
    // calculate the rectangle embraced by axes
    let svg = select(node)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .call(zooming);

    svg.call(tip);

    svg.append("rect")
      .attr("width", width)
      .attr("height", height);

    // x Axis---------------------------
    if(this.props.renderXLabel) {
      svg.append("g")
        .attr("class","x axis")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .append("text")
        .attr("class", "label")
        .attr("fill", "#000")
        .attr("font-size", "0.7em")
        .attr("x", width)
        .attr("y", margin.bottom - 10)
        .style("text-anchor", "end")
        .text(xLabel);}

    else  svg.append("g")
      .attr("class","x axis")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);


    // y Axis---------------------------
    svg.append("g")
      .attr("class","y axis")
      .call(yAxis)
      .append("text")
      .attr("class", "label")
      .attr("font-size", "0.7em")
      .attr("fill", "#000")
      .attr("transform", "rotate(-90)")
      .attr("y", -margin.left)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .text(yLabel);

    let objects = svg.append("svg")
      .attr("class", "objects")
      .attr("width", width)
      .attr("height", height);



    // x Axis Horizontal Line------------------------
    objects.append("svg:line")
      .attr("class","axisLine hAxisLine")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", width)
      .attr("y2", 0)
      .attr("transform", "translate(0," + height + ")");
    // y Axis Vertical Line------------------------
    objects.append("svg:line")
      .attr("class","axisLine vAxisLine")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", height);


    // Data Points ---------------------------------
    objects.selectAll(".dot")
      .data(data.filter(el=>el.type==="e"))
      .enter()
      .append("circle")
      .attr("class","dot")
      .attr("r", DOT_RADIUS)
      .attr("cx", function(d) {
        return xScale(new Date(d.startDate));
      })
      .attr("cy", function(d) {
        return yScale(yValue(d));
      })
      .style("fill", function(d) {
         return (colorCat!=null)?color(d[colorCat]):"#000"; })
      .style("fill-opacity", 0.5)
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide);


    objects.selectAll(".box")
      .data(data.filter(el=>el.type==="q"))
      .enter()
      .append("line")
      .attr("class","box")
      .attr("x1", function(d) {
        return xScale(new Date(d.startDate));
      })
      .attr("x2", function(d) {
        return xScale(new Date(d.endDate)) - xScale(new Date(d.startDate))>1
          ? xScale(new Date(d.endDate))
          : xScale(new Date(d.startDate))+1;
      })
      .attr("y1", function(d) {
        return yScale(yValue(d))-5;
      })
      .attr("y2", function(d) {
        return yScale(yValue(d))-5;
      })
      .style("opacity", 0.5)
      .style("stroke", function(d) {
        return (colorCat!=null)?color(d[colorCat]):"#000"; })
      .style("stroke-width", 10)
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide);


    //====rendering legend==============================
    if (colorCat!= null) {

      //===rendering legend  =================================

      const legendGroup = svg
        .append("g")
        .attr("id","legend")
        .attr("transform","translate(" + (margin.left) + ",0)");

      let legend = legendGroup.selectAll(".legend")
        .data(color.domain())
        .enter()
        .append("g")
        .attr("class", "legend")
        .attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; });

      legend.append("circle")
        .attr("r", DOT_RADIUS)
        .attr("cx", width + 20)
        .attr("fill", color)
        .attr("fill-opacity", .5)
      ;

      legend.append("text")
        .attr("x", width + 26)
        .attr("font-size","12px")
        .attr("dy", ".35em")
        .text(function(d) { return d; });
    }



    function zoomEventHandler() {
      const transform = event.transform;
      svg.select(".x.axis").call(xAxis.scale(transform.rescaleX(xScale)));

      svg.selectAll("circle.dot")
        .attr("cx", function(d) { return transform.applyX(xScale(new Date(d.startDate))); });

      svg.selectAll("line.box")
        .attr("x1", function(d) { return transform.applyX(xScale(new Date(d.startDate))); })
        .attr("x2", function(d) {
          return transform.applyX(xScale(new Date(d.endDate))) - transform.applyX(xScale(new Date(d.startDate)))>1
            ? transform.applyX(xScale(new Date(d.endDate)))
            : transform.applyX(xScale(new Date(d.startDate)))+1;
        })

    }


  }
  render() {
    return     <svg ref={node => this.node = node} />   ;
  }
}
export default Timeline

Timeline.defaultProps = {
  margin:{ top: 50,  right: 300, bottom: 50,  left: 150},
  yAttribute:"subjectId"
};

Timeline.propTypes = {
  data: PropTypes.array.isRequired,
  width:PropTypes.number,
  height:PropTypes.number,
  xLabel:PropTypes.string.isRequired,
  yLabel:PropTypes.string.isRequired,
  colorCat:PropTypes.string,
  margin:PropTypes.object,
  yAttribute:PropTypes.string.isRequired, // which attribute shall be used in y axis (only categorical)
  yAttributeFunction:PropTypes.func.isRequired // which attribute shall be used in y axis (only categorical)
};