/**
 * Helper for handling network requests with fetch.
 * Designed for R only, however in future shall be merged  with RequestService to handle all communication.
 *
 * Handles three cases of response for error:
 *  - containing "attributes" and "value" properties (eg. jsonlite response for glm request)
 *  - containing "error" and "message" properties from R Server (eg. jsonlite response for kappa request)
 *  - containing response from Proxy when R Server is off (eg. Bad Gateway 502 SC)
 *
 *  It communicates with class responsible for displaying error messages in growl with use of "warning" flag
 *  which indicates whether this is numerical error in R (warning) or error in connection.
 *  @see errorHandler.js
 */

class RequestServiceR {

  /** async function for getting data in JSON
   *
   * @param url
   * @param options
   * @returns {Promise<*>}
   */
  async getRequest(url,options){
    return await (fetch(url,options)
        .then(async res => {
          if (res.ok===true)
            return res.json();
          else{
            const data  = await res.json();
            throw new RException('getRequest',res,data);
          }
        })
        .catch(err => {
          throw err
        })
    );
  }
}

export default new RequestServiceR()

function RException (f, res,data){
  this.message = 'Network error:: RequestService::'+f+' for url:'+res.url+' Status: ' + res.status +' ' +res.statusText;
  try {
    if (res.status===502){// if no response body from R server, use proxy server response body
      this.body = data;
    }
    else if (data.hasOwnProperty("value")) {
      this.body = parseRErrorResponse(data);
    }
    else {
      this.body = data;
      this.body["status"] = data["error"];
      this.body["warning"] = true;
    }
  }
  catch (e) {
    this.body = data;  // if no response body from R server, use proxy server response body
  }
}

/**
 * Parse response object from Plumber and convert it to standard Hapi error response with additional parameter warning!
 * Does not handle exceptions - they are handled anyway in getRequest (with default message)
 * @param {object} data - response body
 */
function parseRErrorResponse (data){
 return {
   message:  data["value"][1]["value"][0],
   error : data["value"][0]["value"][0],
   statusCode: data["value"][0]["value"][0],
   warning :true
 }
}