import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {getSelectedPlotSettings} from "../selectors/SAMSelectors";
import SeriesFilterEditor from "../component/SeriesFilterEditor";
import {getSelectedContributionsFilteredByMeasurement} from "../selectors/ContributionsSelectors";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    filters: getSelectedPlotSettings(state).filters,
    compositeKey: getSelectedPlotSettings(state).compositeKey,
    reps: getSelectedPlotSettings(state).reps,
    selectedContributionsFiltered: getSelectedContributionsFilteredByMeasurement(state),
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    }
  }
};

export const ContainerSeriesFilterEditor = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SeriesFilterEditor));