import React from 'react'
import {withRouter} from 'react-router'
import {Button} from "primereact/components/button/Button";
import PropTypes from "prop-types";
import {HOST_URL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {convertISODateAndTimeToLocaleString} from "../../helpers/dates";
import {Dialog} from "primereact/components/dialog/Dialog";
import {getNestedProp} from "../../helpers/expressions";
import InputTextWithValidation from "../../helpers/validation/InputTextWithValidation";
import {ruleRunner, run} from "../../helpers/validation/ruleRunner";
import {isEmail, required} from "../../helpers/validation/rules";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import Timeline from "./Timeline";
import {ContainerTimelinePanel} from "../containers/ContainerTimelinePanel";
import axios from "axios/index";
import ActionsTable from "./ActionsTable";
import QuestionnairesTable from "./QuestionnairesTable";

const fieldValidations = [
  ruleRunner("email", "Email", required, isEmail),
];
class SpineQExperimentMonitor extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      showTimeline:false,
      showAnswers:false,
      showLogs:false,
      showInvalid:false,
      showBackups:false,
      addDialog:false,
      email: '',
      cohortId:"",
      showErrors: false,
      validationErrors: {},
      expandedRows:[],
      deleteDialog:false,
      rowToDelete: null,
      loadingReport:false,
      qDialogTitle:null,
      bDialogTitle:null, //backups
      aDialogTitle:null,
      iDialogTitle:null
    };
    ["onRefresh","onShowAnswers","onShowBackups","onShowInvalid","idBody","onShowLogs","onAddSubject","errorFor","handleChange","onDeleteSubject","getReport"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    this.onRefresh();

  }

  componentWillUnmount() {
    const {clearEnrollments} = this.props;
    if (clearEnrollments!=null )
      clearEnrollments();
  }

  onAddSubject(){
    const {addSubjectEnrollment,match} = this.props;
    const {email,cohortId} = this.state;
    if (addSubjectEnrollment!=null)
      addSubjectEnrollment(match.params.experimentId,email,cohortId, this.onRefresh);
    this.setState({
      addDialog:false
    });
  }

  onDeleteSubject(){
    const {deleteEnrollment} = this.props;
    const {rowToDelete} = this.state;
    if (deleteEnrollment!=null)
      deleteEnrollment(rowToDelete.email,rowToDelete.subjectEnrollmentId,rowToDelete.subjectId,this.onRefresh);
    this.setState({rowToDelete:null, deleteDialog:false});
  }

  errorFor(field) {
    return this.state.validationErrors[field] || "";
  }



  handleChange(event) {

    this.setState({
      [event.target.id]: event.target.value
    });
    this.setState({validationErrors: run(this.state, fieldValidations)});
  }

  onRefresh(){
    const {getEnrollments,match,getCohorts} = this.props;
    if (getEnrollments!=null )
      getEnrollments(match.params.experimentId);
    if (getCohorts!=null )
      getCohorts();
  }

  onShowAnswers(subjectId,email){
    const {getQuestionnaireAnswers} = this.props;
    if (getQuestionnaireAnswers!=null )
      getQuestionnaireAnswers(subjectId);
    this.setState({showAnswers:true,qDialogTitle:"Questionnaires sent by " + email})

  }
  onShowBackups(subjectId,email){
    const {getQuestionnaireBackups} = this.props;
    if (getQuestionnaireBackups!=null )
      getQuestionnaireBackups(subjectId);
    this.setState({showBackups:true,bDialogTitle:"Questionnaire backups sent by " + email})
  }

  onShowInvalid(subjectId,email){
    const {getQuestionnaireBackups,getQuestionnaireAnswers} = this.props;
    if (getQuestionnaireBackups!=null )
      getQuestionnaireBackups(subjectId);
    if (getQuestionnaireAnswers!=null )
      getQuestionnaireAnswers(subjectId);
    this.setState({showInvalid:true,iDialogTitle:"Invalid questionnaire backups sent by " + email})
  }

  onShowLogs(subjectId,email){
    const {getEventLogs} = this.props;
    if (getEventLogs!=null )
      getEventLogs(subjectId);
    this.setState({showLogs:true, aDialogTitle:"Actions of  " + email})
  }

  idBody(propertyValue){
    return (
      <div title={propertyValue}>
        {propertyValue!=null
          ? propertyValue.substring(0,5) + '...'
          : ""
        }
        {propertyValue!=null &&
        <i className={"fa fa-clipboard"}
           onClick={()=>navigator.clipboard.writeText(propertyValue)}
           title={"Copy to clipboard"}
           style={{color:"#00D2D0"}}
        />}
      </div>
    );
  }
  async getReport(){
    const {auth,match} = this.props;
    this.setState({loadingReport:true});
    const config = {headers: {'Authorization': "bearer" + auth.token_bearer},   responseType: 'blob'};
    const response = await axios.get(HOST_URL + `/api/mobileApp/monitor/report/${match.params.experimentId}`,config);
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `report_${new Date().getTime()}.xlsx`;
    link.click();
    this.setState({loadingReport:false})
  }


  render() {
    const {enrollments,enrollmentsState,questionnaireAnswers,questionnaireBackups, subjectEventLogs,cohorts,match,getTimeline} = this.props;
    const {cohortId,loadingReport} = this.state;
    const totalQuestionnaires = enrollments.reduce((acc,curr)=>{
      return acc + curr.questionnairesReceived;
    },0);

    const header = <div style={{textAlign: 'left'}}>
      <Button type="button"
              icon="fa fa-download"
              iconPos="left"
              label="Export enrollments to CSV"
              onClick={() => this.dataTableRef.exportCSV()}/>
      <Button type="button"
              icon={loadingReport?"fa fa-spinner fa-spin":"fa fa-download"}
              iconPos="left"
              label="Export data to Excel"
              disabled={loadingReport}
              onClick={this.getReport}/>
      <Button label={"Refresh"} icon={"fa fa-refresh"}  onClick={()=> this.onRefresh()}/>
      <Button label={"Add subject"} icon={"fa fa-plus"}  onClick={()=>this.setState(
        {
          addDialog:true,
          validationErrors: run(this.state, fieldValidations)
        })}/>
      <Button label={"Show timeline"} icon={"fa fa-calendar"}  onClick={()=>
      {
        this.setState(    {      showTimeline:true  });
        getTimeline();
      }}/>
      <span style={headerStyle}>Total number of subjects: {enrollments.length}</span>
      <span style={headerStyle}>Total number of received questionnaires: {totalQuestionnaires}</span>
    </div>;

    return (
      <div>
        {enrollmentsState === REQUEST_STATUS_REQUESTED &&
        <div className="spinner-centered">Loading dataset <i className="fa fa-spinner fa-spin"/></div>
        }
        {enrollmentsState === REQUEST_STATUS_SUCCESS &&
        <DataTable value={enrollments} header={header} ref={(el) => { this.dataTableRef = el; }} csvSeparator={";"}>
          <Column field={"email"} header={"Email"} sortable={true} filterMatchMode={"contains"} filter={true}/>
          <Column field={"enrollmentDate"} header={"Enrolled on"}
                  sortable={true}
                  style={{textAlign: 'center'}}
                  body={(row)=>{
            return convertISODateAndTimeToLocaleString(row.enrollmentDate)
          }}/>
          <Column field={"active"} header={"Active"}
                  sortable={true}
                  style={{textAlign: 'center'}}
                  body={(row)=>{
                    return row.active?"YES":"NO"
                  }}
                />
          <Column field={"questionnairesReceived"} header={"Answered questionnaires"}
                  sortable={true}
                  style={{textAlign: 'center'}}
                  body={(row)=>{
                    if (row.questionnairesReceived>0)
                      return (<a className={"ui-link"} style={{color:"#00D2D0"}}
                                onClick={()=>{this.onShowAnswers(row.subjectId,row.email)}}> {row.questionnairesReceived}
                    </a>);
                    else
                      return (<div> 0 </div>);
                  }}
          />
          <Column header={"Questionnaire backups"}
                  sortable={true}
                  style={{textAlign: 'center'}}
                  field={"questionnairesBackups"}
                  body={(row)=>{
                    if (row.questionnairesBackups>0)
                      return (<a className={"ui-link"} style={{color:"#00D2D0"}}
                                 onClick={()=>{this.onShowBackups(row.subjectId,row.email)}}> {row.questionnairesBackups}
                      </a>);
                    else
                      return (<div> 0 </div>);
                  }}
          />
          <Column header={"Invalid questionnaires"}
                  style={{textAlign: 'center'}}
                  body={(row)=>{
                    if ((row.questionnairesBackups-row.questionnairesReceived)>0)
                      return (<a className={"ui-link"} style={{color:"#00D2D0"}}
                                 onClick={()=>{this.onShowInvalid(row.subjectId,row.email)}}> {row.questionnairesBackups-row.questionnairesReceived}
                      </a>);
                    else
                      return (<div> 0 </div>);
                  }}
          />
          <Column header={"Actions"}
                  style={{width:"6em",textAlign:"center"}}
                  body={(row)=>{
                    return <div> <Button icon={"fa fa-eye"} onClick={()=>{this.onShowLogs(row.subjectId,row.email)}}/></div>
                  }}
          />
          <Column header={"Delete"}
                  style={{width:"6em",textAlign:"center"}}
                  body={(row)=>{
                    return <div> <Button icon={"fa fa-trash"} onClick={()=>{this.setState({rowToDelete:row, deleteDialog:true})}}/></div>
                  }}
          />
        </DataTable>
        }
        <Dialog onHide={()=>{this.setState({showAnswers:false})}}
                visible={this.state.showAnswers}
                header={this.state.qDialogTitle}
                appendTo={document.body}
        >
          {this.state.showAnswers && <QuestionnairesTable questionnaireAnswers={questionnaireAnswers}/>}
        </Dialog>
        <Dialog onHide={()=>{this.setState({showLogs:false})}}
                visible={this.state.showLogs}
                header={this.state.aDialogTitle}
                appendTo={document.body}
        >
          {this.state.showLogs && <ActionsTable subjectEventLogs={subjectEventLogs} />}
        </Dialog>
        <Dialog onHide={()=>{this.setState({showBackups:false})}}
                visible={this.state.showBackups}
                header={this.state.bDialogTitle}
                appendTo={document.body}
        >
          {this.state.showBackups && <QuestionnairesTable questionnaireAnswers={questionnaireBackups} isBackup={true}/>}
        </Dialog>
        <Dialog onHide={()=>{this.setState({showInvalid:false})}}
                visible={this.state.showInvalid}
                header={this.state.iDialogTitle}
                appendTo={document.body}
        >
          {this.state.showInvalid
          && questionnaireAnswers!=null
          && questionnaireBackups!=null
          && questionnaireBackups.length>0
          && <QuestionnairesTable
            questionnaireAnswers={questionnaireBackups.filter(object1 => {
              return !questionnaireAnswers.some(object2 => {
                return getNestedProp(["rawData","questionnaireStartDate"],object1) === getNestedProp(["rawData","questionnaireStartDate"],object2)
                  && getNestedProp(["rawData","questionnaireEndDate"],object1) === getNestedProp(["rawData","questionnaireEndDate"],object2);
              });
            })}
            isBackup={true}/>}
        </Dialog>
        <Dialog onHide={() => {
          this.setState({addDialog: false})
        }}
                visible={this.state.addDialog}
                header={"Add subject"}
                style={{minWidth: "300px"}}
        >
          <div>
            <label htmlFor={"email"}>Email:</label>
            <InputTextWithValidation
              type={'email'}
              id="email"
              name={"email"}
              style={{width: '100%'}}
              onFieldChanged={this.handleChange}
              showError={this.state.showErrors}
              errorText={this.errorFor("email")}
            />
          </div>
          <div>
            <label htmlFor={"email"}>Cohort:</label>
            <Dropdown
              value={cohortId}
              options={cohorts != null
                ? cohorts.filter((el) => getNestedProp(["reference", "experimentId"], el) === match.params.experimentId).map(el => {
                  return {
                    value: el.uuid,
                    label: el.name
                  }
                })
                : []
              }
              onChange={(e) => this.setState({cohortId: e.value})}
              style={{width: "100%"}}
            />
          </div>
          <div>
            <Button label={"OK"} onClick={this.onAddSubject}
                    disabled={Object.keys(this.state.validationErrors).length > 0}/>
            <Button label={"Cancel"} onClick={() => {
              this.setState({addDialog: false})
            }}/>
          </div>
        </Dialog>
        <Dialog onHide={() => {this.setState({deleteDialog: false})}}
                visible={this.state.deleteDialog}
                header={"Confirmation dialog"}
                style={{width: "300px",zIndex:"100"}}
                footer={
                <div style={{textAlign:"center"}}>
                  <Button label={"Delete"}  onClick={this.onDeleteSubject}/>
                  <Button label={"Cancel"}  onClick={()=>this.setState({deleteDialog:false, rowToDelete:null})}/>
                </div>}
        >
          <div>Are you sure you want to delete the subject?</div>
        </Dialog>
        <Dialog onHide={() => {this.setState({showTimeline: false})}}
                visible={this.state.showTimeline}
                header={"Timeline"}
                style={{width: "1000px",height:"600px",zIndex:"100"}}
        >
          {this.state.showTimeline && <ContainerTimelinePanel/>}
        </Dialog>
      </div>
    )
  }
}
export default withRouter(SpineQExperimentMonitor)



SpineQExperimentMonitor.propTypes ={
  getEnrollments: PropTypes.func.isRequired,
  enrollments: PropTypes.array.isRequired,
  enrollmentsState: PropTypes.string.isRequired,
  clearEnrollments:PropTypes.func.isRequired,
  getEventLogs:PropTypes.func.isRequired,
  selectedSubject:PropTypes.string.isRequired,
  getQuestionnaireAnswers:PropTypes.func.isRequired,
  getQuestionnaireBackups:PropTypes.func.isRequired,
  questionnaireAnswers:PropTypes.array.isRequired,
  questionnaireAnswersState:PropTypes.string.isRequired,
  questionnaireBackups:PropTypes.array.isRequired,
  questionnaireBackupsState:PropTypes.string.isRequired,
  subjectEventLogs:PropTypes.array.isRequired,
  subjectEventLogsState:PropTypes.string.isRequired,
  addSubjectEnrollment:PropTypes.func.isRequired,
  deleteEnrollment:PropTypes.func.isRequired,
  getCohorts:PropTypes.func.isRequired,
  cohorts:PropTypes.array.isRequired,
  getTimeline:PropTypes.func.isRequired,
  auth:PropTypes.string.isRequired
};


const headerStyle={
  marginLeft:"1em"
};