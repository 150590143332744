import React from 'react'
import PropTypes from 'prop-types'
import {IMAGE_URL} from "../../../Constants";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {SlideMenu} from "primereact/components/slidemenu/SlideMenu";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import {Dialog} from "primereact/components/dialog/Dialog";
import VersionInfoPanel from "./VersionInfoPanel";


class Logout extends React.Component {

  // props are not passed

  constructor(props) {
    super(props);
    this.state = {
      legalNoticeVisible: false,
      privacyNoticeVisible: false,
      versionInfoVisible: false,
      legalContent: DEFAULT_NOTES,
      privacyContent: DEFAULT_PRIV,
      language: "fr"
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleOverlay = this.handleOverlay.bind(this);
  }

  /**Adapter function for Prime React Dropdown component
   *
   *  @param item role to convert
   * @returns {Object} object with required attributes
   */
  static adaptSelectableOption(item) {
    let option = {};
    option.label = item.charAt(0).toUpperCase() + item.slice(1);
    option.value = item;
    return option;
  }

  componentDidMount() {

    fetch('/api/legal')// or axios.get if bearer is needed (it shouldn't be though+)
      .then(response => response.json())
      .then(data => {
        this.setState({legalContent: data});
      })
      .catch(e => {
        console.log(e);
      });
    fetch('/api/privacy')// or axios.get if bearer is needed (it shouldn't be though+)
      .then(response => response.json())
      .then(data => {
        this.setState({privacyContent: data});
      })
      .catch(e => {
        console.log(e);
      });
    // fetch('/dist/version.json')// or axios.get if bearer is needed (it shouldn't be though+)
    //   .then(response => response.json())
    //   .then(data => {
    //     this.setState({versionContent: data});
    //   })
    //   .catch(e => {
    //     console.log(e);
    //   });
  }


  handleLogout() {
    console.log('Logout button::onclick');
    this.props.onLogout(this.props.auth, () => {
      this.props.history.push('/')
    }, () => {
      this.props.history.push('/')
    });
  }

  handleOverlay(event) {
    this.op.toggle(event);
  }


  render() {
    console.log("RolePicker::render");
    const {legalNoticeVisible, privacyNoticeVisible, legalContent, privacyContent, versionInfoVisible, language} = this.state;
    const {auth, t, i18n, history} = this.props;
    let roles = auth.roles.map(item => Logout.adaptSelectableOption(item));

    const personalOptionModel = [{
      label: t("logoutComponent.menuOptions.userProfile.label"),
      icon: 'fa fa-fw fa-user',
      command: () => {
        this.op.hide();
        history.push("/personal");
      }
    }, {
      label: t("logoutComponent.menuOptions.settings.label"),
      icon: 'fa fa-fw fa-cog',
      command: () => {
        this.op.hide();
        history.push("/preferences");
      }
    },

      {
        label: 'Help',
        icon: 'fa fa-fw fa-question-circle',
        items: []
      },
      {
        label: t("logoutComponent.menuOptions.privacy.label"),
        icon: 'fa fa-fw fa-user-secret',
        command: () => {
          this.setState({privacyNoticeVisible: true});
        }
      },
      {
        label: t("logoutComponent.menuOptions.legal.label"),
        icon: 'fa fa-fw fa-exclamation-circle',
        command: () => {
          this.setState({legalNoticeVisible: true});
        }
      },
      {
        separator: true
      },
      {
        label: 'Version info',
        icon: 'fa fa-fw fa-drivers-license',
        command: () => {
          this.setState({versionInfoVisible: true});
        }
      },
      {
        separator: true
      },

      {
        label: t("logoutComponent.menuOptions.logout.label"),
        icon: 'fa fa-fw fa-power-off',
        command: () => {
          this.handleLogout();
        }
      }
    ];

    const disclaimerContent = legalNoticeVisible
      ? legalContent
      : privacyContent;
    const propertyName = legalNoticeVisible
      ? "legalNotice"
      : "privacyPolicy";

    const languageLinks = Object.keys(disclaimerContent).map(lKey => {
      return <a onClick={() => {
        this.setState({language: lKey})
      }}
                style={{
                  marginLeft: "1em",
                  textDecoration: this.state.language === lKey ? "underline" : "none"
                }}>{disclaimerContent[lKey]["label"]}</a>
    });

    const toolTitle = t("logoutComponent.tooltip.header");


    return (
      <div style={{float: 'right', marginRight: '16px', height: '50px'}} className="ui-unselectable-text">
        <Tooltip for="#avatar"
                 title={"<strong>" + toolTitle + "</strong>\n" + auth.name + " " + auth.nickname + " " + auth.lastname + "\n" + auth.email}
                 escape={false}
                 tooltipPosition="left" tooltipEvent="hover"
        />
        <img id="avatar"
             src={IMAGE_URL(auth.id)}
             className="circular_img"
             alt="Avatar should be here"
             onClick={this.handleOverlay}
             style={{position: "relative", top: "5px"}}
        />
        <SlideMenu ref={(el) => this.op = el}
                   model={personalOptionModel}
                   popup={true}/>
        <Dialog
          onHide={() => {
            this.setState({legalNoticeVisible: false, privacyNoticeVisible: false, versionInfoVisible: false})
          }}
          visible={legalNoticeVisible || privacyNoticeVisible || versionInfoVisible}
          header={legalNoticeVisible
            ? t("logoutComponent.menuOptions.legal.label")
            : privacyNoticeVisible
              ? t("logoutComponent.menuOptions.privacy.label")
              : "Version info"}
          style={{width: '800px', overflowY: 'hidden', maxHeight: "600px"}}
        >           {(legalNoticeVisible || privacyNoticeVisible) &&
        <pre style={{whiteSpace: "pre-wrap", wordBreak: "keep-all", maxHeight: "25em", overflowY: "auto"}}
             onScroll={this.isBottom}>
                            <div dangerouslySetInnerHTML={{
                              __html: legalNoticeVisible
                                ? legalContent[language].legalNotice
                                : privacyContent[language].privacyPolicy
                            }}/>
                     </pre>}
          {versionInfoVisible && <VersionInfoPanel/>}

          {!versionInfoVisible &&
          <div>
            {languageLinks}
          </div>}
        </Dialog>
      </div>
    )
  }
}

Logout.propTypes = {
  auth: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired
};
export default withTranslation()(withRouter(Logout));

const DEFAULT_NOTES = {
  fr: {
    "label": "Français",
    "legalNotice": "<pre>Legale notice...</pre>"
  },
  en: {
    "label": "English",
    "legalNotice": "<div>Legal Notice...</div>"
  }
};

const DEFAULT_PRIV = {
  fr: {
    "label": "Français",
    "privacyPolicy": "Privacy police..."
  },
  en: {
    "label": "English",
    "privacyPolicy": "Privacy policy..."
  }
};

