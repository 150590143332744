import {
    EXPDESIGN_R_CALC_FAIL,
    EXPDESIGN_R_CALC_REQUEST, EXPDESIGN_R_CALC_SUCCESS
} from "./actionType";

import store from "../../Store";
import NetworkService from "../../helpers/io/NetworkService";
import {SLRModel} from "../models/SLRModel";
import {GLRModel} from "../models/GLRModel";


/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestRModelParameterCalculation = () => ({
    type: EXPDESIGN_R_CALC_REQUEST
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorRModelParameterCalculation = err => ({
    type: EXPDESIGN_R_CALC_FAIL,
    err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param model - model in form of transfer object
 * @returns {{type, model: *}}
 */

const successRModelParameterCalculation = (model) => ({
    type: EXPDESIGN_R_CALC_SUCCESS,
    model
});

/**
 * Get model calculated in R (StudyList).
 * @param dimension - number of random variables
 * @param transferObject - data to send
 * @returns {function(*)} change state of Store
 */
export const calculateModelParams = (dimension, transferObject) => {
    return dispatch => {
        dispatch(requestRModelParameterCalculation());
        console.log('AuditAction.js :: calculateModelParams :: Before axios requres');
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        if(dimension<3)
        NetworkService.getFullModel(transferObject)
            .then(outData => {
                console.log('StatisticalModelAction.js :: getVariableList :: response', outData);
                dispatch(successRModelParameterCalculation(new SLRModel(outData)));
            })
            .catch(err => dispatch(errorRModelParameterCalculation(err)));
            else
            NetworkService.getLMModel(transferObject)
                .then(outData => {
                    console.log('StatisticalModelAction.js :: getVariableList :: response', outData);
                    dispatch(successRModelParameterCalculation(new GLRModel(outData)));
                })
                .catch(err => dispatch(errorRModelParameterCalculation(err)));

    }
};

/**
 * Get model for Bland Altman Agreement Analysis calculated in R (StudyList).
 * @param transferObject - data to send (must contain x,y,l params)
 * @returns {function(*)} change state of Store
 */
export const calculateModelForBlandAltman = (transferObject) => {
    return dispatch => {
        dispatch(requestRModelParameterCalculation());
        console.log('AuditAction.js :: calculateModelForBlandAltman :: Before axios requres');
        NetworkService.getBlandAltmanData(transferObject)
                .then(outData => {
                    console.log('AuditAction.js :: calculateModelForBlandAltman :: response', outData);
                    dispatch(successRModelParameterCalculation(outData));
                })
                .catch(err => dispatch(errorRModelParameterCalculation(err)));
    }
};