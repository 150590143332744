import React from "react";
import PropTypes from "prop-types";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";

/**
 * Datatable for Recommender API.
 * Component of NCBO  Recommender compliant with Bioportal API
 * @link http://data.bioontology.org/documentation#nav_recommender
 */
class RecommenderResultsDataTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      expandedRows:null,
    };
    ["rowExpansionTemplate"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  //
  iriExpansionTemplate(row) {
    return <a target="_blank" href={row.annotatedClass['@id']} title={"Open ".concat(row.annotatedClass['@id']," in external window")}>
      {row.annotatedClass['@id'].substring(row.annotatedClass['@id'].lastIndexOf('/')+1)}
    </a>
  }

  classExpansionTemplate(row) {
    return <span title={"Full specification: ".concat(row.annotatedClass['@id'])}>
      {row.annotatedClass['@id'].substring(row.annotatedClass['@id'].lastIndexOf('/')+1)}
    </span>
  }

  rowExpansionTemplate(row) {


    return (
      <div>
        <h5>Matching details:</h5>
        <DataTable value={row.coverageResult.annotations} emptyMessage={"No options found"}>
          <Column field="from" header="From"/>
          <Column field="to" header="To"/>
          <Column field="matchType" header="Match type"/>
          <Column field="text" header="Text"/>
          <Column field="hierarchySize" header="Hierarchy Size"/>
          <Column header="Class" body={this.iriExpansionTemplate}/>
        </DataTable>
      </div>
    );
  }

  render() {
    const {queryResult} = this.props;
    const {expandedRows} = this.state;

    return (
      <DataTable value={queryResult}
                 paginator={true}
                 rows={10}
                 rowExpansionTemplate={this.rowExpansionTemplate}
                 onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                 expandedRows={expandedRows}
      >
        <Column field={'evaluationScore'} header={"Score"} sortable={true} />
        <Column header={"Ontology"} body={(row)=>{return row.ontologies[0].acronym}}/>
        <Column header={"Id"} body={(row)=>{return row.ontologies[0]['@id']}} />
        <Column field={'coverageResult.numberWordsCovered'} header={"Words Covered"} sortable={true} style={{width:"8em"}}/>
        <Column field={'coverageResult.numberTermsCovered'} header={"Terms Covered"} sortable={true}  style={{width:"8em"}}/>
        <Column expander={true} header={"Coverage"} sortable={true}/>

      </DataTable>

    )
  }

}

export default RecommenderResultsDataTable;

RecommenderResultsDataTable.propTypes = {
  queryResult:PropTypes.object.isRequired
};
