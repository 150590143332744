import React, {Component} from 'react'
import {scaleLinear, scaleSequential} from "d3-scale";
import {event, select, selectAll} from "d3-selection";
import {axisBottom, axisLeft} from "d3-axis";
import {area, line} from "d3-shape";
import d3Tip from "d3-tip"
import {extent, max, min, histogram, bin, median, mean, deviation} from "d3-array";
import PropTypes from "prop-types";
import {zoom} from "d3-zoom";
import {interpolateRainbow} from "d3-scale-chromatic";
import {contextMenu} from "./contextMenu";
import ScatterPlotSAM from "./ScatterPlotSAM";


/** D3-based RaterHistogram Component integrated with React
 * Used in modal window within Avatar Plot.
 * Works in two modes depending on data props (checks if std>0):
 *  - no variability: shows all green-colored avatars inside just one bin (one of three intervals),
 *  - variability: shows six intervals encompassing five bin borders dynamically calculated based on Standard dev. (-2STD, -STD, Mean, STD, 2STD). The more
 *  distant result from mean (green line), the more 'reddish' is color.
 */
class RaterHistogram extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objects: null
        };
        ["createPlot", "cleanPlot"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    componentDidMount() {
        if (this.props.width > 0)
            this.createPlot();
    }

    componentDidUpdate(prevProps, prevState) {
        const {width} = this.props;
        //needed for initialization - if width was previously 0 and slide was inactive, the plot needs to be redrawn
        if (prevProps.data !== this.props.data
            || (prevProps.width !== width && width != null && width > 0)
            || prevProps.viewId !== this.props.viewId
            || prevProps.length < this.props.length) {
            this.cleanPlot();
            this.createPlot();
        }

    }

    cleanPlot() {
        const node = this.node;
        const {prefixId, width} = this.props;
        select(node).selectAll("*").remove();
        selectAll(".d3-tip." + prefixId + "-tip").remove(); //removes all elements only for active panel (prefixId)
        selectAll(".d3-tip." + prefixId + "-tip.n").remove(); //removes all elements only for active panel
    }


    createPlot() {
        const {
            thresholds, prefixId, colorCat, stroke, zoomRatio, selected,
            data, colorLUT, opacityMode, xLabel, xLabelTip, onContextClick
        } = this.props;
        const node = this.node;   //svg ref
        let outerWidth = (this.props.outerWidth != null) ? this.props.outerWidth : 500;
        let outerHeight = (this.props.outerHeight != null) ? this.props.outerHeight : 300;
        let onDataPointClick = this.props.onDataPointClick;
        let xValue = function (d) {
            return d.x;
        };  //Accessor, according to d3-array

        // set the dimensions and margins of the graph
        let margin = {
                top: 20,
                right: 50,
                bottom: 50, //required 50 for ticks and tick labels - now they are removed
                left: 50
            },
            width = outerWidth - margin.left - margin.right,
            height = outerHeight - margin.top - margin.bottom;


        // set the ranges
        let xScale = scaleLinear()
            .range([0, width]).nice();


        // See here for better initialization https://github.com/d3/d3-array#bins    Freedman–Diaconis, Scott, etc.

        // ===============Calculate statistics========================
        // to use in Gaussian plot
        const meanV = mean(data, xValue);
        const devV = deviation(data, xValue);

        // ===========================================================
        const thresholdsStat = (devV > 0) ? [meanV - 2 * devV, meanV - 1 * devV, meanV, meanV + devV, meanV + 2 * devV] : [meanV - 1, meanV + 1];
        const thr = (thresholds != null) ? thresholds : thresholdsStat; //xScale.ticks(numberOfBins);

        const nob = thr.length + 1;
        const DOT_RADIUS = .5 * width / 6; // or nob i  nob;
        //const DOT_RADIUS = .5 * width / nob;

        let bins = new Array(thr.length + 1).fill(0);

        const binSize = devV;

        data.forEach((s) => {
            if (s.x < thr[0]) {
                s['y'] = ++bins[0];
                s['bin'] = 0;
                s['yBinMode'] = thr[0] - binSize * .5;
            }
            else if (s['x'] >= thr[thr.length - 1]) {
                s['y'] = ++bins[bins.length - 1];
                s['bin'] = 5;
                s['yBinMode'] = thr[thr.length - 1] + binSize * .5;
            }
            else
                for (let i = 0; i < thr.length; i++)
                    if (thr[i] <= s['x'] && s['x'] < thr[i + 1]) {
                        s['y'] = ++bins[i + 1];
                        s['bin'] = i + 1;
                        s['yBinMode'] = (thr[i + 1] + thr[i]) * .5;
                    }
        });


        let maxY = max(bins) + 1; // maximal number of elements in bins increased by 1 to show all of them when initialized
        maxY = (maxY > (height/(DOT_RADIUS*2)))?maxY:(height/(DOT_RADIUS*2));
        const yDomain = [0, maxY];
        let yScale = scaleLinear()
            .domain(yDomain)
            .range([height, 0]);
        // .nice();


        //-------------------y domain----------------------------------
        // let yDomain = extent(data, yValue);
        const xDomain = [thr[0] - (thr[1] - thr[0]), thr[thr.length - 1] + (thr[thr.length - 1] - thr[thr.length - 2])];
        xScale.domain(xDomain);


        // set the axes
        let xAxis = axisBottom(xScale);
        let yAxis = axisLeft(yScale);
        let color = scaleSequential(interpolateRainbow);


        // =================  setting ticks ============================
        const sigma = '\u03C3';
        const mi = '\u03BC';
        const xlabels = (devV > 0) ? ['-2' + sigma, '-' + sigma, mi, sigma, '2' + sigma] : ['', ''];

        xAxis
            .tickValues(thr)
            .tickFormat(function (d, i) {
                return xlabels[i];
            });

        yAxis.tickValues([]);// empty
        // set  tips

        const zScore = (val)=> {
            if (devV > 0) return ((val - meanV) / devV);
            else return 0;
        };
        const tip = d3Tip()
            .attr("class", "d3-tip " + prefixId + "-tip")  //prefix added to distinguish tips on many panels - d3Tip uses global variables
            .style("z-index", "10000")
            .offset([-DOT_RADIUS, 0])
            .html((d)=> {
                return "User no" + ": " + d['userId'] + "<br/>" + xLabelTip + ": " + d['x'].toFixed(2)+
                    "<br/>" + "Z-score" + ": " + zScore(d['x']).toFixed(2);
            });

        //zoom parameters and handler assignment
        let zooming = zoom()
            .scaleExtent([.5, 100])
            .translateExtent([[0, 0], [width, height]])
            .extent([[0, 0], [width, height]])
            .on("zoom", zoomEventHandler);

        let avatarClass = [];
        if (opacityMode === "normal")
            avatarClass = ["normalAvatar", "selectedAvatar"];
        else
            avatarClass = ["selectedAvatar", "normalAvatar"]
            ;

        // append the svg object to the body of the page and translate the parent group
        // calculate the rectangle embraced by axes
        let svg = select(node)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .call(zooming)
            .on('contextmenu', function (e) {
              if (onContextClick!=null) {
                  event.preventDefault();
                  event.stopPropagation();
                  onContextClick(event, null, () => {
                  }); //second parameter is callback to unselect
            }
          });

        svg.call(tip);

        svg.append("rect")
            .attr("width", width)
            .attr("height", height);

        svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis)
            .append("text")
            .attr("class", "label")
            .attr("fill", "#000")
            .attr("font-size", "0.7em")
            .attr("x", width)
            .attr("y", margin.bottom - 10)
            .style("text-anchor", "end")
            .text(xLabel);

        // y Axis---------------------------
        svg.append("g")
            .attr("class", "y axis")
            .call(yAxis);


        let objects = svg.append("svg")
            .attr("class", "objects")
            .attr("width", width)
            .attr("height", height);
        this.setState({objects: objects});

        //============= Bin borders thresholds - vertical lines =============================
        thr.forEach((t, index) => {
            let color = (index === 1 || index === 2 || index === 3) ? "green" : "orange";
            color = (devV > 0) ? color : "green";   // if no variablity, set as green
            svg.append("line")          // attach a line
                .attr("class", "thresholdLine")
                .style("stroke", color)  // colour the line
                .style("opacity", 0.5)
                .attr("x1", xScale(t))
                .attr("y1", yScale(yDomain[0]))      // y position of the first end of the line
                .attr("x2", xScale(t))     // x position of the second end of the line
                .attr("y2", yScale(yDomain[1]))
                .lower();    //
        });

        if (!(data != null))
            return;

// ------------------------Gaussian start -------------------------------------
        if (devV > 0) {
            let areas = area()
                .x(function (d) {
                    return xScale(d.q);
                })
                .y0(height) // or zero for inversed
                .y1(function (d) {
                    return yScale(d.p);
                });

            let lines = line()
                .x(function (d) {
                    return xScale(d.q);
                })
                .y(function (d) {
                    return yScale(d.p);
                });
            let gaussianData = [];
            getGaussianData();
            const normalizeYFactor = Math.max(...gaussianData.map((ll) => {
                return ll.p
            }));
            gaussianData.forEach((el) => {
                el.p = el.p / normalizeYFactor * maxY
            });


            svg.append("clipPath")
                .attr("id", "chart-area")
                .append("rect")
                .attr("width", width)
                .attr("height", height);


            svg.append("path")
                .datum(gaussianData)
                .attr("class", "gaussLine")
                .attr("clip-path", "url(#chart-area)")
                .attr("d", lines)
                .lower();

            svg.append("path")
                .datum(gaussianData)
                .attr("clip-path", "url(#chart-area)")
                .attr("class", "gaussianArea")
                .attr("d", areas)
                .lower();

            function getGaussianData() {
                // loop to populate data array with
                // probably - quantile pairs

                for (let i = 0; i < 1000; i++) {
                    let q = normal();// calc random draw from normal dist
                    let p = gaussian(q, meanV, devV); // calc prob of rand draw
                    let el = {
                        "q": q,
                        "p": p
                    };
                    gaussianData.push(el)
                }
                ;
                // need to sort for plotting
                //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
                gaussianData.sort(function (x, y) {
                    return x.q - y.q;
                });
            }
        }
//------ Gaussian  end-------------------------------------

        let getHref = (d) => {
            return d.img;
        };
        let getId = (d) => {
            return "img_" + prefixId + d.userId;
        };
        let getImg = (d) => {
            return "url(#img_" + prefixId + d.userId + ")";
        };


        let colorFunction = (d) => {
            if (devV === 0)
                return "green";
            if (d.bin === 2 || d.bin === 3)
                return "green";
            if (d.bin === 1 || d.bin === 4)
                return "orange";
            else return "red";

        };

        let defs = svg.append("defs");
        let imgPattern = defs
            .selectAll("pattern")
            .data(data)
            .enter()
            .append("pattern")
            .attr("id", getId)
            .attr("width", 1)
            .attr("height", 1)
            .attr("patternUnits", "objectBoundingBox")
            .append("image")
            .attr("x", 0)
            .attr("y", 0)
            .attr("width", DOT_RADIUS * 2 - 2 * stroke)
            .attr("height", DOT_RADIUS * 2 - 2 * stroke)
            .attr("xlink:href", getHref);


        objects.selectAll("circle")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "img")
            .classed(avatarClass[0], true)
            .classed(avatarClass[1], (d) => {
                if (selected != null) return selected.userId === d.userId;
                return false;
            })
            .attr("cx", function (d) {
                return xScale(d.yBinMode);
            })
            .attr("cy", function (d) {
                return yScale(d.y);
            })
            .attr("r", DOT_RADIUS - stroke)
            .style("fill", getImg)
            .style("stroke", colorFunction)
            .style("stroke-width", stroke)
            .on("mouseout", tip.hide)
            .on("mouseover", function (d) {
                select(this).raise();
                tip.show(d, this);
            })
            .on('mouseenter', function (e) {
                select(this)
                    .transition()
                    .attr("r", DOT_RADIUS * zoomRatio - stroke);
                defs.select("pattern#" + getId(e)).select("image")
                    .transition()
                    .attr("width", DOT_RADIUS * 2 * zoomRatio - 2 * stroke)
                    .attr("height", DOT_RADIUS * 2 * zoomRatio - 2 * stroke);
            })
            .on('mouseleave', function (e) {
                select(this)
                    .transition()
                    .attr("r", DOT_RADIUS - stroke);
                defs.select("pattern#" + getId(e)).select("image")
                    .transition()
                    .attr("width", DOT_RADIUS * 2 - 2 * stroke)
                    .attr("height", DOT_RADIUS * 2 - 2 * stroke);
            })
            .on("click", function (e) {
                let alreadySelected = select(this).classed("selectedAvatar");
                if (alreadySelected) {
                    select(this)
                        .classed("selectedAvatar", false);
                    // onDataPointClick(null);
                } else {
                    svg.selectAll("circle.img")
                        .classed("selectedAvatar", false);
                    select(this)
                        .classed("selectedAvatar", true);
                    onDataPointClick(e, () => select(this)
                        .classed("selectedAvatar", false)); //second parameter is callback to unselect
                }
                tip.hide();
            })
            .on('contextmenu', function (e) {
                event.preventDefault();
                event.stopPropagation();
                tip.hide();
                let selected = select(this).classed("selectedAvatar");
                svg.selectAll("circle")
                  .classed("selectedAvatar", false);

                if (e.hasOwnProperty('isDuplicated')) {
                    select(this)
                      .classed("selectedAvatar", !selected);
                    data.filter((el) => e['userId'] === el['isDuplicateOf'])
                      .forEach((el) => {
                          select("#".concat(viewId,'dataId',el['userId']))
                            .style('display', selected ? 'block' : 'none');
                      });
                    return;
                }
                select(this)
                  .classed("selectedAvatar", true)
                  .style("stroke", "red");
                onContextClick(event, e, () => select(this)
                  .classed("selectedAvatar", false))
            });

        function zoomEventHandler() {
            //Zoom only in x axis
            // let new_xScale = event.transform.rescaleX(xScale);
            let new_yScale = event.transform.rescaleY(yScale);
            // xAxis.scale(new_xScale);
            yAxis.scale(new_yScale);

            // svg.select(".x.axis").call(xAxis.scale(new_xScale));
            svg.select(".y.axis").call(yAxis.scale(new_yScale));

            svg.selectAll("circle.dot")
            // .attr('cx',(d)=>{ return  new_xScale(d.yBinMode)})
                .attr('cy', (d) => {
                    return new_yScale(d.y)
                });

            svg.selectAll("circle.img")
            // .attr('cx',(d)=>{ return  new_xScale(d.yBinMode)})
                .attr('cy', (d) => {
                    return new_yScale(d.y)
                });

            svg.selectAll(".thresholdLine")
                .attr("y2", new_yScale(new_yScale.domain()[1]));    //

        }
    }

    render() {
        return <svg ref={node => this.node = node}/>;
    }
}

export default RaterHistogram

RaterHistogram.defaultProps = {
    stroke: 4,
    zoomRatio: 2,
    opacityMode: "normal",
    xLabel: "",
    thresholds: null,
    onContextClick:()=>{}
};

RaterHistogram.propTypes = {
    contextMenu: PropTypes.array,
    data: PropTypes.array.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    onDataPointClick: PropTypes.func,
    onContextClick:PropTypes.func,
    colorCat: PropTypes.string,
    colorLUT: PropTypes.array, //array of user provided colors
    opacityMode: PropTypes.string,
    xLabel: PropTypes.string,
    xLabelTip: PropTypes.string,
    thresholds: PropTypes.array.isRequired,
    viewId: PropTypes.string,
    prefixId: PropTypes.string.isRequired, // required since many elements on page can have the same id
    stroke: PropTypes.number,
    zoomRatio: PropTypes.number
};

//taken from Jason Davies science library
// https://github.com/jasondavies/science.js/
function gaussian(x, mean, sigma) {
    let gaussianConstant = 1 / Math.sqrt(2 * Math.PI);
    x = (x - mean) / sigma;
    return gaussianConstant * Math.exp(-.5 * x * x) / sigma;
};

// from http://bl.ocks.org/mbostock/4349187
// Sample from a normal distribution with mean 0, stddev 1.
function normal() {
    let x = 0;
    let y = 0;
    let rds, c;
    do {
        x = Math.random() * 2 - 1;
        y = Math.random() * 2 - 1;
        rds = x * x + y * y;
    } while (rds === 0 || rds > 1);
    c = Math.sqrt(-2 * Math.log(rds) / rds); // Box-Muller transform
    return (Math.abs(x * c) > 3) ? normal() : x * c; // throw away extra sample y * c
}