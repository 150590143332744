import axios from "axios/index";
import { loadModules } from 'esri-loader';
import {
  GEO_CLEAR,
  GEO_UPDATE_BASEMAP,
  GEO_UPDATE_BLEND_MODE, GEO_UPDATE_FILL_STYLE, GEO_UPDATE_LAYER_STATE, GEO_UPDATE_LAYERS, GEO_UPDATE_LEGEND,
  GEO_UPDATE_MARKER_SIZE, GEO_UPDATE_OPACITY,
  GEO_UPDATE_SITES,
  GEO_UPDATE_VIEW
} from "./actionType";
import { mixedModeFillings} from "./GeoRenderers";
import {getNestedProp} from "../../helpers/expressions";
import {AVATAR_LAYER_DEFINITION, GRAPHIC_LAYER_TYPE} from "./GeoLayers";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";

export const SITES_LAYER_ID = "Sites";

const updateLayerState = (layerState) => ({
  type: GEO_UPDATE_LAYER_STATE,
  layerState
});

const updateLayers = (layers) => ({
  type: GEO_UPDATE_LAYERS,
  layers
});

const updateGeoView = (view) => ({
  type: GEO_UPDATE_VIEW,
  view
});

export const updateSitesData = (sites) => ({
  type: GEO_UPDATE_SITES,
  sites
});

const updateBasemap = (basemap) => ({
  type: GEO_UPDATE_BASEMAP,
  basemap
});

const updateLayerOpacity = (opacity) => ({
  type: GEO_UPDATE_OPACITY,
  opacity
});

const updateBlendMode = (blendMode) => ({
  type: GEO_UPDATE_BLEND_MODE,
  blendMode
});

const updateMarkerSize = (markerSize) => ({
  type: GEO_UPDATE_MARKER_SIZE,
  markerSize
});


const updateFill = (fill) => ({
  type: GEO_UPDATE_FILL_STYLE,
  fill
});

const updateLegend = (legend) => ({
  type: GEO_UPDATE_LEGEND,
  legend
});

export  const clearGeoView = ()=>({
  type: GEO_CLEAR
});

/**
 * ActionCreator for loading map configuration from document requested from backend.
 * @param docId - document id (must be of type "map")
 * @param ref
 * @return {function(...[*]=)}
 */
export const loadConfiguration = (docId,ref) => {
  return (dispatch, getState) => {

    const config = {
      headers: {'Authorization': "bearer" + getState().auth.token_bearer}
    };
    axios.get(`/api/map/configuration/${docId}`,config)
      .then(response=>{
        const params  = getNestedProp(["data","configuration"],response);
        dispatch(initializeGeoView(ref,params));
      } )
      .catch(el=>{});

  }
};
/**
 * ActionCreator for initialization of GeoView component.
 * Creates mapView object and saves it in Redux Store.
 * @return {function(...[*]=)}
 */
export const initializeGeoView = (containerRef, params) => {
  return (dispatch,getState)=> {

    // initial state from reducer and component parameters (component params are overwriting default values from reducer!)
    const initialState = Object.assign({}, getState().geo, params);

    loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/views/SceneView"
    ]).then(([Map, MapView,SceneView]) => {
      const map = new Map({                // Create a Map object
        basemap: initialState["basemap"]
      });

      let viewParams = {
        container: containerRef,
        map: map,
        center: initialState["center"],
        zoom: initialState["zoom"]
      };
      const view = ( initialState!=null && initialState["viewType"]==="scene")
        ? new SceneView(viewParams)
        : new MapView(viewParams);

      if (params.uiComponents!=null)
        view.ui.components = params.uiComponents;

      view.popup.autoOpenEnabled=false;
      window.geomap=view;
      dispatch(updateGeoView(view));
      if (params.layers!=null){
        dispatch(initializeGeoLayers(params.layers));
        dispatch(updateLayers(params.layers));
      } // can be initialized only if provided in configuration document
    });
  }
};

/**Action Creator for initialization of additional layers read from geojson files.
 * Regions are hardcoded yet!
 *
 * @return {function(...[*]=)}
 */

export const initializeGeoLayers = (layers) => {
  return (dispatch, getState) => {
    const map = getState().geo.view.map;
    loadModules([
      "esri/layers/GeoJSONLayer",
      "esri/layers/FeatureLayer",
      "esri/Graphic"
    ])
      .then(([GeoJSONLayer,FeatureLayer,Graphic]) => {

        for (let i = 0; i < layers.length; i++) {
          const layer= {
            id:"Layer"+i,
            blendMode: "multiply",
            copyright: "Bir",
            editingEnabled:true
          };
          if (layers[i].renderer!=null) {
            const config = {
              headers: {'Authorization': "bearer" + getState().auth.token_bearer}
            };
            axios.get(`/api/map/geojson/${layers[i].id}`,config)
              .then(response=>{

                layer['renderer'] = layers[i].renderer;
                if (layers[i].title != null)
                  layer['title'] = layers[i].title;
                if (layers[i].id != null)
                  layer['id'] = layers[i].id;

                const blob = new Blob([JSON.stringify(response.data.data)], {
                  type: "application/json"
                });

                // URL reference to the blob
                layer["url"] = URL.createObjectURL(blob);

                const geojsonlayer = new GeoJSONLayer(layer); // loaded as blob so we can authorize request to SPINE server
                map.add(geojsonlayer);  // adds the layer to the map

              } )
              .catch(el=>{});

          }
          else if (layers[i].graphics!=null){
            if (layers[i].graphics === GRAPHIC_LAYER_TYPE.AVATAR){
              const config = {
                headers: {'Authorization': "bearer" + getState().auth.token_bearer}
              };
              axios.get(`/api/map/geojson/${layers[i].id}`,config)
                .then(response => {
                  const featureCollection = getNestedProp(["data","data","features"],response);
                  if (Array.isArray(featureCollection)){
                    const symbols = [];
                    featureCollection.forEach(el=>{
                      const point = {
                        "type":"point",
                        "latitude" : el.properties.Latitude,
                        "longitude" : el.properties.Longitude
                      };
                      if (el.properties.Status!=null){
                        symbols.push(new Graphic({
                          geometry:point,
                          attributes: {
                            Site_ID: el.properties.Site_ID,
                            Status: el.properties.Status>1 ? String(el.properties.Status): "",
                            StatusColor: el.properties.Status>0?1:0,
                          }
                        })
                        );
                      }
                    });
                    const flayer = Object.assign(AVATAR_LAYER_DEFINITION,{source:symbols});
                    if (layers[i].title != null)
                      flayer['title'] = layers[i].title;
                    if (layers[i].id != null)
                      flayer['id'] = layers[i].id;
                    const gl = new FeatureLayer(flayer);
                    map.add(gl);
                    // gl.applyEdits({addFeatures:symbols});
                  }
                })
                .catch(err=> {
                    console.log(err);
                  }
                );
            }
          }
          }
        dispatch(updateLayerState(REQUEST_STATUS_SUCCESS));
      });
  }
};


/**
 * The method reads special sites for editing purposes.
 * window.geomap.map.layers._items[5].editingEnabled=true
 * ff = await window.geomap.map.layers._items[5].queryFeatures()
 * await window.geomap.map.layers._items[5].applyEdits({deleteFeatures:[ff.features[5]]})
 * @return {function(...[*]=)}
 */
export const loadSitesData = () => {
  return (dispatch,getState) => {
    const config = {
      headers: {'Authorization': "bearer" + getState().auth.token_bearer}
    };
           axios.get(`/api/map/geojson/f457d4daadb65e85be7b7c4fa90160a0`,config)
          .then((response)=>{
            const feats = getNestedProp(["data","data","features"],response);
            dispatch(updateSitesData(feats.map(el=>{
            const props =  el.properties;
            props['visible']=true;
            return props;
            })));
          }
        );
  }
};

/**
 * Updates visibility of site
 * Normally feature visibility can be handled by property, eg.
 *  <code>
 *    feat.visible = visibility;
 *    layer.applyEdits({updateFeatures: [Object.assign({},feat)]}).then((results) => { });
 *  </code>
 * but due to Redux store issues, this had to be done by adding and removing feature in layer.
 * @param site
 * @param visibility
 * @return {function(...[*]=)}
 */
export const updateSiteVisibility = (site,visibility) => {
  return (dispatch,getState) => {
    site['visible']=visibility;
    dispatch(updateSitesData(getState().geo.sites));
    const layer = getState().geo.view.map.layers.find(el=>el.title === SITES_LAYER_ID);
    layer.queryFeatures().then((features)=>{
      const feat = features.features.find(el=>el.attributes.Site_ID===site.Site_ID);
      if (feat!=null && !visibility){
            layer.applyEdits({deleteFeatures: [feat]})
            .then((results) => {
              // console.log("edited feats: ", results);
            })
      }else {
        loadModules(["esri/Graphic"])
          .then(([Graphic]) => {
            const point = { //Create a point
              type: "point",
              longitude: site.Longitude,
              latitude: site.Latitude
            };
            const pointGraphic = new Graphic({
              geometry: point,
              attributes:site
            });

            layer.applyEdits({addFeatures: [pointGraphic]})
              .then((results) => {
                // console.log("edited feats: ", results);
              })
          });
      }
    })
  }
};

/**
 * Action Creator to Update Map property
 * @param property
 * @param value
 * @return {function(...[*]=)}
 */
export const updateMapProperty = (property, value) => {
  return (dispatch,getState)=> {
    getState().geo.view.map[property] = value;
    if (property==='basemap')
      dispatch(updateBasemap(value));
  }
};

/**
 * Action Creator to Update Markers size
 * @param value {number}
 * @return {function(...[*]=)}
 */
export const updateMarkersSize = (value) => {
  return (dispatch,getState)=> {
    const sitesLayer = getState().geo.view.map.layers.find(el=>el.title === SITES_LAYER_ID);
    if (sitesLayer!=null){
      sitesLayer.renderer.symbol.size=value;
      dispatch(updateMarkerSize(value));
    }
  }
};
/**
 * Action Creator to Update Filling style
 * @param value {string} - of value {"solid", "mixed"}
 * @return {function(...[*]=)}
 */
export const updateFillingStyle = (value) => {
  return (dispatch,getState)=> {
    getState().geo.view.map.layers.filter(el=>el.type==='geojson' && el.title!==SITES_LAYER_ID).forEach((layer,index)=>{
      if (value==="mixed"){
        layer['renderer']['symbol']['style'] = mixedModeFillings[index];
      }
      else
        layer['renderer']['symbol']['style'] = "solid";
    });
    dispatch(updateFill(value));
  }
};

/**
 * Action Creator to Update Legend visibility
 * @param value {bool}
 * @return {function(...[*]=)}
 */
export const updateLegendVisibility = (value) => {
  return (dispatch,getState)=> {
    const view = getState().geo.view;
    if (value){
      loadModules(["esri/widgets/Legend"])
        .then(([Legend]) => {
          view.ui.add(new Legend({ view , id:"legend"}), "bottom-left");
        });
    }
    else{
      view.ui.remove("legend");
    }
    dispatch(updateLegend(value));
  }
};
/**
 * Action Creator to Update Layer properties, eg. blendMode, opacity
 * @param property {string}
 * @param value {any}
 * @return {function(...[*]=)}
 */
export const updateEditableLayersProperty = (property, value) => {
  return (dispatch,getState)=> {
    getState().geo.view.map.layers.filter(el=>el.type==='geojson' && el.title!==SITES_LAYER_ID).forEach(layer=>{
      layer[property] = value;
    });
    if (property==="blendMode")
      dispatch(updateBlendMode(value));
    if (property==="opacity")
      dispatch(updateLayerOpacity(value));
  }
};



/**
 * Action Creator to register events to ArcGIS view component.
 * It wraps handlers with one parameter only with multi-argument  function.
 * @param eventType - type of event as defined in  https://developers.arcgis.com/javascript/latest/api-reference/esri-views-View.html#event-details
 * @param func - handler function (event reference wrapped together with view, callback and messageQueue objects)
 * @param callback - callback to run on handler resolve (ArcGIS methods are usually asynchronous, so this is needed to get return information, eg. latitude, longitude, crossed layers, etc. without blocking anything)
 * @return {function(...[*]=)}
 */
export const registerEvent = (eventType, func, callback) => {
  return (dispatch,getState)=> {
    const view = getState().geo.view;
    const messageQueue = getState().messaging.msgQueue;
    view.on(eventType, (ev)=>func(ev, view, callback, messageQueue));
    };
};


/**
 * Action Creator to register events to ArcGIS view component.
 * It wraps handlers with one parameter only with multi-argument  function.
 * @param property - view property to be watched
 * @param func - handler function in the form (newValue, oldValue, propertyName, target)

 * @return {function(...[*]=)}
 */
export const registerWatch = (property, func) => {
  return (dispatch,getState)=> {
    const view = getState().geo.view;
    view.watch(property, func);
  };
};
/**
 * Default handler for onClick
 * @return {function(...[*]=)}
 */
export const defaultOnClickHandler = (event,view) => {

    const lat = Math.round(event.mapPoint.latitude * 1000) / 1000;
    const lon = Math.round(event.mapPoint.longitude * 1000) / 1000;
    view.popup.open({
      // Set the popup's title to the coordinates of the clicked location
      title: "Coords: ["+lon + "," + lat + "]",
      location: event.mapPoint, // Set the location of the popup to the clicked location
    });

    view.hitTest(event).then(response => {
      // only get the graphics returned from myLayer
      const graphicHits = response.results.filter(
        (hitResult) => hitResult.type === "graphic" && hitResult.graphic.layer.type === 'geojson'
      );
      let regions = "";
      let sites="";
      if (graphicHits.length > 0) {
        // do something with the myLayer features returned from hittest
        regions = graphicHits
          .filter((graphicHit) => graphicHit.graphic.layer.title!==SITES_LAYER_ID)
          .map((graphicHit) => {  return graphicHit.graphic.layer.title;}).join(", ");

        sites = graphicHits
          .filter((graphicHit) => graphicHit.graphic.layer.title===SITES_LAYER_ID)
          .map((graphicHit) => {  return graphicHit.graphic.attributes['__OBJECTID'];});
      }
      view.popup.content = (regions==="") ? "No defined regions" : "Regions: "+regions;
      if (sites.length>0){
        const layer = view.map.layers.find(el=>el.title===SITES_LAYER_ID);
        layer.queryFeatures().then((features)=>{
          const feat = features.features.find(el=>el.attributes['__OBJECTID']===sites[0]);
          view.popup.content = view.popup.content + '\n'+"Site: "+feat.attributes.Site_ID;
        });
      }
    });
};

/**
 * Default handler for onClick
 * @return {function(...[*]=)}
 */
export const getRegionsOnClickHandler = (event,view,callback) => {

  view.hitTest(event).then(response => {
    // only get the graphics returned from myLayer
    const graphicHits = response.results.filter(
      (hitResult) => hitResult.type === "graphic" && hitResult.graphic.layer.type === 'geojson'
    );
    let regions = "";
    if (graphicHits.length > 0) {
      // do something with the myLayer features returned from hittest
      regions = graphicHits
        .filter((graphicHit) => graphicHit.graphic.layer.title!==SITES_LAYER_ID)
        .map((graphicHit) => {  return graphicHit.graphic.layer.title;}).join(", ");

      if (callback!=null)
        callback(regions);
    }
  });
};


// // Fires `pointer-move` event when user clicks on "Shift" key and moves the pointer on the view.
// view.on('pointer-move', ["Shift"], function(event){
//   let point = view2d.toMap({x: event.x, y: event.y});
//   bufferPoint(point);
// });
// hitTest(screenPoint, options)  - works as PointPicker



/**
 * Action Creator to Update layer visibility.
 * To be used in Eventform only (quick solution)
 *
 * @return {function(...[*]=)}
 */
export const updateLayerRenderingEventForm = (regionsAsString) => {
  return (dispatch,getState)=> {

    const view = getState().geo.view;
    let regions =  (regionsAsString!=null && regionsAsString!=="")
      ? regionsAsString.split(", ")
      : [];
    view.map.layers.items.forEach((layer)=>{
        if (regions.includes(layer['title']))
          layer['renderer']['symbol']['style'] = "solid";
        else
          layer['renderer']['symbol']['style'] = "none";
    });
  }
};


/**
 * Action Creator to update sites for a given skill
 * @param skillId
 * @return {function(...[*]=)}
 */
export const updateSitesFromSkill = (skillId) => {
  return (dispatch,getState)=> {


    loadModules([
      "esri/Graphic"
    ]).then(([Graphic]) => {

      const view = getState().geo.view;
      const sites = getState().geo.sites;

      let isChanged = false;
      const config = {
        headers: {'Authorization': "bearer" + getState().auth.token_bearer}
      };
      axios.get(`/api/skill/${skillId}/site`,config)
        .then(response=>{
          const newSites = getNestedProp(["data","sites"],response);
          const general = getNestedProp(["data","general"],response);

            const layer =view.map.layers.find(el=>el.title===SITES_LAYER_ID);

            if (layer!=null){
              layer.applyEdits({deleteFeatures: sites})
                .then(()=>{
                  const symbols = [];
                  newSites.forEach(el=>{
                    const point = {
                      "type":"point",
                      "latitude" : el.latitude,
                      "longitude" : el.longitude
                    };
                    if (el.id!=null){
                      symbols.push(new Graphic({
                          geometry:point,
                          attributes: {
                            Site_ID: el.id,
                            Status: el.casesAvailable>0 ? String(el.casesAvailable): "",
                            StatusColor: el.color.toUpperCase(),
                          }
                        })
                      );
                    }
                  });

                  layer.applyEdits({addFeatures: symbols}).then(
                    el=>{
                      dispatch(updateSitesData(symbols));
                      dispatch(updateLegend(general));
                    })
                });
            }

        } )
        .catch(el=>{});
    });

  }
};

// Utils to check on differences between arrays
// const onlyInLeft = (left, right, compareFunction) =>
//   left.filter(leftValue =>
//     !right.some(rightValue =>
//       compareFunction(leftValue, rightValue)));
//
// const isSameObject = (a, b) =>  a.id === b.id && a.color === b.color  && a.casesAvailable === b.casesAvailable && a.status === b.status;