import React from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import {Button} from "primereact/components/button/Button";

/**
 * Layout topbar for Landing Page only.
 */
class LandingPageTopbar extends React.Component {
  render() {
    const {t,onUIChange, version} = this.props;
    return(
      <div className="header landingPageTopbar">
        <div className="landingPageSpineLogoContainerSmall">
          <Link to='/'>
            <svg xmlns="http://www.w3.org/2000/svg" width="112" height="40" viewBox="0 0 112 46">
              <path fill="#A7FFFE" fillRule="evenodd"
                    d="M21.96 13.24h-6.4v-1.444c0-1.631-.355-2.938-1.066-3.921-.711-.984-1.861-1.475-3.45-1.475-1.59 0-2.71.533-3.358 1.6-.648 1.067-.972 2.458-.972 4.173 0 1.84.209 3.137.627 3.89.418.753 1.422 1.443 3.012 2.07l4.894 1.945c2.677 1.004 4.465 2.427 5.365 4.267.9 1.84 1.349 4.497 1.349 7.969 0 4.057-.858 7.257-2.573 9.6-1.715 2.342-4.538 3.513-8.47 3.513-3.096 0-5.69-1.003-7.78-3.011C1.045 40.408 0 37.877 0 34.824v-2.385h6.4v2.008c0 1.297.366 2.384 1.098 3.263.732.878 1.872 1.317 3.42 1.317 2.008 0 3.283-.512 3.827-1.537s.816-2.73.816-5.114-.21-3.942-.628-4.674c-.418-.732-1.443-1.433-3.074-2.102l-4.58-1.882c-2.636-1.088-4.455-2.531-5.46-4.33C.817 17.59.315 15.205.315 12.235c0-3.597.94-6.536 2.823-8.815C5.02 1.14 7.74 0 11.294 0c3.054 0 5.595 1.035 7.624 3.106 2.028 2.07 3.043 4.402 3.043 6.996v3.137zM26.73.375h9.6c1.84 0 3.409.241 4.705.722 1.297.481 2.49 1.286 3.577 2.416 1.087 1.13 1.84 2.478 2.259 4.047.418 1.568.627 3.712.627 6.431 0 2.175-.115 3.911-.345 5.208-.23 1.297-.763 2.625-1.6 3.984-.837 1.36-1.997 2.437-3.482 3.232-1.485.794-3.378 1.192-5.679 1.192H33.13V45.05h-6.4V.376zm6.4 21.208h3.074c2.342 0 3.806-.575 4.392-1.725.586-1.15.879-3.085.879-5.804 0-2.677-.272-4.622-.816-5.835-.544-1.213-1.945-1.82-4.204-1.82h-3.326v15.184zM52.454.376h6.4v44.675h-6.4V.376zm12.36 0h6.15l9.662 26.918.126-26.918h6.4v44.675h-6.024l-9.788-26.855-.125 26.855h-6.4V.376zm28.11 0H112V6.4H99.325v13.114h11.044v6.023H99.325v13.114H112v6.4H92.925V.376z"/>
            </svg>
          </Link>
        </div>
        <div className="slogan-flex">
          {t("landingPage.slogan")}
        </div>
        <div className="landingPageLoginPanel" >
          <Button label={"Login"}/>
          <Button label={"Register"}/>
          <a onClick={()=>onUIChange(version==="new"?"old":"new")}> {version==="new"?"Show old":"Show new"} </a>
        </div>
      </div>
    )
  }
}
LandingPageTopbar.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation() (LandingPageTopbar);