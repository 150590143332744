import React from "react";
import {extent, max,maxIndex, min, histogram, bin, median, mean, deviation,bisect,thresholdFreedmanDiaconis,thresholdScott} from "d3-array";
import PropTypes from "prop-types";
import PixelHistogram from "../../../expDesign/component/d3components/PixelHistogram";
import {Spinner} from "primereact/components/spinner/Spinner";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {SAMPLER_TOOL} from "../../../../Constants";


const HISTOGRAM_RANGE = [
  {label: "Full range", value: "FULL"},
  {label: "Data range", value: "DATA"}
];

const PLOT_TYPE = [
  {label: "Lines", value: "lines"},
  {label: "Bars", value: "bars"}
];

/**
 * Dumb component with output panel of Sampler Tool (Data Probe).
 * @param samplerToolOutput
 * @returns {*}
 */
export class DataProbeOutputPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      numberOfBins: this.initNumberOfBins(),
      plotType: PLOT_TYPE[0].value,
      range: HISTOGRAM_RANGE[1].value,
      xDomain:null,
      markersVisible:false,
      markers:[],
      movingMarkers:null,
      bins:[]
    };
    ["calculateBinsAndMarkers","calculatePeaks","initNumberOfBins","onMidValueChange"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  componentDidMount() {
    this.calculateBinsAndMarkers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {numberOfBins,bins,plotType} = this.state;
    if (prevState.numberOfBins!==numberOfBins && !Number.isNaN(numberOfBins)) {
      this.calculateBinsAndMarkers();
    }
    if (prevState.bins.length!==bins.length){
      this.calculatePeaks();
    }
  }

  onMidValueChange(midPoint){
    const {samplerToolOutput,samplerToolInteractions,updateManualToolProperty} = this.props;
    samplerToolOutput['midPoint'] = midPoint;  //
    updateManualToolProperty(SAMPLER_TOOL, 'output', samplerToolOutput);

    if (samplerToolInteractions!=null){
      Object.keys(samplerToolInteractions).forEach((el)=>{
        const inter = samplerToolInteractions[el];
        if (inter.value==="midPoint" && inter.type === "widget"){
          updateManualToolProperty(inter.tool,inter.property,midPoint);
        }
      });
    }
  }

  /**
   * Uses samplerTool configuration parameter to adjust number of bins.
   *
   * @return {number}
   */

  initNumberOfBins(){
    const {samplerToolBucketing,samplerToolOutput} = this.props;
    const data = samplerToolOutput['stats']['rawData'];

    if (samplerToolBucketing==="SQRT")
      return Math.ceil(Math.sqrt(data.length));

    if (samplerToolBucketing==="STURGES")
      return Math.ceil(Math.log2(data.length))+1;

    if (samplerToolBucketing==="RICE")
      return 2*Math.ceil(Math.cbrt(data.length));

    if (samplerToolBucketing==="SCOTT")
      return thresholdScott(data,samplerToolOutput['stats']['minimum'],samplerToolOutput['stats']['maximum']);

    if (samplerToolBucketing==="FREEDMAN-DIACONIS")
      return thresholdFreedmanDiaconis(data,samplerToolOutput['stats']['minimum'],samplerToolOutput['stats']['maximum']);

    if (String(samplerToolBucketing).includes("WIDTH_")){
      let _width;
      try{
      _width = Number.parseInt(samplerToolBucketing.substring(6))}
      catch (e) {
        _width = 5;
      };
      return Math.ceil((samplerToolOutput['stats']['maximum']-samplerToolOutput['stats']['minimum'])/_width);
    }

    if (Number.isInteger(samplerToolBucketing))
      return samplerToolBucketing;

    return 256;
  }

  calculateBinsAndMarkers() {
    const {samplerToolOutput} = this.props;
    const {numberOfBins} = this.state;
    const data = samplerToolOutput['stats']['rawData'];
    this.setState( { bins : bin().thresholds(numberOfBins)(data) } );
    this.setState( {
      markers :[
      {label:"Min", value:samplerToolOutput['stats']['minimum'],color:"orange"},
      {label:"Max", value:samplerToolOutput['stats']['maximum'],color:"orange"},
      {label:"Avg", value:samplerToolOutput['stats']['average'],color:"green"},
      {label:"Otsu", value:samplerToolOutput['threshold'],color:"black"}
        ]
    });
    }

  calculatePeaks(){
    const {samplerToolOutput,updateManualToolProperty} = this.props;
    const {bins} = this.state;
    const otsu = samplerToolOutput['threshold'];
    const otsuBinIndex = bins.findIndex(arr=>arr.x0<=otsu && arr.x1>otsu)>-1
      ? bins.findIndex(arr=>arr.x0<=otsu && arr.x1>otsu)
      : (bins.length-1);
    const leftExtremeIndex = maxIndex(bins.slice(0,otsuBinIndex).map(b=>b.length));
    const rightExtremeIndex = maxIndex(bins.slice(otsuBinIndex).map(b=>b.length));

    const maxIndL = leftExtremeIndex >-1
      ? leftExtremeIndex
      : 0; // find extreme point on the left to Otsu

    const maxIndH = rightExtremeIndex>-1
      ? rightExtremeIndex
      : (bins.length - 1);

    if (maxIndL>-1 &&  maxIndH>-1 && otsuBinIndex>-1) {
      const peaks = [(bins[maxIndL].x0 + bins[maxIndL].x1) / 2, (bins[otsuBinIndex + maxIndH].x0 + bins[otsuBinIndex + maxIndH].x1) / 2];

      this.setState(
        {
          peaks,
          movingMarkers: [
            {label: "Low-intensity peak", value: peaks[0], color: "green", id: "Low"},
            {label: "High-intensity peak", value: peaks[1], color: "green", id: "High"}
          ], // this is only to initialize
        });

      samplerToolOutput['midPoint'] = (peaks[0] + peaks[1]) / 2;// Math.round((peaks[0] + peaks[1]) / 2);  //
      updateManualToolProperty(SAMPLER_TOOL, 'output', samplerToolOutput);  // calculate so it does not require to get into histogram to set up
    } else console.log("Data probe input error. Most extreme points are inaccessible", maxIndH, maxIndL );
  }

  render() {
    const {samplerToolOutput} = this.props;
    const {numberOfBins,  range, plotType, markersVisible,markers,movingMarkers, bins} = this.state;


    const getDimensionality = (i) => Math.abs(samplerToolOutput['points']["endIJK"][i]-samplerToolOutput['points']["startIJK"][i])+1;
    const voxelsStructure = "voxels ("
      .concat(String(getDimensionality(0)),"x",String(getDimensionality(1)),"x",String(getDimensionality(2)),")");

    const sampleSize=samplerToolOutput['stats']['inum'];

    return (
      <React.Fragment>
        {markers!=null && markers[0] !=null &&
        <div style={{display: "flex", justifyContent: "space-evenly",marginBottom:"1em"}}>
          <span>Sample: {sampleSize} {voxelsStructure}</span>
          <span>&#963; : {samplerToolOutput['stats']['sigma'].toFixed(1)}</span>
          <span style={{color:markersVisible?markers[0].color:"black"}}>{markers[0].label}: {markers[0].value}</span>
          <span style={{color:markersVisible?markers[2].color:"black"}}>{markers[2].label}: {markers[2].value.toFixed(1)}</span>
          <span style={{color:markersVisible?markers[3].color:"black"}}>{markers[3].label}: {markers[3].value}</span>
          <span style={{color:markersVisible?markers[1].color:"black"}}>{markers[1].label}: {markers[1].value}</span>
          <span title={"Point in the middle between distinct peaks."} style={{color:"red"}}>Mid: {samplerToolOutput['midPoint']}</span>
        </div>}
        {bins.length>0 &&
        <PixelHistogram bins={bins}
                        width={600} height={300}
                        threshold={samplerToolOutput['threshold']}
                        numberOfBins={numberOfBins}
                        plotType={plotType}
                        markersVisible={markersVisible}
                        lineMarkers={markers}
                        movingMarkers={movingMarkers}
                        onAdjustedRangeChange={this.onMidValueChange}
                        xDomain={this.state.xDomain}

        />}
        <div/>
         <div style={{display: "inline-flex",verticalAlign:"bottom"}}>
            <SelectButton
              style={{margin:"0 1em 0 1em"}}
              value={plotType}
              options={PLOT_TYPE}
              multiple={false}
              onChange={
                (e) => { if (e.value!=null) this.setState({plotType: e.value})}
              }/>

            <SelectButton
              style={{margin:"0 1em 0 1em"}}
              value={range}
              options={HISTOGRAM_RANGE}
              multiple={false}
              onChange={
                (e) => { if (e.value!=null) {
                  this.setState({
                    range: e.value,
                    xDomain: e.value==="DATA" ? null : [0,samplerToolOutput['stats']['resolution']]
                  })
                }}
              }/>

              <div style={{margin:"0 1em 0 1em"}}>

            <Spinner min={sampleSize<8 ? sampleSize : 8}
                     max={sampleSize<1024 ? sampleSize : 1024}
                     value={numberOfBins}
                     onChange={(e) => {
                       this.setState({numberOfBins: e.value})
                     }}
                     size={4}
                      />
              </div>
             <div style={{margin:"0 1em 0 1em"}}>
                   <label>Markers</label> <Checkbox checked={markersVisible} onChange={(e)=>{this.setState({markersVisible:e.checked})}}/>
             </div>

        </div>
      </React.Fragment>
    );
  }
};

DataProbeOutputPanel.propTypes = {
  samplerToolOutput: PropTypes.object.isRequired, // sampler output only
  samplerToolBucketing:PropTypes.any, // state of entire sampler
  samplerToolInteractions:PropTypes.object,
  updateManualToolProperty:PropTypes.func.isRequired
};
