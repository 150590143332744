import {
    RESULTS_DOWNLOAD_SUCCESS,
    RESULTS_DOWNLOAD_REQUEST,
    RESULTS_DOWNLOAD_FAIL
    } from "../action/actionType";
import {combineReducers} from "redux";

/**
 * downloadResultsStatus: {},
 *
 * @param state
 * @param action
 * @return {*}
 */
const downloadResultsReducer = (state = {
    downloadResultsStatus: {}
    }, action) => {
    switch (action.type) {
        case RESULTS_DOWNLOAD_REQUEST:
            return Object.assign({}, state, {
                downloadResultsStatus: {...state.downloadResultsStatus, [action.id]: RESULTS_DOWNLOAD_REQUEST}
            });
        case RESULTS_DOWNLOAD_SUCCESS:
            return Object.assign({}, state, {
                downloadResultsStatus: {...state.downloadResultsStatus, [action.id]: RESULTS_DOWNLOAD_SUCCESS}
            });
        case RESULTS_DOWNLOAD_FAIL:
            return Object.assign({}, state, {
                downloadResultsStatus:  {...state.downloadResultsStatus, [action.id]: action.err}
            });
        default:
            return state;
    }
};

export const ResultsReducer = combineReducers({
    downloadResults: downloadResultsReducer
});