import React from 'react'
import PropTypes from 'prop-types'
import {Card} from "primereact/components/card/Card";
import Button from "antd/es/button/button";
import {Trans, withTranslation} from "react-i18next";
import {IMAGE_URL} from "../../Constants";
import {Link} from "react-router-dom";
import {PICTURE_EDIT_PAGE_URI} from "./PreferencesPage";

/**
 * Displays personal data.
 */
class PersonalPage extends React.Component {


    render() {
        const {auth,t} = this.props;
        console.log("PersonalPage ...render");
        return (
            <div style={{margin: '16px'}}>
                <div className="ui-g-4">
                    <Card title={"Bio"}>
                        <p>{auth.name} {auth.nickname} {auth.lastname}</p>
                        <p><strong>{auth.role_active}</strong></p>
                        {/*<p>Permissions for: {roles.map(item => item.label).join(' ')}</p>*/}
                        <p>{t("registerForm.property.profession.label")} {(auth.profession)?professionOptions.find((el)=>el.value===auth.profession).label:""}</p>
                        <p>{t("registerForm.property.specialty.label")} {(auth.specialty)?specialtyOptions.find((el)=>el.value===auth.specialty).label:""}</p>
                        <p>{t("registerForm.property.eduLevel.label")} {(auth.eduLevel)?educationOptions.find((el)=>el.value===auth.eduLevel).label:""}</p>
                        <p>{t("registerForm.property.neuroLevel.label")} {(auth.experience)?familiarityLevel.find((el)=>el.value===auth.experience).label:""}</p>
                    </Card>
                </div>
                <div className="ui-g-4">
                    <Card title={"Personal picture"} >
                    <img id="avatarBig"
                         src={IMAGE_URL(auth.id)}
                         className="personal_img"
                         onClick={this.handleOverlay}
                         style={{position:"relative", top:"5px", objectFit: "scale-down", width: "200px"}}
                    />
                        <Link to={"/preferences#"+PICTURE_EDIT_PAGE_URI}>Edit</Link>
                    </Card>
                </div>
                <div className="ui-g-4">
                    <Card title={"Achievements"} >
                    </Card>
                </div>
            </div>
        )
    }
}

PersonalPage.propTypes = {
    auth: PropTypes.object.isRequired,
    t: PropTypes.func //from React-i18next
};


export default withTranslation()(PersonalPage);

export const professionOptions=[
    {value:"medical",label:<Trans>registerForm.property.profession.value.medical.label</Trans>},
    {value:"biology",label:<Trans>registerForm.property.profession.value.biology.label</Trans>},
    {value:"other", label:<Trans>registerForm.property.profession.value.other.label</Trans>}];

export const specialtyOptions=[
    {value:"neurology",label:<Trans>registerForm.property.specialty.value.neurology.label</Trans>},
    {value:"radiology",label:<Trans>registerForm.property.specialty.value.radiology.label</Trans>},
    {value:"other", label:<Trans>registerForm.property.specialty.value.other.label</Trans>}];

export const educationOptions=[
    {value:"highschool",label:<Trans>registerForm.property.eduLevel.value.highschool.label</Trans>},
    {value:"undergraduate", label:<Trans>registerForm.property.eduLevel.value.undergraduate.label</Trans>},
    {value:"master",label:<Trans>registerForm.property.eduLevel.value.master.label</Trans>},
    {value:"phd",label:<Trans>registerForm.property.eduLevel.value.phd.label</Trans>},
    {value:"md", label:<Trans>registerForm.property.eduLevel.value.md.label</Trans>},
    {value:"mdphd",label:<Trans>registerForm.property.eduLevel.value.mdphd.label</Trans>},
    {value:"boardspecialist",label:<Trans>registerForm.property.eduLevel.value.boardspecialist.label</Trans>}];

export const familiarityLevel=[
    {value:"none", label:<Trans>general.levelMeasure.value.none.label</Trans>},
    {value:"medium",label:<Trans>general.levelMeasure.value.medium.label</Trans>},
    {value:"expert", label:<Trans>general.levelMeasure.value.expert.label</Trans>}];
