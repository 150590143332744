import React from "react";
import PropTypes from "prop-types";
import {REQUEST_STATUS_SUCCESS} from "../../../../Constants";


/**
 *  Component displaying SEGMENTATION METRIC for overlay images.
 * @return {*}
 */
class SegmentationMetricsWidget extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      calculated:false,
      jaccardResult:"calculated",
      diceResult:"calculated",
      group1:"calculated",
      group2:"calculated",
      overlap:"calculated",
    };
    ["calculate"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }



  componentDidMount() {
    this.calculate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {tool, overlays} = this.props;
    if (prevProps.overlays !== overlays && !this.state.calculated){
      this.calculate();

    }
  }

  calculate(){
    const {tool, overlays} = this.props;
    if (overlays[tool.input] !=null  && overlays[tool.input].state===REQUEST_STATUS_SUCCESS && !this.state.calculated){

      const dataArray = overlays[tool.input].data.getPointData().getScalars().getData();

      let group1Counter = 0;
      let group2Counter = 0;
      let overlapCounter = 0;
      for (let i=0;i<dataArray.length;i++){
        if (dataArray[i]===2)
          group1Counter=group1Counter+1;
        if (dataArray[i]===3)
          group2Counter=group2Counter+1;
        if (dataArray[i]===1)
          overlapCounter=overlapCounter+1;
      }
      const union = group1Counter+group2Counter+overlapCounter;
      const sum = group1Counter+group2Counter+2*overlapCounter;

      const g1 = (100*group1Counter)/sum;
      const g2 = (100*group2Counter)/sum;
      const ov = (200*overlapCounter)/sum;

      const iou= union>0 ? (overlapCounter)/union : 0;
      const dice = sum>0?(2*overlapCounter)/sum: 0;
      this.setState({
        calculated:true,
        jaccardResult:iou.toFixed(2),
        diceResult: dice.toFixed(2),
        group1:g1.toFixed(2),
        group2:g2.toFixed(2),
        overlap:ov.toFixed(2)
      });
    }
  }

  render() {
    const {tool, overlays} = this.props;
    const {jaccardResult, diceResult, group1, group2, overlap} = this.state;

    const jaccard = (tool!=null && tool.jaccard)?"Jaccard: "+ jaccardResult:"";
    const dice = (tool!=null && tool.dice) ?"Dice: "+ diceResult:"";
    const g1 = "G1 exclusive: "+ group1 + "%";
    const g2 = "G2 exclusive: "+ group2 + "%";
    const g3 = "Overlap: "+ overlap + "%";

    return (
      <div id={"segmentationMetrics"}
           style={{display: "inline-block", float: "right", verticalAlign: "bottom"}}>
        {jaccard} {dice} {g1} {g2} {g3}
      </div>
    );
  };
}


export default SegmentationMetricsWidget;


SegmentationMetricsWidget.propTypes = {
  id: PropTypes.string.isRequired,
  overlays: PropTypes.object.isRequired,
  tool: PropTypes.object.isRequired
};
