import React from 'react'
import {getNestedProp} from "../../helpers/expressions";
import store from "../../Store";
import axios from "axios/index";
import {REQUEST_STATUS_FAIL} from "../../../Constants";
import {
  updateBlandAltmanPlotDataACSegmentations,
  updateBlandAltmanPlotSettingsACSegmentations
} from "../../visualization/action/InteractivePlotAction";
import {getSelectedPlotIndex} from "../selectors/SAMSelectors";
import {
  CLEAR_INTERACTIVE_PLOT_SAM,
  COPY_PLOT_SETTINGS_SAM,
  DUPLICATE_PLOT_SETTINGS_SAM,
  SWITCH_ANALYSIS_MODE_SAM,
  UPDATE_LAYOUT_SAM, UPDATE_PLOT_SETTINGS_MAP_SAM,
  UPDATE_PLOT_SETTINGS_SAM,
  UPDATE_PROPERTY_SAM,
  UPDATE_RAW_PLOT_DATA_SAM,
  UPDATE_SELECTED_CELL_SAM,
  UPDATE_SELECTED_DATA_POINT_SAM,
  UPDATE_SELECTED_MEASUREMENT_SAM,
  UPDATE_STATE_SAM
} from "./actionType";
import {updateBlandAltmanPlotData, updatePlotDataState} from "./BAAction";
import {updateScatterPlotData} from "./ScatterPlotAction";
import {updateRegressionPlotData} from "./RegressionPlotAction";


// ---- HELPERS -----

export const getContributorName = (uuid,data) => {
  const con = data.contributors.find(el => el.uuid === uuid);
  if (!(con!=null))
    return "John Doe";
  return con.type === "user" ? con.label: con.name;
};


export const getContributorType = (uuid,data) => {
  const con = data.contributors.find(el => {
    return el.uuid === uuid});
  if (!(con!=null))
    return "user";
  return con.type;
};

export const getVariableLabel = (uuid,data,shortVersion=true) => {
  const variable = data.variables.find(el =>{
    return el.uuid === uuid;});
  if (!(variable!=null))
    return null;
  if (shortVersion || !(variable.iri!=null))
    return variable.name;
  return <a href={variable.iri} target={"_blank"}>{variable.name}</a>
};

const getRWELabel = (uuid,data,shortVersion=true) => {
  const rwe = data.realWorldEntities.find(el => {
    return el.uuid === uuid
  });
  if (!(rwe!=null))
    return null;
  if (shortVersion || !(rwe.iri!=null))
    return rwe.name;
  return <a href={rwe.iri} target={"_blank"}>{rwe.name}</a>;
};

const getRWEPropsLabels = (propsArr,data) => {
  let label="";
  for (let i=0;i<propsArr.length;i++)
  {
    const rwe = data.realWorldEntityProperties.find(el => {
      return el.uuid === propsArr[i]
    });
    if (rwe!=null)
      label = label + rwe.property +" = " + rwe.value + ", ";
  }
  return label;
};

export const getMeasurementById = (id,data)=>{
  return data.measurementConfigurations.find(el=>el.id === id);
};

/**
 * Filter adapted for PrimeReact component Column.
 * To be used in many PR components.
 * @param value - value
 * @param filter - filter
 * @param rawData
 * @return {boolean}
 */
export const filterMeasurementByString = (value,filter,rawData)=>{
  if (filter === undefined || filter === null || (typeof filter !== 'string')) {
    return true;
  }
  let result = false;
  if (value != null ) {
    const measurement = rawData.measurementConfigurations.find(el=>el.id===value);
    if (measurement!=null){
      const label = getMeasurementShortLabel(measurement,rawData);
      result = label.toLowerCase().includes(filter.toLowerCase());
    }
  }
  return result;
};
/**
 * Filter adapted for PrimeReact component Column.
 * To be used in many PR components.
 * @param value - value
 * @param filter - filter
 * @param rawData
 * @return {boolean}
 */
export const filterContributors = (value, filter,rawData)=>{
  if (filter === undefined || filter === null || (typeof filter !== 'string')) {
    return true;
  }
  let result = false;
  if (value != null ) {
    const contributor = rawData.contributors.find(el=>el.uuid===value);
    if (contributor!=null){
      return(
        contributor.label!=null && contributor.label.toLowerCase().includes(filter.toLowerCase()))
        || (contributor.name!=null && contributor.name.toLowerCase().includes(filter.toLowerCase())
        );
    }
  }
  return result;
};

/**
 * Parses label for Measurement item.
 * @param option
 * @param data
 * @return {string}
 */
export const getMeasurementShortLabel = (option,data)=>{
  if (getNestedProp(["variableId"],option)!=null)
    return getVariableLabel(option.variableId,data) +" OF "+getRWELabel(option.realWorldEntityId,data) + " WHERE " + getRWEPropsLabels(option.realWorldEntityPropertiesIds,data);
  return "selected measurements";
};

/**
 * * Parses label for Measurement item  and returns links (optionally if iri is defined)
 * @param option
 * @param data
 * @return {React}
 */

export const getMeasurementLongLabel = (option,data)=>{
  const properties = getRWEPropsLabels(option.realWorldEntityPropertiesIds,data);
  const propsLabel = (properties!=="")
    ? <React.Fragment><br/> WHERE {properties}</React.Fragment>
    : <React.Fragment/>;
  return <React.Fragment>
    {getVariableLabel(option.variableId,data, false)} OF {getRWELabel(option.realWorldEntityId,data,false)} {propsLabel};
  </React.Fragment>
};

function parseContributors(rawData){
  let contributors = getNestedProp(["contributors"],rawData);
  contributors.forEach(
    (el,index) => {
      el.label = el['type']==="non-user"
        ? ((el['name']!=null)?el['name']:"Non-user ".concat(String(index)))
        : (el['firstName']!=null?el['firstName']:"User ")+" "+(el['lastName']!=null?el['lastName']:String(index));
      el.value = el['uuid']
    }
  );
  return contributors;
}

function parseCases(rawData){
  let cases = getNestedProp(["cases"],rawData);
  cases.forEach(el=> {
    el.value = el.uuid;
  });
  return cases;
}
function parseVisits(rawData){
  let visits = getNestedProp(["visits"],rawData);
  visits.forEach(el=> {
    el.value = el.id;
  });
  return visits;
}

function parseSubjects(rawData){
  let subj = getNestedProp(["subjects"],rawData);
  subj.forEach(el=> {
    el.value = el.id;
  });
  return subj;
}

function parseContributions(rawData){
  let cont = getNestedProp(["contributions"],rawData);
  cont.forEach((el,index)=> {
    el.key = "contribution_"+index;  // generate keys that are unique. Think about hashing function!
  });
  return cont;
}


function initializeDataset(rawData){

  const contributors = parseContributors(rawData);
  const cases = parseCases(rawData);
  const visits = parseVisits(rawData);
  const subjects = parseSubjects(rawData);

  parseContributions(rawData);

  const possibleRatersAndCasesForIntra = []; // TODO implement me
  const emptyCases = [];
  const selectedCases = [];


  return {
    minNumberOfGroups: 2,
    maxNumberOfGroups: 2,
    initialGroupColor: '#1976D2',
    levelAgreementValue: 0,
    groups: [],
    selectedCases,
    emptyCases,
    contributors,
    cases,
    visits,
    subjects,
    percentageDifference: false,
    possibleRatersAndCasesForIntra,
    intraCaseRaterSelected: null,
    intraRaterHandling: 'ALL_DATA',
    // selectedCase: map(cases, 'value')[0],
    thresholdDistance: 5,
    mode: 0 // Bland-altman
  };

}


/**
 *
 * @param data - dataset object
 * @param property - subjectId, visitId etc.
 * @param arrayOfValues - array of ids ["aaa","bbb"]
 * @return {object[]}
 */
export const filterCasesByProperty=(data,property,arrayOfValues)=>{
  return data.cases.filter(el=>{return arrayOfValues.includes(el[property])})
};


// ----ACTIONS --------

const updateRawDataForPlot = (rawData) => ({
  type: UPDATE_RAW_PLOT_DATA_SAM,
  rawData
});

/**
 * Action Creator for clearing state
 * @return {{type: string}}
 */
export const clearStandardizedPlot = () => ({
  type: CLEAR_INTERACTIVE_PLOT_SAM
});


/**
 * Action Creator for updating full state state
 * @return {{type: string}}
 */
export const updateStateSAM = (state) => ({
  type: UPDATE_STATE_SAM,
  state
});

/**
 * Action Creator for updating full state state
 * @return {{type: string}}
 */
export const updatePropertySAM = (property,value) => ({
  type: UPDATE_PROPERTY_SAM,
  property,
  value
});

/**
 * Action Creator for copying state to clipboard.
 * @return {{type: string}}
 */
export const copyPlotSettings = () => ({
  type: COPY_PLOT_SETTINGS_SAM
});


/**
 * Action Creator for copying state to clipboard.
 * @return {{type: string}}
 */
export const duplicatePlotSettings = (forAllFlag=false) => ({
  type: DUPLICATE_PLOT_SETTINGS_SAM,
  forAllFlag
});

/**
 *  Update a given property of current PlotSettings with a given value
 * @param property - string
 * @param value - object
 * @return {{property: *, type: string, value: *}}
 */
export const updatePlotSettingsProperty = (property,value) => ({
  type: UPDATE_PLOT_SETTINGS_SAM,
  property,
  value
});

/**
 *  Update a given property of current PlotSettings with a given value
 * @param {object} properties - object with keys and values
 * @return {{property: *, type: string, value: *}}
 */
export const updatePlotSettingsProperties = (properties) => ({
  type: UPDATE_PLOT_SETTINGS_MAP_SAM,
  properties
});


export const switchMode = (mode) => ({
  type: SWITCH_ANALYSIS_MODE_SAM,
  mode
});


// ---- ACTION CREATORS -----

export const initializeStandardizedAgreementModel = (experimentId, snapshot=null) => {
  return dispatch => {

    const config = {
      headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
    };

    if (!(snapshot!=null)){
      const resultsURL = `/api/experiment/${experimentId}/result/plot-data/v2`;
      axios.get(resultsURL, config)
        .then(response => {
          console.log(`InteractivePlotAction.js :: initializeBlandAltmanPlotSegmentations::{${resultsURL} response`, response);
          if (response.status !== 200) {
            dispatch(updatePlotDataState(REQUEST_STATUS_FAIL));
          } else {
            dispatch(updateRawDataForPlot(response.data));
            dispatch(updateBlandAltmanPlotSettingsACSegmentations(initializeDataset(response.data)));
            dispatch(updateBlandAltmanPlotDataACSegmentations());
          }
        }).catch(error => {
        console.log('InteractivePlotAction.js :: initializeBlandAltmanPlotSegmentations :: error ::', error);
        dispatch(updatePlotDataState(REQUEST_STATUS_FAIL));
      });
    }
    else {
      // const resultsURL = `/api/experiment/${experimentId}/result/plot-data/v2?snapshot=True`; // real version
      const resultsURL = `/dist/jsonDocs/data/snap-${experimentId}.json`; // mock version using dist folder
      axios.get(resultsURL, config)
        .then(response => {
          console.log(`InteractivePlotAction.js :: initializeBlandAltmanPlotSegmentations::{${resultsURL} response`, response);
          if (response.status !== 200) {
            dispatch(updatePlotDataState(REQUEST_STATUS_FAIL));
          } else {
             dispatch(updateStateSAM(response.data));
             dispatch(refreshAllPlots());
          }
        }).catch(error => {
        console.log('InteractivePlotAction.js :: initializeBlandAltmanPlotSegmentations :: error ::', error);
        dispatch(updatePlotDataState(REQUEST_STATUS_FAIL));
      });
    }

    // Demo data
  }
};

export const onLayoutChange =(selectedCell)=>({
    type: UPDATE_LAYOUT_SAM,
    selectedCell
});

export const onSelectedCellChange =(selectedCell)=>({
  type: UPDATE_SELECTED_CELL_SAM,
  selectedCell
});

const  updateMeasurement= (index, measurement) => ({
  type: UPDATE_SELECTED_MEASUREMENT_SAM,
  measurement,
  index
});


export const updateSelectedMeasurement = (measurement) => {
  return (dispatch,getState) => {
    let index = getSelectedPlotIndex(getState());
    dispatch(updateMeasurement(index,measurement));
  }
};


export const  updateSelectedDataPoint= (selectedDataPoint) => ({
  type: UPDATE_SELECTED_DATA_POINT_SAM,
  selectedDataPoint
});


export const refreshAllPlots = () => {

  return (dispatch, getState) => {
    const settings = getState().sam.plotSettings;
    for (let i = 0; i < 9; i++) {
      if (settings[i].mode === 0)
        dispatch(updateBlandAltmanPlotData(i));
      if (settings[i].mode === 1)
        dispatch(updateScatterPlotData(i));
      if (settings[i].mode === 3)
        dispatch(updateRegressionPlotData(i));
    }
  }
};