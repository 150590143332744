// ----------------Standardized Analysis Module (SAM) Plot --------------------------
export const UPDATE_PLOT_SETTINGS_SAM = "UPDATE_PLOT_SETTINGS_SAM";
export const UPDATE_PLOT_SETTINGS_MAP_SAM = "UPDATE_PLOT_SETTINGS_MAP_SAM";
export const UPDATE_PLOT_DATA_SAM = "UPDATE_PLOT_DATA_SAM";
export const UPDATE_PLOT_DATA_DUPS_SAM="UPDATE_PLOT_DATA_DUPS_SAM";
export const UPDATE_RAW_PLOT_DATA_SAM = "UPDATE_RAW_PLOT_DATA_SAM";
export const UPDATE_MANUAL_TOOL_SAM = "UPDATE_MANUAL_TOOL_SAM";
export const UPDATE_ROI_VISUALIZATION_DATA_SAM = "UPDATE_ROI_VISUALIZATION_DATA_SAM";
export const UPDATE_PLOT_DATA_STATE_SAM = "UPDATE_PLOT_DATA_STATE_SAM";
export const UPDATE_MODEL_PARAMETERS_STATE_SAM = "UPDATE_MODEL_PARAMETERS_STATE_SAM";
export const UPDATE_MODEL_PARAMETERS_SAM = "UPDATE_MODEL_PARAMETERS_SAM";
export const CLEAR_INTERACTIVE_PLOT_SAM = "CLEAR_INTERACTIVE_PLOT_SAM";
export const UPDATE_STATE_SAM = "UPDATE_STATE_SAM";
export const UPDATE_LAYOUT_SAM = "UPDATE_LAYOUT_SAM";
export const UPDATE_SELECTED_CELL_SAM = "UPDATE_SELECTED_CELL_SAM";
export const UPDATE_SELECTED_DATA_POINT_SAM = "UPDATE_SELECTED_DATA_POINT_SAM";
export const UPDATE_SELECTED_MEASUREMENT_SAM = "UPDATE_SELECTED_MEASUREMENT_SAM";
export const DUPLICATE_PLOT_SETTINGS_SAM = "DUPLICATE_PLOT_SETTINGS_SAM";
export const COPY_PLOT_SETTINGS_SAM = "COPY_PLOT_SETTINGS_SAM";
export const UPDATE_PROPERTY_SAM = "UPDATE_PROPERTY_SAM";
export const SWITCH_ANALYSIS_MODE_SAM = "SWITCH_ANALYSIS_MODE_SAM";

// -------------------Extended Models--------------------------------

export const UPDATE_EXTENDED_MODEL_PROPERTY_SAM = "UPDATE_EXTENDED_MODEL_PROPERTY_SAM";
export const UPDATE_EXTENDED_MODEL_PROPERTY_STATE_SAM = "UPDATE_EXTENDED_MODEL_PROPERTY_STATE_SAM";
