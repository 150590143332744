import React from "react";
import {HierarchicalDropdown} from "./HierarchicalDropdown";

const TREE_DATA = {
    title: 'Root',
    value: 'Root',
    key: '0',
    iri: 'http://fhfhgfhg/fjhfjhfjhfh',
    children: [{
        title: 'Node1',
        value: 'Node1',
        key: '1',
        children: [{
            title: 'Node1-1',
            value: 'Node1-1',
            key: '1-1',
        },
            {
                title: 'Node1-2',
                value: 'Node1-2',
                key: '1-2',
            }]
    },

        {
            title: 'Node2',
            value: 'Node2',
            key: '2',
        }]
};

export const CUSTOM_ELEMENT = {
    LIST: {
        type: "simple-dropdown",
        name: "List",
        options: ["Choice 1", "Choice 2"],
        value:null,
        placeholder:"Select value"
    },
    TEXT:{
        type:"textbox",
        name: "Text",
        value:"Example",
        placeholder:"Enter name..."
    },
    CHOICE:{
        type:"radiobutton",
        name: "Unique Choice",
        options: ["Choice 1", "Choice 2"]
    },
    MULTIPLE_CHOICE:{
        type:"checkbox",
        name: "Multiple choice",
        options: ["Choice 1", "Choice 2"]
    },
    HIERARCHICAL:{
        type: "recursive-dropdown",
        name: "Hierarchical List",
        options:TREE_DATA,
        value:undefined
    },
    RULER:{
        type: "ruler-widget",
        name: "Ruler widget",
        value:undefined,
        placeholder:"Select value"
    },
    AUTOCOMPLETE:{
        type: "autocomplete",
        name: "AutoComplete",
        value:undefined,
        placeholder:"Find name..."
    }

};




/**This is component for representing single cell in annotation table.
 * Not used anymore.
 *
 * @deprecated
 * @param row
 * @returns {*}
 * @constructor
 */
export const AnnotationCell = (row) => {


    const getSimpleDropdown = (element)=>{
        return <select>
            {element.options.map((el)=> {return <option value={el}>{el}</option>})}   ;
        </select>
    };

    const getSimpleTextBox =  (element)=> {
        return <input type="text" value={element.value} placeholder={element.placeholder}/>;
    };

    const getRadioButtons = (element)=> {
        return <div>{element.options.map((el)=> {
            return <React.Fragment>
                <div>{el}</div>
                <input type="radio" />
            </React.Fragment>
        })}   </div>;
    };
    const getCheckboxes = (element)=> {
        return <div>{element.options.map((el)=> {
            return <React.Fragment>
                <div>{el}</div>
                <input type="checkbox" />
            </React.Fragment>
        })}   </div>;
    };

    const getRecursiveDropdown =  (element)=> {
        return <HierarchicalDropdown parentNode={element.options} updateValue={null}/>
    };

    const getTd = (element)=>{
        switch(element.type){
            case CUSTOM_ELEMENT.LIST.type:
                return getSimpleDropdown(element);
            case CUSTOM_ELEMENT.TEXT.type:
                return getSimpleTextBox(element);
            case CUSTOM_ELEMENT.CHOICE.type:
                return getRadioButtons(element);
            case CUSTOM_ELEMENT.MULTIPLE_CHOICE.type:
                return getCheckboxes(element);
            case CUSTOM_ELEMENT.HIERARCHICAL.type:
                return getRecursiveDropdown(element);
            default: return null;
        }
    };

    return (
        <div style={{display:"inline-block"}}>
            {Object.keys(CUSTOM_ELEMENT).map((el)=>{return getTd(CUSTOM_ELEMENT[el])})}
        </div>
    );
};




