import {getSelectedPlotSettings} from "../selectors/SAMSelectors";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {
  updatePlotDataState
} from "./BAAction";
import {getSeriesObservations} from "../selectors/ObservationsSelectors";
import {updatePlotData} from "./ScatterPlotAction";
import NetworkService from "../../helpers/io/NetworkService";
import {UPDATE_MODEL_PARAMETERS_SAM, UPDATE_MODEL_PARAMETERS_STATE_SAM} from "./actionType";
import {displayCommunicationErrorMessage} from "./errorHandler";




const updateModelParams = (model, index=null) => ({
  type: UPDATE_MODEL_PARAMETERS_SAM,
  model,
  index
});
const updateModelParamsState = (state, index=null) => ({
  type: UPDATE_MODEL_PARAMETERS_STATE_SAM,
  state,
  index
});

/**
 * Get model for Regression Analysis calculated in R.
 * @param transferObject - data to send (must contain x,y,l params)
 * @param plotIndex - optional parameter
 *
 * @returns {function(*)} change state of Store
 */
export const calculateModelForRegression = (transferObject, plotIndex=null) => {
  return (dispatch)=> {
    dispatch(updateModelParamsState(REQUEST_STATUS_REQUESTED,plotIndex));
    console.log('SAMRegressionPlotAction :: calculateModelForRegression :: Before axios requres');
    NetworkService.getGLMModel(transferObject)
      .then(outData => {
        console.log('SAMRegressionPlotAction :: calculateModelForRegression :: response', outData);
        dispatch(updateModelParams(outData,plotIndex));
        dispatch(updateModelParamsState(REQUEST_STATUS_SUCCESS,plotIndex));
      })
      .catch(err => {
        console.log(err);
        dispatch(displayCommunicationErrorMessage(err));
        dispatch(updateModelParamsState(REQUEST_STATUS_FAIL,plotIndex));
      });
  }
};


export const updateRegressionPlotData = (plotIndex = null) => {
  return (dispatch, getState) => {
    const settings = plotIndex != null
      ? getState().sam.plotSettings[plotIndex]
      : getSelectedPlotSettings(getState());

    const instances = getSeriesObservations(getState(), plotIndex);

    const {mode,axisXIndex,axisYIndex,selectedMeasurementConfiguration} = settings;

    if (mode===3)  {  // if regression mode, just in case
      let dataForScatter = [];
      instances.forEach((instance, index) => {
        try {
          const xIndex = instance.contributions.findIndex(c=>c.measurementConfigurationId === selectedMeasurementConfiguration[axisXIndex].id); // find index of x in contributions
          const yIndex = instance.contributions.findIndex(c=>c.measurementConfigurationId === selectedMeasurementConfiguration[axisYIndex].id); // find index of y in contributions
          let single = {
            "x": instance.contributions[xIndex].value, // change index here
            "y": instance.contributions[yIndex].value,
            'realCase': instance.key.caseId,
            "visitX": "whatever",
            "visitY": "whatever",
            "dateX":  "whatever",
            "dateY":  "whatever",
            "contributionsX":[instance.contributions[xIndex]],
            "contributionsY":  [instance.contributions[yIndex]],
            "index":  index,
            "mode":1
          };

          dataForScatter.push(single);

        } catch (err) {
          console.log("Missing sample for: ", instance);
        }

      });

      // calculate Linear Model
      if (dataForScatter.length > 0) {
        dispatch(calculateModelForRegression(createTransferObjectToRServer(instances, settings),plotIndex));
      }
      // then remove duplicates
      // const dataForScatterWithDuplicates = markDuplicates(dataForScatter);

      dispatch(updatePlotData(dataForScatter, plotIndex));
      dispatch(updatePlotDataState(REQUEST_STATUS_SUCCESS, plotIndex));
    }

  };
};

/**
 * Factory method creating transfer object to send to R server in order to calculate stats (line params, r-squared, etc.).
 * This method should be called on full data set (not with reduced duplicates).
 * @param settings - plot setting with family and formula
 * @param instances - observations
 * @return {{}} transfer object
 */
function createTransferObjectToRServer(instances,settings) {
  let transferObject = {data:{}};
  transferObject.family = settings.family;
  transferObject.formula = settings.formula;
  transferObject.confint = settings.confint;
  transferObject.predint = settings.predint;
  transferObject.showPI = settings.showPI;
  transferObject.showCI = settings.showCI;
  Object.keys(settings.measurementIndexToSymbolMapping).forEach(key=>{
    transferObject.data[key] = instances.map(el => el.contributions[settings.measurementIndexToSymbolMapping[key]].value)
  });
  return transferObject;
}