import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import PropTypes from 'prop-types';
import {LEVEL_TRACING_TOOL} from "../../../../Constants";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {
  LEVEL_FUNCTION_OPTIONS,
  setLevelFunction, setLevelFunctionMargin,
  setOutlineThickness, setThreeD
} from "../../../vtk/LevelTracingEffect/LevelTracingRenderer";
import {Spinner} from "primereact/components/spinner/Spinner";
import {Checkbox} from "primereact/components/checkbox/Checkbox";


const LEVEL_FUNCTION = 'levelFunction';
const LEVEL_FUNCTION_MARGIN = 'levelFunctionMargin';
const LEVEL_THREE_D = 'levelThreeD';
const LINE_WIDTH = 'lineWidth';

/**
 * Dumb component with template for Level Tracing Tool.
 * @param changeToolProperties(property,value) - callback
 * @param toolProperties - props object eg. {size:"1"}
 * @param toolConfiguration - initial configuration needed to set visibility of controls
 * @returns {*}
 */
export const LevelTracingToolbar = ({toolProperties, changeToolProperties, toolConfiguration}) => {

  const control = (property, type) => toolConfiguration != null
    && toolConfiguration.properties != null
    && toolConfiguration.properties[property] != null
    && toolConfiguration.properties[property][type] != null
    && toolConfiguration.properties[property][type];

  const checkIfAnyPropertyVisible = Object.keys(toolConfiguration['properties'])
    .find((el) => {
      return toolConfiguration['properties'][el]['controlVisible']
    }) != null;

  return (
    <React.Fragment>
      {checkIfAnyPropertyVisible &&
      <Toolbar style={{display: "inline-block"}}>
        {control(LEVEL_FUNCTION, 'controlVisible') &&
        <React.Fragment>
          <label style={{marginLeft: "1em"}}>Level Tracing:</label>
          <Dropdown key="levelTracingDropdown"
                    value={toolProperties[LEVEL_FUNCTION]}
                    options={LEVEL_FUNCTION_OPTIONS.options}
                    onChange={(e) => {
                      changeToolProperties(LEVEL_TRACING_TOOL, LEVEL_FUNCTION, e.value);
                      setLevelFunction(e.value);
                    }
                    }
          />
        </React.Fragment>}
        {control(LEVEL_FUNCTION_MARGIN, 'controlVisible') &&
        <React.Fragment>
          <label style={{marginLeft: "1em"}}>Delta:</label>
          <Spinner key="levelFunctionMarginSpinner"
                   value={toolProperties[LEVEL_FUNCTION_MARGIN]}
                   min={1} max={100} step={1}
                   size={3}
                   onChange={(e) => {
                     changeToolProperties(LEVEL_TRACING_TOOL, LEVEL_FUNCTION_MARGIN, e.value);
                     setLevelFunctionMargin(e.value);
                     setLevelFunction(toolProperties[LEVEL_FUNCTION]);
                   }
                   }
          />
        </React.Fragment>}
        {control(LINE_WIDTH, 'controlVisible') &&
        <React.Fragment>
          <label style={{marginLeft: "1em"}}>Line Thickness:</label>
          <Spinner key="levelTracingWidthSpinner"
                   value={toolProperties[LINE_WIDTH]}
                   size={3}
                    min={0.1} max={0.5} step={0.1}
                    onChange={(e) => {
                      changeToolProperties(LEVEL_TRACING_TOOL, LINE_WIDTH, e.value);
                      setOutlineThickness(e.value);
                    }
                    }
          />
        </React.Fragment>}
        {control(LEVEL_THREE_D, 'controlVisible') &&
        <React.Fragment>
          <label style={{marginLeft: "1em"}}>3D filling:</label>
          <Checkbox key="threeDCheckbox"
                   checked={toolProperties[LEVEL_THREE_D]}
                   onChange={(e) => {
                     changeToolProperties(LEVEL_TRACING_TOOL, LEVEL_THREE_D, e.checked);
                     setThreeD(e.checked);
                   }
                   }
          />
        </React.Fragment>}
      </Toolbar>}
    </React.Fragment>
  );
};

LevelTracingToolbar.propTypes = {
  toolProperties: PropTypes.object.isRequired,
  toolConfiguration: PropTypes.object.isRequired,
  changeToolProperties: PropTypes.func.isRequired
};
