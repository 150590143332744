import React from 'react';
import ContainerLogin from '../containers/ContainerLogin.js';
import SpineLogo from './SpineLogo.js';
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";

/**
 * Layout topbar for Landing Page only.
 */
class LayoutTopbar extends React.Component {
  render() {
    const {t} = this.props;
    return(
      <div className="header landingPageHeaderTopbar">
          <div className="landingPageSpineLogoContainer">
              <Link to='/'>
                  <SpineLogo/>
              </Link>
          </div>
          <div className="slogan">
              {t("landingPage.slogan")}
          </div>
          <div className="loginPanel" >
              <ContainerLogin/>
          </div>
      </div>
    )
  }
}
LayoutTopbar.propTypes = {
    t: PropTypes.func.isRequired
};

export default withTranslation() (LayoutTopbar);