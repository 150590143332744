import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {DataTable} from "primereact/components/datatable/DataTable";
import {
  ANNOTATION_PROPERTY_NAME__ID,
  REQUEST_STATUS_FAIL,
  REQUEST_STATUS_REQUESTED,
  REQUEST_STATUS_SUCCESS
} from "../../../../Constants";
import {Column} from "primereact/components/column/Column";
import {StatusColumn} from "./StatusColumn";
import {uuidv4} from "../../../helpers/strings";
import OntologyAutoComplete from "../../../ncbo/component/OntologyAutoComplete";


/**
 * Form for creating/editing annotation column.
 */
class AnnotationOntologyBuilder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRow:null,
      selectedOntology:null,
      expandedRows:null,
      selectedStatus:"ALL",
      ontologyFilter:"",
      name:"",
      _id:uuidv4()
    };
    ["onSave","onRowSelect","onRowEdit","onRowDelete","onRowUnselect","onClear"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    if (this.props.getOntologyList!=null)
      this.props.getOntologyList();
  }

  componentWillUnmount() {
    if (this.props.clearSaveOntology!=null)
      this.props.clearSaveOntology();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {saveOntologyError, saveOntologyState, saveOntologyResponse, messageQueue, getOntologyList} = this.props;
    if (prevProps.saveOntologyState !== saveOntologyState) {
      if (saveOntologyState === REQUEST_STATUS_SUCCESS) {
        messageQueue.show({
          sticky: false,
          severity: 'info',
          summary: 'Saved Ontology Definition',
          detail: "A new Ontology has been saved in ".concat(saveOntologyResponse.status, " mode.")
        });
        if (getOntologyList != null)
          getOntologyList();
        if (saveOntologyResponse.status!=="DRAFT"){//clear editor after publishing, otherwise keep it
          this.onClear();
        }
      }
      if (saveOntologyState === REQUEST_STATUS_FAIL) {
        messageQueue.show({
          sticky: false,
          severity: 'error',
          summary: 'Error during Saving',
          detail: saveOntologyError.message
        });
      }
    }
  }


  onSave(mode){
    const {_id,name} = this.state;
    const {messageQueue,ontologyList,saveOntology} = this.props;


    if ( !(name!=null) || ""===name){
      messageQueue.show( {
        sticky: false,
        severity: 'error',
        summary: 'Validation Error',
        detail:"Missing \'name\' data! Data has not been saved."
      });
      return ;
    }
    const repetition = ontologyList.find(el=>{return el.name===name});
    if ( repetition!=null && "DRAFT"!==repetition.status){
      messageQueue.show( {
        sticky: false,
        severity: 'error',
        summary: 'Validation Error',
        detail:"Each ontology acronym has to be unique! Data has not been saved."
      });
      return ;
    }
    const transferObject = {
        "_id": _id,
        "status": mode === "draft" ? "DRAFT" : "PUBLISHED",
        "name": name
      }
    ;

    saveOntology(mode,transferObject);
  }

  onClear(){
    this.setState({
      name:"",
      _id:uuidv4()
    });
  }

  onRowSelect(e) {
    this.setState({
      selectedRow: Object.assign({}, e.data)
    });
  }

  onRowEdit(){
    const {selectedRow}=this.state;
    this.setState({name:selectedRow.name,_id:"DRAFT"===selectedRow.status?selectedRow[ANNOTATION_PROPERTY_NAME__ID]:uuidv4()});
  }

  //TODO Finish when DELETE draft API is created
  onRowDelete(){
    const{messageQueue} = this.props;
    const {selectedRow}=this.state;
    messageQueue.show({
      sticky: false,
      severity: 'info',
      summary: 'Delete',
      detail:"Ontology ".concat( selectedRow.name," has been removed from SPINE knowledge base. Warning: This feature has not been yet implemented!.")
    });
    // const {columns,successAnnotationsDefinition} = this.props;
    // const tempColumns = Object.assign({},columns);
    // delete tempColumns[this.state.selectedRow['_key']];
    // successAnnotationsDefinition(tempColumns);
    this.onRowUnselect();
  }

  onRowUnselect(){
    this.setState({ selectedRow:null})
  }

  render() {
    const {ontologyList,ontologyListState,saveOntologyError,saveOntologyResponse,saveOntologyState} = this.props;
    const {selectedRow,selectedOntology,selectedStatus,name,_id}=this.state;

    return (
      <div className={"ui-g"}>
        <div className={"ui-g-12"}>
          <h2>Ontology List</h2>
          {ontologyListState===REQUEST_STATUS_SUCCESS
          && <DataTable
            ref={(el)=> this.dt = el}
            sortField="status"
            sortOrder={-1}
            value={ontologyList}
            rows={10}
            paginator={true}
            selection={selectedRow}
            onRowSelect={this.onRowSelect}
            selectionMode="single"
            onSelectionChange={()=>{}}>
            {StatusColumn(selectedStatus,(value) =>{
              this.setState({ selectedStatus: value });
              this.dt.filter(value, 'status', 'custom')
            })}
            <Column field="_id" header={"Id"} sortable={true} filter={true} filterMatchMode={"contains"}/>
            <Column field="name" header={"Name"} sortable={true} filter={true} filterMatchMode={"contains"}/>
          </DataTable>}
          <Button label={(selectedRow!=null && "DRAFT"===selectedRow.status) ? "View/Edit":"View/Duplicate"} disabled={!(selectedRow!=null)} onClick={this.onRowEdit}/>
          <Button label={"Delete"} disabled={!(selectedRow!=null && "DRAFT"===selectedRow.status)} onClick={this.onRowDelete} title={"You can delete selected question in DRAFT mode only"}/>
          <Button label={"Unselect"} disabled={!(selectedRow!=null)} onClick={this.onRowUnselect}/>
        </div>
        <div >
          <h2>Add/Edit Ontology</h2>
          <div style={{margin:"1em"}}><span style={{width: "5em",display:"inline-block"}}>Id:</span><input type={"text"} value={_id} className={"ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-disabled"} readOnly={true} style={{width:"20em"}}/></div>
          <div style={{margin:"1em"}}><span style={{width: "5em",display:"inline-block"}}>Name:</span><input className={"ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"} type={"text"} value={name} onChange={(e)=>this.setState({name:e.target.value})}/>
          <Button onClick={()=>{this.setState({name:selectedOntology.acronym})}} icon={"fa fa-arrow-left"} title={"Set"}/>
            <label>Find in NCBO:</label>
          <OntologyAutoComplete onError={()=>{}} value={selectedOntology} onValueChange={(v)=>{this.setState({selectedOntology:v})}}/>
        </div>
          <div>
          <Button label={"Save as Draft"}
                  onClick={e=>this.onSave("draft")}
                  disabled={saveOntologyState===REQUEST_STATUS_REQUESTED}
                  icon={saveOntologyState===REQUEST_STATUS_REQUESTED?"fa fa-spinner fa-spin":"fa fa-save"} />
          <Button label={"Save and Publish"}
                  onClick={e=>this.onSave("published")}
                  disabled={saveOntologyState===REQUEST_STATUS_REQUESTED}
                  icon={saveOntologyState===REQUEST_STATUS_REQUESTED?"fa fa-spinner fa-spin":"fa fa-save"} />
          <Button label={"Clear"} icon={"fa fa-trash"} onClick={this.onClear}/>
          </div>
        </div>
      </div>

    )
  }

}

export default AnnotationOntologyBuilder;

AnnotationOntologyBuilder.propTypes = {
  ontologyList:PropTypes.array.isRequired,
  ontologyListState:PropTypes.string.isRequired,
  getOntologyList:  PropTypes.func.isRequired,
  messageQueue: PropTypes.object.isRequired,
  saveOntologyState:PropTypes.string.isRequired,
  saveOntologyError:PropTypes.object.isRequired,
  saveOntologyResponse:PropTypes.object.isRequired,
  clearSaveOntology:PropTypes.func.isRequired,
  saveOntology:PropTypes.func.isRequired
};
