import React from 'react';
import PropTypes from "prop-types";
import ScatterPlot2 from "./ScatterPlot2";
import {Dialog} from "primereact/components/dialog/Dialog";
import {ContainerManualTool} from "../../../visualization/containers/ContainerManualTool";
import DynamicHistogram from "./DynamicHistogram";
import {generateVisualizationToolConfiguration} from "../../../visualization/component/annotationTool/ToolConfigurationFactory";
import BlandAltmanPlot from "./BlandAltmanPlot";

/**
 * Future feature !
 * Component for managing the state of single plot being a part of multiplot grid.
 * Version for universal implementation (for all types).
 */
class PlotPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      diagramContent :null,
      showDialog: false
    };
    ["generatePlot"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  componentDidMount() {
    const {type,data,calculateHeight,calculateWidth} = this.props;
    if (type!=null && data!=null){
      this.generatePlot();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {calculateWidth,calculateHeight,content,width,height,type,data} = this.props;
    const {diagramContent} = this.state;
    if(prevProps.width!==width && diagramContent!=null){
      this.setState({diagramContent: React.cloneElement(
          this.state.diagramContent,
          {width: calculateWidth()}
        )})
    }
    if(prevProps.height!==height && diagramContent!=null){
      this.setState({diagramContent: React.cloneElement(
          this.state.diagramContent,
          {height: calculateHeight()}
        )})
    }

    if(prevProps.type!==type || prevProps.data!==data){
      this.generatePlot();
    }
  }

  generatePlot(){
    const {type,data,calculateHeight,calculateWidth} = this.props;
    const width = calculateWidth();
    const height = calculateHeight();
    let content = null;
    if (type==="AGREEMENT") {
      content = <BlandAltmanPlot
        yLabel={"(Lesion count Group 1 - Lesion count Group 2)/ Groups lesion count mean\n[Percentage Difference]"}
        xLabel="Lesion count [mean]"
        yLabelTip={ "(Lesion count Group 1 - Lesion count Group 2)/ Groups lesion count mean\n[Percentage Difference]"}
        xLabelTip="Lesion count [mean]"
        length={(!(data != null)) ? -1 : data.length}
        outerWidth={width}
        outerHeight={height}
        data={data}
        colorCat="userId"
        onDataPointClick={(d, onDeselect) => {
          this.onDataPointClick(d, onDeselect)
        }}
        avatarSize={9}
        selected={this.state.selected}
        viewId={"BlandAltmanView"}
        prefixId="avatar"
        // statsServerState={modelParametersState}
        // modelParameters = {modelParameters}
        meanLineWidth={2}
        // percentageDifference={plotSettings.percentageDifference}
      />;
    }
    if (type==="SCATTER") {
      content =
        <ScatterPlot2 key={this.state.indexX}
                      margin={{top: 50,  right: 50, bottom: 50,  left: 50}}
                      data={data}
                      width={width}
                      height={height}
                      slrmodel={null}
                      showRegLine={false}
                      showCILine={false}
                      showPILine={false}
                      xLabel={"xlabel"}
                      renderXLabel={false}
                      onDataPointClick={() => {
                        this.setState({showDialog:true})
                      }}
                      yLabel={"yLabel"}/>;
    }
    if (type==="HISTOGRAM")
      content=<DynamicHistogram width={width-10} height={height-10} data={[{x:1,y:2},{x:2,y:3},{x:43,y:4}]} prefixId={"AA"} thresholds={[0,1,2]}/>;

    this.setState({diagramContent: content});

  }



  render() {
    const {onDelete, width,onPanelSettings,calculateWidth} = this.props;
    return <div style={{
      display: "inline",
      backgroundColor: "white",
      border: "1px solid grey",
      width: "".concat(String(width), "%")
    }}>
      <div style={{display: "inline-block", verticalAlign: "top", width: "10px"}}>
        <i className={"fa fa-ellipsis-v"} style={{...iconStyle, cursor: "pointer"}}
          onClick={() => {
            onPanelSettings()
          }}/>
        <i className={"fa fa-trash-o"} style={{...iconStyle, cursor: "pointer"}}
           onClick={() => {
             onDelete()
           }}/>
        <i className={"fa fa-arrows-alt"} style={{...iconStyle, cursor: "grab"}}
           title={"Drag and replace"}/>
      </div>
      <div style={panelStyle} ref={r => this.ref = r}>
        {this.state.diagramContent}
      </div>
      <Dialog header={"errh23"} closeOnEscape={true} resizable={true}
              visible={this.state.showDialog}
              modal={false}
              onHide={() => {
                this.setState({showDialog:false})
              }}
              contentStyle={{minHeight: '400px', minWidth: '600px'}}
              style={{minHeight: '400px', minWidth: '600px'}}>
        {this.state.showDialog &&
        <ContainerManualTool  predefinedToolConfiguration={generateVisualizationToolConfiguration("575bddd50492d394fe3e506e21134850","cfc61966aa88162e1451a66ac6002491")} />
        }
      </Dialog>
    </div>
  }
}

export default PlotPanel;


PlotPanel.defaultProps = {

};
PlotPanel.propTypes = {
  defaultConfiguration:PropTypes.array.isRequired,
  messageQueue: PropTypes.object,
  content:PropTypes.object,
  calculateWidth:PropTypes.func.isRequired,
  calculateHeight:PropTypes.func.isRequired
};

const panelStyle = {
  display: "inline-block",
  width: "calc(100% - 10px)"
};


const iconStyle = {
  marginBottom: "5px"
};
