import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {BRUSH_TOOL} from "../../../../Constants";
import {checkIfAnyPropertyIsVisible} from "./WidgetToolbar";
import {updateBrushTracingActor} from "../../../vtk/paintbrush/BrushTracingRenderer";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Dropdown} from "primereact/components/dropdown/Dropdown";


/**
 * Dumb component for displaying toolbar for Brush Tool.
 * Uses schemas from manual task state and configuration.
 * @returns {*}
 */
export const BrushToolbar = ({toolProperties, toolConfiguration, changeToolProperties}) => {

    const control = (property, type) => toolConfiguration != null
        && toolConfiguration.properties != null
        && toolConfiguration.properties[property] != null
        && toolConfiguration.properties[property][type] != null
        && toolConfiguration.properties[property][type];

    const checkIfAnyPropertyVisible = checkIfAnyPropertyIsVisible(toolConfiguration.properties);

    return (
        <React.Fragment>
            {checkIfAnyPropertyVisible &&
            <Toolbar style={{display: "inline-block"}}>
                {control('radius', 'controlVisible') &&
                <span title={"Radius of voxel's neighborhood. Radius 0 means only pointed voxel, 1 means voxel with closest neighbors, 2 voxel with 2 layers of neighbors, etc."}>
                    <label style={{marginLeft: "1em"}}>Brush radius:</label>
                    <Spinner key="brushSpinner"
                             value={toolProperties.radius}
                             min={0} max={100} step={1} size={3}
                             onChange={(e) => {
                                 changeToolProperties(BRUSH_TOOL, 'radius', e.value);
                                 updateBrushTracingActor(null,{radius:e.value});
                             }}
                    />
                    {toolProperties.radius===0?"1 voxel":`${2*toolProperties.radius+1} by ${2*toolProperties.radius+1} voxels`}
                </span>
                }
                {control('contour', 'controlVisible') &&
                <React.Fragment>
                    <label style={{marginLeft: "1em"}}>Contour:</label>
                    <Checkbox onChange={(e)=>{changeToolProperties(BRUSH_TOOL, 'contour', e.checked)}}
                              checked={toolProperties.contour}
                              disabled={!control('contour', 'controlEnabled')}
                    />

                </React.Fragment>
                }
                {control('shape', 'controlVisible') &&
                <React.Fragment>
                    <label style={{marginLeft: "1em"}}>Shape:</label>
                    <Dropdown
                      options={[{value:"square",label:"square"},{value:"circle",label:"circle"},{value:"cityblock",label:"City block"}]}
                      value={toolProperties.shape}
                      onChange={(e)=>{
                          changeToolProperties(BRUSH_TOOL, 'shape', e.value);
                          updateBrushTracingActor(null,{shape:e.value});
                      }} />
                </React.Fragment>
                }
            </Toolbar>}
        </React.Fragment>
    );
};

BrushToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    toolConfiguration: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func.isRequired
};
