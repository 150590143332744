import {connect} from "react-redux";
import MRIBrowserPanel from "../../expDesign/component/MRIBrowserPanel";
import {
    changeOrientation, clearViewersState, displayCase, handleKeySlicing, initializeViewersInScatterPlot,
    updateSingleViewer,
    updateViewerProperty
} from "../action/ViewerAction";
import ManualTool from "../component/annotationTool/ManualTool";
import {clearManualToolStateAC, initializePredefinedConfiguration} from "../action/ManualToolAction";

/**
 *
 * @param state
 * @param ownProps - property
 * @returns {{images, overlays, imageOptions: *|Array, viewersState: *|Array}}
 */
const mapStateToProps = (state,ownProps) => {
    return {
        images:state.visu.images.images,
        overlays:state.visu.images.overlays,
        viewersState: state.visu.viewers.viewersState,
        manualToolState: state.visu.manualTool.manualToolState,
        manualToolConfiguration: state.visu.manualTool.manualToolConfiguration
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        clearAnnotatorState:()=>{
            dispatch(clearViewersState())
        },
        initializeViewers:(imageId)=>{
            dispatch(initializeViewersInScatterPlot(imageId))
        },
        updateViewer:(viewer,index)=>{
            dispatch(updateSingleViewer(viewer,index))
        },
        updateViewerProperty:(index,property,value)=>{
            dispatch(updateViewerProperty(index,property,value))
        },
        changeOrientation:(index,value)=>{
            dispatch(changeOrientation(index,value))
        },
        displayCase:(imageId,overlayId)=>{
            dispatch(displayCase(imageId,overlayId))
        },
        handleKeySlicing: (viewerId, event) => {
            dispatch(handleKeySlicing(viewerId, event))
        },
        clearViewersState: () => {
            dispatch(clearViewersState())
        },
        initializePredefinedConfiguration:(conf)=>{
            dispatch(initializePredefinedConfiguration(conf))
        },
        clearManualToolState: () => {
            dispatch(clearManualToolStateAC())
        },
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerViewer = containerCreator(MRIBrowserPanel);

