import {GROWL_MESSAGING_SETUP} from "./actionType";

/**
 * An Action setting up the growl component.
 * @param referenceToGrowl -  React reference to Growl object (PrimeReact)
 * @returns {{type, model: *}}
 */

export const messageQueueSetup = (referenceToGrowl) => ({
    type: GROWL_MESSAGING_SETUP,
    referenceToGrowl
});