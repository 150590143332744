import axios from 'axios';

import {LOGIN_FAIL, LOGIN_SUCCESS, REGISTER_REQUEST, FORGOT_PASSWORD_INITIAL_REQUEST} from './actionType';
import {HOST_URL, COOKIE_TOKEN_NAME} from "../../../Constants";
import {Cookies} from 'react-cookie-consent';

//LOGIN PROCESS ***************************************************************
const PATH_TO_LOGIN = HOST_URL+'/api/user/login';
const PATH_TO_USER_PARAMETERS = HOST_URL+'/api/user/parameters';
//FIXME Below is unused
// function requestLogin(creds) {
//     console.log('Creds inside request login', creds)
//     return {
//         type: LOGIN_REQUEST,
//         isFetching: true,
//         isAuthenticated: false,
//         credentials: creds
//     }
// }

function receiveLogin(creds) {
    return {
        type: LOGIN_SUCCESS,
        creds
    }
}

function errorLogin(message) {
    return {
        type: LOGIN_FAIL,
        message
    }
}
// FIXME Below is unused
// export const loginUser = creds => {
//     return {
//         type: 'LOGIN_REQUEST',
//         creds
//     }
// }


/*
 * Main action:
 * - dispatch the login request to the reducer
 * - get the token and credentials from the db
 * - dispatch the success and token to the reducer
 * OR
 * - dispatch the fail and error to the reducer
 *
 * @param {Object} creds - email and password
 * @return {Object} type, creds or err
 *
 */
export function login(creds, onSuccess, onError) {
    // console.log('Executing login. Creds:', creds);
    return dispatch => {
        console.log('Before post by axios');
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.post(PATH_TO_LOGIN, {email: creds.email, password: creds.password, recaptchaToken: creds.recaptchaToken})
            .then(response => {
                    // console.log('login res:', response);
                    // Get the token and it to the credentials
                    creds.token_bearer = response.headers.authorization;
                    // Get the roles
                    let roles = response.data.roles;
                    // Check and set the active role as contributor
                    if (roles.indexOf('contributor') > -1) {
                        creds.roles = roles;
                        creds.role_active = 'contributor';
                        creds.email = response.data.email;
                        creds.name = response.data.name;
                        creds.lastname = response.data.lastname;
                        creds.nickname = response.data.nickname;
                        creds.eduLevel = response.data.eduLevel;
                        creds.experience = response.data.experience;
                        creds.profession = response.data.profession;
                        creds.specialty = response.data.specialty;
                        creds.id = response.data.id;
                        dispatch(receiveLogin(creds));
                        onSuccess();
                    } else {
                        const errorMessage = 'User does not have contributor role.';
                        onError(errorMessage);
                        dispatch(errorLogin(errorMessage));
                    }
                }
            )
            .catch(err => {
                    console.log(err);
                    onError(err.response.data.message);
                    dispatch(errorLogin(err))
                }
            )
    }
}

/**
 * Populate auth information (user profile, roles and token) in the store
 * @param {*} onSuccess Callback if getting and populating the information was possible
 * @param {*} onError Callback if getting and populating the information was not possible
 */
export function getProfileInformationAndRoles(onSuccess, onError){
    return dispatch => {
        const tokenCookie = Cookies.get(COOKIE_TOKEN_NAME);
        if(tokenCookie != null && tokenCookie != ''){
            const token_bearer = `Bearer ${tokenCookie}`;
            const config = {
                headers: {
                    'Authorization': `bearer${token_bearer}`,
                    'Content-Type': "application/json"
                }
            };
            axios.get(PATH_TO_USER_PARAMETERS, config)
                .then(response => {
                        if (response.status !== 200){
                            onError(response.data);
                        }
                        // Get the token and it to the credentials
                        const creds = {};
                        creds.token_bearer = token_bearer;
                        // Get the roles
                        const roles = response.data.roles;
                        // Check and set the active role as contributor
                        if (roles.indexOf('contributor') > -1) {
                            creds.roles = roles;
                            creds.role_active = 'contributor';
                            creds.email = response.data.email;
                            creds.name = response.data.name;
                            creds.lastname = response.data.lastname;
                            creds.nickname = response.data.nickname;
                            creds.eduLevel = response.data.eduLevel;
                            creds.experience = response.data.experience;
                            creds.profession = response.data.profession;
                            creds.specialty = response.data.specialty;
                            creds.id = response.data.id;
                            dispatch(receiveLogin(creds));
                            onSuccess();
                        } else {
                            const errorMessage = 'User does not have contributor role.';
                            onError(errorMessage);
                            dispatch(errorLogin(errorMessage));
                        }
                    }
                )
                .catch(err => {
                        onError(err);
                        dispatch(errorLogin(err))
                    }
                )
        } else {
            const errorMessage = 'User needs to log in';
            onError(errorMessage);
        }
    }
}



/**
 * Request to redirect to register
 * @returns {type} just signal to change view, no data
 */
export function register() {
    console.log('Executing LoginAction::register');

        return {
            type: REGISTER_REQUEST
};

}

/**
 * Request to redirect to forgot password
 * @returns {type} just signal to change view, no data
 */
export function forgotPassword() {
    console.log('Executing LoginAction::forgotPassword');
    return {
        type: FORGOT_PASSWORD_INITIAL_REQUEST
    };
}