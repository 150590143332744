/**
 * Helper function for processing data to format readable to browser.
 * @param buffer
 * @returns {string}
 */
export function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => binary += String.fromCharCode(b));

    return window.btoa(binary);
}


/**
 *  Helper function for processing PNG from R. PNGs (from R) come in hex; need to convert to base 64.
 *  Example after DataCamp code, see:
 *  @link  https://github.com/datacamp/datacamp-light/blob/master/src/helpers/plot.ts
 *  @param str
 *  @returns {string}
 */
const hexToBase64 = (str) => {
    // Generate hex array from hex string
    const hexArray = str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ");

    const CHUNK_SIZE = 0x8000; // Arbitrary number
    let index = 0;
    const length = hexArray.length;
    let result = "";
    let slice;
    // Passing too many arguments into fromCharCode gives `Maximum call stack size exceeded`.
    // We divide the hex array into pieces and pass these.
    while (index < length) {
        slice = hexArray.slice(index, index + CHUNK_SIZE);
        result += String.fromCharCode.apply(null, slice);
        index += CHUNK_SIZE;
    }
    return window.btoa(result);
};

const createImage = url =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    });

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
        0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
      const result = canvas.toDataURL('image/png');
      // console.log(result);
      return result;
    // As a blob
    // return new Promise(resolve => {
        // canvas.toBlob(file => {
        //     resolve(URL.createObjectURL(file))
        // }, 'image/jpeg')
    // })
}


export function convertDataURIToBinaryFetch(dataURI) {
    return fetch(dataURI)
        .then((res) => res.blob());
}