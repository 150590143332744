import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  getSelectedModelParameters,
  getSelectedPlotData
} from "../selectors/SAMSelectors";
import BACalculator from "../component/BACalculator";



const mapStateToProps = (state) => {
  return {
    modelParameters: getSelectedModelParameters(state),
    plotData: getSelectedPlotData(state)
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
  }
};

export const ContainerBACalculator = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(BACalculator));