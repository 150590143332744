
export const GRAPHIC_LAYER_TYPE = {
  AVATAR: 'avatar'
};

const commonSymbolProps = {
  type: "simple-marker",
  size: 25,
  color: {r: 0, g: 0, b: 0, a: 0.7}
};

const colorRim = (color) => {
  return {
    ...commonSymbolProps,
    outline: {
      width: 2,
      color: color
    }
  }
};


const labelClass = {
  symbol: {
    type: "text",
    color: "orange",
    font: {
      size: 12,
      weight: "bold"
    }
  },
  labelPlacement: "center-center",
  labelExpressionInfo: {
    expression: "$feature.Status"
  }
};

export const AVATAR_LAYER_DEFINITION = {
  objectIdField: "__OBJECTID",
  fields: [
    {
      name: "__OBJECTID", // default id generated by ArcGIS
      type: "oid"
    },
    {
      name: "Site_ID",
      type: "string"
    },
    {
      name: "Status",
      type: "string"
    },
    {
      name: "StatusColor",
      type: "string"
    }
  ],
  geometryType: "point",
  labelingInfo: [labelClass],
  renderer: {
    type: "unique-value",  // autocasts as new Unique value Renderer()
    field: "StatusColor",
    uniqueValueInfos: [
      {
        value: '#FF0000', // code for red
        symbol: colorRim("#FF0000"),
        label: "0"
      },
      {
        value: '#00FF00', // code for green
        symbol: colorRim("#00FF00"),
        label: "1"
      },
      {
        value: '#0000FF', // code for green
        symbol: colorRim("#0000FF"),
        label: "2"
      }
    ],
    defaultSymbol: {
      type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
      size: 25,
      color: {r: 0, g: 0, b: 0, a: 0.7},
      outline: {
        width: 2,
        color: "red"
      }
    }
  },
  source: []
};