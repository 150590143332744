import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  getINOSResults,
  getTabletGameExperimentResults
} from "../action/TabletGameExperimentAction";
import {
  clearInteractivePlotState, initializeAvatarScatterPlot, refreshDataAC
} from "../../visualization/action/InteractivePlotAction";
import {clearDataset} from "../action/StatisticalModelAction";
import MultiHistogramPanel from "../component/d3components/MultiHistogramPanel";

const mapStateToProps = (state, ownProps) => {
  return {
    results:state.expDesign.tabletGame.results,
    resultsState: state.expDesign.tabletGame.resultsState
  }
};
/**
 *
 * @param dispatch
 * @returns {{getTabletGameExperimentResults: function(*=), getImage: function(*=), clearOverlayImage: function(*=)}}
 */
const mapDispatchToProps = dispatch => {
  return {
    getTabletGameExperimentResults: (tabletExperimentId) => {
      dispatch(initializeAvatarScatterPlot(tabletExperimentId))
    },
    clearInteractivePlotState:()=>{
      dispatch(clearInteractivePlotState())
    },
    clearDataset:()=>{
      dispatch(clearDataset())
    },
    refreshData:(scientificEventId)=>{
      dispatch(refreshDataAC(scientificEventId))
    }
  }
};


export const ContainerMultiHistogramPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(MultiHistogramPanel));