import React from "react";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";

const MIDDLE = "middle";  //id of left vie
const LEFT = "left";
const LOWER_LEFT = "lowerLeft";
const UPPER_LEFT = "upperLeft";
const LOWER_RIGHT = "lowerRight";
const UPPER_RIGHT = "upperRight";


/**
 * Layout component for displaying viewers panel with Single element.
 *
 */
class OneMainFourReferenceLayout extends React.Component {
    static getLabel(){
        return "One Main viewer with four half-size reference viewers";
    }

    static getViewersPortKeys(){
        return [MIDDLE,LOWER_RIGHT,UPPER_RIGHT,LOWER_LEFT,UPPER_LEFT];
    }

    constructor(props) {
        super(props);
        this.state = {
            widthMain: "80vh",
            heightMain: "80vh"
        };
        ["calcLayout"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }
    componentDidMount() {
        console.log("Row Layout");
    }
    componentDidUpdate(prevProps, prevState) {
    }
    componentWillUnmount() {
    }
    /** Calculates the size of viewers.
     *  Still it is not final version,
     **/
    calcLayout() {
        if (window.innerWidth < window.innerHeight) {
            this.setState({widthMain: "80vw", heightMain: "80vw"});
            console.log("Portait mode");
        }
        if (window.innerWidth > window.innerHeight) {
            this.setState({widthMain: "80vh", heightMain: "80vh"});
            console.log("Landscape mode");
        }
    }

    render() {
        const {toolComponentProps, toolComponentState} = this.props;
        const viewersState = toolComponentProps.viewersState;

        const middle = (viewersState != null && viewersState.hasOwnProperty(MIDDLE))
            ? BrowserFactory.build(MIDDLE, toolComponentProps, toolComponentState)
            : <div style={{padding: "0.1em",width:"100%",height:"100%"}} title="Main viewer."/>;

        const upperLeft = (viewersState != null && viewersState.hasOwnProperty(UPPER_LEFT))
            ? BrowserFactory.build(UPPER_LEFT, toolComponentProps, toolComponentState)
            : <div style={{padding: "0.1em",width:"100%",height:"100%"}} title="Upper-left reference viewer."/>;

        const lowerLeft = (viewersState != null && viewersState.hasOwnProperty(LOWER_LEFT))
            ? BrowserFactory.build(LOWER_LEFT, toolComponentProps, toolComponentState)
            : <div style={{padding: "0.1em",width:"100%",height:"100%"}} title="Lower-left reference viewer."/>;

        const lowerRight = (viewersState != null && viewersState.hasOwnProperty(LOWER_RIGHT))
            ? BrowserFactory.build(LOWER_RIGHT, toolComponentProps, toolComponentState)
            : <div style={{padding: "0.1em",width:"100%",height:"100%"}} title="Lower-right reference viewer."/>;

        const upperRight = (viewersState != null && viewersState.hasOwnProperty(UPPER_RIGHT))
            ? BrowserFactory.build(UPPER_RIGHT, toolComponentProps, toolComponentState)
            : <div style={{padding: "0.1em",width:"100%",height:"100%"}} title="Upper-right reference viewer."/>;


        return (
            <div className="ui-g-12">
                <div className="viewerContainerFull">
                    <div className="viewerContainerSidePanel">
                        <div className="viewerContainerSide">
                            {upperLeft}
                        </div>
                        <div className="viewerContainerSide">
                            {lowerLeft}
                        </div>
                    </div>
                    <div className="viewerContainerMainPanel">
                        <div style={{padding: "0.1em", height: this.state.heightMain, width: this.state.widthMain}}>
                            {middle}
                        </div>
                    </div>
                    <div className="viewerContainerSidePanel">
                        <div className="viewerContainerSide">
                            {upperRight}
                        </div>
                        <div className="viewerContainerSide">
                            {lowerRight}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default OneMainFourReferenceLayout;

OneMainFourReferenceLayout.defaultProps = {};

OneMainFourReferenceLayout.propTypes = {};


