import {createSelector} from 'reselect';
import {getViewerAssignedToGraphTool} from "./ViewersSelectors";


export const  getImagesState = state => state.visu.images.images;


/**
 * Get viewer bounded with graph tool.
 *
 */
export const getImageAssignedToGraphTool =  createSelector(  [getViewerAssignedToGraphTool,getImagesState],
  (viewer,imagesState) => {
    let image = null;
    try {
      if (viewer != null && viewer.hasOwnProperty("imageId")) {
        image = imagesState[viewer["imageId"]];
      }
    } catch (err) {
    }
    return image;
  });