import React from 'react';
import OptionallyDisplayed from './OptionallyDisplayed.js';
import PropTypes from 'prop-types';
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {withTranslation} from 'react-i18next';
import DisclaimerContentScrollPanel from "./DisclaimerContentScrollPanel";

/**
 * Component for displaying disclaimer checkbox.
 *
 * Checkbox is disabled and can be checked only by opening dialog window, scrolling down and approving content with
 * Approve button.
 */
class DisclaimerWithValidation extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      dialogVisible:false,
      isEndReached:false,
      isApproved:false,
      language:"fr"
    };
    this.shouldDisplayError = this.shouldDisplayError.bind(this);
    this.isBottom = this.isBottom.bind(this);
  }

  isBottom(e) {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1)
      this.setState({isEndReached:true});
  }

  shouldDisplayError() {
    return this.props.showError && this.props.errorText !== "";
  }

  render() {
    const {errorText,disclaimerContent,onFieldChanged,t} = this.props;
    const {isApproved} = this.state;
    let uiError =  this.shouldDisplayError()?"ui-state-error":"";


    const languageLinks = Object.keys(disclaimerContent).map(lKey=>{
      return <a onClick={()=>{this.setState({language:lKey})}}
                style={{
                  marginLeft:"1em",
                  textDecoration:this.state.language===lKey?"underline":"none"}}>{disclaimerContent[lKey]['label']}</a>
    });

    return (
      <React.Fragment>
        <div>
        <Checkbox checked={isApproved}
                  onMouseDown={()=>this.setState({dialogVisible:true})}
                  onChange={()=>{}}
                  // onChange={(event)=>{
                  //   this.setState({isApproved:event.checked});
                  //   onFieldChanged({target:{id:"disclaimer"}, checked:event.checked})
                  // }}
          // Please do not uncomment above, if this should be disabled and changed solely by button in terms dialog!
                  disabled={true}
                  style={this.props.style}
                  name={this.props.name}
                  className={uiError}
                  id={"disclaimer"}
        />
          <label>{t("registerForm.property.approveAll.label")}<a onClick={()=>this.setState({dialogVisible:true})} title={"You need to open, read and approve it"}>{t("registerForm.property.termsAndConditions.label")}</a></label>
        </div>

        <Dialog id="disclaimerContent"
                header={'Terms and conditions'}
                style={{width:'800px',overflowY:'hidden',maxHeight:"600px"}}
                visible={this.state.dialogVisible}
                onHide={()=>{this.setState({dialogVisible:false})}}

                resizable={false}>
          <DisclaimerContentScrollPanel
            disclaimerContent={disclaimerContent}
            onScroll={this.isBottom}
            setEndReached={(value)=>this.setState({isEndReached:value})}
            language={this.state.language}/>
          <Button onClick={()=>
          {
            this.setState({dialogVisible:false,isApproved:true});
            onFieldChanged({target:{id:"disclaimer"}, checked:true}) //generate pseudo-event
          }}
                  label={"Approve"}
                  disabled={!this.state.isEndReached}/>
          <Button onClick={()=>{
            this.setState({dialogVisible:false,isApproved:false});
            onFieldChanged({target:{id:"disclaimer"}, checked:false}) //generate pseudo-event
          }}
                  label={"Reject"}/>
          {languageLinks}

        </Dialog>
        <OptionallyDisplayed display={this.shouldDisplayError()}>
          <div className="validation-error">
            <span className="text">{errorText}</span>
          </div>
        </OptionallyDisplayed>
      </React.Fragment>
    );
  }
}
export default withTranslation()(DisclaimerWithValidation);

DisclaimerWithValidation.propTypes = {
  name:PropTypes.string.isRequired,
  value:PropTypes.bool.isRequired,
  label:PropTypes.object.isRequired,
  showError: PropTypes.bool.isRequired,
  style:PropTypes.object,
  filter:PropTypes.bool,
  errorText:PropTypes.string,
  onFieldChanged:PropTypes.func.isRequired
};
