import React from 'react'
import {Route, withRouter} from 'react-router'
import {ContainerProjectList} from "./containers/ContainerProjectList";
import {ContainerProjectDetails} from "./containers/ContainerProject";
import ContainerExpDesign from "../expDesign/containers/ContainerExpDesign";
import {ContainerSAMPanel} from "../analysis/containers/ContainerSAMPanel";
import {Switch} from "react-router-dom";
import {ContainerSpineQExperimentMonitor} from "../spineqmonitor/containers/ContainerSpineQExperimentMonitor";


class ProjectRouter extends React.Component {

    render() {
        const {match} = this.props;
        console.log("project state", this.state);
        return (
            <React.Fragment>
                <Route exact path={`${match.path}/`} component={ContainerProjectList}/>
                <Route exact path={`${match.path}/:projectid`} component={ContainerProjectDetails}/>
                <Route exact path={`${match.path}/:projectid/expdesign`} component={ContainerExpDesign}/>
                <Route exact path={`${match.path}/:projectid/experiment/:experimentid`} component={ContainerExpDesign}/>
                <Route exact path={`${match.path}/:projectId/experiment/:id/analysis`} component={ContainerSAMPanel}/>
                <Route exact path={`${match.path}/:projectId/experiment/:id/analysis/snapshot/:snapshot`} component={ContainerSAMPanel}/>
                <Route exact path={`${match.path}/:projectId/experiment/:experimentId/spineqmonitor`} component={ContainerSpineQExperimentMonitor}/>
            </React.Fragment>

        )
    }

}

export default withRouter(ProjectRouter)