import React from 'react'
import {Route, withRouter} from 'react-router'
import {ContainerSkills} from "./containers/ContainerSkills";
import {ContainerPlaySkill} from "./containers/ContainerPlaySkill";
import {ContainerPlaySkillMap} from "./containers/ContainerPlaySkillMap";


class SkillRouter extends React.Component {

    render() {
        const {match} = this.props;
        console.log("project state", this.state);
        return (
            <React.Fragment>
                <Route exact path={`${match.path}/`} component={ContainerSkills}/>
                <Route exact path={`${match.path}/playskill/map/:id/:skillId`} component={ContainerPlaySkillMap} />
                <Route exact path={`${match.path}/playskill/:id`} component={ContainerPlaySkill} />
            </React.Fragment>

        )
    }

}

export default withRouter(SkillRouter)