import {connect} from "react-redux";
import TaskPanelContent from "../component/TaskPanelContent";
import {getAllTasksList} from "../../expDesign/action/TaskAction";
import {getAllToolsList} from "../../expDesign/action/ToolAction";



const mapStateToProps = (state,ownProps) => {
  return {
    tasks: state.expDesign.tasks.tasks,
    taskListState: state.expDesign.tasks.taskListState,
    tools: state.expDesign.tools.tools,
    toolListState: state.expDesign.tools.toolListState,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getTasks: () => {
      dispatch(getAllTasksList())
    },
    getTools:()=>{
      dispatch(getAllToolsList())
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerTaskPanelContent = containerCreator(TaskPanelContent);