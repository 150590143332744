import {
    UPDATE_SCENE_ACTIVE_COLOR,
    UPDATE_SCENE_ACTIVE_LAYER, UPDATE_SCENE_INTERACTIVE_LAYER,
    UPDATE_SCENE_LAYER_COLOR,
    UPDATE_SCENES
} from "../action/actionType";


/**
 *
 * @param state
 * @param action
 * @return {{scenes: {}}|({} & {scenes: {}})}
 */
export const sceneReducer = (state = {
    scenes:{}
}, action) => {
    switch (action.type) {
        case UPDATE_SCENES:{
            let result = {};
            Object.keys(action.scenes).forEach((el)=>{
                result[el] = Object.assign({},action.scenes[el]);
                if (Array.isArray(action.scenes[el]['painters']))
                result[el]['painters'] = action.scenes[el]['painters'].slice();
                if (Array.isArray(action.scenes[el]['colorMaps']))
                    result[el]['colorMaps'] = action.scenes[el]['colorMaps'].slice();
                if (Array.isArray(action.scenes[el]['luts']))
                    result[el]['luts'] = action.scenes[el]['luts'].slice();
                if (Array.isArray(action.scenes[el]['lutDescriptions']))
                    result[el]['lutDescriptions'] = action.scenes[el]['lutDescriptions'].slice();
                if (Array.isArray(action.scenes[el]['masks']))
                    result[el]['masks'] = action.scenes[el]['masks'].slice();
                if (Array.isArray(action.scenes[el]['polydata']))
                    result[el]['polydata'] = action.scenes[el]['polydata'].slice();
                if (Array.isArray(action.scenes[el]['activeColor']))
                    result[el]['activeColor'] = action.scenes[el]['activeColor'].slice();
            });
            return Object.assign({}, state, {
                scenes:result
            });
        }
        case  UPDATE_SCENE_ACTIVE_LAYER:{
            const temp=Object.assign({},state);
            temp['scenes']=Object.assign({},state['scenes']);
            temp['scenes'][action.sceneKey] = Object.assign({},state['scenes'][action.sceneKey]); //needs to be copied, so React can detect change
            temp['scenes'][action.sceneKey]['activeLayer']=action.layerIndex;
            return Object.assign({},temp);
        }
        case  UPDATE_SCENE_INTERACTIVE_LAYER:{
            const temp=Object.assign({},state);
            temp['scenes']=Object.assign({},state['scenes']);
            temp['scenes'][action.sceneKey] = Object.assign({},state['scenes'][action.sceneKey]); //needs to be copied, so React can detect change
            temp['scenes'][action.sceneKey]['interactiveLayer']=action.layerIndex;
            return Object.assign({},temp);
        }
        case  UPDATE_SCENE_ACTIVE_COLOR:{
            const temp=Object.assign({},state);
            temp['scenes']=Object.assign({},state['scenes']);
            temp['scenes'][action.sceneKey] = Object.assign({},state['scenes'][action.sceneKey]); //needs to be copied, so React can detect change
            temp['scenes'][action.sceneKey]['activeColor']=[...temp['scenes'][action.sceneKey]['activeColor']];
            temp['scenes'][action.sceneKey]['activeColor'][action.layerIndex] = action.value;
            return Object.assign({},temp);
        }
        case  UPDATE_SCENE_LAYER_COLOR:{
            const temp=Object.assign({},state);
            temp['scenes']=Object.assign({},state['scenes']);
            temp['scenes'][action.sceneKey] = Object.assign({},state['scenes'][action.sceneKey]); //needs to be copied, so React can detect change
            temp['scenes'][action.sceneKey]['colorMaps']=[...temp['scenes'][action.sceneKey]['colorMaps']];
            temp['scenes'][action.sceneKey]['colorMaps'][action.layerIndex] = action.value;
            return Object.assign({},temp);
        }
        default:
            return state;
    }
};

