import React from 'react'
import {ContainerProjectTable} from "../../project/containers/ContainerProjectList";
import {ContainerActivities} from "../containers/ContainerActivities";
import {ContainerSkills} from "../../skills/containers/ContainerSkills";
import {ContainerEvents} from "../containers/ContainerEvents";
import {ContainerRewards} from "../containers/ContainerRewards";
import {ContainerProjectDetails} from "../../project/containers/ContainerProject";
import {Route, withRouter} from 'react-router'
import TaskDashboard from "./TaskDashboard";
import Authorization from "../../authentication/component/Authorization";
import {USER} from "../../../Constants";
import {ContainerResults} from "../containers/ContainerResults";

// Added prefetching script for Manualtool
const manualToolBrowserPromise = import("../../vtk/Viewer2D"); //webpack chunk name
const Viewer2D = React.lazy(() => manualToolBrowserPromise); //dynamic import


 class Dashboard extends React.Component {

    render() {
        const {match} = this.props;
        return (
            <div  style={{margin: '16px'}}>
                <Route exact path={`${match.path}/taskDashboard/:taskid`} component={TaskDashboard}/>
                <div id="myActivities" style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <div style={{width: '50%', display: 'inline-block'}}>
                        <ContainerActivities/>
                    </div>
                    <div style={{width: '50%', display: 'inline-block'}}>
                            <ContainerSkills/>
                    </div>
                </div>
                <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <div style={{width: '50%', display: 'inline-block'}}>
                        <ContainerProjectTable/>
                    </div>
                    <div style={{width: '50%', display: 'inline-block'}}>
                        <ContainerEvents/>
                    </div>
                </div>

              <Authorization roles={[USER.ROLES.ADMIN]}>

                <ContainerResults/>
              </Authorization>
                {/*<div style={{width: '100%', height: 'auto', marginTop: '20px'}}>*/}
                    {/*<div style={{width: '50%', display: 'inline-block'}}>*/}
                        {/*<ContainerRewards/>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        )
    }
}
export default withRouter(Dashboard)