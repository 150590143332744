import {connect} from "react-redux";
import React from "react";
import {clearSaveQuestion, getQuestionListActionCreator, saveQuestion} from "../action/QuestionAction";
import AnnotationQuestionBuilder from "../component/annotationTool/AnnotationQuestionBuilder";
import {getOntologyListActionCreator} from "../action/OntologyAction";


const mapStateToProps = (state,ownProps) => {
  return {
    messageQueue: state.messaging.msgQueue,
    questionList:state.visu.questions.questionList,
    questionListState:state.visu.questions.questionListState,
    ontologyList: state.visu.ontologies.ontologyList,
    ontologyListState: state.visu.ontologies.ontologyListState,
    saveQuestionState:state.visu.saveQuestions.saveQuestionState,
    saveQuestionError:state.visu.saveQuestions.saveQuestionError,
    saveQuestionResponse:state.visu.saveQuestions.saveQuestionResponse,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

    getQuestionsList:()=>{
      dispatch(getQuestionListActionCreator())
    },
    getOntologyList:()=>{
      dispatch(getOntologyListActionCreator())
    },
    saveQuestion:(mode,payload)=>{
      dispatch(saveQuestion(mode,payload))
    },
    clearSaveQuestion:()=>{
      dispatch(clearSaveQuestion())
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);


export const ContainerAnnotationQuestionBuilder = containerCreator(AnnotationQuestionBuilder);