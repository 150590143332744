import React from 'react'
import {Route, withRouter} from 'react-router'
import {ContainerWorkflowManager} from "./container/ContainerWorkflowManager";
import {ContainerWorkflowEditor} from "./container/ContainerWorkflowEditor";



class WorkflowManagerRouter extends React.Component {

    render() {
        const {match} = this.props;
        return (
            <React.Fragment>
                {/*Below old rules used when we wanted to have intermediate view with list of workflows*/}
                {/*<Route exact path={`${match.path}/`} component={ContainerWorkflowManager}/>*/}
                {/*<Route exact path={`${match.path}/:id`} component={ContainerWorkflowEditor} />*/}
                   <Route exact path={`${match.path}`} component={ContainerWorkflowEditor} />
            </React.Fragment>

        )
    }

}

export default withRouter(WorkflowManagerRouter)
