import React from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {CUSTOM_ELEMENT} from "./AnnotationCell";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {HierarchicalDropdown} from "./HierarchicalDropdown";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {
    ANNOTATION_PROPERTY_NAME__ID,
    ANNOTATION_PROPERTY_NAME__ROI,
    ANNOTATION_PROPERTY_NAME__RULER,
    ANNOTATION_TABLE_TOOL,
    MAIN_TOOL, PIN_TOOL, REQUEST_STATUS_FAIL,
    REQUEST_STATUS_SUCCESS, RULER_PROPERTY_NAME__ANNOTATION_MODE, RULER_PROPERTY_NAME__ANNOTATION_SOURCE, RULER_PROPERTY_NAME__END, RULER_PROPERTY_NAME__START,
    RULER_PROPERTY_VALUES__ANNOTATION_SOURCE,
    RULER_TOOL
} from "../../../../Constants";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {getNestedProp} from "../../../helpers/expressions";
//import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";

/**
 * "Dumb" component representing Annotation Table and Active Annotation component.
 * State of table parameters is kept inside component.
 * @param data - data array
 * @param readData - data array with read only data (possibility to be from different inputs)
 * @param readOnly - if the table is read only (only to display info)
 * @param columns - columns
 * @param columnsState - columns state
 * @param viewCallback - callback to handle click on annotation button
 * @param rmCallback - callback to handle click on remove (trash) button
 * @param updateAnnotationData - callback to update data after change in row
 * @param setSubAnnotation - callback for setting subannotations
 * @param activeRowOnly - flag indicating whether only active row should be visible (true- active row, false - active row + full table)
 * @param activeROI - id of active ROI
 */

    export class AnnotationTable extends React.Component {

    constructor(props) {
        super(props);
        ["handleSubmit","requiredValidator","requiredRulerValidator"].forEach(name => {
                this[name] = this[name].bind(this);
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dataState,messageQueue} =this.props;

        if (prevProps.dataState !== dataState && dataState===REQUEST_STATUS_FAIL){
            messageQueue.show({
                sticky: true,
                severity: 'error',
                summary: 'Error',
                detail:"Error loading annotation data"
            })

        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);

        for (let name of data.keys()) {
        const input = form.elements[name];
        //     const parserName = input.dataset.parse;
        //
        //     if (parserName) {
        //         const parser = inputParsers[parserName];
        //         const parsedValue = parser(data.get(name));
        //         data.set(name, parsedValue);
        //     }
        }
    }
    requiredValidator(props) {
        let value = props.rowData[props.field];

        if (value!=null){
            if (value instanceof String)
                return value.length > 0;
            return  true;
        }
        else return false;
    }
    requiredRulerValidator(props) {
        const {manualToolState} = this.props;
        let value = props.rowData[props.field];
        if (getNestedProp([MAIN_TOOL,"leftButtonMode"],manualToolState) === LEFT_BUTTON_MODE.NONE && !(value!=null))
            return true;
        return value !=null && !isNaN(value) && value!=="";
    }

    render() {
        const {columns, columnsState, data, dataState, readData, readOnly, viewCallback, updateAnnotationData, rmCallback, setSubAnnotation, activeRowOnly, activeROI,updateManualSubTask,
        manualToolConfiguration,manualToolState,updateManualToolProperty,updateManualToolState,setActiveAnnotation} = this.props;

        const idPrefix = (manualToolState[PIN_TOOL]!=null&&manualToolState[PIN_TOOL]['idPrefixHack'])?manualToolState[PIN_TOOL]['idPrefixHack']:"";
        let baseData = dataState!==REQUEST_STATUS_FAIL?data.filter(el=>{return el[ANNOTATION_PROPERTY_NAME__ID].substring(0,idPrefix.length)===idPrefix}):[];
        if(readOnly && readData){
            baseData = readData;
        }

        /** Universal method for changing state of data. It can be used by all editors.
         * Since data can be sorted or filtered it performs looking for data using index id.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @param value - value decoded by "native" editor (listening to change in radio, dropdown and other components)
         */
        const onEditorValueChange = (props, value) => {
            let updatedData = data.slice(0);//Important!!!
            let index = updatedData.findIndex((el) => el[ANNOTATION_PROPERTY_NAME__ID] === props.rowData[ANNOTATION_PROPERTY_NAME__ID]);
            updatedData[index][props.field] = value;
            updateAnnotationData(updatedData);
        };

        /** Universal method for changing state of data attached to the ruler.
         * Since Column component cannot work with objects as values, the additional data has to be attached as an extra ("hidden")
         * column, which is not displayed in table.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @param value - value decoded by "native" editor (listening to change in radio, dropdown and other components)
         */
        const onRulerValueChange = (props, value) => {
            let updatedData = data.slice(0);//Important!!!
            let index = updatedData.findIndex((el) => el[ANNOTATION_PROPERTY_NAME__ID] === props.rowData[ANNOTATION_PROPERTY_NAME__ID]);
            updatedData[index][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field] = Object.assign({},value);
            updateAnnotationData(updatedData);
        };

        /** Bring back column value to initial state, so the validation is not checking it.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         */
        const onRulerClose = (props) => {
            console.log("onRulerClose- leftButtonMode: ", manualToolState[MAIN_TOOL]['leftButtonMode']);

            let updatedData = data.slice(0);//Important!!!
            let index = updatedData.findIndex((el) => el[ANNOTATION_PROPERTY_NAME__ID] === props.rowData[ANNOTATION_PROPERTY_NAME__ID]);
            delete  updatedData[index][props.field]; // remove column
            delete  updatedData[index][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field];
            updateAnnotationData(updatedData);
            updateManualToolProperty(MAIN_TOOL, 'leftButtonMode', LEFT_BUTTON_MODE.NONE);
        };

        /** Editor for InputText columns.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @returns {*} - component in JSX code
         */
        const inputTextEditor = (props) => {
            return <InputText type="text"
                              value={props.rowData[props.field]}
                              onChange={(e) => onEditorValueChange(props, e.target.value)}
                              onFocus={()=>updateManualSubTask(columns[props.field]['name'])}
                              key={props.rowData['_id']+props.field}
            />;
        };

        /** Editor for radio (React Native) columns.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @param options - options to select
         * @returns {*} - component in JSX code
         */
        const radioEditor = (props, options) => {
            return <div>{options.map((el) => {
                return <div style={{width: "100%"}} onFocus={()=>updateManualSubTask(columns[props.field]['name'])}>
                    <input type="radio"
                           value={el.value}
                           checked={props.rowData[props.field] === el.value}
                           style={{width: "1em"}}
                           onChange={(e) => onEditorValueChange(props, e.target.value)}
                           onFocus={()=>updateManualSubTask(columns[props.field]['name'])}
                           autoFocus={false}
                    />
                    <span> {el.label}</span>
                </div>
            })}</div>;
        };

        /** Editor for checkbox (React Native) columns.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @param options - options to choose
         * @returns {*} - component in JSX code
         */
        const checkboxEditor = (props, options) => {
            let editorValueChangeAdapter = (props, e) => {
                let result = props.rowData[props.field];
                if (!(result != null))
                    result = [];
                if (e.checked) {
                    result.push(e.value);
                } else {
                    let indexToRemove = result.indexOf(e.value);
                    if (indexToRemove > -1) {
                        result.splice(indexToRemove, 1);
                    }
                }
                onEditorValueChange(props, result);
            };

            return <div onMouseDown={()=>updateManualSubTask(columns[props.field]['name'])}>
                {options.map((el) => {
                return <div style={{width: "100%"}}   onMouseDown={()=>updateManualSubTask(columns[props.field]['name'])}  >
                    <Checkbox value={el}
                              onChange={(e) => editorValueChangeAdapter(props, e)}
                              checked={props.rowData[props.field] != null && props.rowData[props.field].includes(el)}
                              onMouseDown={()=>updateManualSubTask(columns[props.field]['name'])}
                    />
                    <span> {el}</span>
                </div>
            })}</div>;
        };


        const itemTemplate = (option) => {
            if (!(option.iri != null)) {
                return option.label;
            } else {
                return (
                    <div className="ui-helper-clearfix">
                        <span style={{float: 'left', margin: '.5em .25em 0 0'}}>{option.label}</span>
                        <a target="_blank" href={option.iri} style={{float: 'right'}}>
                            <i className="fa fa-external-link" title="External link to definition"/>
                        </a>
                    </div>
                );
            }
        };

        const dropdownEditor = (props, options) => {
            //let pfOptions = options.map((el)=>{return {label:el,value:el}});  //convert to PF format for dropdown options (SelectItem objects)

            const isRequired = columns[props.field]['validation'] != null && columns[props.field]['validation']['required'] === true;

            return (
                <Dropdown itemTemplate={itemTemplate}
                          value={props.rowData[props.field]}
                          options={options}
                          appendTo={document.body}
                          required={isRequired}
                          onMouseDown={()=>updateManualSubTask(columns[props.field]['name'])}
                          onChange={(e) => onEditorValueChange(props, e.value)} style={{width: '100%'}}
                />
            );
        };

        const hierarchicalEditor = (props, options) => {
            return (
                <HierarchicalDropdown value={props.rowData[props.field]}
                                      parentNode={options}
                                      onFocus={()=>updateManualSubTask(columns[props.field]['name'])}
                                      updateValue={(val) => onEditorValueChange(props, val)}
                />
            );
        };

        /**
         * Ruler editor works in a singleton mode: only one table and form cell can work at a given time as a ruler (neither annotation table nor form can
         * open editing of another cell). After opening only buttons from the cell working as ruler are accessible. To edit non-empty cell,
         * the trash button has to be clicked to clear value. To cancel editing, close button can be used.
         * @param props
         * @param options
         * @return {*}
         */
        const rulerEditor = (props, options) => {
            const onFocus = (e) => {
                if(
                     (manualToolState[MAIN_TOOL] !=null)
                  && (LEFT_BUTTON_MODE.RULER !== manualToolState[MAIN_TOOL]['leftButtonMode'])
                  && (!(props.rowData[props.field]!=null) || props.rowData[props.field]==="")//create New Ruler
                ){
                    updateManualToolProperty(MAIN_TOOL, 'leftButtonMode', LEFT_BUTTON_MODE.RULER);
                    updateManualSubTask(columns[props.field]['name']);
                    // updateManualToolState(null,RULER_TOOL);
                    const annotationMode = {};
                    annotationMode[ANNOTATION_PROPERTY_NAME__ID] =  props.rowData[ANNOTATION_PROPERTY_NAME__ID];
                    annotationMode['field'] =  props.field;
                    updateManualToolProperty(RULER_TOOL, RULER_PROPERTY_NAME__ANNOTATION_MODE,annotationMode); //unused yet - it will be needed when other modes of ruler are available
                    updateManualToolProperty(RULER_TOOL, RULER_PROPERTY_NAME__ANNOTATION_SOURCE, RULER_PROPERTY_VALUES__ANNOTATION_SOURCE[1]); // TABLE as event source
                    onEditorValueChange(props, "");
                    if (manualToolState[RULER_TOOL]['centering']){
                        setActiveAnnotation(props.rowData);
                    }
                    // console.log("onFocus Create :Component local value",props.rowData[props.field]);
                }
                else if (
                  (manualToolState[MAIN_TOOL] !=null)
                  && (LEFT_BUTTON_MODE.RULER !== manualToolState[MAIN_TOOL]['leftButtonMode'])
                  && (props.rowData[props.field]!=null && props.rowData[props.field].hasOwnProperty('status') )
                ){
                    updateManualToolProperty(MAIN_TOOL, 'leftButtonMode', LEFT_BUTTON_MODE.RULER);
                    updateManualSubTask(columns[props.field]['name']);
                    onEditorValueChange(props, "");
                }
                else if( LEFT_BUTTON_MODE.RULER === manualToolState[MAIN_TOOL]['leftButtonMode']
                    && e.relatedTarget!=null && e.relatedTarget.id!=null && e.relatedTarget.id.includes("closeRuler"))// if this is generated by close button)
                  { onRulerClose(props); }
            };

            let p1 = props['rowData'][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field]!=null?props['rowData'][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field][RULER_PROPERTY_NAME__START]:null;
            let p2 = props['rowData'][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field]!=null?props['rowData'][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field][RULER_PROPERTY_NAME__END]:null;

            const getDist = ()=> {
                let distance = "";
                if (p1 != null && p2 != null) {
                    distance = Math.round(10*Math.sqrt(
                      (p1.roiPosition[0] - p2.roiPosition[0]) * (p1.roiPosition[0] - p2.roiPosition[0])
                      + (p1.roiPosition[1] - p2.roiPosition[1]) * (p1.roiPosition[1] - p2.roiPosition[1])
                      + (p1.roiPosition[2] - p2.roiPosition[2]) * (p1.roiPosition[2] - p2.roiPosition[2])
                    ))/10;
                }
                return distance;
            };
            let dist = getDist();

            return (
             <div ref={(rulerDOM) => { this.rulerDOM = rulerDOM; }}
                  tabIndex={-1}
                  style={{display:"inline-block"}}
             >
                 <InputText type="text"
                            tabIndex={-1}
                            value={dist}
                            readOnly={true}
                            style={{width:"3em",pointerEvents:"auto"}} // override block from ruler
                            autoFocus={false}
                            onFocus={(e)=>{
                                console.log("Table RulerInputText:onFocus:",e.target);
                                onFocus(e);
                            }
                            }

                 />
                 <Button className={!(dist != null)?"ui-state-disabled":"ui-state-enabled"}
                         readOnly={!(dist != null)}
                         tabIndex={-1}
                         autoFocus={false}
                         icon="fa fa-download"
                         style={{pointerEvents:"auto"}} // override block from ruler
                         title={"Submit"}
                         onClick={() => {
                             updateManualToolProperty(MAIN_TOOL, 'leftButtonMode', LEFT_BUTTON_MODE.NONE);
                             onEditorValueChange(props, dist);
                             onRulerValueChange(props,
                               {
                                   "RULER_START":p1,
                                   "RULER_END":p2
                             });
                             // console.log("Button onClick");
                             //below is required for refreshing
                             setTimeout(function(){document.getElementById("manualToolContainer").click(); }, 100);
                         }
                         }/>
                 <Button className={!(dist!=null)?"ui-state-disabled":"ui-state-enabled"}
                         readOnly={!(dist!=null)}
                         tabIndex={-1}
                         autoFocus={false}
                         style={{pointerEvents:"auto"}}
                         icon="fa fa-trash"
                         title={"Clear measurement"}
                         onClick={(e) => {
                             onEditorValueChange(props, null);
                             onRulerValueChange(props,null);
                             // onFocus(e);
                         }
                         }/>
                 <Button id={"closeRuler-"+props.field}
                         className={"ui-state-enabled"}
                         readOnly={true}
                         style={{pointerEvents:"auto"}} // override block from ruler
                         tabIndex={-1}
                         autoFocus={false}
                         title={"Cancel"}
                         icon="fa fa-close"
                         onClick={(e) => {
                             console.log("Button Close:onClick:",e.target);
                             setTimeout(function(){document.getElementById("manualToolContainer").click(); }, 100);
                             // onRulerClose(props);
                         }}
                         />
             </div>
            );
        };


        /**
         *  Needed to provide callback to viewer for id.
         * @param rowData
         * @param column
         * @returns {*}
         */
        const idTemplate = (rowData, column) => {
            let className = (rowData[ANNOTATION_PROPERTY_NAME__ID].includes('.')) ? ' subROI' : '';

            const getVideoTitle = (d)=>{
                return  "Time: "
                  .concat(d["time"])
                  .concat(" Position: ", d["roiPosition"][0],", ",d["roiPosition"][1])
                  .concat(" RGBA", d['rgba'])
            };

            //   {/*{...(indented ? {style: {fontSize:'xxx-small'}} : {})}*/}
            let color = rowData['color'];
            return <div className={className}>
                <Button type="button"
                        title={rowData[ANNOTATION_PROPERTY_NAME__ROI]["roiCellIJK"]!=null
                          ?"Image position [IJK]: " + rowData[ANNOTATION_PROPERTY_NAME__ROI]["roiCellIJK"].toString()
                          : getVideoTitle(rowData[ANNOTATION_PROPERTY_NAME__ROI])}
                        label={rowData[ANNOTATION_PROPERTY_NAME__ID]}
                        onClick={() =>{
                            updateManualSubTask(null);
                            viewCallback(rowData)}}
                        {...(rowData[ANNOTATION_PROPERTY_NAME__ID] === activeROI ? {style: {backgroundColor: 'red'}}
                             : color!=null ? {style: {backgroundColor: color}} : {})}
                />
            </div>;
        };

        const dataTemplate = (rowData, column) => {
            const className = (rowData[ANNOTATION_PROPERTY_NAME__ID].includes('.')) ? ' subROI' : '';
            let value = '';
            if(column.columnKey && rowData.extraData){
                value = rowData.extraData.annotations[column.columnKey];
            }
            if (Array.isArray(value)){
                return <div className={className}>
                        {value.toString()}
                       </div>;
            }else {
                return <div className={className}>
                    {value}
                </div>;
            }
        }

        const isEditable = (manualToolState!=null
            && manualToolState[ANNOTATION_TABLE_TOOL]!=null
            && manualToolState[ANNOTATION_TABLE_TOOL]['editableROI']!=null)
            ?manualToolState[ANNOTATION_TABLE_TOOL]['editableROI']:true; //by default annotation Table should be ROI-editable

        const isSubAnnotationColumnActive = (manualToolConfiguration!=null
            && manualToolConfiguration[ANNOTATION_TABLE_TOOL]!=null
            && manualToolConfiguration[ANNOTATION_TABLE_TOOL]['properties']!=null
            && manualToolConfiguration[ANNOTATION_TABLE_TOOL]['properties']['subAnnotationColumn']!=null
            && manualToolConfiguration[ANNOTATION_TABLE_TOOL]['properties']['subAnnotationColumn']['controlEnabled']!=null)
            ?manualToolConfiguration[ANNOTATION_TABLE_TOOL]['properties']['subAnnotationColumn']['controlEnabled']:true;
        /**
         *  Needed to provide callback to viewer for id.
         * @param rowData
         * @param column
         * @returns {*}
         */
        const trashTemplate = (rowData, column) => {
            //TODO: CHeck merge of readOnly with editableROI options
            return <div>
                <Button icon="fa fa-trash"
                        {...(isEditable && !readOnly)?{onClick:() => rmCallback(rowData)}:{}}
                        {...(!isEditable || readOnly)?{className:"ui-state-disabled"}:{}}
                />
            </div>;
        };

        const subTemplate = (rowData, column) => {
            return <div>
                {!rowData[ANNOTATION_PROPERTY_NAME__ID].includes(".") &&
                <input type="checkbox"
                       checked={rowData[ANNOTATION_PROPERTY_NAME__ROI]['roiSub'] === true}
                       {...(isSubAnnotationColumnActive)?{onClick:() => setSubAnnotation(rowData)}:{}}
                       {...(!isSubAnnotationColumnActive)?{disabled:"disabled"}:{}}
                       />}
            </div>;
        };

        const getEditor = (props) => {
            let column = columns[props.field];  //columns.find((el)=>el.name===props.field);
            if (column != null)
                switch (column.type) {
                    case CUSTOM_ELEMENT.LIST.type:
                        return dropdownEditor(props, column.options);
                    case CUSTOM_ELEMENT.TEXT.type:
                        return inputTextEditor(props);
                    case CUSTOM_ELEMENT.CHOICE.type:
                        return radioEditor(props, column.options);
                    case CUSTOM_ELEMENT.MULTIPLE_CHOICE.type:
                        return checkboxEditor(props, column.options);
                    case CUSTOM_ELEMENT.HIERARCHICAL.type:
                        return hierarchicalEditor(props, column.options);
                    case CUSTOM_ELEMENT.RULER.type:
                        return rulerEditor(props, column.options);
                    default:
                        return null;
                }
            else return null;
        };

        const filterReadOnly = (value, filter) => {
            if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                return true;
            }
    
            if (value === undefined || value === null) {
                return false;
            }

            if (value.some){
                return value.some( v => v.includes(filter));
            } else if (value.includes) {
                return value.includes(filter);
            } else {
                return value === filter;
            }
        };

        const trashColumn = <Column body={trashTemplate} style={{width: "3em"}}/>;
        const subColumn = <Column body={subTemplate} style={{width: "3em"}}/>;
        // render() {
        // let contextMenuModel =[
        // {label: 'View', icon: 'fa fa-search' },
        // {label: 'Delete', icon: 'fa fa-close'}
        // ];
        // col.readOnly - usage property in definition - should be changed
        let dynamicColumns = (columns != null && columnsState === REQUEST_STATUS_SUCCESS)
            ? Object.keys(columns).map((colName, i) => {
                let col = columns[colName];
                const filterField = `extraData.annotations.${colName}`;
                return <Column key={col.name}
                               {...(readOnly? {columnKey: colName}: {})}
                               {...(!readOnly? {field:colName}: {field: filterField})}
                               {...(readOnly? {body:dataTemplate}: {})}
                                header={col.name}
                               {...(!readOnly && !col.readOnly? {editor: getEditor}: {})}
                               {...(col.validation!=null && col.validation.required)
                                ?{'editorValidator':(col['type']===CUSTOM_ELEMENT.RULER.type)
                                      ?this.requiredRulerValidator
                                      :this.requiredValidator}
                                :{}}
                               {...(col['type']===CUSTOM_ELEMENT.RULER.type)?{'editorValidator':this.requiredRulerValidator}:{}}
                               {...(!activeRowOnly ? {'filter': true} : {})}
                               {...(readOnly && !activeRowOnly ? {filterMatchMode: 'custom', filterFunction: filterReadOnly} : {})}
                               {...(!activeRowOnly ? {'sortable': true} : {})}
                />;
            })
            : [];

        let referenceToDT = null;
        let setRef = element => {
            referenceToDT = element;
        };

        const sortIds = (e) => {
            console.log('your_sortFunction_algorithm');
        };

        const activeElement = (baseData != null && baseData.length > 0) ? baseData.find((el) => {
            return el[ANNOTATION_PROPERTY_NAME__ID] === activeROI
        }) : null;
        let tableData = [];
        if (activeElement != null)
            tableData.push(activeElement);

        if (idPrefix.length>0)
            tableData = tableData
              .filter(el=>{return el[ANNOTATION_PROPERTY_NAME__ID].substring(0,idPrefix.length)===idPrefix});

        let header = <div style={{textAlign: 'left'}}>
            {manualToolState[ANNOTATION_TABLE_TOOL]!=null &&
             manualToolState[ANNOTATION_TABLE_TOOL]['csvExport']!=null &&
             manualToolState[ANNOTATION_TABLE_TOOL]['csvExport']['controlVisible']!=null &&
            <Button type="button" icon="fa fa-external-link" iconPos="left" label="CSV"
                    onClick={() => referenceToDT.exportCSV()}/>}
        </div>;


        const blockWhenRulerEnabled = {pointerEvents:LEFT_BUTTON_MODE.RULER === manualToolState[MAIN_TOOL]['leftButtonMode'] // prevent more than 1 cell opened with ruler editor
              ? "none"
              : "auto"};
        return (
            <form onSubmit={this.handleSubmit}>
                {activeRowOnly && columnsState === REQUEST_STATUS_SUCCESS &&
                <DataTable ref={setRef}
                           value={tableData}
                           editable={readOnly}
                           reorderableColumns={true}
                           emptyMessage="No selected annotation"
                           scrollable={true}
                           scrollHeight="8em"
                           style={blockWhenRulerEnabled}
                >
                    <Column key={ANNOTATION_PROPERTY_NAME__ID} body={idTemplate} field={ANNOTATION_PROPERTY_NAME__ID}
                            header="Id" style={{width:"".concat(String(idPrefix.length+3), "em")}}/>
                    {dynamicColumns}
                    {trashColumn}
                    {subColumn}
                </DataTable>}
                {!activeRowOnly && columnsState === REQUEST_STATUS_SUCCESS &&
                <DataTable ref={setRef}
                           value={baseData}
                           editable={readOnly}
                           scrollable={true}
                           scrollHeight="200px"
                           reorderableColumns={true}
                           onValueChange={sortedData => console.log(sortedData)}
                           sortField={ANNOTATION_PROPERTY_NAME__ID}
                           sortOrder={-1}
                           emptyMessage="No annotations"
                           footer={header}
                           style={readOnly ? {maxHeight: "125px"}:blockWhenRulerEnabled}
                    // onContextMenu={(e) => this.cm.show(e.originalEvent)}
                    // onContextMenuSelectionChange={(e) => this.setState({selected: e.value})}
                    // contextMenuSelection={this.state.selected}
                >

                    <Column key={ANNOTATION_PROPERTY_NAME__ID}
                            body={idTemplate}
                            field={ANNOTATION_PROPERTY_NAME__ID}
                            header="Id" style={{width: "4em"}}
                            sortable={true}
                            sortFunction={() => sortIds()}/>
                    {dynamicColumns}
                    {trashColumn}
                    {subColumn}
                </DataTable>}
            </form>

        );
        // }
    };
}
AnnotationTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data:PropTypes.array.isRequired,
    dataState:PropTypes.string.isRequired,
    messageQueue:PropTypes.object.isRequired,
    readData:PropTypes.object,
    readOnly:PropTypes.bool,
    manualToolState:PropTypes.object,
    manualToolConfiguration:PropTypes.object,
    viewCallback:PropTypes.func,
    rmCallback:PropTypes.func,
    setSubAnnotation:PropTypes.func,
    activeRowOnly:PropTypes.bool.isRequired,
    updateManualSubTask:PropTypes.func.isRequired,
    updateManualToolProperty:PropTypes.func.isRequired,
    updateManualToolState:PropTypes.func.isRequired,
    setActiveAnnotation:PropTypes.func.isRequired
};

