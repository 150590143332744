import {connect} from "react-redux";
import {clearStudy, getStudy} from "../../visualization/action/CohortAction";
import CohortsDetails from "../component/CohortDetails";
import {withRouter} from "react-router";
import {createManualToolConfiguration, createManualToolConfigurationWithROI} from "../action/toolAction";
import {updateManualToolSAM} from "../../analysis/action/toolAction";



const mapStateToProps = state => {
  return{
    cohort:state.visu.study.study,
    manualToolConfiguration:state.sam.manualToolConfiguration // TODO : Migrate manual Tool configuration to separate Redux state
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    getCohort: (cohortId) => {dispatch(getStudy(cohortId))},
    clearCohort: ()=>{dispatch(clearStudy())},
    createManualToolConfiguration: (imagesArr)=>{
      dispatch(createManualToolConfiguration(imagesArr))
    },
    createManualToolConfigurationWithROI: (originalImage, rois)=>{
      dispatch(createManualToolConfigurationWithROI(originalImage, rois))
    },
    updateManualToolSAM:(mTool)=>{
      dispatch(updateManualToolSAM(mTool))
    }
  }
};


export const ContainerCohortDetails  =withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(CohortsDetails));