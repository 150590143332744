import React from 'react';
// import {Link} from "react-router-dom";
import {ContainerSPM} from "./container/ContainerSPM"

export default class SPM extends React.Component{
    render() {
        console.log("SPM ... render");
        return (
            <ContainerSPM/>
        )
    }
}
