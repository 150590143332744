import {connect} from "react-redux";
import React from "react";
import {
getAnnotationsList,
} from "../action/AnnotationAction";
import AnnotationColumnSelection from "../component/annotationTool/AnnotationColumnSelection";
import {getQuestionListActionCreator} from "../action/QuestionAction";





const mapStateToProps = state => {
  return {
    allColumns: state.visu.annotations.annotationsList,
    allColumnsState: state.visu.annotations.annotationsListState,
    questionList:state.visu.questions.questionList,
    questionListState:state.visu.questions.questionListState,
    data: state.visu.annotations.annotationsData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAnnotationsList:()=>{
      dispatch(getAnnotationsList())
    },
    getQuestionsList:()=>{
      dispatch(getQuestionListActionCreator())
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);


export const ContainerAnnotationColumnSelection = containerCreator(AnnotationColumnSelection);