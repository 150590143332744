const _MS_PER_DAY = 1000 * 60 * 60 * 24;

/** Normalizing the two dates to UTC, and then calculating the difference between those two UTC dates.*/
export function dateDiffInDays(a, b) {
    let utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    let utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}


/**
 * Function to convert ISO  8601 date string into localized human-readable date
 * @param dateToPrint - stringf containing date in ISO 8601 format
 * @return {string}
 */
export function convertISODateToLocaleString(dateToPrint) {
    let date = new Date(dateToPrint);
    if (date != null)
        return date.toLocaleDateString();
    return "";
}

/**
 * Function to convert ISO  8601 date string into localized human-readable date
 * @param dateToPrint - stringf containing date in ISO 8601 format
 * @return {string}
 */
export function convertISODateAndTimeToLocaleString(dateToPrint) {
    let date = new Date(dateToPrint);
    if (date != null)
        return date.toLocaleDateString() +" "+ date.toLocaleTimeString();
    return "";
}