import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    ROLE_PICK_SUCCESS,
    REGISTER_REQUEST,
    FORGOT_PASSWORD_INITIAL_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    REGISTER_SUCCESS
    } from "../action/actionType";

const AuthReducer = (state = {
                         email: '',
                         login_status: 'logout',
                         roles: [],
                         role_active: '',
                         token_bearer: '',
                         name: '',
                         lastname: '',
                         nickname: '',
                         eduLevel: '',
                         experience: '',
                         profession: '',
                         specialty: '',
                         id: ''
                     },
                     action) => {

    console.log('action is ' + action.type);
    // console.log('state is ' + state);

    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                login_status: 'requested'
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                    login_status: 'logged',
                    token_bearer: action.creds.token_bearer,
                    roles: action.creds.roles,
                    role_active: action.creds.role_active,
                    email: action.creds.email,
                    name: action.creds.name,
                    lastname: action.creds.lastname,
                    nickname: action.creds.nickname,
                    eduLevel: action.creds.eduLevel,
                    experience: action.creds.experience,
                    profession: action.creds.profession,
                    specialty: action.creds.specialty,
                    id: action.creds.id
                }
            );
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                    login_status: 'logout',
                    token_bearer: '',
                    roles: [],
                    role_active: '',
                    email: '',
                    name: '',
                    lastname: '',
                    nickname: '',
                    eduLevel: '',
                    experience: '',
                    profession: '',
                    specialty: '',
                    id: ''
                }
            );
        case ROLE_PICK_SUCCESS:
            return Object.assign({}, state, {
                role_active: action.auth.role_active,
                token_bearer: action.auth.token_bearer,
                roles: action.auth.roles

            });
        case REGISTER_REQUEST:
            return Object.assign({}, state, {
                login_status: 'toRegister'
            });
        case REGISTER_SUCCESS:
            return Object.assign({}, state, {
                login_status: 'registered'
            });
        case FORGOT_PASSWORD_INITIAL_REQUEST:
            return Object.assign({}, state, {
                login_status: FORGOT_PASSWORD_INITIAL_REQUEST
            });
        case FORGOT_PASSWORD_REQUEST:
            return Object.assign({}, state, {
                login_status: FORGOT_PASSWORD_REQUEST
            });
        case FORGOT_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                login_status: FORGOT_PASSWORD_SUCCESS
            });
        case FORGOT_PASSWORD_FAIL:
            return Object.assign({}, state, {
                login_status: FORGOT_PASSWORD_FAIL
            });
        case PASSWORD_RESET_REQUEST:
            return Object.assign({}, state, {
                login_status: PASSWORD_RESET_REQUEST
            });
        case PASSWORD_RESET_SUCCESS:
            return Object.assign({}, state, {
                login_status: PASSWORD_RESET_SUCCESS
            });
        case PASSWORD_RESET_FAIL:
            return Object.assign({}, state, {
                login_status: PASSWORD_RESET_FAIL
            });
        default:
            return state
    }
};

export default AuthReducer;