export const mockActivities = [
    {
        "uuid": "2802b284-da5b-4283-50bb-f338bdb77a70",
        "experiment": {uuid: "3130bbbc-cf76-49cd-a5cf-4e8b214f3f1c", name: "Does  volume of WM lesions predict EDSS? "},
        "dueDate": "Completed",
        "task": {uuid: "9a2510f8daeb1c8b38bf5db8a4003d50", name: "Segmentation of Medulla Oblongata"},
        "project": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "IPMSA - EPITOME"}
    },
    {
        "uuid": "2802b284-da5b-4283-50bb-f338bdb77a78",
        "experiment": {
            uuid: "3130bbbc-cf76-49cd-a5cf-4e8b214f3f1c",
            name: "Does  volume of WM lesions predict EDSS2? "
        },
        "dueDate": "Completed",
        "task": {uuid: "9a2510f8daeb1c8b38bf5db8a4003d58", name: "Segmentation model"},
        "project": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "IPMSA - EPITOME"}
    },

    {
        "uuid": "2802b284-da5b-4283-50bb-f338bdb77a79",
        "experiment": {
            uuid: "3130bbbc-cf76-49cd-a5cf-4e8b214f3f1d",
            name: "Is EDSS correlated with volume of WM Lesions?"
        },
        "dueDate": "May 21, 2019",
        "task": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "White Matter Lesion Segmentation"},
        "project": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "IPMSA - EPITOME"}
    },
    {
        "uuid": "2802b284-da5b-4283-50bb-f338bdb77a80",
        "experiment": {
            uuid: "3130bbbc-cf76-49cd-a5cf-4e8b214f3f1d",
            name: "Is EDSS correlated with number of WM Lesions?"
        },
        "dueDate": "June 02, 2019",
        "task": {uuid: "9a2510f8daeb1c8b38bf5db8a4000f38", name: "White Matter Lesion Annotation"},
        "project": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "IPMSA - EPITOME"}
    }

];

/** Mock for the new structure of tasks
 *
 * @type {*[]}
 */
export const mockActivities2 = [
    {
        "name": "Medulla Oblongata Segmentation - demo",
        "type": 'miniWorkflow',
        "taskId": '9a2510f8daeb1c8b38bf5db8a4003d50',
        "miniWorkflowSetId": '2802b284-da5b-4283-50bb-f338bdb77a70', // Document with the set of miniwf
        "miniWorkflowKey": '2802b284-da5b-4283-50bb-f338bdb77a70', // The specific miniwf
        "dueDate": "2019-10-12T04:00:00.000Z", // due date for earliest case to be done
        "numberOfCases": 45,
        "status": "COMPLETED"
    },
    {
        "name": "Medulla Oblongata Segmentation",
        "taskId": '9a2510f8daeb1c8b38bf5db8a4003d57',
        "type": "miniWorkflow",
        "dueDate": "2019-10-12T04:00:00.000Z",
        "numberOfCases": 15,
        "miniWorkflowSetId": "8aec0035b34116338689f0537722c1d1",
        "miniWorkflowKey": "mw1",
        "status": "ONGOING"
    },
    {
        "name": "Lesion Segmentation - demo",
        "type": 'singleTask',
        "taskId": '9a2510f8daeb1c8b38bf5db8a4002707',
        "miniWorkflowSetId": '2802b284-da5b-4283-50bb-f338bdb77a70', // Document with the set of miniwf
        "miniWorkflowKey": '2802b284-da5b-4283-50bb-f338bdb77a70', // The specific miniwf
        "dueDate": "2019-06-23T04:00:00.000Z",
        "numberOfCases": 54,
        "status": "ONGOING"
    },
    {
        "name": "Lesion Annotation - demo",
        "type": 'singleTask',
        "taskId": '9a2510f8daeb1c8b38bf5db8a4000f38',
        "miniWorkflowSetId": '2802b284-da5b-4283-50bb-f338bdb77a70', // Document with the set of miniwf
        "miniWorkflowKey": '2802b284-da5b-4283-50bb-f338bdb77a70', // The specific miniwf
        "dueDate": "2019-01-21T04:00:00.000Z",
        "numberOfCases": 14,
        "status": "ONGOING"
    }

];


export const mockTasksById = [
    {
        "expName": "Is EDSS correlated to lesions in T2 ?",
        "expId": "1276951db03f1254a04e0b6df133baa7",
        "dueDate": "2019-10-12T04:00:00.000Z",
        "numberOfCases": {missing: 12, done: 12},
        "taskId": "9a2510f8daeb1c8b38bf5db8a4003d50"  //MOCK
    },
    {
        "expName": "Is EDSS correlated to lesions in T1 ?",
        "expId": "1276951db03f1254a04e0b6df133ba27",
        "dueDate": "2019-10-12T04:00:00.000Z",
        "numberOfCases": {missing: 16, done: 12},
        "taskId": "9a2510f8daeb1c8b38bf5db8a4000f38"  //MOCK
    },
    {
        "expName": "Is 9HPT correlated to lesions in T2 ?",
        "expId": "1276951db03f1254a04e0b6df133baa8",
        "dueDate": "2019-10-12T04:00:00.000Z",
        "numberOfCases": {missing: 12, done: 13},
        "taskId": "9a2510f8daeb1c8b38bf5db8a4002707"  //MOCK
    },
    {
        "expName": "Is 9HPT correlated to lesions in T1 ?",
        "expId": "1276951db03f1254a04e0b6df133ba28",
        "dueDate": "2019-10-12T04:00:00.000Z",
        "numberOfCases": {missing: 16, done: 14},
        "taskId": "9a2510f8daeb1c8b38bf5db8a4003d58"  //MOCK
    }
];


export const mockTask = [
    {
        listOfCases: [{number: 1, status: "FINISHED"},
            {number: 2, status: "FINISHED"},
            {number: 3, status: "FINISHED"},
            {number: 4, status: "UNFINISHED"}],
        currentCase: 4,
        expId: "33535",
        miniWorkflow: {
            miniworkflowPath: [
                {
                    stepKey: "lowerbound",
                    stepName: "Lower Boundary"
                },
                {
                    stepKey: "upperbound",
                    stepName: "Upper Boundary"
                },
                {
                    stepKey: "segment",
                    stepName: "Segment"
                },
                {
                    stepKey: "confirm",
                    stepName: "Confirm"
                }
            ],
            currentStep: 0,
            currentTool: {}, // whole tool document - not used in the front-end right now
            currentMaterializedTask: {},// "whole" document
            currentTutorial: {},
            status: "done"  // (enum:["done","notstarted","started" ])
        }
    }


];