//Poly actions********************************************************


import {POLY_REQUEST_FAILED, POLY_REQUEST_SUCCESS, POLY_REQUESTED} from "./actionType";
import {updateScenesOnInputLoaded} from "./SceneAction";
import polyDataReader from "vtk.js/Sources/IO/Legacy/PolyDataReader/index";
import axios from 'axios';




const requestPoly = (polyId) => ({
  type: POLY_REQUESTED,
  polyId
});

const errorPoly = (err,polyId) => ({
  type: POLY_REQUEST_FAILED,
  err,
  polyId
});

const successPoly = (polyId,polyData) => ({
  type: POLY_REQUEST_SUCCESS,
  polyId,
  polyData
});

/**
 * ActionCreator to fetch tractography and other polydata in .vtk format
 * @param polyId
 * @return {function(...[*]=)}
 */
export const getPoly = (polyId) => {
  return (dispatch, getState) => {
    dispatch (requestPoly(polyId));
    console.log('PolyAction.js :: getPoly :: Before axios request');
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;


    // loadPolyfromFile(polyId)
    axios.get(`/api/file/${polyId}/file`)
      .then((data)=>
      {
        const polyReader = polyDataReader.newInstance();
        polyReader.parseAsText(data.data);
        dispatch(successPoly(polyId,polyReader.getOutputData(0)));
        dispatch(updateScenesOnInputLoaded(polyId));
      })
      .catch(err=>dispatch (errorPoly(err,polyId)));
  };
};


//These are mock to simulate async loading of data - map with id and name of data file (json)
// let mockedPolyMap = null;
// loadPolyRegister().then(data=>mockedPolyMap = data); //Load register of mocked data


//------------ToDO Remove this mocks when data collected from DB
// async function loadPolyfromFile(poly) {
//   // let response = await fetch(`/dist/jsonDocs/data/${mockedPolyMap[poly]}.vtk`);
//   await polyReader.setUrl(`/dist/jsonDocs/data/${mockedPolyMap[poly]}.vtk`);
//   return polyReader.getOutputData(0);
// }
//
// async function loadPolyRegister() {
//   let response = await fetch('/dist/jsonDocs/data/polyRegister.json',{cache: "reload"});
//   const data = await response.json();
//   return await data;
// }