import React from 'react';
import PropTypes from "prop-types";
import {WorkflowTrayItem} from "./WorkflowTrayItem";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";

/**
 * Component to represent workflow tray in Palette.
 * Panel only for workflows.
 */
class WorkflowPanelContent extends React.Component {


  componentDidMount() {
    this.props.getWorkflows();
  }

  render() {
    const {workflows,workflowListState,onFilterChange,filter,lockDiagramModel} =this.props;

    const filtered =(workflows!=null && workflowListState===REQUEST_STATUS_SUCCESS)
        ? workflows   //.filter(el=>{return el['name'].toLowerCase().includes(filter.toLowerCase())})  //moved to selector
                   .map((el)=><WorkflowTrayItem workflowData={el} />)
        :<React.Fragment/> ;

    return (
      <React.Fragment>
        <InputText
          onChange={(e)=>onFilterChange(e.target.value)}
          value={filter}
          onFocus={(e)=>{
            lockDiagramModel(true);
          }}
          onBlur={(e)=>{
            lockDiagramModel(false);
          }}
        />
        <div className={"workflow-editor-panel-tray-content"} draggable={false}>
        {filtered}
        </div>
      </React.Fragment>
    );
  }
};



export default WorkflowPanelContent;

WorkflowPanelContent.propTypes= {
  workflows : PropTypes.object,
  workflowListState: PropTypes.string,
  getWorkflows:PropTypes.func.isRequired,
  onFilterChange:PropTypes.func.isRequired,
  filter:PropTypes.string.isRequired,
  lockDiagramModel:PropTypes.func.isRequired
};