import React from 'react';
import PropTypes from "prop-types";
import {Prompt, withRouter} from "react-router";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import initialDemo from '../../jsonDocs/tools/modelingToolDemo.json'
import {ContainerManualTool} from "../../visualization/containers/ContainerManualTool";
import EditorTabContent from "./EditorTabContent";
import {Accordion, AccordionTab} from "primereact/components/accordion/Accordion";
import ViewersConfigurationTabContent from "./ViewersConfigurationTabContent";
import {ContainerGeneralTab} from "../container/ContainerGeneralTab";
import {InputText} from "primereact/components/inputtext/InputText";
import {getNestedProp} from "../../helpers/expressions";
import ScenesConfigurationTabContent from "./ScenesConfigurationTabContent";
import ErrorBoundary from "../../root/ErrorBoundary";
import {OverlayPanel} from "primereact/components/overlaypanel/OverlayPanel";
import IOConfigurationTabContent from "./IOConfigurationTabContent";


class ManualToolBuilderMainPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error:"",
      errorInfo:null,
      activeAccordionIndex:0,
      /*state for temporary objects (cache) for Transfer Object*/
      isChangedFlag:false,
      nodeSettings:null,
      accordionIndex:0,
      activeIndex:0,
      transferObject:initialDemo, //finalresult to send to DB
      validationErrors:null,
      testingData:{}//Materialized Task
    };
    ["onTabChange","onError"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  onError(error,errorInfo) {
    const {messageQueue} =this.props;
      this.setState({errorInfo,error});
      messageQueue.show({
        severity: 'error',
        summary: 'Error',
        sticky: true,
        detail: "Wrong configuration caused problem: ".concat(error)
      })
  }

  updateTransferObjectCache(key,value){
    this.setState({[key]:value});
  }
  onTabChange(e){
    if (e.index!==this.state.activeIndex) {
      this.setState({activeIndex: e.index});
    }
  }

  render() {
    const {messageQueue} =this.props;
    const {isChangedFlag,transferObject} =this.state;

    const errorPage = <div style={errorPageStyle}>
      <h1  title={this.state.errorInfo!=null&&this.state.errorInfo.componentStack!=null?this.state.errorInfo.componentStack:""}> Page cannot be displayed due to error in the configuration <i className="fa fa-exclamation"/></h1>
      {/*{this.state.errorInfo!=null&&this.state.errorInfo.componentStack!=null && <pre>{this.state.errorInfo.componentStack}</pre>}*/}
    </div>;

    return (
      <React.Fragment>
        <Prompt
          when={true}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <Sidebar
          position={"left"}
          visible={this.state.leftSidebarVisible}
          id="palette-sidebar"
          onShow={this.onShow}
          onHide={() => this.setState({leftSidebarVisible:false})}
          {...(this.state.leftSidebarVisible ? {style: {marginLeft:"50px",marginTop:"50px"}} :{style: {marginTop:"50px"}})}
        >
        </Sidebar>
        <Sidebar
          position={"right"}
          visible={this.state.rightSidebarVisible}
          id="action-sidebar"
          onShow={this.onShow}
          onHide={() => this.setState({rightSidebarVisible:false})}
          style={{marginTop:"50px"}}
        >
        </Sidebar>
        <TabView activeIndex={this.state.activeIndex} onTabChange={this.onTabChange} >
          <TabPanel header="Configurator" contentStyle={{padding:"10px 0 0 0px"}} disabled={isChangedFlag}>
            <Accordion activeIndex={this.state.activeAccordionIndex}
                       onTabOpen={(e)=>{
                         this.setState({activeAccordionIndex:e.index});
                       }
                       }
                       onTabClose={(e)=>{this.setState({activeAccordionIndex:e.index})}}
            >
              <AccordionTab header="General data" >
                <ContainerGeneralTab  transferObject={transferObject} updateTransferObject={(val)=>{this.setState({transferObject:val})}}/>
              </AccordionTab>
              <AccordionTab header="Inputs/Outputs">
                <ErrorBoundary   onError={ this.onError } errorPage={errorPage}>
                {getNestedProp(['miniWorkflow','currentTool'],transferObject) != null &&
                <IOConfigurationTabContent
                  messageQueue={messageQueue}
                  transferObject={transferObject}
                  updateTransferObject={(val)=>{this.setState({transferObject:val})}}
                />
                }
                </ErrorBoundary>
              </AccordionTab>
              <AccordionTab header="Scenes" >
                {getNestedProp(['miniWorkflow','currentTool','configuration','scenes'],transferObject) != null &&

                <ScenesConfigurationTabContent
                  messageQueue={messageQueue}
                  transferObject={transferObject}
                  updateTransferObject={(val)=>{this.setState({transferObject:val})}}
                />
                }
              </AccordionTab>
              <AccordionTab header="Viewers">
                {transferObject != null &&
                transferObject['miniWorkflow'] !=null &&
                transferObject['miniWorkflow']['currentTool'] &&
                transferObject['miniWorkflow']['currentTool']['configuration'] &&
                transferObject['miniWorkflow']['currentTool']['configuration']['viewers'] &&
                <ViewersConfigurationTabContent
                  messageQueue={messageQueue}
                  viewersConfiguration={transferObject['miniWorkflow']['currentTool']['configuration']['viewers']}
                  transferObject={transferObject}
                  updateTransferObject={(val)=>{this.setState({transferObject:val})}}
                  />
                }
              </AccordionTab>

              <AccordionTab header="Widgets">
                Work in progress
              </AccordionTab>
              <AccordionTab header="Testing data">
                Work in progress
              </AccordionTab>
            </Accordion>
          </TabPanel>
          <TabPanel header={isChangedFlag?"JSON*":"JSON"} disabled={!(this.state.transferObject!=null)}>
            <ErrorBoundary   onError={ this.onError } errorPage={errorPage}>
           <EditorTabContent
             transferObject={transferObject}
             updateTransferObject={(to)=>{this.setState({transferObject:to})}}
             updateIsChangedFlag={(value)=>{this.setState({isChangedFlag:value})}}
             isChangedFlag={isChangedFlag}
             messageQueue={messageQueue}
           />
            </ErrorBoundary>
          </TabPanel>
          <TabPanel header="Preview" disabled={!(this.state.transferObject!=null) || isChangedFlag} >
            <div className={"ui-g"}>
            <ErrorBoundary   onError={ this.onError } errorPage={errorPage}>
            <ContainerManualTool
              predefinedToolConfiguration={this.state.transferObject}
            />
            </ErrorBoundary>
            </div>
          </TabPanel>
        </TabView>
      </React.Fragment>
    )
  };
}
export default withRouter(ManualToolBuilderMainPage);

ManualToolBuilderMainPage.propTypes= {
  workflow : PropTypes.object.isRequired,
  workflowState: PropTypes.string.isRequired,
  workflowSaveState: PropTypes.string.isRequired,
  workflowSaveError: PropTypes.object.isRequired,
  getWorkflow:PropTypes.func.isRequired,
  getWorkflows:PropTypes.func.isRequired,
  clearWorkflow:PropTypes.func.isRequired,
  saveWorkflow:PropTypes.func.isRequired,
  messageQueue:PropTypes.object.isRequired
};

const errorPageStyle= {
  margin: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
};