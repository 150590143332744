import {connect} from "react-redux";
import SegmentationMetricsWidget from "../component/annotationTool/SegmentationMetricsWidget";
import {SEGMENTATION_METRICS_TOOL} from "../../../Constants";


const mapStateToProps = (state, ownProps) => {
  return {
    overlays: state.visu.images.overlays,
    tool: state.visu.manualTool.manualToolState[SEGMENTATION_METRICS_TOOL]
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerSegmentationMetricsWidget = containerCreator(SegmentationMetricsWidget);

