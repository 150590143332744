import {mockActivities, mockActivities2, mockTasksById} from "../tests/Activities.test";
import {
    CLEAR_MANUAL_TASK,
    MANUAL_TASK_ACK_FAIL,
    MANUAL_TASK_ACK_REQUEST, MANUAL_TASK_ACK_SUCCESS,
    MANUAL_TASK_GET_FAIL,
    MANUAL_TASK_GET_REQUEST, MANUAL_TASK_GET_SUCCESS,
    MANUAL_TASK_LIST_GET_FAIL, MANUAL_TASK_LIST_GET_REQUEST, MANUAL_TASK_LIST_GET_SUCCESS, MANUAL_TASK_UPDATE_SUBTASK,
    SET_MANUAL_TASK_VISIBILITY_FILTER, SET_MANUAL_TASK_VISIBILITY_SORTER
} from "./actionType";
import {HOST_URL, REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import axios from 'axios';
import store from "../../Store";
import {clearSkillResultsAC, clearSkillStateAC} from "../../skills/action/SkillAction";
import i18n from "i18next";

//****************************************************************************
//
//                                Actions
//
//****************************************************************************


// Activity actions *************************************************************

const requestManualTaskList = () => ({
    type: MANUAL_TASK_LIST_GET_REQUEST
});


const errorManualTaskList = err => ({
    type: MANUAL_TASK_LIST_GET_FAIL,
    err
});

const successManualTaskList = (manualTasks) => ({
    type: MANUAL_TASK_LIST_GET_SUCCESS,
    manualTasks
});

//
// export const getManualTaskList = () => {
//     return dispatch => {
//         dispatch (successManualTaskList(mockTasksById));
//
//     };
//
// };

export function setVisibilityFilter(filter) {
    return {type: SET_MANUAL_TASK_VISIBILITY_FILTER, filter}
}

export function setSorter(sorter) {
    return {type: SET_MANUAL_TASK_VISIBILITY_SORTER, sorter}
}

/**
 * Action Creator to retrieve the workload corresponding to a MiniWorkflow task for a user.
 * The result is an array, one item corresponds to one experiment
 * @see https://docs.google.com/document/d/1o5A8nVP_dokX4aZJYkNvJOCPHUpyXTlcefQPXRezOaA/edit#heading=h.sngrzux18682
 *
 * @param uuid -
 * @param mfKey -  moi
 * @returns {function(*)}
 */
export const getManualTaskList = (uuid, mfKey) => {
    return dispatch => {
        dispatch(requestManualTaskList());
        console.log('ManualTaskAction.js :: getManualTaskList :: Before axios request');
        const url = (mfKey != null)
            ? HOST_URL + `/api/workload?miniWorkflowSet=${uuid}&miniWorkflow=${mfKey}`
            : HOST_URL + `/api/workload?task=${uuid}`;
        return axios.get(url
        ).then(response => {
            console.log('ManualTaskAction.js :: getManualTaskList :: response', response);
            if (response.status !== 200) {
                dispatch(errorManualTaskList(response.data));
                return Promise.reject(response.data);
            } else {
                console.log('ManualTaskAction.js :: getManualTaskList :: Before success');
                dispatch(successManualTaskList(response.data));
            }
        }).catch(err => dispatch(errorManualTaskList(err)));
    };
};


// One activity *****************************************************************
const requestManualTask = () => ({
    type: MANUAL_TASK_GET_REQUEST
});


const errorManualTask = err => ({
    type: MANUAL_TASK_GET_FAIL,
    err
});

const successManualTask = (manualTask) => ({
    type: MANUAL_TASK_GET_SUCCESS,
    manualTask
});


/**
 * Action Creator to clear manual task state.
 * It is used by Manual Tool dispatcher (on unmount).
 */
export const clearManualTaskAC = ()=>({
    type:CLEAR_MANUAL_TASK
});

// One activity *****************************************************************
const requestManualTaskSave = () => ({
    type: MANUAL_TASK_ACK_REQUEST
});


const errorManualTaskSave = err => ({
    type: MANUAL_TASK_ACK_FAIL,
    err
});

const successManualTaskSave = () => ({
    type: MANUAL_TASK_ACK_SUCCESS
});

export const updateManualSubTask = (name) => ({
    type: MANUAL_TASK_UPDATE_SUBTASK,
    name
});


/**
 *  Function for sending acknowledge signal.
 * @param signal
 * @param err
 * @return {function(...[*]=)}
 */
export const setAcknowledgeSignal = (signal, err) => {
    return dispatch => {
        if (signal===REQUEST_STATUS_REQUESTED)
            dispatch(requestManualTaskSave());
        else if (signal===REQUEST_STATUS_FAIL)
            dispatch(errorManualTaskSave(err));
        else if (signal===REQUEST_STATUS_SUCCESS)
            dispatch(successManualTaskSave());
    }
};


/** MOCK*/
// export const getManualTask = () => {
//     return dispatch => {
//         dispatch (successManualTask());
//     };
// };


const getLanguage = ()=>{return (i18n.language!=null)?"&lang="+i18n.language.toLowerCase().substring(0,2):""};
/**
 * Action Creator to Get the details of a workload for a specific experiment for a mini workflow and the current step.
 * @see https://docs.google.com/document/d/1o5A8nVP_dokX4aZJYkNvJOCPHUpyXTlcefQPXRezOaA/edit#heading=h.sngrzux18682
 *
 * @param uuid - workflow set id
 * @param mfKey -  moi
 * @param expId - experimentId
 * @returns {function(*)}
 */
export const getManualTask = (uuid, mfKey, expId) => {
    return dispatch => {
        dispatch(requestManualTask());
        console.log('ManualTaskAction.js :: getManualTask :: Before axios request');
        const url = HOST_URL + `/api/workload?miniWorkflowSet=${uuid}&miniWorkflow=${mfKey}&experiment=${expId}${getLanguage()}`;
        return axios.get(url
        ).then(response => {
            console.log('ManualTaskAction.js :: getManualTask :: response', response);
            if (response.status !== 200) {
                dispatch(errorManualTask(response.data));
                return Promise.reject(response.data);
            } else {
                console.log('ManualTaskAction.js :: getManualTask :: Before success');
                dispatch(successManualTask(response.data));
            }
        }).catch(err => dispatch(errorManualTask(err)));
    };
};


/**
 * Action Creator to Get the details of a workload for a specific Live Presenter
 * experiment for a mini workflow and the current step.
 * @see https://docs.google.com/document/d/1YBhDgSbbWs-YExxYNgRU-ix1DXvFHOGea1ip_Hgs0eA/edit#heading=h.63gmsai5xiuu
 * @param livePresenterExperimentId - id of 'fake' experiment
 * @param miniWorkflowSetId - workflow set id
 * @param miniWorkflowNameInSet -  moi
 * @param currentStep - experimentId
 * @returns {function(*)}
 */
export const getManualTaskLivePresenter = (livePresenterExperimentId,
                                           currentStep,
                                           miniWorkflowSetId,
                                           miniWorkflowNameInSet) => {
    return dispatch => {
        dispatch(requestManualTask());
        console.log('ManualTaskAction.js :: getManualTaskLivePresenter :: Before axios request');
        // API: /workload?livePresenterExperiment=uuid&currentStep=0&miniWorkflowSet=uuid&miniWorkflow=key
        const url = HOST_URL +
            `/api/workload?livePresenterExperiment=${livePresenterExperimentId}` +
            // `&currentStep=${currentStep}` +
            `&miniWorkflowSet=${miniWorkflowSetId}` +
            `&miniWorkflow=${miniWorkflowNameInSet}`
            +getLanguage();
        return axios.get(url)
            .then(response => {
                console.log('ManualTaskAction.js :: getManualTask :: response', response);
                if (response.status !== 200) {
                    dispatch(errorManualTask(response.data));
                    return Promise.reject(response.data);
                } else {
                    console.log('ManualTaskAction.js :: getManualTask :: Before success');
                    dispatch(successManualTask(response.data));
                }
            })
            .catch(err => dispatch(errorManualTask(err)));
    };
};

/**
 * Endpoint for skill of "map" type.
 * @param skillId
 * @param siteId
 * @return {function(*): Promise<>}
 */
export const getManualTaskPlaySkillMap = (skillId, siteId) => {
    return dispatch => {
        dispatch(requestManualTask());
        console.log('ManualTaskAction.js :: getManualTaskPlayskillMap :: Before axios request');
        return axios.get(`/api/skill/${skillId}/workload?siteId=${siteId}`)
          .then(response => {
              console.log('ManualTaskAction.js :: getManualTaskPlayskillMap :: response', response);
              if (response.status !== 200) {
                  dispatch(errorManualTask(response.data));
                  return Promise.reject(response.data);
              } else {
                  console.log('ManualTaskAction.js :: getManualTaskPlayskillMap :: Before success');
                  dispatch(successManualTask(response.data));
              }
          })
          .catch(err => dispatch(errorManualTask(err)));
    };
};


/**
 * Action Creator to Get Another Case (Go To a different case)
 * @see https://docs.google.com/document/d/1o5A8nVP_dokX4aZJYkNvJOCPHUpyXTlcefQPXRezOaA/edit#heading=h.sngrzux18682
 *
 * @param uuid - workflow set id
 * @param mfKey -  miniworkflow key
 * @param expId - experimentId
 * @param caseNumber - [optional]
 * @returns {function(*)}
 */
export const getAnotherCase = (uuid, mfKey, expId, caseNumber) => {
    return dispatch => {
        dispatch(requestManualTask());
        console.log('ManualTaskAction.js :: getAnotherCase :: Before axios request. Case number:', caseNumber);
        const url = (caseNumber!=null)
            ? HOST_URL + `/api/workload?miniWorkflowSet=${uuid}&miniWorkflow=${mfKey}&experiment=${expId}&caseLabel=${caseNumber}${getLanguage()}`
            : HOST_URL + `/api/workload?miniWorkflowSet=${uuid}&miniWorkflow=${mfKey}&experiment=${expId}${getLanguage()}`;
        return axios.get(url
        ).then(response => {
            console.log('ManualTaskAction.js :: getAnotherCase :: response', response);
            if (response.status !== 200) {
                dispatch(errorManualTask(response.data));
                return Promise.reject(response.data);
            } else {
                console.log('ManualTaskAction.js :: getAnotherCase :: Before success');
                dispatch(successManualTask(response.data));
            }
        }).catch(err => dispatch(errorManualTask(err)));
    };
};



/**
 * Action Creator to Get Next Task
 * Navigating to next and previous task of the mini wf or a different case.
 *
 * Implementation of API method:
 * GET /materialized-task/{materializedTaskId}/navigate?dir=next
 * @return {function(*, *)}
 *
 * @see https://docs.google.com/document/d/1o5A8nVP_dokX4aZJYkNvJOCPHUpyXTlcefQPXRezOaA/edit#heading=h.sngrzux18682
 * @param materializedTaskId - workflow set id
 * @param dir - direction [enum] {'next', 'previous'}
 * @param step - optional parameter
 * @returns {function(*)}
 */
export const getNextOrPreviousTask = (materializedTaskId, dir,step=null) => {
    return dispatch => {
        dispatch(requestManualTask());
        console.log('ManualTaskAction.js :: getNextOrPreviousTask :: Before axios request', dir);
        const stepParam = step!=null?"&step=".concat(step):'';
        const url = HOST_URL + `/api/materialized-task/${materializedTaskId}/navigate?dir=${dir}${stepParam}${getLanguage()}`;
        return axios.get(url
        ).then(response => {
            console.log('ManualTaskAction.js :: getNextOrPreviousTask :: response', response);
            if (response.status !== 200) {
                dispatch(errorManualTask(response.data));
                return Promise.reject(response.data);
            } else {
                console.log('ManualTaskAction.js :: getNextOrPreviousTask :: Before success');
                dispatch(successManualTask(response.data));
            }
        }).catch(err => dispatch(errorManualTask(err)));
    };
};


/** Action to recreate training of workflow.
 *  See @link https://docs.google.com/document/d/1YBhDgSbbWs-YExxYNgRU-ix1DXvFHOGea1ip_Hgs0eA/edit#bookmark=id.939oiqa3957x
 * @param experimentId
 * @param miniWorkflowSet
 * @param miniWorkflowKey
 * @param eventId - [optional] id of event which results should be associated with
 * @return {function(*, *)}
 */
export const recreateTrainingWorkflowInLP = (experimentId,miniWorkflowSet,miniWorkflowKey,eventId=null) => {
    return (dispatch, getState) => {
        dispatch(requestManualTask());
        dispatch(clearSkillResultsAC());

        const eventQueryParameter = (eventId!=null)?"&eventId=".concat(eventId):"";
        console.log('ManualTaskAction.js :: recreateTrainingWorkflowInLP :: Before axios request');
        // const url = HOST_URL + `/api/workload?livePresenterExperiment=${experimentId}`+
        //     `&currentStep=0&miniWorkflowSet=${miniWorkflowSet}&miniWorkflow=${miniWorkflowKey}&createNew=true${getLanguage()}`;
        const url = HOST_URL + `/api/workload?livePresenterExperiment=${experimentId}`+
            `&miniWorkflowSet=${miniWorkflowSet}&miniWorkflow=${miniWorkflowKey}&createNew=true${getLanguage()}${eventQueryParameter}`;
        return axios.get(url
        ).then(response => {
            console.log('ManualTaskAction.js :: recreateTrainingWorkflowInLP :: response', response);
            if (response.status !== 200) {
                dispatch(errorManualTask(response.data));
                return Promise.reject(response.data);
            } else {
                console.log('ManualTaskAction.js :: recreateTrainingWorkflowInLP :: Before success');
                dispatch(successManualTask(response.data));
            }
        }).catch(err => dispatch(errorManualTask(err)));

    }
};


/** Action to get new case for workflow (get real cases).
 *  Called from Live Presenter (Skill summary) to get real cases.
 *
 *  See @link https://docs.google.com/document/d/1YBhDgSbbWs-YExxYNgRU-ix1DXvFHOGea1ip_Hgs0eA/edit#bookmark=id.939oiqa3957x
 * @param liveExperimentId
 * @param history - React router object for navigation
 * @return {function(*, *)}
 */
export const getNewCase = (liveExperimentId,history) => {
    return (dispatch, getState) => {
        dispatch(requestManualTask());
        console.log('ManualTaskAction.js :: getNewCase :: Before axios request');
        const url = HOST_URL + `/api/live-presenter/experiment/${liveExperimentId}/case`;
        return axios.get(url
        ).then(response => {
            console.log('ManualTaskAction.js :: getNewCase :: response', response);
            if (response.status !== 200) {
                dispatch(errorManualTask(response.data));
                return Promise.reject(response.data);
            } else {
                console.log('ManualTaskAction.js :: getNewCase :: Before success');
                history.push(`/tool/${response.data.miniWorkflowKey}/${response.data.miniWorkflowSetId}/${response.data.experimentId}`);
            }
        }).catch(err => dispatch(errorManualTask(err)));

    }
};

/**
* Action Creator to set manual task (workload) in Store.
* @see https://docs.google.com/document/d/1o5A8nVP_dokX4aZJYkNvJOCPHUpyXTlcefQPXRezOaA/edit#heading=h.sngrzux18682
*
* @param workload - workflow set id
* @returns {function(*)}
*/
export const setManualTask = (workload) => {
    return dispatch => {
           dispatch(successManualTask(workload));
            }
};


const VIDEO_SEEN_CONFIRMATION = {
    "seen": true
};

/**
 * Action Creator to set .
 * @see https://docs.google.com/document/d/1xKTMyPi90C0ucf0NPhZ3UQ8LhaZ3X0ESrM1-z15tu8k/edit#bookmark=kix.yd0m291gkwob
 *
 * @param uuid - currentMaterializedTaskId
 * @returns {function(*)}
 */
export const setTutorialVideoState = (uuid) => {
    return dispatch => {
        const url = HOST_URL + `/api/materialized-task/${uuid}/video`;
        return axios.put(url, VIDEO_SEEN_CONFIRMATION)
            .then(response => {
            console.log('ManualTaskAction.js :: getNewCase :: response', response);
            if (response.status !== 200) {
            } else {
                console.log('ManualTaskAction.js :: setTutorialVideoState :: Before success');
                console.log('ManualTaskAction.js :: state:',response.data);
            }
        }).catch(err => dispatch(errorManualTask(err)));
    }
};

// Used for single result review mode
export const getManualTaskSingleReview = (workload) => {
    return dispatch => {
        dispatch(requestManualTask());
        dispatch(successManualTask(workload))
    };
};
