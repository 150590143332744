import React from "react";
import PropTypes from 'prop-types';
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import {Growl} from "primereact/components/growl/Growl";
import {setAcknowledgeSignal} from "../../../dashboard/action/ManualTaskAction";
/**
 * Panel with control components for task/miniworkflow task.
 * @returns {*}
 */
export class LPControlPanel extends React.Component {


    render() {
        const {currentStep,goToNextStep,goToPreviousStep,slides } = this.props;

        const isNotResultPage = (slides[currentStep].slideType.toUpperCase()!=="SCORE");

        const spinnerIcon =  <i className="fa fa-spinner fa-spin"
                                style={{marginLeft: "1em", fontSize: "xx-large", position: "fixed", right: "150px", top:"10px", color: "#FFFFFF",zIndex:"1001"}}/>;
        const backButton = (<a style={{fontSize: "xx-large"}}>
            <i className="fa fa-play fa-flip-horizontal topbarBackwardButton"
               onClick={()=>goToPreviousStep()}/>
        </a>);
        const forwardButton = (<a style={{fontSize: "xx-large"}}>
            <i className="fa fa-play topbarForwardButton"
               onClick={()=>goToNextStep()}/>
        </a>);

        return (
            <React.Fragment>
                {currentStep > 0 && isNotResultPage && backButton}
                {slides!=null && slides.length>0 && currentStep < (slides.length-1) && forwardButton}
            </React.Fragment>
        );
    }
};

// TODO Remove HACKS (onNext, onPrevious) when we find reasonable solution for presentation
LPControlPanel.propTypes = {
    currentStep: PropTypes.number.isRequired,
    goToPreviousStep: PropTypes.func.isRequired,
    goToNextStep:PropTypes.func.isRequired,
    slides:PropTypes.array.isRequired
};