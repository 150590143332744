import axios from 'axios';
import {HOST_URL} from "../../../Constants";
import store from "../../Store";
import fileDownload from "js-file-download";
import {RESULTS_DOWNLOAD_SUCCESS,
        RESULTS_DOWNLOAD_REQUEST,
        RESULTS_DOWNLOAD_FAIL } from './actionType'; 

//****************************************************************************
//
//                                Actions
//
//****************************************************************************
const requestResultsDownload = id => ({
    type: RESULTS_DOWNLOAD_REQUEST,
    id
});


const errorResultsDownload = (id, err) => ({
    type: RESULTS_DOWNLOAD_FAIL,
    id,
    err
});

const successResultsDownload = id => ({
    type: RESULTS_DOWNLOAD_SUCCESS,
    id
});


/**
 * Action to download a csv with results of an experiment given the parameters passed.
 * 
 * @param {*} experimentId Identifier/UUID of the experiment 
 * @param {*} workflowId Identifier/UUID of the workflow
 * @param {*} miniworkflowId Identifier/UUID of the mini-workflow
 * @param {*} miniworkflowSet Identifier/UUID of the mini-workflow set
 * @param {*} onError Callback in case an error occurs. It receives the errored response
 * @returns {function(*)}
 */ 
export const downloadExperimentResultsCSV = (experimentId, workflowId, miniworkflowId, miniworkflowSet, downloadId, onError) => {
    return dispatch => {
        //dispatch (requestActivityList());
        console.log('ResultsAction.js :: downloadExperimentResultsCSV :: Before axios request');
        const config = {
            headers: {'Authorization': `bearer${store.getState().auth.token_bearer}`},
            responseType: 'blob'
        };
        const url = `${HOST_URL}/api/experiment/${experimentId}/workflow/${workflowId}/mini-workflow-set/${miniworkflowSet}/mini-workflow/${miniworkflowId}/results-xls-v2`;
        dispatch(requestResultsDownload(downloadId));
        return axios.get(
            url,
            config
            ).then(response => {
                console.log('ResultsAction.js :: downloadExperimentResultsCSV :: response', response);
                if(response.status !== 200) {
                    onError(response);
                    return Promise.reject(response.data);
                } else {
                    console.log('ResultsAction.js :: downloadExperimentResultsCSV :: Before downloadExperimentResultsCSV');
                    const filename = response.headers['content-disposition']? response.headers['content-disposition'].split('filename=')[1] || 'results.xlsx' : 'results.xlsx';
                    fileDownload(response.data, filename);
                    dispatch(successResultsDownload(downloadId));
                }
            }).catch(err => {
                console.log(err)
                onError({message: 'Unable to download the results file'});
                dispatch(errorResultsDownload(downloadId, err))
            });
    };
};
