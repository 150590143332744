import axios from 'axios'
import {HOST_URL, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {
    TGAME_EXPERIMENT_RESULTS_FAIL, TGAME_EXPERIMENT_RESULTS_REQUEST,
    TGAME_EXPERIMENT_RESULTS_SUCCESS, TGAME_IMAGE_CLEAR, TGAME_IMAGE_FAIL, TGAME_IMAGE_REQUEST, TGAME_IMAGE_SUCCESS
} from "./actionType";
import {loggingDataset} from "../../vtk/MRIBrowserLogger";
import ReaderFactory from "../../vtk/ReaderFactory";
import vtkITKImageReader from "vtk.js/Sources/IO/Misc/ITKImageReader/index";
import readImageArrayBuffer from "../../vtk/readImageArrayBuffer";
import extensionToImageIO from "itk/extensionToImageIO";
import {create_data2} from "../component/d3components/DataHelper";
import {MOCKED_EXPERTS_DATA} from "../tests/Mocks";


//================getting experiment results

const requestTabletGameResults = (tabletExperimentId) => ({
    type: TGAME_EXPERIMENT_RESULTS_REQUEST,
    tabletExperimentId
});

const errorTabletGameResults = (err,tabletExperimentId) => ({
    type: TGAME_EXPERIMENT_RESULTS_FAIL,
    err,
    tabletExperimentId
});

const successTabletGameResults = (results,tabletExperimentId) => ({
    type: TGAME_EXPERIMENT_RESULTS_SUCCESS,
    results,
    tabletExperimentId
});


/**
 * ActionCreator for getting results for Tablet Game Experiment.
 * @param tabletExperimentId - id of "special-type experiment"
 * @returns {function(*, *)}
 */
export const getTabletGameExperimentResults = (tabletExperimentId) => {
    console.log('TabletGameExperimentAction::getTabletGameExperimentResults:request');
    return (dispatch,getState) => {
        axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
        dispatch(requestTabletGameResults(tabletExperimentId));
        axios.get(HOST_URL + '/api/game/result?tabletExperimentId='+tabletExperimentId)
            .then(response => {
                console.log('TabletGameExperimentAction.js :: getTabletGameExperimentResults :: response ::', response)
                if (response.status !== 200) {
                    dispatch(errorTabletGameResults(error,tabletExperimentId))
                } else {
                    dispatch(successTabletGameResults(response.data));
                }
            }).catch(error => {
            console.log('TabletGameExperimentAction.js :: getTabletGameExperimentResults :: error ::', error);
            dispatch(errorTabletGameResults(error,tabletExperimentId))
        });
    }
};

const extensions = Array.from(
    new Set(Object.keys(extensionToImageIO).map((ext) => ext.toLowerCase()))
);
vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer); //required by external library
extensions.forEach((extension) => {
    ReaderFactory.registerReader({
        extension,
        name: `${extension.toUpperCase()} Reader`,
        vtkReader: vtkITKImageReader,
        binary: true,
        fileNameMethod: 'setFileName',
    });
});


const requestTabletGameImage = (propertyName) => ({
    type: TGAME_IMAGE_REQUEST,
    propertyName
});

const errorTabletGameImage = (error,propertyName) => ({
    type: TGAME_IMAGE_FAIL,
    error,
    propertyName
});

const successTabletGameImage = (image,propertyName) => ({
    type: TGAME_IMAGE_SUCCESS,
    image,
    propertyName
});

const clearTabletGameImage = (propertyName) => ({
    type: TGAME_IMAGE_CLEAR,
    propertyName
});

/**
 * ActionCreator for reading ALL images and variables.
 * @param imageProperties - object {fid:"0052bfbd-774b-4ae4-ad1a-e5bf79034288", propertyName:"mriImage",isOverlay:false}
 * @returns {function(*,*)}
 */
export const getImageActionCreator = (imageProperties) => {
    console.log('TabletGameExperimentAction::getTabletGameExperimentResults:request');
    return dispatch => {
        const {fid,propertyName,roomShortId,tabletExperimentId} = imageProperties;
        console.log("Reading image");
        dispatch(requestTabletGameImage(propertyName));
        let uri = null;
        let imgFormat = null;
        switch(propertyName){
            case 'mriImage':{
                imgFormat = 'or.nii.gz';
                // uri = `/overlay/${fid}/original`;//MOCK
                uri = `/game/tabletExperiment/${tabletExperimentId}/input`; //UNCOMMENT
            break;}
            case "leftOverlay":
            case "rightOverlay":{
                imgFormat = 'or.nii.gz';
                // uri = `/overlay/${fid}`;//MOCK
                uri = `/game/image/${fid}`;
                break;
            }
            case "leftFreq":
            case "rightFreq":{
               imgFormat = 'or.nii';
               // uri = `/overlay/${fid}`;//MOCK
               uri =  `/game/room/${roomShortId}/frequency-map?tabletExperimentId=${tabletExperimentId}`; //UNCOMMENT
               break;
            }
        }

        ReaderFactory.downloadDataset(imgFormat,uri, null)
            .then((result) => {
                const reader = result.reader;
                const results = {};
                results['data'] = reader.getOutputData();
                if (!propertyName.includes('mriImage'))   //only mriImage does not require port
                    results['port'] = reader.getOutputPort();
                dispatch(successTabletGameImage(results, propertyName));
            }).catch((error) => {
            dispatch(errorTabletGameImage(error, propertyName));
        })
    }
};
/**
 * Clearing image variables for a given experiment. Id of image is not needed,
 * since this clears data bound to viewer only.
 * @param propertyName -  just name of store property to clear (eg. leftOverlay)
 * @returns {function(*)}
 */
export const clearOverlayImageActionCreator = (propertyName) => {
    console.log('TabletGameExperimentAction::getTabletGameExperimentResults:request');
    return dispatch => {
        dispatch(clearTabletGameImage(propertyName));
    }
};



/**
 * ActionCreator for getting results for Tablet Game Experiment.
 * @param tabletExperimentId - id of "special-type experiment"
 * @returns {function(*, *)}
 */
export const getINOSResults = (tabletExperimentId) => {
    console.log('TabletGameExperimentAction::getINOSResults:request');
    if (!(tabletExperimentId!=null))
        return (dispatch,getState) => {
            dispatch(successTabletGameResults(MOCKED_DATA_FOR_DYNAMIC_HISTOGRAM));
        };
    else
    return (dispatch,getState) => {
        axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
        dispatch(requestTabletGameResults(tabletExperimentId));
        axios.get(HOST_URL + '/api/game/result?tabletExperimentId='+tabletExperimentId)
          .then(response => {
              console.log('TabletGameExperimentAction.js :: getTabletGameExperimentResults :: response ::', response)
              if (response.status !== 200) {
                  dispatch(errorTabletGameResults(error,tabletExperimentId))
              } else {
                  dispatch(successTabletGameResults(response.data));
              }
          }).catch(error => {
            console.log('TabletGameExperimentAction.js :: getTabletGameExperimentResults :: error ::', error);
            dispatch(errorTabletGameResults(error,tabletExperimentId))
        });
    }
};

export const MOCKED_DATA_FOR_DYNAMIC_HISTOGRAM =
  {
      "dataDescription": {
          "clinical": {
              "CSCT": {
                  "name": "CSCT",
                  "unit": null
              },
              "NHPT-MD": {
                  "name": "NHPT-MD",
                  "unit": null
              },
              "NHPT-MND": {
                  "name": "NHPT-MND",
                  "unit": null
              },
              "age": {
                  "name": "Age",
                  "unit": "years"
              },
              "edss": {
                  "name": "EDSS",
                  "unit": null
              },
              "edu": {
                  "name": "Education",
                  "unit": "(0=Bas, 1=Haut)"
              },
              "group": {
                  "name": "Group",
                  "unit": "(1=Patient,2=Controle)"
              },
              "lesion_vol": {
                  "name": "Lesion volume",
                  "unit": "ml"
              },
              "mucca": {
                  "name": "MUCCA",
                  "unit": null
              },
              "sex": {
                  "name": "Sex",
                  "unit": "(1=M,2=F)"
              },
              "t25fw": {
                  "name": "t25fw",
                  "unit": "s"
              }
          },
          "measurement": {
              "volume": {
                  "name": "Volume",
                  "unit": "cc"
              },
              "volume_mean": {
                  "name": "Axial mean volume",
                  "unit": "cc"
              },
              "volume_mean_normalized": {
                  "name": "Axial mean volume normalized by ICC",
                  "unit": "cc"
              },
              "volume_normalized": {
                  "name": "Volume normalized by ICC",
                  "unit": "cc"
              }
          }
      },
      "cases": {
          "P32JC64_V0": {
              "measurement": {
                  "volume": {
                      "originalImageId": "379a9ef5-20a6-40be-9ee9-2d98ebbc85e7",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de669364d6b",
                              "value": 0.6049997115135193,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "1bd19afa-ad19-4847-9ec1-81bd69a9d970"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "379a9ef5-20a6-40be-9ee9-2d98ebbc85e7",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de669364d6b",
                              "value": 0.6049997115135193,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "1bd19afa-ad19-4847-9ec1-81bd69a9d970"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "379a9ef5-20a6-40be-9ee9-2d98ebbc85e7",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de669364d6b",
                              "value": 0.12099994230270386,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "1bd19afa-ad19-4847-9ec1-81bd69a9d970"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "379a9ef5-20a6-40be-9ee9-2d98ebbc85e7",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de669364d6b",
                              "value": 0.12099994230270386,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "1bd19afa-ad19-4847-9ec1-81bd69a9d970"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 46,
                  "NHPT-MD": 22,
                  "NHPT-MND": 22,
                  "age": 49,
                  "edss": 1,
                  "edu": 0,
                  "group": 1,
                  "lesion_vol": 0.22,
                  "sex": 1,
                  "t25fw": 8
              }
          },
          "P45BD66_V0": {
              "measurement": {
                  "volume": {
                      "originalImageId": "7cf4cc4b-b690-4d3b-bd14-ad64e7504a62",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de669398aaa",
                              "value": 0.8450771605968476,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "67356a6e-97c1-442a-bec3-214bf88402f9"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "7cf4cc4b-b690-4d3b-bd14-ad64e7504a62",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de669398aaa",
                              "value": 0.8450771605968476,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "67356a6e-97c1-442a-bec3-214bf88402f9"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "7cf4cc4b-b690-4d3b-bd14-ad64e7504a62",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de669398aaa",
                              "value": 0.1690154321193695,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "67356a6e-97c1-442a-bec3-214bf88402f9"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "7cf4cc4b-b690-4d3b-bd14-ad64e7504a62",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de669398aaa",
                              "value": 0.1690154321193695,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "67356a6e-97c1-442a-bec3-214bf88402f9"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 62,
                  "NHPT-MD": 22.8,
                  "NHPT-MND": 26.25,
                  "age": 49,
                  "edss": 2,
                  "edu": 1,
                  "group": 1,
                  "lesion_vol": 0.24,
                  "sex": 1,
                  "t25fw": 4.6
              }
          },
          "P29AM83_V5": {
              "measurement": {
                  "volume": {
                      "originalImageId": "0a626bd8-7f41-482f-928e-682e9659bcce",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6693b5559",
                              "value": 0.04499623954296112,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "71c34d00-b8b6-4263-b695-462a0767ea9f"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "0a626bd8-7f41-482f-928e-682e9659bcce",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6693b5559",
                              "value": 0.04499623954296112,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "71c34d00-b8b6-4263-b695-462a0767ea9f"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "0a626bd8-7f41-482f-928e-682e9659bcce",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6693b5559",
                              "value": 0.008999247908592224,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "71c34d00-b8b6-4263-b695-462a0767ea9f"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "0a626bd8-7f41-482f-928e-682e9659bcce",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6693b5559",
                              "value": 0.008999247908592224,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "71c34d00-b8b6-4263-b695-462a0767ea9f"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 55,
                  "NHPT-MD": 26.8,
                  "NHPT-MND": 23.8,
                  "age": 36,
                  "edss": 0,
                  "edu": 1,
                  "group": 1,
                  "lesion_vol": 17.072,
                  "sex": 1,
                  "t25fw": 3.8
              }
          },
          "T20JT62_V5": {
              "measurement": {
                  "volume": {
                      "originalImageId": "17926e96-8ab6-4517-b5b4-6f3ab08642ce",
                      "contribution": [
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de6693ca223",
                              "value": 0.422971106171608,
                              "userInformation": {
                                  "birthYear": "1990",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Etudiant - Santé"
                              },
                              "workflow execution uuid": "80f0af79-ce44-4c2e-ad04-36543dcd6360"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "17926e96-8ab6-4517-b5b4-6f3ab08642ce",
                      "contribution": [
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de6693ca223",
                              "value": 0.422971106171608,
                              "userInformation": {
                                  "birthYear": "1990",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Etudiant - Santé"
                              },
                              "workflow execution uuid": "80f0af79-ce44-4c2e-ad04-36543dcd6360"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "17926e96-8ab6-4517-b5b4-6f3ab08642ce",
                      "contribution": [
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de6693ca223",
                              "value": 0.0845942212343216,
                              "userInformation": {
                                  "birthYear": "1990",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Etudiant - Santé"
                              },
                              "workflow execution uuid": "80f0af79-ce44-4c2e-ad04-36543dcd6360"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "17926e96-8ab6-4517-b5b4-6f3ab08642ce",
                      "contribution": [
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de6693ca223",
                              "value": 0.0845942212343216,
                              "userInformation": {
                                  "birthYear": "1990",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Etudiant - Santé"
                              },
                              "workflow execution uuid": "80f0af79-ce44-4c2e-ad04-36543dcd6360"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 53,
                  "NHPT-MD": null,
                  "NHPT-MND": null,
                  "age": 56,
                  "edss": null,
                  "edu": 1,
                  "group": 2,
                  "lesion_vol": null,
                  "sex": 1,
                  "t25fw": null
              }
          },
          "P46ND66_V0": {
              "measurement": {
                  "volume": {
                      "originalImageId": "1c1d9544-743b-47a4-a4f1-f3408fbb1bbc",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66938acfa",
                              "value": 5767.04390625,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "8ac6cce5-4935-48dc-9030-009c670c160d"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "1c1d9544-743b-47a4-a4f1-f3408fbb1bbc",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66938acfa",
                              "value": 5767.04390625,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "8ac6cce5-4935-48dc-9030-009c670c160d"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "1c1d9544-743b-47a4-a4f1-f3408fbb1bbc",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66938acfa",
                              "value": 1153.40878125,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "8ac6cce5-4935-48dc-9030-009c670c160d"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "1c1d9544-743b-47a4-a4f1-f3408fbb1bbc",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66938acfa",
                              "value": 1153.40878125,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "8ac6cce5-4935-48dc-9030-009c670c160d"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 42,
                  "NHPT-MD": 21.5,
                  "NHPT-MND": 22.5,
                  "age": 48,
                  "edss": 1,
                  "edu": 0,
                  "group": 1,
                  "lesion_vol": 0.58,
                  "sex": 2,
                  "t25fw": 4.96
              }
          },
          "P54MG70_V1": {
              "measurement": {
                  "volume": {
                      "originalImageId": "1bc7483d-27d2-49e0-b9b0-f2451cf88203",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6694107fd",
                              "value": 0.604930799305439,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "a6a4205b-2f19-4077-a764-b6d5fcbdd489"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "1bc7483d-27d2-49e0-b9b0-f2451cf88203",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6694107fd",
                              "value": 0.604930799305439,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "a6a4205b-2f19-4077-a764-b6d5fcbdd489"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "1bc7483d-27d2-49e0-b9b0-f2451cf88203",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6694107fd",
                              "value": 0.1209861598610878,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "a6a4205b-2f19-4077-a764-b6d5fcbdd489"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "1bc7483d-27d2-49e0-b9b0-f2451cf88203",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6694107fd",
                              "value": 0.1209861598610878,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "a6a4205b-2f19-4077-a764-b6d5fcbdd489"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 58,
                  "NHPT-MD": null,
                  "NHPT-MND": null,
                  "age": 46,
                  "edss": 1,
                  "edu": 1,
                  "group": 1,
                  "lesion_vol": 0.71,
                  "sex": 2,
                  "t25fw": null
              }
          },
          "T20JT62_V0": {
              "measurement": {
                  "volume": {
                      "originalImageId": "e5e116a4-2414-4018-8c5f-13d6dbe886c0",
                      "contribution": [
                          {
                              "userId": "0b16f866-191d-43a3-8d1b-25e828ae5093",
                              "segmentationId": "5a1deda3d848809560e34de669404b7c",
                              "value": 7.644012757301331,
                              "userInformation": {
                                  "birthYear": "1960",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Non-partagé",
                                  "language": "English",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "c32e7906-95b3-4419-a185-23e6f0e45056"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "e5e116a4-2414-4018-8c5f-13d6dbe886c0",
                      "contribution": [
                          {
                              "userId": "0b16f866-191d-43a3-8d1b-25e828ae5093",
                              "segmentationId": "5a1deda3d848809560e34de669404b7c",
                              "value": 7.644012757301331,
                              "userInformation": {
                                  "birthYear": "1960",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Non-partagé",
                                  "language": "English",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "c32e7906-95b3-4419-a185-23e6f0e45056"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "e5e116a4-2414-4018-8c5f-13d6dbe886c0",
                      "contribution": [
                          {
                              "userId": "0b16f866-191d-43a3-8d1b-25e828ae5093",
                              "segmentationId": "5a1deda3d848809560e34de669404b7c",
                              "value": 1.5288025514602661,
                              "userInformation": {
                                  "birthYear": "1960",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Non-partagé",
                                  "language": "English",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "c32e7906-95b3-4419-a185-23e6f0e45056"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "e5e116a4-2414-4018-8c5f-13d6dbe886c0",
                      "contribution": [
                          {
                              "userId": "0b16f866-191d-43a3-8d1b-25e828ae5093",
                              "segmentationId": "5a1deda3d848809560e34de669404b7c",
                              "value": 1.5288025514602661,
                              "userInformation": {
                                  "birthYear": "1960",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Non-partagé",
                                  "language": "English",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "c32e7906-95b3-4419-a185-23e6f0e45056"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 54,
                  "NHPT-MD": 17.25,
                  "NHPT-MND": 21.45,
                  "age": 51,
                  "edss": null,
                  "edu": 1,
                  "group": 2,
                  "lesion_vol": null,
                  "sex": 1,
                  "t25fw": 3.5
              }
          },
          "P19SS82_V0": {
              "measurement": {
                  "volume": {
                      "originalImageId": "90645e17-18fb-4721-b73e-e56e8bf3077f",
                      "contribution": [
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de6693d05dc",
                              "value": 0.605,
                              "userInformation": {
                                  "birthYear": "1990",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Etudiant - Santé"
                              },
                              "workflow execution uuid": "d419868a-0540-494b-977c-42f64e7d7564"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "90645e17-18fb-4721-b73e-e56e8bf3077f",
                      "contribution": [
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de6693d05dc",
                              "value": 0.605,
                              "userInformation": {
                                  "birthYear": "1990",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Etudiant - Santé"
                              },
                              "workflow execution uuid": "d419868a-0540-494b-977c-42f64e7d7564"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "90645e17-18fb-4721-b73e-e56e8bf3077f",
                      "contribution": [
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de6693d05dc",
                              "value": 0.121,
                              "userInformation": {
                                  "birthYear": "1990",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Etudiant - Santé"
                              },
                              "workflow execution uuid": "d419868a-0540-494b-977c-42f64e7d7564"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "90645e17-18fb-4721-b73e-e56e8bf3077f",
                      "contribution": [
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de6693d05dc",
                              "value": 0.121,
                              "userInformation": {
                                  "birthYear": "1990",
                                  "countryOfResidence": "Afghanistan",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Etudiant - Santé"
                              },
                              "workflow execution uuid": "d419868a-0540-494b-977c-42f64e7d7564"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 47,
                  "NHPT-MD": 22.5,
                  "NHPT-MND": 24,
                  "age": 31,
                  "edss": 0,
                  "edu": 0,
                  "group": 1,
                  "lesion_vol": 3.9,
                  "sex": 2,
                  "t25fw": 6.71
              }
          },
          "P44VB87_V0": {
              "measurement": {
                  "volume": {
                      "originalImageId": "02bd3538-7e8c-4678-ad21-c9571e3670c7",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6693910e1",
                              "value": 0.1210110490322113,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "da27f269-d18f-41de-9a9d-f1efb48d6fad"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "02bd3538-7e8c-4678-ad21-c9571e3670c7",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6693910e1",
                              "value": 0.1210110490322113,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "da27f269-d18f-41de-9a9d-f1efb48d6fad"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "02bd3538-7e8c-4678-ad21-c9571e3670c7",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6693910e1",
                              "value": 0.024202209806442262,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "da27f269-d18f-41de-9a9d-f1efb48d6fad"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "02bd3538-7e8c-4678-ad21-c9571e3670c7",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de6693910e1",
                              "value": 0.024202209806442262,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "da27f269-d18f-41de-9a9d-f1efb48d6fad"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 61,
                  "NHPT-MD": 14.95,
                  "NHPT-MND": 19,
                  "age": 28,
                  "edss": 1,
                  "edu": 1,
                  "group": 1,
                  "lesion_vol": 0.25,
                  "sex": 2,
                  "t25fw": 4.1
              }
          },
          "P47KI70_V1": {
              "measurement": {
                  "volume": {
                      "originalImageId": "0e25f293-cfed-404a-8e47-de53d33059e4",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66939e877",
                              "value": 0,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "da53b041-9574-4542-9321-5f7b26b1137f"
                          },
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de66939e877",
                              "value": 0.3,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "da53b041-9574-4542-9321-5f7b26b1137f"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "0e25f293-cfed-404a-8e47-de53d33059e4",
                      "contribution": [ {
                          "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                          "segmentationId": "5a1deda3d848809560e34de66939e877",
                          "value": 0,
                          "userInformation": {
                              "birthYear": "1993",
                              "countryOfResidence": "France",
                              "gender": "Masculin",
                              "language": "Français",
                              "profession": "Autre (extérieur a l'université)"
                          },
                          "workflow execution uuid": "da53b041-9574-4542-9321-5f7b26b1137f"
                      },
                          {
                              "userId": "bc77cad2-5755-48db-ae16-2367814b2ec1",
                              "segmentationId": "5a1deda3d848809560e34de66939e877",
                              "value": 0.3,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "da53b041-9574-4542-9321-5f7b26b1137f"
                          }]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "0e25f293-cfed-404a-8e47-de53d33059e4",
                      "contribution": []
                  },
                  "volume_mean": {
                      "originalImageId": "0e25f293-cfed-404a-8e47-de53d33059e4",
                      "contribution": []
                  }
              },
              "clinical": {
                  "CSCT": 67,
                  "NHPT-MD": 19.75,
                  "NHPT-MND": 19,
                  "age": 45,
                  "edss": 1.5,
                  "edu": 0,
                  "group": 1,
                  "lesion_vol": 0.48,
                  "sex": 2,
                  "t25fw": 4.75
              }
          },
          "P13SP68_V0": {
              "measurement": {
                  "volume": {
                      "originalImageId": "dfed6c4d-41a8-4111-8790-222ed067a096",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66936cbf0",
                              "value": 0.605,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "db9d2eb6-230e-48ee-94d2-7e1bf6aa1a4b"
                          }
                      ]
                  },
                  "volume_normalized": {
                      "originalImageId": "dfed6c4d-41a8-4111-8790-222ed067a096",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66936cbf0",
                              "value": 0.605,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "db9d2eb6-230e-48ee-94d2-7e1bf6aa1a4b"
                          }
                      ]
                  },
                  "volume_mean_normalized": {
                      "originalImageId": "dfed6c4d-41a8-4111-8790-222ed067a096",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66936cbf0",
                              "value": 0.121,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "db9d2eb6-230e-48ee-94d2-7e1bf6aa1a4b"
                          }
                      ]
                  },
                  "volume_mean": {
                      "originalImageId": "dfed6c4d-41a8-4111-8790-222ed067a096",
                      "contribution": [
                          {
                              "userId": "5705e62b-8fa2-4432-87e8-9014be64f203",
                              "segmentationId": "5a1deda3d848809560e34de66936cbf0",
                              "value": 0.121,
                              "userInformation": {
                                  "birthYear": "1993",
                                  "countryOfResidence": "France",
                                  "gender": "Masculin",
                                  "language": "Français",
                                  "profession": "Autre (extérieur a l'université)"
                              },
                              "workflow execution uuid": "db9d2eb6-230e-48ee-94d2-7e1bf6aa1a4b"
                          }
                      ]
                  }
              },
              "clinical": {
                  "CSCT": 51,
                  "NHPT-MD": 17.5,
                  "NHPT-MND": 19,
                  "age": 45,
                  "edss": 1,
                  "edu": 1,
                  "group": 1,
                  "lesion_vol": 10.21,
                  "sex": 2,
                  "t25fw": 4
              }
          }
      },
      "studyLUT": {
          "P01RD83_V0": {
              "subjectLabel": "P01RD83",
              "visitLabel": "V0"
          },
          "P01RD83_V1": {
              "subjectLabel": "P01RD83",
              "visitLabel": "V1"
          },
          "P01RD83_V5": {
              "subjectLabel": "P01RD83",
              "visitLabel": "V5"
          },
          "P03CS78_V0": {
              "subjectLabel": "P03CS78",
              "visitLabel": "V0"
          },
          "P03CS78_V1": {
              "subjectLabel": "P03CS78",
              "visitLabel": "V1"
          },
          "P03CS78_V5": {
              "subjectLabel": "P03CS78",
              "visitLabel": "V5"
          },
          "P04ED88_V0": {
              "subjectLabel": "P04ED88",
              "visitLabel": "V0"
          },
          "P04ED88_V1": {
              "subjectLabel": "P04ED88",
              "visitLabel": "V1"
          },
          "P04ED88_V5": {
              "subjectLabel": "P04ED88",
              "visitLabel": "V5"
          },
          "P07DR76_V0": {
              "subjectLabel": "P07DR76",
              "visitLabel": "V0"
          },
          "P07DR76_V1": {
              "subjectLabel": "P07DR76",
              "visitLabel": "V1"
          },
          "P07DR76_V5": {
              "subjectLabel": "P07DR76",
              "visitLabel": "V5"
          },
          "P09JR54_V0": {
              "subjectLabel": "P09JR54",
              "visitLabel": "V0"
          },
          "P09JR54_V1": {
              "subjectLabel": "P09JR54",
              "visitLabel": "V1"
          },
          "P09JR54_V5": {
              "subjectLabel": "P09JR54",
              "visitLabel": "V5"
          },
          "P13SP68_V0": {
              "subjectLabel": "P13SP68",
              "visitLabel": "V0"
          },
          "P13SP68_V1": {
              "subjectLabel": "P13SP68",
              "visitLabel": "V1"
          },
          "P13SP68_V5": {
              "subjectLabel": "P13SP68",
              "visitLabel": "V5"
          },
          "P14OB91_V0": {
              "subjectLabel": "P14OB91",
              "visitLabel": "V0"
          },
          "P14OB91_V1": {
              "subjectLabel": "P14OB91",
              "visitLabel": "V1"
          },
          "P14OB91_V5": {
              "subjectLabel": "P14OB91",
              "visitLabel": "V5"
          },
          "P15AB77_V0": {
              "subjectLabel": "P15AB77",
              "visitLabel": "V0"
          },
          "P15AB77_V1": {
              "subjectLabel": "P15AB77",
              "visitLabel": "V1"
          },
          "P15AB77_V5": {
              "subjectLabel": "P15AB77",
              "visitLabel": "V5"
          },
          "P17CD80_V0": {
              "subjectLabel": "P17CD80",
              "visitLabel": "V0"
          },
          "P17CD80_V1": {
              "subjectLabel": "P17CD80",
              "visitLabel": "V1"
          },
          "P17CD80_V5": {
              "subjectLabel": "P17CD80",
              "visitLabel": "V5"
          },
          "P19SS82_V0": {
              "subjectLabel": "P19SS82",
              "visitLabel": "V0"
          },
          "P19SS82_V1": {
              "subjectLabel": "P19SS82",
              "visitLabel": "V1"
          },
          "P19SS82_V5": {
              "subjectLabel": "P19SS82",
              "visitLabel": "V5"
          },
          "P21MC59_V0": {
              "subjectLabel": "P21MC59",
              "visitLabel": "V0"
          },
          "P21MC59_V1": {
              "subjectLabel": "P21MC59",
              "visitLabel": "V1"
          },
          "P21MC59_V5": {
              "subjectLabel": "P21MC59",
              "visitLabel": "V5"
          },
          "P22BF74_V0": {
              "subjectLabel": "P22BF74",
              "visitLabel": "V0"
          },
          "P22BF74_V1": {
              "subjectLabel": "P22BF74",
              "visitLabel": "V1"
          },
          "P22BF74_V5": {
              "subjectLabel": "P22BF74",
              "visitLabel": "V5"
          },
          "P25CG70_V0": {
              "subjectLabel": "P25CG70",
              "visitLabel": "V0"
          },
          "P25CG70_V1": {
              "subjectLabel": "P25CG70",
              "visitLabel": "V1"
          },
          "P25CG70_V5": {
              "subjectLabel": "P25CG70",
              "visitLabel": "V5"
          },
          "P26HL68_V0": {
              "subjectLabel": "P26HL68",
              "visitLabel": "V0"
          },
          "P26HL68_V1": {
              "subjectLabel": "P26HL68",
              "visitLabel": "V1"
          },
          "P26HL68_V5": {
              "subjectLabel": "P26HL68",
              "visitLabel": "V5"
          },
          "P29AM83_V0": {
              "subjectLabel": "P29AM83",
              "visitLabel": "V0"
          },
          "P29AM83_V1": {
              "subjectLabel": "P29AM83",
              "visitLabel": "V1"
          },
          "P29AM83_V5": {
              "subjectLabel": "P29AM83",
              "visitLabel": "V5"
          },
          "P32JC64_V0": {
              "subjectLabel": "P32JC64",
              "visitLabel": "V0"
          },
          "P32JC64_V1": {
              "subjectLabel": "P32JC64",
              "visitLabel": "V1"
          },
          "P32JC64_V5": {
              "subjectLabel": "P32JC64",
              "visitLabel": "V5"
          },
          "P33IL77_V0": {
              "subjectLabel": "P33IL77",
              "visitLabel": "V0"
          },
          "P33IL77_V1": {
              "subjectLabel": "P33IL77",
              "visitLabel": "V1"
          },
          "P33IL77_V5": {
              "subjectLabel": "P33IL77",
              "visitLabel": "V5"
          },
          "P35CR70_V0": {
              "subjectLabel": "P35CR70",
              "visitLabel": "V0"
          },
          "P35CR70_V1": {
              "subjectLabel": "P35CR70",
              "visitLabel": "V1"
          },
          "P35CR70_V5": {
              "subjectLabel": "P35CR70",
              "visitLabel": "V5"
          },
          "P36LR89_V0": {
              "subjectLabel": "P36LR89",
              "visitLabel": "V0"
          },
          "P36LR89_V1": {
              "subjectLabel": "P36LR89",
              "visitLabel": "V1"
          },
          "P36LR89_V5": {
              "subjectLabel": "P36LR89",
              "visitLabel": "V5"
          },
          "P37VF69_V0": {
              "subjectLabel": "P37VF69",
              "visitLabel": "V0"
          },
          "P37VF69_V1": {
              "subjectLabel": "P37VF69",
              "visitLabel": "V1"
          },
          "P37VF69_V5": {
              "subjectLabel": "P37VF69",
              "visitLabel": "V5"
          },
          "P39SD83_V0": {
              "subjectLabel": "P39SD83",
              "visitLabel": "V0"
          },
          "P39SD83_V1": {
              "subjectLabel": "P39SD83",
              "visitLabel": "V1"
          },
          "P39SD83_V5": {
              "subjectLabel": "P39SD83",
              "visitLabel": "V5"
          },
          "P41AD82_V0": {
              "subjectLabel": "P41AD82",
              "visitLabel": "V0"
          },
          "P41AD82_V1": {
              "subjectLabel": "P41AD82",
              "visitLabel": "V1"
          },
          "P41AD82_V5": {
              "subjectLabel": "P41AD82",
              "visitLabel": "V5"
          },
          "P44VB87_V0": {
              "subjectLabel": "P44VB87",
              "visitLabel": "V0"
          },
          "P44VB87_V1": {
              "subjectLabel": "P44VB87",
              "visitLabel": "V1"
          },
          "P44VB87_V5": {
              "subjectLabel": "P44VB87",
              "visitLabel": "V5"
          },
          "P45BD66_V0": {
              "subjectLabel": "P45BD66",
              "visitLabel": "V0"
          },
          "P45BD66_V1": {
              "subjectLabel": "P45BD66",
              "visitLabel": "V1"
          },
          "P45BD66_V5": {
              "subjectLabel": "P45BD66",
              "visitLabel": "V5"
          },
          "P46ND66_V0": {
              "subjectLabel": "P46ND66",
              "visitLabel": "V0"
          },
          "P46ND66_V1": {
              "subjectLabel": "P46ND66",
              "visitLabel": "V1"
          },
          "P46ND66_V5": {
              "subjectLabel": "P46ND66",
              "visitLabel": "V5"
          },
          "P47KI70_V0": {
              "subjectLabel": "P47KI70",
              "visitLabel": "V0"
          },
          "P47KI70_V1": {
              "subjectLabel": "P47KI70",
              "visitLabel": "V1"
          },
          "P47KI70_V5": {
              "subjectLabel": "P47KI70",
              "visitLabel": "V5"
          },
          "P48JJ88_V0": {
              "subjectLabel": "P48JJ88",
              "visitLabel": "V0"
          },
          "P48JJ88_V1": {
              "subjectLabel": "P48JJ88",
              "visitLabel": "V1"
          },
          "P48JJ88_V5": {
              "subjectLabel": "P48JJ88",
              "visitLabel": "V5"
          },
          "P50MG58_V0": {
              "subjectLabel": "P50MG58",
              "visitLabel": "V0"
          },
          "P50MG58_V1": {
              "subjectLabel": "P50MG58",
              "visitLabel": "V1"
          },
          "P50MG58_V5": {
              "subjectLabel": "P50MG58",
              "visitLabel": "V5"
          },
          "P51MF97_V0": {
              "subjectLabel": "P51MF97",
              "visitLabel": "V0"
          },
          "P51MF97_V1": {
              "subjectLabel": "P51MF97",
              "visitLabel": "V1"
          },
          "P51MF97_V5": {
              "subjectLabel": "P51MF97",
              "visitLabel": "V5"
          },
          "P53GM87_V0": {
              "subjectLabel": "P53GM87",
              "visitLabel": "V0"
          },
          "P53GM87_V1": {
              "subjectLabel": "P53GM87",
              "visitLabel": "V1"
          },
          "P53GM87_V5": {
              "subjectLabel": "P53GM87",
              "visitLabel": "V5"
          },
          "P54MG70_V0": {
              "subjectLabel": "P54MG70",
              "visitLabel": "V0"
          },
          "P54MG70_V1": {
              "subjectLabel": "P54MG70",
              "visitLabel": "V1"
          },
          "P54MG70_V5": {
              "subjectLabel": "P54MG70",
              "visitLabel": "V5"
          },
          "P55SV82_V0": {
              "subjectLabel": "P55SV82",
              "visitLabel": "V0"
          },
          "P55SV82_V1": {
              "subjectLabel": "P55SV82",
              "visitLabel": "V1"
          },
          "P55SV82_V5": {
              "subjectLabel": "P55SV82",
              "visitLabel": "V5"
          },
          "T01PS84_V0": {
              "subjectLabel": "T01PS84",
              "visitLabel": "V0"
          },
          "T01PS84_V1": {
              "subjectLabel": "T01PS84",
              "visitLabel": "V1"
          },
          "T01PS84_V5": {
              "subjectLabel": "T01PS84",
              "visitLabel": "V5"
          },
          "T05SG78_V0": {
              "subjectLabel": "T05SG78",
              "visitLabel": "V0"
          },
          "T05SG78_V1": {
              "subjectLabel": "T05SG78",
              "visitLabel": "V1"
          },
          "T05SG78_V5": {
              "subjectLabel": "T05SG78",
              "visitLabel": "V5"
          },
          "T10SG77_V0": {
              "subjectLabel": "T10SG77",
              "visitLabel": "V0"
          },
          "T10SG77_V1": {
              "subjectLabel": "T10SG77",
              "visitLabel": "V1"
          },
          "T10SG77_V5": {
              "subjectLabel": "T10SG77",
              "visitLabel": "V5"
          },
          "T11HF77_V0": {
              "subjectLabel": "T11HF77",
              "visitLabel": "V0"
          },
          "T11HF77_V1": {
              "subjectLabel": "T11HF77",
              "visitLabel": "V1"
          },
          "T11HF77_V5": {
              "subjectLabel": "T11HF77",
              "visitLabel": "V5"
          },
          "T16MB68_V0": {
              "subjectLabel": "T16MB68",
              "visitLabel": "V0"
          },
          "T16MB68_V1": {
              "subjectLabel": "T16MB68",
              "visitLabel": "V1"
          },
          "T16MB68_V5": {
              "subjectLabel": "T16MB68",
              "visitLabel": "V5"
          },
          "T20JT62_V0": {
              "subjectLabel": "T20JT62",
              "visitLabel": "V0"
          },
          "T20JT62_V1": {
              "subjectLabel": "T20JT62",
              "visitLabel": "V1"
          },
          "T20JT62_V5": {
              "subjectLabel": "T20JT62",
              "visitLabel": "V5"
          },
          "T21ST76_V0": {
              "subjectLabel": "T21ST76",
              "visitLabel": "V0"
          },
          "T21ST76_V1": {
              "subjectLabel": "T21ST76",
              "visitLabel": "V1"
          },
          "T21ST76_V5": {
              "subjectLabel": "T21ST76",
              "visitLabel": "V5"
          },
          "T22BJ72_V0": {
              "subjectLabel": "T22BJ72",
              "visitLabel": "V0"
          },
          "T22BJ72_V1": {
              "subjectLabel": "T22BJ72",
              "visitLabel": "V1"
          },
          "T22BJ72_V5": {
              "subjectLabel": "T22BJ72",
              "visitLabel": "V5"
          },
          "T25CQ69_V0": {
              "subjectLabel": "T25CQ69",
              "visitLabel": "V0"
          },
          "T25CQ69_V1": {
              "subjectLabel": "T25CQ69",
              "visitLabel": "V1"
          },
          "T25CQ69_V5": {
              "subjectLabel": "T25CQ69",
              "visitLabel": "V5"
          }
      }
  }