import {
  ANNOTATION_PROPERTY_NAME__ANNOTATIONS,
  ANNOTATION_PROPERTY_NAME__ID,
  ANNOTATION_PROPERTY_NAME__ROI,
  ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB, ANNOTATION_PROPERTY_NAME__RULER,
  PIN_TOOL, PREVENT_FROM_ADDING_PIN
} from "../../../Constants";
import axios from 'axios'
import {
  SUCCESS_ANNOTATION_DATA_SAVE,
  ANNOTATION_DATA_FAIL,
  ANNOTATION_DATA_REQUEST,
  ANNOTATION_DATA_SUCCESS,
  ANNOTATION_DATA_UPDATE,
  ANNOTATION_DEFINITION_FAIL,
  ANNOTATION_DEFINITION_REQUEST,
  ANNOTATION_DEFINITION_SUCCESS,
  ANNOTATION_FORM_DATA_FAIL,
  ANNOTATION_FORM_DATA_REQUEST,
  ANNOTATION_FORM_DATA_SUCCESS,
  ANNOTATION_FORM_DATA_UPDATE,
  ANNOTATION_FORM_DEFINITION_FAIL,
  ANNOTATION_FORM_DEFINITION_REQUEST,
  ANNOTATION_FORM_DEFINITION_SUCCESS,
  CLEAR_ANNOTATOR_STATE,
  PIN_REDO,
  PIN_UNDO,
  ROI_LIST_FAIL,
  ROI_LIST_REQUEST,
  ROI_LIST_SUCCESS,
  UPDATE_ACTIVE_ROI,
  UPDATE_ANNOTATION_DOCUMENT_ID,
  UPDATE_ANNOTATION_FORM_DOCUMENT_ID,
  UPDATE_PIN_HISTORY,
  ANNOTATION_DEFINITIONS_LIST_REQUEST,
  ANNOTATION_DEFINITIONS_LIST_FAIL,
  ANNOTATION_DEFINITIONS_LIST_SUCCESS,
  ANNOTATION_LIST_REQUEST,
  ANNOTATION_LIST_FAIL,
  ANNOTATION_LIST_SUCCESS,
  ANNOTATION_DEFINITION_SAVE_REQUEST,
  ANNOTATION_DEFINITION_SAVE_FAIL,
  ANNOTATION_DEFINITION_SAVE_SUCCESS,
  CLEAR_ANNOTATION_DEFINITION_SAVE

} from "./actionType";
import {spineLabelsForNMOProject} from "../tests/finalLabels";
//import {spineFinalLabels4, spineLabelsForNMOProject} from "../tests/finalLabels";
import {updateViewerProperty} from "./ViewerAction";
// import {loadImageData} from "./ImageAction";
// import store from "../../Store";
// import nmoProjectAnnotationTableDefinition from "../../jsonDocs/annotation/NMOTableDefinition";
import {calculateRelationalExpression, getNestedProp} from "../../helpers/expressions";
// import {getMasksPreventingFromAddingPin} from "../selectors/SceneSelectors";
import {checkMasks} from "./SceneAction";


//****************************************************************************
//
//                                Constants and Factories
//
//****************************************************************************

const PATH_TO_GET_ANNOTATIONS_DEFINITION =
  (annotationTableDefinitionId)=>`/api/annotation-table-definition/${annotationTableDefinitionId}`;

/**
 * @see https://docs.google.com/document/d/1O-PGuyAp4h2Sf5x--BoOEcVkcDt0Ks5fZPnBlG4pcxM/edit#bookmark=id.ibmeesj4wftq
 * @type {string}
 */
const PATH_TO_GET_ROI_LIST_DATA ="/api/roi/get-list";

//****************************************************************************
//
//                                Actions
//
//****************************************************************************

// Annotation actions *************************************************************

const updateAnnotations = (data) => ({
  type: ANNOTATION_DATA_UPDATE,
  data
});

const successSaveAnnotationDataToServer = () => ({
    type: SUCCESS_ANNOTATION_DATA_SAVE,
});

const updateAnnotationsForm = (data) => ({
  type: ANNOTATION_FORM_DATA_UPDATE,
  data
});
/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestAnnotationsDefinition = () => ({
  type: ANNOTATION_DEFINITION_REQUEST
});

/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestAnnotationsFormDefinition = () => ({
  type: ANNOTATION_FORM_DEFINITION_REQUEST
});


/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorAnnotationsDefinition = err => ({
  type: ANNOTATION_DEFINITION_FAIL,
  err
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorAnnotationsFormDefinition = err => ({
  type: ANNOTATION_FORM_DEFINITION_FAIL,
  err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param columns
 * @returns {{type, variablesList: *}}
 */

export const successAnnotationsDefinition = (columns) => ({
  type: ANNOTATION_DEFINITION_SUCCESS,
  columns
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param columns
 * @returns {{type, variablesList: *}}
 */

export const successAnnotationsFormDefinition = (columns) => ({
  type: ANNOTATION_FORM_DEFINITION_SUCCESS,
  columns
});

/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestAnnotationsData = () => ({
  type: ANNOTATION_DATA_REQUEST
});


/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestAnnotationsFormData = () => ({
  type: ANNOTATION_FORM_DATA_REQUEST
});
/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorAnnotationsData = err => ({
  type: ANNOTATION_DATA_FAIL,
  err
});
/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorAnnotationsFormData = err => ({
  type: ANNOTATION_FORM_DATA_FAIL,
  err
});
/**
 * An action informing the reducers that the request finished successfully.
 * @param data - annotations data
 * @returns {{type, variablesList: *}}
 */

export const successAnnotationsData = (data) => ({
  type: ANNOTATION_DATA_SUCCESS,
  data
});


/**
 * An action informing the reducers that the request finished successfully.
 * @param data - annotations data
 * @returns {{type, variablesList: *}}
 */

export const successAnnotationsFormData = (data) => ({
  type: ANNOTATION_FORM_DATA_SUCCESS,
  data
});

/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestROIListData = () => ({
  type: ROI_LIST_REQUEST
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorROIListData = err => ({
  type: ROI_LIST_FAIL,
  err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param inputKey - input key used to refer to the list of rois in the config
 * @param data - rois data as in https://docs.google.com/document/d/1O-PGuyAp4h2Sf5x--BoOEcVkcDt0Ks5fZPnBlG4pcxM/edit#bookmark=id.j0k4tjqv52uw
 * @param readOnly -
 * @returns {{type, variablesList: *}}
 */

export const successROIListData = (inputKey, data, readOnly) => ({
    type: ROI_LIST_SUCCESS,
    inputKey,
    data,
    readOnly
});

/**
 * An action updating active roi
 * @param roiId - annotations data
 * @returns {{type, variablesList: *}}
 */

export const updateActiveROI = (roiId) => ({
  type: UPDATE_ACTIVE_ROI,
  roiId
});


/**
 * An action updating active roi
 * @param id - annotations doc reference id
 * @returns {{type, variablesList: *}}
 */

export const updateAnnotationDocumentId = (id) => ({
  type: UPDATE_ANNOTATION_DOCUMENT_ID,
  id
});

/**
 * An action updating active roi
 * @param id - annotations doc reference id
 * @returns {{type, variablesList: *}}
 */

export const updateAnnotationFormDocumentId = (id) => ({
  type: UPDATE_ANNOTATION_FORM_DOCUMENT_ID,
  id
});

/**
 * Helper for getting active element.
 * @param data all data
 * @param activeROIID - id of active element
 * @returns row
 */
const getActive = (data, activeROIID) => {
  return data.find((el) => el[ANNOTATION_PROPERTY_NAME__ID] === activeROIID);
};


/**
 * Helper for setting sub - annotation element.
 * @param data
 * @param id - property of element to be active
 * @returns modified data
 */
const setSub = (data, id) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i][ANNOTATION_PROPERTY_NAME__ID] === id && data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB] == null)
      data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB] = true;
    else {
      if (data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB] === true)
        delete data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB];
    }
  }
  return data;
};

/**
 * ActionCreator for setting active annotation.
 * @param row - annotation to display
 * @param shouldUpdateHistory - whether history should be updated
 * @returns {function(*,*)}
 */
export const setActiveAnnotation = (row,shouldUpdateHistory=false) => {
  return (dispatch, getState) => {
    let images = getState().visu.images.images;
    let viewersState = getState().visu.viewers.viewersState;
    let scenes = getState().visu.scenes.scenes;

    if(shouldUpdateHistory)
      dispatch(updatePinHistory());
    dispatch(updateActiveROI(row[ANNOTATION_PROPERTY_NAME__ID]));

    Object.keys(viewersState).forEach((el) => {
      //if (viewersState[el]['imageId'] === row[ANNOTATION_PROPERTY_NAME__ROI]['sceneState'].viewersStateSnapshot[el]['imageId']) {
      if (scenes[viewersState[el]['sceneId']]['primaryImage'] === row[ANNOTATION_PROPERTY_NAME__ROI]['imageId']) {
        const image = images[scenes[viewersState[el]['sceneId']]['primaryImage']];
        dispatch(updateViewerProperty(
          el,
          'sliceNumber',
          row[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][image['properties']['slicingModes'][viewersState[el]['orientation']]])
        );
      }
    });
  };
};

// export const restoreSnapshot = (viewer,index) => {
//     return (dispatch,getState) => {
//         let anns  = getState().visu.annotations.annotations;
//         dispatch (updateAnnotationData(data));
//     };
// };


/**
 * ActionCreator for adding sub-annotation for active annotation if such exists.
 * If none of annotations is active than does nothing.
 * @returns {function(*,*)}
 * @param roi - roi to create
 * @param key - index of viewer (to assign to different images)
 */
export const addSubAnnotationForActiveAnnotation = (roi, key) => {
  return (dispatch, getState) => {
    const manualToolState = getState().visu.manualTool.manualToolState;
    const viewersState = getState().visu.viewers.viewersState;
    if ( (manualToolState[PIN_TOOL]!=null && manualToolState[PIN_TOOL]['selectionOnly']) ||
      // (key!==manualToolState.mainTool.activeViewerId) ||  //Add only if active viewer
      (manualToolState[PIN_TOOL]!=null
        && manualToolState[PIN_TOOL]['subAnnotationsAvailable']===false) // if turned off in configuration
    )
      return;
    if (!checkConstraints(manualToolState[PIN_TOOL]['constraints'],viewersState,key))  // Add annotation only if active viewer
      return;
    let anns = getState().visu.annotations.annotationsData;
    let activeROIId = getState().visu.annotations.activeROI;
    const active = getActive(anns, activeROIId);
    if (active != null) {
      dispatch(updatePinHistory());
      dispatch(setSubAnnotation(active));
      dispatch(addAnnotation(roi, key));
      dispatch(setSubAnnotation(active));
    }
  };
};
/**
 * ActionCreator for setting sub annotation selection.
 * @param row -
 * @returns {function(*,*)}
 */
export const setSubAnnotation = (row) => {
  return (dispatch, getState) => {
    let anns = getState().visu.annotations.annotationsData;
    const pinToolState = getState().visu.manualTool.manualToolState[PIN_TOOL];
    if  (pinToolState!=null
      && pinToolState['subAnnotationsAvailable']===false) // if turned off in configuration
      return;
    let data = setSub(anns, row[ANNOTATION_PROPERTY_NAME__ID]);
    dispatch(updateAnnotationData(data));
  };
};

/**
 * ActionCreator for container.
 * @param id - task id, skill id, etc.
 * @returns {function(*)}
 */
// export const getAnnotations = (id) => {
//     return dispatch => {
//         // dispatch (successAnnotations({columns:ANNOTATION_TABLE_MOCK,data:ANNOTATION_TABLE_DATA_MOCK}));
//         dispatch (successAnnotations({columns:spineFinalLabels4,data:[]}));
//     };
// };

/**
 * ActionCreator for updating data.
 * @param data
 * @returns {function(*, *)}
 */
export const updateAnnotationData = (data) => {

  return (dispatch) => {
    // console.log("Setting annotation data",data);
    // let annData  = getState().visu.annotations.annotationsData;
    // annData.data=data.slice(0);
    dispatch(updateAnnotations(data));
  };

};

/**
 * ActionCreator for saving data to server.
 * @param materializedTaskId
 * @param data
 * @returns {function(*, *)}
 */
export const saveAnnotationDataToServer = (materializedTaskId,data) => {
    return (dispatch, getState) => {
        axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
        const url = `/api/materialized-task/${materializedTaskId}/temporary-result`;
        const messageQueue = getState().messaging.msgQueue;
        console.log(data);
        return axios.put(url,data,{
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json'
          }
        }).then(response => {
            console.log('AnnotationAction.js :: saveAnnotationDataToServer :: response', response);
            if (response.status !== 200) {
                // TODO  : handle this error
                console.log(response.data)
                // dispatch(errorAnnotationsFormDefinition(response.data));
                // return Promise.reject(response.data);
            } else {
                console.log('AnnotationAction.js :: saveAnnotationDataToServer ::  Before success');
              if (messageQueue!=null)
                messageQueue.show( {
                  sticky: false,
                  severity: 'info',
                  summary: 'Success',
                  detail:"Annotations have been successfully submitted."
                });
                dispatch(successSaveAnnotationDataToServer());
            }
        }).catch(err => {
          const message = err !=null && err.response != null && err.response.data != null ? err.response.data.message : err.message;
          console.log(message);
          if (messageQueue!=null)
            messageQueue.show( {
              sticky: false,
              severity: 'error',
              summary: 'Error',
              detail: message
            });
        });
    };
};

/**
 * ActionCreator for updating data.
 * @param data
 * @returns {function(*, *)}
 */
export const updateAnnotationFormData = (data) => {
  return (dispatch) => {
    dispatch(updateAnnotationsForm(data));
  };
};


/**
 * ActionCreator for removing annotation.
 * @param data
 * @returns {function(*, *)}
 */
export const removeAnnotation = (data) => {
  return (dispatch, getState) => {
    console.log("Remove annotation data", data);
    let anns = getState().visu.annotations.annotationsData;
    let activeId = getState().visu.annotations.activeROI;
    const idPrefix = getState().visu.manualTool.manualToolState['pinTool']['idPrefixHack']?getState().visu.manualTool.manualToolState['pinTool']['idPrefixHack']:"";

    let index = anns.findIndex((el) => {
      return el[ANNOTATION_PROPERTY_NAME__ID] === data[ANNOTATION_PROPERTY_NAME__ID]
    });
    let activeIndex = anns.findIndex((el) => {
      return el[ANNOTATION_PROPERTY_NAME__ID] === activeId
    });
    let characteristic = null;
    let mantissa = null;
    if (index > -1) {
      dispatch(updatePinHistory());
      characteristic = data[ANNOTATION_PROPERTY_NAME__ID].split(".")[0].substring(idPrefix.length);
      mantissa = data[ANNOTATION_PROPERTY_NAME__ID].split(".")[1];
      if (mantissa != null)
        anns.splice(index, 1);
      else
        anns = anns.filter((el) => {
          return el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0].substring(idPrefix.length) !== characteristic
        }); //remove full branch

      anns.forEach((el) => {
        if (mantissa != null && characteristic === el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0].substring(idPrefix.length)) {
          if (parseInt(el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1]) > parseInt(mantissa)) {
            el[ANNOTATION_PROPERTY_NAME__ID] = el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0] + '.' + String(Number(el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1]) - 1);
          }
        }
        if (!mantissa) {
          if (parseInt(el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0].substring(idPrefix.length)) > parseInt(characteristic)) { //decrease before decimal
            let charact = String(parseInt(el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0].substring(idPrefix.length)) - 1);
            let mantis = (el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1] != null) ? '.' + el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1] : '';
            el[ANNOTATION_PROPERTY_NAME__ID] =idPrefix + charact + mantis;
          }
          // if (el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0] === characteristic) { //remove full branch
          // object.splice(index,1);
          // }

        }
      });
    }
    dispatch(updateAnnotationData(anns));
    if (data[ANNOTATION_PROPERTY_NAME__ID]===activeId)
      dispatch(updateActiveROI(-1));
    else if (index < activeIndex) //check if active ROI should be updated (the order can change if element before active is removed)
      dispatch(updateActiveROI(anns[activeIndex-1][ANNOTATION_PROPERTY_NAME__ID]));


  }
};


/**
 * ActionCreator for clearing all annotations.
 * @returns {function(*)}
 */
export const clearAllAnnotations = () => {
  return (dispatch) => {
    dispatch(updatePinHistory());
    dispatch(updateAnnotationData([]));
  }
};

/**
 * Check set of constraints for a given tool.
 * @see {@link src/frontend/jsonDocs/tools/perivascularSpacesDemo.json:283}
 * @param constraints
 * @param viewersState
 * @param key
 * @return {boolean|boolean}
 */
const checkConstraints=(constraints,viewersState,key)=>{

  if (constraints!=null && typeof constraints ==='object')
    return Object.keys(constraints).reduce(function(previousValue, currentValue) {
      let result = true;
      if(constraints[currentValue]['controlElement']==='viewer'){
        const operand1 = viewersState[key][constraints[currentValue]['property']];
        const operand2 = constraints[currentValue]['value'];
        result = calculateRelationalExpression(operand1, operand2,constraints[currentValue]['operator']);
      }
      return previousValue && result;
    }, true);
  else
    return true;

};


/**
 * ActionCreator for adding annotation.
 * @param roi Region of Interest object
 * @param key of viewer
 * @returns {function(*,*)}
 */
export const addAnnotation = (roi, key) => {
  return (dispatch, getState) => {
    let data = getState().visu.annotations.annotationsData;
    const viewersState = getState().visu.viewers.viewersState;
    const scenes = getState().visu.scenes.scenes;
    const manualToolState = getState().visu.manualTool.manualToolState;

    const idPrefix = getState().visu.manualTool.manualToolState['pinTool']['idPrefixHack']?getState().visu.manualTool.manualToolState['pinTool']['idPrefixHack']:"";

    if (manualToolState[PIN_TOOL]!=null && manualToolState[PIN_TOOL]['selectionOnly'])
      return;
    // if (key!==manualToolState.mainTool.activeViewerId)  // Add annotation only if active viewer
    //     return;
    if (!checkConstraints(manualToolState[PIN_TOOL]['constraints'],viewersState,key))  // Add annotation only if active viewer
      return;
    if (!checkMasks(roi.roiCellIJK,getState,PREVENT_FROM_ADDING_PIN))
      return;
    let sub = data.find((el) => {
      return el[ANNOTATION_PROPERTY_NAME__ROI].hasOwnProperty(ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB)
    });
    let element = {};
    roi['imageId'] = scenes[viewersState[key]['sceneId']]['primaryImage'];
    roi['sceneState'] = {};
    const vSnapshot = {};
    Object.keys(viewersState).forEach(el=> //copy like in the reducer
    {
      vSnapshot[el]=Object.assign({},viewersState[el]);
      vSnapshot[el]["reference"]=null;  // remove references to Viewer2D to prevent from cycles in serialization
    }
    );
    roi['sceneState']['viewersStateSnapshot'] = vSnapshot;
    roi['sceneState']['manualToolStateSnapshot'] = Object.assign({}, manualToolState);
    if (sub != null) {
      let characteristic = sub[ANNOTATION_PROPERTY_NAME__ID].split(".")[0].substring(idPrefix.length);
      let mantissa = (data.length > 0) ? Math.max.apply(Math, data
        .filter((el) => {
          return el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0].substring(idPrefix.length) === characteristic;
        })
        .map((el) => {
          return (el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1] != null) ? Number(el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1]) : 0
        })) : 0;
      mantissa += 1;
      element[ANNOTATION_PROPERTY_NAME__ID] = idPrefix + characteristic + '.' + mantissa.toString();
      element[ANNOTATION_PROPERTY_NAME__ROI] = roi;
    } else {
      let maxIndex = (data.length > 0) ? Math.max.apply(Math, data.map((el) => {
        const number = parseInt(el[ANNOTATION_PROPERTY_NAME__ID].substring(idPrefix.length));
        return isNaN(number) ? 0 : number;
      })) : 0;
      element[ANNOTATION_PROPERTY_NAME__ID] = idPrefix + (maxIndex + 1);
      element[ANNOTATION_PROPERTY_NAME__ROI] = roi;
    }
    const columnsDefinition = getState().visu.annotations.annotationsDefinition; //get data definition to initialize default values
    Object.keys(columnsDefinition).forEach((el) => {
      if (columnsDefinition[el].value != null) element[el] = columnsDefinition[el].value
    }); // initialize def vals
    dispatch(updatePinHistory());
    data.push(element);
    dispatch(updateAnnotationData(data));
    //dispatch(updateActiveROI(element[ANNOTATION_PROPERTY_NAME__ID]));
    dispatch(setActiveAnnotation(element));
  };
};

const clearAnnotator = () => ({
  type: CLEAR_ANNOTATOR_STATE,
});
export const clearAnnotatorState = () => {
  return (dispatch) => {
    dispatch(clearAnnotator());
  }
};

/**
 * Method used for initialization of annotation table demo (HierarchicalDropdownDemo)
 * @return {function(*)}
 */
export const initializeAnnotationTable = () => {
  return (dispatch) => {

    dispatch (successAnnotationsDefinition(spineLabelsForNMOProject));
    // dispatch (successAnnotations({columns:spineLabelsForNMOProject,data:[initData]}));
  }
};

/**
 * Converter to be compliant with ROI schema.
 * Regular function (no dispatch!).
 * @param data - data Array from Redux Store
 * @param activeRoiId -id of active annotation
 * @param definition - annotation table definition
 * @param annotationsDefinitionDocumentId
 */
export const convertToAnnotationsTransferObject = (data, activeRoiId, definition,annotationsDefinitionDocumentId) => {
  let outputObject = {};
  if (activeRoiId != null) {
    outputObject['lookAndFeel'] = {};
    outputObject['lookAndFeel']['activeRoiId'] = activeRoiId;
  }
  if (annotationsDefinitionDocumentId != null) {
    outputObject['reference'] = {};
    outputObject['reference']['annotationTableDefinitionId'] = annotationsDefinitionDocumentId;
  }
  let outputArray = [];
  if (data != null && Array.isArray(data) && data.length > 0)
    data.forEach((row) => {
      let result = {};
      //convert rois
      result['id'] = row[ANNOTATION_PROPERTY_NAME__ID];

      if (row['uuid']!=null)
        result['uuid'] = row['uuid'];
      result['roi'] = {};
      result['roi']['roiType'] = row[ANNOTATION_PROPERTY_NAME__ROI]['roiType'];
      result['roi'] ['roiProperties'] = {};

      if (getNestedProp(['roiPosition'],row[ANNOTATION_PROPERTY_NAME__ROI])!=null) {
        result['roi'] ['roiProperties']['worldCoordinates'] = {};
        result['roi'] ['roiProperties']['worldCoordinates']['x'] = row[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][0];
        result['roi'] ['roiProperties']['worldCoordinates']['y'] = row[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][1];
        result['roi'] ['roiProperties']['worldCoordinates']['z'] = row[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][2];
      }
      if (getNestedProp(['roiCellIJK'],row[ANNOTATION_PROPERTY_NAME__ROI])!=null) {
        result['roi'] ['roiProperties']['imageCoordinates'] = {};
        result['roi'] ['roiProperties']['imageCoordinates']['i'] = row[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][0];
        result['roi'] ['roiProperties']['imageCoordinates']['j'] = row[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][1];
        result['roi'] ['roiProperties']['imageCoordinates']['k'] = row[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][2];
      }
      result['roi']['imageId'] = row[ANNOTATION_PROPERTY_NAME__ROI]['imageId'];
      result['roi']['sceneState'] = row[ANNOTATION_PROPERTY_NAME__ROI]['sceneState'];

      if (getNestedProp(['radius'],row[ANNOTATION_PROPERTY_NAME__ROI])!=null){
        result['roi'] ['roiProperties']['radius'] = getNestedProp(['radius'],row[ANNOTATION_PROPERTY_NAME__ROI]);
      }
      if (getNestedProp(['orientation'],row[ANNOTATION_PROPERTY_NAME__ROI])!=null){
        result['roi']['orientation'] =  getNestedProp(['orientation'],row[ANNOTATION_PROPERTY_NAME__ROI]);
      }


      //convert annotations
      result[ANNOTATION_PROPERTY_NAME__ANNOTATIONS] = {};
      Object.keys(row)
        .filter((el) => el !== ANNOTATION_PROPERTY_NAME__ID && el !== ANNOTATION_PROPERTY_NAME__ROI && el !== 'uuid')
        .filter((el) => !el.includes(ANNOTATION_PROPERTY_NAME__RULER))  //merge _rulers
        .forEach((column) => {
          result[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][column] = {};
          if (row[ANNOTATION_PROPERTY_NAME__RULER+"_"+column]!=null){
            result[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][column]['typeValue'] = 'ruler';
            result[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][column]['value'] = row[ANNOTATION_PROPERTY_NAME__RULER+"_"+column];
            result[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][column]['value']['DISTANCE'] = row[column];
          }
          else{
            result[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][column]['typeValue'] = 'text';
            result[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][column]['value'] = row[column];
          }
        });
      outputArray.push(result);
    });

  outputObject['data'] = outputArray;

  return outputObject;
};

/**
 * Converter to load and parse  annotations data.
 * Regular function (no dispatch!).
 * @param inputData - data in format defined by API
 * @return {*} - object containing annotations data in format Redux Store-friendly
 */
export const convertFromAnnotationsTransferObject = (inputData) => {

    let outputObject = {};
    console.log(inputData);
    if (inputData['lookAndFeel']!=null && inputData['lookAndFeel']['activeRoiId']!=null)
        outputObject['activeRoiId'] = inputData['lookAndFeel']['activeRoiId'];
    if (inputData['reference']!=null && inputData['reference']['annotationTableDefinitionId']!=null)
        outputObject['annotationTableDefinitionId'] = inputData['reference']['annotationTableDefinitionId'];
    outputObject['data'] = [];

    if (inputData.data != null && Array.isArray(inputData.data) && inputData.data.length > 0)
        inputData.data.forEach((row) => {
            let result = {};

            if (row['uuid']!=null)
               result['uuid'] = row['uuid'];
            result[ANNOTATION_PROPERTY_NAME__ID] = row['id'];
            result[ANNOTATION_PROPERTY_NAME__ROI] = {};
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiType'] = row['roi']['roiType'];
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK']=[];
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][0]=row['roi'] ['roiProperties']['imageCoordinates']['i'];
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][1]=row['roi'] ['roiProperties']['imageCoordinates']['j'];
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][2]=row['roi'] ['roiProperties']['imageCoordinates']['k'];
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition']=[];
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][0]=row['roi'] ['roiProperties']['worldCoordinates']['x'];
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][1]=row['roi'] ['roiProperties']['worldCoordinates']['y'];
            result[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][2]=row['roi'] ['roiProperties']['worldCoordinates']['z'];
            result[ANNOTATION_PROPERTY_NAME__ROI]['imageId'] = row['roi']['imageId'];
            result[ANNOTATION_PROPERTY_NAME__ROI]['sceneState']=row['roi']['sceneState'];

            //convert annotations
            Object.keys(row[ANNOTATION_PROPERTY_NAME__ANNOTATIONS]).forEach((el)=>{
              if (row[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][el]['typeValue']!=='ruler'){
                result[el] = row[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][el]['value'];
              }
              else {
                result[el] = row[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][el]['value']["DISTANCE"];
                result[ANNOTATION_PROPERTY_NAME__RULER+'_'+el] = row[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][el]['value'];
              }

            });

            outputObject.data.push(result);
        });
    console.log("converting data saved");
    return outputObject;

};


/**
 * Converter to load and parse annotations data from ROI's list.
 * Regular function (no dispatch!).
 * @param inputData - data in format defined by API
 * @return {*} - object containing annotations data in format Redux Store-friendly
 */
export const convertFromROITransferObject = (inputData) => {
  let outputObject = {};
  outputObject['data'] = [];
  let counters = inputData.reduce(function(map, obj) {
    map[obj.uuid] = 0;
    return map;
  }, {});
  let counter = 0;

  if (Array.isArray(inputData) && inputData.length > 0) {
    inputData.forEach((row) => {
      let result = {};
      if (row['uuid']!=null)
        result['uuid'] = row['uuid'];
      result[ANNOTATION_PROPERTY_NAME__ROI] = {};
      if (row['properties'].hasOwnProperty('implicit')) {
        result[ANNOTATION_PROPERTY_NAME__ROI]['roiType'] = getNestedProp(['properties','implicit','implicitGeometryPointer'],row)!=null
          ? getNestedProp(['properties','implicit','implicitGeometryPointer'],row)
          : getNestedProp(['properties','implicit','implicitGemetryPointer'],row);

        result[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'] = [];
        result[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][0] = getNestedProp(['properties','implicit','roiProperties','imageCoordinates','i'],row);
        result[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][1] = getNestedProp(['properties','implicit','roiProperties','imageCoordinates','j'],row);
        result[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][2] = getNestedProp(['properties','implicit','roiProperties','imageCoordinates','k'],row);

        result[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'] = [];
        result[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][0] = getNestedProp(['properties','implicit','roiProperties','worldCoordinates','x'],row);
        result[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][1] = getNestedProp(['properties','implicit','roiProperties','worldCoordinates','y'],row);
        result[ANNOTATION_PROPERTY_NAME__ROI]['roiPosition'][2] = getNestedProp(['properties','implicit','roiProperties','worldCoordinates','z'],row);


        if (getNestedProp(['properties','implicit','roiProperties','radius'],row)!=null){
          result[ANNOTATION_PROPERTY_NAME__ROI]['radius'] = getNestedProp(['properties','implicit','roiProperties','radius'],row);
        }
        if (getNestedProp(['properties','implicit','orientation'],row)!=null){
          result[ANNOTATION_PROPERTY_NAME__ROI]['orientation'] = getNestedProp(['properties','implicit','orientation'],row);
        }

      }
      if (row['reference'] != null && row['reference']['imageEntityId'] != null)
        result[ANNOTATION_PROPERTY_NAME__ROI]['imageId'] = row['reference']['imageEntityId'];
      // else
      //     result[ANNOTATION_PROPERTY_NAME__ROI]['imageId'] = "8dd1bba899e596024e9a45e7a58cd6fa"; //MOCK FIXME
      if (row['sceneState'] != null)
        result[ANNOTATION_PROPERTY_NAME__ROI]['sceneState'] = row['roi']['sceneState'];

      //convert annotations
      if (row[ANNOTATION_PROPERTY_NAME__ANNOTATIONS] != null)
        Object.keys(row[ANNOTATION_PROPERTY_NAME__ANNOTATIONS]).forEach((el) => {
          result[el] = row[ANNOTATION_PROPERTY_NAME__ANNOTATIONS][el]['value'];
        });

      if (row['properties']['isSubRoi'] !== true)
        result[ANNOTATION_PROPERTY_NAME__ID] = (++counter).toString();
      else {
        result['counterKey'] = row['reference']['mainRoiId'];//mark the ids that may be unknown
      }
      outputObject.data.push(result);
    });

    outputObject.data.forEach((el,index) => { //resolve the unknown ids for subROIs
      if (!(el[ANNOTATION_PROPERTY_NAME__ID] != null)) {
        if (el['counterKey'] != null) {
          const main = outputObject.data.find((e) => e['uuid'] === el['counterKey']);
          outputObject.data[index][ANNOTATION_PROPERTY_NAME__ID] = ""
            .concat(main[ANNOTATION_PROPERTY_NAME__ID], ".", (++counters[el['counterKey']]).toString());
          delete outputObject.data[index]['counterKey'];
        }
      }
    });
    if (outputObject.data!=null && outputObject.data.length>0)
      outputObject.activeROI = "1";
  }

  return outputObject;
};


/**
 * Action Creator - parses and loads annotation definition.
 * @param annotationTableDefinitionId - columns for annotation table
 */
export const parseAndLoadAnnotationDefinition =  (annotationTableDefinitionId) => {
  return (dispatch,getState) => {
    dispatch(requestAnnotationsDefinition());
    dispatch (updateAnnotationDocumentId(annotationTableDefinitionId));
    //MOCK below:
    // dispatch (successAnnotationsDefinition(nmoProjectAnnotationTableDefinition));
    // return;
    // console.log('AnnotationAction.js :: parseAndLoadAnnotationDefinition :: Before axios request');
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    const url = PATH_TO_GET_ANNOTATIONS_DEFINITION(annotationTableDefinitionId);
    return axios.get(url).then(response => {
      console.log('AnnotationAction.js :: parseAndLoadAnnotationDefinition :: response', response);
      if (response.status !== 200) {
        dispatch(errorAnnotationsDefinition(response.data));
        return Promise.reject(response.data);
      } else {
        console.log('AnnotationAction.js :: parseAndLoadAnnotationDefinition ::  Before success');
        dispatch(successAnnotationsDefinition(response.data.columns));
      }
    }).catch(err => dispatch(errorAnnotationsDefinition(err)));
  };
};


/**
 * Action Creator - parses and loads annotation definition for Form only.
 * @param annotationTableDefinitionId - columns for annotation table
 */
export const parseAndLoadAnnotationFormDefinition =  (annotationTableDefinitionId) => {
  return (dispatch,getState) => {
    dispatch(requestAnnotationsFormDefinition());
    dispatch (updateAnnotationFormDocumentId(annotationTableDefinitionId));
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    const url = PATH_TO_GET_ANNOTATIONS_DEFINITION(annotationTableDefinitionId);
    return axios.get(url).then(response => {
      console.log('AnnotationAction.js :: parseAndLoadAnnotationFormDefinition :: response', response);
      if (response.status !== 200) {
        dispatch(errorAnnotationsFormDefinition(response.data));
        return Promise.reject(response.data);
      } else {
        console.log('AnnotationAction.js :: parseAndLoadAnnotationFormDefinition ::  Before success');
        dispatch(successAnnotationsFormDefinition(response.data.columns));
      }
    }).catch(err => dispatch(errorAnnotationsFormDefinition(err)));
  };
};


export const isAnnotationTableValid = (annotationsData,annotationsDefinition)=>{
  let valid = true;
  let fieldCounter =0;
  let missingFieldsArray = [];
  if (annotationsData!=null && annotationsData.length>0 && annotationsDefinition !=null){
    //checking every required
    Object.keys(annotationsDefinition).forEach(defKey=>{
      const aDef = annotationsDefinition[defKey];
      Object.keys(annotationsData).forEach(dataKey=>{
        const aData = annotationsData[dataKey];
        if( !aData['_id'].includes(".")
          && aDef.hasOwnProperty('validation')
          && aDef['validation'].hasOwnProperty('required')
          &&  aDef['validation']['required']) {
          const validField = aData.hasOwnProperty(defKey) && aData[defKey]!=null && aData[defKey]!=="";
          if (!validField) {
            fieldCounter++;
            if (aData['_id']!=="-1")
              missingFieldsArray.push(aData['_id'].concat(": ",aDef['name'])); // table only
            else
              missingFieldsArray.push(aDef['name']); //form only
          }
          valid = valid && validField;
        }
      })
    });
  }
  return {isValid:valid, missingFields:fieldCounter, missingFieldsArray:missingFieldsArray};
};


/**
 * ActionCreator for getting ROI's.
 * @param rois  - array of Region of Interest objects
 * @param options - object with roisInputKey, readOnly, color keys to config and parse ROIS
 * @property {object} axios.defaults.headers.common
 * @returns {function(*,*)}
 */
export const parseAndLoadROIListData = (rois, options) => {
    const {roisInputKey, readOnly, color, isInvisibleInAnnotationTableHack} = options;
    //TODO: Change success to map data by key, check support for both variations (simple list and object?)
    return (dispatch, getState) => {
        const rawData = getState().visu.interactivePlot.rawData;
        dispatch(clearAllAnnotations());
        dispatch(requestROIListData());
        axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;

        return axios.post(PATH_TO_GET_ROI_LIST_DATA,{"list":rois}).then(response => {
            console.log('AnnotationAction.js :: getROIList :: response', response);
            if (response.status !== 200) {
                dispatch(errorROIListData(response.data));
                return Promise.reject(response.data);
            } else {
                const data = convertFromROITransferObject(response.data);
              let roisData = data.data;
              // FIXME: Maybe the logic below needs to go to a function. Something like 'addPresentationDataToROIList(roisData, options)'
              roisData = roisData.map((roi) => {
                // if (isInvisibleInAnnotationTableHack === true) {
                //   roi['_id'] = '_' + roi['_id'];
                // }
                if (color!=null){
                  roi['color'] = color;
                }
                if (readOnly) {
                  if (rawData && Object.keys(rawData).length > 0) {
                    const {data} = rawData;
                    const caseRater = data.find((caseRaterExecution) => {
                      return caseRaterExecution.rows.some((row)=>{ return row['lesion uuid'] === roi.uuid})
                    });
                    roi['raterEmail'] = caseRater.raterEmail;
                    roi['raterName'] = caseRater.raterName; 
                    roi['raterId'] = caseRater.raterId;
                    roi['extraData'] = data.map((caseRaterExecution) => {
                      return caseRaterExecution.rows;
                    }).flat().find(row => {
                      return row['lesion uuid'] === roi.uuid;
                    });
                  }
                }
                return roi;
              });

              dispatch(successROIListData(roisInputKey, roisData, readOnly));
              if (data.activeROI)
                dispatch(updateActiveROI(data.activeROI));
            }
        }).catch(err => dispatch(errorROIListData(err)));
    };
};

/**
 * Actions for undo pin operation
 * @return {{type: string}}
 */
export const undoPin = () => ({
  type: PIN_UNDO
});

/**
 * Actions for redo pin operation
 * @return {{type: string}}
 */
export const redoPin = () => ({
  type: PIN_REDO
});

/**
 * Actions for redo pin operation
 * @return {{type: string}}
 */
export const updatePinHistory = () => ({

    type: UPDATE_PIN_HISTORY
});


/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestAnnotationsDefinitionsList = () => ({
  type: ANNOTATION_DEFINITIONS_LIST_REQUEST
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorAnnotationsDefinitionsList = err => ({
  type: ANNOTATION_DEFINITIONS_LIST_FAIL,
  err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param data
 * @returns {{type, variablesList: *}}
 */

const successAnnotationsDefinitionsList = (data) => ({
  type: ANNOTATION_DEFINITIONS_LIST_SUCCESS,
  data
});
/**
 * ActionCreator for getting Annotation Tables.
 * @returns {function(*,*)}
 */
export const getAnnotationsDefinitionsList = () => {
  return (dispatch, getState) => {
    dispatch(requestAnnotationsDefinitionsList());
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    return axios.get("/api/annotation-table-definition").then(response => {
      console.log('AnnotationAction.js :: getAnnotationsDefinitionsList :: response', response);
      if (response.status !== 200) {
        dispatch(errorAnnotationsDefinitionsList(response.data));
        return Promise.reject(response.data);
      } else {
        console.log('AnnotationAction.js :: getAnnotationsDefinitionsList ::  Before success');
        dispatch(successAnnotationsDefinitionsList(response.data));
      }
    }).catch(err => dispatch(errorAnnotationsDefinitionsList(err)));
  };
};



/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestAnnotationsList = () => ({
  type: ANNOTATION_LIST_REQUEST
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorAnnotationsList = err => ({
  type: ANNOTATION_LIST_FAIL,
  err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param data
 * @returns {{type, variablesList: *}}
 */

const successAnnotationsList = (data) => ({
  type: ANNOTATION_LIST_SUCCESS,
  data
});

/**
 * ActionCreator for getting all Annotation Columns from DB.
 * @returns {function(*,*)}
 */
export const getAnnotationsList = () => {
  return (dispatch, getState) => {
    dispatch(requestAnnotationsList());
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    return axios.get("/api/annotation-table-column-definition").then(response => {
      console.log('AnnotationAction.js :: getAnnotationsList :: response', response);
      if (response.status !== 200) {
        dispatch(errorAnnotationsList(response.data));
        return Promise.reject(response.data);
      } else {
        console.log('AnnotationAction.js :: getAnnotationsList ::  Before success');
        dispatch(successAnnotationsList(response.data));
      }
    }).catch(err => dispatch(errorAnnotationsList(err)));
  };
};


const requestSaveAnnotationsTableDefinition = ()=>({
  type:ANNOTATION_DEFINITION_SAVE_REQUEST
});

const errorSaveAnnotationsTableDefinition = (err)=>({
  type:ANNOTATION_DEFINITION_SAVE_FAIL,
  err
});

const successSaveAnnotationsTableDefinition = (data)=>({
  type:ANNOTATION_DEFINITION_SAVE_SUCCESS,
  data
});


export const clearSaveAnnotationsTableDefinition = ()=>({
  type:CLEAR_ANNOTATION_DEFINITION_SAVE
});

/**
 * ActionCreator for getting all Annotation Columns from DB.
 * @param mode -
 * @param payload -
 * @returns {function(*,*)}
 */
export const saveAnnotationsTableDefinition = (mode,payload) => {
  return (dispatch, getState) => {
    dispatch(requestSaveAnnotationsTableDefinition());
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    return axios.post(`/api/annotation-table-definition?status=${mode}`,payload).then(response => {
      console.log('AnnotationAction.js :: saveAnnotationsTableDefinition :: response', response);
      if (response.status !== 200) {
        dispatch(errorSaveAnnotationsTableDefinition(response.data));
        return Promise.reject(response.data);
      } else {
        console.log('AnnotationAction.js :: saveAnnotationsTableDefinition ::  Before success');
        dispatch(successSaveAnnotationsTableDefinition(response.data));
      }
    }).catch(err => dispatch(errorSaveAnnotationsTableDefinition(err)));
  };
};


/**
 * ActionCreator for getting all Annotation Columns from DB.
 * @param mode -
 * @param payload -
 * @returns {function(*,*)}
 */
export const saveAnnotationColumn = (mode,payload) => {
  return (dispatch, getState) => {
    dispatch(requestSaveAnnotationsTableDefinition());
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    return axios.post(`/api/annotation-table-column-definition?status=${mode}`,payload).then(response => {
      console.log('AnnotationAction.js :: saveAnnotationColumn :: response', response);
      if (response.status !== 200) {
        dispatch(errorSaveAnnotationsTableDefinition(response.data));
        return Promise.reject(response.data);
      } else {
        console.log('AnnotationAction.js :: saveAnnotationColumn ::  Before success');
        dispatch(successSaveAnnotationsTableDefinition(response.data));
      }
    }).catch(err => dispatch(errorSaveAnnotationsTableDefinition(err)));
  };
};


/**
 * ActionCreator for getting all Annotation Instances for a given cohort.
 * @returns {function(*,*)}
 */
export const getAnnotationListForCohort = () => {
  return async (dispatch, getState) => {

    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    dispatch(requestAnnotationsList());
    const cohort = getState().visu.study.study;
    const roisList = getNestedProp(["rois","list"],cohort)!=null && Array.isArray(getNestedProp(["rois","list"],cohort))
      ? getNestedProp(["rois","list"],cohort).map(el=>el.uuid)
      : [];

    if (roisList.length>0){
      try {
        let annotationList = await axios.post('/api/annotation/byROI', {"roiIds": roisList});
        dispatch(successAnnotationsList(annotationList.data));
      }catch (err){
        dispatch(errorAnnotationsList(err));
      }
    }
    else
      dispatch(successAnnotationsList([]));
  };
};