import {connect} from "react-redux";
import React from "react";
import {
  getAnnotationsDefinitionsList,
  initializeAnnotationTable,
  parseAndLoadAnnotationDefinition,
  updateAnnotationData
} from "../action/AnnotationAction";
import AnnotationTableSelection from "../component/annotationTool/AnnotationTableSelection"; //static import




const mapStateToProps = state => {
  return {
    annotationsDefinitionsListState:state.visu.annotations.annotationsDefinitionsListState,
    annotationsDefinitionsList:state.visu.annotations.annotationsDefinitionsList,
    columns: state.visu.annotations.annotationsDefinition,
    columnsState: state.visu.annotations.annotationsDefinitionState,
    data: state.visu.annotations.annotationsData,
    tasks: state.expDesign.tasks.tasks,
    taskListState: state.expDesign.tasks.taskListState,
    manualToolState: state.visu.manualTool.manualToolState
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAnnotationData:(data)=>{
      dispatch(updateAnnotationData(data))
    },
    initialize:()=>{
      dispatch(initializeAnnotationTable())
    },
    getAnnotationsDefinitionsList:()=>{
      dispatch(getAnnotationsDefinitionsList())
    },
    getColumnsDefinition:(id)=>{
      dispatch(parseAndLoadAnnotationDefinition(id))
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);


export const ContainerAnnotationTableSelection = containerCreator(AnnotationTableSelection);