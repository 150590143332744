import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  duplicatePlotSettings, updatePlotSettingsProperty
} from "../action/SAMAction";
import {
  getSelectedPlotIndex,
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import CasesPanel from "../component/CasesPanel";
import {
  getSelectedCasesFilteredByMeasurement,
  getTotalCasesFilteredByMeasurement
} from "../selectors/CasesSelectors";
import {
  getSelectedContributionsFilteredByMeasurement,
  getTotalContributionsFilteredByMeasurement
} from "../selectors/ContributionsSelectors";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration,
    selectedContributions: getSelectedContributionsFilteredByMeasurement(state),

    totalCasesFiltered: getTotalCasesFilteredByMeasurement(state),
    selectedCasesFiltered: getSelectedCasesFilteredByMeasurement(state),
    totalContributionsFiltered: getTotalContributionsFilteredByMeasurement(state),
    selectedContributionsFiltered: getSelectedContributionsFilteredByMeasurement(state),

    selectedCases: getSelectedPlotSettings(state).selectedCases,
    selectedVisits: getSelectedPlotSettings(state).selectedVisits,
    selectedSubjects: getSelectedPlotSettings(state).selectedSubjects,
    filterVisitOn: getSelectedPlotSettings(state).filterVisitOn,
    filterSubjectOn: getSelectedPlotSettings(state).filterSubjectOn,
    plotIndex: getSelectedPlotIndex(state) // selector used here !
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    duplicatePlotSettings:(allFlag)=>{
      dispatch(duplicatePlotSettings(allFlag))
    },
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    }
  }
};

export const ContainerCasesPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(CasesPanel));