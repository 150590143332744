import React from "react";
import PropTypes from "prop-types";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";

/**
 * Datatable for Term Search API.
 * Data model according to
 * @link http://data.bioontology.org/documentation#nav_search
 */
class TermSearchResultsDataTable extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null
    };
    ["onRowSelect"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  onRowSelect(e) {
    this.setState({
      selectedRow: e.data
    });

    if (this.props.onRowSelected!=null){
      this.props.onRowSelected(e.data)
    }
  }

  iriExpansionTemplate(row) {
    return <a target="_blank" href={row['@id']} title={"Open ".concat(row['@id']," in external window")}>
      {row['@id'].substring(row['@id'].lastIndexOf('/')+1)}
    </a>
  }

  owlExpansionTemplate(row) {
    return <span title={"Full specification: ".concat(row['@type'])}>
      {row['@type'].substring(row['@type'].lastIndexOf('/')+1)}
    </span>
  }

  render() {
    const {queryResult} = this.props;


    return (
          <DataTable value={queryResult!=null?queryResult.collection:[]}
                     paginator={true}
                     onSelectionChange={() => { }}
                     selectionMode="single"
                     selection={this.state.selectedRow}
                     onRowSelect={this.onRowSelect}
                     resizableColumns={true}
                     rows={10}>
            <Column field={'@id'} header={"Id"} sortable={true} body={this.iriExpansionTemplate}/>
            <Column field={'prefLabel'} header={"Label"} sortable={true}/>
            <Column field={'definition'} header={"Definition"} body={(row)=>{return row['definition']!=null&& row['definition'].length>0? row['definition'][0]:""}} sortable={true}/>
            <Column field={'matchType'} header={"Match type"} sortable={true} style={{width:"8em"}} filter={true} filterMatchMode={"contains"}/>
            <Column field={'@type'} header={"Type"} sortable={true} body={this.owlExpansionTemplate} style={{width:"8em"}}/>
            <Column field={'ontologyType'} header={"Onto-type"} sortable={true} style={{width:"8em"}} filter={true} filterMatchMode={"contains"}/>
            <Column field={'obsolete'} header={"Obsolete"} style={{width:"8em"}} sortable={true}/>
            <Column field={'provisional'} header={"Provisional"} style={{width:"8em"}} sortable={true}/>
          </DataTable>

    )
  }

}

export default TermSearchResultsDataTable;

TermSearchResultsDataTable.propTypes = {
  queryResult:PropTypes.object.isRequired,
  onRowSelected:PropTypes.func
};
