
/**
 * Class providing  accessors to data from General Linear Model calculated with
 *                         glm
 * method and obtained from  R Plumber and jsonlite serializer.
 *
 * Beware! This is wrapper only and works in slightly different manner as SLRModel and GLRModel.
 * The difference is in using accessors.
 *
 *
 */
export class GLMModel{

  constructor(object, levelCI=0.95){
    // let getIdx = function (name){return object.attributes.names.value.indexOf(name);};
    this._attributes = object.attributes.names.value;
    this._object = object;
  }


  getIndex(name){
    return this._attributes.indexOf(name);
  }


  /**
   *
   * @returns {*} raw object as it is generated by R with Plumber and jsonlite serializer
   */
  get object() {
    return this._object;
  }

  coefficientsObject(){
    return this._object.value[this.getIndex('coefficients')];
  }


  /**
   * @returns {*} beta estimates from coefficients matrix
   */
   getBetasEstimates() {
    const obj = this.coefficientsObject();
    const size = obj.attributes.dim.value[0];  // get number of rows
    return obj.value.slice(0,size);
  }

  /**
   *
   * @returns {*} beta estimates from coefficients matrix
   */
  getBetasArray() {
    const obj = this.coefficientsObject();
    const size = obj.attributes.dim.value[0];  // get number of rows
    return obj.attributes.dimnames.value[0].value.map((el, index) => {
      const sample = {"name": el};
      sample[obj.attributes.dimnames.value[1].value[0]] = obj.value[index];
      sample[obj.attributes.dimnames.value[1].value[1]] = obj.value[index + size];
      sample[obj.attributes.dimnames.value[1].value[2]] = obj.value[index + 2 * size];
      sample[obj.attributes.dimnames.value[1].value[3]] = obj.value[index + 3 * size];
      return sample;
    });
  }

  getBetasHeaders(){
    return this.coefficientsObject().attributes.dimnames.value[1].value;
  }

  /**
   * @returns {*} beta names from coefficients matrix
   */
  getBetasNames() {
    const obj = this.coefficientsObject();
    return obj.attributes.dimnames.value[0].value;
  }


  getArrayOfValuesByAttributeName(name){
    return this._object.value[this.getIndex(name)].value;
  }

  /**
   * Get CI for intercept and slope params. These values are used to evaluate hypothesis H0!!!
   * @returns {*} confidence intervals for beta estimations as presented in R language
   * i.e. eg.
   *               2.5 %    97.5 %
   *     (Intercept) -8.5669158 5.9892763
   *                  0.1904498 0.2156429
   */
  getCIArray() {
    const obj = this._object.value[this.getIndex("confintresult")];
    const size = obj.attributes.dim.value[0];  // get number of rows
    return obj.attributes.dimnames.value[0].value.map((el, index) => {
      const sample = {"name": el};
      sample[obj.attributes.dimnames.value[1].value[0]] = obj.value[index];
      sample[obj.attributes.dimnames.value[1].value[1]] = obj.value[index + size];
      return sample;
    });
  }

  getCIHeaders(){
    return this._object.value[this.getIndex("confintresult")].attributes.dimnames.value[1].value;
  }

  /**
   * @returns {*} beta names from coefficients matrix
   */
  getCINames() {
    return this._object.value[this.getIndex("confintresult")].attributes.dimnames.value[0].value;
  }


  /**
   * Get confidence interval lower bound values predicted  for each point x.
   * This parses output of ciTools :: add_ci function containing the following list:
   * ['Y', 'X1', 'X2',..., 'pred', 'lcb', 'ucb']
   * @returns {*} vector  of size [n] containing lower bound values of CI calculated for
   * ordered ASCENDING x
   */
  getLowerboundci() {
    const ci = this._object.value[this.getIndex("ci")];
    return (ci!=null)
      ? ci.value[ci.value.length-2].value.sort(function(a, b){return a - b})
      : ci;
  }

  /**
   * Get confidence interval lower bound values predicted  for each point x.
   * This parses output of ciTools :: add_ci function containing the following list:
   * ['Y', 'X1', 'X2',..., 'pred', 'lcb', 'ucb']
   * @returns {*} vector  of size [n] containing lower bound values of CI calculated for
   * ordered ASCENDING x
   */
  getUpperboundci() {
    const ci = this._object.value[this.getIndex("ci")];
    return (ci!=null)
      ? ci.value[ci.value.length-1].value.sort(function(a, b){return a - b})
      : ci ;
  }


  /**
   * Get prediction interval lower bound values calculated  for each point x.
   * @returns {*} vector  of size [n] containing lower bound values of PI calculated for
   * ordered ASCENDING x
   */
  getLowerboundpi() {
    const pi = this._object.value[this.getIndex("pi")];
    return (pi != null)
      ? pi.value[pi.value.length-2].value.sort(function(a, b){return a - b})
      : pi;
  }


  /**
   * Get prediction interval lower bound values calculated  for each point x.
   * @returns {*} vector  of size [n] containing lower bound values of PI calculated for
   * ordered ASCENDING x
   */
  getUpperboundpi() {
    const pi = this._object.value[this.getIndex("pi")];
    return (pi != null)
      ? pi.value[pi.value.length-1].value.sort(function(a, b){return a - b})
      : pi;
  }


  /**
   * Sorted order of requested regressor (for displaying purpose)
   * @param index
   * @return {array}
   */
  getOrderedX(index){
    const ci = this._object.value[this.getIndex("ci")]; // no matter which object with model data
    return (ci!=null)
      ? ci.value[index].value.sort(function(a, b){return a - b})
      : ci; //
  }

  /**
   * Converts to be able to communicate with Scatter plots, which handle only 2 dimensions and
   * property accessors (instead of normal methods)
   * @param betaIndex - index of beta estimate for calculation of slope parameter, confidence intervals rendering
   */
  convertToSLRModel(betaIndex){
    let lineParams = this.getBetasEstimates();
    return {
      betasEstimates: [lineParams[0], lineParams[betaIndex]],  // intercept and X[betaIndex]
      lowerboundci: this.getLowerboundci(),
      upperboundci: this.getUpperboundci(),
      lowerboundpi: this.getLowerboundpi(),
      upperboundpi: this.getUpperboundpi(),
      orderedx: this.getOrderedX(betaIndex)
    }
  }

}
