import React from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {Button} from "primereact/components/button/Button";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";



/**
 * Component of NCBO  Recommender compliant with Bioportal API
 *  @link http://data.bioontology.org/documentation#nav_recommender
 *
 */
class RecommenderInputText extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredNodes:null,
      requestStatus:""
    };
    ["onSearch"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }



  onSearch(){
    const{text,onResponse,onError}=this.props;
    this.setState({requestStatus:REQUEST_STATUS_REQUESTED});
    axios.get(`/api/ncbo/recommender?input=${text}`).then(response => {
      onResponse(response.data);
      this.setState({requestStatus:REQUEST_STATUS_SUCCESS});
    }).catch(err => {
      onError(err);
      this.setState({requestStatus:REQUEST_STATUS_FAIL});
    });

  }

  render() {
    const{text,onTextChange,inputStyle}=this.props;
    const{requestStatus}=this.state;

    const icon = requestStatus===REQUEST_STATUS_REQUESTED? "fa fa-spinner fa-spin":"fa fa-search";

    return (
      <div  {...inputStyle!=null?{style:inputStyle}:{}}>
        <div className="ui-inputgroup">
          <InputTextarea value={text}   onChange={(e) => onTextChange(e.target.value )} />
          <Button icon={icon} onClick={this.onSearch} title={"Find matching ontologies in NCBO"}/>
        </div>
      </div>
    )
  }
}

export default RecommenderInputText;

RecommenderInputText.propTypes = {
  text:PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onError:PropTypes.func.isRequired,
  onResponse:PropTypes.func,
  inputStyle:PropTypes.string
};


