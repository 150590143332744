export const ACTIVITY_LIST_REQUEST = "ACTIVITY_LIST_REQUEST";
export const ACTIVITY_LIST_SUCCESS = "ACTIVITY_LIST_SUCCESS";
export const ACTIVITY_LIST_FAIL = "ACTIVITY_LIST_FAIL";

export const ACTIVITY_REQUEST = "ACTIVITY_REQUEST";
export const ACTIVITY_SUCCESS = "ACTIVITY_SUCCESS";
export const ACTIVITY_FAIL = "ACTIVITY_FAIL";
export const ACTIVITY_CREATE = "ACTIVITY_CREATE";

export const MANUAL_TASK_LIST_GET_REQUEST = "MANUAL_TASK_LIST_GET_REQUEST";
export const MANUAL_TASK_LIST_GET_SUCCESS = "MANUAL_TASK_LIST_GET_SUCCESS";
export const MANUAL_TASK_LIST_GET_FAIL = "MANUAL_TASK_LIST_GET_FAIL";

export const MANUAL_TASK_GET_REQUEST = "MANUAL_TASK_GET_REQUEST";
export const MANUAL_TASK_GET_SUCCESS = "MANUAL_TASK_GET_SUCCESS";
export const MANUAL_TASK_GET_FAIL = "MANUAL_TASK_GET_FAIL";
export const CLEAR_MANUAL_TASK="CLEAR_MANUAL_TASK";
export const MANUAL_TASK_UPDATE_SUBTASK = "MANUAL_TASK_UPDATE_SUBTASK";


export const MANUAL_TASK_ACK_REQUEST = "MANUAL_TASK_ACK_REQUEST";
export const MANUAL_TASK_ACK_FAIL = "MANUAL_TASK_ACK_FAIL";
export const MANUAL_TASK_ACK_SUCCESS = "MANUAL_TASK_ACK_SUCCESS";



export const SET_MANUAL_TASK_VISIBILITY_FILTER = 'SET_MANUAL_TASK_VISIBILITY_FILTER';
export const SET_MANUAL_TASK_VISIBILITY_SORTER = 'SET_MANUAL_TASK_VISIBILITY_SORTER';


export const SET_ACTIVITY_VISIBILITY_FILTER = 'SET_ACTIVITY_VISIBILITY_FILTER';
export const SET_ACTIVITY_SORTER = 'SET_ACTIVITY_SORTER';

export const RESULTS_DOWNLOAD_REQUEST = "RESULTS_DOWNLOAD_REQUEST";
export const RESULTS_DOWNLOAD_FAIL = "RESULTS_DOWNLOAD_FAIL";
export const RESULTS_DOWNLOAD_SUCCESS = "RESULTS_DOWNLOAD_SUCCESS";

