import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  getSelectedPlotIndex,
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import {
  getSeriesCases,
} from "../selectors/CasesSelectors";
import {
  getSeriesContributions
} from "../selectors/ContributionsSelectors";
import ValidDataPanel from "../component/ValidDataPanel";
import {getSeriesObservations} from "../selectors/ObservationsSelectors";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration,
    seriesCases: getSeriesCases(state,null),
    seriesContributions: getSeriesContributions(state,null),
    instances: getSeriesObservations(state),
    plotIndex: getSelectedPlotIndex(state) // selector used here !
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
  }
};

export const ContainerValidDataPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(ValidDataPanel));