import { connect } from 'react-redux';
import { passwordReset } from '../action/PasswordResetAction.jsx';
import PasswordReset from '../component/PasswordReset';

const mapStateToProps = state => {
  return{
  	loginStatus : state.auth.login_status
  }
};

const mapDispatchToProps = dispatch => {
  return{
  	passwordReset : (resetObject,onSuccess,onError) => {
  	  dispatch(passwordReset(resetObject,onSuccess,onError))
  	} 
  }
};

const ContainerPasswordReset = connect(
 mapStateToProps,
 mapDispatchToProps)(PasswordReset);

export default ContainerPasswordReset;