import React from "react";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import {MiniWorkflowStepMonitor} from "./MiniWorkflowStepMonitor";
import {CircularProgressComponent} from "../../../root/CircularProgressComponent";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {REQUEST_STATUS_SUCCESS, VISUALIZATION_TOOL} from "../../../../Constants";
import {ContainerManualTool} from "../../containers/ContainerManualTool";
import {getNestedProp} from "../../../helpers/expressions";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Button} from "primereact/components/button/Button";
import { withTranslation } from 'react-i18next';
import {Tooltip} from "primereact/components/tooltip/Tooltip"; // High order translation component


/**Replacement for manual tool.
 * To use when a given step of miniworkflow in live presenter has been already performed.
 * It should be used only in LP Mode!!!
 * @version 1.0
 */
class ResultsPageINOS extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showWindowPortal: false,
      linkViewers: true,
      leftButtonMode: LEFT_BUTTON_MODE.NONE,
      showDialogWindow:false,
      stepIndex:0, // step for Dialog window
      showUserResult:true,
      showExpertResult:true
    };
    ["keyListener","updateSliceLines"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }


  componentDidMount() {
    const { livePresenterExperimentId, initializeResultsPage} = this.props;
    initializeResultsPage(livePresenterExperimentId);
    document.body.addEventListener("keydown", this.keyListener, false);
  }

  componentDidUpdate(prevProps, prevState, ss) {
    const { images, viewersState, skillResult, updateViewerProperty} = this.props;
    if (prevProps.skillResult !== skillResult  || (!prevProps.viewersState.hasOwnProperty('middle')
      && viewersState.hasOwnProperty("middle"))) {
      if (viewersState!=null
        && viewersState["middle"]!=null
        && viewersState["middle"].imageId!=null
        && images[viewersState["middle"].imageId].state===REQUEST_STATUS_SUCCESS) {
        this.setState({});
        const image = images[viewersState["middle"].imageId];
        const slMode = image['properties']['slicingModes'][0]; //SAGITTAL only
        const middleSlice = Math.abs(Math.floor((image['data'].getExtent()[slMode * 2 + 1]
          - image['data'].getExtent()[slMode * 2]) / 2));
        Object.keys(viewersState).forEach((el) => {
          updateViewerProperty(el, 'sliceNumber', middleSlice);
        });
      }
    }
  }

  /**
   * Clear state of viewers and widgets.
   * Removes key listener.
   */
  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.keyListener, false);
  }


  keyListener(event) {
    if (event.code === "PageDown") {

      this.props.onPrevious();
      return;
    }
    if (event.code === "PageUp") {

      this.props.onNext();
    }

  }
  updateSliceLines(e,lineId){
    const {updateViewerProperty,viewersState} = this.props;

    const crossHairTool = Object.assign({},viewersState['middle']['crossHairTool']);

    if (lineId==="user") {
      this.setState({showUserResult: e.checked});
      if (e.checked) {
        crossHairTool['defaultValue']['limits']['invisible']['slicingRanges_AXIAL_inferiormostSliceNumber'] = {color: "#0000FF"};
      } else  delete crossHairTool['defaultValue']['limits']['invisible']['slicingRanges_AXIAL_inferiormostSliceNumber'];
    }
    else {
      this.setState({showExpertResult: e.checked});
      if (e.checked) {
        crossHairTool['defaultValue']['limits']['invisible']['slicingRanges_AXIAL_superiormostSliceNumber'] = {color: "#FFFF00"};
      } else  delete crossHairTool['defaultValue']['limits']['invisible']['slicingRanges_AXIAL_superiormostSliceNumber'];
    }

    updateViewerProperty("middle","crossHairTool",crossHairTool);
  }

  render() {

    const {
      manualTaskState,  workload, recreateTrainingWorkflowInLP, livePresenterExperimentId, miniWorkflowNameInSet,  skillResult,
      miniWorkflowSetId, goToPreviousSkillStep,t } = this.props;


    let steps = (workload != null && workload.miniWorkflow != null && workload.miniWorkflow['miniWorkflowPath'] != null)
      ? workload.miniWorkflow['miniWorkflowPath'].map(el => {
        return {name: el.stepName}
      })
      : null;

    let currentStep = (workload != null && workload.miniWorkflow != null && workload.miniWorkflow['currentStep'] != null)
      ? (workload.miniWorkflow.status === "DONE") ? -1 : workload.miniWorkflow['currentStep']
      : null;

    let partialResults = <i className="fa fa-spinner fa-spin"
                            style={{fontSize: "40px", verticalAlign: "middle", margin: "auto"}}/>;
    if (skillResult != null && skillResult.resultState === REQUEST_STATUS_SUCCESS) {
      partialResults = skillResult.result.scoring.map((el,index) => <div  className="resultTableRow"  style={{position:"relative"}}>
        <div className="resultTableName" > {el.name}</div>
        <div className="resultTableDots"/>
        <div className="resultTableScore" id={"score-".concat(index)}> {Math.round(el.value)}/{el.max}</div>
        <a>
        <i className={"fa fa-question-circle-o"} style={{cursor:"pointer", float: "right", marginLeft: "10px",zIndex:"1000",position:"relative"}} onClick={()=>{this.setState({step:index,showDialogWindow:true})}}/>
        </a>
        <Tooltip for={"#score-".concat(index)} title={el.tooltip}/>
      </div>)

    }



    return (
      <React.Fragment>
        <div style={{display: "block", position: "fixed", top: "10px", left: "auto", zIndex: "1000"}}>
          {manualTaskState != null && steps != null && currentStep != null &&
          <MiniWorkflowStepMonitor
            steps={steps}
            currentStep={-1}
            status={"FINISHED"}/>
          }
        </div>
        <div style={{backgroundColor:"white"}}>
          <div className="viewerContainerFull">
            <div className="viewerContainerMainPanel"
                 style={{height: "80vh", flexDirection: "column", textAlign: "center", justifyContent: "space-evenly"}}>
              <h1 style={{fontSize: "x-large"}}> {t("resultsPage.gratitude")} </h1>

              {skillResult != null && skillResult.resultState === REQUEST_STATUS_SUCCESS && getNestedProp(['result','final','score'],skillResult) != null &&
                <div id={"final-score"} style={{width: "fit-content", margin: "0 auto"}}>
                  <CircularProgressComponent title= {t("resultsPage.yourScore")} value={Math.round(getNestedProp(['result','final','score'],skillResult))}/>
                  <Tooltip for={"#final-score"} title={getNestedProp(['result','final','tooltip'],skillResult)}  />
                </div>}
              <br/>
              <div className="resultTableMain">
                {partialResults}
              </div>
              <br/>

              <div style={{fontSize: "x-large", width:"80%",zIndex:"1000",margin:"auto"}}>
                <div> {getNestedProp(['result','final','text'],skillResult)}</div>
                <div>
                  <a
                    {...getNestedProp(['result','final','value'],skillResult)?{href:getNestedProp(['result','final','link'],skillResult)}:{}}
                  onClick={()=>{
                    //recreateTrainingWorkflowInLP(
                    //  getNestedProp(['result','final','livePresenterExperiment'],skillResult),
                    //  getNestedProp(['result','final','miniWorkflowSet'],skillResult),
                    //  getNestedProp(['result','final','miniWorkflow'],skillResult)
                    //);
                    if (!getNestedProp(['result','final','value'],skillResult)){  // checks if passed - remove exclamation to inverse!
                      goToPreviousSkillStep();
                    }
                    else
                      this.props.history.push(getNestedProp(['result','final','link'],skillResult))
                  }}
                >{ getNestedProp(['result','final','linkText'],skillResult) }</a>
                </div>
              </div>

            </div>
            <div className="viewerContainerMainPanel" style={{height: "80vh"}}>
              { getNestedProp(['result','views','main','originalImageId'],skillResult )!=null
                && getNestedProp(['result','views','main','labelMapImageId'],skillResult )!=null
                && !this.state.showDialogWindow
                &&  <ContainerManualTool
                predefinedToolConfiguration={
                  resultConf(skillResult.result.views.main.originalImageId,
                    skillResult.result.views.main.labelMapImageId,
                    skillResult.result.views.main.lut,
                    skillResult.result.views.main.lutDescription
                  )
                }
              />}
            </div>
          </div>
          <div/>
        </div>
        <Sidebar
          onHide={()=>this.setState({showDialogWindow:false,showUserResult:true,showExpertResult:true})}
          visible={this.state.showDialogWindow}
          fullScreen={true}
          style={{ width: "calc(100vw - 50px)",left: "50px", height: "calc(100vh - 50px)", top: "50px",display:this.state.showDialogWindow?"block":"none"}}
          contentStyle={{height:"100%",width:"max-content"}}
        >
          <Button label={t("general.button.close.label")} icon={"fa fa-close"} style={{position: "fixed", right: "5px"}} onClick={()=>this.setState({showDialogWindow:false})}/>
          { getNestedProp(['result','views','slices','originalImageId'],skillResult )!=null && this.state.showDialogWindow && this.state.step === 0 &&
          <div>
          <ContainerManualTool
            predefinedToolConfiguration={slicesConfiguration(
              skillResult.result.views.slices.originalImageId,
              skillResult.result.views.slices.userResult.value,
              skillResult.result.views.slices.expertResult.value,
            )}
          />
          <div style={{border: "solid 1px",height: "8em",  width: "15em", display: "inline-block", position: "absolute", marginTop: "45vh", marginLeft:"5em"}}>
            <h3>{skillResult.result.scoring[0].name}</h3>
            <div>
              <Checkbox checked={this.state.showUserResult} onChange={(e)=>{this.updateSliceLines(e,"user")}}/>
              <div  style={{width: "3em", borderBottom: "3px solid blue", display: "inline-block", marginBottom: "0.5em", marginRight:"1em",marginLeft:"1em"}}/>
              {t("resultsPage.your")}
            </div>
            <div>
              <Checkbox checked={this.state.showExpertResult} onChange={(e)=>{this.updateSliceLines(e,"expert")}}/>
              <div  style={{width: "3em", borderBottom: "3px solid yellow", display: "inline-block", marginBottom: "0.5em", marginRight:"1em",marginLeft:"1em"}}/>
              {t("resultsPage.expert")}
            </div>
          </div>

          </div>
          }
          {getNestedProp(['result','views','volumes','originalImageId'],skillResult )!=null &&
            this.state.showDialogWindow && this.state.step === 1 &&
            <div>
          <ContainerManualTool
            predefinedToolConfiguration={segmentationConfig(
              skillResult.result.views.volumes.originalImageId,
              skillResult.result.views.volumes.labelMapImageId,
              skillResult.result.views.volumes.lut,
              skillResult.result.views.volumes.lutDescription,
            )}
          />
              <div style={{border: "solid 1px",height: "8em",  width: "8em", display: "inline-block", position: "absolute", marginTop: "30vh", marginLeft:"0em"}}>
                <h3>{skillResult.result.scoring[1].name}</h3>
                <div>
                  <div  style={{width: "1em", borderBottom: "1em solid blue", display: "inline-block", marginBottom: "0em", marginRight:"1em",marginLeft:"1em"}}/>
                  {t("resultsPage.your")}
                </div>
                <div>
                  <div  style={{width: "1em", borderBottom: "1em solid yellow", display: "inline-block", marginBottom: "0em", marginRight:"1em",marginLeft:"1em"}}/>
                  {t("resultsPage.expert")}
                </div>
                <div>
                  <div  style={{width: "1em", borderBottom: "1em solid green", display: "inline-block", marginBottom: "0em", marginRight:"1em",marginLeft:"1em"}}/>
                  {t("resultsPage.overlap")}
                </div>
              </div>
            </div>
          }
        </Sidebar>
      </React.Fragment>
    )
  }

}

export default withTranslation()(withRouter(ResultsPageINOS));

ResultsPageINOS.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getAnnotations: PropTypes.func.isRequired,
  updateAnnotationData: PropTypes.func.isRequired,
  rmCallback: PropTypes.func.isRequired,
  saveOverlayData: PropTypes.func,
  clearAll: PropTypes.func.isRequired,
  addAnnotation: PropTypes.func.isRequired,
  setActiveAnnotation: PropTypes.func.isRequired,
  clearAnnotatorState: PropTypes.func.isRequired,
  clearViewersState: PropTypes.func.isRequired,
  initializeTool: PropTypes.func.isRequired,
  viewersState: PropTypes.array.isRequired,  //array containing state of all viewers
  updateViewerProperty: PropTypes.func.isRequired,
  updateViewersState: PropTypes.func.isRequired,
  updateViewer: PropTypes.func,
  goToPreviousStep: PropTypes.func,
  imageOptions: PropTypes.array.isRequired,
  changeImageType: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  overlays: PropTypes.array.isRequired,
  manualTaskState: PropTypes.string.isRequired,
  workload: PropTypes.object.isRequired,
  updateManualTask: PropTypes.func.isRequired,
  getAnotherCase: PropTypes.func.isRequired,
  handleKeySlicing: PropTypes.func,
  clearManualToolState: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  miniWorkflowNameInSet: PropTypes.string.isRequired,
  miniWorkflowSetId: PropTypes.string.isRequired,
  livePresenterExperimentId: PropTypes.string.isRequired,
  initializeResultsPage: PropTypes.func.isRequired,
  skillResult: PropTypes.object.isRequired,
  goToPreviousSkillStep: PropTypes.func.isRequired

};


const toolConf = {
  "id":
    "1dd174e57260e384c4f45e9c490004a9",
  "name":
    "Visualization tool (Check)",
  "description":
    "This tool is used to visualize a segmentation and check it.",
  "version":
    "0.0.1",
  "privacy":
    "PUBLIC",
  "type":
  VISUALIZATION_TOOL,
  "inputs":
    {
      "inputImage_key":
        {
          "name":
            "Input image",
          "description":
            "Input image",
          "isList":
            false,
          "type":
            "imageEntityInOut",
          "imageEntityInOut_Type":
            "ANATOMICAL",
          "imageEntityInOut_FileFormat":
            "nii.gz",
          "required":
            true
        }
      ,
      "segmentation_key":
        {
          "name":
            "Segmentation",
          "description":
            "Segmentation",
          "isList":
            false,
          "type":
            "roiInOut",
          "typeROI":
            "EXPLICIT",
          "required":
            true
        }
      ,
      "inputLutDescription_key1":
        {
          "name":
            "Input description of LUT data",
          "description":
            "Input LUT data for displaying options.",
          "isList":
            false,
          "type":
            "lookUpTableDescription",
          "required":
            true
        }
      ,
      "inputLut_key1":
        {
          "name":
            "Input LUT data",
          "description":
            "Input LUT data for overlay displaying.",
          "isList":
            false,
          "type":
            "lookUpTable",
          "required":
            true
        }
    }
  ,

  "configuration":
    {
      "luts":
        {
          "lut_key1":
            {
              "fromInputs":
                true,
              "lutInputKey":
                "inputLut_key1"
            }
        }
      ,
      "lutDescriptions":
        {
          "lutDescription_key1":
            {
              "fromInputs":
                true,
              "lutDescriptionInputKey":
                "inputLutDescription_key1"
            }
        }
      ,
      "rois":
        {
          "overlays":
            {
              "overlay_key_1":
                {
                  "fromInputs":
                    true,
                  "imageInputKey":
                    "segmentation_key",
                  "lutKey":
                    "lut_key1",
                  "lutDescriptionKey":
                    "lutDescription_key1"
                }
            }
        }
      ,
      "scenes":
        {
          "sceneKey1":
            {
              "primaryImageInputKey":
                "inputImage_key",
              "rois":
                {
                  "overlays":
                    ["overlay_key_1"]
                }
            }
        }
      ,
      "viewers":
        {
          "layout":
            {
              "type":
                "SINGLE",
              "layoutOrder":
                {
                  "middle":
                    "0"
                }
            }
          ,
          "renderWindows":
            {
              "0":
                {
                  "name":
                    "3D viewer",
                  "type":
                    "3D",
                  "displayControls":
                    {
                      "smoothing":
                        {
                          "controlVisible":
                            false,
                          "controlEnabled":
                            false,
                          "defaultValue":
                            true
                        }
                      ,
                      "sequence":
                        {
                          "controlVisible":
                            false,
                          "controlEnabled":
                            false
                        }
                    }
                  ,
                  "displayScenes":
                    {
                      "possibleScenesToDisplay":
                        [
                          "sceneKey1"
                        ],
                      "hasDefaultSceneToDisplay":
                        true,
                      "defaultSceneToDisplay":
                        "sceneKey1"
                    },
                  "displayImageSlices": {
                    "0": {
                      "fromState": false,
                      "initialState": {
                        "orientationAndSliceNumber": {
                          "orientation": "SAGITTAL",
                          "strategy": "DYNAMIC",
                          "dynamicSliceValue": "LABELMAP_COG",
                          "labelMapValue": 1,
                          "layerIndex": 0
                        },
                        "initialWindowLevel": {
                          "strategy": "DYNAMIC",
                          "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
                        }
                      }
                    }
                  }
                }
            }
        }
      ,
      "widgets":
        {

        }
    }
};

let resultConf = (inputImage_key, segmentation_key,lut_key,lutDescription_key) => {
  return {
    "miniWorkflow": {
      "currentTool": toolConf,
      "currentMaterializedTask": {
        "inputs": {
          "inputImage_key": {
            "value": inputImage_key
          },
          "segmentation_key": {
            "value": segmentation_key,
            "format": "nii.gz"
          },
          "inputLut_key1": {
            "value": lut_key
          },
          "inputLutDescription_key1": {
            "value": lutDescription_key
          }
        }
      }
    }
  }
};

const slicesConfiguration =(inputImage_key, userValue, expertValue, showUserValue, showExpertValue)=> {
  return {
    "miniWorkflow": {
      "currentTool": {
        "id": "1dd174e57260e384c4f45e9c490004a9",
        "name": "Pin tool",
        "description": "This tool is used to characterize previously identified ROI's.",
        "version": "1.0.0",
        "privacy": "PUBLIC",
        "type": "VISUALIZATION_TOOL",
        "inputs": {
          "inputImage_key": {
            "name": "Input image",
            "description": "Input image",
            "isList": false,
            "type": "imageEntityInOut",
            "imageEntityInOut_Type": "ANATOMICAL",
            "imageEntityInOut_FileFormat": "nii.gz",
            "required": true
          },
          "lowerSliceNumber_key": {
            "name": "Lower axial slice number",
            "description": "Lower axial slice number",
            "isList": false,
            "type": "Number",
            "required": true
          },
          "upperSliceNumber_key": {
            "name": "The upper bound",
            "description": "The selected slice number for the upper bound",
            "isList": false,
            "type": "Number",
            "required": true
          }
        },
        "outputs": {},
        "bindingToolInputsToViewersInputs": [
          {
            "inputTool": "lowerSliceNumber_key",
            "inputViewer": {
              "viewerId": "1",
              "property": "slicingRanges_AXIAL_inferiormostSliceNumber"
            }
          },
          {
            "inputTool": "upperSliceNumber_key",
            "inputViewer": {
              "viewerId": "1",
              "property": "slicingRanges_AXIAL_superiormostSliceNumber"
            }
          }
        ],
        "configuration": {
          "scenes": {
            "sceneKey1": {
              "primaryImageInputKey": "inputImage_key",
              "rois": {
                "geometricalROIsInputKeys": [
                  "outputAnnotationTableData_key"
                ]
              }
            }
          },
          "viewers": {
            "layout": {
              "type": "SINGLE",
              "layoutOrder": {
                "middle": "0",
                "invisible": "1"
              }
            },
            "renderWindows": {
              "0": {
                "name": "Middle viewer",
                "type": "2D",
                "linkedControlVisible": false,
                "initialState": {
                  "orientationAndSliceNumber": {
                    "orientation": "SAGITTAL",
                    "strategy": "DYNAMIC",
                    "dynamicSliceValue": "MIDDLE"
                  },
                  "initialWindowLevel": {
                    "strategy": "dynamic",
                    "value": "MIDDLE-FULL"
                  }
                },
                "displayScenes": {
                  "possibleScenesToDisplay": [
                    "sceneKey1"
                  ],
                  "hasDefaultSceneToDisplay": true,
                  "defaultSceneToDisplay": "sceneKey1"
                },
                "lookAndFeel": {
                  "color": "#000000",
                  "hasControlPanel": false
                },
                "interactions": {
                  "activeViewer": {
                    "controlEnabled": false,
                    "defaultValue": true
                  },
                  "crossHairTool": {
                    "controlEnabled": true,
                    "defaultValue": {
                      "limits": {
                        "invisible": {
                          "slicingRanges_AXIAL_superiormostSliceNumber": {
                            "color": "#FFFF00"
                          },
                          "slicingRanges_AXIAL_inferiormostSliceNumber": {
                            "color": "#0000FF"
                          }
                        }
                      }
                    }
                  }
                }
              },
              "1": {
                "name": "Middle viewer",
                "type": "2D",
                "linkedControlVisible": false,
                "initialState": {
                  "orientationAndSliceNumber": {
                    "orientation": "AXIAL",
                    "strategy": "DYNAMIC",
                    "dynamicSliceValue": "MIDDLE"
                  },
                  "initialWindowLevel": {
                    "strategy": "dynamic",
                    "value": "MIDDLE-FULL"
                  }
                },
                "displayScenes": {
                  "possibleScenesToDisplay": [
                    "sceneKey1"
                  ],
                  "hasDefaultSceneToDisplay": true,
                  "defaultSceneToDisplay": "sceneKey1"
                },
                "lookAndFeel": {
                  "color": "#000000",
                  "hasControlPanel": false
                },
                "interactions": {
                  "activeViewer": {
                    "controlEnabled": false,
                    "defaultValue": false
                  },
                  "crossHairTool": {
                    "controlEnabled": false
                  }
                }
              }
            }
          },
          "widgets": {
            "crossHairTool": {
              "controlVisible": false,
              "controlEnabled": false,
              "defaultValue": true
            },
            "slicePositionIndexTool": {
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": true,
              "properties": {
                "viewerKey": {
                  "controlVisible": false,
                  "controlEnabled": false,
                  "defaultValue": "middle"
                }
              }
            }
          }
        }
      },
      "currentMaterializedTask": {
        "label": "training-9c39728c-8ce8-419e-886d-8138ba68e96e",
        "inputs": {
          "inputImage_key": {
            "value": inputImage_key,
            "label": "T1"
          },
          "lowerSliceNumber_key": {
            "value": userValue
          },
          "upperSliceNumber_key": {
            "value": expertValue
          }
        },
        "status": "TODO",
        "assignedTo": "gregory.bliault@u-bordeaux.fr",
        "dueDate": "2019-09-28T22:43:29.728Z",
        "miniWorkflow": "mw1",
        "uuid": "53b643d3f55f98c38962b596530000d3"
      },
      "status": "notstarted"
    },
    "listOfCases": [
      {
        "caseNumber": "EXTRANODAL1",
        "caseStatus": "notstarted"
      }
    ],
    "currentCase": "EXTRANODAL1"
  }
};


export const segmentationConfig =(inputImage_key, segmentation_key,lut_key, lutDescription_key)=> {return {
    "miniWorkflow": {
      "currentTool": {
      "id": "1dd174e57260e384c4f45e9c490004a9",
        "name": "Characterization tool",
        "description": "This tool is used to characterize previously identified ROI's.",
        "version": "1.0.0",
        "privacy": "PUBLIC",
        "type": "VISUALIZATION_TOOL",
        "inputs": {
        "inputImage_key2": {
          "name": "Input image",
            "description": "Input image",
            "isList": false,
            "type": "imageEntityInOut",
            "imageEntityInOut_Type": "ANATOMICAL",
            "imageEntityInOut_FileFormat": "nii.gz",
            "required": true
        },
        "inputImage_key3": {
          "name": "Segmentation",
            "description": "Segmentation",
            "isList": false,
            "type": "roiInOut",
            "typeROI": "EXPLICIT",
            "required": true,
            "imageEntityInOut_FileFormat": "nii.gz"
        },
        "inputLut_key2": {
          "name": "Input LUT data",
            "description": "Input LUT data for overlay displaying.",
            "isList": false,
            "type": "lookUpTable",
            "required": true
        },
        "inputLutDescription_key2": {
          "name": "Input description of LUT data",
            "description": "Input LUT data for displaying options.",
            "isList": false,
            "type": "lookUpTableDescription",
            "required": true
        }
      },
      "outputs": {},
      "configuration": {
        "luts": {
          "lut_key2": {
            "fromInputs": true,
              "lutInputKey": "inputLut_key2"
          }
        },
        "lutDescriptions": {
          "lutDescription_key2": {
            "fromInputs": true,
              "lutDescriptionInputKey": "inputLutDescription_key2"
          }
        },
        "rois": {
          "overlays": {
            "overlay_key_2": {
              "fromInputs": true,
                "imageInputKey": "inputImage_key3",
                "lutKey": "lut_key2",
                "lutDescriptionKey": "lutDescription_key2",
                "label": "Segmentation"
            }
          },
          "geometricalROIs": {}
        },
        "scenes": {
          "sceneKey2": {
            "primaryImageInputKey": "inputImage_key2",
              "rois": {
              "geometricalROIs": [
                "roiList_key1"
              ],
                "overlays": [
                "overlay_key_2"
              ]
            }
          }
        },
        "viewers": {
          "layout": {
            "type": "1M1R_IN_ROW",
              "layoutOrder": {
              "middle": "0",
               "upperRight": "1"
            }
          },
          "renderWindows": {
            "0": {
              "name": "Sagittal viewer",
                "type": "2D",
                "initialState": {
                "orientationAndSliceNumber": {
                  "orientation": "AXIAL",
                    "strategy": "DYNAMIC",
                    "dynamicSliceValue": "LABELMAP_COG"
                },
                "initialWindowLevel": {
                  "strategy": "DYNAMIC",
                    "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
                }
              },
              "displayScenes": {
                "possibleScenesToDisplay": [
                  "sceneKey2"
                ],
                  "hasDefaultSceneToDisplay": true,
                  "defaultSceneToDisplay": "sceneKey2"
              },
              "displayControls": {
                "orientation": {
                  "controlVisible": false,
                    "controlEnabled": false,
                    "options": [
                    {
                      "label": "Sagittal",
                      "value": "SAGITTAL"
                    },
                    {
                      "label": "Coronal",
                      "value": "CORONAL"
                    },
                    {
                      "label": "Axial",
                      "value": "AXIAL"
                    }
                  ],
                    "defaultValue": "AXIAL"
                },
                "smoothing": {
                  "controlVisible": false,
                    "controlEnabled": false,
                    "defaultValue": false
                },
                "sequence": {
                  "controlVisible": true,
                    "controlEnabled": true
                },
                "linked": {
                  "controlVisible": false,
                    "controlEnabled": false
                }
              },
              "lookAndFeel": {
                "color": "#ffa07a"
              }
            },
            "1": {
              "name": "3D viewer",
                "type": "3D",
                "displayControls": {
                "smoothing": {
                  "controlVisible": false,
                    "controlEnabled": false,
                    "defaultValue": true
                },
                "sequence": {
                  "controlVisible": false,
                    "controlEnabled": false
                }
              },
              "displayScenes": {
                "possibleScenesToDisplay": [
                  "sceneKey2"
                ],
                  "hasDefaultSceneToDisplay": true,
                  "defaultSceneToDisplay": "sceneKey2"
              },
              "displayImageSlices": {
                "0": {
                  "fromState": true,
                    "stateKey": "middle"
                }
              }
            }
          }
        },
        "widgets": {
          "mainTool": {
            "activeViewerId": "middle"
          },
          "labelmapOpacity": {
            "controlVisible": true,
            "controlEnabled": true,
            "defaultValue": 1
          },
          "labelmapLUT": {
            "controlVisible": true,
            "controlEnabled": true,
            "defaultValue": 1
          }
        }
      }
    },
    "currentMaterializedTask": {
      "label": "training-9c39728c-8ce8-419e-886d-8138ba68e96e",
        "inputs": {
        "inputImage_key2": {
          "value": inputImage_key,
            "label": "Flair"
        },
        "inputImage_key3": {
          "value": segmentation_key,
            "format": "nii.gz"
        },
        "inputLut_key2": {
          "value": lut_key
        },
        "inputLutDescription_key2": {
          "value": lutDescription_key
        }
      },
      "status": "TODO",
        "assignedTo": "amarciniak@bwh.harvard.edu",
        "dueDate": "2019-09-28T22:43:29.728Z",
        "miniWorkflow": "mw1",
        "uuid": "06bf3451e2d63c3bf86b439838081dc7"
    },
    "status": "notstarted"
  },
    "listOfCases": [
    {
      "caseNumber": "NMO1",
      "caseStatus": "notstarted"
    }
  ],
    "currentCase": "NMO1"
  }
};





