import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import {dateDiffInDays} from "../../helpers/dates";
import ShowHide from "./ShowHide";
import {renderSortIcon, sortByProperty} from "../../helpers/comparators";
import {withTranslation} from "react-i18next";

/**
 * Activities (Tasks) Panel visible in Dashboard.
 */
class Activities extends React.Component {

    constructor() {
        super();
        this.onShowHideCompleted = this.onShowHideCompleted.bind(this);
        Activities.isMissedDeadline = Activities.isMissedDeadline.bind(this);
    }

    componentWillMount() {
        const {getActivities} = this.props;
        getActivities();
    }

    onShowHideCompleted() {
        if (this.props.filter !== 'HIDE_COMPLETED')
            this.props.onFilterChange('HIDE_COMPLETED');
        else this.props.onFilterChange('SHOW_ALL');
    }

    static isMissedDeadline(date, status) {
        if (status === "COMPLETED")
            return <span title="Task completed">Completed</span>;
        else {
            let now=new Date();
            let thenn = new Date(date);
            if (now > thenn) {
                return <span className="missedDeadline"
                             title="Missed deadline">{thenn.toLocaleDateString()}</span>;
            }
            else {
                if (dateDiffInDays(now, thenn) < 2)
                    return <span className="closeDeadline"
                          title="Don't miss the deadline! This is the last day!">
                        {thenn.toLocaleDateString()}
                    </span>;
                else return <span>{thenn.toLocaleDateString()}</span>;
            }
        }
    };
    render() {
        let {activities, onSorterChange, sorter, t} = this.props;

        // if (this.state.filterCompleted) activities=activities.filter((p)=>p.dueDate!=='Completed');

        const nodes = activities.map((p) =>
            <div className='spineRowStyle' key={p.uuid}>
                {p.type === "singleTask" &&
                    <Link to={`/home/task/${p['taskId']}`}>
                        <div className="spineColStyle" style={{width: "50%"}}>{p.name}</div>
                    </Link>
                }
                {p.type === "miniWorkflow" &&
                <Link to={`/home/miniwf/${p['miniWorkflowKey']}/${p['miniWorkflowSetId']}`}>
                    <div className="spineColStyle" style={{width: "50%"}}>{p.name}</div>
                </Link>
                }
                <div className="spineColStyle col4">{Activities.isMissedDeadline(p.dueDate, p.status)}</div>
                <div className="spineColStyle col4" style={{textAlign: "center"}}>{p.numberOfCases}</div>
                {/*{isMissedDeadline(["spineColStyle","col4"],p.dueDate)}*/}
            </div>);


        return (
            <div style={{width: '98%', margin: '1%'}}>
                <span className="reg-headtitle">{t("dashboard.tables.myTasks.header")}</span>
                <div className="spineTableStyle">
                    <div className='spineRowHeaderStyle'>
                        <div className="spineColStyle " style={{width: '50%'}} onClick={() => {
                            sortByProperty("NAME", sorter, onSorterChange);
                        }}>
                            {t("dashboard.tables.myTasks.property.task.label")}{renderSortIcon('NAME', sorter)}</div>
                        <div className="spineColStyle col4" onClick={() => {
                            sortByProperty("DUE_DATE", sorter, onSorterChange);
                        }}>
                            {t("dashboard.tables.myTasks.property.dueDate.label")}{renderSortIcon('DUE_DATE', sorter)}</div>
                        <div className="spineColStyle col4" style={{textAlign: "center"}} onClick={() => {
                            sortByProperty("NUMBER_OF_CASES", sorter, onSorterChange);
                        }}>
                           {t("dashboard.tables.myTasks.property.numberOfCases.label")} {renderSortIcon('NUMBER_OF_CASES', sorter)}</div>
                    </div>
                    <div className="spineTableScrollableContent">
                        <div className="spineTableBody">
                            {nodes}
                        </div>
                    </div>
                </div>
                <ShowHide clb={this.onShowHideCompleted} value={this.props.filter === 'HIDE_COMPLETED'}/>
            </div>
        );
    }
};

export default  withTranslation()(Activities);

Activities.propTypes = {
    getActivities: PropTypes.func.isRequired,
    activities: PropTypes.array.isRequired,
    onFilterChange: PropTypes.func,
    onSorterChange: PropTypes.func
};


