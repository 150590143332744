import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {
  filterContributors, getContributorName,
  getMeasurementShortLabel
} from "../action/SAMAction";
import {getNestedProp} from "../../helpers/expressions";

/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class SeriesDataPointsPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    ["contributorBody"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }


  contributorBody (row, column) {
    const {rawData} = this.props;
    const contributors =[...new Set(row.contributions.map(el=>el.contributorId))];  // remove dups
    const label = contributors.length>0
        ? contributors.map(el=>getContributorName(el,rawData)).join(", ")
        : "NA";
    return <div >{label}</div>;
  };

  render() {
    const {t, instances, selectedMeasurementConfiguration,rawData} = this.props;

    const dimensionality = Array.isArray(selectedMeasurementConfiguration) ?selectedMeasurementConfiguration.length:0;

    let measurementsColumns = [...Array(dimensionality).keys()]
      .map((colName, i) => {
        return <Column key={"measurement"+i}
                       header={getMeasurementShortLabel(selectedMeasurementConfiguration[i],rawData)}
                       body={(row)=>{return getNestedProp(["contributions",i,"value"],row)}}
        />;
      });


    return (
      <React.Fragment>
        <DataTable
          value={instances} // concat and remove dups
          emptyMessage={"No properties found"}
          paginator={true}
          rows={10}
        >
          <Column field="key.contributorId" header="Contributor key" body={this.contributorBody} filter={true} filterFunction={(v,f)=>filterContributors(v,f,rawData)} filterMatchMode={"custom"}/>
          <Column field="key.caseId" header="Case key" sortable={true} filter={true} filterMatchMode={"contains"}/>
          {/*<Column field="key.roiId" header="ROI key" sortable={true} filter={true} filterMatchMode={"contains"}/>*/}
          {measurementsColumns}

        </DataTable>
      </React.Fragment>
    );
  }
}

export default withTranslation()(SeriesDataPointsPanel);

SeriesDataPointsPanel.propTypes = {
  rawData: PropTypes.object.isRequired,
  instances:PropTypes.array.isRequired,
  updateSelectedDataPoint: PropTypes.func.isRequired,
  selectedMeasurementConfiguration: PropTypes.array.isRequired,
  t: PropTypes.func
};
