import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { HOST_URL } from "../../../Constants";
import SlideCard from "../component/slideCard";
import store from "../../Store";
import axios from "axios/index";

const SideView = (props) => {
  const { match } = props;
  const [slides, setSlides] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const { register, handleSubmit, control, errors } = useForm();
  const [requestData, setRequestData] = useState(new Date());
  const { fields, append, remove } = useFieldArray({
    control,
    name: "slideProperties",
  });

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
    axios
      .get(HOST_URL + "/api/presentation/" + match.params.id + "/slide")
      .then((response) => {
        console.log("getSlideList :: response ::", response);
        if (response.status === 200) {
          setSlides(response.data);
        }
      })
      .catch((error) => {
        console.log("project err:", error);
      });
  }, [requestData]);

  const onSubmit = (data) => {
    const slideProperties = new Object();
    data.slideProperties.forEach((item) => {
      slideProperties[item.type] = item.value;
    });

    axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
    axios
      .get(HOST_URL + "/api/presentation/" + match.params.id)
      .then((presentation) => {
        console.log("PresentationAction.js :: getPresentation :: response ::", presentation);

        if (presentation.status === 200) {
          axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
          axios
            .post(HOST_URL + "/api/presentation/" + match.params.id + "/slide", {
              name: data.slideName,
              slideType: data.slideType,
              description: data.slideDescription,
              title: data.slideName,
              thumbnail: data.slideThumbnail,
              show: true,
              slideProperties: slideProperties,
            })
            .then((slide) => {
              console.log("addSlide :: response ::", slide);
              let slidesList = presentation.data.slides;
              if (data.slidePosition === "last") {
                slidesList = slidesList.push(slide.data.uuid);
              } else {
                slidesList = slidesList.splice(data.slidePosition, 0, slide.data.uuid);
              }

              if (slide.status === 200) {
                axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
                axios
                  .put(HOST_URL + "/api/presentation/" + match.params.id, {
                    creationDate: presentation.data.creationDate,
                    uuid: presentation.data.uuid,
                    revisionId: presentation.data.revisionId,
                    name: presentation.data.name,
                    description: presentation.data.description,
                    privacy: presentation.data.privacy,
                    owner: presentation.data.owner,
                    modifiedBy: presentation.data.modifiedBy,
                    loopSlides: presentation.data.loopSlides,
                    slides: presentation.data.slides,
                  })
                  .then((update) => {
                    console.log("addSlidePresentation :: response ::", update);
                    setRequestData(new Date());

                    if (update.status === 200) {
                      console.log("Slide Added To Presentation");
                    }
                  })
                  .catch((error) => {
                    console.log("project err:", error);
                  });
              }
            })
            .catch((error) => {
              console.log("project err:", error);
            });
        }
      })
      .catch((error) => {
        console.log("project err:", error);
      });

    setModalShow(false);
  };

  return (
    <div className="PresentationList">
      {modalShow && (
        <>
          <div className="AddPresentationModalFade"></div>
          <div className="AddPresentationModalWrapper">
            <div className="AddPresentationModalBlock">
              <div className="AddPresentationModal">
                <div className="AddPresentationModalHeader">
                  <h4>Add Slide</h4>
                  <button onClick={() => setModalShow(false)}>
                    <span>×</span>
                  </button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="AddPresentationModalBody">
                    <label>Slide Name: </label>
                    <input
                      type="text"
                      className="PresentationTextBox"
                      name="slideName"
                      ref={register({ required: true })}
                    />
                    <br />
                    <label>Slide Position: </label>
                    <br />
                    <select name="slidePosition" ref={register({ required: true })}>
                      <option value="last">Add to last</option>
                      {slides.map((items, key) => {
                        return <option value={key}>{key + 1}</option>;
                      })}
                    </select>
                    <br />
                    <label>Slide Type: </label>
                    <br />
                    <select name="slideType" ref={register({ required: true })}>
                      <option value="image">Image</option>
                      <option value="video">Video</option>
                      <option value="experiment">Experiment</option>
                      <option value="miniWorkflowTask">Mini Workflow Task</option>
                    </select>
                    <br />
                    <label>Slide Description: </label>
                    <input
                      type="text"
                      className="PresentationTextBox"
                      name="slideDescription"
                      ref={register({ required: true })}
                    />
                    <br />
                    <label>Slide Thumbnail: </label>
                    <input
                      type="url"
                      className="PresentationTextBox"
                      name="slideThumbnail"
                      ref={register({ required: true })}
                    />
                    <br />
                    <label>Slide Properties: </label>
                    <ul>
                      {fields.map((item, index) => (
                        <li key={item.id}>
                          <select name={`slideProperties[${index}].type`} ref={register({ required: true })}>
                            <option value="url">URL</option>
                            <option value="experimentId">Experiment ID</option>
                            <option value="miniWorkflowSetId">MiniWorkflowSet ID</option>
                          </select>
                          <input
                            className="PresentationTextBox"
                            name={`slideProperties[${index}].value`}
                            ref={register({ required: true })}
                          />
                          <button className="SlidePropertiesButton" type="button" onClick={() => remove(index)}>
                            Delete
                          </button>
                        </li>
                      ))}
                    </ul>
                    <button className="SlidePropertiesButton" type="button" onClick={() => append({})}>
                      New Property
                    </button>
                  </div>
                  <div className="AddPresentationModalFooter">
                    <button type="submit">Add Slide</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="PresentationDeckWrapper">
        <button className="PresentationAddBtn" onClick={() => setModalShow(true)}>
          Add Slide +
        </button>
        <div className="PresentationDeck">
          {slides.map((items, key) => {
            return (
              <SlideCard
                presentationId={match.params.id}
                data={items}
                number={key + 1}
                setRequestData={setRequestData}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SideView;
