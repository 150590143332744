import {getNestedProp} from "../helpers/expressions";

/**
 * Checks if all viewers have all viewers state property smoothing set on true.
 * @param viewersState
 * @return {boolean}
 */
export function areAllViewersSmoothed(viewersState){
  return Object.keys(viewersState).every((el) => {return viewersState[el]['smoothing'];})
}

/**
 *  Sets smoothing property to All viewers
 * @param viewersState
 * @param updateViewerProperty
 * @param value - boolean
 */
export function setAllViewersSmoothing(viewersState,updateViewerProperty,value){
  Object.keys(viewersState).forEach((el) => updateViewerProperty(el, 'smoothing', value));
}


/**
 *
 * Apply smoothing value to viewer. According to configuration,
 * if scope is not defined , it applies new value to all vtkImageSlice actors.
 *
 * @param renderer
 * @param smoothing
 * @param toolState
 */
export function applySmoothingValue(renderer,smoothing,toolState){
  const scope = getNestedProp(['scope'],toolState);
  if ("PRIMARY_IMAGE"===scope){
    renderer
      .getActors()
      .filter(el=>{return el.getClassName() === "vtkImageSlice"})[0]
      .getProperty()
      .setInterpolationType(smoothing?1:0)
  }
  else
    renderer
      .getActors()
      .filter(el=>{return el.getClassName() === "vtkImageSlice"})
      .forEach((el)=>el.getProperty().setInterpolationType(smoothing?1:0));
}