import {withRouter} from "react-router";
import {connect} from "react-redux";
import SAMSidebar
  from "../component/SAMSidebar";
import {clearInteractivePlotState, updateBlandAltmanPlotSettingsACSegmentations} from "../../visualization/action/InteractivePlotAction";
import {getSelectedPlotSettings} from "../selectors/SAMSelectors";
import {refreshAllPlots, switchMode, updatePropertySAM} from "../action/SAMAction";
import {updateBlandAltmanPlotData} from "../action/BAAction";
import {updateScatterPlotData} from "../action/ScatterPlotAction";
import {updateRegressionPlotData} from "../action/RegressionPlotAction";


const mapStateToProps = (state,ownProps) => {
  return {
    plotSettings: getSelectedPlotSettings(state),
    plotData: state.sam.plotData, // TODO wirte selectors
    rawData: state.sam.rawData,
    optionsSidebarVisible: state.sam.optionsSidebarVisible
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    switchAnalysisMode:(mode)=>{
      dispatch(switchMode(mode))
    },
    updateScatterPlotData:(index)=>{
      dispatch(updateScatterPlotData(index))
    },
    updateRegressionPlotData:()=>{
      dispatch(updateRegressionPlotData());
    },
    clearInteractivePlotState:()=>{
      dispatch(clearInteractivePlotState())
    },
    updatePlotSettings:(settings)=>{
      dispatch(updateBlandAltmanPlotSettingsACSegmentations(settings))
    },
    updateBlandAltmanPlotData:()=>{
      dispatch(updateBlandAltmanPlotData());
    },
    updatePropertySAM:(property,value)=>{
      dispatch(updatePropertySAM(property,value))
    },
    refreshAllPlots:()=>{
      dispatch(refreshAllPlots())
    }
  }
};

export const ContainerSAMSidebar = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SAMSidebar));