import React from 'react'
import { connect } from 'react-redux'
//import Login from '../component/Login'
import EventDiscoverBrighamPage from "../component/EventDiscoverBrighamPage";
import {recreateTrainingWorkflowInLP} from "../../dashboard/action/ManualTaskAction";
import EventNeuroAgoraPage from "../component/EventNeuroAgoraPage";

const mapStateToProps = state => {
  return{
    auth : state.auth
  }
};

const mapDispatchToProps = dispatch => ({
    recreateTrainingWorkflowInLP : (experimentId,miniWorkflowSet,miniWorkflowKey,eventId) => {
        dispatch(recreateTrainingWorkflowInLP(experimentId,miniWorkflowSet,miniWorkflowKey,eventId))
  }
});

const ContainerEventDiscoverBrighamPage = connect(
  mapStateToProps,
  mapDispatchToProps)(EventDiscoverBrighamPage);

export const ContainerEventNeuroAgoraPage = connect(
    mapStateToProps,
    mapDispatchToProps)(EventNeuroAgoraPage);


export default ContainerEventDiscoverBrighamPage;
