import React from 'react'
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import PlotPanel from "./PlotPanel";
import {getNestedProp} from "../../../helpers/expressions";


/**
 * Future feature!
 * Grid component for many plots within single view.
 *
 *
 */
export class MultiplotPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      panelGrid:JSON.parse(JSON.stringify(props.defaultConfiguration)), // array of arrays - needs to be deeply copied to avoid keeping state all the time
      settingsPanelVisible:false,
      selectedPanelRowIndex:0,
      selectedPanelColumnIndex:0,
    };
    ["addRow","deleteRow","addColumn","deleteColumn","openSettingsPanel","setType","calculateAbsoluteWidth"
      ,"calculateAbsoluteHeight"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }


  /**
   * Add row to grid at given position.
   * @param index - row to be appended to
   */
  addRow(index){
    const {panelGrid} =this.state;
    panelGrid.splice(index+1,0,[{}]);
    this.setState({panelGrid:panelGrid});
  }

  /**
   * Delete row at given position.
   * @param index
   */
  deleteRow(index){
    const {panelGrid} =this.state;
    if (panelGrid.length>1) {
      panelGrid.splice(index, 1);
      this.setState({panelGrid: panelGrid});
    }
  }

  /**
   * Add column
   * @param rowIndex - index of row to be expanded with a new column
   */
  addColumn(rowIndex){
    const {panelGrid} =this.state;
    panelGrid[rowIndex].push({});
    this.setState({panelGrid:panelGrid});
  }

  deleteColumn(rowIndex,columnIndex){
    const {panelGrid} =this.state;
    panelGrid[rowIndex].splice(columnIndex,1);
    this.setState({panelGrid:panelGrid});
  }

  openSettingsPanel(rowIndex,columnIndex){
    this.setState({
      settingsPanelVisible:true,
      selectedPanelRowIndex:rowIndex,
      selectedPanelColumnIndex:columnIndex
    });
  }

  calculateAbsoluteWidth(rowIndex){
    const {panelGrid} =this.state;
    return document.getElementById("plotGridRef").getBoundingClientRect().width/panelGrid[rowIndex].length;
  }
  calculateAbsoluteHeight(){
    const {panelGrid} =this.state;
    return document.getElementById("plotGridRef").getBoundingClientRect().height/panelGrid.length;
  }

  setType(event){
    const {panelGrid,selectedPanelRowIndex,selectedPanelColumnIndex} =this.state;
    const data = [{x:1,y:2},{x:2,y:3},{x:43,y:4}];
    panelGrid[selectedPanelRowIndex][selectedPanelColumnIndex].type = event.value;
    panelGrid[selectedPanelRowIndex][selectedPanelColumnIndex].data = data;
    this.setState({panelGrid:panelGrid})
  }


  render() {
    const {panelGrid,settingsPanelVisible,selectedPanelRowIndex,selectedPanelColumnIndex} =this.state;


    const rows = panelGrid.map((colArray,index)=>{
      const height = Math.round(100/panelGrid.length);
      const width = Math.round(100/colArray.length);
      const top = index * height;
      return (<div style={{...flexRowContainer,height:"".concat(String(height),"%"),top:"".concat(String(top),"%")}}>
        <div>
        <i className={"fa fa-plus"}  onClick={()=>this.addRow(index)}    title={"Add row"}    style={mainIconsStyle} role={"button"}/>
        <i className={"fa fa-plus"}  onClick={()=>this.addColumn(index)} title={"Add column"} style={mainIconsStyle} role={"button"}/>
        {panelGrid.length>1 &&
        <i className={"fa fa-trash"} onClick={()=>this.deleteRow(index)} title={"Remove row"} style={mainIconsStyle} role={"button"}/>}
        </div>
        {colArray.map((c,cIndex)=>{
          return <PlotPanel
            width={width}
            height={height}
            calculateHeight={this.calculateAbsoluteHeight}
            calculateWidth={()=>this.calculateAbsoluteWidth(index)}
            onDelete={()=>this.deleteColumn(index,cIndex)}
            onPanelSettings={()=>this.openSettingsPanel(index,cIndex)}
            type={panelGrid[index][cIndex].type}
            data={panelGrid[index][cIndex].data}
            />
          })}
      </div>);
    });

    return (<div id="plotGridRef" style={gridContainer}>
        {rows}
        <Dialog header={"Plot settings"}
                visible={settingsPanelVisible}
                modal={true}
                onHide={()=>this.setState({settingsPanelVisible:false})}
                width={"60vw"}
                height={"60vh"}
                contentStyle={{height:"100%"}}
                >
          <div className={"ui-g"}>
          <Dropdown options={this.props.plotOptions}
                    value={getNestedProp([[selectedPanelRowIndex],[selectedPanelColumnIndex],'type'],panelGrid)}
                    placeholder={"Select type of figure"}
                    style={{width:'10em'}}
                    onChange={(e)=>{this.setType(e)}}
          />
          </div>
        </Dialog>
    </div>
    )
  }
}

MultiplotPanel.defaultProps = {
  plotDataState:"",
  modelParametersState:"",
  defaultConfiguration:[[{}]],
  plotOptions:[
    {label:"Agreement",value:"AGREEMENT"},
    {label:"Rater",value:"RATER"},
    {label:"Histogram",value:"HISTOGRAM"},
    {label:"Avatar Scatter Plot",value:"AVATAR"},
    {label:"Gantt",value:"GANTT"},
    {label:"Scatter Plot",value:"SCATTER"}
    ]
};
MultiplotPanel.propTypes = {
  defaultConfiguration:PropTypes.array.isRequired,
  messageQueue: PropTypes.object,
  plotOptions:PropTypes.array
};

const gridContainer={
  width:"inherit",
  height:"inherit",
  position:"relative"
};
const flexRowContainer = {
  display: "flex",
  position:"absolute",
  width:"100%"
};

const iconStyle={
  marginBottom:"5px"
};

const mainIconsStyle={
  ...iconStyle,
  cursor:"pointer",
  display:"block"
};