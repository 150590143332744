import {connect} from "react-redux";
import {withRouter} from "react-router";
import AgreementModelsSidebar from "../../expDesign/component/d3components/AgreementModelsSidebar";
import {
    clearInteractivePlotState,
    updateBlandAltmanPlotSettingsACSegmentations
} from "../action/InteractivePlotAction";
import MultiAgreementModelsSidebar from "../../expDesign/component/d3components/MultiAgreementModelsSidebar";





const mapStateToProps = (state,ownProps) => {
    return {
        plotSettings: state.visu.interactivePlot.plotSettings
    }
};

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        clearInteractivePlotState:()=>{
            dispatch(clearInteractivePlotState())
        },
        updatePlotSettings:(settings)=>{
            dispatch(updateBlandAltmanPlotSettingsACSegmentations(settings))
        }
    }
};

export const ContainerAgreementModelsSidebar =withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)(AgreementModelsSidebar));


export const ContainerMultiAgreementModelsSidebar =withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(MultiAgreementModelsSidebar));

