import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {getSeriesContributions} from "../selectors/ContributionsSelectors";
import SeriesDataPointsPanel from "../component/SeriesDataPointsPanel";
import {getSelectedPlotSettings} from "../selectors/SAMSelectors";
import {getSeriesObservations} from "../selectors/ObservationsSelectors";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    contributions: getSeriesContributions(state),
    instances: getSeriesObservations(state),
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    }
  }
};

export const ContainerSeriesDataPointsPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SeriesDataPointsPanel));