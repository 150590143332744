import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import {
    ANNOTATION_PROPERTY_NAME__ID,
    ANNOTATION_TABLE_TOOL,
    REQUEST_STATUS_SUCCESS
} from "../../Constants";
import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
import { vec3, mat4 } from 'gl-matrix';
import * as vtkMath from 'vtk.js/Sources/Common/Core/Math';

//****************************************************************************
//
//            Key handlers acccording to 3D Slicer convention
//
//see https://www.slicer.org/wiki/Documentation/4.1/SlicerApplication/MouseandKeyboardShortcuts
//****************************************************************************


/** Helper to calculate slice with respect to constraints. Beware that minSlice can be greater to maxSlice if
 * direction is equal to -1.
 *
 * @param event - keyEvent
 * @param slice - current slice
 * @param slicingDirection - {1, -1}
 * @param minSlice - lower boundary in given direction [MOST INFERIOR, MOST RIGHT, MOST ANTERIOR]
 * @param maxSlice - upper boundary in given direction [MOST SUPERIOR, MOST LEFT, MOST POSTERIOR]
 * @returns {*}
 */
export function slicingKeyHandler(event,  slice, slicingDirection, minSlice, maxSlice) {
    let result = slice;
    if (event.code === "ArrowDown"
        || event.code === "ArrowUp"
        || event.code === "PageDown"
        || event.code === "PageUp"
        || event.code === "ArrowLeft"
        || event.code === "ArrowRight"
        || event.code === "Home"
        || event.code === "End"){

        if (event.type != null){
         event.stopPropagation();
         event.preventDefault();}
    }
        if (event.code === "ArrowDown" || event.code === "PageDown" || event.code === "ArrowLeft") {
        if (slicingDirection > 0)
            slice -= 1;
        else slice += 1;
        if ((slicingDirection > 0 && slice >= minSlice) || (slicingDirection < 0 && slice <= minSlice))
            return slice;
    }
    if (event.code === "ArrowUp" || event.code === "PageUp" || event.code === "ArrowRight") {
        if (slicingDirection > 0)
            slice += 1;
        else slice -= 1;
        if ((slicingDirection > 0 && slice <= maxSlice) || (slicingDirection < 0 && slice >= maxSlice))
            return slice;
    }
    if (event.code === "Home") {
            return minSlice;
    }
    if (event.code === "End") {
            return maxSlice;
    }
    return result;
}


/** Helper to handle navigation between fiducials.
 * Added logic:
 * - if end of list, then go to first when for forward
 * - if begining of list, then go to last one when backward,
 *
 * @param event - keyEvent
 * @param activeROI - active fiducial (Store)
 * @param setActiveIndexCallback - callback to what to do if proper key is clicked
 * @param data
 * @param removeCallback - callback to remove annotation
 * @param getWidgetToolState - function to get property of widget (tool,property)
 * @returns {*}
 */
export function fiducialKeyHandler(event, activeROI,  setActiveIndexCallback,data,removeCallback,getWidgetToolState) {

    const isGeneratedByForm = event.target!=null && event.target.form!=null && event.target.form.tagName==="FORM";
    const isGeneratedByFilter = event.target!=null && event.target.className.includes("filter");
    const isGeneratedByInput = event.target!=null && event.target.className.includes("input");

    if (isGeneratedByForm || isGeneratedByFilter || isGeneratedByInput){
        return;
    }

    if(event.code === "Backquote" || event.code === "Delete" || event.code === "Backspace") {

        const maxIndex = data.length-1;
        let result = data.findIndex((el) => {
            return el[ANNOTATION_PROPERTY_NAME__ID] === activeROI
        });
        if (event.code === "Backquote" && (!event.shiftKey) ) {
            if(result < maxIndex)
                result++; // if result===-1 then automatically this will give 0
            else
                result = 0;
            event.stopPropagation();
            event.preventDefault();
            setActiveIndexCallback(data[result])
        }
        if (event.code === "Backquote" && event.shiftKey) {
            if (result > 0)
                result--;
            else result = maxIndex;
            event.stopPropagation();
            event.preventDefault();
            setActiveIndexCallback(data[result]);
        }

        // if event comes from form then DO NOT HANDLE DELETING
        if (((event.code === "Delete") || (event.code === "Backspace")) && result > -1 ) {
            const editable = getWidgetToolState(ANNOTATION_TABLE_TOOL, 'editableROI');
            if (editable === null || editable === undefined || editable === true) {
                event.stopPropagation();
                event.preventDefault();
                removeCallback(data[result]);
                if (maxIndex>0) {
                    let newActiveIndex = 0;
                    if ((result - 1) > -1)
                        newActiveIndex = result - 1;
                    else
                        newActiveIndex = result;
                        setActiveIndexCallback(data[newActiveIndex]);
                }
            }
        }
    }
}

/** Helper to activate-deactivate displaying layers (works as toggler).
 *  Key G is making segmentations and contours  invisible
 * @param event - keyEvent
 * @param viewersState - function to call
 * @param isOn - value
 * @param callback - function to update state with parameter (stateValue)=>{}
 * @returns {*}
 */
export function toggleSegmentations(event,viewersState,isOn,callback) {
    if (event.code === "KeyG") {
        if (!(document.activeElement.form !=null || document.activeElement.formAction !=null)){
            event.stopPropagation();
            event.preventDefault();

            const value = isOn!=null  ? (!isOn) : false;

            Object.values(viewersState).forEach(el=>{
                if (el.renderer!=null){
                    el.renderer.getActors().forEach((act,index)=>{
                        if (index>0)
                            act.setVisibility(value);
                    });
                    el.renderer.getRenderWindow().render();
                }
            });
            callback(value);
        }
    }
}

/** Helper to activate-deactivate displaying annotations.
 *
 * @param event - keyEvent
 * @param callback - function to call
 * @returns {*}
 */
export function toggleAnnotations(event,callback) {
    if (event.code === "KeyA") {
        if (!(document.activeElement.form !=null || document.activeElement.formAction !=null) || document.activeElement.readOnly){
            event.stopPropagation();
            event.preventDefault();
            callback();
        }
    }
}


/** Helper to change radius in circle annotation.
 *
 * @param event - keyEvent
 * @param callback - function to call
 * @returns {*}
 */
export function annotationRadiusChangeKeys(event, callback) {
    if (event.key === "+" || event.key === "-" ) {
        if (!(document.activeElement.form !=null || document.activeElement.formAction !=null) || document.activeElement.readOnly){
            event.stopPropagation();
            event.preventDefault();
            callback();
        }
    }
}

/** Helper to activate-deactivate displaying annotation projections on surrounding slices.
 *
 * @param event - keyEvent
 * @param callback - function to call
 * @returns {*}
 */
export function toggleProjections(event,callback) {
    if (event.code === "KeyP") {
        if (!(document.activeElement.form !=null || document.activeElement.formAction !=null)){
            event.stopPropagation();
            event.preventDefault();
            callback();
        }
    }
}

/** Helper to toggle on-off Pin Tool.
 *
 * @param event - keyEvent
 * @param callback - function to call
 * @returns {*}
 */
export function togglePinToolShortcut(event,callback) {
    if (event.code === "KeyI") {
        if (!(document.activeElement.form !=null || document.activeElement.formAction !=null)){
            event.stopPropagation();
            event.preventDefault();
            callback();
        }
    }
}

/** Helper to toggle on-off smoothingAllTool.
 *
 * @param event - keyEvent
 * @param callback - function to call
 * @returns {*}
 */
export function toggleSmoothingToolShortcut(event,callback) {
    if (event.code === "KeyS") {
        if (!(document.activeElement.form !=null || document.activeElement.formAction !=null)){
            event.stopPropagation();
            event.preventDefault();
            callback();
        }
    }
}

/** Helper to handle keys in viewer context
 *
 * @param event - keyEvent
 * @param viewer - ref to Viewer2D object
 * @returns {*}
 */
export function keyDownListenerInViewerContext(event,viewer) {
    if ((event.code === "KeyR" && viewer.props.isActive) || (event.code === "KeyV") ) { // if R then reset Camera
        if (!(document.activeElement.form !=null || document.activeElement.formAction !=null)){
            event.stopPropagation();
            event.preventDefault();
            viewer.resetCamera();
            if(event.shiftKey) { // if R and Shift reset Window Level
                viewer.resetWindowLevel();
                viewer.refreshRenderWindow();
            }
        }
    }
}

//****************************************************************************
//
//           Other helpers
//
//****************************************************************************

/**
 * Calculates rulers  - color lines denoting slice positions.
 * @param id
 * @param activeViewerId
 * @param viewersState
 * @param images
 * @returns {Array}
 */
export const calcRulers = (id, activeViewerId, viewersState, images)=>{
    if (activeViewerId!=null){
        let rulers = [];
        let thisView =  viewersState[id];
        let activeView =  viewersState[activeViewerId];
        if ((!(activeView!=null)) || thisView.imageId !== activeView.imageId) return [];
        Object.keys(viewersState).forEach((el)=>{
            if (viewersState[el]['type'] === "2D") {
                if (thisView.imageId === viewersState[el].imageId) {
                    const image = images[viewersState[activeViewerId].imageId];//.find((el) => el.uuid === viewersState[activeViewerId].imageId);
                    if (thisView.orientation !== viewersState[el].orientation && image!=null && image['state']===REQUEST_STATUS_SUCCESS) {
                        rulers.push({
                            sliceNumber: viewersState[el].sliceNumber,
                            slicingMode: image['properties']['slicingModes'][viewersState[el].orientation],
                            orientation: viewersState[el].orientation,
                            color: viewersState[el].color //viewer Index (needed to set the color)
                        });
                    }
                }
            }
        });
        return rulers;
    }
    else return [];
};


/**
 * Creates viewer configuration on demand.
 * Use: to create viewer for displaying as ruler.
 * @param color - color of viewer (ruler line)
 * @param orientation
 * @param sliceNumber
 * @param image
 * @returns {object}
 */
export const createViewerConfiguration = (color, orientation, sliceNumber,  image)=>{
    if (image!=null && image['properties']!=null && image['state']===REQUEST_STATUS_SUCCESS)
    return {      sliceNumber: sliceNumber,
                  slicingMode: image['properties']['slicingModes'][orientation],
                  orientation: orientation,
                  color: color,
                  type: "2D",
                  imageId: image['uuid']
              };
    else
        return null;
};

/**
 * Calculates center of gravity of labelmap volume.
 * @param imageData - vtkImageData object with overlay
 * @param labelMapValue - encoded value of labelmap (integer)
 * @returns {Array} - ijk values of CG
 */
export const calculateCenterOfGravity = (imageData, labelMapValue)=>{
    let result = [0,0,0];
    try {
        if (imageData != null && imageData.getPointData() != null && imageData.getPointData().getScalars().getData() != null) {
            let voxelCounter = 0;
            imageData.getPointData().getScalars().getData().forEach((el,index)=>{
                if (el === labelMapValue) {
                    ++voxelCounter;
                    const ijk = getIJKFromIndex(imageData,index);
                    result[0]+=ijk[0];
                    result[1]+=ijk[1];
                    result[2]+=ijk[2];
                }
            });
                return (voxelCounter>0) ? [Math.round(result[0]/voxelCounter), Math.round(result[1]/voxelCounter),Math.round(result[2]/voxelCounter)]
                                        : [Math.round( imageData.getDimensions()[0]*.5), Math.round( imageData.getDimensions()[1]*.5),Math.round( imageData.getDimensions()[2]*.5)] ;
        }
    }catch(err){
        return result;
    }
};

/**
 * Calculates center of gravity of labelmap volume.
 * @param imageData - vtkImageData object with overlay
 * @param index - index of dataPoint
 * @returns {Array} - ijk values
 */
export const getIJKFromIndex  =(imageData,index)=>{
    let ijk =[0. ,0. ,0.];
    const dims = imageData.getDimensions();
    ijk[0] = index % dims[0]; //remainder
    ijk[1] = Math.floor(index / dims[0]) % dims[1];
    ijk[2] = Math.floor(index / (dims[0] * dims[1]));
    return ijk;
};

/**
 * Calculates index from IJK.
 * @param imageData - vtkImageData object with overlay
 * @param  ijk - vector of ijk coords
 * @returns {Array} - ijk values
 */
export const getIndexFromIJK  =(imageData,ijk)=>{
    const dims = imageData.getDimensions();
    return ijk[0] + ijk[1] * dims[0] + ijk[2] * dims[0] * dims[1];
};

/**
 * Generates linear vtkColorTransferFunction
 * @param colorMap - array of colors eg. [{color:"#ff0"},{color:'#ff0000'}]
 * @return {*}
 */
export const generateColorTransferFunction = (colorMap)=>{
    const cfun = vtkColorTransferFunction.newInstance();
    colorMap.forEach((el)=>{
        const rgb = parseColor(el.color);
        cfun.addRGBPoint(el.value, rgb[0], rgb[1], rgb[2]); // label "1" will be blue
    });
    cfun.addRGBPoint(0.5,0,0,0); // label "1" will be blue
    return cfun;
};


/**
 * Function parsing colors.
 * @param color
 * @return {number[]}
 */
export function parseColor(color){
    const shortColor = color.length === 4; //check if one byte per channel
    let r=shortColor ? parseInt(color.charAt(1),16)/15. : parseInt(color.substring(1,3),16)/255.;
    let g=shortColor ? parseInt(color.charAt(2),16)/15. : parseInt(color.substring(3,5),16)/255.;
    let b=shortColor ? parseInt(color.charAt(3),16)/15. : parseInt(color.substring(5,7),16)/255.;
    return [r,g,b];
}


/**
 * Generate ColorTransferFunction from predefined colormap (applies on)
 * @param colorMapName - name of colormap predefined in vtkColorMaps (only those that contain RGBPoints property can be applied),
 * eg. "BkBu","2hot" etc.
 * @param dataRange - optional parameter for resolution adjustment
 * @return {*}
 */
export const generateColorTransferFunctionFromPredefinedVTKLookupTable = (colorMapName,dataRange=[0,255])=>{
    const lookupTable = vtkColorTransferFunction.newInstance();
    const preset = vtkColorMaps.getPresetByName(colorMapName);
    lookupTable.applyColorMap(preset);
    lookupTable.setMappingRange(...dataRange);
    lookupTable.updateRange();
    return lookupTable;
};


/**
 * Function to modify opacity for a given actor and label.
 * @param actor - vtkActor with Property (eg. vtkImageSlice)
 * @param label - colormap integer value
 * @param value - opacity value from range [0,1]
 */
export const modifyOpacityFunction=(actor,label,value)=>{

    const func = actor.getProperty().getScalarOpacity();
    if (func!=null){
        func.addPoint(label,value);
    }
};


/**
 * Method for animation of 3d viewer. It moves camera around focal point.
 * @param renderer
 * @param dx
 * @param dy
 */

export const animateCameraHorizontalMovement = (renderer, dx,dy) => {

    const trans = new Float64Array(16);
    const newCamPos = new Float64Array(3);
    const newFp = new Float64Array(3);
    const newViewUp = new Float64Array(3);
    const v2 = new Float64Array(3);
    const centerNeg = new Float64Array(3);
    const direction = new Float64Array(3);

    const camera = renderer.getActiveCamera();
    const cameraPos = camera.getPosition();
    const cameraFp = camera.getFocalPoint();

    mat4.identity(trans);

    const center = [0,0,0];
    const rotationFactor  = 1;

    // Translate to center
    mat4.translate(trans, trans, center);


    const size = renderer.getRenderWindow().getViews()[0].getSize();

    // Azimuth
    const viewUp = camera.getViewUp();
    mat4.rotate(
      trans,
      trans,
      vtkMath.radiansFromDegrees(((360.0 * dx) / size[0]) * rotationFactor),
      viewUp
    );

    // Elevation
    vtkMath.cross(camera.getDirectionOfProjection(), viewUp, v2);
    mat4.rotate(
      trans,
      trans,
      vtkMath.radiansFromDegrees(((-360.0 * dy) / size[1]) * rotationFactor),
      v2
    );

    // Translate back
    centerNeg[0] = -center[0];
    centerNeg[1] = -center[1];
    centerNeg[2] = -center[2];
    mat4.translate(trans, trans, centerNeg);

    // Apply transformation to camera position, focal point, and view up
    vec3.transformMat4(newCamPos, cameraPos, trans);
    vec3.transformMat4(newFp, cameraFp, trans);
    direction[0] = viewUp[0] + cameraPos[0];
    direction[1] = viewUp[1] + cameraPos[1];
    direction[2] = viewUp[2] + cameraPos[2];
    vec3.transformMat4(newViewUp, direction, trans);

    camera.setPosition(newCamPos[0], newCamPos[1], newCamPos[2]);
    camera.setFocalPoint(newFp[0], newFp[1], newFp[2]);
    camera.setViewUp(
      newViewUp[0] - newCamPos[0],
      newViewUp[1] - newCamPos[1],
      newViewUp[2] - newCamPos[2]
    );
    camera.orthogonalizeViewUp();

    renderer.resetCameraClippingRange();

    // if (interactor.getLightFollowCamera()) {
    //     renderer.updateLightsGeometryToFollowCamera();
    // }


};