import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {FIDUCIAL_TOOL} from "../../../../Constants";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {checkIfAnyPropertyIsVisible} from "./WidgetToolbar";
import {InputMask} from "primereact/components/inputmask/InputMask";
import {
    animatedEllipse,
    calculateCursorRadius,
    checkeredCursor,
    circleCursor, createEcho, rotatedEllipse,
    twoEllipses,
    whiskersCursor
} from "../../../helpers/canvasUtils";
import {Tooltip} from "primereact/components/tooltip/Tooltip";


const fiduOptions=[
    {label:'circle',value:'circle'},
    {label:'whiskers',value:'whiskers'},
    {label:'checkered',value:'checkered'},
    {label:'two ellipses',value:'twoEllipses'},
    {label:'rotated ellipse',value:'rotatedEllipse'},
    {label:'rotating ellipse',value:'animatedEllipse'}
];

/**
 * Stateful component with template for fiducial tool toolbar.
 * Uses the same SVG-based
 * @returns {*}
 */
export class FiducialToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false
        };
    }

    static itemTemplate(option) {
        const c = 8;
        let shape = ()=> {
            switch (option.value) {
                case 'circle':
                    return circleCursor(c);
                case 'whiskers':
                    return whiskersCursor(c);
                case 'checkered':
                    return checkeredCursor(c);
                case 'twoEllipses':
                    return twoEllipses(c);
                case 'animatedEllipse':
                    return animatedEllipse(c);
                case 'rotatedEllipse':
                    return rotatedEllipse(c, 45);
                default:
                    return null
            }
        };
        const shapeValue = shape();


        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="ui-helper-clearfix">
                    <span style={{float: 'left', margin: '.5em .25em 0 0'}}>{option.label}</span>
                    <img src={shapeValue} alt="Cannot display SVG" style={{float: "right",margin: "4px"}}/>
                </div>
            );
        }
    };

    render() {
        const {toolProperties, changeToolProperties, toolConfiguration} = this.props;
        const control = (property, type) => toolConfiguration != null
            && toolConfiguration.properties != null
            && toolConfiguration.properties[property] != null
            && toolConfiguration.properties[property][type] != null
            && toolConfiguration.properties[property][type];

        const checkIfAnyPropertyVisible = checkIfAnyPropertyIsVisible(toolConfiguration.properties);

        return (
            <React.Fragment>
                {checkIfAnyPropertyVisible &&
                <Toolbar style={{display: "inline-block"}}>
                    {control('type', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Fiducial:</label>
                        <Dropdown
                            itemTemplate={FiducialToolbar.itemTemplate}
                            value={toolProperties['type']}
                            options={fiduOptions}
                            onChange={(e) => changeToolProperties(FIDUCIAL_TOOL, 'type', e.value)}
                        />
                    </React.Fragment>
                    }
                    {control('diameter', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Diameter[mm]:</label>
                        <Spinner value={toolProperties['diameter']}
                                 min={1}
                                 max={1000}
                                 step={1}
                                 size={3}
                                 onChange={(e) => changeToolProperties(FIDUCIAL_TOOL, 'diameter', e.value)}
                        />
                    </React.Fragment>
                    }
                    {control('rotation', 'controlVisible') && toolProperties['type']==='rotatedEllipse' &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Rotation[deg]:</label>
                        <Spinner
                            value={toolProperties['rotation']}
                                 min={0}
                                 max={359}
                                 step={1}
                                 size={3}
                                 onChange={(e) => changeToolProperties(FIDUCIAL_TOOL, 'rotation', e.value)}
                        />
                    </React.Fragment>
                    }
                    {control('adjustableZoom', 'controlVisible')  &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Zoom ratio:</label>
                        <Spinner
                            id={"fiducial-size-to-view-size-spinner"}
                            value={toolProperties['adjustableZoom']}
                            min={0.5}
                            max={100}
                            step={0.1}
                            size={3}
                            onChange={(e) => changeToolProperties(FIDUCIAL_TOOL, 'adjustableZoom', e.value)}
                        />
                        <Tooltip for={"#fiducial-size-to-view-size-spinner"}
                                 title={"<strong>Zoom ratio</strong>  \n Ratio between display and fiducial size. Diameter of fiducial is equal to display width for ratio = 0.5."}
                                 escape={false}/>
                    </React.Fragment>
                    }
                </Toolbar>}
            </React.Fragment>
        );
    }

};


FiducialToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    toolConfiguration: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func
};
