import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { Trans } from 'react-i18next';
import axios from "axios/index";
import {HOST_URL} from "../../../Constants";



class Events extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events:[],
        };
    }

    loadUserEventList() {
        // Get the authentication from the store
        const {auth} = this.props;
    
        // Check if the user in enrolled in the event and update state
        axios.defaults.headers.common['Authorization'] = auth.token_bearer;
        axios.get(`${HOST_URL}/api/event?filter=onlyEnrolledIn`)
          .then(response => {
            if (response.status !== 200) {
                console.log('Error getting event subscriptions');
                this.setState({events: []});
            } else {
                this.setState({events: response.data});
            }
          }).catch(error => {
          console.log('event?onlyEnrolledIn=true err:', error);
          this.setState({events: []});
        });
    }
    componentDidMount() {
        const {getProjects} = this.props;
        getProjects();
        this.loadUserEventList();
    }

    render() {
        const {t} = this.props;
        const eventNodes = this.state.events.map((p) =>
            <div className='spineRowStyle' key={p.uuid}>
                <div className="spineColStyle"
                    onClick={()=>{this.props.history.push(`/event/${p.uuid}`)}}>
                        {p.name}
                </div>
                <div className="spineColStyle"><Link to={`/event/${p.uuid}`}>
                    <span style={{ textDecorationLine: "underline"}}>{t("dashboard.tables.myEvents.property.link.value.go.label")}</span>
                </Link>
                </div>
            </div>);
        return (
            <div style={{width:'96%',margin:'2%'}}>
                <span className="reg-headtitle">{t("dashboard.tables.myEvents.header")}</span>
                <div className="spineTableStyle">
                    <div className='spineRowHeaderStyle'>
                        <div className="spineColStyle">{t("dashboard.tables.myEvents.property.event.label")}</div>
                        <div className="spineColStyle">{t("dashboard.tables.myEvents.property.link.label")}</div>
                    </div>
                    <div className="spineTableScrollableContent">
                         <div className="spineTableBody">
                               {eventNodes}
                         </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default withTranslation() (Events);

Events.propTypes = {
    getProjects: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
};