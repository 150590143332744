/**
 * Generate offset for a given size and shape
 * @param slicingMode
 * @param radius
 * @param shape
 * @return {[][]}
 */
export function createOffsets(slicingMode, radius, shape = 'square') {

  if (radius===0)
    return [[0,0,0]];

  let offsets = {};
  offsets[0] = [];
  offsets[1] = [];
  offsets[2] = [];

  let extent = [];
  let result = [];
  if (slicingMode === 0) {
    extent = [0, 0, -radius, radius, -radius, radius];
  } else if (slicingMode === 1) {
    extent = [-radius, radius, 0, 0, -radius, radius];
  } else {
    extent = [-radius, radius, -radius, radius, 0, 0];
  }

  let fillInResult = () => {
  };

  if (shape === 'square') {
    fillInResult = (ijk) => result.push(ijk);
  }
  if (shape === 'circle' || shape === 'cityblock') {
    let distance = shape === 'circle' ? radius * radius : radius;
    let metric = shape === 'circle'
      ? (ijk) => ijk[0] * ijk[0] + ijk[1] * ijk[1] + ijk[2] * ijk[2]
      : (ijk) => Math.abs(ijk[0]) + Math.abs(ijk[1]) + Math.abs(ijk[2]);
    fillInResult = (point) => {
      if (metric(point) < distance)
        result.push(point);
    }
  }


  for (let i = extent[0]; i <= extent[1]; i++) {
    for (let j = extent[2]; j <= extent[3]; j++) {
      for (let k = extent[4]; k <= extent[5]; k++) {
        fillInResult([i, j, k]);
      }
    }
  }
  return result;


}

/**
 * Estimate a given quantile (percentile) from SAMPLE using Type 7 quantile estimation  (R7, NumPy, Maple-6, Julia).
 * Linear interpolation of the modes for the order statistics for the uniform distribution on [0,1].
 * This version is using random finite sample N.
 * @link  http://en.wikipedia.org/wiki/Quantile#Quantiles_of_a_population
 * @version 2.0 - previous version was calculating from entire population. This was reasonable due to assumption about integer values of intensities
 * - with floats, this approach is computationally inefficient.
 * @param image - vtkImageData object
 * @param percentile - to calculate [0,100]
 */
export function calculateQuantile(image,percentile){

  if (image!=null && image.getClassName()==="vtkImageData"){

    const data = image.getPointData().getScalars().getData();

    const SAMPLE_SIZE = 10000;
    const arr = data.length<SAMPLE_SIZE
      ? data.slice(0)
      : new Float64Array(SAMPLE_SIZE).map(curr => data[Math.floor(Math.random() * data.length)]); // random 10000 samples

    const asc = arr => arr.sort((a, b) => a - b);

    const quantile = (arr, q) => {
      const sorted = asc(arr);
      const pos = (sorted.length - 1) * q;
      const base = Math.floor(pos);
      const rest = pos - base;
      if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
      } else {
        return sorted[base];
      }
    };

    return quantile(arr, percentile/100);
  }
  return 0;
}