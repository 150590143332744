import React from "react";
import PropTypes from "prop-types";
import {ContainerManualTool} from "../containers/ContainerManualTool";
import {withRouter} from "react-router";
import {ContainerDirectedGraphControlPanel} from "../containers/ContainerDirectedGraphControlPanel";
import {parseConnectomeCaseConfiguration} from "../action/ConnectomeAction";

class ResultsPageDirectedGraph extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      parsedManualTool:null,
      sidebarVisible:true,
      emptyTool:null
    }
  }

  componentDidMount() {
    const {getConnectomeCases,match} = this.props;
    if (getConnectomeCases && match.params.experimentId)
      getConnectomeCases(match.params.experimentId);
    fetch('/dist/jsonDocs/tools/emptyGraphVisualizationTool.json')
      .then(response => response.json())
      .then(data => {
        this.setState(
          {emptyTool:data}
        )});

   }

  componentDidUpdate(prevProps, prevState, ss) {

    if (prevProps.selectedCase!==this.props.selectedCase){
      this.setState({parsedManualTool:this.props.selectedCase});
    }

    if (prevProps.connectomes!==this.props.connectomes && Object.keys(this.props.connectomes).length > 0){
      if (this.props.getLUTFromConnectome)
        this.props.getLUTFromConnectome();
    }
  }

  render() {
    const caseId = this.props.selectedCase!=null ? this.props.selectedCase.case : "";
    const graphId = this.props.selectedCase!=null ? this.props.selectedCase.graph_document_id : "";

    return (
      <React.Fragment>

        {this.state.emptyTool != null &&
        <ContainerManualTool
          key={"case" + caseId + graphId}
          predefinedToolConfiguration={
            this.props.selectedCase!==null && this.props.selectedCase.hasOwnProperty("case")
            ? parseConnectomeCaseConfiguration(this.state.emptyTool, this.props.selectedCase)
            : this.state.emptyTool}/>
        }

          <i className={
            this.state.sidebarVisible
              ? "fa fa-minus-square-o"
              : "fa fa-plus-square-o"
          }
             style={panelIconStyle}
             onClick={()=>{this.setState({sidebarVisible:!this.state.sidebarVisible})}}/>
        <div
          className={"graph-panel-container"}
            style={{
              display:this.state.sidebarVisible?"block":"none",
              width: "45em"
            }}
        >
            <ContainerDirectedGraphControlPanel/>
        </div>

      </React.Fragment>
    );
  }
}

export default withRouter(ResultsPageDirectedGraph);

ResultsPageDirectedGraph.propTypes = {
  selectedCase: PropTypes.object.isRequired,
  getConnectomeCases: PropTypes.func.isRequired
};

ResultsPageDirectedGraph.defaultProps = {};

const panelIconStyle = {
  color: "rgb(0, 210, 208)",
  fontSize: "2em",
  position: "fixed",
  right:"1em",
  top:"50px",
  margin:"0.5em"
};

