import {withRouter} from "react-router";
import {connect} from "react-redux";
import SeriesCasesPanel from "../component/SeriesCasesPanel";
import {getSelectedCasesFilteredByMeasurement} from "../selectors/CasesSelectors";



const mapStateToProps = (state,ownProps) => {
  return {
    cases: getSelectedCasesFilteredByMeasurement(state)
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
  }
};

export const ContainerSelectedCasesFilteredByMeasurementPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SeriesCasesPanel));