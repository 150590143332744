/**
 * Get value of member property for a given properties path.
 * Splitting the property path and looping through the different properties:
 *
 * @param obj - object
 * @param desc - properties path eg. "a.b.c"
 * @return {*}
 */
export function getDescendantProp(obj, desc) {
    let arr = desc.split('.');
    while (arr.length) {
        obj = obj[arr.shift()];
    }
    return obj;
}


/**
 * Set value of member property for a given properties path.
 * Splitting the property path and looping through the different properties:
 *
 * @param obj - object
 * @param desc - properties path eg. "a.b.c"
 * @param value - value to assign
 * @return {*}
 */
export function setDescendantProp(obj, desc, value) {
    let arr = desc.split('.');
    while (arr.length > 1) {
        obj = obj[arr.shift()];
    }
    return obj[arr[0]] = value;
}


/**
 * Rename key in object. Important - it does not change order of props!
 * @param object
 * @param key
 * @param newKey
 * @return {{}}
 */
export const renameKey = (object, key, newKey) => {

    const result = {};
    Object.keys(object).forEach(el=>{
       if (el===key){
           result[newKey]=object[key];
       }
       else
           result[el]=object[el];

    });

    return result;

};

/**
 * Remove property in object.
 * @param obj
 * @param path
 * @return {{}}
 */
export function deletePropertyPath (obj, path) {

    if (!obj || !path) {
        return;
    }

    for (let i = 0; i < path.length - 1; i++) {
        obj = obj[path[i]];
        if (typeof obj === 'undefined') {
            return;
        }
    }
    delete obj[path.pop()];
}