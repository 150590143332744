import RequestServiceR from './RequestServiceR'
import RequestService from './RequestService'
import {EXPERIMENT_PROPERTY_NAME__UUID, HOST_URL} from "../../../Constants";
import {arrayBufferToBase64} from "./io";

/** Helper class for handling network requests.
 *
 */

class NetworkService {

    /**
     * gets variables from backend API
     * @param variables - names (ids) of variables eg. { variable1:"", variable2:""}
     * @returns {Promise<*>}
     */
    getVariables(variables){
        const options={
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify(variables)
        };
        const url = HOST_URL + '/api/clinicalData2Variables';
        return RequestService.getRequest(url,options);
    }

    /**
     * gets variables from backend API -  new version for MLR
     * @deprecated
     * @param variables - names (ids) of variables {["CLINICAL_...", "CLINICAL_..."]}
     * @param authorization
     * @returns {Promise<*>}
     */
    getNVariables(variables,authorization){
        const options={
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify(variables)
        };
        const url = HOST_URL + '/api/clinicalDataNVariables';
        return RequestService.getRequest(url,options);
    }

    /**
     * gets data
     * @param experiment  -
     * @param authorization -
     * @returns {Promise<*>}
     */
    getDataset(experiment,authorization){
        const options={
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify(experiment)
        };
        const url = HOST_URL + '/api/experiment/'+experiment[EXPERIMENT_PROPERTY_NAME__UUID]+'/updateAndGetData';
        return RequestService.getRequest(url,options);
    }


    /**
     * gets variables from backend API -  new version for MLR using cohorts
     * @deprecated replaced with getData
     * @param variables - names (ids) of variables {["CLINICAL_...", "CLINICAL_..."]}
     * @param cohorts
     * @param authorization
     * @returns {Promise<*>}
     */
    getNVariablesFromCohorts(variables,cohorts,authorization){
        const options={
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': authorization
            },
            body: JSON.stringify({variables:variables,cohorts:cohorts})
        };
        const url = HOST_URL + '/api/clinicalDataNVariables';
        return RequestService.getRequest(url,options);
    }

    /**
     * gets lm (full) model from Plumber
     * @param dataToSend must contain data to send i.e. x,y,l attributes in JSON and post method, eg.:
     * let transferObject = {};
     * transferObject.x = x;
     * transferObject.y = y;
     * transferObject.l = this.state.ciLevel;
     * @returns {Promise<*>}
     */
    getFullModel(dataToSend){
        const options=  {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(dataToSend)
        };
        const url =HOST_URL + '/api/plumber/getfullmodel';
        return RequestService.getRequest(url,options)
    }

    /**
     * gets lm (simple) model from Plumber
     * @param dataToSend must contain data to send i.e. x,y,l attributes in JSON and post method, eg.:
     * let transferObject = {};
     * transferObject.x = x;
     * transferObject.y = y;
     * transferObject.l = this.state.ciLevel;
     * @returns {Promise<*>}
     */
    getLMModel(dataToSend){
        const options=  {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(dataToSend)
        };
        const url = HOST_URL + '/api/plumber/getlmmodel';
        return RequestService.getRequest(url,options)
    }

    /**
     * Gets IRIS plot from Plumber.
     * This is is example, how to deal with images in Plumber.
     * Beware that arrayBufferToBase64 is used here for postprocessing (to avoid encoding-related problems
     * with sending binary data through HTTP)!
     * Example of use:
     * componentDidMount () {
     *   NetworkService.getPlumberPlot()
     *         .then(data=> {
     *              this.setState({ imageData:data });
     *      });
     *}
     *
     *  and in JSX template:
     *  <img src={`data:image/png;base64,${this.state.imageData}`}/>
     * @returns {Promise<*>}
     */
    getPlumberPlot(){
        const url = HOST_URL + '/api/plumber/plot';
        return RequestService.getBinaryRequest(url,{}).then(result => {
                 	return arrayBufferToBase64(result);})
    }

    /**
     * Gets histogram.
     *  <img src={`data:image/png;base64,${this.props.imageData}`}/>
     * @returns {Promise<*>}
     */
    getHistogram(data){
        const url = HOST_URL + '/api/plumber/hist';
        const options = { method: 'POST',
            headers: {
                'Accept': 'image/png'
            },
            body: JSON.stringify(data)
        };
        return RequestService.getBinaryRequest(url,options).then(result => {
            return arrayBufferToBase64(result);})
    }

    /**
     * Gets histogram with Kernel Density Estimation.
     *  <img src={`data:image/png;base64,${this.props.imageData}`}/>
     * @returns {Promise<*>}
     */
    getKernelPlot(data){
        const url = HOST_URL + '/api/plumber/kernel';
        const options = { method: 'POST',
            headers: {
                'Accept': 'image/png'
            },
            body: JSON.stringify(data)
        };
        return RequestService.getBinaryRequest(url,options).then(result => {
            return arrayBufferToBase64(result);})
    }
    /**
     * Gets Boxplot.
     *  <img src={`data:image/png;base64,${this.props.imageData}`}/>
     * @returns {Promise<*>}
     */
    getBoxPlot(data){
        const url = HOST_URL + '/api/plumber/boxplot';
        const options = { method: 'POST',
            headers: {
                'Accept': 'image/png'
            },
            body: JSON.stringify(data)
        };
        return RequestService.getBinaryRequest(url,options).then(result => {
            return arrayBufferToBase64(result);})
    }

    /**
     * Gets Statistics.
     *
     * @returns {Promise<*>}
     */
    getStats(data){
        const url = HOST_URL + '/api/plumber/stats';
        const options = { method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        };
        return RequestServiceR.getRequest(url,options);
    }

    /**
     * Gets Exemplary ggplot.
     *  <img src={`data:image/png;base64,${this.props.imageData}`}/>
     * @returns {Promise<*>}
     */
    getGGPlot(){
        const url = HOST_URL + '/api/plumber/ggplot';
        const options = { method: 'GET',
            headers: {
                'Accept': 'image/png'
            },
            // body: JSON.stringify(data)
        };
        return RequestService.getBinaryRequest(url,options).then(result => {
            return arrayBufferToBase64(result);})
    }

    /** Sends to plumber and back information about user.
     *  This is ONLY example for using request body!
     * Example:
     *     componentDidMount(){
     *          NetworkService.processBodyWithPlumber('id=123&name=Jennifer').
     *              then(data=>{ this.setState({user:data}) })
     *     }
     * @param body  -  url-encoded request body, eg.  'id=123&name=Jennifer'
     * @returns {Promise<*>}
     */

    processBodyWithPlumber(body){
        const url = HOST_URL + '/api/plumber/user';
        const options = { method: 'POST',
                headers: {
                    'Accept': 'application/json'
                },
                body: body
            };
        return RequestService.getRequest(url,options);
    }
    /**
     * Gets any function call.
     *
     *
     * @param data containing mandatory properties
     * - "f" - function string
     * - "data" - function args object
     * eg. {"f" : "pwr.f2.test",
     *      "data" : {"u":2,"f2":0.2, "sig.level":0.05,"v":230}}
     * @returns {Promise<*>}
     */
    getCall(data){
        const url = HOST_URL + '/api/plumber/call';
        const options = { method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        };
        return RequestService.getRequest(url,options);
    }
    /**
     * Gets plot for statistical power for GLR.
     * @param u containing mandatory number of model degree of freedom {"u":2}
     * @returns {Promise<*>}
     */
    getGLRPowerPlot(u){
        const url = HOST_URL + '/api/plumber/glrpowerplot';
        const options = { method: 'POST',
            headers: {
                'Accept': 'image/png'
            },
            body: JSON.stringify(u)
        };
        return RequestService.getBinaryRequest(url,options).then(result => {
            return arrayBufferToBase64(result);})
    }

    /**Gets lm (full) model from Plumber
     * @param dataToSend must contain :
     *  - textScript
     *  -
     * @returns {Promise<*>}
     */
    executeScript(dataToSend){
        const options=  {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(dataToSend)
        };
        const url =HOST_URL + '/api/plumber/executescript';
        return RequestService.getRequest(url,options)
    }


    /**
     * Gets Bland Altman model from Plumber
     * @param dataToSend must contain data to send i.e. x,y,l attributes in JSON and post method, eg.:
     * let transferObject = {};
     * transferObject.x = x;
     * transferObject.y = y;
     * transferObject.l = this.state.ciLevel;
     * @returns {Promise<*>}
     */
    getBlandAltmanData(dataToSend){
        const options=  {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(dataToSend)
        };
        const url =HOST_URL + '/api/plumber/blandaltman';
        return RequestServiceR.getRequest(url,options)
    }
    /**
     * Gets ICC model from Plumber
     * @param dataToSend must contain data to send i.e. x,y,l attributes in JSON and post method, eg.:
     * {"data":[[123, 122],[123,124],[125,127],[122,122],[123,123]],"model":"twoway","type":"agreement","r0":1,"unit":"single","conf.level":0.93}
     * @returns {Promise<*>}
     */
    getICCModel(dataToSend){
        const options=  {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(dataToSend)
        };
        const url =HOST_URL + '/api/plumber/icc';
        return RequestServiceR.getRequest(url,options)
    }
    /**
     * Gets Kappa model from Plumber
     * @param dataToSend must contain data to send i.e. x,y,l attributes in JSON and post method, eg.:
     * {"data":[[123, 122],[123,124],[125,127],[122,122],[123,123]],"weight":"equal","sort.levels":false}
     * @returns {Promise<*>}
     */
    getKappaModel(dataToSend){
        const options=  {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(dataToSend)
        };
        const url =HOST_URL + '/api/plumber/kappa';
        return RequestServiceR.getRequest(url,options)
    }

    /**
     * Gets Generalized Linear Model from Plumber
     *
     * @param {object} dataToSend must contain data to send i.e. x,y,l attributes in JSON and post method, eg.:
     * {"data": {"Y":[10,24,6],"X1":[10,24,6]}, "family": "gaussian","formula": "Y~X1","confint": 0.94,"predint": 0.96}
     *
     * @returns {Promise<*>}
     */
    getGLMModel(dataToSend){
        const options=  {
            method: 'POST',
            headers: {'Accept': 'application/json'},
            body: JSON.stringify(dataToSend)
        };
        const url =HOST_URL + '/api/plumber/glm';
        return RequestServiceR.getRequest(url,options)
    }

}

export default new NetworkService()