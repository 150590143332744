import {
    CLEAR_MANUAL_TOOL_STATE,
    UPDATE_MANUAL_TOOL_CONFIGURATION,
    UPDATE_MANUAL_TOOL_NAVIGATION_MODE,
    UPDATE_MANUAL_TOOL_PROPERTY,
    UPDATE_MANUAL_TOOL_STATE
} from "../action/actionType";
import {LEFT_BUTTON_MODE} from "../../vtk/SpineInteractorStyleImage";
import {setDescendantProp} from "../../helpers/properties";


const getInitialState=()=>{return {
    manualToolState:{
        mainTool:{
            activeViewerId:null,
            leftButtonMode:LEFT_BUTTON_MODE.NONE,
            annotationsVisible:true, //variable to use in browser to show/hide annotations
            projectionsVisible:false, // variable to store projections mode for key shortcut
            navigationModeLabel:"case" //variable to keep a navigation rule for forward button (case, go to step1 only..., go to step2 only...)
        }
    },
    manualToolConfiguration:{} //immutable, should be set only once
    // overlays requested or loaded
}};

/**
 * Reducer for manual tool. Data scheme is organized according to js Object keys:
 * {"brushTool": {
 *         "size":1,
 *         "color":"..."
 *         }
 *  "fillingTool":{  // ...id here
 *          //... properties here
 *         }
 * }
 *
 * @param state
 * @param action
 * @return {*}
 */

export const manualToolReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case CLEAR_MANUAL_TOOL_STATE: {
            return Object.assign({}, state, getInitialState());
        }
        case UPDATE_MANUAL_TOOL_STATE:{
            let newTool = Object.assign({},action.manualToolState);
            let newManualToolState = Object.assign({},state.manualToolState);
            newManualToolState[action.id] = newTool;
            return Object.assign({}, state, {
                manualToolState:newManualToolState
            });
        }
        case UPDATE_MANUAL_TOOL_PROPERTY:{
            let updatedTool = Object.assign({}, state.manualToolState[action.id]);
            //Careful, only properties can be changed
            if (!action.property.includes(".")) {
                updatedTool[action.property] = action.value;
            }
            else {
                // let expression = "updatedTool.".concat(action.property,"=action.value");
                //eval(expression);
                setDescendantProp(updatedTool,action.property,action.value);
            }
            let newManualToolState = Object.assign({}, state.manualToolState);
            newManualToolState[action.id] = updatedTool;
            return Object.assign({}, state, {
                manualToolState: newManualToolState
            });

        }
        case UPDATE_MANUAL_TOOL_CONFIGURATION:{
            return Object.assign({}, state, {
                manualToolConfiguration:action.configuration
            });
        }
        default:
            return state;
    }
};

