import { connect } from 'react-redux';
import {getPublicEventsList} from "../action/EventsAction";
import PublicEventsPage from '../component/PublicEventsPage';

const mapStateToProps = state => {
  return{
    events: state.event.events.events,
  }
};

const mapDispatchToProps = dispatch => ({
    getEvents : (onError) => {
        dispatch(getPublicEventsList(onError))
  }
});

export const ContainerPublicEventsPage = connect( mapStateToProps, mapDispatchToProps)(PublicEventsPage);
