import {VISUALIZATION_TOOL, CHARACTERIZATION_TOOL_TYPE} from "../../../../Constants";
import {flatMap, filter} from 'lodash';
import {segmentationConfig} from '../annotationTool/ResultsPageINOS';

export const generateSegmentationsVisualizationToolConfiguration = (inputImage_key, segmentation_key, experimentProperties) => {
  const {lut_key, lutDescription_key} = experimentProperties;
  return segmentationConfig(inputImage_key, segmentation_key, lut_key, lutDescription_key);
};

const getInputsROIDataLists = (data) => {
    // Fuction to map groups data to the form:
    // "inputROIDataList_key<g number>": {
    //   "value": [ROIs list for g number],
    //   "readOnly": true,
    //   "color": "fcba03"
    // },
    // for currentMaterializedTask and the form:
    // 
    // for currentTool
    const {groups} = data;
    const inputsROIDataLists = {};
    const toolInputsROIDataLists = {};
    groups.forEach((group, idx) =>{
      const keyNumber = idx+1;
      inputsROIDataLists[`inputROIDataList_key${keyNumber}`] = {
        value: filter(flatMap(group['contributors'],
                              (rater) => {
                                return rater['roiId'];
                              }),
                      (roi) => {
                        return roi;
                      }),
        readOnly: true,
        color: group['color'],
        };
      toolInputsROIDataLists[`inputROIDataList_key${keyNumber}`] = {
                                                                name: "Input ROis data",
                                                                description: `Input ROI data number ${keyNumber}`,
                                                                isList: true,
                                                                type: "roiInOut",
                                                                typeROI: "IMPLICIT",
                                                                required: true
                                                              };
    });
  return {inputsROIDataLists, toolInputsROIDataLists};
}

export const generateAnnotationVisualizationToolConfiguration = (currentTool, data, experimentProperties) => {
    const {originalImageId:inputImage_key, originalImageFormat:inputImageFormat, raters} = data;
    // TODO enable the use of actual parameters
    const {inputAnnotationTableDefinition_key1, inputAnnotationFormDefinition_key1} = experimentProperties;
    const {inputsROIDataLists, toolInputsROIDataLists } = getInputsROIDataLists(data);

    // Add inputsROIDataList for currentMaterializedTask
    const currentMaterializedTaskInputs = {
        "inputImage_key": {
            "value": inputImage_key,
            "format": inputImageFormat
        }, // annotation table def, FormDefinition, ROIs list
        "inputAnnotationTableDefinition_key1": {
            "value": inputAnnotationTableDefinition_key1
        },
          "inputAnnotationFormDefinition_key1": {
            "value": inputAnnotationFormDefinition_key1
        },
        ...inputsROIDataLists
    };

    // Update currentTool inputs to have the correspondant inputsROIDataLists
    const currentToolInputs = {...currentTool.inputs, ...toolInputsROIDataLists};
    let modifiedCurrentTool = Object.assign({}, currentTool);
    modifiedCurrentTool.inputs = currentToolInputs;

    return {
        "miniWorkflow": {
            "currentTool": modifiedCurrentTool,
            "currentMaterializedTask": {
                inputs: currentMaterializedTaskInputs
            },
        },
    };

};

// TODO recreate using https://github.com/SPINEProject/SPINE_V.2.0/blob/master/src/frontend/jsonDocs/tools/leptomeningeal2.json
// This should be an endpoint!
export const configurationAnnonVisuTool = {
    "id":"1dd174e57260e384c4f45e9c490004a9",
    "name": "Characterization tool",
    "description": "This tool is used to see previously identified and characteriazed ROI's.",
    "version": "1.0.0",
    "privacy": "PUBLIC",
    "type": VISUALIZATION_TOOL,
    "inputs": {
      "inputImage_key":{
        "name": "Input image",
        "description": "Input image",
        "isList": false,
        "type": "imageEntityInOut",
        "imageEntityInOut_Type": "ANATOMICAL",
        "imageEntityInOut_FileFormat": "nii.gz",
        "required": true
      },
      "inputAnnotationTableDefinition_key1": {
        "name": "Input annotations definition",
        "description": "Input annotations definition for annotation table",
        "isList": false,
        "type": "annotationTableDefinitionInputOutput",
        "required": true
      },
      "inputAnnotationFormDefinition_key1": {
        "name": "Input annotations definition",
        "description": "Input annotations definition for annotation table",
        "isList": false,
        "type": "annotationFormDefinitionInputOutput",
        "required": true
      }
    },
    "outputs": {
      "outputAnnotationTableData_key": {
        "name": "Output annotations data",
        "description": "Output annotations data from annotation table",
        "isList": false,
        "type": "annotationTableDataInOut",
        "required": true
      },
      "outputAnnotationFormData_key": {
        "name": "Output annotations data",
        "description": "Output annotations data from annotation form",
        "isList": false,
        "type": "annotationFormDataInOut",
        "required": true
      }
    },
    "configuration":{
      "scenes": {
        "sceneKey1": {
          "primaryImageInputKey": "inputImage_key",
          "rois": {
            "geometricalROIsInputKeys": [
              "inputROIDataList_key1", "inputROIDataList_key2"
            ]
          }
        }
      },
      "viewers": {
        "layout": {
          "type": "ROW",
          "layoutOrder": {
            "left": "0",
            "middle": "1",
            "right": "2"
          }
        },
        "renderWindows": {
          "0": {
            "name": "Sagittal viewer",
            "type": "2D",
            "initialState": {
              "orientationAndSliceNumber": {
                "orientation": "SAGITTAL",
                "strategy": "DYNAMIC",
                "dynamicSliceValue": "MIDDLE"
              },
              "initialWindowLevel": {
                "strategy": "DYNAMIC",
                "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
              }
            },
            "displayScenes": {
              "possibleScenesToDisplay": [
                "sceneKey1"
              ],
              "hasDefaultSceneToDisplay": true,
              "defaultSceneToDisplay": "sceneKey1"
            },
            "displayControls": {
              "orientation": {
                "controlVisible": false,
                "controlEnabled": false,
                "options": [
                  {
                    "label": "Sagittal",
                    "value": "SAGITTAL"
                  },
                  {
                    "label": "Coronal",
                    "value": "CORONAL"
                  },
                  {
                    "label": "Axial",
                    "value": "AXIAL"
                  }
                ],
                "defaultValue": "AXIAL"
              },
              "smoothing": {
                "controlVisible": false,
                "controlEnabled": false,
                "defaultValue": false
              },
              "sequence": {
                "controlVisible": false,
                "controlEnabled": false
              },
              "linked": {
                "controlVisible": false,
                "controlEnabled": false
              }
            },
            "lookAndFeel":{
              "color":"#ffa07a"
            }
          },
          "1": {
            "name": "Middle viewer",
            "type": "2D",
            "linkedControlVisible": false,
            "initialState": {
              "orientationAndSliceNumber": {
                "orientation": "AXIAL",
                "strategy": "DYNAMIC",
                "dynamicSliceValue": "MIDDLE"
              },
              "initialWindowLevel": {
                "strategy": "dynamic",
                "value": "MIDDLE-FULL"
              }
            },
            "displayScenes": {
              "possibleScenesToDisplay": [
                "sceneKey1"
              ],
              "hasDefaultSceneToDisplay": true,
              "defaultSceneToDisplay": "sceneKey1"
            },
            "displayControls": {
              "orientation": {
                "controlVisible": false,
                "controlEnabled": false,
                "options": [
                  {
                    "label": "Sagittal",
                    "value": "SAGITTAL"
                  },
                  {
                    "label": "Coronal",
                    "value": "CORONAL"
                  },
                  {
                    "label": "Axial",
                    "value": "AXIAL"
                  }
                ],
                "defaultValue": "AXIAL"
              },
              "smoothing": {
                "controlVisible": false,
                "controlEnabled": false,
                "defaultValue": false
              },
              "sequence": {
                "controlVisible": false,
                "controlEnabled": false
              },
              "linked": {
                "controlVisible": false,
                "controlEnabled": false
              }
            },
            "lookAndFeel":{
              "color":"#fff967"
            }
          },
          "2": {
            "name": "Right viewer",
            "type": "2D",
            "linkedControlVisible": false,
            "initialState": {
              "orientationAndSliceNumber": {
                "orientation": "CORONAL",
                "strategy": "DYNAMIC",
                "dynamicSliceValue": "MIDDLE"
              },
              "initialWindowLevel": {
                "strategy": "dynamic",
                "value": "MIDDLE-FULL"
              }
            },
            "displayScenes": {
              "possibleScenesToDisplay": [
                "sceneKey1"
              ],
              "hasDefaultSceneToDisplay": true,
              "defaultSceneToDisplay": "sceneKey1"
            },
            "displayControls": {
              "orientation": {
                "controlVisible": false,
                "controlEnabled": false,
                "options": [
                  {
                    "label": "Sagittal",
                    "value": "SAGITTAL"
                  },
                  {
                    "label": "Coronal",
                    "value": "CORONAL"
                  },
                  {
                    "label": "Axial",
                    "value": "AXIAL"
                  }
                ],
                "defaultValue": "CORONAL"
              },
              "smoothing": {
                "controlVisible": false,
                "controlEnabled": false,
                "defaultValue": false
              },
              "sequence": {
                "controlVisible": false,
                "controlEnabled": false
              },
              "linked": {
                "controlVisible": false,
                "controlEnabled": false
              }
            },
            "lookAndFeel":{
              "color":"#90ee90"
            }
          }
        }
      },
      "widgets":{
        "mainTool": {
          "leftButtonMode":2
        },
        "casesControl": {
          "controlVisible": false,
          "controlEnabled": false,
          "defaultValue": false
        },
        "pinTool": {
          "controlVisible": false,
          "controlEnabled": false,
          "properties": {
            "markerSize": {
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": 5
            },
            "fontSize":{
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": 20
            },
            "selectionOnly": {
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": true
            },
            "clearAll":{
              "controlVisible": false,
              "controlEnabled": false
            },
            "subAnnotationsAvailable":{
              "controlVisible": false,
              "controlEnabled": false,
              "defaultValue": false
            },
            "centering": {
              "controlVisible": false,
              "controlEnabled": false,
              "defaultValue": true
            }
          }
        },
        "annotationOpacity": {
          "controlVisible": true,
          "controlEnabled": true,
          "defaultValue": 0.7
        },
        "pointerTool": {
          "controlVisible": true,
          "controlEnabled": true,
          "defaultValue": false,
          "properties": {
            "mode": {
              "controlVisible": false,
              "controlEnabled": false,
              "defaultValue": "onClick"
            }
          }
        },
        "projectionTool": {
          "controlVisible": true,
          "controlEnabled": true,
          "defaultValue": false,
          "properties": {
            "range": {
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": 1
            }
          }
        },
        "customCursorTool": {
          "controlVisible": true,
          "controlEnabled": true,
          "defaultValue": true,
          "properties": {
            "type": {
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": "infoTooltip"
            },
            "diameter": {
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": 3
            }
          },
          "type": "infoTooltip",
          "diameter": 3,
          "customCursorTool": true
        },
        "fiducialTool": {
          "controlVisible": true,
          "controlEnabled": true,
          "defaultValue": false,
          "properties": {
            "type": {
              "controlVisible": false,
              "controlEnabled": false,
              "defaultValue": "circle"
            },
            "diameter": {
              "controlVisible": false,
              "controlEnabled": false,
              "defaultValue": 5
            },
            "rotation": {
              "controlVisible": false,
              "controlEnabled": true,
              "defaultValue": 0
            },
            "adjustableZoom": {
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": 4
            }
          }
        },
        "crossHairTool": {
          "controlVisible": true,
          "controlEnabled": true,
          "defaultValue": false
        },
        "annotationTableTool": {
          "controlVisible": true,
          "controlEnabled": true,
          "properties": {
            "fullTable": {
              "controlVisible": true,
              "controlEnabled": true,
              "defaultValue": false
            },
            "editableROI": {
              "controlVisible": false,
              "controlEnabled": false,
              "defaultValue": false
            },
            "subAnnotationColumn":{
              "controlVisible": true,
              "controlEnabled": false
            }
          }
        }
      }
    }
  };

// TODO check dependend code in this function
export const generateVisualizationToolConfiguration = (inputImage_key, segmentation_key) =>{
    // TODO add actual data
    return {
        "miniWorkflow": {
            "currentTool": configurationVisuTool,
            "currentMaterializedTask": { //CHANGE to use real data
                "inputs": {
                    "inputImage_key": {
                        "value": inputImage_key
                    },
                    "segmentation_key":{ // annotation table def, ROIs, ROIs list
                        "value": segmentation_key,
                        "format": "nii.gz"
                    },
                    "inputLut_key1": {
                        "value": "c63c3c687bfeab4782a90290e1002fa9"
                    },
                    "inputLutDescription_key1": {
                        "value": "c63c3c687bfeab4782a90290e1007642"
                    }
                },
            },
        },
    };

};

// This should be an endpoint! (get current tool - get tool config by id)
const configurationVisuTool =  {
    "id":"1dd174e57260e384c4f45e9c490004a9",
    "name": "Visualization tool (Check)",
    "description": "This tool is used to visualize a segmentation and check it.",
    "version": "0.0.1",
    "privacy": "PUBLIC",
    "type": VISUALIZATION_TOOL,
    "inputs": {
        "inputImage_key":{
            "name": "Input image",
            "description": "Input image",
            "isList": false,
            "type": "imageEntityInOut",
            "imageEntityInOut_Type": "ANATOMICAL",
            "imageEntityInOut_FileFormat": "nii.gz",
            "required": true
        },
        "segmentation_key":{
            "name": "Segmentation",
            "description": "Segmentation",
            "isList": false,
            "type": "roiInOut",
            "typeROI": "EXPLICIT",
            "required": true
        },
        "inputLutDescription_key1": {
            "name": "Input description of LUT data",
            "description": "Input LUT data for displaying options.",
            "isList": false,
            "type": "lookUpTableDescription",
            "required": true
        },
        "inputLut_key1": {
            "name": "Input LUT data",
            "description": "Input LUT data for overlay displaying.",
            "isList": false,
            "type": "lookUpTable",
            "required": true
        }
    },
    "outputs": {
        "confirmationSignal_key":{
            "name": "Confirmation signal",
            "description": "Confirmation signal",
            "isList": false,
            "type": "confirmation"
        }
    },
    "configuration":{
        "luts": {
            "lut_key1": {
                "fromInputs": true,
                "lutInputKey": "inputLut_key1"
            }
        },
        "lutDescriptions": {
            "lutDescription_key1": {
                "fromInputs": true,
                "lutDescriptionInputKey": "inputLutDescription_key1"
            }
        },
        "rois": {
            "overlays": {
                "overlay_key_1": {
                    "fromInputs": true,
                    "imageInputKey": "segmentation_key",
                    "lutKey":"lut_key1",
                    "lutDescriptionKey": "lutDescription_key1"
                }
            }
        },
        "scenes": {
            "sceneKey1": {
                "primaryImageInputKey": "inputImage_key",
                "rois": {
                    "overlays": [  "overlay_key_1"    ]
                }
            }
        },
        "viewers": {
            "layout": {
                "type": "ROW",
                "layoutOrder": {
                    "left": "0",
                    "middle": "1",
                    "middleRight": "2",
                    "right": "3"
                }
            },
            "renderWindows": {
                "0": {
                    "name": "Sagittal viewer",
                    "type": "2D",
                    "initialState": {
                        "orientationAndSliceNumber": {
                            "orientation": "SAGITTAL",
                            "strategy": "DYNAMIC",
                            "dynamicSliceValue": "LABELMAP_COG"
                        },
                        "initialWindowLevel": {
                            "strategy": "DYNAMIC",
                            "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
                        }
                    },
                    "displayScenes": {
                        "possibleScenesToDisplay": [
                            "sceneKey1"
                        ],
                        "hasDefaultSceneToDisplay": true,
                        "defaultSceneToDisplay": "sceneKey1"
                    },
                    "displayControls": {
                        "orientation": {
                            "controlVisible": false,
                            "controlEnabled": false,
                            "options": [
                                {
                                    "label": "Sagittal",
                                    "value": "SAGITTAL"
                                },
                                {
                                    "label": "Coronal",
                                    "value": "CORONAL"
                                },
                                {
                                    "label": "Axial",
                                    "value": "AXIAL"
                                }
                            ],
                            "defaultValue": "AXIAL"
                        },
                        "smoothing": {
                            "controlVisible": false,
                            "controlEnabled": false,
                            "defaultValue": false
                        },
                        "sequence": {
                            "controlVisible": false,
                            "controlEnabled": false
                        }
                    },
                    "lookAndFeel":{
                        "color":"#ffa07a"
                    }
                },
                "1": {
                    "name": "Coronal viewer",
                    "type": "2D",
                    "linkedControlVisible": false,
                    "initialState": {
                        "orientationAndSliceNumber": {
                            "orientation": "CORONAL",
                            "strategy": "DYNAMIC",
                            "dynamicSliceValue": "LABELMAP_COG"
                        },
                        "initialWindowLevel": {
                            "strategy": "dynamic",
                            "value": "MIDDLE-FULL"
                        }
                    },
                    "displayScenes": {
                        "possibleScenesToDisplay": [
                            "sceneKey1"
                        ],
                        "hasDefaultSceneToDisplay": true,
                        "defaultSceneToDisplay": "sceneKey1"
                    },
                    "displayControls": {
                        "orientation": {
                            "controlVisible": false,
                            "controlEnabled": false,
                            "options": [
                                {
                                    "label": "Sagittal",
                                    "value": "SAGITTAL"
                                },
                                {
                                    "label": "Coronal",
                                    "value": "CORONAL"
                                },
                                {
                                    "label": "Axial",
                                    "value": "AXIAL"
                                }
                            ],
                            "defaultValue": "CORONAL"
                        },
                        "smoothing": {
                            "controlVisible": false,
                            "controlEnabled": false,
                            "defaultValue": false
                        },
                        "sequence": {
                            "controlVisible": false,
                            "controlEnabled": false
                        }
                    },
                    "lookAndFeel":{
                        "color":"#fff967"
                    }
                },
                "2": {
                    "name": "Coronal viewer",
                    "type": "2D",
                    "linkedControlVisible": false,
                    "initialState": {
                        "orientationAndSliceNumber": {
                            "orientation": "AXIAL",
                            "strategy": "DYNAMIC",
                            "dynamicSliceValue": "LABELMAP_COG"
                        },
                        "initialWindowLevel": {
                            "strategy": "dynamic",
                            "value": "MIDDLE-FULL"
                        }
                    },
                    "displayScenes": {
                        "possibleScenesToDisplay": [
                            "sceneKey1"
                        ],
                        "hasDefaultSceneToDisplay": true,
                        "defaultSceneToDisplay": "sceneKey1"
                    },
                    "displayControls": {
                        "orientation": {
                            "controlVisible": false,
                            "controlEnabled": false,
                            "options": [
                                {
                                    "label": "Sagittal",
                                    "value": "SAGITTAL"
                                },
                                {
                                    "label": "Coronal",
                                    "value": "CORONAL"
                                },
                                {
                                    "label": "Axial",
                                    "value": "AXIAL"
                                }
                            ],
                            "defaultValue": "AXIAL"
                        },
                        "smoothing": {
                            "controlVisible": false,
                            "controlEnabled": false,
                            "defaultValue": false
                        },
                        "sequence": {
                            "controlVisible": false,
                            "controlEnabled": false
                        }
                    },
                    "lookAndFeel":{
                        "color":"#90ee90"
                    }
                },
                "3": {
                    "name": "3D viewer",
                    "type": "3D",
                    "displayControls": {
                        "smoothing": {
                            "controlVisible": false,
                            "controlEnabled": false,
                            "defaultValue": true
                        },
                        "sequence": {
                            "controlVisible": false,
                            "controlEnabled": false
                        }
                    },
                    "displayScenes": {
                        "possibleScenesToDisplay": [
                            "sceneKey1"
                        ],
                        "hasDefaultSceneToDisplay": true,
                        "defaultSceneToDisplay": "sceneKey1"
                    },
                    "displayImageSlices": {
                        "0": {
                            "fromState": true,
                            "stateKey": "left"
                        },
                        "1": {
                            "fromState": true,
                            "stateKey": "middle"
                        },
                        "2": {
                            "fromState": true,
                            "stateKey": "middleRight"
                        }
                    }
                }
            }
        },
        "widgets": {
            "pointerTool":{
                "controlVisible":true,
                "controlEnabled":true,
                "defaultValue":false,
                "properties": {
                    "mode": {
                        "controlVisible": false,
                        "controlEnabled": false,
                        "defaultValue": "onClick"
                    }
                }
            },
            "labelmapOpacity":{
                "controlVisible":true,
                "controlEnabled":true,
                "defaultValue":1
            },
            "crossHairTool":{
                "controlVisible":true,
                "controlEnabled":true,
                "defaultValue":true,
                "strategy":"ACTIVE_WINDOW"
            }
        }
    }
};
