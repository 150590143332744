import axios from "axios/index";
import {HOST_URL} from "../../../Constants";
import {
  CLEAR_ONTOLOGY_SAVE,
  ONTOLOGY_LIST_REQUEST_FAILED,
  ONTOLOGY_LIST_REQUEST_SUCCESS,
  ONTOLOGY_LIST_REQUESTED,
  ONTOLOGY_SAVE_FAIL,
  ONTOLOGY_SAVE_REQUEST, ONTOLOGY_SAVE_SUCCESS
} from "./actionType";

/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestOntologyList = () => ({
  type:ONTOLOGY_LIST_REQUESTED
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorOntologyList = (err) => ({
  type: ONTOLOGY_LIST_REQUEST_FAILED,
  err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param ontologies
 * @returns {{type, variablesList: *}}
 */

const successOntologyList = (ontologies) => ({
  type: ONTOLOGY_LIST_REQUEST_SUCCESS,
  ontologies
});

/**
 * Action Creator for getting list of ontologies.
 * @return {function(*, *): Promise<AxiosResponse<any>>}
 */
export const getOntologyListActionCreator = () => {
  return (dispatch,getState) => {
    dispatch(requestOntologyList());
    console.log('OntologyAction.js :: getOntologyList :: request');
    let config = {
      headers: {'Authorization': "bearer" + getState().auth.token_bearer}
    };
    return axios.get(HOST_URL + '/api/ontology',config
    ).then(resp => {
      console.log('OntologyAction.js :: getOntologyList :: response',resp);
      if (resp.status !== 200) {
        dispatch(errorOntologyList(resp.status));
        return Promise.reject(resp.data);
      } else {
        console.log('OntologyAction.js :: getOntologyList :: success');
        dispatch(successOntologyList(resp.data));
      }
    })
      .catch(err => dispatch(errorOntologyList( err)));
  };
};


const requestSaveOntology = ()=>({
  type:ONTOLOGY_SAVE_REQUEST
});

const errorSaveOntology = (err)=>({
  type:ONTOLOGY_SAVE_FAIL,
  err
});

const successSaveOntology = (data)=>({
  type:ONTOLOGY_SAVE_SUCCESS,
  data
});


export const clearSaveOntology = ()=>({
  type:CLEAR_ONTOLOGY_SAVE
});

/**
 * ActionCreator for getting all Annotation Columns from DB.
 * @param mode -
 * @returns {function(*,*)}
 */
export const saveOntology = (mode,payload) => {
  return (dispatch, getState) => {
    dispatch(requestSaveOntology());
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    return axios.post(`/api/ontology?status=${mode}`,payload).then(response => {
      console.log('OntologyAction.js :: saveOntology :: response', response);
      if (response.status !== 200) {
        dispatch(errorSaveOntology(response.data));
        return Promise.reject(response.data);
      } else {
        console.log('OntologyAction.js :: saveOntology ::  Before success');
        dispatch(successSaveOntology(response.data));
      }
    }).catch(err => dispatch(errorSaveOntology(err)));
  };
};

