import {
    UPDATE_PLOT_SETTINGS, UPDATE_PLOT_DATA, UPDATE_RAW_PLOT_DATA, UPDATE_PLOT_DATA_STATE, CLEAR_INTERACTIVE_PLOT,
} from './actionType'
import {calculateModelForBlandAltman, calculateModelParams} from "../../expDesign/action/AuditAction";
import axios from "axios/index";
import {DEFAULT_USER_ID, HOST_URL, REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {mean, uniqBy, map, combinations, filter, pick} from 'lodash';
import dataset from '../../expDesign/tests/agreementResults.json';
import segmentationsDataset from '../../expDesign/tests/agreementSegmentationsResults.json';
import store from "../../Store";
import { distance } from 'mathjs';
import munkres from 'munkres-js';
import {MOCKED_DATA_FOR_DYNAMIC_HISTOGRAM} from "../../expDesign/action/TabletGameExperimentAction";

//---------------Actions-----------------------------
const updatePlotSettings = (plotSettings) => ({
    type: UPDATE_PLOT_SETTINGS,
    plotSettings
});

const updatePlotData = (plotData) => ({
    type: UPDATE_PLOT_DATA,
    plotData
});

const updateRawDataForPlot = (rawData) => ({
    type: UPDATE_RAW_PLOT_DATA,
    rawData
});

const updatePlotDataState = (state) => ({
    type: UPDATE_PLOT_DATA_STATE,
    state
});

const clearInteractivePlot = () => ({
    type: CLEAR_INTERACTIVE_PLOT
});

//---------------Common Action creators-----------------------------

export const clearInteractivePlotState = () => {
    return dispatch => {
        dispatch(clearInteractivePlot());
    }
};


export const updatePlotSettingsAC = (plotSettings) => {
    return (dispatch) => {
       dispatch(updatePlotDataState(REQUEST_STATUS_REQUESTED));
       dispatch(updatePlotSettings(plotSettings));
       dispatch(updatePlotDataAC());
    };
};

export const updateMultiPlotSettingsAC = (plotSettings,data) => {
    return (dispatch) => {
        dispatch(updatePlotDataState(REQUEST_STATUS_REQUESTED));
        dispatch(updatePlotSettings(plotSettings));
        dispatch(updatePlotDataAC(data));

    };
};

export const updateBlandAltmanPlotSettingsACSegmentations = (plotSettings) => {
    return (dispatch) => {
       dispatch(updatePlotDataState(REQUEST_STATUS_REQUESTED));
       dispatch(updatePlotSettings(plotSettings));
       dispatch(updateBlandAltmanPlotDataACSegmentations());
    };
};

export const updateBlandAltmanPlotSettingsAC = (plotSettings) => {
    return (dispatch) => {
       dispatch(updatePlotDataState(REQUEST_STATUS_REQUESTED));
       dispatch(updatePlotSettings(plotSettings));
       dispatch(updateBlandAltmanPlotDataAC());
    };
};

const checkIfRServerIsNeeded=(settings)=>{
  return   settings.equationVisible || settings.rSquaredVisible || settings.regressionVisible
};


/**
 * Function used to refresh data only. It does not assume change of settings.
 * @param scientificEventId
 * @return {function(*,*)}
 */
export const refreshDataAC = (scientificEventId)=>{
    return (dispatch,getState) => {
        const rawData = getState().visu.interactivePlot.rawData;
        const config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        //this is displaying demo data
        if (scientificEventId === "avatarPlot") {
            dispatch(updateRawDataForPlot(MOCKED_DATA_FOR_AVATAR_PLOT_VERSION2));
            dispatch(updatePlotDataAC());
        }
        //real version
        else
            axios.get(HOST_URL + `/api/live-presenter/experiment/${scientificEventId}/result`, config)
                .then(response => {
                    console.log(`InteractivePlotAction.js :: initializeAvatarScatterPlot 
                ::/live-presenter/experiment/${scientificEventId}/result response`, response)
                    if (response.status !== 200) {
                        // dispatch(failExperiment(err,expId))
                    } else {
                        if (response.data!=null && response.data.cases!=null &&
                            JSON.stringify(response.data.cases) !== JSON.stringify(rawData.cases)){
                        dispatch(updateRawDataForPlot(response.data));
                        dispatch(updatePlotDataAC());}
                    }
                }).catch(error => {
                console.log('Experiment Action.js :: getExperimentList :: error ::', error);
                // dispatch(failExperiment(error,expId))
            });
    }
};

/**
 * Marks duplicates so that they can be properly displayed.
 * Duplicated element has counter of its duplicates.
 * @param dTR - data to reduce
 * @return {*}
 */
function markDuplicates(dTR){

    //generate unique keys corresponding to values
    const hashedValues = dTR.map((item)=>{return 'x:'+item.x+', y:'+item.y});
    let result = [];
    dTR.forEach((item,index)=>{
        const uniqueValueIndex = hashedValues.indexOf('x:'+item.x+', y:'+item.y);
        if ( uniqueValueIndex !== index) {
            result[uniqueValueIndex]['isDuplicated'] = true;
            item['isDuplicateOf'] = 'dup'+result[uniqueValueIndex]['userId'];
            if( result[uniqueValueIndex].hasOwnProperty('duplicatesCounter')) {
                result[uniqueValueIndex].duplicatesCounter += 1;
                item['duplicateNo'] = result[uniqueValueIndex].duplicatesCounter;
            }
            else{
                result[uniqueValueIndex]['duplicateNo'] = 1;
                item['duplicateNo'] = 2;
                result[uniqueValueIndex].duplicatesCounter = 2;
            }
        }
        result.push(item);
    });
    const duplicated = result
        .filter((el)=>el.hasOwnProperty('isDuplicated')) //find duplicated
        .map((el)=>{
            let deepCopy = JSON.parse(JSON.stringify(el)); //make a deep copy, otherwise any operations like delete will affect both
            delete deepCopy["isDuplicated"];
            delete el["duplicateNo"];
            el["userId"] = "dup"+el["userId"];
            deepCopy['isDuplicateOf'] = el['userId'];
            return deepCopy;
        });

    return result.concat(duplicated);
}

/**
 * Factory method creating transfer object to send to R server in order to calculate stats (line params, r-squared, etc.).
 * This method should be called on full data set (not with reduced duplicates).
 * @param dFS - data for scatter plot
 * @return {{}} transfer object
 */
function createTransferObjectToRServer(dFS,ci=0.95){
    let transferObject = {};
    transferObject.x = dFS.map(el => el.x);
    transferObject.y = dFS.map(el => el.y);
    transferObject.l = ci; //confidence intervals
    return transferObject;
}
/**
 *
 * @return {function(*, *)}
 */
export const updatePlotDataAC = (dataToProcess=null) => {
    return (dispatch, getState) => {
        const settings = getState().visu.interactivePlot.plotSettings;
        const dTP = dataToProcess!=null ? dataToProcess : getState().visu.interactivePlot.rawData;
        const email = getState().auth.email;

        let dataForScatter = [];

        const keys = Object.keys(dTP['cases']);
        const cases = dTP['cases'];
        keys.forEach((key, index) => {

            try {
                let parseY = cases[key]['clinical'][settings['clinicalValue']];
                const contributorsNumber = cases[key]['measurement'][settings['measurementValue']]['contribution'].length;

                let parseX = (contributorsNumber < 2)
                    ? cases[key]['measurement'][settings['measurementValue']]['contribution'][0]['value']
                    : cases[key]['measurement'][settings['measurementValue']]['contribution']
                        .reduce(function (sum, currentValue) {
                            return sum + parseFloat(currentValue.value);
                        }, 0)
                    / contributorsNumber;

                const parseOriginalImageId = cases[key]['measurement'][settings['measurementValue']]['originalImageId'];

                let single = {
                    "y": parseY,
                    "x": parseX,
                    "originalImageId": parseOriginalImageId,
                    "userId": index + 1,
                    "caseId" : key
                };
                if (contributorsNumber < 2) { // if only 1 contributor, get his picture
                    single["img"] = '/api/user/' + cases[key]['measurement'][settings['measurementValue']]['contribution'][0]['userId'] + '/picture';
                    single["segmentationId"] = cases[key]['measurement'][settings['measurementValue']]['contribution'][0]['segmentationId'];
                }

                if (contributorsNumber > 1) {
                    single['isCollectiveResult'] = true;
                    single['numberOfUsers'] = contributorsNumber;
                    single['raters'] = cases[key]['measurement'][settings['measurementValue']]['contribution'].map((el, index) => {
                        return {
                            "x": el['value'],
                            "originalImageId": parseOriginalImageId,
                            "userId": index + 1,
                            "img": '/api/user/' + cases[key]['measurement'][settings['measurementValue']]['contribution'][index]['userId'] + '/picture',
                            "segmentationId": cases[key]['measurement'][settings['measurementValue']]['contribution'][index]['segmentationId']
                        };
                    });
                }
                if (single['x'] != null && single['y'] != null && !isNaN(single['x']) && !isNaN(single['y']))
                    dataForScatter.push(single)
            }catch(err){
                console.log("Missing sample for: ", key, ":", cases[key] );
            }
        });

        // first calculate statistics if needed
        if (checkIfRServerIsNeeded(settings)) {
            dispatch(calculateModelParams(2, createTransferObjectToRServer(dataForScatter)));
        }

        // then remove duplicates
        dataForScatter = markDuplicates(dataForScatter);
        dispatch(updatePlotData(dataForScatter));
        dispatch(updatePlotDataState(REQUEST_STATUS_SUCCESS));
    };
};


/**
 * Create label.
 *
 * @param variable
 * @return {*}
 */
function extractLabel (variable){
    return (variable.unit != null)
        ? variable.name.concat(' [', variable.unit, ']')
        : variable.name;
};

//--------------Avatar scatter plot - specific actions


export const initializeAvatarScatterPlot = (scientificEventId) => {
    return dispatch => {

        let initialSettings = {
            equationVisible: false,
            regressionVisible: false,
            rSquaredVisible: false,
            optionsForMeasurements: [],
            optionsForClinical: [],
            measurementValue: "volume",
            clinicalValue: "edss",
            regressionLineColor: '#1976D2',
            regressionLineWidth:2,
            refreshRate_seconds:5,
            polling:true
        };

        const initializeDataAndSettings = (data)=>{
            initialSettings ['optionsForMeasurements'] = Object.keys(data.dataDescription.measurement)
                .map((el) => {
                    return {
                        value: el,
                        label: extractLabel(data.dataDescription.measurement[el])
                    }
                });
            initialSettings ['optionsForClinical'] = Object.keys(data.dataDescription.clinical)
                .map((el) => {
                    return {
                        value: el,
                        label: extractLabel(data.dataDescription.clinical[el])
                    }
                });
            dispatch(updatePlotSettingsAC(initialSettings));
        };

        const config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };

        //this is displaying demo data
        if (scientificEventId==="avatarPlot" ){
            dispatch(updateRawDataForPlot(MOCKED_DATA_FOR_AVATAR_PLOT_VERSION2));
            initializeDataAndSettings(MOCKED_DATA_FOR_AVATAR_PLOT_VERSION2);
        }
        if (scientificEventId==="multipleAvatarPlot") {
            dispatch(updateRawDataForPlot(MOCKED_DATA_FOR_DYNAMIC_HISTOGRAM));
            initializeDataAndSettings(MOCKED_DATA_FOR_DYNAMIC_HISTOGRAM);
        }

        //real version
        else
        axios.get(HOST_URL + `/api/live-presenter/experiment/${scientificEventId}/result`, config)
            .then(response => {
                console.log(`InteractivePlotAction.js :: initializeAvatarScatterPlot 
                ::/live-presenter/experiment/${scientificEventId}/result response`, response)
                if (response.status !== 200) {
                    // dispatch(failExperiment(err,expId))
                } else {
                    dispatch(updateRawDataForPlot(response.data));
                    initializeDataAndSettings(response.data);
                }
            }).catch(error => {
            console.log('Experiment Action.js :: getExperimentList :: error ::', error);
            // dispatch(failExperiment(error,expId))
        });
    }

};

// Check unique values by case, rater and workflow execution id and if combinations of case-rater with different
// workflow execution id exist
// Note: Final list of duplicates usable only if the max number of instances per rater and case is two otherwise it will not be a unique list of duplicates
// (currently add all the intances of duplication even if such duplication is already in the list)  
function checkIntraRaterAnalysisAvailability(data){
    const possibleRatersAndCasesForIntra = data
                    .map(({realCase, raterName, raterEmail})=>{
                        return {realCase, raterName: raterName!=null?raterName:raterEmail, raterEmail}})
                    .filter((instanceRaterCase, idx, arr)=>{
                            return idx !== arr.findIndex(
                                            instance => {
                                                return instance.realCase === instanceRaterCase.realCase && 
                                                        instance.raterEmail === instanceRaterCase.raterEmail}
                                        )
                        });
    return possibleRatersAndCasesForIntra;
}

function initialSettingsBlandAltmanPlot(rawData){
    const {data} = rawData;
    let contributors = uniqBy(map(data,
                                  (caseRaterExecution) => {
                                    if(caseRaterExecution['raterName']==null || caseRaterExecution['raterName']==''){
                                        caseRaterExecution['raterName'] = caseRaterExecution['raterEmail']
                                    }
                                    return {label: caseRaterExecution['raterName'],
                                            value: caseRaterExecution['raterEmail']}
                                    }),
                              'value');
    let cases = uniqBy(map(data,
                           (caseRaterExecution) => {
                                if(caseRaterExecution['realCase']==null){
                                    caseRaterExecution['realCase'] = caseRaterExecution['case']
                                }
                                return {label: caseRaterExecution['realCase'],
                                        value: caseRaterExecution['realCase']}
                                        }),
                      'value');

    const possibleRatersAndCasesForIntra = map(checkIntraRaterAnalysisAvailability(data),
                                             (caseRater) => {
                                                 return {label: `${caseRater.realCase}-${caseRater.raterName}`,
                                                         value: caseRater} 
                                             });
    const emptyCases = [];
    const selectedCases = [];

    map(cases, 'value').forEach(selectedCase => {
        let caseFound = data.find(
            ({realCase, rows})=>{
                return realCase === selectedCase && rows.length > 0;
            });
        if(caseFound!=null){
            selectedCases.push(selectedCase);
        }else{
            emptyCases.push(selectedCase);
        }
    });

    cases = cases.filter(caseI => selectedCases.includes(caseI.value));

    const initialSettings = {
        regressionLineColor: '1976D2',
        regressionLineWidth: 2,
        minNumberOfGroups: 2,
        maxNumberOfGroups: 2,
        initialGroupColor: '#1976D2',
        levelAgreementValue: 0,
        groups: [],
        selectedCases,
        emptyCases,
        contributors,
        cases,
        percentageDifference: false,
        possibleRatersAndCasesForIntra,
        intraCaseRaterSelected: null,
        intraRaterHandling: 'ALL_DATA',
        selectedCase: map(cases, 'value')[0],
        thresholdDistance: 5,
        mode: 0 // Bland-altman
    };
    return initialSettings;
}

export const initializeBlandAltmanPlot = (experimentProperties) => {
    return dispatch => {
        const {workflowId, miniWorkflowSetId, experimentId, miniWorkflowKey} = experimentProperties;
        // Real data
        if (workflowId && miniWorkflowSetId && experimentId && miniWorkflowKey){
            const config = {
                headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
            };
            const resultsURL = `${HOST_URL}/api/experiment/${experimentId}/workflow/${workflowId}/mini-workflow-set/${miniWorkflowSetId}/mini-workflow/${miniWorkflowKey}/results-v2`;
            axios.get(resultsURL, config)
                .then(response => {
                    console.log(`InteractivePlotAction.js :: initializeBlandAltmanPlot :: ${resultsURL} response`, response);
                    if (response.status !== 200) {
                        dispatch(updatePlotDataState(REQUEST_STATUS_FAIL));
                    } else {
                        dispatch(updateRawDataForPlot(response.data));
                        dispatch(updateBlandAltmanPlotSettingsAC(initialSettingsBlandAltmanPlot(response.data)));
                        dispatch(updateBlandAltmanPlotDataAC());
                    }
                }).catch(error => {
                    //TODO populate development database to use data directly from the backend...
                    console.log('InteractivePlotAction.js :: initializeBlandAltmanPlot :: error ::', error);
                    dispatch(updatePlotDataState(REQUEST_STATUS_FAIL));
            });
        // Demo data
        } else {
            dispatch(updateRawDataForPlot(dataset));                        
            dispatch(updateBlandAltmanPlotSettingsAC(initialSettingsBlandAltmanPlot(dataset)));
            dispatch(updateBlandAltmanPlotDataAC());
        }
    }
};


function createGroupByCase(groupUsers, groupColor, caseId, data, intraRaterHandling){
    const group = {
        value: 0,
        contributors: [],
        color: groupColor
            };
    groupUsers.forEach((user) => {
        const newUser = {
            userId: user,
            roiId: [],
            value: 0,
            userInformation: {}
        };
        const caseRaterExecutions = filter(data, (caseRaterExecution) => {
            return caseRaterExecution.raterEmail == user && caseRaterExecution.realCase == caseId;
        });
        //TODO: Check correct value to use, for now retrieve all the values for the user in a case
        if(caseRaterExecutions.length > 0){
            newUser['userId'] = caseRaterExecutions[0].raterName;
            if (intraRaterHandling === 'ALL_DATA'){ // Merge all available ROI data for the rater
                newUser['roiId'] = map(caseRaterExecutions, (caseRaterExecution) => {
                    return caseRaterExecution.rows.map((roi) => {return roi['lesion uuid']});
                }).flat();
            } else { // Use only first rater set of ROI (workflow execution ROIs) found
                newUser['roiId'] = caseRaterExecutions[0].rows.map((roi)=>{return roi['lesion uuid']});
            }
            newUser['value'] = newUser['roiId'].length;
        }
        group['contributors'].push(newUser);
    });

    group['value'] = mean(map(group['contributors'],
                              (contributor) => {
                                  return contributor['value'];
                            }));

    return group;
}

function groupData(groups, selectedCases, rawData, intraRaterHandling){
    let cases = [];
    let emptyCases = []; 
    const {data} = rawData;
    selectedCases.forEach(selectedCase => {
        let caseFound = data.find(
            ({realCase, rows})=>{
                return realCase === selectedCase && rows.length > 0;
            });
        if(caseFound!=null){
            cases.push({id:caseFound.realCase,
                        originalImageId: caseFound.rows[0].ROI.reference.imageEntityId,
                        originalImageFormat: caseFound.rows[0].ROI.reference.imageEntityFormat});
        }else{
            emptyCases.push(selectedCase);
        }
    });
    console.log('InteractivePlorAction.js::groupData::cases: ', cases);
    const formatedData = {cases: [],
                          emptyCases};
    cases.forEach((caseI) => {
        const newCase = { ...caseI,
                         groups: []
                         };
        for(const group of groups){
            const groupUsers = group['contributions'];
            const groupColor = group['color'];
            newCase['groups'].push(createGroupByCase(groupUsers, groupColor, caseI.id, data, intraRaterHandling));
        }
        formatedData['cases'].push(newCase); 
    });

    return formatedData;
}

function matchingCostMatrix(rows, columns, distanceFunction){
    const costMatrix = [];
    for (const row of rows) {
        const rowCost = [];
        for (const column of columns) {
            const costDistance = distanceFunction(row.coordinates, column.coordinates);  // [xRow, yRow, zRow] vs [xColumn, yColumn, zColumn]
            rowCost.push(costDistance);
        }
        costMatrix.push(rowCost);
    }
    return costMatrix;
}

function matchingWithDistanceThreshold(rows, columns, thresholdDistance){
    const costMatrix = matchingCostMatrix(rows, columns, distance);
    const groupsMatches = munkres(costMatrix);
    // Map matches in the form:
    // {
    //     contributions: [],
    //     color: '',
    //     coordinates: [],
    // }
    const withMatchIndexes = []; // Previous groups/lesions that matched new elements
    let updatedMatches = groupsMatches.reduce((newMatches, match) => {
        const rowIndex = match[0];
        const columnIndex = match[1];
        const rowCoordinates = rows[rowIndex].coordinates;
        const columnCoordinates = columns[columnIndex].coordinates;
        withMatchIndexes.push(rowIndex); // Update groups indexed that where matched
        const newContributor = { // New rater to be added. Formated in an object with his identifier and ROI uuid
            userId: columns[columnIndex].raterName,
            roiId: [columns[columnIndex]['lesion uuid']],
            value: 1,
            originalImageId: columns[columnIndex].ROI.reference.imageEntityId,
            originalImageFormat: columns[columnIndex].ROI.reference.imageEntityFormat,
            userInformation: {}
        };
        // Check threshold to don't take into account unfeasable matches and then create a new hypo. lesion if needed.
        // Depending on how large or small the value more matches will be dropped and more different groups will be created
        if(distance(rowCoordinates, columnCoordinates) <= thresholdDistance){
            rows[rowIndex].contributors.push(newContributor); // Add rater identifier to contributors list
            rows[rowIndex].coordinates = [mean([rowCoordinates[0], columnCoordinates[0]]), // Calculate new coordinates with centroid taking into account the match.
                                          mean([rowCoordinates[1], columnCoordinates[1]]),
                                          mean([rowCoordinates[2], columnCoordinates[2]])];
            newMatches.push(rows[rowIndex]);
        } else {
            // Previously matched element
            newMatches.push(rows[rowIndex]);
            // Unmatched element which will become a new lesion
            const newLesion = {
                contributors: [newContributor],
                color: "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);}), // Initialize color for lesion
                coordinates: columns[columnIndex].coordinates // Initial coordinates of the lesion are the initial pin selected
            };
            newMatches.push(newLesion);
        }
        return newMatches;
    }, []);

    // Ensure that non-matched previous groups are not left behind
    const missingMatchIndexes = [...Array(rows.length).keys()].filter(index => !withMatchIndexes.includes(index)); // Previous groups/lesions that didn't match new elements
    for (const unmatchedIndex of missingMatchIndexes) {
        updatedMatches.push(rows[unmatchedIndex]);
    }

    return updatedMatches;
}

function runMatchingAlgorithmForCase(caseId, data, thresholdDistance){
    const caseRaterExecutions = filter(data, (row) => {
        return row.realCase === caseId;
    });

    let ratersRois = [];
    for (const caseRaterExecution of caseRaterExecutions) {
        caseRaterExecution.rows.forEach(
            (row)=>{
                const roiWorldCoordinates = row.ROI.properties.implicit.roiProperties.worldCoordinates;
                row['coordinates'] = [roiWorldCoordinates.x, roiWorldCoordinates.y, roiWorldCoordinates.z];
                row['case'] = caseRaterExecution['case'];
                row['realCase'] = caseRaterExecution['realCase'];
                row['raterEmail'] = caseRaterExecution['raterEmail'];
                row['raterId'] = caseRaterExecution['raterId'];
                row['raterName'] = caseRaterExecution['raterName'];
                row['workflow execution uuid'] = caseRaterExecution['workflow execution uuid'];
                return row;
            });
        ratersRois.push(caseRaterExecution.rows);
    }
    let lesionGroups = [];
    if(ratersRois.length == 1){ // Handle case when only one rater is available for the case.Take each of the pins of the rater as a lesion group
        lesionGroups = ratersRois[0].map((roi)=>{
            const initialContributors = [{
                userId: roi.raterName,
                roiId: [roi['lesion uuid']],
                value: 1,
                originalImageId: roi.ROI.reference.imageEntityId,
                originalImageFormat: roi.ROI.reference.imageEntityFormat,
                userInformation: {}
            }];
            const initialLesion = {
                contributors: initialContributors, // Initialize contributions with itself
                color: "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);}), // Initialize color for lesion
                coordinates: roi.coordinates // Initial coordinates of the lesion are the initial pin selected
            };
            return initialLesion;
        });
    } else if(ratersRois.length >= 2){ // Handle case where actual matching can be run (2 or more raters in the case)
        ratersRois = ratersRois.sort((a, b) => {
            return b.length - a.length;
        });
        // First set (initialRows) determines the number of max hypothetical lesions. so is usable to stablish the colors per lesion/group
        const initialRows = ratersRois[0].map((roi)=>{
            const initialContributors = [{
                userId: roi.raterName,
                roiId: [roi['lesion uuid']],
                value: 1,
                originalImageId: roi.ROI.reference.imageEntityId,
                originalImageFormat: roi.ROI.reference.imageEntityFormat,
                userInformation: {}
            }];
            const initialLesion = {
                contributors: initialContributors, // Initialize contributions with itself
                color: "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);}), // Initialize color for lesion
                coordinates: roi.coordinates // Initial coordinates of the lesion are the initial pin selected
            };
            return initialLesion;
        }); 
        const initialColumns = ratersRois[1];
        lesionGroups = matchingWithDistanceThreshold(initialRows, initialColumns, thresholdDistance);
        if(lesionGroups.length === 0){ // Case where not acceptable match was found for the first iteration and lesionGroups is empty
            lesionGroups = initialRows;
        }
        ratersRois = ratersRois.slice(2); // Remove the first to raters (used for the initial run of the matching)
        for (const raterRoi of ratersRois) {
            const newLesionGroups = matchingWithDistanceThreshold(lesionGroups, raterRoi, thresholdDistance); 
            if (newLesionGroups.length > 0){
                lesionGroups = newLesionGroups;
            }
        }
    }
    return lesionGroups;
}


function groupDataForMatching(selectedCases, rawData, thresholdDistance){
    let cases = [];
    const {data} = rawData;
    selectedCases.forEach(selectedCase => {
        let caseFound = data.find(
            ({realCase, rows})=>{
                return realCase === selectedCase && rows.length > 0;
            });
        if(caseFound!=null){
            cases.push({realCase:caseFound.realCase,
                        originalImageId: caseFound.rows[0].ROI.reference.imageEntityId,
                        originalImageFormat: caseFound.rows[0].ROI.reference.imageEntityFormat});
        }
    });
    const formatedData = {cases: []}
    cases.forEach((caseI)=>{
        // Each lesion is a group of points
        const groups = runMatchingAlgorithmForCase(caseI.realCase, data, thresholdDistance);
        const newCase = { ...caseI,
                            groups
                        };
        formatedData.cases.push(newCase);
    });
    return formatedData;
}

/**Just for demo purposes - Agreement Demo
 *
 * @return {function(*, *)}
 */
export const updateBlandAltmanPlotDataAC = () => {
    return (dispatch, getState) => {
        const settings = getState().visu.interactivePlot.plotSettings;
        const rawData = getState().visu.interactivePlot.rawData;
        const {
            groups: settedGroups,
            selectedCases:settedCases,
            thresholdDistance,
            cases:allCases,
            mode,
            intraRaterHandling} = settings;

        if(mode === 0){ //TODO: Use constants 0 = Bland-altman; 1 = Matching algorithm
            const dTP = groupData(settedGroups, settedCases, rawData, intraRaterHandling);
            console.log('InteractivePlotAction.js::updateBlandAltmanPlotDataAC::Grouped Data', dTP);
            let dataForScatter = [];
            const cases = dTP['cases'];
            cases.forEach((caseI, index) => {
                const groups = caseI['groups'];
                if(groups.length == 2) {
                    try {
                        let parseY = groups[1]['value'];
                        let parseX = groups[0]['value'];
                        const contributorsNumberG1 = groups[1]['contributors'].length;
                        const contributorsNumberG0 = groups[0]['contributors'].length;
                        const parseOriginalImageId = caseI['originalImageId'];
                        const parseOriginalImageFormat = caseI['originalImageFormat'];
    
                        let single = {
                            "y": parseY, //data for group2
                            "x": parseX, //data for group1
                            'realCase': caseI['id'],
                            "originalImageId": parseOriginalImageId,
                            "originalImageFormat": parseOriginalImageFormat,
                            "userId": index + 1,
                        };
        
                        if (contributorsNumberG1 + contributorsNumberG0 > 1) {
                            single['isCollectiveResult'] = true;
                            single['numberOfUsers'] = contributorsNumberG1 + contributorsNumberG0;
                            single['groups'] = [];
                            groups.forEach((group) => {
                                const color = group['color'];
                                single['groups'].push(
                                { contributors: group['contributors'].map((el) => {
                                    return {
                                        "x": el['value'],
                                        "originalImageId": parseOriginalImageId,
                                        "originalImageFormat": parseOriginalImageFormat,
                                        "userId": el['raterId'],
                                        "img": `/api/user/${el['raterId']}/picture`,
                                        "roiId": el['roiId']
                                    };
                                    }),
                                    color
                                });
                            });
                        }
                        if (single['x'] != null && single['y'] != null && !isNaN(single['x']) && !isNaN(single['y']))
                            dataForScatter.push(single)
                    }catch(err){
                        console.log("Missing sample for: ", caseI['id'], ":", caseI );
                    }
                }
            });
            // first calculate statistics if needed
            if (dataForScatter.length > 0){
                dispatch(calculateModelForBlandAltman(createTransferObjectToRServer(dataForScatter)));
            }
            // then remove duplicates
            dataForScatter = markDuplicates(dataForScatter);
            dispatch(updatePlotData(dataForScatter));
            dispatch(updatePlotDataState(REQUEST_STATUS_SUCCESS));
        } else if(mode === 1) {
            const matchingResults = groupDataForMatching(map(allCases, 'value'), rawData, thresholdDistance);
            console.log('InteractivePlotAction.js::updateBlandAltmanPlotDataAC::Matched Data', matchingResults);    
            dispatch(updatePlotData([{matchingResults: matchingResults.cases}]));
            dispatch(updatePlotDataState(REQUEST_STATUS_SUCCESS));
        }
    };
};


// BAP: Segmentation vs Segmentation - Measure: Volume

function createGroupSegmentationByCase(groupUsers, groupColor, caseId, cases, intraRaterHandling){
    const group = {
        value: 0,
        contributors: [],
        color: groupColor
            };
    groupUsers.forEach((user) => {
        const newUser = {
            userId: user,
            roiId: [],
            value: 0,
            userInformation: {}
        };
        const caseRaterExecutions = cases[caseId].measurement.volume.contribution.filter((contribution)=>contribution.userId==user);
        //TODO: Check correct value to use, for now retrieve all the values for the user in a case
        if(caseRaterExecutions.length > 0){
            newUser['userId'] = caseRaterExecutions[0].userId;
            newUser['userInformation'] = caseRaterExecutions[0].userInformation;
            if (intraRaterHandling === 'ALL_DATA'){ // Merge all available ROI data for the rater
                newUser['roiId'] = map(caseRaterExecutions, (caseRaterExecution) => {
                    return caseRaterExecution.segmentationId;
                });
            } else { // Use only first rater set of ROI (workflow execution ROIs) found
                newUser['roiId'] = caseRaterExecutions[0].segmentationId;
            }
            newUser['value'] = mean(caseRaterExecutions.map(caseRaterExecution=>caseRaterExecution.value));
        }
        if(newUser['roiId'].length > 0){
            group['contributors'].push(newUser);
        }
    });

    group['value'] = mean(map(group['contributors'],
                              (contributor) => {
                                  return contributor['value'];
                            }));

    return group;
}

function groupDataSegmentations(groups, selectedCases, rawData, intraRaterHandling){
    let cases = [];
    let emptyCases = []; 
    const {cases:rawCases} = rawData;
    selectedCases.forEach(selectedCase => {
        let caseWithMeasurementFound = rawCases[selectedCase].measurement.volume;
        if(caseWithMeasurementFound!=null){
            cases.push({id:selectedCase,
                        originalImageId: rawCases[selectedCase].measurement.volume.originalImageId,
                        originalImageFormat: 'nii.gz'});
        }else{
            emptyCases.push(selectedCase);
        }
    });
    console.log('InteractivePlorAction.js::groupDataSegmentations::cases: ', cases);
    const formatedData = {cases: [],
                          emptyCases};
    cases.forEach((caseI) => {
        const newCase = { ...caseI,
                         groups: []
                         };
        for(const group of groups){
            const groupUsers = group['contributions'];
            const groupColor = group['color']; // TODO: This is actually a LUT for segementations
            newCase['groups'].push(createGroupSegmentationByCase(groupUsers, groupColor, caseI.id, rawCases, intraRaterHandling));
        }
        formatedData['cases'].push(newCase); 
    });

    return formatedData;
}



export const updateBlandAltmanPlotDataACSegmentations = () => {
    return (dispatch, getState) => {
        const settings = getState().visu.interactivePlot.plotSettings;
        const rawData = getState().visu.interactivePlot.rawData;
        const {
            groups: settedGroups,
            selectedCases:settedCases,
            thresholdDistance,
            cases:allCases,
            mode,
            intraRaterHandling,
            levelAgreementValue} = settings;

        if(mode === 0){ //TODO: Use constants 0 = Bland-altman;
            const dTP = groupDataSegmentations(settedGroups, settedCases, rawData, intraRaterHandling);
            console.log('InteractivePlotAction.js::updateBlandAltmanPlotDataACSegmentations::Grouped Data', dTP);
            let dataForScatter = [];
            const cases = dTP['cases'];
            cases.forEach((caseI, index) => {
                const groups = caseI['groups'];
                if(groups.length == 2) {
                    try {
                        let parseY = groups[1]['value'];
                        let parseX = groups[0]['value'];
                        const contributorsNumberG1 = groups[1]['contributors'].length;
                        const contributorsNumberG0 = groups[0]['contributors'].length;
                        const parseOriginalImageId = caseI['originalImageId'];
                        const parseOriginalImageFormat = caseI['originalImageFormat'];
    
                        let single = {
                            "y": parseY, //data for group2
                            "x": parseX, //data for group1
                            'realCase': caseI['id'],
                            "originalImageId": parseOriginalImageId,
                            "originalImageFormat": parseOriginalImageFormat,
                            "userId": index + 1,
                        };
        
                        if (contributorsNumberG1 + contributorsNumberG0 > 1) {
                            single['isCollectiveResult'] = true;
                            single['numberOfUsers'] = contributorsNumberG1 + contributorsNumberG0;
                            single['groups'] = [];
                            groups.forEach((group) => {
                                const color = group['color'];
                                single['groups'].push(
                                { contributors: group['contributors'].map((el) => {
                                    return {
                                        "x": el['value'],
                                        "originalImageId": parseOriginalImageId,
                                        "originalImageFormat": parseOriginalImageFormat,
                                        "userId": el['userId'],
                                        "img": `/api/user/${el['userId']}/picture`,
                                        "roiId": el['roiId']
                                    };
                                    }),
                                    color
                                });
                            });
                        }
                        if (single['x'] != null && single['y'] != null && !isNaN(single['x']) && !isNaN(single['y'])){
                            dataForScatter.push(single)
                        }
                    }catch(err){
                        console.log("Missing sample for: ", caseI['id'], ":", caseI );
                    }
                }
            });
            // first calculate statistics if needed
            if (dataForScatter.length > 0){
                // TODO: Check the returned structure to see if make sense
                dispatch(calculateModelForBlandAltman(createTransferObjectToRServer(dataForScatter)));
            }
            // then remove duplicates
            dataForScatter = markDuplicates(dataForScatter);
            dispatch(updatePlotData(dataForScatter));
            dispatch(updatePlotDataState(REQUEST_STATUS_SUCCESS));
        }
    };
};


function initialSettingsBlandAltmanPlotSegmentations(rawData){
    const {cases:rawCases, studyLUT, dataDescription} = rawData;
    let contributors = [];

    let cases = Object.keys(rawCases).map(caseI=>{
        return {label: caseI,
                value: caseI}
    });

    const possibleRatersAndCasesForIntra = []; // TODO: Filtering if the endpoint gives the workflow execution per case-contributor
    const emptyCases = [];
    const selectedCases = [];

    map(cases, 'value').forEach(selectedCase => {
        let caseMeasure = rawCases[selectedCase].measurement.volume;
        if(caseMeasure!=null){
            selectedCases.push(selectedCase);
        }else{
            emptyCases.push(selectedCase);
        }
    });

    cases = cases.filter(caseI => selectedCases.includes(caseI.value));

    const initialSettings = {
        data: {cases: pick(rawCases, selectedCases), studyLUT, dataDescription},
        regressionLineColor: '1976D2',
        regressionLineWidth: 2,
        minNumberOfGroups: 2,
        maxNumberOfGroups: 2,
        initialGroupColor: '#1976D2',
        levelAgreementValue: 80,
        groups: [],
        selectedCases,
        emptyCases,
        contributors,
        cases,
        percentageDifference: false,
        possibleRatersAndCasesForIntra,
        intraCaseRaterSelected: null,
        intraRaterHandling: 'ALL_DATA',
        selectedCase: map(cases, 'value')[0],
        thresholdDistance: 5,
        mode: 0 // Bland-altman
    };
    return initialSettings;
}

export const initializeBlandAltmanPlotSegmentations = (experimentProperties) => {
    // TODO: Change endpoint, check parameters for the load of the information
    return dispatch => {
        const {experimentId} = experimentProperties;
        // Real data
        if (experimentId){
            const config = {
                headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
            };
            const resultsURL = `${HOST_URL}/api/live-presenter/experiment/${experimentId}/result`;

            axios.get(resultsURL, config)
                .then(response => {
                    console.log(`InteractivePlotAction.js :: initializeBlandAltmanPlotSegmentations::{${resultsURL} response`, response)
                    if (response.status !== 200) {
                        dispatch(updatePlotDataState(REQUEST_STATUS_FAIL));
                    } else {
                        dispatch(updateRawDataForPlot(response.data));
                        dispatch(updateBlandAltmanPlotSettingsACSegmentations(initialSettingsBlandAltmanPlotSegmentations(response.data)));
                        dispatch(updateBlandAltmanPlotDataACSegmentations());
                    }
                }).catch(error => {
                    console.log('InteractivePlotAction.js :: initializeBlandAltmanPlotSegmentations :: error ::', error);
                    dispatch(updatePlotDataState(REQUEST_STATUS_FAIL));});
        // Demo data
        } else {
            dispatch(updateRawDataForPlot(segmentationsDataset));
            dispatch(updateBlandAltmanPlotSettingsACSegmentations(initialSettingsBlandAltmanPlotSegmentations(segmentationsDataset)));
            dispatch(updateBlandAltmanPlotDataACSegmentations());
        }
    }
};



export const MOCKED_DATA_FOR_AVATAR_PLOT_VERSION2 =
    {
        "dataDescription": {
            "clinical": {
                "edss": {
                    "name": "EDSS",
                    "unit": null
                },
                "9hpt": {
                    "name": "9HPT",
                    "unit": null
                }
            },
            "measurement": {
                "volume": {
                    "name": "Volume",
                    "unit": "cc",
                }
            }
        },
        "cases": {
            "caseId1": {
                "clinical": {
                    "edss": 4,
                    "9hpt": 7
                },
                "measurement": {
                    "volume": {
                        "originalImageId": "20c2d549136e928edffcdb64b902474d",
                        "contribution": [
                            {
                                "userId": "ECTRIMSV2_5-2243@spinegame.org",
                                "segmentationId": "d94c5c803fc1df8d0b2ad4f2fb00c15c",
                                "value": 0.4,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2322@spinegame.org",
                                "segmentationId": "d94c5c803fc1df8d0b2ad4f2fb003956",
                                "value": 0.6,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2359@spinegame.org",
                                "segmentationId": "4e74831357b23f0854278a9125036bc9",
                                "value": 0.6,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            }
                        ]
                    }
                }
            },
            "caseId2": {
                "clinical": {
                    "edss": 4,
                    "9hpt": 9
                },
                "measurement": {
                    "volume": {
                        "originalImageId": "575bddd50492d394fe3e506e21134850",
                        "contribution": [
                            {
                                "userId": "ECTRIMSV2_5-2322@spinegame.org",
                                "segmentationId": "4e74831357b23f0854278a9125036bc9",
                                "value": 0.6,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            }
                        ]
                    }
                }
            },
            "caseId3": {
                "clinical": {
                    "edss": 4,
                    "9hpt": 8
                },
                "measurement": {
                    "volume": {
                        "originalImageId": "b9e5860dc809e082903c96e3e1ac8082",
                        "contribution": [
                            {
                                "userId": "ECTRIMSV2_5-2343@spinegame.org",
                                "segmentationId": "acd2ec808091850c561d4584420208f0",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            }
                        ]
                    }
                }
            },
            "caseId4": {
                "clinical": {
                    "edss": 4,
                    "9hpt": 8
                },
                "measurement": {
                    "volume": {
                        "originalImageId": "b9e5860dc809e082903c96e3e1ac8082",
                        "contribution": [
                            {
                                "userId": "ECTRIMSV2_5-2375@spinegame.org",
                                "segmentationId": "d94c5c803fc1df8d0b2ad4f2fb003956",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2320@spinegame.org",
                                "segmentationId": "d94c5c803fc1df8d0b2ad4f2fb00c15c",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2322@spinegame.org",
                                "segmentationId": "acd2ec808091850c561d4584420208f0",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2379@spinegame.org",
                                "segmentationId": "acd2ec808091850c561d4584420208f0",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2375@spinegame.org",
                                "segmentationId": "acd2ec808091850c561d4584420208f0",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2320@spinegame.org",
                                "segmentationId": "acd2ec808091850c561d4584420208f0",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2322@spinegame.org",
                                "segmentationId": "acd2ec808091850c561d4584420208f0",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            },
                            {
                                "userId": "ECTRIMSV2_5-2379@spinegame.org",
                                "segmentationId": "acd2ec808091850c561d4584420208f0",
                                "value": 0.55,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            }
                        ]
                    }
                }
            },
            "caseId5": {
                "clinical": {
                    "edss": 4,
                    "9hpt": 9
                },
                "measurement": {
                    "volume": {
                        "originalImageId": "575bddd50492d394fe3e506e21134850",
                        "contribution": [
                            {
                                "userId": "ECTRIMSV2_5-2379@spinegame.org",
                                "segmentationId": "4e74831357b23f0854278a9125036bc9",
                                "value": 0.6,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            }
                        ]
                    }
                }
            },
            "caseId6": {
                "clinical": {
                    "edss": 4,
                    "9hpt": 9
                },
                "measurement": {
                    "volume": {
                        "originalImageId": "575bddd50492d394fe3e506e21134850",
                        "contribution": [
                            {
                                "userId": "ECTRIMSV2_5-2375@spinegame.org",
                                "segmentationId": "4e74831357b23f0854278a9125036bc9",
                                "value": 0.6,
                                "userInformation": {
                                    "id": DEFAULT_USER_ID
                                }
                            }
                        ]
                    }
                }
            },
        }
    };
