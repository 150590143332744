import React from 'react';
import PropTypes from "prop-types";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {ToolTrayItem} from "./ToolTrayItem";
import {ordinalColors, WAHIDAH} from "../../helpers/colors";

/**
 * Component to represent tool tray in Palette.
 * Panel only for workflows.
 */
class ToolPanelContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter:""
    };
  }

  componentDidMount() {
    this.props.getTools();
  }

  render() {
    const {toolListState,tools,lockDiagramModel} =this.props;
    const {filter} =this.state;

    const filtered =(toolListState===REQUEST_STATUS_SUCCESS)
      ? tools
        .filter(el=>{ //check if is filtered by search and non-automatic tool
          if (el.docType!=="boutiquesDescriptor")
            return el['name'].toLowerCase().includes(filter.toLowerCase()) && el.docType!=null && (el.docType === "manualTool" || el.docType === "tool" || el.docType === "manual");
          if (el.descriptor!=null && el['descriptor']['name']!=null)
            return el['descriptor']['name'].toLowerCase().includes(filter.toLowerCase()) && el.docType!=null;
          return false;
        })
        .map((el,index)=><ToolTrayItem toolData={el} color={ordinalColors(WAHIDAH)[0]}/>)
      :<React.Fragment/> ;

    return (
      <React.Fragment>
        <InputText
          onChange={(e)=>this.setState({filter:e.target.value})}
          value={filter}
          onFocus={(e)=>{
            lockDiagramModel(true);
          }}
          onBlur={(e)=>{
            lockDiagramModel(false);
          }}
        />
        <div className={"workflow-editor-panel-tray-content"} draggable={false}>
        {filtered}
        </div>
      </React.Fragment>
    );
  }
};



export default ToolPanelContent;

ToolPanelContent.propTypes= {
  tools:PropTypes.array.isRequired,
  toolListState: PropTypes.string.isRequired,
  getTools: PropTypes.func.isRequired,
  lockDiagramModel:PropTypes.func.isRequired
};