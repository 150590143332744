import React from "react";
import PropTypes from "prop-types";
import {
    COHORT_PROPERTY_NAME__COINVESTIGATORS, COHORT_PROPERTY_NAME__CREATION_DATE, COHORT_PROPERTY_NAME__DESCRIPTION,
    COHORT_PROPERTY_NAME__NAME, COHORT_PROPERTY_NAME__PI, COHORT_PROPERTY_NAME__SUBJECTS, COHORT_PROPERTY_NAME__UUID,
    EXPERIMENT_PROPERTY_NAME__COHORTS
} from "../../../Constants";
import {Checkbox} from "primereact/components/checkbox/Checkbox";


/**"Dumb" renderer for "List" mode for Models.
 * Used in Model Options Panel.
 * @param item - mandatory component if used with ItemList container
 * @param cohorts- selected cohorts
 * @param onSelect - callback to provide handler for checkbox onSelect
 * @returns {*}
 * @constructor
 */
const ModelOptionsItemListElement = ({item,experiment,onSelect}) => {

    return (
        <div className="cohortListElement" onClick={()=>onSelect(item.uuid)}
            // onClick={()=>{history.push('/project/'+item.cohortId+'/experiment/'+item.uuid)}}
        >
            <div className="ui-g-3">
                {item['name']}
            </div>
            <div className="ui-g-3">
                {item['author']}
            </div>
            <div className="ui-g-3">
                {item['spineType']}
            </div>
            <div className="ui-g-3">

            </div>
        </div>
    )
};
export default ModelOptionsItemListElement;

ModelOptionsItemListElement.propTypes = {
    item: PropTypes.object.isRequired,
    experiment : PropTypes.object.isRequired, //selected cohorts
    onSelect :  PropTypes.func.isRequired,//callback to select cohort
};