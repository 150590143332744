import {HOST_URL} from "../../../Constants";
import {
    SINGLE_RESULT_VIEW_FAIL, SINGLE_RESULT_VIEW_SUCCESS, SINGLE_RESULT_VIEW_REQUEST,CLEAR_SINGLE_RESULT_STATE
} from "./actionType";
import {
    MANUAL_TASK_GET_REQUEST
} from "../../dashboard/action/actionType";
import store from '../.././Store';
import axios from "axios/index";



//****************************************************************************
//
//                                Actions
//
//****************************************************************************


// Presentation actions *************************************************************

const request = () => ({
    type: SINGLE_RESULT_VIEW_REQUEST
});


const errorAC = (err) => ({
    type: SINGLE_RESULT_VIEW_FAIL,
    error: err
});

const success = (result) => ({
    type: SINGLE_RESULT_VIEW_SUCCESS,
    result: result
});

const requestManualTask = () => ({
    type: MANUAL_TASK_GET_REQUEST
});

const clearSingleResultState = () => ({
    type: CLEAR_SINGLE_RESULT_STATE,
});


// This is an action creator
export const clearSingleResultStateAC = ()=>{
    return (dispatch) => {
        dispatch(clearSingleResultState());
    }
};

// This is an action creator
export const getResult = (workflowExecutionId) => {
    console.log("getting single result view")
    
    return dispatch => {
        dispatch(request())
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        axios.get(HOST_URL + '/api/workflow-executor/'+workflowExecutionId+'/mini-workflow/mw1/results')
            .then(response => {
                console.log('Single result review :: response ::', response)
                if (response.status !== 200) {
                  dispatch(errorAC(response.data))
                } else {
                  // Create workfload for manual tool
                  const workload = {
                  		     "miniWorkflow":{
                  		        "miniWorkflowPath":[
                  		           {
                  		              "stepKey":"Review mode",
                  		              "stepName":"Review mode"
                  		           }
                  		        ],
                  		        "currentStep":0,
                  		        "currentTool":response.data.toolForReview,
                  		        "currentMaterializedTask":{
                  		           "preStep":{
                  		              "seen":false
                  		           },
                  		           "label":"review",
                  		           "inputs": response.data.inputsForReview,
                  		           "status":"TODO",
                  		           "assignedTo":"",
                  		           "dueDate":"",
                  		           "miniWorkflow":"",
                  		           "uuid":"fake-uuid"
                  		        },
                  		        "currentTutorial":{
                  		           "seen":false
                  		        },
                  		        "status":"notstarted"
                  		     },
                  		     "listOfCases":[
                  		       {caseNumber: "review", caseStatus: "TODO"}
                  		     ],
                  		     "currentCase":"review"
                         }
                    dispatch(success(workload))
                }
            }).catch(error => {
              dispatch(errorAC(error))
              console.log('single result view error:', error);
        });
    }
};