import React from "react";
import {Column} from "primereact/components/column/Column";
import {Dropdown} from "primereact/components/dropdown/Dropdown";


/**
 * "Dumb" stateless function to encapsulate logic for status column (filterFunction does not work with filterElement correctly
 * so in PrimeReact 1.6 this workaround is required). Check this after upgrading PrimeReact!
 * @param filterValue - variable  where filter state is kept
 * @param onFilterValueChange - function to update state and apply filter on DataTable eg.
 * (value) =>{
 *            this.setState({ selectedStatus: value });
 *            this.dt.filter(value, 'status', 'custom')
 *          })
 *
 * @return {*}
 * @constructor
 */
export const StatusColumn = (filterValue,onFilterValueChange) => {

  const statusFilter = <div><Dropdown style={{width:"100%"}}
                                      value={filterValue}
                                      options={[{label:"All",value:"ALL"},{label:"Published",value:"PUBLISHED"},{label:"Draft",value:"DRAFT"}]}
                                      onChange={e=>onFilterValueChange(e.value)}
  /></div>;

  return <Column columnKey={"statusColumn"}
                 field="status"
                 header={"Status"}
                 body={renderStatus}
                 sortable={true}
                 filter={true}
                 filterMatchMode="custom"
                 filterElement={statusFilter}
                 filterFunction={filterStatus}
                 style={{width:"8em"}}
  />
};
const filterStatus = (value,filter)=>{
  if (filter==="ALL" || filter === undefined || filter === null || (typeof filter !== 'string')) {
    return true;
  }
  if ((value === undefined || value === null) && (filter==="PUBLISHED" || filter==="ALL"))
    return true;
  else return filter===value;
};
const renderStatus = (row)=>{
  if (row.status==="DRAFT")
    return "DRAFT";
  else return "PUBLISHED"
};
