import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/mode/text';
import 'brace/theme/github';
import 'brace/ext/searchbox';
import {Button} from "primereact/components/button/Button";
import {validateTool} from "../../workflowManager/WorkflowValidator";
import {getNestedProp} from "../../helpers/expressions";



class EditorTabContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeIndex:0,
      initialText:null,
      editorText:null, //finalresult to send to DB
      validationErrors:null,
      testingData:{}//Materialized Task
    };
    ["save","validate"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });

  }


  componentDidMount() {
    const {transferObject} = this.props;
    const ace = this.aceEditor;
    this.setState({
      editorText:JSON.stringify(transferObject, null, '\t'),
      initialText:JSON.stringify(transferObject, null, '\t')
    });
    setTimeout(function() {
      console.log("timeout");
      ace.editor.session.getUndoManager().reset();
    }, 700);
    this.validate(transferObject);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentWillUnmount() {
    const {updateTransferObject} = this.props;
  }

  validate(to){
    const {valid,errorObject} = validateTool(getNestedProp(['miniWorkflow','currentTool'],to));
    this.setState({
      validationErrors:errorObject
    });
  }

  save(){
    const {editorText} =this.state;
    const {updateTransferObject,updateIsChangedFlag,messageQueue} = this.props;
    try{
      const to = JSON.parse(editorText);
      this.setState({initialText:editorText});
      updateTransferObject(to);
      this.validate(to);
      updateIsChangedFlag(false);
    }
    catch(e){
      messageQueue.show( {
        sticky: false,
        severity: 'error',
        summary: 'Error',
        detail:"Invalid JSON syntax of tool configuration!"
      });
    }
  }



  updateTransferObjectCache(key,value){
    this.setState({[key]:value});
  }


  render() {
    const {getWorkflow,messageQueue,updateIsChangedFlag,isChangedFlag,transferObject} =this.props;
    const {editorText,initialText,validationErrors} =this.state;

    return (
      <div className="ui-g">
            <div className="ui-g-7">
              <Button icon={'fa fa-save'} title={"Update tool"} onClick={this.save}/>
              <Button icon={'fa fa-undo'}
                      onClick={()=> { this.aceEditor.editor.undo() }
                      }/>
              <Button icon={'fa fa-repeat'}
                      onClick={()=> {this.aceEditor.editor.redo()}}/>
              <Button icon={'fa fa-refresh'} title={"Reload and remove all changes until last successful save or page load"}
                      onClick={()=> {
                        this.componentDidMount();
                        updateIsChangedFlag(false);
                      }
                      }/>
              <AceEditor
                ref={editor => this.aceEditor = editor}
                mode="json"
                readOnly={false}
                theme="github"
                name="EDITOR"
                highlightActiveLine={true}
                width="100%"
                height="calc(100vh - 200px)"
                value={editorText!=null?editorText:"Nothing here"}
                wrapEnabled={true}
                onChange={(value)=> {
                  this.setState({editorText: value});
                  if(value!==initialText)
                    updateIsChangedFlag(true);
                  else
                    updateIsChangedFlag(false);
                }
                }
              />
            </div>
            <div className="ui-g-5">
              <div>Validation Errors Total: {validationErrors!=null?validationErrors.length:0}</div>
              <AceEditor
                mode="json"
                readOnly={true}
                theme="github"
                name="EDITOR"
                highlightActiveLine={false}
                value={validationErrors!=null?JSON.stringify(validationErrors,null,'\t'):"Not available"}
                height="calc(100vh - 200px)"
                width="100%"
                wrapEnabled={true}
              />
            </div>

      </div>
    )
  };
}
export default withRouter(EditorTabContent);

EditorTabContent.propTypes= {
  messageQueue:PropTypes.object.isRequired,
  transferObject: PropTypes.object.isRequired,
  updateTransferObject: PropTypes.func.isRequired,
  updateIsChangedFlag: PropTypes.func.isRequired
};
