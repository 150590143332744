import {
  EXPERIMENT_SPINEQ_BACKUPS_FAILURE,
  EXPERIMENT_SPINEQ_BACKUPS_REQUEST, EXPERIMENT_SPINEQ_BACKUPS_SUCCESS,
  EXPERIMENT_SPINEQ_ENROLLMENTS_FAILURE,
  EXPERIMENT_SPINEQ_ENROLLMENTS_REQUEST,
  EXPERIMENT_SPINEQ_ENROLLMENTS_SUCCESS,
  EXPERIMENT_SPINEQ_ENROLLMENTS_UPDATE,
  EXPERIMENT_SPINEQ_EVENT_LOGS_FAILURE,
  EXPERIMENT_SPINEQ_EVENT_LOGS_REQUEST,
  EXPERIMENT_SPINEQ_EVENT_LOGS_SUCCESS,
  EXPERIMENT_SPINEQ_QUESTIONNAIRES_FAILURE,
  EXPERIMENT_SPINEQ_QUESTIONNAIRES_REQUEST,
  EXPERIMENT_SPINEQ_QUESTIONNAIRES_SUCCESS,
  EXPERIMENT_SPINEQ_TIMELINE_FAILURE,
  EXPERIMENT_SPINEQ_TIMELINE_REQUEST,
  EXPERIMENT_SPINEQ_TIMELINE_SUCCESS
} from "./actionType";
import store from "../../Store";
import {HOST_URL} from "../../../Constants";
import axios from 'axios';
import {getNestedProp} from "../../helpers/expressions";


const requestEnrollments = (experimentId) => ({
  type: EXPERIMENT_SPINEQ_ENROLLMENTS_REQUEST,
  experimentId
});

const successEnrollments = (experimentId, enrollments) => ({
  type: EXPERIMENT_SPINEQ_ENROLLMENTS_SUCCESS,
  experimentId,
  enrollments
});

const failEnrollments = (experimentId,err) => ({
  type: EXPERIMENT_SPINEQ_ENROLLMENTS_FAILURE,
  experimentId,
  err
});


const requestQuestionnaires = (subjectId) => ({
  type: EXPERIMENT_SPINEQ_QUESTIONNAIRES_REQUEST,
  subjectId
});

const successQuestionnaires = ( subjectId,questionnaireAnswers) => ({
  type: EXPERIMENT_SPINEQ_QUESTIONNAIRES_SUCCESS,
  subjectId,
  questionnaireAnswers
});

const failQuestionnaires = (subjectId,err) => ({
  type: EXPERIMENT_SPINEQ_QUESTIONNAIRES_FAILURE,
  subjectId,
  err
});

const requestBackups = (subjectId) => ({
  type: EXPERIMENT_SPINEQ_BACKUPS_REQUEST,
  subjectId
});

const successBackups = ( subjectId,questionnaireAnswers) => ({
  type: EXPERIMENT_SPINEQ_BACKUPS_SUCCESS,
  subjectId,
  questionnaireAnswers
});

const failBackups = (subjectId,err) => ({
  type: EXPERIMENT_SPINEQ_BACKUPS_FAILURE,
  subjectId,
  err
});

const requestEventLogs = (subjectId) => ({
  type: EXPERIMENT_SPINEQ_EVENT_LOGS_REQUEST,
  subjectId
});

const successEventLogs = ( subjectId,subjectEventLogs) => ({
  type: EXPERIMENT_SPINEQ_EVENT_LOGS_SUCCESS,
  subjectId,
  subjectEventLogs
});

const failEventLogs = (subjectId,err) => ({
  type: EXPERIMENT_SPINEQ_EVENT_LOGS_FAILURE,
  subjectId,
  err
});

const requestTimeline = (experimentId) => ({
  type: EXPERIMENT_SPINEQ_TIMELINE_REQUEST,
  experimentId
});

const successTimeline = ( experimentId, data) => ({
  type: EXPERIMENT_SPINEQ_TIMELINE_SUCCESS,
  experimentId,
  data
});

const failTimeline = (experimentId,err) => ({
  type: EXPERIMENT_SPINEQ_TIMELINE_FAILURE,
  experimentId,
  err
});


export const updateEnrollments = (property,value) => ({
  type: EXPERIMENT_SPINEQ_ENROLLMENTS_UPDATE,
  property,
  value
});


/**
 * Action Creator for reading all enrollments for a given experiment
 * @returns {function(*)}
 */
export const getEnrollments = (experimentId) => {
  return dispatch => {
    dispatch (requestEnrollments(experimentId));
    console.log('SpineQAction.js :: getEnrollments :: Before axios request');
    const payload = {experimentId:experimentId};
    const config = {
      headers: {'Authorization': "bearer" + store.getState().auth.token_bearer},
    };

    return axios.post(HOST_URL+'/api/mobileApp/monitor/enrollments',payload,config
    ).then(response => {
      console.log('SpineQAction.js :: getEnrollments :: Response', response);
      if(response.status !== 200) {
        dispatch (failEnrollments(experimentId, response.data));
        dispatch(displayCommunicationErrorMessage({message:"Unknown problem with request for enrollments: SC:"+response.status})); // display in growl
      } else {
        dispatch (successEnrollments(experimentId, response.data));
      }
    }).catch(err => {
      dispatch (failEnrollments(experimentId,err));
      dispatch(displayCommunicationErrorMessage(err)); // display in growl
    });
  };
};


/**
 * Action Creator for reading all enrollments for a given experiment
 * @returns {function(*,*)}
 */
export const addSubjectEnrollment = (experimentId,subjectEmail,cohortId,onSuccess) => {
  return (dispatch,getState) => {

    console.log('SpineQAction.js :: addSubjectEnrollment :: Before axios request');

    const messageQueue = getState().messaging.msgQueue;
    const payload = {
      experimentUuid:experimentId,
      subjectEmail:subjectEmail,
      cohortUuid:cohortId
    };
    const config = {
      headers: {'Authorization': "bearer" + store.getState().auth.token_bearer},
    };

    return axios.post(HOST_URL+'/api/subjectEnrollment',payload,config
    ).then(response => {
      console.log('SpineQAction.js :: addSubjectEnrollment :: Response', response);
      if(response.status !== 200) {
        messageQueue.show({
          sticky: false,
          life:2000,
          severity: 'error',
          summary: "Error",
          detail: "Subject has not been added"
        });
      } else {
        messageQueue.show({
          sticky: false,
          life:2000,
          severity: 'info',
          summary: "Info",
          detail: "Subject "+ subjectEmail + " has been added"
        });
        if (onSuccess)
          onSuccess();
      }
    }).catch(err => {
      messageQueue.show({
        sticky: false,
        life:2000,
        severity: 'error',
        summary: "Error",
        detail: err.message
      });
    });
  };
};


/**
 * Action Creator for deleting subject from experiment
 * @param subjectEmail
 * @param subjectEnrollmentId
 * @param subjectId
 * @param onSuccess - callback to call on success
 * @returns {function(*,*)}
 */
export const deleteEnrollment = (subjectEmail,subjectEnrollmentId,subjectId,onSuccess) => {
  return (dispatch,getState) => {

    console.log('SpineQAction.js :: deleteEnrollment :: Before axios request');

    const messageQueue = getState().messaging.msgQueue;
    const payload = { subjectEnrollmentId, subjectEmail, subjectId};
    const config = {
      headers: {'Authorization': "bearer" + store.getState().auth.token_bearer},
    };

    return axios.delete(HOST_URL+'/api/subjectEnrollment/'+subjectEnrollmentId,config).then(response => {
      console.log('SpineQAction.js :: deleteEnrollment :: Response', response);
      if(response.status !== 200) {
        messageQueue.show({
          sticky: false,
          life:2000,
          severity: 'error',
          summary: "Error",
          detail: "Subject "+ subjectEmail + " has NOT been deleted"
        });
      } else {
        messageQueue.show({
          sticky: false,
          life:2000,
          severity: 'info',
          summary: "Info",
          detail: "Subject "+ subjectEmail + " has been deleted"
        });
        if (onSuccess)
          onSuccess();
      }
    }).catch(err => {
      messageQueue.show({
        sticky: false,
        life:2000,
        severity: 'error',
        summary: "Error",
        detail: err.message
      });
    });
  };
};

export const clearEnrollments = () =>{
  return dispatch => {
   dispatch(successEnrollments("",[]))
  }
};



/**
 * Action Creator for reading all questionnaire for a given subject.
 * @returns {function(*, *): Promise<<any>>}
 */
export const getQuestionnaireAnswers = (subjectId) => {
  return (dispatch,getState) => {
    dispatch (requestQuestionnaires(subjectId));
    console.log('SpineQAction.js :: getQuestionnaireAnswers :: Before axios request');
    const payload = {
      experimentId:getState().spineQMonitor.experimentId,
      subjectId:subjectId
    };
    const config = {
      headers: {'Authorization': "bearer" + store.getState().auth.token_bearer},
    };

    return axios.post(HOST_URL+'/api/mobileApp/monitor/qsAnswers',payload,config
    ).then(response => {
      console.log('SpineQAction.js :: getQuestionnaireAnswers :: Response', response);
      if(response.status !== 200) {
        dispatch (failQuestionnaires(subjectId, response.data));
        dispatch(displayCommunicationErrorMessage({message:"Unknown problem with request for questionnaires: SC:"+response.status})); // display in growl
      } else {
        dispatch (successQuestionnaires(subjectId, response.data));
      }
    }).catch(err =>{
      dispatch (failQuestionnaires(subjectId,err));
      dispatch(displayCommunicationErrorMessage(err)); // display in growl
    });
  };
};


/**
 * Action Creator for reading all questionnaire backups for a given subject.
 * @returns {function(*, *): Promise<<any>>}
 */
export const getQuestionnaireBackups = (subjectId) => {
  return (dispatch,getState) => {
    dispatch (requestBackups(subjectId));
    console.log('SpineQAction.js :: getQuestionnaireAnswers :: Before axios request');
    const payload = {
      experimentId:getState().spineQMonitor.experimentId,
      subjectId:subjectId
    };
    const config = {
      headers: {'Authorization': "bearer" + store.getState().auth.token_bearer},
    };

    return axios.post(HOST_URL+'/api/mobileApp/monitor/qsBackups',payload,config
    ).then(response => {
      console.log('SpineQAction.js :: getQuestionnaireAnswers :: Response', response);
      if(response.status !== 200) {
        dispatch (failBackups(subjectId, response.data));
        dispatch(displayCommunicationErrorMessage({message:"Unknown problem with request for questionnaires: SC:"+response.status})); // display in growl
      } else {
        dispatch (successBackups(subjectId, response.data));
      }
    }).catch(err =>{
      dispatch (failBackups(subjectId,err));
      dispatch(displayCommunicationErrorMessage(err)); // display in growl
    });
  };
};


/**
 * Action Creator for reading eventLogs for a given subject.
 * @returns {function(*, *): Promise<<any>>}
 */
export const getEventLogs = (subjectId) => {
  return (dispatch,getState) => {
    dispatch (requestEventLogs(subjectId));
    console.log('SpineQAction.js :: getEventLogs :: Before axios request');
    const payload = {
      experimentId:getState().spineQMonitor.experimentId,
      subjectId:subjectId
    };
    const config = {
      headers: {'Authorization': "bearer" + store.getState().auth.token_bearer},
    };

    return axios.post(HOST_URL+'/api/mobileApp/monitor/eventLogs',payload,config
    ).then(response => {
      console.log('SpineQAction.js :: getEventLogs :: Response', response);
      if(response.status !== 200) {
        dispatch (failEventLogs(subjectId, response.data));
        dispatch(displayCommunicationErrorMessage({message:"Unknown problem with request for eventLogs: SC:"+response.status})); // display in growl
      } else {
        dispatch (successEventLogs(subjectId, response.data));
      }
    }).catch(err =>{
      dispatch (failEventLogs(subjectId,err));
      dispatch(displayCommunicationErrorMessage(err)); // display in growl
    });
  };
};


/**
 * Action Creator for reading eventLogs for a given subject.
 * @returns {function(*, *): Promise<<any>>}
 */
export const getTimeline = () => {
  return (dispatch,getState) => {
    const experimentId =getState().spineQMonitor.experimentId;
    const payload = {
      experimentId:experimentId,
    };
    dispatch (requestTimeline(experimentId));
    console.log('SpineQAction.js :: getTimeline :: Before axios request');
    const config = {
      headers: {'Authorization': "bearer" + store.getState().auth.token_bearer},
    };

    return axios.post(HOST_URL+'/api/mobileApp/monitor/timeline',payload,config).then(response => {
      console.log('SpineQAction.js :: getTimeline :: Response', response);
      if(response.status !== 200) {
        dispatch (failTimeline(experimentId, response.data));
        dispatch(displayCommunicationErrorMessage({message:"Unknown problem with request for timeline: SC:"+response.status})); // display in growl
      } else {
        dispatch (successTimeline(experimentId, response.data));
      }
    }).catch(err =>{
      dispatch (failTimeline(experimentId,err));
      dispatch(displayCommunicationErrorMessage(err)); // display in growl
    });
  };
};

/**
 * Handles displaying errors in Growl.
 * @param err
 * @return {function(...[*]=)}
 */
const displayCommunicationErrorMessage = (err) => {
  return (dispatch, getState)=> {
    const messageQueue = getState().messaging.msgQueue;

    messageQueue.show({
      sticky: false,
      life:2000,
      severity: 'error',
      summary: "Error",
      detail: err.message
    });
  }
};