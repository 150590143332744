import {
    CLEAR_SKILL_STATE,
    SET_SKILL_SORTER, SKILL_SLIDE_LIST_FAIL, SKILL_SLIDE_LIST_REQUESTED, SKILL_SLIDE_LIST_SUCCESS,
    SKILL_UPDATE_CURRENT_STEP_INDEX, SKILL_URL_MOCK_ROCHE_EN, SKILL_URL_MOCK_ROCHE_FR, SKILL_URL_MOCK_ROCHE_FR_2
} from "./actionType";
import {HOST_URL} from "../../../Constants";
import store from "../../Store";
import axios from "axios/index";
import {MOCKED_SKILL_RESULTS} from "../../expDesign/tests/Mocks";
import {
    SET_SKILL_VISIBILITY_FILTER,
    SKILL_FAIL,
    SKILL_LIST_FAIL, SKILL_LIST_REQUEST, SKILL_LIST_SUCCESS, SKILL_REQUEST,
    SKILL_SUCCESS
} from "./actionType";
import {SLIDE_CREATE, SLIDE_LIST_FAIL, SLIDE_LIST_SUCCESS} from "../../livePresenter/action/actionType";
import {goToPreviousStep} from "../../visualization/action/ManualToolAction";
import {loadImageData} from "../../visualization/action/ImageAction";



// const mockSkills = [
//     {
//         "uuid": "3802b284-da5b-4283-50bb-f338bdb77a77",
//         "name": "Segmentation of Thalamus",
//         "contribution": -2, //this is flag, just for mixing real skills with fake ones - to remove in future
//         "expertise": "Gold",
//         "link":"",
//         "workflowParams":[],
//         "project": {uuid:"22cd2ab5-c136-490b-9fca-406c431ebd1b", name:"Fatigue"}
//     },
//     {
//         "uuid": "3802b284-da5b-4283-50bb-f338bdb77a79",
//         "name": "Segmentation of MS lesions",
//         "contribution": -2,
//         "expertise": "Silver",
//         "link":"",
//         "workflowParams":[],
//         "project": {uuid:"1557f770-6295-47ea-af49-eca1e1729702", name:"WOLFSON"}
//     },
//     {
//         "uuid": "3802b284-da5b-4283-50bb-f338bdb77a79",
//         "name": "Leptomeningeal metastases identification and characterization",
//         "contribution": 0,
//         "expertise": "Silver",
//         "link":"/skills/playskill/ef8c47475fb0bb40ba9deb55c704d3c9",
//         "workflowParams":["f133fbed-4cfe-4940-b3e4-0c8775b28661","12914275-28af-4c15-b217-59b7225dac91","mw1",""],
//         "project": {uuid:"1557f770-6295-47ea-af49-eca1e1729702", name:"WOLFSON"}
//     }
// ];

//     {
//         "uuid": "3802b284-da5b-4283-50bb-f338bdb77a80",
//         "name": "Segmentation of Medulla Oblongata",
//         "contribution": -1,
//         "expertise": "Bronze",
//         "link":SKILL_URL_MOCK_ROCHE_EN,
//         "workflowParams":["47320796cab03852b61400298e002298","6e0822d84ffa9db459dfe4c604037513","mw1"],
//         "project": {uuid:"5f293422-2e83-4db8-8af3-a52726bb1175", name:"IPMSA"}
//     },
//     {
//         "uuid": "3802b284-da5b-4283-50bb-f338bdb77a80",
//         "name": "Segmentation de la Moelle Allongée",
//         "contribution": -1,
//         "expertise": "Bronze",
//         "link":SKILL_URL_MOCK_ROCHE_FR,
//         "workflowParams":["47320796cab03852b61400298e002298","b9e5860dc809e082903c96e3e1b10374","mw1"],
//         "project": {uuid:"5f293422-2e83-4db8-8af3-a52726bb1175", name:"IPMSA"}
//     },
//     {
//         "uuid": "3802b284-da5b-4283-50bb-f338bdb77a80",
//         "name": "Segmentation de la Moelle Allongée 2",
//         "contribution": -1,
//         "expertise": "Bronze",
//         "link":SKILL_URL_MOCK_ROCHE_FR_2,
//         "workflowParams":["8f09b60a78cf2eb3a438674fec00f27c","8f09b60a78cf2eb3a438674fec00ed39","mw1"],
//         "project": {uuid:"24c6f931577f948420cedbb599052789", name:"IPMSA"}
//     }
//
// ];

//****************************************************************************
//
//                                Actions
//
//****************************************************************************



// Skill actions *************************************************************

const requestSkillList = () => ({
    type: SKILL_LIST_REQUEST
});



const errorSkillList = err => ({
    type: SKILL_LIST_FAIL,
    err
});

const successSkillList = (skills) => ({
    type: SKILL_LIST_SUCCESS,
    skills
});


async function loadMockedSkillList() {
    let response = await fetch('/dist/jsonDocs/data/mockSkills.json');
    const data = await response.json();
    return await data;
}

/** TODO Bind with real data instead mockup*/
export const getSkillList = () => {
    return dispatch => {
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        console.log('SkillAction.js :: getSkillList :: requested ::');
        dispatch(requestSkillList());
        return axios.get(`${HOST_URL}/api/skill`, config)
                    .then(response => {
                        if (response.status !== 200) {
                            dispatch(errorSkillList(response.data));
                            return Promise.reject(response.data);
                        } else {
                            dispatch(successSkillList(response.data));
                        }
                    })
                    .catch(err => dispatch(errorSkillList(err)));
    };

};

export function setVisibilityFilter(filter) {
    return { type: SET_SKILL_VISIBILITY_FILTER, filter }
}

export function setSorter(sorter) {
    return { type: SET_SKILL_SORTER, sorter }
}



// One skill *****************************************************************
const requestSkill = (idSkill) => ({
    type: SKILL_REQUEST,
    idSkill
});



const errorSkill = err => ({
    type: SKILL_FAIL,
    err
});

const successSkill = (skill) => ({
    type: SKILL_SUCCESS,
    skill
});


// /** TODO Bind with real data instead mockup*/
// export const getSkill = () => {
//     return dispatch => {
//         dispatch (successSkill(mockSkills[0]));
//     };
// };

//**************** Skill results *************************************************
// One skill *****************************************************************
const requestSkillResults = () => ({
    type: "SKILL_RESULT_REQUEST"
});

const errorSkillResults = err => ({
    type: 'SKILL_RESULT_FAIL',
    err
});

export const successSkillResults = (result) => ({
    type: 'SKILL_RESULT_SUCCESS',
    result
});

/**
 * Clears state of skillResults only.
 * @return {{type: string}}
 */

export const clearSkillResultsAC = ()=>({
    type:'CLEAR_SKILL_RESULT'
});


/**
 *  Gets skill results.
 *  Used in dice coefficient panel
 * @return {function(*, *)}
 */
export const getSkillResults = (livePresenterExperimentId) => {
    return (dispatch,getState)=> {
        dispatch (requestSkillResults());
        let path=HOST_URL +'/api/live-presenter/experiment/'+livePresenterExperimentId+'/dice-score';
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;

        // dispatch (successSkillResults(MOCKED_SKILL_RESULTS));
        axios.get(path)
            .then(response => {
                console.log('SkillAction.js :: getSkillResults :: response ::', response);
                if (response.status !== 200) {
                    dispatch(errorSkillResults(response.statusText));
                } else {
                    dispatch(successSkillResults(response.data));
                }
            }).catch(error => {
            console.log('SkillAction :: getSkillResults :: error ::', error);
            dispatch(errorSkillResults(error));
        });
    };
};


//=========================SKILL EXECUTION========================================

const errorSlideList = err => ({
    type: SKILL_SLIDE_LIST_FAIL,
    err
});

const successSlideList = (slides) => ({
    type: SKILL_SLIDE_LIST_SUCCESS,
    slides: slides
});

const requestSlideList = () => ({
    type: SKILL_SLIDE_LIST_REQUESTED
});

/**
 * Action Creator for setting index of current step in "slide" presentation.
 * @param index
 * @return {{type, currentStepIndex: *}}
 */
export const updateCurrentStepIndexActionCreator = (index) => ({
    type: SKILL_UPDATE_CURRENT_STEP_INDEX,
    currentStepIndex: index
});

/**
 * Action Creator responsible for setting index of current step in "slide" presentation.
 * @return {function(*, *)}
 */

export const goToNextSkillStep = () => {
    return (dispatch,getState) => {
        let currentIndex = getState().skill.skill.currentStepIndex;
        if (++currentIndex < getState().skill.skill.slides.length)
            dispatch(updateCurrentStepIndexActionCreator(currentIndex));
    }
};

/**
 * Action Creator responsible for setting index of current step in "slide" presentation.*
 * @return {function(*, *)}
 */

export const goToPreviousSkillStep = () => {
    return (dispatch,getState) => {
        let currentIndex = getState().skill.skill.currentStepIndex;
        if (--currentIndex >= 0)
            dispatch(updateCurrentStepIndexActionCreator(currentIndex))
    }
};
//
// /**
//  * Action Creator responsible for setting index of current step in "slide" presentation for 3 positions back.
//  * This should be used as action for back button in mini workflow task. Moves presntation back to tutorial video before
//  * previous task.
//  * @return {function(*, *)}
//  */
// export const goToPreviousSkillStepInWorkflow = () => {
//     return (dispatch,getState) => {
//         let currentIndex = getState().skill.skill.currentStepIndex-3;
//         if (currentIndex >= 0)
//             dispatch(updateCurrentStepIndexActionCreator(currentIndex));
//         dispatch(goToPreviousStep());
//     }
// };

/**
 * AC for clearing state of slides.
 * @see SkillReducer.
 * @return {{type, currentStepIndex: *}}
 */
export const clearSkillStateAC = () => ({
    type: CLEAR_SKILL_STATE
});


/**
 * Gets all slides for a given Skill Execution.
 * this is equivalent to getSlideList from LivePresenter.
 * Dispatch success action to save SORTED by number slides in Store.
 * @param id
 * @return {function(*)}
 */
export const getSlideListForSkill = (id) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestSlideList());
        axios.get(HOST_URL + '/api/presentation/'+ id+'/slide')
            .then(response => {
                console.log('SkillAction.js :: getSlideListForSkill :: response ::', response)
                if (response.status !== 200) {
                    dispatch(errorSlideList(err))
                } else {
                    const slides = response.data;
                    let sortedSlides = null;
                    if (slides != null && slides.length > 0) {
                        sortedSlides = slides.sort((a, b) => {
                            return parseInt(a.orderNumber) - parseInt(b.orderNumber)
                        })
                    }
                    dispatch(successSlideList(sortedSlides))
                }
            }).catch(error => {
                console.log('SkillAction.js :: getSlideListForSkill :: response ::', error);
                dispatch(errorSlideList(error))
            });
    }
};


/**
 *  Gets skill results for individual user.
 *  Used in results page.
 * @return {function(*, *)}
 */
export const getScoreResults = (livePresenterExperimentId) => {
    return (dispatch,getState)=> {
        dispatch (requestSkillResults());
        let path=HOST_URL +'/api/live-presenter/experiment/'+livePresenterExperimentId+'/score';
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;

        // dispatch (successSkillResults(MOCK_SCORE_RESULTS));
        axios.get(path)
            .then(response => {
                console.log('SkillAction.js :: getSkillResults :: response ::', response);
                if (response.status !== 200) {
                    dispatch(errorSkillResults(response.statusText));
                } else {
                    dispatch(successSkillResults(response.data));
                    if (response.data.originalImageId!=null &&  response.data.labelMapImageId!=null) {
                        dispatch(loadImageData(response.data.originalImageId, "nii.gz", `/api/image-entity/${response.data.originalImageId}/file`));
                        dispatch(loadImageData(response.data.labelMapImageId, "nii.gz", `/api/image-entity/${response.data.labelMapImageId}/file`, "overlay"));
                    }
                }
            }).catch(error => {
            console.log('SkillAction :: getSkillResults :: error ::', error);
            dispatch(errorSkillResults(error));
        });
    };
};


const MOCK_SCORE_RESULTS = {
    "scoring": [
        {
            "name": "Lower slice",
            "value": 15,
            "max": 20
        },
        {
            "name": "Segmentation",
            "value": 59.68635301968635,
            "max": 80
        }
    ],
        "labelMapImageId": "06bf3451e2d63c3bf86b43983807c309",
        "originalImageId": "b9e5860dc809e082903c96e3e1ac8098"
};

