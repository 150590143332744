import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import { ProgressBar } from "primereact/components/progressbar/ProgressBar";
import Authorization from "../../authentication/component/Authorization";
import {USER} from "../../../Constants";
import {Button} from "primereact/components/button/Button";

/**"Dumb" renderer for "List" mode for Experiments.
 * Required since experiment attributes need to be processed.
 *
 * @param item - mandatory component if used with ItemList container
 * @param history - provided by withRouter HOC wrapper
 * @param onRemoveClick
 * @param onProgressClick
 * @param onAnalysisClick
 * @returns {*}
 * @constructor
 */
const ExperimentItemListElement = ({item,history,onRemoveClick,onProgressClick,onAnalysisClick}) => {

    let dateToDisplay =  new Date(item.modificationDate).toLocaleString();
    let people = (item['co-investigators']!=null)?item['co-investigators'].length:0;
    // TODO: Progress information that needs to be provided!
    let progress = item.progress || 50;
    let timeToFinishLabel = `${item.timeToFinish || 'N days'} to finish`;

    let move = ()=>{history.push('/project/'+item.projectId+'/experiment/'+item.uuid)};
    return (
            <div className="experimentListElement">
                <div className="ui-g-4" onClick={move}>
                        {item.question}
                </div>
                <div className="ui-g-2" onClick={move}>{people}</div>
                <div className="ui-g-1" onClick={move}>{item.status}</div>
                <div className="ui-g-1" onClick={move}>{dateToDisplay}</div>
                <Authorization roles={[USER.ROLES.ADMIN]}>
                    <div className="ui-g-2" onClick={()=>{onProgressClick(item)}}>
                        <ProgressBar value={progress} />
                    <i>{timeToFinishLabel}</i>
                    </div>
                </Authorization>
                <div className="ui-g-2" style={{textAlign:"right"}}>
                    <Button className={"fa fa-mobile"} title={"Monitor progress"} style={{fontSize:"large"}} onClick={()=>{
                      history.push('/project/'+item.projectId+'/experiment/'+item.uuid+'/spineqmonitor')
                    }}/>
                    <Button className={"fa fa-bar-chart"} title={"Analyze results"} style={{fontSize:"large"}} onClick={()=>{onAnalysisClick(item)}}/>
                    <Button className="fa fa-trash" title="Remove experiment" style={{fontSize:"large"}}
                     onClick={()=>{onRemoveClick(item)}}/>
                </div>
            </div>
    )
};
export default withRouter(ExperimentItemListElement);

ExperimentItemListElement.propTypes = {
    item: PropTypes.object.isRequired,
    onAnalysisClick: PropTypes.func.isRequired
};
