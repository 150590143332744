import React from "react";
import {getLayoutClass} from "../../root/component/LayoutManager";
import {getPortLabelByType} from "../../visualization/action/ManualToolAction";



const ToolHintPanel = ({toolData}) => {


if (toolData!=null) {

  // const bindViewers =(originalKey)=>{
  // return Object.keys(toolData.configuration.viewers.layout.layoutOrder).find(key => toolData.configuration.viewers.layout.layoutOrder[key] === originalKey)
  // };

  let viewersState = {};
  Object.keys(toolData.configuration.viewers.layout.layoutOrder)
    .forEach((el)=>{
      viewersState[el]=toolData.configuration.viewers.renderWindows[toolData.configuration.viewers.layout.layoutOrder[el]];
      viewersState[el]['sceneId']="sceneKey1";//toolData.configuration.scenes.sceneKey1;
    });

  const isDefinedClassName = getLayoutClass(toolData.configuration.viewers.layout.type)!=null && getLayoutClass(toolData.configuration.viewers.layout.type).getClassName!=null;
  const isDefinedLabel = getLayoutClass(toolData.configuration.viewers.layout.type)!=null && getLayoutClass(toolData.configuration.viewers.layout.type).getLabel!=null;
  const label = isDefinedLabel?getLayoutClass(toolData.configuration.viewers.layout.type).getLabel():"Preview not available";
  const icon = isDefinedClassName?<div className={ getLayoutClass(toolData.configuration.viewers.layout.type).getClassName()}/>:<div>{label}</div>;

  return (
    <div>
      <div>Layout: {toolData.configuration.viewers.layout.type} </div>
      {icon}
      <div>Scenes:  {Object.keys(toolData.configuration.scenes).length}</div>
      <div>Viewers: {Object.keys(toolData.configuration.viewers.layout.layoutOrder).length}</div>
      <div>Widgets: {Object.keys(toolData.configuration.widgets).reduce(((previousValue, currentValue) => {return previousValue.concat(currentValue,", ")} )," ")}</div>
      <div>Inputs: {Object.values(toolData.inputs).reduce(((previousValue, currentValue) => {return previousValue.concat(currentValue.name,"[",getPortLabelByType(currentValue.type),"], ")} )," ")}</div>
      <div>Outputs: {Object.values(toolData.outputs).reduce(((previousValue, currentValue) => {return previousValue.concat(currentValue.name,"[",getPortLabelByType(currentValue.type),"], ")} )," ")}</div>
    </div>
  );
}
else return <div/>;
};
export default ToolHintPanel;

