import React from "react";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import PropTypes from "prop-types";

/**
 * Resizable sidebar.
 * This component is a wrapper over regular Primereact Sidebar providing new features as:
 * - modality feature
 * - resizable width.
 */
class ResizableSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: props.defaultWidth,
            isDragging: false,
            delta: 0
        };
        ["startResize", "stopResize", "resizePanel", "onShow"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.width!==this.state.width){
            this.props.onWindowWidthChange(this.state.width);
        }
    }

    /**
     * This function contains HACK to solve the problem with missing modality property in Sidebar component (PrimeReact v.1.6)
     */

    onShow() {
        const {modal,onShow} = this.props;
        if (modal!=null && modal===false) {
            let el = document.getElementsByClassName("ui-sidebar-mask");
            el[0].style.visibility = "collapse";
        }
        if (onShow!=null)
        onShow();
    }


    startResize(event) {
        this.setState({
            isDragging: true,
            initialPos: event.clientX
        });
        event.stopPropagation();
        event.preventDefault();
    }

    stopResize(event) {
        if (this.state.isDragging) {
            this.setState({
                isDragging: false,
                width: this.state.width + this.state.delta,
                delta: 0,
                currentPanel: null
            });
            event.stopPropagation();
            event.preventDefault();
        }
    }

    resizePanel(event) {
        if (this.state.isDragging) {
            const delta = event.clientX - this.state.initialPos;
            this.setState({
                delta: delta
            });
            event.stopPropagation();
            event.preventDefault();
        }
    }

    render() {
        const {visible,modal,onUnpin} = this.props;
        const {width, delta, isDragging} = this.state;

        const resizerStyle = {left: (width + delta - 10) + "px"};
        if (isDragging)
            resizerStyle['backgroundColor'] = "gray";

        const props = this.props;
        let sidebarStyle = {width: width + "px",pointerEvents:"all"};
        if(props.style!=null)
        sidebarStyle = Object.assign(props.style,sidebarStyle);
        delete props.onShow;
        delete props.style;

            return (
                <div id="resizable-sidebar-container"
                     style={{display: (visible) ? "block" : "none", pointerEvents:modal?"all":"none"}}
                     onMouseMove={this.resizePanel}
                     onMouseLeave={this.stopResize}
                     onMouseUp={this.stopResize}>
                    <Sidebar {...props} onShow={this.onShow} style={sidebarStyle}>
                        <a className="fa fa-fw fa-thumb-tack" style={{float:"right",transform:"rotate(90deg)"}} title="Unpin" role={"button"} onClick={()=>{onUnpin()}}/>
                        <div style={{height:"100%",overflowY:"auto",marginTop:"2em"}}>
                        <div>
                        {this.props.children}
                        </div>
                        <div onMouseDown={(e) => this.startResize(e)}
                             key={"resizer"}
                             className="resizable-sidebar-resizer"
                             style={resizerStyle}
                        >
                            <div id="resizable-sidebar-resizer-handle" className="fa fa-ellipsis-v"/>
                        </div>
                        </div>
                    </Sidebar>

                </div>);
        }


}

ResizableSidebar.defaultProps ={
    defaultWidth:300,
    modal:false
};

ResizableSidebar.propTypes = {
    visible: PropTypes.bool.isRequired,
    id: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    position: PropTypes.string,
    fullScreen: PropTypes.bool,
    blockScroll: PropTypes.bool,
    baseZIndex: PropTypes.number,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    defaultWidth:PropTypes.number.isRequired,
    modal:PropTypes.bool, //whether to hide mask
    onWindowWidthChange:PropTypes.func
};
export default ResizableSidebar;
