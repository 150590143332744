import React from "react";
import PropTypes from "prop-types";

import {Chart} from "primereact/components/chart/Chart";
import {Card} from "primereact/components/card/Card";
import {ORDINAL24, ORDINAL24LIGHT, ordinalColorsArray} from "../../helpers/colors";
import {getNestedProp} from "../../helpers/expressions";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {getNested} from "../../helpers/comparators";


const backgroundColors = ordinalColorsArray(ORDINAL24, 24);
const hoverColors = ordinalColorsArray(ORDINAL24LIGHT, 24);
const chartTypeOptions = [{label: "pie", value: "pie"}, {label: "doughnut", value: "doughnut"}, {
  label: "bar",
  value: "bar"
}];

const categorySubjectsOptions = [{label: "sex", value: "sex"}, {label: "dob", value: "dob"}];
const categoryAcquisitionsOptions = [{label: "Type", value: "acquisitionType"}, {label: "Modality", value: "modality"}];
const categoryROISOptions = [{label: "Type", value: "typeROI"}];
const categoryVisitsOptions = [{label: "Subject id", value: "reference.subjectId"}];

/**
 * Component used for SELECTION of cohorts.
 * Visualization of selected cohorts is implemented in different place!
 * This is NOT independent standalone component! It uses parent component ids !!!
 *
 */

class ChartsPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      typeImages: "pie",
      typeSubjects: "pie",
      typeVisits: "pie",
      typeROIS: "pie",
      categorySubjects: "sex",
      categoryVisits: "reference.subjectId",
      categoryAcquisitions: "acquisitionType",
      categoryROIS: "typeROI"
    };
    ["generateData"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  /**
   * Navigate to tabs in parent panel
   * hack to reach navigation to tab (not exposed in TabPanel API)
   *
   * @param tab - string with class for tab (id cannot be used since it is generated dynamically after each render call)
   * {"tabHeaderSubjects",""tabHeaderVisits","tabHeaderAcquisitions","tabHeaderROIs"}
   */
  navigateToParentTab(tab) {
    document.getElementsByClassName(tab)[0].children[0].click(); //
  }

  generateData(listName, property) {
    const {cohort} = this.props;
    switch (property) {
      case "space": {
        return {
          labels: ['Not available'],
          datasets: [
            {
              data: [0],
              backgroundColor: backgroundColors,
              hoverBackgroundColor: hoverColors
            }
          ]
        };
      }

      default: {
        const list = getNestedProp([listName, "list"], cohort);
        if (list != null && Array.isArray(list) && list.length > 0) {
          const propertyArr = list.map(e => getNested(e, property));   // convert possible "reference.subjectId" to
          const labels = [...new Set(propertyArr)]; // a set of unique labels
          const valuesArray = new Array(labels.size);
          for (let i = 0; i < labels.length; i++) {
            valuesArray[i] = 0; // init just in case filter won't find anything
            valuesArray[i] = propertyArr.filter(el => el === labels[i]).length;
          }

          return {
            labels: labels,
            datasets: [
              {
                data: valuesArray,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: hoverColors
              }
            ]
          }
        } else {
          return {
            labels: ['Not available'],
            datasets: [
              {
                data: [0],
                backgroundColor: backgroundColors,
                hoverBackgroundColor: hoverColors
              }
            ]
          }
        }
      }
    }
  }


  render() {
    const {cohort, onClickCallback} = this.props;
    const {typeImages, typeSubjects, typeVisits, typeROIS, categorySubjects, categoryAcquisitions, categoryROIS, categoryVisits} = this.state;


    const chartOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#495057'
          }
        }
      },
      onClick: (e, items) => {  //this has to be in a single handler for all charts, due to Chart.js event handler architecture
        if (getNestedProp([0, 'index'], items) != null && getNested(e, "chart.canvas.parentNode.id") != null) {
          console.log(e.chart.data.labels[items[0].index], " : ", e.chart.data.datasets[items[0]['datasetIndex']].data[items[0].index]);

          let chartId = getNested(e, "chart.canvas.parentNode.id");
          let parentTab = "tabHeaderSubjects";

          if (chartId === "subChart") {
            if (onClickCallback)
              onClickCallback("filtersSubjects", categorySubjects, e.chart.data.labels[items[0].index],e.chart.data.datasets[items[0]['datasetIndex']].data[items[0].index]);
          }

          if (chartId === "acqChart") {
            parentTab = "tabHeaderAcquisitions";
            if (onClickCallback)
              onClickCallback("filtersImages", categoryAcquisitions, e.chart.data.labels[items[0].index],e.chart.data.datasets[items[0]['datasetIndex']].data[items[0].index]);
          }

          if (chartId === "visChart") {
            parentTab = "tabHeaderVisits";
            if (onClickCallback)
              onClickCallback("filtersVisits", categoryVisits, e.chart.data.labels[items[0].index],e.chart.data.datasets[items[0]['datasetIndex']].data[items[0].index]);
          }

          if (chartId === "roiChart") {
            parentTab = "tabHeaderROIs";
            if (onClickCallback)
              onClickCallback("filtersROIs", categoryROIS, e.chart.data.labels[items[0].index],e.chart.data.datasets[items[0]['datasetIndex']].data[items[0].index]);
          }

          setTimeout(() => {
            this.navigateToParentTab(parentTab);
          }, 250);
          //
        }
      }
    };

    return (
      <div style={{width: "100%", display: "flex", justifyContent: "start"}}>

        <Card style={{width: "25%"}}>
          <a onClick={() => {
            this.navigateToParentTab("tabHeaderAcquisitions")
          }}>
            <h2>All Acquisitions: {getNestedProp(["images", "count"], cohort)}</h2>
          </a>
          <div>
            Property: <Dropdown value={categoryAcquisitions} options={categoryAcquisitionsOptions}
                                onChange={(e) => this.setState({categoryAcquisitions: e.value})}/>
            Chart: <Dropdown value={typeImages} options={chartTypeOptions}
                             onChange={(e) => this.setState({typeImages: e.value})}/>
          </div>
          <Chart id={"acqChart"} type={typeImages}
                 options={chartOptions}
                 data={this.generateData("images", categoryAcquisitions)}/>
        </Card>

        <Card style={{width: "25%"}}>
          <a onClick={() => {
            this.navigateToParentTab("tabHeaderSubjects")
          }}>
            <h2>All Subjects: {getNestedProp(["subjects", "count"], cohort)}</h2>
          </a>
          <div>
            Property: <Dropdown value={categorySubjects} options={categorySubjectsOptions}
                                onChange={(e) => this.setState({categorySubjects: e.value})}/>
            Chart: <Dropdown value={typeSubjects} options={chartTypeOptions}
                             onChange={(e) => this.setState({typeSubjects: e.value})}/>
          </div>
          <Chart id={"subChart"} type={typeSubjects}
                 options={chartOptions}
                 data={this.generateData("subjects", categorySubjects)}/>
        </Card>

        <Card style={{width: "25%"}}>
          <a onClick={() => {
            this.navigateToParentTab("tabHeaderVisits")
          }}>
            <h2>All Visits: {getNestedProp(["visits", "count"], cohort)}</h2>
          </a>
          <div>
            Property: <Dropdown value={categoryVisits} options={categoryVisitsOptions}
                                onChange={(e) => this.setState({categoryVisits: e.value})}/>
            Chart: <Dropdown value={typeVisits} options={chartTypeOptions}
                             onChange={(e) => this.setState({typeVisits: e.value})}/>
          </div>
          <Chart id={"visChart"} type={typeVisits}
                 options={chartOptions}
                 data={this.generateData("visits", categoryVisits)}/>
        </Card>

        <Card style={{width: "25%"}}>
          <a onClick={() => {
            this.navigateToParentTab("tabHeaderROIs")
          }}>
            <h2>All Regions of interests: {getNestedProp(["rois", "count"], cohort)}</h2>
          </a>
          <div>
            Property: <Dropdown value={categoryROIS} options={categoryROISOptions}
                                onChange={(e) => this.setState({categoryROIS: e.value})}/>
            Chart: <Dropdown value={typeROIS} options={chartTypeOptions}
                             onChange={(e) => this.setState({typeROIS: e.value})}/>
          </div>
          <Chart id={"roiChart"} type={typeROIS}
                 options={chartOptions}
                 data={this.generateData("rois", categoryROIS)}/>
        </Card>
      </div>
    )
  }
}

export default ChartsPanel;

ChartsPanel.propTypes = { //ALL props are passed from CohortSelectionMenu via  {...this.props}
  cohort: PropTypes.object.isRequired,//filtered cohorts
  onClickCallback: PropTypes.func
};