import {connect} from "react-redux";
import React from 'react';
import {clearScriptOutputAC, executeScript} from "../action/ScriptAction";
// import {ScriptPanel} from "../component/ScriptPanel";
// import {AuditScriptPanel} from "../component/AuditScriptPanel";

const ScriptPanel =  React.lazy(() => import("../component/ScriptPanel"));
const AuditScriptPanel =  React.lazy(() => import("../component/AuditScriptPanel"));

const mapStateToProps = (state,ownProps) => {
    return {
        plots: state.expDesign.script.plots,
        scriptExecutorState:state.expDesign.script.scriptExecutorState,
        stdOut:state.expDesign.script.stdOut,
        rawData:state.expDesign.script.rawData,
        experiment: state.expDesign.experiment
    }
};

const mapDispatchToProps = dispatch => {
    return {
        executeScript:(script,data)=> {
                dispatch(executeScript(script,data));
           },
        clearOutput:()=>{
            dispatch(clearScriptOutputAC());
        }
    }
};

export const ContainerScriptPanel = connect( mapStateToProps, mapDispatchToProps)(ScriptPanel);
export const ContainerAuditScriptPanel = connect( mapStateToProps, mapDispatchToProps)(AuditScriptPanel);