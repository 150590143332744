import React from 'react'
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {getNestedProp} from "../../helpers/expressions";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {ROI_TABLE_TOOL} from "../../../Constants";


/**
 *
 * Component displaying ROI table  in Dialog Window.
 * Implemented as Manual Tool Widget.
 */
export class ROITableWidget extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: null,
      height: null
    };
    ["ijkBody","xyzBody"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      }); //Binding methods
  }

  typeBody(row){
    return (row['properties'].hasOwnProperty("explicit"))
      ? <div>{"labelmap"} </div>
      : <div>{"geometric"} </div>
  }
  markerBody(row){
    const geom = getNestedProp(["properties","implicit","implicitGeometryPointer"],row);
    const gem = getNestedProp(["properties","implicit","implicitGemetryPointer"],row); // bug in data
    const relation = getNestedProp(["properties","implicit","implicitRelationWithRoi"],row);
    return <div>{geom!=null?geom:""}{gem!=null?gem:""} {relation!=null?relation:""} </div>
  }

  xyzBody(row){
    const {setSliceNumbersToIJK} = this.props;
    const xyz = getNestedProp(["properties","implicit","roiProperties","worldCoordinates"],row);
    const ijk = getNestedProp(["properties","implicit","roiProperties","imageCoordinates"],row);
    if (xyz!=null)
      return <a
        style={{textDecoration:"underline"}}
        {
          ...ijk!=null?
          {onClick:()=>setSliceNumbersToIJK([ijk.i,ijk.j,ijk.k])}:
          {}
        }
        >
        [{xyz.x.toFixed(2)},{xyz.y.toFixed(2)},{xyz.z.toFixed(2)}]
    </a>;
    else
      return <React.Fragment/>
  }

  ijkBody(row){
    const {setSliceNumbersToIJK} = this.props;
    const ijk = getNestedProp(["properties","implicit","roiProperties","imageCoordinates"],row);
    if (ijk!=null)
      return <a style={{textDecoration:"underline"}} onClick={()=>setSliceNumbersToIJK([ijk.i,ijk.j,ijk.k])}>[{ijk.i},{ijk.j},{ijk.k}]</a>;
    else
      return <React.Fragment/>
  }

  render() {
    const {selectedDataPoint,visualizationToolDialogVisible,onHide, manualToolConfiguration,roiVisualizationData,
      manualToolState,updateManualToolProperty} = this.props;

    const dynamicColumns = (getNestedProp(["questions"],roiVisualizationData)!=null)
      ? getNestedProp(["questions"],roiVisualizationData)
        .map(el=>{return(
          <Column
            columnKey={el['_id']}
            header={(el.shortName!=null)?el.shortName:el.questionText}
            body={(row,column)=>{
              if (row!=null && row.annotations!=null){
                const answer = row.annotations.find(el=>el.reference.questionId === column.columnKey);
                if (answer!=null && answer.hasAnswer && getNestedProp(["annotationProperties","value"],answer)!=null)
                  return <a
                    target={"_blank"}
                    {...getNestedProp(["annotationProperties","value","ontologyClassIri"],answer)!=null
                      ? {href: getNestedProp(["annotationProperties","value","ontologyClassIri"],answer)}
                      : {}
                    }

                  >{getNestedProp(["annotationProperties","value","label"],answer)}</a>
              }
              return <React.Fragment/>}}
          />
        )})
      :[];


    return (
      <React.Fragment>
        <Dialog header={"Regions of interest"}
                closeOnEscape={true}
                resizable={true}
                visible={getNestedProp([ROI_TABLE_TOOL,ROI_TABLE_TOOL],manualToolState)}
                modal={false}
                onHide={()=>updateManualToolProperty(ROI_TABLE_TOOL,ROI_TABLE_TOOL,false)}
                style={{width:"90vw"}}>

          {selectedDataPoint != null && manualToolConfiguration != null && visualizationToolDialogVisible && roiVisualizationData!=null && roiVisualizationData.rois!=null &&
          <DataTable value={roiVisualizationData.rois} scrollable={true} scrollHeight={"200px"}
          >
            <Column field={"uuid"} header={"Id"} />
            <Column field={"color"} header={"Color"} body={(el)=>{return <div style={colorBox(el["color"])}/>}} style={{width:"5em"}}/>
            <Column field={"contributorLabel"} header={"Rater"} />
            <Column field={"properties"} header={"Type"}   body={this.typeBody} style={{width:"10em"}}/>
            <Column field={"properties"} header={"Marker"} body={this.markerBody}                     />
            <Column field={"properties"} header={"xyz"}    body={this.xyzBody}  style={{width:"15em"}}/>
            <Column field={"properties"} header={"ijk"}    body={this.ijkBody}  style={{width:"15em"}}/>
            {dynamicColumns}
          </DataTable>}
        </Dialog>
      </React.Fragment>
    )
  }
}

ROITableWidget.defaultProps = {
  visualizationToolDialogVisible:false
};
ROITableWidget.propTypes = {
  selectedDataPoint: PropTypes.object.isRequired,
  manualToolConfiguration: PropTypes.object.isRequired,
  manualToolState: PropTypes.object.isRequired,
  roiVisualizationData:PropTypes.object.isRequired,
  updateManualToolSAM: PropTypes.func.isRequired,
  updateManualToolProperty: PropTypes.func.isRequired,
  setSliceNumbersToIJK: PropTypes.func.isRequired,
  visualizationToolDialogVisible:PropTypes.bool.isRequired,
  rawData:PropTypes.object.isRequired
};

const colorBox = (color)=>{
  return {
    width: "20px",
    height: "20px",
    display: "inline-block",
    marginInlineEnd: "5px",
    verticalAlign: "middle",
    backgroundColor: color
  }
};