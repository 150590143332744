import React from 'react'
import PropTypes from "prop-types";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {convertISODateAndTimeToLocaleString} from "../../helpers/dates";
import {getNestedProp} from "../../helpers/expressions";

class ActionsTable extends React.Component {

  idBody(propertyValue){
    return (
      <div title={propertyValue}>
        {propertyValue!=null
          ? propertyValue.substring(0,5) + '...'
          : ""
        }
        {propertyValue!=null &&
        <i className={"fa fa-clipboard"}
           onClick={()=>navigator.clipboard.writeText(propertyValue)}
           title={"Copy to clipboard"}
           style={{color:"#00D2D0"}}
        />}
      </div>
    );
  }



  render() {
    const {subjectEventLogs} = this.props;

    return (
          <DataTable value={subjectEventLogs} rows={15}  paginator={true} sortField={"timestamp"} sortOrder={-1}>
            <Column field={"timestamp"} header={"Date of event"}
                    sortable={true}
                    style={{width:"15em"}}
                    body={(row)=>{
                      return convertISODateAndTimeToLocaleString(row.timestamp)
                    }}/>
            <Column field={"creationDate"} header={"Saved on"}
                    sortable={true}
                    style={{width:"15em"}}
                    body={(row)=>{
                      return convertISODateAndTimeToLocaleString(row.creationDate)
                    }}/>
            <Column header={"Id"} field={"_id"}
                    style={{width:"8em"}}
                    filter={true} filterMatchMode={"contains"}
                    body={(row)=>this.idBody(getNestedProp(['_id'],row))}/>
            <Column field={"event"} header={"Event"}
                    sortable={true}
                    style={{width:"8em"}}
                    filter={true} filterMatchMode={"contains"}
            />
            <Column field={"eventDescription"} header={"Description"}
                    sortable={true}
                    filter={true} filterMatchMode={"contains"}
            />
            <Column field={"submittedVia"} header={"Agent"}
                    sortable={true}
                    style={{width:"10em"}}
                    filter={true} filterMatchMode={"contains"}
            />
          </DataTable>
    )
  }
}
export default ActionsTable



ActionsTable.propTypes ={
  subjectEventLogs:PropTypes.array.isRequired,
  subjectEventLogsState:PropTypes.string,
};

