/**
 * logging data from
 * @param data  vtkImageData
 */
export function loggingDataset(data){
    console.log('=============================Image parameters===================================');
    console.log('Spacing:',data.getSpacing());
    console.log('Origin:',data.getOrigin());
    console.log('Dimension:',data.getDimensions());
    console.log('Data range', data.getPointData().getScalars().getRange());
    console.log('Extent:', data.getExtent());
    console.log('Direction matrix rows:');
    console.log(data.getDirection()[0], data.getDirection()[1], data.getDirection()[2]);
    console.log(data.getDirection()[3], data.getDirection()[4], data.getDirection()[5]);
    console.log(data.getDirection()[6], data.getDirection()[7], data.getDirection()[8]);
    // console.log('Direction matrix cols:');
    // console.log(data.getDirection()[0], data.getDirection()[3], data.getDirection()[6]);
    // console.log(data.getDirection()[1], data.getDirection()[4], data.getDirection()[7]);
    // console.log(data.getDirection()[2], data.getDirection()[5], data.getDirection()[8]);
    console.log('=======================End of Image parameters===================================');
}

/**
 * Use: this.state.renderWindow.getRenderers()[idx]
 * Logging camera state
 * @param renderer
 */
export function loggingCameraSettings(renderer) {
    console.log('Position:', renderer.getActiveCamera().getPosition());
    console.log('Focal Point', renderer.getActiveCamera().getFocalPoint());
    console.log('ViewUp:', renderer.getActiveCamera().getViewUp());
    console.log('Distance:', renderer.getActiveCamera().getDistance());
    console.log('Direction of Projection', renderer.getActiveCamera().getDirectionOfProjection());
    console.log('Clipping Range:', renderer.getActiveCamera().getClippingRange());
}

/**
 * Use: this.state.renderWindow.getRenderers()[idx]
 * Logging camera state
 * @param matrix3by3
 */
export function loggingMatrix3by3(matrix3by3) {
    matrix3by3.forEach((a)=>console.log(a[0],a[1],a[2]));
}