import React from "react";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {Card} from "primereact/components/card/Card";
import {Button} from 'primereact/components/button/Button';
import { withTranslation } from 'react-i18next';
import axios from "axios/index";
import {HOST_URL} from "../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Growl} from "primereact/components/growl/Growl"; // High order translation component

const LIVE_PRESENTER_EXPERIMENT_ID="8f09b60a78cf2eb3a438674fec00f27c";
const MINIWORKFLOW_SET_ID="8f09b60a78cf2eb3a438674fec00ed39";
const MINIWORKFLOW_NAME_IN_SET="mw1";
const EVENT_ID="06bf3451e2d63c3bf86b4398380de347";

/**
 * This is just demo to present Events available in SPINE.
 * TODO: AMP - To be connected with the events and skills API
 * @returns {*}
 */
class EventDiscoverBrighamPage extends React.Component {

  constructor(){
    super();

    // Set the state
    this.state = {
      isUserEnrolled:false,
      dialogCodeVisible: false,
      codeValue:""
    }

    // Bind functions for the buttons
    this.onHide = this.onHide.bind(this);
    this.showDialogEnterCode = this.showDialogEnterCode.bind(this);
    this.enrollUser = this.enrollUser.bind(this);
    this.showError = this.showError.bind(this);
    // this.recreateWorkflow = this.recreateWorkflow.bind(this);
  }

  // Define the functions handling the actions
  onHide() {
    //this.state.dialogCodeVisible = false;
    this.setState({dialogCodeVisible: false});
  }

  showDialogEnterCode() {
    //this.state.dialogCodeVisible = true;
    this.setState({dialogCodeVisible: true});
  }

  showError(error) {
    this.growl.show({severity: 'error', summary: 'Error Message', detail: error});
  }

  enrollUser(code){

    // Get the authentication from the store
    const {auth} = this.props;

    // Enroll the user and update the state
    // POST /event/{uuid}/signup
    axios.defaults.headers.common['Authorization'] = auth.token_bearer;
    axios.post(HOST_URL + '/api/event/' + EVENT_ID + '/signup',{"password":code})
      .then(response => {
        if (response.status !== 200) {
          console.log('AMP - Error subscribing');
          this.showError('Error subscribing')
        } else {
          this.setState({isUserEnrolled: true});
          this.setState({dialogCodeVisible: false});
          console.log('AMP - User subscribed')
        }
      }).catch(error => {
      console.log('AMP - Error event?onlyEnrolledIn=true err:', error);
      this.showError('Error Subscribing.')
    });

  }

  componentDidMount(){

    // Get the authentication from the store
    const {auth} = this.props;

    // Check if the user in enrolled in the event and update state
    axios.defaults.headers.common['Authorization'] = auth.token_bearer;
    axios.get(HOST_URL + '/api/event?filter=onlyEnrolledIn')
      .then(response => {
        if (response.status !== 200) {
          console.log('Error getting event subscriptions')
          this.showError('Error enrolling')
        } else {
          for(let i in response.data){
            let event = response.data[i]
            if(event.uuid &&
               event.uuid === EVENT_ID){
                this.setState({isUserEnrolled: true});
            }
          }
        }
      }).catch(error => {
      console.log('event?onlyEnrolledIn=true err:', error);
    });

  }

  render() {

    //.........................................................................
    // Translation prop
    const { t,recreateTrainingWorkflowInLP } = this.props;



    //.........................................................................
    // Styles
    const cardStyle = {width: "30em", height: "15em", margin: "4.5em", marginTop:"2em", fontSize: "1.2em"};
    const textStyle = {height: "8em", overflowY: "auto"};
    const textStyleButtons = {height: "4em", overflowY: "auto"};
    const headerImage = (name) => <div className={name}/>;

    //.........................................................................
    // Create the button to enroll of try!
    let buttonEnrollOrTry = null;

    if(this.state.isUserEnrolled){
      // TODO: Add the proper link
      buttonEnrollOrTry = <Link to={"/skills/playskill/8f09b60a78cf2eb3a438674fec00fcab"}
                     onClick={()=>recreateTrainingWorkflowInLP(LIVE_PRESENTER_EXPERIMENT_ID, MINIWORKFLOW_SET_ID, MINIWORKFLOW_NAME_IN_SET,EVENT_ID)}>
        <Button
          label={t("dashboard.tables.mySkills.property.contribution.value.tryIt")}
          title={t("dashboard.tables.mySkills.property.contribution.value.tryIt")}
        />
      </Link>
    }
    else{
      buttonEnrollOrTry = <Button
        label={t("enrollButton.label")}
        title={t("enrollButton.title")}
        onClick={this.showDialogEnterCode}
      />
    }
    //.........................................................................
      // Create the button to see the results!

      let buttonResults =
          <Link to={"/eventResults/"+LIVE_PRESENTER_EXPERIMENT_ID}>
              <Button
                  label={t("dashboard.tables.myEvents.property.link.value.see.label")}
                  title={t("dashboard.tables.myEvents.property.link.value.see.label")}
              />
          </Link>
      //.........................................................................
    return (
      <React.Fragment>
        <Growl ref={(el) => this.growl = el} />

        <div style={{
          marginTop: "3em",
          marginLeft: "6em"
        }}>
          <span className="reg-headtitle">
            {t("event")} Discover Brigham 2019
          </span>
        </div>

        <div style={{
          flexDirection: "row",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start"
        }}>

            <Card
              //footer={footer}
              header={headerImage("medullaOblongataSegmentation")}
              title="Medulla Oblongata Segmentation"
              subTitle="Medulla Oblongata Segmentation"
              style={cardStyle}
            >
              <div style={textStyle}>
                Learn how to measure the size of a part of the brain called the Medulla Oblongata!
              </div>
              <div style={textStyleButtons}>
                  {/*{buttonEnrollOrTry}*/}
                  {buttonResults}
              </div>
            </Card>

        </div>
        <Dialog
          header={'Enrollment code'}
          visible={this.state.dialogCodeVisible}
          modal={true}
          onHide={this.onHide}
        >
          Insert the enrollment code: <InputText name={"code"}
                                                 value={this.state.codeValue}
                                                 onChange={(event)=>this.setState({codeValue: event.target.value})}/>
          <Button
            label={t("enrollButton.label")}
            title={t("enrollButton.title")}
            onClick={()=>this.enrollUser(this.state.codeValue)}
          />
        </Dialog>
      </React.Fragment>
    );
  }
};

EventDiscoverBrighamPage.propTypes={
  t:PropTypes.func.isRequired,
  recreateTrainingWorkflowInLP:PropTypes.func.isRequired
};

export default withTranslation()(EventDiscoverBrighamPage);