import {connect} from "react-redux";
import {
  getSingleWorkflow
} from "../../expDesign/action/WorkflowAction";
import ManualToolBuilderMainPage from "../component/ManualToolBuilderMainPage";




const mapStateToProps = state => {
  return {
    messageQueue: state.messaging.msgQueue,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getWorkflow:(id) => {
      dispatch(getSingleWorkflow(id))
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerManualToolBuilderMainPage = containerCreator(ManualToolBuilderMainPage);