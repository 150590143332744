import {connect} from "react-redux";
import {WorkflowEditorLeftPanel} from "../component/WorkflowEditorLeftPanel";



const mapStateToProps = (state,ownProps) => {
  return {

  }
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerWorkflowEditorLeftPanel = containerCreator(WorkflowEditorLeftPanel);