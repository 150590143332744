import React from 'react';
import PropTypes from "prop-types";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import { CountryDropdown } from "react-country-region-selector";
import {Button} from "primereact/components/button/Button";
import {MultiSelect} from "primereact/components/multiselect/MultiSelect";
import {Checkbox} from "primereact/components/checkbox/Checkbox";

const MEASUREMENT="measurement";
/**
 * Panel for filtering results data.
 *
 */
class FilterPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      gender :"",
      countryOfResidence:"", // this is default
      profession:"",
      language:"",
      ageFrom:0,
      ageTo:100,
      selectedCases:[],
      selectedVisits:[],
      applyFilters:true
    };
    this.casesOptions = [];
    this.visitsOptions = [];
    ["filterData","initOptions"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  componentDidMount() {
    const {data} = this.props;
    this.setState({profession: PROFFESION_OPTIONS.map(el => { return el.value})});
    if (data!=null && data.cases!=null) {
      this.initOptions();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {data} = this.props;
    if (prevProps.data!== data && data!=null && data.cases!=null) {
      this.initOptions();
    }
  }

  initOptions(){
    const {data} = this.props;
    this.setState({selectedCases: Object.keys(data.cases)});
    this.casesOptions = Object.keys(data.cases).map(el => {
      return {value: el, label: el}
    });
    if (data.studyLUT!=null){
      this.visitsOptions = Object.values(data.studyLUT)
        .map(el=>el.visitLabel)
        .filter(function(item, pos,self){ return self.indexOf(item) == pos;})
        .map(el=>{return {value: el, label: el}});
      this.setState({selectedVisits: this.visitsOptions.map(el=>el.value)});
    }
  }


  filterData(){
    const {data} = this.props;
    const {gender,countryOfResidence,profession,language,ageFrom,ageTo,selectedCases,selectedVisits, applyFilters} = this.state;

    const filteredData = JSON.parse(JSON.stringify(data)); // the deepest copy ever

    // Filter cases - delete cases if not selected
    if (filteredData.cases!=null)
    Object.keys(filteredData.cases).filter(el=>!selectedCases.includes(el)).forEach(e => delete filteredData.cases[e]);

    //Filter visits -  delete cases if they do not match with selectedVisits according to studyLUT
    if (filteredData.studyLUT!=null)
      Object.keys(filteredData.cases).filter(el=>!selectedVisits.includes(filteredData.studyLUT[el].visitLabel)).forEach(e => delete filteredData.cases[e]);


    // filter contributions by user details -  do not filter if filters are not touched
    if (applyFilters)
    Object.keys(filteredData.cases)
      .forEach((caseInstance,caseIndex) =>
      {
        Object.keys(filteredData.cases[caseInstance][MEASUREMENT]).forEach((measurement,measIdx)=>{
          if (filteredData.cases[caseInstance][MEASUREMENT][measurement].contribution!=null && filteredData.cases[caseInstance][MEASUREMENT][measurement].contribution.length>0){

            filteredData.cases[caseInstance][MEASUREMENT][measurement].contribution = filteredData.cases[caseInstance][MEASUREMENT][measurement].contribution.filter(contribution=>{
                  if (contribution.userInformation!=null){
                    return !(
                         (gender !== "" && gender !== contribution.userInformation.gender)
                      || (countryOfResidence !== "" && countryOfResidence !== contribution.userInformation.countryOfResidence)
                      || (language !== "" && language !== contribution.userInformation.language)
                      || (profession.length !== PROFFESION_OPTIONS.length && !profession.includes(contribution.userInformation.profession))
                      || (ageFrom !== 0 && ageFrom > (new Date().getYear()+1900 - Number(contribution.userInformation.birthYear)))
                      || (ageTo !== 100 && ageTo < (new Date().getYear()+1900 - Number(contribution.userInformation.birthYear)))
                    );
                  }
                  else
                    return false;
              }
            );
          }
        })
      });

    return filteredData;
  }

  render() {
    const {gender,countryOfResidence,profession,language,ageFrom,ageTo,selectedCases,selectedVisits,applyFilters} = this.state;
    const {data, options={}} = this.props;
    return <React.Fragment>
      {options.title? options.title: <h2 style={{fontWeight: 'normal'}}>Filters</h2>}
      {options.casesFilter!=false?<div className="ui-g-12">
        <label htmlFor="caseSelector" className="ui-checkbox-label" style={{marginRight: "1em"}}>{"Cases"}</label>
        <MultiSelect
          id={"caseSelector"}
          options={this.casesOptions}
          value={selectedCases}
          onChange={(e) => this.setState({ selectedCases: e.value })}
          style={{width: '100%', whiteSpace: 'nowrap' }}
          filter={true}
        />
      </div>:null}
      <div className="ui-g-12">
        <label htmlFor="caseSelector" className="ui-checkbox-label" style={{marginRight: "1em"}}>{"Visits"}</label>
        <MultiSelect
          id={"visitSelector"}
          options={this.visitsOptions}
          value={selectedVisits}
          onChange={(e) => this.setState({ selectedVisits: e.value })}
          style={{width: '100%', whiteSpace: 'nowrap' }}
          filter={true}
        />
      </div>
      <div className="ui-g-12">
        <label htmlFor="prof" className="ui-checkbox-label" style={{marginRight: "1em"}}>{"Profession"}</label>
        <MultiSelect
          id={"prof"}
          options={PROFFESION_OPTIONS}
          value={profession}
          onChange={(e) => this.setState({ profession: e.value })}
          style={{width: '100%', whiteSpace: 'nowrap' }}
          filter={true}
        />
      </div>
      <div className="ui-g-12">
        <label htmlFor="countryOfResidence" className="ui-checkbox-label" style={{marginRight: "1em"}}>{"Country"}</label>
        <CountryDropdown
          style={{width: "100%"}}
          value={countryOfResidence}
          onChange={(value) => this.setState({countryOfResidence:value})}
          defaultOptionLabel="All"
        />
      </div>
      <div className="ui-g-12">
        <label htmlFor="gender" className="ui-checkbox-label" style={{marginRight: "1em"}}>{"Gender"}</label>
        <Dropdown id="gender"
                  onChange={(e) => this.setState({gender: e.value})}
                  value={gender}
                  options={GENDER_OPTIONS}
        />
      </div>
      <div className="ui-g-12">
        <label htmlFor="lang" className="ui-checkbox-label" style={{marginRight: "1em"}}>{"Language"}</label>
        <Dropdown id="lang"
                  onChange={(e) => this.setState({language: e.value})}
                  value={language}
                  options={LANG_OPTIONS}
        />
      </div>
      <div className="ui-g-12">
        <label htmlFor="year" className="ui-checkbox-label" style={{marginRight: "1em"}}>{"Age from/to"}</label>
        <input type="number"
               id="year"
               onChange={(e) =>this.setState({ageFrom: Number(e.target.value)}) }
               min={0} step={1} max={100}
               value={ageFrom}
               size={2}
        />
        <input type="number"
               onChange={(e) =>this.setState({ageTo: Number(e.target.value)}) }
               min={0} step={1} max={100}
               value={ageTo}
               size={2}
        />

        <div className="ui-g-12">

        <Checkbox id="applyFilters" checked={applyFilters} onChange={(e)=>this.setState({applyFilters:e.checked})}/>
        <label htmlFor="applyFilters" className="ui-checkbox-label" style={{marginRight: "1em"}}>{"Apply filters"}</label>
        <Button icon={"fa fa-times"} label={"Clear all filters"} onClick={()=>{
          this.setState({
            gender :"",
            countryOfResidence:"",
            profession: PROFFESION_OPTIONS.map(el => { return el.value}),
            language:"",
            selectedCases:data!=null && data.cases!=null?Object.keys(data.cases):[],
            ageFrom:0,
            ageTo:100})
        }}/>
        </div>
      </div>

    </React.Fragment>
  }
}

export default FilterPanel;


FilterPanel.defaultProps = {

};
FilterPanel.propTypes = {
  data : PropTypes.object.isRequired
};

const GENDER_OPTIONS=[
  {label:"All",value:""},
  {label:"Masculin",value:"Masculin"},
  {label:"Féminin",value:"Féminin"},
  {label:"Autre",value:"Autre"},
  {label:"Non-partagé",value:"Non-partagé"}
];

const LANG_OPTIONS=[
  {label:"All",value:""},
  {label:"Français",value:"Français"},
  {label:"English",value:"English"}
];

const PROFFESION_OPTIONS=[
  {label:"Etudiant - Santé",value:"Etudiant - Santé"},
  {label:"Etudiant - Sciences et Technologies",value:"Etudiant - Sciences et Technologies"},
  {label:"Etudiant - Economie et Gestion",value:"Etudiant - Economie et Gestion"},
  {label:"Etudiant - Droit et Sciences Politiques",value:"Etudiant - Droit et Sciences Politiques"},
  {label:"Etudiant - Sciences Humaines et Sociales",value:"Etudiant - Sciences Humaines et Sociales"},
  {label:"Etudiant - Lettres, Les Langues, L’art",value:"Etudiant - Lettres, Les Langues, L’art"},
  {label:"Etudiant - Autre",value:"Etudiant - Autre"},
  {label:"Enseignant/Chercheur",value:"Enseignant/Chercheur"},
  {label:"Personnel de l’université",value:"Personnel de l’université"},
  {label:"Médecin Neurologue",value:"Médecin Neurologue"},
  {label:"Médecin Radiologue",value:"Médecin Radiologue"},
  {label:"Médecin (autre specialité)",value:"Médecin (autre specialité)"},
  {label:"Autre (extérieur a l’université)",value:"Autre (extérieur a l’université)"}
];


const OPERATORS_OPTIONS=[
    {
      label:"All",
      value:""
    },
    {
      "type":"ORDINAL_OPERATOR",
      "label":"<",
      "value": "less than"
    },
    {
      "type":"ORDINAL_OPERATOR",
      "label":"<=",
      "value": "less than or equal to"
    },
    {
      "type":"ORDINAL_OPERATOR",
      "label":"=",
      "value": "equals"
    },
    {
      "type":"ORDINAL_OPERATOR",
      "label":"!=",
      "value": "not equals"
    },
    {
      "type":"ORDINAL_OPERATOR",
      "label":">=",
      "value": "greater than or equal to"
    },
    {
      "type":"ORDINAL_OPERATOR",
      "label":">",
      "value": "greater than"
    }
];