import React from 'react';
import {Link} from "react-router-dom";
import {ContainerManualTool} from "../../visualization/containers/ContainerManualTool";

export class SPM_landing_page extends React.Component {

    constructor(){
        super();
        this.state = {value: '', pval: 0.05};

        this.handleChange = this.handleChange.bind(this);
          this.handleChangePval = this.handleChangePval.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getManualTool = this.getManualTool.bind(this);
        this.getForm = this.getForm.bind(this);
        this.displayMessage = this.displayMessage.bind(this);
    }
    componentWillMount() {
      const {} = this.props;
    }

    componentWillUnmount() {
      const {clearSingleResultStateAc} = this.props;
      clearSingleResultStateAc(); // clear state
    }


    handleChange(event) {
     this.setState({value: event.target.value});
     }

     handleChangePval(event) {
      this.setState({pval: event.target.value});
      }

     handleSubmit(event) {
       event.preventDefault();
       const {getSPMResults, clearSingleResultStateAc} = this.props;
       clearSingleResultStateAc(); // clear state
       getSPMResults(this.state.value, this.state.pval);
     }

    getManualTool(workload) {
      return (<ContainerManualTool
        predefinedToolConfiguration={workload}
      />);

    }

    getForm() {
      console.log("getting form")
      return (
        <form onSubmit={this.handleSubmit}>
          <label>
            SPM experiment UID:
            <input type="text" value={this.state.value} onChange={this.handleChange} />
            Corrected p-value :
            <input type="number" value={this.state.pval} onChange={this.handleChangePval} />
          </label>
          <input type="submit" value="Send" />
        </form>);
    }

    displayRequesting() {
      return (
          <label>
            Loading experiment results...
          </label>);
    }

    displayError() {
      const {error} = this.props;
      return (
          <label>
            {JSON.stringify(error)}
          </label>);
    }


    displayMessage() {

      return (<label>Thresholded maps are not yet ready for this threshold value, please try again in few seconds.</label>);
    }



    render() {

      const {spm,status} = this.props;

    return(
        <React.Fragment>

          {this.getForm()}

          {!spm.hasOwnProperty("workload") && status == "requesting" && this.displayRequesting()}

          {!spm.hasOwnProperty("workload") && status == "error" && this.displayError()}

          {spm.hasOwnProperty("workload") &&
          spm.workload.hasOwnProperty("miniWorkflow") &&
          spm.workload.miniWorkflow.hasOwnProperty("currentMaterializedTask") &&
          spm.workload.miniWorkflow.currentMaterializedTask.hasOwnProperty("inputs") &&
          !spm.workload.miniWorkflow.currentMaterializedTask.inputs.hasOwnProperty("overlay_2") &&
          this.displayMessage()
          }

          {spm.hasOwnProperty("workload") && this.getManualTool(spm.workload)}





        </React.Fragment>

    )}
};
