import React from 'react'
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";
import {ListBox} from 'primereact/components/listbox/ListBox';
import {MultiSelect} from 'primereact/components/multiselect/MultiSelect';
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {Slider} from 'primereact/components/slider/Slider';
import {Spinner} from "primereact/components/spinner/Spinner";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {withTranslation} from 'react-i18next';
import { TabPanel, TabView } from 'primereact/components/tabview/TabView';
import { InputText } from 'primereact/components/inputtext/InputText';
import { map } from 'lodash';
import {RadioButton} from 'primereact/components/radiobutton/RadioButton';
import { Accordion, AccordionTab } from 'primereact/components/accordion/Accordion';

/**
 * Component for displaying agreement experiment results grouping options.
 */
export class AgreementModelsSidebar extends React.Component {

    constructor() {
        super();
        this.state = {}; //initial state is in action initializeBlandAltmanPlot
        ["onApplyBlandAltman", "onApplyMatching", "onCancel", "onShow", "onAddGroup"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    componentDidMount() {
        const {plotSettings} = this.props;
        this.setState(plotSettings);
    }

    onApplyBlandAltman() {
        const {updatePlotSettings} = this.props;
        const {minNumberOfGroups, maxNumberOfGroups, groups, selectedCases} = this.state;
        const numberOfGroups = groups.length;
        const emptyGroup = groups.some((group)=>{return group.contributions.length === 0});
        if(minNumberOfGroups <= numberOfGroups && maxNumberOfGroups >= numberOfGroups && !emptyGroup && selectedCases.length > 0){
            updatePlotSettings(this.state);
            this.props.onHide();
        } else {
            alert(`You need to define minimum ${minNumberOfGroups} and maximum ${maxNumberOfGroups} group(s) (with their respective raters) and at least select one case.`);
        }
    }

    onApplyMatching() {
        const {updatePlotSettings} = this.props;
        updatePlotSettings(this.state);
        this.props.onHide();
    }

    onCancel() {
        const {plotSettings} = this.props;
        this.setState(plotSettings);
        this.props.onHide();
    }

    onShow() {
        const {plotSettings} = this.props;
        this.setState(plotSettings);
    }

    onAddGroup(){
        const groupNumber = this.state.groups.length;
        const randomColor = "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});
        this.setState({
                groups: [
                    ...this.state.groups,
                    {  name: `group${groupNumber}`,
                        color: groupNumber >= 1 ? randomColor: this.state.initialGroupColor,
                        contributions: []
                    }
                ]});
    }

    renderGroups(){
        const {groups = [], contributors = []} = this.state;
        return (
                groups.map((item, key) => 
                    <div key={key} className="ui-g-12">
                        <div style={{maxWidth: '100%', whiteSpace: 'nowrap' }}>
                            <ColorPicker id={`color-${item.name}`} value={this.state.groups[key].color}
                                                onChange={(e) => {
                                                    let groups = [...this.state.groups];
                                                    groups[key].color = `#${e.value}`;
                                                    this.setState({groups});
                                                }}/>
                            <MultiSelect
                            options={contributors}
                            value={this.state.groups[key].contributions}
                            onChange={(e) => {
                                let groups = [...this.state.groups];
                                groups[key].contributions = e.value;
                                this.setState({groups});
                            }
                            }
                            style={{width: '100%', whiteSpace: 'nowrap' }}
                            filter={true}
                            />
                        </div>
                        <div>
                            <Button label="Remove Group" onClick={()=>
                                {
                                    let groups = [...this.state.groups];
                                    groups.splice(key, 1);
                                    this.setState({groups});
                                }}/>
                        </div>
                    </div>)
        );
    }

    renderBlandAltman(){
        const {t} = this.props;
        return (
            <TabView style={{}}>
                <TabPanel header="Inter-rater" contentStyle={{height: '100%', padding: '0.5em'}}>
                        {/*
                    <div className="ui-g-12">
                        //TODO: Mesurement selection
                        <h2 style={{fontWeight: 'normal'}}> Measurement </h2>
                        <div>
                            <label style={{marginRight: "1em"}}>X</label>
                            <Dropdown value={measurementValue} options={optionsForMeasurements}
                                    style={{width: "8em"}} onChange={(e) => {
                                this.setState({measurementValue: e.value})
                            }}/>
                    </div>
                        </div> */}
                    <div className="ui-g-13" style={{height: '25em'}}>
                        <h2 style={{fontWeight: 'normal'}}>Cases</h2>
                        <div className="ui-g-12" style={{height: '10%'}}>
                            <Button
                                label={t("general.button.selectall.label")}
                                onClick={()=>this.setState({selectedCases: map(this.state.cases, 'value')})}/>
                            <Button
                                label={t("general.button.deselectall.label")}
                                onClick={()=>this.setState({selectedCases: []}) }/>
                        </div>
                        <ListBox
                            options={this.state.cases}
                            value={this.state.selectedCases}
                            onChange={(e) => {
                                this.setState({selectedCases: e.value});
                            }}
                            filter={true}
                            multiple={true}
                            style={{height: '70%', overflowY: 'scroll', width: 'auto'}}
                        />
                    </div>
                    <div className="ui-g-12">
                        <h2 style={{fontWeight: 'normal'}}>Groups</h2>
                        {this.state.possibleRatersAndCasesForIntra!=null && this.state.possibleRatersAndCasesForIntra.length > 0 &&
                         <div className="ui-g-12">
                             <h3 style={{fontWeight: 'normal'}}>Intra-rater data available</h3>
                             <div className="ui-g-12" style={{marginRight: "1em"}}>
                                <RadioButton
                                    inputId="intraRaterRb1"
                                    name="intra-rater"
                                    value="ALL_DATA"
                                    onChange={(e) => {
                                        this.setState({intraRaterHandling: e.value})
                                    }}
                                    checked={this.state.intraRaterHandling === 'ALL_DATA'}>
                                </RadioButton>
                                <label htmlFor="intraRaterRb1"
                                    className="ui-radiobutton-label">
                                    {"Use all the data available for the raters per case"}
                                </label>
                             </div>
                             <div className="ui-g-12" style={{marginRight: "1em"}}>
                                <RadioButton
                                    inputId="intraRaterRb2"
                                    name="intra-rater"
                                    value="FIRST_MATCH_DATA"
                                    onChange={(e) => {
                                        this.setState({intraRaterHandling: e.value})
                                    }}
                                    checked={this.state.intraRaterHandling === 'FIRST_MATCH_DATA'}>
                                </RadioButton>
                                <label htmlFor="intraRaterRb2"
                                    className="ui-radiobutton-label">
                                    {"Use raters first attempt found per case"}
                                </label>
                             </div>
                          </div>}
                        <div style={{maxHeight: '70%'}}>
                            {this.renderGroups()}
                        </div>
                        <Button label="Add Group" onClick={this.onAddGroup}/>
                    </div>
                    <div className="ui-g-12" style={{height: '15%'}}>
                        <h2 style={{fontWeight: 'normal'}}>Other parameters</h2>
                        <Checkbox
                            inputId="difference" style={{marginRight: "1em"}}
                            onChange={() => {
                                this.setState({percentageDifference: !this.state.percentageDifference})
                            }}
                            checked={this.state.percentageDifference}>
                        </Checkbox>
                        <label htmlFor="difference"
                            className="ui-checkbox-label">
                            {"Difference as a %"}
                        </label>
                    </div>
                    <div className="ui-g-12" style={{height: '5%'}}>
                            <Button label={t("general.button.apply.label")} onClick={this.onApplyBlandAltman}/>
                            <Button label={t("general.button.cancel.label")} onClick={() => this.props.onHide()}/>
                    </div>
                </TabPanel>
                    {/* //TODO: Add logic to handle intra-rater selection sidebar */}
                    {/* <TabPanel header="Intra-rater" disabled={this.state.possibleRatersAndCasesForIntra==null || this.state.possibleRatersAndCasesForIntra.length === 0}>
                            <div className="ui-g-12" style={{maxWidth: '100%', whiteSpace: 'nowrap' }}>
                                <Dropdown
                                    placeholder="Select the case-rater combinati}on"
                                    value={this.state.intraCaseRaterSelected}
                                    options={this.state.possibleRatersAndCasesForIntra}
                                    onChange={(e) => {
                                        this.setState({intraCaseRaterSelected: e.value})
                                    }}
                                    filter={true}
                                    filterBy='label'
                                    style={{width: '100%'}}
                                    panelStyle={{width: '100%'}}/>
                            </div>
                    </TabPanel> */}
            </TabView>
        );
    }

    renderMatchingAlgorithm(){
        const {t} = this.props;
        return (
            <React.Fragment>
                <div className="ui-g-12">
                    <h2 style={{fontWeight: 'normal'}}>Threshold Distance (mm)</h2>
                    <InputText
                        inputId="thresholdDistance"
                        type="number"
                        keyFilter="pnum"
                        min="0"
                        value={this.state.thresholdDistance}
                        onChange={(e) => {
                        const parsedThresholdDistance = parseFloat(e.target.value);
                        this.setState({thresholdDistance: parsedThresholdDistance})
                    }}/>
                </div>
                <div className="ui-g-12" style={{height: '5%'}}>
                    <Button label={t("general.button.apply.label")} onClick={this.onApplyMatching}/>
                    <Button label={t("general.button.cancel.label")} onClick={() => this.props.onHide()}/>
                </div>

            </React.Fragment>
        );
    }

    render() {
        const {visible, position, onHide, t} = this.props;

        // TODO: Change hardcoded labels to use internationalization
        return (
            <Sidebar
                position={position}
                visible={visible}
                onHide={() => onHide()}
                onShow={() => this.onShow()}
                style={{overflow: 'auto'}}
            >
                {/* // TODO: Use tabs to put differenciate elements to be rendered */}
                <h1 style={{fontWeight: 'normal'}}>
                    <i className="fa fa-cog" style={{color: "green", margin: "0.5em 0.25em 0px 0px"}}/>
                    {t("agreement.sidebar.configuration.header")}
                </h1>
                <TabView onTabChange={(e)=>{this.setState({mode: e.index})}}>
                    <TabPanel header="Bland-altman" contentStyle={{height: '100%', padding: '0.5em'}}>
                        {this.renderBlandAltman()}
                    </TabPanel>
                    <TabPanel header="Matching algorithm">
                        {this.renderMatchingAlgorithm()}
                    </TabPanel>
                </TabView>
                <h1 style={{fontWeight: 'normal'}}>
                    <i className="fa fa-envelope" style={{color: "green", margin: "0.5em 0.25em 0px 0px"}}/>
                    {t("agreement.sidebar.messages.header")}
                </h1>
                <Accordion>
                    <AccordionTab header="Data">
                        {this.state.emptyCases != null && this.state.emptyCases.length>0
                        ? 'Warning - The following cases don\'t include any ROI and will not be shown: \n\n'
                            + this.state.emptyCases.toString().replace(/,/g, '\n\n')
                        : 'All the data was loaded'}
                    </AccordionTab>
                </Accordion>
            </Sidebar>
        )
    }
}

export default withTranslation()(AgreementModelsSidebar);

AgreementModelsSidebar.defaultProps = {};
AgreementModelsSidebar.propTypes = {
    visible: PropTypes.bool.isRequired,
    position: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    plotSettings: PropTypes.object.isRequired,
    clearInteractivePlotState: PropTypes.func.isRequired,
    updatePlotSettings: PropTypes.func.isRequired,
    t: PropTypes.func
};
