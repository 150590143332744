import {withRouter} from "react-router";
import {connect} from "react-redux";

import SelectedCasePanel from "../component/SelectedCasePanel";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    selectedDataPoint: state.sam.selectedDataPoint
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
  }
};

export const ContainerSelectedCasePanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SelectedCasePanel));