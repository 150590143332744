import {
  CLEAR_FORMS_STATE,  UPDATE_FORMS_STATE,  UPDATE_SINGLE_FORM_PROPERTY, UPDATE_SINGLE_FORM_STATE,
} from "../action/actionType";


export const formsReducer = (state = {
  formsState:{},
}, action) => {
  switch (action.type) {
    case CLEAR_FORMS_STATE:
      return Object.assign({}, state, {
        formsState:{}
      });
    case UPDATE_SINGLE_FORM_STATE:{
      let temp= Object.assign({},state.formsState);
      temp[action.id]=Object.assign({},action.form);
      return Object.assign({}, state, {
        formsState:temp
      });
    }
    case UPDATE_FORMS_STATE:{
      let result = {};
      Object.keys(action.forms).forEach((el)=>
        result[el] = Object.assign({},action.forms[el]));
      return Object.assign({}, state, {
        formsState:result
      });
    }
    case UPDATE_SINGLE_FORM_PROPERTY:{
      let temp= Object.assign({},state.formsState);
      let tempRow = Object.assign({},temp[action.id]);
      tempRow[action.property]=action.value;
      temp[action.id]=tempRow;
      return Object.assign({}, state, {
        formsState:temp
      });
    }
    default:
      return state;
  }
};

