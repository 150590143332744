import React from "react";
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {RadioButton} from "primereact/components/radiobutton/RadioButton";
import {Button} from "primereact/components/button/Button";
import DownloadLink from "react-download-link";
import {getLongFormatData, getWideFormatData} from "../action/reportAction";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Checkbox} from "primereact/components/checkbox/Checkbox";


/**
 * Component used for SELECTION of Layout.
 */

class ExportCSVDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLongFormat: false,
      separator: ",",
      treatment: false
    };
    ['exportCSV'].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }


  exportCSV() {
    const {fullState} = this.props;
    const {isLongFormat, separator, treatment} = this.state;

    if (isLongFormat) {
      return getLongFormatData(fullState, separator);
    } else {
      return getWideFormatData(fullState, separator, treatment);
    }
  }

  render() {

    const {exportDialogVisible, onHide} = this.props;
    const {isLongFormat, treatment} = this.state;

    return (
      <React.Fragment>
        <Dialog header={"Choose data sheet format"}
                appendTo={document.body}
                footer={
                  <div style={{textAlign: "center"}}>
                    <DownloadLink
                      className={"ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only"}
                      style={{color: "white"}}
                      label={"Download"}
                      filename={`SAM_${isLongFormat ? "Long" : "Wide"}_${new Date().toISOString()}.csv`}
                      tagName={"button"}
                      exportFile={this.exportCSV}/>
                    <Button label={"Close"} onClick={onHide}/>
                  </div>
                }
                visible={exportDialogVisible}
                onHide={onHide}>
          <div style={{display: "flex", justifyContent: "space-evenly"}}>
            <label title={"Data format"}>Format</label>
            <div>
              <RadioButton
                onChange={(event) => this.setState({isLongFormat: event.checked})}
                checked={isLongFormat}
              />
              <label title={"A long format contains values that do repeat in the first column."}>Long</label>
            </div>
            <div>
              <RadioButton
                onChange={(event) => this.setState({isLongFormat: !event.checked})}
                checked={!isLongFormat}/>
              <label title={"A wide format contains values that do not repeat in the first column."}>Wide</label>
            </div>
          </div>
          <div style={{display: "flex", justifyContent: "space-evenly", margin: "1em"}}>
            <label title={"Special character  used by CSV to separate columns in sheet"} style={{marginRight: "1em"}}>Separator</label>
            <RadioButton
              onChange={(event) => {
                if (event.checked)
                  this.setState({separator: ","})
              }}
              checked={this.state.separator === ","}
            />
            <label>Comma</label>
            <RadioButton
              onChange={(event) => {
                if (event.checked)
                  this.setState({separator: ";"})
              }}
              checked={this.state.separator === ";"}/>
            <label>Semicolon</label>
            <RadioButton
              onChange={(event) => {
                if (event.checked)
                  this.setState({separator: "\t"})
              }}
              checked={this.state.separator === "\t"}/>
            <label>Tabulation</label>
          </div>
          <div>
            <h5>Advanced settings</h5>
            <Checkbox checked={treatment} onChange={(event)=>this.setState({treatment:event.checked})} />
            <label>Mark treatment based on laterality [Wide only]</label>
            <i className={"fa fa-question-circle-o"}
               title={"This requires Treatment_side measurement in data!!!"}
               style={{fontSize: "1.5em", marginLeft: "0.5em"}}
            />
          </div>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default ExportCSVDialog;

ExportCSVDialog.propTypes = {
  fullState: PropTypes.object.isRequired,  // data to be displayed
  exportDialogVisible: PropTypes.bool.isRequired, // flag for dialog to be displayed
  onHide: PropTypes.func.isRequired // callback to change flag to false
};
