import React from 'react';
// import {Link} from "react-router-dom";
import {ContainerSingleResultReview} from "./container/ContainerSingleResultReview"

export default class SingleResultReview extends React.Component{
    render() {
        console.log("Single result review ... render");
        return (
            <ContainerSingleResultReview/>
        )
    }
}