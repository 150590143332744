import React from "react";
import {Button} from "primereact/components/button/Button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {Dialog} from "primereact/components/dialog/Dialog";
import {PROJECTION_TOOL} from "../../../../Constants";
import {checkIfAnyPropertyIsVisible} from "./WidgetToolbar";


/**
 * Stateful component with template for Projection Tool.
 * Responsible for annotation projections on neughboring slides.
 * @returns {*}
 */
export class ProjectionToolbar extends React.Component {

    constructor() {
        super();
        this.state = {
            dialogVisible: false
        };
    }

    render() {
        const {toolProperties, changeToolProperties, toolConfiguration} = this.props;
        const control = (property, type) => toolConfiguration != null
            && toolConfiguration.properties != null
            && toolConfiguration.properties[property] != null
            && toolConfiguration.properties[property][type] != null
            && toolConfiguration.properties[property][type];

        const checkIfAnyPropertyVisible = checkIfAnyPropertyIsVisible(toolConfiguration.properties);

        return (
            <React.Fragment>
                {checkIfAnyPropertyVisible &&
                <Toolbar style={{display: "inline-block"}}>
                    {control('range', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>Neighborhood:</label>
                        <Spinner value={toolProperties['range']}
                                 min={1}
                                 max={10000}
                                 step={1}
                                 size={3}
                                 onChange={(e) => changeToolProperties(PROJECTION_TOOL, 'range', e.value)}
                        />
                    </React.Fragment>
                    }
                </Toolbar>}
            </React.Fragment>
        );
    }

};

ProjectionToolbar.defaultProps = {
    toolProperties: {range: 1},
};
ProjectionToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    toolConfiguration: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func
};
