import React from "react";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import {MiniWorkflowStepMonitor} from "./MiniWorkflowStepMonitor";
import {CircularProgressComponent} from "../../../root/CircularProgressComponent";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {REQUEST_STATUS_SUCCESS, VISUALIZATION_TOOL} from "../../../../Constants";
import {ContainerManualTool} from "../../containers/ContainerManualTool";


/**Replacement for manual tool.
 * To use when a given step of miniworkflow in live presenter has been already performed.
 * It should be used only in LP Mode!!!
 * @version 1.0
 */
class ResultsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showWindowPortal: false,
      linkViewers: true,
      leftButtonMode: LEFT_BUTTON_MODE.NONE
    };
    ["keyListener"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }


  componentDidMount() {
    const {updateViewersState, livePresenterExperimentId, initializeResultsPage, skillResult,updateViewerProperty,images,viewersState} = this.props;
    initializeResultsPage(livePresenterExperimentId);
    document.body.addEventListener("keydown", this.keyListener, false);
  }

  componentDidUpdate(prevProps, prevState, ss) {
    const { images, viewersState, skillResult, updateViewerProperty} = this.props;
    if (prevProps.skillResult !== skillResult  || (!prevProps.viewersState.hasOwnProperty('middle')
      && viewersState.hasOwnProperty("middle"))) {
      if (viewersState!=null
        && viewersState["middle"]!=null
        && viewersState["middle"].imageId!=null
        && images[viewersState["middle"].imageId].state===REQUEST_STATUS_SUCCESS) {
        this.setState({});
        const image = images[viewersState["middle"].imageId];
        const slMode = image['properties']['slicingModes'][0]; //SAGITTAL only
        const middleSlice = Math.abs(Math.floor((image['data'].getExtent()[slMode * 2 + 1]
          - image['data'].getExtent()[slMode * 2]) / 2));
        Object.keys(viewersState).forEach((el) => {
          updateViewerProperty(el, 'sliceNumber', middleSlice);
        });
      }
    }
  }

  /**
   * Clear state of viewers and widgets.
   * Removes key listener.
   */
  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.keyListener, false);
  }


  keyListener(event) {
    if (event.code === "PageDown") {

      this.props.onPrevious();
      return;
    }
    if (event.code === "PageUp") {

      this.props.onNext();
    }

  }


  render() {

    const {
      manualTaskState,  workload, recreateTrainingWorkflowInLP, livePresenterExperimentId, miniWorkflowNameInSet,  skillResult,
      miniWorkflowSetId, goToPreviousSkillStep
    } = this.props;


    let steps = (workload != null && workload.miniWorkflow != null && workload.miniWorkflow['miniWorkflowPath'] != null)
      ? workload.miniWorkflow['miniWorkflowPath'].map(el => {
        return {name: el.stepName}
      })
      : null;

    let currentStep = (workload != null && workload.miniWorkflow != null && workload.miniWorkflow['currentStep'] != null)
      ? (workload.miniWorkflow.status === "DONE") ? -1 : workload.miniWorkflow['currentStep']
      : null;

    let aggregatedResult = 0;
    if (skillResult != null && skillResult.resultState === REQUEST_STATUS_SUCCESS) {
      aggregatedResult = skillResult.result.scoring.reduce(function (previousValue, currentValue) {
        return previousValue.value + currentValue.value;
      });
    }

    let partialResults = <i className="fa fa-spinner fa-spin"
                            style={{fontSize: "40px", verticalAlign: "middle", margin: "auto"}}/>;
    if (skillResult != null && skillResult.resultState === REQUEST_STATUS_SUCCESS) {
      partialResults = skillResult.result.scoring.map((el) => <div className="resultTableRow">
        <div className="resultTableName"> {el.name}</div>
        <div className="resultTableDots"/>
        <div className="resultTableScore"> {Math.round(el.value)}/{el.max}</div>
      </div>)

    }

    return (
      <React.Fragment>
        <div style={{display: "block", position: "fixed", top: "10px", left: "auto", zIndex: "1000"}}>
          {manualTaskState != null && steps != null && currentStep != null &&
          <MiniWorkflowStepMonitor
            steps={steps}
            currentStep={-1}
            status={"FINISHED"}/>
          }
        </div>
        <div>
          <div className="viewerContainerFull">
            <div className="viewerContainerMainPanel"
                 style={{height: "80vh", flexDirection: "column", textAlign: "center", justifyContent: "space-evenly"}}>
              <h1 style={{fontSize: "x-large"}}> Thank you for your contribution!</h1>

              {skillResult != null && skillResult.resultState === REQUEST_STATUS_SUCCESS &&
              <CircularProgressComponent title="Your score" value={Math.round(aggregatedResult)}/>}
              <br/>
              <div className="resultTableMain">
                {partialResults}
              </div>
              <br/>
                <div style={{cursor:"pointer",fontSize: "x-large", textDecoration: "underline",width:"max-content",zIndex:"1000",margin:"auto"}}
                     onClick={() => {
                       recreateTrainingWorkflowInLP(livePresenterExperimentId, miniWorkflowSetId, miniWorkflowNameInSet);
                       goToPreviousSkillStep();
                     }}>
                  Try again
                </div>
            </div>
            <div className="viewerContainerMainPanel" style={{height: "80vh"}}>
              {skillResult.result.originalImageId !=null && skillResult.result.labelMapImageId !=null &&
              <ContainerManualTool
                predefinedToolConfiguration={resultConf(skillResult.result.originalImageId, skillResult.result.labelMapImageId)}
              />}
            </div>
          </div>
          <div/>
        </div>
      </React.Fragment>
    )
  }

}

export default withRouter(ResultsPage);

ResultsPage.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getAnnotations: PropTypes.func.isRequired,
  updateAnnotationData: PropTypes.func.isRequired,
  rmCallback: PropTypes.func.isRequired,
  saveOverlayData: PropTypes.func,
  clearAll: PropTypes.func.isRequired,
  addAnnotation: PropTypes.func.isRequired,
  setActiveAnnotation: PropTypes.func.isRequired,
  clearAnnotatorState: PropTypes.func.isRequired,
  clearViewersState: PropTypes.func.isRequired,
  initializeTool: PropTypes.func.isRequired,
  viewersState: PropTypes.array.isRequired,  //array containing state of all viewers
  updateViewerProperty: PropTypes.func.isRequired,
  updateViewersState: PropTypes.func.isRequired,
  updateViewer: PropTypes.func,
  goToPreviousStep: PropTypes.func,
  imageOptions: PropTypes.array.isRequired,
  changeImageType: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  overlays: PropTypes.array.isRequired,
  manualTaskState: PropTypes.string.isRequired,
  workload: PropTypes.object.isRequired,
  updateManualTask: PropTypes.func.isRequired,
  getAnotherCase: PropTypes.func.isRequired,
  handleKeySlicing: PropTypes.func,
  clearManualToolState: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  miniWorkflowNameInSet: PropTypes.string.isRequired,
  miniWorkflowSetId: PropTypes.string.isRequired,
  livePresenterExperimentId: PropTypes.string.isRequired,
  initializeResultsPage: PropTypes.func.isRequired,
  skillResult: PropTypes.object.isRequired,
  goToPreviousSkillStep: PropTypes.func.isRequired

};


let toolConf = {
  "id":
    "1dd174e57260e384c4f45e9c490004a9",
  "name":
    "Visualization tool (Check)",
  "description":
    "This tool is used to visualize a segmentation and check it.",
  "version":
    "0.0.1",
  "privacy":
    "PUBLIC",
  "type":
  VISUALIZATION_TOOL,
  "inputs":
    {
      "inputImage_key":
        {
          "name":
            "Input image",
          "description":
            "Input image",
          "isList":
            false,
          "type":
            "imageEntityInOut",
          "imageEntityInOut_Type":
            "ANATOMICAL",
          "imageEntityInOut_FileFormat":
            "nii.gz",
          "required":
            true
        }
      ,
      "segmentation_key":
        {
          "name":
            "Segmentation",
          "description":
            "Segmentation",
          "isList":
            false,
          "type":
            "roiInOut",
          "typeROI":
            "EXPLICIT",
          "required":
            true
        }
      ,
      "inputLutDescription_key1":
        {
          "name":
            "Input description of LUT data",
          "description":
            "Input LUT data for displaying options.",
          "isList":
            false,
          "type":
            "lookUpTableDescription",
          "required":
            true
        }
      ,
      "inputLut_key1":
        {
          "name":
            "Input LUT data",
          "description":
            "Input LUT data for overlay displaying.",
          "isList":
            false,
          "type":
            "lookUpTable",
          "required":
            true
        }
    }
  ,

  "configuration":
    {
      "luts":
        {
          "lut_key1":
            {
              "fromInputs":
                true,
              "lutInputKey":
                "inputLut_key1"
            }
        }
      ,
      "lutDescriptions":
        {
          "lutDescription_key1":
            {
              "fromInputs":
                true,
              "lutDescriptionInputKey":
                "inputLutDescription_key1"
            }
        }
      ,
      "rois":
        {
          "overlays":
            {
              "overlay_key_1":
                {
                  "fromInputs":
                    true,
                  "imageInputKey":
                    "segmentation_key",
                  "lutKey":
                    "lut_key1",
                  "lutDescriptionKey":
                    "lutDescription_key1"
                }
            }
        }
      ,
      "scenes":
        {
          "sceneKey1":
            {
              "primaryImageInputKey":
                "inputImage_key",
              "rois":
                {
                  "overlays":
                    ["overlay_key_1"]
                }
            }
        }
      ,
      "viewers":
        {
          "layout":
            {
              "type":
                "SINGLE",
              "layoutOrder":
                {
                  "middle":
                    "0"
                }
            }
          ,
          "renderWindows":
            {
              "0":
                {
                  "name":
                    "3D viewer",
                  "type":
                    "3D",
                  "displayControls":
                    {
                      "smoothing":
                        {
                          "controlVisible":
                            false,
                          "controlEnabled":
                            false,
                          "defaultValue":
                            true
                        }
                      ,
                      "sequence":
                        {
                          "controlVisible":
                            false,
                          "controlEnabled":
                            false
                        }
                    }
                  ,
                  "displayScenes":
                    {
                      "possibleScenesToDisplay":
                        [
                          "sceneKey1"
                        ],
                      "hasDefaultSceneToDisplay":
                        true,
                      "defaultSceneToDisplay":
                        "sceneKey1"
                    },
                  "displayImageSlices": {
                    "0": {
                      "fromState": false,
                      "initialState": {
                        "orientationAndSliceNumber": {
                          "orientation": "SAGITTAL",
                          "strategy": "DYNAMIC",
                          "dynamicSliceValue": "LABELMAP_COG",
                          "labelMapValue": 1,
                          "layerIndex": 0
                        },
                        "initialWindowLevel": {
                          "strategy": "DYNAMIC",
                          "dynamicStrategy": "FULLWINDOW_MIDDLELEVEL"
                        }
                      }
                    }
                  }
                }
            }
        }
      ,
      "widgets":
        {

        }
    }
};

let resultConf = (inputImage_key, segmentation_key) => {
  return {
    "miniWorkflow": {
      "currentTool": toolConf,
      "currentMaterializedTask": {
        "inputs": {
          "inputImage_key": {
            "value": inputImage_key
          },
          "segmentation_key": {
            "value": segmentation_key,
            "format": "nii.gz"
          },
          "inputLut_key1": {
            "value": "c63c3c687bfeab4782a90290e1000260"
          },
          "inputLutDescription_key1": {
            "value": "c63c3c687bfeab4782a90290e1003c40"
          }
        }
      }
    }
  }
};
