import {connect} from "react-redux";
import RegionPage from "../RegionPage";


const mapStateToProps = (state, ownProps) => {
    return {
        auth : state.auth
    }
};

/**
 *
 * @param dispatch
 * @param ownProps - call params functions
 * @returns {{getWorkflows: function()}}
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
};


const ContainerRegionPage= connect( mapStateToProps, mapDispatchToProps)(RegionPage);
export default ContainerRegionPage;