import {connect} from "react-redux";
import ResultsPageDirectedGraph from "../component/ResultsPageDirectedGraph";
import {getConnectomeCases, getLUTFromConnectome} from "../action/ConnectomeAction";


const mapStateToProps = state => {
  return {
    selectedCase: state.visu.connectomes.selectedCase,
    connectomes: state.visu.connectomes.connectomes,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getConnectomeCases: (experimentId)=>{
     dispatch(getConnectomeCases(experimentId))
    },
    getLUTFromConnectome:()=>{
      dispatch(getLUTFromConnectome())
    }
  }
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerResultsPageDirectedGraph= containerCreator(ResultsPageDirectedGraph);