// Import (from) libraries
import React from 'react'
import 'font-awesome/css/font-awesome.css';
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'
import YouTube from 'react-youtube'
// Import components/containers/classes
import LayoutTopbar from './LayoutTopbar.js'
import ContainerRegister from '../containers/ContainerRegister.js'
import Footer from "../../root/Footer"
import {ContainerCurrentProjectsList} from "../../project/containers/ContainerProjectList"
import {HowTab} from "./HowTab"
import {WhoTab} from "./WhoTab"
import {WhyTab} from "./WhyTab"
import { withTranslation } from 'react-i18next';
/**
 * Landing page of application.
 * It all starts here...
 */
class EntryPagePanel extends React.Component {

    constructor() {
        super();
        this.state = {
            activeTab: 0
        };
        this.handleTab = this.handleTab.bind(this);
    }

    handleTab(page, e) {
        this.setState({activeTab: page});
    }


    render() {
        const {loginStatus,t} = this.props;
        const playerOpts = {
            host:'https://www.youtube-nocookie.com',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                rel: 0,
                autoplay: 0,
                cc_load_policy:1,
                cc_lang_pref:'fr'
            }
        };
        const activeTab = this.state.activeTab;
        let slickTabStyle = function (index) {
            if (activeTab === index)
                return {
                    fontWeight: 'bold',
                    width: '25%',
                    padding: '5px 5px 1px 10px',
                    borderTop: '1px solid #707070',
                    borderBottom: 'none',
                    borderRight: '1px solid #707070'
                };
            else
                return {
                    width: '25%',
                    padding: '5px 5px 1px 10px',
                    borderTop: '1px solid #707070',
                    borderBottom: '1px solid #707070',
                    borderRight: '1px solid #707070'
                };
        };


        console.log("EntryPagePanel:: this.propsAA:", this.props);



        return (
                <div id="entry_page_main">
                        <div id="entry_slider" >
                            <div style={{
                                width: '640px', height: '80%', margin: 'auto', borderLeft: '1px solid #707070',
                                borderBottom: '1px solid #707070', borderRight: '1px solid #707070'
                            }}>
                                <div className="reg-headtitle" style={{display: 'flex', marginTop: '10px'}}>
                                    <span onClick={this.handleTab.bind(this, 0)} style={slickTabStyle(0)}> {t("landingPage.news.label")} </span>
                                    <span onClick={this.handleTab.bind(this, 1)} style={slickTabStyle(1)}> {t("landingPage.why.label")} </span>
                                    <span onClick={this.handleTab.bind(this, 2)} style={slickTabStyle(2)}> {t("landingPage.how.label")} </span>
                                    <span onClick={this.handleTab.bind(this, 3)} style={slickTabStyle(3)}> {t("landingPage.who.label")} </span>
                                </div>
                                <div style={{padding: '10px', width: 'calc(100% - 20px)', height: '325px'}}>
                                    {this.state.activeTab === 0 && <ContainerCurrentProjectsList/>}
                                    {this.state.activeTab === 1 && <WhyTab/>}
                                    {this.state.activeTab === 2 && <HowTab/>}
                                    {this.state.activeTab === 3 && <WhoTab/>}
                                </div>
                            </div>
                        </div>
                        <div id="entry_video">
                            <div >
                                <div className="reg-headtitle"
                                     style={{textAlign: 'left',  width: '640px',marginBottom: '10px'}}>
                                    SPINE Tablet Application
                                </div>
                                <YouTube
                                    videoId="a7qNsmHc3sU" opts={playerOpts}/>
                            </div>
                        </div>
                    </div>
        );
    }

}

EntryPagePanel.propTypes = {
    loginStatus: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default withTranslation()(EntryPagePanel);