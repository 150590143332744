import React from 'react'
import {Button} from 'primereact/components/button/Button';
import {Tooltip} from 'primereact/components/tooltip/Tooltip';
import {expDesignPagesEnum} from "../ExpDesign";
import {required} from "../../helpers/validation/rules";
import {ruleRunner, run} from "../../helpers/validation/ruleRunner";
import {Panel} from "primereact/components/panel/Panel";
import ContainerDataSelection from "../containers/ContainerDataSelection";
import ContainerWorkflowSelection from "../containers/ContainerWorkflowSelection";
import PropTypes from "prop-types";
import {
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__SYMBOL,
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID,
    EXPERIMENT_PROPERTY_NAME__COHORTS, EXPERIMENT_PROPERTY_NAME__DESCRIPTION,
    EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA, EXPERIMENT_PROPERTY_NAME__HYPOTHESIS,
    EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA, EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID,
    EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES, EXPERIMENT_PROPERTY_NAME__QUESTION,
    EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA, EXPERIMENT_PROPERTY_NAME__UUID, LOGICAL_OPERATOR_INIT,
    REQUEST_STATUS_REQUESTED,
    REQUEST_STATUS_SUCCESS
} from "../../../Constants";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Dialog} from "primereact/components/dialog/Dialog";
import {IconButtonComponent} from "./IconButtonComponent";
import ModelAssumptionsPanel from "./ModelAssumptionsPanel";
import ContainerCalculator from "../containers/ContainerCalculator";
import {setCalculatorParameter} from "../action/CalculatorAction";
import ModelOptionsPanel from "../../visualization/component/ModelOptionsPanel";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import ContainerModels from "../../visualization/containers/ContainerModels";
import ModelOptionsItemCardElement from "../../visualization/component/ModelOptionsItemCardElement";

const Factor = props => {
    let index = props.index;
    return <span>+b<sub>{index}</sub>x<sub>{index}</sub></span>
};

const Definition = props => {
    let updatedVars = props.factors;
    return (<div style={{fontSize: 'xx-large'}}>y=b<sub>0</sub>{updatedVars.slice(1).map((factor, index) =>
        <Factor index={index + 1} key={index}/>)}+e</div>);
};

const fieldValidations = [
    // ruleRunner(EXPERIMENT_PROPERTY_NAME__HYPOTHESIS, "Hypothesis", required),
    // ruleRunner(EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID, "Model", required),
];

/**
 * Class responsible for initialisation of experiment part related to model and variables and .....
 */
export default class StatisticalModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showErrors: false,
            validationErrors: {},
            dataPanelCollapsed: false,
            qhPanelCollapsed: true,
            modelPanelCollapsed: true,
            statisticalBiasDialogVisible: false,
            statisticalCalculatorDialogVisible: false,
            modelAssumptionsDialogVisible: false,
            costsDialogVisible: false,
            timeToCompletionDialogVisible: false,
            selectModelDialogVisible:false
        };
         ["handleClickAdd","handleClickRemove" , "handleSubmitNext", "handleSubmitPrevious",
          "errorFor","init","onModelPanelToggle","onqhPanelToggle","onDataPanelToggle","handleRefresh","handleStatisticalCalculator" ].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }


    onModelPanelToggle(e) {
        this.setState({modelPanelCollapsed: !this.state.modelPanelCollapsed})
    }

    onDataPanelToggle(e) {
        this.setState({dataPanelCollapsed: !this.state.dataPanelCollapsed})
    }

    onqhPanelToggle(e) {
        this.setState({qhPanelCollapsed: !this.state.qhPanelCollapsed})
    }

    componentWillMount() {
        const {calculateSampleSizeActionCreator, getStatisticalPowerPlot, calculator,setCalculatorParameters,experiment} = this.props;
        this.init();
        // Run validations on initial state
        this.setState({validationErrors: run(this.state, fieldValidations)});
        this.handleStatisticalCalculator();
    }

    /**
     * Initialization of lacking properties.
     * If these properties are not set, the template (and its children) will not work properly.
     * Properties are initialized only in Redux.
     *
     */
    init() {
        const {experiment, updateExperiment} = this.props;

        if (!experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA)) {
            experiment[EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA] = Object.assign({},{
                inclusion:{
                    criteria: [],
                    expressions: [],
                    logicalOperator: LOGICAL_OPERATOR_INIT},
                exclusion:{
                    criteria: [],
                    expressions: [],
                    logicalOperator: LOGICAL_OPERATOR_INIT
                }
            });
        }
        if (!experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__COHORTS)) {
            experiment[EXPERIMENT_PROPERTY_NAME__COHORTS] = [];
        }
        if (!experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID)) {
            experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID] = '';
        }
        if (!experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES)) {
            experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES] = {};

        }
        updateExperiment(experiment);

    }

    checkEmptyVariables() {
        let exit = false;
        Object.keys(this.props.experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES])
            .forEach((v) => {
            if (v[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID] === '' ||
                v[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID] === null) exit = true;
        }); //errors from child elements
        return exit;
    }

    handleSubmitNext() {
        this.setState({showErrors: true});
        let val = run(this.props.experiment, fieldValidations);//now this cannot be done through state
        if (Object.keys(val).length > 0) return null;
        //if (Object.keys(this.state.validationErrors).length > 0) return null;//errors in this form
        // if (this.checkEmptyVariables()) return null;  //do not go further if sth is empty
        this.props.setStep(expDesignPagesEnum.Audit);
        //this.props.saveExperiment(this.props.experiment);  TODO Uncomment me when changed backend
    }

    handleSubmitPrevious() {
        this.props.setStep(expDesignPagesEnum.Global);
    }

    errorFor(field) {
        return this.state.validationErrors[field] || "";
    }



    /* wrapper for validation*/
    handleFieldChanged() {
        return (e) => {
            let newState = this.handleChange(e);
            newState.validationErrors = run(newState, fieldValidations);
            this.setState(newState);
        };
    }

    handleStatisticalCalculator(){
        const {calculator,experiment,calculateSampleSizeActionCreator,getStatisticalPowerPlot,setCalculatorParameters} = this.props;
        if(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]!=null && experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]!=={}) {
            let modelVariablesLength = Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]).length;
            if (modelVariablesLength < 2)
                modelVariablesLength = 2;
            let params = setCalculatorParameter(calculator.parameters, "u", modelVariablesLength - 1);
            setCalculatorParameters(params);
            calculateSampleSizeActionCreator();
            getStatisticalPowerPlot(modelVariablesLength - 1);
        }
    }

    handleClickAdd() {
        const {calculator,experiment,calculateSampleSizeActionCreator,getStatisticalPowerPlot,      setCalculatorParameters} = this.props;

        let updatedVars = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES];
        let card = Object.keys(updatedVars).length;
        let x = (card > 0) ? 'X' + card : 'Y';
        updatedVars[x]  = {
            "entityType": "SUBJECT",
            "symbol":x
        };
        experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES] = updatedVars;
        this.props.updateVariables(experiment);

        //refreshing Calculator
        this.handleStatisticalCalculator();
        console.log("statisticalModel add parameter: CLICK");

    }

    handleRefresh(){
        const {experiment,getDataset}=this.props;
            // const arrayOfVars = Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES])
            //     .map((s) => experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][s]['clinicalVariableId']['uuid']);
            // updateExperiment(experiment);
            getDataset(experiment);
    }

    handleClickRemove() {
        const {calculator,experiment,calculateSampleSizeActionCreator,getStatisticalPowerPlot,setCalculatorParameters,updateVariables} = this.props;



        if (Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]).length > 2) {
            let howMany = Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]).length-1;
            const symbol = 'X'+howMany;

            delete experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][symbol];
        }
        updateVariables(experiment);

        //refreshing calculator
       this.handleStatisticalCalculator();
       console.log("statisticalModel remove parameter: CLICK");
    }


    render() {
        const {modelsList, experiment, dataSelection,calculator} = this.props;
        const{selectModelDialogVisible, qhPanelCollapsed,modelPanelCollapsed,statisticalCalculatorDialogVisible,
        modelAssumptionsDialogVisible,costsDialogVisible,timeToCompletionDialogVisible,dataPanelCollapsed,
        statisticalBiasDialogVisible} = this.state;



        const numberOfSamples = (dataSelection.datasetState === REQUEST_STATUS_SUCCESS) ? dataSelection.dataset.length : '#';
        let statisticalCalcColor = null;
        if (numberOfSamples !== '#' && numberOfSamples > calculator.ss)
            statisticalCalcColor = 'green';
        else if (numberOfSamples !== '#' && numberOfSamples < calculator.ss)
            statisticalCalcColor = 'red';

        const finalDate = 'day month year';
        const costInFlops = '#';
        const costInHours = '#';
        const hasModelSet = (experiment!=null
            && experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID]!=null && experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID]!=="");

        const setModelButtonLabel = (hasModelSet)?"Change Model":"SelectModel";

        const costIconStyle = {marginTop: '10%', textAlign: 'left', marginLeft: '10%'};

        console.log("StatisticalModel::experiment state", experiment);
        const selectedModel=modelsList.find((el)=>{return el.uuid === experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID]});

        const addButtonVisibility = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID]==='GLR';

        return (
            <React.Fragment>
                <div className="ui-g-12">

                    <Panel header={experiment[EXPERIMENT_PROPERTY_NAME__QUESTION]}
                           toggleable={true}
                           collapsed={qhPanelCollapsed}
                           onToggle={(e)=>{console.log("Panel Questions&Hypo Toggled")}}

                           onCollapse={() => this.onqhPanelToggle(false)}
                           onExpand={() => this.onqhPanelToggle(true)}
                           className="questionPanel"
                    >

                        Question: {experiment[EXPERIMENT_PROPERTY_NAME__QUESTION]}
                        <br/>
                        Hypothesis: {experiment[EXPERIMENT_PROPERTY_NAME__HYPOTHESIS]}<br/>
                        Background and Significance: {experiment[EXPERIMENT_PROPERTY_NAME__DESCRIPTION]}
                    </Panel>

                </div>
                <div className="ui-g-12">

                    <Panel header="Cohorts" toggleable={true} collapsed={dataPanelCollapsed}

                        // onToggle={(e)=>this.setState({dataPanelCollapsed:e.value})}
                           onCollapse={() => this.onDataPanelToggle(false)}
                           onExpand={() => this.onDataPanelToggle(true)}
                    >
                        <ContainerDataSelection/>
                    </Panel>

                </div>
                <div className="ui-g-12">
                    <Panel header="Model" toggleable={true} collapsed={modelPanelCollapsed}
                        // onToggle={this.onModelPanelToggle}
                           onCollapse={() => this.onModelPanelToggle(false)}
                           onExpand={() => this.onModelPanelToggle(true)}
                    >
                        <div className="ui-g">
                        <div className="ui-g-12">
                            <div className="ui-g-2">
                                <div style={{height: "140px"}}>

                                    <div style={{display:'block'}}>
                                         <ModelOptionsItemCardElement item={selectedModel} onSelect={()=>{}}/>
                                         <div style={{display:'block'}}>
                                             <Button
                                                style={{width:'200px'}}
                                                label={setModelButtonLabel}
                                                icon="fa-plus"
                                                onClick={()=>{this.setState({selectModelDialogVisible:true})}} />
                                         </div>
                                    </div>

                                    <div style={{
                                        color: '#009d9b',
                                        // float: 'left',
                                        fontFamily: "Helvetica",
                                        // marginLeft: "10px",
                                        display: 'block',
                                        margin:'auto',
                                        paddingTop:'10%'
                                    }}>

                                    {/*<Definition factors={this.props.experiment.modelVariables}/>*/}
                                    </div>

                                </div>

                            </div>
                            <div className="ui-g-10">
                                <IconButtonComponent iconClassName="expBiasIcon" color="red"
                                                     onClick={() => this.setState({statisticalBiasDialogVisible: true})}>

                                </IconButtonComponent>
                                <IconButtonComponent iconClassName="expModelCorrectnessIcon" onClick={() => {
                                    this.setState({modelAssumptionsDialogVisible: true})
                                }}>
                                </IconButtonComponent>
                                <IconButtonComponent iconClassName="expCostIcon" onClick={() => {
                                    this.setState({costsDialogVisible: true})
                                }}>
                                    <div style={costIconStyle}>
                                        <i className="fa fa-user-md"        style={{fontSize: 'large'}}/>
                                        <span style={{fontSize: 'large'}}> {costInHours} </span>hrs
                                    </div>
                                    <div style={costIconStyle}>
                                        <i className="fa fa-server"
                                                                       style={{fontSize: 'large'}}/>
                                        <span style={{fontSize: 'large'}}> {costInFlops} </span>Gflop-hrs
                                    </div>

                                </IconButtonComponent>
                                <IconButtonComponent iconClassName="expDeadlineIcon" onClick={() => {
                                    this.setState({timeToCompletionDialogVisible: true})
                                }}>
                                    <div style={{marginTop: '10%',fontSize:'large'}}>{finalDate}</div>
                                </IconButtonComponent>
                                <IconButtonComponent iconClassName="expAbacusIcon"
                                                     {...(statisticalCalcColor ? {color: statisticalCalcColor} : {})}
                                                     onClick={() => {
                                                       this.setState({statisticalCalculatorDialogVisible: true})
                                                     }}>
                                    <div style={{marginTop: '10%'}}><i className="fa fa-users"
                                                                       style={{fontSize: 'large'}}/>
                                        <span style={{fontSize: 'large'}}> {numberOfSamples}</span>
                                    </div>
                                </IconButtonComponent>
                            </div>
                            {hasModelSet &&
                            <div className="ui-g-12">
                                 <ContainerWorkflowSelection/>


                            <div style={{marginTop: '1em'}}>
                                {addButtonVisibility &&
                                    <React.Fragment>
                                <Button id="addParameter" onClick={this.handleClickAdd}  icon="fa fa-plus"/>
                                <Tooltip for="#addParameter" title="Add variable" tooltipPosition="right"
                                                 tooltipEvent="hover"/>
                                <Tooltip for="#removeParameter" title="Remove last variable" tooltipPosition="top"
                                         tooltipEvent="hover"/>
                                <Button id="removeParameter" onClick={this.handleClickRemove} icon="fa fa-minus"/>
                                    </React.Fragment>}
                                <Button id="saveModel" type="button" className="ui-btn-primary" iconPos="right"
                                        disabled={dataSelection.datasetState === REQUEST_STATUS_SUCCESS}
                                        icon={dataSelection.datasetState === REQUEST_STATUS_REQUESTED?"fa fa-spinner fa-spin":"fa fa-refresh"} onClick={this.handleRefresh}/>
                                <Tooltip for="#saveModel" title="Refresh data" tooltipPosition="top"
                                         tooltipEvent="hover"/>
                            </div>
                            </div>}
                        </div>
                        </div>
                    </Panel>
                    <Button onClick={this.handleSubmitPrevious} label="Previous" id="previous"
                            style={{float: 'left'}}/>
                    <Button onClick={this.handleSubmitNext} label="Next" id="next"
                            style={{float: 'right'}}/>
                </div>
                <Dialog header={'Statistical Bias'}
                        resizable={false}
                        visible={statisticalBiasDialogVisible}
                        modal={true}
                        onHide={() => {
                            this.setState({statisticalBiasDialogVisible: false})
                        }}>
                    In development!
                </Dialog>
                <Dialog header={'Time To completion'}
                        resizable={false}
                        visible={timeToCompletionDialogVisible}
                        modal={true}
                        onHide={() => {
                            this.setState({timeToCompletionDialogVisible: false})
                        }}>
                    In development
                </Dialog>
                <Dialog header={'Cost'}
                        resizable={false}
                        visible={costsDialogVisible}
                        modal={true}
                        onHide={() => {
                            this.setState({costsDialogVisible: false})
                        }}>
                    In development!
                </Dialog>
                <Dialog header={'Model Compliance'}
                        resizable={false}
                        visible={modelAssumptionsDialogVisible}
                        modal={true}
                        onHide={() => {
                            this.setState({modelAssumptionsDialogVisible: false})
                        }}>
                    <ModelAssumptionsPanel/>
                </Dialog>
                <Dialog header={'Sample Size Calculator'}
                        resizable={true}
                        visible={statisticalCalculatorDialogVisible}
                        modal={true}
                        onHide={() => {
                            this.setState({statisticalCalculatorDialogVisible: false})
                        }}>
                    <ContainerCalculator numberOfSamples={numberOfSamples}/>
                </Dialog>
                <Dialog
                    modal={true}
                    resizable={false}
                    style={{width:"50vw",height:"60vh" }}
                    visible={selectModelDialogVisible}
                    onHide={()=>{this.setState({selectModelDialogVisible:false})}}
                    showCloseIcon={true}
                    contentStyle={{height:"50vh",overflowY:"hidden"}}
                >
                    <ContainerModels
                        {...this.props}
                        onCloseModelSidebar={()=>{this.setState({selectModelDialogVisible:false})}}/>
                </Dialog>
            </React.Fragment>
        )
    }

}
StatisticalModel.propTypes = {
    experiment: PropTypes.object.isRequired, // Type defined in JOI
    dataSelection: PropTypes.object.isRequired, //
    updateExperiment: PropTypes.func.isRequired
};