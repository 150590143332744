import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  clearOverlayImageActionCreator,
  getImageActionCreator,
  getTabletGameExperimentResults
} from "../action/TabletGameExperimentAction";
import {
  clearViewersState,
  updateViewerProperty
} from "../../visualization/action/ViewerAction";
import {
  clearInteractivePlotState, initializeAvatarScatterPlot, refreshDataAC
} from "../../visualization/action/InteractivePlotAction";
import {loadImageData} from "../../visualization/action/ImageAction";
import {calculateModelParams} from "../action/AuditAction";
import {clearDataset} from "../action/StatisticalModelAction";
import {MultiplotPanel} from "../component/d3components/MultiplotPanel";

const mapStateToProps = (state, ownProps) => {
  return {
    messageQueue: state.messaging.msgQueue,
    email: state.auth.email,
    plotSettings: state.visu.interactivePlot.plotSettings,
    plotData:state.visu.interactivePlot.plotData,
    plotDataState:state.visu.interactivePlot.plotDataState,
    images: state.visu.images.images,
    overlays: state.visu.images.overlays,
    viewersState: state.visu.viewers.viewersState,
    modelParameters: state.expDesign.audit.modelParameters,
    modelParametersState:state.expDesign.audit.modelParametersState
  }
};
/**
 * Future feature - not yet fully implemented.
 * @param dispatch
 * @returns {{getTabletGameExperimentResults: function(*=), getImage: function(*=), clearOverlayImage: function(*=)}}
 */
const mapDispatchToProps = dispatch => {
  return {
    getTabletGameExperimentResults: (tabletExperimentId) => {
      dispatch(getTabletGameExperimentResults(tabletExperimentId))
    },
    getImage: (imageProperties) => {
      dispatch(getImageActionCreator(imageProperties))
    },
    clearOverlayImage: (propName) => {
      dispatch(clearOverlayImageActionCreator(propName))
    },
    clearViewersState: () => {
      dispatch(clearViewersState())
    },
    updateViewerProperty: (key, property, value) => {
      dispatch(updateViewerProperty(key, property, value))
    },
    initializeAvatarScatterPlot:(scientficEventId)=>{
      dispatch(initializeAvatarScatterPlot(scientficEventId))
    },
    clearInteractivePlotState:()=>{
      dispatch(clearInteractivePlotState())
    },
    loadImageData: (imageId, format, url, type)=>{
      dispatch(loadImageData(imageId, format, url, type))
    },
    calculateParameters: (dim, data)=>{
      dispatch(calculateModelParams(dim,data))
    },
    clearDataset:()=>{
      dispatch(clearDataset())
    },
    refreshData:(scientificEventId)=>{
      dispatch(refreshDataAC(scientificEventId))
    }
  }
};
export const ContainerMultiplotPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(MultiplotPanel));