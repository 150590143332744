import React from 'react';
import PropTypes from "prop-types";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {getNestedProp, setNestedProp} from "../../helpers/expressions";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";


class Viewer2DControls extends React.Component {
  constructor(props) {
    super(props);
    ["onOrientationOptionChange"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  onOrientationOptionChange(event, viewerKey,orientationLabel){
    const{transferObject,updateTransferObject} = this.props;
    const path = ['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','orientation','options'];
    let opAr = getNestedProp(path,transferObject);
    if (event.checked){
      opAr.push({label:orientationLabel,value:orientationLabel.toUpperCase()});
      setNestedProp(path, opAr, transferObject);
    }
    else {
      let filtered = opAr.filter(function(el) { return el.value !== orientationLabel.toUpperCase(); });
      setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','orientation','options'],
        filtered, transferObject);
    }
    updateTransferObject(transferObject);
  }

  render() {
    const{transferObject,updateTransferObject,viewerKey,messageQueue}= this.props;
    const vc = getNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey],transferObject);


    return <React.Fragment>

      <div>
        <label>Orientation control:</label>
        <Checkbox checked={getNestedProp(['displayControls','orientation','controlVisible'],vc)}
                  onChange={(e) => {
                    setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','orientation','controlVisible'],
                      e.checked, transferObject);
                    updateTransferObject(transferObject);
                  }
                  }
        />
        <Checkbox checked={getNestedProp(['displayControls','orientation','controlEnabled'],vc)}
                  onChange={(e) => {
                    setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','orientation','controlEnabled'],
                      e.checked, transferObject);
                    updateTransferObject(transferObject);
                  }
                  }
        />

        <Checkbox checked={Array.isArray(getNestedProp(['displayControls','orientation','options'],vc))
        && getNestedProp(['displayControls','orientation','options'],vc).findIndex((el)=>el.value==="SAGITTAL")>-1 }
                  onChange={(e) => {this.onOrientationOptionChange(e,viewerKey,"Sagittal")}}
        />
        <Checkbox checked={Array.isArray(getNestedProp(['displayControls','orientation','options'],vc))
        && getNestedProp(['displayControls','orientation','options'],vc).findIndex((el)=>el.value==="CORONAL")>-1 }
                  onChange={(e) => {this.onOrientationOptionChange(e,viewerKey,"Coronal")}}
        />
        <Checkbox checked={Array.isArray(getNestedProp(['displayControls','orientation','options'],vc))
        && getNestedProp(['displayControls','orientation','options'],vc).findIndex((el)=>el.value==="AXIAL")>-1 }
                  onChange={(e) => {this.onOrientationOptionChange(e,viewerKey,"Axial")}}
        />
        <Dropdown value={getNestedProp(['displayControls','orientation','defaultValue'],vc)}
                  options={orientationOptions}
                  onChange={(e)=> {
                    setNestedProp(['miniWorkflow', 'currentTool', 'configuration', 'viewers', 'renderWindows', viewerKey, 'displayControls', 'orientation', 'defaultValue'],
                      e.value, transferObject);
                    updateTransferObject(transferObject);
                    let opts = getNestedProp(['displayControls','orientation','options'],vc);
                    if (opts.findIndex(el=>{return el.value===e.value})===-1){
                      messageQueue.show({
                        sticky: false,
                        severity: 'warning',
                        summary: 'Warning',
                        detail:"The default value for orientation is not available in the orientation options. Please select other value or change options."
                      })
                    }
                  }
                  }
        />



      </div>
      <label>Sequence:</label>
      <Checkbox checked={getNestedProp(['displayControls','sequence','controlVisible'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','sequence','controlVisible'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['displayControls','sequence','controlEnabled'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','sequence','controlEnabled'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <label>Linked:</label>
      <Checkbox checked={getNestedProp(['displayControls','linked','controlVisible'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','linked','controlVisible'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['displayControls','linked','controlEnabled'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','linked','controlEnabled'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <label>Smoothing control:</label>
      <Checkbox checked={getNestedProp(['displayControls','smoothing','controlVisible'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','smoothing','controlVisible'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['displayControls','smoothing','controlEnabled'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','smoothing','controlEnabled'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['displayControls','smoothing','defaultValue'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','smoothing','defaultValue'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <label>Look and feel:</label>

      Color:<ColorPicker value={getNestedProp(['lookAndFeel','color'],vc).substring(1)}
                   onChange={(e) => {
                     setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'lookAndFeel','color'],
                       "#".concat(e.value), transferObject);
                     updateTransferObject(transferObject);
                   }}
                   id={"viewerRim"}/>
                   Has control panel:
      <Checkbox checked={getNestedProp(['lookAndFeel','hasControlPanel'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'lookAndFeel','hasControlPanel'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      Has rim:
      <Checkbox checked={getNestedProp(['lookAndFeel','hasRim'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'lookAndFeel','hasRim'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      Interactions:
      Active viewer:
      <Checkbox checked={getNestedProp(['interactions','activeViewer','controlVisible'],vc)!=null?getNestedProp(['interactions','activeViewer','controlVisible'],vc):true}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'interactions','activeViewer','controlVisible'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['interactions','activeViewer','controlEnabled'],vc)!=null?getNestedProp(['interactions','activeViewer','controlEnabled'],vc):true}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'interactions','activeViewer','controlEnabled'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['interactions','activeViewer','defaultValue'],vc)!=null?getNestedProp(['interactions','activeViewer','defaultValue'],vc):true}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'interactions','activeViewer','defaultValue'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      Crosshair tool:
      <Checkbox checked={getNestedProp(['interactions','crossHairTool','controlVisible'],vc)!=null?getNestedProp(['interactions','crossHairTool','controlVisible'],vc):true}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'interactions','crossHairTool','controlVisible'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['interactions','crossHairTool','controlEnabled'],vc)!=null?getNestedProp(['interactions','crossHairTool','controlEnabled'],vc):true}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'interactions','crossHairTool','controlEnabled'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />

    </React.Fragment>;
  }
}
const orientationOptions =  [
  {
    label: "Sagittal",
    value: "SAGITTAL"
  },
  {
    label: "Coronal",
    value: "CORONAL"
  },
  {
    label: "Axial",
    value: "AXIAL"
  }
];

export default  Viewer2DControls;

Viewer2DControls.propTypes = {
  transferObject: PropTypes.object.isRequired,
  updateTransferObject: PropTypes.func.isRequired,
  viewerKey:PropTypes.string.isRequired,
  messageQueue:PropTypes.object.isRequired
};
