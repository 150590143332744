import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {ANNOTATION_PROPERTY_NAME__ID, REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {StatusColumn} from "./StatusColumn";
import {CUSTOM_ELEMENT} from "./AnnotationCell";
import {TreeTable} from "primereact/components/treetable/TreeTable";
import {traverseTree} from "../../../helpers/trees";


/**
 * Form for creating/editing annotation column.
 */
class AnnotationColumnSelection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRow:null,
      selectedStatus:"ALL",
      expandedRows:null
    };

    ["onRowSelect","renderQuestion","filterQuestions","rowExpansionTemplate"
      ,"renderIRIOptionsDetails"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    if (this.props.getAnnotationsList!=null)
      this.props.getAnnotationsList();
    if (this.props.getQuestionsList!=null)
      this.props.getQuestionsList();
    if (this.props.getOntologyList!=null)
      this.props.getOntologyList();
  }

  onRowSelect(e) {
    this.setState({
      selectedRow: Object.assign({}, e.data)
    });
  }



  renderQuestion(row){
    const {questionList,questionListState} = this.props;

    if(questionListState===REQUEST_STATUS_SUCCESS && questionList!=null && questionList.length>0){

      if (row!=null && row.reference!=null && row.reference.questionId!=null ) {
        const question = questionList.find((el)=>{return el[ANNOTATION_PROPERTY_NAME__ID]===row.reference.questionId});
        if (question!=null)
          return question.questionText;
      }
    }
    return "NA";
  }

  filterQuestions(value,filter){
    const {questionList,questionListState} = this.props;
    if (filter === undefined || filter === null || (typeof filter !== 'string')||questionListState!==REQUEST_STATUS_SUCCESS ) {
      return true;
    }
    let result = false;
    if (value!=null && value.questionId!=null) {
      const ont = questionList.find((o) => {
        return o._id === value.questionId
      });
      if (ont!=null) {
        result = ont.questionText.toLowerCase().includes(filter.toLowerCase());
      }
    }
    return result;
  }
  renderValidation(row){
    if (row['definition']!=null && row['definition']['validation']!=null)
      return JSON.stringify(row['definition']['validation']);
    else return " ";
  }

  static generateLink(link){
    return (<a target="_blank" href={link} title={"Open ".concat(link," in external window")}>
      {link.substring(link.lastIndexOf('/')+1)}
    </a>);
  }
  renderIRI(row){
    if (row['definition']!=null && row['definition']['iri']!=null)
      return AnnotationColumnSelection.generateLink(row['definition']['iri']);
    else return " ";
  }
  renderIRIOptions(row){
    if (row!=null && row['iri']!=null)
      return AnnotationColumnSelection.generateLink(row['iri']);
    else return " ";
  }

  renderIRIOptionsDetails(row,key,prop){
    const {ontologyList,ontologyListState}=this.props;
    if (row.definition.optionsDetails!=null &&  row.definition.optionsDetails[key]){
      if (prop==="ontologyClassIri"){
        return AnnotationColumnSelection.generateLink(row.definition.optionsDetails[key][prop])
      }
      if(ontologyListState===REQUEST_STATUS_SUCCESS){
        const ont = ontologyList.find((el)=>{return el._id===row.definition.optionsDetails[key][prop]});
        if (ont!=null){
          return ont.name;
        }
        else return row.definition.optionsDetails[key][prop];
      }
      else return row.definition.optionsDetails[key][prop];
    }
    else return "";
  }

  rowExpansionTemplate(row) {
    if(row.definition.type===CUSTOM_ELEMENT.HIERARCHICAL.type){

      const processing = (el)=>{
        el['data']={};
        el['data']['title']=el.title;
        el['data']['value']=el.value;
        el['data']['iri']=el.iri;
        if (el.iri!=null)
          el['data']['iri'] = AnnotationColumnSelection.generateLink(el['iri']);
      };
      const newData = [row.definition.options];
      traverseTree(newData,newData[0],processing);
      return (<div>
        <h5>{row.definition.name} > Properties Tree:</h5>
        <TreeTable  value={newData} emptyMessage={"No options found"}>
          <Column field="title" header="Title" expander/>
          <Column field="value" header="Value"/>
          <Column field="iri" header="IRI"  />
        </TreeTable>
      </div>);
    }
    if(row.definition.type===CUSTOM_ELEMENT.MULTIPLE_CHOICE.type){
      return (
        <div>
          <h5>{row.definition.name} > Properties:</h5>
          <DataTable value={row.definition.options} emptyMessage={"No options found"}>
            <Column header="Label" body={(r)=>{return r}}/>
            <Column header="Ontology" body={(r)=>{return this.renderIRIOptionsDetails(row,r,"ontologyId")}}/>
            <Column header="IRI" body={(r)=>{return this.renderIRIOptionsDetails(row,r,"ontologyClassIri")}}/>
          </DataTable>
        </div>
      );
    }
    return (
      <div>
        <h5>{row.definition.name} > Properties:</h5>
        <DataTable value={row.definition.options} emptyMessage={"No options found"}>
          <Column field="label" header="Label"/>
          <Column field="value" header="value"/>
          <Column field="iri" header="IRI" body={this.renderIRIOptions}/>
        </DataTable>
      </div>
    );
  }

  render() {
    const {allColumns,allColumnsState,onSave,selected} = this.props;
    const {selectedRow,selectedStatus,expandedRows} = this.state;

    return (
      <div className={"ui-g"}>
        <div className={"ui-g-12"}>
          {allColumnsState===REQUEST_STATUS_SUCCESS
          && <DataTable
            ref={(el)=> this.dt = el}
            sortField="status"
            sortOrder={-1}
            value={allColumns}
            expandedRows={expandedRows}
            onRowToggle={(e) => this.setState({ expandedRows: e.data })}
            rows={10}
            paginator={true}
            selection={selectedRow}
            onRowSelect={this.onRowSelect}
            rowExpansionTemplate={this.rowExpansionTemplate}
            selectionMode="single"
            resizableColumns={true}
            onSelectionChange={()=>{}}>
            {StatusColumn(selectedStatus,(value) =>{
              this.setState({ selectedStatus: value });
              this.dt.filter(value, 'status', 'custom')
            })}
            <Column field="_id" header={"id"}  sortable={true} filter={true} filterMatchMode="contains" style={{overflowX:"hidden"}}/>
            <Column field="reference" header={"Question"} body={this.renderQuestion} filter={true}  filterMatchMode="custom" filterFunction={this.filterQuestions}/>
            <Column field="definition.name" header={"Name"} sortable={true} filter={true}  filterMatchMode="contains"/>
            <Column field="definition.placeholder" header={"Placeholder"} sortable={true} filter={true}  filterMatchMode="contains"/>
            <Column field="definition.iri" body={this.renderIRI} header={"IRI"} sortable={true} filter={true}  filterMatchMode="contains" style={{overflowX:"hidden"}}/>
            <Column field="definition.type" header={"Type"} sortable={true} filter={true}  filterMatchMode="contains"/>
            <Column field="definition.value" header={"Value"} sortable={true} filter={true} filterMatchMode="contains"/>
            <Column field="definition.validation" header={"Validation"} body={this.renderValidation}/>
            <Column field="definition.options" header={"Options"} expander={true} style={{width:"7em"}}/>
          </DataTable>}
        </div>
        <div className={"ui-g-12"}>
          <Button label={"Set selected"} disabled={!(selectedRow!=null)} onClick={()=>onSave(selectedRow)}/>
          <Button label={"Collapse All Expanded"} disabled={!(expandedRows!=null)} onClick={()=>this.setState({expandedRows:null})}/>
        </div>
      </div>

    )
  }


}

export default AnnotationColumnSelection;

AnnotationColumnSelection.propTypes = {
  messageQueue: PropTypes.object.isRequired,
  getAnnotationsList: PropTypes.func.isRequired,
  getQuestionsList:  PropTypes.func.isRequired,
  getOntologyList: PropTypes.func.isRequired,
  onSave:PropTypes.func.isRequired,
  onClose:PropTypes.func.isRequired,
  taskListState: PropTypes.string.isRequired,
  allColumns: PropTypes.object.isRequired,
  allColumnsState: PropTypes.string.isRequired,
  questionList: PropTypes.array.isRequired,
  questionListState:PropTypes.string.isRequired,
  ontologyList: PropTypes.array.isRequired,
  ontologyListState: PropTypes.string.isRequired,
  selected:PropTypes.object.isRequired
};
