import React from 'react';
import PropTypes from "prop-types";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {getNestedProp, setNestedProp} from "../../helpers/expressions";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";


class Viewer3DControls extends React.Component {



  render() {
    const{transferObject,updateTransferObject,viewerKey,messageQueue}= this.props;
    const vc = getNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey],transferObject);




    return <React.Fragment>

      <div>
        <label>Display Image Slices:</label>

        {getNestedProp(['displayImageSlices'],vc)!=null
        && Object.keys(getNestedProp(['displayImageSlices'],vc))!=null
        && Object.keys(getNestedProp(['displayImageSlices'],vc)).map(
         el=>{

           return<React.Fragment>
             From state:
             <Checkbox checked={getNestedProp(['displayImageSlices',el,'fromState'],vc)}
                     onChange={(e) => {
                       setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayImageSlices',el,'fromState'],
                         e.checked, transferObject);
                       if (!e.checked)
                         delete transferObject['miniWorkflow']['currentTool']['configuration']['viewers']['renderWindows'][viewerKey]['displayImageSlices'][el]['stateKey'];
                       updateTransferObject(transferObject);
                     }
                     }
             />
             {  getNestedProp(['displayImageSlices',el,'fromState'],vc)===true &&
             <Dropdown value={getNestedProp(['displayImageSlices',el,'stateKey'],vc)}
                       options={
                         Object.keys(getNestedProp(['miniWorkflow', 'currentTool', 'configuration', 'viewers', 'layout', 'layoutOrder'],transferObject))
                           .map(vl=>{return {label:vl,value:vl}})
                       }
                       onChange={(e)=> {
                         setNestedProp(['miniWorkflow', 'currentTool', 'configuration', 'viewers', 'renderWindows', viewerKey, 'displayImageSlices', el, 'stateKey'],
                           e.value, transferObject);
                         updateTransferObject(transferObject);

                       }
                       }
             />}
             </React.Fragment>
         }
        )
        }




      </div>
      <label>Sequence:</label>
      <Checkbox checked={getNestedProp(['displayControls','sequence','controlVisible'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','sequence','controlVisible'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['displayControls','sequence','controlEnabled'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','sequence','controlEnabled'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />

      <label>Smoothing control:</label>
      <Checkbox checked={getNestedProp(['displayControls','smoothing','controlVisible'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','smoothing','controlVisible'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['displayControls','smoothing','controlEnabled'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','smoothing','controlEnabled'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />
      <Checkbox checked={getNestedProp(['displayControls','smoothing','defaultValue'],vc)}
                onChange={(e) => {
                  setNestedProp(['miniWorkflow','currentTool','configuration','viewers','renderWindows',viewerKey,'displayControls','smoothing','defaultValue'],
                    e.checked, transferObject);
                  updateTransferObject(transferObject);
                }
                }
      />

    </React.Fragment>;
  }
}

export default  Viewer3DControls;
Viewer3DControls.propTypes = {
  transferObject: PropTypes.object.isRequired,
  updateTransferObject: PropTypes.func.isRequired,
  viewerKey:PropTypes.string.isRequired,
  messageQueue:PropTypes.object.isRequired
};
