import {connect} from "react-redux";
import {SlicePositionWidget} from "../component/annotationTool/SlicePositionWidget";


const mapStateToProps = (state, ownProps) => {
  return {
    images: state.visu.images.images,
    viewersState: state.visu.viewers.viewersState,
    manualTool: state.visu.manualTool
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerSlicePositionWidget = containerCreator(SlicePositionWidget);

