import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {getNestedProp} from "../../helpers/expressions";

/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class SelectedCasePanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,

    };
    // ["contributorBody","groupBody"].forEach(name => {
    //   this[name] = this[name].bind(this);
    // });
  }




  render() {
    const {t, selectedDataPoint, rawData} = this.props;



    const isCaseDescriptionAvailable = getNestedProp(["cases"],rawData)!=null  && rawData.cases.find(el=>el.uuid === selectedDataPoint.realCase);

    if (!isCaseDescriptionAvailable)
        return ( <React.Fragment>{"Case description is unavailable. Case id: "}{selectedDataPoint.realCase}</React.Fragment>
    );

    const caseDescription = rawData.cases.find(el=>el.uuid === selectedDataPoint.realCase);

    if (!(caseDescription!=null))
      return ( <React.Fragment>{"Case description cannot be found in attached list of cases. Contact with the SPINE administrator." +
        "Case id: "}{selectedDataPoint.realCase}</React.Fragment>
      );

    const isVisitDescriptionAvailable =  getNestedProp(["visits"],rawData)!=null  && rawData.visits.find(el=>el.id === caseDescription.visitId);
    const visit = isVisitDescriptionAvailable?rawData.visits.find(el=>el.id === caseDescription.visitId):null;
    const visitDescription = isVisitDescriptionAvailable
    ? <React.Fragment>
        <div>Visit id: {caseDescription.visitId}</div>
        <div>Visit label: {visit.label}</div>
        <div>Visit description: {visit.visitDescription}</div>
      </React.Fragment>
      :<React.Fragment>
        <div>Visit id: {caseDescription.visitId}</div>
        <div>Visit label: {caseDescription.visitLabel}</div>
      </React.Fragment>;

    const isSubjectDescriptionAvailable =  getNestedProp(["subjects"],rawData)!=null  && rawData.subjects.find(el=>el.id === caseDescription.subjectId);
    const subject = isSubjectDescriptionAvailable
      ? rawData.subjects.find(el=>el.id === caseDescription.subjectId)
      : null;
    const subjectDescription = isSubjectDescriptionAvailable
      ? <React.Fragment>
        <div>Subject id: {subject.id}</div>
        <div>Subject label: {subject.label}</div>
      </React.Fragment>
      :<React.Fragment>
        <div>Subject id: {caseDescription.subjectId}</div>
      </React.Fragment>;


    return (
      <React.Fragment>
        <div>Id: {caseDescription.uuid}</div>
        <div>Name: {caseDescription.label}</div>
        {visitDescription}
        {subjectDescription}
      </React.Fragment>
    );
  }
}

export default withTranslation()(SelectedCasePanel);

SelectedCasePanel.propTypes = {
  rawData: PropTypes.object.isRequired,
  selectedDataPoint: PropTypes.object.isRequired,
  t: PropTypes.func
};
