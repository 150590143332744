import {connect} from "react-redux";
import WidgetToolbar from "../component/annotationTool/WidgetToolbar";
import {updateManualToolProperty} from "../action/ManualToolAction";
import {clearAllAnnotations, redoPin, undoPin} from "../action/AnnotationAction";
import {updateViewerProperty} from "../action/ViewerAction";
import {invertColors, updateActiveColorInScene} from "../action/SceneAction";
import {
    getActiveLayerColormapSelector,
    getActiveLayerValueSelector,
    getActivePainterSelector
} from "../selectors/SceneSelectors";
import {updateCameraSynchronization} from "../action/CameraAction";




const mapStateToProps = (state, ownProps) => {
    return {
        manualToolState: state.visu.manualTool.manualToolState,
        manualToolConfiguration: state.visu.manualTool.manualToolConfiguration,
        manualTask: state.activity.manualTask.manualTask,
        viewersState: state.visu.viewers.viewersState,
        scenes:state.visu.scenes.scenes,
        luts:state.visu.luts.luts,
        lutDescriptions:state.visu.luts.lutDescriptions,
        activePainter:getActivePainterSelector(state),
        activeColor:getActiveLayerValueSelector(state),//color value
        activeColormap:getActiveLayerColormapSelector(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        updateManualToolProperty: (key, property, value) => {
            dispatch(updateManualToolProperty(key, property, value))
        },
        updateViewerProperty:(index,property,value)=>{
            dispatch(updateViewerProperty(index,property,value))
        },
        updateCameraSynchronization:(shouldSync)=>{
            dispatch(updateCameraSynchronization(shouldSync))
        },
        clearAll : ()=>{
            dispatch(clearAllAnnotations())
        },
        undoPin:()=>{
            dispatch(undoPin())
        },
        redoPin:()=>{
            dispatch(redoPin())
        },
        updateActiveColorInScene:(value)=>{
            dispatch(updateActiveColorInScene(value))
        },
        invertColorsInOverlay:()=>{
            dispatch(invertColors())
        }
    }
};

export const ContainerWidgetToolbar =connect( mapStateToProps, mapDispatchToProps)(WidgetToolbar);
