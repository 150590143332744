import {
  requestLUT,
  requestLUTDescription,
  successLUT,
  successLUTDescription
} from "../../visualization/action/LUTAction";
import {getNestedProp} from "../../helpers/expressions";
import {ORDINAL24, ordinalColors} from "../../helpers/colors";
import {updateManualToolSAM} from "../../analysis/action/toolAction";


async function getVTStub(toolTemplatePath){
  let response = await fetch(toolTemplatePath,{cache: "reload"});
  const data = await response.json();
  return await data;
}


/**
 * Creates manual tool configuration for:
 *  - comparator tool (2 images in array as input)
 *  - visualization tool (1 orginal image as input)
 * @param imagesArray - array of images to display
 * @return {Promise<*>}
 */
export function createManualToolConfiguration(imagesArray){

  return async (dispatch, getState) => {
    let toolTemplate = '/dist/jsonDocs/tools/visualizationToolStubEmpty.json';

    if (imagesArray.length === 2)
      toolTemplate = '/dist/jsonDocs/tools/comparatorToolStubEmpty.json';

    const mtc = await getVTStub(toolTemplate);

    for (let i = 0; i < imagesArray.length; i++) {
      mtc.miniWorkflow.currentTool.inputs["input_" + imagesArray[i].uuid] = {
        "name": "Input image",
        "description": "Input image",
        "isList": false,
        "type": "imageEntityInOut",
        "imageEntityInOut_Type": "ANATOMICAL",
        "imageEntityInOut_FileFormat": "nii.gz",
        "required": true
      };
      mtc.miniWorkflow.currentTool.configuration.scenes["sceneKey" + (i + 1)].primaryImageInputKey = "input_" + imagesArray[i].uuid;
      mtc.miniWorkflow.currentMaterializedTask.inputs["input_" + imagesArray[i].uuid] = {
        "value": imagesArray[i].uuid,
        "label": imagesArray[i].label
      };
    }

    dispatch(updateManualToolSAM(mtc));
  }
}

/**
 * Creates visualization Tool for ROI. Requires original image and roi image as input.
 * @param originalImage
 * @param rois - array of rois
 * @return {Promise<*>}
 */
export function createManualToolConfigurationWithROI(originalImage,rois){

  return async (dispatch, getState) => {

    const mtc = await getVTStub('/dist/jsonDocs/tools/visualizationToolStubEmpty.json');

    mtc.miniWorkflow.currentTool.inputs["input_" + originalImage.uuid] = {
      "name": "Input image",
      "description": "Input image",
      "isList": false,
      "type": "imageEntityInOut",
      "imageEntityInOut_Type": "ANATOMICAL",
      "imageEntityInOut_FileFormat": "nii.gz",
      "required": true
    };
    mtc.miniWorkflow.currentTool.configuration.scenes["sceneKey1"].primaryImageInputKey = "input_" + originalImage.uuid;
    mtc.miniWorkflow.currentMaterializedTask.inputs["input_" + originalImage.uuid] = {
      "value": originalImage.uuid,
      "label": originalImage.label
    };

    for (let i=0;i<rois.length;i++) {
      const segKey = rois[i].uuid;

      if (getNestedProp(["properties", "explicit", "labelMap"], rois[i]) != null) {
        const intensity = getNestedProp(["properties", "explicit", "labelMap", "labelIntensity"], rois[i]) != null
          ? getNestedProp(["properties", "explicit", "labelMap", "labelIntensity"], rois[i])
          : 1;

        dispatch(requestLUT("lut_" + segKey));
        dispatch(successLUT("lut_" + segKey, [
          {
            "value": intensity,
            "color":  ordinalColors(ORDINAL24)[i]
          }
        ]));

        dispatch(requestLUTDescription("lutDescription_" + segKey));
        dispatch(successLUTDescription("lutDescription_" + segKey, [{
          "value": intensity,
          "label": "Region of interest "+(i+1)
        }
        ]));

        // Although the lookup tables are not from inputs, mark them as they are - to avoid clearing in ManualTool::initializeNonInputs
        mtc.miniWorkflow.currentTool.configuration.luts["lut_"+segKey] = {fromInputs: true, lutInputKey:"lut_"+segKey};
        mtc.miniWorkflow.currentTool.configuration.lutDescriptions["lutDescription_"+segKey] = {fromInputs: true, lutDescriptionInputKey:"lutDescription_"+segKey};
        mtc.miniWorkflow.currentTool.inputs["input_"+segKey] = {
          "name": "Segmentation",
          "description": "Segmentation",
          "isList": false,
          "type": "roiInOut",
          "typeROI": "EXPLICIT",
          "required": true,
          "imageEntityInOut_FileFormat": "nii.gz"
        };
        mtc.miniWorkflow.currentTool.configuration.rois.overlays["overlay_"+segKey] = {
          "fromInputs": true,
          "imageInputKey": "input_"+segKey,
          "lutKey": "lut_"+segKey,
          "lutDescriptionKey": "lutDescription_"+segKey,
          "label": "Region of interest "+(i+1)
        };
        mtc.miniWorkflow.currentTool.configuration.scenes.sceneKey1.rois.overlays.push("overlay_"+segKey);
        mtc.miniWorkflow.currentMaterializedTask.inputs["input_"+segKey] = {
          "value": getNestedProp(["properties","explicit","labelMap","imageEntityId"],rois[i]),
          "format": "nii.gz"
        };
      }
      if (!(getNestedProp(["miniWorkflow","currentTool","inputs","input_"+segKey],mtc)!=null)) {
        mtc["miniWorkflow"]["currentTool"]["inputs"]["input_"+segKey] = {
          "name": "Input ROis data",
          "description": "Input ROI data for annotation table",
          "isList": true,
          "type": "roiInOut",
          "typeROI": "IMPLICIT",
          "required": true
        };
        mtc["miniWorkflow"]["currentMaterializedTask"]["inputs"]["input_"+segKey] = {
          value:[],
          "readOnly": true,
          "color":ordinalColors(ORDINAL24)[i]
        };
        mtc["miniWorkflow"]["currentMaterializedTask"]["inputs"]["input_"+segKey]["value"].push(rois[i]["uuid"]);
      }
    }

    dispatch(updateManualToolSAM(mtc));
    // return mtc
  }
}
