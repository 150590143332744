import React from 'react'
import PropTypes from "prop-types";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";
import {Button} from "primereact/components/button/Button";
import {Spinner} from "primereact/components/spinner/Spinner";
import {withTranslation} from 'react-i18next';
import LayoutChooser from "./LayoutChooser";
import LayoutElementSelector from "./LayoutElementSelector";
import FilterPanel from "./FilterPanel";


/**
 * Component for displaying experiment results in live presenter.
 */
class MultiAvatarScatterPlotSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      layoutChooserVisible:false
    }; //initial state is in action initializeAvatarScatterPlot
    ["onApply", "onCancel", "onShow"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {plotSettings} = this.props;
    this.setState(plotSettings);
  }

  onApply() {
    const {updatePlotSettings} = this.props;
    updatePlotSettings(this.state, this.filterData.filterData());
    // this.props.onHide();
  }


  onCancel() {
    const {plotSettings} = this.props;
    this.setState(plotSettings);
    this.props.onHide();
  }

  onShow() {
    const {plotSettings} = this.props;
    this.setState(plotSettings);
  }


  render() {
    const {visible, position, onHide, t, onChangeLayout,onChangeCell,data} = this.props;
    const {optionsForClinical, optionsForMeasurements, clinicalValue, measurementValue} = this.state;
    return (
      <Sidebar
        position={position}
        visible={visible}
        onHide={() => onHide()}
        onShow={() => this.onShow()}
        style={{width:"300px",overflowY:"auto"}}
      >
        <div className="ui-g-12">
          <h1 style={{fontWeight: 'normal'}}>
            <i className="fa fa-cog" style={{color: "green", margin: "0.5em 0.25em 0px 0px"}}/>
            {t("plot.ui.configuration.header")}
          </h1>
          <div className="ui-g-12">
            <h2 style={{fontWeight: 'normal'}}> {"Layout"}</h2>
            <div style={{height: '5em'}}>
              <Button icon={"fa fa-th"} label={"Select layout"}
                      onClick={()=>{this.setState({layoutChooserVisible:!this.state.layoutChooserVisible})}}/>
              <LayoutChooser
                visible={this.state.layoutChooserVisible}
                onChange={(cell)=>{onChangeLayout(cell)}}
                onClick={()=>{this.setState({layoutChooserVisible:!this.state.layoutChooserVisible})}}
                selectedCell={this.props.selectedLayout}
              />
              <LayoutElementSelector
                visible={true}
                onChange={(cell)=>{onChangeCell(cell)}}
                selectedCell={this.props.selectedCell}
                selectedLayout={this.props.selectedLayout}
              />
            </div>
            <FilterPanel data={data} ref={filter=>this.filterData=filter}/>
            <h2 style={{fontWeight: 'normal'}}> {t("plot.ui.axes.header")}</h2>
            <div>
              <label style={{marginRight: "1em"}}>X</label>
              <Dropdown value={measurementValue} options={optionsForMeasurements}
                        placeholder={t("plot.ui.selectX.label")}
                        style={{width: "100%"}} onChange={(e) => {
                this.setState({measurementValue: e.value})
              }}/>
            </div>
            <div>
              <label style={{marginRight: "1em"}}>Y</label>
              <Dropdown value={clinicalValue} options={optionsForClinical}
                        placeholder={t("plot.ui.selectY.label")}
                        style={{width: "100%"}} onChange={(e) => {
                this.setState({clinicalValue: e.value})
              }}
              />
            </div>
            <h2 style={{fontWeight: 'normal'}}> {t("plot.ui.stats.header")}</h2>
            <div className="ui-g-12">
              <div>
                <Checkbox inputId="regression" style={{marginRight: "1em"}}

                          onChange={() => {
                            this.setState({regressionVisible: !this.state.regressionVisible})
                          }}
                          checked={this.state.regressionVisible}>
                </Checkbox>
                <label htmlFor="regression"
                       className="ui-checkbox-label">{t("plot.ui.stats.property.regression.label")}</label>
              </div>
              <div>
                <label htmlFor="color" className="ui-checkbox-label"
                       style={{marginRight: "1em"}}>{t("general.color.label")}</label>
                <ColorPicker id="color" value={this.state.regressionLineColor}
                             onChange={(e) => this.setState({regressionLineColor: e.value})}/>
              </div>
              <div>
                <label htmlFor="stroke" className="ui-checkbox-label"
                       style={{marginRight: "1em"}}>{t("general.width.label")}</label>
                <Spinner id="stroke" min={1} max={8} value={this.state.regressionLineWidth} step={1}
                         size={2}
                         onChange={(e) => this.setState({regressionLineWidth: e.value})}/>
              </div>
            </div>
            <div className="ui-g-12">
              <Checkbox inputId="equation" style={{marginRight: "1em"}}
                        onChange={() => {
                          this.setState({equationVisible: !this.state.equationVisible})
                        }}
                        checked={this.state.equationVisible}>
              </Checkbox>
              <label htmlFor="equation"
                     className="ui-checkbox-label">{t("general.equation.label")}</label>
            </div>
            <div className="ui-g-12">
              <Checkbox inputId="rSquaredVisible" style={{marginRight: "1em"}}
                        onChange={() => {
                          this.setState({rSquaredVisible: !this.state.rSquaredVisible})
                        }}
                        checked={this.state.rSquaredVisible}>
              </Checkbox>
              <label htmlFor="rSquaredVisible"
                     className="ui-checkbox-label">{t("plot.ui.stats.property.rSquared.label")}</label>
            </div>
            <div className="ui-g-12">
              <Button label={t("general.button.apply.label")} onClick={this.onApply}/>
              <Button label={t("general.button.cancel.label")} onClick={() => this.props.onHide()}/>
            </div>
          </div>
        </div>
      </Sidebar>
    )
  }
}

export default withTranslation()(MultiAvatarScatterPlotSidebar);

MultiAvatarScatterPlotSidebar.defaultProps = {

};

MultiAvatarScatterPlotSidebar.propTypes = {
  visible: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  plotSettings: PropTypes.object.isRequired,
  clearInteractivePlotState: PropTypes.func.isRequired,
  updatePlotSettings: PropTypes.func.isRequired,
  t: PropTypes.func
};
