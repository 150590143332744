import {connect} from "react-redux";
import {
  loadSitesData,
  updateEditableLayersProperty, updateFillingStyle, updateLegendVisibility,
  updateMapProperty, updateMarkersSize, updateOpacity,
  updateSitesData,
  updateSiteVisibility
} from "../action/GeoAction";

import GeoControlPanel from "../component/GeoControlPanel";


const mapStateToProps = state => {
  return {
    view: state.geo.view,
    viewState: state.geo.viewState,
    sites: state.geo.sites,
    sitesState: state.geo.sitesState,
    basemap:  state.geo.basemap,
    blendMode:  state.geo.blendMode,
    opacity: state.geo.opacity,
    markerSize:  state.geo.markerSize,
    fill:  state.geo.fill,
    legend:state.geo.legend
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateMapProperty:(property,value) =>{
      dispatch(updateMapProperty(property,value))
    },
    loadSitesData:() =>{
      dispatch(loadSitesData())
    },
    updateSitesData:(data) =>{
      dispatch(updateSitesData(data))
    },
    updateSiteVisibility : (site,visibility) => {
      dispatch(updateSiteVisibility(site,visibility))
    },
    updateEditableLayersProperty:(property,value) =>{
      dispatch(updateEditableLayersProperty(property,value))
    },
    updateMarkersSize:(value)=>{
      dispatch(updateMarkersSize(value))
    },
    updateOpacity:(opacity)=>{
      dispatch(updateOpacity(opacity))
    },
    updateFillingStyle:(fill)=>{
      dispatch(updateFillingStyle(fill))
    },
    updateLegendVisibility:(legend)=>{
      dispatch(updateLegendVisibility(legend))
    }
  }
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerGeoControlPanel = containerCreator(GeoControlPanel);