import React from "react";
import PropTypes from "prop-types";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";

const MIDDLE = "middle";  //id of left vie
const LOWER_RIGHT = "lowerRight";
const UPPER_RIGHT = "upperRight";



const widthHMain="calc(100vh - 190px)"; // -50 - 35 -35 -65
const heightHMain="calc(100vh - 190px + 32px)";

const widthHReference="calc((100vh - 190px)*0.5 - 16px)";
const heightHReference="calc((100vh - 190px)*0.5 + 16px)";


const widthVMain="calc((100vw - 50px)*2/3)";
const heightVMain="calc((100vw - 50px)*2/3)";

const widthVReference="calc((100vw - 50px)*1/3 - 16px)";
const heightVReference="calc((100vw - 50px)*1/3 + 16px)";
/**
 * Layout component for displaying viewers panel with
 *  - main viewer,
 *  - reference viewers,
 *  - widget toolbar,
 *   - annotation table
 *  Estimated  sizes:
 *  - navi bars = 50px
 *  - widget toolbar ~ 35px
 *  - viewer toolbar ~ 35px
 *  - table active row ~65 px
 *
 *
 */
class OneMainTwoReferenceWigetsLayout extends React.Component {
    static getLabel(){
        return "One main viewer with two reference viewers and widget toolbar"
    }
    static getClassName(){
        return "sliceSelection3ViewersDemo"
    }
    static getViewersPortKeys(){
        return [MIDDLE,LOWER_RIGHT,UPPER_RIGHT];
    }

    constructor(props) {
        super(props);
        this.state = {
            widthMain:widthHMain,
            widthReference:widthHReference,
            heightMain:heightHMain,
            heightReference:heightHReference
        };
        ["calcLayout"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }
    componentDidMount() {
        this.calcLayout();
        window.addEventListener("resize",this.calcLayout);
    }
    componentDidUpdate(prevProps, prevState,snapshot) {
    }
    componentWillUnmount() {
        window.removeEventListener("resize",this.calcLayout);
    }
    /** Calculates the size of viewers.
     *  Still it is not final version,
     * Ratio 1.23 established empirically (since calculations can be inaccurate due to toolbars of dynamic size)
     **/
    calcLayout() {
        if (window.innerWidth < (1.23 * window.innerHeight) ) {
            this.setState({
                widthMain:widthVMain,
                widthReference:widthVReference,
                heightMain:heightVMain,
                heightReference:heightVReference
            });
            console.log("Portait mode");
        }
       else {
            this.setState({
                widthMain:widthHMain,
                widthReference:widthHReference,
                heightMain:heightHMain,
                heightReference:heightHReference
            });
            console.log("Landscape mode");
        }
    }

    render() {
        const {toolComponentProps, toolComponentState, widgetPanel,importExportPanel} = this.props;
        const viewersState = toolComponentProps.viewersState;
        const styleMain = {padding: "0.1em", height: this.state.heightMain, width: this.state.widthMain};
        const styleRef = {padding: "0.1em", height: this.state.heightReference, width: this.state.widthReference};

        const middle = (viewersState != null && viewersState.hasOwnProperty(MIDDLE))
            ? BrowserFactory.build(MIDDLE, toolComponentProps, toolComponentState)
            : <div title="Main viewer here."/>;

        const lowerRight = (viewersState != null && viewersState.hasOwnProperty(LOWER_RIGHT))
            ? BrowserFactory.build(LOWER_RIGHT, toolComponentProps, toolComponentState)
            : <div title="Lower-right reference viewer here."/>;

        const upperRight = (viewersState != null && viewersState.hasOwnProperty(UPPER_RIGHT))
            ? BrowserFactory.build(UPPER_RIGHT, toolComponentProps, toolComponentState)
            : <div title="Upper-right reference viewer here."/>;


        return (
            <React.Fragment>
             <div>{widgetPanel}{importExportPanel}</div>
            <div  style={{display:"flex",justifyContent:"center"}}>
                <div style={{display:"inline-flex"}}>
                    <div style={styleMain} >
                        {middle}
                    </div>
                </div>
                <div>
                    <div style={styleRef} >
                        {upperRight}
                    </div>
                    <div style={styleRef} >
                        {lowerRight}
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default OneMainTwoReferenceWigetsLayout;

OneMainTwoReferenceWigetsLayout.defaultProps = {


};

OneMainTwoReferenceWigetsLayout.propTypes = {
    toolComponentProps:PropTypes.object.isRequired,
    toolComponentState:PropTypes.object.isRequired,
    importExportPanel:PropTypes.object.isRequired,
    widgetPanel:PropTypes.object.isRequired
};


