import {connect} from "react-redux";
import React from "react";
import {
  parseAndLoadAnnotationDefinition,
  updateAnnotationData
} from "../../visualization/action/AnnotationAction";
import {TaskPropertiesHelpPanelTab} from "../component/TaskPropertiesHelpPanelTab";



const mapStateToProps = state => {
  return {
    messageQueue: state.messaging.msgQueue,
    columns: state.visu.annotations.annotationsDefinition,
    columnsState: state.visu.annotations.annotationsDefinitionState,
    data: state.visu.annotations.annotationsData,
    tasks: state.expDesign.tasks.tasks,
    taskListState: state.expDesign.tasks.taskListState,
    manualToolState: state.visu.manualTool.manualToolState
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAnnotationData:(data)=>{
      dispatch(updateAnnotationData(data))
    },
    getColumnsDefinition:(id)=>{
      dispatch(parseAndLoadAnnotationDefinition(id))
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);


export const ContainerTaskPropertiesHelpPanelTab = containerCreator(TaskPropertiesHelpPanelTab);