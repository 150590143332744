import React from "react";
import { Link } from "react-router-dom";
import { HOST_URL } from "../../../Constants";
import { getThumbnails } from "../utils/livePresenterHelpers";
import store from "../../Store";
import axios from "axios/index";

const SlideCard = (props) => {
  const deleteSlide = (presentationId, slideId, revisionId) => {
    axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
    axios.get(HOST_URL + "/api/presentation/" + presentationId).then((presentation) => {
      if (presentation.status === 200) {
        const index = presentation.data.slides.indexOf(slideId);
        if (index > -1) {
          presentation.data.slides.splice(index, 1);
        }

        axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
        axios
          .put(HOST_URL + "/api/presentation/" + presentationId, {
            creationDate: presentation.data.creationDate,
            uuid: presentation.data.uuid,
            revisionId: presentation.data.revisionId,
            name: presentation.data.name,
            description: presentation.data.description,
            privacy: presentation.data.privacy,
            owner: presentation.data.owner,
            modifiedBy: presentation.data.modifiedBy,
            loopSlides: presentation.data.loopSlides,
            slides: presentation.data.slides,
          })
          .then((update) => {
            console.log("addSlidePresentation :: response ::", update);
            props.setRequestData(new Date());

            if (update.status === 200) {
              console.log("Slide Removed To Presentation");
            }
          })
          .catch((error) => {
            console.log("project err:", error);
          });
      }
    });

    axios.defaults.headers.common["Authorization"] = store.getState().auth.token_bearer;
    axios
      .delete(HOST_URL + `/api/presentation/${presentationId}/slide/${slideId}`, { data: { revisionId: revisionId } })
      .then((response) => {
        console.log("PresentationAction.js :: deletePresentation :: response ::", response);

        if (response.status === 200) {
          console.log("Slide Deleted");
        }
      })
      .catch((error) => {
        console.log("project err:", error);
      });
  };

  return (
    <div className="PresentationCard" style={{ width: "18rem" }}>
      <div className="PresentationCardImg">{getThumbnails(props.data)}</div>
      <div className="PresentationCardBody">
        <p className="PresentationCardText" style={{ fontWeight: 500 }}>
          ({props.number}) {props.data.name}
        </p>
        <br />
        <div className="PresentationActions">
          <Link to={`/livePresenter/${props.presentationId}/slide/${props.data.uuid}`} className="PresentationView">
            View
          </Link>
          <span
            onClick={() => deleteSlide(props.presentationId, props.data.uuid, props.data.revisionId)}
            className="SlideDelete"
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  );
};

export default SlideCard;
