import React from 'react';

/** 3. Editor Component */
import Editor from 'workflow-new/dist/editor.bundle'

/** import mini workflow json schema */
import mini_workflow from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/b9e5860dc809e082903c96e3e1b0fe25.json'

/** import tasks json schema */
import task_Lowerbound from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/b9e5860dc809e082903c96e3e1b0d831.json'
import task_Upperbound from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/b9e5860dc809e082903c96e3e1b0dd80.json'
import task_Segmentation from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/b9e5860dc809e082903c96e3e1b0f43b.json'
import task_SegmentationCheck from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/b9e5860dc809e082903c96e3e1b0e875.json'
import task_VolumeMeasurement from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/b9e5860dc809e082903c96e3e1b0caca.json'

/** import tools json schema */
import slice_selector from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/1b158260-b7bf-41b5-a876-f370ca2a7c42.json'
import segementation_brush from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/90b2f53b-60db-4e25-8146-67ab643f85c5.json'
import slice_selector_range from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/723a012fa2003851280690b3f300174c.json'
import segmentation_check from 'workflow-new/src/jsonfiles_workflow_MedullaOblongata/ae3a7f3b-338e-4dbb-b9d5-ef4541da1b42.json'

/**
 *
 * @param workflowSchema - workflow schema provided as property of argument object
 * @return {*}
 * @constructor
 */
function WorkflowVisualizerDemo({wSchema}) {

    // __________________________________________________________
    // Mini Workflow ============================================
    const workflowSchema = (wSchema!=null)?wSchema:mini_workflow;

    // __________________________________________________________
    // Task Files ===============================================
    const jsonTasks = [
        task_Lowerbound,
        task_Upperbound,
        task_Segmentation,
        task_SegmentationCheck,
        task_VolumeMeasurement
    ];

    // let jsonStrTasks = [];
    // jsonTasks.forEach(eachFile => {
    //     jsonStrTasks.push(JSON.stringify(eachFile))
    // })

    // __________________________________________________________
    // Tool Files ===============================================
    let jsonTools = [
        slice_selector,
        segementation_brush,
        slice_selector_range,
        segmentation_check
    ];
    // let jsonStrTools = [];
    // jsonTools.forEach(eachFile => {
    //     jsonStrTools.push(JSON.stringify(eachFile))
    // });

    // __________________________________________________________
    // Event Handler ============================================
    // 1. For Diamond Node --------------------------------------
    /** Diamond Node - OnClick */
    const handleClickDiamondNode = (node) => {
        console.log('Click for diamond node', node);
        alert(node.getToolName());
    };
    /** Diamond Node - Double Click */
    const handleDoubleClickDiamondNode = (node) => {
        console.log('Double Click for diamond node', node)
    };
    // 2. For IO Node --------------------------------------------
    /** IO Node - OnClick */
    const handleClickIONode = (node) => {
        console.log('Click for IO node', node)
    };
    /** IO Node - Double Click */
    const handleDoubleClickIONode = (node) => {
        console.log('Double Click for IO node', node)
    };

    // ==========================================================
    // For Workflow Visualization
    // ==========================================================

    return (

      <div>
      <Editor
        autoLayout = {true}
        inputWorkflow={workflowSchema}
        leftPanelVisible={false}
        rightPanelVisible={false}
        topPanelVisible={false}
        onOutputModified={(data)=>{console.log(data)}}

        /** data */
        jsonTasks={jsonTasks}
        jsonTools={jsonTools}

        /** handler */
        // 1. Diamond Node
        handleClickDiamondNode={handleClickDiamondNode}
        handleDoubleClickDiamondNode={handleDoubleClickDiamondNode}
        // 2. IO Node
        handleClickIONode={handleClickIONode}
        handleDoubleClickIONode={handleDoubleClickIONode}
      />
      </div>
    )
}

export default WorkflowVisualizerDemo;