import {
    ANNOTATION_IMAGE_FAIL,
    ANNOTATION_IMAGE_REQUESTED, ANNOTATION_IMAGE_SET_QUANTILES,
    ANNOTATION_IMAGE_SUCCESS,
    LABELMAP_IMAGE_FAIL,
    LABELMAP_IMAGE_REQUESTED,
    LABELMAP_IMAGE_SUCCESS
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


export const imageReducer = (state = {
    overlays:{}, // overlays requested or loaded
    images:{}    // images requested or loaded
}, action) => {
    let stateCopy = null;
    let requestedImage = null;
    switch (action.type) {
        case ANNOTATION_IMAGE_SUCCESS:{
            stateCopy = Object.assign({},state.images);
            if (!(stateCopy[action.imageId]!=null))
                return state;
            requestedImage = stateCopy[action.imageId];
            requestedImage['state'] =  REQUEST_STATUS_SUCCESS;
            requestedImage['data'] =  Object.assign({},action.imageData.data);
            requestedImage['properties'] =
                Object.assign({},{
                        slicingDirections: action.imageData.slicingDirections,
                        slicingModes: action.imageData.slicingModes,
                        position: action.imageData.position,
                        viewUp: action.imageData.viewUp,
                        anatomicalOrientation: action.imageData.anatomicalOrientation
                });
            stateCopy[action.imageId]=Object.assign({},requestedImage);
            console.log('Orientation parameters: ', requestedImage['properties']);
            return Object.assign({}, state, {
                images:Object.assign({},stateCopy)
            });
        }
        case ANNOTATION_IMAGE_FAIL:{
            console.log(action.error);
            stateCopy = Object.assign({},state.images);
            if (stateCopy[action.imageId]!=null)
                return state;
            requestedImage = stateCopy[action.imageId];
            requestedImage['state'] =  REQUEST_STATUS_FAIL;
            requestedImage['data'] =  action.imageData;
            stateCopy[action.imageId]=Object.assign({},requestedImage);
            return Object.assign({}, state, {
                images:Object.assign({},stateCopy)
            });
        }
        case ANNOTATION_IMAGE_REQUESTED:{
            stateCopy = Object.assign({},state.images);
            let isImageAlreadyInArray = stateCopy[action.imageId]!=null;
            if (isImageAlreadyInArray)  //Maybe some conditions if error occurred (reload?)
                return state;
            let newImage = {};
            newImage['state'] =  REQUEST_STATUS_REQUESTED;
            newImage['uuid'] =  action.imageId;
            stateCopy[action.imageId] = newImage;
            return Object.assign({}, state, {
                images:Object.assign({},stateCopy)
            });
        }

        case ANNOTATION_IMAGE_SET_QUANTILES:{  // to avoid callculations of percentiles every time when reset WL
            stateCopy = Object.assign({},state.images);
            if (!(stateCopy[action.imageId]!=null))
                return state;
            requestedImage = stateCopy[action.imageId];
            requestedImage['properties'] =
              Object.assign({},{
                  slicingDirections:   requestedImage['properties'].slicingDirections,
                  slicingModes:   requestedImage['properties'].slicingModes,
                  position:   requestedImage['properties'].position,
                  viewUp:   requestedImage['properties'].viewUp,
                  anatomicalOrientation:   requestedImage['properties'].anatomicalOrientation,
                  quantiles: action.quantiles
              });
            stateCopy[action.imageId]=Object.assign({},requestedImage);
            return Object.assign({}, state, {
                images:Object.assign({},stateCopy)
            });
        }
        case LABELMAP_IMAGE_FAIL:{
            stateCopy = Object.assign({},state.overlays);
            if (!(stateCopy[action.imageId]!=null))
                return state;
            requestedImage = stateCopy[action.imageId];
            requestedImage['state'] =  REQUEST_STATUS_FAIL;
            requestedImage['data'] =  action.imageData;
            stateCopy[action.imageId]=Object.assign({},requestedImage);
            return Object.assign({}, state, {
                overlays:Object.assign({},stateCopy)
            });
        }


        case LABELMAP_IMAGE_SUCCESS:{
            stateCopy = Object.assign({},state.overlays);
            if (!(stateCopy[action.imageId]!=null))
                return state;
            requestedImage = stateCopy[action.imageId];
            requestedImage['state'] =  REQUEST_STATUS_SUCCESS;
            requestedImage['data'] =  Object.assign({},action.imageData.data);
            requestedImage['properties'] =
                Object.assign({},{
                    slicingDirections: action.imageData.slicingDirections,
                    slicingModes: action.imageData.slicingModes,
                    position: action.imageData.position,
                    viewUp: action.imageData.viewUp,
                    anatomicalOrientation: action.imageData.anatomicalOrientation
                });
            stateCopy[action.imageId]=Object.assign({},requestedImage);
            console.log('Orientation parameters: ', requestedImage['properties']);
            return Object.assign({}, state, {
                overlays:Object.assign({},stateCopy)
            });
        }

        case LABELMAP_IMAGE_REQUESTED:{
            stateCopy = Object.assign({},state.overlays);
            let isImageAlreadyInArray = stateCopy[action.imageId]!=null;
            if (isImageAlreadyInArray)  //Maybe some conditions if error occurred (reload?)
                return state;
            let newImage = {};
            newImage['state'] =  REQUEST_STATUS_REQUESTED;
            newImage['uuid'] =  action.imageId;
            stateCopy[action.imageId] = newImage;
            return Object.assign({}, state, {
                overlays:Object.assign({},stateCopy)
            });
        }
        default:
            return state;
    }
};

