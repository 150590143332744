import React from "react";
import {ListSwitchType} from "../../root/ListTypeSwitch";
import {ItemList} from "../../project/component/ItemList";
import CohortOptionsItemCardElement from "../../visualization/component/CohortOptionsItemCardElement";
import PropTypes from "prop-types";
import CohortOptionsItemListElement from "../../visualization/component/CohortOptionsItemListElement";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {InputText} from "primereact/components/inputtext/InputText";

/**
 * Component used for SELECTION of cohorts.
 * Visualization of selected cohorts is implemented in different place!
 */

export default class CohortsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      areCohortsInListMode: false
    };
  }

  componentDidMount() {
    const {getCohorts} = this.props;
    if (getCohorts!=null){
      getCohorts();
    }
  }

  render() {
    const {filteredCohorts,cohortFilter,onFilterChange,onCloseSidebar,experiment} = this.props;
    let sorterOptions=[
      {value: 'SORT_BY_NAME_DESC', label: 'Name Descending'},
      {value: 'SORT_BY_NAME_ASC', label: 'Name Ascending'},
      {value: 'SORT_BY_SUBJECT_NUMBER_DESC', label: 'Subjects No.Descending'},
      {value: 'SORT_BY_SUBJECT_NUMBER_ASC', label: 'Subjects No. Ascending'},
      {value: 'SORT_BY_ACQUISITION_NUMBER_DESC', label: 'Acquisitions No. Descending'},
      {value: 'SORT_BY_ACQUISITION_NUMBER_ASC', label: 'Acquisitions No. Ascending'},
      {value: 'SORT_BY_VISIT_NUMBER_DESC', label: 'Visits No. Descending'},
      {value: 'SORT_BY_VISIT_NUMBER_ASC', label: 'Visits No. Ascending'}
    ];
    return (
      <div style={{marginLeft:"10%",marginRight:"10%"}}>
        <div className="cohortDetailsMenu" >
          <div style={{padding: "2em"}}>
          <div style={{float: 'right'}}>

            <label htmlFor="filterCohorts" style={{marginRight:"1em"}}>Filter by Name and Description</label>
            <InputText id="filterCohorts" value={cohortFilter} onChange={(e) => onFilterChange(e.target.value)}/>
            <i className="fa fa-search" style={{marginRight:"1em"}}/>
            <label htmlFor="sortCohorts" style={{marginRight:"1em"}} >Sort by</label>
            <Dropdown id="sortCohorts" options={sorterOptions}
                        value={this.props.cohortSorter}
                        onChange={(e) => this.props.onSorterChange(e.value)}
                        style={{border: 'none', background: '#f0f4f7'}}/>
            <i className="fa fa-sort" style={{marginRight:"1em"}}/>
              <ListSwitchType clb={() => {
                this.setState({areCohortsInListMode: !this.state.areCohortsInListMode})
              }}
                              isList={this.state.areCohortsInListMode}/>
            </div>
            {/*<i className="fa fa-close" onClick={onCloseSidebar}/>*/}

          </div>
        </div>
        <ItemList model={filteredCohorts}
                  isList={this.state.areCohortsInListMode}
                  classNames={["ui-g-3", "ui-g-3","ui-g-3","ui-g-3"]}
                  headers={["Name", "Principal Investigator","Subjects","Select"]}
                  properties={["name", "description","subjects"]}
                  cardRenderer={<CohortOptionsItemCardElement {...this.props} />}
                  listRenderer={<CohortOptionsItemListElement  {...this.props}   />}
                  columnStyle="ui-g-12  ui-md-6 ui-lg-4"
                  optionalListStyle={{display:'inline-flex',width:"100%",
                    marginBottom:'1px', padding:'10px'}}
        />

      </div>
    )
  }
}
CohortsPage.propTypes = { //ALL props are passed from CohortSelectionMenu via  {...this.props}
  filteredCohorts: PropTypes.array.isRequired,//filtered cohorts
  onSelect: PropTypes.func.isRequired,//callback to select cohort
  selectCheckboxState: PropTypes.func,// flag indicating whether all cohorts are selected
  handleChange: PropTypes.func.isRequired,//does not apply here, just passed with others
  isList: PropTypes.bool,//does not apply here
  listModeClb: PropTypes.func,//does not apply here
  experiment:PropTypes.object,
  getCohorts:PropTypes.func
};