import {connect} from "react-redux";
import editPresentation from "../component/editPresentation";
import {getSlideList, addSlide} from "../action/PresentationAction";
import {withRouter} from 'react-router';
import playSlidesManual from "../component/playSlidesManual";
import playSlidesAuto from "../component/playSlidesAuto";

// Used by connect()
const mapStateToProps = (state,own) => {
    return {
        slides: state.presentation.slides.slide_list,
        presentations: state.presentation.presentations.presentation_list
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSlides: (id) => {
            dispatch(getSlideList(id))
        },
        addSlide: (slide) => {
            dispatch(addSlide(slide))
        }
    }
};

export const ContainerSlides = withRouter(connect( mapStateToProps, mapDispatchToProps)(editPresentation));

export const ContainerPlayManually = withRouter(connect( mapStateToProps, mapDispatchToProps)(playSlidesManual));

export const ContainerPlayAutomatically = withRouter(connect( mapStateToProps, mapDispatchToProps)(playSlidesAuto));

/** The container can bind many components if they use the same reducer.*/
//export const ContainerSlides = slideContainerCreator(editPresentation);