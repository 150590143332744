// Connectome actions

import {
  CLEAR_CONNECTOME_STATE, CONNECTOME_CASES_REQUEST_FAILED, CONNECTOME_CASES_REQUEST_SUCCESS, CONNECTOME_CASES_REQUESTED,
  CONNECTOME_REQUEST_FAILED,
  CONNECTOME_REQUEST_SUCCESS,
  CONNECTOME_REQUESTED, UPDATE_CONNECTOME_SELECTED_CASES,
} from "./actionType";
import axios from "axios/index";
import {requestLUT, requestLUTDescription, successLUT, successLUTDescription} from "./LUTAction";
import {getNestedProp} from "../../helpers/expressions";
import {
  GRAPH_TOOL_INTENSITY_PROPERTY_CODE,
  GRAPH_TOOL_LABEL_PROPERTY_CODE, HOST_URL,
  REQUEST_STATUS_SUCCESS
} from "../../../Constants";
import {getTurboColor} from "../../helpers/colors";


const requestConnectome = (connId) => ({
  type: CONNECTOME_REQUESTED,
  connId
});

const errorConnectome = (err,connId) => ({
  type: CONNECTOME_REQUEST_FAILED,
  err,
  connId
});

const successConnectome = (connId,connectome) => ({
  type: CONNECTOME_REQUEST_SUCCESS,
  connId,
  connectome
});

export const getConnectome = (connId) => {
  return (dispatch, getState) => {
    dispatch(requestConnectome(connId));
    console.log('ConnectomeAction.js :: getConnectome :: Before axios request');
    // loadConnfromFile(connId)
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    axios.get(`/api/connectome/${connId}`)
      .then((connResponse) => {

        const data = connResponse.data;
        const names = data.nodes.map(el => {
          return {"name": el[GRAPH_TOOL_LABEL_PROPERTY_CODE]}
        });

        const PAYLOAD = {
          "name_search": "pubmed_fatigue",
          "classes": names
        };


        axios.post(HOST_URL + '/api/indra/get_network', PAYLOAD)
          .then((response) => {
            const indraData = Object.assign(...response.data);  // flat array of objects into object

            const nodesArray = indraData["nodes"];
            const edgesArray = indraData["edges"];
            const edgeAttributes = indraData["edgeAttributes"];

            const publications = edgeAttributes.filter(el => el["n"] === "citations");

            data.edgeAttributes.push({
              "name": "Publications",
              "description": "Publications",
              "label": "Strength",
              "type": "array",
              "items": {
                "type": "string",
                "format": "pubmed"
              },
              "property": "p"
            });

            const existsInBothArrays = nodesArray.map((element1) => {
                const index = data.nodes.findIndex((element2) => element2["l"] === (element1["n"]));
                if (index > -1) {
                  element1["spineId"] = index;
                  return element1
                } else return null;
              }
            ).filter(el => el !== null);


            const nodeIds = existsInBothArrays.map(ex => ex["@id"]);

            edgesArray
              .filter(el => nodeIds.includes(el.s) && nodeIds.includes(el.t))
              .map(el => {
                const sourceNode = existsInBothArrays.find(mel => mel['@id'] === el['s']);
                const targetNode = existsInBothArrays.find(mel => mel['@id'] === el['t']);
                if (sourceNode != null && targetNode != null) {
                  // check if this connection exist in connectome
                  const spineEdge = data["edges"].find(cel =>
                    (cel["l"][0] === sourceNode.spineId && cel["l"][1] === targetNode.spineId)
                    || (cel["l"][1] === sourceNode.spineId && cel["l"][0] === targetNode.spineId)
                  );
                  // add other atributes
                  if (spineEdge != null) {
                    const pub = edgeAttributes.find(pel => pel["po"] === el["@id"] && pel["n"] === "citations");
                    if (pub != null) {
                      spineEdge["p"] = pub["v"];
                    }
                    return spineEdge;
                  } else return null;
                } else return null
              }).filter(el => el !== null);


            dispatch(successConnectome(connId, data))
          })
          .catch((error) => {
            dispatch(successConnectome(connId, data)); // no publications available
          });


      })
      .catch(err => dispatch(errorConnectome(err, connId)));
  };
};



const clearConnectomes=()=>(
  {
    type: CLEAR_CONNECTOME_STATE
  }
);
/**
 * ActionCreator for releasing connectomes from memory.
 * @returns {function(*)}
 */
export const clearConnectomesState = () => {
  return (dispatch) => {
    dispatch(clearConnectomes());
  };
};

const changeCase = (selectedCase)=>({
    type: UPDATE_CONNECTOME_SELECTED_CASES,
      selectedCase
  }
);

/**
 * ActionCreator for releasing connectomes from memory.
 * @returns {function(*)}
 */
export const updateConnectomeCase = (selectedCase) => {
  return (dispatch) => {
    dispatch(changeCase(selectedCase));
  };
};


const requestConnectomeCases = () => ({
  type: CONNECTOME_CASES_REQUESTED,
});

const errorConnectomeCases = (err) => ({
  type: CONNECTOME_CASES_REQUEST_FAILED,
  err
});

const successConnectomeCases = (data) => ({
  type: CONNECTOME_CASES_REQUEST_SUCCESS,
  data
});


export const getConnectomeCases = (experimentId) => {
  return (dispatch,getState) => {

    dispatch(requestConnectomeCases());
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    axios.get(' /api/experiment/'+experimentId+'/connectomics')
      .then(response => {
        if (response.status !== 200) {
          dispatch(errorConnectomeCases(response))
        } else {
          dispatch(successConnectomeCases(Object.values(response.data).flat()));
        }
      }).catch(error => {
      dispatch(errorConnectomeCases(error));
      // dispatch(successConnectomeCases(Object.values(FAKE_CASES).flat())); // previous hack
    });
  };
};


//These are mock to simulate async loading of data - map with id and name of data file (json)
// let connMap = null;
// loadConnectomeRegister().then(data=>connMap = data); //Load register of mocked data


//------------
// async function loadConnfromFile(poly) {
//   const fn = connMap[poly];
//   let response = await fetch(`/dist/jsonDocs/data/${fn}.json`);
//   const data = await response.json();
//   return await data;
// }
//
// async function loadConnectomeRegister() {
//   let response = await fetch('/dist/jsonDocs/data/connRegister.json',{cache: "reload"});
//   const data = await response.json();
//   return await data;
// }

//
// const FAKE_CASES =
//   {
//     "B865": [
//       {
//       "case": "B865",
//       "graph_document_id": "4427ccb3d68cc7c6c54b81924100099a",
//       "acquisitions": [
//         {
//           "name": "output",
//           "provenance": {
//             "type":
//               "task_output",
//             "task_name": "replace",
//             "wfExecution": "3f65dbe9-0098-4b14-ae48-863333f13070"
//           },
//           "id": "575bddd50492d394fe3e506e21134850"
//         }
//       ],
//       "tractographies": [
//         {
//           "name": "output",
//           "provenance": {
//             "type": "task_output",
//             "task_name": "replace",
//             "wfExecution": "3f65dbe9-0098-4b14-ae48-863333f13070"
//           },
//           "id": "e756bb213534c103f7ddb49b31000698"
//         }
//       ]
//       },
//       {
//         "case": "B865",
//         "graph_document_id": "3e4ac69dc3318c59353e194f8d002298",
//         "acquisitions": [
//           {
//             "name": "output",
//             "provenance": {
//               "type":
//                 "task_output",
//               "task_name": "replace",
//               "wfExecution": "3f65dbe9-0098-4b14-ae48-863333f13070"
//             },
//             "id": "575bddd50492d394fe3e506e21134850"
//           }
//         ],
//         "tractographies": [
//           {
//             "name": "output",
//             "provenance": {
//               "type": "task_output",
//               "task_name": "replace",
//               "wfExecution": "3f65dbe9-0098-4b14-ae48-863333f13070"
//             },
//             "id": "e756bb213534c103f7ddb49b31000698"
//           }
//         ]
//       }
//     ],
//     "B965": [{
//       "case": "B965",
//       "graph_document_id": "4427ccb3d68cc7c6c54b81924100099a",
//       "acquisitions": [
//         {
//           "name": "output",
//           "provenance": {
//             "type":
//               "task_output",
//             "task_name": "replace",
//             "wfExecution": "3f65dbe9-0098-4b14-ae48-863333f13070"
//           },
//           "id": "1c0d7bc38c3a25eb8914e0bb030044a4"
//         }
//       ],
//       "tractographies": [
//         {
//           "name": "output",
//           "provenance": {
//             "type": "task_output",
//             "task_name": "replace",
//             "wfExecution": "3f65dbe9-0098-4b14-ae48-863333f13070"
//           },
//           "id": "fcdcf23f47e64c1b1b31135027000e44"
//         }
//       ]
//     }],
//     "C865": [{
//       "case": "C865",
//       "graph_document_id": "00000012321",
//       "acquisitions": [
//         {
//           "name": "output",
//           "provenance": {
//             "type":
//               "task_output",
//             "task_name": "replace",
//             "wfExecution": "3f65dbe9-0098-4b14-ae48-863333f13070"
//           },
//           "id": "90b2cf1df2af286aee19a5de0a1a628b"
//         }
//       ],
//       "tractographies": [
//         {
//           "name": "output",
//           "provenance": {
//             "type": "task_output",
//             "task_name": "replace",
//             "wfExecution": "3f65dbe9-0098-4b14-ae48-863333f13070"
//           },
//           "id": "90b2cf1df2af286aee19a5de0a1a628b"
//         }
//       ]
//     }]
//   }
// ;


export const parseConnectomeCaseConfiguration = (emptyTool, caseConfiguration)=>{

  const parsedConf = JSON.parse(JSON.stringify(emptyTool)); // make deep copy!
  const inputDefs = parsedConf["miniWorkflow"]["currentTool"]["inputs"];
  const inputMatTasks = parsedConf["miniWorkflow"]["currentMaterializedTask"]["inputs"];
  const configuration =  parsedConf["miniWorkflow"]["currentTool"]["configuration"];

  const graphId = getNestedProp(["graph_document_id"],caseConfiguration);
  const atlasId = getNestedProp(["atlasId"],caseConfiguration);

  if (!graphId || !atlasId)
    return;
  const lutKey = "lut_"+graphId;
  const lutDescKey = "lutDesc_"+graphId;

  if (Array.isArray(caseConfiguration.acquisitions) && caseConfiguration.acquisitions.length>0){

    const atlasAcquisition = caseConfiguration.acquisitions.find(el=>el["id"]===atlasId);

    if (!atlasAcquisition)
      return;

    const acquisition = caseConfiguration.acquisitions
      .filter(el=>el["id"]!==atlasId) // exclude atlas from it
      .find(el=>el["space"]===atlasAcquisition["space"]); // find acquisition using the same space

    // caseConfiguration.acquisitions.forEach((el,index)=> {
    inputDefs["inputImage_key1"]=  // + (index + 1)] =
        {
          "name": "Normal image",
          "description": "Input image",
          "isList": false,
          "type": "imageEntityInOut",
          "imageEntityInOut_Type": "ANATOMICAL",
          "imageEntityInOut_FileFormat": "nii.gz",
          "required": true
    };
    inputMatTasks["inputImage_key1"]=  // + (index + 1)] =
        {
          "value": acquisition.id,
          "label":"MRI"
    };

    // });

    inputDefs["segmentation_key"] = // just one so far
      {
        "name": "Segmentation",
        "description": "Segmentation",
        "isList": false,
        "type": "roiInOut",
        "typeROI": "EXPLICIT",
        "required": true,
        "imageEntityInOut_FileFormat": "nii.gz"
      };



    inputMatTasks["segmentation_key"] ={
      "value": atlasId,
      "label": "Segmentation"
    };

    configuration.luts[lutKey] = {
      "fromInputs": false
    };

    configuration.lutDescriptions[lutDescKey] = {
      "fromInputs": false
    };

    configuration.rois.overlays["overlay_key_1"] = {
      "fromInputs": true,
      "imageInputKey":"segmentation_key",
      "lutKey": lutKey,
      "lutDescriptionKey": lutDescKey
    };

    configuration.scenes["sceneKey1"] = {
      "label": "Segmentation",
      "primaryImageInputKey": "inputImage_key1",
      "rois": {
        "overlays": [
          "overlay_key_1"
        ]
      }
    };

    configuration.viewers.renderWindows[0].displayScenes = {
      "possibleScenesToDisplay": [ "sceneKey1"],
      "hasDefaultSceneToDisplay": true,
      "defaultSceneToDisplay": "sceneKey1"
    };
    configuration.viewers.renderWindows[1].displayScenes = {
      "possibleScenesToDisplay": [ "sceneKey1"],
      "hasDefaultSceneToDisplay": true,
      "defaultSceneToDisplay": "sceneKey1"
    };
    configuration.viewers.renderWindows[2].displayScenes = {
      "possibleScenesToDisplay": [ "sceneKey1"],
      "hasDefaultSceneToDisplay": true,
      "defaultSceneToDisplay": "sceneKey1"
    };
    configuration.viewers.renderWindows[3].displayScenes = {
      "possibleScenesToDisplay": [ "sceneKey1"],
      "hasDefaultSceneToDisplay": true,
      "defaultSceneToDisplay": "sceneKey1"
    }
  }
  else return emptyTool;

  if (caseConfiguration["graph_document_id"]!=null){

    configuration.viewers.renderWindows[5].displayScenes = {
      "possibleScenesToDisplay": [ ],
      "hasDefaultSceneToDisplay": true,
      "defaultSceneToDisplay": "sceneGraphKey1"
    };


    inputDefs["inputConnectome_key1"] = {
        "name": "Input description of LUT data",
        "description": "Input LUT data for displaying options.",
        "isList": false,
        "type": "connectome",
        "required": true
    };
    inputMatTasks["inputConnectome_key1"] = {
        "value": caseConfiguration["graph_document_id"]
    };

    configuration.rois.connectomes["conn_key_1"] = {
        "fromInputs": true,
        "dataInputKey": "inputConnectome_key1"
    };

    configuration.scenes["sceneGraphKey1"] = {
        "label": "Simple graph",
        "primaryImageInputKey": "inputImage_key1",
        "rois": {
          "connectomes": "conn_key_1"
        }
    };
    configuration.viewers.renderWindows[5].displayScenes.possibleScenesToDisplay.push("sceneGraphKey1");

  }

  if (Array.isArray(caseConfiguration.tractographies) && caseConfiguration.tractographies.length>0){

    caseConfiguration.tractographies.forEach((el,index)=> {
      inputDefs["inputPoly_key" + (index + 1)] =
        {
          "name": "Tractography data",
          "description": "Tractography data",
          "isList": false,
          "type": "polyRoiInOut",
          "typeROI": "EXPLICIT",
          "required": true,
          "dataEntityInOut_FileFormat": ".vtk"
        };
      inputMatTasks["inputPoly_key" + (index + 1)] =
        {
          "value": el.id
        };

      configuration.rois.polydatas["poly_key_"+(index+1)] = {
        "fromInputs": true,
        "dataInputKey": "inputPoly_key"+(index+1)
      };

      configuration.scenes["polySceneKey"+(index+1)] = {
        "primaryImageInputKey": "inputImage_key1",
        "rois": {
          "polydatas": [
            "poly_key_"+(index+1)
          ]
        }
      };
    });

    configuration.viewers.renderWindows[4].displayScenes = {
      "possibleScenesToDisplay": caseConfiguration.tractographies.map((el,index)=>"polySceneKey"+(index+1)),
      "hasDefaultSceneToDisplay": true,
      "defaultSceneToDisplay": "polySceneKey1"
    }
  }

  return parsedConf;
};


/**
 * Action creator for setting up Lookup tables based on connectome Nodes structure.
 * @return {function(...[*]=)}
 */
export const getLUTFromConnectome = () => {
  return (dispatch,getState) => {

    const conns = getState().visu.connectomes.connectomes;
    const selected = getState().visu.connectomes.selectedCase;
    const graphId = getNestedProp(["graph_document_id"],selected);

    if (!graphId)
      return;

    const lutKey = "lut_"+graphId;
    const lutDescKey = "lutDesc_"+graphId;
    const connectome = conns[graphId];

    if (getNestedProp(["state"],connectome) === REQUEST_STATUS_SUCCESS && Array.isArray(getNestedProp(["data","nodes"],connectome))){

      dispatch(requestLUT(lutKey));
      dispatch(requestLUTDescription(lutDescKey));

      const data = connectome["data"];
      const size= data.nodes.length;
      const lt = data.nodes.map((el,index)=>{
        return(
        {
          "value":el[GRAPH_TOOL_INTENSITY_PROPERTY_CODE],
          "color":getTurboColor(index/size)
        }
        )
      });

      dispatch(successLUT(lutKey,lt));

      const ltd = data.nodes.map((el,index)=>{
        return(
          {
            "value":el[GRAPH_TOOL_INTENSITY_PROPERTY_CODE],
            "label": el[GRAPH_TOOL_LABEL_PROPERTY_CODE],
            "iri":el["iri"]
          }
        )
      });

      dispatch(successLUTDescription(lutDescKey,ltd));
    }

  };
};