import {connect} from "react-redux";
import WorkflowManager from "../component/WorkflowManager";
import {getAllWorkflowList} from "../../expDesign/action/WorkflowAction";

// Used by connect()
const mapStateToProps = state => {
    return {
        workflows: state.expDesign.workflows.workflows,
        workflowListState: state.expDesign.workflows.workflowListState,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getWorkflows: () => {
            dispatch(getAllWorkflowList())
        }
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerWorkflowManager = containerCreator(WorkflowManager);
