import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {POINTER_TOOL} from "../../../../Constants";
import {checkIfAnyPropertyIsVisible} from "./WidgetToolbar";


/**
 * Stateful component with template for Pointer Tool.
 * Responsible for changing and parameterizing cursor.
 * @returns {*}
 */
export class PointerToolbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogVisible: false
        };
    }

    render() {
        const {toolProperties, changeToolProperties, toolConfiguration} = this.props;
        const control = (property, type) => toolConfiguration != null
            && toolConfiguration.properties != null
            && toolConfiguration.properties[property] != null
            && toolConfiguration.properties[property][type] != null
            && toolConfiguration.properties[property][type];

        const checkIfAnyPropertyVisible = checkIfAnyPropertyIsVisible(toolConfiguration.properties);

        return (
            <React.Fragment>
                {checkIfAnyPropertyVisible &&
                <Toolbar style={{display: "inline-block"}}>
                    {control('mode', 'controlVisible') &&
                    <React.Fragment>
                        <label style={{marginLeft: "1em"}}>On mouse:</label>
                        <Dropdown
                            value={toolProperties['mode']}
                            options={[{label:'click',value:'onClick'},{label:'move',value:'onMove'}]}
                            onChange={(e) => changeToolProperties(POINTER_TOOL, 'mode', e.value)}
                        />
                    </React.Fragment>
                    }
                </Toolbar>}
            </React.Fragment>
        );
    }

};

PointerToolbar.defaultProps = {
    toolProperties: {range: 1},
};
PointerToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    toolConfiguration: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func
};
