import React from 'react'
import PropTypes from "prop-types";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Button} from "primereact/components/button/Button";
import {Spinner} from "primereact/components/spinner/Spinner";
import {withTranslation} from 'react-i18next';
import LayoutChooser from "./LayoutChooser";
import LayoutElementSelector from "./LayoutElementSelector";
import {InputText} from "primereact/components/inputtext/InputText";
import FilterPanel from "./FilterPanel";
import {getNestedProp} from "../../../helpers/expressions";
import {INTENSITY_FILTER_TOOL} from "../../../../Constants";


/**
 * Component for displaying experiment results in live presenter.
 */
class MultiHistogramSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      layoutChooserVisible:false,
      numberOfBins:3,
      globalMinValue:0,
      globalMaxValue:0,
      variable:"volume",
      xLabel:"",
      yLabel:""

    }; //initial state is in action initializeAvatarScatterPlot
    ["onApply", "onCancel", "onShow","prepareData","calculateRange"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {plotSettings,data} = this.props;
    this.setState(plotSettings);
    if (data!=null)
      this.calculateRange();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {data} = this.props;
    const {variable} = this.state;
    if (prevProps.data!==data || prevState.variable!==variable){
      this.calculateRange();
    }
  }

  calculateRange(){
    const {data} = this.props;
    const {variable}= this.state;

    let min=Infinity;
    let max=-Infinity;
    if (data !=null && data.cases!=null)
    Object.keys(data.cases)
      .forEach((caseInstance,caseIndex) => {

          if (data.cases[caseInstance]["measurement"][variable]!=null && data.cases[caseInstance]["measurement"][variable].contribution!=null && data.cases[caseInstance]["measurement"][variable].contribution.length>0){
            data.cases[caseInstance]["measurement"][variable].contribution.forEach(contribution=>{
                if (contribution.value < min) min = contribution.value;
                if (contribution.value > max) max = contribution.value;
              }
            );
          }

      });
    this.setState({
      globalMinValue : min,
      globalMaxValue : max
    });

  }

  prepareData() {
    const {numberOfBins, globalMinValue, globalMaxValue, variable} = this.state;

    let thresholds = [];

    const filteredData = this.filterData.filterData();

    let binSize = (globalMaxValue - globalMinValue) / this.state.numberOfBins;

    for (let i = 0; i < (numberOfBins - 1); i++) {
      thresholds.push(globalMinValue + binSize * i + binSize * .5);
    }

    let dataForScatter = [];
    //if (!(data != null) || !(data['results'] != null) || !(data['results'].length > 0)) return null;
    let bins = new Array(thresholds.length + 1).fill(0);

    if (filteredData != null && filteredData.cases != null){
      Object.keys(filteredData.cases).forEach((key, index) => {
        const caseInstance = filteredData.cases[key];

        if (caseInstance.measurement.hasOwnProperty(variable)) {

          caseInstance.measurement[variable].contribution.forEach((s, sIndex) => {
              let single = {
                "y": s['value'],
                "originalImageId": caseInstance.measurement[variable]['originalImageId'],
                "segmentationId": s['segmentationId'],
                "userId":  s['userId'],
                "caseId": key,
                "img": '/api/user/' + s['userId'] + '/picture'
              };
              if (s['value'] < thresholds[0]) {
                single['x'] = ++bins[0];
                single['yBinMode'] = thresholds[0] - binSize * .5;
              } else if (s['value'] >= thresholds[thresholds.length - 1]) {
                single['x'] = ++bins[bins.length - 1];
                single['yBinMode'] = thresholds[thresholds.length - 1] + binSize * .5;
              } else
                for (let i = 0; i < thresholds.length; i++)
                  if (thresholds[i] <= s['value'] && s['value'] < thresholds[i + 1]) {
                    single['x'] = ++bins[i + 1];
                    single['yBinMode'] = (thresholds[i + 1] + thresholds[i]) * .5;
                  }
              dataForScatter.push(single);
            }
          );
        }
      });
  }
    return {data:dataForScatter,thresholds:thresholds};
  }


  onApply() {
    const {onApply} = this.props;
    const {numberOfBins,xLabel,yLabel} = this.state;

    const {data,thresholds} = this.prepareData();

    onApply(data,{numberOfBins,thresholds,xLabel,yLabel});
  }


  onCancel() {
    const {plotSettings} = this.props;
    this.setState(plotSettings);
    this.props.onHide();
  }

  onShow() {
    const {plotSettings} = this.props;
    this.setState(plotSettings);
  }


  render() {
    const {visible, position, onHide, t, onChangeLayout,onChangeCell,data} = this.props;
    const {variable} = this.state;
    return (
      <Sidebar
        position={position}
        visible={visible}
        onHide={() => onHide()}
        onShow={() => this.onShow()}
        style={{width:"300px",overflowY:"auto"}}
      >
        <div className="ui-g-12">
          <h1 style={{fontWeight: 'normal'}}>
            <i className="fa fa-cog" style={{color: "green", margin: "0.5em 0.25em 0px 0px"}}/>
            {t("plot.ui.configuration.header")}
          </h1>
          <div className="ui-g-12">
            <h2 style={{fontWeight: 'normal'}}> {"Layout"}</h2>
            <div style={{height: '5em'}}>
              <Button icon={"fa fa-th"} label={"Select layout"}
                      onClick={()=>{this.setState({layoutChooserVisible:!this.state.layoutChooserVisible})}}/>
              <LayoutChooser
                visible={this.state.layoutChooserVisible}
                onChange={(cell)=>{onChangeLayout(cell)}}
                onClick={()=>{this.setState({layoutChooserVisible:!this.state.layoutChooserVisible})}}
                selectedCell={this.props.selectedLayout}
              />
              <LayoutElementSelector
                visible={true}
                onChange={(cell)=>{onChangeCell(cell)}}
                selectedCell={this.props.selectedCell}
                selectedLayout={this.props.selectedLayout}
              />
            </div>
            <FilterPanel data={data} ref={filter=>this.filterData=filter}/>


            <h2 style={{fontWeight: 'normal'}}> {t("plot.ui.stats.header")}</h2>
            <div>
              <div>
              <label style={{marginRight: "1em"}}>{t("plot.ui.configuration.parameters.variable.label")}</label>
              <Dropdown id={"variableDropdown"}
                        onChange={(e)=>{this.setState({variable:e.value})}}
                        options={getNestedProp(["dataDescription","measurement"],data)!=null
                          ? Object.keys(getNestedProp(["dataDescription","measurement"],data))
                            .map(el=>{return {
                              value:el,
                              label:"".concat(getNestedProp(["dataDescription","measurement",el,"name"],data),"[",getNestedProp(["dataDescription","measurement",el,"unit"],data),"]")
                            }})
                          : []}
                        value={variable}/>
              </div>
              <div>
              <label style={{marginRight: "1em"}}>{t("plot.ui.stats.property.minimum.label")}</label>
                <input
                  id="globalMinSpinner"
                  type={"number"}
                         value={this.state.globalMinValue}
                         step={0.1}
                         size={3}
                         onChange={(e) => this.setState({globalMinValue: Number(e.currentTarget.value)})}
                />
              </div>
              <div>
              <label style={{marginRight: "1em"}}>{t("plot.ui.stats.property.maximum.label")}</label>
                <input
                  id="globalMaxSpinner"
                  type={"number"}
                  value={this.state.globalMaxValue}
                  step={0.1}
                  size={3}
                  onChange={(e) => this.setState({globalMaxValue: Number(e.currentTarget.value)})}
                />
              </div>
            </div>
            <div className="ui-g-12">
              <label htmlFor="numberOfBins" className="ui-checkbox-label" style={{marginRight: "1em"}}>
                {t("plot.ui.configuration.parameters.bins.number.label")}</label>
              <Spinner id="numberOfBins"
                       min={3}
                       max={10}
                       value={this.state.numberOfBins}
                       step={1}
                       size={3}
                       onChange={(e) => this.setState({numberOfBins: e.value})}/>

            </div>
            {/*<div className="ui-g-12">*/}
            {/*  <label htmlFor="xLabel" className="ui-checkbox-label" style={{marginRight: "1em"}}>*/}
            {/*    {t("plot.ui.axes.xLabel.label")}</label>*/}
            {/*  <InputText id="xLabel"*/}
            {/*           value={this.state.xLabel}*/}
            {/*           onChange={(e) => this.setState({xLabel: e.target.value})}/>*/}

            {/*</div>*/}
            <div className="ui-g-12">
              <label htmlFor="yLabel" className="ui-checkbox-label" style={{marginRight: "1em"}}>
                {t("plot.ui.axes.yLabel.label")}</label>
              <InputText id="yLabel"
                         value={this.state.yLabel}
                         onChange={(e) => this.setState({yLabel: e.target.value})}/>

            </div>
            <div className="ui-g-12">
              <Button label={t("general.button.apply.label")} onClick={this.onApply}/>
              <Button label={t("general.button.cancel.label")} onClick={() => this.props.onHide()}/>
            </div>
          </div>
        </div>
      </Sidebar>
    )
  }
}

export default withTranslation()(MultiHistogramSidebar);

MultiHistogramSidebar.defaultProps = {

};

MultiHistogramSidebar.propTypes = {
  visible: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  plotSettings: PropTypes.object.isRequired,
  clearInteractivePlotState: PropTypes.func.isRequired,
  updatePlotSettings: PropTypes.func.isRequired,
  data:PropTypes.array,
  t: PropTypes.func
};
