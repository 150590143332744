/**
 * Get parameter from url.
 * @param url - to parse
 * @param parameter - to find
 * @returns {string}
 */
export const getParameterFromUrl = (url, parameter)=> {
    try {
        let urlObject = new URL(url);
        let search_params = new URLSearchParams(urlObject.search);
        if (search_params.has(parameter))
            return search_params.get(parameter);
        else
            return "";
    }catch(err){
        return "";
    }
};