import axios from 'axios'

import {
  IMAGE_INFO_REQUEST,
  IMAGE_INFO_FAIL,
  IMAGE_INFO_SUCCESS,
  LABELMAP_IMAGE_SUCCESS, ANNOTATION_IMAGE_FAIL, ANNOTATION_IMAGE_REQUESTED,
  ANNOTATION_IMAGE_SUCCESS, LABELMAP_IMAGE_REQUESTED, ANNOTATION_IMAGE_SET_QUANTILES,
} from './actionType'
import {HOST_URL} from "../../../Constants";
import ReaderFactory from "../../vtk/ReaderFactory";
import extensionToImageIO from "itk/extensionToImageIO";
import vtkITKImageReader from "vtk.js/Sources/IO/Misc/ITKImageReader/index";
import readImageArrayBuffer from "../../vtk/readImageArrayBuffer";
// import vtkHttpDataAccessHelper from "vtk.js/Sources/IO/Core/DataAccessHelper/HttpDataAccessHelper";
import {convertToLPS} from "../../vtk/convertToLPS";
import writeImageArrayBuffer from "itk/writeImageArrayBuffer";
import itkreadImageDICOMFileSeries from "itk/readImageDICOMFileSeries";
import {convertVtkToItkImage} from "../../vtk/convertVtkToItk";
import untar from "js-untar";
import vtkITKHelper from "vtk.js/Sources/Common/DataModel/ITKHelper/index";
import store from "../../Store";
import pako from "pako";
import {updateScenesOnInputLoaded} from "./SceneAction";


//ITK versions until 10.0 requires this
// const extensions = Array.from(
//     new Set(Object.keys(extensionToImageIO).map((ext) => ext.toLowerCase()))
// );
// extensions.forEach((extension) => {
//   ReaderFactory.registerReader({
//     extension,
//     name: `${extension.toUpperCase()} Reader`,
//     vtkReader: vtkITKImageReader,
//     binary: true,
//     fileNameMethod: 'setFileName',
//   });
// });




vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer); //required by external library
extensionToImageIO.forEach((value,extension) => {
    ReaderFactory.registerReader({
        extension,
        name: `${extension.toUpperCase()} Reader`,
        vtkReader: vtkITKImageReader,
        binary: true,
        fileNameMethod: 'setFileName',
    });
});


//Image info actions********************************************************
const requestImageInfo = (idImage) => ({
	type: IMAGE_INFO_REQUEST,
	idImage
});

const errorImageInfo = err => ({
	type: IMAGE_INFO_FAIL,
	err
});

const successImageInfo = (idImage) => ({
	type: IMAGE_INFO_SUCCESS,
	idImage
});

// Get an image
//add IDs and right get html

export const getImageInfo = (idImage) => {
	return dispatch => {
		dispatch (requestImageInfo(idImage));
		return axios.get(HOST_URL+'/api/imageInfo',
			{ params: idImage }
			).then(response => {
			if(response.status !== 200) {
				dispatch (errorImageInfo(response.data));
				return Promise.reject(response.data);
			} else {
				dispatch (successImageInfo(id));
			}
		}).catch(err => dispatch (errorImageInfo(err)));
	};
};

//*********************** Image actions *****************************************************
/**
 * An action informing the reducers that the request finished successfully.
 * @param imageData
 * @param imageId
 * @returns {{type, imageData: *}}
 */

const successAnnotationImage = (imageData,imageId) => ({
    type: ANNOTATION_IMAGE_SUCCESS,
    imageData,
    imageId
});


const errorAnnotationImage = (error,imageId) => ({
    type: ANNOTATION_IMAGE_FAIL,
    error,
    imageId
});

const requestAnnotationImage = (imageId) => ({
    type: ANNOTATION_IMAGE_REQUESTED,
    imageId
});

const successLabelMapImage = (imageData,imageId) => ({
    type: LABELMAP_IMAGE_SUCCESS,
    imageData,
    imageId
});

const requestLabelMapImage = (imageId) => ({
    type: LABELMAP_IMAGE_REQUESTED,
    imageId
});

const errorLabelMapImage = (error,imageId) => ({
    type: LABELMAP_IMAGE_REQUESTED,
    imageId,
    error
});


/**
 * ActionCreator for saving overlay image.
 * @param vtkImageData - data to save
 * @param id - task id (?)
 * @returns {function(*)}
 */
export const saveOverlayData = (vtkImageData,id) => {
    return (dispatch) => {
        console.log("Saving image", vtkImageData);
        let itkImage = convertVtkToItkImage(vtkImageData);
        writeImageArrayBuffer(null, true, itkImage, 'image.nii').then(
            ({arrayBuffer,webWorker})=>{
                const config = {
                    headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
                };
                let blobbed = new Blob([arrayBuffer]);
                const data = new FormData();
                data.append('file', blobbed, id+'.nii');
                axios.post(HOST_URL + '/api/upload', data,config)
                    .then(response => {
                        console.log('ProjectAction.js :: getProjectList :: response ::', response)
                        if (response.status !== 200) {
                            // dispatch(failProjectList(err))
                            console.log(err);
                        } else {
                            //dispatch(successProjectList(response.data))
                        }
                    }).catch(error => {
                    console.log('project err:', error);
                    //  dispatch(failProjectList(error))
                });
            }
        )
    }
};


/**
 * Helper function for saving overlay image.
 * @param vtkImageData - data to save
 * @param id - task id (?)
 * @return Promise
 *
 */
export const saveImageEntity = (vtkImageData,id) => {
        console.log('ImageAction.js :: saveImageEntity :: before request ::', vtkImageData);
        let itkImage = convertVtkToItkImage(vtkImageData);
        return writeImageArrayBuffer(null, true, itkImage, 'image.nii.gz').then(
            ({arrayBuffer,webWorker})=>{
                const config = {
                    headers: {
                        'Authorization': "bearer" + store.getState().auth.token_bearer,
                        'Content-Type':"application/octet-stream"
                    }
                };
                let blobbed = new Blob([arrayBuffer]);
                const data = new FormData();
                data.append('file', blobbed, id+'.nii');
                return axios.put(HOST_URL + `/api/image-entity/${id}/file-multipart-blob/nii.gz`, data,config);
            }
        )
    };


//**
//  * ActionCreator for saving overlay image.
//  * @param vtkImageData - data to save
//  * @param id - task id (?)
//  * @returns {function(*)}
//  */
// export const saveOverlayData = (vtkImageData,id) => {
//     return (dispatch) => {
//         console.log("Saving image", vtkImageData);
//         let itkImage = convertVtkToItkImage(vtkImageData);
//         writeImageArrayBuffer(null, true, itkImage, 'image.nii').then(
//             ({arrayBuffer,webWorker})=>{
//                 const config = {
//                     headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
//                 };
//                 let blobbed = new Blob([arrayBuffer]);
//                 const data = new FormData();
//                 data.append('file', blobbed, id+'.nii');
//                 axios.post(HOST_URL + '/upload', data,config)
//                     .then(response => {
//                         console.log('ProjectAction.js :: getProjectList :: response ::', response);
//                         if (response.status !== 200) {
//                             // dispatch(failProjectList(err))
//                             console.log(err);
//                         } else {
//                             //dispatch(successProjectList(response.data))
//                         }
//                     }).catch(error => {
//                     console.log('project err:', error);
//                     //  dispatch(failProjectList(error))
//                 });
//             }
//         )
//     }
//
// };
//


/**
 * ActionCreator for loading MRI image.
 * @param imageId - id of image to load
 * @param format - format like ".nii",".tar.gz"
 * @param url - url to read
 * @param type - enum["image" or "overlay"] - whether
 * @returns {function(*,*)}
 */
export const loadImageData = (imageId, format, url, type="image") => {
    return async (dispatch,getState) =>{
        console.log("Reading image");
        const imagePool = (type==="image")
            ?getState().visu.images.images
            :getState().visu.images.overlays;
        if (imagePool[imageId]!=null)  //return if already exists
            return;
        let requestFunc = (type==="image")?requestAnnotationImage:requestLabelMapImage;
        let successFunc = (type==="image")?successAnnotationImage:successLabelMapImage;
        let errorFunc = (type==="image")?errorAnnotationImage:errorLabelMapImage;

        const options={
            headers: {
                'Authorization': getState().auth.token_bearer,
                'Pragma':'no-cache',
                'Cache-Control':'no-cache, no-store, must-revalidate',
                'Expires':'0'
            }
        };
        let _format = format;
        try{


          const ff = await parseImageFormat(imageId, options);
          if (ff!=null)
            _format = ff;

        }
        catch(err){
          console.log("Format when error:",format)
        }
        dispatch (requestFunc(imageId));
        console.log("Format:",format);
        if (_format.includes("tar.gz") || _format.includes("dicom.gz") || _format.includes("dicom.tgz")){

            ReaderFactory.fetchBinary(url,{options}).
            // RequestService.getBinaryRequest(url,options).
            then((arrayBuffer) => {
                const buf = pako.inflate(arrayBuffer);
                let files=null;
                untar(buf.buffer) // extract arrayBuffer from array and untar it
                    .then(
                        function(extractedFiles) {
                            files= extractedFiles.map((el)=>{return new File([el.buffer],el.name)});
                            itkreadImageDICOMFileSeries(files)
                                .then(({ image: itkImage, webWorker }) => {
                                    const data = vtkITKHelper.convertItkToVtkImage(itkImage);
                                    if (data!=null) {
                                        let imageData = {data:data};
                                        imageData = Object.assign(imageData,convertToLPS(data));
                                        dispatch(successFunc(imageData, imageId));
                                    }
                                    else
                                        dispatch(errorFunc("Can't convert ITK to VTK format",imageId));
                                    dispatch(updateScenesOnInputLoaded(imageId));
                                })
                                .catch((err)=>{
                                    console.log(err);
                                    dispatch(errorFunc("Can't read ITK",imageId))});
                                    dispatch(updateScenesOnInputLoaded(imageId));
                        },
                        function(err) { // onError
                            dispatch(errorFunc(err,imageId));
                        },
                    );
            })
                .catch(()=>{dispatch(errorFunc("Can't read binaries",imageId))});
        }
        else {
            // RequestService.getBinaryRequest(url,options)
            ReaderFactory.downloadDataset("or."+_format, url, null,options)
            // .then((rawData) => {ReaderFactory.readRawData({ _format, data: rawData })
                .then((result) => {
                    const reader = result.reader;
                    const data = reader.getOutputData();
                    if (data!=null){
                        let imageData = {data:data};
                        imageData = Object.assign(imageData,convertToLPS(data));
                        dispatch(successFunc(imageData, imageId));
                    } else dispatch(errorFunc(data,imageId));
                    dispatch(updateScenesOnInputLoaded(imageId));
                })
        }
    };
};

/**
 *  Add overlay labelmap to Store.
 *  Used to set labelmap when it is created in frontEnd (scene contains labelmap not provided in input).
 * @param imageData
 * @param key - Store key
 * @return {function(*, *)}
 */
export const initLabelMapInStore = (imageData,key) => {
    return (dispatch) => {
        let imageData2 = {data:imageData};
        imageData2 = Object.assign(imageData2,convertToLPS(imageData)); //creates additional LPS props in fast way
      dispatch(requestLabelMapImage(key));    //it needs to be called ALWAYS since it is required for pool checking in reducer
      dispatch(successLabelMapImage(imageData2,key));
    }
};

/**
 * An action informing the reducers that the request finished successfully.
 * @param quantiles
 * @param imageId
 * @returns {{type, imageData: *}}
 */

export const setQuantiles = (quantiles,imageId) => ({
  type: ANNOTATION_IMAGE_SET_QUANTILES,
  quantiles,
  imageId
});


/**
 * parse image format based on imageEntity document.
 *
 * the returned value will be:
 * 1. format extracted from pathToFile property, or (if not exist)
 * 2. format extracted from path property, or (if not exist)
 * 3. format property (it should be of first priority but some data might be inconsistent in DB)
 * @param id
 * @param options
 * @return {Promise<string|*|string[]|null>}
 */
const parseImageFormat = async (id,options)=>{
  const url = HOST_URL + `/api/image-entity/${id}`;
  const response = await axios.get(url, options);

  const imageEntity = response.data;
  if (imageEntity !=null && imageEntity.pathToFile != null)
    return extractFormat(imageEntity.pathToFile);

  const prop = Object.keys(imageEntity).find(key=>key.includes("path"));
  if (prop!=null)
    return extractFormat(imageEntity[prop]);

  return response.data.format;
};

const extractFormat = (pathToFile)=>{
  if (pathToFile!=null){
    const l = pathToFile.split(".");
    if (l.length>2)
      return l[l.length-2].concat(".",l[l.length-1]);
    else if (l.length>1)
      return l[l.length-1];
    else
      return l;
  }
  else return null ;
};