import React from 'react';
import PropTypes from "prop-types";
import {Prompt, withRouter} from "react-router";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {InputText} from "primereact/components/inputtext/InputText";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {getNestedProp, setNestedProp} from "../../helpers/expressions";
import {Button} from "primereact/components/button/Button";
import {uuidv4} from "../../helpers/strings";
import {deletePropertyPath, renameKey} from "../../helpers/properties";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";



class ScenesConfigurationTabContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeViewerIndex:0
    };
    ["onTabChange","listItem","onAdd"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  onTabChange(e){
    if (e.index!==this.state.activeViewerIndex) {
      this.setState({activeViewerIndex: e.index});
    }
  }

  onAdd(path,object){
    const {transferObject,updateTransferObject} = this.props;
    const key =uuidv4();
    setNestedProp(path.concat(key),object, transferObject);
    updateTransferObject(transferObject);
  }


  listItem(item,symbol,path,index){
    const {transferObject,updateTransferObject} = this.props;
    return (
      <div style={{display:"inline-flex"}} className={"ui-inputgroup"}>
                <span className="ui-inputgroup-addon" style={{fontWeight:"bold"}}>
                  {symbol}
                </span>
        <InputText size={15} value={item} title={"Key"} readOnly={true} />
        <span className="ui-inputgroup-addon" >
               <i className="fa fa-close" onClick={()=>{
                 let array = getNestedProp(path,transferObject);
                 array.splice(index,1);
                 setNestedProp(path,array,transferObject);
                 updateTransferObject(transferObject);
               }}/>
        </span>
      </div>
    );
  }

  render() {
    const {transferObject,updateTransferObject,viewersConfiguration,messageQueue} = this.props;
    const pathToInputs = ['miniWorkflow','currentTool','inputs'];




    const renderScenes = ()=>{
      const pathToElement =['miniWorkflow','currentTool','configuration','scenes'];



      if (getNestedProp(pathToElement,transferObject)!=null)
        return   Object.keys(getNestedProp(pathToElement,transferObject)).map((el)=>{


          const elementsInScene = (type,symbol)=> {
            const ovs = getNestedProp(pathToElement.concat(el,"rois",type),transferObject);
            if (ovs!=null)
              return ovs.map((ov,index)=>{return this.listItem(ov,symbol,pathToElement.concat(el,"rois",type),index)});
            else return <React.Fragment/>;
          };


          return (
            <div style={{marginBottom:"1.5em"}}
              onDragOver={ev=>ev.preventDefault()}
              onDrop={(e)=>{
                   if (e.dataTransfer!=null && e.dataTransfer.getData('type')!=null){
                     const type = e.dataTransfer.getData('type');
                     if (type ==="overlays" || type ==="masks" || type ==="geometricalROIs"){
                       let arr = getNestedProp(pathToElement.concat(el,"rois",type),transferObject);
                       if (arr!=null){
                         arr.push(e.dataTransfer.getData("value"));
                         setNestedProp(pathToElement.concat(el,"rois",type),arr ,transferObject);
                       }
                       else{
                         setNestedProp(pathToElement.concat(el,"rois",type),[e.dataTransfer.getData("value")] ,transferObject);
                       }
                       updateTransferObject(transferObject);
                     }
                   }
                 }
                 }>
              <InputText value={el} title={"Key"}  onChange={
                (e)=>{
                  setNestedProp(pathToElement, renameKey(getNestedProp(pathToElement,transferObject),el,e.currentTarget.value),transferObject);
                  updateTransferObject(transferObject);}
              }/>
              <Dropdown value={getNestedProp(pathToElement.concat(el,"primaryImageInputKey"),transferObject)}
                        options={Object.keys(getNestedProp(pathToInputs,transferObject))
                          .filter(f=>getNestedProp(pathToInputs.concat(f,'type'),transferObject)==="imageEntityInOut")
                          .map(input=>{
                            return{value:input, label:input}
                          })}
                        onChange={e=>{
                          setNestedProp(pathToElement.concat(el,"primaryImageInputKey"), e.value, transferObject);
                          updateTransferObject(transferObject);
                        }
                        }
              />
              <Button icon={"fa fa-trash"} onClick={
                ()=>{
                  deletePropertyPath(transferObject, pathToElement.concat(el));
                  updateTransferObject(transferObject);
                }
              }/>
              <div style={elementStyle} title={"Drop overlays, masks and geometrical ROIs here"}>
              {elementsInScene("overlays","O")}
              {elementsInScene("masks","M")}
              {elementsInScene("geometricalROIs","G")}
              </div>

            </div>
          );
        });
      else return<React.Fragment/>


    };

    const renderElementProps = (pathToElement,inputType,inputKey)=>{



      if (getNestedProp(pathToElement,transferObject)!=null)
        return   Object.keys(getNestedProp(pathToElement,transferObject)).map((el)=>{
          return (
            <div style={{...elementStyle,cursor:"grab"}}
                 draggable={true}
                 onDragStart={(e)=>{
                   e.dataTransfer.setData("type", pathToElement[pathToElement.length-1]);
                   e.dataTransfer.setData("value", el);
                 }}
                 onDragOver={e=>e.preventDefault()}
                 onDrop={(e)=>{
                   if (e.dataTransfer!=null && e.dataTransfer.getData('type')!=null){
                     const type = e.dataTransfer.getData('type');
                     if (type ==="luts"){
                       setNestedProp(pathToElement.concat(el,"lutKey"),e.dataTransfer.getData("value") ,transferObject);
                       updateTransferObject(transferObject);
                     }
                     if (type ==="lutDescriptions"){
                       setNestedProp(pathToElement.concat(el,"lutDescriptionKey"),e.dataTransfer.getData("value") ,transferObject);
                       updateTransferObject(transferObject);
                     }
                   }
                 }
                 }>

              <div style={{display:"inline-flex"}} className={"ui-inputgroup"}>
                <span className="ui-inputgroup-addon" style={{fontWeight:"bold"}}>
                  { pathToElement[pathToElement.length-1].charAt(0).toUpperCase()}
                </span>
                <InputText size={15} value={el} title={"Key"}   onChange={
                  (e)=>{
                    setNestedProp(pathToElement, renameKey(getNestedProp(pathToElement,transferObject),el,e.currentTarget.value),transferObject);
                    updateTransferObject(transferObject);}
                }/>
                {/*<span className="ui-inputgroup-addon" title={"Drag&drop"}  >*/}
                {/*                <i className="fa fa-hand-rock-o"/>*/}
                {/*</span>*/}
              </div>

              <span title={"Is set from input?"}>
                  <Checkbox checked={getNestedProp(pathToElement.concat(el,'fromInputs'),transferObject)}
                            onChange={(e) => {
                              setNestedProp(pathToElement.concat(el,'fromInputs'), e.checked, transferObject);
                              if (!e.checked)
                                deletePropertyPath(transferObject,pathToElement.concat(el,inputKey));
                              updateTransferObject(transferObject);
                            }
                            }
                  />
                </span>
              {getNestedProp(pathToElement.concat(el,'fromInputs'),transferObject) &&
              <Dropdown value={getNestedProp(pathToElement.concat(el,inputKey),transferObject)}
                        options={Object.keys(getNestedProp(pathToInputs,transferObject))
                          .filter(f=>getNestedProp(pathToInputs.concat(f,'type'),transferObject)===inputType)
                          .map(input=>{
                            return{value:input, label:input}
                          })}
                        onChange={e=>{
                          setNestedProp(pathToElement.concat(el,inputKey), e.value, transferObject);
                          updateTransferObject(transferObject);
                        }
                        }
              />
              }
              <Button icon={"fa fa-trash"} onClick={
                ()=>{
                  deletePropertyPath(transferObject, pathToElement.concat(el));
                  updateTransferObject(transferObject);
                }
              }/><br/>
              {(pathToElement[pathToElement.length-1]==="overlays" || pathToElement[pathToElement.length-1]==="masks") &&
                <React.Fragment>
                  <InputText size={15} value={getNestedProp(pathToElement.concat(el,"label"),transferObject)}
                             title={"Label"}   onChange={
                    (e)=>{
                      setNestedProp(pathToElement.concat(el,"label"), e.currentTarget.value,transferObject);
                      updateTransferObject(transferObject);}
                  }/>
                  <Dropdown value={getNestedProp(pathToElement.concat(el,"lutKey"),transferObject)}
                        options={Object.keys(getNestedProp(['miniWorkflow','currentTool','configuration',"luts"],transferObject)!=null?
                          getNestedProp(['miniWorkflow','currentTool','configuration',"luts"],transferObject):[])
                          .map(input=>{
                            return{value:input, label:input}
                          })}
                        onChange={e=>{
                          setNestedProp(pathToElement.concat(el,"lutKey"), e.value, transferObject);
                          updateTransferObject(transferObject);
                        }
                        }
              />
                  <Dropdown value={getNestedProp(pathToElement.concat(el,"lutDescriptionKey"),transferObject)}
                            options={Object.keys(getNestedProp(['miniWorkflow','currentTool','configuration',"lutDescriptions"],transferObject)!=null?
                              getNestedProp(['miniWorkflow','currentTool','configuration',"lutDescriptions"],transferObject):[])
                              .map(input=>{
                                return{value:input, label:input}
                              })}
                            onChange={e=>{
                              setNestedProp(pathToElement.concat(el,"lutDescriptionKey"), e.value, transferObject);
                              updateTransferObject(transferObject);
                            }
                            }
                  />

                </React.Fragment>
              }
            </div>
          );
        });
        else return<React.Fragment/>
    };


    return (
      <div className={"ui-g"}>
        <div className={"ui-g-4"}>
          <h3>Lookup Tables</h3>
          {renderElementProps(['miniWorkflow','currentTool','configuration',"luts"],"lookUpTable","lutInputKey")}
          <Button icon={"fa fa-plus"} title={"Add Lookup Table"}
                  onClick={ ()=> this.onAdd(['miniWorkflow','currentTool','configuration','luts'],{"fromInputs":false}) }
          />
          <br/>
          <h3>Lookup Table Descriptions</h3>
          {renderElementProps(['miniWorkflow','currentTool','configuration',"lutDescriptions"],"lookUpTableDescription","lutDescriptionInputKey")}
          <Button icon={"fa fa-plus"} title={"Add Lookup table description"}
                  onClick={ ()=> this.onAdd(['miniWorkflow','currentTool','configuration','lutDescriptions'],{"fromInputs":false}) }
          />

        </div>
        <div className={"ui-g-4"}>
        <h3>Overlays</h3>
          {renderElementProps(['miniWorkflow','currentTool','configuration',"rois","overlays"],"roiInOut","imageInputKey")}
          <Button icon={"fa fa-plus"} title={"Add overlay"}
                  onClick={ ()=> this.onAdd(['miniWorkflow','currentTool','configuration','rois','overlays'],{"fromInputs":false}) }
          />
        <h3>Masks</h3>
          {renderElementProps(['miniWorkflow','currentTool','configuration',"rois","masks"],"roiInOut","imageInputKey")}
          <Button icon={"fa fa-plus"} title={"Add overlay"}
                  onClick={ ()=> this.onAdd(['miniWorkflow','currentTool','configuration','rois','masks'],{"fromInputs":false}) }
          />
        <h3>Geometrical ROIs</h3>
          {renderElementProps(['miniWorkflow','currentTool','configuration',"rois","geometricalROIs"],"roiInOut","imageInputKey")}
          <Button icon={"fa fa-plus"} title={"Add overlay"}
                  onClick={ ()=> this.onAdd(['miniWorkflow','currentTool','configuration','rois','geometricalROIs'],{"fromInputs":false}) }

          />

        </div>
        <div className={"ui-g-4"}>
        <h3>Scenes</h3>
          {renderScenes()}
          <Button icon={"fa fa-plus"} title={"Add scene"}
                  onClick={ ()=> this.onAdd(['miniWorkflow','currentTool','configuration','scenes'],{}) }
          />

        </div>
      </div>
    )
  };
}
export default withRouter(ScenesConfigurationTabContent);

const elementStyle = {minHeight: "3em",backgroundColor: "azure", border: "1px solid",   borderRadius: "1em",
  padding: "0.1em 0 0 .5em", margin: "0 0.5em 0.5em 0"};

ScenesConfigurationTabContent.propTypes= {
  transferObject: PropTypes.object.isRequired,
  messageQueue:PropTypes.object.isRequired,
  updateTransferObject: PropTypes.func.isRequired,
  updateIsChangedFlag: PropTypes.func.isRequired
};
