import {
  ONTOLOGY_LIST_REQUEST_SUCCESS,
  ONTOLOGY_LIST_REQUEST_FAILED,
  ONTOLOGY_LIST_REQUESTED,
  ONTOLOGY_SAVE_REQUEST,
  CLEAR_ONTOLOGY_SAVE, ONTOLOGY_SAVE_FAIL, ONTOLOGY_SAVE_SUCCESS
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


/**
 * }
 * @param state
 * @param action
 * @return {{polys: {}}|({} & {polys: {}} & {luts: any})}
 */
export const ontologyReducer = (state = {
  ontologyList:[],ontology:{},ontologyListError:{},ontologyError:{},ontologyListState:"",ontologyState:""
}, action) => {
  switch (action.type) {
    case ONTOLOGY_LIST_REQUESTED:{
      return Object.assign({},state, {
        ontologyListState: REQUEST_STATUS_REQUESTED
      });
    }
    case ONTOLOGY_LIST_REQUEST_FAILED:{
      return Object.assign({}, state, {
        ontologyListState:REQUEST_STATUS_FAIL,
        ontologyListError:action.err
      });
    }

    case ONTOLOGY_LIST_REQUEST_SUCCESS:{
      return Object.assign({}, state, {
        ontologyListState:REQUEST_STATUS_SUCCESS,
        ontologyList:action.ontologies
      });
    }

    default:
      return state;
  }
};


export const saveOntologyReducer = (state = {

  saveOntologyState:"",
  saveOntologyError:{},
  saveOntologyResponse:{}

},action) => {
  switch (action.type) {
    case ONTOLOGY_SAVE_REQUEST:{
      return Object.assign({}, state, {
        saveOntologyState: REQUEST_STATUS_REQUESTED
      });
    }
    case ONTOLOGY_SAVE_FAIL:{
      return Object.assign({}, state, {
        saveOntologyState: REQUEST_STATUS_FAIL,
        saveOntologyError: action.err
      });
    }
    case ONTOLOGY_SAVE_SUCCESS:{
      return Object.assign({}, state, {
        saveOntologyState: REQUEST_STATUS_SUCCESS,
        saveOntologyResponse: action.data
      });
    }
    case CLEAR_ONTOLOGY_SAVE:{
      return Object.assign({}, state, {
        saveOntologyState:"",
        saveOntologyError:{},
        saveOntologyResponse:{}
      });
    }
    default:
      return state;
  }
};
