/**
 *  TODO  make function of user parameter (so that user picture can be provided to screen)
 */
export const MOCKED_EXPERTS_DATA = (email)=>{ return  {
    "results": [
        {   "experience": 0,
            "name": "GAME",
            "timestamp": "2019-03-06T23:27:41.775Z",
            "submitted": true,
            "userEmail": email,// "ECTRIMSV2_5-2075@spinegame.org",//email here
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "f7f0b7ea-d2e0-4696-9276-46ec4cfaa573",
                "volume": 1.674
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Max",
                    "lastname": "Wallack",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "radiology",
                    "eduLevel": "undergraduate",
                    "experience": "none",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e105812c"
        },
        { "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-07T00:10:25.612Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2079@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "74901023-b496-4de7-97ca-5dcaba0b39cd",
                "volume": 1.394
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Michele",
                    "lastname": "Cavallari",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "neurology",
                    "eduLevel": "phd",
                    "experience": "expert",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e105879f"
        },
        {   "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-10T11:17:17.782Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2243@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "48c98b31-d9e1-42f6-99c8-aaa9b8300b51",
                "volume": 1.450
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "francois",
                    "lastname": "cotton",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "radiology",
                    "eduLevel": "phdmd",
                    "experience": "expert",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e10b8f00"
        },
        {  "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-10T22:47:43.594Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2259@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "718fa481-2152-492c-acb7-a7c8ff67d193",
                "volume": 1.355
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Thomas",
                    "lastname": "Tourdias",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "radiology",
                    "eduLevel": "phdmd",
                    "experience": "expert",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e10cb957"
        },
        {  "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-11T20:14:49.816Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2320@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "4902f7ae-7c33-4653-a8cc-09d704e357f4",
                "volume": 1.323
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Miklos",
                    "lastname": "Palotai",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "radiology",
                    "eduLevel": "md",
                    "experience": "expert",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e10ee072"
        },
        {  "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-12T04:45:20.499Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2322@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "f499abb9-cc69-47fe-8ed0-21b487fabedc",
                "volume": 1.516
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Pauline",
                    "lastname": "PRIN",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "neurology",
                    "eduLevel": "masters",
                    "experience": "medium",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e10eed8b"
        }
    ],
    "roomName": "Experts",
    "roomDescription": "Segmentation from experts"
}};


export const MOCKED_DATA_FOR_AVATAR_PLOT = (email)=>{ return  {
    "results": [
        {   "experience": 0,
            "name": "GAME",
            "timestamp": "2019-03-06T23:27:41.775Z",
            "submitted": true,
            "userEmail": email,// "ECTRIMSV2_5-2075@spinegame.org",//email here
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "f7f0b7ea-d2e0-4696-9276-46ec4cfaa573",
                "volume": 1.674,
                "edss": 5.0
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Max",
                    "lastname": "Wallack",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "radiology",
                    "eduLevel": "undergraduate",
                    "experience": "none",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e105812c"
        },
        { "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-07T00:10:25.612Z",
            "submitted": true,
            "isCollectiveResult":true, // mock to annotate many users
            "numberOfUsers":3,
            "raters":[{
                "userEmail": "ECTRIMSV2_5-2079@spinegame.org"
            },{
                "userEmail": "ECTRIMSV2_5-2243@spinegame.org"
            },{
                "userEmail": "ECTRIMSV2_5-2259@spinegame.org"
            }],
            "userEmail": "ECTRIMSV2_5-2079@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "74901023-b496-4de7-97ca-5dcaba0b39cd",
                "volume": 1.394,
                "edss": 4.5
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Michele",
                    "lastname": "Cavallari",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "neurology",
                    "eduLevel": "phd",
                    "experience": "expert",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e105879f"
        },
        {   "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-10T11:17:17.782Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2243@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "48c98b31-d9e1-42f6-99c8-aaa9b8300b51",
                "volume": 1.450,
                "edss": 6.0
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "francois",
                    "lastname": "cotton",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "radiology",
                    "eduLevel": "phdmd",
                    "experience": "expert",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e10b8f00"
        },
        {  "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-10T22:47:43.594Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2259@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "718fa481-2152-492c-acb7-a7c8ff67d193",
                "volume": 1.355,
                "edss": 3.5
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Thomas",
                    "lastname": "Tourdias",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "radiology",
                    "eduLevel": "phdmd",
                    "experience": "expert",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e10cb957"
        },
        {  "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-11T20:14:49.816Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2320@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "4902f7ae-7c33-4653-a8cc-09d704e357f4",
                "volume": 1.323,
                "edss": 2.0
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Miklos",
                    "lastname": "Palotai",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "radiology",
                    "eduLevel": "md",
                    "experience": "expert",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e10ee072"
        },
        {  "experience": 1,
            "name": "GAME",
            "timestamp": "2019-03-12T04:45:20.499Z",
            "submitted": true,
            "userEmail": "ECTRIMSV2_5-2322@spinegame.org",
            "reference": {
                "skillId": 79,
                "toolId": 19,
                "roomId": "expert",
                "userTaskId": "c6970b7d-0ccd-4efe-b0fa-5bb93fb01751",
                "tabletExperimentId": "17de848e-f22e-4973-b6d8-a0031a6e437c"
            },
            "inputs": {
                "imageFileId": "8cb06aa1-9987-4cf2-b559-062f26212714"
            },
            "results": {
                "imageFileId": "f499abb9-cc69-47fe-8ed0-21b487fabedc",
                "volume": 1.516,
                "edss": 0.5
            },
            "userSnapshot": {
                "certification": null,
                "demographics": {
                    "name": "Pauline",
                    "lastname": "PRIN",
                    "nickname": "",
                    "profession": "medical",
                    "specialty": "neurology",
                    "eduLevel": "masters",
                    "experience": "medium",
                    "age": 7,
                    "gender": "F"
                }
            },
            "id": "b9e5860dc809e082903c96e3e10eed8b"
        }
    ],
    "roomName": "Experts",
    "roomDescription": "Segmentation from experts"
}};


export const MOCKED_SKILL_RESULTS = {
    "experts": [
    {
        "results": {
            "volume": 1.52
        },
        "userEmail": "ECTRIMSV2_5-2079@spinegame.org"
    },
    {
        "results": {
            "volume": 1.45
        },
        "userEmail": "ECTRIMSV2_5-2243@spinegame.org"
    },
    {
        "results": {
            "volume": 1.39
        },
        "userEmail": "ECTRIMSV2_5-2322@spinegame.org"
    },
    {
        "results": {
            "volume": 1.35
        },
        "userEmail": "ECTRIMSV2_5-2322@spinegame.org"
    },
    {
        "results": {
            "volume": 1.54
        },
        "userEmail": "ECTRIMSV2_5-2320@spinegame.org"
    }
],
    "user": [
    {
        "results": {
            "volume": 1.674
        },
        "userEmail": "admin@test.com"
    }
],
    "dice": 0.7460794127460794,
    "overlapLabelMapId": "47320796cab03852b61400298e0d1ff4",
    "originalImageId": "57320796cab03852b61400298e0d1ff4"
};