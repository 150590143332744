import {connect} from "react-redux";
import {withRouter} from "react-router";
import AvatarScatterPlotSidebar from "../../expDesign/component/d3components/AvatarScatterPlotSidebar";
import {
    clearInteractivePlotState, updateMultiPlotSettingsAC,
    updatePlotSettingsAC
} from "../action/InteractivePlotAction";
import MultiplotSidebar from "../../expDesign/component/d3components/MultiplotSidebar";
import MultiAvatarScatterPlotSidebar from "../../expDesign/component/d3components/MultiAvatarScatterPlotSidebar";
import MultiHistogramSidebar from "../../expDesign/component/d3components/MultiHistogramSidebar";





const mapStateToProps = (state,ownProps) => {
    return {
        plotSettings: state.visu.interactivePlot.plotSettings
    }
};

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        clearInteractivePlotState:()=>{
            dispatch(clearInteractivePlotState())
        },
        updatePlotSettings:(settings)=>{
            dispatch(updatePlotSettingsAC(settings))
        }
    }
};

const mapDispatchToProps2 = (dispatch,ownProps) => {
    return {
        clearInteractivePlotState:()=>{
            dispatch(clearInteractivePlotState())
        },
        updatePlotSettings:(settings,data)=>{
            dispatch(updateMultiPlotSettingsAC(settings,data))
        }
    }
};


const mapStateToProps2 = (state,ownProps) => {
    return {
        plotSettings: state.visu.interactivePlot.plotSettings,
        data:state.visu.interactivePlot.rawData,
        rawData:state.visu.interactivePlot.rawData,
        plotDataState:state.visu.interactivePlot.plotDataState,
    }
};


export const ContainerAvatarScatterPlotSidebar =withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)(AvatarScatterPlotSidebar));

export const ContainerMultiplotSidebar =withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(MultiplotSidebar));

export const ContainerMultiAvatarScatterPlotSidebar =withRouter(connect(
  mapStateToProps,
  mapDispatchToProps2)(MultiAvatarScatterPlotSidebar));

export const ContainerMultiHistogramSidebar =withRouter(connect(
  mapStateToProps2,
  mapDispatchToProps)(MultiHistogramSidebar));