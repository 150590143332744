import React from 'react';
import PropTypes from "prop-types";
import {Prompt, withRouter} from "react-router";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {InputText} from "primereact/components/inputtext/InputText";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {getNestedProp, setNestedProp} from "../../helpers/expressions";
import {Button} from "primereact/components/button/Button";
import {uuidv4} from "../../helpers/strings";
import {deletePropertyPath, renameKey} from "../../helpers/properties";
import coreSchema from "SPINE-json-schema/schemas/core.schema"
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";
import {getPortLabelByType, ToolIOTypes} from "../../visualization/action/ManualToolAction";




class IOConfigurationTabContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeViewerIndex:0
    };
    ["onTabChange","onAdd"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }

  onTabChange(e){
    if (e.index!==this.state.activeViewerIndex) {
      this.setState({activeViewerIndex: e.index});
    }
  }

  onAdd(path,object){
    const {transferObject,updateTransferObject} = this.props;
    const key =uuidv4();
    setNestedProp(path.concat(key),object, transferObject);
    updateTransferObject(transferObject);
  }

  render() {
    const {transferObject,updateTransferObject,messageQueue} = this.props;

    const renderElementProps = (pathToElement)=>{

      if (getNestedProp(pathToElement,transferObject)!=null)
        return   Object.keys(getNestedProp(pathToElement,transferObject)).map((el)=>{
          return (
            <div style={elementStyle} draggable={false}>
              <div >
                <InputText size={15} value={el} title={"Key"}   onChange={
                  (e)=>{
                    setNestedProp(pathToElement, renameKey(getNestedProp(pathToElement,transferObject),el,e.currentTarget.value),transferObject);
                    updateTransferObject(transferObject);}
                }/>
                <InputText size={15}
                           value={getNestedProp(pathToElement.concat(el,"name"),transferObject)}
                           title={"Name"}   onChange={
                  (e)=>{
                    setNestedProp(pathToElement.concat(el,"name"), e.currentTarget.value,transferObject);
                    updateTransferObject(transferObject);}
                }/>
                <InputTextarea rows={2}
                           value={getNestedProp(pathToElement.concat(el,"description"),transferObject)}
                           title={"description"}   onChange={
                  (e)=>{
                    setNestedProp(pathToElement.concat(el,"description"), e.currentTarget.value,transferObject);
                    updateTransferObject(transferObject);}
                }/>
                <span title={"Is list?"}>
                <Checkbox checked={getNestedProp(pathToElement.concat(el,'isList'),transferObject)}
                          onChange={(e) => {
                            setNestedProp(pathToElement.concat(el,'isList'), e.checked, transferObject);
                            updateTransferObject(transferObject);
                          }
                          }
                />
                </span>
                <Dropdown value={getNestedProp(pathToElement.concat(el,"type"),transferObject)}
                          //options={coreSchema.definitions.spineTypeInOut.enum  //TODO Change to SPINE-JSON-schema
                          options={ToolIOTypes
                            .map(input=>{
                              return{value:input, label:getPortLabelByType(input)}
                            })}
                          onChange={e=>{
                            setNestedProp(pathToElement.concat(el,"type"), e.value, transferObject);
                            updateTransferObject(transferObject);
                          }
                          }
                />
                {getNestedProp(pathToElement.concat(el,"type"),transferObject)==="roiInOut" &&
                <span title={"Is explicit?"}>
                <Checkbox checked={getNestedProp(pathToElement.concat(el,'typeROI'),transferObject)==="EXPLICIT"}
                          onChange={(e) => {
                            setNestedProp(pathToElement.concat(el,'typeROI'), e.checked?"EXPLICIT":"IMPLICIT", transferObject);
                            updateTransferObject(transferObject);
                          }
                          }
                />
                </span>}
                <span title={"Is required?"}>
                <Checkbox checked={getNestedProp(pathToElement.concat(el,'required'),transferObject)}
                          onChange={(e) => {
                            setNestedProp(pathToElement.concat(el,'required'), e.checked, transferObject);
                            updateTransferObject(transferObject);
                          }
                          }
                />
                </span>
              </div>

              <Button icon={"fa fa-trash"} onClick={
                ()=>{
                  deletePropertyPath(transferObject, pathToElement.concat(el));
                  updateTransferObject(transferObject);
                }
              }/><br/>
            </div>
          );
        });
      else return<React.Fragment/>
    };


    return (
      <div className={"ui-g"}>
        <div className={"ui-g-6"}>
          <h3>Inputs</h3>
          {renderElementProps(['miniWorkflow','currentTool',"inputs"])}
          <Button icon={"fa fa-plus"} title={"Add Input"}
                  onClick={ ()=> this.onAdd(['miniWorkflow','currentTool','inputs'],{}) }
          />
        </div>
        <div className={"ui-g-6"}>
          <h3>Outputs</h3>
          {renderElementProps(['miniWorkflow','currentTool',"outputs"])}
          <Button icon={"fa fa-plus"} title={"Add overlay"}
                  onClick={ ()=> this.onAdd(['miniWorkflow','currentTool',"outputs"],{}) }
          />
        </div>
      </div>
    )
  };
}
export default withRouter(IOConfigurationTabContent);

const elementStyle = {minHeight: "3em",backgroundColor: "azure", border: "1px solid",   borderRadius: "1em",
  padding: "0.1em 0 0 .5em", margin: "0 0.5em 0.5em 0"};

IOConfigurationTabContent.propTypes= {
  transferObject: PropTypes.object.isRequired,
  messageQueue:PropTypes.object.isRequired,
  updateTransferObject: PropTypes.func.isRequired,
  updateIsChangedFlag: PropTypes.func.isRequired
};
