import {updateViewerProperty} from "./ViewerAction";
import axios from "axios";


/**
 * Fetch audio stream as blob, and update viewer state blob property, so it can be used in native audio component
 * with src attribute.
 * @deprecated due to bad performance we do not get blobs for audio - instead the cookie is attached in audio crossorigin
 * @param key
 * @return {function(...[*]=)}
 */
export const fetchAudioStreamAsBlob = (key) => {
  return (dispatch, getState) => {

    const viewer = getState().visu.viewers.viewersState[key];

    const config = {
      headers: {'Authorization': "bearer" + getState().auth.token_bearer},
      responseType:'blob'
    };

    if (viewer!=null && viewer['src']!=null){
      axios.get(viewer['src'],config)
        .then(response => {
          dispatch(updateViewerProperty(key,"blob",URL.createObjectURL(response.data)))
        })
        .catch(err=> {
            console.log(err);
            const messageQueue = getState().messaging.msgQueue;
            messageQueue.show({
              sticky: false,
              life: 10000,
              severity: 'error',
              summary: 'Downloading error',
              detail: "Cannot download "+viewer['src']+". Details:" + err.message
            });
          }
        );
    }
  }
};


/**
 * Fetch audio stream as blob,  so it can be used in native audio component
 * with src attribute.
 * Version using callback instead of Redux state.
 * @deprecated due to bad performance we do not get blobs for audio - instead the cookie is attached in audio crossorigin
 * @param id
 * @param callback - optional parameter if you want to pass result outside Redux
 * @return {function(...[*]=)}
 */
export const fetchAudioStreamAsBlobWithCallback = (id,callback) => {
  return (dispatch, getState) => {
    const config = {
      headers: {'Authorization': "bearer" + getState().auth.token_bearer},
      responseType:'blob'
    };

    axios.get(`/api/audio-entity/${id}/file`,config)
        .then(response => {
          callback(URL.createObjectURL(response.data));
        })
        .catch(err=> {
            console.log(err);
            const messageQueue = getState().messaging.msgQueue;
            messageQueue.show({
              sticky: false,
              life: 10000,
              severity: 'error',
              summary: 'Downloading error',
              detail: "Cannot download "+id+". Details:" + err.message
            });
          }
        );

  }
};


/**
 * Fetch metadata from audio descriptor.
 * @param key
 * @param attributes {array}  - properties to be fetched
 * @return {function(...[*]=)}
 */
export const fetchSourceMetadata = (key, attributes) => {
  return (dispatch, getState) => {

    const viewer = getState().visu.viewers.viewersState[key];

    const config = {
      headers: {'Authorization': "bearer" + getState().auth.token_bearer},
      responseType:'string'
    };

    if (viewer!=null && viewer['src']!=null){
      const url = viewer['src'].replace("file","source-metadata")+"?attributes="+attributes.join(',');
      axios.get(url,config)
        .then(response => {
          dispatch(updateViewerProperty(key,"source-metadata",response.data))
        })
        .catch(err=> {
            console.log(err);
          }
        );
    }
  }
};