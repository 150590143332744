import React from "react";
import axios from 'axios';
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import HelpEditor from "../../helpEditor/component/HelpEditor";
import {ContainerPlayManually} from "../../livePresenter/container/ContainerSlides";
import {NonModalSidebar} from "../../root/component/NonModalSidebar";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";



export class TaskPropertiesHelpPanelTab extends React.Component {

  constructor(prop) {
    super(prop);
    this.state = {
      helpEditorVisible: false,
      editedHelpKey: null,
      playUniversalHelp: false,
      annotationTables:{}, //temporary object keeping annotation Tables assigned to current task
      annotationColumns:{} //temporary object keeping columns of annotation Tables assigned to current task
    };
    ["renderRow","updateColumns"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount(){
    this.updateColumns();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {columns,columnsState} = this.props;
    const {annotationColumns}=this.state;

    if(prevProps.columns!==columns && columnsState===REQUEST_STATUS_SUCCESS){
      if (columns!=null && Object.keys(columns).length>0){
        const atemp = Object.assign({},annotationColumns);
        Object.keys(columns).forEach((key)=>{atemp[key]=columns[key]});
        this.setState({annotationColumns:atemp})
      }
    }

  }

  updateColumns(){
    const {constants,getColumnsDefinition} = this.props;
    const {annotationTables} = this.state;
    if (constants!=null && getColumnsDefinition!=null){
    Object
      .values(constants)
      .filter((el)=>{return el.type==="annotationFormDefinitionInputOutput" || el.type==="annotationTableDefinitionInputOutput"})
      .forEach((el)=>{
        if (!annotationTables.hasOwnProperty(el.value)){
         getColumnsDefinition(el.value);
        }
      });
    }
  }


  renderRow(label, propertyName) {
    const {helpPanel, constants} = this.props;

    const isCreated = helpPanel != null && helpPanel[propertyName] != null;

    return (
      <tr className="help-link-table-row">
        <td className="help-link-table-td" {...(label ==="Main help" ? {style: {fontWeight:"bold"}} : {})}>{label}</td>
        <td className="help-link-table-td">
          <Button label={isCreated ? "View/Edit" : "Create"}
                  icon={"fa fa-edit"}
                  onClick={() => this.setState({helpEditorVisible: true, editedHelpKey: propertyName})}/>
        </td>
        {/*<td className="help-link-table-td">*/}
        {/*  <Button label="Preview" icon={"fa fa-eye"} disabled={!(isCreated)}/>*/}
        {/*</td>*/}
        {/*<td className="help-link-table-td">*/}
        {/*  <Button label="See instructions video" icon={"fa fa-eye"} disabled={!(isCreated)}/>*/}
        {/*</td>*/}
        {/*<td className="help-link-table-td">*/}
        {/*  <Button label="See tools video" icon={"fa fa-eye"} disabled={!(isCreated)}/>*/}
        {/*</td>*/}
        <td className="help-link-table-td">
          <Button label="Remove" icon={"fa fa-close"} disabled={!(isCreated)} onClick={()=>
          { delete helpPanel[propertyName];
            this.setState({});
          }}/>
        </td>
      </tr>)
  }


  render() {
    const {constants,helpPanel,taskData,updateHelpPanel} = this.props;
    const {playUniversalHelp,editedHelpKey,helpEditorVisible,annotationColumns} = this.state;


    const renderHelpPanel = (helpPanel!=null && Object.keys(helpPanel).length>0)
      ? Object.keys(helpPanel)
        .filter(key=>{return key!=="_mainTool"}) //main Tool is already rendered
        .map((key)=>{return this.renderRow(key,key)})
      : <React.Fragment/>;

    const renderColumns = Object.keys(annotationColumns)
      .filter((col)=>{return !(helpPanel!=null && helpPanel.hasOwnProperty(annotationColumns[col].name))}) //avoid repetitions to already created
      .map((col)=>{
        return this.renderRow(annotationColumns[col].name,annotationColumns[col].name)});


    return <div style={{display: "flex", height: "400px"}}>
      <div style={{width: "90%"}}>
        {playUniversalHelp &&
        <ContainerPlayManually
          predefined={"b669fdfdfdbbda98f6d485369b003c21"}
          slideShowClassName={"slideshow-embedded-div"}
          prevClassName={"prev-embedded-div"}
          nextClassName={"next-embedded-div"}
        />}
        {!playUniversalHelp &&
        <table className="help-link-table">
          <thead>
          </thead>
          <tbody>
          {this.renderRow("Main help", "_mainTool")}
          {renderHelpPanel}
          {renderColumns}
          </tbody>
        </table>}
      </div>
      <div style={{width: "10%", bottom: "0px", position: "relative", height: "inherit", textAlign: "center"}}>
        <i style={{fontSize: "xx-large", position: "absolute", bottom: "0px", color: "#00D2D0"}}
           className="fa fa-question-circle-o"
           onClick={() => this.setState({playUniversalHelp: !playUniversalHelp})}/>
      </div>


      <NonModalSidebar
        visible={this.state.helpEditorVisible}
        fullScreen={true}
        baseZIndex={1}
        onHide={() => this.setState({helpEditorVisible: false, editedHelpKey: null})}>
        <h1>Help Editor</h1>
        {helpEditorVisible &&
           <HelpEditor
             messageQueue={this.props.messageQueue}
             data={helpPanel!=null?helpPanel[editedHelpKey]:null}
             onClose={(data)=>{
               updateHelpPanel(data,editedHelpKey);
               this.setState({helpEditorVisible: false, editedHelpKey: null});
             }}
           />
        }
      </NonModalSidebar>
    </div>
  }
}

TaskPropertiesHelpPanelTab.propTypes = {
  columns: PropTypes.object.isRequired,
  columnsState: PropTypes.string.isRequired,
  helpPanel: PropTypes.object.isRequired,
  updateHelpPanel: PropTypes.func.isRequired,
  getColumnsDefinition: PropTypes.func.isRequired,
  constants: PropTypes.object.isRequired,
  taskData:  PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired //DRAFT/PUBLISHED
};