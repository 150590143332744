import React from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import {AutoComplete} from "primereact/components/autocomplete/AutoComplete";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {InputText} from "primereact/components/inputtext/InputText";
import {Button} from "primereact/components/button/Button";



/**
 * Component responsible for concept searchin with NCBO Bioportal API.
 *
 */
class ClassSearchInputText extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filteredNodes:null,
      requestStatus:""
    };
    ["onSearch"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }



  onSearch(){
    const{value,onResponse,onError,filteringOntologies}=this.props;
    this.setState({requestStatus:REQUEST_STATUS_REQUESTED});
    let filters="";
    if (filteringOntologies!=null && filteringOntologies.length>0){
      filters=filteringOntologies.reduce((prev,cur)=>{return prev.concat(cur,",")},"&ontologies=");
    }
    axios.get(`/api/ncbo/search?q=${value}${filters}&display_context=false&display_links=false`).then(response => {
      onResponse(response.data);
      this.setState({requestStatus:REQUEST_STATUS_SUCCESS});
    }).catch(err => {
      onError(err);
      this.setState({requestStatus:REQUEST_STATUS_FAIL});
    });

  }

  render() {
    const{value,onValueChange,inputGroupStyle}=this.props;
    const{requestStatus}=this.state;

    const icon = requestStatus===REQUEST_STATUS_REQUESTED? "fa fa-spinner fa-spin":"fa fa-search";

    return (
      <React.Fragment>
        <div className="ui-inputgroup" {...inputGroupStyle!=null?{style:inputGroupStyle}:{}}>
          <InputText    value={value}
                        onChange={(e) => onValueChange(e.target.value )}
          />
          <Button icon={icon} onClick={this.onSearch}/>
        </div>

      </React.Fragment>
    )
  }
}

export default ClassSearchInputText;

ClassSearchInputText.propTypes = {
  value:PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onError:PropTypes.func.isRequired,
  onResponse:PropTypes.func,
  filteringOntologies:PropTypes.array.isRequired,
  inputGroupStyle:PropTypes.object
};


