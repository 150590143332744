import React from "react";
import {withRouter} from "react-router";
import {Panel} from "primereact/components/panel/Panel";
import PropTypes from "prop-types";
import {Carousel} from "./Carousel";
import {Button} from "primereact/components/button/Button";
import ResizableSidebar from "../../../root/component/ResizableSidebar";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {Dialog} from "primereact/components/dialog/Dialog";



/**
 * This is page displaying contextual information about a given task and tool.
 *
 */
class ManualToolHelpPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: 0,
            sidebarWidth: 300,
            goalCollapsed: false,
            toolCollapsed: true,
            examplesCollapsed: true,
            imageVisible: false,
            image: null,
            toolsHeight: 300,
            dialogMode:false  // dialog or sidebar
        };
        ["helpImageTemplate", "getSubTaskDefinition", "onVideoClick"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            });
    }


    helpImageTemplate(image) {
        const helpObject = this.getSubTaskDefinition();
        return (
            <div className="car-details">
                <div className="ui-grid ui-nogutter">
                    <div className="ui-g-12" style={{textAlign: "center"}}>
                        <img src={image.src} alt={image.name} style={{maxHeight: "100px", maxWidth: "100px"}}/>
                    </div>
                    <div className="ui-g-12" style={{textAlign: "center"}}>
                        <div className="help-image-title" title={image.name}>{image.name}</div>
                        <div className="help-image-buttons">
                            <Button icon="fa  fa-search" className="ui-button-secondary"
                                    onClick={() => this.props.setImageVisible(image,helpObject.images)}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Parse definition of helppanel to get a content for active subtask or main task.
     * @return {null|*}
     */
    getSubTaskDefinition() {
        const {helpPanel, subTask} = this.props;
        if (subTask != null && subTask.name != null && helpPanel[subTask.name] != null) {
            return helpPanel[subTask.name];
        } else if (helpPanel["_main"] != null)
            return helpPanel["_main"];
        else return null;
    }

    onVideoClick(section) {
        const {onVideoLinkClick, helpPanel, subTask} = this.props;
        if (subTask != null && subTask.name != null && helpPanel[subTask.name] != null)
            onVideoLinkClick(helpPanel[subTask.name]['videos'][section]);
        else
            onVideoLinkClick(helpPanel["_main"]['videos'][section]);
    }

    render() {
        const {visible, onHide, helpUrl, helpPanel, subTask} = this.props;
        const {goalCollapsed, toolCollapsed, examplesCollapsed} = this.state;


        const _subTaskName = (subTask != null && subTask.name != null && helpPanel!= null && helpPanel[subTask.name] != null) ? subTask.name : '_main';

        const link = (section) => {
            if (helpPanel != null && helpPanel[_subTaskName] != null && helpPanel[_subTaskName]['videos'] != null && helpPanel[_subTaskName]['videos'][section] != null)
                return <a className="help-panel-subpanel-header"
                          onClick={() => this.onVideoClick(section)}> Watch video </a>;
            else return <React.Fragment/>;
        };


        // This snippet is kept to be compatible with old documents
        const oldPanel = <div className="taskToolHelpSidebar" style={{display: (visible) ? "block" : "none"}}>
            <i className="fa fa-close taskToolHelpSidebarClose" onClick={() => onHide()}/>
            <img src={helpUrl} className="taskToolHelpPanelContent" alt="This task does not require help."/>
        </div>;

        if (helpPanel != null) {
            let helpObject = this.getSubTaskDefinition();
            const goalHeader = <React.Fragment> Instructions {link("goalAndDefinition")}</React.Fragment>;
            const toolHeader = <React.Fragment> Tools {link("tools")}</React.Fragment>;
            if (!(helpObject != null))
                return <div/>;

            const helpPanelContent =  <div id="help-panel-content">
              <Panel id="help-panel-content-goal"
                     key="help-panel-content-goal"
                     header={goalHeader}
                     toggleable={true}
                     collapsed={goalCollapsed}
                     onCollapse={() => this.setState({goalCollapsed: !this.state.goalCollapsed})}
                     onExpand={() => this.setState({goalCollapsed: !this.state.goalCollapsed})}
              >
                <div id="help-panel-content-goal-inner"
                     dangerouslySetInnerHTML={{__html: helpObject['goalAndDefinition']}}/>
              </Panel>
              <Panel header="Examples"
                     id="help-panel-content-examples"
                     toggleable={true}
                     collapsed={examplesCollapsed}
                     onCollapse={() => this.setState({examplesCollapsed: !this.state.examplesCollapsed})}
                     onExpand={() => this.setState({examplesCollapsed: !this.state.examplesCollapsed})}
              >
                <Carousel value={helpObject.images}
                          itemTemplate={this.helpImageTemplate}
                          numVisible={3}
                          numScroll={3}
                          responsiveOptions={responsiveOptions}
                          {...(this.state.sidebarWidth != null
                            ? {windowWidth: this.state.sidebarWidth}
                            : {})}
                >
                </Carousel>
              </Panel>
              <div id="help-panel-content-tool-ref" ref={(div) => this.Wrapper = div}/>
              <Panel header={toolHeader}
                     id="help-panel-content-tool"
                     key="help-panel-content-tool"
                     toggleable={true}
                     collapsed={toolCollapsed}
                     onCollapse={() => this.setState({toolCollapsed: !this.state.toolCollapsed})}
                     onExpand={() => this.setState({toolCollapsed: !this.state.toolCollapsed})}
              >

                <TabView activeIndex={this.state.activeTabIndex}
                         onTabChange={(e) => this.setState({activeTabIndex: e.index})}>
                  <TabPanel header="Tools">

                      <div id="help-panel-content-tool-inner"
                           dangerouslySetInnerHTML={{__html: helpObject['tools']}}/>

                  </TabPanel>
                  <TabPanel header="Gestures">

                      <div id="help-panel-content-tool-inner"
                           dangerouslySetInnerHTML={{__html: helpObject['combos']}}/>

                  </TabPanel>
                  <TabPanel header="Forms">

                      <div id="help-panel-content-tool-inner"
                           dangerouslySetInnerHTML={{__html: helpObject['annotations']}}/>

                  </TabPanel>
                </TabView>
              </Panel>
            </div>;
          if (!this.state.dialogMode)
            return (<ResizableSidebar
                visible={visible}
                id="help-panel-sidebar"
                modal={false}
                onHide={() => onHide()}
                onUnpin={()=>this.setState({dialogMode:true})}
                defaultWidth={this.state.sidebarWidth}
                onWindowWidthChange={(el) => {
                    this.setState({sidebarWidth: el})
                }}
            >
              {helpPanelContent}
            </ResizableSidebar>);
          return (<Dialog
            onHide={()=>onHide()}
            closable={false}
            visible={this.state.dialogMode && visible}
            width={"600px"}
            height={"80vh"}
            contentStyle={{height:"calc(100% - 70px)"}}
            header={<span>Help Panel
                  <i style={{float:"right"}} className={"fa fa-fw fa-close"} onClick={()=>onHide()}/>
                  <i style={{float:"right"}} className={"fa fa-fw fa-thumb-tack"} onClick={()=>{this.setState({dialogMode:false})}}/>
                </span>}
          >{helpPanelContent}</Dialog>);
        } else return oldPanel;
    }
}

const responsiveOptions = [
    {
        breakpoint: '768px',
        numVisible: 3,
        numScroll: 3
    },
    {
        breakpoint: '560px',
        numVisible: 2,
        numScroll: 2
    },
    {
        breakpoint: '320px',
        numVisible: 1,
        numScroll: 1
    }
];

ManualToolHelpPage.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    helpUrl: PropTypes.string.isRequired,
    helpPanel: PropTypes.object,
    subTask: PropTypes.string,
    onVideoLinkClick: PropTypes.func,
    setImageVisible : PropTypes.func.isRequired
};
export default withRouter(ManualToolHelpPage);
