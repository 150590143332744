import {connect} from "react-redux";
import {
    addAnnotation, addSubAnnotationForActiveAnnotation,
    clearAnnotatorState,
    removeAnnotation,
    setActiveAnnotation,
    setSubAnnotation,
    updateAnnotationData,
    updateAnnotationFormData
} from "../action/AnnotationAction";
import ManualTool from "../component/annotationTool/ManualTool";
import {
    alignViewers, applyWL,
    changeOrientation, changeScene, clearViewersState, handleKeySlicing,
    pickerCallbackAC, updateSingleViewer,
    updateViewerProperty
} from "../action/ViewerAction";
import {saveOverlayData, setQuantiles} from "../action/ImageAction";
import {
    clearManualToolStateAC, initializePredefinedConfiguration, initializeTool,
    initializeTemporaryResults,
    updateManualToolProperty, saveOutputs
} from "../action/ManualToolAction";
import {getManualTask, getManualTaskLivePresenter, updateManualSubTask} from "../../dashboard/action/ManualTaskAction";
import {
    updateActiveColorInScene,
    updateActiveLayerIndex,
    updateColorMapInScene, updateInteractiveLayerIndex,
    updateScenes
} from "../action/SceneAction";
import {updateFormProperty} from "../action/FormsAction";
import {clearConnectomesState} from "../action/ConnectomeAction";


const mapStateToProps = (state,ownProps) => {
    return {
        columns: state.visu.annotations.annotationsDefinition,
        annotationsFormData: state.visu.annotations.annotationsFormData,
        annotationsData: state.visu.annotations.annotationsData,
        annotationsReadData: state.visu.annotations.annotationsReadData,
        readOnly: state.visu.annotations.readOnly,
        activeROI: state.visu.annotations.activeROI,
        manualToolState: state.visu.manualTool.manualToolState,
        manualToolConfiguration: state.visu.manualTool.manualToolConfiguration,
        manualTask: state.activity.manualTask.manualTask,
        subTask: state.activity.manualTask.subTask,
        manualTaskState: state.activity.manualTask.manualTaskState,
        images:state.visu.images.images,
        overlays:state.visu.images.overlays,
        polydatas:state.visu.polys.polys,
        connectomes:state.visu.connectomes.connectomes,
        imageOptions: state.visu.viewers.imageOptions,
        viewersState: state.visu.viewers.viewersState,
        formsState: state.visu.forms.formsState,
        scenes:state.visu.scenes.scenes,
        messageQueue: state.messaging.msgQueue
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    // TODO: Only add functions needed in case of readOnly config?
    return {
        updateFormProperty:(id,property,value)=>{
            dispatch(updateFormProperty(id,property,value))
        },
        saveOutputs:()=>{
            dispatch(saveOutputs())
        },
        updateActiveLayer:(s,l)=>{
            dispatch(updateActiveLayerIndex(s,l))
        },
        updateInteractiveLayer:(s,l)=>{
            dispatch(updateInteractiveLayerIndex(s,l))
        },
        updateActiveColorInScene:(value,sceneId,layerId)=>{
            dispatch(updateActiveColorInScene(value,sceneId,layerId))
        },
        updateColorMapInScene:(colormap,sceneId,layerIndex)=>{
        dispatch(updateColorMapInScene(colormap,sceneId,layerIndex))
        },
        updateAnnotationFormData:(data)=>{
            dispatch(updateAnnotationFormData(data))
        },
        updateAnnotationData:(data)=>{
            dispatch(updateAnnotationData(data))
        },
        rmCallback:(data)=>{
            dispatch(removeAnnotation(data))
        },
        saveOverlayData : (imageData,id)=>{
            dispatch(saveOverlayData(imageData,id))
        },
        setQuantiles:(quantiles, imageId)=>{
            dispatch(setQuantiles(quantiles,imageId))
        },
        addAnnotation : (roi,index)=>{
            dispatch(addAnnotation(roi,index))
        },
        setActiveAnnotation: (annotation)=>{
            dispatch(setActiveAnnotation(annotation,true))
        },
        setSubAnnotation: (annotation)=>{
            dispatch(setSubAnnotation(annotation))
        },
        addSubAnnotationForActiveAnnotation: (roi,index)=>{
            dispatch(addSubAnnotationForActiveAnnotation(roi,index))
        },
        clearAnnotatorState:()=>{
            dispatch(clearAnnotatorState())
        },
        updateViewer:(viewer,index)=>{
            dispatch(updateSingleViewer(viewer,index))
        },
        updateViewerProperty:(index,property,value)=>{
            dispatch(updateViewerProperty(index,property,value))
        },
        changeScene:(id,value)=>{
            dispatch(changeScene(id,value))
        },
        changeOrientation:(index,value)=>{
            dispatch(changeOrientation(index,value))
        },
        handleKeySlicing: (index,event)=>{
            dispatch(handleKeySlicing(index,event))
        },
        clearViewersState: () => {
            dispatch(clearViewersState())
        },
        initializePredefinedConfiguration:(conf)=>{
            dispatch(initializePredefinedConfiguration(conf))
        },
        initializeTool:(conf)=>{
            dispatch(initializeTool())
        },
        initializeTemporaryResults:(conf)=>{
            dispatch(initializeTemporaryResults())
        },
        clearManualToolState: () => {
            dispatch(clearManualToolStateAC())
        },
        updateManualToolProperty: (key, property, value) => {
            dispatch(updateManualToolProperty(key, property, value))
        },
        pickerCallback:(id,ijk)=>{
            dispatch(pickerCallbackAC(id,ijk))
        },
        getManualTask: (uuid, mfKey, expId) => {
            dispatch(getManualTask(uuid, mfKey, expId))
        },
        getManualTaskLivePresenter: (livePresenterExperimentId,
                                     currentStep,
                                     miniWorkflowSetId,
                                     miniWorkflowNameInSet) => {
            dispatch(getManualTaskLivePresenter(livePresenterExperimentId,
                currentStep,
                miniWorkflowSetId,
                miniWorkflowNameInSet))
        },
        updateManualSubTask: (name)=>{
            dispatch(updateManualSubTask(name))
        },
        alignViewers:()=>{
            dispatch(alignViewers())
        },
        wlCallback:(viewer,ww,level)=>{
            dispatch(applyWL(viewer,ww,level));
        },
        updateScenes:(scenes)=>{
            dispatch(updateScenes(scenes))
        },
        clearConnectomesState: () => {
            dispatch(clearConnectomesState())
        },
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerManualTool = containerCreator(ManualTool);

