export const SKILL_LIST_REQUEST = "SKILL_LIST_REQUEST";
export const SKILL_LIST_SUCCESS = "SKILL_LIST_SUCCESS";
export const SKILL_LIST_FAIL = "SKILL_LIST_FAIL";
export const SKILL_REQUEST = "SKILL_REQUEST";
export const SKILL_SUCCESS = "SKILL_SUCCESS";
export const SKILL_FAIL = "SKILL_FAIL";
export const SET_SKILL_VISIBILITY_FILTER = 'SET_SKILL_VISIBILITY_FILTER';
export const SET_SKILL_SORTER = 'SET_SKILL_SORTER';
export const SKILL_CREATE = "SKILL_CREATE";
export const SKILL_UPDATE_CURRENT_STEP_INDEX = "SKILL_UPDATE_CURRENT_STEP_INDEX";

export const SKILL_SLIDE_LIST_FAIL="SKILL_SLIDE_LIST_FAIL";
export const SKILL_SLIDE_LIST_REQUESTED="SKILL_SLIDE_LIST_REQUESTED";
export const SKILL_SLIDE_LIST_SUCCESS="SKILL_SLIDE_LIST_SUCCESS";

export const CLEAR_SKILL_STATE="CLEAR_SKILL_STATE";

export const SKILL_URL_MOCK_ROCHE_FR = "/skills/playskill/b9e5860dc809e082903c96e3e1bc3810";
export const SKILL_URL_MOCK_ROCHE_EN = "/skills/playskill/c7e5b1be77c99bb2760f176c47000e50";
// export const SKILL_URL_MOCK_ROCHE_FR_2 = "/skills/playskill/0cc385aa-1a96-4429-b02a-518722715ea5";
export const SKILL_URL_MOCK_ROCHE_FR_2 = "/skills/playskill/8f09b60a78cf2eb3a438674fec00fcab";