import React from "react";
import PropTypes from "prop-types";
import {colorBoxStyle} from "../../vtk/controlPanel/LabelmapStripBody";
import {Panel} from "primereact/components/panel/Panel";
import {GRAPH_TOOL_LABEL_PROPERTY_CODE} from "../../../Constants";

class GraphSelectedPanel extends React.Component {

  constructor(props) {
    super(props);
    ["renderNodeLabel","renderNode","renderEdgeProperties"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, ss) {
  }

  /**
   * Display label or hyperlink for node data
   * @param row
   * @return {*}
   */
  renderNodeLabel(row){
    const label =  row[GRAPH_TOOL_LABEL_PROPERTY_CODE];
    if (row["iri"]!=null)
      return(
        <a href={row["iri"]} target={"_blank"} title={"Click to see definition in Bioportal:" + row["iri"]}>
          {label}
        </a>
      );
    return  <span title={"No available definition"}>{label}</span>;
  }



  renderAttr(attr,row){
    let value = <div/>;
    if (attr["type"]==="number"){
      value = row[attr["property"]];
    }
    if (attr["type"]==="array" && Array.isArray(row[attr["property"]])){
      if (attr["items"]!=null &&  attr["items"]["format"] === "pubmed"){
        value = <div style={pubmedStyle}>{row[attr["property"]]
          .map((el,index)=><a href={"https://identifiers.org/"+el}
                      target={"_blank"}
                      title={"Click to see abstract: "+ el}
                      style={{textDecoration:"underline", color:"#00D2D0"}}>
            {(index+1)}
          </a>)}
        </div>;
      }
      else
        value = row[attr["property"]].join(",");
    }
    if (attr["type"]==="string"){
      if (attr["format"]==="iri")
        value =  <a href={row["iri"]} target={"_blank"} title={"Click to see definition in Bioportal:"}>
          {row["iri"]}
        </a>;
      else
        value = row[attr["property"]]
    }

    return <div style={rowStyle}>
      <div style={labelStyle}>
        {attr["name"]}
      </div>
      {value}
    </div>
  };
  /**
   * Render node
   * @param row
   * @return {*}
   */
  renderNode(row){
    const {viewer} = this.props;
    const color = (viewer.mapNodeValueToColor(row[viewer['gtNodeColorAttributeProperty']])!=null)
      ? ("rgba("+viewer.mapNodeValueToColor(row[viewer['gtNodeColorAttributeProperty']]).join(",")+")")
      : "green";

    const header = <div style={{display: "inline-block"}}>
      <div style={{...colorBoxStyle, backgroundColor: color, width: "2em"}}/>
      {this.renderNodeLabel(row)}
    </div>;

    return <Panel toggleable={true} header={header} >
      <div style={tableStyle}>
      {viewer.directedGraph.getNodeAttributes().map(el=>this.renderAttr(el,row))}
      </div>
    </Panel>
  }
  renderEdgeProperties(row){
    const {viewer} = this.props;

    if (row==null){
      return "No edge";
    }

    const color = (viewer.mapEdgeValueToColor(row[viewer['gtEdgeColorAttributeProperty']])!=null)
      ? ("rgba("+viewer.mapEdgeValueToColor(row[viewer['gtEdgeColorAttributeProperty']]).join(",")+")")
      : "green";

    const header = <div style={{display: "inline-block"}}>
      <div style={{...colorBoxStyle, backgroundColor: color, width: "2em"}}/>
    </div>;

    return <Panel toggleable={true} header={header} >
      <div style={tableStyle}>
        {viewer.directedGraph.getEdgeAttributes().map(el=>this.renderAttr(el,row))}
      </div>
    </Panel>;

  }


  render() {
    const {viewer, selected} = this.props;

    let result = "No selected node/edge";
    if (!(selected !=null && selected.hasOwnProperty("tooltip")))
      return result;

    if(viewer != null && viewer.directedGraph != null){
      if (Array.isArray(selected.tooltip)
        && selected.tooltip.length === 2
        &&  viewer.directedGraph.getEdgeByIndex(selected.pointId)!=null){
        result = (
          <div>
            <h5>Selected edge</h5>
            From:<br/>
            {this.renderNode(selected.tooltip[0])}
            to:<br/>
            {this.renderNode(selected.tooltip[1])}
            with: <br/>
            {this.renderEdgeProperties(viewer.directedGraph.getEdgeByIndex(selected.pointId))}
          </div>);
      }
      else {
        const {incoming,outcoming} = viewer
          .directedGraph
          .getAdjacentNodesByIndex(selected.pointId);

        const income = incoming
          .map(node=>{
            return this.renderNode(node);
          });

        const outcome = outcoming
          .map(node=>{
            return this.renderNode(node);
          });

        result = (
          <div>
            <h5>Selected node</h5>
            {this.renderNode(selected.tooltip)}
            {incoming.length>0 &&
            <div> <h5>Incoming associations</h5> {income}</div>}
            {outcoming.length>0 &&
            <div> <h5>Outcoming associations</h5> {outcome}</div>}
          </div>
        );
      }
    }
    return result;
  }
}

export default GraphSelectedPanel;

GraphSelectedPanel.propTypes = {
  viewer:PropTypes.object.isRequired,
  selected:PropTypes.object.isRequired
};

GraphSelectedPanel.defaultProps = {};

const labelStyle = {
  width: "12em",
  display: "table-cell"
};

const tableStyle = {
  display:"table"
};

const rowStyle = {
  margin:"1em",
  display:"table-row"
};


const pubmedStyle={
  display: "flex",
  flexFlow: "wrap",
  alignItems: "stretch",
  alignContent: "flex-start",
  columnGap: "0.5em"
};