import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";


/**
 * Stateful component  for calculating  models related to agreement analysis.
 * @returns {*}
 */
class GraphCasePanel extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const {updateConnectomeCase,selectedCase,cases} = this.props;
    return (
      <React.Fragment>
        <h5>Available cases for experiment</h5>
      <DataTable
        value={cases!=null?cases.data:[]}
        paginator={true}
        rows={15}
        selectionMode={"single"}
        selection={selectedCase}
        onSelectionChange={e => updateConnectomeCase(e.data)}
        onRowSelect={()=>{}}
      >
        <Column header={"Case id"} field={"case"} filter={true} filterMatchMode={"contains"} sortable={true}/>
        <Column header={"Number of acquisitions"} body={(row)=>{
          if (row.hasOwnProperty("acquisitions") && Array.isArray(row["acquisitions"]))
            return row["acquisitions"].length;
          else return 0;
        }}/>
        <Column header={"Number of tractographies"}  body={(row)=>{
          if (row.hasOwnProperty("tractographies") && Array.isArray(row["tractographies"]))
            return row["tractographies"].length;
          else return 0;
        }}/>
      </DataTable>
      </React.Fragment>
    );
  }
}

export default withRouter (GraphCasePanel)


GraphCasePanel.propTypes={
  updateConnectomeCase: PropTypes.func.isRequired,
  cases: PropTypes.array.isRequired
};

