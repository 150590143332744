import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {getNestedProp} from "../../helpers/expressions";
import {getMeasurementShortLabel} from "../action/SAMAction";

/**
 * Component designed for displaying distribution statistics in agreement module.
 *
 */

class DistributionStatsPanel extends React.Component {

  constructor(props) {
    super(props);
    ["measurementBody"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {getStatistics} = this.props;
      getStatistics()
  }



  measurementBody(row,column){
    const {rawData,selectedMeasurementConfiguration} = this.props;
    return getMeasurementShortLabel(selectedMeasurementConfiguration[column.rowIndex],rawData);
  }


  render() {
    const {extendedModelParameters} = this.props;
    const data = getNestedProp(["psychOutcome"],extendedModelParameters)!=null
      ? getNestedProp(["psychOutcome"],extendedModelParameters)
      : [];
    return (
      <React.Fragment>
        <DataTable value={data} emptyMessage={"No statistics available"}>
          <Column header={"Measurement"} body={this.measurementBody}/>
          <Column field="n" header={"Sample size"}/>
          <Column field="mean" header={"Mean"}/>
          <Column field="trimmed" header={"Trimmed"}/>
          <Column field="median" header={"Median"}/>
          <Column field="min" header={"Min"}/>
          <Column field="max" header={"Max"}/>
          <Column field="sd" header={"STD"}/>
          <Column field="IQR" header={"IQR"}/>
          <Column field="mad" header={"MAD"}/>
          <Column field="se" header={"SE"}/>
          <Column field="skew" header={"Skew"}/>
          <Column field="kurtosis" header={"Kurtosis"}/>
          <Column field="pValue" header={"KS p-Value"}/>
          {/*<Column body={showHistogram} header={"Histogram"}/>*/}
          {/*<Column body={showBoxplot} header={"Boxplot"}/>*/}
          {/*<Column body={showKernel} header={"Kernel plot"}/>*/}
        </DataTable>
        <div style={{fontStyle: "italic", float: "right"}}>Generated with use of CRAN package: <a
          style={{textDecoration: "underline"}} target={"_blank"}
          href={"https://cran.r-project.org/web/packages/psych/psych.pdf#nameddest=describe"}>Procedures for Psychological, Psychometric, and Personality
          Research (Psych)</a></div>

      </React.Fragment>
    );
  }
}

export default withTranslation()(DistributionStatsPanel);


DistributionStatsPanel.propTypes = {
  rawData: PropTypes.object.isRequired,
  modelParameters: PropTypes.object.isRequired,
  extendedModelParameters: PropTypes.object.isRequired,
  selectedMeasurementConfiguration: PropTypes.array.isRequired,
  plotData: PropTypes.array.isRequired,
  getStatistics: PropTypes.func.isRequired,
  t: PropTypes.func
};
