import React from "react";
import PropTypes from "prop-types";
/**
 * Component for displaying text blinking on the change of value.
 * To be used in value monitors, gauges etc.
 *
 *
 */
class BlinkingText extends React.Component {

  constructor(props) {
    super(props);

  }
  componentDidMount() {

  }
  componentDidUpdate(prevProps, prevState,ss) {
    if (prevProps.value !== this.props.value) {
      if(this.blinking!=null && this.blinking.classList!=null){
        const blinkClasses = this.blinking.classList;
        blinkClasses.add("blinkingText");
        setTimeout(function(){
          blinkClasses.remove("blinkingText");
        }, 1200);
      }
    }
  }

  render() {
    return (
      <span ref={el=>this.blinking = el}
            {...this.props.style!=null?{style:this.props.style}:{}}>
        {this.props.value}
      </span>
    )
  }

}

export default BlinkingText;

BlinkingText.propTypes={
  value:PropTypes.number.isRequired,
  style:PropTypes.object
};


