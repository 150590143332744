import {createSelector} from 'reselect';


export const  getViewersState = state => state.visu.viewers.viewersState;
export const  getGraphTool = state => state.visu.manualTool.manualToolState['graphTool'];


/**
 * Get viewer bounded with graph tool.
 *
 */
export const getViewerAssignedToGraphTool =  createSelector(  [getGraphTool,getViewersState],
  (graphTool,viewersState) => {
    let viewer = null;
    try {
      if (graphTool != null && graphTool.hasOwnProperty("viewerKey")) {
        viewer = viewersState[graphTool["viewerKey"]];
      }
    } catch (err) {
    }
    return viewer;
  });