import {
    TASK_LIST_FAIL,
    TASK_LIST_REQUEST, TASK_LIST_SUCCESS
} from "./actionType";

import axios from 'axios'
import store from "../../Store";
import {HOST_URL} from "../../../Constants";


const requestTaskList = (workflowId) => ({
    type: TASK_LIST_REQUEST,
    workflowId
});

const errorTaskList = (err,workflowId) => ({
    type: TASK_LIST_FAIL,
    err,
    workflowId
});

const successTaskList = (tasks,workflowId) => ({
    type: TASK_LIST_SUCCESS,
    tasks,
    workflowId
});


// TODOOOOOO CONNECT TO DB and Add dispatching request and error handling
// Get user list (local version)
/**
 * ActionCreator for getting Tasks.
 * @param workflowId
 * @returns {function(*)}
 */
export const getTaskList = (workflowId) => {
    // console.log('tasks for workflowId',mockTasks);
    return dispatch => {
        // axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestTaskList(workflowId));
        // dispatch(successTaskList(mockTasks,workflowId));

         axios.get(HOST_URL + '/api/workflow/'+workflowId+'/workflowtask')
            .then(response => {
                console.log('TaskAction.js :: getTaskList :: response ::', response);
                if (response.status !== 200) {
                    dispatch(errorTaskList(error,workflowId))
                } else {
                    dispatch(successTaskList(response.data,workflowId));
                }
            }).catch(error => {
            console.log('Task Action.js :: getTaskList :: error ::', error);
            dispatch(errorTaskList(error,workflowId))
        });

    }
};


/**
 * ActionCreator for getting Tasks.
 * @returns {function(*)}
 */
export const getAllTasksList = () => {

    return dispatch => {
        dispatch(requestTaskList());
        const config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        axios.get(HOST_URL + '/api/task',config)
          .then(response => {
              console.log('TaskAction.js :: getAllTasksList :: response ::', response);
              if (response.status !== 200) {
                  dispatch(errorTaskList(response.status))
              } else {
                  dispatch(successTaskList(response.data));
              }
          }).catch(error => {
            console.log('Task Action.js :: getAllTasksList :: error ::', error);
            dispatch(errorTaskList(error))
        });

    }
};

