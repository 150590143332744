import React from "react";
import PropTypes from "prop-types";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";
/**
 * Layout component for displaying viewers panel.
 *
 */
class RowLayout extends React.Component {

    static getLabel(){
        return "One row of viewers of any size"
    }
    static getClassName(){
        return "annotationToolDemo"
    }
    static getViewersPortKeys(){
        return [];
    }

    constructor(props){
        super(props);
        this.state = {
        };
    }
    componentDidMount(){
        console.log("Row Layout");
    }
    componentDidUpdate(prevProps,prevState){
    }
    componentWillUnmount(){
    }
    render(){
        const {toolComponentProps,toolComponentState,importExportPanel,widgetPanel} =this.props;
        const viewersState = toolComponentProps.viewersState;
        let arrayOfBrowsers = (viewersState!=null && Object.keys(viewersState).length>0)?Object.keys(viewersState).map((el,index)=>
            {
                return BrowserFactory.build(el,toolComponentProps,toolComponentState)
            }
        ): <div className="ui-g-12" style={{padding:"0.1em"}}/>;

        const layoutRatio = (arrayOfBrowsers!=null && arrayOfBrowsers.length===3)
          ? 33.33333
          : 100/(arrayOfBrowsers.length);


        return (
            <React.Fragment>
                <div className="ui-g-12">
                    {importExportPanel}
                    {widgetPanel}
                </div>
                <div className="ui-g-12">
                {arrayOfBrowsers!=null && Array.isArray(arrayOfBrowsers) && arrayOfBrowsers.map((el)=>
                    <div className="ui-g-12" style={{padding:"0.1em",width:`${layoutRatio}%`}}>
                        {el}
                    </div>
                )}
                </div>
            </React.Fragment>
        )
    }

}
export default RowLayout;

RowLayout.defaultProps={
};

RowLayout.propTypes = {
    toolComponentProps:PropTypes.object.isRequired,
    toolComponentState:PropTypes.object.isRequired,
    importExportPanel:PropTypes.object.isRequired,
    widgetPanel:PropTypes.object.isRequired
};


