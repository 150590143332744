import React from "react";
import PropTypes from "prop-types";
import 'brace/mode/json';
import 'brace/theme/github';
import {getLayoutClass} from "../../root/component/LayoutManager";


export class TaskPreviewPanel extends React.Component {



  render() {
    const {nodeSettings} = this.props;

    if (!(nodeSettings!=null))
      return <React.Fragment/>;

    const toolData = nodeSettings.toolData;
    if (!(toolData!=null))
      return <React.Fragment/>;

    let layoutType = null;
    let layoutClass = null;
    try{
      if (toolData.docType==="boutiquesDescriptor"){
        layoutClass = "boutiquesDemo";
      }else {
        layoutType = toolData.configuration.viewers.layout.type;
        layoutClass = getLayoutClass(layoutType).getClassName();
      }
    }catch(e){
      console.log("Error in parsing tool data: missing docType or layout");
    }


    const icon = layoutClass !=null?<div className={layoutClass}/>:<div>Preview not available</div>;

    return <React.Fragment>
      {icon}
    </React.Fragment>
  }
}

TaskPreviewPanel.propTypes = {
  nodeSettings: PropTypes.object.isRequired,
};