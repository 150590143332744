import React from 'react'
import PropTypes from "prop-types";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {convertISODateAndTimeToLocaleString} from "../../helpers/dates";
import {getNestedProp} from "../../helpers/expressions";

class QuestionnairesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      expandedRows:[]
    };

  }

  idBody(propertyValue){
    return (
      <div title={propertyValue}>
        {propertyValue!=null
          ? propertyValue.substring(0,5) + '...'
          : ""
        }
        {propertyValue!=null &&
        <i className={"fa fa-clipboard"}
           onClick={()=>navigator.clipboard.writeText(propertyValue)}
           title={"Copy to clipboard"}
           style={{color:"#00D2D0"}}
        />}
      </div>
    );
  }

  rowExpansionTemplate(row) {

    const answers = getNestedProp(["rawData","answers"],row);
    const pivotId = answers.reduce((obj, cur) => ({...obj, [cur.questionId]: cur.questionId}), {label:"Question id"});
    const pivotStart = answers.reduce((obj, cur) => ({...obj, [cur.questionId]: convertISODateAndTimeToLocaleString(cur.startDate)}), {label:"Start"});
    const pivotEnd = answers.reduce((obj, cur) => ({...obj, [cur.questionId]: convertISODateAndTimeToLocaleString(cur.endDate)}), {label:"End"});
    const pivotAnswer = answers.reduce((obj, cur) => ({...obj, [cur.questionId]: cur.answer}), {label:"Answer"});

    const data = [pivotId,pivotStart,pivotEnd,pivotAnswer];
    const dynamicColumns = (answers!=null)
      ? answers
        .map((el,index)=>{return(
          <Column
            columnKey={el['questionId']}
            header={"Question "+(index+1)}
            field={el['questionId']}
            style={{width:"20em"}}
          />
        )})
      :[];

    return (
      <div>
        <DataTable  header={"Answers"} value={data} scrollable={true}>
          <Column field={'label'} header={"Property"} style={{width:"6em"}}/>
          {dynamicColumns}
        </DataTable>
      </div>
    );
  }


  render() {
    const {questionnaireAnswers,isBackup} = this.props;

    return (
      <DataTable
        value={questionnaireAnswers}  rows={15}  paginator={true} sortField={"rawData.questionnaireEndDate"} sortOrder={-1}
        expandedRows={this.state.expandedRows}
        onRowToggle={(e) => this.setState({expandedRows: e.data})}
        rowExpansionTemplate={this.rowExpansionTemplate}
      >
        <Column header={"Id"} field={"_id"} body={(row)=>this.idBody(getNestedProp(['_id'],row))}/>
        {/*<Column field={"creationDate"} header={"Saved on"}*/}
        {/*        sortable={true}*/}
        {/*        body={(row)=>{*/}
        {/*          return convertISODateAndTimeToLocaleString(row.creationDate)*/}
        {/*        }}/>*/}
        <Column field={"creationDate"} header={"Creation date"}
                sortable={true}
                body={(row)=>{
                  return convertISODateAndTimeToLocaleString(row.creationDate)
                }}/>
        <Column header={"Number of answers"}
                sortable={true}
                field={(!isBackup)?"answers.length":"rawData.answers.length"}
                body={(row)=>{
                  if (isBackup)
                    return row.rawData.answers.length;
                  else
                    return row.answers.length;
                }}/>
        <Column field={"rawData.cycleId"} header={"Cycle name"}
                sortable={true}
        />
        <Column field={"rawData.questionnaireIndex"}  header={"Index"}
                sortable={true}
        />
        <Column field={"rawData.questionnaireStartDate"}  header={"Start date"}
                sortable={true}
                body={(row)=>{
                  return convertISODateAndTimeToLocaleString(row.rawData.questionnaireStartDate)
                }}
        />
        <Column field={"rawData.questionnaireEndDate"}  header={"End date"}
                sortable={true}
                body={(row)=>{
                  return convertISODateAndTimeToLocaleString(row.rawData.questionnaireEndDate)
                }}
        />
        <Column expander={true} header={"Answers"}/>
      </DataTable>
    )
  }
}
export default QuestionnairesTable


QuestionnairesTable.defaultProps={
  isBackup:false
};
QuestionnairesTable.propTypes ={
  questionnaireAnswers:PropTypes.array.isRequired,
  isBackup:PropTypes.bool.isRequired
};

