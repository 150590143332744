import axios from 'axios';
import {HOST_URL} from "../../../Constants";
import store from "../../Store";
import {EVENTS_LIST_SUCCESS,
        EVENTS_LIST_REQUEST,
        EVENTS_LIST_FAIL } from './actionType'; 

//****************************************************************************
//
//                                Actions
//
//****************************************************************************
const requestEvents = () => ({
    type: EVENTS_LIST_REQUEST,
});


const errorEvents = () => ({
    type: EVENTS_LIST_FAIL,
});

const successEvents = events => ({
    type: EVENTS_LIST_SUCCESS,
    events
});


/**
 * Action to get the list of events available for the user.
 * 
 * @param {*} onError Callback in case an error occurs. It receives the errored response
 * @returns {function(*)}
 */ 
export const getEventsList = (onError) => {
    return dispatch => {
        console.log('EventsAction.js :: getEventsList');
        const config = {
            headers: {'Authorization': `bearer${store.getState().auth.token_bearer}`}
        };
        const url = `${HOST_URL}/api/event`;
        dispatch(requestEvents());
        return axios.get(
            url,
            config
            ).then(response => {
                if(response.status !== 200) {
                    onError(response);
                    return Promise.reject(response.data);
                } else {
                    dispatch(successEvents(response.data));
                }
            }).catch(err => {
                console.log(err);
                onError({message: 'Unable to get available events'});
                dispatch(errorEvents())
            });
    };
};

/**
 * Action to get the list of public events available.
 * 
 * @param {*} onError Callback in case an error occurs. It receives the errored response
 * @returns {function(*)}
 */ 
export const getPublicEventsList = (onError) => {
    return dispatch => {
        console.log('EventsAction.js :: getPublicEventsList');
        const url = `${HOST_URL}/api/publicEvent`;
        dispatch(requestEvents());
        return axios.get(
            url
            ).then(response => {
                if(response.status !== 200) {
                    onError(response);
                    return Promise.reject(response.data);
                } else {
                    dispatch(successEvents(response.data));
                }
            }).catch(err => {
                console.log(err);
                onError({message: 'Unable to get available events'});
                dispatch(errorEvents())
            });
    };
};