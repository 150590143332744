import {connect} from "react-redux";
import GraphCasePanel from "../../visualization/component/GraphCasePanel";
import {updateConnectomeCase} from "../../visualization/action/ConnectomeAction";


const mapStateToProps = state => {
  return {
    auth: state.auth,
    selectedCase: state.visu.connectomes.selectedCase,
    cases: state.visu.connectomes.cases
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateConnectomeCase:(sel)=>{
      dispatch(updateConnectomeCase(sel))
    }
  }
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerGraphCasePanel = containerCreator(GraphCasePanel);