import React from 'react'
import PropTypes from "prop-types";
import AvatarScatterPlot from "./AvatarScatterPlot";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {Dialog} from "primereact/components/dialog/Dialog";
import RaterHistogram from "./RaterHistogram";
import {ContainerAvatarScatterPlotSidebar} from "../../../visualization/containers/ContainerAvatarScatterPlotSidebar";
import {
    REQUEST_STATUS_REQUESTED,
    REQUEST_STATUS_SUCCESS
} from "../../../../Constants";
import {generateVisualizationToolConfiguration} from "../../../visualization/component/annotationTool/ToolConfigurationFactory";
import {ContainerManualTool} from "../../../visualization/containers/ContainerManualTool";

/**
 * Panel for displaying experiment results in live presenter. The only directly required information is
 * experimentId.
 * Panel uses statistical models (SLR).
 *
 * Panel is initialized (see componentDidMount) depending on the mode of use.
 * The avatar plot panel can be working in:
 *  - Live Presenter (Skill) mode - parameters are passed through slide properties
 *  - regular component mode (with real or fake data) - parameter of experimentid
 *
 *
 */
export class AvatarScatterPlotPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: null,
            height: null,
            optionsSidebarVisible: false, //TODO put it in store
            showMRIDialog: false,//TODO put it in store
            showHistogramDialog: false, //TODO put it in store
            raters: null,
            opts: [{label: "None", value: "none"}],
            selected: null,   //selected Data Points in left and right
            leftButtonMode: LEFT_BUTTON_MODE.NONE,
            onDeselectFromVolume: null, //callback function that should be set dynamically by children - this one is for deselecting from Volume View
            onDeselectFromRater:null
        };
        ["setPlotSize", "onDataPointClick", "onHideVolumeDialog", "onHideHistogramDialog","onHideSidebar"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            });
    }

    componentDidUpdate(prevProps, prevState,snapshot) {
        const {plotData,plotSettings,match,slideProperties, initializeAvatarScatterPlot,refreshData}=this.props;
        if (prevProps.plotData !== plotData)
            console.log('Different data in plot');
        // change polling function only if it is on
        if ((prevProps.plotSettings!=null
            && prevProps.plotSettings['refreshRate_seconds'] !== plotSettings['refreshRate_seconds']
            && plotSettings['polling']) ||
            (prevProps.plotSettings!=null && prevProps.plotSettings['polling'] !== plotSettings['polling']  && plotSettings['polling'])
        ){
            clearInterval(this.polling);
            this.polling =
                setInterval(() => {
                        console.log("<<<<<<<<<<<<<<<<<<<<<<----Poll---->>>>>>>>>>>>>>>>>>>>>>>>>>");
                        console.log("every<<<<<<<<<<<",plotSettings['refreshRate_seconds'],">>>>>>>>>>>>")
                        if (!(this.state.showHistogramDialog || this.state.showMRIDialog)) {
                            if (match.path.toLowerCase().includes('playskill') || match.path.toLowerCase().includes('livepresenter'))    // if this is the case of Live Presenter
                                refreshData(slideProperties['experimentId']);
                            else
                                refreshData( match.params.id);

                        }
                    }
                    , plotSettings['refreshRate_seconds'] * 1000);
        }
        if (prevProps.plotSettings!=null && prevProps.plotSettings['polling'] !== plotSettings['polling']  && !plotSettings['polling']){
            clearInterval(this.polling);
        }
    }

    componentWillUnmount() {
        const {clearInteractivePlotState,clearDataset,plotSettings} = this.props;
        document.body.removeEventListener("keydown", this.keyListener, true);
        window.removeEventListener("resize", this.setPlotSize);
        clearInteractivePlotState();
        clearDataset();  // used from Audit module, TODO move to independent part of Store responsible for R communication
        if (plotSettings['polling'])
             clearInterval(this.polling);
    }

    componentDidMount() {
        const {initializeAvatarScatterPlot,slideProperties,match} = this.props;
        if (match.path.toLowerCase().includes('playskill') || match.path.toLowerCase().includes('livepresenter'))    // if this is the case of Live Presenter
            initializeAvatarScatterPlot(slideProperties['experimentId']);
        else
            initializeAvatarScatterPlot( match.params.id);
        document.body.addEventListener("keydown", this.keyListener, true);
        this.setPlotSize();
        window.addEventListener("resize", this.setPlotSize);
    }


    /**
     * Function to be passed to children components.
     *
     * @param dataPoint - data associated with selected element
     * @param onDeselect - callback function defined in children component and to be set in
     * this component state. This clbk function can be used to deselect element in data.
     *
     */
    onDataPointClick(dataPoint, onDeselect) {
       if (dataPoint.hasOwnProperty('isCollectiveResult')) {
            this.setState({
                showHistogramDialog: true,
                onDeselectFromRater: onDeselect,
                selected:dataPoint,
                raters: dataPoint['raters']
            });
        }
        else {
            this.setState({
                showMRIDialog: true,
                onDeselectFromVolume: onDeselect,
                selected:dataPoint
            });
        }
    }

    /**
     * Event handler to call when volume dialog window is closed.
     */
    onHideVolumeDialog() {
        if (this.state.onDeselectFromVolume != null)
            this.state.onDeselectFromVolume();
        this.setState({
            showMRIDialog: false,
            onDeselectFromVolume: null,
            selected:null
        })
    }

    onHideSidebar(){
        this.setState({optionsSidebarVisible: false});
    }


    /**
     * Event handler to call when vertical histogram dialog window is closed.
     */
    onHideHistogramDialog() {
        if (this.state.onDeselectFromRater != null)
            this.state.onDeselectFromRater();
        this.setState({
            showHistogramDialog: false,
            onDeselectFromRater: null
        })
    }

    setPlotSize() {
        if (this.histogramContainer != null) {
            console.log('Calculate height and width');
            const rect = this.histogramContainer.getBoundingClientRect();
            this.setState({height: rect.height, width: rect.width});
        }
    }



    render() {
        const {plotData, plotSettings, modelParameters,modelParametersState, plotDataState} = this.props;
        const {width, height,selected} = this.state;

        //results property Name

        let xLabel =null;
        let yLabel =null;
        try {
             xLabel = (plotSettings != null && plotSettings.optionsForMeasurements != null) ? plotSettings.optionsForMeasurements.find((el) => {
                return el['value'] === plotSettings.measurementValue
            })['label'] : "";
            yLabel = (plotSettings != null && plotSettings.optionsForMeasurements != null) ? plotSettings.optionsForClinical.find((el) => {
                return el['value'] === plotSettings.clinicalValue
            })['label'] : "";
        }catch(err){
            return ( <div style={{fontSize: "20px", textAlign: "center", color: "red"}}> No valid data. Try  later!</div>);
        }

            return (
                <div id="avatarId" className="ui-g-12 ui-g-nopad" ref={node => this.histogramContainer = node}>
                    {plotData != null && plotData.length === 0 && plotDataState === REQUEST_STATUS_SUCCESS &&
                    <div style={{fontSize: "20px", textAlign: "center", color: "red"}}> No available data yet. Try
                        later!</div>
                    }
                    {plotDataState === REQUEST_STATUS_REQUESTED &&
                    <i className="fa fa-spinner fa-spin" style={{fontSize:"4em", textAlign: "center", color: "#00D2D0"}}/>
                    }
                    {/*{modelParametersState === REQUEST_STATUS_FAIL*/}
                        {/*&& (plotSettings.regressionVisible || plotSettings.equationVisible || plotSettings.rSquaredVisible)*/}
                        {/*&&*/}
                    {/*<div style={{fontSize: "10px", textAlign: "center", color: "red"}}> Connection lost! Statistics cannot be computed.*/}
                        {/*later!</div>*/}
                    {/*}*/}

                    {width != null && height != null &&
                    <AvatarScatterPlot
                        yLabel={yLabel}
                        xLabel={xLabel}
                        yLabelTip={yLabel}
                        xLabelTip={xLabel}
                        length={(!(plotData != null)) ? -1 : plotData.length}
                        outerWidth={width}
                        outerHeight={height}
                        data={plotData}
                        colorCat="userId"
                        onDataPointClick={(d, onDeselect) => {
                            this.onDataPointClick(d, onDeselect)
                        }}
                        useAvatars={true}
                        selected={this.state.selected}
                        viewId={"view"}
                        prefixId="avatar"
                        regressionVisible={plotSettings.regressionVisible}
                        regressionLineColor={(plotSettings.regressionLineColor!=null && plotSettings.regressionLineColor.charAt(0)==='#')?plotSettings.regressionLineColor:'#' + plotSettings.regressionLineColor}
                        regressionLineWidth={plotSettings.regressionLineWidth}
                        regressionLineParameters={(modelParameters)!=null?modelParameters.betasEstimates:null}
                        equationVisible={plotSettings.equationVisible}
                        rSquaredVisible={plotSettings.rSquaredVisible}
                        rSquared={(modelParameters)!=null?modelParameters.rsquared:null}
                        statsServerState={modelParametersState}
                    />
                    }

                    <a><i className="fa fa-fw fa-ellipsis-v"  title="Configuration" onClick={(e) => this.setState({optionsSidebarVisible: true})}
                       style={{position: "fixed", top: "70px", right: "10px",fontSize:"20px"}}/></a>
                    {/*<Button icon="fa fa-fw fa-ellipsis-v"*/}
                            {/*style={{position: "absolute", top: "50px", right: "10px"}}*/}
                            {/*onClick={}/>*/}

                    <ContainerAvatarScatterPlotSidebar
                        visible={this.state.optionsSidebarVisible}
                        position="right"
                        onHide={() => this.onHideSidebar()}
                    />
                    <Dialog header='Volume'
                            closeOnEscape={true}
                            resizable={true}
                            visible={this.state.showMRIDialog}
                            modal={false}
                            onHide={this.onHideVolumeDialog}
                        // onShow={() => this.setState({showMRIDialog: true})}
                            contentStyle={{
                                minHeight: '400px',
                                minWidth: '600px'
                            }}
                            style={{minHeight: '400px', minWidth: '600px'}}>
                        {selected != null && !selected.hasOwnProperty('isCollectiveResult') &&
                        <ContainerManualTool
                            predefinedToolConfiguration={generateVisualizationToolConfiguration(selected.originalImageId,selected.segmentationId)}
                        />}
                    </Dialog>
                    <Dialog header={<span
                        style={{fontVariant: "petite-caps", color: "#00D2D0", fontWeight: "bold"}}>Contributors</span>}
                            closeOnEscape={true}
                            resizable={true}
                            visible={this.state.showHistogramDialog}
                            modal={false}
                            onHide={this.onHideHistogramDialog}
                            onShow={() => this.setState({showHistogramDialog: true})}
                            contentStyle={{minHeight: '600px', minWidth: '400px'}}
                            style={{minHeight: '600px', minWidth: '400px'}}>
                        {this.state.raters != null &&
                        <RaterHistogram data={this.state.raters}
                                        prefixId={'raters'}
                                        xLabel={xLabel}
                                        xLabelTip={xLabel}
                                        width={400}
                                        outerWidth={400}
                                        outerHeight={500}
                                        onDataPointClick={(d, onDeselect) => {
                                            this.onDataPointClick(d, onDeselect)
                                        }}
                        />
                        }
                    </Dialog>
                </div>
            )
    }
}

AvatarScatterPlotPanel.defaultProps = {
    plotDataState:"",
    modelParametersState:""
};
AvatarScatterPlotPanel.propTypes = {
    experimentId: PropTypes.object.isRequired, //from React-router
    initializeAvatarScatterPlot: PropTypes.func.isRequired,
    loadImageData: PropTypes.func.isRequired,
    clearViewersState:PropTypes.func.isRequired,
    clearDataset:PropTypes.func.isRequired,
    plotData: PropTypes.array,
    plotSettings: PropTypes.object,
    plotDataState:PropTypes.string.isRequired,
    modelParameters: PropTypes.array.isRequired,
    modelParametersState: PropTypes.string.isRequired,
    results: PropTypes.object,
    resultsState: PropTypes.string,
    getTabletGameExperimentResults: PropTypes.func,
    mriImage: PropTypes.object,
    leftOverlay: PropTypes.object,
    leftFreq: PropTypes.object,
    rightOverlay: PropTypes.object,
    rightFreq: PropTypes.object,
    clearOverlayImage: PropTypes.func,
    slideProperties: PropTypes.object, //passed from slide executor through own props
    clearInteractivePlotState:PropTypes.func,
    match:PropTypes.object.isRequired,
    refreshData:PropTypes.func.isRequired
};
