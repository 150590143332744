
import {connect} from "react-redux";
import SpineQExperimentMonitor from "../components/SpineQExperimentMonitor";
import {
  addSubjectEnrollment,
  clearEnrollments, deleteEnrollment,
  getEnrollments,
  getEventLogs,
  getQuestionnaireAnswers, getQuestionnaireBackups, getTimeline
} from "../action/SpineQAction";
import {getStudyList} from "../../visualization/action/CohortAction";

const mapStateToProps = (state,ownProps) => {
  return {
    enrollments:state.spineQMonitor.enrollments,
    enrollmentsState:state.spineQMonitor.enrollmentsState,
    cohorts:state.visu.studies.studies,
    error:state.spineQMonitor.error,
    questionnaireAnswers:state.spineQMonitor.questionnaireAnswers,
    questionnaireAnswersState:state.spineQMonitor.questionnaireAnswersState,
    questionnaireBackups:state.spineQMonitor.questionnaireBackups,
    questionnaireBackupsState:state.spineQMonitor.questionnaireBackupsState,
    subjectEventLogs:state.spineQMonitor.subjectEventLogs,
    subjectEventLogsState:state.spineQMonitor.subjectEventLogsState,
    auth : state.auth
  }
};


const mapDispatchToProps = (dispatch) => {
  return {
    getEnrollments:(experimentId)=>{
      dispatch(getEnrollments(experimentId))
  },
    clearEnrollments:()=>{
      dispatch(clearEnrollments())
    },
    getQuestionnaireAnswers:(subjectId)=>{
      dispatch(getQuestionnaireAnswers(subjectId))
    },
    getQuestionnaireBackups:(subjectId)=>{
      dispatch(getQuestionnaireBackups(subjectId))
    },
    getEventLogs: (subjectId)=>{
      dispatch(getEventLogs(subjectId))
    },
    addSubjectEnrollment:(experimentId,subjectEmail,cohortId,onSuccess)=>{
      dispatch(addSubjectEnrollment(experimentId,subjectEmail,cohortId,onSuccess))
    },
    deleteEnrollment:(subjectEmail,subjectEnrollmentId,subjectId,onSuccess)=>{
      dispatch(deleteEnrollment(subjectEmail,subjectEnrollmentId,subjectId,onSuccess))
    },
    getCohorts: () => {
      dispatch(getStudyList())
    },
    getTimeline:()=>{
      dispatch(getTimeline())
    }
  }
};

const projectContainerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerSpineQExperimentMonitor = projectContainerCreator(SpineQExperimentMonitor);

