import React from 'react'
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import {IMAGE_URL, REQUEST_STATUS_SUCCESS} from "../../Constants";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {Slider} from "primereact/components/slider/Slider";
import {convertDataURIToBinaryFetch, getCroppedImg} from "../helpers/io/io";
import {withRouter} from "react-router";
import {FileInputButton} from "./component/FileInputButton";
import axios from "axios/index";


/**
 * Temporarily used as testing page for various components.
 *
 */

class PicturePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showCameraDialog: false,
            // showPreviewDialog: false,
            imageSrc: null,
            crop: {x: 0, y: 0},
            croppedAreaPixels: null,
            zoom: 1,
            rotation: 0,
            aspect: 1
        };
        this.cameraRef = React.createRef();
        ["onSnap", "handleFileChosen", "onCropChange", "onCropComplete", "onZoomChange", "onRotationChange"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    onSnap() {
        const {setCroppedImage} = this.props;
        let video = this.cameraRef.current;
        let canvas = document.createElement('canvas');
        canvas.width = 480;
        canvas.height = 320;
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        let dataURI = canvas.toDataURL('image/png');
        if (setCroppedImage != null)
            setCroppedImage(dataURI);
        this.setState({
            imageSrc: dataURI,
            croppedImage: dataURI,
            crop: {x: 0, y: 0},
            zoom: 1
        })
    }

    onCropChange(crop) {
        this.setState({crop});
        console.log("Crop", crop);
    }

    onRotationChange(rotation) {
        this.setState({rotation})
    }

    onCropComplete(croppedArea, croppedAreaPixels) {
        this.setState({croppedAreaPixels:croppedAreaPixels});

        console.log("Cropped area", croppedArea);
        console.log("Cropped area pixels", croppedAreaPixels);
    }


    onZoomChange(zoom) {
        this.setState({zoom})
    }

    handleFileChosen(file) {
        const handleFileRead = (e) => {
            const content = fileReader.result;

            // let canvas = document.getElementById('canvasPicture');
            // let context = canvas.getContext('2d');
            // context.drawImage(content, 0, 0, 320, 240);
            this.setState({
                imageSrc: content,
                crop: {x: 0, y: 0},
                zoom: 1,
            })

            if (this.props.setCroppedImage != null)
                this.props.setCroppedImage(content)
        };

        let fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsDataURL(file);
    };

    componentDidMount() {
        const {auth, initialImageURL} = this.props;
        // Grab elements, create settings, etc.            
        if (!(initialImageURL != null)){
            fetch(IMAGE_URL(auth.id)).then(data=>data.blob()).then(blob=>{
                this.handleFileChosen(blob);
            });
        } else {
            fetch(initialImageURL).then(data=>data.blob()).then(blob=>{
                this.handleFileChosen(blob);
            });
        }
    }


    componentDidUpdate(prevProps, prevState) {
        const {showCameraDialog} = this.state;

        if (prevState.showCameraDialog !== showCameraDialog) {
            let video = this.cameraRef.current;
            let stream = video.srcObject;

            if (!showCameraDialog) {
                // now get all tracks and close each track by having forEach loop

                if (stream != null)
                    stream.getTracks().forEach(function (track) {
                        // stopping every track
                        track.stop();
                    });
                video.srcObject = null;
            }
            else {

                // Get access to the camera!
                if (!(video.srcObject != null))
                    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                        // Not adding `{ audio: true }` since we only want video now
                        navigator.mediaDevices.getUserMedia({video: true}).then(function (stream) {
                            // video.src = window.URL.createObjectURL(stream);
                            video.srcObject = stream;
                            video.play();
                        });
                    }
            }
        }
    }

    render() {
        const {auth, t, i18n, croppedImage, setCroppedImage, match} = this.props;
        return (
            <div style={{margin: '16px'}}>
                <div>
                <label className="reg-plaintext">{t("registerForm.property.avatar.label")}</label>
                {/* <Button onClick={() => this.setState({showCameraDialog: true})} label="Use camera"
                        icon={"fa fa-camera"} style={{marginLeft: "5.5em"}}/> */}

                {!match.path.includes("register") &&
                <FileInputButton
                    style={{display:"inline-block",verticalAlign:"top"}}
                    label="Choose file"
                    filter="image/*"
                    onChange={e => this.handleFileChosen(e.target.files[0])}
                />
                }
                </div>

                {this.state.imageSrc &&
                <div>
                    <div style={{width: "300px", height: "300px", position: "relative"}}>
                        <Cropper
                            image={this.state.imageSrc}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            rotation={this.state.rotation}
                            cropShape="round"
                            aspect={this.state.aspect}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onRotationChange={this.onRotationChange}
                            onZoomChange={this.onZoomChange}
                        />
                    </div>
                    {!match.path.includes("register") &&
                    <div>
                        <div style={{width: "240px"}}>
                            <input
                                type="range"
                                value={this.state.rotation}
                                min={0}
                                max={360}
                                step={1}
                                onChange={(e) => this.setState({rotation: e.target.value})}
                                id="rotationSlider"
                            />
                            <label htmlFor="rotationSlider">Rotation</label>
                        </div>
                        <div style={{width: "240px"}}>
                            <input
                                type="range"
                                value={this.state.zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                onChange={(e) => this.setState({zoom: e.target.value})}
                                id="rotationSlider"
                            />
                            <label htmlFor="rotationSlider">Zoom</label>
                        </div>
                        <Button
                            onClick={() => {
                                showCroppedImage(this.state.imageSrc, this.state.croppedAreaPixels, this.state.rotation).then(result => {
                                        if (setCroppedImage != null)
                                            setCroppedImage(result);
                                    convertDataURIToBinaryFetch(result).then((blob)=>{
                                        const payload = new FormData();     //
                                        payload.append("parse", "true");
                                        payload.append("output", blob);
                                        const config = {headers:{"Authorization": "bearer" + auth.token_bearer}}
                                        axios
                                            .post(`api/user/${auth.id}/picture`, payload, config)
                                            .then((result) => {
                                                console.log("Avatar updated");
                                                const avatar = document.getElementById('avatar');
                                                avatar.src =IMAGE_URL(auth.id);
                                            });
                                    });
                                    }
                                );

                            }}
                            label="Crop and set"
                            icon={"fa fa-crop"}/>
                    </div>}
                </div>
                }
                {/*<canvas id="canvasPicture" width="320" height="240"/>*/}
                <Dialog header='Take Snapshot'
                        visible={this.state.showCameraDialog}
                        width="600"
                        modal={false}
                        onHide={() => this.setState({showCameraDialog: false})}
                >

                    <video id="videoCamera" preload="none" ref={this.cameraRef} width="240" height="240"
                           style={{display: "block"}}/>
                    <Button onClick={this.onSnap} label="Snap" icon={"fa fa-camera"}/>
                    <Button onClick={() => this.setState({showCameraDialog: false})} label="Close"
                            icon={"fa fa-close"}/>
                </Dialog>
            </div>
        )
    }
}

PicturePage.defaultProps = {
    initialImageURL: null
};

PicturePage.propTypes = {
    auth: PropTypes.object.isRequired, // user record
    initialImageURL: PropTypes.string.isRequired,
    croppedImage: PropTypes.string.isRequired,
    setCroppedImage: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
};

export default withTranslation()(withRouter(PicturePage));


const showCroppedImage = (dogImg, croppedAreaPixels, rotation) => {
    return getCroppedImg(dogImg, croppedAreaPixels, rotation);
};
