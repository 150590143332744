import React from "react";
import PropTypes from "prop-types";
import {HierarchicalDropdownGenerator} from "./HierarchicalDropdownGenerator";
import {searchParent} from "../../../helpers/trees";
import {Button} from "primereact/components/button/Button";


/**
 * Form for creating/editing annotation column.
 */
class HierarchicalOptionsEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data:null,
      dataDetails:null
    };
    ["addNode","removeNode","updateData","updateOntologyRegister"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  static generateLink(link) {
    return (<a target="_blank" href={link} title={"Open ".concat(link, " in external window")}>
      {link.substring(link.lastIndexOf('/') + 1)}
    </a>);
  }

  componentDidMount() {
    const {  options,optionsDetails} =this.props;

    if (options!=null){
        this.setState({
          data:Object.assign({},JSON.parse(JSON.stringify(options))),
        }); //deep copy of tree to avoid unwanted modification
    }
    else this.setState({data:{value:"",key:'0',title:"",children:[]}});
    if (optionsDetails!=null){
      this.setState({
        dataDetails:Object.assign({},JSON.parse(JSON.stringify(optionsDetails)))
      }); //deep copy of tree to avoid unwanted modification
    }
    else this.setState({dataDetails:{}});
  }





  /**
   * Adding the node to the tree.
   * Key is generated dynamically, and its value is incremented based on the key of the last child of the node
   * (not the number of elements in the list). If any sibling is removed, it does not cause
   * that node can have the same key.
   * @param node
   */
  addNode(node) {
    if (!node.children)
      node.children = [];
    let key = node.key;
    let index = node.children.length + 1;
    if (index > 1) {
      let str = node.children[index - 2].key.split(SEPARATOR);
      index = Number.parseInt(str[str.length - 1]) + 1;
    }
    ;
    let keyNew = (key === '0') ? '' + index : '' + key + SEPARATOR + index;
    let title = "";
    node.children.push({
      iri:"",
      title: title,
      value: title,
      key: keyNew,
    });
    let data = Object.assign({}, this.state.data);
    this.setState({data: data});
  }

  removeNode(node) {
    const {data} = this.state;
    let element = searchParent(data, node.key);
    let indexToRemove = element.children.findIndex((el) => el.key === node.key);
    if (indexToRemove != null)
      element.children.splice(indexToRemove, 1);
    this.setState({data: Object.assign({}, data)});
  }


  updateData(node, property, value) {
    node[property] = value;
    let data = Object.assign({}, this.state.data);
    this.setState({data: data});
  }

  updateOntologyRegister(key, property,value) {
    const {dataDetails}  = this.state;
    let data = Object.assign({}, dataDetails);
    if(!(data[key]!=null)){
      data[key]={};
    }
    data[key][property] = value;
    this.setState({dataDetails: data});
  }


  render() {
    const{data,dataDetails}=this.state;
    const{hide,updateOptions,options,findInOntology,ontologyList}=this.props;

    return (
      <div className={"ui-g-12"} >
        <Button label={"Save"} icon={"fa fa-save"} onClick={()=>{updateOptions(data,dataDetails)}}/>
        <Button label={"Close"} icon={"fa fa-close"} onClick={()=>{hide()}}/>

        <div>
          {data!=null && dataDetails!=null &&
          <HierarchicalDropdownGenerator parentNode={data}
                                         updateData={this.updateData}
                                         addNode={this.addNode}
                                         removeNode={this.removeNode}
                                         ontologyRegister={dataDetails}
                                         updateOntologyRegister={this.updateOntologyRegister}
                                         findInOntology={(key)=>findInOntology(key,this.updateOntologyRegister)}
                                         ontologyList={ontologyList}
          />
          }
        </div>

      </div>
    )
  }


}
const SEPARATOR = '-';

export default HierarchicalOptionsEditor;

HierarchicalOptionsEditor.propTypes = {
  options:PropTypes.object.isRequired,
  optionsDetails:PropTypes.object.isRequired,
  updateOptions:PropTypes.func.isRequired,
  hide:PropTypes.func.isRequired,
  findInOntology:PropTypes.func.isRequired,
  ontologyList:PropTypes.array.isRequired
};
