import {connect} from "react-redux";
import Users from "../component/Users";

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

const BaseContainerUsers = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerUsers = BaseContainerUsers(Users);