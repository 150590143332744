import {createSelector} from "reselect";
import {calculateIndex, getPlotSettings, getRawData, getSelectedCell, getSelectedLayout} from "./SAMSelectors";
import {
  checkMissingContributionsStrategy,
  filterContributionsByContributorsMode,
  filterContributionsByMeasurementsMode
} from "./ContributionsSelectors";

/**Get Total cases for a given plot and measurement.
 * Filter cases by measurements, and selected cases. Selected cases is calculated with use of filters for visit and subject .
 * @type {OutputSelector<unknown, unknown, (res1: *, res2: *, res3: *, res4: *) => unknown>}
 */
export const getTotalCasesFilteredByMeasurement = createSelector(
  [getSelectedCell, getSelectedLayout, getPlotSettings, getRawData],
  (cell, layout, plot, rawData) => {
    const currentPlotSettings = plot[calculateIndex(cell, layout)];
    const contributionsToCases = (rawData != null && rawData.contributions != null && currentPlotSettings.selectedMeasurementConfiguration!=null)
      ? rawData.contributions
        .filter((c,i,ar) => filterContributionsByMeasurementsMode(c,i,ar,currentPlotSettings))
        .map(el => el.caseId)
      : [];

    return (rawData != null && rawData.cases != null)
      ? rawData.cases
        .filter(el => contributionsToCases.includes(el.uuid))
      : [];
  }
);

/**
 * Filter cases by measurements, and selected cases. Selected cases is calculated with use of filters for visit and subject .
 * @type {OutputSelector<unknown, unknown, (res1: *, res2: *, res3: *, res4: *) => unknown>}
 */
export const getSelectedCasesFilteredByMeasurement = createSelector(
  [getSelectedCell, getSelectedLayout, getPlotSettings, getRawData],
  (cell, layout, plot, rawData) => {
    const currentPlotSettings = plot[calculateIndex(cell, layout)];
    const contributionsToCases = (rawData != null && rawData.contributions != null && currentPlotSettings.selectedMeasurementConfiguration!=null)
      ? rawData.contributions
        .filter((c,i,ar) => filterContributionsByMeasurementsMode(c,i,ar,currentPlotSettings))
        .filter((c) => currentPlotSettings.selectedCases.includes(c.caseId))
        .map(el => el.caseId)
      : [];

    return (rawData != null && rawData.cases != null)
      ? rawData.cases
        .filter(el => contributionsToCases.includes(el.uuid))
      : [];
  }
);
/**
 * Filter cases by measurements, groups and selected cases. Selected cases is calculated with use of filters for visit and subject .
 * @param state
 * @param index - index of plot (if null then selected cell index)
 * @type {OutputSelector<unknown, unknown, (res1: *, res2: *, res3: *, res4: *) => unknown>}
 */
export const getSeriesCases = createSelector(
  [(state,index)=>index, getSelectedCell, getSelectedLayout, getPlotSettings, getRawData],
  (index, cell, layout, plot, rawData) => {
    const  currentPlotSettings = (index!=null)
      ? plot[index]
      : plot[calculateIndex(cell, layout)];
    const contributionsToCases = (rawData != null && rawData.contributions != null && currentPlotSettings.selectedMeasurementConfiguration!=null)
      ? rawData.contributions
        .filter((c,i,ar) => filterContributionsByMeasurementsMode(c,i,ar,currentPlotSettings))
        .filter((c) => currentPlotSettings.selectedCases.includes(c.caseId))
        .filter((el)=> filterContributionsByContributorsMode(el,currentPlotSettings))
        .filter((el,index,cArray)=>
          currentPlotSettings.mode !== 0 // if "distribution mode" no need to check missing contributions strategy
          || checkMissingContributionsStrategy(currentPlotSettings,el,cArray)
        )
        .map(el => el.caseId)
      : [];

    return (rawData != null && rawData.cases != null)
      ? rawData.cases
        .filter(el => contributionsToCases.includes(el.uuid))
      : [];
  }
);