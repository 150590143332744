import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {
  getSelectedPlotIndex,
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import {updateBlandAltmanPlotData} from "../action/BAAction";
import DistributionPanel from "../component/DistributionPanel";
import CustomAnalysisPanel from "../component/CustomAnalysisPanel";




const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    plotSettings: getSelectedPlotSettings(state),
    plotIndex: getSelectedPlotIndex(state) // selector used here !
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    },
    updateBlandAltmanPlotData:()=>{
      dispatch(updateBlandAltmanPlotData());
    }
  }
};

export const ContainerCustomAnalysisPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(CustomAnalysisPanel));