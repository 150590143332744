import axios from 'axios';
import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS
} from './actionType';
import {HOST_URL} from "../../../Constants";



//LOGOUT PROCESS **************************************************************
const PATH_TO_LOGOUT = `${HOST_URL}/api/user/logout`;

function requestLogout() {
  return {
    type: LOGOUT_REQUEST
  }
}

function receiveLogout(auth) {
  return {
    type: LOGOUT_SUCCESS,
    auth
  }
}

/*
 * Main action:
 * - Remove token
 * - dispatch to reducer
 * @param {Object} creds - email and password
 * @return {Object} type, creds or err
 *
 */
export function logout(creds, onSuccess, onError) {
    // console.log('Executing logout. Creds:', creds);
    console.log('Executing logout.');
    return dispatch => {
        // Change state in case logout takes time to prevent user re-request
        dispatch(requestLogout());
        axios.post(PATH_TO_LOGOUT)
            .then(response => {
                    if (response.status !== 200){
                        onError(response.data);
                    } else {
                        dispatch(receiveLogout(creds));
                        onSuccess();
                    }
                })
            .catch(err => {
                    console.log(err);
                    onError(err);
                }
            )
    }
}