import {getSelectedPlotSettings} from "../selectors/SAMSelectors";
import {REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {
  markDuplicates,
  updatePlotDataState
} from "./BAAction";
import {UPDATE_PLOT_DATA_SAM} from "./actionType";
import {getSeriesObservations} from "../selectors/ObservationsSelectors";
import NetworkService from "../../helpers/io/NetworkService";
import {SAM_PLOT_TYPE} from "../reducer/SAMReducer";


/**
 *
 * @param plotData - data to be provided to plot component
 * @param index - optional parameter, if not specified reducer will change selected plot (slot)
 * @return {{index: *, plotData: *, type: string}}
 */
export const updatePlotData = (plotData, index = null) => ({
  type: UPDATE_PLOT_DATA_SAM,
  plotData,
  index  // optional parameter - if null the selected plot shall be updated
});

export const updateScatterPlotData = (plotIndex = null) => {
  return (dispatch, getState) => {
    const settings = plotIndex != null
      ? getState().sam.plotSettings[plotIndex]
      : getSelectedPlotSettings(getState());

    const instances = getSeriesObservations(getState(), plotIndex);

    const {mode,axisXIndex,axisYIndex,axisZIndex,selectedMeasurementConfiguration,dimensionality, plotType} = settings;

    if (mode === 1 && (plotType === SAM_PLOT_TYPE.SCATTER_PLOT || plotType === SAM_PLOT_TYPE.AVATAR_PLOT)) {  // if distribution and plot of type
      let dataForScatter = [];
      instances.forEach((instance, index) => {
          try {
            const xIndex = instance.contributions.findIndex(c=>c.measurementConfigurationId === selectedMeasurementConfiguration[axisXIndex].id); // find index of x in contributions
            let single = {
              "x": instance.contributions[xIndex].value, // change index here
              'realCase': instance.key.caseId,
              "visitX": "whatever",
              "visitY": "whatever",
              "dateX":  "whatever",
              "dateY":  "whatever",
              "contributionsX":[instance.contributions[xIndex]],
              "contributionsY":[],
              "contributionsZ":[],
              "index":  index,
              "mode":1
            };
            if (dimensionality>1) {
              const yIndex = instance.contributions.findIndex(c=>c.measurementConfigurationId === selectedMeasurementConfiguration[axisYIndex].id); // find index of y in contributions
              single["y"] = instance.contributions[yIndex].value;
              single["contributionsY"] = [instance.contributions[yIndex]];
            }
            if (dimensionality>2) {
              const zIndex = instance.contributions.findIndex(c=>c.measurementConfigurationId === selectedMeasurementConfiguration[axisZIndex].id); // find index of z in contributions
              single["z"] = instance.contributions[zIndex].value;
              single["contributionsZ"] =  [instance.contributions[zIndex]];
            }
            dataForScatter.push(single);

          } catch (err) {
            console.log("Missing sample for: ", instance);
          }

        });

      // then remove duplicates
      const dataForScatterWithDuplicates = markDuplicates(dataForScatter);
      dispatch(updatePlotData(dataForScatter, plotIndex));
      dispatch(updatePlotDataState(REQUEST_STATUS_SUCCESS, plotIndex));
    }
    if (mode === 1 && plotType === SAM_PLOT_TYPE.KERNEL_STATIC_PLOT) {  // if distribution and plot of type
      let dataForScatter =  instances.map(instance=>instance.contributions[axisXIndex].value);
      dispatch(getStaticPlotSAM(plotIndex,{x:dataForScatter,title:"KDE of "},NetworkService.getKernelPlot));
      // then remove duplicates
    }
    if (mode === 1 && plotType === SAM_PLOT_TYPE.BOX_STATIC_PLOT) {  // if distribution and plot of type
      let dataForScatter =  instances.map(instance=>instance.contributions[axisXIndex].value);
      dispatch(getStaticPlotSAM(plotIndex,{x:dataForScatter,title:"Box of "},NetworkService.getBoxPlot));
      // then remove duplicates
    }
    if (mode === 1 && plotType === SAM_PLOT_TYPE.HISTOGRAM_STATIC_PLOT) {  // if distribution and plot of type
      let dataForScatter =  instances.map(instance=>instance.contributions[axisXIndex].value);
      dispatch(getStaticPlotSAM(plotIndex,{x:dataForScatter,title:"KDE of ", freq: 30, xlab:"Measurement", breaks: "freedman-diaconis"},NetworkService.getHistogram));
      // then remove duplicates
    }
  };
};

export const getStaticPlotSAM = (plotIndex, dataToProcess, serviceFunction) => {
  return (dispatch,getState) => {

    dispatch(updatePlotDataState(REQUEST_STATUS_REQUESTED, plotIndex));
    serviceFunction(dataToProcess)
      .then(binaryData => {
        dispatch(updatePlotData(binaryData, plotIndex));
        dispatch(updatePlotDataState(REQUEST_STATUS_SUCCESS, plotIndex));
      })
      .catch(error => {
        const messageQueue = getState().messaging.msgQueue;
        messageQueue.show({
          sticky: false,
          life:10000,
          severity: 'error',
          summary: 'Error',
          detail:"Cannot calculate data for static plot."
        });
      });
  }
};