import React from 'react';
import PropTypes from "prop-types";
import 'brace/mode/json';
import 'brace/mode/text';
import 'brace/theme/github';
import DownloadLink from "react-download-link";
import {loadRegister} from "../../visualization/component/annotationTool/DemosPage";
import {DemoCard} from "../../visualization/component/annotationTool/DemoCard";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {InputText} from "primereact/components/inputtext/InputText";
import {getConfiguration} from "../../visualization/component/TaskView";
import {InputTextarea} from "primereact/components/inputtextarea/InputTextarea";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {TOOL_TYPE} from "../../../Constants";
import {FileInputButton} from "../../root/component/FileInputButton";


class GeneralTab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      register: null,
      filter:"",
      selectedDemo:null,
      isDemoSelectVisible:false
    };
    ["onUpdate","loadTemp"]
      .forEach(name => {
        this[name] = this[name].bind(this);
      });
  }
  componentDidMount() {
    loadRegister().then((data) => {
      this.setState({isLoading: false, register: data});
      }
    );
  }
  onUpdate(){
    const {updateTransferObject} = this.props;
    getConfiguration(this.state.selectedDemo).then(data => {
        this.setState({
          isLoading: false,
          isDemoSelectVisible:false
        });
      updateTransferObject(data);
      }
    );
  }
  loadTemp(file){
    const {updateTransferObject, messageQueue}=this.props;
    const handleFileRead = (e) => {
      try {
        const to = JSON.parse(fileReader.result.toString());
        updateTransferObject(to);
      }
      catch(e){
        messageQueue.show( {
          sticky: false,
          severity: 'error',
          summary: 'Error',
          detail:"The tool cannot be loaded due to incorrect JSON definition."
        });
      }
    };
    let fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  }


  render() {
    const {transferObject,updateTransferObject} = this.props;
    const {register,isLoading,isDemoSelectVisible,selectedDemo} = this.state;
    const footer = <i className="fa fa-check" title="You can play with it"/>;
    return (
      <div className="ui-g">
        <div className="ui-g-6">
          {transferObject!=null && transferObject['miniWorkflow'] !=null &&
          <React.Fragment>
            <div className="ui-g-4">
              <label>Name</label>
            </div>
            <div className="ui-g-8">
                <InputText style={{width:'100%'}}
                           value={transferObject['miniWorkflow']['currentTool']['name']}
                           onChange={(ev)=>{
                             transferObject['miniWorkflow']['currentTool']['name'] = ev.target.value;
                             updateTransferObject(transferObject);
                           }}
                />
            </div>
            <div className="ui-g-4">
              <label>Description</label>
            </div>
            <div  className="ui-g-8">
                <InputTextarea style={{width:'100%'}}
                               value={transferObject['miniWorkflow']['currentTool']['description']}
                               onChange={(ev)=>{
                                 transferObject['miniWorkflow']['currentTool']['description'] = ev.target.value;
                                 updateTransferObject(transferObject);
                               }}
                />

            </div>
            <div className="ui-g-4">
              <label>Version</label>
            </div>
            <div  className="ui-g-8">
              <InputText style={{width:'100%'}}
                         value={transferObject['miniWorkflow']['currentTool']['version']}
                         onChange={(ev)=>{
                           transferObject['miniWorkflow']['currentTool']['version'] = ev.target.value;
                           updateTransferObject(transferObject);
                         }}
              />
            </div>
            <div className="ui-g-4">
              <label>Type</label>
            </div>
            <div  className="ui-g-8" title={"The tool type is deprecated in this version of SPINE."}>
              <Dropdown style={{width:'100%'}}
                        value={transferObject['miniWorkflow']['currentTool']['type']}
                        options={Object.values(TOOL_TYPE).map(el=>{return {label:el,value:el}})}
                        onChange={(ev)=>{
                          transferObject['miniWorkflow']['currentTool']['type'] = ev.value;
                          updateTransferObject(transferObject);
                        }}
              />
            </div>
            <div className="ui-g-4">
              <label>Id</label>
            </div>
            <div  className="ui-g-8">
              <InputText style={{width:'100%'}} value={transferObject['miniWorkflow']['currentTool']['uuid']} disabled={true}/>
            </div>
          </React.Fragment>}
        </div>
        <div className="ui-g-6">
          <Button label={"Duplicate demo"} icon={"fa fa-folder-open-o"} onClick={()=>{this.setState({isDemoSelectVisible:true})}}/>
          <Button label={"Duplicate tool"} icon={"fa fa-cogs"} disabled={true}/>
          <span title={"Save to file on local disk"}>
                    <DownloadLink
                      className={"ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only fa fa-save"}
                      style={{color:"white"}}
                      label={""}
                      filename="TemporaryTool.json"
                      tagName={"i"}
                      exportFile={() => JSON.stringify(transferObject)}/>
           </span>
          <span title={"Load tool configuration from your local disk"}>
                        <FileInputButton
                          style={{display:"inline-block"}}
                          label=""
                          filter="application/json"
                          onChange={e => this.loadTemp(e.target.files[0])}
                        />
          </span>

          <Dialog header={"Select demo to duplicate tool"}
                  visible={isDemoSelectVisible}
                  modal={true}
                  onHide={()=>{this.setState({isDemoSelectVisible:false})}}
                  style={{width:'60vw'}}>
            <div style={{position: "fixed",width:"calc(60vw - 3em)", height: "4em", backgroundColor: "white", marginTop: "-1em", paddingTop: "1em", paddingBottom: "1em",zIndex:"1000"}}>
              <label>Search:</label><InputText onChange={(e)=>this.setState({filter:e.target.value})} value={this.state.filter}/>
              <Button label={"Select"} onClick={this.onUpdate} disabled={!(selectedDemo!=null)}/>
              <Button label={"Cancel"} onClick={()=>{this.setState({isDemoSelectVisible:false})}}/>
            </div>
            <div style={{marginTop:"1em"}}>
            <div style={{
              flexDirection: "row",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}>
              {!isLoading && register != null &&
              Object.keys(register)
                .filter(el=>{return (register[el]['title'].toLowerCase().includes(this.state.filter.toLowerCase()) || register[el]['description'].toLowerCase().includes(this.state.filter.toLowerCase())) })
                .filter(el=>{return register[el].type === "MANUAL_TASK" &&  register[el]['status'] === 'ENABLED'})
                .map(el => <div
                  onClick={()=>{this.setState({selectedDemo:el})}}
                >
                  <DemoCard
                    {... (selectedDemo ===el?{style : {backgroundColor:"aquamarine"}}:{})}
                    footer={footer}
                    headerClassName={register[el]['style']['icon']}
                    title={register[el]['title']}
                    subTitle={register[el]['subTitle']}
                    description={register[el]['description']}
                    filter={this.state.filter}
                  />
                </div>)
              }
            </div>
            </div>
          </Dialog>

        </div>
      </div>

    )
  };
}
export default GeneralTab;

GeneralTab.propTypes= {
  transferObject: PropTypes.object.isRequired,
  updateTransferObject: PropTypes.func.isRequired
};
