import React from "react";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {ListBox} from "primereact/components/listbox/ListBox";
import {Dialog} from "primereact/components/dialog/Dialog";
import {withTranslation} from 'react-i18next';
import {MultiSelect} from "primereact/components/multiselect/MultiSelect";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {ContainerTotalCasesFilteredByMeasurementPanel} from "../containers/ContainerTotalCasesFilteredByMeasurementPanel";
import {ContainerSelectedCasesFilteredByMeasurementPanel} from "../containers/ContainerSelectedCasesFilteredByMeasurementPanel";
import {ContainerTotalContributionsFilteredByMeasurementPanel} from "../containers/ContainerTotalContributionsFilteredByMeasurementPanel";
import {ContainerSelectedContributionsFilteredByMeasurementPanel} from "../containers/ContainerSelectedContributionsFilteredByMeasurementPanel";
import {getMeasurementShortLabel} from "../action/SAMAction";


/**
 * Component used for SELECTION of cases with use of filters.
 */

class CasesPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      selectedCases: [],
      selectedVisits: [],
      selectedSubjects: [],
      filterVisitOn: false,
      filterSubjectOn: false,
      totalCasesInfoVisible: false,
      selectedCasesInfoVisible: false,
      totalContributionsInfoVisible: false,
      selectedContributionsInfoVisible: false,
      casesInfoTitle: "Cases"
    };
    ["onApply"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    this.setState(
      {
        selectedCases: this.props.selectedCases,
        selectedVisits: this.props.selectedVisits,
        selectedSubjects: this.props.selectedSubjects,
        filterVisitOn: this.props.filterVisitOn,
        filterSubjectOn: this.props.filterSubjectOn
      }
    )
  }

  componentDidUpdate(prevProps, prevState, ss) {
    if (prevState.dialogVisible !== this.state.dialogVisible && this.state.dialogVisible)
      this.setState(
        {
          selectedCases: this.props.selectedCases,
          selectedVisits: this.props.selectedVisits,
          selectedSubjects: this.props.selectedSubjects,
          filterVisitOn: this.props.filterVisitOn,
          filterSubjectOn: this.props.filterSubjectOn
        }
      )
  }

  onApply() {
    const {rawData} = this.props;
    const {filterVisitOn, filterSubjectOn, selectedVisits, selectedSubjects} = this.state;


    const filtered = rawData.cases
      .filter(c => {
        return (!filterVisitOn || selectedVisits.includes(c.visitId))
      })
      .filter(c => {
        return (!filterSubjectOn || selectedSubjects.includes(c.subjectId))
      })
      .map(el => el.uuid);


    const duplicates = this.state.selectedCases
      .filter(function (val) {
        return filtered.indexOf(val) !== -1;
      });

    this.setState({
      selectedCases: duplicates
    });
  }

  render() {
    const {
      rawData, t, totalCasesFiltered, selectedCasesFiltered, selectedMeasurementConfiguration, updatePlotSettingsProperty,
      selectedContributionsFiltered, totalContributionsFiltered, plotIndex
    } = this.props;

    const measurementShortLabel = getMeasurementShortLabel(selectedMeasurementConfiguration, rawData);

    return (
      <div>
        <h3>Cases
          <Button onClick={() => {
            this.setState({dialogVisible: true})
          }}
                  icon={"fa fa-edit"}
                  title={"Edit"}
                  style={{float: "right"}}
          />
        </h3>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div id={"cases_" + plotIndex} style={{width: "80%"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{width: "10em"}}>All matching:</div>
              <a onClick={() => this.setState({
                totalCasesInfoVisible: true,
                casesInfoTitle: DIALOG_TITLE[0] + measurementShortLabel
              })}
                 title={MSG[0]}
                 className={"nav__text_link"}
                 style={{display: "block"}}
              >
                cases - {totalCasesFiltered.length}
              </a>
              <a onClick={() => this.setState({
                totalContributionsInfoVisible: true,
                casesInfoTitle: DIALOG_TITLE[2] + measurementShortLabel
              })}
                 className={"nav__text_link"}
                 style={{display: "block"}}
                 title={MSG[2]}>
                contributions - {totalContributionsFiltered.length}
              </a>

            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <div style={{width: "10em"}}>Selected & matching:</div>
              <a onClick={() => this.setState({
                selectedCasesInfoVisible: true,
                casesInfoTitle: DIALOG_TITLE[1] + measurementShortLabel
              })}
                 title={MSG[1]}
                 className={"nav__text_link"}
                 style={{display: "block"}}
              >cases - {selectedCasesFiltered.length}
              </a>
              <a onClick={() => this.setState({
                selectedContributionsInfoVisible: true,
                casesInfoTitle: DIALOG_TITLE[3] + measurementShortLabel
              })}
                 className={"nav__text_link"}
                 style={{display: "block"}}
                 title={MSG[3]}>
                contributions - {selectedContributionsFiltered.length}
              </a>
            </div>
          </div>
          <Dialog
            header={"Cases"}
            width={800}
            height={600}
            visible={this.state.dialogVisible}
            appendTo={document.body}
            onHide={() => {
              this.setState({dialogVisible: false})
            }}>
            <div className="ui-g-12" style={{height: "150px"}}>

              <h3>Filters</h3>
              <div className={"filter-row-sam"}>
                <div className="ui-checkbox-label" style={{width: "10em"}}/>
                <div className="ui-checkbox-label" style={{width: "60%"}}/>
                <div className="ui-checkbox-label"
                     style={{width: "1em", transform: "translate(-10px, 0px)"}}>{"Apply"}</div>
              </div>
              <div className={"filter-row-sam"}>
                <label htmlFor="visits" className="ui-checkbox-label">{"Visits"}</label>
                <MultiSelect
                  id={"visits"}
                  options={rawData.visits}
                  value={this.state.selectedVisits}
                  onChange={(e) => this.setState({selectedVisits: e.value})}
                  filter={true}
                />
                <Checkbox checked={this.state.filterVisitOn} onChange={e => this.setState({filterVisitOn: e.checked})}/>
              </div>
              <div className={"filter-row-sam"}>
                <label htmlFor="subjects" className="ui-checkbox-label">{"Subjects"}</label>
                <MultiSelect
                  id={"subjects"}
                  options={rawData.subjects}
                  value={this.state.selectedSubjects}
                  onChange={(e) => this.setState({selectedSubjects: e.value})}
                  filter={true}
                />
                <Checkbox checked={this.state.filterSubjectOn}
                          onChange={e => this.setState({filterSubjectOn: e.checked})}/>
              </div>
            </div>
            <div className="ui-g-12">
              <h3>Selected cases</h3>
              <Button
                label={t("general.button.selectall.label")}
                onClick={() => this.setState({selectedCases: rawData.cases.map(e => e.uuid)})}/>
              <Button
                label={t("general.button.deselectall.label")}
                onClick={() => this.setState({selectedCases: []})}/>
              <Button
                label={"Apply filters"}
                onClick={this.onApply}/>
              <ListBox
                options={rawData.cases}
                value={this.state.selectedCases}
                onChange={(e) => {
                  this.setState({selectedCases: e.value});
                }}
                filter={true}
                multiple={true}
                style={{overflowY: 'scroll', width: 'auto', height: "200px"}}
              />
            </div>

            <div>
              <Button onClick={() => {
                this.setState({dialogVisible: false});
                updatePlotSettingsProperty("selectedCases", this.state.selectedCases);
                updatePlotSettingsProperty("selectedVisits", this.state.selectedVisits);
                updatePlotSettingsProperty("selectedSubjects", this.state.selectedSubjects);
                updatePlotSettingsProperty("filterVisitOn", this.state.filterVisitOn);
                updatePlotSettingsProperty("filterSubjectOn", this.state.filterSubjectOn);
              }} icon={"fa fa-check"} label={"OK"}/>
              <Button onClick={() => {
                this.setState({dialogVisible: false})
              }} icon={"fa fa-close"} label={"Cancel"}/>
            </div>
          </Dialog>
          <Dialog
            id={"cases-contributions-panel-dialog"}
            appendTo={document.body}
            header={this.state.casesInfoTitle}
            closeOnEscape={true}
            resizable={true}
            visible={this.state.totalCasesInfoVisible || this.state.selectedCasesInfoVisible || this.state.totalContributionsInfoVisible || this.state.selectedContributionsInfoVisible}
            modal={false}
            onHide={() => {
              this.setState({
                totalCasesInfoVisible: false,
                selectedCasesInfoVisible: false,
                totalContributionsInfoVisible: false,
                selectedContributionsInfoVisible: false
              });
            }}
            contentStyle={contentStyle}
            style={dynamicStyle}>
            {this.state.totalCasesInfoVisible && <ContainerTotalCasesFilteredByMeasurementPanel/>}
            {this.state.selectedCasesInfoVisible && <ContainerSelectedCasesFilteredByMeasurementPanel/>}
            {this.state.totalContributionsInfoVisible && <ContainerTotalContributionsFilteredByMeasurementPanel/>}
            {this.state.selectedContributionsInfoVisible && <ContainerSelectedContributionsFilteredByMeasurementPanel/>}
          </Dialog>
        </div>
      </div>
    )
  }
}

export default withTranslation()(CasesPanel);

CasesPanel.propTypes = {
  rawData: PropTypes.object.isRequired,
  selectedMeasurementConfiguration: PropTypes.object.isRequired,
  updatePlotSettingsProperty: PropTypes.func.isRequired,
  plotIndex: PropTypes.object.isRequired,

  selectedContributionsFiltered: PropTypes.array,
  totalContributionsFiltered: PropTypes.array,
  totalCasesFiltered: PropTypes.array.isRequired,
  selectedCasesFiltered: PropTypes.array.isRequired,

  selectedCases: PropTypes.array.isRequired,
  selectedVisits: PropTypes.array.isRequired,
  selectedSubjects: PropTypes.array.isRequired,
  filterSubjectOn: PropTypes.bool.isRequired,
  filterVisitOn: PropTypes.bool.isRequired,
  t: PropTypes.func
};

const dynamicStyle = {
  maxHeight: (window.innerHeight),
  minHeight: (window.innerHeight * 0.7),
  // maxWidth: window.innerHeight / 250 < 3 ? window.innerWidth / 2 : window.innerWidth * 0.9,
  minWidth: (window.innerWidth / 2),
  overflowWrap: "break-word"
};


const contentStyle = {
  minHeight: (window.innerHeight * 0.7),
  minWidth: (window.innerWidth / 2)
};

const MSG = [
  "The number of all matching cases containing selected measurement",
  "The number of selected and matching cases containing selected measurement",
  "The number of all matching contributions containing  selected measurement",
  "The number of selected and matching contributions containing  selected measurement"
];

const DIALOG_TITLE = [
  "All cases containing ",
  "Selected cases containing ",
  "All contributions containing ",
  "Selected contributions containing "
];