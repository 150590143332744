
import {CAMERA_3D_TOOL} from "../../../Constants";
import * as macro from 'vtk.js/Sources/macro';
import {updateManualToolProperty} from "./ManualToolAction";


/**
 * ActionCreator for updating camera synchronization.
 * Requires camera3Dtool
 * @param shouldSync {boolean}
 * @returns {function(*,*)}
 */
export const updateCameraSynchronization = (shouldSync) => {
  return (dispatch,getState) => {

    const cameraTool = getState().visu.manualTool.manualToolState[CAMERA_3D_TOOL];
    const viewersState = getState().visu.viewers.viewersState;
    const renderers = Object.values(viewersState)
      .filter(el=>el.type==="3D" || el.type==="BRAINGRAPH")
      .map(el=>el.renderer);

    if (shouldSync){

      const cameras = renderers.map(el=>el.getActiveCamera());
      const firstCamera = renderers[0].getActiveCamera();
      renderers.forEach((el,index)=>{
        if (index>0){
          renderers[index].setActiveCamera(firstCamera);
        }
      });
      const sub = firstCamera.onModified(macro.chain(...renderers.map(el=>el.getRenderWindow().render)));

      dispatch(updateManualToolProperty(CAMERA_3D_TOOL,CAMERA_3D_TOOL ,true)); // store state
      dispatch(updateManualToolProperty(CAMERA_3D_TOOL,"subscription",sub)); // store function to unsubscribe
      dispatch(updateManualToolProperty(CAMERA_3D_TOOL,"cameras",cameras)); // store cameras instances

    }
    else {

      cameraTool["subscription"].unsubscribe();
      renderers.forEach((el,index)=>{
        el.setActiveCamera(cameraTool["cameras"][index]);
      });

      dispatch(updateManualToolProperty(CAMERA_3D_TOOL,CAMERA_3D_TOOL ,false)); // store state
      dispatch(updateManualToolProperty(CAMERA_3D_TOOL,"subscription",null)); // store function to unsubscribe
      dispatch(updateManualToolProperty(CAMERA_3D_TOOL,"cameras",null)); // store cameras instances
    }

  };
};