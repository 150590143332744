import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";

import SelectedContributionsPanel from "../component/SelectedContributionsPanel";



const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    selectedDataPoint: state.sam.selectedDataPoint
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    }
  }
};

/**
 * This container is for selected contributions according to DataPoint (eg. 'dot' from plot data)
 * @type {React.ComponentType<unknown>}
 */
export const ContainerSelectedContributionsPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SelectedContributionsPanel));