import {HOST_URL} from "../../../Constants";
import axios from 'axios'
import {
	STUDY_REQUEST,
	STUDY_FAIL,
	STUDY_SUCCESS,
	STUDY_LIST_REQUEST,
	STUDY_LIST_FAIL,
	STUDY_LIST_SUCCESS, SET_COHORT_VISIBILITY_FILTER, SET_COHORT_SORTER, STUDY_CLEAR,
} from './actionType'
// import {bravoTest, bravoTest2, mockStudy} from "../tests/Mocks";

//****************************************************************************
//
//                                Actions
//
//****************************************************************************


// Study actions *************************************************************

const requestStudyList = () => ({
	type: STUDY_LIST_REQUEST
});

const errorStudyList = err => ({
	type: STUDY_LIST_FAIL,
	err
});

const successStudyList = (studies) => ({
	type: STUDY_LIST_SUCCESS,
	studies
});

/**
 * Filter for the list of ALL cohorts.
 * @param filter
 * @returns {{type, filter: *}}
 */
export function setVisibilityFilter(filter) {
    return { type: SET_COHORT_VISIBILITY_FILTER, filter }
}

/**
 * Sorter for the list of ALL cohorts.
 * @param sorter
 * @returns {{type, filter: *}}
 */

export function setSorter(sorter) {
    return { type: SET_COHORT_SORTER, sorter }
}

//Get study (StudyList)
export const getStudyList = () => {
	// return dispatch =>dispatch (successStudyList([bravoTest,bravoTest2])); //testing multiple cohorts with mocks
	return (dispatch,getState) => {
		dispatch (requestStudyList());
        console.log('CohortAction.js :: getStudyList :: Before axios requres');
        axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
		return axios.get(HOST_URL+'/api/cohort'
		).then(response => {
      console.log('CohortAction.js :: getStudyList :: response', response);
			if(response.status !== 200) {
				dispatch (errorStudyList(response.data));
				return Promise.reject(response.data);
			} else {
        console.log('CohortAction.js :: getStudyList :: Before successStudyList');
				dispatch (successStudyList(response.data));
			}
		}).catch(err => dispatch (errorStudyList(err)));
	};
};


// One study *****************************************************************
const requestStudy = (idStudy) => ({
	type: STUDY_REQUEST,
	idStudy
});



const errorStudy = err => ({
	type: STUDY_FAIL,
	err
});

const successStudy = (study) => ({
	type: STUDY_SUCCESS,
	study
});


//Get study (StudyList) Local version
export const getStudy = (idStudy) => {
	return (dispatch, getState) => {
		dispatch (requestStudy(idStudy));
		console.log('CohortAction.js :: getStudy :: Before axios requres');
		axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
		return axios.get(HOST_URL+'/api/cohort/'+idStudy
		).then(response => {
			console.log('CohortAction.js :: getStudy :: response', response);
			if(response.status !== 200) {
				dispatch (errorStudy(response.data));
				return Promise.reject(response.data);
			} else {
				console.log('CohortAction.js :: getStudyList :: Before successStudyList');
				dispatch (successStudy(response.data));
			}
		}).catch(err => dispatch (errorStudy(err)));
	};
};


//Get study (StudyList) Local version
export const clearStudy = () => ({
	type: STUDY_CLEAR
});

