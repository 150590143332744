import {GROWL_MESSAGING_SETUP} from "../action/actionType";

export const messagingQueueReducer = (state = { msgQueue:null}, action) => {
    switch (action.type) {
        case GROWL_MESSAGING_SETUP:
            return Object.assign({},state, {
                msgQueue: action.referenceToGrowl,
            });
        default:
            return state;
    }
};