import {connect} from "react-redux";
import TimelinePanel from "../components/TimelinePanel";

const mapStateToProps = (state,ownProps) => {
  return {
    enrollments:state.spineQMonitor.enrollments,
    enrollmentsState:state.spineQMonitor.enrollmentsState,
    experimentId:state.spineQMonitor.experimentId,
    timelineData:state.spineQMonitor.timelineData,
    timelineDataState:state.spineQMonitor.timelineDataState
  }
};


const mapDispatchToProps = (dispatch) => {
  return { }
};

const projectContainerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerTimelinePanel = projectContainerCreator(TimelinePanel);

