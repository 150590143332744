import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import english from './locales/en/translation';
// import french from './locales/fr/translation';

import english from './locales/locale-en';
import french from './locales/locale-fr';
import polish from './locales/locale-pl';


import englishCountries from './locales/countries-en';
import polishCountries from './locales/countries-pl';
import frenchCountries from './locales/countries-fr';

import englishLangs from './locales/languages-en';
import polishLangs from './locales/languages-pl';
import frenchLangs from './locales/languages-fr';

import englishGender from './locales/gender-en';
import polishGender from './locales/gender-pl';
import frenchGender from './locales/gender-fr';

const resources = {
    en: { translation: english },
    fr: { translation: french },
    pl: { translation: polish }
};


i18n
    //TODO Replace local 'resource' with public as below after fixing issue with server!!!
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next

    .init({
        resources,
        // lng: 'en',
        fallbackLng:'en',
        debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

/**
 * Add Resource bundles below. Remember to use namespaces properly, ie.
 *
 * export default withTranslation(['countries','translation'])(Activities);
 * or
 * const { t } = useTranslation(['translation', 'countries']);
 *
 * Default namespace is "translation". When any namespace is mentioned in withTranslation, then
 * use full ns in calls:
 * t("countries:PL")  or t("translation:dashboard.tables.myTasks.header")
 *
 */

i18n.addResourceBundle('en', 'countries', englishCountries);
i18n.addResourceBundle('fr', 'countries', frenchCountries);
i18n.addResourceBundle('pl', 'countries', polishCountries);

i18n.addResourceBundle('en', 'languages', englishLangs);
i18n.addResourceBundle('pl', 'languages', polishLangs);
i18n.addResourceBundle('fr', 'languages', frenchLangs);

i18n.addResourceBundle('en', 'gender', englishGender);
i18n.addResourceBundle('fr', 'gender', frenchGender);
i18n.addResourceBundle('pl', 'gender', polishGender);


export default i18n;