import React from "react";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next'; // High order translation component
import EventsPage from "./EventsPage";
import SpineLogo from "../../authentication/component/SpineLogo";
import Footer from "../../root/Footer";

/**
 * List available public events (current and previous(?)) inside SPINE
 * @returns {*}
 */
class PublicEventsPage extends React.Component {
  
  constructor(props){
    super(props);

    // Set the state
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
      <div className="header landingPageHeaderTopbar"
           style={{display:"flex",alignItems:"center"}}>
        <div className="landingPageSpineLogoContainer" style={{display: "inline-flex",  margin: "auto", marginLeft:"16px"}}>
            <Link to='/'>
                <SpineLogo/>
            </Link>
        </div>
        <div className="reg-headtitle" style={{ color: "#A7FFFE",    margin: "auto", display: "inline-flex"}}>
            {t("events.header")}
        </div>
      </div>
      <div style={{height: 'calc(100vh - 75px)', overflowY: 'auto'}}>
        <EventsPage {...this.props} eventLink="/publicEvent/"/>
        <div className="ui-g-12">
          <Footer/>
        </div>
      </div>
    </React.Fragment>
    );
  }
};

export default withTranslation()(PublicEventsPage);
PublicEventsPage.propTypes = {
  getEvents: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
}