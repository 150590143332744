import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import ShowHide from "../../dashboard/components/ShowHide";
import {withTranslation} from "react-i18next";

/**
 * Project Panel for Dashboard.
 */
class ProjectTable extends React.Component {

    constructor() {
        super();
        this.state={filterCompleted: false};
        this.handleClick = this.handleClick.bind(this);
        this.onShowHideCompleted = this.onShowHideCompleted.bind(this);
    }

    handleClick() {

    }
    onShowHideCompleted(){
        this.setState({filterCompleted:!this.state.filterCompleted});
    }

    componentWillMount() {
        const {getProjects} = this.props;
        getProjects();
    }

    render() {

        const {projects,t} = this.props;

        const projectNodes = projects.map((p) =>
            <div className='spineRowStyle' key={p.uuid}>
                <Link to={`/project/` + p.uuid }>
                    <div className="spineColStyle">{p.name}</div>
                </Link>
                <div className="spineColStyle">{t("dashboard.tables.myProjects.property.role.value.coInvestigator")}</div>
                <div className="spineColStyle">{t("dashboard.tables.myProjects.property.status.value.active")}</div>
            </div>);

        return (
            <div style={{width: '96%', margin: '2%'}}>
                <span className="reg-headtitle">{t("dashboard.tables.myProjects.header")}</span>
                <div className="spineTableStyle">
                    <div className='spineRowHeaderStyle'>
                        <div className="spineColStyle">{t("dashboard.tables.myProjects.property.project.label")}</div>
                        <div className="spineColStyle">{t("dashboard.tables.myProjects.property.role.label")}</div>
                        <div className="spineColStyle">{t("dashboard.tables.myProjects.property.status.label")}</div>
                    </div>
                    <div className="spineTableScrollableContent">
                        <div className="spineTableBody">
                            {projectNodes}
                        </div>
                    </div>
                </div>
                <ShowHide clb={this.onShowHideCompleted} value={this.state.filterCompleted}/>
            </div>
        );
    }
};

export default withTranslation()(ProjectTable);

ProjectTable.propTypes = {
    getProjects: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
};



