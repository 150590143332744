import React from 'react'
import PropTypes from "prop-types";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {ColorPicker} from "primereact/components/colorpicker/ColorPicker";
import {Button} from "primereact/components/button/Button";
import {Spinner} from "primereact/components/spinner/Spinner";
import {withTranslation} from 'react-i18next';


/**
 * Component for displaying experiment results in live presenter.
 */
export class MultiPlotSidebar extends React.Component {

  constructor() {
    super();
    this.state = {}; //initial state is in action initializeAvatarScatterPlot
    ["onApply", "onCancel", "onShow"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {plotSettings} = this.props;
    this.setState(plotSettings);
  }

  onApply() {
    const {updatePlotSettings} = this.props;
    updatePlotSettings(this.state);
    this.props.onHide();
  }

  onCancel() {
    const {plotSettings} = this.props;
    this.setState(plotSettings);
    this.props.onHide();
  }

  onShow() {
    const {plotSettings} = this.props;
    this.setState(plotSettings);
  }


  render() {
    const {visible, position, onHide, t} = this.props;
    const {optionsForClinical, optionsForMeasurements, clinicalValue, measurementValue} = this.state;
    return (
      <Sidebar
        position={position}
        visible={visible}
        onHide={() => onHide()}
        onShow={() => this.onShow()}
      >
        <div className="ui-g-12">
          <h1 style={{fontWeight: 'normal'}}>
            <i className="fa fa-cog" style={{color: "green", margin: "0.5em 0.25em 0px 0px"}}/>
            {t("plot.ui.configuration.header")}
          </h1>
          <div className="ui-g-12">
            <div className="ui-g-12">
              <Button label={t("general.button.apply.label")} onClick={this.onApply}/>
              <Button label={t("general.button.cancel.label")} onClick={() => this.props.onHide()}/>
            </div>
          </div>
        </div>
      </Sidebar>
    )
  }
}

export default withTranslation()(MultiPlotSidebar);

MultiPlotSidebar.defaultProps = {};
MultiPlotSidebar.propTypes = {
  visible: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  plotSettings: PropTypes.object.isRequired,
  clearInteractivePlotState: PropTypes.func.isRequired,
  updatePlotSettings: PropTypes.func.isRequired,
  t: PropTypes.func
};
