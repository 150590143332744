import React from 'react';
import { withTranslation } from 'react-i18next';
import {Redirect, Link} from 'react-router-dom';

/**
 *  Not Found page to handle a direct links requests that are not associated with a component by redirecting to home
 */
class NotFound extends React.Component {
  render(){
  const {t} = this.props;
   return <div className="spinner-centered">
            {t("general.notFound.message.label")}
            <div>
              <Link to="/home">{t("general.notFound.redirectionlinkmessage.label")}</Link>
            </div>
          </div>;
  }
};

export default withTranslation()(NotFound);
