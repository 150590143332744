import {connect} from "react-redux";
import PicturePage from "../PicturePage";


const mapStateToProps = (state,ownProps) => {
    return {
        auth: state.auth
    }
};

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
    }
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
const ContainerPicturePage = connect( mapStateToProps, mapDispatchToProps)(PicturePage);
export default  ContainerPicturePage;