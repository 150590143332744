import React from 'react'
import PropTypes from 'prop-types'
import {renderSortIcon, sortByProperty} from "../../helpers/comparators";
import {withTranslation} from "react-i18next";


 class Rewards extends React.Component {

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
    }

    componentWillMount() {
        const {getProjects} = this.props;
        getProjects();
    }

    render() {

        let {projects,t} = this.props;
        projects = [];// Change to real data!!!
        const projectNodes = projects.map((p) =>
            <div className='spineRowStyle' key={p.uuid}>
                <div className="spineColStyle" onClick={this.handleClick}>{p.name}</div>
                <div className="spineColStyle">{p.name}</div>
                <div className="spineColStyle">{p.name}</div>
            </div>);
        return (
            <div style={{width:'96%',margin:'2%'}}>
                <span className="reg-headtitle">{t("dashboard.tables.myRewards.header")}</span>
                <div className="spineTableStyle">
                    {/*<div className='spineRowHeaderStyle'>*/}
                        {/*<div className="spineColStyle col3" onClick={()=>{sortByProperty("NAME",sorter,onSorterChange); }}>Skills {renderSortIcon('NAME',sorter)}</div>*/}
                        {/*<div className="spineColStyle col3" onClick={()=>{sortByProperty("EXPERTISE",sorter,onSorterChange); }}>Expertise  {renderSortIcon('EXPERTISE',sorter)}</div>*/}
                        {/*<div className="spineColStyle col3" onClick={()=>{sortByProperty("CONTRIBUTION",sorter,onSorterChange); }}>Contribution {renderSortIcon('CONTRIBUTION',sorter)}</div>*/}
                    {/*</div>*/}
                    {/*<div className="spineTableScrollableContent">*/}
                        {/*<div className="spineTableBody">*/}
                            {/*{skillNodes}*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
};


export default  withTranslation()(Rewards);
Rewards.propTypes = {
    getProjects: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
};