import {
    EVENTS_LIST_SUCCESS,
    EVENTS_LIST_REQUEST,
    EVENTS_LIST_FAIL
    } from "../action/actionType";
import {combineReducers} from "redux";

/**
 * events: [],
 *
 * @param state
 * @param action
 * @return {*}
 */
const eventsReducer = (state = {
    events: [],
    }, action) => {
    switch (action.type) {
        case EVENTS_LIST_REQUEST:
            return Object.assign({}, state, {
                events: []
            });
        case EVENTS_LIST_SUCCESS:
            return Object.assign({}, state, {
                events: action.events
            });
        case EVENTS_LIST_FAIL:
            return Object.assign({}, state, {
                events: []
            });
        default:
            return state;
    }
};

export const EventsReducer = combineReducers({
    events: eventsReducer
});