// This file provides actions for scenes only - mainly for synchronization of images within the scene
import axios from 'axios'

import {
    LUT_DESCRIPTION_REQUEST_FAILED, LUT_DESCRIPTION_REQUEST_SUCCESS,
    LUT_DESCRIPTION_REQUESTED,
    LUT_REQUEST_FAILED,
    LUT_REQUEST_SUCCESS,
    LUT_REQUESTED,
} from "./actionType";
import {updateScenesOnInputLoaded} from "./SceneAction";

import store from '../../Store'
import {HOST_URL} from "../../../Constants";

//lut actions********************************************************
export const requestLUT = (lutId) => ({
    type: LUT_REQUESTED,
    lutId
});

const errorLUT = (err,lutId) => ({
    type: LUT_REQUEST_FAILED,
    err,
    lutId
});

export const successLUT = (lutId,lutData) => ({
    type: LUT_REQUEST_SUCCESS,
    lutId,
    lutData
});

//LUT description actions********************************************************
export const requestLUTDescription = (lutId) => ({
    type: LUT_DESCRIPTION_REQUESTED,
    lutId
});

const errorLUTDescription = (err,lutId) => ({
    type: LUT_DESCRIPTION_REQUEST_FAILED,
    err,
    lutId
});

export const successLUTDescription = (lutId,lutData) => ({
    type: LUT_DESCRIPTION_REQUEST_SUCCESS,
    lutId,
    lutData
});

export const getLUT = (lutId) => {
    return dispatch => {
        dispatch (requestLUT(lutId));
        console.log('LUTAction.js :: getLUT :: Before axios request');

        let config = {
          headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };

        return axios.get(HOST_URL+'/api/lut/' + lutId, config).then(
          response => {
            if (response.status !== 200) {
              dispatch(errorLUT(response.data, lutId));
              return Promise.reject(response.data);
            } else {
              console.log('LUTAction.js :: getLUT :: response.data', response.data);
              dispatch(successLUT(lutId,response.data.lookupTable));
              dispatch(updateScenesOnInputLoaded(lutId));
            }
          }
        ).catch( err=>dispatch (errorLUT(err, lutId)) );

        // loadlutfromjson(lutId)
        //     .then((data)=>
        //     {
        //         dispatch(successLUT(lutId,data));
        //         dispatch(updateScenesOnInputLoaded(lutId));
        //     })
        //     .catch(err=>dispatch (errorLUT(err,lutId)));
    };
};

// //These are mock to simulate async loading of data - map with id and name of data file (json)
// let mockedLUTMap = null;
// loadLUTRegister().then(data=>mockedLUTMap = data); //Load register of mocked data
//
//
// //------------ToDO Remove this mocks when data collected from DB
// async function loadlutfromjson(lut) {
//     let response = await fetch(`/dist/jsonDocs/data/${mockedLUTMap[lut]}.json`);
//     const data = await response.json();
//     return await data;
// }


export const getLUTDescription = (lutDescriptionId) => {
    return dispatch => {
        dispatch (requestLUTDescription(lutDescriptionId));
        console.log('LUTAction.js :: getLUTDescription :: Before axios request');

        let config = {
          headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };

        return axios.get(HOST_URL+'/api/lutDescription/' + lutDescriptionId, config).then(
          response => {
          if (response.status !== 200) {
          dispatch(errorLUTDescription(response.data, lutDescriptionId));
          return Promise.reject(response.data);
        } else {
          console.log('LUTAction.js :: getLUT :: response.data', response.data);
          dispatch(successLUTDescription(lutDescriptionId,response.data.lookupTable));
          dispatch(updateScenesOnInputLoaded(lutDescriptionId));
        }
      }
      ).catch( err=>dispatch (errorLUTDescription(err, lutDescriptionId)) );

        // loadlutdescriptionfromjson(lutId)
        //     .then((data)=> {
        //         dispatch(successLUTDescription(lutId, data));
        //         dispatch(updateScenesOnInputLoaded(lutId));
        //     })
        //     .catch(err=>dispatch (errorLUTDescription(err,lutId)));
    };
};

//These are mock to simulate async loading of data - map with id and name of data file (json)
 let mockedLUTDescriptionMap = null;

loadLUTDescriptionRegister().then(data=>mockedLUTDescriptionMap=data);

async function loadLUTRegister() {
    let response = await fetch('/dist/jsonDocs/data/lutRegister.json',{cache: "reload"});
    const data = await response.json();
    return await data;
}

async function loadLUTDescriptionRegister() {
    let response = await fetch('/dist/jsonDocs/data/lutDescriptionRegister.json',{cache: "reload"});
    const data = await response.json();
    return await data;
}

//------------ToDO Remove this mocks when data collected from DB
async function loadlutdescriptionfromjson(lut) {
    let response = await fetch(`/dist/jsonDocs/data/${mockedLUTDescriptionMap[lut]}.json`);
    const data = await response.json();
    return await data;
}