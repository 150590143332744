import React from 'react'
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {ContainerManualTool} from "../../visualization/containers/ContainerManualTool";
import {getNestedProp} from "../../helpers/expressions";
import {ContainerROITableWidget} from "../containers/ContainerROITableWidget";


/**
 *
 * Panel being container for Visualization Tool.
 * Decomposed from SAMPanel due to dynamic creating of Manual Tool Configuration.
 * This is handled by lifecycle methods.
 */
export class VisualizationToolPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: null,
      height: null
    };
    // [""]
    //   .forEach(name => {
    //     this[name] = this[name].bind(this);
    //   });
  }


  componentDidUpdate(prevProps, prevState, snapshot) {

    const {visualizationToolDialogVisible,manualToolType, roi,createManualToolConfiguration,updateManualToolSAM, manualToolConfiguration} = this.props;
    if (prevProps.visualizationToolDialogVisible!==visualizationToolDialogVisible && visualizationToolDialogVisible && !(manualToolConfiguration!=null)){
         createManualToolConfiguration(manualToolType);
    }
    if (prevProps.visualizationToolDialogVisible!==visualizationToolDialogVisible && !visualizationToolDialogVisible){
      updateManualToolSAM(null); //clear it
    }
  }


  render() {
    const {selectedDataPoint,visualizationToolDialogVisible,onHide, manualToolConfiguration,manualToolType,rawData} = this.props;

    const dialogStyle = manualToolType==="group"
      ? {
        height: (window.innerHeight > window.innerWidth)?window.innerWidth*0.9:window.innerHeight*0.9,
        width: (window.innerHeight > window.innerWidth)? window.innerWidth / 2 : 1.6*window.innerHeight * 0.9,
        overflowWrap: "break-word"
      }
      : {
        maxHeight: (window.innerHeight),
        minHeight: (window.innerHeight * 0.7),
        maxWidth: window.innerHeight / 250 < 3 ? window.innerWidth / 2 : window.innerWidth * 0.9,
        minWidth: (window.innerWidth / 2),
        overflowWrap: "break-word"
      };

    const contentStyle = manualToolType==="group"
      ?
      {
        height: dialogStyle.height*0.90,
      }
      :{
        minHeight: (window.innerHeight * 0.7),
        minWidth: (window.innerWidth / 2)
      };

    const caseLabel = ()=>{
     if(getNestedProp(["realCase"],selectedDataPoint)!=null && getNestedProp(["cases"],rawData)!=null){
       const caseObject = getNestedProp(["cases"],rawData).find(el=>el.uuid === getNestedProp(["realCase"],selectedDataPoint));
       if (caseObject!=null)
         return caseObject.label;
       return getNestedProp(["realCase"],selectedDataPoint);
     }
      return "NA";
    };


    const label =  manualToolType==="group"
      ? "Group overlapping for case: " + caseLabel()
      : "Individual results for case: "+ caseLabel();
    return (
      <React.Fragment>
        <Dialog header={label}
                closeOnEscape={true}
                resizable={true}
                visible={visualizationToolDialogVisible}
                modal={false}
                onHide={onHide}
                contentStyle={contentStyle}
                style={dialogStyle}>
          {selectedDataPoint != null && !(manualToolConfiguration != null) && visualizationToolDialogVisible &&
          <div className="spinner-centered">Loading dataset <i className="fa fa-spinner fa-spin"/></div>
          }

          {selectedDataPoint != null && manualToolConfiguration != null && visualizationToolDialogVisible &&
          <ContainerManualTool
            predefinedToolConfiguration={manualToolConfiguration}
          />}
          {selectedDataPoint != null && manualToolConfiguration != null && visualizationToolDialogVisible &&
          <ContainerROITableWidget/>}
        </Dialog>
      </React.Fragment>
    )
  }
}

VisualizationToolPanel.defaultProps = {
  visualizationToolDialogVisible:false
};
VisualizationToolPanel.propTypes = {
  selectedDataPoint: PropTypes.object.isRequired,
  createManualToolConfiguration:PropTypes.func.isRequired,
  manualToolConfiguration: PropTypes.object.isRequired,
  updateManualToolSAM: PropTypes.func.isRequired,
  visualizationToolDialogVisible:PropTypes.bool.isRequired,
  rawData:PropTypes.object.isRequired
};

