import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {ContainerGeoComponent} from "../../arcgis/container/ContainerGeoComponent";
import {SITES_LAYER_ID} from "../../arcgis/action/GeoAction";
import {getNestedProp} from "../../helpers/expressions";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {withRouter} from "react-router";
import {OverlayPanel} from "primereact/components/overlaypanel/OverlayPanel";
import {ContainerManualToolDispatcher} from "../../visualization/containers/ContainerManualToolDispatcher";
import {toolOutputValid} from "../../visualization/component/annotationTool/ManualToolOutputValidator";

/**
 * Controller for executing skills with "map" configuration.
 * This is "Skill" equivalent to playSlidesManually (since there is no inheritance in React).
 * This version is using Redux to keep the state of "presentation".
 */
class PlaySkillMap extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      selectedPoint:{},
      selectedSiteId:null,
      rPanelVisible:false,  // visibility of recognition Panel
      isSubmitting:false
    };
    this.highlight = null;
    ["renderHelp","onHide","onSubmit","onCancel","onClickHandler","validateSubmit"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {layerState,updateSitesFromSkill,match, ackSignalState} = this.props;

    if ( prevProps.layerState!==layerState && layerState===REQUEST_STATUS_SUCCESS ) {// initialized layers
      console.log("UPDATED SITES");
      updateSitesFromSkill(match.params.skillId);
    }

    // submitted ack is changed to "", not necessarily to "success"
    if (prevProps.ackSignalState!==ackSignalState
      && ( ackSignalState===REQUEST_STATUS_SUCCESS || ackSignalState===REQUEST_STATUS_FAIL)) {
      updateSitesFromSkill(match.params.skillId);
      this.setState({
        rPanelVisible:false,
        isSubmitting:false
      });
    }
  }

  componentWillUnmount() {
    const {clearGeoView} = this.props;
    if (clearGeoView)
      clearGeoView();
  }

  renderHelp(){
    const {selectedPoint,selectedSiteId} = this.state;
    if (selectedPoint!=null && selectedPoint.id!=null)
      return (`Id: ${selectedSiteId} \n Latitude: ${selectedPoint.lat} \n Longitude: ${selectedPoint.lng} \nBiome: ${selectedPoint.biome}`);
    return "";
  }

  validateSubmit(){
    const {manualTask,  manualToolState,annotationsData,annotationsDefinition,annotationsFormData,annotationsFormDefinition, formsState} = this.props;
    return toolOutputValid(manualTask,manualToolState,annotationsData,annotationsDefinition,annotationsFormData,annotationsFormDefinition,formsState);
  }

  onHide(){
    const {updateFormProperty} = this.props;
    this.setState({
      rPanelVisible: false,
      selectedSiteId:null
    });
    if (this.highlight && this.highlight.remove)
      this.highlight.remove();
    const collection = document.getElementsByTagName("audio"); // HTMLCollection
    for (let i = 0; i < collection.length; i++) {
      collection[i].pause();
    }
    updateFormProperty("0","value",{});
  }

  onShow(){
    const collection = document.getElementsByTagName("audio"); // HTMLCollection
    for (let i = 0; i < collection.length; i++) {
      collection[i].currentTime=0;
    }
  }

  onSubmit(){
    const {saveOutputAndSubmit,manualTask} = this.props;
    if (manualTask!=null){
      saveOutputAndSubmit(
        true,
        getNestedProp(["expId"],manualTask),
        getNestedProp(["miniWorkflow","currentMaterializedTask","miniWorkflow"],manualTask),
        null, //miniWorkflowSetId is ignored by this function
        null);
      this.setState({isSubmitting:true});
    }
  }
  onCancel(){
    this.setState({
      rPanelVisible:false,
      confirmationDialogVisible: false
    });
  }

  /**
   * Default handler for onClick. Checks all the points hit in a given layer. If at least one of them meets criteria,
   * select it.
   * @return {function(...[*]=)}
   */
  onClickHandler (event,view) {
    const{messageQueue}=this.props;

    view.hitTest(event).then(response => {
      // only get the graphics returned from myLayer
      const graphicHits = response.results.filter(
        (hitResult) => hitResult.type === "graphic" && (hitResult.graphic.layer.type === 'geojson' || hitResult.graphic.layer.type === 'feature')
      );
      let biomes = "";
      const result = {};
      if (graphicHits.length > 0) {
        // do something with the myLayer features returned from hittest
        biomes = graphicHits
          .filter((graphicHit) => graphicHit.graphic.layer.title!==SITES_LAYER_ID)
          .map((graphicHit) => {  return graphicHit.graphic.layer.title;})
          .join(", ");

        const point = graphicHits
          .filter((graphicHit) => graphicHit.graphic.layer.title===SITES_LAYER_ID);

        if (point!=null && point.length>=1){ // if there is 1 and only 1 layer

          // find first non-red element
          const selPoint = point.find(el=>getNestedProp(["graphic","attributes","Status"],el)!=null
            && getNestedProp(["graphic","attributes","Status"],el)!=="");

          // if at least 1 non-red point is in the list of hit
          if (selPoint!=null){
            result["lat"] = selPoint.mapPoint['latitude'];
            result["lng"] = selPoint.mapPoint['longitude'];
            result["biome"] = biomes;
            result["id"] = getNestedProp(["graphic","attributes","__OBJECTID"],selPoint);

            const attributes = getNestedProp(["graphic","attributes"],selPoint); // get attributes
            if (attributes!=null) {
              this.setState({
                selectedSiteId: attributes.Site_ID,
                selectedPoint: result,
                rPanelVisible: true})
            }

            view.whenLayerView(selPoint.layer).then((layerView) => {
              if (this.highlight) {
                this.highlight.remove();
              }
              this.highlight = layerView.highlight(result["id"]);
            });
          }
          else {
            messageQueue.show({
              sticky: false,
              severity: 'warning',
              summary: 'Wrong Input',
              detail: 'All records in the selected location have been completed.'
            });

          }

          // Below old way of getting attributes by using Query to Layer data
          // Might be useful for geojsons layer data!

          // result["lat"] = point[0].mapPoint['latitude'];
          // result["lng"] = point[0].mapPoint['longitude'];
          // result["biome"] = biomes;
          // result["id"] = getNestedProp(["graphic","attributes","__OBJECTID"],point[0]);
          //
          // view.whenLayerView(point[0].layer).then((layerView) => {
          //   if (this.highlight) {
          //     this.highlight.remove();
          //   }
          //   this.highlight = layerView.highlight(result["id"]);
          // });
          //
          // const queryObject = point[0].layer.createQuery();
          // queryObject.where = `__OBJECTID = ${result["id"]}`;
          // point[0].layer.queryFeatures(queryObject).then((features)=>{
          //
          //   const attributes = getNestedProp(["features",0,"attributes"],features);
          //   if (attributes!=null) {
          //     this.setState({
          //       selectedSiteId: attributes.Site_ID,
          //       selectedPoint: result,
          //       rPanelVisible: true
          //     });
          //   }
          // });

        }
      }
    });
  };



  render() {
    const {selectedPoint,selectedSiteId,isSubmitting} = this.state;
    const {match,updateSitesFromSkill,legendInfo,t} = this.props;
    const {isValid,validationMessage}=this.validateSubmit();
    const footer =(
      <div style={{textAlign:"center"}}>
        <Button label={"Submit"} onClick={this.onSubmit}
                icon={(isSubmitting)?"fa fa-spinner fa-spin":"fa fa-save"}
                disabled={isSubmitting || !isValid}
                title={isValid?"Submit results":validationMessage}/>
        <Button label={"Cancel"} onClick={()=>{this.setState({confirmationDialogVisible: true})}} />
      </div>
    );

    return (
      <React.Fragment>
        <div className="slideshow-div" style={{display:"flex"}}>
          <ContainerGeoComponent   width={"100%"}
                                   height={"100%"}
                                   configurationId={match.params.id}
                                   onClick={this.onClickHandler}
                                   onClickCallback={(point,record)=>{
                                     if (point!=null && record !=null) {

                                     }
                                   }} />
          <OverlayPanel appendTo={document.body} id={"Legend"} style={legendStyle}>
            <h2>Samples</h2>
            <div style={rowStyle}>
              <div style={circle("#FF0000")} />
              <div style={labelStyle}>completed</div>
            </div>
            <div style={rowStyle}>
              <div style={circle("#00FF00")}>
              <span style={{marginLeft:"6px",color:"orange",fontWeight:"bold",fontSize:"14px"}}>2</span>
              </div>
              <div style={labelStyle}>2 available</div>
            </div>

            <h2>Cases completed</h2>
            <div style={rowStyle}>
              <div style={numStyle}>{legendInfo!=null?legendInfo.casesDoneByOtherUsers : ""}</div>
              <div style={labelStyle}> by other users</div>
            </div>
            <div style={rowStyle}>
              <div style={numStyle}>{legendInfo!=null?legendInfo.casesDoneByCurrentUser : ""}</div>
              <div style={labelStyle}> by You</div>
            </div>
            <Button icon={"fa fa-refresh"} onClick={()=>updateSitesFromSkill(match.params.skillId)}/>
          </OverlayPanel>

          <Dialog onHide={this.onHide}
                  onShow={this.onShow}
                  closable={false}
                  visible={this.state.rPanelVisible && selectedPoint!=null}
                  header={"Task"}
                  id={"identification-task-window"}
                  style={{width:"800px",height:"600px"}}
                  footer={footer}
                  contentStyle={{height:"85%"}}
          >
            { selectedPoint!=null && selectedPoint.id !=null &&
            <div>
              <h1> Please identify all birds recorded in the selected location: <i className={"fa fa-question-circle-o"}
                                                             style={{fontSize: "1.5em", marginLeft: "0.5em"}}
                                                             id={"helpIcon"}/>
              </h1>
              <Tooltip
                for="#helpIcon"
                escape={false}
                title={this.renderHelp()}
                tooltipEvent="hover"
              />
              {selectedSiteId!=null &&
              <ContainerManualToolDispatcher
                siteId={selectedSiteId}
              />}

            </div>}
          </Dialog>
          <Dialog header={t("messages.confirmation.header")}
                  visible={this.state.confirmationDialogVisible}
                  width="350px"
                  modal={true}
                  onHide={() => this.setState({confirmationDialogVisible: false})}
          >
            {t("messages.confirmation.question")}<br/>
            {t("messages.confirmation.warning")}
            <br/>
            <div style={{justifyContent: "center", display: "flex", marginTop: "1em"}}>
              <Button onClick={this.onCancel} label={t("general.button.yes.label")}/>
              <Button onClick={() => this.setState({confirmationDialogVisible: false})} label={t("general.button.no.label")}/>
            </div>
          </Dialog>

        </div>
      </React.Fragment>
    )

  }
}

PlaySkillMap.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  clearSkillState: PropTypes.func.isRequired,
  clearGeoView: PropTypes.func.isRequired,
  saveOutputAndSubmit: PropTypes.func.isRequired,
  manualTask: PropTypes.object.isRequired,
  ackSignalState:PropTypes.string.isRequired,
  updateFormProperty: PropTypes.func.isRequired,
  updateSitesFromSkill: PropTypes.func.isRequired,
  layerState: PropTypes.string.isRequired,
  legendInfo: PropTypes.object.isRequired,  // NOT Arcgis
  messageQueue: PropTypes.object.isRequired
};


export default withTranslation()(withRouter(PlaySkillMap));


const legendStyle = {
  position:"fixed",
  right: "15px",
  display: "block",
  top: "65px",
  width: "200px"
};
const circle = (color)=> {
  return {
  border: "3px solid "+color,
  backgroundColor: "rgba(0,0,0,0.7)",
  height: "25px",
  borderRadius:"50%",
  width: "25px",
  marginRight:"1em"
}};

const rowStyle= {
  display: "flex",
  justifyContent: "flex-start",
  alignContent: "center"
};

const labelStyle = {
  verticalAlign: "middle",
  lineHeight: "25px"
};

const numStyle = {
  marginRight: "1em",
  lineHeight: "25px"
};
