import {connect} from "react-redux";
import MeasurementPanelContent from "../component/MeasurementPanelContent";
import {getSelectedPlotSettings, isMultipleMeasurementMode} from "../selectors/SAMSelectors";
import {updateSelectedMeasurement} from "../action/SAMAction";


const mapStateToProps = (state,ownProps) => {
  return {
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration,
    isMultiple: isMultipleMeasurementMode(state),
    rawData: state.sam.rawData
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateSelectedMeasurement: (measurement) => {
      dispatch(updateSelectedMeasurement(measurement))
    }
  }
};

export const ContainerMeasurementPanelContent =  connect( mapStateToProps, mapDispatchToProps)(MeasurementPanelContent);


