import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";

/**
 * Component designed for displaying Group Panel in agreement module.
 *
 */

class BACalculator extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const {modelParameters,plotData} = this.props;

    const joinedData = plotData.map((el,index) =>{
      el['group1'] = modelParameters.groups[index].group1;
      el['group2'] = modelParameters.groups[index].group2;
      el['mean'] = modelParameters.means[index];
      el['diffs'] = modelParameters.diffs[index];
      return el});

    const total = [Object.assign({},modelParameters)].map(el=>{
      el["based"]=el["based.on"];
      el["lower"]=el["lower.limit"];
      el['upper']=el['upper.limit'];
      el['mean']=el['mean.diffs'];
      el['critical']=el['critical.diff'];
      el['CI']=el['CI.lines'];
      return el;
    });


    return (

      <React.Fragment>
        <h3>Cases</h3>
            <DataTable
              value={joinedData} // concat and remove dups
              emptyMessage={"No cases found"}
              paginator={true}
              rows={15}
            >
              <Column field="realCase" header="Case Id" sortable={true}/>
              <Column field="group1" header={<div title={"Average in Group 1"}>Group 1</div>} sortable={true}/>
              <Column field="group2" header={<div title={"Average in Group 2"}>Group 2</div>} sortable={true}/>
              <Column field="mean" header={<div title={"Mean of groups"}>Mean</div>} sortable={true}/>
              <Column field="diffs" header={<div title={"Difference between groups"}>Difference</div>} sortable={true}/>
            </DataTable>
            <h3>Statistics</h3>
            <DataTable
              value={total} // concat and remove dups
              emptyMessage={"No statistics found"}
            >
              <Column field={"based"} header={<div title={"Count of pairwise complete cases in groups"}>Total cases</div>} />
              <Column field={'lower'} header={<div title={"Lower limit for BA plot"}>Lower limit</div>} />
              <Column field={'mean'} header={<div title={"Mean of differences"}>Bias</div>} />
              <Column field={'upper'} header={<div title={"Upper limit for BA plot"}>Upper limit</div>} />
              <Column field="two" header={<div title={"Numeric defines how many standard deviations from mean are to be computed,\n" +
              "defaults to 1.96 as this gives proper 95 percent CI. However, in the original BA\n" +
              "publication a factor of 2 is used"}>Factor Two</div>} />
              <Column field="critical" header={<div title={"1.96 times standard deviation of differences, equals half\n" +
              "the difference of lower.limit and upper.limit"}>Critical difference</div>} />
              <Column field={'CI'} header={<div title={"Vector of confidence intervalls for the values of lines (based on the assumption of normal " +
              "distribution of differences diffs)"}>CI lines coeff.</div>} />
            </DataTable>
        <div style={{fontStyle:"italic",float:"right"}}>Generated with use of CRAN package:
          <a  style={{textDecoration:"underline"}} target={"_blank"}
              href={"https://cran.r-project.org/web/packages/BlandAltmanLeh/BlandAltmanLeh.pdf#nameddest=bland.altman.stats"}>
            BlandAltmanLeh
          </a>
        </div>
      </React.Fragment>

    );
  }
}

export default withTranslation()(BACalculator);



BACalculator.propTypes = {
  modelParameters: PropTypes.object.isRequired,
  plotData: PropTypes.array.isRequired,
  t: PropTypes.func
};
