import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {TOOL_LIST_REQUEST} from "../action/actionType";
import {TOOL_LIST_FAIL} from "../action/actionType";
import {TOOL_LIST_SUCCESS} from "../action/actionType";

/**
 *
 * Reducer responsible for management of tools related to workflows
 *
 * @param state - state of tasks, ListState:status for the list of optional workflows,
 * workflows: optional workflows, workflowVariable: measurement for which the list of matching workflows is created
 * @param action
 * @returns {*}
 */
export const toolListReducer = (state = { toolListState: '', tools: {}, workflowId:'' }, action) => {

  switch (action.type) {
    case TOOL_LIST_REQUEST:
      return Object.assign({},state, {
        toolListState: REQUEST_STATUS_REQUESTED,
        workflowId:action.workflowId
      });
    case TOOL_LIST_FAIL:
      return Object.assign({},state, {
        toolListState: REQUEST_STATUS_FAIL,
        workflowId:action.workflowId
      });
    case TOOL_LIST_SUCCESS:
      return Object.assign({}, state, {
        toolListState: REQUEST_STATUS_SUCCESS,
        tools:action.tools,
        workflowId:action.workflowId
      });
    default:
      return state;
  }
};