import {connect} from "react-redux";
import {getAllWorkflowList, setVisibilityFilter} from "../../expDesign/action/WorkflowAction";
import WorkflowPanelContent from "../component/WorkflowPanelContent";
import {getVisibilityFilter, getVisibleWorkflows} from "../selectors/WorkflowSelectors";



const mapStateToProps = (state,ownProps) => {
  return {
    workflows: getVisibleWorkflows(state),
    filter: getVisibilityFilter(state),
    workflowListState: state.expDesign.workflows.workflowListState
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getWorkflows: () => {
      dispatch(getAllWorkflowList())
    },
    onFilterChange: (filter) => {
      dispatch(setVisibilityFilter(filter))
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerWorkflowPanelContent = containerCreator(WorkflowPanelContent);