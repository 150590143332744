import {
  EXPERIMENT_SPINEQ_ENROLLMENTS_FAILURE,
  EXPERIMENT_SPINEQ_ENROLLMENTS_SUCCESS,
  EXPERIMENT_SPINEQ_ENROLLMENTS_REQUEST,
  EXPERIMENT_SPINEQ_QUESTIONNAIRES_REQUEST,
  EXPERIMENT_SPINEQ_QUESTIONNAIRES_SUCCESS,
  EXPERIMENT_SPINEQ_QUESTIONNAIRES_FAILURE,
  EXPERIMENT_SPINEQ_EVENT_LOGS_REQUEST,
  EXPERIMENT_SPINEQ_EVENT_LOGS_SUCCESS,
  EXPERIMENT_SPINEQ_EVENT_LOGS_FAILURE,
  EXPERIMENT_SPINEQ_TIMELINE_REQUEST,
  EXPERIMENT_SPINEQ_TIMELINE_SUCCESS,
  EXPERIMENT_SPINEQ_TIMELINE_FAILURE,
  EXPERIMENT_SPINEQ_BACKUPS_REQUEST, EXPERIMENT_SPINEQ_BACKUPS_SUCCESS, EXPERIMENT_SPINEQ_BACKUPS_FAILURE
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";

/**
 *
 *
 * @param state
 * @param action
 * @return {*}
 */
export const spineQMonitorReducer = (state = {
  enrollments: [],
  enrollmentsState:"",
  experimentId:"",
  error:{},
  questionnaireAnswers:[] ,
  questionnaireAnswersState:"",
  questionnaireBackups:[] ,
  questionnaireBackupsState:"",
  subjectId:"",
  subjectEventLogs:[],
  subjectEventLogsState:"",
  timelineData:[],
  timelineDataState:"",
}, action) => {
  switch (action.type) {
    case EXPERIMENT_SPINEQ_ENROLLMENTS_REQUEST:
      return Object.assign({}, state, {
        enrollmentsState:REQUEST_STATUS_REQUESTED,
        enrollments: [],
        experimentId: action.experimentId
      });
    case EXPERIMENT_SPINEQ_ENROLLMENTS_SUCCESS:
      return Object.assign({}, state, {
        enrollmentsState:REQUEST_STATUS_SUCCESS,
        experimentId: action.experimentId,
        enrollments: action.enrollments
      });
    case EXPERIMENT_SPINEQ_ENROLLMENTS_FAILURE:
      return Object.assign({}, state, {
        enrollmentsState:REQUEST_STATUS_FAIL,
        experimentId: action.experimentId,
        error:action.err
      });

    case EXPERIMENT_SPINEQ_QUESTIONNAIRES_REQUEST:
      return Object.assign({}, state, {
        questionnaireAnswersState:REQUEST_STATUS_REQUESTED,
        subjectId: action.subjectId,
        questionnaireAnswers: []
      });
    case EXPERIMENT_SPINEQ_QUESTIONNAIRES_SUCCESS:
      return Object.assign({}, state, {
        questionnaireAnswersState:REQUEST_STATUS_SUCCESS,
        subjectId: action.subjectId,
        questionnaireAnswers: action.questionnaireAnswers
      });
    case EXPERIMENT_SPINEQ_QUESTIONNAIRES_FAILURE:
      return Object.assign({}, state, {
        questionnaireAnswersState:REQUEST_STATUS_FAIL,
        subjectId: action.subjectId,
        error:action.err
      });

    case EXPERIMENT_SPINEQ_BACKUPS_REQUEST:
      return Object.assign({}, state, {
        questionnaireBackupsState:REQUEST_STATUS_REQUESTED,
        subjectId: action.subjectId,
        questionnaireBackups: []
      });
    case EXPERIMENT_SPINEQ_BACKUPS_SUCCESS:
      return Object.assign({}, state, {
        questionnaireBackupsState:REQUEST_STATUS_SUCCESS,
        subjectId: action.subjectId,
        questionnaireBackups: action.questionnaireAnswers
      });
    case EXPERIMENT_SPINEQ_BACKUPS_FAILURE:
      return Object.assign({}, state, {
        questionnaireBackupsState:REQUEST_STATUS_FAIL,
        subjectId: action.subjectId,
        error:action.err
      });

    case EXPERIMENT_SPINEQ_EVENT_LOGS_REQUEST:
      return Object.assign({}, state, {
        subjectEventLogsState:REQUEST_STATUS_REQUESTED,
        subjectId: action.subjectId,
        subjectEventLogs: []
      });
    case EXPERIMENT_SPINEQ_EVENT_LOGS_SUCCESS:
      return Object.assign({}, state, {
        subjectEventLogsState:REQUEST_STATUS_SUCCESS,
        subjectId: action.subjectId,
        subjectEventLogs: action.subjectEventLogs
      });
    case EXPERIMENT_SPINEQ_EVENT_LOGS_FAILURE:
      return Object.assign({}, state, {
        subjectEventLogsState:REQUEST_STATUS_FAIL,
        subjectId: action.subjectId,
        error:action.err
      });
    case EXPERIMENT_SPINEQ_TIMELINE_REQUEST:
      return Object.assign({}, state, {
        timelineDataState:REQUEST_STATUS_REQUESTED,
        timelineData:[]
      });
    case EXPERIMENT_SPINEQ_TIMELINE_SUCCESS:
      return Object.assign({}, state, {
        timelineDataState:REQUEST_STATUS_SUCCESS,
        timelineData:action.data
      });
    case EXPERIMENT_SPINEQ_TIMELINE_FAILURE:
      return Object.assign({}, state, {
        timelineDataState:REQUEST_STATUS_FAIL,
        error:action.err,
        timelineData: []
      });
    default:
      return state;
  }
};
