import React from 'react';

import store from '../.././Store';
import {ContainerPresentations} from '../container/ContainerPresentations';
import {ItemList} from "../../project/component/ItemList";
import LpItemListElementSlides from "./lpItemListElementSlides";
import LpItemCardElementSlides from "./lpItemCardElementSlides";


const presentation_list = {
    data: [
        {
            "name": "U Bordeaux",
            "status": "Public",
            "id": "abc"
        },
        {
            "name": "Brigham",
            "status": "Private",
            "id": "def"
        },
        {
            "name": "Fenway Park",
            "status": "Public",
            "id": "pqr"
        }
    ]
}


class editPresentation extends React.Component{

    componentDidMount() {
        const {getSlides} = this.props;
        getSlides(this.props.match.params['id']);
    }

    render() {
        const {match,addSlide} = this.props;
        const addSlideCallback = addSlide;
        const current_id = match.params.id;
        const current_item = this.props.presentations.find((el)=>el.uuid===current_id);
        // Call new data to get id_details
        const Slides_list = (props) => {
            return (
                <React.Fragment>
                    {/* {props.items.map(item => (
                        <React.Fragment key = {item.pos}>
                            <div style={{width: '1000px', border: '1px solid', margin: '20px 0px 20px 40px', padding: '0px 0px 0px 20px'}}>
                            <p>{item.pos}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.type}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.title}</p>
                            </div>
                        </React.Fragment>
                    ))} */}
                    <ItemList model = {props.items.sort((a,b) => {return parseInt(a.orderNum) - parseInt(b.orderNum)})}
                            isList = {true}
                            classNames = {["ui-g-2","ui-g-2","ui-g-4","ui-g-3","ui-g-1"]}
                            listRenderer = {<LpItemListElementSlides/>}
                            cardRenderer = {<LpItemCardElementSlides/>}
                            headers = {["Position","Type","Name","Time","",""]}/>
                </React.Fragment>
            )
        }

        function open_new_slide_modal(){
            document.getElementById('new_slide_modal').style.display = "block";
        }

        function close_new_slide_modal(){
            document.getElementById('new_slide_modal').style.display = "none";
        }

        function save_changes(){
            alert("Save changes");
        }

        function open_new_slide_web_modal(){
            close_new_slide_modal();
            document.getElementById('new_slide_website_modal').style.display = "block";
        }

        function close_new_slide_web_modal(){
            document.getElementById('new_slide_website_modal').style.display = "none";
        }

        function add_new_slide_web(){
            const web_url = document.getElementById('web_url').value;
            const web_heading = document.getElementById('web_heading').value;
            const web_desc = document.getElementById('web_desc').value;
            const web_time_min = document.getElementById('web_time_min').value;
            const web_time_sec = document.getElementById('web_time_sec').value;
            const new_slide_number = store.getState().presentation.slides.slide_list.length + 1;
            const new_slide = {
                "pos": new_slide_number,
                "type": "Website",
                "show": true,
                "title": web_heading
            };
            addSlideCallback(new_slide);
            close_new_slide_web_modal();
            alert("URL: " + web_url + "\nHeading: " + web_heading + "\nDesc: "+ web_desc + "\nTime min: " + web_time_min + "\nTime sec: " + web_time_sec);
        }

        function open_new_slide_vid_modal(){
            close_new_slide_modal();
            document.getElementById('new_slide_video_modal').style.display = "block";
        }

        function close_new_slide_vid_modal(){
            document.getElementById('new_slide_video_modal').style.display = "none";
        }

        function add_new_slide_vid(){
            const vid_url = document.getElementById('vid_url').value;
            const vid_heading = document.getElementById('vid_heading').value;
            const vid_desc = document.getElementById('vid_desc').value;
            const vid_time_min = document.getElementById('vid_time_min').value;
            const vid_time_sec = document.getElementById('vid_time_sec').value;
            const new_slide_number = store.getState().presentation.slides.slide_list.length + 1;
            const new_slide = {
                "pos": new_slide_number,
                "type": "Video",
                "show": true,
                "title": vid_heading
            };
            addSlideCallback(new_slide);
            close_new_slide_vid_modal();
            alert("URL: " + vid_url + "\nHeading: " + vid_heading + "\nDesc: "+ vid_desc + "\nTime min: " + vid_time_min + "\nTime sec: " + vid_time_sec);
        }

        function fetch_vid(){
            const fetch_vid_url = document.getElementById('vid_url').value;
            alert("Fetch video from " + fetch_vid_url);
            document.getElementById('vid_preview').innerHTML = "Video from " + fetch_vid_url;
        }

        function open_upload_video_modal(){
            document.getElementById('upload_video_modal').style.display = "block";
        }

        function close_upload_video_modal(){
            document.getElementById('upload_video_modal').style.display = "none";
        }

        function upload_video(){
            alert("Upload video");
        }

        function open_new_slide_img_modal(){
            close_new_slide_modal();
            document.getElementById('new_slide_image_modal').style.display = "block";
        }

        function close_new_slide_img_modal(){
            document.getElementById('new_slide_image_modal').style.display = "none";
        }

        function add_new_slide_img(){
            const img_url = document.getElementById('img_url').value;
            const img_heading = document.getElementById('img_heading').value;
            const img_desc = document.getElementById('img_desc').value;
            const img_time_min = document.getElementById('img_time_min').value;
            const img_time_sec = document.getElementById('img_time_sec').value;
            const new_slide_number = store.getState().presentation.slides.slide_list.length + 1;
            const new_slide = {
                "pos": new_slide_number,
                "type": "Image",
                "show": true,
                "title": img_heading
            };
            addSlideCallback(new_slide);
            close_new_slide_img_modal();
            alert("URL: " + img_url + "\nHeading: " + img_heading + "\nDesc: "+ img_desc + "\nTime min: " + img_time_min + "\nTime sec: " + img_time_sec);
        }



        console.log("Edit slides ... render");
        return (
            <React.Fragment>
                <input type = "text" defaultValue = {current_item.name} style = {{margin:'40px 0px 20px 40px'}}/>
                <button id = "save_button" className="ui-button ui-widget ui-state-default ui-corner-all" onClick = {save_changes} style = {{margin:'0px 0px 20px 40px'}}>Save Changes</button>
                <Slides_list items={this.props.slides} />
                <button id = "add_slide_button" className="ui-button ui-widget ui-state-default ui-corner-all" onClick = {open_new_slide_modal} style = {{margin:'20px 47% 0px 47%'}}>Add Slide</button>

                <div id="new_slide_modal" className="lp_modal">
                    <div className="lp_modal_content">
                        <span className="lp_modal_close" onClick={close_new_slide_modal}>&times;</span>
                        <h2>Choose the type of slide to be added</h2>
                        <button id="new_slide_web" className="ui-button ui-widget ui-state-default ui-corner-all" onClick = {open_new_slide_web_modal}>Website</button><br/><br/>
                        <button id="new_slide_exp" className="ui-button ui-widget ui-state-default ui-corner-all">Experiment</button><br/><br/>
                        <button id="new_slide_vid" className="ui-button ui-widget ui-state-default ui-corner-all" onClick = {open_new_slide_vid_modal}>Video</button><br/><br/>
                        <button id="new_slide_img" className="ui-button ui-widget ui-state-default ui-corner-all" onClick = {open_new_slide_img_modal}>Image</button><br/><br/>
                    </div>
                </div>

                <div id="new_slide_website_modal" className="lp_modal">
                    <div className="lp_modal_content">
                        <span className="lp_modal_close" onClick={close_new_slide_web_modal}>&times;</span>
                        <h2>Add Website</h2>
                        <p>URL</p>
                        <input id="web_url" placeholder="www.website.com"/>
                        <p>Title</p>
                        <input id="web_heading"/>
                        <p>Description</p>
                        <input id="web_desc"/>
                        <p>Time</p>
                        <input id="web_time_min" type="number" min="0" max="59" step="1"/>&nbsp;&nbsp;&nbsp;<span>min</span>&nbsp;&nbsp;&nbsp;
                        <input id="web_time_sec" type="number" min="0" max="59"/>&nbsp;&nbsp;&nbsp;<span>sec</span><br/><br/><br/>
                        <button id="add_slide_web" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={add_new_slide_web}>Save</button>&nbsp;&nbsp;&nbsp;
                        <button id="cancel_slide_web" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={close_new_slide_web_modal}>Cancel</button>
                    </div>
                </div>

                <div id="new_slide_video_modal" className="lp_modal">
                    <div className="lp_modal_content">
                        <span className="lp_modal_close" onClick={close_new_slide_vid_modal}>&times;</span>
                        <h2>Add Video</h2>
                        <p>URL</p>
                        <input id="vid_url" placeholder="www.video.com"/>
                        {/* <button id="upload_vid_button" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={open_upload_video_modal} style={{margin: "0px 0px 0px 5px"}}>Upload</button>
                        <button id="fetch_vid_button" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={fetch_vid} style={{margin: "0px 0px 0px 5px"}}>Fetch</button>
                        <p>Video Preview</p>
                        <p id="vid_preview">Video preview here</p> */}
                        <p>Time</p>
                        <input id="vid_time_min" type="number" min="0" max="59" step="1"/>&nbsp;&nbsp;&nbsp;<span>min</span>&nbsp;&nbsp;&nbsp;
                        <input id="vid_time_sec" type="number" min="0" max="59"/>&nbsp;&nbsp;&nbsp;<span>sec</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input id="vid_repetitions" type="number" min="0" max="59"/>&nbsp;&nbsp;&nbsp;<span>Repetitions</span><br/>
                        <p>Title</p>
                        <input id="vid_heading"/>
                        <p>Description</p>
                        <input id="vid_desc"/><br/><br/><br/>
                        <button id="add_slide_vid" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={add_new_slide_vid}>Save</button>&nbsp;&nbsp;&nbsp;
                        <button id="cancel_slide_vid" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={close_new_slide_vid_modal}>Cancel</button>
                    </div>
                </div>

                <div id="upload_video_modal" className="lp_modal">
                    <div className="lp_modal_content">
                        <span className="lp_modal_close" onClick={close_upload_video_modal}>&times;</span>
                        <h2>Upload Video</h2>
                        {/* <input type="file" name="local_video">Choose file</input> */}
                        <p>Public <input name="remember" type="checkbox" defaultChecked/></p>
                        <p>Video Title</p>
                        <input id="vid_heading"/>
                        <p>Description</p>
                        <input id="vid_desc"/><br/><br/><br/>
                        <button id="upload_vid_btn" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={upload_video}>Save</button>&nbsp;&nbsp;&nbsp;
                        <button id="cancel_upload_vid_btn" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={close_upload_video_modal}>Cancel</button>
                    </div>
                </div>

                <div id="new_slide_image_modal" className="lp_modal">
                    <div className="lp_modal_content">
                        <span className="lp_modal_close" onClick={close_new_slide_img_modal}>&times;</span>
                        <h2>Add Image</h2>
                        <p>URL</p>
                        <input id="img_url" placeholder="www.website.com/image.jpg"/>
                        <p>Title</p>
                        <input id="img_heading"/>
                        <p>Description</p>
                        <input id="img_desc"/>
                        <p>Time</p>
                        <input id="img_time_min" type="number" min="0" max="59" step="1"/>&nbsp;&nbsp;&nbsp;<span>min</span>&nbsp;&nbsp;&nbsp;
                        <input id="img_time_sec" type="number" min="0" max="59"/>&nbsp;&nbsp;&nbsp;<span>sec</span><br/><br/><br/>
                        <button id="add_slide_img" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={add_new_slide_img}>Save</button>&nbsp;&nbsp;&nbsp;
                        <button id="cancel_slide_img" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={close_new_slide_img_modal}>Cancel</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
;

export default editPresentation;