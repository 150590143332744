import { connect } from 'react-redux';
import PublicEventDetailsPage from '../component/PublicEventDetailsPage';

const mapStateToProps = state => {
  return{
    auth : state.auth
  }
};

const mapDispatchToProps = dispatch => ({
});

export const ContainerPublicEventDetailsPage = connect( mapStateToProps, mapDispatchToProps)(PublicEventDetailsPage);
