import math from "vtk.js/Sources/Common/Core/Math/index";
import {loggingDataset, loggingMatrix3by3} from "./MRIBrowserLogger";


const labelsPositive = ['R', 'A', 'I'];
const labelsNegative = ['L', 'P', 'S'];


const getDirectionInImageSpace = (directionVector) => {

    let maxPos = 0;
    let maxVal = Math.abs(directionVector[0]);

    if (Math.abs(directionVector[1]) > maxVal) {
        maxPos = 1;
        maxVal = Math.abs(directionVector[1]);
    }
    if (Math.abs(directionVector[2]) > maxVal) {
        maxPos = 2;
    }

    let direction = "NA";
    if (maxPos === 0) {
        if (directionVector[0] > 0)
            direction = "X";
        else
            direction = "-X";
    } else if (maxPos === 1) {
        if (directionVector[1] > 0)
            direction = "Y";
        else
            direction = "-Y"
    } else if (maxPos === 2) {
        if (directionVector[2] > 0)
            direction = "Z";
        else
            direction = "-Z";
    }

    return direction;
};

/**
 * Method that calculates the parameters required by VTK to properly display
 * an image given its direction matrix. The parameters are the following:
 *   - Slicing directions
 *   - Position of the camera (for each direction)
 *   - View up of the camera (for each direction)
 * To properly understand what we are dealing on with in this method read the
 * following webpages:
 *   - https://www.slicer.org/wiki/Coordinate_systems
 * @param {Object} image Image to be displayed in VTK
 */
const convertToLPI = (image) => {

    console.log("===");
    console.log("convertToLPI");

    // Get the direction matrix from the image.
    const dirMatrix_array = image.getDirection();

    // VTK specifies that the direction matrix that they expose corresponds
    // to the axes directions in world coordinates for I, J, K. See:
    // https://kitware.github.io/vtk-js/api/Common_DataModel_ImageData.html#getDirection-setDirection-array-9-setDirection-%E2%80%A6array
    const I_direction_world_coord = [dirMatrix_array[0], dirMatrix_array[1], dirMatrix_array[2]];
    const J_direction_world_coord = [dirMatrix_array[3], dirMatrix_array[4], dirMatrix_array[5]];
    const K_direction_world_coord = [dirMatrix_array[6], dirMatrix_array[7], dirMatrix_array[8]];

    // Find the corresponding axis in the image space
    const I_direction_img_space = getDirectionInImageSpace(I_direction_world_coord);
    const J_direction_img_space = getDirectionInImageSpace(J_direction_world_coord);
    const K_direction_img_space = getDirectionInImageSpace(K_direction_world_coord);

    // Set view up for coronal and sagittal slices. This correspond to the axis
    // being transformed to Z in the image space.
    let viewUp_coronal = [0, 0, 0];
    if (I_direction_img_space === "Z") {
        viewUp_coronal = [...I_direction_world_coord];
    } else if (I_direction_img_space === "-Z") {
        viewUp_coronal = [...I_direction_world_coord];
        math.multiplyScalar(viewUp_coronal, -1)
    } else if (J_direction_img_space === "Z") {
        viewUp_coronal = [...J_direction_world_coord];
    } else if (J_direction_img_space === "-Z") {
        viewUp_coronal = [...J_direction_world_coord];
        math.multiplyScalar(viewUp_coronal, -1)
    } else if (K_direction_img_space === "Z") {
        viewUp_coronal = [...K_direction_world_coord];
    } else if (K_direction_img_space === "-Z") {
        viewUp_coronal = [...K_direction_world_coord];
        math.multiplyScalar(viewUp_coronal, -1)
    }
    const viewUp_sagittal = [...viewUp_coronal];

    // Set view up for axial slices. This corresponds to the axis being
    // transformed to -Y in the image space. This is a particular case for
    // VTK.js
    let viewUp_axial = [0, 0, 0];
    if (I_direction_img_space === "Y") {
        viewUp_axial = [...I_direction_world_coord];
        math.multiplyScalar(viewUp_axial, -1)
    }
    if (I_direction_img_space === "-Y") {
        viewUp_axial = [...I_direction_world_coord];
    }
    if (J_direction_img_space === "Y") {
        viewUp_axial = [...J_direction_world_coord];
        math.multiplyScalar(viewUp_axial, -1)
    }
    if (J_direction_img_space === "-Y") {
        viewUp_axial = [...J_direction_world_coord];
    }
    if (K_direction_img_space === "Y") {
        viewUp_axial = [...K_direction_world_coord];
        math.multiplyScalar(viewUp_axial, -1)
    }
    if (K_direction_img_space === "-Y") {
        viewUp_axial = [...K_direction_world_coord];
    }

    // Build the viewUp matrix. This is build with by rows:
    // first -> axial
    // second -> sagittal
    // third -> Coronal
    const viewUp_matrix_ax_sa_co = [
        [...viewUp_sagittal],
        [...viewUp_coronal],
        [...viewUp_axial]
    ];

    console.log('viewUp_axial:', viewUp_axial);
    console.log('viewUp_sagittal:', viewUp_sagittal);
    console.log('viewUp_coronal:', viewUp_coronal);

    // Set the camera position for axial slices. This corresponds to the axis
    // being transformed to Z direction, which corresponds to the same direction
    // of the view up for coronal slices but inverted
    const cameraPos_axial = [...viewUp_coronal];
    math.multiplyScalar(cameraPos_axial, -1);

    // Set the camera position for sagittal slices. This corresponds to the axis
    // being transformed to X direction, which corresponds to the same direction
    // of the view up for coronal slices
    let cameraPos_sagittal = [0, 0, 0];
    if (I_direction_img_space === "X") {
        cameraPos_sagittal = [...I_direction_world_coord];
    }
    if (I_direction_img_space === "-X") {
        cameraPos_sagittal = [...I_direction_world_coord];
        math.multiplyScalar(cameraPos_sagittal, -1)
    }
    if (J_direction_img_space === "X") {
        cameraPos_sagittal = [...J_direction_world_coord];
    }
    if (J_direction_img_space === "-X") {
        cameraPos_sagittal = [...J_direction_world_coord];
        math.multiplyScalar(cameraPos_sagittal, -1)
    }
    if (K_direction_img_space === "X") {
        cameraPos_sagittal = [...K_direction_world_coord];
    }
    if (K_direction_img_space === "-X") {
        cameraPos_sagittal = [...K_direction_world_coord];
        math.multiplyScalar(cameraPos_sagittal, -1)
    }

    // Set the camera position for coronal slices. This corresponds to the axis
    // being transformed to -Y direction.
    let cameraPos_coronal = [0, 0, 0];
    if (I_direction_img_space === "Y") {
        cameraPos_coronal = [...I_direction_world_coord];
        math.multiplyScalar(cameraPos_coronal, -1)
    }
    if (I_direction_img_space === "-Y") {
        cameraPos_coronal = [...I_direction_world_coord];
    }
    if (J_direction_img_space === "Y") {
        cameraPos_coronal = [...J_direction_world_coord];
        math.multiplyScalar(cameraPos_coronal, -1)
    }
    if (J_direction_img_space === "-Y") {
        cameraPos_coronal = [...J_direction_world_coord];
    }
    if (K_direction_img_space === "Y") {
        cameraPos_coronal = [...K_direction_world_coord];
        math.multiplyScalar(cameraPos_coronal, -1)
    }
    if (K_direction_img_space === "-Y") {
        cameraPos_coronal = [...K_direction_world_coord];
    }

    // Build the camera position matrix. This is build with by rows:
    // first -> sagittal
    // second -> coronal
    // third -> axial

    const cameraPos_matrix_ax_sa_co = [
        [...cameraPos_sagittal],
        [...cameraPos_coronal],
        [...cameraPos_axial]
    ];

    console.log('camPos_axial:', cameraPos_axial);
    console.log('camPos_sagittal:', cameraPos_sagittal);
    console.log('camPos_coronal:', cameraPos_coronal);

    console.log("===");

    return {
        position: cameraPos_matrix_ax_sa_co,
        viewUp: viewUp_matrix_ax_sa_co
    }
};

/**
 * Calculates maximum values in columns of direction matrix.
 * Maximum values indicates slicing modes IJK.
 * @param cols3 - direction matrix in column-order
 * @return vector of slicing modes for orientations [SAGITTAL,CORONAL, AXIAL]
 * eg. [2,1,0] means Sagittal with K, Coronal with J, Axial with I
 */
function calculateSlicingModes(cols3) {
    return cols3.map((el) => {  //find absolute maximas in columns to set proper ImageMapper Slicing Mode
        let temp = el.map((eli) => {
            return Math.abs(eli);
        });
        return temp.indexOf(Math.max(...temp));
    });
}


/**
 * Calculates corrections for slicing directions for ImageMapper use.
 * Explanation:
 * In order to minimize the chances for misinterpretation we prefer to be consistent
 * in the use of radiology clinical conventions for direction of slicing:
 *  - left to right,
 *  - down to up
 *  - back to front.
 * Since Vtk ImageMapper uses direction matrix the directions might not be exactly the same, ie. setting slice to
 * 0 in axial might cause that browser will show most superior slice (when most inferior is expected).
 * Directions are used only for visualization in Sliders and to handle slicing up/down with keys.
 *
 * Maximum values indicates slicing modes IJK.
 * @param anat - anatomical orientation of image as String (eg. "RAS","ASL","LPI" etc.)
 * @return {[*,*,*]} vector of slicing direction coefficients for orientations [SAGITTAL,CORONAL, AXIAL]
 * eg. [-1,1,-1] means Sagittal real slicing direction is right to left, Coronal - back to front and Axial up to down.
 */
function calculateSlicingDirection(anat) {
    const slicingDirections = [1, 1, 1];
    [0, 1, 2].forEach((el) => {
        if (el === 0)
            slicingDirections[el] = (anat.includes('R')) ? -1 : 1;
        if (el === 1)
            slicingDirections[el] = (anat.includes('A')) ? -1 : 1;
        if (el === 2)
            slicingDirections[el] = (anat.includes('S')) ? -1 : 1;
    });
    return slicingDirections;
}

/**
 * This method calculates slicing direction and slicing Mode for different
 * orientation systems.
 * Resolves problem with shifted orientations (eg. ARS instead of RAS).
 * @version 1.0
 * @param image - vtkImageData object
 * @returns {*} - itkImage object
 */
export function convertToLPS(image) {

    const a = image.getDirection();
    const cols3 = [                      //Transposed since columns are used to find proper slicing mode when IJK are flipped, eg. RAS =IJK, but ASR=KIJ
        [a[0], a[3], a[6]],
        [a[1], a[4], a[7]],
        [a[2], a[5], a[8]]
    ];
    const rows3 = [                     //Rows are used to find position and viewup
        [a[0], a[1], a[2]],
        [a[3], a[4], a[5]],
        [a[6], a[7], a[8]]
    ];

    let maxCols = calculateSlicingModes(cols3); /// this can be  confusing - I,J,K might be switched
    let maxRows = rows3.map((el) => {  //find absolute maximas in rowss to set viewups on [0,-1,0] and [0,0,1]
        let temp = el.map((eli) => {
            return Math.abs(eli);
        });
        return temp.indexOf(Math.max(...temp));
    });
    //------------------For debugging and comparing with ITK-SNAP and 3D slicer-------------------------
    const getAnatomicalOrientation = () => {
        return (
            "".concat(
                rows3[0][maxRows[0]] > 0 ? labelsPositive[maxRows[0]] : labelsNegative[maxRows[0]],
                rows3[1][maxRows[1]] > 0 ? labelsPositive[maxRows[1]] : labelsNegative[maxRows[1]],
                rows3[2][maxRows[2]] > 0 ? labelsPositive[maxRows[2]] : labelsNegative[maxRows[2]]
            )
        );
    };

    const anat = getAnatomicalOrientation();
    let slicingDirections = calculateSlicingDirection(anat);
    console.log("Image anatomical orientation:", anat);
    console.log("Slicing directions:", slicingDirections);
    if (image)
        loggingDataset(image);


    // AMP
    const resConvertToLPI = convertToLPI(image);
    const viewUp = [...resConvertToLPI.viewUp];
    const position = [...resConvertToLPI.position];
    // PMA

    //------------------For debugging and comparing with ITK-SNAP and 3D slicer-------------------------
    // console.log("+=+=+=Derived matrices+=+=+=",anat);
    // console.log("Position matrix ",anat);
    // loggingMatrix3by3(position);

    return {
        slicingModes: maxCols, // VTK Slicing Mode I=0, J=1, K=2
        slicingDirections: slicingDirections, // Ascending or descending {-1,1}
        position: position, //camera initial Position
        viewUp: viewUp, // camera initial Viewup
        anatomicalOrientation: anat
    }
}