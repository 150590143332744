import React from "react";
import {Card} from "primereact/components/card/Card";
import PropTypes from "prop-types";
import {
    COHORT_PROPERTY_NAME__DESCRIPTION,
    COHORT_PROPERTY_NAME__NAME, COHORT_PROPERTY_NAME__PI,
    COHORT_PROPERTY_NAME__PRIVACY, COHORT_PROPERTY_NAME__SUBJECTS, COHORT_PROPERTY_NAME__SUMMARY_CLINICAL_TRIALS,
    COHORT_PROPERTY_NAME__UUID,
    COHORT_PROPERTY_VALUE_PRIVACY__PRIVATE,
    COHORT_PROPERTY_VALUE_PRIVACY__PUBLIC,
} from "../../../Constants";
import image from "../../sass/images/iconforcohort.jpg";
import {
    MOCK_CODE, MOCK_COHORT_DESCRIPTION, MOCK_COHORT_PI, MOCK_KEYWORDS, MOCK_LINK_TO_COHORT, MOCK_LINK_TO_G35,
    MOCK_LINK_TO_KEYS
} from "../tests/Mocks";

/** "Dumb" Renderer for "Cards" mode.
 * @param item - mandatory component if used with ItemList container
 * @param cohorts- all cohorts
 * @param onSelect - callback to provide handler for checkbox onSelect
 * @returns {*}
 * @constructor
 */
const CohortItemCardElement = ({item, onSelect, experiment}) => {

    // src={`data:image/png;base64,${image}`}/>; //switching to data acquired from DB via streaming

    const description =(item[COHORT_PROPERTY_NAME__SUMMARY_CLINICAL_TRIALS]!=null && item[COHORT_PROPERTY_NAME__SUMMARY_CLINICAL_TRIALS].length>0)
        ?(item[COHORT_PROPERTY_NAME__SUMMARY_CLINICAL_TRIALS].length>250)?item[COHORT_PROPERTY_NAME__SUMMARY_CLINICAL_TRIALS].slice(0,250)+"...":item[COHORT_PROPERTY_NAME__SUMMARY_CLINICAL_TRIALS]
        :"";


    let privacy = null;
    switch  (item[COHORT_PROPERTY_NAME__PRIVACY]) {
        case COHORT_PROPERTY_VALUE_PRIVACY__PUBLIC:{
            privacy = <div style={{float:'right'}}>
                <i className="fa fa-unlock cohortCardInternalIcon" title={"Public"} style={{fontSize:'20px'}}/>
            </div>;
            break;
        }
        default : {
            privacy = <div style={{float:'right'}}>
                <i className="fa fa-lock cohortCardInternalIcon" title={"Private"} style={{fontSize:'20px'}}/>
            </div>;
            break;
        }


    }
    const name = item[COHORT_PROPERTY_NAME__NAME];
    const header =
        <div className="cohortCardHeader">
            <img alt={name + ' Logo'} className="cohortCardIcon"  src={image} title={name + ' Logo'}/>

            {privacy}

            <div style={{float:'right'}}>
                <a target="_blank"href={(item["linkClinicalTrials"]!=null)?item["linkClinicalTrials"]:"https://clinicaltrials.gov/"}>
                    <i className="fa fa-external-link cohortCardInternalIcon" title={name+" Website"}/>
                </a>
            </div>
            <h1>{name}</h1>
            <div className="cohortDescription"> {description}</div>
        </div>;

    return (
        <Card className="cohortCard" header={header}>
            <div style={{height: '4em'}}>
                <hr/>
                <div >
                    <div title="Principal Investigator" style={{textAlign:'left'}}>
                        <i className="fa fa-user-md cohortCardInternalIcon"/>
                        <span className="cohortCardIconText">
                            {item[COHORT_PROPERTY_NAME__PI]}
                            {/*{MOCK_COHORT_PI}*/}
                        </span>
                    </div>
                    <div title="Number of subjects" style={{textAlign:'left'}}>
                        <i className="fa fa-users cohortCardInternalIcon"/>
                        <span className="cohortCardIconText">
                            {item[COHORT_PROPERTY_NAME__SUBJECTS].count}
                        </span>
                    </div>
                    <div title="Number of images" style={{textAlign:'left'}}>
                        <i
                            className="fa fa-picture-o  cohortCardInternalIcon"/>
                        <span className="cohortCardIconText"> {(item["images"]!=null)?item["images"].count:""}</span>
                    </div>

                    <div title="Keywords"  style={{textAlign:'left'}}>
                        <a target="_blank" href={MOCK_LINK_TO_KEYS + MOCK_CODE}>
                            <i className="fa fa-key cohortCardInternalIcon"/>
                            <span className="cohortCardIconText">{MOCK_KEYWORDS}
                            </span>
                        </a>
                    </div>

                    <div title="Remove from list" style={{float: 'right'}}>
                        <i className="fa fa-close cohortCardInternalIcon" />
                    </div>
                </div>
            </div>
        </Card>

    )
};

export default CohortItemCardElement;

CohortItemCardElement.propTypes = {
    item: PropTypes.object.isRequired,
    experiment: PropTypes.object.isRequired, //experiment
    cohortList: PropTypes.array,//does not apply here
    onSelect: PropTypes.func.isRequired,//callback to select cohort
    selectCheckboxState: PropTypes.func,// flag indicating whether all cohorts are selected
    handleChange: PropTypes.func.isRequired,//does not apply here, just passed with others
    isList: PropTypes.bool,//does not apply here
    listModeClb: PropTypes.func//does not apply here
};
