import {create1DArray} from "../../helpers/arrays";
import {
  CLEAR_INTERACTIVE_PLOT_SAM,
  COPY_PLOT_SETTINGS_SAM,
  DUPLICATE_PLOT_SETTINGS_SAM, SWITCH_ANALYSIS_MODE_SAM, UPDATE_EXTENDED_MODEL_PROPERTY_SAM,
  UPDATE_LAYOUT_SAM, UPDATE_MANUAL_TOOL_SAM,
  UPDATE_MODEL_PARAMETERS_SAM,
  UPDATE_MODEL_PARAMETERS_STATE_SAM, UPDATE_PLOT_DATA_DUPS_SAM,
  UPDATE_PLOT_DATA_SAM,
  UPDATE_PLOT_DATA_STATE_SAM, UPDATE_PLOT_SETTINGS_MAP_SAM, UPDATE_PLOT_SETTINGS_SAM, UPDATE_PROPERTY_SAM,
  UPDATE_RAW_PLOT_DATA_SAM, UPDATE_ROI_VISUALIZATION_DATA_SAM,
  UPDATE_SELECTED_CELL_SAM,
  UPDATE_SELECTED_DATA_POINT_SAM,
  UPDATE_SELECTED_MEASUREMENT_SAM,
  UPDATE_STATE_SAM
} from "../action/actionType";

/**
 * Initial state for all plot settings (agreement mode)
 * @return {Array | *[]}
 */
const createSettings = () => {
  return create1DArray(9, () => {
    return createBAPlotSettings();
  })
};

const createBAPlotSettings = ()=>{
  return {
    minNumberOfGroups: 2, // groups Panel
    maxNumberOfGroups: 2, // groups Panel
    initialGroupColor: '#1976D2',
    levelAgreementValue: 80, // groups Panel - need to figure out
    groups: [], // array of objects with color, array of contributors etc. (see GroupPanel)
    selectedCases: [], // cases panel - array of strings (id)
    selectedContributions: [], // contributions matching all filtering criteria, regardless from group NOT YET IMPLEMENTED
    selectedVisits: [], // cases panel - array of strings (id)
    selectedSubjects: [], // cases panel - array of strings (id)
    filterVisitOn:false, // cases panel
    filterSubjectOn:false, // cases panel
    selectedMeasurementConfiguration:null, // full variable definition "Number of EPVS where locatio=basal ganglia"
    percentageDifference: false,  // parameter from Other parameters
    aggregateCalculation:"mean", // parameter from Other parameters -  how to calculate measurement for group {"mean","median","trimmedMean"}
    missingContributionsStrategy:"ignored", // {ignored, rejected, extrapolated}
    possibleRatersAndCasesForIntra: [],
    intraCaseRaterSelected: null, // ??
    intraRaterHandling: 'ALL_DATA', // ??
    thresholdDistance: 5,
    mode: 0 // Agreement - Bland-altman}});
  };
};

const createDistributionPlotSettings =()=>{
  return {
    selectedCases: [], // cases panel - array of strings (id)
    selectedContributions: [], // contributions matching all filtering criteria, regardless from group NOT YET IMPLEMENTED
    selectedVisits: [], // cases panel - array of strings (id)
    selectedSubjects: [], // cases panel - array of strings (id)
    filterVisitOn:false, // cases panel
    filterSubjectOn:false, // cases panel
    selectedMeasurementConfiguration:[], // there might be more than 1 measurement
    plotType:SAM_PLOT_TYPE.SCATTER_PLOT, // {0:scatterPlot,1:avatarPlot,2:Kernal static plot, 3: static Boxplot }
    dimensionality:2, // 1,2,3 - D
    axisXIndex:0, // index of measurement to provide for axis X
    axisYIndex:1,
    axisZIndex:2,
    mode: 1, // Distribution}});
    compositeKey:["caseId","contributorId"],
    reps:"random" // {"random" - first met or random, "recent" -  most recent, "obsolete" - the oldest one from candidates}
  }
};

const createRegressionPlotSettings =()=>{
  return {
    selectedCases: [], // cases panel - array of strings (id)
    selectedContributions: [], // contributions matching all filtering criteria, regardless from group NOT YET IMPLEMENTED
    selectedVisits: [], // cases panel - array of strings (id)
    selectedSubjects: [], // cases panel - array of strings (id)
    filterVisitOn:false, // cases panel
    filterSubjectOn:false, // cases panel
    selectedMeasurementConfiguration:[], // there might be more than 1 measurement
    measurementIndexToSymbolMapping:{}, // this is required to build formula befor sending
    axisXIndex:0, // index of measurement to provide for axis X
    axisYIndex:1,
    family:"gaussian",
    confint:0.95,
    predint:0.95,
    formula:"Y~X1",
    showCI:false,
    showPI:false,
    interactions:false,
    mode: 3, // Regression
    compositeKey:["caseId","contributorId"],
    reps:"random" // {"random" - first met or random, "recent" - most recent, "obsolete" - the oldest one from candidates}
  }
};
const createCustomPlotSettings =()=>{
  return {
    selectedCases: [], // cases panel - array of strings (id)
    selectedContributions: [], // contributions matching all filtering criteria, regardless from group NOT YET IMPLEMENTED
    selectedVisits: [], // cases panel - array of strings (id)
    selectedSubjects: [], // cases panel - array of strings (id)
    filterVisitOn:false, // cases panel
    filterSubjectOn:false, // cases panel
    selectedMeasurementConfiguration:[], // there might be more than 1 measurement
    // plotType:SAM_PLOT_TYPE.SCATTER_PLOT, // {0:scatterPlot,1:avatarPlot,2:Kernal static plot, 3: static Boxplot }
    script:"# Put you code here\n# or load existing one from your disk\n# or choose predefined configuration\n" ,
    mode: 2 // Custom}});
  }
};


export const SAM_PLOT_TYPE = {
 SCATTER_PLOT:0,
 AVATAR_PLOT:1,
 KERNEL_STATIC_PLOT:2,
 BOX_STATIC_PLOT:3,
 HISTOGRAM_STATIC_PLOT:4
};

const createBAExtendedModelParameters = ()=>{
  return {
    ICCParameters:{
      "ratings":"all",  // {group1, group2, means, all}
      "model":"twoway",
      "type":"agreement",
      "r0":1,
      "unit":"single",
      "conf.level":0.95
    },
    ICCOutcome:{},
    ICCOutcomeState:"",
    ICCOutcomeError:{},
    kappaParameters:{
      "weight":"equal",
      "sort.levels":false,
    },
    kappaOutcome:{},
    kappaOutcomeState:"",
    kappaOutcomeError:{},
    mode: 0 // Agreement}});
  };
};
const createDistributionExtendedModelParameters = ()=>{
  return {
    psychOutcome:{},    // psych package is used
    psychOutcomeState:"",
    psychOutcomeError:{},
    mode: 1 // Distribution}});
  };
};

const createExtendedModelParameters = () => {
  return create1DArray(9, () => {
    return createBAExtendedModelParameters();
  })
};

const initialState = {
  selectedLayout:{row: 0, col: 0, className: "hover"},  // layout 2D
  selectedCell:{row: 0, col: 0},  // cell index in 2D - can be used both for layout component and plots on panel
  plotSettings : createSettings(), // plot settings (above)
  plotData: create1DArray(9,() => []),    // images requested or loaded
  plotDataWithDups: create1DArray(9,() => []), // optional plot Data with duplicates - non-empty only when duplicates are detected - keeps output of markDuplicates
  modelParameters: create1DArray(9,() => {}), // R server outcome - model params for plot
  modelParametersState: create1DArray(9,() => ""),  // R server outcome state
  extendedModelParameters: createExtendedModelParameters(), // parameters for extended models, eg. IRR, kappa
  rawData:{}, // raw data from result dataset format
  plotDataState:create1DArray(9,() => "success"), // Maybe different value?
  clipboardBuffer:null, // buffer for duplication of state
  selectedDataPoint:null, // case data
  manualToolConfiguration:null, // dynamically created manual tool conf for selected point
  roiVisualizationData:null,
  optionsSidebarVisible:false, // flag indicating whether sidebar should be visible
  visualizationToolDialogVisible:false, // // flag indicating whether dialog MRI should be visible
};

export const SAMReducer = (state = initialState, action) => {

  const getSelectedCellIndex = ()=>{
    return state.selectedCell.row * (state.selectedLayout.col +1) + state.selectedCell.col;
  };
  const getActionIndex = ()=>{
    return action.index!=null ? action.index : getSelectedCellIndex();
  };

  const updateArray = (arr,index)=>{
    let tempRow = Object.assign({},arr[index]);
    if (Array.isArray(action.value))
      tempRow[action.property] = action.value.slice(0);
    else if (typeof  action.value ==='object' && action.value != null)
      tempRow[action.property] = Object.assign({},action.value);
    else
      tempRow[action.property] = action.value;
    return [
      ...arr.slice(0, index),
      tempRow,
      ...arr.slice(index+1)
    ];
  };

  const updateArrayWithObject = (arr,index)=>{
    let tempRow = Object.assign({},arr[index]);
    Object.keys(action.properties).forEach(key=> {
      if (Array.isArray(action.properties[key]))
        tempRow[key] = action.properties[key].slice(0);
      else if (typeof action.properties[key] === 'object' && action.properties[key] != null)
        tempRow[key] = Object.assign({}, action.properties[key]);
      else
        tempRow[key] = action.properties[key];
    });
    return [
      ...arr.slice(0, index),
      tempRow,
      ...arr.slice(index+1)
    ];
  };

  switch (action.type) {
    case CLEAR_INTERACTIVE_PLOT_SAM:{
      console.log('SAM: Update plot settings:', action.plotSettings);
      return Object.assign({}, state, initialState);
    }
    case UPDATE_STATE_SAM:{
      console.log('SAM: Update state:', action.state);
      return Object.assign({}, action.state);
    }
    case UPDATE_PLOT_SETTINGS_SAM:{
      console.log('SAM: Update plot settings:', action.property, action.value);
      const index = getSelectedCellIndex();
      return Object.assign({}, state, {
        plotSettings:updateArray(state.plotSettings,index)
      });
    }
    case UPDATE_PLOT_SETTINGS_MAP_SAM:{
      console.log('SAM: Update plot settings with object props:', action.properties);
      const index = getSelectedCellIndex();
      return Object.assign({}, state, {
        plotSettings:updateArrayWithObject(state.plotSettings,index)
      });
    }
    case SWITCH_ANALYSIS_MODE_SAM:{
      console.log('SAM: Change plot mode:', action.mode);
      const index = getSelectedCellIndex();
      let initialPlotSettings;
      switch (action.mode) {
        case 1 :
          initialPlotSettings = createDistributionPlotSettings();
          break;
        case 2 :
          initialPlotSettings = createCustomPlotSettings();
          break;
        case 3 :
          initialPlotSettings = createRegressionPlotSettings();
          break;
        default:
          initialPlotSettings = createBAPlotSettings();
      }

      let plotSetingsRow = Object.assign({},initialPlotSettings);
      const plotSettings=  [
        ...state.plotSettings.slice(0, index),
        plotSetingsRow,
        ...state.plotSettings.slice(index+1)
      ];
      let initialExtendedModel = action.mode === 0
        ? createBAExtendedModelParameters()
        : createDistributionExtendedModelParameters();
      let extModelRow = Object.assign({},initialExtendedModel);
      const extendedModelParameters=  [
        ...state.extendedModelParameters.slice(0, index),
        extModelRow,
        ...state.extendedModelParameters.slice(index+1)
      ];
      return Object.assign({}, state, {
        plotSettings:plotSettings,
        extendedModelParameters:extendedModelParameters
      });
    }
    case COPY_PLOT_SETTINGS_SAM:{
      const index = getSelectedCellIndex();
      console.log('SAM: Copy plot settings to clipboard. Plot index: ' + index);
      return Object.assign({}, state, {
        clipboardBuffer : Object.assign({},state.plotSettings[index])
      });
    }
    case DUPLICATE_PLOT_SETTINGS_SAM:{
      console.log('SAM: Duplicate plot settings from clipboard');
      if (!(state.clipboardBuffer!=null))
        return Object.assign({}, state);
      if (!action.forAllFlag){
        const index = getSelectedCellIndex();
        let tempRow = Object.assign({},state.clipboardBuffer);
        const plotSettings=  [
          ...state.plotSettings.slice(0, index),
          tempRow,
          ...state.plotSettings.slice(index+1)
        ];
        return Object.assign({}, state, {
          plotSettings:plotSettings
        });
      }
      return Object.assign({}, state, {
        plotSettings:
          create1DArray(9,()=>Object.assign({},state.clipboardBuffer))
      });

    }
    case UPDATE_PLOT_DATA_SAM:{
      console.log('SAM: Update plot data:', action.plotData, action.index);
      const index = getActionIndex();
      const plotData=  [
        ...state.plotData.slice(0, index),
        action.plotData.slice(0),
        ...state.plotData.slice(index+1)
      ];
      return Object.assign({}, state, {
        plotData:plotData
      });
    }
    case UPDATE_PLOT_DATA_DUPS_SAM:{
      console.log('SAM: Update plot data with duplicates:', action.plotData,action.index);
      const index = getActionIndex();
      const plotDataWithDups=  [
        ...state.plotDataWithDups.slice(0, index),
        action.plotData.slice(0),
        ...state.plotDataWithDups.slice(index+1)
      ];
      return Object.assign({}, state, {
        plotDataWithDups:plotDataWithDups
      });
    }
    case UPDATE_RAW_PLOT_DATA_SAM:{
      console.log('SAM: Update raw data:', action.rawData);
      return Object.assign({}, state, {
        rawData:Object.assign({},action.rawData)
      });
    }
    case UPDATE_MANUAL_TOOL_SAM:{
      console.log('SAM: Update man tool conf:', action.tool);
      return Object.assign({}, state, {
        manualToolConfiguration:action.tool  // do not assign empty object here!!!
      });
    }
    case UPDATE_ROI_VISUALIZATION_DATA_SAM:{
      console.log('SAM: Update man tool conf:', action.data);
      return Object.assign({}, state, {
        roiVisualizationData:action.data  // do not assign empty object here!!!
      });
    }
    case UPDATE_PLOT_DATA_STATE_SAM:{
      console.log('SAM: Update plot data:', action.state,action.index);
      const index = getActionIndex();
      const plotDataState=  [...state.plotDataState.slice(0)];
      plotDataState[index]=action.state;
      return Object.assign({}, state, {
        plotDataState:plotDataState
      });
    }

    case UPDATE_MODEL_PARAMETERS_STATE_SAM:{
      console.log('SAM: Update model param state:', action.state,action.index);
      const index = getActionIndex();
      const modelState=  [...state.modelParametersState.slice(0)];
      modelState[index]=action.state;
      return Object.assign({}, state, {
        modelParametersState:modelState
      });
    }
    case UPDATE_MODEL_PARAMETERS_SAM:{
      console.log('SAM: Update model param:', action.model,action.index);
      const index = getActionIndex();
      const model=  [...state.modelParameters.slice(0)];
      model[index]=Object.assign({},action.model);
      return Object.assign({}, state, {
        modelParameters:model
      });
    }
    case UPDATE_LAYOUT_SAM:{
      console.log('SAM: Update layout', action.selectedCell);
      return Object.assign({}, state, {
        selectedLayout:Object.assign({},action.selectedCell),
        selectedCell: Object.assign({},{row:0,col:0})
      });
    }
    case UPDATE_SELECTED_CELL_SAM:{
      console.log('SAM: Update cell', action.selectedCell);
      return Object.assign({}, state, {
        selectedCell: Object.assign({},action.selectedCell)
      });
    }
    case UPDATE_SELECTED_DATA_POINT_SAM:{
      console.log('SAM: Update datapoint', action.selectedDataPoint);
      return Object.assign({}, state, {
        selectedDataPoint: Object.assign({},action.selectedDataPoint)
      });
    }
    case UPDATE_SELECTED_MEASUREMENT_SAM:{
      console.log('SAM: Update measurement in plot number', action.index);
      let tempRow = Object.assign({},state.plotSettings[action.index]);
      tempRow.selectedMeasurementConfiguration = action.measurement;
      const plotSettings=  [
        ...state.plotSettings.slice(0, action.index),
        tempRow,
        ...state.plotSettings.slice(action.index+1)
      ];
      return Object.assign({}, state, {
        plotSettings: plotSettings
      });
    }
    case UPDATE_PROPERTY_SAM:{
      console.log('SAM: Update property: ', action.property, action.value);
      const newObject={};
      newObject[action.property] = action.value;
      return Object.assign({}, state, newObject);
    }
    case UPDATE_EXTENDED_MODEL_PROPERTY_SAM:{
      console.log('SAM: Update ext model property: ', action.property, action.value);
      const index = getSelectedCellIndex();
      return Object.assign({}, state, {
        extendedModelParameters:updateArray(state.extendedModelParameters,index)
      });
    }
    default:
      return state;
  }
};

