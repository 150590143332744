import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from 'react-i18next';
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import {ContainerMeasurementPanelContent} from "../containers/ContainerMeasurementPanelContent";
import {ContainerCasesPanel} from "../containers/ContainerCasesPanel";
import {ContainerGroupPanel} from "../containers/ContainerGroupPanel";
import {ContainerBAParametersPanel} from "../containers/ContainerBAParametersPanel";
import {ContainerValidDataPanel} from "../containers/ContainerValidDataPanel";

/**
 * Component used for SELECTION of Layout.
 * It also provides UI for saving, retrieving and copy & paste.
 */

class BlandAltmanPanel extends React.Component {

  constructor(props) {
    super(props);
  }

  onApplyBlandAltman() {

    // const groupsWithContributions = groups.map((group, key)=>{
    //   const filterData = this[`filterData_${key}`].filterData();
    //   group.contributions = [...new Set(Object.keys(filterData.cases).map(key=>filterData.cases[key].measurement.volume.contribution.map(contribution=>contribution.userId)).flat())];
    //   if (group.contributions.length == 0)
    //     emptyGroup.push(group);
    //   return group;
    // });
    // if(minNumberOfGroups <= numberOfGroups && maxNumberOfGroups >= numberOfGroups && selectedCases.length > 0){
    //   // const newState = {...this.state, groups: groupsWithContributions};
    //   updatePlotSettings(newState);
    //   // this.props.onHide();
    // } else {
    //   alert(`You need to define minimum ${minNumberOfGroups} and maximum ${maxNumberOfGroups} group(s) (with their respective raters) and at least select one case.`);
    // }
  }

  render() {
    const {t,plotSettings} = this.props;
    return (
      <React.Fragment>
      <TabView style={{}}>
        <TabPanel header="Inter-rater" contentStyle={{height: '100%', padding: '0.5em'}}>
          <div>
            <ContainerMeasurementPanelContent/>
            <ContainerCasesPanel/>
            <h3>Groups</h3>
            <ContainerGroupPanel/>
            <ContainerBAParametersPanel/>
            <ContainerValidDataPanel/>
          </div>
        </TabPanel>
        {/* //TODO: Add logic to handle intra-rater selection sidebar */}
        <TabPanel header="Intra-rater"
                  disabled={true}
          //          disabled={this.state.possibleRatersAndCasesForIntra==null || this.state.possibleRatersAndCasesForIntra.length === 0}
        >
          <div className="ui-g-12" style={{maxWidth: '100%', whiteSpace: 'nowrap' }}>
            {/*<Dropdown*/}
            {/*    placeholder="Select the case-rater combinati}on"*/}
            {/*    value={this.state.intraCaseRaterSelected}*/}
            {/*    options={this.state.possibleRatersAndCasesForIntra}*/}
            {/*    onChange={(e) => {*/}
            {/*        this.setState({intraCaseRaterSelected: e.value})*/}
            {/*    }}*/}
            {/*    filter={true}*/}
            {/*    filterBy='label'*/}
            {/*    style={{width: '100%'}}*/}
            {/*    panelStyle={{width: '100%'}}/>*/}
          </div>
        </TabPanel>

      </TabView>

      </React.Fragment>
    );
  }
}

export default withTranslation()(BlandAltmanPanel);

BlandAltmanPanel.propTypes = {
  rawData:PropTypes.object.isRequired,
  plotSettings:PropTypes.object.isRequired,
  updatePlotSettingsProperty:PropTypes.func.isRequired,
  t: PropTypes.func
};
