import {connect} from "react-redux";
import {
  clearGeoView,
  initializeGeoLayers,
  initializeGeoView, loadConfiguration,
  registerEvent,
  registerWatch
} from "../action/GeoAction";
import GeoComponent from "../component/GeoComponent";


const mapStateToProps = (state, ownProps) => {
  return {
    view: state.geo.view,
    viewState: state.geo.viewState,
    width: ownProps.width,
    height: ownProps.height,
    layers: ownProps.layers,
    configurationId: ownProps.configurationId
  }
};

const mapDispatchToProps = dispatch => {
  return {
    clearGeoView: ()=>{
      dispatch(clearGeoView())
    },
    loadConfiguration: (docId,ref) => {
      dispatch(loadConfiguration(docId,ref))
    },
    initializeGeoView: (containerRef,params) => {
      dispatch(initializeGeoView(containerRef,params))
    },
    initializeGeoLayers: (layers) =>{
      dispatch(initializeGeoLayers(layers))
    },
    registerEvent: (type,func,clbk)=>{
      dispatch(registerEvent(type,func,clbk))
    },
    registerWatch: (prop,func)=>{
      dispatch(registerWatch(prop,func))
    }
  }
};

const containerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerGeoComponent = containerCreator(GeoComponent);