import {withRouter} from "react-router";
import {connect} from "react-redux";
import SeriesCasesPanel from "../component/SeriesCasesPanel";
import {getSeriesCases} from "../selectors/CasesSelectors";



const mapStateToProps = (state,ownProps) => {
  return {
    cases: getSeriesCases(state,null)
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
  }
};

export const ContainerSeriesCasesPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(SeriesCasesPanel));