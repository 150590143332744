import React from "react";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next'; // High order translation component
import SpineLogo from "../../authentication/component/SpineLogo";
import axios from "axios/index";
import EventDetailsPage from "./EventDetailsPage";
import {HOST_URL} from "../../../Constants";
import { Button } from "primereact/components/button/Button";
import { Growl } from "primereact/components/growl/Growl";
import Footer from "../../root/Footer";

/**
 * Show details for a public event inside SPINE
 * @returns {*}
 */
class PublicEventDetailsPage extends React.Component {
  
  constructor(props){
    super(props);

    // Set the state
    this.state = {
      isUserEnrolled: false,
      event: null,
    };
  }

  showError(error) {
    this.growl.show({
        severity: 'error',
        summary: 'Error Message',
        detail: error});
  }

  componentDidMount(){
    // Get the authentication from the store
    const {auth, match} = this.props;
    const eventId = match.params.eventId;
    // TODO: Fetch with language
    const lang = '';
    // Check if the user in enrolled in the event and update state
    let eventEndpoint = '/api/publicEvent/';
    if(auth.token_bearer){
      axios.defaults.headers.common['Authorization'] = auth.token_bearer;
      eventEndpoint = '/api/event/';
    }
    const url = `${HOST_URL}${eventEndpoint}${eventId}?lang=${lang}`;
    axios.get(url)
        .then(response => {
            if (response.status !== 200) {
                console.log('Error getting event information')
                this.showError('Error getting event information')
            } else {
                const event = response.data;
                const eventClosed = new Date(event.closingDate) < new Date();
                this.setState({
                    isEventClosed: eventClosed,
                    isUserEnrolled: event.isUserEnrolled,
                    event});
            }
        }).catch(error => {
        console.log('EventPage.js :: error', error);
    });
  }

  render() {
    const { t, match} = this.props;
    const eventId = match.params.eventId;
    const {event, isUserEnrolled} = this.state;
    if(event==null){
      return (
          <div className="spinner-centered">Loading <i className="fa fa-spinner fa-spin"/></div>
      );
    }
    return (
      <React.Fragment>
      <Growl ref={(el) => { this.growl = el; }}/>
      <div className="header landingPageHeaderTopbar"
           style={{display:"flex",alignItems:"center"}}>
        <div className="landingPageSpineLogoContainer" style={{display: "inline-flex",  margin: "auto", marginLeft:"16px"}}>
            <Link to='/'>
                <SpineLogo/>
            </Link>
        </div>
        <div className="reg-headtitle" style={{ color: "#A7FFFE",    margin: "auto", display: "inline-flex"}}>
          {t("event")} {event.name}
        </div>
      </div>
      <div style={{height: 'calc(100vh - 75px)', overflowY: 'auto'}}>
        <EventDetailsPage
          event={event} // TODO: Check endpoint to add correct isUserEnrolled value (check if user is authenticated and add the value to the flag)
          eventButton={<Link to={`/event/${eventId}?directRegister=true`}> {isUserEnrolled?
                        <Button label={t("accessButton.label")}/>:
                        <Button label={t("enrollButton.label")}/>}
                       </Link> }
        />
        <div className="ui-g-12">
          <Footer/>
        </div>
      </div>
    </React.Fragment>
    );
  }
};

export default withTranslation()(PublicEventDetailsPage);
PublicEventDetailsPage.propTypes = {
  auth: PropTypes.object.isRequired
}