import React from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {CUSTOM_ELEMENT} from "./AnnotationCell";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {HierarchicalDropdown} from "./HierarchicalDropdown";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {
    ANNOTATION_PROPERTY_NAME__ANNOTATIONS,
    ANNOTATION_PROPERTY_NAME__ID, ANNOTATION_PROPERTY_NAME__ROI,
    ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB, ANNOTATION_TABLE_TOOL,
    ANNOTATION_PROPERTY_NAME__RULER,
    REQUEST_STATUS_SUCCESS,
    MAIN_TOOL, RULER_TOOL, RULER_PROPERTY_NAME__ANNOTATION_MODE,
    RULER_PROPERTY_NAME__END, RULER_PROPERTY_NAME__START, RULER_PROPERTY_VALUES__ANNOTATION_SOURCE, RULER_PROPERTY_NAME__ANNOTATION_SOURCE
} from "../../../../Constants";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {getNestedProp} from "../../../helpers/expressions";

/**
 * Component representing Annotation Form.
 */

export class AnnotationForm extends React.Component {

    constructor(props) {
        super(props);
        ["handleSubmit","requiredValidator","requiredRulerValidator"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    //initialize with default values
    componentDidMount() {
        const {columns, columnsState, data,updateAnnotationData} = this.props;

        data.forEach((dataEl,index)=> {
            if (columns != null && columnsState === REQUEST_STATUS_SUCCESS){
                Object.keys(columns).forEach((colName, i) => {
                    let col = columns[colName];
                    if(col.value !=null){
                        const updatedData = data.slice(0);
                        updatedData[index][colName] = col.value;
                        updateAnnotationData(updatedData);
                    }
                })}
        })
    }

    //initialize with default values
    componentDidUpdate(prevProps,prevState,ss) {
        const {columns, columnsState, data,updateAnnotationData} = this.props;

        if (prevProps.columnsState!== this.props.columnsState && columnsState === REQUEST_STATUS_SUCCESS)
        data.forEach((dataEl,index)=> {
                Object.keys(columns).forEach((colName, i) => {
                    let col = columns[colName];
                    if(col.value !=null){
                        const updatedData = data.slice(0);
                        updatedData[index][colName] = col.value;
                        updateAnnotationData(updatedData);
                    }
                })
        })
    }

    componentWillUnmount() {


    }


    /**
     * Unused!!! It might be hepful if we want to change style of invalidated field.
     * @param event
     */
    handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);

        for (let name of data.keys()) {
            const input = form.elements[name];
        }
    }
    requiredValidator(props) {
        let value = props.rowData[props.field];
        return value!=null && value.length > 0;
    }
    requiredRulerValidator(props) {
        let value = props.rowData[props.field];
        return value !=null && !isNaN(value) && value!=="";
    }

    render() {
        const {columns, columnsState, data, manualToolState, updateAnnotationData, updateManualSubTask,
                updateManualToolProperty,updateManualToolState,resetAllViewersCameras} = this.props;

        /** Universal method for changing state of data. It can be used by all editors.
         * Since data can be sorted or filtered it performs looking for data using index id.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @param value - value decoded by "native" editor (listening to change in radio, dropdown and other components)
         */
        const onEditorValueChange = (props, value) => {
            let updatedData = data.slice(0);//Important!!!
            let index = updatedData.findIndex((el) => el[ANNOTATION_PROPERTY_NAME__ID] === props.rowData[ANNOTATION_PROPERTY_NAME__ID]);
            updatedData[index][props.field] = value;
            updateAnnotationData(updatedData);
        };

        /** Universal method for changing state of data attached to the ruler.
         * Since Column component cannot work with objects as values, the additional data has to be attached as an extra ("hidden")
         * column, which is not displayed in table.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @param value - value decoded by "native" editor (listening to change in radio, dropdown and other components)
         */
        const onRulerValueChange = (props, value) => {
            let updatedData = data.slice(0);//Important!!!
            let index = updatedData.findIndex((el) => el[ANNOTATION_PROPERTY_NAME__ID] === props.rowData[ANNOTATION_PROPERTY_NAME__ID]);
            updatedData[index][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field] = Object.assign({},value);
            updateAnnotationData(updatedData);
        };

        /** Editor for InputText columns.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @returns {*} - component in JSX code
         */
        const inputTextEditor = (props) => {
            return <InputText type="text"
                              value={props.rowData[props.field]}
                              onChange={(e) => onEditorValueChange(props, e.target.value)}
            />;
        };

        /** Editor for radio (React Native) columns.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @param options - options to select
         * @returns {*} - component in JSX code
         */
        const radioEditor = (props, options) => {
            return <div style={{display:"inline",marginRight:"10px"}}>{options.map((el) => {
                return <div style={{width: "100%",display:"inline",marginLeft:"1.5em"}}>
                    <input type="radio"
                           value={el.value}
                           checked={props.rowData[props.field] === el.value}
                           style={{width: "1em",display:"inline-block"}}
                           onChange={(e) => onEditorValueChange(props, e.target.value)}/>
                    <span> {el.label}</span>
                </div>
            })}</div>;
        };

        /** Editor for checkbox (React Native) columns.
         * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
         * @param options - options to choose
         * @returns {*} - component in JSX code
         */
        const checkboxEditor = (props, options) => {
            let editorValueChangeAdapter = (props, e) => {
                let result = props.rowData[props.field];
                if (!(result != null))
                    result = [];
                if (e.checked) {
                    result.push(e.value);
                } else {
                    let indexToRemove = result.indexOf(e.value);
                    if (indexToRemove > -1) {
                        result.splice(indexToRemove, 1);
                    }
                }
                onEditorValueChange(props, result);
            };

            return <div>{options.map((el) => {
                return <div style={{width: "100%"}}>
                    <Checkbox value={el}
                              onChange={(e) => editorValueChangeAdapter(props, e)}
                              checked={props.rowData[props.field] != null && props.rowData[props.field].includes(el)}/>
                    <span> {el}</span>
                </div>
            })}</div>;
        };


        const itemTemplate = (option) => {
            if (!(option.iri != null)) {
                return option.label;
            } else {
                return (
                    <div className="ui-helper-clearfix">
                        <span style={{float: 'left', margin: '.5em .25em 0 0'}}>{option.label}</span>
                        <a target="_blank" href={option.iri} style={{float: 'right'}}>
                            <i className="fa fa-external-link" title="External link to definition"/>
                        </a>
                    </div>
                );
            }
        };

        const dropdownEditor = (props, options) => {
            //let pfOptions = options.map((el)=>{return {label:el,value:el}});  //convert to PF format for dropdown options (SelectItem objects)

            const isRequired = columns[props.field]['validation'] != null && columns[props.field]['validation']['required'] === true;

            return (
                <Dropdown itemTemplate={itemTemplate}
                          value={props.rowData[props.field]}
                          options={options}
                          required={isRequired}
                          onMouseDown={()=>updateManualSubTask(columns[props.field]['name'])}
                          onChange={(e) => onEditorValueChange(props, e.value)} style={{width: '100%'}}
                />
            );
        };

        const hierarchicalEditor = (props, options) => {
            return (
                <HierarchicalDropdown value={props.rowData[props.field]}
                                      parentNode={options}
                                      onFocus={()=>updateManualSubTask(columns[props.field]['name'])}
                                      updateValue={(val) => onEditorValueChange(props, val)}
                />
            );
        };

        const blockWhenRulerEnabled = {pointerEvents:LEFT_BUTTON_MODE.RULER === manualToolState[MAIN_TOOL]['leftButtonMode'] // prevent more than 1 cell opened with ruler editor
              ? "none"
              : "auto"};

        const rulerEditor = (props, options) => {
            const onFocus = (e) => {
                // simplified code here in comparison to annotation table ruler
                    updateManualToolProperty(MAIN_TOOL, 'leftButtonMode', LEFT_BUTTON_MODE.RULER);
                    updateManualSubTask(columns[props.field]['name']);
                    const annotationMode = {};
                    annotationMode[ANNOTATION_PROPERTY_NAME__ID] =  props.rowData[ANNOTATION_PROPERTY_NAME__ID];
                    annotationMode['field'] =  props.field;
                    updateManualToolProperty(RULER_TOOL, RULER_PROPERTY_NAME__ANNOTATION_MODE,annotationMode); //unused yet - it will be needed when other modes of ruler are available
                    updateManualToolProperty(RULER_TOOL, RULER_PROPERTY_NAME__ANNOTATION_SOURCE, RULER_PROPERTY_VALUES__ANNOTATION_SOURCE[0]); // FORM as event source
                    onEditorValueChange(props, "");
                    resetAllViewersCameras();
            };

            let p1 = props['rowData'][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field]!=null?props['rowData'][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field][RULER_PROPERTY_NAME__START]:null;
            let p2 = props['rowData'][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field]!=null?props['rowData'][ANNOTATION_PROPERTY_NAME__RULER+"_"+props.field][RULER_PROPERTY_NAME__END]:null;

            const getDist = ()=> {
                let distance = "";
                if (p1 != null && p2 != null) {
                    distance = Math.round(10*Math.sqrt(
                      (p1.roiPosition[0] - p2.roiPosition[0]) * (p1.roiPosition[0] - p2.roiPosition[0])
                      + (p1.roiPosition[1] - p2.roiPosition[1]) * (p1.roiPosition[1] - p2.roiPosition[1])
                      + (p1.roiPosition[2] - p2.roiPosition[2]) * (p1.roiPosition[2] - p2.roiPosition[2])
                    ))/10;
                }
                return distance;
            };
            let dist = getDist();

            return (
             <div ref={(rulerDOM) => { this.rulerDOM = rulerDOM; }}
                  tabIndex={-1}
                  style={{display:"inline-block"}}
             >
                 <InputText type="text"
                            tabIndex={-1}
                            value={dist}
                            readOnly={true}
                            style={{width:"3em",...blockWhenRulerEnabled}}
                            autoFocus={false}
                            onFocus={(e)=>{onFocus(e);}}
                 />
                 {
                     getNestedProp([RULER_TOOL,RULER_PROPERTY_NAME__ANNOTATION_MODE,ANNOTATION_PROPERTY_NAME__ID],manualToolState) ==="-1" &&
                     getNestedProp([RULER_TOOL,RULER_PROPERTY_NAME__ANNOTATION_MODE,"field"],manualToolState) ===props.field &&
                       LEFT_BUTTON_MODE.RULER ===    getNestedProp([MAIN_TOOL,'leftButtonMode'],manualToolState) &&
                     <React.Fragment>
                     <Button className={!(dist != null)?"ui-state-disabled":"ui-state-enabled"}
                         readOnly={!(dist != null)}
                         tabIndex={-1}
                         autoFocus={false}
                         style={{pointerEvents:"auto"}}
                         icon="fa fa-download"
                         title={"Submit"}
                         onClick={() => {
                             updateManualToolProperty(MAIN_TOOL, 'leftButtonMode', LEFT_BUTTON_MODE.NONE);
                             onEditorValueChange(props, dist);
                             onRulerValueChange(props,
                               {
                                   "RULER_START":p1,
                                   "RULER_END":p2
                             });
                         }
                         }/>
                     <Button className={!(dist!=null)?"ui-state-disabled":"ui-state-enabled"}
                         readOnly={!(dist!=null)}
                         tabIndex={-1}
                         style={{pointerEvents:"auto"}}
                         autoFocus={false}
                         icon="fa fa-trash"
                         title={"Clear measurement"}
                         onClick={(e) => {
                             onEditorValueChange(props, null);
                             onRulerValueChange(props,null);
                             onFocus(e);
                         }
                         }/>
                     <Button className={!(dist!=null)?"ui-state-disabled":"ui-state-enabled"}
                                 readOnly={!(dist!=null)}
                                 tabIndex={-1}
                                 autoFocus={false}
                                 style={{pointerEvents:"auto"}}
                                 title={"Cancel"}
                                 icon="fa fa-close"
                                 onClick={(e) => {
                                     onEditorValueChange(props, null);
                                     onRulerValueChange(props, null);
                                     updateManualToolProperty(MAIN_TOOL, 'leftButtonMode', LEFT_BUTTON_MODE.NONE);
                                 }
                         }/>
                 </React.Fragment>}
             </div>
            );
        };

        const getFormEditor = (props) => {
            let column = props;
            if (column != null)
                switch (column.type) {
                    case CUSTOM_ELEMENT.LIST.type:
                        return dropdownEditor(props, column.options);
                    case CUSTOM_ELEMENT.TEXT.type:
                        return inputTextEditor(props);
                    case CUSTOM_ELEMENT.CHOICE.type:
                        return radioEditor(props, column.options);
                    case CUSTOM_ELEMENT.MULTIPLE_CHOICE.type:
                        return checkboxEditor(props, column.options);
                    case CUSTOM_ELEMENT.HIERARCHICAL.type:
                        return hierarchicalEditor(props, column.options);
                    case CUSTOM_ELEMENT.RULER.type:
                        return rulerEditor(props, column.options);
                    default:
                        return null;
                }
            else return null;
        };



        return (
            <form onSubmit={this.handleSubmit} style={blockWhenRulerEnabled}>
                    {
                        data.map((dataEl,index)=> {
                            return (columns != null && columnsState === REQUEST_STATUS_SUCCESS)
                                ? Object.keys(columns).map((colName, i) => {
                                    let col = columns[colName];
                                    col['rowData'] = dataEl;
                                    col['field'] = colName;
                                    return <div key={col.name}
                                                style={{display: "inline-block", border: "solid 1px black", marginLeft: "1em", marginTop: "1em"}}
                                                onFocus={()=>updateManualSubTask(col['name'])}>
                                       <span className="annotationForm-question" >{col.name}:</span>
                                       <span className="annotationForm-answers"> {getFormEditor(col)}</span>
                                    </div>
                                })
                                : []
                        })
                    }
            </form>

        );
        // }
    };
}
AnnotationForm.propTypes = {
    columns: PropTypes.array.isRequired,
    data:PropTypes.array.isRequired,
    manualToolState:PropTypes.object,
    manualToolConfiguration:PropTypes.object,
    viewCallback:PropTypes.func,
    rmCallback:PropTypes.func,
    setSubAnnotation:PropTypes.func,
    activeRowOnly:PropTypes.bool.isRequired,
    updateManualSubTask:PropTypes.func,
    updateManualToolProperty:PropTypes.func.isRequired,
    updateManualToolState:PropTypes.func.isRequired,
    resetAllViewersCameras:PropTypes.func.isRequired,
};

