import {connect} from "react-redux";
import {
    removeAnnotation,
    setActiveAnnotation,
    setSubAnnotation,
    updateAnnotationData,
    saveAnnotationDataToServer
} from "../action/AnnotationAction";
import {AnnotationTable} from "../component/annotationTool/AnnotationTable";
import {updateManualSubTask} from "../../dashboard/action/ManualTaskAction";
import {updateManualToolProperty, updateManualToolState} from "../action/ManualToolAction";
const mapStateToProps = (state,ownProps) => {
    return {
        columns: state.visu.annotations.annotationsDefinition,
        columnsState: state.visu.annotations.annotationsDefinitionState,
        manualToolState: state.visu.manualTool.manualToolState,
        manualToolConfiguration: state.visu.manualTool.manualToolConfiguration,
        data: state.visu.annotations.annotationsData,
        dataState: state.visu.annotations.annotationsDataState,
        messageQueue: state.messaging.msgQueue,
        readData: state.visu.annotations.annotationsReadData,
        readOnly: state.visu.annotations.readOnly,
        activeROI: state.visu.annotations.activeROI,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateAnnotationData:(data)=>{
            dispatch(updateAnnotationData(data))
        },
        viewCallback: (annotation)=>{
            dispatch(setActiveAnnotation(annotation,true))
        },
        rmCallback:(data)=>{
            dispatch(removeAnnotation(data))
        },
        setSubAnnotation: (annotation)=>{
            dispatch(setSubAnnotation(annotation))
        },
        setActiveAnnotation: (row)=>{
            dispatch(setActiveAnnotation(row))
        },
        updateManualSubTask: (name)=>{
            dispatch(updateManualSubTask(name))
        },
        updateManualToolProperty: (key, property, value) => {
            dispatch(updateManualToolProperty(key, property, value))
        },
        updateManualToolState: (state, id) => {
            dispatch(updateManualToolState(state,id))
        }
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerAnnotationTable = containerCreator(AnnotationTable);
