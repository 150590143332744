import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  getSelectedExtendedModelParameters,
  getSelectedModelParameters,
  getSelectedPlotData, getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import DistributionStatsPanel from "../component/DistributionStatsPanel";
import {calculateDistributionStatistics} from "../action/statsAction";



const mapStateToProps = (state,ownProps) => {
  return {
    modelParameters: getSelectedModelParameters(state),
    extendedModelParameters: getSelectedExtendedModelParameters(state),
    plotData: getSelectedPlotData(state),
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration,
    rawData: state.sam.rawData
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    getStatistics:() =>{
      dispatch(calculateDistributionStatistics())
    }
  }
};

export const ContainerDistributionStatsPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(DistributionStatsPanel));