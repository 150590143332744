import React from "react";
import PropTypes from "prop-types";
import {
  ANNOTATION_PROPERTY_NAME__ID,
  REQUEST_STATUS_FAIL,
  REQUEST_STATUS_REQUESTED,
  REQUEST_STATUS_SUCCESS
} from "../../../../Constants";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Button} from "primereact/components/button/Button";
import {AnnotationTable} from "./AnnotationTable";
import {NonModalSidebar} from "../../../root/component/NonModalSidebar";
import {uuidv4} from "../../../helpers/strings";
import {ContainerAnnotationColumnSelection} from "../../containers/ContainerAnnotationColumnSelection";
import {StatusColumn} from "./StatusColumn";
import {InputText} from "primereact/components/inputtext/InputText";


class AnnotationTableBuilder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedRow:null,
      selectedStatus:"ALL",
      selectedRow2:null,  //keeps which column in modified table is set
      selectedRow2Index:null,
      columnSelectionVisible:false,  //create/edit column
      editedColumns:[],
      editedUUID:uuidv4()
    };
    ["renderValidation","onRowSelect","onClear","onRowEdit","onRowDelete","handleTrashButton",
      "renderSelectColumnTemplate","renderTrashTemplate","onSaveAnnotationTable"
      ,"onRowUnselect","onRowSave","renderUseCases","renderNumberOfColumns"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {getAnnotationsDefinitionsList, getTasks,getAnnotationsList} = this.props;
    if (getAnnotationsDefinitionsList != null)
      getAnnotationsDefinitionsList();
    if (getTasks != null)
      getTasks();
    if (getAnnotationsList!=null)
     getAnnotationsList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {saveAnnotationError, saveAnnotationResponse, saveAnnotationState, messageQueue, getAnnotationsDefinitionsList} = this.props;
    if (prevProps.saveAnnotationState !== saveAnnotationState) {
      if (saveAnnotationState === REQUEST_STATUS_SUCCESS) {
        messageQueue.show({
          sticky: false,
          severity: 'info',
          summary: 'Saved Annotation Table Definition',
          detail: "A new annotation definition has been saved in ".concat(saveAnnotationResponse.status, " mode.")
        });
        if (getAnnotationsDefinitionsList != null)
          getAnnotationsDefinitionsList();
        if (saveAnnotationResponse.status!=="DRAFT"){//clear editor after publishing, otherwise keep it
          this.onClear();
        }
      }
      if (saveAnnotationState === REQUEST_STATUS_FAIL) {
        messageQueue.show({
          sticky: false,
          severity: 'error',
          summary: 'Error during Saving',
          detail: saveAnnotationError.message
        });
      }
    }
  }


  componentWillUnmount() {
    const{clearAnnotatorState,clearSaveAnnotationsTableDefinition}=this.props;
    if (clearAnnotatorState)
      clearAnnotatorState();
    if (clearSaveAnnotationsTableDefinition)
      clearSaveAnnotationsTableDefinition();
  }

  onRowSelect(e) {
    this.setState({
      selectedRow: Object.assign({}, e.data)
    });
  }

  onClear() {
    this.setState({
      editedColumns:[],
      editedUUID:uuidv4()
    });
  }

  onRowEdit(){
    const {allColumns,allColumnsState} = this.props;
    const {selectedRow}=this.state;
    const edited = [];

    if (allColumnsState===REQUEST_STATUS_SUCCESS) {
      if (selectedRow != null && selectedRow.columns != null)
        Object.keys(selectedRow.columns).forEach((key) => {
          const def= allColumns.find((col)=>{return col[ANNOTATION_PROPERTY_NAME__ID] === selectedRow.columns[key]});
          if (def!=null){
            def['_key']=key;
            edited.push(def);
          }
        });
      this.setState({editedColumns:edited,editedUUID:"DRAFT"===selectedRow.status?selectedRow[ANNOTATION_PROPERTY_NAME__ID]:uuidv4()});
    }
  }

  onRowDelete(){
    const{messageQueue} = this.props;
    const {selectedRow}=this.state;
    messageQueue.show({
      sticky: false,
      severity: 'info',
      summary: 'Delete',
      detail:"Annotation definition ".concat( selectedRow[ANNOTATION_PROPERTY_NAME__ID]," has been removed from SPINE knowledge base. Warning: This feature has not been yet implemented!.")
    });

    // const {columns,successAnnotationsDefinition} = this.props;
    // const tempColumns = Object.assign({},columns);
    // delete tempColumns[this.state.selectedRow['_key']];
    // successAnnotationsDefinition(tempColumns);
    this.onRowUnselect();
  }

  onRowSave(column){
    const {selectedRow2,selectedRow2Index,editedColumns} = this.state;
    const tempColumns = editedColumns.slice(0);
    tempColumns[selectedRow2Index]=Object.assign({_key:selectedRow2['_key']},column);
    this.setState({columnSelectionVisible:false,selectedRow2:null,selectedRow2Index:null,editedColumns:tempColumns})
  }

  onRowUnselect(){
    this.setState({ selectedRow:null})
  }

  renderValidation(row){
    return JSON.stringify(row['validation']);
  }

  renderNumberOfColumns(row){
    return Object.keys(row.columns).length;
  }

  renderUseCases(row){
    const {tasks,taskListState} = this.props;
    if (taskListState===REQUEST_STATUS_SUCCESS){
      return tasks
        .filter((el)=>{
          return el['constants']!=null && Object.values(el['constants']).findIndex((val)=> {return val.value === row[ANNOTATION_PROPERTY_NAME__ID]})>-1})
        .reduce((prev,current)=>{ return prev.concat(current.name," ")},"")
    }
    else return "NA";
  }

  renderSelectColumnTemplate  (row,col) {
    return <div>  <Button icon="fa fa-edit"  onClick={()=>{this.setState({columnSelectionVisible:true,selectedRow2:row,selectedRow2Index:col.rowIndex})}}/>   </div>;
  };

  handleTrashButton(row,col){
    const {editedColumns} = this.state;
    const tempColumns = editedColumns.slice(0);
    tempColumns.splice(col.rowIndex,1);
    this.setState({editedColumns:tempColumns})
  }

  renderTrashTemplate (row,col,ind){
    return <div>  <Button icon="fa fa-trash"  onClick={()=>this.handleTrashButton(row,col)}/>   </div>;
  };


  onSaveAnnotationTable(mode){
    const {editedColumns,editedUUID} = this.state;
    const {messageQueue,saveAnnotationsTableDefinition} = this.props;

    const size= editedColumns.length;

    const payload = editedColumns.reduce((prev,cur)=>{
        prev[cur._key]=cur._id;
        return prev;},
      {});


    if (Object.keys(payload).length!==size){
      messageQueue.show( {
        sticky: false,
        severity: 'error',
        summary: 'Validation Error',
        detail:"Each Key has to be unique! Data has not been saved."
      });
      return ;
    }

    if (Object.entries(payload).filter(([k,v],i)=>!!v).length!==size){
      messageQueue.show( {
        sticky: false,
        severity: 'error',
        summary: 'Validation Error',
        detail:"Missing column data! Data has not been saved."
      });
      return ;
    }

    const transferObject = {
        "_id": editedUUID,
        "status": mode === "draft" ? "DRAFT" : "PUBLISHED",
        "columns": payload
      }
    ;

    saveAnnotationsTableDefinition(mode,transferObject);

  }


  render() {
    const {annotationsDefinitionsListState,annotationsDefinitionsList,allColumnsState,saveAnnotationState} = this.props;
    const {selectedRow,selectedRow2,selectedStatus,columnSelectionVisible,editedColumns} = this.state;

    const inputTextEditor = (props) => {
      return <InputText type="text"
                        value={props.rowData[props.field]}
                        onChange={(e) => {
                          props.rowData[props.field]=e.target.value;
                          this.setState({})
                        }}
      />;
    };

    const previewColumns = (editedColumns!=null && editedColumns.length>0)
      ?editedColumns.reduce((prev,cur)=>{
        prev[cur._key]=cur.definition;
        return prev;},
        {})
      :{};

    return (
      <div className={"ui-g"}>
        <div className={"ui-g-6"}>

          {/*<Button label={"Edit draft"} onClick={()=>this.setState({selectionEditorVisible:true})}/>*/}
          {/*<Button label={"Duplicate from existing"} onClick={()=>this.setState({selectionEditorVisible:true})}/>*/}

          <h2> All annotation definitions (forms and tables)</h2>
          {annotationsDefinitionsListState===REQUEST_STATUS_SUCCESS
          && <DataTable
            ref={(el)=> this.dt = el}
            sortField="status"
            sortOrder={-1}
            value={annotationsDefinitionsList}
            rows={10}
            paginator={true}
            selection={this.state.selectedRow}
            onRowSelect={this.onRowSelect}
            selectionMode="single"
            onSelectionChange={()=>{}}>
            {StatusColumn(selectedStatus,(value) =>{
              this.setState({ selectedStatus: value });
              this.dt.filter(value, 'status', 'custom')
            })}
            <Column field="_id" header={"id"} filter={true} filterMatchMode={"contains"} style={{overflowX:"hidden"}}/>
            <Column field="columns" header={"Columns"} body={this.renderNumberOfColumns} style={{width:"5em"}}/>
            <Column header={"Use cases"}  body={this.renderUseCases} />
          </DataTable>}
          <Button label={(selectedRow!=null && "DRAFT"===selectedRow.status) ? "View/Edit":"View/Duplicate"} disabled={!(selectedRow!=null)} onClick={this.onRowEdit}/>
          <Button label={"Delete"} disabled={!(selectedRow!=null && "DRAFT"===selectedRow.status)} onClick={this.onRowDelete} title={"You can delete selected entry in DRAFT mode only"}/>
          <Button label={"Unselect"} disabled={!(selectedRow!=null)} onClick={this.onRowUnselect}/>
        </div>
        <div className={"ui-g-6"}>
          {allColumnsState === REQUEST_STATUS_SUCCESS &&  editedColumns!=null&&
          <React.Fragment>
            <h2> New/modified definition</h2>
            <DataTable
              value={editedColumns}
              rows={10}
              paginator={true}
              editable={true}
              onRowReorder={(e) => this.setState({editedColumns: e.value})}
            >
              <Column rowReorder={true} style={{width:"3em"}} />
              <Column field="_key" header={"Key"} editor={inputTextEditor} />
              <Column field="_id" header={"Id"} style={{overflowX:"hidden"}}/>
              <Column field="definition.name" header={"Name"}/>
              <Column body={this.renderSelectColumnTemplate} style={{width: "3em"}}/>
              <Column body={this.renderTrashTemplate} style={{width: "3em"}}/>
            </DataTable>
            <Button label={"Add new column"} onClick={()=>{
              editedColumns.push({_key:"NewColumn",definition:{}});
              this.setState({});
            }}/>
            <Button label={"Save as Draft"}
                    onClick={e=>this.onSaveAnnotationTable("draft")}
                    disabled={saveAnnotationState===REQUEST_STATUS_REQUESTED}
                    icon={saveAnnotationState===REQUEST_STATUS_REQUESTED?"fa fa-spinner fa-spin":"fa fa-save"} />
            <Button label={"Save and Publish"}
                    onClick={e=>this.onSaveAnnotationTable("published")}
                    disabled={saveAnnotationState===REQUEST_STATUS_REQUESTED}
                    icon={saveAnnotationState===REQUEST_STATUS_REQUESTED?"fa fa-spinner fa-spin":"fa fa-save"} />
            <Button label={"Clear"} icon={"fa fa-trash"} onClick={this.onClear}/>

          </React.Fragment>
          }
        </div>
        <div className={"ui-g-12"} style={{position:"fixed",bottom:"0px"}}>
          {allColumnsState===REQUEST_STATUS_SUCCESS && editedColumns !=null &&
          <React.Fragment>
            <h2>Preview</h2>
            <AnnotationTable columns={previewColumns}
                             data={[{_id:"1",_roi:{roiCellIJK:[0,0,0]},annotations:{}}]}
                             manualToolState={this.props.manualToolState}
                             columnsState={allColumnsState}
                             viewCallback={()=>{}}
                             activeRowOnly={false}
                             updateManualSubTask={()=>{}}
                             updateManualToolState={()=>{}}
                             updateManualToolProperty={()=>{}}/>
          </React.Fragment>
          }
        </div>

        <NonModalSidebar
          visible={columnSelectionVisible}
          onHide={() => this.setState({columnSelectionVisible:false})}>
          {columnSelectionVisible &&
          <React.Fragment>
            <h1>Annotation Definition</h1>
            <ContainerAnnotationColumnSelection
              selected={selectedRow2}
              onSave={this.onRowSave}
              onClose={()=>this.setState({columnSelectionVisible:false,selectedRow2:null,selectedRow2Index:null})}
            />
          </React.Fragment>
          }
        </NonModalSidebar>
      </div>

    )
  }

}

export default AnnotationTableBuilder;

AnnotationTableBuilder.propTypes = {
  messageQueue: PropTypes.object.isRequired,
  tasks: PropTypes.object.isRequired,
  taskListState: PropTypes.string.isRequired,
  getTasks:PropTypes.func.isRequired,
  getAnnotationsList: PropTypes.func.isRequired,
  getAnnotationsDefinitionsList: PropTypes.func.isRequired,
  annotationsDefinitionsListState:PropTypes.string.isRequired,
  annotationsDefinitionsState:PropTypes.array.isRequired,
  allColumns: PropTypes.object.isRequired,
  allColumnsState: PropTypes.string.isRequired,
  updateData: PropTypes.func.isRequired,
  manualToolState: PropTypes.object.isRequired,
  editedAnnotationKey: PropTypes.string.isRequired,
  successAnnotationsDefinition: PropTypes.func.isRequired,
  clearAnnotatorState:PropTypes.func.isRequired,
  saveAnnotationState:PropTypes.string.isRequired,
  saveAnnotationError:PropTypes.object.isRequired,
  saveAnnotationResponse:PropTypes.object.isRequired,
  saveAnnotationsTableDefinition:PropTypes.func.isRequired,
  clearSaveAnnotationsTableDefinition:PropTypes.func.isRequired
};
