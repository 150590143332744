import axios from 'axios';

import {
	REGISTER_REQUEST,
	REGISTER_SUCCESS,
    REGISTER_FAIL
} from './actionType.js';
import {HOST_URL, COOKIE_TOKEN_NAME} from "../../../Constants";


//REGISTER PROCESS ************************************************************

function requestRegister(creds) {
  return {
    type: REGISTER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  }
}

function receiveRegister(user) {
  return {
    type: REGISTER_SUCCESS,
    isFetching: false,
    isAuthenticated: true
  }
}

function errorRegister(message) {
  return {
    type: REGISTER_FAIL,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}


/**
 * Dispatch to reducer the request and it's output
 *
 * @param creds {Object}
 * @param picture
 * @param onError
 * @return function(*) or success {Object}
 */
export function registerUser(creds,picture,onSuccess,onError) {
    console.log('Executing registerUser.');
    return dispatch => {
        // Dispatch action request (if the process takes time we can
        // display the rolling ball animation)
        dispatch(requestRegister(creds));
        const payload = new FormData();     //
        payload.append("parse","true");
        payload.append("output",picture);
        for (const key in creds) {
          if (creds.hasOwnProperty(key)) {
            payload.append(key, creds[key]);
          }
        }
        // Request to the backend
        return axios.post(HOST_URL + '/api/user', payload)
            .then(response => {
                //Check status code
                console.log('response ', response);
                if (response.status !== 200) {
                    //Display error message if creds are invalid
                    dispatch(errorRegister(response.data));
                    return Promise.reject(response.data)
                } else {
                    //Store the token locally
                    console.log('create user ', response.data);
                    console.log('RegisterAction:: response', response);
                    // Dispath action of registration received
                    dispatch(receiveRegister(creds));
                    onSuccess();
                }
            }).catch(err =>{
                console.log(err);
                onError(err.response.data.message);
                dispatch(errorRegister(err));
            })
    }

}