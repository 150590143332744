import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router";

/**
 * React component that catch JavaScript errors anywhere in its child component tree,
 * log those errors, and display a fallback UI instead of the component tree that crashed.
 *
 * @see https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {  return { hasError: true };  }


  componentDidCatch(error, errorInfo) {
    if(this.props.onError!=null){
      this.props.onError(error,errorInfo);
    }
    console.log(error, errorInfo);
  }
      render() {
        if (this.state.hasError) {
          return this.props.errorPage;
        }
          return this.props.children;
        }
      }

export default withRouter(ErrorBoundary);


ErrorBoundary.defaultProps= {
  errorPage:<h1>Something went wrong.</h1>,
};

ErrorBoundary.propTypes= {
  onError:PropTypes.func,
  errorPage:PropTypes.object.isRequired
};