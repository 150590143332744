import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {clearSkillStateAC} from "../action/SkillAction";
import PlaySkillMap from "../component/PlaySkillMap";
import { saveOutputAndSubmit} from "../../visualization/action/ManualToolAction";
import { updateFormProperty} from "../../visualization/action/FormsAction";
import {clearGeoView, updateSitesFromSkill} from "../../arcgis/action/GeoAction";



// Used by connect()
const mapStateToProps = state => {
  return {
    slides: state.skill.skill.slides,
    skill:  state.skill,
    view: state.geo.view,
    viewState: state.geo.viewState,
    sites: state.geo.sites,
    sitesState: state.geo.sitesState,
    layerState: state.geo.layerState,
    legendInfo: state.geo.legend,   // not arcgis legend!, here used for general data
    manualTask: state.activity.manualTask.manualTask, // needed for control buttons
    ackSignalState: state.activity.manualTask.ackSignalState, // needed for control buttons
    messageQueue: state.messaging.msgQueue,
    manualToolState: state.visu.manualTool.manualToolState,
    manualToolConfiguration: state.visu.manualTool.manualToolConfiguration,
    annotationsFormData: state.visu.annotations.annotationsFormData,
    annotationsData: state.visu.annotations.annotationsData,
    formsState: state.visu.forms.formsState,
    annotationsDefinition: state.visu.annotations.annotationsDefinition,
    annotationsFormDefinition: state.visu.annotations.annotationsFormDefinition,
  }
};
/**
 *
 */
const mapDispatchToProps = dispatch => {
  return {
    clearSkillState:()=>{
      dispatch(clearSkillStateAC());
    },
    clearGeoView:()=>{
      dispatch(clearGeoView());
    },
    saveOutputAndSubmit:(lpMode,experimentId,miniWorkflowKey,miniWorkflowSetId,onNext)=>{
      dispatch(saveOutputAndSubmit(lpMode,experimentId,miniWorkflowKey,miniWorkflowSetId,onNext))
    },
    updateFormProperty:(id, property, value)=>{
      dispatch(updateFormProperty(id, property, value))
    },
    updateSitesFromSkill:(skillId)=>{
      dispatch(updateSitesFromSkill(skillId))
    },
  }
};

export const ContainerPlaySkillMap = withRouter(connect( mapStateToProps, mapDispatchToProps)(PlaySkillMap));

