import axios from "axios/index";
import {HOST_URL} from "../../../Constants";
import {
  CLEAR_ONTOLOGY_SAVE, CLEAR_QUESTION_SAVE,
  ONTOLOGY_SAVE_FAIL,
  ONTOLOGY_SAVE_REQUEST, ONTOLOGY_SAVE_SUCCESS,
  QUESTION_LIST_REQUEST_FAILED,
  QUESTION_LIST_REQUEST_SUCCESS,
  QUESTION_LIST_REQUESTED, QUESTION_SAVE_FAIL, QUESTION_SAVE_REQUEST, QUESTION_SAVE_SUCCESS
} from "./actionType";


/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestQuestionList = () => ({
  type:QUESTION_LIST_REQUESTED
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorQuestionList = (err) => ({
  type: QUESTION_LIST_REQUEST_FAILED,
  err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param questions
 * @returns {{type, variablesList: *}}
 */

const successQuestionList = (questions) => ({
  type: QUESTION_LIST_REQUEST_SUCCESS,
  questions
});

/**
 * Action Creator for getting list of questions.
 * @return {function(*, *): Promise<AxiosResponse<any>>}
 */
export const getQuestionListActionCreator = () => {
  return (dispatch,getState) => {
    dispatch(requestQuestionList());
    console.log('QuestionAction.js :: getQuestionList :: request');
    let config = {
      headers: {'Authorization': "bearer" + getState().auth.token_bearer}
    };
    return axios.get('/api/question',config
    ).then(resp => {
      console.log('QuestionAction.js :: getQuestionList :: response',resp);
      if (resp.status !== 200) {
        dispatch(errorQuestionList(resp.status));
        return Promise.reject(resp.data);
      } else {
        console.log('QuestionAction.js :: getQuestionList :: success');
        dispatch(successQuestionList(resp.data));
      }
    })
      .catch(err => dispatch(errorQuestionList( err)));
  };
};

const requestSaveQuestion = ()=>({
  type:QUESTION_SAVE_REQUEST
});

const errorSaveQuestion = (err)=>({
  type:QUESTION_SAVE_FAIL,
  err
});

const successSaveQuestion = (data)=>({
  type:QUESTION_SAVE_SUCCESS,
  data
});


export const clearSaveQuestion = ()=>({
  type:CLEAR_QUESTION_SAVE
});

/**
 * ActionCreator for getting all Annotation Columns from DB.
 * @param mode -
 * @returns {function(*,*)}
 */
export const saveQuestion = (mode,payload) => {
  return (dispatch, getState) => {
    dispatch(requestSaveQuestion());
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    return axios.post(`/api/question?status=${mode}`,payload).then(response => {
      console.log('QuestionAction.js :: SaveQuestion :: response', response);
      if (response.status !== 200) {
        dispatch(errorSaveQuestion(response.data));
        return Promise.reject(response.data);
      } else {
        console.log('QuestionAction.js :: SaveQuestion ::  Before success');
        dispatch(successSaveQuestion(response.data));
      }
    }).catch(err => dispatch(errorSaveQuestion(err)));
  };
};

