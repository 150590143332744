import {
  GEO_CLEAR,
  GEO_UPDATE_BASEMAP,
  GEO_UPDATE_BLEND_MODE,
  GEO_UPDATE_FILL_STYLE,
  GEO_UPDATE_LAYER_STATE,
  GEO_UPDATE_LAYERS,
  GEO_UPDATE_LEGEND,
  GEO_UPDATE_MARKER_SIZE,
  GEO_UPDATE_OPACITY,
  GEO_UPDATE_SITES,
  GEO_UPDATE_VIEW
} from "../action/actionType";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";

const initialState =  {
  viewState: '',
  view: {},
  layerState:'',
  layers:[],
  sites:[],
  sitesState:'',
  blendMode: "multiply",
  basemap: "topo-vector",
  opacity:0.7,
  markerSize:2,
  fill:"mixed",  // "mixed" or "solid"
  legend:false,
  zoom:3,
  center:[18,51]
};

export const GeoReducer = (state = Object.assign({}, initialState), action) => {
  switch (action.type) {
    case GEO_UPDATE_VIEW:
      return Object.assign({}, state, {
        viewState: REQUEST_STATUS_SUCCESS,
        view: action.view
      });
    case GEO_CLEAR:
      return Object.assign({}, initialState);
    case GEO_UPDATE_SITES:
      return Object.assign({}, state, {
        sitesState: REQUEST_STATUS_SUCCESS,
        sites: action.sites.slice()
      });
    case GEO_UPDATE_BASEMAP:
      return Object.assign({}, state, {
        basemap: action.basemap
      });
    case GEO_UPDATE_LAYERS:
      return Object.assign({}, state, {
        layers: action.layers.slice()
      });
    case GEO_UPDATE_LAYER_STATE:
      return Object.assign({}, state, {
        layerState: action.layerState
      });
    case GEO_UPDATE_BLEND_MODE:
      return Object.assign({}, state, {
        blendMode: action.blendMode
      });
    case GEO_UPDATE_MARKER_SIZE:
      return Object.assign({}, state, {
        markerSize: action.markerSize
      });
    case GEO_UPDATE_OPACITY:
      return Object.assign({}, state, {
        opacity: action.opacity
      });
    case GEO_UPDATE_FILL_STYLE:
      return Object.assign({}, state, {
        fill: action.fill
      });
    case GEO_UPDATE_LEGEND:
      return Object.assign({}, state, {
        legend: action.legend
      });
    default:
      return state;
  }
};

