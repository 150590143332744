import macro from 'vtk.js/Sources/macro';
import vtkInteractorStyleTrackballCamera from 'vtk.js/Sources/Interaction/Style/InteractorStyleTrackballCamera';
// import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import vtkMath from 'vtk.js/Sources/Common/Core/Math';
import {States} from 'vtk.js/Sources/Rendering/Core/InteractorStyle/Constants';
// import vtkLineSource from 'vtk.js/Sources/Filters/Sources/LineSource';
// import vtkPolyData from 'vtk.js/Sources/Common/DataModel/PolyData/index';
// import vtkActor from "vtk.js/Sources/Rendering/Core/Actor/index";
// import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper/index";
// import vtkCellArray from 'vtk.js/Sources/Common/Core/CellArray/index';
// import vtkPoints from 'vtk.js/Sources/Common/Core/Points/index';
import {
  BRUSH_TOOL,
  ERASER_TOOL,
  INFO_TOOL,
  RULER_PROPERTY_NAME__END,
  RULER_PROPERTY_NAME__START
} from "../../Constants";
import {createHistogram, otsu} from "./processing/Otsu";
import {
  createLevelTracingActor,
  getLevelTracingActor, getLevelTracingRegion,
  setTracingOutlineColor, updateLevelTracingActor
} from "./LevelTracingEffect/LevelTracingRenderer";
// import {infoTooltip} from "../helpers/canvasUtils";
import {
  createBrushTracingActor,
  getBrushTracingActor,
  setBrushTracingOutlineColor,
  updateBrushTracingActor
} from "./paintbrush/BrushTracingRenderer";

const IS_ZOOM=1026;
export const LEFT_BUTTON_MODE = {
  NONE: 0,
//    PICKER: 1, Picker is decoupled from LeftButtonMode
  PIN: 2,
  BRUSH: 3,
  ERASER: 4,
  FILLING: 5,
  AZIMUTH: 6,
  RULER:7,
  SAMPLER:8,
  LEVEL:9,
  DRAW:10
};

/**
 *  vtkInteractorStyleImage methods modified for SPIne
 *
 * Interactions:
 * shift + left mouse button - move image
 * ctrl+left mouse button range- window level
 * mouse wheel - slicing (formerly zoom in & out)
 *
 * @param publicAPI
 * @param model
 * @constructor
 */
function SpineInteractorStyleImage(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('SpineInteractorStyleImage');


  const pickPoint = (callData) => {
    const interactor = model.interactor;
    const pointPicker = interactor.getPicker();
    const pos = callData.position;
    pointPicker.pick([pos.x, pos.y, 0.0], callData.pokedRenderer);
    let ijk = [];
    try {
      ijk = pointPicker.getPointIJK();
      if(model.infoCallback !=null ) {
        let worldPos = pointPicker.getPickedPositions()[0];
        model.infoCallback(worldPos, worldPos!=null?ijk:null); // return null so they can be handled as out of range
      }
      if(model.dragCallback !=null ) {
        let worldPos = pointPicker.getPickedPositions()[0];
        model.dragCallback(worldPos, worldPos!=null?ijk:null); // return null so they can be handled as out of range
      }
    } catch (err) {
      if(model.infoCallback !=null )
        model.infoCallback(null,null); // return null so they can be handled as out of range
      if(model.dragCallback !=null )
        model.dragCallback(null,null); // return null so they can be handled as out of range
      return null;
    }
    return ijk;
  };

  const fillPickedPoint = (callData) => {
    if (model.painter != null) {
      const ijk = pickPoint(callData);
      if (ijk != null) {
        const orient = callData.pokedRenderer.getActors()[0].getMapper().getSlicingMode();
        model.painter.setOrientation(orient);
        model.painter.fill(ijk,orient);
        callData.pokedRenderer.getRenderWindow().render();
      }
    }
  };


  // Public API methods
  publicAPI.superHandleMouseMove = publicAPI.handleMouseMove;
  publicAPI.handleMouseMove = (callData) => {
    const pos = callData.position;
    const renderer = callData.pokedRenderer;


    switch (model.state) {
      case States.IS_WINDOW_LEVEL:
        publicAPI.windowLevel(renderer, pos);
        publicAPI.invokeInteractionEvent({type: 'InteractionEvent'});
        break;

      case States.IS_SLICE:
        publicAPI.slice(renderer, pos);
        publicAPI.invokeInteractionEvent({type: 'InteractionEvent'});
        break;

      case IS_ZOOM:
        publicAPI.zoom(renderer, pos);
        break;

      default:
        break;
    }

    if (model.leftButtonMode === LEFT_BUTTON_MODE.SAMPLER && !callData.shiftKey ){
      const pos = callData.position;
      if (model.samplerStart!=null){
        model.samplerEnd = [pos.x, pos.y, 0.0];
        model.samplerCallback({start:model.samplerStart, end:[pos.x, pos.y, 0.0]});
      }
      return;
    }
    if (model.leftButtonMode === LEFT_BUTTON_MODE.LEVEL){
      const ijk = pickPoint(callData);

      if (callData.pokedRenderer!=null){
        const label = model.painter.getActiveColorFunc()();
        const cMap = model.manualToolBrowser.props.colorMaps[model.manualToolBrowser.props.activeLayer];
        const col = cMap.find(el=>el.value === label);

        if (getLevelTracingActor(renderer)!=null) {
          if (label!=null) {
            //const rgbArray = renderer.getActors()[1].getProperty().getRGBTransferFunction().mapValue(label);
            setTracingOutlineColor(col.color);
          }
          updateLevelTracingActor(renderer, {seed: ijk});
        }
        else {
          createLevelTracingActor(callData.pokedRenderer, 0, 1, ijk);
          setTracingOutlineColor(col.color);
        }
        callData.pokedRenderer.getRenderWindow().render();
      }
      return;
    }
    if (model.leftButtonMode === LEFT_BUTTON_MODE.BRUSH && model.painter.getToolPropsFunc()(BRUSH_TOOL,"contour")&& !callData.shiftKey){
      const ijk = pickPoint(callData);
      if (callData.pokedRenderer!=null){
        const label = model.painter.getActiveColorFunc()();
        const cMap = model.manualToolBrowser.props.colorMaps[model.manualToolBrowser.props.activeLayer];
        const col = cMap.find(el=>el.value === label);

        if (getBrushTracingActor(renderer)!=null) {
          if (label!=null) {
            setBrushTracingOutlineColor(col.color);
          }
          updateBrushTracingActor(renderer, {seed: ijk});
        }
        else {
          createBrushTracingActor(
            callData.pokedRenderer,
            0,
            1,
            model.painter.getToolPropsFunc()(BRUSH_TOOL,"radius"),
            model.painter.getToolPropsFunc()(BRUSH_TOOL,"shape")!=null ? model.painter.getToolPropsFunc()(BRUSH_TOOL,"shape") :"square",
            ijk);
          setTracingOutlineColor(col.color);
        }
        if (model.painter != null && model.painter.getDrawingState()) {  //checking the state of PAINTER (state of stroke)
          model.painter.addPoint(ijk);
          model.painter.getLabelMap().modified();
        }
        callData.pokedRenderer.getRenderWindow().render();
      }
      return;
    }

    if (model.leftButtonMode === LEFT_BUTTON_MODE.ERASER && model.painter.getToolPropsFunc()(ERASER_TOOL,"contour")){
      const ijk = pickPoint(callData);
      if (callData.pokedRenderer!=null){
        const label = 0;
        if (getBrushTracingActor(renderer)!=null) {
          if (label!=null) {
            setBrushTracingOutlineColor("#fff");
          }
          updateBrushTracingActor(renderer, {seed: ijk});
        }
        else {
          createBrushTracingActor(
            callData.pokedRenderer,
            0,
            0,
            model.painter.getToolPropsFunc()(ERASER_TOOL,"radius"),
            model.painter.getToolPropsFunc()(ERASER_TOOL,"shape")!=null ? model.painter.getToolPropsFunc()(ERASER_TOOL,"shape") :"square",
            ijk);
          setTracingOutlineColor("#fff");
        }
        if (model.painter != null && model.painter.getDrawingState()) {  //checking the state of PAINTER (state of stroke)
          model.painter.addPoint(ijk);
          model.painter.getLabelMap().modified();
        }
        callData.pokedRenderer.getRenderWindow().render();
      }
      return;
    }



    if (model.leftButtonMode === LEFT_BUTTON_MODE.BRUSH
      || model.leftButtonMode === LEFT_BUTTON_MODE.ERASER
      || (model.picker != null && model.picker['pointerTool'] && model.picker['mode'] === "onMove")
      || (model.infoTool!=null && model.infoTool[INFO_TOOL] )
      || (model.dragCallback!=null)
    ) {
      const ijk = pickPoint(callData);
      if (Array.isArray(ijk) && ijk.length) {
        if (model.leftButtonMode === LEFT_BUTTON_MODE.BRUSH || model.leftButtonMode === LEFT_BUTTON_MODE.ERASER) {
          if (model.painter != null && model.painter.getDrawingState()) {  //checking the state of PAINTER (state of stroke)
            model.painter.addPoint(ijk);
            model.painter.getLabelMap().modified();
            callData.pokedRenderer.getRenderWindow().render();
          }
        }
        if (model.picker != null && model.picker['pointerTool'] && model.picker['mode'] === "onMove")
          model.pickCallback(ijk);
      }
    }

    publicAPI.superHandleMouseMove(callData);
  };

  publicAPI.zoom = (renderer, position) => {
    if (position!=null && model.previousPosition!=null) {  //model.previousPosition is state indicator (States.IS_ZOOM is not available in VTK)
      const dy = model.previousPosition.y - position.y;
      const camera = renderer.getActiveCamera();

      if (camera.getParallelProjection()) {
        const k = dy * model.zoomScale;
        camera.setParallelScale((1.0 - k) * camera.getParallelScale());
      } else {
        const cameraPos = camera.getPosition();
        const cameraFp = camera.getFocalPoint();
        const norm = camera.getDirectionOfProjection();
        const k = dy * model.zoomScale;

        let tmp = k * norm[0];
        cameraPos[0] += tmp;
        cameraFp[0] += tmp;

        tmp = k * norm[1];
        cameraPos[1] += tmp;
        cameraFp[1] += tmp;

        tmp = k * norm[2];
        cameraPos[2] += tmp;
        cameraFp[2] += tmp;

        if (!camera.getFreezeFocalPoint()) {
          camera.setFocalPoint(cameraFp[0], cameraFp[1], cameraFp[2]);
        }

        camera.setPosition(cameraPos[0], cameraPos[1], cameraPos[2]);
        renderer.resetCameraClippingRange();
      }

      if (model.interactor.getLightFollowCamera()) {
        renderer.updateLightsGeometryToFollowCamera();
      }
      model.previousPosition = position;
      model.manualToolBrowser.refreshRenderWindow();
    }
  };




  // ======================3D Slicer-like interface updates====================

  //--------------------------------------------------------------------------
  /**
   * Overridden default handler (otherwise it will zoom and slice at the same time)
   * @param callData
   */
  publicAPI.handleStartMouseWheel = (callData) => {
    publicAPI.handleMouseWheel(callData);
  };

  //--------------------------------------------------------------------------
  /**
   * Overridden default handler (otherwise it will zoom and slice at the same time)
   */
  publicAPI.handleEndMouseWheel = () => {
  };

  /**
   * Handler of mouse wheel (with slicing).
   * @param callData
   */
  publicAPI.handleMouseWheel = (callData) => {
    let delta = 0;
    if (callData.spinY>0)
      delta=-1; //scroll down
    else
      delta=1; //scroll up
    model.manualToolBrowser.changeSliceWithDelta(delta);
  };

  //============================================================================

  //----------------------------------------------------------------------------
  publicAPI.superHandleLeftButtonPress = publicAPI.handleLeftButtonPress;
  publicAPI.handleLeftButtonPress = (callData) => {
    const pos = callData.position;

    if (!callData.shiftKey && !callData.controlKey) {
      // console.log("leftButtonMode",model.leftButtonMode);

      //FIRST check shortcuts
      if (model.keyCache === 'F') {
        fillPickedPoint(callData);
      }

      if (model.leftButtonMode === LEFT_BUTTON_MODE.SAMPLER && !callData.shiftKey){
        model.samplerStart = [pos.x, pos.y, 0.0];
        model.samplerCallback({start:model.samplerStart, end:[pos.x, pos.y, 0.0]});
        return;
      }

      if (model.leftButtonMode === LEFT_BUTTON_MODE.LEVEL) {
        if (model.painter!=null){
          model.painter.addSegmentedRegion(getLevelTracingRegion());
        }
      }


      if (model.leftButtonMode === LEFT_BUTTON_MODE.NONE && (!(model.picker!=null) || !model.picker['pointerTool'])) {
        //model.state = States.IS_WINDOW_LEVEL;
        publicAPI.wl(callData);
        return;
      }
      // Turned off
      // TODO if finished orientation for I and J this must be
      // finished too with handling many actors, ie.
      // Suppose that we work in K renderer:
      // 1. active actor id must be established
      // 2. if the active actor is original image - ijk values are correct
      // 3. otherwise value for k must be as it was before, and i,j must be recalculated based on
      // translation between actor origins (original and a given overlay)
      //

      //==========================Annotations && Positioning============================================

      if (model.leftButtonMode === LEFT_BUTTON_MODE.RULER || model.leftButtonMode === LEFT_BUTTON_MODE.PIN || (model.picker!=null && model.picker['pointerTool'] && model.picker['mode'] === "onClick")) {
        // if (renderer !== callData.pokedRenderer) {
        //     return;
        // }
        const interactor = model.interactor;
        const pointPicker = interactor.getPicker();
        const pos = callData.position;
        const point = [pos.x, pos.y, 0.0];
        pointPicker.pick(point, callData.pokedRenderer);
        let worldPos = pointPicker.getPickedPositions()[0]; //only the background Actor
        let ijk = [0, 0, 0];
        if (worldPos == null) {
          alert("You cannot pick a point outside MRI volume");
          return;
        }
        try {
          ijk = pointPicker.getPointIJK();
          // let counter = 0;
          // console.log("You picked ", ijk);
        } catch (err) {
          alert("You cannot pick a point outside MRI volume");
          return;
        }
        if (model.leftButtonMode === LEFT_BUTTON_MODE.PIN) {
          if (model.pinCallback != null) {
            model.pinCallback({
              "roiType": "POINT",
              "roiCellIJK": ijk,
              "roiPosition": worldPos,
            });
          }
        }
        if (model.leftButtonMode === LEFT_BUTTON_MODE.RULER) {
          if (model.rulerCallback != null) {
            let roiType = (model['ruler']!=null)?RULER_PROPERTY_NAME__END:RULER_PROPERTY_NAME__START;

            model.rulerCallback(roiType,{
              "roiType": "POINT",
              "roiCellIJK": ijk,
              "roiPosition": worldPos
            });
            if (model['ruler']!=null){
              delete model['ruler']
            }
            else model['ruler'] = true;
          }
        }

        if (model.pickCallback != null && model.picker!=null && model.picker['pointerTool']) { //now Picker is separated from Left Button
          model.pickCallback(ijk);
        }

        // return;
      }
      //TODO Add callback!!! to get slices
      // callData.pokedRenderer.getRenderWindow().getRenderers()[1].getActors()[0].getMapper()
      // renderer.getRenderWindow().getRenderers().forEach((s,idx)=>{
      //     s.getActors()[0].getMapper().setSlice(cellIJK[idx]);
      //
      // });
      // renderer.getRenderWindow().render();

      //========================== Brushing ============================================
      if (model.leftButtonMode === LEFT_BUTTON_MODE.BRUSH || model.leftButtonMode === LEFT_BUTTON_MODE.ERASER) {
        if (model.painter != null) {
          model.painter.startStroke();
          const orient = callData.pokedRenderer.getActors()[0].getMapper().getSlicingMode();
          model.painter.setOrientation(orient);
          const ijk = pickPoint(callData);
          if (ijk != null) {
            model.painter.addPoint(ijk);  // add point and connected mask
            callData.pokedRenderer.getRenderWindow().render();
          }
        }
      }
      if (model.leftButtonMode === LEFT_BUTTON_MODE.FILLING) {
        fillPickedPoint(callData);
      }
      if (model.leftButtonMode === LEFT_BUTTON_MODE.AZIMUTH) {
        publicAPI.startRotate();
      }


    } else if (
      model.interactionMode === 'IMAGE_SLICING' &&
      callData.controlKey
    ) {
      publicAPI.wl(callData);
    } else {
      // The rest of the button + key combinations remain the same
      publicAPI.superHandleLeftButtonPress(callData);
    }
  };

  /**
   *
   * @param callData
   */
  publicAPI.wl =(callData)=>{
    // publicAPI.startSlice();
    const pos = callData.position;
    model.windowLevelStartPosition[0] = pos.x;
    model.windowLevelStartPosition[1] = pos.y;
    // Get the last (the topmost) image
    //publicAPI.setCurrentImageNumber(model.currentImageNumber);
    //publicAPI.setCurrentImageNumber(0);
    const property = model.currentImageProperty;
    if (property) {
      model.windowLevelInitial[0] = property.getColorWindow();
      model.windowLevelInitial[1] = property.getColorLevel();
    }
    publicAPI.startWindowLevel();
  };

  //--------------------------------------------------------------------------
  publicAPI.superHandleLeftButtonRelease = publicAPI.handleLeftButtonRelease;
  publicAPI.handleLeftButtonRelease = (callData) => {
    switch (model.state) {
      case States.IS_WINDOW_LEVEL:
        publicAPI.endWindowLevel();
        model.state = States.IS_NONE;
        break;

      case States.IS_SLICE:
        publicAPI.endSlice();
        break;

      default:
        publicAPI.superHandleLeftButtonRelease();
        break;
    }

    if (model.leftButtonMode === LEFT_BUTTON_MODE.SAMPLER && !callData.shiftKey){
      const pos = callData.position;
      model.samplerEnd = [pos.x, pos.y, 0.0];
      console.log("Sampler",model.samplerStart, model.samplerEnd);
      const interactor = model.interactor;
      const pointPicker = interactor.getPicker();

      pointPicker.pick(model.samplerStart, callData.pokedRenderer);
      let worldPos = pointPicker.getPickedPositions()[0];
      let startIJK = pointPicker.getPointIJK();
      pointPicker.pick(model.samplerEnd, callData.pokedRenderer);
      let worldPos2 = pointPicker.getPickedPositions()[0];
      let endIJK = pointPicker.getPointIJK();
      const bounds = [worldPos[0],worldPos2[0],worldPos[1],worldPos2[1],worldPos[2],worldPos2[2]];
      const img = callData.pokedRenderer.getActors()[0].getMapper().getInputData();
      const stats = createHistogram(img,bounds);
      const threshold = otsu(stats.histogram,stats.inum);
      const points={start:model.samplerStart,end:model.samplerEnd, startXYZ:worldPos,endXYZ:worldPos2, startIJK,endIJK};
      model.samplerCallback({stats,threshold,midPoint:threshold,points});
      delete model.samplerStart;
      delete model.samplerEnd;
      return;
    }


    if (model.leftButtonMode === LEFT_BUTTON_MODE.BRUSH || model.leftButtonMode === LEFT_BUTTON_MODE.ERASER) {
      if (model.painter != null) {
        model.painter.endStroke();
      }
    }
  };

  /**
   * Add sub-annotation on rightclick (if in Pin mode)
   * or zoom in/out otherwise.
   * @param callData
   */
  publicAPI.handleRightButtonPress = (callData) => {
    // const pos = callData.position;
    if (!callData.shiftKey) {
      //==========================Annotations && Positioning============================================
      if (model.leftButtonMode === LEFT_BUTTON_MODE.PIN && !callData.controlKey) {
        const interactor = model.interactor;
        const pointPicker = interactor.getPicker();
        const pos = callData.position;
        const point = [pos.x, pos.y, 0.0];
        pointPicker.pick(point, callData.pokedRenderer);
        let worldPos = pointPicker.getPickedPositions()[0]; //only the background Actor
        let ijk = [0, 0, 0];
        if (worldPos == null) {
          alert("You cannot pick a point outside MRI volume");
          return;
        }
        try {
          ijk = pointPicker.getPointIJK();
          // let counter = 0;
        } catch (err) {
          alert("You cannot pick a point outside MRI volume");
          return;
        }
        if (model.leftButtonMode === LEFT_BUTTON_MODE.PIN) {
          if (model.subAnnotationPinCallback != null) {
            model.subAnnotationPinCallback({
              "roiType": "POINT",
              "roiCellIJK": ijk,
              "roiPosition": worldPos,
            });
          }
        }
        if (model.pickCallback != null && model.picker!=null && model.picker['pointerTool']) { //now Picker is separated from Left Button
          model.pickCallback(ijk);
        }
        return;
      }
      else{
        const renderer = callData.pokedRenderer;
        model.previousPosition = callData.position;
        model.state = IS_ZOOM;
        const size = model.interactor.getView().getSize();

        const camera = renderer.getActiveCamera();
        if (camera.getParallelProjection()) {
          model.zoomScale = 1.5 / size[1];
        } else {
          const range = camera.getClippingRange();
          model.zoomScale = 1.5 * (range[1] / size[1]);
        }
      }
    }
  };

  publicAPI.handleRightButtonRelease  = () => {
    model.state = States.IS_NONE;
  };

  //--- this function is used by mousemove handler--------------------------------------------------
  publicAPI.windowLevel = (renderer, position) => {
    model.windowLevelCurrentPosition[0] = position.x;
    model.windowLevelCurrentPosition[1] = position.y;
    const rwi = model.interactor;
    const isWindowLevelControlDisabled = (model.viewerState!=null && model.viewerState.isWindowLevelControlEnabled !=null && !model.viewerState.isWindowLevelControlEnabled);

    if (model.currentImageProperty && !isWindowLevelControlDisabled) {
      const size = rwi.getView().getViewportSize(renderer);

      const mWindow = model.windowLevelInitial[0];
      const level = model.windowLevelInitial[1];

      // Compute normalized delta
      let dx =
        (model.windowLevelCurrentPosition[0] -
          model.windowLevelStartPosition[0]) *
        4.0 /
        size[0];
      let dy =
        (model.windowLevelStartPosition[1] -
          model.windowLevelCurrentPosition[1]) *
        4.0 /
        size[1];

      // Scale by current values
      if (Math.abs(mWindow) > 0.01) {
        dx *= mWindow;
      } else {
        dx *= mWindow < 0 ? -0.01 : 0.01;
      }
      if (Math.abs(level) > 0.01) {
        dy *= level;
      } else {
        dy *= level < 0 ? -0.01 : 0.01;
      }

      // Abs so that direction does not flip
      if (mWindow < 0.0) {
        dx *= -1;
      }
      if (level < 0.0) {
        dy *= -1;
      }

      // Compute new mWindow level
      let newWindow = dx + mWindow;
      const newLevel = level - dy;

      if (newWindow < 0.01) {
        newWindow = 0.01;
      }

      model.currentImageProperty.setColorWindow(newWindow);
      model.currentImageProperty.setColorLevel(newLevel);
      if (model.currentImageProperty.getRGBTransferFunction()!=null){
        // const dataRange = renderer.getActors()[0].getMapper().getInputData().getPointData().getScalars().getRange();
        model.currentImageProperty.getRGBTransferFunction().setMappingRange(newLevel-newWindow/2,newLevel+newWindow/2);
      }
      if (model.onModifyWL!=null)
        model.onModifyWL(newWindow,newLevel);
    }
  };

  //----------------------------------------------------------------------------
  publicAPI.slice = (renderer, position) => {
    const rwi = model.interactor;

    const dy = position.y - model.lastSlicePosition;

    const camera = renderer.getActiveCamera();
    const range = camera.getClippingRange();
    let distance = camera.getDistance();

    // scale the interaction by the height of the viewport
    let viewportHeight = 0.0;
    if (camera.getParallelProjection()) {
      viewportHeight = camera.getParallelScale();
    } else {
      const angle = vtkMath.radiansFromDegrees(camera.getViewAngle());
      viewportHeight = 2.0 * distance * Math.tan(0.5 * angle);
    }

    const size = rwi.getView().getViewportSize(renderer);
    const delta = dy * viewportHeight / size[1];
    distance += delta;

    // clamp the distance to the clipping range
    if (distance < range[0]) {
      distance = range[0] + viewportHeight * 1e-3;
    }
    if (distance > range[1]) {
      distance = range[1] - viewportHeight * 1e-3;
    }
    camera.setDistance(distance);

    model.lastSlicePosition = position.y;
  };

  //----------------------------------------------------------------------------
  // This is a way of dealing with images as if they were layers.
  // It looks through the renderer's list of props and sets the
  // interactor ivars from the Nth image that it finds.  You can
  // also use negative numbers, i.e. -1 will return the last image,
  // -2 will return the second-to-last image, etc.
  publicAPI.setCurrentImageNumber = (i) => {
    const renderer = model.interactor.getCurrentRenderer();
    if (!renderer) {
      return;
    }
    model.currentImageNumber = i;

    function propMatch(j, prop, targetIndex) {
      if (
        prop.isA('vtkImageSlice') &&
        j === targetIndex &&
        prop.getPickable()
      ) {
        return true;
      }
      return false;
    }

    const props = renderer.getViewProps();
    let targetIndex = i;
    if (i < 0) {
      targetIndex += props.length;
    }
    let imageProp = null;
    let foundImageProp = false;
    for (let j = 0; j < props.length && !foundImageProp; j++) {
      if (propMatch(j, props[j], targetIndex)) {
        foundImageProp = true;
        imageProp = props[j];
      }
    }

    if (imageProp) {
      model.currentImageProperty = imageProp.getProperty();
    }
  };
  //----------------------------------------------------------------------------
  publicAPI.handleKeyPress = (callData) => {
    // const rwi = model.interactor;
    // let ac = null;
    switch (callData.key) {
      case 'f':
      case 'F':
        model.keyCache = 'F';
        break;

      default:
        break;
    }
  };

  publicAPI.handleKeyUp = (callData) => {
    // const rwi = model.interactor;
    // let ac = null;
    switch (callData.key) {
      case 'f':
      case 'F':
        model.keyCache = null;
        break;

      default:
        break;
    }
  };


}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
  windowLevelStartPosition: [0, 0],
  windowLevelCurrentPosition: [0, 0],
  lastSlicePosition: 0,
  windowLevelInitial: [1.0, 0.5],
  currentImageProperty: 0,
  currentImageNumber: -1,
  interactionMode: 'IMAGE2D',
  xViewRightVector: [0, 1, 0],
  xViewUpVector: [0, 0, -1],
  yViewRightVector: [1, 0, 0],
  yViewUpVector: [0, 0, -1],
  zViewRightVector: [1, 0, 0],
  zViewUpVector: [0, 1, 0],
  leftButtonMode: LEFT_BUTTON_MODE.NONE,
  keyCache: null
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance
  vtkInteractorStyleTrackballCamera.extend(publicAPI, model, initialValues);

  // Create get-set macros
  macro.setGet(publicAPI, model,
    ['interactionMode', 'leftButtonMode','rulerCallback','pinCallback', 'subAnnotationPinCallback', 'pickCallback',
      'samplerCallback', 'painter', 'keyCache', 'picker','manualToolBrowser',"onModifyWL","viewerState","infoTool","infoCallback","dragCallback"]);

  // For more macro methods, see "Sources/macro.js"

  // Object specific methods
  SpineInteractorStyleImage(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'SpineInteractorStyleImage');

// ----------------------------------------------------------------------------

export default Object.assign({newInstance, extend});
