import React from 'react';
import {Link} from "react-router-dom";
import {Card} from "primereact/components/card/Card";
import {DemoCard} from "./DemoCard";

export class AnnotationBuilderMainPage extends React.Component {


  render() {
    const footer = <i className="fa fa-check" title="Ready to use"/>;
    return ( <div style={{
        flexDirection: "row",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        paddingTop:"40px"
      }}>

        <Link to={"/annotationsBuilder/tables"}>
          <DemoCard
          footer={footer}
          headerClassName={"dynamicFormDemo"}
          title={"Tables"}
          description={"Create, update, view or delete annotation tables or forms"}
          filter={""}/>
        </Link>
        <Link to={"/annotationsBuilder/columns"}>  <DemoCard
          footer={footer}
          headerClassName={"dynamicFormDemo"}
          title={"Columns"}
          description={"Create, update, view or delete annotation columns or form entries"}
          filter={""}/>
        </Link>
        <Link to={"/annotationsBuilder/questions"}>
          <DemoCard
            footer={footer}
            headerClassName={"dynamicFormDemo"}
            title={"Questions"}
            description={"Create, update, view or delete semantic questions"}
            filter={""}/>
        </Link>
        <Link to={"/annotationsBuilder/ontologies"}>  <DemoCard
          footer={footer}
          headerClassName={"dynamicFormDemo"}
          title={"Ontologies"}
          description={"Create, update or view ontologies in SPINE"}
          filter={""}/></Link>
      </div>
    );

  };
}
AnnotationBuilderMainPage.propTypes = {

};

