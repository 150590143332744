import {connect} from "react-redux";
import { getStudy} from "../../visualization/action/CohortAction";
import {withRouter} from "react-router";
import AnnotationTabPanel from "../component/AnnotationTabPanel";
import {getAnnotationListForCohort} from "../../visualization/action/AnnotationAction";




const mapStateToProps = state => {
  return{
    cohort:state.visu.study.study,
    annotationsState: state.visu.annotations.annotationsListState,
    annotations: state.visu.annotations.annotationsList
  }
};

const mapDispatchToProps = (dispatch) => {
  return{
    getCohort: (cohortId) => {dispatch(getStudy(cohortId))},
    getAnnotations: ()=>dispatch(getAnnotationListForCohort())
  }
};


export const ContainerAnnotationTabPanel  =withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(AnnotationTabPanel));