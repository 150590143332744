import vtkColorMapsJSON from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps.json';
import {stringPropertyComparator} from "../../helpers/comparators";
import {parseColor} from "../SpineVTKHelper";



export const rgbPresetObjects = vtkColorMapsJSON
  .filter((p) => p.RGBPoints)
  .sort(stringPropertyComparator("Name"))
  .map((el)=>{

    let background = "";
    for (let i=0; i<el.RGBPoints.length;i+=4){
      background = background.concat("rgb(",
        String(Math.floor(el.RGBPoints[i+1]*255)),
        ",",
        String(Math.floor(el.RGBPoints[i+2]*255)),
        ",",
        String(Math.floor(el.RGBPoints[i+3]*255)),
        ",",
        String(Math.floor(el.RGBPoints[i]*255)),
        "),");
    }
    el['background'] = background.substring(0,background.length-2);
    return el;
  })
;

/**
 * This is default black-and-white representation of grayscale colormap.
 * To use in boxes displaying colormap stripes.
 * @type {{background: string, Name: string}}
 */
export const defaultColorMap = {background: "rgb(0,0,0,1), rgb(128,128,128), rgb(255,255,255)", Name:"None"};

/**
 * Converts SPINE LUT to string containing elements ready to visualize in boxes displaying colormap stripes.
 *
 * @param lut - color lookup table in SPINE format
 * @return {string} - concatenated color values eg. "#ffa, #003, #fff"
 */
export const convertLUTToRGBA = (lut)=>{
  const reduced =  lut.reduce((previous,current)=>{
    return previous.concat(current.color,", ");
  },"");

  return reduced.substring(0,reduced.length-2)
};



