import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'primereact/components/button/Button';
import {Growl} from 'primereact/components/growl/Growl';
import {InputText} from 'primereact/components/inputtext/InputText';
import { withTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_API_KEY} from "../../../Constants";

class Login extends React.Component {

    constructor(props) {
        console.log("Login ... constructor");
        super(props);
        this.state = {
            email: '',
            password: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.recaptcha = React.createRef();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

  render(){
    const { t,onLogin} = this.props;
    console.log('Login render');
    return(
        <React.Fragment>
            <Growl ref={(el) => { this.growl = el; }}/>
            <form onSubmit={this.onSave}>
                <div style={{marginTop:'10px', display:'flex', justifyContent:"space-around"}}>
                    <div >
                        <div className="nav__text">{t("email")}</div>
                        <div>
                        <InputText
                            name="email"
                            type={'email'}
                            placeholder="john.doe@nasa.gov"
                            value={this.state.value}
                            onChange={this.handleChange}
                        />
                        </div>
                        <div className="nav__text_opaque nav__text_link">
                            <Link style={{color: 'white'}} to="/register" onClick={()=>this.props.onRegister()}>
                                {t("registerForm.header")}
                            </Link>
                        </div>
                    </div>
                    <div style={{paddingLeft:"1%"}}>
                        <div className="nav__text">{t("password.label")}</div>
                        <div>
                        <input
                            type="password"
                            name="password"
                            value={this.state.value}
                            onChange={this.handleChange}
                            className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                        />
                        </div>
                        <div className="nav__text_opaque nav__text_link">
                            <Link style={{color: 'white'}} to="/forgotPassword" onClick={()=>this.props.onForgotPassword()}>
                                {t("password.forgotMessage")}
                            </Link>
                        </div>

                    </div>
                    <div style={{paddingLeft:"1%",marginTop:"16px"}}>
                        <Button
                            label={t("loginButton.label")}
                            title={t("loginButton.title")}
                            onClick={e => {
                                e.preventDefault();
                                this.recaptcha.current.execute();
                            }}
                        />
                    </div>
                </div>
                <ReCAPTCHA
                    ref={this.recaptcha}
                    size="invisible"
                    sitekey={RECAPTCHA_API_KEY}
                    onChange={value => 
                        {
                            if(value) {
                                onLogin(
                                {...this.state, recaptchaToken: value},
                                ()=>{
                                    fetch('/dist/version.json')
                                        .then(response => response.json())
                                        .then(data => {
                                           this.props.history.push(data.landingPage)
                                        });
                                },
                                (message) => this.growl.show(
                                                {   
                                                    sticky: false,
                                                    severity: 'info',
                                                    summary: 'Login error',
                                                    detail: message
                                                })
                                );
                                this.recaptcha.current.reset();
                            } else {
                                this.growl.show({
                                    sticky: false,
                                    severity: 'info',
                                    summary: 'Login error',
                                    detail: 'RECAPTCHA validation failed!'
                                })
                            }
                        }
                    }
                />
            </form>

        </React.Fragment>
        );
    }
}

Login.propTypes = {
  loginStatus: PropTypes.string.isRequired,
  onLogin: PropTypes.func.isRequired,
  onRegister :PropTypes.func.isRequired,
  onForgotPassword :PropTypes.func.isRequired
};

export default withTranslation()(Login);