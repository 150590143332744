import {mean, median, quantile, quickselect} from "d3-array";
import {
  UPDATE_MODEL_PARAMETERS_SAM,
  UPDATE_MODEL_PARAMETERS_STATE_SAM,
  UPDATE_PLOT_DATA_DUPS_SAM,
  UPDATE_PLOT_DATA_SAM,
  UPDATE_PLOT_DATA_STATE_SAM
} from "./actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import NetworkService from "../../helpers/io/NetworkService";
import {getSelectedPlotSettings} from "../selectors/SAMSelectors";
import {getSeriesContributions} from "../selectors/ContributionsSelectors";
import {displayCommunicationErrorMessage} from "./errorHandler";


// --------------------------------------------HELPERS --------------------------------------------------------------
/**
 * Helper to calculate trimmed mean
 * @param values
 * @param valueof
 * @return {Generator<*, void, ?>}
 */
function* numbers(values, valueof) {
  if (valueof === undefined) {
    for (let value of values) {
      if (value != null && (value = +value) >= value) {
        yield value;
      }
    }
  } else {
    let index = -1;
    for (let value of values) {
      if ((value = valueof(value, ++index, values)) != null && (value = +value) >= value) {
        yield value;
      }
    }
  }
}

/**
 * Calculate trimmed mean (only for more than 2 ratings)
 * @param values
 * @param valueof
 * @return {*}
 */
function trimmedMean(values, valueof){
  values = Float64Array.from(numbers(values, valueof));
  if (!values.length) return;
  const n = values.length;
  if (n > 2) { // calculate for more than 3 results
    const i = n >> 1;
    quickselect(values, i - 1, 0);
    if ((n & 1) === 0) quickselect(values, i, i);
    const lower = quantile(values, 0.05);
    const upper = quantile(values, 0.95);
    values = values.filter(el => {
      return el >= lower && el <= upper
    });
  }
  return mean(values, d => d);
}

/**
 * Function returning the function to calculate aggregates.
 * Aggregates can be : {"m"}
 * @param aggregateCalculation - flag indicating how the aggregate should be calculated {"median","mean","tmean"}
 */
export const aggregateValueForGroupFunction = (aggregateCalculation) => {
  if (aggregateCalculation === "median")
    return median;
  if (aggregateCalculation === "tmean")
    return trimmedMean;
  return mean;
};

//-------------------------------ACTIONS && ACTION CREATORS----------------------------------------

/**
 *
 * @param state - state of data to be provided to plot component
 * @param index - optional parameter, if not specified reducer will change selected plot (slot)
 * @return {{index: *, plotData: *, type: string}}
 */

export const updatePlotDataState = (state, index = null) => ({
  type: UPDATE_PLOT_DATA_STATE_SAM,
  state,
  index
});
/**
 *
 * @param plotData - data to be provided to plot component
 * @param index - optional parameter, if not specified reducer will change selected plot (slot)
 * @return {{index: *, plotData: *, type: string}}
 */
const updatePlotData = (plotData, index = null) => ({
  type: UPDATE_PLOT_DATA_SAM,
  plotData,
  index  // optional parameter - if null selected plot shall be updated
});
/**
 *
 * @param plotData -
 * @param index - optional parameter, if not specified reducer will change selected plot (slot)
 * @return {{index: *, plotData: *, type: string}}
 */
const updatePlotDataWithDups = (plotData, index = null) => ({
  type: UPDATE_PLOT_DATA_DUPS_SAM,
  plotData,
  index
});

/**
 * Function to calculate data for BA plot.
 *
 * @param plotIndex
 * @return {function(...[*]=)}
 */
export const updateBlandAltmanPlotData = (plotIndex = null) => {
  return (dispatch, getState) => {
    const settings = plotIndex != null
      ? getState().sam.plotSettings[plotIndex]
      : getSelectedPlotSettings(getState());
    const series = getSeriesContributions(getState(),plotIndex);
    const rawData = getState().sam.rawData;
    const {mode, groups, selectedCases, selectedMeasurementConfiguration, aggregateCalculation} = settings;

    if (mode === 0) { //TODO: Use constants 0 = Bland-altman;
      let dataForScatter = [];
      rawData
        .cases
        .filter(el => series.findIndex(contrib => contrib.caseId === el.uuid) > -1) // take into account strategy for missing contributions
        .forEach((caseI, index) => {
          try {
            const contributionsX = rawData.contributions
              .filter(el => {
                return el.caseId === caseI.uuid && groups[0].contributors.includes(el.contributorId) && el.measurementConfigurationId === selectedMeasurementConfiguration.id
              });

            const valuesX = contributionsX.map(el => el.value);
            const contributionsY = rawData.contributions
              .filter(el => {
                return el.caseId === caseI.uuid && groups[1].contributors.includes(el.contributorId) && el.measurementConfigurationId === selectedMeasurementConfiguration.id
              });
            const valuesY = contributionsY.map(el => el.value);


            if (valuesX.length === 0 || valuesY.length === 0) {
              throw "Missing sample";
            }

            const contributorsNumberG1 = groups[1]['contributors'].length;
            const contributorsNumberG0 = groups[0]['contributors'].length;

            let single = {
              "y": aggregateValueForGroupFunction(aggregateCalculation)(valuesY, d => d), //data for group2
              "x": aggregateValueForGroupFunction(aggregateCalculation)(valuesX, d => d), //data for group1
              'realCase': caseI['uuid'],
              "contributionsX": contributionsX,
              "contributionsY": contributionsY,
              "userId": index + 1,
              "mode":0
            };

            if (contributorsNumberG1 + contributorsNumberG0 > 1) {
              single['isCollectiveResult'] = true;
              //   single['numberOfUsers'] = contributorsNumberG1 + contributorsNumberG0;
              //   single['groups'] = [];
              //   groups.forEach((group) => {
              //     const color = group['color'];
              //     single['groups'].push(
              //       { contributors: group['contributors'].map((el) => {
              //           return {
              //             "x": el['value'],
              //             "originalImageId": parseOriginalImageId,
              //             "originalImageFormat": parseOriginalImageFormat,
              //             "userId": el['userId'],
              //             "img": `/api/user/${el['userId']}/picture`,
              //             "roiId": el['roiId']
              //           };
              //         }),
              //         color
              //       });
              //   });
            }
            if (single['x'] != null && single['y'] != null && !isNaN(single['x']) && !isNaN(single['y'])) {
              dataForScatter.push(single)
            }
          } catch (err) {
            console.log("Missing sample for: ", caseI['uuid'], ":", caseI);
          }

        });
      // first calculate statistics if needed
      if (dataForScatter.length > 0) {
        dispatch(calculateModelForBlandAltman(createTransferObjectToRServer(dataForScatter),plotIndex));
      }
      // then remove duplicates
      const dataForScatterWithDuplicates = markDuplicates(dataForScatter);
      dispatch(updatePlotData(dataForScatter, plotIndex));
      if (dataForScatterWithDuplicates.length !== dataForScatter.length)
        dispatch(updatePlotDataWithDups(dataForScatterWithDuplicates, plotIndex));
      else
        dispatch(updatePlotDataWithDups([], plotIndex));
      dispatch(updatePlotDataState(REQUEST_STATUS_SUCCESS, plotIndex));
    }
  };
};

/**
 * Marks duplicates so that they can be properly displayed.
 * Duplicated element has counter of its duplicates.
 * @param dTR - data to reduce
 * @return {*}
 */
export function markDuplicates(dTR) {

  //generate unique keys corresponding to values
  const hashedValues = dTR.map((item) => {
    return 'x:' + item.x + ', y:' + item.y
  });
  let result = [];
  dTR.forEach((item, index) => {
    const uniqueValueIndex = hashedValues.indexOf('x:' + item.x + ', y:' + item.y);
    if (uniqueValueIndex !== index) {
      result[uniqueValueIndex]['isDuplicated'] = true;
      item['isDuplicateOf'] = 'dup' + result[uniqueValueIndex]['userId'];
      if (result[uniqueValueIndex].hasOwnProperty('duplicatesCounter')) {
        result[uniqueValueIndex].duplicatesCounter += 1;
        item['duplicateNo'] = result[uniqueValueIndex].duplicatesCounter;
      } else {
        result[uniqueValueIndex]['duplicateNo'] = 1;
        item['duplicateNo'] = 2;
        result[uniqueValueIndex].duplicatesCounter = 2;
      }
    }
    result.push(item);
  });
  const duplicated = result
    .filter((el) => el.hasOwnProperty('isDuplicated')) //find duplicated
    .map((el) => {
      let deepCopy = JSON.parse(JSON.stringify(el)); //make a deep copy, otherwise any operations like delete will affect both
      delete deepCopy["isDuplicated"];
      delete el["duplicateNo"];
      el["userId"] = "dup" + el["userId"];
      deepCopy['isDuplicateOf'] = el['userId'];
      return deepCopy;
    });

  return result.concat(duplicated);
}

const updateModelParams = (model, index=null) => ({
  type: UPDATE_MODEL_PARAMETERS_SAM,
  model,
  index
});
const updateModelParamsState = (state, index=null) => ({
  type: UPDATE_MODEL_PARAMETERS_STATE_SAM,
  state,
  index
});
/**
 * Get model for Bland Altman Agreement Analysis calculated in R (StudyList).
 * @param transferObject - data to send (must contain x,y,l params)
 * @param plotIndex - optional parameter
 *
 * @returns {function(*)} change state of Store
 */
export const calculateModelForBlandAltman = (transferObject, plotIndex=null) => {
  return (dispatch)=> {
    dispatch(updateModelParamsState(REQUEST_STATUS_REQUESTED,plotIndex));
    console.log('SAM Action :: calculateModelForBlandAltman :: Before axios requres');
    NetworkService.getBlandAltmanData(transferObject)
      .then(outData => {
        console.log('SAM Action :: calculateModelForBlandAltman :: response', outData);
        dispatch(updateModelParams(outData,plotIndex));
        dispatch(updateModelParamsState(REQUEST_STATUS_SUCCESS,plotIndex));
      })
      .catch(err => {
        dispatch(displayCommunicationErrorMessage(err));
        dispatch(updateModelParamsState(REQUEST_STATUS_FAIL,plotIndex));
      });
  }
};

/**
 * Factory method creating transfer object to send to R server in order to calculate stats (line params, r-squared, etc.).
 * This method should be called on full data set (not with reduced duplicates).
 * @param dFS - data for scatter plot
 * @param ci - confidence intervals
 * @return {{}} transfer object
 */
function createTransferObjectToRServer(dFS, ci = 0.95) {
  let transferObject = {};
  transferObject.x = dFS.map(el => el.x);
  transferObject.y = dFS.map(el => el.y);
  transferObject.l = ci; //confidence intervals
  return transferObject;
}

