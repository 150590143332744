import {withRouter} from "react-router";
import {connect} from "react-redux";
import DynamicHistogramDicePanel from "../component/d3components/DynamicHistogramDicePanel";
import {
    clearOverlayImageActionCreator,
    getImageActionCreator,
    getTabletGameExperimentResults
} from "../action/TabletGameExperimentAction";
import {initializeDicePanel, initializeViewersInDicePanel} from "../../visualization/action/ManualToolAction";
import {clearViewersState, handleKeySlicing, updateViewerProperty} from "../../visualization/action/ViewerAction";

const mapStateToProps = (state, ownProps) => {
    return {
        skillResult: state.skill.skillResult,
        email: state.auth.email,
        results: state.expDesign.tabletGame.results,
        resultsState: state.expDesign.tabletGame.resultsState,
        mriImage: state.expDesign.tabletGame.mriImage,
        leftOverlay: state.expDesign.tabletGame.leftOverlay,
        leftFreq: state.expDesign.tabletGame.leftFreq,
        rightOverlay: state.expDesign.tabletGame.rightOverlay,
        rightFreq: state.expDesign.tabletGame.rightFreq,
        images: state.visu.images.images,
        overlays: state.visu.images.overlays,
        viewersState: state.visu.viewers.viewersState,
        livePresenterExperimentId: ownProps.livePresenterExperimentId
    }
};
/**
 *
 * @param dispatch
 * @returns {{getTabletGameExperimentResults: function(*=), getImage: function(*=), clearOverlayImage: function(*=)}}
 */
const mapDispatchToProps = dispatch => {
    return {
        getTabletGameExperimentResults: (tabletExperimentId) => {
            dispatch(getTabletGameExperimentResults(tabletExperimentId))
        },
        getImage: (imageProperties) => {
            dispatch(getImageActionCreator(imageProperties))
        },
        clearOverlayImage: (propName) => {
            dispatch(clearOverlayImageActionCreator(propName))
        },
        initialize: (experimentId) => {
            dispatch(initializeDicePanel(experimentId))
        },
        initializeViewersInDicePanel: (originalImageId, labelMapId) => {
            dispatch(initializeViewersInDicePanel(originalImageId, labelMapId))
        },
        clearViewersState: () => {
            dispatch(clearViewersState())
        },
        updateViewerProperty: (key, property, value) => {
            dispatch(updateViewerProperty(key, property, value))
        },
        handleKeySlicing: (index, event) => {
            dispatch(handleKeySlicing(index, event))
        }
    }
};
export const ContainerDynamicHistogramDicePanel = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)(DynamicHistogramDicePanel));