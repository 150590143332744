import React from 'react'
import {
    ANNOTATION_PROPERTY_NAME__ID,
    ANNOTATION_TOOL_TYPE, CHARACTERIZATION_TOOL_TYPE, IDENTIFICATION_TOOL_TYPE, PIN_TOOL,
    POINTER_TOOL, SEGMENTATION_TOOL_TYPE,
    SLICE_SELECTOR_TOOL_RANGED, SLICE_SELECTOR_TOOL_TYPE,
    SLICE_SELECTOR_WITH_PICKER
} from "../../../../Constants";
import {isAnnotationTableValid} from "../../action/AnnotationAction";
import {areFormsOutputValid} from "../../action/FormsAction";

/**
 * Validator responsible for (des-) activation of Forward/Submit button.
 *
 * Temporary solution based on Type of Tool - this should be replaced with validation section defined in manual task!!!
 *
 * Proper solution:
 * - parse output validation rule defined in manual Task configuration
 * - use state of widgets (may require to pass a viewersState as well)
 *
 * @param manualTask - definition of task
 * @param manualToolState - state of tools in Redux store
 * @param annotationsData - table data from redux
 * @param annotationsDefinition - definition of annotation table
 * @param annotationsFormData - form data from redux
 * @param annotationsFormDefinition - form definition from redux
 * @param formsState - state of custom forms from redux
 * @return {{isValid: *, validationMessage: *}}
 */
export const toolOutputValid = (manualTask,manualToolState,annotationsData,annotationsDefinition,annotationsFormData,annotationsFormDefinition,formsState)=> {

    try {
        const toolType = manualTask.miniWorkflow.currentTool.type;
        switch (toolType){
            case SLICE_SELECTOR_WITH_PICKER:
                return {isValid:manualToolState[POINTER_TOOL]['value']!=null,validationMessage:getValidationMessage(manualTask)};
            case ANNOTATION_TOOL_TYPE:
            case CHARACTERIZATION_TOOL_TYPE:{
                const idPrefix = (manualToolState[PIN_TOOL]!=null&&manualToolState[PIN_TOOL]['idPrefixHack'])?manualToolState[PIN_TOOL]['idPrefixHack']:null;
                let data = annotationsData;
                if(idPrefix!=null){
                    data = data.filter(el=>{return el[ANNOTATION_PROPERTY_NAME__ID].substring(0,idPrefix.length)===idPrefix});
                }
                const tableValid = isAnnotationTableValid(data, annotationsDefinition);
                const formValid = isAnnotationTableValid(annotationsFormData, annotationsFormDefinition);
                const formsValid = areFormsOutputValid(manualTask,formsState);
                const isValid = tableValid.isValid && formValid.isValid && formsValid.isValid;
                const missingFields = tableValid.missingFields + formValid.missingFields + formsValid.missingFields;
                return {isValid: isValid, validationMessage: getValidationMessage(manualTask, missingFields, tableValid.missingFieldsArray,formValid.missingFieldsArray)};
            }
            case IDENTIFICATION_TOOL_TYPE:
                return {isValid:true, validationMessage: getValidationMessage(manualTask)};
            case SLICE_SELECTOR_TOOL_RANGED:
            case SEGMENTATION_TOOL_TYPE:
            case SLICE_SELECTOR_TOOL_TYPE:
            default:
                return {isValid:true, validationMessage: getValidationMessage(manualTask)};
        }
    } catch(err){
        return true;
    }

};


export const getValidationMessage = (manualTask,missingFields,missingTableFields, missingFormFields )=> {
    try {
        const toolType = manualTask.miniWorkflow.currentTool.type;
        switch (toolType){
            case SLICE_SELECTOR_WITH_PICKER:
                return "Please select a point";
            case ANNOTATION_TOOL_TYPE:
            case CHARACTERIZATION_TOOL_TYPE:
                return <DetailsExpander missingFields={missingFields} missingTableFields={missingTableFields} missingFormFields={missingFormFields}/>;
            case IDENTIFICATION_TOOL_TYPE:
            case SLICE_SELECTOR_TOOL_TYPE:
            case  SEGMENTATION_TOOL_TYPE:
            default:
                return `Does not apply`;
        }
    } catch(err){
        return "";
    }
};


class DetailsExpander extends React.Component{

    constructor(props) {
        super(props);
        this.state={expand:false};
    }
    render() {
        const {missingFields,missingFormFields,missingTableFields}=this.props;
        return(
          <div>
              Please fill in all required fields. <br/>
              There is {missingFields} missing fields left.<br/>
              {!this.state.expand && <a style={{textDecoration:"underline"}} onClick={()=>this.setState({expand:true})}>See details</a>}
              {this.state.expand && <div> {  missingTableFields!=null && missingTableFields.length>0 && <div>Table: {missingTableFields.map(el=><div>{el}</div>)}</div> }</div>}
              {this.state.expand && <div> {  missingFormFields!=null && missingFormFields.length>0 && <div>Form: {missingFormFields.map(el=><div>{el}</div>)}</div> }</div>}
          </div>
        );
    }
}