import React from "react";
import BrowserFactory from "../../visualization/component/annotationTool/BrowserFactory";
import PropTypes from "prop-types";

/**
 * Layout component for displaying six squares in a grid and the panel in the remaining space.
 *
 *
 */
class SixPackLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewportHeight: null,
      sidebarVisible:false
    };
    ["calcLayout"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  static getLabel() {
    return "Six square panels in 2 rows with widget Toolbar"
  }

  static getClassName() {
    return "birdsDemo"
  }

  static getViewersPortKeys() {
    return ["upperLeft", "upperMiddle", "upperRight", "lowerLeft", "lowerMiddle", "lowerRight"];
  }

  componentDidMount() {
    this.calcLayout();
    window.addEventListener("resize", this.calcLayout);
  }

  calcLayout() {

    const mainHeight = document.getElementsByClassName("mainContents")[0].clientHeight;
    const widgetsHeight = 40;

    this.setState({
      viewportHeight: (mainHeight - widgetsHeight) / 2 - controlPanelHeight - 4,
    });


  }

  render() {
    const {toolComponentProps, toolComponentState, widgetPanel, importExportPanel} = this.props;
    const viewersState = toolComponentProps.viewersState;

    const upperMiddle = (viewersState != null && viewersState.hasOwnProperty("upperMiddle"))
      ? BrowserFactory.build("upperMiddle", toolComponentProps, toolComponentState)
      : <div title="Middle viewer here."/>;
    const upperLeft = (viewersState != null && viewersState.hasOwnProperty("upperLeft"))
      ? BrowserFactory.build("upperLeft", toolComponentProps, toolComponentState)
      : <div title="Upper-left reference viewer here."/>;
    const upperRight = (viewersState != null && viewersState.hasOwnProperty("upperRight"))
      ? BrowserFactory.build("upperRight", toolComponentProps, toolComponentState)
      : <div title="Upper-right reference viewer here."/>;

    const lowerLeft = (viewersState != null && viewersState.hasOwnProperty("lowerLeft"))
      ? BrowserFactory.build("lowerLeft", toolComponentProps, toolComponentState)
      : <div title="Lower-left reference viewer here."/>;
    const lowerMiddle = (viewersState != null && viewersState.hasOwnProperty("lowerMiddle"))
      ? BrowserFactory.build("lowerMiddle", toolComponentProps, toolComponentState)
      : <div title="Lower-middle reference viewer here."/>;
    const lowerRight = (viewersState != null && viewersState.hasOwnProperty("lowerRight"))
      ? BrowserFactory.build("lowerRight", toolComponentProps, toolComponentState)
      : <div title="Lower-right reference viewer here."/>;

    return (
      <React.Fragment>
        <div className="sixpacklayout-bar-style">{widgetPanel}{importExportPanel}</div>
        <div className="sixpacklayout-main-container">
          <div className="sixpacklayout-viewers-container">
            {this.state.viewportHeight != null &&
            <div style={sixPackGrid(this.state.viewportHeight)}>
              <div>{upperLeft}</div>
              <div>{upperMiddle}</div>
              <div>{upperRight}</div>
              <div>{lowerLeft}</div>
              <div>{lowerMiddle}</div>
              <div>{lowerRight}</div>
            </div>
            }
          </div>
          <div className="sixpacklayout-panel-container" id={"graph-panel-placeholder"}>
          {/*  <i className={*/}
          {/*    this.state.sidebarVisible*/}
          {/*    ? "fa fa-minus-square-o"*/}
          {/*    : "fa fa-plus-square-o"*/}
          {/*  }*/}
          {/*     style={panelIconStyle}*/}
          {/*     onClick={()=>{this.setState({sidebarVisible:!this.state.sidebarVisible})}}/>*/}
          {/*  <div*/}
          {/*    style={{*/}
          {/*      width: "inherit",*/}
          {/*      position: "inherit",*/}
          {/*      display:this.state.sidebarVisible?"block":"none"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <ContainerDirectedGraphControlPanel/>*/}
          {/*  </div>*/}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default SixPackLayout;

SixPackLayout.defaultProps = {};

SixPackLayout.propTypes = {
  widgetPanel: PropTypes.object.isRequired
};


const sixPackGrid = (h) => {
  return {
    display: "grid",
    gridTemplateColumns: `repeat(3, ${h}px)`,
    gridTemplateRows: `repeat(2, ${h}px)`,
    gridGap: `${controlPanelHeight}px 4px`,
    justifyContent: "left"
  }
};

// const panelIconStyle = {
//   color: "rgb(0, 210, 208)",
//   fontSize: "2em",
//   position: "fixed",
//   right:"1em",
//   top:"50px",
//   margin:"0.5em"
// };



const controlPanelHeight = 36;