import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  updatePlotSettingsProperty
} from "../action/SAMAction";
import {
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import { updateScatterPlotData} from "../action/ScatterPlotAction";
import CustomScriptPanelContent from "../component/CustomScriptPanelContent";




const mapStateToProps = (state,ownProps) => {
  return {
    rawData: state.sam.rawData,
    selectedMeasurementConfiguration: getSelectedPlotSettings(state).selectedMeasurementConfiguration,
    script: getSelectedPlotSettings(state).script
  }
};

const mapDispatchToProps = (dispatch,ownProps) => {
  return {
    updatePlotSettingsProperty:(p,v)=>{
      dispatch(updatePlotSettingsProperty(p,v))
    },
    updateScatterPlotData:(p,index)=>{
      dispatch(updateScatterPlotData(p,index))
    }
  }
};

export const ContainerCustomScriptPanelContent = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(CustomScriptPanelContent));