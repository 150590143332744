/* eslint-disable no-bitwise */
import { vec3 } from 'gl-matrix';
// import WebworkerPromise from 'webworker-promise';

import macro from 'vtk.js/Sources/macro';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';
import {
    BRUSH_TOOL, ERASER_TOOL, INTENSITY_FILTER_TOOL, MAIN_TOOL,
    MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE
} from "../../../Constants";
import {LEFT_BUTTON_MODE} from "../SpineInteractorStyleImage";
import {createOffsets} from "../processing/VoxelProcessing";

// import PaintFilterWorker from 'vtk.js/Sources/Filters/General/PaintFilter/PaintFilter.worker';

const { vtkErrorMacro } = macro;


const HISTORY_BUFFER_CAPACITY = 10;

// ----------------------------------------------------------------------------
// vtkPaintFilter methods
// ----------------------------------------------------------------------------


function SpinePaintFilter(publicAPI, model) {
    // Set our className
    model.classHierarchy.push('SpinePaintFilter');

    // const globals = {
    //     // single-component labelmap
    //     buffer: null,
    //     dimensions: [0, 0, 0],
    //     prevPoint: null,
    // };

    let renderers = [];
    // let worker = null;
    // let workerPromise = null;
    const history = {
        buffer: [],
        // current painted layer index
        cindex: -1,
        // colors: [],
    };
    const connectedOffset = []; //neighborhood matrix
    connectedOffset[0] = [[0, -1, 0],[0, 0, -1],[0, 1, 0],[0, 0, 1]];
    connectedOffset[1] = [[-1, 0, 0],[0, 0, -1],[1, 0, 0],[0, 0, 1]];
    connectedOffset[2] = [[-1, 0, 0],[0, -1, 0],[1, 0, 0],[0, 1, 0]];

    /**
     *  Get index for a given point IJK coordinates
     * @param point - 3d point in IJK space
     * @param dims - dimensions
     * @return {*}
     */
    function getIndex(point,dims){
        return point[0] + point[1] * dims[0] + point[2] * dims[0] * dims[1];
    }

    // /**
    //  *
    //  * @param point - 3d  point in IJK space
    //  * @param data - 1D array of scalar data  (grayscale, overlay)
    //  * @param dims - dimensions
    //  * @return {*}
    //  */
    // function getValueAtPixel (point,data,dims){
    //     return  data[getIndex(point,dims)];
    // }



    function isFilterOff(){
    return  model.toolPropsFunc(INTENSITY_FILTER_TOOL,INTENSITY_FILTER_TOOL)===false    // filter turned off
        || !(model.toolPropsFunc(INTENSITY_FILTER_TOOL,INTENSITY_FILTER_TOOL)!=null)   //tool not registered
    }

    /**
     * Check whether intensity in original image is in the range of filter.
     *
     * @param voxIndex - 1D index of point in array of scalars
     * @return {*|boolean} - true/false
     */
    function filterIntensities(voxIndex){


        const minValue= (model.toolPropsFunc(INTENSITY_FILTER_TOOL,'minValue')!=null)
          ? model.toolPropsFunc(INTENSITY_FILTER_TOOL,'minValue')
          : 0;
        const maxValue= (model.toolPropsFunc(INTENSITY_FILTER_TOOL,'maxValue')!=null)
          ? model.toolPropsFunc(INTENSITY_FILTER_TOOL,'maxValue')
          : Number.MAX_SAFE_INTEGER;
        const origData = model.backgroundImage.getPointData().getScalars().getData();

        return (model.toolPropsFunc(INTENSITY_FILTER_TOOL,INTENSITY_FILTER_TOOL)              //filter is on
          && origData[voxIndex]>=minValue
          && origData[voxIndex]<=maxValue) || isFilterOff()
    }


    /**
     * Checks if potential voxel coordinates are in image bounds.
     * @param vox
     * @param dims
     */
    function voxelInBounds(vox,dims){
        return vox[0] >= 0
          &&  vox[1] >= 0
          &&  vox[2] >= 0
          &&  vox[0] <= dims[0] - 1
          &&  vox[1] <= dims[1] - 1
          &&  vox[2] <= dims[2] - 1;
    }
    // --------------------------------------------------------------------------
    function handlePaint(point) {
        const scalars = model.labelMap.getPointData().getScalars();
        const data = scalars.getData();

        const dims = model.labelMap.getDimensions();
        // const index = getIndex(point,dims);
        const label = (model.toolPropsFunc(MAIN_TOOL,MAIN_TOOL_PROPERTY_NAME_LEFT_BUTTON_MODE)!==LEFT_BUTTON_MODE.ERASER)
            ? model.activeColorFunc()
            : 0;
        let widget = model.toolPropsFunc(MAIN_TOOL,'leftButtonMode');
        let amBrush = (widget===LEFT_BUTTON_MODE.BRUSH)
          ? model.toolPropsFunc(BRUSH_TOOL,'radius')
          : model.toolPropsFunc(ERASER_TOOL,'radius');   // check which radius should be used

        let offsets = createOffsets(model.orientation,amBrush,model.toolPropsFunc(BRUSH_TOOL,'shape')!=null?model.toolPropsFunc(BRUSH_TOOL,'shape'):"square");

        for(let i = 0; i < offsets.length; i++){
            let offset = offsets[i];
            let vox = [point[0] + offset[0], point[1] + offset[1], point[2] + offset[2]];
            if (voxelInBounds(vox,dims)) { // checks if brush elements are within grid range
                let voxIndex = getIndex(vox, dims);
                if ((isFilterOff() || filterIntensities(voxIndex)) && model.checkMasksFunc(vox))
                    data[voxIndex] = label;
            }
        }
        scalars.modified();
        model.labelMap.modified();
        publicAPI.modified();
    }

    publicAPI.renderAll = ()=>{
        renderers.forEach((el)=>{el()});
    };

    publicAPI.invertColorsInOverlay = (value)=>{
      console.log("invert",value);
        if (model.labelMap) {
            const scalars = model.labelMap.getPointData().getScalars();
            const data = scalars.getData();
            for (let i=0;i<data.length;i++){
                if(data[i]===value){
                    data[i]=0;
                }
                else if(data[i]===0){
                    data[i]=value;
                }
            }
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
        }
    };

    publicAPI.addSegmentedRegion = (addedImageData)=>{
        if (model.labelMap) {
            const addedDataArray = addedImageData.getPointData().getScalars().getData();
            const scalars = model.labelMap.getPointData().getScalars();
            const data = scalars.getData();
            // const dims = model.labelMap.getDimensions();
            const label =  model.activeColorFunc();

            // maybe masks checking needs to be added?? AM
            if(data.length === addedDataArray.length){
                for (let pIndex=0; pIndex<data.length; pIndex++){
                    if (addedDataArray[pIndex]>0 && (isFilterOff() || filterIntensities( pIndex))) {
                        data[pIndex] = label;
                    }
                }
            }

            saveToHistory();
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
        }
    };

    publicAPI.fill = (indexPt,slicingMode)=>{
        if (model.labelMap) {
            const scalars = model.labelMap.getPointData().getScalars();
            const data = scalars.getData();
            const dims = model.labelMap.getDimensions();


            const label =  model.activeColorFunc();

            // const worldPt = [point[0], point[1], point[2]];
            // const indexPt = [0, 0, 0];  //seed ijk coordinates
            // vec3.transformMat4(indexPt, worldPt, model.maskWorldToIndex);
            // indexPt[0] = Math.round(indexPt[0]);
            // indexPt[1] = Math.round(indexPt[1]);
            // indexPt[2] = Math.round(indexPt[2]);

            let getValueAtPixel = (vec3) => {
                return data[getIndex(vec3, dims)];
            };

            if (getValueAtPixel(indexPt) > 0) {  //check if point is already filled
                return;
            }
            let setValueAtPixel = (index, value) => {
                const ind = getIndex(index, dims);
                data[ind] = value;
                // globals.buffer[ind] = value;
            };

            // globals.buffer = new Uint8Array(dims[0] * dims[1] * dims[2]);

            let voxelCoords = indexPt.slice();
            let offset = connectedOffset[slicingMode];
            let frontpix = [voxelCoords];

            while (frontpix.length > 0) {
                let tempfront = [];
                frontpix.forEach(function (front) {
                    offset.forEach(function (offs) {
                        let off = [];
                        off[0] = offs[0] + front[0];
                        off[1] = offs[1] + front[1];
                        off[2] = offs[2] + front[2];
                        if (voxelInBounds(off,dims)) {
                            let currentLabel = getValueAtPixel(off);
                            if (currentLabel === 0) {
                                if ((label === 0 || isFilterOff() || filterIntensities(getIndex(off, dims))) && model.checkMasksFunc(off)) {
                                    setValueAtPixel(off, label);
                                    tempfront.push(off);
                                }
                            }
                        }
                    });
                });
                frontpix = tempfront;
            }

            if (label===0 || isFilterOff() || filterIntensities( getIndex(indexPt,dims)))
                setValueAtPixel(indexPt, label);//add initial Point at the end of processing
            saveToHistory();
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
        }
    };

    /**
     *
     * @param canvas - function provided by canvas context
     * @param canvas2 - external canvas (eg. glass)
     * @param slicingMode - ijk
     * @param sliceNumber -
     * @param worldToDisplay - function from View
     * @param renderer - vtk renderer
     */
    publicAPI.fillClosedArea=(canvas,canvas2,slicingMode,sliceNumber,worldToDisplay,renderer)=>{

        if (model.labelMap) {
            const canvasContext = canvas.getContext('2d');
            const scalars = model.labelMap.getPointData().getScalars();
            const data = scalars.getData();
            const dims = model.labelMap.getDimensions();
            const label =  model.activeColorFunc();

            const rect2 = canvas2.getBoundingClientRect(); // abs. size of element
            const scaleX2 = canvas2.width / rect2.width;    // relationship bitmap vs. element for X
            const scaleY2 = canvas2.height / rect2.height;  // relationship bitmap vs. element for Y

            const setValueAtPixel = (index, value) => {
                const ind = getIndex(index, dims);
                data[ind] = value;
            };

            const startI = slicingMode===0?sliceNumber:0;
            const endI = slicingMode===0?sliceNumber+1:dims[0];
            const startJ = slicingMode===1?sliceNumber:0;
            const endJ = slicingMode===1?sliceNumber+1:dims[1];
            const startK = slicingMode===2?sliceNumber:0;
            const endK = slicingMode===2?sliceNumber+1:dims[2];

            for (let i=startI;i<endI;i++){
                for (let j=startJ;j<endJ;j++){
                    for (let k=startK;k<endK;k++){
                        let xyz = model.backgroundImage.indexToWorld([i,j,k]);  // IJK to XYZ
                        const displayXYZ = worldToDisplay(xyz[0],xyz[1],xyz[2],renderer);       // XYZ to display xy
                        if (canvasContext.isPointInPath(displayXYZ[0]/scaleX2,rect2.height - displayXYZ[1]/scaleY2)){  // use scale from glass (VTK is scaling X,Y and flipping Y)
                            if ((label===0 || isFilterOff() || filterIntensities( getIndex([i,j,k],dims))) &&  model.checkMasksFunc([i,j,k])) {
                                setValueAtPixel([i,j,k], label);
                            }
                        }
                    }
                }
            }

            saveToHistory();
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
        }
    };

    publicAPI.startStroke = () => {
        if (model.labelMap) {
            // const dims = model.labelMap.getDimensions();
            model.drawingState = true;
            // globals.buffer = new Uint8Array(dims[0]*dims[1]*dims[2]);

            // worker = new PaintFilterWorker();
            // workerPromise = new WebworkerPromise(worker);
            // workerPromise.exec('start', {
            //     bufferType: 'Uint8Array',
            //     dimensions: model.labelMap.getDimensions(),
            // });
        }
    };

    // --------------------------------------------------------------------------

    function saveToHistory (){
        const scalars = model.labelMap.getPointData().getScalars();
        const data = scalars.getData();

        // const strokeLabelMap = globals.buffer;

        if (history.cindex === HISTORY_BUFFER_CAPACITY) {
            history.buffer.shift(); //remove first element if HISTORY_BUFFER_CAPACITY
        } else {
            history.cindex++;
        }
        // history.colors.splice(history.cindex, history.colors.length);
        history.buffer.length = history.cindex;
        history.buffer.push(data.slice());
        //
        //         const bgScalars = model.backgroundImage.getPointData().getScalars();
        //         if (model.voxelFunc) {
        //             for (let i = 0; i < strokeLabelMap.length; i++) {
        //                 if (strokeLabelMap[i]) {
        //                     const voxel = bgScalars.getTuple(i);
        //                     const out = model.voxelFunc(voxel, strokeLabelMap[i], i);
        //                     if (out !== null) {
        //                         data[i] = out;
        //                     }
        //                 }
        //             }
        //         } else {
        // for (let i = 0; i < strokeLabelMap.length; i++) {
        //     if (history.cindex === HISTORY_BUFFER_CAPACITY) {
        //         // last bit will be shifted off
        //         const lastBit = history.buffer[i] & 0x1;
        //         history.buffer[i] = (history.buffer[i] >> 1) | lastBit;
        //     }
        //
        //     if (strokeLabelMap[i]) {
        //         data[i] = model.label;
        //         history.buffer[i] |= 1 << history.cindex;
        //     } else {
        //         history.buffer[i] &= ~(1 << history.cindex);
        //     }
        // }

    }

    publicAPI.endStroke = () => {
        if (model.labelMap) {
            model.drawingState = false;
            model.orientation = -1;
            // if (workerPromise) {
            //     workerPromise.exec('end').then((strokeBuffer) => {
            const scalars = model.labelMap.getPointData().getScalars();
            // const data = scalars.getData();

            saveToHistory();

            //
            //         worker.terminate();
            //         worker = null;
            //         workerPromise = null;
            //
            //         scalars.setData(data);
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
            //     });
            // }
        }

    };
    // --------------------------------------------------------------------------

    publicAPI.addPoint = (point) => {  //point- world position
        if (model.labelMap) {
            // if (workerPromise) {
            //     const worldPt = [point[0], point[1], point[2]];
            //     const indexPt = [0, 0, 0];
            //     vec3.transformMat4(indexPt, worldPt, model.maskWorldToIndex);
            //     indexPt[0] = Math.round(indexPt[0]);
            //     indexPt[1] = Math.round(indexPt[1]);
            //     indexPt[2] = Math.round(indexPt[2]);
            handlePaint(point);
            // }
        }
    };

    // --------------------------------------------------------------------------

    publicAPI.undo = () => {
        if (history.cindex > 0) {
            const scalars = model.labelMap.getPointData().getScalars();
            // const data = scalars.getData();

            // for (let i = 0; i < history.buffer.length; i++) {
            //     let labeli = history.cindex - 1;
            //     while (labeli > -1 && !(history.buffer[i] & (1 << labeli))) {
            //         labeli--;
            //     }
            //     const label = history.colors[labeli] || 0;
            //     data[i] = label;
            // }


            history.cindex--;

            scalars.setData(history.buffer[history.cindex].slice());
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
        }
    };

    // --------------------------------------------------------------------------

    publicAPI.redo = () => {
        if (history.cindex < HISTORY_BUFFER_CAPACITY) {
            const scalars = model.labelMap.getPointData().getScalars();
            // const data = scalars.getData();
            // const labeli = history.cindex + 1;
            // const label = history.colors[labeli];

            // for (let i = 0; i < history.buffer.length; i++) {
            //     if (history.buffer[i] & (1 << labeli)) {
            //         data[i] = label;
            //     }
            // }


            if (history.buffer[history.cindex+1]){
                history.cindex++;
                scalars.setData(history.buffer[history.cindex].slice());
                scalars.modified();
                model.labelMap.modified();
                publicAPI.modified();
                publicAPI.renderAll();
            }

        }
    };

    // --------------------------------------------------------------------------

    const superSetLabelMap = publicAPI.setLabelMap;
    publicAPI.setLabelMap = (lm) => {
        if (superSetLabelMap(lm)) {
            // reset history layer
            // history.buffer = new Uint8Array(lm.getNumberOfPoints());
            history.buffer.push(new Uint8Array(lm.getNumberOfPoints()));
            history.cindex = 0;
        }
    };

    // This function is used to force re-render
    publicAPI.registerRender=(renderFunction)=>{
        renderers.push(renderFunction);
    };

    /**
     * This function is required to set the proper encoding for labels.
     * @param data
     * @return {Uint8ArrayConstructor,Uint8ClampedArrayConstructor,Uint16ArrayConstructor, Float32ArrayConstructor,Float64ArrayConstructor,Uint32ArrayConstructor}
     */
    function getDataArrayType (data){
        if(data instanceof Float32Array){
            return Float32Array;
        }
        if(data instanceof Uint16Array){
            return  Uint16Array;
        }
        if(data instanceof Float64Array){
            return Float64Array;
        }
        if(data instanceof Uint32Array){
            return Uint32Array;
        }
        if(data instanceof Uint8ClampedArray){
           return Uint8ClampedArray;
        }
        return Uint8Array;
    }

    //This function is used to retrieve data from existing image.
    //Watch out! Computationally expensive since data are coregistered.
    publicAPI.setOverlayImageData = (overlayImageData)=>{
        const dims = model.labelMap.getDimensions();
        if(overlayImageData.getPointData().getScalars().getData().length!== model.labelMap.getPointData().getScalars().getData().length){

            // The size shall be as in original image, but type as in overlay
            const arrayConstructor = getDataArrayType(overlayImageData.getPointData().getScalars().getData());
            const values = new arrayConstructor(model.backgroundImage.getNumberOfPoints());   // original image size
            const dataArray = vtkDataArray.newInstance({
                numberOfComponents: overlayImageData.getPointData().getScalars().getNumberOfComponents(), // labelmap with single component
                values,
            });
            model.labelMap.getPointData().setScalars(dataArray);

            overlayImageData.getPointData().getScalars().getData().forEach((el,index)=>{
                if(el>0){
                    const worldPt = overlayImageData.getPoint(index);
                    const indexPt = [0, 0, 0];
                    vec3.transformMat4(indexPt, worldPt, model.maskWorldToIndex);
                    indexPt[0] = Math.round(indexPt[0]);
                    indexPt[1] = Math.round(indexPt[1]);
                    indexPt[2] = Math.round(indexPt[2]);
                    const index2 =  getIndex(indexPt,dims);
                    model.labelMap.getPointData().getScalars().getData()[index2]=el;
                }
            });
            history.buffer.shift();
            history.buffer.push(model.labelMap.getPointData().getScalars().getData().slice());
        }
        else{
            history.buffer.shift();
            model.labelMap.getPointData().getScalars().setData(overlayImageData.getPointData().getScalars().getData());
            history.buffer.push(model.labelMap.getPointData().getScalars().getData().slice());
        }
    };

    // --------------------------------------------------------------------------

    publicAPI.requestData = (inData, outData) => {
        if (!model.backgroundImage) {
            vtkErrorMacro('No background image');
            return;
        }

        if (!model.backgroundImage.getPointData().getScalars()) {
            vtkErrorMacro('Background image has no scalars');
            return;
        }

        if (!model.labelMap) {
            // clone background image properties
            const labelMap = vtkImageData.newInstance(
                model.backgroundImage.get('spacing', 'origin', 'direction')
            );
            labelMap.setDimensions(model.backgroundImage.getDimensions());
            labelMap.computeTransforms();

            // right now only support 256 labels
            const values = new Uint8Array(model.backgroundImage.getNumberOfPoints());
            const dataArray = vtkDataArray.newInstance({
                numberOfComponents: 1, // labelmap with single component
                values,
            });
            labelMap.getPointData().setScalars(dataArray);

            publicAPI.setLabelMap(labelMap);
        }

        if (!model.maskWorldToIndex) {
            model.maskWorldToIndex = model.labelMap.getWorldToIndex();
        }

        const scalars = model.labelMap.getPointData().getScalars();

        if (!scalars) {
            vtkErrorMacro('Mask image has no scalars');
            return;
        }

        model.labelMap.modified();

        outData[0] = model.labelMap;
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    backgroundImage: null,
    labelMap: null,
    maskWorldToIndex: null,
    voxelFunc: null,
    // brushRadius: 1,
    // eraserRadius: 1,
    // label: 0,
    orientation:-1,
    drawingState:false,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Make this a VTK object
    macro.obj(publicAPI, model);

    // Also make it an algorithm with no input and one output
    macro.algo(publicAPI, model, 0, 1);

    macro.setGet(publicAPI, model, [
        'backgroundImage',
        'labelMap',
        'labelWorldToIndex',
        'voxelFunc',
        'checkMasksFunc',//function to check masks from preventing drawing
        // 'label',
        // 'brushRadius',
        'toolPropsFunc',
        'activeColorFunc',
        // 'eraserRadius',
        'orientation',
        'drawingState',  //this is used for handling interactions:  leftpressed & mousemove
        'forceRender'
    ]);

    // Object specific methods
    SpinePaintFilter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'SpinePaintFilter');

// ----------------------------------------------------------------------------

export default { newInstance, extend };
