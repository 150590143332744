import React from "react";
import PropTypes from "prop-types";
import {REQUEST_STATUS_SUCCESS, SLICE_POSITION_INDEX} from "../../../../Constants";
import {getBoundarySlices} from "../../action/ViewerAction";

/**
 *  Component displaying position of slice within some range of allowed slices.
 * @param id - id of div rendered on page - required if other compnents are going to display tooltips or refer to this one
 * @param viewersState -
 * @param manualTool - manual tool from Store
 * @param images - imagePool
 * @return {*}
 */
export const SlicePositionWidget = ({id, viewersState, manualTool, images}) => {


  const image = images [viewersState["middle"]['imageId']];

  const viewerKey = (manualTool != null && manualTool['manualToolState'] && manualTool['manualToolState'][SLICE_POSITION_INDEX] != null && manualTool['manualToolState'][SLICE_POSITION_INDEX]['viewerKey'] != null)
    ? manualTool['manualToolState'][SLICE_POSITION_INDEX]['viewerKey']
    : "middle";

  const {minSlice, maxSlice} = (viewersState != null
    && viewersState[viewerKey] != null
    && image != null
    && image['state'] === REQUEST_STATUS_SUCCESS) ? getBoundarySlices(viewersState[viewerKey], image) : {
    minSlice: 0,
    maxSlice: 0
  };


  const numberOfVisibleSlices = Math.abs(maxSlice - minSlice) + 1;
  const positionIndicator =
    (viewersState != null && viewersState[viewerKey] != null)
      ? (maxSlice > minSlice) ? 1 + viewersState[viewerKey]["sliceNumber"] - minSlice : 1 + minSlice - viewersState[viewerKey]["sliceNumber"]
      : 0;


  return (
    <div id={id} style={{display: "inline-block", float: "right", verticalAlign: "bottom", fontSize: "2em"}}>
      {"Counter:"}{positionIndicator}{" /"} {numberOfVisibleSlices}</div>
  );
};


SlicePositionWidget.propTypes = {
  id: PropTypes.string.isRequired,
  viewersState: PropTypes.object.isRequired,
  images: PropTypes.object.isRequired,
  manualTool: PropTypes.object.isRequired
};
