import React from "react";
import YouTube from "react-youtube";
import workflow from "../resources/workflow.jpeg";
import { ContainerTabletGameExperiment } from "../../expDesign/containers/ContainerTabletGameExperiment";
import { ContainerManualToolDispatcher } from "../../visualization/containers/ContainerManualToolDispatcher";
import { ContainerSkillSummaryLP } from "../../visualization/containers/ContainerSkillSummaryLP";
import { ContainerDynamicHistogramDicePanel } from "../../expDesign/containers/ContainerDynamicHistogramDicePanel";

export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK = "MINIWORKFLOWTASK";
export const SLIDE_PROPERTY_NAME__SLIDE_TYPE = "slideType";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE = "IMAGE";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO = "VIDEO";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE = "WEBSITE";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT = "EXPERIMENT";
export const SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES = "slideProperties";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_RESULTS_DICE = "MINIWORKFLOWRESULTSDICE";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__SKILL_SUMMARY = "SKILLSUMMARY";

export const getRenderCurrentSlide = (currentSlide) => {
  const playerOpts = {
    height: "100%",
    width: "100%",
    playerVars: {
      rel: 0,
      cc_load_policy: 1,
      cc_lang_pref: "fr",
      mute: 0,
      autoplay: 1,
    },
  };

  const slide_style = {
    width: "inherit",
    height: "inherit",
    position: "relative",
    display: "block",
  };

  const slide_style_summary = {
    width: "inherit",
    height: "inherit",
    position: "relative",
    display: "block",
    backgroundColor: "white",
  };

  const slide_style_video = {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "block",
    overflowX: "hidden",
    overflowY: "hidden",
  };

  switch (currentSlide[SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase()) {
    case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE:
      return (
        <React.Fragment key={currentSlide.orderNumber}>
          <div style={slide_style} className="slide_div fade">
            <iframe is="x-frame-bypass" src={currentSlide.slideProperties.url} height="100%" width="100%" />
          </div>
        </React.Fragment>
      );

    case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO: {
      const onEnd =
        currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["onEndGoNext"] != null &&
        currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["onEndGoNext"]
          ? () => plusSlides(1)
          : () => {};
      let url = new URL(currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["url"]); // i am parsing url here, since i need 1 param only
      return (
        <React.Fragment key={currentSlide.orderNumber}>
          <div style={slide_style_video} className="slide_div fade">
            <YouTube
              videoId={url.searchParams.get("v")}
              onEnd={onEnd}
              opts={playerOpts}
              height="100%"
              width="100%"
              containerClassName="youtubeVideo"
            />
          </div>
        </React.Fragment>
      );
    }

    case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT:
      return (
        <React.Fragment key={currentSlide.orderNumber}>
          <div style={slide_style} className="slide_div fade">
            <ContainerTabletGameExperiment slideProperties={currentSlide["slideProperties"]} active={true} />
          </div>
        </React.Fragment>
      );

    case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE:
      return (
        <React.Fragment key={currentSlide.orderNumber}>
          <div style={slide_style} className="slide_div fade">
            <img src={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["url"]} height="100%" width="100%" />
          </div>
        </React.Fragment>
      );

    case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_TASK:
      return (
        <React.Fragment key={currentSlide.orderNumber}>
          <div style={slide_style} className="slide_div fade">
            <ContainerManualToolDispatcher
              materializedTaskId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["taskId"]}
              livePresenterExperimentId={
                currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["livePresenterExperimentId"]
              }
              miniWorkflowSetId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["miniWorkflowSetId"]}
              miniWorkflowNameInSet={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["miniWorkflowNameInSet"]}
              taskNameInMiniWorkflow={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["taskNameInMiniWorkflow"]}
              currentStep={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["currentStep"]}
              onNext={plusSlides}
            />
          </div>
        </React.Fragment>
      );

    case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__MINI_WORKFLOW_RESULTS_DICE:
      return (
        <React.Fragment key={currentSlide.orderNumber}>
          <div style={slide_style} className="slide_div fade">
            <ContainerDynamicHistogramDicePanel
              livePresenterExperimentId={
                currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["livePresenterExperimentId"]
              }
            />
          </div>
        </React.Fragment>
      );
    case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__SKILL_SUMMARY:
      return (
        <React.Fragment key={currentSlide.orderNumber}>
          <div style={slide_style_summary} className="slide_div fade">
            <ContainerSkillSummaryLP
              urlFailure={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["urlFailure"]}
              urlSuccess={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["urlSuccess"]}
              livePresenterExperimentId={
                currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["livePresenterExperimentId"]
              }
              mfKey={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["miniWorkflowNameInSet"]}
              miniWorkflowSetId={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["miniWorkflowSetId"]}
              goToFirstSlide={() => {
                setSlideIndex(0);
              }}
            />
          </div>
        </React.Fragment>
      );
  }
};

export const getThumbnails = (currentSlide) => {
  const slide_style = {
    width: "inherit",
    height: "inherit",
    position: "relative",
    display: "block",
  };
  const playerOpts = {
    height: "100%",
    width: "100%",
  };
  const slide_style_video = {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "block",
    overflowX: "hidden",
    overflowY: "hidden",
    pointerEvents: "none",
  };

  if (currentSlide) {
    switch (currentSlide[SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase()) {
      case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE:
        return (
          <div style={slide_style} className="slide_div fade">
            <img src={currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["url"]} height="100%" width="100%" />
          </div>
        );

      case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO: {
        let url = new URL(currentSlide[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]["url"]);
        return (
          <div style={slide_style_video} className="slide_div fade">
            <YouTube
              videoId={url.searchParams.get("v")}
              opts={playerOpts}
              height="100%"
              width="100%"
              containerClassName="youtubeVideo"
            />
          </div>
        );
      }
      default:
        return (
          <div style={slide_style} className="slide_div fade">
            <img src={workflow} height="100%" width="100%" />
          </div>
        );
    }
  } else {
    return (
      <div style={slide_style} className="slide_div fade">
        <img src={workflow} height="100%" width="100%" />
      </div>
    );
  }
};
