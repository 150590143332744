import React from 'react'
import PropTypes from 'prop-types';
import {Route, Switch, withRouter} from 'react-router-dom'
import {Redirect} from 'react-router-dom'
import LayoutTopbar from '../root/LayoutTopbar'
import LeftNavigationBar from "../root/LeftNavigationBar";
import News from "../root/News";
import Tour from "../root/Tour";
import ProjectRouter from "../project/ProjectRouter";
import ExperimentChoiceView from "../project/component/ExperimentChoiceView";
import {ContainerTaskView} from "../visualization/containers/ContainerTaskView";
import DashboardRouter from "../dashboard/DashboardRouter";
import LivePresenterRouter from "../livePresenter/LivePresenterRouter";
import SingleResultReviewRouter from "../singleResultReview/SingleResultReviewRouter";
import SPMRouter from "../spm/SPMRouter";
import {ContainerManualToolDispatcher} from "../visualization/containers/ContainerManualToolDispatcher";
import DemosPage from "../visualization/component/annotationTool/DemosPage";
import EventsPage from "../event/component/EventsPage"
import SkillRouter from "../skills/SkillRouter";
import {ContainerAvatarScatterPlotPanel} from "../expDesign/containers/ContainerAvatarScatterPlotPanel";
import ContainerPersonalPage from "../root/containers/ContainerPersonalPage";
import ContainerPreferencesPage from "../root/containers/ContainerPreferencesPage";
import ContainerEventDiscoverBrighamPage, {ContainerEventNeuroAgoraPage} from "../event/containers/ContainerEventDiscoverBrighamPage";
import {Growl} from "primereact/components/growl/Growl";
import WorkflowManagerRouter from "../workflowManager/WorkflowManagerRouter";
import HelpEditor from "../helpEditor/component/HelpEditor";
import {ContainerAnnotationTableBuilder} from "../visualization/containers/ContainerAnnotationTableBuilder";
import Authorization from "../authentication/component/Authorization";
import {USER} from "../../Constants";
import {AnnotationBuilderMainPage} from "../visualization/component/annotationTool/AnnotationBuilderMainPage";
import {ContainerAnnotationColumnBuilder} from "../visualization/containers/ContainerAnnotationColumnBuilder";
import {ContainerAnnotationQuestionBuilder} from "../visualization/containers/ContainerAnnotationQuestionBuilder";
import {ContainerAnnotationOntologyBuilder} from "../visualization/containers/ContainerAnnotationOntologyBuilder";
import {ContainerManualToolBuilderMainPage} from "../manualToolBuilder/container/ContainerManualToolBuilderMainPage";
import {withTranslation} from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import NotFound from './NotFound';
import { ContainerEventsPage } from '../event/containers/ContainerEventsPage';
import { ContainerEventPage } from '../event/containers/ContainerEventPage';
import {ContainerMultiHistogramPanel} from '../expDesign/containers/ContainerMultiHistogramPanel';
import {ContainerMultiAgreementModelsPanel} from '../expDesign/containers/ContainerAgreementModelsPanel';
import {ContainerMultiAvatarScatterPlotPanel} from "../expDesign/containers/ContainerAvatarScatterPlotPanel";
import { ContainerUsers } from '../users/containers/ContainerUsers';
import {ContainerSAMPanel} from "../analysis/containers/ContainerSAMPanel";
import {ContainerCohortsPage} from "../cohort/containers/ContainerCohortsPage";
import {ContainerCohortDetails} from "../cohort/containers/ContainerCohortDetails";
import CouchQueryTool from "./CouchQueryTool";

/**
 *  Layout page of application. It contains:
 *   - Top toolbar,
 *   - left navigation toolbar
 *   - invisible Growl component for displaying messages from single message queue,
 *   - main content panel,
 *   - Switch for routing to different contents of main panel.
 *  In old version tabs were used instead of routing to different pages.
 *  Routing to experiment design pae has been moved to ProjectRouter.
 */
class Home extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {setupMsgQueue, getProjects, getSession} = this.props;
    getSession(
      ()=>{  // On success setup growl component and other parts of the redux store
        setupMsgQueue(this.growl);
        getProjects();
      },
      ()=>{  // On error (no session available) redirect to the RedirectLogin component passing the initial requested url
        const {history, location} = this.props;
        if(history.action === 'POP' && location.key!=null){
          history.go(-1);
        } else{
          history.push('/login', {redirect: location.pathname, redirectParams: location.search});
        }
      }
    );
  }

  /** Checks whether any of the roles assigned to User is in a given set of Roles
   * @param assignedRoles  roles already assigned to User
   * @param allowedRoles   roles required to have access to a given resource
   * @returns {boolean} true if
   */
  hasUserARole(assignedRoles, allowedRoles) {
    if (!(allowedRoles && allowedRoles.length))
      return false;
    return !!allowedRoles.some(r => assignedRoles.indexOf(r) >= 0);
  }


  render() {
    const activeRole = [this.props.auth.role_active];
    const loginStatus = [this.props.auth.login_status];

    const {match, location, history, appState, t} = this.props;


    if (loginStatus != 'logged') {  // do not ever change the operator
       return <div className="spinner-centered">Loading <i className="fa fa-spinner fa-spin"/></div>
    }
    const allRoles = ['scientific', 'contributor', 'administrator', 'admin']; //prospective feature - A&A can be handled here
    const onlyScientist = ['scientific'];

    return (
      <React.Fragment>
        <Growl baseZIndex={1001} ref={(el) => {
          this.growl = el;
        }}/>
        <LayoutTopbar match={match} location={location} history={history} appState={appState}/>
        <LeftNavigationBar/>
        <div className="mainContents">
          <Switch>
            <Route path='/home' component={DashboardRouter}/>
            <Route path='/news' component={News}/>
            <Route path='/tour' component={Tour}/>
            <Route path='/personal' component={ContainerPersonalPage}/>
            <Route path='/preferences' component={ContainerPreferencesPage}/>

            <Route path='/project' component={ProjectRouter} match={match}/>
            <Route path='/tool/:miniWorkflowKey/:miniWorkflowSetId/:experimentId'
                   component={ContainerManualToolDispatcher}/>
            <Route path='/expchoice' component={ExperimentChoiceView} match={match}/>
            <Route path='/skills' component={SkillRouter}/>
            <Route path='/livePresenter' component={LivePresenterRouter}/>

            <Route exact path='/demo' component={DemosPage}/>
            <Route exact path='/demo/:id' component={ContainerTaskView}/>


            <Route exact path='/event' component={ContainerEventsPage}/>
            <Route exact path='/event/discoverbrigham2019' component={ContainerEventDiscoverBrighamPage}/>
            <Route exact path='/event/neuroagora2019' component={ContainerEventNeuroAgoraPage}/>
            <Route exact path='/event/:eventId' component={ContainerEventPage}/>
            <Route exact path='/eventResults/:id'
                   render={(props) => <ContainerAvatarScatterPlotPanel {...props}
                   slideProperties={{experimentId: match.params.id}}/>}
            />
            <Route exact path='/eventResultsMultiplot/:id/histogram'
                  render={(props) => <ContainerMultiHistogramPanel {...props}
                  slideProperties={{experimentId: match.params.id}}/>}
            />
            <Route exact path='/eventResultsMultiplot/:id/avatar'
                  render={(props) => <ContainerMultiAvatarScatterPlotPanel {...props}
                  slideProperties={{experimentId: match.params.id}}/>}
            />
            {/* TODO: Add experimentProperties as in frontend/visualization/component/TaskView.js */}
            <Route exact path='/eventResultsMultiplot/:id/agreement'
                  render={(props) => {
                            const experimentProperties = {
                              experimentId: props.match.params.id,
                              lut_key: "656355c2-a771-4f1e-b35f-f800e66d7d57",
                              lutDescription_key: "5a1deda3d848809560e34de6694544fa"
                            };
                            return <ContainerMultiAgreementModelsPanel
                                      {...props}
                                      experimentProperties={experimentProperties}
                                      slideProperties={{experimentId: match.params.id}}/>}}
            />
            <Authorization roles={[USER.ROLES.ADMIN]}>
              <Switch>
                <Route path='/workflowManager' component={WorkflowManagerRouter} />
                <Route exact path='/manualToolBuilder' component={ContainerManualToolBuilderMainPage}/>
                <Route exact path='/annotationsBuilder' component={AnnotationBuilderMainPage}/>
                <Route exact path='/annotationsBuilder/tables' component={ContainerAnnotationTableBuilder}/>
                <Route exact path='/annotationsBuilder/columns' component={ContainerAnnotationColumnBuilder}/>
                <Route exact path='/annotationsBuilder/questions' component={ContainerAnnotationQuestionBuilder}/>
                <Route exact path='/annotationsBuilder/ontologies' component={ContainerAnnotationOntologyBuilder}/>
                <Route path='/helpEditor' component={HelpEditor}/>
                <Route exact path='/singleResultReview' component={SingleResultReviewRouter}/>
                <Route exact path='/spm' component={SPMRouter}/>
                <Route exact path='/users' component={ContainerUsers}/>
                <Route exact path='/cohorts' component={ContainerCohortsPage}/>
                <Route exact path='/cohorts/:cohortId' component={ContainerCohortDetails}/>
                <Route exact path='/couch' component={CouchQueryTool}/>
                <Route path='/*' component={NotFound}/>
              </Switch>
            </Authorization>
            <Route path='/*' component={NotFound}/>
          </Switch>
        </div>
        <CookieConsent
            location="bottom"
            buttonText={t("general.cookies.acceptbutton.label")}
            style={{opacity: '0.9'}}>
            {t("general.cookies.message.label")}
        </CookieConsent>
      </React.Fragment>
    )
  }
}

export default withTranslation()(Home);

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  setupMsgQueue: PropTypes.func
};
