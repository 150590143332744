import React from 'react'
import {connect} from "react-redux";
import {getTaskList} from "../expDesign/action/TaskAction";


/**
 * Temporarily used as testing page for various components.
 *
 */

 class News extends React.Component {


    componentDidMount(){
  // const {getTaskList} =this.props;
  // getTaskList('whatever');
   }
    render() {
        console.log("News ...render");
        return (
            <div  style={{margin: '16px'}}>

            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    console.log("tasks", state.expDesign.tasks.tasks);
    return {
        tasks: state.expDesign.tasks
        // experiment:state.expDesign.experiment
    }
};

/**
 *
 * @param dispatch
 * @param ownProps - call params functions
 * @returns {{getWorkflows: function()}}
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getTaskList:(workflowId)=>{dispatch(getTaskList(workflowId))}
    }
};


 export default News = connect( mapStateToProps, mapDispatchToProps)(News);