import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  copyPlotSettings,
  duplicatePlotSettings,
  onLayoutChange,
  onSelectedCellChange, refreshAllPlots, updateStateSAM
} from "../action/SAMAction";
import LayoutMultiPlotPanel from "../component/LayoutMultiPlotPanel";



const mapStateToProps = (state) => {
  return {
    selectedLayout:state.sam.selectedLayout,
    selectedCell:state.sam.selectedCell,
    fullState:state.sam,
    messageQueue: state.messaging.msgQueue
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeLayout:(layoutCell)=>{
      dispatch(onLayoutChange(layoutCell))
    },
    onChangeCell:(layoutCell)=>{
      dispatch(onSelectedCellChange(layoutCell))
    },
    copyPlotSettingsToClipboard:()=>{
      dispatch(copyPlotSettings())
    },
    duplicatePlotSettings:(allFlag)=>{
      dispatch(duplicatePlotSettings(allFlag))
    },
    updateState:(state)=>{
      dispatch(updateStateSAM(state))
    },
    refreshAllPlots:()=>{
      dispatch(refreshAllPlots())
    }
  }
};

export const ContainerLayoutMultiPlotPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(LayoutMultiPlotPanel));