import React from 'react';
import {Link} from "react-router-dom";
import LpItemListElement from "./lpItemListElement";
import LpItemCardElement from "./lpItemCardElement";
import {ItemList} from "../../project/component/ItemList";
import ListSwitchType from "../../root/ListTypeSwitch";

export class LP_landing_page extends React.Component {

    constructor(){
        super();
        this.state = {
            isList: false
        }
    }
    componentWillMount() {
        const {getPresentations} = this.props;
        getPresentations();
    }
    

    render() {
        const items = (this.props.presentations != null && this.props.presentations.length > 0)
         ? this.props.presentations.map(item => (
            <React.Fragment key = {item.uuid}>
                <div style={{width: '200px', border: 'solid', margin: '40px'}}>
                    <Link to={'/livePresenter/' + item.uuid}>
                            <h2>{item.name}</h2>
                    </Link>
                    <p>Status = {item.status}</p>
                    <Link to={'/livePresenter/play/' + item.uuid}>
                            <h2>Play</h2>
                    </Link>
                </div>
            </React.Fragment>
        )):null;
        
        // var add_prstn_modal = document.getElementById('add_prstn_modal');
        // var add_prstn_button = document.getElementById("add_prstn_button");
        // var add_prstn_span = document.getElementsByClassName("lp_modal_close")[0];
        
        // When the user clicks the button, open the lp_modal 
        function open_new_prstn_modal() {
            let add_prstn_modal = document.getElementById('add_prstn_modal');
            add_prstn_modal.style.display = "block";
        }
        
        // When the user clicks on <span> (x), close the lp_modal
        function close_new_prstn_modal() {
            let add_prstn_modal = document.getElementById('add_prstn_modal');
            add_prstn_modal.style.display = "none";
        }
        
        function submit_new_prstn(){
            const new_title = document.getElementById('new_prstn_title').value;
            alert(new_title);
            //Send request to backend
        }
        
        // When the user clicks anywhere outside of the lp_modal, close it
        window.onclick = function(event) {
            let add_prstn_modal = document.getElementById('add_prstn_modal');
            if (event.target === add_prstn_modal) {
            add_prstn_modal.style.display = "none";
            }
        };
        return (
            <React.Fragment>
                {/* <ListSwitchType clb={() => {this.setState({isList: this.state.isList})}} isList={this.state.isList}></ListSwitchType> */}
                {/* {items} */}
                <ItemList model = {this.props.presentations}
                            isList = {this.state.isList}
                            // classNames = {["ui-g-4","ui-g-2","ui-g-2","ui-g-2","ui-g-2"]}
                            listRenderer = {<LpItemListElement/>}
                            cardRenderer = {<LpItemCardElement/>}
                            headers = {["Name","Status","Edit","Play","Delete"]}/>
                {/*<button id="add_prstn_button" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={open_new_prstn_modal} style={{margin: '25px 45% 0px 45%'}}>New Presentation</button>*/}
                <div id="add_prstn_modal" className="lp_modal">
                    <div className="lp_modal_content">
                        <span className="lp_modal_close" onClick={close_new_prstn_modal}>&times;</span>
                        <p>Please enter the name of the presentation</p>
                        <input id="new_prstn_title"/>
                        <button id="add_prstn_submit_btn" className="ui-button ui-widget ui-state-default ui-corner-all" onClick={submit_new_prstn} style={{margin: "0px 0px 0px 5px"}}>Submit</button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};