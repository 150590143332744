
export const EXPERIMENT_SPINEQ_ENROLLMENTS_REQUEST = "EXPERIMENT_SPINEQ_ENROLLMENTS_REQUEST";
export const EXPERIMENT_SPINEQ_ENROLLMENTS_SUCCESS = "EXPERIMENT_SPINEQ_ENROLLMENTS_SUCCESS";
export const EXPERIMENT_SPINEQ_ENROLLMENTS_FAILURE = "EXPERIMENT_SPINEQ_ENROLLMENTS_FAILURE";


export const EXPERIMENT_SPINEQ_QUESTIONNAIRES_REQUEST = "EXPERIMENT_SPINEQ_QUESTIONNAIRES_REQUEST";
export const EXPERIMENT_SPINEQ_QUESTIONNAIRES_SUCCESS = "EXPERIMENT_SPINEQ_QUESTIONNAIRES_SUCCESS";
export const EXPERIMENT_SPINEQ_QUESTIONNAIRES_FAILURE = "EXPERIMENT_SPINEQ_QUESTIONNAIRES_FAILURE";


export const EXPERIMENT_SPINEQ_BACKUPS_REQUEST = "EXPERIMENT_SPINEQ_BACKUPS_REQUEST";
export const EXPERIMENT_SPINEQ_BACKUPS_SUCCESS = "EXPERIMENT_SPINEQ_BACKUPS_SUCCESS";
export const EXPERIMENT_SPINEQ_BACKUPS_FAILURE = "EXPERIMENT_SPINEQ_BACKUPS_FAILURE";

export const EXPERIMENT_SPINEQ_EVENT_LOGS_REQUEST = "EXPERIMENT_SPINEQ_EVENT_LOGS_REQUEST";
export const EXPERIMENT_SPINEQ_EVENT_LOGS_SUCCESS = "EXPERIMENT_SPINEQ_EVENT_LOGS_SUCCESS";
export const EXPERIMENT_SPINEQ_EVENT_LOGS_FAILURE = "EXPERIMENT_SPINEQ_EVENT_LOGS_FAILURE";

export const EXPERIMENT_SPINEQ_TIMELINE_REQUEST = "EXPERIMENT_SPINEQ_TIMELINE_REQUEST";
export const EXPERIMENT_SPINEQ_TIMELINE_SUCCESS = "EXPERIMENT_SPINEQ_TIMELINE_SUCCESS";
export const EXPERIMENT_SPINEQ_TIMELINE_FAILURE = "EXPERIMENT_SPINEQ_TIMELINE_FAILURE";

export const EXPERIMENT_SPINEQ_ENROLLMENTS_UPDATE = "EXPERIMENT_SPINEQ_ENROLLMENTS_UPDATE";