import {getNestedProp} from "../../helpers/expressions";

/**
 * Handles displaying errors in Growl.
 * @param err
 * @return {function(...[*]=)}
 */
export const displayCommunicationErrorMessage = (err) => {
  return (dispatch, getState)=> {
  const messageQueue = getState().messaging.msgQueue;
  const isWarning = getNestedProp(["body","warning"],err);
  messageQueue.show({
          sticky: false,
          life:10000,
          severity: isWarning?'warning':'error',
          summary: isWarning?'R Server Warning':'Error',
          detail:isWarning? getNestedProp(["body","message"],err):"Cannot connect to R server.\n Details:"+err.message
        });
  }
};