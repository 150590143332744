import axios from 'axios'
import {
  UPDATE_FORMS_STATE,
  UPDATE_SINGLE_FORM_PROPERTY,
  UPDATE_SINGLE_FORM_STATE
} from "./actionType";
import {getNested} from "../../helpers/comparators";
import {getNestedProp} from "../../helpers/expressions";

const updateSingleFormState = (form, id) => ({
  type: UPDATE_SINGLE_FORM_STATE,
  form,
  id
});
export const updateFormProperty = (id, property, value) => ({
  type: UPDATE_SINGLE_FORM_PROPERTY,
  id,
  property,
  value
});
export const updateFormsState = (forms) => ({
  type: UPDATE_FORMS_STATE,
  forms
});


const PATH_TO_GET_COLUMN_DEFINITION = "/api/annotation-table-column-definition/";

/**
 * Helper function to use binding between template and forms json configuration.
 * @param formBindings
 * @param originalKey
 * @return {string | undefined}
 */
const getFormKeyForTemplate = (formBindings, originalKey) => {
  return Object.keys(formBindings).find(key => formBindings[key] === originalKey);
};


/**
 *  Parses viewers configuration and updates viewers state in Store.
 */
export const initializeForms = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const formConfiguration = getNested(mTask, "miniWorkflow.currentTool.configuration.forms.renderForms");

    if (!(formConfiguration!=null)) return;

    const formBindings = mTask.miniWorkflow.currentTool.configuration.forms.layout.layoutOrder;
    const iSOF = {};    //initialStateOfForms
    Object.keys(formConfiguration).forEach((el) => {
      const key = getFormKeyForTemplate(formBindings, el);
      iSOF[key] = {
        id:key,
        annotationTableColumnDefinitionKey : formConfiguration[el].annotationTableColumnDefinitionKey
      };
   });
    dispatch(updateFormsState(iSOF));

    Object.values(iSOF).forEach(el=>{dispatch(parseAndLoadForm(mTask,el))}); // Load data in async mode

  }
};



/**
 * ActionCreator for updating only one property in formsState.
 * @returns {function(*)}
 */
export const clearFormsState = () => {
  return (dispatch) => {
    dispatch(updateFormsState({}));
  };
};


/**
 * Parses and loads inputs of form type
 * @param mTask
 * @param {object} element of form state
 */
const parseAndLoadForm = (mTask, element) => {
  return (dispatch,getState) => {
    const inputs = mTask.miniWorkflow.currentMaterializedTask.inputs;
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;

    const url = PATH_TO_GET_COLUMN_DEFINITION + getNestedProp([element.annotationTableColumnDefinitionKey,"value"],inputs);

    return axios.get(url).then(response => {
        console.log('FormsAction.js :: parseAndLoadForm ::  Before success');
        const form = Object.assign(response.data.definition,{id:element.id});
        dispatch(updateSingleFormState(form,element.id));
    }).catch(() => console.log("Error"));

  };
};


export const areFormsOutputValid = (manualTask, formsState)=>{
  let valid = true;
  let fieldCounter =0;
  let missingFieldsArray = [];
  let binding = getNested(manualTask,"miniWorkflow.currentTool.bindingFormsOutputsToToolOutputs");

  if (binding!=null && formsState !=null){
    for (const [key, value] of Object.entries(formsState)) {
      if (value.validation.required) {
        if (value.type === 'autocomplete') {
          if (!value['unknown']) { // first check if this is not the field with option 'I do not know' - if this is selected than it is validated
            if (Array.isArray(value.value) && value.value.length===0) { // check if not a list defined with use of array
              valid = false;
              fieldCounter = fieldCounter + 1;
              missingFieldsArray.push("Form " + value.id);
            }
            if (typeof value.value !== 'object' || !(value.value != null)) {
              valid = false;
              fieldCounter = fieldCounter + 1;
              missingFieldsArray.push("Form " + value.id);
            }
          }
        }
      }
    }
  }
  return {isValid:valid, missingFields:fieldCounter, missingFieldsArray:missingFieldsArray};
};