import React from "react";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import dataset from "../tests/leptomeningeal"
import {ContainerAgreementModelsPanel} from "../containers/ContainerAgreementModelsPanel";



const measurementOptions = [
    {label:"Total number of lesions per case",value:"Total"}
    ,{label:"Average number of lesions per case",value:"Average"}];

/**
 * Stateful component  for calculating  models related to agreement analysis.
 * @returns {*}
 */
class AgreementModel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            definition:null,
            data:null,
            measurement:null,
            measurements:null,
        };
        ["onChangeMeasurement","onChangeCIInterval","onChangePercentage","onChangeSS"].forEach(name => { this[name] = this[name].bind(this);});
    }
    componentDidMount() {
        this.setState({data:dataset.data,definition:dataset.definition});
    }

    onChangeMeasurement(e) {
        this.setState({measurement: e.value});
    }
    onChangePercentage(e) {
        if (!isNaN(Number(e.target.value)) && Number(e.target.value)<=100 && Number(e.target.value)>0)
            this.setState({percentage: e.target.value})
    }

    onChangeCIInterval(e) {
        if (!isNaN(Number(e.target.value))&& Number(e.target.value)>0 )
            this.setState({ciInterval: e.target.value})
    }
    onChangeSS(e) {
        if (!isNaN(Number(e.target.value)))
            this.setState({ss: e.target.value})
    }

    render() {
        // params: {
        //     workflowId: ,
        //     miniWorkflowSetId: ,
        //     experimentId: ,
        //     miniWorkflowKey: ,
        //     inputAnnotationTableDefinition_key1: ,
        //     inputAnnotationFormDefinition_key1: 
        // };
        // Default values for demo
        let experimentProperties = {
            inputAnnotationTableDefinition_key1: "7e0c58ae948decbb445f6c495e0042b6",
            inputAnnotationFormDefinition_key1: "b7fc97eb044d96113b87f38b2d000d93"
        };
        if (this.props.match) {
            experimentProperties = this.props.match.params;
        };
        
        return (
            <ContainerAgreementModelsPanel experimentProperties={experimentProperties}/>
        );
    }
}

export default AgreementModel