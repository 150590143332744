import Location from "../../jsonDocs/annotation/Location.json";
import Gadolinium from "../../jsonDocs/annotation/Gadolinium.json";
import Size from "../../jsonDocs/annotation/Size.json";

// HACK FOR BORDEAUX
export const spineFinalLabelsBordeaux = [

    {
        type: "simple-dropdown",
        name: "Certainty",
        options: ["Uncertain"],
        value: "Uncertain",
        placeholder: "Select a value"
    },
    {
        type: "simple-dropdown",
        name: "Lesion",
        options: ["Lesion", "Not lesion"],
        value: "",
        placeholder: "Select a value"
    },
    {
        type: "textbox",
        name: "Comments",
        value: "",
        placeholder: "Use this free text area to add comments"
    }

];

export const spineFinalLabels4 = [
    {
        type: "recursive-dropdown",
        name: "Location",
        value: null,// value:'Supra-Tentorial',
        options: {
            title: 'Root',
            value: 'Root',
            key: '0',
            iri: 'http://put/some/ref/to/ontology/here',
            children: [
                {
                    title: 'Supra-Tentorial',
                    value: 'Supra-Tentorial',
                    key: '1',
                    children: [
                        {
                            title: 'Deep-Grey-Nuclei',
                            value: 'Deep-Grey-Nuclei',
                            key: '1-1',
                            children: [
                                {
                                    title: "Left-Caudate",
                                    value: "Left-Caudate",
                                    key: '1-1-1',
                                    children: []
                                },
                                {
                                    title: "Right-Caudate",
                                    value: "Right-Caudate",
                                    key: '1-1-2',
                                    children: []
                                },
                                {
                                    title: "Left-Putamen",
                                    value: "Left-Putamen",
                                    key: '1-1-3',
                                    children: []
                                },
                                {
                                    title: "Right-Putamen",
                                    value: "Right-Putamen",
                                    key: '1-1-4',
                                    children: []
                                },
                                {
                                    title: "Left-Pallidum",
                                    value: "Left-Pallidum",
                                    key: '1-1-5',
                                    children: []
                                },
                                {
                                    title: "Right-Pallidum",
                                    value: "Right-Pallidum",
                                    key: '1-1-6',
                                    children: []
                                },
                                {
                                    title: "Left-Thalamus",
                                    value: "Left-Thalamus",
                                    key: '1-1-7',
                                    children: [
                                        {
                                            title: "Left-paraventricular-nu",
                                            value: "Left-paraventricular-nu",
                                            key: '1-1-7-1',
                                            children: []
                                        },
                                        {
                                            title: "Left-Anterior-Nuclei",
                                            value: "Left-Anterior-Nuclei",
                                            freesurfer: "Thalamus-Anterior",
                                            key: '1-1-7-2',
                                            children: []
                                        },
                                        {
                                            title: "Left-Medial-Nuclei",
                                            value: "Left-Medial-Nuclei",
                                            freesurfer: "Thalamus-Medial",
                                            key: '1-1-7-3',
                                            children: []
                                        },
                                        {
                                            title: "Thalamus-Ventral-Posterior-Lateral",
                                            value: "Thalamus-Ventral-Posterior-Lateral",
                                            freesurfer: "Thalamus-Ventral-Posterior-Lateral",
                                            key: '1-1-7-4',
                                            children: []
                                        },
                                        {
                                            title: "Left-Pulvinar",
                                            value: "Left-Pulvinar",
                                            freesurfer: "Thalamus-Pulvinar",
                                            key: '1-1-7-5',
                                            children: []
                                        }
                                    ]
                                },
                                {
                                    title: "Right-Thalamus",
                                    value: "Right-Thalamus",
                                    key: '1-1-8',
                                    children: [
                                        {
                                            title: "Right-paraventricular-nu",
                                            value: "Right-paraventricular-nu",
                                            key: '1-1-8-1',
                                            children: []
                                        },
                                        {
                                            title: "Right-Anterior-Nuclei",
                                            value: "Right-Anterior-Nuclei",
                                            freesurfer: "Thalamus-Anterior",
                                            key: '1-1-8-2',
                                            children: []
                                        },
                                        {
                                            title: "Right-Medial-Nuclei",
                                            value: "Right-Medial-Nuclei",
                                            freesurfer: "Thalamus-Medial",
                                            key: '1-1-8-3',
                                            children: []
                                        },
                                        {
                                            title: "Thalamus-Ventral-Posterior-Lateral",
                                            value: "Thalamus-Ventral-Posterior-Lateral",
                                            freesurfer: "Thalamus-Ventral-Posterior-Lateral",
                                            key: '1-1-8-4',
                                            children: []
                                        },
                                        {
                                            title: "Right-Pulvinar",
                                            value: "Right-Pulvinar",
                                            freesurfer: "Thalamus-Pulvinar",
                                            key: '1-1-8-5',
                                            children: []
                                        }]
                                },
                            ]
                        },//FInished here - finish me when time has come
                        {
                            title: 'Left-Cerebral-White-Matter',
                            value: 'Left-Cerebral-White-Matter',
                            key: '1-2',
                            children: []
                        },
                        {
                            title: 'Right-Cerebral-White-Matter',
                            value: 'Right-Cerebral-White-Matter',
                            key: '1-3',
                            children: []
                        },
                        {
                            title: 'Left-Gray-Matter',
                            value: 'Left-Gray-Matter',
                            key: '1-4',
                            children: []
                        },
                        {
                            title: 'Right-Gray-Matter',
                            value: 'Right-Gray-Matter',
                            key: '1-5',
                            children: []
                        },
                        {
                            title: 'Optic-Structures',
                            value: 'Optic-Structures',
                            key: '1-6',
                            children: []
                        }
                    ]
                },
                {
                    title: 'Infra-Tentorial',
                    value: 'Infra-Tentorial',
                    key: '2',
                    children: []
                },
                {
                    title: 'spinal cord',
                    value: 'spinal cord',
                    key: '3',
                    children: []
                },

            ]
        }
    },
    {
        type: "simple-dropdown",
        name: "T1-Signal",
        options: ["Hypointense", "Isointense", "Hyperintense"],
        value: null,
        placeholder: "Select value"
    },
    {
        type: "recursive-dropdown",
        name: "Gadolinium",
        options: {
            title: 'Root',
            value: 'Root',
            key: '0',
            iri: 'http://put/some/ref/to/ontology/here',
            children: [{
                title: 'Gadolinium enhacement',
                value: 'Gadolinium enhacement',
                key: '1',
                children: [
                    {
                        title: 'Large',
                        value: 'Large',
                        key: '1-1',
                    },
                    {
                        title: 'Thin',
                        value: 'Thin',
                        key: '1-2',
                    },
                    {
                        title: 'Extensif',
                        value: 'Extensif',
                        key: '1-3',
                    },
                    {
                        title: 'Cloud-Like',
                        value: 'Cloud-Like',
                        key: '1-4',
                    }, {
                        title: 'Ring-Enhancement',
                        value: 'Ring-Enhancement',
                        key: '1-5',
                    }, {
                        title: 'Punctiform',
                        value: 'Punctiform',
                        key: '1-6',
                    },
                    {
                        title: 'Nodular',
                        value: 'Nodular',
                        key: '1-7',
                    }
                ]
            },
                {
                    title: 'No-enhancement',
                    value: 'No-enhancement',
                    key: '2',
                    children: []
                }]
        },
        value: undefined

    },
    {
        type: "simple-dropdown",
        name: "Shape",
        options: ["Cavitation", "Dot-Like", "Patchy", "Extensif", "Tumefactive", "Diffuse", "Nodular", "Confluent", "Ovoid", "Spilled-Ink",
            "Gyriform", "Fusiform", "Linear", "Dawson's-Finger"],
        value: null,
        placeholder: "Select value"
    },
    {
        type: "simple-dropdown",
        name: "Lobar-Location",
        options: ["Frontal",
            "Temporal",
            "Parietal",
            "Occipital",
            "Insula",
            "Cyngulate-Gyrus"],
        value: null,
        placeholder: "Select value"
    },
    {
        type: "simple-dropdown",
        name: "Spinal location",
        options: ["Cervicobulbar",
            "Cervical",
            "Thoracic"],
        value: null,
        placeholder: "Select value"
    },
    {
        type: "simple-dropdown",
        name: "Size",
        options: ["Inferior-3mm",
            "Between-3mm-10mm",
            "Superior-10mm",
            ">=3 segments",
            "inferior to 3 segments"],
        value: null,
        placeholder: "Select value"
    },
    {
        type: "simple-dropdown",
        name: "Margins",
        options: ["well-defined",
            "Halo",
            "blurred-margins",
            "Evanescent"],
        value: null,
        placeholder: "Select value"
    },
    {
        type: "simple-dropdown",
        name: "trophycity",
        options: ["atrophy", "normal"],
        value: null,
        placeholder: "Select value"
    },
    {
        type: "textbox",
        name: "Comments",
        value: "",// value:"Comment",
        placeholder: "Use this free text area to add comments"
    }
];

/** Original schema for annotation table for NMO Project.
 * See
 * @link https://docs.google.com/spreadsheets/d/1kxh8YSZ6LeceScZsXvv3MD9RyUkp1qPR5h7LwFeKSno/edit?usp=sharing
 *
 */
export const spineLabelsForNMOProject = {
    "Column1": {
        type: "recursive-dropdown",
        name: "Location",
        value: null,// value:'Supra-Tentorial',
        options: Location,
        required: true
    },
    "Column2": {
        type: "simple-dropdown",
        name: "T1-Signal",
        options: ["Hypointense", "Isointense", "Hyperintense"],
        value: null,
        placeholder: "Select value"
    },
    "Column3": {
        type: "simple-dropdown",
        name: "T2-Signal",
        options: ["Hypointense", "Isointense", "Hyperintense"],
        value: null,
        placeholder: "Select value"
    },
    "Column4": {
        type: "recursive-dropdown",
        name: "Gadolinium",
        options: Gadolinium,
        value: undefined
    },
    "Column5": {
        type: "simple-dropdown",
        name: "Shape",
        options: ["nodular", "tumefactive", "confluent", "ovoid", "gyriform", "linear"],
        value: null,
        placeholder: "Select value"
    },
    "Column6": {
        type: "simple-dropdown",
        name: "Lobar-Location",
        options: ["see atlas",
            "Dawson finger",
            "periventricular",
            "juxtacortical"
        ],
        value: null,
        placeholder: "Select value"
    },
    "Column7": {
        type: "simple-dropdown",
        name: "Spinal location",
        options: ["medulla oblongata",
            "cervically",
            "thoracally",
            "medullary cone"
        ],
        value: null,
        placeholder: "Select value"
    },
    "Column8": {
        type: "recursive-dropdown",
        name: "Size",
        options: Size,
        value: null,
        placeholder: "Select value"
    },
    "Column9": {
        type: "simple-dropdown",
        name: "Margins",
        options: ["sharply delineated",
            "blury edges",
            "\"Halo\"",
            "fading"],
        value: null,
        placeholder: "Select value"
    },
    "Column10": {
        type: "simple-dropdown",
        name: "trophycity",
        options: ["space occupying ", "atrophic", "non"],
        value: null,
        placeholder: "Select value"
    },
    "Column11": {
        type: "textbox",
        name: "Comments",
        value: "",// value:"Comment",
        placeholder: "Use this free text area to add comments"
    }
};


const finalLabels = {
    "attributes": {
        "column": [
            {
                "-name": "Location",
                "values": {
                    "menu": [
                        {
                            "-name": "Supra-Tentorial",
                            "values": {
                                "menu": [
                                    {
                                        "-name": "Deep-Grey-Nuclei",
                                        "values": {
                                            "value": [
                                                "Left-Caudate",
                                                "Right-Caudate",
                                                "Left-Putamen",
                                                "Right-Putamen",
                                                "Left-Pallidum",
                                                "Right-Pallidum"
                                            ],
                                            "menu": [
                                                {
                                                    "-name": "Left-Thalamus",
                                                    "values": {
                                                        "value": [
                                                            "Left-paraventricular-nu",
                                                            {
                                                                "-freesurfer": "Thalamus-Anterior",
                                                                "#text": "Left-Anterior-Nuclei"
                                                            },
                                                            {
                                                                "-freesurfer": "Thalamus-Medial",
                                                                "#text": "Left-Medial-Nuclei"
                                                            },
                                                            {
                                                                "-freesurfer": "Thalamus-Ventral-Posterior-Lateral",
                                                                "#text": "Thalamus-Ventral-Posterior-Lateral"
                                                            },
                                                            {
                                                                "-freesurfer": "Thalamus-Pulvinar",
                                                                "#text": "Left-Pulvinar"
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    "-name": "Right-Thalamus",
                                                    "values": {
                                                        "value": [
                                                            "Right-paraventricular-nu",
                                                            {
                                                                "-freesurfer": "Thalamus-Anterior",
                                                                "#text": "Right-Anterior-Nuclei"
                                                            },
                                                            {
                                                                "-freesurfer": "Thalamus-Medial",
                                                                "#text": "Right-Medial-Nuclei"
                                                            },
                                                            {
                                                                "-freesurfer": "Thalamus-Ventra-Posterior-Lateral",
                                                                "#text": "Thalamus-Ventra-Posterior-Lateral"
                                                            },
                                                            {
                                                                "-freesurfer": "Thalamus-Pulvinar",
                                                                "#text": "Right-Pulvinar"
                                                            }
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "-name": "Left-Cerebral-White-Matter",
                                        "values": {
                                            "value": [
                                                "Left-juxtacortical",
                                                "Left-subcortical",
                                                "Left-deep-white-matter",
                                                "Left-Corticospinal-Tracts",
                                                "Left-IntCapsule-Pos",
                                                "Left-External-Capsule",
                                                "Fornix",
                                                {
                                                    "-freesurfer": "Thalamus-Lateral-Geniculate",
                                                    "#text": "Left-lateral-geniculate"
                                                },
                                                "Left-Hypothalamus",
                                                "Left-Bed-Nucleus-of-stria-terminalis",
                                                "Left-Mamillo-Thalamic-Tract",
                                                "Left-Mamillary-Nu",
                                                "WM-LH-Parahippocampal",
                                                "Left-Fimbria"
                                            ],
                                            "menu": [
                                                {
                                                    "-name": "Left-periventricular",
                                                    "values": {
                                                        "value": [
                                                            "Left-Lateral-Ventricle(Periventricular)",
                                                            "Left-3rd-Ventricle(Periventricular)"
                                                        ]
                                                    }
                                                },
                                                {
                                                    "-name": "Left-Periependymal",
                                                    "values": {
                                                        "value": [
                                                            "Left-Lateral-Ventricle(Periependymal)",
                                                            "Left-3rd-Ventricle(Periependymal)"
                                                        ]
                                                    }
                                                },
                                                {
                                                    "-name": "Corpus_Callosum",
                                                    "values": {
                                                        "value": [
                                                            "splenium of Corpus Callosum",
                                                            "Trunk of Corpus Callosum",
                                                            "Genu of Corpus Callosum",
                                                            "Rostrum of Corpus Callosum"
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "-name": "Right-Cerebral-White-Matter",
                                        "values": {
                                            "value": [
                                                "Right-juxtacortical",
                                                "Right-subcortical",
                                                "Right-deep-white-matter",
                                                "Right-Corticospinal-Tracts",
                                                "Right-IntCapsule-Pos",
                                                "Right-External-Capsule",
                                                "Fornix",
                                                "Right-Hypothalamus",
                                                "Right-Bed-Nucleus-of-stria-terminalis",
                                                "Right-Mamillo-Thalamic-Tract",
                                                "Right-Mamillary-Nu",
                                                {
                                                    "-freesurfer": "Thalamus-Lateral-Geniculate",
                                                    "#text": "Right-lateral-geniculate"
                                                },
                                                "WM-RH-Parahippocampal",
                                                "Right-Fimbria"
                                            ],
                                            "menu": [
                                                {
                                                    "-name": "Right-periventricular",
                                                    "values": {
                                                        "value": [
                                                            "Right-Lateral-Ventricle(Periventricular)",
                                                            "Right-3rd-Ventricle(Periventricular)"
                                                        ]
                                                    }
                                                },
                                                {
                                                    "-name": "Right-Periependymal",
                                                    "values": {
                                                        "value": [
                                                            "Right-Lateral-Ventricle(Periependymal)",
                                                            "Right-3rd-Ventricle(Periependymal)"
                                                        ]
                                                    }
                                                },
                                                {
                                                    "-name": "Corpus_Callosum",
                                                    "values": {
                                                        "value": [
                                                            "splenium of Corpus Callosum",
                                                            "Trunk of Corpus Callosum",
                                                            "Genu of Corpus Callosum",
                                                            "Rostrum of Corpus Callosum"
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "-name": "Left-Gray-Matter",
                                        "values": {
                                            "menu": {
                                                "-name": "Left-Cerebral-Cortex",
                                                "values": {
                                                    "value": [
                                                        "Left-Piriform-Cortex",
                                                        "Left-Entorhinal-Cortex",
                                                        "Left-Cerebral-Cortex"
                                                    ]
                                                }
                                            },
                                            "value": [
                                                "Left-Amygdala",
                                                "Left-Hippocampus",
                                                "GM-LH-Parahippocampal"
                                            ]
                                        }
                                    },
                                    {
                                        "-name": "Right-Gray-Matter",
                                        "values": {
                                            "menu": {
                                                "-name": "Right-Cerebral-Cortex",
                                                "values": {
                                                    "value": [
                                                        "Right-Piriform-Cortex",
                                                        "Right-Entorhinal-Cortex",
                                                        "Right-Cerebral-Cortex"
                                                    ]
                                                }
                                            },
                                            "value": [
                                                "Right-Amygdala",
                                                "Right-Hippocampus",
                                                "GM-RH-Parahippocampal"
                                            ]
                                        }
                                    },
                                    {
                                        "-name": "Optic-Structures",
                                        "values": {
                                            "menu": [
                                                {
                                                    "-name": "Left-Optical-Nerve",
                                                    "values": {
                                                        "value": [
                                                            "Anterior-half(left optical nerve)",
                                                            "Posterior-half(left optical nerve)"
                                                        ]
                                                    }
                                                },
                                                {
                                                    "-name": "Right-Optical-Nerve",
                                                    "values": {
                                                        "value": [
                                                            "Anterior-half(right optical nerve)",
                                                            "Posterior-half(right optical nerve)"
                                                        ]
                                                    }
                                                }
                                            ],
                                            "value": [
                                                "Optic-Chiasm",
                                                "Left-Optic-Tract",
                                                "Right-Optic-Tract",
                                                "Left-Occipital-Optic-Radiation-End",
                                                "Right-Occipital-Optic-Radiation-End"
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "-name": "Infra-Tentorial",
                            "values": {
                                "menu": [
                                    {
                                        "-name": "Brainstem",
                                        "values": {
                                            "menu": [
                                                {
                                                    "-name": "Midbrain",
                                                    "values": {
                                                        "menu": [
                                                            {
                                                                "-name": "Tectum",
                                                                "values": {
                                                                    "value": [
                                                                        "Periacqueducal-Area",
                                                                        "Brainstem-Inferior-Colliculus",
                                                                        "Brainstem-Superior-Colliculus"
                                                                    ]
                                                                }
                                                            },
                                                            {
                                                                "-name": "Tegmentum",
                                                                "values": {
                                                                    "value": [
                                                                        "Right-Substancia-Nigra",
                                                                        "Left-Substancia-Nigra",
                                                                        "Left-Red-Nucleus",
                                                                        "Right-Red-Nucleus",
                                                                        "Occulomotor-Nu",
                                                                        "Midbrain-Reticular-Formation"
                                                                    ]
                                                                }
                                                            },
                                                            {
                                                                "-name": "4th-Ventricle",
                                                                "values": {
                                                                    "value": [
                                                                        "Periventricular(Midbrain)",
                                                                        "Periependymal(Midbrain)"
                                                                    ]
                                                                }
                                                            }
                                                        ],
                                                        "value": [
                                                            "Left-Cerebral-Peduncle",
                                                            "Right-Cerebral-Peduncle",
                                                            "Midbrain-Raphe-Nuclear-Complex"
                                                        ]
                                                    }
                                                },
                                                {
                                                    "-name": "Pons",
                                                    "values": {
                                                        "value": [
                                                            "Pontine-Nuclei",
                                                            "Pons-Corticospinal-Tracts",
                                                            "Reticulotegmental-Nuclei-Of-Pons"
                                                        ],
                                                        "menu": [
                                                            {
                                                                "-name": "Pons-Tegmentum",
                                                                "values": {
                                                                    "value": [
                                                                        "Raphe-Nucleus-of-Pons",
                                                                        "Reticular-formation-of-Pons"
                                                                    ]
                                                                }
                                                            },
                                                            {
                                                                "-name": "4th-Ventricule",
                                                                "values": {
                                                                    "value": [
                                                                        "Periventricular(Pons)",
                                                                        "Periependymal(Pons)"
                                                                    ]
                                                                }
                                                            }
                                                        ]
                                                    }
                                                },
                                                {
                                                    "-name": "Medulla-Oblongata",
                                                    "values": {
                                                        "menu": {
                                                            "-name": "Dorsal Medulla",
                                                            "values": {
                                                                "value": [
                                                                    "Reticular-Formation",
                                                                    "Area-Postrema"
                                                                ]
                                                            }
                                                        },
                                                        "value": [
                                                            "Left-Bulbar-Olives",
                                                            "Right-Bulbar-Olives",
                                                            "Left-Pyramidal-Fibers",
                                                            "Right-Pyramidal-Fibers"
                                                        ]
                                                    }
                                                }
                                            ]
                                        }
                                    },
                                    {
                                        "-name": "Cerebellum",
                                        "values": {
                                            "value": [
                                                "Vermis",
                                                "Left-Cerebellum-White-Matter",
                                                "Left-Cerbellum-Cortex",
                                                "Right-Cerebellum-White-Matter",
                                                "Right-Cerebellum-Cortex",
                                                "Left-Superior-Cerebellar-Peduncle",
                                                "Right-Superior-Cerebellar-Peduncle",
                                                "Left-Middle-Cerebellar-Peduncle",
                                                "Right-Middle-Cerebellar-Peduncle",
                                                "Left-Inferior-Cerebellar-Peduncle",
                                                "Right-Inferior-Cerebellar-Peduncle"
                                            ]
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            "-name": "spinal cord",
                            "values": {
                                "value": [
                                    "holocord",
                                    "central cord"
                                ],
                                "menu": {
                                    "-name": "peripheral cord",
                                    "values": {
                                        "value": [
                                            "left posterior column",
                                            "right posterior column",
                                            "left corticospinal tracts",
                                            "right corticospinal tracts"
                                        ]
                                    }
                                }
                            }
                        }
                    ]
                }
            },
            {
                "-name": "T1-Signal",
                "values": {
                    "value": [
                        "Hypointense",
                        "Isointense",
                        "Hyperintense"
                    ]
                }
            },
            {
                "-name": "T2-signal",
                "values": {
                    "value": [
                        "Hypointense",
                        "Hyperintense",
                        "Isointense"
                    ]
                }
            },
            {
                "-name": "Gadolinium",
                "values": {
                    "menu": {
                        "-name": "Gadolinium enhacement",
                        "values": {
                            "value": [
                                "Large",
                                "Thin",
                                "Extensif",
                                "Cloud-Like",
                                "Ring-Enhancement",
                                "Punctiform",
                                "Nodular"
                            ]
                        }
                    },
                    "value": "No-enhancement"
                }
            },
            {
                "-name": "Shape",
                "values": {
                    "value": [
                        "Cavitation",
                        "Dot-Like",
                        "Patchy",
                        "Extensif",
                        "Tumefactive",
                        "Diffuse",
                        "Nodular",
                        "Confluent",
                        "Ovoid",
                        "Spilled-Ink",
                        "Gyriform",
                        "Fusiform",
                        "Linear",
                        "Dawson's-Finger"
                    ]
                }
            },
            {
                "-name": "Lobar-Location",
                "values": {
                    "value": [
                        "Frontal",
                        "Temporal",
                        "Parietal",
                        "Occipital",
                        "Insula",
                        "Cyngulate-Gyrus"
                    ]
                }
            },
            {
                "-name": "spinal location",
                "values": {
                    "value": [
                        "Cervicobulbar",
                        "cervical",
                        "dorsal"
                    ]
                }
            },
            {
                "-name": "Size",
                "values": {
                    "value": [
                        "Inferior-3mm",
                        "Between-3mm-10mm",
                        "Superior-10mm",
                        ">=3 segments",
                        "inferior to 3 segments"
                    ]
                }
            },
            {
                "-name": "margins",
                "values": {
                    "value": [
                        "well-defined",
                        "Halo",
                        "blurred-margins",
                        "Evanescent"
                    ]
                }
            },
            {
                "-name": "trophycity",
                "values": {
                    "value": [
                        "atrophy",
                        "normal"
                    ]
                }
            }
        ]
    }
};
