import {
  getSelectedExtendedModelParameters,
  getSelectedPlotData,
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import NetworkService from "../../helpers/io/NetworkService";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {UPDATE_EXTENDED_MODEL_PROPERTY_SAM, UPDATE_EXTENDED_MODEL_PROPERTY_STATE_SAM,} from "./actionType";
import {getSeriesContributions} from "../selectors/ContributionsSelectors";
import {displayCommunicationErrorMessage} from "./errorHandler";
import {getSeriesObservations} from "../selectors/ObservationsSelectors";


// ----------------------------ACTIONS--------------------------------------------------

export const  updateExtendedModelPropertySAM= (property,value) => ({
  type: UPDATE_EXTENDED_MODEL_PROPERTY_SAM,
  property,
  value
});

export const  updateExtendedModelPropertyStateSAM= (property,state) => ({
  type: UPDATE_EXTENDED_MODEL_PROPERTY_STATE_SAM,
  property,
  state
});


/**
 * Get ICC model for Bland Altman Agreement Analysis calculated in R (StudyList).

 * @returns {function(*)} change state of Store
 */
export const calculateModelForICC = () => {
  return (dispatch, getState) => {
    const model = Object.assign({}, getSelectedExtendedModelParameters(getState()).ICCParameters);
    const plotData = getSelectedPlotData(getState());

    if(model['ratings']==="group1"){
      model['data'] = plotData.map(el=>el.contributionsX.map(c=>c.value));
    }
    if(model['ratings']==="group2"){
      model['data'] = plotData.map(el=>el.contributionsY.map(c=>c.value));
    }
    if(model['ratings']==="means"){
      model['data'] = plotData.map(el=>[el.group1,el.group2]);
    }
    if(model['ratings']==="all"){
      model['data'] = plotData.map(el=>el.contributionsX.map(c=>c.value).concat(el.contributionsY.map(c=>c.value)));
    }

    dispatch(updateExtendedModelPropertySAM("ICCOutcomeState",REQUEST_STATUS_REQUESTED));
    console.log('SAM Action :: calculateModelForICC :: Before axios requres');
    NetworkService.getICCModel(model)
      .then(outData => {
        console.log('SAM Action :: calculateModelForICC :: response', outData);
        dispatch(updateExtendedModelPropertySAM("ICCOutcome",outData));
        dispatch(updateExtendedModelPropertySAM("ICCOutcomeState",REQUEST_STATUS_SUCCESS));
      })
      .catch(err => {
        dispatch(updateExtendedModelPropertySAM("ICCOutcomeState",REQUEST_STATUS_FAIL));
        dispatch(updateExtendedModelPropertySAM("ICCOutcome",{}));
        dispatch(updateExtendedModelPropertySAM("ICCOutcomeError",err));
        dispatch(displayCommunicationErrorMessage(err));
      });
  }
};

// {
//   "subjects": "5",
//   "raters": "2",
//   "model": "twoway",
//   "type": "agreement",
//   "unit": "single",
//   "icc.name": "ICC(A,1)",
//   "value": "0.777777777777778",
//   "r0": "1",
//   "Fvalue": "0",
//   "df1": "4",
//   "df2": "4.78622478825951",
//   "p.value": "1",
//   "conf.level": "0.95",
//   "lbound": "-0.0197637269413956",
//   "ubound": "0.974014790722964"
// }

// To REMOVE
//
// function createTransferObjectToRServerIRR(){
//   let transferObject = {
//   "data":[[123, 122],[123,124],[125,127],[122,122],[123,123]],
//   "model":"twoway",
//   "type":"agreement",
//   "r0":1,
//    "unit":"single",
//     "conf.level":0.93
//   };
//   return transferObject;
// }
//

/**
 * Get Kappa model for Bland Altman Agreement Analysis calculated in R (StudyList).
 * @param transferObject - data to send (must contain x,y,l params)
 * @returns {function(*)} change state of Store
 */
export const calculateModelForKappa = (transferObject) => {
  return (dispatch, getState) => {
    const model = Object.assign({}, getSelectedExtendedModelParameters(getState()).kappaParameters);

    const plotData = getSelectedPlotData(getState());

    // Kappa is for 2 raters only, so data are taken from group means
      model['data'] = plotData.map(el=>[el.group1,el.group2]);


    dispatch(updateExtendedModelPropertySAM("kappaOutcomeState",REQUEST_STATUS_REQUESTED));
    console.log('SAM Action :: calculateModelForkappa :: Before axios requres');
    NetworkService.getKappaModel(model)
      .then(outData => {
        console.log('SAM Action :: calculateModelForKappa :: response', outData);
        dispatch(updateExtendedModelPropertySAM("kappaOutcome",outData));
        dispatch(updateExtendedModelPropertySAM("kappaOutcomeState",REQUEST_STATUS_SUCCESS));
      })
      .catch(err => {
        dispatch(updateExtendedModelPropertySAM("kappaOutcomeState",REQUEST_STATUS_FAIL));
        dispatch(updateExtendedModelPropertySAM("kappaOutcome",{}));
        dispatch(updateExtendedModelPropertySAM("kappaOutcomeError",err));
        dispatch(displayCommunicationErrorMessage(err))
      });
  }
};


/**
 * Getting distribution stats.
 * This is modification of experiment design solution
 * getStatisticsActionCreator:: "../../expDesign/action/DataSelectionAction"
 */
export const calculateDistributionStatistics = () => {
  return (dispatch,getState) => {
    const measurements = getSelectedPlotSettings(getState()).selectedMeasurementConfiguration;
    const series = getSeriesObservations(getState());


    // const transferObject = {"x":[
    //   [1, 0,123,124,125,127,122,122,123,123],
    //   [1, 0,123,124,125,120,122,122,123,123]
    // ]
    // };

    const dataArray = measurements
      .map(el=>
        series
          .map(sel=>sel.contributions)
          .flat()// create 2d array of values
          .filter(sel=>sel.measurementConfigurationId === el.id)
          .map(sel=>sel.value)  // create 2d array of values
      ).reduce((prev, next) => next.map((item, i) =>
      (prev[i] || []).concat(next[i])
    ), []); //reduce is used for transposition of array matrix

    const transferObject = {"x":dataArray};
    // const transferObject = {"x":[
    //   [1, 0,123,124,125,127,122,122,123,123],
    //   [1, 0,123,124,125,120,122,122,123,123]
    // ]
    // };
    dispatch(updateExtendedModelPropertySAM("psychOutcomeState",REQUEST_STATUS_REQUESTED));

    // transferObject should be object with property x and 2-d array with m*n size where m - number of samples per variable, n - number of variables
    // eg. {"x":[[1, 0, 123], [1, 0, 123], [1, 0, 123], [1, 0, 123],[1, 0, 123]] -  3 vars with 5 samples per each
    NetworkService.getStats(transferObject)
      .then(data => {
        dispatch(updateExtendedModelPropertySAM("psychOutcome",data));
        dispatch(updateExtendedModelPropertySAM("psychOutcomeState",REQUEST_STATUS_SUCCESS));
      })
      .catch(error => {
        dispatch(updateExtendedModelPropertySAM("psychOutcomeState",REQUEST_STATUS_FAIL));
        dispatch(updateExtendedModelPropertySAM("psychOutcome",{}));
        dispatch(updateExtendedModelPropertySAM("psychOutcomeError",error));
        dispatch(displayCommunicationErrorMessage(error));
      });
  }
};