import React from "react";
import PropTypes from 'prop-types';
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {MAIN_TOOL, REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import {Growl} from "primereact/components/growl/Growl";
import {setAcknowledgeSignal} from "../../../dashboard/action/ManualTaskAction";
import {withTranslation} from "react-i18next";
import {toolOutputValid} from "./ManualToolOutputValidator";
import {ContainerTaskControlPanel} from "../../containers/ContainerTaskControlPanel";
import {SplitButton} from "primereact/components/splitbutton/SplitButton";
import {getNestedProp} from "../../../helpers/expressions";


const CONFIRMATION_DIALOG_MESSAGES = ["Beware: you are trying to go to the previous step.\n" +
"Are you sure that you want to remove all the work previously done?",
    "Beware: after case is submitted, you will not be able to change results.\n" +
    "Are you sure you want to submit the case as it is?",
];

/**
 * Panel with control components for task/miniworkflow task.
 * @returns {*}
 */
class TaskControlPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmationDialogVisible: false,
            confirmationDialogMessage: "",
            action:null
        };
        ["onNextTask", "onPreviousTask", "onBackClick","onForwardClick","validate"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }
    static isLivePresenterMode (match){
        return match.path.includes("playskill");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {ackSignalState,nack} = this.props;

        if (prevProps.ackSignalState!==ackSignalState && ackSignalState === REQUEST_STATUS_FAIL) {
            let detail = getNestedProp(["response","data","message"], nack);
            if (!(detail!=null))
                detail = (nack != null && nack.message != null) ? nack.message : "Connection problem";
            this.growl.show({
                severity: 'error',
                summary: 'Please Try Again.',
                detail: detail
            });
        }
    }

    validate(){
        const {manualTask,  manualToolState,annotationsData,annotationsDefinition,annotationsFormData,annotationsFormDefinition, formsState} = this.props;
        return toolOutputValid(manualTask,manualToolState,annotationsData,annotationsDefinition,annotationsFormData,annotationsFormDefinition,formsState);
    }
    /**
     * Handler to forward button.
     */
    onNextTask(){
        const {saveOutputAndSubmit, match,  onNextSkillsStep} = this.props;
        const {experimentId, miniWorkflowKey, miniWorkflowSetId} = match['params'];
        saveOutputAndSubmit(TaskControlPanel.isLivePresenterMode(match), experimentId, miniWorkflowKey, miniWorkflowSetId, onNextSkillsStep);

    };

    /**
     * Handler to back button.
     * If this is skill (livePresenter) mode then
     * calls
     * otherwise
     * calls ManualToolAction::goToPreviousStep.
     *
     */

    onPreviousTask(){
        const {goToPreviousStep, match,onPreviousSkillsStep, currentStep, skillCurrentStep} = this.props;
        if(currentStep>0)
            goToPreviousStep();
        else
            if (skillCurrentStep!=null && skillCurrentStep>0)
                onPreviousSkillsStep();
    };


    onBackClick(){
        const {manualTask,  manualToolState} = this.props;

        const backwardDialog = getNestedProp(['miniWorkflow','currentMaterializedTask','navigationRules','backwardConfirmationWindow'],manualTask);
        const backwardDialogMessage = getNestedProp(['miniWorkflow','currentMaterializedTask','navigationRules','backwardConfirmationWindowMessage'],manualTask);

        if (backwardDialog) {
            this.setState({
                confirmationDialogVisible: true,
                confirmationDialogMessage:backwardDialogMessage!=null?backwardDialogMessage:CONFIRMATION_DIALOG_MESSAGES[0],
                action:this.onPreviousTask
            })
        }
        else
            this.onPreviousTask();
    }
    onForwardClick(){
        const {manualTask, t} = this.props;
        const {isValid,validationMessage}=this.validate();

        const forwardDialog = getNestedProp(['miniWorkflow','currentMaterializedTask','navigationRules','forwardConfirmationWindow'],manualTask);
        const forwardDialogMessage = getNestedProp(['miniWorkflow','currentMaterializedTask','navigationRules','forwardConfirmationWindowMessage'],manualTask);

        if (isValid) {
            if (forwardDialog)
                this.setState({
                    confirmationDialogVisible: true,
                    confirmationDialogMessage:forwardDialogMessage!=null?forwardDialogMessage:CONFIRMATION_DIALOG_MESSAGES[1],
                    action:this.onNextTask
                });
            else
                this.onNextTask();
        }
        else
            this.growl.show({
                severity: 'error',
                sticky:true,
                summary: t("messages.info.header"),
                detail: validationMessage})

    }

    render() {
        const {currentStep, ackSignalState, manualTask,
            skillCurrentStep,onHelpButtonClick, manualToolState,
            videoButtonOn,helpButtonOn, t} = this.props;
        const disabled = ackSignalState === REQUEST_STATUS_REQUESTED;

        const navigationModeLabel = manualToolState[MAIN_TOOL]['navigationModeLabel']==="case"?"step":"case"; //According to Charles request, to change it: use navigationModeLabel

        const {isValid}=this.validate();

        const spinnerIcon =  <i className="fa fa-spinner fa-spin"
                                style={{marginLeft: "1em", fontSize: "xx-large", position: "fixed", right: "150px", top:"10px", color: "#FFFFFF",zIndex:"1001"}}/>;
        const backButton = (<a style={{fontSize: "x-large"}}>
            <i className="fa fa-play fa-flip-horizontal topbarBackwardButton"  onClick={this.onBackClick}/>
            <div className="topbarBackwardButton" style={{fontSize:"xx-small",top:"35px"}}> {"step"} </div>
        </a>);
        const forwardClasses = "fa fa-play topbarForwardButton".concat((!isValid)?" topbarForwardButtonInvalid":"");
        const forwardButton = (<a style={{fontSize: "x-large"}}>
            <i className={forwardClasses}
               onClick={this.onForwardClick}/>
            <div className="topbarForwardButton" style={{fontSize:"xx-small",top:"35px"}}> {navigationModeLabel} </div>
        </a>);

        const helpClasses = "fa fa-question-circle-o taskToolHelpButton".concat((helpButtonOn)?" toggledOn":"");
        const videoClasses = "fa fa-film taskToolVideoButton".concat((videoButtonOn)?" toggledOn":"");

        const helpButton = (manualTask!=null
            && manualTask['miniWorkflow']!=null
            && ((manualTask['miniWorkflow']['currentTutorial']!=null  && manualTask['miniWorkflow']['currentTutorial']['panel']!=null)
            || manualTask['miniWorkflow']['currentHelpPanel']!=null )
        )
            ?<i className={helpClasses}  style={{fontSize: "30px"}} onClick={()=>onHelpButtonClick()} title="Help" />
            :null;

        return (
            <React.Fragment>
                <Growl ref={(el) => this.growl = el} onRemove={()=>{ setAcknowledgeSignal(REQUEST_STATUS_SUCCESS)}}/>
                {disabled && spinnerIcon }
                {currentStep !== 0 && !disabled
                    && backButton}
                {currentStep === 0 && !disabled && skillCurrentStep>0
                && backButton}
                {!disabled && forwardButton}
                {helpButton}
                {/*<i className={videoClasses} style={{fontSize: "30px"}} onClick={()=>onVideoButtonClick()} title="Tutorial video"/>*/}
                <Dialog header="Confirmation dialog"
                        visible={this.state.confirmationDialogVisible}
                        width="350px"
                        modal={true}
                        onHide={() => this.setState({confirmationDialogVisible: false})}>
                    {this.state.confirmationDialogMessage}
                    <br/>
                    <div style={{justifyContent: "center", display: "flex", marginTop: "1em"}}>
                        <Button onClick={() => {
                            this.state.action();
                            this.setState({confirmationDialogVisible: false});
                        }} label={t("general.button.ok.label")}/>
                        <Button onClick={() => this.setState({confirmationDialogVisible: false})} label={t("general.button.cancel.label")}/>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
};


export default withTranslation()(TaskControlPanel);

// TODO Remove HACKS (onNext, onPrevious) when we find reasonable solution for presentation
TaskControlPanel.defaultProps = {
    helpButtonOn:false,
    videoButtonOn:false
};


TaskControlPanel.propTypes = {
    t:PropTypes.func.isRequired, //react-i18next
    miniWorkflowPath: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired, //step of miniworkflow
    skillCurrentStep: PropTypes.number.isRequired, //step of workflow within the skill
    saveOutputAndSubmit: PropTypes.func.isRequired,
    goToPreviousStep: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    onNextSkillsStep: PropTypes.func.isRequired,
    onPreviousSkillsStep: PropTypes.func.isRequired,
    ackSignalState: PropTypes.string.isRequired,
    nack:  PropTypes.object.isRequired,
    onHelpButtonClick : PropTypes.func.isRequired,  //callback to set state of external tool, eg. show video
    onVideoButtonClick : PropTypes.func.isRequired,
    validNextStep:PropTypes.bool.isRequired, //checks if forward can be rendered
    validationMessage: PropTypes.string.isRequired,
    helpButtonOn:PropTypes.bool.isRequired, //state of help button
    videoButtonOn:PropTypes.bool.isRequired, //state of video button - state of video
    manualToolState:PropTypes.object.isRequired,
    manualTask:PropTypes.object.isRequired,
    annotationsData: PropTypes.array.isRequired,
    annotationsDefinition: PropTypes.object.isRequired,
    annotationsFormData: PropTypes.array.isRequired,
    formsState:  PropTypes.object.isRequired,
    annotationsFormDefinition: PropTypes.object.isRequired,
    navigationModeLabel:PropTypes.string.isRequired
};