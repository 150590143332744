import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'primereact/components/button/Button';
import {Growl} from 'primereact/components/growl/Growl';
import {InputText} from 'primereact/components/inputtext/InputText';
import { withTranslation } from 'react-i18next';
import {Link} from "react-router-dom";
import SpineLogo from "./SpineLogo";
import CookieConsent from 'react-cookie-consent';
import Footer from '../../root/Footer';
import ReCAPTCHA from 'react-google-recaptcha';
import {RECAPTCHA_API_KEY} from "../../../Constants";

class RedirectLogin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.recaptcha = React.createRef();
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidMount(){
        const {getSession, history} = this.props;
        fetch('/dist/version.json')
          .then(response => response.json())
          .then(data => {
            getSession(()=>{this.props.history.push(data.landingPage)},()=>{});
        });
    }

    render(){
        const { t,onLogin} = this.props;
        return (
            <React.Fragment>
                <Growl ref={(el) => { this.growl = el; }}/>
                <div className="header landingPageHeaderTopbar"
                        style={{display:"flex",alignItems:"center"}}>
                    <div className="landingPageSpineLogoContainer" style={{display: "inline-flex",  margin: "auto", marginLeft:"16px"}}>
                        <Link to='/'>
                            <SpineLogo/>
                        </Link>
                    </div>
                    <div className="reg-headtitle" style={{ color: "#A7FFFE",    margin: "auto", display: "inline-flex"}}>
                        {t("redirectLoginForm.header")}
                    </div>
                </div>
                <form style={{height: 'calc(100vh - 75px)', overflowY: 'auto'}}>
                        <div id="loginForm" className="ui-g-12" style={{height: '80%', float:'none'}}>
                            <div style={{margin: "auto", width: "30%"}}>
                                <div className="ui-g-12 ui-g-nopad reg-pad-bot">
                                    <label className="reg-plaintext">{t("email")} </label>
                                    <InputText
                                        name="email"
                                        type={'email'}
                                        style={{width: '100%'}}
                                        placeholder="john.doe@nasa.gov"
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className="ui-g-12 ui-g-nopad reg-pad-bot">
                                    <label className="reg-plaintext">{t("password.label")}</label>
                                    <input
                                        type="password"
                                        name="password"
                                        style={{width: '100%'}}
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                                    />
                                </div>
                                <div className="ui-g-12 ui-g-nopad reg-pad-bot">
                                    <div className="nav__text_opaque nav__text_link">
                                        <Link to="/forgotPassword" onClick={()=>this.props.onForgotPassword()}>
                                            {t("password.forgotMessage")}
                                        </Link>
                                    </div>
                                </div>
                                <div className="ui-g-12">
                                    <div style={{display: "block",  margin: "auto",  textAlign: "center"}}>
                                        <Button
                                            label={t("loginButton.label")}
                                            title={t("loginButton.title")}
                                            onClick={e => {
                                                e.preventDefault();
                                                this.recaptcha.current.execute();
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{textAlign: "center"}}>
                                    <span className="reg-headtitle ui-g-12" style={{textAlign: "center"}}>
                                        {t("registerForm.newAccount")}
                                    </span>
                                    <Link to={{pathname: "/register", state: this.props.location.state}} >
                                        <Button label={t("registerForm.buttonCreate.label")} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <ReCAPTCHA
                            ref={this.recaptcha}
                            size="invisible"
                            sitekey={RECAPTCHA_API_KEY}
                            onChange={value => 
                                {
                                    if(value){
                                        onLogin(
                                            {...this.state, recaptchaToken: value},
                                            ()=> this.props.history.push(
                                                this.props.location.state? this.props.location.state.redirect+this.props.location.state.redirectParams || '/home': '/home'),
                                            (message) => this.growl.show(
                                                            {   
                                                                sticky: false,
                                                                severity: 'info',
                                                                summary: 'Login error',
                                                                detail: message
                                                            })
                                        );
                                        this.recaptcha.current.reset();
                                    } else {
                                        this.growl.show({
                                            sticky: false,
                                            severity: 'info',
                                            summary: 'Login error',
                                            detail: 'RECAPTCHA validation failed!'
                                        });
                                    }
                                }
                            }
                        />
                </form>
                <CookieConsent
                    location="bottom"
                    buttonText={t("general.cookies.acceptbutton.label")}
                    style={{opacity: '0.9'}}>
                    {t("general.cookies.message.label")}
                </CookieConsent>
            </React.Fragment>
        );
    }
}

RedirectLogin.propTypes = {
  loginStatus: PropTypes.string.isRequired,
  onLogin: PropTypes.func.isRequired,
  onRegister :PropTypes.func.isRequired,
  onForgotPassword :PropTypes.func.isRequired
};

export default withTranslation()(RedirectLogin);