import {connect} from "react-redux";
import CaseComponent from "../component/annotationTool/CaseComponent";
import {updateManualToolProperty} from "../action/ManualToolAction";
import {getAnotherCase} from "../../dashboard/action/ManualTaskAction";
import {CASE_CONTROL_TOOL, MAIN_TOOL} from "../../../Constants";


const mapStateToProps = (state) => {
    return {
        navigationModeLabel:state.visu.manualTool.manualToolState[MAIN_TOOL].navigationModeLabel,
        casesControl:state.visu.manualTool.manualToolState[CASE_CONTROL_TOOL],
        demoCase: state.visu.viewers.demoCase,
        manualTask: state.activity.manualTask.manualTask,
        messageQueue: state.messaging.msgQueue
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAnotherCase: (uuid, mfKey, expId, caseNumber) => {
            dispatch(getAnotherCase(uuid, mfKey, expId, caseNumber))
        },
        onNavigationModeChange:(value) => {
            dispatch(updateManualToolProperty(MAIN_TOOL, 'navigationModeLabel', value))
        }
    }
};

export const ContainerCaseComponent =connect( mapStateToProps, mapDispatchToProps)(CaseComponent);
