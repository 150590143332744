import React from "react";
import {TAB} from "./LandingPage";


/**
 * Dumb component with template for Projects.
 * @returns {*}
 * @constructor
 */
export const LandingPageProjectPage=({item,tab})=> {

  let contributors = item.people;
  let title = item.title;

  return(
    <div key={item.uuid} id={item.uuid}  className="landingPageBoxItem">
      <div className="landingPageJournalLogoContainer">
        <img src={item.imageUrl} alt={"Project logo"} className="landingPageJournalLogoImage"/>
      </div>
      <div className="landingPageItemTitle">
        {title}
      </div>
      <div className="landingPageItemContributors">
        {contributors}
      </div>
      <div className="landingPageItemAbstract">
        {item.summary} <a href={item.url} target={"_blank"}>more...</a>
      </div>
    </div>
  );
};