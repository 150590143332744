import React from 'react'
// import ExperimentIcon from "./ExperimentIcon";
import WrenchIcon from "./WrenchIcon";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {Link} from "react-router-dom";
// import PeopleIcon from "./PeopleIcon";
import {withTranslation} from 'react-i18next';
import Authorization from "../authentication/component/Authorization";
import {USER} from "../../Constants";


/**
 * "Dumb" component responsible for displaying navigation panel.
 * @param t - one of three parameters provided by withTranslation HOC - {i18n: I18n, tReady: true, t: ƒ}
 * @return {*}
 * @constructor
 */
const LeftNavigationBar = ({t}) => {

    return (
        <div className="sidenav">
            <Link to={`/home`}>
                <div id="dashboard" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}
                >
                    <i className="fa fa-dashboard" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                </div>
            </Link>

          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to={`/project`}>
                <div id="projects" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                    <i className="fa fa-briefcase" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                </div>
            </Link>
            </Authorization>

            <Authorization roles={[USER.ROLES.ADMIN]}>
                <div id="skills" style={{width: '100%', marginLeft: '15px', marginTop: '10px', marginBottom: '10px'}}>
                    <Link to={`/skills`}>
                        <WrenchIcon/>
                    </Link>
                </div>
            </Authorization>

            <Link to={`/event`}>
                <div id="events"
                     style={{width: '100%', marginLeft: '15px', marginTop: '10px', marginBottom: '10px'}}>
                    <i className="fa fa-bullhorn" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                </div>
            </Link>
            <Authorization roles={[USER.ROLES.ADMIN]}>
                <Link to={`/demo`}>
                    <div id="demo"
                        style={{width: '100%', marginLeft: '15px', marginTop: '10px', marginBottom: '10px'}}>
                        {/*<PeopleIcon/>*/}
                        <i className="fa fa-folder-open-o" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                    </div>
                </Link>
            </Authorization>
            <Authorization roles={[USER.ROLES.ADMIN]}>
                <Link to={`/news`}>
                    {/*TODO change it*/}
                    <div id="news" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                        <i className="fa fa-newspaper-o" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                    </div>
                </Link>
            </Authorization>
          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to="/livePresenter">
                <div id="livePresenterIconDiv" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                    <i className="fa fa-television" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                    {/* <div className="lp_logo"></div> */}
                </div>
            </Link>
          </Authorization>
          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to="/workflowManager">
                <div id="workflowManagerIconDiv" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                    <i className="fa fa-sitemap" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                </div>
            </Link>
          </Authorization>
          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to="/annotationsBuilder">
              <div id="annotationsBuilderIconDiv" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                 <i className="fa fa-table" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
              </div>
            </Link>
          </Authorization>
          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to="/manualToolBuilder">
              <div id="manualToolBuilderIconDiv" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                <i className="fa fa-gavel" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
              </div>
            </Link>
          </Authorization>
          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to={`/helpEditor`}>
                <div id="helpEditorIconDiv" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                    <i className="fa fa-question-circle-o" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                </div>
            </Link>
          </Authorization>
          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to={`/singleResultReview`}>
                <div id="singleResultReview" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                    <i className="fa fa-eye" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                </div>
            </Link>
          </Authorization>

          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to={`/users`}>
                <div id="users" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                <i className="fa fa-users" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                </div>
            </Link>
          </Authorization>
          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to={`/cohorts`}>
              <div id="cohorts" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                <i className="fa fa-database" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
              </div>
            </Link>
          </Authorization>

          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to={`/spm`}>
                <div id="spm" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                    <i className="fa fa-eye" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                </div>
            </Link>
          </Authorization>

          <Authorization roles={[USER.ROLES.ADMIN]}>
            <Link to={`/couch`}>
              <div id="couch" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                <img src={"http://docs.couchdb.org/en/stable/_static/logo.png"}  style={{width: '20px'}} alt={"CouchDB Manager"}/>
              </div>
            </Link>
          </Authorization>


            {/*<Link to="/taskBordeauxMenu">*/}
            {/*<div style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>*/}
            {/*<i className="fa fa-hand-pointer-o" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>*/}
            {/*/!* <div className="lp_logo"></div> *!/*/}
            {/*</div>*/}
            {/*</Link>*/}
            {/*<Link to="/taskBordeauxReviewMenu">*/}
            {/*<div style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>*/}
            {/*<i className="fa fa-eye" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>*/}
            {/*/!* <div className="lp_logo"></div> *!/*/}
            {/*</div>*/}
            {/*</Link>*/}
            {/*<Link to={`/expdesign`}>*/}
            {/*<div id="expdesign" style={{width: '100%', marginLeft: '15px'}}>*/}
            {/*<ExperimentIcon/>*/}
            {/*</div>*/}
            {/*</Link>*/}

            {/*<Link to={`/tour`}>*/}
            {/*<div id="tour" style={{width: '100%', marginLeft: '12px', marginTop: '10px'}}>*/}
            {/*<ExperienceIcon/>*/}
            {/*</div>*/}
            {/*</Link>*/}


            <Tooltip for="#dashboard" title={t("navigation.dashboard.title")} tooltipPosition="right"
                     tooltipEvent="hover"/>
            <Tooltip for="#news" title={t("navigation.news.title")} tooltipPosition="right" tooltipEvent="hover"/>
            {/*<Tooltip for="#expdesign" title="Experiment design" tooltipPosition="right" tooltipEvent="hover"/>*/}
            {/*<Tooltip for="#tour" title="What how and who" tooltipPosition="right" tooltipEvent="hover"/>*/}
            <Tooltip for="#skills" title={t("navigation.skills.title")} tooltipPosition="right" tooltipEvent="hover"/>
            <Tooltip for="#events" title={t("navigation.events.title")} tooltipPosition="right" tooltipEvent="hover"/>
            <Tooltip for="#projects" title={t("navigation.projects.title")} tooltipPosition="right"
                     tooltipEvent="hover"/>
            <Tooltip for="#demo" title={t("navigation.demos.title")} tooltipPosition="right" tooltipEvent="hover"/>
            <Tooltip for="#livePresenterIconDiv" title={t("navigation.livePresenter.title")} tooltipPosition="right"
                     tooltipEvent="hover"/>
            <Tooltip for="#workflowManagerIconDiv" title={t("navigation.workflowManager.title")} tooltipPosition="right"
                     tooltipEvent="hover"/>
            <Tooltip for="#helpEditorIconDiv" title={"Help Panel Editor"} tooltipPosition="right"
                     tooltipEvent="hover"/>
            <Tooltip for="#annotationsBuilderIconDiv" title={"Annotations Builder"} tooltipPosition="right"
                   tooltipEvent="hover"/>
          <Tooltip for="#manualToolBuilderIconDiv" title={"Manual Tool Builder"} tooltipPosition="right"
                   tooltipEvent="hover"/>
          <Tooltip for="#singleResultReview" title={"Results Finder"} tooltipPosition="right"
                   tooltipEvent="hover"/>
          <Tooltip for="#users" title={"User Manager"} tooltipPosition="right"
                   tooltipEvent="hover"/>
          <Tooltip for="#cohorts" title={"Cohort Explorer"} tooltipPosition="right"
                   tooltipEvent="hover"/>
          <Tooltip for="#spm" title={"SPM Inspector"} tooltipPosition="right"
                   tooltipEvent="hover"/>
          <Tooltip for="#couch" title={"CouchDB Query Tool"} tooltipPosition="right"
                   tooltipEvent="hover"/>
        </div>
    )

};
export default withTranslation()(LeftNavigationBar);
