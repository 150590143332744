import React from "react";

/**
 * Dumb component with template for displaying Betas table.
 * @param id -  optional parameter for id
 * @param name - optional parameter for name (required if multiple buttons are going to be used in the same view)
 * @param label - message to display
 * @param style - component style
 * @param classNameContent - optional CSS formatting class for container
 * @param classNameBox - optional CSS formatting class for box
 * @param filter - optional string - MIME filter for files, default value:  "image/*"
 * @param multiple - boolean -  whether multiple elements are allowed, default is false
 * @param dataMultipleCaption - caption in case of multiple files
 * @param fileInputButtonVariant - variant of button Look&Feel [1,2,3]
 * @param onChange - callback to what to do with selected file (-s)
 * @returns {*}
 */
export const FileInputButton = (
    {
        label,
        style,
        id="fileInput",
        name="fileInput",
        classNameContent="",
        classNameBox = "",
        filter="image/*",
        multiple=false,
        dataMultipleCaption="{count} files selected",
        fileInputButtonVariant = "1",
        onChange,
        labelStyle={}
    }
    ) => {

    return (
        <div className={"fileInputButtonContent ".concat(classNameContent)}  style={style}>
            <div className={"fileInputButtonBox ".concat(classNameBox)}>
                <input type="file"
                       name={name}
                       value=""
                       id={id}
                       onChange={onChange}
                       {...filter !=null ? {accept: filter} : {}}
                       className={"fileInputButton ".concat("fileInputButtonVariant",fileInputButtonVariant)}
                       data-multiple-caption={dataMultipleCaption}
                       {...multiple ? {multiple: "multiple"} : {}} />
                <label htmlFor={name} style={{
                    display:"flex",
                    justifyContent:"space-between",
                    marginRight:"10px",
                    marginLeft:"10px",
                    ...labelStyle
                }}>
                    <i className="fa fa-upload"/>
                    <span style={{marginLeft:"5px"}}>{label}</span>
                </label>
            </div>

        </div>
    );
};

//
// const svgIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
//     <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
// </svg>;