
import vtkBoundingBox from 'vtk.js/Sources/Common/DataModel/BoundingBox';
import * as vtkMath from 'vtk.js/Sources/Common/Core/Math';

/**
 * This function is based on ImageData.computeHistogram code.
 * @param image
 * @param worldBounds
 * @param voxelFunc
 * @return {{sigma: number, average: number, hist: *, variance: number, maximum: number, minimum: number, resolution:number}}
 */

export const createHistogram = (image, worldBounds, voxelFunc = null) => {
  const bounds = [0, 0, 0, 0, 0, 0];
  image.worldToIndexBounds(worldBounds, bounds);

  const point1 = [0, 0, 0];
  const point2 = [0, 0, 0];
  vtkBoundingBox.computeCornerPoints(bounds, point1, point2);

  vtkMath.roundVector(point1, point1);
  vtkMath.roundVector(point2, point2);

  const dimensions = image.getDimensions();

  const pixels = image
    .getPointData()
    .getScalars()
    .getData();

  const numberOfBins = Math.pow(256,pixels.BYTES_PER_ELEMENT);

  //var histogram = new Float32Array(numberOfBins);

  let histogram = new Map(); //due to issues with big arrays , histogram is kept in Map with keys of type "int"

  // histogram.fill(0);

  vtkMath.clampVector(
    point1,
    [0, 0, 0],
    [dimensions[0] - 1, dimensions[1] - 1, dimensions[2] - 1],
    point1
  );
  vtkMath.clampVector(
    point2,
    [0, 0, 0],
    [dimensions[0] - 1, dimensions[1] - 1, dimensions[2] - 1],
    point2
  );

  const yStride = dimensions[0];
  const zStride = dimensions[0] * dimensions[1];



  let maximum = -Infinity;
  let minimum = Infinity;
  let sumOfSquares = 0;
  let isum = 0;
  let inum = 0;
  let rawData=[];

  for (let z = point1[2]; z <= point2[2]; z++) {
    for (let y = point1[1]; y <= point2[1]; y++) {
      let index = point1[0] + y * yStride + z * zStride;
      for (let x = point1[0]; x <= point2[0]; x++) {
        if (!voxelFunc || voxelFunc([x, y, z], bounds)) {
          // const pixel = Math.floor(pixels[index]);
          const pixel = pixels[index];
          if (pixel > maximum) maximum = pixel;
          if (pixel < minimum) minimum = pixel;
          sumOfSquares += pixel * pixel;
          isum += pixel;
          inum += 1;
          rawData.push(pixel);
          if(histogram.has(pixel)){
            histogram.set(pixel,histogram.get(pixel)+1);
          }
          else
            histogram.set(pixel,1);
        }

        ++index;
      }
    }
  }

  let mean = 0;
  // for(let i=0;i< numberOfBins ;i++)
  histogram.forEach((v,k)=>{
    mean+=v*k;
  });
      // mean+=i*histogram[i];

  let variance = 0;

  // for(let i=0;i< numberOfBins ;i++)
  //   variance += ((i-mean)*(i-mean)*histogram[i]);

  histogram.forEach((v,k)=>{
    variance += ((k-mean)*(k-mean)*v);
  });


histogram=new Map([...histogram].sort((a,b)=>{return a-b}));

  const average = inum > 0 ? isum / inum : 0;
  //const variance = sumOfSquares - average * average; //Check it!!!
  variance = inum > 0 ? variance / numberOfBins : 0;
  const sigma = Math.sqrt(variance);

  return {
    minimum,
    maximum,
    average,
    variance,
    sigma,
    histogram,
    inum,
    rawData,
    resolution:numberOfBins // resolution of intensity Levels, ie. 256 for BYTE per element, 65536 for 2 bytes per element, etc.
  };
};


/**
 *
 * @param histData - Array of 256 greyscale values
 * @param total - number of pixels in probe
 */
export function otsu(histData, total ) {
  let sum = 0;

  // const numberOfBins = histData.length;

  // for (let t=0 ; t<numberOfBins ; t++) sum += t * histData[t];
  histData.forEach((v,k)=>{
    sum+=v*k;
  });


  let sumB = 0;
  let wB = 0;
  let wF = 0;

  let varMax = 0;
  let threshold = 0;

  // const maxBin = Math.max(...histData.keys());

  for (let t of histData.keys()) {

    if (! histData.has(t))
      continue;

    wB += histData.get(t);               // Weight Background
    if (wB === 0) continue;

    wF = total - wB;                 // Weight Foreground
    if (wF === 0) break;

    sumB += t * histData.get(t);

    let mB = sumB / wB;            // Mean Background
    let mF = (sum - sumB) / wF;    // Mean Foreground

    // Calculate Between Class Variance
    let varBetween = wB * wF * (mB - mF) * (mB - mF);

    // Check if new maximum found
    if (varBetween > varMax) {
      varMax = varBetween;
      threshold = t;
    }
  }

  return threshold;
}