import React from "react";
import PropTypes from "prop-types";
import {ContainerGeoComponent} from "../arcgis/container/ContainerGeoComponent";
import {withTranslation} from 'react-i18next';
import LayoutTopbar from "../authentication/component/LayoutTopbar";
import Slider from "react-slick";
import {LandingPageProjectPage} from "./LandingPageProjectPage";
import {getNestedProp} from "../helpers/expressions";
import {REQUEST_STATUS_SUCCESS} from "../../Constants";
import {loadModules} from 'esri-loader';
import {SITES_LAYER_ID} from "../arcgis/action/GeoAction";
import YouTube from 'react-youtube'
import LandingPageTopbar from "../authentication/component/LandingPageTopbar";


/**
 * Landing Page demo
 * Uses slick
 * https://react-slick.neostack.com/docs/api/
 * and arcgis-esri library
 * @see {@link https://github.com/SPINEProject/SPINE_V.2.0/blob/master/src/frontend/arcgis/README.md }
 */
class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      leftRatio: 3,
      activeTab: TAB.PROJECTS,
      content: {"Papers": [], "Projects": [], "Events": []}, // array of arrays [projects,papers,events],
      dataState: "initial",
      mapConfiguration: null,
      activeSlide: 0,
      dialogVideo: false,
      videoKey:"",
      uiVersion:"new"
    };
    ["onZoom", "onSlideChange", "initLayers", "updateLayers", "onTabChange", "onClickHandler","createPopupTemplate",
      "onIconClick","onCloseVideo"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    fetch('/dist/jsonDocs/data/landingPageData.json')
      .then(response => response.json())
      .then(data => {
        this.setState({
          content: data["content"],
          mapConfiguration: data["mapConfiguration"],
          dataState: "loaded"
        })
      });
  }

  componentDidUpdate(prevProps, prevState, ss) {
    const {viewState,view} = this.props;
    const {activeSlide, activeTab} = this.state;
    if ((prevState.dataState !== this.state.dataState && viewState === REQUEST_STATUS_SUCCESS)
      || (prevProps.viewState !== viewState && this.state.dataState === "loaded" && viewState === REQUEST_STATUS_SUCCESS)
    ) {
      this.initLayers();
    }
    if (prevState.activeSlide !== activeSlide || prevState.activeTab !== activeTab) {
      view.popup.close();
      this.updateLayers();
    }
  }

  initLayers() {
    const {view} = this.props;
    const {activeSlide, activeTab} = this.state;
    loadModules([
      "esri/layers/FeatureLayer",
      "esri/Graphic"
    ])
      .then(([FeatureLayer, Graphic]) => {
        const featureCollection = getNestedProp(["state", "content", activeTab, activeSlide, "features"], this);
        const layers = getNestedProp(["state", "mapConfiguration", "layers"], this);
        for (let i = 0; i < layers.length; i++) {
          if (Array.isArray(featureCollection)) {
            const symbols = featureCollection.map(el => new Graphic(el));
            const flayer = Object.assign(layers[i], {source: symbols});
            const gl = new FeatureLayer(flayer);
            view.map.add(gl);
            view.center = symbols[0].geometry;
          }
        }
      });
  }

  updateLayers() {
    const {view} = this.props;
    const {activeSlide, activeTab} = this.state;
    loadModules([
      "esri/Graphic"
    ]).then(([Graphic]) => {
      const featureCollection = getNestedProp(["state", "content", activeTab, activeSlide, "features"], this);
      const layers = getNestedProp(["state", "mapConfiguration", "layers"], this);
      for (let i = 0; i < layers.length; i++) {
        const layer = view.map.layers.find(el => el.title === layers[i].title);
        if (layer) {
          layer.queryFeatures()
            .then((fts) => {
              const feat = fts.features;
              if (feat != null) {
                layer.applyEdits({deleteFeatures: feat})
                  .then((results) => {
                    if (Array.isArray(featureCollection)) {
                      const symbols = featureCollection.map(el => new Graphic(el));
                      layer.applyEdits({addFeatures: symbols})
                        .then((results) => {
                          const {longitude, latitude} = symbols[0].geometry;
                          view.goTo({center: [longitude, latitude]}, cameraOptions);
                        });
                    }
                  })
              }
            })
        }
      }
    });
  }


  onClickHandler(event, view) {
    view.hitTest(event).then(response => {

      const hits = response.results
        .filter((hitResult) =>
          hitResult.type === "graphic"
          && hitResult.graphic.layer.type === 'feature'
          && hitResult.graphic.layer.title === SITES_LAYER_ID
        );

      if (hits != null && hits.length >= 1) { // if there is 1 and only 1 layer
        this.createPopupTemplate(hits, event);
      }
    });
  };

  createPopupTemplate(hits, event) {
    const {activeSlide, activeTab} = this.state;
    const {view} = this.props;

    const lat = Math.round(event.mapPoint.latitude * 1000) / 1000;
    const lon = Math.round(event.mapPoint.longitude * 1000) / 1000;
    let title = "Coords: [" + lon + "," + lat + "]";
    let content = "No sites here";
    if (activeTab === TAB.PROJECTS) {
      content = hits.map(el => {
        const organisation = getNestedProp(["graphic", "attributes", "organisation"], el);
        let people = "";
        if(getNestedProp(["graphic", "attributes", "icon"], el)==="man") {
          title = "Project participants";
          people = getNestedProp(["graphic", "attributes", "people"], el);
        }
        if(getNestedProp(["graphic", "attributes", "icon"], el)==="home") {
          title = "Project site and participants";
          people = getNestedProp(["graphic", "attributes", "people"], el);
        }
        return organisation + "<br><b>" + people + "</b>"
      })
        .join("<br>");
    }
    if (activeTab === TAB.PAPERS) {
      title = "Affiliation of paper contributors";
      content = hits.map(el => {
        const organisation = getNestedProp(["graphic", "attributes", "organisation"], el);
        const people = getNestedProp(["graphic", "attributes", "people"], el);
        return organisation + "<br><b>" + people + "</b>"
      })
        .join("<br>");
    }
    if (activeTab === TAB.EVENTS) {
      title = "Event locations";
      content = hits.map(el => {
        const organisation = getNestedProp(["graphic", "attributes", "organisation"], el);
        let people = "";
        if(getNestedProp(["graphic", "attributes", "icon"], el==="man"))
          people= getNestedProp(["graphic", "attributes", "people"], el);
        if(getNestedProp(["graphic", "attributes", "icon"], el==="home"))
          people= "Event site <i class='fa fa-home'  />";
        return organisation + "<br><b>" + people + "</b>"
      })
        .join("<br>");
    }

    view.popup.open({
      location: event.mapPoint,
      title: title,
      content: content
    });
  }

  onZoom(newValue, oldValue, property, object) {
    if (newValue < 4) {
      this.setState({leftRatio: 3})
    }
    if (newValue > 4 && newValue < 4.5) {
      this.setState({leftRatio: 2})
    }
    if (newValue > 4.5) {
      this.setState({leftRatio: 1})
    }
  }

  onSlideChange(current) {
    this.setState({activeSlide: current});
  }

  onTabChange(tab) {
    this.slider.slickGoTo(0);
    this.setState({activeTab: tab, activeSlide: 0}); // to avoid out of range
  }


  onIconClick(el) {

    let vKey ;
    switch (el){
      case "livePresenter":
        vKey = "6UDdTMsV1D8";
        break;
      case "event":
        vKey = "YbrJnnfyJMw";
        break;
      case "workflow":
        vKey = "T6IxuTaeCgI";
        break;
      case "project":
      default: vKey = "a7qNsmHc3sU";
    }
    this.setState({
      dialogVideo:true,
      videoKey:vKey
    })
  }


  onCloseVideo(){

    if (this.player){
     this.player.getInternalPlayer().pauseVideo();
    }
    this.setState({dialogVideo:false});
  }


    render() {
    const {t} = this.props;
    const {activeTab, mapConfiguration, content} = this.state;

    let generateTabs = content[activeTab].map((item) => {
      // if (activeTab === TAB.PAPERS)
      //   return <LandingPagePaperPage item={item}/>;
      return <LandingPageProjectPage item={item} tab={activeTab}/>
    });


    let settings = {
      infinite: true,
      autoplay: false,
      autoplaySpeed: 10000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      afterChange: this.onSlideChange
    };

    return (
      <React.Fragment>
        { this.state.uiVersion === "old" &&
        <LayoutTopbar/>}
        { this.state.uiVersion === "new" &&
        <LandingPageTopbar
          onUIChange = {(version)=>this.setState({uiVersion:version})}
          version={this.state.uiVersion}
        />
        }
        <div style={(this.state.uiVersion==="old") ? oldContentStyle : newContentStyle}>
          {mapConfiguration != null &&
          <ContainerGeoComponent zoom={mapConfiguration.zoom}
                                 basemap={mapConfiguration.basemap}
                                 center={mapConfiguration.center}
                                 viewType={mapConfiguration.viewType}
                                 uiComponents={mapConfiguration.uiComponents}
                                 height={"100%"}
                                 width={"100%"}
                                 onZoom={this.onZoom}
                                 onClick={this.onClickHandler}
          />}
          <a><i className="fa fa-briefcase landingPageIcon"
                style={{top: "20vh", left: String(this.state.leftRatio * 8) + "vw", fontSize: "3em"}}
                onClick={()=>this.onIconClick("project")}
          /></a>
          <a><i className="fa fa-television landingPageIcon"
                style={{top: "40vh", left: String(this.state.leftRatio * 8 - 5) + "vw", fontSize: "3em"}}
                onClick={()=>this.onIconClick("livePresenter")}
          /></a>
          <a><i className="fa fa-bullhorn landingPageIcon"
                style={{top: "60vh", left: String(this.state.leftRatio * 8 - 5) + "vw", fontSize: "3em"}}
                onClick={()=>this.onIconClick("event")}
          /></a>
          <a><i className="fa fa-sitemap landingPageIcon"
                style={{top: "80vh", left: String(this.state.leftRatio * 8) + "vw", fontSize: "3em"}}
                onClick={()=>this.onIconClick("workflow")}
          /></a>
          <div className="landingPageCopyright">{t("landingPage.copyright.label")}</div>

          <div className="landingPageBox">
            <div className="landingPageBoxTabs">
              <div className={"landingPageBoxTab".concat((activeTab === TAB.PROJECTS) ? " activeTab" : "")}
                   onClick={() => {
                     this.onTabChange(TAB.PROJECTS)
                   }}>
                {TAB.PROJECTS}
              </div>
              <div className={"landingPageBoxTab".concat((activeTab === TAB.PAPERS) ? " activeTab" : "")}
                   onClick={() => {
                     this.onTabChange(TAB.PAPERS)
                   }}>
                {TAB.PAPERS}
              </div>
              <div className={"landingPageBoxTab".concat((activeTab === TAB.EVENTS) ? " activeTab" : "")}
                   onClick={() => {
                     this.onTabChange(TAB.EVENTS)
                   }}>
                {TAB.EVENTS}
              </div>
            </div>
            <div className="landingPageBoxSlider">
              <Slider ref={slider => (this.slider = slider)} {...settings}>
                {generateTabs}
              </Slider>
            </div>
          </div>

          <div className="landingPageFooter">
            <a href="mailto:guttmann@bwh.harvard.edu" style={{marginRight: '16px'}}>{t("footer.contactUs")}</a>
            <a href="javascript:void(0)" style={{marginRight: '16px'}} onClick={() => this.setState({
              privacyNoticeVisible: true,
              legalNoticeVisible: false
            })}>{t("footer.privacyPolicy")}</a>
            <a href="javascript:void(0)" style={{marginRight: '16px'}} onClick={() => this.setState({
              privacyNoticeVisible: false,
              legalNoticeVisible: true
            })}>{t("footer.legalNotice")}</a>
          </div>
        </div>

        <div className="landingPageVideoContainer" style={this.state.dialogVideo?{display:"block"}:{display:"none"}}>
          <div className="fa fa-close"
               onClick={this.onCloseVideo}
               style={{fontSize: '3em',
                 color: "white",
                 float: "right",
                 textShadow: "2px 2px gray"}}
          />
          <YouTube  videoId={this.state.videoKey} opts={playerOpts} ref={player=>this.player=player}/>

        </div>

      </React.Fragment>
    );
  }
}

const oldContentStyle = {
  height: 'calc(100vh - 75px)',
  overflowY: 'auto',
  width: "100%"
};

const newContentStyle = {
  height: 'calc(100vh - 50px)',
  overflowY: 'auto',
  width: "100%"
};


const playerOpts = {
  host:'https://www.youtube-nocookie.com',
  playerVars: { // https://developers.google.com/youtube/player_parameters
    rel: 0,
    autoplay: 0,
    cc_load_policy:1,
    cc_lang_pref:'fr'
  }
};

export const TAB = {
  PROJECTS: "Projects",
  PAPERS: "Papers",
  EVENTS: "Events"
};

const cameraOptions = {
  speedFactor: 0.5
};

export default withTranslation()(LandingPage);
LandingPage.propTypes = {
  t: PropTypes.object.isRequired,
  updateSitesData: PropTypes.func.isRequired,
  view: PropTypes.object.isRequired,
  viewState: PropTypes.string.isRequired
};
LandingPage.defaultProps = {};