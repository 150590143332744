import {
    LUT_DESCRIPTION_REQUEST_FAILED, LUT_DESCRIPTION_REQUEST_SUCCESS, LUT_DESCRIPTION_REQUESTED,
    LUT_REQUEST_FAILED,
    LUT_REQUEST_SUCCESS,
    LUT_REQUESTED
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


/**
 * Lookup table and their descriptions are stored here.
 * The structure:
 * {
 * luts:{"cf8909090u98j":{
 *     data:[{"value":1, "color":"#ff0"},{"value":2, "color":"#0f0"} ],
 *     state:"success"
 * }
 * }
 * lutDescriptions:{"ed8909090u98c":{
 *     data:[{"value":1, "label":"label","iri":"http://whatever"},{"value":2, "label":"label2","iri":"http://whatever"} ],
 *     state:"success"
 * }
 * }
 * }
 * @param state
 * @param action
 * @return {({} & {lutDescriptions: {}, luts: {}} & {luts: {}})|({} & {lutDescriptions: {}, luts: {}} & {lutDescriptions: {}})|{lutDescriptions: {}, luts: {}}}
 */
export const lutReducer = (state = {
    luts:{}, // overlays requested or loaded
    lutDescriptions:{}    // images requested or loaded
}, action) => {
    switch (action.type) {
        case LUT_REQUESTED:{
            let stateCopy = Object.assign({},state.luts);
            let isImageAlreadyInArray = stateCopy[action.lutId]!=null;
            if (isImageAlreadyInArray)  //Maybe some conditions if error occurred (reload?)
                return state;
            let newLUT = {};
            newLUT['state'] =  REQUEST_STATUS_REQUESTED;
            stateCopy[action.lutId] = newLUT;
            return Object.assign({}, state, {
                luts:Object.assign({},stateCopy)
            });
        }
        case LUT_REQUEST_FAILED:{
            let stateCopy = Object.assign({},state.luts);
            if (!(stateCopy[action.lutId]!=null))
                return state;
            let requestedLUT = stateCopy[action.lutId];
            requestedLUT['state'] =  REQUEST_STATUS_FAIL;
            requestedLUT['data'] =  action.err;
            stateCopy[action.lutId]=Object.assign({},requestedLUT);
            return Object.assign({}, state, {
                luts:Object.assign({},stateCopy)
            });
        }

        case LUT_REQUEST_SUCCESS:{
            let stateCopy = Object.assign({},state.luts);
            if (!(stateCopy[action.lutId]!=null))
                return state;
            let requestedLUT = stateCopy[action.lutId];
            requestedLUT['state'] =  REQUEST_STATUS_SUCCESS;
            requestedLUT['data'] =  action.lutData;
            stateCopy[action.lutId]=Object.assign({},requestedLUT);
            return Object.assign({}, state, {
                luts:Object.assign({},stateCopy)
            });
        }
        case LUT_DESCRIPTION_REQUESTED:{
            let stateCopy = Object.assign({},state.lutDescriptions);
            let isImageAlreadyInArray = stateCopy[action.lutId]!=null;
            if (isImageAlreadyInArray)  //Maybe some conditions if error occurred (reload?)
                return state;
            let newLutDescription = {};
            newLutDescription['state'] =  REQUEST_STATUS_REQUESTED;
            stateCopy[action.lutId] = newLutDescription;
            return Object.assign({}, state, {
                lutDescriptions:Object.assign({},stateCopy)
            });
        }
        case LUT_DESCRIPTION_REQUEST_FAILED:{
            let stateCopy = Object.assign({},state.lutDescriptions);
            if (!(stateCopy[action.lutId]!=null))
                return state;
            let requestedLutDesciption = stateCopy[action.lutId];
            requestedLutDesciption['state'] =  REQUEST_STATUS_FAIL;
            requestedLutDesciption['data'] =  action.err;
            stateCopy[action.lutId]=Object.assign({},requestedLutDesciption);
            return Object.assign({}, state, {
                lutDescriptions:Object.assign({},stateCopy)
            });
        }

        case LUT_DESCRIPTION_REQUEST_SUCCESS:{
            let stateCopy = Object.assign({},state.lutDescriptions);
            if (!(stateCopy[action.lutId]!=null))
                return state;
            let requestedLutDescription = stateCopy[action.lutId];
            requestedLutDescription['state'] =  REQUEST_STATUS_SUCCESS;
            requestedLutDescription['data'] =  action.lutData;
            stateCopy[action.lutId]=Object.assign({},requestedLutDescription);
            return Object.assign({}, state, {
                lutDescriptions:Object.assign({},stateCopy)
            });
        }
        default:
            return state;
    }
};

