import {interpolateSinebow} from "d3-scale-chromatic"
import {color} from "d3-color";

export const ORDINAL10 = "1f77b4ff7f0e2ca02cd627289467bd8c564be377c27f7f7fbcbd2217becf";
export const WAHIDAH =  "5ac18e003366ff7f50c39797468499d8c564be377c27f7f7fbcbd2217becf";
export const ORDINAL24      ="e41a1c377eb84daf4a984ea3ff7f00ffff33a65628f781bf999999d95f027570b3e7298a66a61ee6ab02a6761dfbb4aeb3cde3ccebc5decbe4fed9a6ffffcce5d8bdfddaecf2f2f2";
export const ORDINAL24LIGHT ="ff1a1c4CB0FF70FF6CED78FFB55B00CBCB2DF08035C0608F998B88964602A7A0FFB226668BE328987402E4A12AA579748FA7B8E1FFD9988A9Efed9a6ffffcce5d8bdfddaecf2f2f2";

export function ordinalColors(specifier) {
  let n = specifier.length / 6 | 0, colors = new Array(n), i = 0;
  while (i < n) colors[i] = "#" + specifier.slice(i * 6, ++i * 6);
  return colors;
}

/**
 * Helper to create an array of different colors
 * @param specifier - string containing concatenated color values (ORDINAL10, WAHIDAH etc.)
 * @param size -
 * @return {any[]}
 */

export function ordinalColorsArray(specifier,size) {
  let arr = new Array(size);
  for(let i=0;i<size;i++){
    arr[i] = ordinalColors(specifier)[i]
  }
  return arr;
}

/**
 *
 * @param value
 * @return {string}
 */
export function getTurboColor(value){
  return color(interpolateSinebow(value)).hex();
}
