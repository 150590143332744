import {withRouter} from "react-router";
import {connect} from "react-redux";
import {
  getSelectedPlotIndex,
  getSelectedPlotSettings
} from "../selectors/SAMSelectors";
import RegressionPanel from "../component/RegressionPanel";




const mapStateToProps = (state) => {
  return {
    rawData: state.sam.rawData,
    plotSettings: getSelectedPlotSettings(state),
    plotIndex: getSelectedPlotIndex(state) // selector used here !
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export const ContainerRegressionPanel = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps)(RegressionPanel));