import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {Button} from "primereact/components/button/Button";
import {OverlayPanel} from "primereact/components/overlaypanel/OverlayPanel";
import {SplitButton} from "primereact/components/splitbutton/SplitButton";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
const statusIconStyle = {marginLeft: '1em', verticalAlign: 'middle'};
/**
 * Control component for cases.
 *
 * Component to replace dropdown for cases (CasesSelectionDropdown).
 * Uses listOfCases in the form :
 *  {
      "caseNumber": "NMO1",
      "caseStatus": "NOTSTARTED",
      "caseCurrentStep":0
    },
 {
      "caseNumber": "NMO2",
      "caseStatus": "STARTED",
      "caseCurrentStep":1
    },
 {
      "caseNumber": "NMO3",
      "caseStatus": "DONE",
       "caseCurrentStep":1
    }
 *
 * List of cases is sorted alphabetically according to label (caseNumber).
 * Sorter does not trim spaces!
 *
 * @link
  */
class CaseComponent extends React.Component {
    constructor(props) {
        super(props);
        ["onCaseChange","onDemoCaseChange","statusColumnTemplate", "caseNumberColumnTemplate", "rowClassName"]
            .forEach(name => { this[name] = this[name].bind(this);});
    }

    /**
     * Handler foe changing case
     * @param caseNumber
     */
    onCaseChange(caseNumber){
        const { manualTask,match,getAnotherCase, messageQueue} = this.props;
        if(manualTask.currentCase===caseNumber)
            return;

        if (Array.isArray(manualTask['listOfCases'])) {
            const target = manualTask['listOfCases'].find(el=>el['caseNumber']===caseNumber);
            if (target!=null && target['caseStatus']!=null && target['caseStatus'].toUpperCase()!=="DONE")
            getAnotherCase(
                match.params['miniWorkflowSetId'],
                match.params['miniWorkflowKey'],
                match.params['experimentId'],
                caseNumber
            );
            else messageQueue.show({severity: 'info', summary: 'Info', detail: `Case ${caseNumber} has already been done.`});
        }
    }
    onDemoCaseChange(caseNumber){
        const { manualTask, messageQueue} = this.props;
        if(manualTask.currentCase===caseNumber)
            return;
        messageQueue.show({severity: 'info', summary: 'Info', detail: `This function is not available in demo mode.`});
    }
    rowClassName(rowData) {
        const status = (rowData['caseStatus'] != null) ? rowData['caseStatus'].toUpperCase() : "";
        switch (status) {
            case "DONE":
                return {"row-background-lightgreen":true};
            case "STARTED":
                return {"row-background-lightred":true};
            case "NOTSTARTED":
            default:
                return {"row-background-white":true};
        }
    }

    caseNumberColumnTemplate(rowData) {
        // const status = (rowData['caseStatus'] != null) ? rowData['caseStatus'].toUpperCase() : "";
        const {match} = this.props;
        if (match.path.includes("demo"))
            return (<a onClick={() => this.onDemoCaseChange(rowData['caseNumber'])}>{rowData['caseNumber']} </a>);
        else
            return (<a onClick={() => this.onCaseChange(rowData['caseNumber'])}>{rowData['caseNumber']} </a>);

    }

    statusColumnTemplate(rowData) {
        const status = (rowData['caseStatus'] != null) ? rowData['caseStatus'].toUpperCase() : "";
        const step = (rowData['caseCurrentStep'] != null) ? String(rowData['caseCurrentStep'] + 1) : "?";
        return <span>
      {status === "DONE" && <span> {"DONE"}
          <i className="fa fa-check" title="Done"
             style={{color: 'green',...statusIconStyle }}/>
      </span>
      }
            {status === "STARTED" &&
            <span> {"Step " + step}
                <i className="fa fa-spinner" title="In progress"
                   style={{color: 'orange', ...statusIconStyle}}/>
      </span>
            }
            {status === "NOTSTARTED" && <span>{"Step 1"}
                <i className="fa fa-square-o" title="Not started"
                   style={{color: 'red', ...statusIconStyle}}/></span>
            }
     </span>;
    }
    render() {
        const { manualTask, casesControl, onNavigationModeChange,navigationModeLabel} = this.props;

        const totalDone = manualTask['listOfCases'].reduce(function(previousValue, currentValue) {
            const statusCounter = (currentValue['caseStatus'] != null && "DONE" === currentValue['caseStatus'].toUpperCase())? 1 : 0;
            return previousValue + statusCounter;
        }, 0);

        const navModel = manualTask.miniWorkflow.miniWorkflowPath.map((el,index)=>{
            return {label:"step ".concat(String(index+1)," in the next case"), command: ()=>onNavigationModeChange("step "+(index+1) )}
        });

        navModel.unshift({label:"next step in this case",command: ()=>onNavigationModeChange("case")});

        const rows = (casesControl!=null && casesControl['rows']!=null)?casesControl['rows']:10;
        let paginatorProps = {
            rows: rows,
            paginator: (manualTask.listOfCases.length > rows)
        };
        if (casesControl!=null && Array.isArray(casesControl['rowsPerPageOptions']))
            paginatorProps['rowsPerPageOptions'] = casesControl['rowsPerPageOptions'];


        return (
            <React.Fragment>
                <Button icon="fa fa-tasks" label={manualTask.currentCase} onClick={(e) => this.casesOverlayPanel.toggle(e)} title={"Cases"}/>
                <OverlayPanel ref={(el) => this.casesOverlayPanel = el} style={{textAlign :"center"}}>
                    <label>
                    Action forward:
                    </label>
                    <SplitButton label={navigationModeLabel==="case"?"next step in this case":(navigationModeLabel + " in next case")}
                                 model={navModel}
                    />
                    <div style={{margin:"0.5em"}}>Total cases done: {totalDone} out of {manualTask.listOfCases.length}</div>
                    <DataTable value={manualTask.listOfCases}
                               rowClassName={this.rowClassName}
                               tableStyle={{width:"20em"}}
                               sortField="caseNumber"
                               sortOrder={1}
                               {...paginatorProps}
                    >
                        <Column field="caseNumber" header="Label" sortable={true} body={this.caseNumberColumnTemplate} style={{width:"8em"}}/>
                        <Column field="caseStatus" header="Progress" body={this.statusColumnTemplate} sortable={true} style={{width:"4em"}}/>
                    </DataTable>
                </OverlayPanel>
            </React.Fragment>
        )
    }

}

export default withRouter(CaseComponent);

CaseComponent.defaultProps = {
};

CaseComponent.propTypes = {
    casesControl: PropTypes.object,
    options: PropTypes.object.isRequired,
    messageQueue: PropTypes.object.isRequired,
    onNavigationModeChange:PropTypes.func.isRequired
};


