import React from 'react'
import PropTypes from "prop-types";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {Dialog} from "primereact/components/dialog/Dialog";
import {ContainerAgreementModelsSidebar} from "../../../visualization/containers/ContainerAgreementModelsSidebar";
import {
    REQUEST_STATUS_FAIL,
    REQUEST_STATUS_REQUESTED,
    REQUEST_STATUS_SUCCESS
} from "../../../../Constants";
import {generateAnnotationVisualizationToolConfiguration,
        configurationAnnonVisuTool} from "../../../visualization/component/annotationTool/ToolConfigurationFactory";
import {ContainerManualTool} from "../../../visualization/containers/ContainerManualTool";
import BlandAltmanPlot from "./BlandAltmanPlot";
import { Button } from 'primereact/components/button/Button';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';

/**
 * Panel being container for Bland Altman Plot and other agreement results/analysis.
 * It is responsible for handling logic related to overlay windows:
 *  - dialog window to view individual cases results from manual tool
 */
export class AgreementModelsPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: null,
            height: null,
            optionsSidebarVisible: false, //TODO put it in store
            showMRIDialog: false,//TODO put it in store
            showHistogramDialog: false, //TODO put it in store
            raters: null,
            opts: [{label: "None", value: "none"}],
            selected: null,   //selected Data Points in left and right
            manualToolConfiguration: null,  //manualTool configuration taking into account currently selected point
            leftButtonMode: LEFT_BUTTON_MODE.NONE,
            onDeselectFromVolume: null, //callback function that should be set dynamically by children - this one is for deselecting from Volume View
            onDeselectFromRater:null
        };
        ["setPlotSize", "onDataPointClick", "onHideVolumeDialog", "onHideHistogramDialog","onHideSidebar"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            });
    }

    componentDidUpdate(prevProps, prevState,snapshot) {
        const {plotData}=this.props;
        if (prevProps.plotData !== plotData)
            console.log('Different data in plot');
    }

    componentWillUnmount() {
        const {clearInteractivePlotState,clearDataset} = this.props;
        document.body.removeEventListener("keydown", this.keyListener, true);
        window.removeEventListener("resize", this.setPlotSize);
        clearInteractivePlotState();
        clearDataset();  // used from Audit module, TODO move to independent part of Store responsible for R communication

    }

    componentDidMount() {
        const {initializeBlandAltmanPlot, experimentProperties, match} = this.props;
        // TODO: Determine parameters of experiment and workflow to pass to load the results data
        // currently path to component is match.params.id -> "agreement"
        initializeBlandAltmanPlot(experimentProperties);
        
        document.body.addEventListener("keydown", this.keyListener, true);
        this.setPlotSize();
        window.addEventListener("resize", this.setPlotSize);
    }


    /**
     * Function to be passed to children components.
     *
     * @param dataPoint - data associated with selected element
     * @param onDeselect - callback function defined in children component and to be set in
     * this component state. This clbk function can be used to deselect element in data.
     *
     */
    onDataPointClick(dataPoint, onDeselect) {
        const {experimentProperties} = this.props;
        this.setState({
            showMRIDialog: true,
            onDeselectFromVolume: onDeselect,
            selected:dataPoint,
            manualToolConfiguration: generateAnnotationVisualizationToolConfiguration(
                configurationAnnonVisuTool, dataPoint, experimentProperties)
        });
    }

    /**
     * Event handler to call when volume dialog window is closed.
     */
    onHideVolumeDialog() {
        if (this.state.onDeselectFromVolume != null)
            this.state.onDeselectFromVolume();
        this.setState({
            showMRIDialog: false,
            onDeselectFromVolume: null,
            selected:null,
            manualToolConfiguration:null
        })
    }

    onHideSidebar(){
        this.setState({optionsSidebarVisible: false});
    }


    /**
     * Event handler to call when vertical histogram dialog window is closed.
     */
    onHideHistogramDialog() {
        if (this.state.onDeselectFromRater != null)
            this.state.onDeselectFromRater();
        this.setState({
            showHistogramDialog: false,
            onDeselectFromRater: null
        })
    }

    setPlotSize() {
        if (this.histogramContainer != null) {
            console.log('Calculate height and width');
            const rect = this.histogramContainer.getBoundingClientRect();
            this.setState({height: rect.height, width: rect.width});
        }
    }



    render() {
        const {experimentProperties, plotData, plotSettings, modelParameters,modelParametersState, plotDataState} = this.props;
        const {width, height,selected,manualToolConfiguration} = this.state;

        return (
            <div id="avatarId" className="ui-g-12 ui-g-nopad" ref={node => this.histogramContainer = node}>
                {plotDataState !== REQUEST_STATUS_SUCCESS && plotDataState !== REQUEST_STATUS_FAIL &&
                 <div className="spinner-centered">Loading dataset <i className="fa fa-spinner fa-spin"/></div>}
                {plotDataState === REQUEST_STATUS_FAIL &&
                 <div className="spinner-centered">No data available</div>}
                {plotSettings != null && plotData != null && plotData.length === 0 &&
                 plotDataState === REQUEST_STATUS_SUCCESS && plotSettings.groups.length === 0 && plotSettings.mode === 0 &&
                 <div style={{fontSize: "20px", textAlign: "center", color: "red"}}>
                    Please select the analysis configuration from the sidebar
                 </div>
                }
                {width != null && height != null
                 && plotDataState === REQUEST_STATUS_SUCCESS && plotSettings.mode === 0 && plotData.length === 0 && plotSettings.groups.length > 0 &&
                 <div style={{fontSize: "20px", textAlign: "center", color: "red"}}>
                    No ROIs available for the cases and groups selection! Please select a new analysis configuration from the sidebar
                 </div>}
                {width != null && height != null && modelParametersState === REQUEST_STATUS_SUCCESS
                 && plotDataState === REQUEST_STATUS_SUCCESS && plotSettings.mode === 0 && plotData.length > 0 &&
                 <BlandAltmanPlot
                    yLabel={plotSettings.percentageDifference? "(Lesion count Group 1 - Lesion count Group 2)/ Groups lesion count mean\n[Percentage Difference]": "Lesion count Group 1 - Lesion count Group 2 [Difference]"}
                    xLabel="Lesion count [mean]"
                    yLabelTip={plotSettings.percentageDifference? "(Lesion count Group 1 - Lesion count Group 2)/ Groups lesion count mean\n[Percentage Difference]": "Lesion count Group 1 - Lesion count Group 2 [Difference]"}
                    xLabelTip="Lesion count [mean]"
                    length={(!(plotData != null)) ? -1 : plotData.length}
                    outerWidth={width}
                    outerHeight={height}
                    data={plotData}
                    colorCat="userId"
                    onDataPointClick={(d, onDeselect) => {
                        this.onDataPointClick(d, onDeselect)
                    }}
                    avatarSize={9}
                    selected={this.state.selected}
                    viewId={"BlandAltmanView"}
                    prefixId="avatar"
                    statsServerState={modelParametersState}
                    modelParameters = {modelParameters}
                    meanLineWidth={2}
                    percentageDifference={plotSettings.percentageDifference}
                 />}

                {plotDataState === REQUEST_STATUS_SUCCESS &&
                 <React.Fragment>
                    <a>
                        <i className="fa fa-fw fa-ellipsis-v"
                            title="Configuration"
                            onClick={(e) => this.setState({optionsSidebarVisible: true})}
                            style={{position: "fixed", top: "70px", right: "10px",fontSize:"20px"}}/>
                    </a>
                    <ContainerAgreementModelsSidebar
                        visible={this.state.optionsSidebarVisible}
                        position="right"
                        onHide={() => this.onHideSidebar()}
                    />
                 </React.Fragment>}
                
                {plotSettings.mode === 1 && plotData[0] != null && plotData[0].matchingResults != null &&
                 
                 <div className="ui-g-12" style={{height: '100%'}}>
                    <h2 style={{fontWeight: 'normal'}}>Matching results</h2>
                    <DataTable 
                        value={plotData[0].matchingResults}
                        groupField="realCase"
                        scrollable={true}
                        scrollHeight="90%"
                        style={{overflowWrap:'break-word'}}
                    >
                        <Column header="Case" field="realCase" filter={true}/>
                        <Column header="Hypothetical lesions colors and contributors" field="groups" filter={true}
                                filterMatchMode='custom'
                                filterFunction={(rowData, filter)=>{
                                    if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
                                        return true;
                                    }
                            
                                    if (rowData === undefined || rowData === null) {
                                        return false;
                                    }
                        
                                    if (rowData != null){
                                        return rowData.some( g => g.contributors.some(c => c.userId.includes(filter)));
                                    } else {
                                        return false;
                                    }
                                }}
                                body={({groups}) => {
                                    return (
                                        <DataTable value={groups}>
                                            <Column header="Lesion Color"
                                                    style={{textAlign: 'center'}}
                                                    body={(groupData)=>{
                                                        return (
                                                            <span
                                                                style={{
                                                                    display:'inline-block',
                                                                    width: '32px',
                                                                    height: '32px',
                                                                    verticalAlign: 'middle',
                                                                    background: groupData.color
                                                                }}>
                                                            </span>
                                                        );
                                                    }}/>
                                            <Column header="Contributors" field="contributors"
                                                    body={(groupData) => {
                                                        return (
                                                            <ul>
                                                                {groupData.contributors.map(contributor => <li>{contributor.userId}</li>)}
                                                            </ul>
                                                        );
                                                    }}/>
                                        </DataTable>
                                    );
                                }}
                        />
                        <Column header="Visualization"
                                style={{textAlign: 'center'}}
                                body={(rowData)=>{
                                        return (
                                        <Button
                                            label="Open viewer"
                                            onClick={()=>{this.onDataPointClick(rowData, ()=>{})}}
                                        />);
                        }}/>

                    </DataTable>
                 </div>}
                <Dialog header={plotSettings.mode === 0? selected?`Annotations: ${selected.realCase}` : "Annotations":
                                                         selected? `Matching for ${selected.realCase}`: "Matching"}
                        closeOnEscape={true}
                        resizable={true}
                        visible={this.state.showMRIDialog}
                        modal={false}
                        onHide={this.onHideVolumeDialog}
                        contentStyle={{
                            minHeight: (window.innerHeight*0.9),
                            minWidth: (window.innerWidth/2)
                        }}
                        style={{maxHeight: (window.innerHeight),
                                minHeight: (window.innerHeight),
                                maxWidth: window.innerHeight/250 < 3? window.innerWidth/2 : window.innerWidth*0.9,
                                minWidth: (window.innerWidth/2),
                                overflowWrap:"break-word"}}>
                    {selected != null && manualToolConfiguration!=null &&
                     <ContainerManualTool
                         predefinedToolConfiguration={manualToolConfiguration}
                     />}
                </Dialog>
            </div>
        )
    }
}

AgreementModelsPanel.defaultProps = {
    plotDataState:"",
    modelParametersState:""
};
AgreementModelsPanel.propTypes = {
    experimentId: PropTypes.object.isRequired, //from React-router
    initializeBlandAltmanPlot: PropTypes.func.isRequired,
    clearDataset:PropTypes.func.isRequired,
    plotData: PropTypes.array,
    plotSettings: PropTypes.object,
    plotDataState:PropTypes.string.isRequired,
    modelParameters: PropTypes.array.isRequired,
    modelParametersState: PropTypes.string.isRequired,
    results: PropTypes.object,
    resultsState: PropTypes.string,
    experimentProperties: PropTypes.object, //passed from parent component through own props to give ids of experiment, workflow, etc
    clearInteractivePlotState:PropTypes.func,
    match:PropTypes.object.isRequired
};
