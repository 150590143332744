import {
   TASK_LIST_REQUEST, TASK_LIST_FAIL, TASK_LIST_SUCCESS
} from '../action/actionType'
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";

/**
 *
 * Reducer responsible for management of tasks related to workflows
 *
 * @param state - state of tasks, ListState:status for the list of optional workflows,
 * workflows: optional workflows, workflowVariable: measurement for which the list of matching workflows is created
 * @param action
 * @returns {*}
 */
export const taskListReducer = (state = { taskListState: '', tasks: {}, workflowId:'' }, action) => {
    // console.log("taskListReducer", action);
    switch (action.type) {
        case TASK_LIST_REQUEST:
            return Object.assign({},state, {
                taskListState: REQUEST_STATUS_REQUESTED,
                workflowId:action.workflowId
            });
        case TASK_LIST_FAIL:
            return Object.assign({},state, {
                taskListState: REQUEST_STATUS_FAIL,
                workflowId:action.workflowId
            });
        case TASK_LIST_SUCCESS:
            return Object.assign({}, state, {
                taskListState: REQUEST_STATUS_SUCCESS,
                tasks:action.tasks,
                workflowId:action.workflowId
            });
        default:
            return state;
    }
};