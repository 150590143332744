import {connect} from "react-redux";
import React from "react";
import {
  clearSaveAnnotationsTableDefinition,
  getAnnotationsList, saveAnnotationColumn,
} from "../action/AnnotationAction";
import AnnotationColumnBuilder from "../component/annotationTool/AnnotationColumnBuilder";
import {getQuestionListActionCreator} from "../action/QuestionAction";
import {getOntologyListActionCreator} from "../action/OntologyAction";


const mapStateToProps = (state,ownProps) => {
  return {
    messageQueue: state.messaging.msgQueue,
    allColumns: state.visu.annotations.annotationsList,
    allColumnsState: state.visu.annotations.annotationsListState,
    questionList:state.visu.questions.questionList,
    questionListState:state.visu.questions.questionListState,
    ontologyList: state.visu.ontologies.ontologyList,
    ontologyListState: state.visu.ontologies.ontologyListState,
    data: state.visu.annotations.annotationsData,
    saveAnnotationState:state.visu.saveAnnotations.saveAnnotationState,
    saveAnnotationError:state.visu.saveAnnotations.saveAnnotationError,
    saveAnnotationResponse:state.visu.saveAnnotations.saveAnnotationResponse
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAnnotationsList:()=>{
      dispatch(getAnnotationsList())
    },
    getQuestionsList:()=>{
      dispatch(getQuestionListActionCreator())
    },
    getOntologyList:()=>{
      dispatch(getOntologyListActionCreator())
    },
    saveAnnotationColumn:(mode,payload)=>{
      dispatch(saveAnnotationColumn(mode,payload))
    },
    clearSaveAnnotationsTableDefinition:()=>{
      dispatch(clearSaveAnnotationsTableDefinition())
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);


export const ContainerAnnotationColumnBuilder = containerCreator(AnnotationColumnBuilder);