import {
    SINGLE_RESULT_VIEW_FAIL, SINGLE_RESULT_VIEW_SUCCESS, SINGLE_RESULT_VIEW_REQUEST, CLEAR_SINGLE_RESULT_STATE
} from '../action/actionType'
import {combineReducers} from "redux";


export const SingleResultReviewReducer = ( state = { singleResult: {} }, action) => {

    switch (action.type) {
      
        case SINGLE_RESULT_VIEW_REQUEST:
          return Object.assign({}, state, {
              status: "requesting"
          });
        
        case  SINGLE_RESULT_VIEW_FAIL:
          return Object.assign({}, state, {
              error: action.error,
              status: "error"
          });
        
        case SINGLE_RESULT_VIEW_SUCCESS:
            return Object.assign({}, state, {
                singleResult: {"workload": action.result},
                status: "success"
            });
            
        case CLEAR_SINGLE_RESULT_STATE:
          return Object.assign({}, state, {
              singleResult: {},
              status: "",
              error: ""
          });

        default:
            return state;
    }
};


