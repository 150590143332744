
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {
    TGAME_EXPERIMENT_RESULTS_FAIL, TGAME_EXPERIMENT_RESULTS_REQUEST,
    TGAME_EXPERIMENT_RESULTS_SUCCESS, TGAME_IMAGE_CLEAR, TGAME_IMAGE_FAIL, TGAME_IMAGE_REQUEST, TGAME_IMAGE_SUCCESS
} from "../action/actionType";

/**
 *
 * Reducer responsible for management of tasks related to workflows
 *
 * @param state - state of tasks, ListState:status for the list of optional workflows,
 * workflows: optional workflows, workflowVariable: measurement for which the list of matching workflows is created
 * @param action
 * @returns {*}
 */
export const tabletGameReducer = (state = { resultsState: '', results: {},tabletExperimentId:'', mriImage:{},
leftOverlay:{}, leftFreq:{}, rightOverlay:{},rightFreq:{}} , action) => {
    // console.log("tabletGameReducer", action);
    switch (action.type) {
        case TGAME_EXPERIMENT_RESULTS_REQUEST:
            return Object.assign({},state, {
               resultsState: REQUEST_STATUS_REQUESTED,
               tabletExperimentId:action.tabletExperimentId
            });
        case TGAME_EXPERIMENT_RESULTS_FAIL:
            return Object.assign({},state, {
                resultsState: REQUEST_STATUS_FAIL,
                tabletExperimentId:action.tabletExperimentId
            });
        case TGAME_EXPERIMENT_RESULTS_SUCCESS:
            return Object.assign({},state, {
                resultsState: REQUEST_STATUS_SUCCESS,
                tabletExperimentId:action.tabletExperimentId,
                results:action.results
            });
        case TGAME_IMAGE_REQUEST:{
            state[action.propertyName]['state']=REQUEST_STATUS_REQUESTED;
            return Object.assign({},state);}
        case TGAME_IMAGE_FAIL:{
            state[action.propertyName]['state']=REQUEST_STATUS_FAIL;
            state[action.propertyName]['error']=action.error;
            return Object.assign({},state);}
        case TGAME_IMAGE_SUCCESS:{
            state[action.propertyName]=action.image;
            state[action.propertyName]['state']=REQUEST_STATUS_SUCCESS;
            return Object.assign({},state);}
        case TGAME_IMAGE_CLEAR:{
            state[action.propertyName]={};
            return Object.assign({},state);}
        default:
            return state;
    }
};