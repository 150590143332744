import {
  HOST_URL, IDENTIFICATION_TOOL_TYPE, LINK_ALL_TOOL, MAIN_TOOL, ORIENTATION_LUT,
  REQUEST_STATUS_FAIL,
  REQUEST_STATUS_REQUESTED,
  REQUEST_STATUS_SUCCESS, SAMPLER_TOOL, SEGMENTATION_METRICS_TOOL
} from "../../../Constants";
import {
  CLEAR_ANNOTATOR_STATE,
  CLEAR_MANUAL_TOOL_STATE,
  MANUAL_TOOL_CONFIGURATION_REQUESTED,
  MANUAL_TOOL_CONFIGURATION_SUCCESS,
  UPDATE_IMAGE_OPTIONS,
  UPDATE_MANUAL_TASK_STATE,
  UPDATE_MANUAL_TOOL_CONFIGURATION,
  UPDATE_MANUAL_TOOL_PROPERTY,
  UPDATE_MANUAL_TOOL_STATE,
} from "./actionType";
// import {spineFinalLabels4, spineLabelsForNMOProject} from "../tests/finalLabels";
// import nmoProjectAnnotationTableDefinition from "../../jsonDocs/annotation/NMOTableDefinition.json"
import {
  convertToAnnotationsTransferObject,
  parseAndLoadAnnotationDefinition,
  parseAndLoadAnnotationFormDefinition,
  parseAndLoadROIListData,
  successAnnotationsData,
  successAnnotationsFormData,
  updateAnnotationData,
  updateActiveROI,
  convertFromAnnotationsTransferObject,
  convertFromROITransferObject,
  updateAnnotationFormData
} from "./AnnotationAction";
import {loadImageData, saveImageEntity} from "./ImageAction";
import {clearViewersState, initializeViewers, updateViewerProperty, updateViewersState} from "./ViewerAction";
import axios from "axios/index";
import {
  getNextOrPreviousTask,
  setAcknowledgeSignal,
  setManualTask
} from "../../dashboard/action/ManualTaskAction";
import {getScoreResults, getSkillResults} from "../../skills/action/SkillAction";
import i18n from "i18next";
import {findPrimaryImageForOverlayKey, initializeScenes, updateScenesOnInputLoaded} from "./SceneAction";
import {
  getLUT,
  getLUTDescription,
  requestLUT,
  requestLUTDescription,
  successLUT,
  successLUTDescription
} from "./LUTAction";
import {getPoly} from "./PolyAction";
import {getNestedProp} from "../../helpers/expressions";
import {updateMaterializedTaskResults} from "./ManualTaskAction";
import {getROI} from "./ROIAction";
import {getNested} from "../../helpers/comparators";
import {initializeForms} from "./FormsAction";
import {getConnectome} from "./ConnectomeAction";
// import {ORIENTATION_OPTIONS} from "../../vtk/Viewer2D";


//****************************************************************************
//
//                                Constants and Factories
//
//****************************************************************************

const IMAGE_ENTITY_IN_OUT = "imageEntityInOut";
const IMAGE_ENTITY_IN_OUT_FILE_FORMAT = "imageEntityInOut_FileFormat";
const ROI_IN_OUT = "roiInOut";
const ROI_WITH_ANNOTATION = "roiWithAnnotationsInOut";
const ANNOTATION_TABLE_IN_OUT = "annotationTableDefinitionInputOutput";
const ANNOTATION_FORM_IN_OUT = "annotationFormDefinitionInputOutput";
const ANNOTATION_TABLE_DATA_IN_OUT = "annotationTableDataInOut";
const ANNOTATION_FORM_DATA_IN_OUT = "annotationFormDataInOut";
const LOOKUP_TABLE = "lookUpTable";
const LOOKUP_TABLE_DESCRIPTION = "lookUpTableDescription";
const POLY_ROI_IN_OUT = "polyRoiInOut";
const CONNECTOME_IN_OUT = "connectome";
const IMPLICIT = "IMPLICIT";
const EXPLICIT = "EXPLICIT";

export const ToolIOTypes = [IMAGE_ENTITY_IN_OUT,IMAGE_ENTITY_IN_OUT_FILE_FORMAT,ROI_IN_OUT,ROI_WITH_ANNOTATION,ANNOTATION_TABLE_IN_OUT,ANNOTATION_FORM_IN_OUT,ANNOTATION_TABLE_DATA_IN_OUT,LOOKUP_TABLE,LOOKUP_TABLE_DESCRIPTION,
  POLY_ROI_IN_OUT];
;
/**
 *
 * @param code
 * @return {string|*}
 */
export const getPortLabelByType = (code) =>{
  switch(code){
    case IMAGE_ENTITY_IN_OUT: return "Image";
    case ROI_IN_OUT: return "ROI";
    case LOOKUP_TABLE: return "Colors LUT";
    case LOOKUP_TABLE_DESCRIPTION: return "Description LUT";
    case ANNOTATION_FORM_IN_OUT: return "Annotation form";
    case ANNOTATION_TABLE_IN_OUT: return "Annotation table";
    case POLY_ROI_IN_OUT: return "Polydata";
    case ANNOTATION_TABLE_DATA_IN_OUT: return "Annotation data";
    case IMAGE_ENTITY_IN_OUT_FILE_FORMAT: return "File format descriptor";
    default: return code;
  }
};

//----------------------------------Payloads and Paths------------------------------------------------

const SLICE_PAYLOAD = (imageId, value) => {
  return {
    "typeROI": "IMPLICIT",
    "reference": {
      "imageEntityId": imageId,
    },
    "properties": {
      "implicit": {
        "implicitRelationWithRoi": "CAUDAL_EXTERNAL_LIMIT",
        "implicitGeometryPointer": "SLICE",
        "planeProperties": {
          "anatomicalDirection": "AXIAL",
          "planeSliceNumberInAnatomicalDirection": value
        }
      }
    }
  }
};
/**
 *
 * @param imageId
 * @param startIJK
 * @param startXYZ
 * @param endIJK
 * @param endXYZ
 * @return {{reference: {imageEntityId: RECTANGLE_PAYLOAD.props}, typeROI: string, properties: {implicit: {rectangleProperties: {pointA: {worldCoordinates: {x: *, y: *, z: *}, imageCoordinates: {i: *, j: *, k: *}}, pointB: {worldCoordinates: {x: *, y: *, z: *}, imageCoordinates: {i: *, j: *, k: *}}}, implicitGeometryPointer: string}}}}
 * @constructor
 */
const RECTANGLE_PAYLOAD = (imageId, startIJK, startXYZ, endIJK, endXYZ) => {
  return {
    "typeROI": "IMPLICIT",
    "reference": {
      "imageEntityId": imageId
    },
    "properties": {
      "implicit": {
        "implicitGeometryPointer": "RECTANGLE",
        "rectangleProperties": {
          "pointA": {
            "worldCoordinates": {
              "x": startXYZ[0],
              "y": startXYZ[1],
              "z": startXYZ[2]
            },
            "imageCoordinates": {
              "i": startIJK[0],
              "j": startIJK[1],
              "k": startIJK[2]
            }
          },
          "pointB": {
            "worldCoordinates": {
              "x": endXYZ[0],
              "y": endXYZ[1],
              "z": endXYZ[2]
            },
            "imageCoordinates": {
              "i": endIJK[0],
              "j": endIJK[1],
              "k": endIJK[2]
            }
          }
        }
      }
    }
  }
};


const IMAGE_ENTITY_PAYLOAD = {
  "imageEntityType": "discreteLabelMap",
  "imageEntityFileFormat": "nii.gz"
};

const CONFIRMATION_PAYLOAD = {
  "confirmation": true
};

/**
 *
 * @param originalImageId - id of original image
 * @param labelmapImageId - id of labelmap
 * @param labelMapValue - intensity
 */
const IMAGE_ENTITY_ROI_PAYLOAD = (originalImageId, labelmapImageId, labelMapValue) => {
  return {
    "typeROI": EXPLICIT,
    "reference": {
      "imageEntityId": originalImageId,
    },
    "properties": {
      "explicit": {
        "labelMap": {
          "imageEntityId": labelmapImageId,
          "labelIntensity": labelMapValue
        }
      }
    }
  }
};


const PATH_TO_SEND_ROI = (materializedTaskId, outputKey) => {
  return HOST_URL + `/api/roi?materializedTaskId=${materializedTaskId}&outputKey=${outputKey}`;
};

const PATH_TO_SEND_IMAGE_ENTITY_UUID = (materializedTaskId, outputKey) => {
  return HOST_URL + `/api/image-entity-uuid?materializedTaskId=${materializedTaskId}&outputKey=${outputKey}`;
};

const PATH_TO_SUBMIT_TASK = (materializedTaskId) => {
  return HOST_URL + `/api/materialized-task/${materializedTaskId}/result/submit`
};

const PATH_TO_SEND_NUMBER = (materializedTaskId,outputKey) => {
  return HOST_URL + `/api/number?materializedTaskId=${materializedTaskId}&outputKey=${outputKey}`;
};

const PATH_TO_SUBMIT_CONFIRMATION = (materializedTaskId, outputKey) => {
  return HOST_URL + `/api/confirmation?materializedTaskId=${materializedTaskId}&outputKey=${outputKey}`;
};

const PATH_TO_POST_ANNOTATIONS_DATA = (uuid, key) => `/api/annotation-table-data?materializedTaskId=${uuid}&outputKey=${key}`;

const PATH_TO_POST_ANNOTATIONS_LIST = (uuid, key) => `/api/annotation-list-data?materializedTaskId=${uuid}&outputKey=${key}`;

const getLanguage = () => {
  return (i18n.language != null) ? "&lang=" + i18n.language.toLowerCase().substring(0, 2) : ""
};

// const PATH_TO_GO_TO_NEXT_CASE = (uuid, mfKey, expId) => `/api/workload?miniWorkflowSet=${uuid}&miniWorkflow=${mfKey}&experiment=${expId}${getLanguage()}`;


const VIEWER_PROPERTIES = [
  "slicingRanges_AXIAL_inferiormostSliceNumber",
  "slicingRanges_AXIAL_superiormostSliceNumber",
  "slicingRanges_SAGITTAL_leftmostSliceNumber",
  "slicingRanges_SAGITTAL_rigthmostSliceNumber",
  "slicingRanges_CORONAL_posteriormostSliceNumber",
  "slicingRanges_CORONAL_anteriormostSliceNumber",

  "windowLevel_windowWidth",
  "windowLevel_levelValue",

  "currentSliceNumber_AXIAL",
  "currentSliceNumber_SAGITTAL",
  "currentSliceNumber_CORONAL",

  "orientation",

  "isEnabledForSlice"  //property allowing user to define whether viewer should be in "enabled" stet - this is used for displaying dotted rim
];


export const SLICING_RANGE = {
  "slicingRanges_AXIAL_inferiormostSliceNumber":{orientation:2},
  "slicingRanges_AXIAL_superiormostSliceNumber":{orientation:2},
  "slicingRanges_SAGITTAL_leftmostSliceNumber":{orientation:0},
  "slicingRanges_SAGITTAL_rigthmostSliceNumber":{orientation:0},
  "slicingRanges_CORONAL_posteriormostSliceNumber":{orientation:1},
  "slicingRanges_CORONAL_anteriormostSliceNumber":{orientation:1},
};

//============================Predefined object


const GLOBAL_ROI = {
  "uuid": "e64e724ff6782f91b0ad79222000ebde",
  "_roi": {
    "roiType": "IMAGE",
    "roiCellIJK": [
      -1, -1, -1
    ],
    "roiPosition": [
      0, 0, 0
    ],
    "imageId": "e64e724ff6782f91b0ad79222000ebde"
  },
  "_id": "-1"
};

//****************************************************************************
//
//                                Actions
//
//****************************************************************************

// export const restoreSnapshot = (viewer,index) => {
//     return (dispatch,getState) => {
//         let anns  = getState().visu.annotations.annotations;
//         dispatch (updateAnnotationData(data));
//     };
// };

const clearAnnotator = () => ({
  type: CLEAR_ANNOTATOR_STATE,
});
export const clearAnnotatorState = () => {
  return (dispatch) => {
    dispatch(clearAnnotator());
  }
};

const updateImageOptions = (options) => ({
  type: UPDATE_IMAGE_OPTIONS,
  options
});

/**
 * Action clearing all tools (widget) for a scene (Tool like SegmentationTool, Slice Selection Tool etc.)
 * @return {{type, manualToolState: *, id: *}}
 */

const clearManualToolState = () => ({
  type: CLEAR_MANUAL_TOOL_STATE,
});

/**
 * Action Creator clearing all tools (widget) for a scene (Tool like SegmentationTool, Slice Selection Tool etc.)
 * @return {{type, manualToolState: *, id: *}}
 */
export const clearManualToolStateAC = () => {
  return (dispatch) => {
    dispatch(clearManualToolState());
  }
};

/**
 * Updates whole tool, eg. adds or replace "brushTool", "fillingTool", etc.
 * @param manualToolState - tool to add/update
 * @param id - id of tool
 * @return {{type, manualToolState: *, id: *}}
 */
export const updateManualToolState = (manualToolState, id) => ({
  type: UPDATE_MANUAL_TOOL_STATE,
  manualToolState,
  id
});
export const updateManualToolProperty = (id, property, value) => ({
  type: UPDATE_MANUAL_TOOL_PROPERTY,
  id,
  property,
  value
});

export const updateManualToolConfiguration = (configuration) => ({
  type: UPDATE_MANUAL_TOOL_CONFIGURATION,
  configuration
});


/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestManualToolConfiguration = () => ({
  type: MANUAL_TOOL_CONFIGURATION_REQUESTED
});

/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const successManualToolConfiguration = (data) => ({
  type: MANUAL_TOOL_CONFIGURATION_SUCCESS,
  configuration: data
});

/**
 * Function setting widget configuration and initial state in Redux Store.
 * Initialization is based on looking for defaultValue in main level or inside properties.
 *   "eraserTool":{
                "properties":{
                    "size":{ "defaultValue": 1 }
                }
            },
 "labelmapOpacity":{ "defaultValue":1 }
 */
export const initializeWidgets = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const widgets = mTask.miniWorkflow.currentTool.configuration.widgets;
    //initialize State for widgets
    if (widgets != null) {
      //store configuration in Store (to have easy access)
      dispatch(updateManualToolConfiguration(widgets));
      Object.keys(widgets).forEach(
        (el) => {
          if (widgets[el]['properties'] != null)
            Object.keys(widgets[el]['properties']).forEach(keyProperty => {
              dispatch(updateManualToolProperty(el, keyProperty, widgets[el]['properties'][keyProperty]['defaultValue']));
            });
          if (widgets[el]['defaultValue'] != null) {
            dispatch(updateManualToolProperty(el, el, widgets[el]['defaultValue']));
          }
          // initialize main tool
          if (el === MAIN_TOOL) {
            Object.keys(widgets[MAIN_TOOL]).forEach(keyProperty => {
              dispatch(updateManualToolProperty(el, keyProperty, widgets[MAIN_TOOL][keyProperty]));
            });
          }
          // add input id to metrics tool
          if (el === SEGMENTATION_METRICS_TOOL) {
              const key = widgets[SEGMENTATION_METRICS_TOOL].properties.input.defaultValue;
              dispatch(updateManualToolProperty(el, "input", mTask.miniWorkflow.currentMaterializedTask.inputs[key].value));
          }
        }
      );

      // parse bindings - inputs provided by tool
      const bindingToolInputsToWidgetInputs = mTask.miniWorkflow.currentTool['bindingToolInputsToWidgetInputs'];

      if (bindingToolInputsToWidgetInputs != null) {
        bindingToolInputsToWidgetInputs.forEach(el => {
          console.log(el);
          const inputs = mTask.miniWorkflow.currentMaterializedTask.inputs;
          dispatch(updateManualToolProperty(el['widget']['widgetKey'], el['widget']['property'], inputs[el["inputTool"]]["value"]));
        });
      }


      //initialize State of viewers dependent on widget properties
      if (widgets.hasOwnProperty(LINK_ALL_TOOL) && widgets[LINK_ALL_TOOL]['defaultValue']) {
        Object.keys(getState().visu.viewers.viewersState)
          .forEach((el) => dispatch(updateViewerProperty(el, 'linked', true)))
      }
    }
  }
};
/**
 * Function used for demos and visualization Tools.
 * @param predefinedTask
 * @return {function(...[*]=)}
 */
export const initializePredefinedConfiguration = (predefinedTask) => {
  return (dispatch) => {
    dispatch(clearAnnotatorState());
    dispatch(setManualTask(predefinedTask));
    dispatch(initializeTool());

  }
};

/**
 * Initialize inputs for manual tool.
 * Inputs are loaded in asynchronous way.
 * @return {function(...[*]=)}
 */
export const initializeInputs = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const inputKeys = Object.keys(mTask.miniWorkflow.currentTool.inputs);
    inputKeys.forEach((el) => {
      if (mTask.miniWorkflow.currentTool.inputs[el].type === IMAGE_ENTITY_IN_OUT) {
        parseAndLoadImageInput(mTask, el, getState, dispatch, "image");
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === ROI_IN_OUT
        && mTask.miniWorkflow.currentTool.inputs[el].typeROI === EXPLICIT) {
        parseAndLoadImageInput(mTask, el, getState, dispatch, "overlay");
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === ANNOTATION_TABLE_IN_OUT) {
        parseAndLoadAnnotationTable(mTask, el, getState, dispatch,false);
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === ANNOTATION_FORM_IN_OUT) {
        parseAndLoadAnnotationForm(mTask, el, getState, dispatch,false);
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === LOOKUP_TABLE) {
        dispatch(getLUT(mTask.miniWorkflow.currentMaterializedTask.inputs[el].value));
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === LOOKUP_TABLE_DESCRIPTION) {
        dispatch(getLUTDescription(mTask.miniWorkflow.currentMaterializedTask.inputs[el].value));
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === POLY_ROI_IN_OUT) {
        dispatch(getPoly(mTask.miniWorkflow.currentMaterializedTask.inputs[el].value));
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === CONNECTOME_IN_OUT) {
        dispatch(getConnectome(mTask.miniWorkflow.currentMaterializedTask.inputs[el].value));
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === ROI_IN_OUT
        && mTask.miniWorkflow.currentTool.inputs[el].typeROI !== EXPLICIT
        && mTask.miniWorkflow.currentTool.inputs[el].isList === true
        && !(mTask.miniWorkflow.currentMaterializedTask.hasOwnProperty("temporaryResult"))
        && !(mTask.miniWorkflow.currentMaterializedTask.hasOwnProperty("results"))) // If there are temporary results, no need to load the roi list, it's already in the saved table
      {
        parseAndLoadROIList(mTask, el, getState, dispatch);
      }
      if (mTask.miniWorkflow.currentTool.inputs[el].type === ROI_WITH_ANNOTATION
        && !(mTask.miniWorkflow.currentMaterializedTask.hasOwnProperty("results"))) {
        const {data,activeRoiId} = convertFromAnnotationsTransferObject(mTask.miniWorkflow.currentMaterializedTask.inputs[el].value);
        dispatch(updateAnnotationData(data));
        if (activeRoiId != null)
          dispatch(updateActiveROI(activeRoiId));
      }
    });
  }
};

/**
 * This function is responsible for fetching all binaries stored in materializedTask results.
 * @return {function(...[*]=)}
 */
export const initializeResults = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const outputKeys = Object.keys(mTask.miniWorkflow.currentTool.outputs);
    const currentResults = mTask.miniWorkflow.currentMaterializedTask.results;

    if (currentResults!=null) {
      outputKeys.forEach((el) => {
        const outDefinition = mTask.miniWorkflow.currentTool.outputs[el];

        if (outDefinition.type === ROI_IN_OUT
          && outDefinition.typeROI === EXPLICIT
          && getNestedProp([el, "doc", "properties", "explicit", "labelMap", "imageEntityId"], currentResults) != null
        ) {
          const url = `/api/image-entity/${getNestedProp([el, "doc", "properties", "explicit", "labelMap", "imageEntityId"], currentResults)}/file`;
          dispatch(loadImageData(getNestedProp([el, "doc", "properties", "explicit", "labelMap", "imageEntityId"], currentResults), null, url, "overlay"));
        }
        if (outDefinition.type === ANNOTATION_TABLE_DATA_IN_OUT
          && getNestedProp([el,"doc","reference","annotationTableDefinitionId"],currentResults)!=null ) {
          parseAndLoadAnnotationTable(mTask, el, getState, dispatch, true);
        }
        if (outDefinition.type === ANNOTATION_FORM_DATA_IN_OUT
          && getNestedProp([el,"doc","reference","annotationTableDefinitionId"],currentResults)!=null ) { //it's ok, thas same as above
          parseAndLoadAnnotationForm(mTask, el, getState, dispatch, true);
        }
      });
    }
  }
};
/**
* Initialize scene elements like luts or lutdescriptions that are not provided as inputs.
 * Use keys to keep them.
* @return {function(...[*]=)}
  */
export const initializeNonInputs = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const _luts = mTask.miniWorkflow.currentTool.configuration.luts;
    const _lutDescs = mTask.miniWorkflow.currentTool.configuration.lutDescriptions;

    if (_luts!=null && Object.keys(_luts).length>0) {
      Object.keys(_luts).forEach((lutKey)=>{
        if(_luts[lutKey]['fromInputs'] === false){
          dispatch(requestLUT(lutKey)); //prepare state in reducer
          dispatch(successLUT(lutKey,null));
          dispatch(updateScenesOnInputLoaded(lutKey));
        }
      });
    }

    if (_lutDescs!=null && Object.keys(_lutDescs).length>0) {
      Object.keys(_lutDescs).forEach((lutKey) => {
        if (_lutDescs[lutKey]['fromInputs'] === false) {
          dispatch(requestLUTDescription(lutKey)); //prepare state in reducer
          dispatch(successLUTDescription(lutKey, null));
          dispatch(updateScenesOnInputLoaded(lutKey));
        }
      });
    }
  }
};


/**
 * Initialize temporary results
 * @return {function(...[*]=)}
 */
export const initializeTemporaryResults = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    console.log("im here");
    if(mTask.miniWorkflow.currentMaterializedTask.hasOwnProperty("temporaryResult")) {
      console.log(mTask);

      const {data,activeRoiId} = convertFromAnnotationsTransferObject(mTask.miniWorkflow.currentMaterializedTask.temporaryResult);
      console.log(data);
      console.log(activeRoiId);
      dispatch(updateAnnotationData(data));
      if (activeRoiId != null)
          dispatch(updateActiveROI(activeRoiId));
    }
  }
};


/**
 * Preprocess lists in input and scene definitions. Replace lists with normal inputs, ie. modifies sections of currentTool.inputs,
 * currentTool.configuration.scenes and currentMaterializedTask.inputs.
 * For example materialized task input :
 * "image_list": {  "value":  ["abb87822-56b6-4588-9235-dbf653e91849",  "aab87822-56b6-4588-9235-dbf653e91849" ]}
 * is replaced with
 * "image_list_0": {"value":  "abb87822-56b6-4588-9235-dbf653e91849"}
 * "image_list_1": {"value":  "aab87822-56b6-4588-9235-dbf653e91849"}
 * @param dispatch
 * @param getState
 */
const preprocessLists = (dispatch,getState)=>{

  const mTask = getNested(getState(),"activity.manualTask.manualTask");
  const scenes = getNested(mTask,"miniWorkflow.currentTool.configuration.scenes");
  const inputDefinitions = getNested(mTask,"miniWorkflow.currentTool.inputs");
  const inputInstances = getNested(mTask,"miniWorkflow.currentMaterializedTask.inputs");
  const viewers = getNested(mTask,"miniWorkflow.currentTool.configuration.viewers.renderWindows");
  const viewerKeys = Object.keys(viewers);

  if (scenes!=null && inputDefinitions!=null){
    const keyArray = Object.keys(scenes);
    for (let i=0; i<keyArray.length;i++){ // thread-safe loop!
      const scene = scenes[keyArray[i]];
      const imageKey = scene.primaryImageInputKey;
      if (scene.hasOwnProperty("isList") && scene.isList && inputDefinitions[imageKey].isList){

        const values = inputInstances[imageKey].value;
        if (Array.isArray(values)){
          const possibleScenesToDisplay = [];
          for (let j=0;j<values.length;j++){
            const newKey = imageKey+"_"+j;
            // replace currentMaterializedTask inputs
            inputInstances[newKey] = Object.assign({},inputInstances[imageKey]);
            inputInstances[newKey].value = values[j];
            inputDefinitions[newKey] = Object.assign({},inputDefinitions[imageKey]);
            inputDefinitions[newKey].isList = false; // not list anymore

            // replace scenes
            scenes[keyArray[i]+"_"+j] = Object.assign({},scene);
            scenes[keyArray[i]+"_"+j].primaryImageInputKey = newKey;
            delete scenes[keyArray[i]+"_"+j].isList;
            possibleScenesToDisplay.push(keyArray[i]+"_"+j);
          }
          delete scenes[keyArray[i]];
          for (let v=0; v<viewerKeys.length;v++){
            if (viewers[v].displayScenes.defaultSceneToDisplay === keyArray[i]){
              viewers[v].displayScenes.defaultSceneToDisplay =
                (possibleScenesToDisplay[v]!=null)
                  ? possibleScenesToDisplay[v]
                  : possibleScenesToDisplay[0];
              viewers[v].displayScenes.possibleScenesToDisplay.push(...possibleScenesToDisplay);
              viewers[v].displayScenes.possibleScenesToDisplay.splice(viewers[v].displayScenes.possibleScenesToDisplay.indexOf(keyArray[i]), 1);
            }
          }
        }
      }
    }
  }

  // now remove inputs with isList
  const defArray = Object.keys(inputDefinitions);
  for (let l=0;l<defArray.length;l++){
    const definition = inputDefinitions[defArray[l]];
    if (definition.isList === true && (definition.type === "imageEntityInOut" || definition.typeROI === "EXPLICIT" ))
      delete inputDefinitions[defArray[l]];
  }

};

/**
 * ActionCreator for initializing tool and viewers state.
 * It uses manual Task kept in store which needs to be initialized!
 *
 * @returns {function(*,*)}
 */
export const initializeTool = () => {
  return (dispatch, getState) => {

    dispatch(clearViewersState());
    dispatch(clearManualToolState());
    console.log(getState().activity.manualTask.manualTaskState);
    if (getState().activity.manualTask.manualTaskState !== REQUEST_STATUS_SUCCESS) return;
    // parse image inputs

    preprocessLists(dispatch,getState);
    dispatch(initializeScenes()); //order is important -> first scenes, then inputs so scenes can observe inputs, finally viewers
    dispatch(initializeNonInputs()); // initialize scene elements that are kept in state but originates not from input (luts)
    dispatch(initializeInputs());
    dispatch(initializeResults());
    dispatch(initializeViewers());
    dispatch(initializeForms());
    dispatch(initializeWidgets());



  };
};


/**
 * Parses inputs of Image type and loads images and overlays.
 * @param mTask - manual task
 * @param key - input key
 * @param getState - middleware function to get access to Store
 * @param dispatch - redux dispatch
 * @param type -  {"image","overlay"}
 */
const parseAndLoadImageInput = (mTask, key, getState, dispatch, type) => {
  // const imagePool = (type === "image") ? getState().visu.images.images : getState().visu.images.overlays;
  const format = mTask.miniWorkflow.currentMaterializedTask.inputs[key].format;
  const imageId = mTask.miniWorkflow.currentMaterializedTask.inputs[key].value;

  const url = `/api/image-entity/${imageId}/file`;
  dispatch(loadImageData(imageId, format, url, type));
};

/**
 * Parses inputs of annotation table type and loads table definition and data ()
 * @param mTask
 * @param key
 * @param getState
 * @param dispatch
 * @param fromResults - flag indicating whether definition table shall be taken from inputs or results
 */
const parseAndLoadAnnotationTable = (mTask, key, getState, dispatch, fromResults) => {
  if (!fromResults){
    dispatch(parseAndLoadAnnotationDefinition(mTask.miniWorkflow.currentMaterializedTask.inputs[key].value));
    dispatch(successAnnotationsData([]));
  }
  else{
    dispatch(parseAndLoadAnnotationDefinition(getNestedProp(["miniWorkflow","currentMaterializedTask","results",key,"doc","reference","annotationTableDefinitionId"],mTask)));
    const {data,activeRoiId} = convertFromAnnotationsTransferObject(getNestedProp(["miniWorkflow","currentMaterializedTask","results",key,"doc"],mTask));
    console.log(data);
    console.log(activeRoiId);
    dispatch(updateAnnotationData(data));
    if (activeRoiId != null)
      dispatch(updateActiveROI(activeRoiId));
  }
};

/**
 * Parses inputs of annotation form type and loads form definition
 * In comparison to dynamic table (rows are defined by data),  Form is static structure and can be used to define
 * a single form of data, eg. questions related to image as a whole.
 * @param mTask
 * @param key
 * @param getState
 * @param dispatch
 * @param fromResults - flag indicating whether definition table shall be taken from inputs or results
 */
const parseAndLoadAnnotationForm = (mTask, key, getState, dispatch, fromResults) => {
  if (!fromResults) {
    dispatch(parseAndLoadAnnotationFormDefinition(mTask.miniWorkflow.currentMaterializedTask.inputs[key].value));
    //TODO Replace with input-output data from document - FOR FORM it is  ROI with location -1,-1,-1
    dispatch(successAnnotationsFormData([Object.assign({}, GLOBAL_ROI)]));
  }
  else{
    dispatch(parseAndLoadAnnotationFormDefinition(getNestedProp(["miniWorkflow","currentMaterializedTask","results",key,"doc","reference","annotationTableDefinitionId"],mTask)));
    const {data} = convertFromAnnotationsTransferObject(getNestedProp(["miniWorkflow","currentMaterializedTask","results",key,"doc"],mTask));
    dispatch(updateAnnotationFormData(data));
  }
};


/**
 * Parses inputs of annotation table type and loads table definition and data ()
 * @param mTask
 * @param key
 * @param getState
 * @param dispatch
 */
const parseAndLoadROIList = (mTask, key, getState, dispatch) => {
  const rois = (mTask.miniWorkflow.currentMaterializedTask.inputs[key].value != null)
    ? mTask.miniWorkflow.currentMaterializedTask.inputs[key].value
    : mTask.miniWorkflow.currentMaterializedTask.inputs[key];
  const readOnly = mTask.miniWorkflow.currentMaterializedTask.inputs[key].readOnly;
  const color = mTask.miniWorkflow.currentMaterializedTask.inputs[key].color;
  const isInvisibleInAnnotationTableHack = mTask.miniWorkflow.currentMaterializedTask.inputs[key].isInvisibleInAnnotationTableHack;
  const options = {roisInputKey: key, readOnly, color, isInvisibleInAnnotationTableHack};
  dispatch(parseAndLoadROIListData(rois, options));
};


/**
 * Helper function to use binding between tool output and viewer output.
 * @param viewerBindings - Array of bindings
 * @param toolKey - tool key
 * @param inputOutputFlag - enum of 'outputTool' or 'inputTool' value
 * @return {string | undefined}
 */
const getViewerForInputOutputTool = (viewerBindings, toolKey, inputOutputFlag) => {
  return viewerBindings.find(element => element[inputOutputFlag] === toolKey);
};

/**
 * Helper function to use binding between template and viewers json configuration.
 * @param viewerBindings
 * @param originalKey
 * @return {string | undefined}
 */
const getViewerKeyForTemplate = (viewerBindings, originalKey) => {
  return Object.keys(viewerBindings).find(key => viewerBindings[key] === originalKey);
};

/**
 *  Request to generate a new uuid for image to be saved. This will be id for acquisition doc in DB.
 * @return {string | undefined}
 */
async function getImageEntityId() {
  const response = await axios.post(HOST_URL + `/api/image-entity`, IMAGE_ENTITY_PAYLOAD);
  return response.data._id;
}


/**
 * Async function to send Submit Task signal.
 * @param materializedTaskId
 * @return {Promise<>}
 */
const submitTask = async (materializedTaskId) => {
  return (await axios.put(PATH_TO_SUBMIT_TASK(materializedTaskId)));
};


/**
 * Async function to send overlay.
 * It requires sequence of 2 steps that need to be done in proper sequence: getting image id and sending image
 * @param el - output key
 * @param getState - access to Store
 * @return {Promise<void>}
 */
const sendOverlay = async (el, getState) => {
  const mTask = getState().activity.manualTask.manualTask;
  const materializedTask = mTask.miniWorkflow.currentMaterializedTask;
  const bindingSceneElementsToToolOutputs = mTask.miniWorkflow.currentTool.bindingSceneElementsToToolOutputs;
  const roisConfiguration = mTask.miniWorkflow.currentTool.configuration.rois;
  const inputs = mTask.miniWorkflow.currentMaterializedTask.inputs;
  const overlayKeyFromOutputBinding = bindingSceneElementsToToolOutputs.find(item => item.outputTool === el).roiKeyName;
  const overlayConfiguration = roisConfiguration['overlays'][overlayKeyFromOutputBinding];

  const roiResultsValue = getNestedProp(["results",el,"value"],materializedTask);  // currentMaterializedTask results for a given key

  let labelmapImageId; // labelmap image uuid to store in ROI document
  let primaryImageId;  // primary image uuid to store in ROI document

  if (roiResultsValue!=null){ // if results already exists (saving was performed at least once)
    const roiForImage  = await getROI(roiResultsValue);

    labelmapImageId = getNestedProp(["properties","explicit","labelMap","imageEntityId"],roiForImage);
    primaryImageId = getNestedProp(["reference","imageEntityId"],roiForImage);

    const overlayToSend = getState().visu.images.overlays[labelmapImageId]['data'];  // get data for output from Image Pool

    // if segmentation key (overlay_key) has not been yet replaced with output key in scene layers, use roiKeyName from binding
    if (!(primaryImageId!=null))
      primaryImageId = findPrimaryImageForOverlayKey(getState, overlayKeyFromOutputBinding);

    await saveImageEntity(overlayToSend, labelmapImageId); // save binary data only
  }
  else { //if the results does not exist create new id

    const overlayId = (overlayConfiguration['fromInputs'])
      ? inputs[overlayConfiguration['imageInputKey']].value //if from Inputs: use uuid value from inputs
      : overlayKeyFromOutputBinding; //if not: use just key, eg. "overlay_key_1"

    primaryImageId = findPrimaryImageForOverlayKey(getState, overlayId);

    // No matter if you edit existing file, ie. {fromInput: true} OR create output from scratch, ie. {fromInput: false}
    // always generate new id (so for each task you have result)
    labelmapImageId = await getImageEntityId(); //get new uuid
    const overlayToSend = getState().visu.images.overlays[overlayId]['data'];  // get data for output from Image Pool
    await saveImageEntity(overlayToSend, labelmapImageId); // save binary data

    getState().visu.images.overlays[labelmapImageId] = getState().visu.images.overlays[overlayId];  // change Image Pool
    getState().visu.images.overlays[labelmapImageId]["uuid"] = labelmapImageId;
  }
  // always send roi so it can be created or replaced with different status = "SAVED",
  // otherwise submit API function will not validate ROI with status set already on "SUBMITTED"
  await axios.post(
    PATH_TO_SEND_ROI(materializedTask.uuid, el),
    IMAGE_ENTITY_ROI_PAYLOAD(primaryImageId, labelmapImageId, 1) // save roi description for binary data //FIXME True value for labelmap here!!!
  );
  console.log("Image ROI saved - labelmap:  ",labelmapImageId, " created based on original image: ", primaryImageId);
};

/**
 * Async function to send slice.
 * @param el - output key
 * @param getState - access to Store
 * @return {Promise<void>}
 */
const sendSlice = async (el, getState) => {
  const mTask = getState().activity.manualTask.manualTask;
  const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
  const viewerBindings = mTask.miniWorkflow.currentTool.configuration.viewers.layout.layoutOrder;
  const bindingViewersOutputsToToolOutputs = mTask.miniWorkflow.currentTool.bindingViewersOutputsToToolOutputs;
  const viewerInConfiguration = getViewerForInputOutputTool(bindingViewersOutputsToToolOutputs, el, 'outputTool');
  const viewerInTemplateKey = getViewerKeyForTemplate(viewerBindings, viewerInConfiguration['outputViewer']['viewerId']);
  const viewer = getState().visu.viewers.viewersState[viewerInTemplateKey];
  const image = getState().visu.images.images[viewer['imageId']];
  let valueToSend = viewer[viewerInConfiguration['outputViewer']['property']];
  const messageQueue = getState().messaging.msgQueue;

  if (getNestedProp(["outputViewer","function"],viewerInConfiguration)!=null){
    const shiftKey = getNestedProp(["outputViewer","function","keyInputNumberSlices"],viewerInConfiguration);
    const shift = getNestedProp(["miniWorkflow","currentMaterializedTask","inputs",shiftKey,"value"],mTask);
    const directionKey = getNestedProp(["outputViewer","function","keyInputDirection"],viewerInConfiguration);
    const direction = getNestedProp(["miniWorkflow","currentMaterializedTask","inputs",directionKey,"value"],mTask);

    try{
    if (getNestedProp(["outputViewer","function","operation"],viewerInConfiguration)==="ADDITION"){
      valueToSend+= Number(shift) * image['properties']['slicingDirections'][ORIENTATION_LUT(direction)]; // slicing direction can change sign
    }
    if (getNestedProp(["outputViewer","function","operation"],viewerInConfiguration)==="SUBTRACTION"){
      valueToSend-= Number(shift) * image['properties']['slicingDirections'][ORIENTATION_LUT(direction)]; // slicing direction can change sign
    }
    if (isNaN(valueToSend))
      messageQueue.show({
        sticky: false,
        life:5000,
        severity: 'error',
        summary: 'Error',
        detail:"Calculated slice value is not a number! Possible error in definition of function to calculate SLICE output."
      });
    }catch(e){
      messageQueue.show({
        sticky: false,
        life:5000,
        severity: 'error',
        summary: 'Error',
        detail:"Error in definition of function to calculate SLICE output."
      });
    }
  }
  // ----------- OLD HACK for operations -----------------------
  // if (el === "slice_number_axial_shifted_key") {
  //   valueToSend = valueToSend + 4 * image['properties']['slicingDirections'][2];
  // }
  // if (el === "slice_number_axial_shifted_key1") {
  //   valueToSend = valueToSend + 2 * image['properties']['slicingDirections'][2];
  // }
  // if (el === "slice_number_axial_shifted_key2") {
  //   valueToSend = valueToSend - 2 * image['properties']['slicingDirections'][2];
  // }

  await axios.post(PATH_TO_SEND_ROI(materializedTaskId, el),
    SLICE_PAYLOAD(viewer.imageId, valueToSend));
};

/**
 * Async function to send slice.
 * @param el - output key
 * @param getState - access to Store
 * @return {Promise<void>}
 */
const sendImageEntityUuid = async (el, getState) => {
  const mTask = getState().activity.manualTask.manualTask;
  const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
  const viewerBindings = mTask.miniWorkflow.currentTool.configuration.viewers.layout.layoutOrder;
  const bindingViewersOutputsToToolOutputs = mTask.miniWorkflow.currentTool.bindingViewersOutputsToToolOutputs;
  const viewerInConfiguration = getViewerForInputOutputTool(bindingViewersOutputsToToolOutputs, el, 'outputTool');
  const viewerInTemplateKey = getViewerKeyForTemplate(viewerBindings, viewerInConfiguration['outputViewer']['viewerId']);
  const viewer = getState().visu.viewers.viewersState[viewerInTemplateKey];

  // viewer['imageId'] DOESNT seem to be the current image but the default primary image for this viewer so we are going to use viewer['sceneId']
  let inputKey = mTask.miniWorkflow.currentTool.configuration.scenes[viewer['sceneId']].primaryImageInputKey;
  let imageEntityId = mTask.miniWorkflow.currentMaterializedTask.inputs[inputKey].value;

  await axios.post(PATH_TO_SEND_IMAGE_ENTITY_UUID(materializedTaskId, el),{
    "uuid": imageEntityId
  });
};


const sendAnnotationTableDataInOut = async (el, getState) => {
  const mTask = getState().activity.manualTask.manualTask;
  const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
  const annoData = getState().visu.annotations.annotationsData;
  const activeRoiId = getState().visu.annotations.activeROI;
  const definition = getState().visu.annotations.annotationsDefinition;
  const annotationsDefinitionDocumentId = getState().visu.annotations.annotationsDefinitionDocumentId;
  const annoPayload = convertToAnnotationsTransferObject(annoData, activeRoiId, definition, annotationsDefinitionDocumentId);
  if (mTask.miniWorkflow.currentTool.type === IDENTIFICATION_TOOL_TYPE) {
    annoPayload['reference']['annotationTableDefinitionId'] = "no-annotation-table-definition";
  }
  await axios.post(PATH_TO_POST_ANNOTATIONS_DATA(materializedTaskId, el), annoPayload);
};


const sendAnnotationFormDataInOut = async (el, getState) => {
  const mTask = getState().activity.manualTask.manualTask;
  const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
  const formData = getState().visu.annotations.annotationsFormData;
  const formDefinition = getState().visu.annotations.annotationsFormDefinition;
  const formDefinitionDocumentId = getState().visu.annotations.annotationsFormDefinitionDocumentId;
  const formPayload = convertToAnnotationsTransferObject(formData, null, formDefinition, formDefinitionDocumentId);
  if (mTask.miniWorkflow.currentTool.type === IDENTIFICATION_TOOL_TYPE) {
    formPayload['reference']['annotationTableDefinitionId'] = "no-annotation-table-definition";
  }
  await axios.post(PATH_TO_POST_ANNOTATIONS_DATA(materializedTaskId, el), formPayload);

};

const sendConfirmation = async (el, getState) => {
  const mTask = getState().activity.manualTask.manualTask;
  const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
  await axios.post(PATH_TO_SUBMIT_CONFIRMATION(materializedTaskId, el), CONFIRMATION_PAYLOAD);
};


/**
 * Function for sending sampler value.
 * This uses special syntax with 'output' variable
 * @param {object} el element of bindings array
 * @param {function} getState
 * @property {object} el.widget
 * @property {object} el.widget.widgetKey
 * @return {Promise<void>}
 */
const sendSamplerValue = async (el,getState)=>{
  const mTask = getState().activity.manualTask.manualTask;
  const tools = getState().visu.manualTool.manualToolState;
  const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;


  const samplerToolProps = tools[el.widget.widgetKey];
  if(!(samplerToolProps!=null &&samplerToolProps['output']!=null && samplerToolProps['output']['points']!=null))
    throw new Error("Output of Data Probe Widget is required");


  const sceneKey = getState().visu.viewers.viewersState[samplerToolProps.viewerId].sceneId;
  const imageId = getState().visu.scenes.scenes[sceneKey].primaryImage;


  if(el.widget.property.toLowerCase()==="points") {
    const ps = samplerToolProps['output']['points'];
    const payload = RECTANGLE_PAYLOAD(imageId, ps.startIJK, ps.startXYZ, ps.endIJK, ps.endXYZ);
    await axios.post(PATH_TO_SEND_ROI(materializedTaskId, el.outputTool), payload);
  }
  if(el.widget.property.toLowerCase()==="threshold") {
    const payload = samplerToolProps['output']['threshold'];
    await axios.post(PATH_TO_SEND_NUMBER(materializedTaskId, el.outputTool), {'value':payload});
  }
};

/**
 * Saves outputs only  - NO task submission.
 * Action creator designed to be used with containers.
 * Use cases:
 *  - when Autosave mechanism is used (triggered by time)
 *  - when user click Save button
 * @return {function(..[*]=)}
 */
export const saveOutputs = () => {
  return async (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const materializedTask = mTask.miniWorkflow.currentMaterializedTask;
    const messageQueue = getState().messaging.msgQueue;
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;

    await updateMaterializedTaskResults(materializedTask); // update results if they were autosaved in the meantime

    let toDisplay = false;
    try {
      toDisplay = await saveEveryOutput(mTask,getState);
      if (toDisplay)
        messageQueue.show({
          sticky: false,
          life: 2000,
          severity: 'info',
          summary: 'Save',
          detail: "Outputs have been saved!"
        });
    }catch(err){
      messageQueue.show({
        sticky: false,
        life: 2000,
        severity: 'error',
        summary: 'Error',
        detail: "There is a problem with saving outputs!"
      });

    }
  }
};

/**
 * Send annotations 'extracted' from forms.
 * So far it is used for sending annotations in birds project.
 * There are many HACKS in here:
 * - order of outputs is fixed (by order of bindings)
 * - rois are provided as list in a fixed order
 * - ontology ID for birds hardcoded
 * @param el
 * @param getState
 * @return {Promise<void>}
 */

async function sendAnnotationInOutAsList(el, getState) {
  const mTask = getState().activity.manualTask.manualTask;
  const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
  const formConfiguration = getNested(mTask, "miniWorkflow.currentTool.configuration.forms.renderForms");
  const formsState = getState().visu.forms.formsState;
  const bindings =getNested(mTask,"miniWorkflow.currentTool.bindingFormsOutputsToToolOutputs");

  const formBinding = bindings.find(l=>l.outputTool===el);
  const valueProperty = formBinding.outputViewer.valueProperty;
  const labelProperty = formBinding.outputViewer.labelProperty;
  const roiListKey = formBinding.roiKey;

  const annotationArray = [];

  const keys = Object.keys(formsState);
  for (let i=0; i< keys.length;i++){

    const key = keys[i];
    const selected = formsState[key].value;
    const formKey =  getNestedProp([key,"annotationTableColumnDefinitionKey" ],formConfiguration);
    const noAnswer = formsState[key]["unknown"]===true ; // checks if there is unknown marker

    const isMultipleAnswer = Array.isArray(selected);
    const annotation = {
      "answerType": (isMultipleAnswer)
        ? "MULTIPLE_CHOICE_MULTIPLE_ANSWER"
        : "MULTIPLE_CHOICE_SINGLE_ANSWER",
      "status": "SUBMITTED",
      "hasAnswer": !noAnswer,
      "reference": {
        "annotationTableColumnDefinitionId":  mTask.miniWorkflow.currentMaterializedTask.inputs[formKey].value,
        "roiId":  (mTask.miniWorkflow.currentMaterializedTask.inputs[roiListKey].value!=null)
          ? mTask.miniWorkflow.currentMaterializedTask.inputs[roiListKey].value[i]
          : mTask.miniWorkflow.currentMaterializedTask.inputs[roiListKey][i]
      },
      "docType": "annotation"
    };
    if (!noAnswer){
      if (isMultipleAnswer) {
        annotation["annotationProperties"]=
          {
            "values":[]
          };
        selected.forEach(sel=>{
          const isAlreadyinList = annotation["annotationProperties"]["values"]
            .findIndex(el=>el["ontologyClassIri"]===sel[valueProperty]); // check if in list already
          if (isAlreadyinList===-1)                             // prevent duplicates!
          annotation["annotationProperties"]["values"].push(
            {
              "label": sel[labelProperty],
              "ontologyId": "5b33a5d1c8a2e4e8e60e957b070017fd", //HACK here!!!
              "ontologyClassIri": sel[valueProperty]
            });
        });
        annotationArray.push(annotation);
      }
      else {
        annotation["annotationProperties"] = {
          "value": {
            "label": selected[labelProperty],
            "ontologyId": "5b33a5d1c8a2e4e8e60e957b070017fd", //HACK here!!!
            "ontologyClassIri": selected[valueProperty]
          }
        };
        annotationArray.push(annotation);
      }
    }
    if (noAnswer)
      annotationArray.push(annotation);
  }
  await axios.post(PATH_TO_POST_ANNOTATIONS_LIST(materializedTaskId, el), annotationArray);
}
/**
 * Global function to save all outputs.
 * @param mTask
 * @param getState
 * @return {Promise<boolean>} - returns flag indicating output has been saved
 */
async function saveEveryOutput(mTask, getState) {
  const outputKeys = Object.keys(mTask.miniWorkflow.currentTool.outputs);
  let isSaved = false;
  for (const el of outputKeys) {
    // if (mTask.miniWorkflow.currentTool.outputs[el].type === ROI_IN_OUT
    //   && mTask.miniWorkflow.currentTool.outputs[el].typeROI === EXPLICIT) {
    //   await sendOverlay(el, getState);
    // }
    // if (mTask.miniWorkflow.currentTool.outputs[el].type === ROI_IN_OUT &&
    //   mTask.miniWorkflow.currentTool.outputs[el].typeROI === IMPLICIT) {
    //   await sendSlice(el, getState);
    // }
    if (mTask.miniWorkflow.currentTool.outputs[el].type === "annotationInOut" && mTask.miniWorkflow.currentTool.outputs[el].isList) {
      await sendAnnotationInOutAsList(el, getState);
      isSaved = true;
    }

    if (mTask.miniWorkflow.currentTool.outputs[el].type === "annotationTableDataInOut") {
      await sendAnnotationTableDataInOut(el, getState);
      isSaved = true;
    }
    if (mTask.miniWorkflow.currentTool.outputs[el].type === "annotationFormDataInOut") {
      await sendAnnotationFormDataInOut(el, getState);
      isSaved = true;
    }
    if (mTask.miniWorkflow.currentTool.outputs[el].type === "confirmation") {
      await sendConfirmation(el, getState);
      isSaved = true;
    }
  }

  if (mTask.miniWorkflow.currentTool.bindingWidgetOutputsToToolOutputs != null && Array.isArray(mTask.miniWorkflow.currentTool.bindingWidgetOutputsToToolOutputs))
    for (const el of mTask.miniWorkflow.currentTool.bindingWidgetOutputsToToolOutputs) {
      // const out = mTask.miniWorkflow.currentTool.outputs[el.outputTool];
      if (el.widget != null && el.widget.widgetKey === SAMPLER_TOOL) {
        await sendSamplerValue(el, getState);
        isSaved = true;
      }
    }
  if (mTask.miniWorkflow.currentTool.bindingViewersOutputsToToolOutputs != null && Array.isArray(mTask.miniWorkflow.currentTool.bindingViewersOutputsToToolOutputs))
    for (const el of mTask.miniWorkflow.currentTool.bindingViewersOutputsToToolOutputs) {
      const out = mTask.miniWorkflow.currentTool.outputs[el.outputTool];
      if (out.type === ROI_IN_OUT && out.typeROI === IMPLICIT) {
        await sendSlice(el.outputTool, getState);
        isSaved = true;
      } else if (out.type === "imageEntityUuidInOut") {
        await sendImageEntityUuid(el.outputTool, getState);
        isSaved = true;
      }
    }
  if (mTask.miniWorkflow.currentTool.bindingSceneElementsToToolOutputs != null && Array.isArray(mTask.miniWorkflow.currentTool.bindingSceneElementsToToolOutputs))
    for (const el of mTask.miniWorkflow.currentTool.bindingSceneElementsToToolOutputs) {
      const out = mTask.miniWorkflow.currentTool.outputs[el.outputTool];
      if (out.type === ROI_IN_OUT && out.typeROI === EXPLICIT) {
        await sendOverlay(el.outputTool, getState);
        isSaved = true;
      }
    }
  return isSaved;
}

/**
 * Parses outputs  from  [manualTask.miniWorkflow.currentTool.outputs],
 * Sequence of actions:
 * - sends all outputs,
 * - confirm submission,
 * - get next task or case (turned off if in Live Presenter Mode)
 *
 *
 * @param livePresenterMode - boolean, true if called from livePresenter.
 * @param experimentId -  defined by getManualTask API @see https://docs.google.com/document/d/1o5A8nVP_dokX4aZJYkNvJOCPHUpyXTlcefQPXRezOaA/edit#heading=h.sngrzux18682
 * @param miniWorkflowKey - as above
 * @param miniWorkflowSetId - as above
 * @param onNext - callback to call after successful submission
 */
export const saveOutputAndSubmit = (livePresenterMode, experimentId, miniWorkflowKey, miniWorkflowSetId, onNext) => {

  return async (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const messageQueue = getState().messaging.msgQueue;
    const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
    const navigationModeLabel = getState().visu.manualTool.manualToolState[MAIN_TOOL].navigationModeLabel;

  //  await updateMaterializedTaskResults(mTask.miniWorkflow.currentMaterializedTask); // update results if they were autosaved in the meantime

    //setting global vars
    axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
    dispatch(setAcknowledgeSignal(REQUEST_STATUS_REQUESTED));

    try {//Attention : Promise.all could replace awaits below if parallel processing is implemented in backend
      await saveEveryOutput(mTask, getState);
      await submitTask(materializedTaskId);
      dispatch(setAcknowledgeSignal(REQUEST_STATUS_SUCCESS));
      if (messageQueue!=null)
        messageQueue.show( {
          sticky: false,
          severity: 'info',
          summary: 'Success',
          detail:"Task has been successfully submitted."
        });
      console.log("LP MODE vars");
      console.log("is LP?:",livePresenterMode);
      console.log("experimentId?:",experimentId);
      console.log("onNext:",onNext);


      //TODO Use navigation Mode here!!!
      if (!livePresenterMode || mTask.miniWorkflow.currentStep < (mTask.miniWorkflow.miniWorkflowPath.length - 1)) {//use navigation if not in LP mod
        if("case".includes(navigationModeLabel))
        dispatch(getNextOrPreviousTask(materializedTaskId, 'next'));  //Change here if navigation should trigger after getting next task
        else {
          const step = Number(navigationModeLabel.substring(5))-1; //remove "step " from "step 1"
          dispatch(getNextOrPreviousTask(materializedTaskId, 'nextCase',String(step)));
        }
      }
      else{
          //   dispatch(getManualTask(miniWorkflowSetId, miniWorkflowKey, experimentId));
        if (onNext != null)
          onNext();
      }
      //FIXME handle going to next step in a proper way

      // if (mTask.miniWorkflow.currentStep === (mTask.miniWorkflow.miniWorkflowPath.length - 1))
      //   dispatch(getManualTask(miniWorkflowSetId, miniWorkflowKey, experimentId));
      // else
      //   dispatch(getNextOrPreviousTask(materializedTaskId, 'next'));
    } catch (error) {
      dispatch(setAcknowledgeSignal(REQUEST_STATUS_FAIL, error));
    }
  }
};


/**
 * Facade method over
 * GET /materialized-task/{materializedTaskId}/navigate?dir=next
 * @return {function(*, *)}
 *
 */

export const goToPreviousStep = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
    dispatch(getNextOrPreviousTask(materializedTaskId, 'previous'));
  }
};

/**
 * Facade method over
 * GET /materialized-task/{materializedTaskId}/navigate?dir=next
 * @return {function(*, *)}
 *
 */
export const goToNextStep = () => {
  return (dispatch, getState) => {
    const mTask = getState().activity.manualTask.manualTask;
    const materializedTaskId = mTask.miniWorkflow.currentMaterializedTask.uuid;
    dispatch(getNextOrPreviousTask(materializedTaskId, 'next'));
  }
};

const updateTaskStateProperty = (task) => ({
  type: UPDATE_MANUAL_TASK_STATE,
  task
});


/**
 * ActionCreator for updating task state.
 * @param task
 * @returns {function(*,*)}
 */
export const updateManualTaskState = (task) => {
  return (dispatch) => {
    dispatch(updateTaskStateProperty(task));
  };
};


/**
 * ActionCreator for initializing tool state for DYNAMIC HISTOGRAM DICE PANEL.
 * Viewers are iniitialized later
 * @returns {function(*,*)}
 */
export const initializeDicePanel = (experimentId) => {
  return (dispatch) => {
    const imageOptions = [];
    dispatch(updateImageOptions(imageOptions));
    dispatch(getSkillResults(experimentId));
    const initialState = {
      "left": {
        sliceNumber: 87, orientation: 0, type: "2D", linked: false, colorIndex: 0, smoothing: false,
        imageId: null, hasOverlay: true, overlayId: null
      },
      "middleLeft": {
        sliceNumber: 89, orientation: 1, type: "2D", linked: false, smoothing: false,
        imageId: null, colorIndex: 1, hasOverlay: true, overlayId: null
      },
      "middleRight": {
        sliceNumber: 74, orientation: 2, type: "2D", linked: false, smoothing: false,
        imageId: null, colorIndex: 2, hasOverlay: true, overlayId: null
      },
      "right": {
        sliceNumber: 74, orientation: 2, type: "3D", linked: false, smoothing: false,
        imageId: null, hasOverlay: true, overlayId: null, sliceVisibility: 1
      }
    };
    dispatch(updateViewersState(initialState));
  };
};

/**
 * ActionCreator for initializing results page only.
 * Viewers are iniitialized later
 * @returns {function(*,*)}
 */
export const initializeResultsPage = (experimentId) => {
  return (dispatch) => {
    const imageOptions = [];
    dispatch(updateImageOptions(imageOptions));
    dispatch(getScoreResults(experimentId));
  };
};

/**
 * ActionCreator for initializing Skill Summary Page.
 * @returns {function(*)}
 */
export const initializeSkillSummary = (experimentId) => {
  return (dispatch) => {
    dispatch(getSkillResults(experimentId));
  };
};


/**
 * ActionCreator for initializing tool and viewers state for
 *               DYNAMIC HISTOGRAM DICE PANEL
 * @returns {function(*,*)}
 */
export const initializeViewersInDicePanel = (originalImageId, labelMapId) => {
  return (dispatch, getState) => {

    const images = getState().visu.images.images;
    // const overlays = getState().visu.images.overlays;

    dispatch(clearViewersState());

    //initial State of viewers
    //slice number, orientation (eg. axial), slicing mode (i,j,k),  is linked?
    const initialState = {
      "left": {
        sliceNumber: 87, orientation: 0, type: "2D", linked: false, colorIndex: 0, smoothing: false,
        imageId: originalImageId, hasOverlay: true, overlayId: labelMapId, color: "#ffa07a"
      },
      "middleLeft": {
        sliceNumber: 89, orientation: 1, type: "2D", linked: false, smoothing: false,
        imageId: originalImageId, colorIndex: 1, hasOverlay: true, overlayId: labelMapId, color: "#fff967"
      },
      "middleRight": {
        sliceNumber: 74, orientation: 2, type: "2D", linked: false, smoothing: false,
        imageId: originalImageId, colorIndex: 2, hasOverlay: true, overlayId: labelMapId, color: "#90ee90",
      },
      "right": {
        sliceNumber: 74, orientation: 2, type: "3D", linked: false, smoothing: false,
        imageId: originalImageId, hasOverlay: true, overlayId: labelMapId
      }
    };
    dispatch(updateViewersState(initialState));
    //load images if they're not in the memory
    Object.keys(initialState).forEach((element) => {
      const image = images[initialState[element].imageId]; //.find((el)=>el.uuid===initialState[element].imageId); //look for already loaded
      if (initialState[element].imageId != null && initialState[element].imageId !== "" && !(image != null)) {
        dispatch(loadImageData(initialState[element].imageId, 'format.nii.gz', `/api/image-entity/${initialState[element]['imageId']}/file`, "image"))
      }
      if (initialState[element].overlayId != null && initialState[element].imageId !== "" && initialState[element].hasOverlay) {
        dispatch(loadImageData(initialState[element].overlayId, 'format.nii.gz', `/api/image-entity/${initialState[element]['overlayId']}/file`, "overlay"))
      }
    });

  };
};
