import React from "react";
import PropTypes from "prop-types";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {getMeasurementShortLabel} from "../action/SAMAction";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {SAM_PLOT_TYPE} from "../reducer/SAMReducer";


/**
 * Component used for SELECTION of measurements.
 */

class PlotParametersPanelContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dialogVisible: false,
      dimensionality: 2,  // parameter from Other parameters
      axisXIndex: 0,
      axisYIndex: "ignored",
      axisZIndex: 80,
      plotType: 0
    };
  }

  componentDidMount() {

    this.setState(
      {
        dimensionality: this.props.dimensionality,
        axisXIndex: this.props.axisXIndex,
        axisYIndex: this.props.axisYIndex,
        axisZIndex: this.props.axisZIndex,
        plotType: this.props.plotType
      }
    )
  }

  componentDidUpdate(prevProps, prevState, ss) {
    if (prevState.dialogVisible !== this.state.dialogVisible && this.state.dialogVisible)
      this.setState(
        {
          dimensionality: this.props.dimensionality,
          axisXIndex: this.props.axisXIndex,
          axisYIndex: this.props.axisYIndex,
          axisZIndex: this.props.axisZIndex,
          plotType: this.props.plotType
        }
      )
  }


  render() {
    const {rawData, dimensionality, axisYIndex, axisXIndex, axisZIndex, selectedMeasurementConfiguration, updatePlotSettingsProperty, updateScatterPlotData} = this.props;

    const optionsForAxisSelection = selectedMeasurementConfiguration.map((el, index) => {
      return {value: index, label: getMeasurementShortLabel(el, rawData)}
    });

    return (
      <div>
        <h3>Plot
          <Button onClick={() => {
            this.setState({dialogVisible: true})
          }}
                  icon={"fa fa-edit"}
                  title={"Edit"}
                  style={{float: "right"}}
          />
        </h3>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div>
            {"Dimensions: "} <b>{dimensionality}</b><br/>
            {"Axis X measurement: "}<b>{getMeasurementShortLabel(selectedMeasurementConfiguration[axisXIndex], rawData)}</b><br/>
            {dimensionality > 1 &&
            <React.Fragment>
              {"Axis Y measurement: "}<b>{getMeasurementShortLabel(selectedMeasurementConfiguration[axisYIndex], rawData)}</b><br/>
            </React.Fragment>}
            {dimensionality > 2 &&
            <React.Fragment>
              {"Axis Z measurement: "}<b>{getMeasurementShortLabel(selectedMeasurementConfiguration[axisZIndex], rawData)}</b><br/>
            </React.Fragment>}
          </div>
          <Dialog
            header={"Adjust plot parameters"}
            width={600}
            height={400}
            visible={this.state.dialogVisible}
            appendTo={document.body}
            onHide={() => {
              this.setState({dialogVisible: false})
            }}>

            <div style={{height: "300px"}}>
              <div className="ui-g-12">
                <Dropdown
                  inputId="plotType"
                  style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({plotType: e.value})
                  }}
                  options={[
                    {value: SAM_PLOT_TYPE.SCATTER_PLOT, label: "Interactive plot"},
                    {value: SAM_PLOT_TYPE.KERNEL_STATIC_PLOT, label: "Static KDE plot"},
                    {value: SAM_PLOT_TYPE.BOX_STATIC_PLOT, label: "Static box plot"},
                    {value: SAM_PLOT_TYPE.HISTOGRAM_STATIC_PLOT, label: "Histogram plot"}
                  ]}
                  value={this.state.plotType}>
                </Dropdown>
                <label htmlFor="plotType"
                       className="ui-checkbox-label">
                  {"Type of plot"}
                </label>
              </div>
              <div className="ui-g-12">
                <Dropdown
                  inputId="dimensionality"
                  style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({dimensionality: e.value})
                  }}
                  options={[{value: 1, label: "1"}, {value: 2, label: "2"}, {value: 3, label: "3"}]}
                  value={this.state.dimensionality}>
                </Dropdown>
                <label htmlFor="dimensionality"
                       className="ui-checkbox-label">
                  {"Dimensionality"}
                </label>
              </div>
              <div className="ui-g-12">
                <Dropdown
                  inputId="axisX" style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({axisXIndex: e.value})
                  }}
                  options={optionsForAxisSelection}
                  value={this.state.axisXIndex}>
                </Dropdown>
                <label htmlFor="axisX"
                       className="ui-checkbox-label">
                  {"Measurement for Axis X"}
                </label>
              </div>
              {this.state.dimensionality > 1 &&
              <div className="ui-g-12">
                <Dropdown
                  inputId="axisY" style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({axisYIndex: e.value})
                  }}
                  options={optionsForAxisSelection}
                  value={this.state.axisYIndex}>
                </Dropdown>
                <label htmlFor="axisY"
                       className="ui-checkbox-label">
                  {"Measurement for Axis Y"}
                </label>
              </div>}
              {this.state.dimensionality > 2 &&
              <div className="ui-g-12">
                <Dropdown
                  inputId="axisZ" style={{marginRight: "1em", width: "15em"}}
                  onChange={(e) => {
                    this.setState({axisZIndex: e.value})
                  }}
                  options={optionsForAxisSelection}
                  value={this.state.axisZIndex}>
                </Dropdown>
                <label htmlFor="axisZ"
                       className="ui-checkbox-label">
                  {"Measurement for Axis Z"}
                </label>
              </div>}
            </div>
            <div>
              <Button onClick={() => {
                this.setState({dialogVisible: false});
                updatePlotSettingsProperty("dimensionality", this.state.dimensionality);
                updatePlotSettingsProperty("axisXIndex", this.state.axisXIndex);
                updatePlotSettingsProperty("axisYIndex", this.state.axisYIndex);
                updatePlotSettingsProperty("axisZIndex", this.state.axisZIndex);
                updatePlotSettingsProperty("plotType", this.state.plotType);
                updateScatterPlotData();
              }} icon={"fa fa-check"} label={"OK"}/>
              <Button onClick={() => {
                this.setState({dialogVisible: false})
              }} icon={"fa fa-close"} label={"Cancel"}/>
            </div>
          </Dialog>
        </div>
      </div>
    )
  }
}

export default PlotParametersPanelContent;

PlotParametersPanelContent.propTypes = {
  rawData: PropTypes.object.isRequired,
  selectedMeasurementConfiguration: PropTypes.object.isRequired,
  updatePlotSettingsProperty: PropTypes.func.isRequired,
  updateScatterPlotData: PropTypes.func.isRequired,
  dimensionality: PropTypes.number.isRequired,
  axisYIndex: PropTypes.number.isRequired,
  axisXIndex: PropTypes.number.isRequired,
  axisZIndex: PropTypes.number.isRequired
};
