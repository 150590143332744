import React from 'react';
import {withTranslation} from "react-i18next";
import {Dialog} from "primereact/components/dialog/Dialog";


class Footer extends React.Component{

	constructor(props) {
        super(props);
        this.state={
          legalNoticeVisible:false,
          privacyNoticeVisible:false,
          legalContent:DEFAULT_NOTES,
          privacyContent:DEFAULT_PRIV,
          language:"fr"
        };
    }

	componentDidMount() {

        fetch('/api/legal')// or axios.get if bearer is needed (it shouldn't be though+)
          .then(response => response.json())
          .then(data => {
              this.setState({legalContent: data});
          })
          .catch(e=> {
              console.log(e);
          });
        fetch('/api/privacy')// or axios.get if bearer is needed (it shouldn't be though+)
          .then(response => response.json())
          .then(data => {
              this.setState({privacyContent: data});
          })
          .catch(e=> {
              console.log(e);
          });
    }


	render(){
		const {t} = this.props;
        const {legalNoticeVisible,  privacyNoticeVisible,  legalContent,   privacyContent,language} = this.state;
		const disclaimerContent = legalNoticeVisible
									? legalContent
									: privacyContent;
		const languageLinks = Object.keys(disclaimerContent).map(lKey=>{
			return <a onClick={()=>{this.setState({language:lKey})}}
						style={{
							marginLeft:"1em",
							textDecoration:this.state.language===lKey?"underline":"none"}}>{disclaimerContent[lKey]["label"]}</a>
		});

		return (
			<div >
				<div className="footer">
					<a href="mailto:guttmann@bwh.harvard.edu" style={{marginRight:'16px'}}>{t("footer.contactUs")}</a>
					<a href="javascript:void(0)" style={{marginRight:'16px'}} onClick={()=>this.setState({privacyNoticeVisible:true, legalNoticeVisible:false})}>{t("footer.privacyPolicy")}</a>
					<a href="javascript:void(0)" style={{marginRight:'16px'}} onClick={()=>this.setState({privacyNoticeVisible:false, legalNoticeVisible:true})}>{t("footer.legalNotice")}</a>
				</div>
				<p className="footer">Copyright © 2012 - 2021 Charles R.G. Guttmann</p>
				<Dialog
					onHide={()=>{ this.setState({legalNoticeVisible:false,privacyNoticeVisible:false})}}
					visible={this.state.legalNoticeVisible || this.state.privacyNoticeVisible}
					header={this.state.legalNoticeVisible
						? t("logoutComponent.menuOptions.legal.label")
						: t("logoutComponent.menuOptions.privacy.label")}
					style={{width:'800px',overflowY:'hidden',maxHeight:"600px"}}
					>
						<pre style={{whiteSpace: "pre-wrap", wordBreak: "keep-all", maxHeight:"25em",overflowY:"auto"}}
							onScroll={this.isBottom}>
								<div dangerouslySetInnerHTML={{__html: legalNoticeVisible
									? legalContent[language].legalNotice
									: privacyContent[language].privacyPolicy}}/>
							</pre>
						<div>
						{languageLinks}
						</div>
				</Dialog>
			</div>);
		}
}

const DEFAULT_NOTES = {
    fr: {
        "label": "Français",
          "legalNotice": "<pre>Legale notice...</pre>"
    },
    en: {
        "label": "English",
          "legalNotice": "<div>Legal Notice...</div>"
    }
};

const DEFAULT_PRIV ={
    fr: {
        "label": "Français",
        "privacyPolicy": "Privacy police..."
    },
    en: {
        "label": "English",
        "privacyPolicy": "Privacy policy..."
    }
};

export default withTranslation()(Footer);