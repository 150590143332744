import React from 'react';
import {Button} from 'primereact/components/button/Button';
import {Growl} from "primereact/components/growl/Growl";
import PropTypes from 'prop-types';
import {ruleRunner, run} from "../../helpers/validation/ruleRunner";
import {isEmail, isStrongPassword, mustMatch, required} from "../../helpers/validation/rules";
import InputTextWithValidation from "../../helpers/validation/InputTextWithValidation";
import {withTranslation} from 'react-i18next';
import Footer from "../../root/Footer";
import {Link} from "react-router-dom";
import SpineLogo from "./SpineLogo";
import {Redirect} from "react-router";
import { PASSWORD_RESET_SUCCESS, PASSWORD_RESET_REQUEST } from '../action/actionType';
import CookieConsent from 'react-cookie-consent';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_API_KEY } from '../../../Constants';


const fieldValidations = [
    ruleRunner("email", "Email", required, isEmail),
    ruleRunner("password", "Password", required, isStrongPassword),
    ruleRunner("confirmpassword", "Confirm password", mustMatch("password", "Password"))

];

class PasswordReset extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confirmpassword: '',
            match: false,
            showErrors: false,
            validationErrors: {},
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordResetSubmit = this.handlePasswordResetSubmit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.errorFor = this.errorFor.bind(this);
        this.recaptcha = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.loginStatus === PASSWORD_RESET_SUCCESS){
            setTimeout(()=>{
                this.props.history.push('/');
            }, 2000)
        }
    }

    componentWillMount() {
        // Run validations on initial state
        this.setState({validationErrors: run(this.state, fieldValidations)});
    }

    /**required by validation */
    errorFor(field) {
        return this.state.validationErrors[field] || "";
    }

    handleChange(event) {
        // console.log('setState', event.target.value);
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit(recaptchaToken) {
        let newState = this.state;
        newState.validationErrors = run(newState, fieldValidations);
        newState.showErrors = true;
        this.setState(newState);
        if (Object.keys(newState.validationErrors).length > 0) return null;
        this.handlePasswordResetSubmit(recaptchaToken);
    }

    handlePasswordResetSubmit(recaptchaToken) {
        const {passwordReset, match} = this.props;
        if (this.state.password === this.state.confirmpassword) {
            this.setState({
                match: false
            });

            const resetObject = {
                email: this.state.email,
                password: this.state.password,
                resetToken: match.params.resetToken,
                recaptchaToken
            };
            // Get the function from props. This is available thanks to mapDispatchToProps
            // method in the container ../containers/ContainerPasswordReset.js
            passwordReset(
                resetObject,
                ({message})=>this.growl.show({
                    sticky:false,
                    severity: 'info',
                    summary: 'Information',
                    detail: message
                }),    
                ({message})=>this.growl.show({
                    sticky:false,
                    severity: 'error',
                    summary: 'Warning',
                    detail: message}));
        } else {
            this.setState({
                match: true
            })
        }
    }

    render() {
        const {t,loginStatus, match} = this.props;
        return (
            <React.Fragment>
                <Growl ref={(el) => { this.growl = el; }}/>
                <div className="header landingPageHeaderTopbar"
                     style={{display:"flex",alignItems:"center"}}>
                    <div className="landingPageSpineLogoContainer" style={{display: "inline-flex",  margin: "auto", marginLeft:"16px"}}>
                        <Link to='/'>
                            <SpineLogo/>
                        </Link>
                    </div>
                    <div className="reg-headtitle" style={{ color: "#A7FFFE",    margin: "auto", display: "inline-flex"}}>
                        {t("passwordResetForm.header")}
                    </div>
                </div>
                {match.params.resetToken==null
                ? <Redirect to="/"/>
                : <form style={{height: 'calc(100vh - 75px)', overflowY: 'auto'}}>
                    <div id="passwordResetForm" className="ui-g-12" style={{height: '80%', float:'none'}}>
                        <div style={{margin: "auto", width: "30%"}}>
                            <div className="ui-g-12 ui-g-nopad reg-pad-bot">
                                <label className="reg-plaintext">{t("passwordResetForm.property.email.label")} </label>
                                <InputTextWithValidation
                                    type={'email'}
                                    id="email"
                                    style={{width: '100%'}}
                                    onFieldChanged={this.handleChange}
                                    showError={this.state.showErrors}
                                    errorText={this.errorFor("email")}
                                />
                            </div>
                            <div className="ui-g-12 ui-g-nopad reg-pad-bot">
                                <label className="reg-plaintext">{t("passwordResetForm.property.password.label")} </label>
                                <div style={{width: '100%'}}>
                                    <InputTextWithValidation
                                        id="password"
                                        type="password"
                                        showError={this.state.showErrors}
                                        errorText={this.errorFor("password")}
                                        style={{width: '100%'}}
                                        onFieldChanged={this.handleChange}
                                        className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                                    />
                                </div>
                            </div>
                            <div className="ui-g-12 ui-g-nopad reg-pad-bot">
                                <label
                                    className="reg-plaintext">{t("passwordResetForm.property.confirmPassword.label")} </label>
                                <div style={{width: '100%'}}>
                                    <InputTextWithValidation
                                        type="password"
                                        id="confirmpassword"
                                        showError={this.state.showErrors}
                                        errorText={this.errorFor("confirmpassword")}
                                        onFieldChanged={this.handleChange}
                                        style={{width: '100%'}}
                                        className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                                    />
                                    {this.state.match && <label style={{color: 'red'}}>
                                        <small>{t("passwordResetForm.property.passwordNotMatching.label")}</small>
                                    </label>}
                                </div>
                            </div>
                        </div>
                        <div className="ui-g-12">
                            <div style={{    display: "block",  margin: "auto",  textAlign: "center"}}>
                                {loginStatus === PASSWORD_RESET_REQUEST?
                                    <div> <i className="fa fa-spinner fa-spin"/></div>
                                : loginStatus === PASSWORD_RESET_SUCCESS?
                                    <Link to={'/'}>
                                        <Button label={t("general.button.ok.label")}/>
                                    </Link>
                                :<React.Fragment>
                                    <Button
                                        label = {t("passwordResetForm.buttonSubmit.label")}
                                        onClick={e => {
                                            e.preventDefault();
                                            this.recaptcha.current.execute();
                                            }
                                        }
                                    />
                                    <Link to={'/'}>
                                        <Button label={t("general.button.cancel.label")}/>
                                    </Link>
                                </React.Fragment>}
                            </div>
                        </div>
                    </div>
                    <Footer/>
                    <ReCAPTCHA
                            ref={this.recaptcha}
                            size="invisible"
                            sitekey={RECAPTCHA_API_KEY}
                            onChange={value => 
                                {
                                    if(value){
                                        this.handleSubmit(value);
                                        this.recaptcha.current.reset();
                                    } else {
                                        this.growl.show({
                                            sticky: false,
                                            severity: 'info',
                                            summary: 'Password reset error',
                                            detail: 'RECAPTCHA validation failed!'
                                        });
                                    }
                                }
                            }
                        />
                </form>
                }
                <CookieConsent
                    location="bottom"
                    buttonText={t("general.cookies.acceptbutton.label")}
                    style={{opacity: '0.9'}}>
                    {t("general.cookies.message.label")}
                </CookieConsent>
            </React.Fragment>
        );
    }
}

export default withTranslation()(PasswordReset);

PasswordReset.propTypes = {
    passwordReset: PropTypes.func.isRequired,
    loginStatus: PropTypes.string.isRequired
};
