import {
    EXPDESIGN_CLEAR_SCRIPT_SUCCESS,
    EXPDESIGN_EXECUTE_SCRIPT_FAIL, EXPDESIGN_EXECUTE_SCRIPT_REQUEST,
    EXPDESIGN_EXECUTE_SCRIPT_SUCCESS
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


export const scriptExecutionReducer = (state = { scriptExecutorState: '', plots: [],stdOut:"", rawData:{} }, action) => {
    // console.log("scriptExecutionReducer", action);
    switch (action.type) {
        case EXPDESIGN_EXECUTE_SCRIPT_REQUEST:
            return Object.assign({},state, {
                scriptExecutorState: REQUEST_STATUS_REQUESTED,
            });
        case EXPDESIGN_EXECUTE_SCRIPT_FAIL:
            return Object.assign({},state, {
                scriptExecutorState: REQUEST_STATUS_FAIL,
                stdOut:action.error
            });
        case EXPDESIGN_EXECUTE_SCRIPT_SUCCESS:
            return Object.assign({}, state, {
                scriptExecutorState: REQUEST_STATUS_SUCCESS,
                plots:action.plots,
                stdOut:action.stdOut,
                rawData:action.rawData
            });
        case EXPDESIGN_CLEAR_SCRIPT_SUCCESS:
            return Object.assign({}, state, {
                scriptExecutorState: '',
                plots: [],
                stdOut: "",
                rawData: {}
            });
        default:
            return state;
    }
};