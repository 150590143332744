import {connect} from "react-redux";
import React from "react";
import {clearSaveQuestion, getQuestionListActionCreator, saveQuestion} from "../action/QuestionAction";
import {getOntologyListActionCreator} from "../action/OntologyAction";
import AnnotationQuestionSelection from "../component/annotationTool/AnnotationQuestionSelection";


const mapStateToProps = (state,ownProps) => {
  return {
    messageQueue: state.messaging.msgQueue,
    questionList:state.visu.questions.questionList,
    questionListState:state.visu.questions.questionListState,
    ontologyList: state.visu.ontologies.ontologyList,
    ontologyListState: state.visu.ontologies.ontologyListState
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {

    getQuestionsList:()=>{
      dispatch(getQuestionListActionCreator())
    },
    getOntologyList:()=>{
      dispatch(getOntologyListActionCreator())
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);


export const ContainerAnnotationQuestionSelection = containerCreator(AnnotationQuestionSelection);