import {connect} from "react-redux";
import {getAllToolsList} from "../../expDesign/action/ToolAction";
import ToolPanelContent from "../component/ToolPanelContent";



const mapStateToProps = (state,ownProps) => {
  return {
    tools: state.expDesign.tools.tools,
    toolListState: state.expDesign.tools.toolListState,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    getTools:()=>{
      dispatch(getAllToolsList())
    }
  }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerToolPanelContent = containerCreator(ToolPanelContent);