import taskSchema from "SPINE-json-schema/schemas/task.schema.json"
import toolSchema from "SPINE-json-schema/schemas/tool.schema.json"
import coreSchema from "SPINE-json-schema/schemas/core.schema.json"
import viewerSchema from "SPINE-json-schema/schemas/viewer.schema.json"
import annotationSchema from "SPINE-json-schema/schemas/annotation.schema.json"
// import annotationForm from "SPINE-json-schema/schemas/annotation."
import roiSchema from "SPINE-json-schema/schemas/roi.schema.json"
import workflowSchema from "SPINE-json-schema/schemas/workflow.schema.json";



import Ajv from 'ajv';

const ajv = new Ajv({ allErrors: true });
ajv.addSchema(coreSchema, 'coreSchema');
ajv.addSchema(taskSchema, 'taskSchema');
ajv.addSchema(annotationSchema, 'annotationSchema');
// ajv.addSchema(annotationForm, 'annotationForm'); //according to new version 2.1
ajv.addSchema(roiSchema, 'roiSchema');
ajv.addSchema(toolSchema, 'toolSchema');
ajv.addSchema(viewerSchema, 'viewerSchema');
ajv.addSchema(workflowSchema, 'workflowSchema');


export const  validateWorkflow = ( workflowData) =>{
  const validateWorkflow = ajv.validate(workflowSchema, workflowData);

  if (validateWorkflow)   return {valid:true,errorObject:null};
  else return {valid:false, errorObject:ajv.errors};
};


export const  validateTask = ( taskData) =>{
  const validateTask = ajv.validate(taskSchema, taskData);

  if (validateTask)   return {valid:true,errorObject:null};
  else return {valid:false, errorObject:ajv.errors};
};

export const  validateTool = ( toolData) =>{
  const validateTask = ajv.validate(toolSchema, toolData);
  if (validateTask)   return {valid:true,errorObject:null};
  else return {valid:false, errorObject:ajv.errors};
};


export const generateMessage = (error)=>{
  if (error.keyword === "additionalProperties"){
    return error.dataPath + " " +  error.message + " : " + error.params.additionalProperty;
  }
  if (error.keyword === "minProperties"){
    return error.dataPath + " " +  error.message;
  }
  if (error.keyword === "required"){
    return error.dataPath + " " +  error.message;
  }
  return error.message;
};

