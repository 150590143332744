import React from "react";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import {MiniWorkflowStepMonitor} from "./MiniWorkflowStepMonitor";
import {CircularProgressComponent} from "../../../root/CircularProgressComponent";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import {getNestedProp} from "../../../helpers/expressions";
import {withTranslation} from 'react-i18next';
import {Tooltip} from "primereact/components/tooltip/Tooltip"; // High order translation component

/**Replacement for manual tool.
 * To use when a given step of miniworkflow in live presenter has been already performed.
 * It should be used only in LP Mode!!!
 * @version 1.0
 */
class ResultsPageBirds extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showWindowPortal: false,
      linkViewers: true,
      leftButtonMode: LEFT_BUTTON_MODE.NONE,
      showDialogWindow: false,
      stepIndex: 0, // step for Dialog window
      showUserResult: true,
      showExpertResult: true
    };
    ["keyListener","onAudioError"].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {livePresenterExperimentId, initializeResultsPage} = this.props;
    initializeResultsPage(livePresenterExperimentId);
    document.body.addEventListener("keydown", this.keyListener, false);
  }
  /**
   * Clear state of viewers and widgets.
   * Removes key listener.
   */
  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.keyListener, false);
  }

  keyListener(event) {
    if (event.code === "PageDown") {
      this.props.onPrevious();
      return;
    }
    if (event.code === "PageUp") {
      this.props.onNext();
    }
  }
  onAudioError(e,el){
    const {messageQueue} = this.props;
    if (getNestedProp(["inputs", "audioEntityId"], el) != null)
      messageQueue.show({
        sticky: false,
        life: 5000,
        severity: 'error',
        summary: 'Audio Player Error',
        detail: e.message
      });
  }


  render() {

    const {
      manualTaskState, workload, skillResult,
      goToPreviousSkillStep, t
    } = this.props;


    let steps = (workload != null && workload.miniWorkflow != null && workload.miniWorkflow['miniWorkflowPath'] != null)
      ? workload.miniWorkflow['miniWorkflowPath'].map(el => {
        return {name: el.stepName}
      })
      : null;

    let currentStep = (workload != null && workload.miniWorkflow != null && workload.miniWorkflow['currentStep'] != null)
      ? (workload.miniWorkflow.status === "DONE") ? -1 : workload.miniWorkflow['currentStep']
      : null;

    let partialResults = <i className="fa fa-spinner fa-spin"
                            style={{fontSize: "40px", verticalAlign: "middle", margin: "auto"}}/>;

    if (skillResult != null && skillResult.resultState === REQUEST_STATUS_SUCCESS) {
      partialResults = skillResult.result.scoring.map((el, index) => (
        <React.Fragment>
          <div className="resultTableName" style={{fontSize: "inherit"}}>
            <audio
              src={`/api/audio-entity/${getNestedProp(["inputs", "audioEntityId"], el)}/file`}
              preload={"metadata"}
              crossOrigin={"use-credentials"}
              controls={true}
              onError={(e) => this.onAudioError(e, el)}/>
          </div>

          <div className="resultTableName"
               style={{fontSize: 'small',fontVariant: 'initial', padding:"1em", wordBreak: 'break-word',whiteSpace: 'break-spaces'}}>

              <span>{getNestedProp(["realAnswer", "sourceMetadata","Who"], el)}</span>
              {", "}
            <span>{getNestedProp(["realAnswer", "sourceMetadata","Rec.name"], el)}</span>
            {" "}
            {getNestedProp(["realAnswer", "sourceMetadata","Registration_link"], el)!=null &&
             <a href={getNestedProp(["realAnswer", "sourceMetadata","Registration_link"], el)}
               target={"_blank"}
              >Link</a>}
          </div>

          <div className="resultTableName"
               style={{fontSize: "inherit",wordBreak: 'break-word',whiteSpace: 'break-spaces'}}> {getNestedProp(["realAnswer", "ontologyClassIri"], el)}</div>

          <div id={"score-".concat(String(index))} className="resultTableName"
               style={{fontSize: "inherit", wordBreak: 'break-word',whiteSpace: 'break-spaces'}}>
            {getNestedProp(["userAnswer", "label"], el)}
            {getNestedProp(["userAnswer", "label"], el) !== "No answer" &&
            <i>(lat. {getNestedProp(["userAnswer", "ontologyClassIri"], el)})</i>}
            <i className={(el.value === 0) ? "fa fa-close" : "fa fa-check"}/>
          </div>
          <Tooltip for={"#score-".concat(String(index))} title={el.tooltip}/>
        </React.Fragment>
      ))
    }

    return (
      <React.Fragment>
        <div style={{display: "block", position: "fixed", top: "10px", left: "auto", zIndex: "1000"}}>
          {manualTaskState != null && steps != null && currentStep != null &&
          <MiniWorkflowStepMonitor
            steps={steps}
            currentStep={-1}
            status={"FINISHED"}/>
          }
        </div>
        <div style={{backgroundColor: "white"}}>
          <div className="viewerContainerFull">
            <div className="viewerContainerMainPanel"  style={{width: "80%"}}>
              <div className="resultTableMain" style={{fontSize: "medium", display: "grid", gridTemplateColumns: "25% 25% 25% 25%", width: "100%", alignItems:"center"}}>
                  <div className="resultTableName" style={{height:"3em"}}> </div>
                  <div className="resultTableName">{"Reference"}</div>
                  <div className="resultTableName">{t("resultsPage.correct")}</div>
                  <div className="resultTableName">{t("resultsPage.your")}</div>
                {partialResults}
              </div>
            </div>
            <div className="viewerContainerMainPanel"
                 style={{
                   height: "80vh",
                   flexDirection: "column",
                   textAlign: "center",
                   justifyContent: "space-evenly",
                   width: "20%"
                 }}>
              <h1 style={{fontSize: "x-large"}}> {t("resultsPage.gratitude")} </h1>

              {skillResult != null && skillResult.resultState === REQUEST_STATUS_SUCCESS && getNestedProp(['result', 'final', 'score'], skillResult) != null &&
              <div id={"final-score"} style={{width: "fit-content", margin: "0 auto"}}>
                <CircularProgressComponent
                  title={t("resultsPage.yourScore")}
                  value={Math.round(getNestedProp(['result', 'final', 'score'], skillResult))}
                  isAbsolute={true}
                  max={getNestedProp(['result', 'scoring', 'length'], skillResult)}/>
                <Tooltip for={"#final-score"} title={getNestedProp(['result', 'final', 'tooltip'], skillResult)}/>
              </div>}


              <div style={{fontSize: "x-large", width: "80%", zIndex: "1000", margin: "auto"}}>
                <div> {getNestedProp(['result', 'final', 'text'], skillResult)}</div>
                <div>
                  <a
                    {...getNestedProp(['result', 'final', 'value'], skillResult) ? {href: getNestedProp(['result', 'final', 'link'], skillResult)} : {}}
                    onClick={() => {
                      if (!getNestedProp(['result', 'final', 'value'], skillResult)) {  // checks if passed - remove exclamation to inverse!
                        goToPreviousSkillStep();
                      } else
                        this.props.history.push(getNestedProp(['result', 'final', 'link'], skillResult))
                    }}
                  >{getNestedProp(['result', 'final', 'linkText'], skillResult)}</a>
                </div>
              </div>
            </div>
          </div>
          <div/>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(withRouter(ResultsPageBirds));

ResultsPageBirds.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  getAnnotations: PropTypes.func.isRequired,
  updateAnnotationData: PropTypes.func.isRequired,
  rmCallback: PropTypes.func.isRequired,
  saveOverlayData: PropTypes.func,
  clearAll: PropTypes.func.isRequired,
  addAnnotation: PropTypes.func.isRequired,
  setActiveAnnotation: PropTypes.func.isRequired,
  clearAnnotatorState: PropTypes.func.isRequired,
  clearViewersState: PropTypes.func.isRequired,
  initializeTool: PropTypes.func.isRequired,
  viewersState: PropTypes.array.isRequired,  //array containing state of all viewers
  updateViewerProperty: PropTypes.func.isRequired,
  updateViewersState: PropTypes.func.isRequired,
  updateViewer: PropTypes.func,
  goToPreviousStep: PropTypes.func,
  imageOptions: PropTypes.array.isRequired,
  changeImageType: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  overlays: PropTypes.array.isRequired,
  manualTaskState: PropTypes.string.isRequired,
  workload: PropTypes.object.isRequired,
  updateManualTask: PropTypes.func.isRequired,
  getAnotherCase: PropTypes.func.isRequired,
  handleKeySlicing: PropTypes.func,
  clearManualToolState: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  miniWorkflowNameInSet: PropTypes.string.isRequired,
  miniWorkflowSetId: PropTypes.string.isRequired,
  livePresenterExperimentId: PropTypes.string.isRequired,
  initializeResultsPage: PropTypes.func.isRequired,
  skillResult: PropTypes.object.isRequired,
  goToPreviousSkillStep: PropTypes.func.isRequired
};














