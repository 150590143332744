import React from "react";
import PropTypes from "prop-types";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {rgbPresetObjects} from "../../vtk/processing/ColorMapProcessing";
import {MultiSelect} from "primereact/components/multiselect/MultiSelect";
import {getNestedProp} from "../../helpers/expressions";
import {GRAPH_TOOL_LABEL_PROPERTY_CODE} from "../../../Constants";

 
class GraphVisualizationPanel extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      colorMapOptions:[],
      nodeAttributeOptions:[],
      edgeAttributeOptions:[],
      nodeFilterOptions:[]
    };
    [].forEach(name => {
      this[name] = this[name].bind(this);
    });
  }

  componentDidMount() {
    const {directedGraph} = this.props;
    if (directedGraph!=null) {
      const colorOptions = rgbPresetObjects.map(el => {
        el["value"] = el.Name;
        el["label"] = el.Name;
        return el;
      });
      const nodeAttributes = directedGraph.getNodeAttributes().map(el => {
        return {
          label: el["name"],
          value: el["property"]
        }
      });
      const edgeAttributes = directedGraph.getEdgeAttributes().map(el => {
        return {
          label: el["name"],
          value: el["property"]
        }
      });

      const filterOptions = directedGraph.getNodes().map((el, index) => {
        return {
          label: el[GRAPH_TOOL_LABEL_PROPERTY_CODE],
          value: index
        }
      });

      this.setState({
        colorMapOptions: colorOptions,
        nodeAttributeOptions: nodeAttributes,
        edgeAttributeOptions: edgeAttributes,
        nodeFilterOptions: filterOptions
      });
    }
  }

  componentDidUpdate(prevProps, prevState, ss) {
  }

  render() {
    const {directedGraph, updateViewerProperty, id, gtNodeColormapName, gtEdgeColormapName, gtPickerTolerance,
      gtEdgeColorAttributeProperty,gtEdgeRadiusAttributeFilter, gtEdgeRadiusAttributeProperty,
      gtEdgeRadiusRange,gtNodeColorAttributeProperty, gtNodeRadiusAttributeFilter,
      gtNodeRadiusRange, gtNodeRadiusAttributeProperty,gtNodeStats, gtEdgeStats, gtNodeFilter} = this.props;

    const {colorMapOptions, nodeAttributeOptions, edgeAttributeOptions} = this.state;

    if (directedGraph!=null)
    return (
      <React.Fragment>
        <h5>Node visualization parameters</h5>
        <div className="flexFormTable">
          <div className="flexFormRow">
            <span className="flexFormColumn">Colormap:</span>
            <Dropdown className="flexFormColumn"
                      value={gtNodeColormapName}
                      onChange={(e)=>{
                        updateViewerProperty(id,"gtNodeColormapName",e.value);
                        updateViewerProperty(id, "gtState", "TO_UPDATE");
                      }}
                      options={colorMapOptions}>
            </Dropdown>
          </div>
          <div className="flexFormRow">
            <span className="flexFormColumn">Color attribute:</span>
            <Dropdown className="flexFormColumn"
                      value={gtNodeColorAttributeProperty}
                      onChange={(e)=>{
                        updateViewerProperty(id,"gtNodeColorAttributeProperty",e.value);
                        updateViewerProperty(id, "gtState", "TO_UPDATE");

                      }}
                      options={nodeAttributeOptions}>
            </Dropdown>
          </div>
          <div className="flexFormRow">
            <span className="flexFormColumn">Radius attribute:</span>
            <Dropdown className="flexFormColumn"
                      value={gtNodeRadiusAttributeProperty}
                      onChange={(e)=>{
                        updateViewerProperty(id,"gtNodeRadiusAttributeProperty",e.value);
                        updateViewerProperty(id, "gtState", "TO_UPDATE_WITH_STATS");
                      }}
                      options={this.state.nodeAttributeOptions}>
            </Dropdown>
          </div>
          <div className="flexFormRow">
            <span className="flexFormColumn">Sphere radius range</span>
            <div className="flexFormColumn" style={{flexDirection:"row"}}>
              <span>From</span>
              <input
                     type="number"
                     id="node-radius-min"
                     onChange={(e) => {
                       gtNodeRadiusRange[0] = Number(e.target.value);
                       updateViewerProperty(id,"gtNodeRadiusRange",gtNodeRadiusRange);
                       updateViewerProperty(id, "gtState", "TO_UPDATE");
                     }}
                     min={0.5}
                     step={0.05}
                     max={gtNodeRadiusRange[1]}
                     value={gtNodeRadiusRange[0]}
                     size={4}
              />
              <span>to</span>
              <input
                     type="number"
                     id="node-radius-max"
                     onChange={(e) => {
                       gtNodeRadiusRange[1] = Number(e.target.value);
                       updateViewerProperty(id,"gtNodeRadiusRange",gtNodeRadiusRange);
                       updateViewerProperty(id, "gtState", "TO_UPDATE");
                     }}
                     min={gtNodeRadiusRange[0]}
                     max={5}
                     step={0.05}
                     value={gtNodeRadiusRange[1]}
                     size={4}
              />
            </div>
          </div>

        </div>
        <h5>Edge visualization parameters</h5>
        <div className="flexFormTable">
          <div className="flexFormRow">
            <span className="flexFormColumn">Colormap:</span>
            <Dropdown className="flexFormColumn"
                      value={gtEdgeColormapName}
                      onChange={(e)=>{
                        updateViewerProperty(id,"gtEdgeColormapName",e.value);
                        updateViewerProperty(id, "gtState", "TO_UPDATE");
                      }}
                      options={colorMapOptions}>
            </Dropdown>
          </div>
          <div className="flexFormRow">
            <span className="flexFormColumn">Color attribute:</span>
            <Dropdown className="flexFormColumn"
                      value={gtEdgeColorAttributeProperty}
                      onChange={(e)=>{
                        updateViewerProperty(id,"gtEdgeColorAttributeProperty",e.value);
                        updateViewerProperty(id, "gtState", "TO_UPDATE");
                      }}
                      options={edgeAttributeOptions}>
            </Dropdown>
          </div>
          <div className="flexFormRow">
            <span className="flexFormColumn">Radius attribute:</span>
            <Dropdown className="flexFormColumn"
                      value={gtEdgeRadiusAttributeProperty}
                      onChange={(e)=>{
                        updateViewerProperty(id,"gtEdgeRadiusAttributeProperty",e.value);
                        updateViewerProperty(id, "gtState", "TO_UPDATE");
                      }}
                      options={edgeAttributeOptions}>
            </Dropdown>
          </div>
          <div className="flexFormRow">
            <span className="flexFormColumn">Stick radius range</span>
            <div className="flexFormColumn" style={{flexDirection:"row"}}>
              <span>From</span>
              <input
                     type="number"
                     id="stick-radius-min"
                     onChange={(e) => {
                       gtEdgeRadiusRange[0] = Number(e.target.value);
                       updateViewerProperty(id,"gtEdgeRadiusRange",gtEdgeRadiusRange);
                       updateViewerProperty(id, "gtState", "TO_UPDATE");
                     }}
                     min={0.5}
                     step={0.05}
                     max={gtEdgeRadiusRange[1]}
                     value={gtEdgeRadiusRange[0]}
                     size={5}
              />
              <span>to</span>
              <input
                     type="number"
                     id="stick-radius-max"
                     onChange={(e) => {
                       gtEdgeRadiusRange[1] = Number(e.target.value);
                       updateViewerProperty(id,"gtEdgeRadiusRange",gtEdgeRadiusRange);
                       updateViewerProperty(id, "gtState", "TO_UPDATE");
                     }}
                     min={gtEdgeRadiusRange[0]}
                     max={3}
                     step={0.05}
                     value={gtEdgeRadiusRange[1]}
                     size={5}
              />
            </div>
          </div>

        </div>

        <h5>Data</h5>
        <div className="flexFormTable">
          <div className="flexFormRow">
            <span className="flexFormColumn">Filter nodes:</span>
            <MultiSelect
              id={"graph-node-filter"}
              options={this.state.nodeFilterOptions}
              value={gtNodeFilter}
              onChange={(e) =>{
                updateViewerProperty(id,"gtNodeFilter",e.value);
                updateViewerProperty(id, "gtState", "TO_UPDATE");
              }}
              filter={true}
              className="flexFormColumn"
              style={{width:'20em'}}
            />
          </div>
          <div className="flexFormRow">
            <span className="flexFormColumn">Filter nodes by value</span>
            <div className="flexFormColumn" style={{flexDirection:"row"}}>
              <span>From</span>
              <input
                     type="number"
                     id="sphere-filter-min"
                     onChange={(e) => {
                       const newVal = [...gtNodeRadiusAttributeFilter];
                       newVal[0] = Number(e.target.value);
                       updateViewerProperty(id,"gtNodeRadiusAttributeFilter",newVal);
                       updateViewerProperty(id, "gtState", "TO_UPDATE");
                     }}
                     min={getNestedProp([gtNodeRadiusAttributeProperty,0],gtNodeStats)}
                     max={getNestedProp([1],gtNodeRadiusAttributeFilter)}
                     value={getNestedProp([0],gtNodeRadiusAttributeFilter)}
                     size={5}
              />
              <span>to</span>
              <input
                     type="number"
                     id="sphere-filter-max"
                     onChange={(e) => {
                       const newVal = [...gtNodeRadiusAttributeFilter];
                       newVal[1] = Number(e.target.value);
                       updateViewerProperty(id,"gtNodeRadiusAttributeFilter",newVal);
                       updateViewerProperty(id, "gtState", "TO_UPDATE");
                     }}
                     min={getNestedProp([0],gtNodeRadiusAttributeFilter)}
                     max={getNestedProp([gtNodeRadiusAttributeProperty,1],gtNodeStats)}
                     value={getNestedProp([1],gtNodeRadiusAttributeFilter)}
                     size={5}
              />
            </div>
          </div>
          <div className="flexFormRow">
            <span className="flexFormColumn">Filter edges by value</span>
            <div className="flexFormColumn" style={{flexDirection:"row"}}>
              <span>From</span>
              <input
                     type="number"
                     id="stick-filter-min"
                     onChange={(e) => {
                       const newVal = [...gtEdgeRadiusAttributeFilter];
                       newVal[0] = Number(e.target.value);
                       updateViewerProperty(id,"gtEdgeRadiusAttributeFilter",newVal);
                       updateViewerProperty(id, "gtState", "TO_UPDATE");
                     }}
                     min={getNestedProp([gtEdgeRadiusAttributeProperty,0],gtEdgeStats)}
                     max={getNestedProp([1], gtEdgeRadiusAttributeFilter)}
                     value={getNestedProp([0], gtEdgeRadiusAttributeFilter)}
                     size={5}
              />
              <span>to</span>
              <input
                     type="number"
                     id="stick-filter-max"
                     onChange={(e) => {
                       const newVal = [...gtEdgeRadiusAttributeFilter];
                       newVal[1] = Number(e.target.value);
                       updateViewerProperty(id,"gtEdgeRadiusAttributeFilter",newVal);
                       updateViewerProperty(id, "gtState", "TO_UPDATE");
                     }}
                     min={getNestedProp([0], gtEdgeRadiusAttributeFilter)}
                     max={getNestedProp([gtEdgeRadiusAttributeProperty,1],gtEdgeStats)}
                     value={getNestedProp([1], gtEdgeRadiusAttributeFilter)}
                     size={5}
              />
            </div>
          </div>
        </div>
        <h5>Picker </h5>
        <div className="flexFormTable">
          <div className="flexFormRow">
            <span className="flexFormColumn">Picker tolerance:</span>
            <input className="flexFormColumn"
                   type="number"
                   id="picker-tolerance"
                   onChange={(e) => updateViewerProperty(id, "gtPickerTolerance", Number(e.target.value))}
                   min={0.0}
                   step={0.0005}
                   max={1.0}
                   value={gtPickerTolerance}
                   size={5}
            />
          </div>
        </div>
      </React.Fragment>
    );
    return "No selected graph"
  }
}

export default GraphVisualizationPanel;

GraphVisualizationPanel.propTypes = {
  gtNodeColormapName: PropTypes.string.isRequired,
  gtEdgeColormapName: PropTypes.string.isRequired,
  gtNodeColorAttributeProperty: PropTypes.string.isRequired,
  gtEdgeColorAttributeProperty: PropTypes.string.isRequired,
  gtNodeRadiusAttributeProperty: PropTypes.string.isRequired,
  gtEdgeRadiusAttributeProperty: PropTypes.string.isRequired,
  gtNodeRadiusRange: PropTypes.array.isRequired,
  gtEdgeRadiusRange: PropTypes.array.isRequired,
  gtNodeRadiusAttributeFilter: PropTypes.array.isRequired,
  gtEdgeRadiusAttributeFilter: PropTypes.array.isRequired,
  gtNodeFilter:PropTypes.array.isRequired,
  gtNodeMapping: PropTypes.array.isRequired,
  gtEdgeMapping: PropTypes.array.isRequired,
  directedGraph: PropTypes.array.isRequired
};

GraphVisualizationPanel.defaultProps = {


};