import React from "react";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import ManualToolHelpPage from "../../visualization/component/annotationTool/ManualToolHelpPage";
import {Editor} from "primereact/components/editor/Editor";
import {Button} from "primereact/components/button/Button";
import DownloadLink from "react-download-link";
import {FileInputButton} from "../../root/component/FileInputButton";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Dialog} from "primereact/components/dialog/Dialog";
import {InputText} from "primereact/components/inputtext/InputText";
import {HelpEditorIcons} from "./HelpEditorIcons";
// import {SplitButton} from "primereact/components/splitbutton/SplitButton";
import {Accordion, AccordionTab} from "primereact/components/accordion/Accordion";
import {ImageForm} from "./ImageForm";
import {TutorialVideo} from "../../visualization/component/TutorialVideo";
import {getParameterFromUrl} from "../../helpers/urls";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import ManualToolHelpPageImagePanel from "../../visualization/component/annotationTool/ManualToolHelpPageImagePanel";

/**
 * This is page displaying contextual information about a given task and tool.
 *
 */
class HelpEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            videoLink: null,
            videoVisible: false,
            previewVisible: false,
            selectedImg: null,
            img: null,
            displayDialog: false,
            gestureTool:Object.keys(HelpEditorIcons.Combos)[0],
            tool:Object.keys(HelpEditorIcons.Tools)[0],
            annotation:Object.keys(HelpEditorIcons.Annotations)[0],
            data: {
                _main: {
                    "goalAndDefinition": "",
                    "tools": "",
                    "combos": "",
                    "annotations": "",
                    "videos": {},
                    "images": []
                },
            },
            imageDialogVisible : false, // image Window
            image : null,  // image from help panel
            imageArray:null,  // image list from help panel
            imageWindowWidth:600,
            imageWindowHeight:400,
        };
        ["updateEditor", "loadAnnotations", "prepareToSave", "addNewImage", "findSelectedIndex", "saveImage", "deleteImage",
            "updateProperty", "onImgSelect", "insertIconToEditor", "updateVideo","toClipboard","fromClipboard","imagePanelMutationHandler"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            });
    }
    componentDidMount() {
        if(this.props.data!=null){
            const _goal = this.goal;
            const _tool = this.tool;
            const _combo = this.combos;
            const _annotation = this.annotations;
            const data = Object.assign({},this.props.data);
            if (data.hasOwnProperty("_main")) {
                _goal.quill.setContents(data._goalDelta);
                _tool.quill.setContents(data._toolDelta);
                _combo.quill.setContents(data._comboDelta);
                _annotation.quill.setContents(data._annotationDelta);

                delete data._goalDelta;
                delete data._toolDelta;
                delete data._comboDelta;
                delete data._annotationDelta;
                this.setState({data: data});
            } else alert("Improper data format")
        };
        this.resizeObserver = new ResizeObserver( this.imagePanelMutationHandler);
        this.resizeObserver.observe(document.getElementById("image-view-window"));
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    imagePanelMutationHandler(mutationsList){
        mutationsList.forEach(mutation => {
            const titlebarHeight = document.getElementById( 'image-view-window' ).getElementsByTagName( 'div' )[0].getBoundingClientRect().height;
            const width = mutation.contentRect.width;
            const height = mutation.contentRect.height;
            if (!Number.isNaN(width) && !Number.isNaN(height))
                this.setState({
                    imageWindowWidth:width,
                    imageWindowHeight:height - titlebarHeight
                })
        })
    }

    updateEditor(field, text) {
        const data = Object.assign({}, this.state.data);
        data._main[field] = text;
        this.setState({data: data});
    }

    /**
     * Uses Quill blots to add element to editor.
     * @param el
     * @param editorRef - React reference to editor
     */
    insertIconToEditor(el, editorRef) {
        const selection = editorRef.quill.getSelection();
        let index = 0;
        if (selection != null)
            index = selection.index;
        if (el.description != null) editorRef.quill.insertText(index, el.description);
        editorRef.quill.insertEmbed(index, 'image', el.src);
    }

    loadAnnotations(file) {
        const _goal = this.goal;
        const _tool = this.tool;
        const _combo = this.combos;
        const _annotation = this.annotations;

        const handleFileRead = (e) => {
            const data = JSON.parse(fileReader.result.toString());
            if (data.hasOwnProperty("_main")) {
                _goal.quill.setContents(data._goalDelta);
                _tool.quill.setContents(data._toolDelta);
                _combo.quill.setContents(data._comboDelta);
                _annotation.quill.setContents(data._annotationDelta);

                delete data._goalDelta;
                delete data._toolDelta;
                delete data._comboDelta;
                delete data._annotationDelta;
                this.setState({data: data});
            } else alert("Improper data format")
        };
        let fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        fileReader.readAsText(file);
    }


    prepareToSave() {
        const tranferObject = Object.assign({}, this.state.data);
        tranferObject['_goalDelta'] = this.goal.quill.getContents();
        tranferObject['_toolDelta'] = this.tool.quill.getContents();
        tranferObject['_comboDelta'] = this.combos.quill.getContents();
        tranferObject['_annotationDelta'] = this.annotations.quill.getContents();
        return tranferObject;
    }


    addNewImage() {
        this.newImg = true;
        this.setState({
            img: {name: '', link: ''},
            displayDialog: true
        });
    }

    findSelectedIndex() {
        return this.state.data._main.images.indexOf(this.state.selectedImg);
    }


    onImgSelect(e) {
        this.newImg = false;
        this.setState({
            displayDialog: true,
            img: Object.assign({}, e.data)
        });
    }

    updateVideo(key, value) {
        let vids = Object.assign({}, this.state.data._main.videos);
        const data = Object.assign({}, this.state.data);
        vids[key] = value;
        data._main.videos = vids;
        this.setState({data: data});
    }


    saveImage() {
        let imgs = [...this.state.data._main.images];
        const data = Object.assign({}, this.state.data);
        if (this.newImg)
            imgs.push(this.state.img);
        else
            imgs[this.findSelectedIndex()] = this.state.img;
        data._main.images = imgs;
        this.setState({data: data, selectedImg: null, img: null, displayDialog: false});
    }

    deleteImage() {
        let index = this.findSelectedIndex();
        let imgs = [...this.state.data._main.images];
        const data = Object.assign({}, this.state.data);
        imgs = imgs.filter((val, i) => i !== index);
        data._main.images = imgs;
        this.setState({
            data: data,
            selectedImg: null,
            img: null,
            displayDialog: false
        });
    }

    updateProperty(property, value) {
        let img = this.state.img;
        img[property] = value;
        this.setState({img: img});
    }

    toClipboard(){
        navigator.clipboard.writeText(JSON.stringify(this.prepareToSave()));
        if (this.props.messageQueue!=null)
        this.props.messageQueue.show( {
            sticky: false,
            severity: 'info',
            summary: 'Info',
            detail:"Content has been copied to clipboard."
        });
    }

    async fromClipboard(){
        try {
            const text = await navigator.clipboard.readText();
            const _goal = this.goal;
            const _tool = this.tool;
            const _combo = this.combos;
            const _annotation = this.annotations;
            const data = JSON.parse(text);
            if (data.hasOwnProperty("_main")) {
                _goal.quill.setContents(data._goalDelta);
                _tool.quill.setContents(data._toolDelta);
                _combo.quill.setContents(data._comboDelta);
                _annotation.quill.setContents(data._annotationDelta);
                    delete data._goalDelta;
                    delete data._toolDelta;
                    delete data._comboDelta;
                    delete data._annotationDelta;
                    this.setState({data: data});
                } else alert("Improper data format")
            this.props.messageQueue.show( {
                sticky: false,
                severity: 'info',
                summary: 'Info',
                detail:"Content has been loaded clipboard."
            });
        } catch (error) {
            alert(error);
        }
    };

    render() {
        const {previewVisible, data,gestureTool,tool,annotation} = this.state;
        const {match,onClose,messageQueue} = this.props;
        let header = <div className="ui-clearfix" style={{lineHeight: '1.87em'}}>Image examples </div>;

        let footer = <div className="ui-clearfix" style={{width: '100%'}}>
            <Button style={{float: 'left'}} label="Add" icon="ui ui-plus" onClick={this.addNewImage}/>
        </div>;

        let dialogFooter = <div className="ui-dialog-buttonpane ui-clearfix">
            <Button label="Delete" icon="fa fa-times" onClick={this.deleteImage}/>
            <Button label="Save" icon="fa fa-check" onClick={this.saveImage}/>
        </div>;

        const itemTemplate = (option) => {
                return (
                  <div className="ui-helper-clearfix">
                      <i className={option.icon} />
                      <span style={{margin: '.5em .25em 0.5em 0.5em'}}>{option.label}</span>
                  </div>
                );
        };


        const tutorialVid = (this.state.videoLink != null && this.state.videoVisible) ? <TutorialVideo
            visible={this.state.videoVisible}
            videoId={getParameterFromUrl(this.state.videoLink, 'v')}
            onEnd={() => {
                this.setState({videoVisible: false});
            }}/> : null;

        return <React.Fragment>
            {tutorialVid}
            <ManualToolHelpPage visible={previewVisible}
                                onHide={() => this.setState({previewVisible: false})}
                                onVideoLinkClick={(videoLink) => this.setState({
                                    previewVisible: false,
                                    videoVisible: true,
                                    videoLink: videoLink
                                })}
                                helpUrl={null}
                                helpPanel={this.state.data}
                                subTask={null}
                                setImageVisible={(image,imageArray)=>{this.setState({image:image, imageDialogVisible:true,imageArray:imageArray})}}
            />
            <Dialog onHide={() => {  this.setState({image: null,imageDialogVisible:false}); }}
                    visible={this.state.imageDialogVisible}
                    header={(this.state.image != null) ? this.state.image.name : "Image preview"}
                    contentStyle={
                        {
                            width: this.state.imageWindowWidth+"px",
                            height: this.state.imageWindowHeight+"px",
                            padding:"0"
                        }
                    }
                    id={"image-view-window"}
            >
                {this.state.image != null &&
                <ManualToolHelpPageImagePanel
                  width={this.state.imageWindowWidth}
                  height={this.state.imageWindowHeight}
                  src={this.state.image.src}
                  alt={this.state.image.name}
                  onPrevious={()=>{
                      const ind = this.state.imageArray.findIndex(el=>el===this.state.image);
                      this.setState({
                          image: ind!=null && ind > 0? this.state.imageArray[ind-1] : this.state.imageArray[0]
                      })}}
                  onNext={()=>{
                      const ind = this.state.imageArray.findIndex(el=>el===this.state.image);
                      this.setState({
                          image: ind!=null && ind < (this.state.imageArray.length -1)? this.state.imageArray[ind+1] : this.state.imageArray[this.state.imageArray.length-1]
                      })}}
                /> }
            </Dialog>
            {!this.state.videoVisible &&
            <div className="ui-g-12">
                <div className="ui-g-4">
                </div>
                <div className="ui-g-8">
                    {match.path.toLowerCase().includes('workflow') &&
                    <Button label={'Close editing'} onClick={() => onClose(this.prepareToSave())}/>}
                    <Button label={'Preview'} onClick={() => this.setState({previewVisible: true})}/>
                    <span title={"Save to file on local disk"}>
                    <DownloadLink
                      className={"ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only fa fa-save"}
                      style={{color:"white"}}
                      label={""}
                      filename="HelpPanel.json"
                      tagName={"i"}
                      exportFile={() => JSON.stringify(this.prepareToSave())}/>
                    </span>
                    <span title={"Load annotations from your local disk"}>
                    <FileInputButton
                        style={{display: "inline-block"}}
                        label=""
                        filter="application/json"
                        onChange={e => this.loadAnnotations(e.target.files[0])}
                    />
                    </span>
                    <Button icon={"fa fa-copy"} onClick={()=>this.toClipboard()} title={"Copy to clipboard"}/>
                    <Button icon={"fa fa-clipboard"} onClick={()=>this.fromClipboard()} title={"Load from clipboard"}/>
                    <Accordion>
                        <AccordionTab header={"Instructions"}>
                            <Editor
                                ref={ref => {
                                    this.goal = ref
                                }}
                                value={data._main.goalAndDefinition}
                                id="goal-editor"
                                onTextChange={(e) => this.updateEditor("goalAndDefinition", e.htmlValue)}/>
                            <Button label="Clear" icon="fa fa-times"
                                    onClick={() => this.updateEditor('goalAndDefinition', '')}/>
                        </AccordionTab>
                        <AccordionTab header={"Examples"}>
                            <DataTable value={this.state.data._main.images}
                                       paginator={true}
                                       rows={15} header={header} footer={footer}
                                       selectionMode="single"
                                       selection={this.state.selectedImg}
                                       onSelectionChange={e => this.setState({selectedImg: e.data})}
                                       onRowSelect={this.onImgSelect}>
                                <Column field="name" header="Name" sortable={true}/>
                                <Column field="src" header="Link" sortable={true}/>
                            </DataTable>
                            For Google Drive use proper parameters eg.: https://drive.google.com/<strong>uc?export=download&id=</strong>10Wq8oDibc_3CoAUfhjVvFMjVYyFt4Leg
                        </AccordionTab>
                        <AccordionTab header={"Videos"}>
                            <span className="ui-float-label" style={{margin: "1em"}}>
                           <InputText id="help-panel-video-form-instructions"
                                      value={this.state.data._main.videos.goalAndDefinition}
                                      onChange={(e) => this.updateVideo("goalAndDefinition", e.target.value)}
                                      style={{width: "25em", margin: "0.25em"}}/>
                                                         <label htmlFor={"help-panel-video-form-instructions"}>Instructions link</label>
                            </span>

                            <br/>

                            <span className="ui-float-label" style={{margin: "1em"}}>
                           <InputText id="help-panel-video-form-tools"
                                      value={this.state.data._main.videos.tools}
                                      onChange={(e) => this.updateVideo("tools", e.target.value)}
                                      style={{width: "25em", margin: "0.25em"}}/>
                            <label htmlFor={"help-panel-video-form-tools"}>Tools link</label>
                            </span><br/>
                            For YouTube link use proper parameters eg.: https://www.youtube.com/<strong>watch?v=</strong>I5vJ8ST4K60<strong>&feature=youtu.be</strong>
                        </AccordionTab>
                        <AccordionTab header={"Tools"}>
                            <Editor
                                ref={ref => {
                                    this.tool = ref
                                }}
                                value={data._main.tools}
                                id="tool-editor"
                                onTextChange={(e) => this.updateEditor("tools", e.htmlValue)}/>
                            <Button label="Clear" icon="fa fa-times" onClick={() => this.updateEditor('tools', '')}/>
                            <Dropdown value={tool}
                                      onChange={(e)=>{this.setState({tool:e.value})}}
                                      options={Object.keys(HelpEditorIcons.Tools).map(el=>{
                                          return {
                                              ...HelpEditorIcons.Tools[el],
                                              value:el
                                          }
                                      })}
                                      itemTemplate={itemTemplate}
                            />
                            <Button label="Add Tool" icon="fa fa-plus" onClick={() => {this.insertIconToEditor(HelpEditorIcons.Tools[tool], this.tool)}} />

                            {/*<SplitButton label="Add Tool" icon="fa fa-plus" onClick={() => {*/}
                            {/*}} model={Object.values(HelpEditorIcons.Tools).map(el => {*/}
                            {/*    el['command'] = () => this.insertIconToEditor(el, this.tool);*/}
                            {/*    return el;*/}
                            {/*})}/>*/}
                        </AccordionTab>
                        <AccordionTab header={"Gestures"}>
                            <Editor
                                ref={ref => {
                                    this.combos = ref
                                }}
                                value={data._main.combos}
                                id="combos-editor"
                                onTextChange={(e) => this.updateEditor("combos", e.htmlValue)}/>
                            <Button label="Clear" icon="fa fa-times" onClick={() => this.updateEditor('combos', '')}/>
                            <Dropdown value={gestureTool}
                                      onChange={(e)=>{this.setState({gestureTool:e.value})}}
                                      options={Object.keys(HelpEditorIcons.Combos).map(el=>{
                                          return {
                                              ...HelpEditorIcons.Combos[el],
                                              value:el
                                          }
                                      })}
                            />
                            <Button label="Add Combo" icon="fa fa-plus" onClick={() => {this.insertIconToEditor(HelpEditorIcons.Combos[gestureTool], this.combos)}} />
                            {/*<SplitButton label="Add Combo" icon="fa fa-plus" onClick={() => {*/}
                            {/*}} model={Object.values(HelpEditorIcons.Combos).map(el => {*/}
                            {/*    el['command'] = () => this.insertIconToEditor(el, this.combos);*/}
                            {/*    return el;*/}
                            {/*})}/>*/}
                        </AccordionTab>
                        <AccordionTab header={"Forms"}>
                            <Editor
                                ref={ref => {
                                    this.annotations = ref
                                }}
                                value={data._main.annotations}
                                id="annotations-editor"
                                onTextChange={(e) => this.updateEditor("annotations", e.htmlValue)}/>
                            <Button label="Clear" icon="fa fa-times"
                                    onClick={() => this.updateEditor('annotations', '')}/>
                            <Dropdown value={annotation}
                                      onChange={(e)=>{this.setState({annotation:e.value})}}
                                      options={Object.keys(HelpEditorIcons.Annotations).map(el=>{
                                          return {
                                              ...HelpEditorIcons.Annotations[el],
                                              value:el
                                          }
                                      })}
                            />
                            <Button label="Add Annotation UI" icon="fa fa-plus" onClick={() => {this.insertIconToEditor(HelpEditorIcons.Annotations[annotation], this.annotations)}} />
                            {/*<SplitButton label="Add Annotation UI" icon="fa fa-plus" onClick={() => {*/}
                            {/*}} model={Object.values(HelpEditorIcons.Annotations).map(el => {*/}
                            {/*    el['command'] = () => this.insertIconToEditor(el, this.annotations);*/}
                            {/*    return el;*/}
                            {/*})}/>*/}
                        </AccordionTab>
                    </Accordion>

                    <Dialog visible={this.state.displayDialog}
                            width="300px" header="Image Details"
                            modal={false} footer={dialogFooter}
                            onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.img &&
                            <ImageForm onChange={(e) => {
                                this.updateProperty('name', e.target.value)
                            }} img={this.state.img} onChange1={(e) => {
                                this.updateProperty('src', e.target.value)
                            }}/>
                        }
                    </Dialog>
                </div>
            </div>}

        </React.Fragment>;
    }
}


HelpEditor.propTypes = {
    visible: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onClose:PropTypes.func,
    messageQueue: PropTypes.object
};
export default withRouter(HelpEditor);
